
import L, { Layer } from 'leaflet';
import '../libs/GridLayer.GeoJSON';

export function createGeoJSONWithGeoJSONVT(geojsonDocument: any, customOptions: any = {}): Layer {

    const tileOptions = {
        maxZoom: 24,  // max zoom to preserve detail on
        tolerance: 5, // simplification tolerance (higher means simpler)
        indexMaxZoom: 4,        // max zoom in the initial tile index
        indexMaxPoints: 100000, // max number of points per tile in the index
        solidChildren: false,    // whether to include solid tile children in the index
        maxNativeZoom: 19,
        style: {
            fillRule: 'evenodd',
            weight: 1,
            fillOpacity: 0.2,
            stroke: 'black',
            lineWidth: 1
        },
        zIndex: 999,
        debug: 0,
        ...customOptions
    };

    const canvasLayer = L.gridLayer.geoJson(geojsonDocument, tileOptions);
    return canvasLayer;
}