import { useEffect, useState } from 'react';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../helpers/GeovisHooks';

import ServerRoutesGen from '../../../../server/Routes/ServerRoutesGen';
import { createChartRenderedFunc } from './tools';

interface IComponentProps extends IWithGeovisServerProps {
    reportId: number;
    chartId: number;
    projectId: number;
    settingsId?: string;
    userId?: string;
    userToken?: string;
    drawBorder: boolean;
    height: number;
    pageNum: number;
}

const ChartImageRenderComponent = ({ height, drawBorder, projectId, settingsId, reportId, chartId, userId, userToken, pageNum, Server }: IComponentProps) => {

    // // we should check when image is loaded
    // // this solution don't show image in PDF
    // return (<div
    //     style={{
    //         height: `${height}px`, 
    //         backgroundImage: `url(${Server.getEndpointServerUrl(url)})`,
    //         backgroundRepeat: 'no-repeat',
    //         backgroundPosition: 'center',
    //         backgroundSize: 'contain'
    //     }}
    // />);

    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

    useEffect(() => {

        if (!imageRef) {
            return;
        }

        const ctrlHeight = imageRef.clientHeight;
        const ctrlWidth = imageRef.clientWidth;

        const url = ServerRoutesGen.ReportPdfRenderData.GetGeovisReportChartToPng.patch(projectId, reportId, settingsId, pageNum, chartId, ctrlHeight, ctrlWidth, userId, userToken).path;

        imageRef.onload = createChartRenderedFunc(pageNum, chartId);
        imageRef.src = Server.getEndpointServerUrl(url);

    }, [imageRef]);

    return (
        <div style={{ height: `${height}px`, width: '100%', borderRight: drawBorder ? '2px solid black' : '' }}>
            <img
                ref={setImageRef}
                // src={Server.getEndpointServerUrl(url)}
                // onLoad={createChartRenderedFunc(pageNum, chartId)}
                style={{
                    height: '100%',
                    width: '100%',
                    // // if scale PDF, then the img could be overlaid on the borders of external div
                    // borderRight: '1px solid black',
                    // borderLeft: '1px solid black',
                    // // width: 'calc(100% - 2px)',
                    // // marginLeft: '1px',
                    // // marginRight: '1px',
                }}
            />
        </div>
    );
}

export const ChartImageRender = withGeovisServer(ChartImageRenderComponent);