import React, { useState } from "react";
import Button from "@atlaskit/button";
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close'
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { VibrationPreviewChartRender } from "./VibrationPreviewChartRender";
import { VibrationChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { ChartType } from "../../../../../../server/AVTService/TypeLibrary/Common/ChartType";
import { VibrationEventPreviewChartRender } from "./VibrationEventPreviewChartRender";
import { XyVibrationPreviewChartRender } from "./XyVibrationPreviewChartRender";
import { XYVibrationChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XYVibrationChartModel";

interface IComponentProps {
    chart: GeovisChartModel;
    projectId: number;
}

interface IComponentState {
    showEvent: boolean;
    eventId: string;
    fullId: string;
    eventDate: string;
}

export const VibrationPreviewChartRenderHost = ({
    chart,
    projectId
}: IComponentProps) => {
    const [state, setState] = useState<IComponentState>({
        eventId: "",
        showEvent: false,
        eventDate: '',
        fullId: ''
    })

    const onShowEventChart = (eventId: string, fullId: string, eventDate: string) => {
        setState({
            eventDate,
            eventId,
            fullId,
            showEvent: true
        })
    }

    const onCloseEventChart = () => {
        setState({
            eventDate: '',
            eventId: '',
            fullId: '',
            showEvent: false
        })
    }

    return (
        <React.Fragment>
            {!state.showEvent && chart.Type === ChartType.VibrationDiagramm &&
                <VibrationPreviewChartRender
                    chart={chart as VibrationChartModel}
                    onShowEventChart={onShowEventChart}
                    projectId={projectId}
                />
            }
            {!state.showEvent && chart.Type === ChartType.XYVibrationEventDiagram &&
                <XyVibrationPreviewChartRender
                    chart={chart as XYVibrationChartModel}
                    onShowEventChart={onShowEventChart}
                    projectId={projectId}
                />
            }
            {state.showEvent &&
                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                        <div style={{ flexGrow: 2 }}>
                            {/*  */}
                        </div>
                        <div>
                            <Button
                                iconBefore={<EditorCloseIcon label="close" />}
                                onClick={onCloseEventChart}
                            />
                        </div>
                    </div>
                    <div style={{ height: '100%' }}>
                        <VibrationEventPreviewChartRender
                            chart={chart as VibrationChartModel}
                            eventId={state.eventId}
                            fullId={state.fullId}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}