import { Reducer } from "redux";
import { elementsToMap } from "../../helpers/StorageHelper";
import { DtsSectionInfo } from "../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfo";
import { SensorInfo } from "../../server/GEOvis3/Model/SensorInfo";
import {
    PROJECT_DTS_ADD_SECTION_DIALOG_SHOW,
    PROJECT_DTS_EDIT_SECTION_DIALOG_HIDE,
    PROJECT_DTS_EDIT_SECTION_DIALOG_SHOW,
    PROJECT_DTS_REMOVE_SECTION_DIALOG_HIDE,
    PROJECT_DTS_REMOVE_SECTION_DIALOG_SHOW,
    PROJECT_DTS_SECTION_INFO_LOADED,
    PROJECT_DTS_SECTION_INFO_LOADING,
    PROJECT_DTS_SECTION_INFO_LOADING_ERROR,
    PROJECT_DTS_SECTION_INFO_UPDATE_STATE,
    PROJECT_DTS_SECTION_PROPERTIES_CALCULATION_FINISHED,
    PROJECT_DTS_SECTION_PROPERTIES_CALCULATION_STARTED,
    PROJECT_DTS_SECTION_T_SENSORS_SELECT_DIALOG_HIDE,
    PROJECT_DTS_SECTION_T_SENSORS_SELECT_DIALOG_SHOW,
    PROJECT_DTS_SECTION_TAB_CHANGED,
    PROJECT_DTS_T_SENSOR_INFOS_LOADED,
    PROJECT_DTS_T_SENSOR_INFOS_LOADING,
    PROJECT_DTS_T_SENSOR_INFOS_LOADING_ERROR
} from "../actions/dtsConfigurationActions";
import { ISensorsInfoStorage } from "../data.types";
import { IDtsConfigurationAction, IDtsConfigurationState, IDtsSectionInfoStorage, ISelectTSensorsState } from "../dtsConfiguration.types"
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../types";

const dtsSectionInfoStorageInitialState: IDtsSectionInfoStorage = {
    ...defaultSomethingStorageState,
    dtsSectionInfo: {
        ...new DtsSectionInfo()
    }
}

const selectSensorInfoStorageInitialState: ISensorsInfoStorage = {
    ...defaultSomethingStorageState,
    sensorsInfo: new Map<string, SensorInfo>()
}

const selectSensorsInitialState: ISelectTSensorsState = {
    showSelectDialog: false,
    sensorsInfoStorage: {
        ...selectSensorInfoStorageInitialState
    }
}

export const dtsConfigurationInitialState: IDtsConfigurationState = {
    showEditDialog: false,
    showRemoveDialog: false,
    sectionId: "",
    dtsSectionInfoStorage: {
        ...dtsSectionInfoStorageInitialState
    },
    isCalculationInProgress: false,
    tabIndex: 0,
    selectTSensorsState: {
        ...selectSensorsInitialState
    }
};

const dtsConfigurationReducer: Reducer<IDtsConfigurationState> = (
    state: IDtsConfigurationState = dtsConfigurationInitialState,
    action: IDtsConfigurationAction
): IDtsConfigurationState => {

    switch (action.type) {
        case PROJECT_DTS_ADD_SECTION_DIALOG_SHOW:
            return {
                ...dtsConfigurationInitialState,
                showEditDialog: true
            }

        case PROJECT_DTS_EDIT_SECTION_DIALOG_SHOW:
            if (!action.sectionId) {
                return state;
            }

            return {
                ...dtsConfigurationInitialState,
                showEditDialog: true,
                sectionId: action.sectionId
            }

        case PROJECT_DTS_EDIT_SECTION_DIALOG_HIDE:
            return {
                ...state,
                showEditDialog: false,
                sectionId: ""
            }

        case PROJECT_DTS_REMOVE_SECTION_DIALOG_SHOW:
            if (!action.sectionId) {
                return state;
            }

            return {
                ...dtsConfigurationInitialState,
                showRemoveDialog: true,
                sectionId: action.sectionId
            }

        case PROJECT_DTS_REMOVE_SECTION_DIALOG_HIDE:
            return {
                ...state,
                showRemoveDialog: false,
                sectionId: ""
            }

        case PROJECT_DTS_SECTION_INFO_LOADING:
            return {
                ...state,
                dtsSectionInfoStorage: {
                    ...dtsSectionInfoStorageInitialState
                }
            }

        case PROJECT_DTS_SECTION_INFO_LOADED:
            if (!action.dtsSectionInfo) {
                return state;
            }

            return {
                ...state,
                dtsSectionInfoStorage: {
                    ...loadedSomethingStorageState,
                    dtsSectionInfo: {
                        ...action.dtsSectionInfo
                    }
                }
            }

        case PROJECT_DTS_SECTION_INFO_LOADING_ERROR:
            return {
                ...state,
                dtsSectionInfoStorage: {
                    ...state.dtsSectionInfoStorage,
                    ...errorSomethingStorageState(action.error)
                }
            }

        case PROJECT_DTS_SECTION_TAB_CHANGED: {
            if (action.tabIndex === undefined) {
                return state;
            }

            return {
                ...state,
                tabIndex: action.tabIndex
            }
        }

        case PROJECT_DTS_SECTION_INFO_UPDATE_STATE:
            if (!action.dtsSectionInfo) {
                return state;
            }

            return {
                ...state,
                dtsSectionInfoStorage: {
                    ...state.dtsSectionInfoStorage,
                    dtsSectionInfo: { ...action.dtsSectionInfo }
                }
            }

        case PROJECT_DTS_SECTION_T_SENSORS_SELECT_DIALOG_SHOW:
            return {
                ...state,
                selectTSensorsState: {
                    ...state.selectTSensorsState,
                    showSelectDialog: true,
                }
            }

        case PROJECT_DTS_SECTION_T_SENSORS_SELECT_DIALOG_HIDE:
            return {
                ...state,
                selectTSensorsState: {
                    ...state.selectTSensorsState,
                    showSelectDialog: false,
                }
            }

        case PROJECT_DTS_T_SENSOR_INFOS_LOADING: {
            return {
                ...state,
                selectTSensorsState: {
                    ...state.selectTSensorsState,
                    sensorsInfoStorage: {
                        ...selectSensorInfoStorageInitialState
                    }
                }
            }
        }

        case PROJECT_DTS_T_SENSOR_INFOS_LOADED: {
            if (!action.sensors) {
                return state;
            }

            return {
                ...state,
                selectTSensorsState: {
                    ...state.selectTSensorsState,
                    sensorsInfoStorage: {
                        ...loadedSomethingStorageState,
                        sensorsInfo: elementsToMap(action.sensors)
                    }
                }
            }
        }

        case PROJECT_DTS_T_SENSOR_INFOS_LOADING_ERROR: {
            return {
                ...state,
                selectTSensorsState: {
                    ...state.selectTSensorsState,
                    sensorsInfoStorage: {
                        ...state.selectTSensorsState.sensorsInfoStorage,
                        ...errorSomethingStorageState(action.error)
                    }
                }
            }
        }

        case PROJECT_DTS_SECTION_PROPERTIES_CALCULATION_STARTED:
            return {
                ...state,
                isCalculationInProgress: true
            }

        case PROJECT_DTS_SECTION_PROPERTIES_CALCULATION_FINISHED:
            return {
                ...state,
                isCalculationInProgress: false
            }
    }

    return state;
}

export default dtsConfigurationReducer;