//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartData } from './GeovisChartData';
import { HtmlReportSensorDescriptionViewModel } from '../Report/Model/HtmlReportSensorDescriptionViewModel';
import { LogbookModel } from '../Logbook/LogbookModel';
import { VibrationBackgroundVelocityDataModel } from './VibrationBackgroundVelocityDataModel';
import { VibrationBackgroundFrequencyDataModel } from './VibrationBackgroundFrequencyDataModel';
import { PlotBandModel } from '../Model/GeovisCharts/PlotBandModel';

export class VibrationChartData extends GeovisChartData
{
	public Descriptions: HtmlReportSensorDescriptionViewModel[];
	public Logbooks: LogbookModel[];
	public VelocityData: VibrationBackgroundVelocityDataModel[];
	public FrequencyData: VibrationBackgroundFrequencyDataModel[];
	public PlotBands: PlotBandModel[];
}
