/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 16.07.2020
 * @description Select tool methods and helpers
 */

import { OptionType } from '@atlaskit/select';
import { mapToListOfElements } from '../../helpers/StorageHelper';
import { getProjectTypeToName, ProjectType, ProjectTypeList } from '../../server/AVTService/TypeLibrary/Common/ProjectType';
import { getServiceModelToName, ServiceModel, ServiceModelList } from '../../server/AVTService/TypeLibrary/Common/ServiceModel';
import { GeovisUserRoleEnum, getGeovisUserRoleEnumToName } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserRoleEnum';
import AuthService from '../../services/AuthService';
import { IBusinessCompaniesStorage } from '../../store/businessData.types';
import { getIGvOptionType, IGvOptionType } from './GeovisSelect_tools';

/**
 * Company to select option
 * @param company 
 */
export const companyToSelectOption = (companiesStorage: IBusinessCompaniesStorage, companyId: string): IGvOptionType<string> | undefined => {

    if (!companyId) {
        return undefined;
    }

    const company = companiesStorage.companies.get(companyId);
    if (company) {
        return { label: company.Name, value: company.Id };
    }

    return { label: 'Unknown company', value: companyId };
}

/**
 * Loaded companies storage to select options
 * @param companiesStorage 
 */
export const companiesToOptions = (companiesStorage: IBusinessCompaniesStorage): ReadonlyArray<IGvOptionType<string>> => {
    if (companiesStorage.isLoading) {
        return [];
    }

    return mapToListOfElements(companiesStorage.companies).map<IGvOptionType<string>>(company => ({ label: company.Name, value: company.Id }));
}

/**
 * Get user roles select options
 */
export const getUserRoleSelectOptions = (userCompanyId: string): Array<IGvOptionType<GeovisUserRoleEnum>> => {

    if (AuthService.hasUserTypeAsAdmin()) {
        return AuthService.getUserRoles().map<IGvOptionType<GeovisUserRoleEnum>>(value => getIGvOptionType(value, getGeovisUserRoleEnumToName));
    }

    const selectOptionUser = getIGvOptionType(GeovisUserRoleEnum.User, getGeovisUserRoleEnumToName);

    if (AuthService.hasUserTypeAsCompanyAdmin() && userCompanyId === AuthService.currentUserCompanyId()) {
        return [selectOptionUser, getIGvOptionType(GeovisUserRoleEnum.CompanyAdmin, getGeovisUserRoleEnumToName)];
    }

    return [selectOptionUser];
}

export const projectTypeToSelectOption = (projectType: ProjectType): OptionType => ({ label: getProjectTypeToName(projectType), value: projectType });
export const getProjectTypeSelectOptions = (): OptionType[] => ProjectTypeList.map<OptionType>(projectType => projectTypeToSelectOption(projectType));

export const serviceModelToSelectOption = (serviceModel: ServiceModel): OptionType => ({ label: getServiceModelToName(serviceModel), value: serviceModel });
export const getServiceModelOptions = (): OptionType[] => ServiceModelList.map<OptionType>(serviceModel => serviceModelToSelectOption(serviceModel));