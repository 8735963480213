/**
 * The reports list, draw a table with reports in the reportsStorage
 * Used in ProjectOverviewPage (right bottom corner) and in ProjectReportsPage
 */

import Button from '@atlaskit/button';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';
import { connect } from 'react-redux';
import { ReactComponent as InvisibleIcon } from 'src/resources/icons/eye-off.svg';
import { IS_PRODUCTION } from '../../ApiConfig';
import { mapToListOfElementsOfIds } from '../../helpers/StorageHelper';
import { getReportName } from '../../helpers/types';
import i18next from '../../i18n';
import { ProjectReportInfo } from '../../server/ProjectReportInfo';
import AuthService from '../../services/AuthService';
import { IReportsStorage } from '../../store/data.types';
import { IGeovisStoreState } from '../../store/store.types';
import { DynamicTableWithFixedHeader } from '../DynamicTableWithFixedHeader';

interface IStateToProps {
    reportsStorage: IReportsStorage;
}

/**
 * It is OwnProps of the connected component
 */
export interface IReportsListProps {
    title: string;
    searchQuery: string;
    projectId: number;

    onClick: (report: ProjectReportInfo) => void;
}

interface IComponentProps extends IStateToProps, IReportsListProps {

}

const Component = ({ reportsStorage, title, projectId, searchQuery, onClick }: IComponentProps) => {

    const isAdminOfProject = AuthService.isActualAdminOfProject(projectId);
    const { isLoading, reports } = reportsStorage;

    const linkToGeovisReport = (report: ProjectReportInfo) => {

        const onReportClick = () => onClick(report);

        if (!report.IsPublic) {
            return (
                <Button
                    appearance="link"
                    spacing="compact"
                    onClick={onReportClick}
                    iconAfter={report.IsNewGeovisReport || IS_PRODUCTION ? undefined : <EditorWarningIcon label='' />}
                >
                    <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <span style={{ lineHeight: "24px", float: "left" }}>{getReportName(report)}</span>
                        <InvisibleIcon style={{ float: "left", marginLeft: 5, height: '20px', width: '20px', marginTop: '2px' }} />
                    </div>
                </Button>
            );
        }

        return (
            <Button
                appearance="link"
                spacing="compact"
                onClick={onReportClick}
                iconAfter={report.IsNewGeovisReport || IS_PRODUCTION ? undefined : <EditorWarningIcon label='' />}
            >
                {getReportName(report)}
            </Button>
        );
    }

    const head = {
        cells: [{
            key: 'reportTitle',
            content: i18next.t("reportTitleColumnName"),
            isSortable: true
        }]
    };


    const filterReportFunc = (report: ProjectReportInfo): boolean => {
        if (searchQuery && searchQuery.length > 0) {
            const lowerQuery = searchQuery.toLowerCase();
            const name = report.Title || report.Name;
            try {
                if (name.toLowerCase().search(lowerQuery) === -1) {
                    return false;
                }
            } catch {
                return false;
            }
        }
        if (!isAdminOfProject && !report.IsPublic) {
            return false;
        }
        return true;
    }

    const rows = mapToListOfElementsOfIds(reports).filter(r => filterReportFunc(r)).map(report => ({
        key: `row-${report.Id}`,
        cells: [{
            key: report.Title || report.Name,
            content: linkToGeovisReport(report)
        }]
    }));

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className="caption">
                {title}
            </div>
            <DynamicTableWithFixedHeader
                defaultSortKey='reportTitle'
                defaultSortOrder='ASC'
                isLoading={isLoading}
                head={head}
                rows={rows}
            />
        </div>
    );
}


const mapStateToProps = ({ data: { reportsStorage } }: IGeovisStoreState): IStateToProps => ({
    reportsStorage
});

export const ReportsList = connect<IStateToProps, never, IReportsListProps>(mapStateToProps)(Component);