import { Dispatch } from "redux";
import AddIcon from '@atlaskit/icon/glyph/add'
import TrashIcon from '@atlaskit/icon/glyph/trash';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import { connect } from "react-redux";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import Textfield from "@atlaskit/textfield";
import { debounce } from "lodash";
import { HeadCellType, HeadType, RowCellType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import Button from "@atlaskit/button";
import { useParams } from "react-router-dom";
import { ToggleStateless } from "@atlaskit/toggle";
import Checkbox from "@atlaskit/checkbox";
import { GeovisUserCommonInfo } from "../../../../server/GEOvis3/Model/User/GeovisUserCommonInfo";
import { IGeovisProjectUsersStorage } from "../../../../store/data.types";
import { IGeovisStoreState } from "../../../../store/store.types";
import { IGeovisAction, ISomethingStorageBaseEx, defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../../../../store/types";
import { projectDataUsersUpdate } from "../../../../store/creators/dataCreators";
import { AlarmActionUsersPreset } from "../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUsersPreset";
import { t } from "../../../../i18n";
import { GeovisUserTableInfo } from "../../../../server/GEOvis3/Model/User/GeovisUserTableInfo";
import UserProfileEditorDialog from "../../../../components/users/UserProfileEditorDialog";
import { CompanyInfo } from "../../../../server/GEOvis3/Model/Company/CompanyInfo";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import { fetchServerElements } from "../../../../helpers/ProjectDataHelper";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import IRouteParams from "../../../../helpers/IRouteParams";
import { PresetUser } from "../../../../server/AVTService/TypeLibrary/Alarming/PresetUser";
import { getPredefinedColors, invertArray, verifyUsersPhone } from "../alarmEdit/AlarmActionsEdit/tools";
import { GeovisDynamicTable } from "../../../../components/GeovisDynamicTable";
import { AlarmActionAddUserDialog } from "../alarmEdit/AlarmActionsEdit/AlarmActionAddUserDialog";
import { UserEditorMode } from "../../../../components/users/types";

interface IComponentStateProps {
    projectUsersStorage: IGeovisProjectUsersStorage;
}

interface IComponentDispatchProps {
    projectUsersUpdate: (...projectUsers: GeovisUserCommonInfo[]) => void;
}

interface IComponentOwnProps {
    onClose: () => void;
    preset: AlarmActionUsersPreset;
    onSave: (preset: AlarmActionUsersPreset) => Promise<void>;
    onCreate: (preset: AlarmActionUsersPreset) => Promise<void>;
}

interface IComponentProps extends IComponentStateProps, IComponentDispatchProps, IComponentOwnProps, IWithGeovisServerProps {

}

interface IComponentState extends ISomethingStorageBaseEx<CompanyInfo[]> {
    preset: AlarmActionUsersPreset;
    showEditUserDialog: boolean;
    userId: string;
    showAddUsersDialog: boolean;
}

const Component = ({
    projectUsersStorage,
    projectUsersUpdate,
    onClose,
    onSave,
    preset,
    Server,
    onCreate
}: IComponentProps) => {

    const { projectId } = useParams<IRouteParams>();

    const numberRef = useRef<HTMLInputElement>(null);

    const [state, setState] = useState<IComponentState>({
        ...defaultSomethingStorageState,
        preset,
        showEditUserDialog: false,
        userId: '',
        data: [],
        showAddUsersDialog: false
    })

    useEffect(() => {
        (async function loadCompaniesData() {
            const url = ServerRoutesGen.Account.RelatedCompanies.patch(projectId);
            const response = await fetchServerElements<CompanyInfo[]>(Server, url);
            if (!response.Success) {
                setState({
                    ...state,
                    ...errorSomethingStorageState(response.Messages.join("; ")),
                    data: []
                })
                return;
            }
            setState({
                ...state,
                ...loadedSomethingStorageState,
                data: response.Data
            })
        })();
    }, [1])

    const isCreateMode = preset.Id === "";

    const onCloseHandler = () => {
        onClose();
    }

    const onSaveHandler = async () => {
        isCreateMode
            ? await onCreate(state.preset)
            : await onSave(state.preset);
    }

    const showUserEditDialog = (userId: string) => () => {
        setState({
            ...state,
            showEditUserDialog: true,
            userId
        })
    }

    const hideUserEditDialog = () => {
        setState({
            ...state,
            showEditUserDialog: false,
            userId: ""
        })
    }

    const onCloseAddUserDialog = () => {
        setState({
            ...state,
            showAddUsersDialog: false
        })
    }

    const onShowAddUsersDialog = () => {
        setState({
            ...state,
            showAddUsersDialog: true
        })
    }

    const saveUserData = (user: GeovisUserTableInfo) => {
        projectUsersUpdate(user);
        hideUserEditDialog();
    }

    const onAddNewUserIds = (usersIds: string[]) => {
        const indexArr: number[] = [];
        for (let index = 0; index < state.preset.ConditionsCount; index++) {
            indexArr.push(index);
        }

        // state.preset.Users = [];

        usersIds.forEach(id => {
            const exUser = state.preset.Users.find(u => u.UserId === id);
            if (!exUser) {
                state.preset.Users.push({
                    UserId: id,
                    SmsNotifyConditionsIndexes: [],
                    EmailNotifyConditionsIndexes: indexArr
                })
            }

        });

        setState({
            ...state,
            showAddUsersDialog: false,
            preset: {
                ...state.preset,
                Users: state.preset.Users
            }
        });
    }

    const onChangeNameDebounced = debounce((value: string) => setState({ ...state, preset: { ...state.preset, Name: value } }), 300);

    const onChangeNameHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onChangeNameDebounced(event.currentTarget.value);
    }

    const onChangeCountOfConditionsDebounced = debounce((value: number) => setState({ ...state, preset: { ...state.preset, ConditionsCount: value } }), 300);

    const onChangeCountOfConditionsHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        const value = +event.currentTarget.value;
        if (isNaN(value)) {
            return;
        }
        if (value < 1) {
            onChangeCountOfConditionsDebounced(1);
            if (numberRef.current !== null) {
                numberRef.current.value = "1";
            }
            return;
        }
        if (value > 10) {
            onChangeCountOfConditionsDebounced(10);
            if (numberRef.current !== null) {
                numberRef.current.value = "10";
            }
            return;
        }
        onChangeCountOfConditionsDebounced(value);
    }

    const getUserName = (userId: string): string => {
        const user = projectUsersStorage.users.get(userId);
        if (!user) {
            return t("Unknown user")
        }
        return `${user.Name}, ${user.Forename}`;
    }

    const getUserCompany = (userId: string): string => {
        if (!state.isLoaded) {
            return "";
        }
        const user = projectUsersStorage.users.get(userId);
        if (!user) {
            return t("Unknown company")
        }
        const company = state.data.find(c => c.Id === user.CompanyId);
        return company === undefined ? "" : company.Name;
    }

    const removeUserFromReceivers = (userId: string) => () => {
        setState({
            ...state,
            preset: {
                ...state.preset,
                Users: state.preset.Users.filter(u => u.UserId !== userId)
            }
        });
    }

    const onChangeUserReceivingState = (isEmail: boolean, userId: string, conditionIndex: number) => (event: SyntheticEvent<HTMLInputElement>) => {
        const user = state.preset.Users.find(u => u.UserId === userId);
        if (!user) {
            return;
        }

        if (event.currentTarget.checked) {
            if (isEmail) {
                user.EmailNotifyConditionsIndexes.push(conditionIndex);
            }
            else {
                user.SmsNotifyConditionsIndexes.push(conditionIndex);
            }
        }
        else {
            if (isEmail) {
                user.EmailNotifyConditionsIndexes = user.EmailNotifyConditionsIndexes.filter(c => c !== conditionIndex);
            }
            else {
                user.SmsNotifyConditionsIndexes = user.SmsNotifyConditionsIndexes.filter(c => c !== conditionIndex);
            }
        }

        setState({
            ...state,
            preset: {
                ...state.preset,
                Users: state.preset.Users
            }
        })
    }

    const getConditionsCells = (user: PresetUser): RowCellType[] => {
        const indexArr: number[] = [];
        for (let index = 0; index < state.preset.ConditionsCount; index++) {
            indexArr.push(index);
        }
        return invertArray(indexArr).map(i => {
            return ({
                key: `cond-${i}`,
                content: (
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                            <ToggleStateless
                                isChecked={user.EmailNotifyConditionsIndexes.includes(i)}
                                onChange={onChangeUserReceivingState(true, user.UserId, i)}
                            />
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                            <ToggleStateless
                                isChecked={user.SmsNotifyConditionsIndexes.includes(i)}
                                isDisabled={!verifyUsersPhone(user.UserId, projectUsersStorage)}
                                onChange={onChangeUserReceivingState(false, user.UserId, i)}
                            />
                        </div>
                    </div>
                )
            })
        })
    }

    const getUsersRows = (): RowType[] => {
        return state.preset.Users.map(user => ({
            cells: [{
                key: 'name',
                content: (
                    <span>{getUserName(user.UserId)}</span>
                )
            }, {
                key: 'company',
                content: (
                    <span>{getUserCompany(user.UserId)}</span>
                )
            },
            ...getConditionsCells(user),
            {
                key: 'actions',
                content: (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <div>
                            <Button
                                iconBefore={<EditFilledIcon label='edit' />}
                                spacing='compact'
                                appearance="subtle-link"
                                onClick={showUserEditDialog(user.UserId)}
                            />
                        </div>
                        <div>
                            <Button
                                iconBefore={<TrashIcon label='remove' />}
                                spacing='compact'
                                appearance="subtle-link"
                                onClick={removeUserFromReceivers(user.UserId)}
                            />
                        </div>
                    </div>
                )
            }]
        }))
    }

    const getHead = (): HeadType => {
        return ({
            cells: [{
                key: 'name',
                width: 10,
                isSortable: false,
                content: (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <span>{t("Receivers")}</span>
                    </div>
                )
            }, {
                key: 'company',
                width: 15,
                isSortable: false,
                content: (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <span>{t("Company")}</span>
                    </div>
                )
            },
            ...getHeadFromConditions(),
            {
                key: "actions",
                width: 15,
            }]
        })
    }

    const allReceiversSelectedForCondition = (isEmail: boolean, conditionIndex: number): boolean => {
        return isEmail
            ? state.preset.Users.every(u => u.EmailNotifyConditionsIndexes.includes(conditionIndex))
            : state.preset.Users.filter(u => verifyUsersPhone(u.UserId, projectUsersStorage)).every(u => u.SmsNotifyConditionsIndexes.includes(conditionIndex));
    }

    const onAllConditionNotificationsChanged = (email: boolean, conditionIndex: number) => (event: SyntheticEvent<HTMLInputElement>) => {
        state.preset.Users.forEach(user => {
            if (email) {
                if (event.currentTarget.checked && !user.EmailNotifyConditionsIndexes.includes(conditionIndex)) {
                    user.EmailNotifyConditionsIndexes.push(conditionIndex);
                }
                else if (!event.currentTarget.checked) {
                    user.EmailNotifyConditionsIndexes = user.EmailNotifyConditionsIndexes.filter(c => c !== conditionIndex);
                }
            }
            else {
                if (event.currentTarget.checked && !user.SmsNotifyConditionsIndexes.includes(conditionIndex) && verifyUsersPhone(user.UserId, projectUsersStorage)) {
                    user.SmsNotifyConditionsIndexes.push(conditionIndex);
                }
                else if (!event.currentTarget.checked) {
                    user.SmsNotifyConditionsIndexes = user.SmsNotifyConditionsIndexes.filter(c => c !== conditionIndex);
                }
            }
        })

        setState({
            ...state,
            preset: {
                ...state.preset,
                Users: state.preset.Users
            }
        })
    }

    const getHeadFromConditions = (): HeadCellType[] => {
        const result: HeadCellType[] = [];

        const indexArr: number[] = [];
        for (let index = 0; index < state.preset.ConditionsCount; index++) {
            indexArr.push(index);
        }

        const predefinedColors = getPredefinedColors();

        const getConditionName = (index: number, collection: number[]): string => {
            if (index === 0) {
                return t("Lowest");
            }
            else if (index === collection.length - 1) {
                return t("Highest")
            }
            return `${t("Condition №")}${index}`;
        }

        invertArray(indexArr).forEach((condition, index, collection) => {
            result.push({
                isSortable: false,
                key: `condition-${condition}`,
                width: 70 / collection.length,
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ background: predefinedColors[condition], display: 'flex', justifyContent: 'space-around' }}>
                            <span>{getConditionName(index, collection)}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ width: '100%', textAlign: 'center', marginLeft: '-3px' }}>
                                    <span>{t("Email")}</span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                                    <Checkbox
                                        isChecked={allReceiversSelectedForCondition(true, condition)}
                                        onChange={onAllConditionNotificationsChanged(true, condition)}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ width: '100%', textAlign: 'center', marginLeft: '-3px' }}>
                                    <span>{t("SMS")}</span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                                    <Checkbox
                                        isChecked={allReceiversSelectedForCondition(false, condition)}
                                        onChange={onAllConditionNotificationsChanged(false, condition)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        })

        return result;
    }

    return (
        <ModalTransition>
            <Modal
                heading={isCreateMode ? t("Create users preset") : `${t("Edit preset ")}${state.preset.Name}`}
                actions={[{
                    text: t("Save"), onClick: onSaveHandler, appearance: 'primary'
                }, {
                    text: t("Close"), onClick: onCloseHandler, appearance: 'default'
                }]}
                height={'100%'}
                width={'60%'}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                        <span>{t("Preset name")}:</span>
                        <div style={{ width: '350px' }}>
                            <Textfield
                                defaultValue={state.preset.Name}
                                onChange={onChangeNameHandler}
                                isCompact={true}
                            />
                        </div>
                        <span>{t("Count of conditions")}:</span>
                        <div>
                            <Textfield
                                defaultValue={state.preset.ConditionsCount}
                                type="number"
                                isCompact={true}
                                onChange={onChangeCountOfConditionsHandler}
                                ref={numberRef}
                            />
                        </div>
                        <div style={{ flexGrow: 4 }}>

                        </div>
                        <div>
                            <Button
                                appearance="primary"
                                iconBefore={<AddIcon label={"add"} />}
                                onClick={onShowAddUsersDialog}
                            >
                                {t("Add user")}
                            </Button>
                        </div>
                    </div>

                    <div style={{ height: '100%' }}>
                        <GeovisDynamicTable
                            rows={getUsersRows()}
                            head={getHead()}
                            isLoading={state.isLoading}
                            emptyView={<span>{t("No users added")}</span>}
                        />
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{`*${t("SMS sending only works with CH/FL network providers")}`}</span>
                    </div>

                    {state.showEditUserDialog && (
                        <UserProfileEditorDialog
                            userId={state.userId}
                            editMode={UserEditorMode.Edit}
                            onSave={saveUserData}
                            onClose={hideUserEditDialog}
                        />
                    )}
                    {state.showAddUsersDialog && (
                        <AlarmActionAddUserDialog
                            addedUsersIds={state.preset.Users.map(u => u.UserId)}
                            companies={state.data}
                            projectUsersStorage={projectUsersStorage}
                            onClose={onCloseAddUserDialog}
                            onAdd={onAddNewUserIds}
                        />
                    )}
                </div>

            </Modal>
        </ModalTransition>
    )
}

const mapStateToProps = (state: IGeovisStoreState): IComponentStateProps => ({
    projectUsersStorage: state.data.projectUsersStorage
})

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IComponentDispatchProps => ({
    projectUsersUpdate: (users) => dispatch(projectDataUsersUpdate(users)),
})

export default connect<IComponentStateProps, IComponentDispatchProps, IComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(Component));