import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IWithGeovisServerProps, withGeovisServer } from "../../../helpers/GeovisHooks";
import { t } from "../../../i18n";
import { ActionResponse } from "../../../server/ActionResponse";
import ServerRoutesGen from "../../../server/Routes/ServerRoutesGen";
import FlagService from "../../../services/FlagService";
import { projectDataLabelRemoved } from "../../../store/creators/dataCreators";
import { projectLabelsDeleteHide } from "../../../store/creators/projectEditCreators";
import { IGeovisProjectLabelStorage } from "../../../store/data.types";
import { IGeovisStoreState } from "../../../store/store.types";
import { IGeovisAction } from "../../../store/types";

interface ILabelDeleteViewStateProps {
    labelId: string;
    showDialog: boolean;
    projectLabelsStorage: IGeovisProjectLabelStorage;
    projectId: number;
}

interface ILabelDeleteViewDispatchProps {
    onRemove: (labelId: string) => void;
    onClose: () => void;
}

interface ILabelDeleteViewProps extends ILabelDeleteViewStateProps, ILabelDeleteViewDispatchProps, IWithGeovisServerProps {

}

const LabelDeleteView = ({
    Server,
    labelId,
    projectId,
    projectLabelsStorage,
    showDialog,
    onRemove,
    onClose
}: ILabelDeleteViewProps) => {
    if (!showDialog || !labelId) {
        return null;
    }

    const label = projectLabelsStorage.labels.get(labelId);
    if (!label) {
        return null;
    }

    const onRemoveLabel = async () => {
        try {
            const url = ServerRoutesGen.Project.DeleteLabel.patch(projectId, labelId).path;
            const response = await Server.get<ActionResponse>(url);
            if (!response.Success) {
                FlagService.addError("Failed to remove label", response.Messages.join(", "));
                return;
            }
            onRemove(labelId);
            onClose();
        } catch (error) {
            FlagService.addError("Exception occurs when removing label", error);
        }
    }

    return (
        <ModalTransition>
            <Modal
                heading={t("Comfirm removing label")}
                actions={[
                    { text: t("Remove"), appearance: 'danger', onClick: onRemoveLabel },
                    { text: t("Cancel"), onClick: onClose }
                ]}>
                <span>{t("Are you really want to remove label ")}{label.name}?</span>
            </Modal>
        </ModalTransition>
    )
}

const mapStateToProps = ({ data, projectEdit }: IGeovisStoreState): ILabelDeleteViewStateProps => ({
    labelId: projectEdit.projectEditLabelState.labelId,
    projectId: data.projectInfo.project.Id,
    projectLabelsStorage: data.projectLabelStorage,
    showDialog: projectEdit.projectEditLabelState.showDeleteLabelDialog
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): ILabelDeleteViewDispatchProps => ({
    onClose: () => dispatch(projectLabelsDeleteHide()),
    onRemove: labelId => dispatch(projectDataLabelRemoved(labelId))
});

export default connect<ILabelDeleteViewStateProps, ILabelDeleteViewDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(LabelDeleteView))