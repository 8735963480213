import L, {
    DivIcon,
    PointExpression
} from 'leaflet';
import "../../libs/svg.icon";
import "./IconsJs";

export type GeovisIcon = DivIcon & {
    _createSVG(): string;
};

import markericon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markericon from 'leaflet/dist/images/marker-icon.png';
import markershadow from 'leaflet/dist/images/marker-shadow.png';
import { SensorCategory } from '../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { SensorBase } from '../../server/SensorBase';
import Logger from '../../services/Logger';
import {
    defaultAirPressureSensorIconProps,
    defaultCounterSensorIconProps,
    defaultCurrentLoopIconProps,
    defaultDistanceSensorIconProps,
    defaultExtensometerSensorIconProps,
    defaultFlowSensorIconProps,
    defaultGHSensorIconProps,
    defaultGPSSensorIconProps,
    defaultHydrostaticLevelSensorIconProps,
    defaultInclinometerChainPositionIconProps,
    defaultInclinometerSensorIconProps,
    defaultLoadCellSensorIconProps,
    defaultMstSensorIconProps,
    defaultNoiseIconProps,
    defaultOxygenLevelSensorIconProps,
    defaultPfHSensorIconProps,
    defaultPfVSensorIconProps,
    defaultpHSensorIconProps,
    defaultPiezometerSensorIconProps,
    defaultPrismIconProps,
    defaultRainmeterSensorIconProps,
    defaultSbSensorIconProps,
    defaultSensorSymbolIconProps,
    defaultSettlementSensorIconProps,
    defaultSolarRadiationIconProps,
    defaultTemperatureSensorIconProps,
    defaultTheodoliteSensorIconProps,
    defaultUnknownIconProps,
    defaultVibrationSensorIconProps,
    defaultVirtualSensorIconProps,
    defaultVoltageIconProps,
    defaultVolumeSensorIconProps,
    defaultVSensorIconProps,
    defaultWindSpeedSensorIconProps,
    getDefaultSensorIconProps,
    getDefaultSensorSymbolIconProps,
    IProjectsOverviewIconMapIconProps,
    ISVGIconProps,
    textIconProps
} from './types';

export const DefaultPointerIcon = new L.Icon({
    iconUrl: markericon,
    iconRetinaUrl: markericon2x,
    iconAnchor: [15, 41],
    popupAnchor: [-4, -40],
    iconSize: [25, 41],
    shadowUrl: markershadow,
    shadowSize: [30, 52],
    shadowAnchor: [12, 51],
});

export const projectStateMarkerIconExt = (props: IProjectsOverviewIconMapIconProps) => {
    return L.divIcon.svgIcon.projectStateIconExt(props);
};

const getSensorTooltipOffsetFor = (sensorType: SensorCategory): PointExpression => {
    return getDefaultSensorIconProps(sensorType).popupAnchor;
}

interface ISensorTooltipOffsetProps {
    sensor: SensorBase;
    useGeovis4SensorTypeSymbol?: boolean;
}

export const getSensorTooltipOffset = ({
    sensor,
    useGeovis4SensorTypeSymbol
}: ISensorTooltipOffsetProps) => {
    if (useGeovis4SensorTypeSymbol === false) {
        return getDefaultSensorSymbolIconProps(sensor.Symbol).popupAnchor;
    }

    if (sensor.PhysicalType === SensorCategory.VirtualSensor ||
        sensor.PhysicalType === SensorCategory.AgmsVirtualSensor) {
        return getSensorTooltipOffsetFor(sensor.PhysicalType);
    }

    return getSensorTooltipOffsetFor(sensor.PhysicalType);
}

export const getChainTooltipOffset = ({
    sensor,
    useGeovis4SensorTypeSymbol
}: ISensorTooltipOffsetProps) => {
    if (useGeovis4SensorTypeSymbol === false) {
        return getDefaultSensorSymbolIconProps(sensor.Symbol).popupAnchor;
    }

    return defaultInclinometerChainPositionIconProps.popupAnchor;
}

/**
 * Create icon of many sensors
 */
export function createMultipleSensorsIcon(props: ISVGIconProps) {
    return L.divIcon.svgIcon.multipleSensorsIcon(props);
}

/**
 * Create the SVG icon depends of properties (className is the key)
 * @param props icon properties
 */
export const createSingleSensorIcon = (props: ISVGIconProps): GeovisIcon => {

    switch (props.className) {
        case defaultUnknownIconProps.className: return L.divIcon.svgIcon.unknownIcon(props);
        case defaultPrismIconProps.className: return L.divIcon.svgIcon.prismIcon(props);
        case defaultTheodoliteSensorIconProps.className: return L.divIcon.svgIcon.theodoliteSensorIcon(props);
        case defaultExtensometerSensorIconProps.className: return L.divIcon.svgIcon.extensometerSensorIcon(props);
        case defaultInclinometerSensorIconProps.className: return L.divIcon.svgIcon.inclinometerSensorIcon(props);
        case defaultTemperatureSensorIconProps.className: return L.divIcon.svgIcon.temperatureSensorIcon(props);
        case defaultPiezometerSensorIconProps.className: return L.divIcon.svgIcon.piezometerSensorIcon(props);
        case defaultAirPressureSensorIconProps.className: return L.divIcon.svgIcon.airPressureSensorIcon(props);
        case defaultWindSpeedSensorIconProps.className: return L.divIcon.svgIcon.windSpeedSensorIcon(props);
        case defaultSolarRadiationIconProps.className: return L.divIcon.svgIcon.solarRadiationIcon(props);
        case defaultDistanceSensorIconProps.className: return L.divIcon.svgIcon.distanceSensorIcon(props);
        case defaultLoadCellSensorIconProps.className: return L.divIcon.svgIcon.loadCellSensorIcon(props);
        case defaultVoltageIconProps.className: return L.divIcon.svgIcon.voltageIcon(props);
        case defaultVirtualSensorIconProps.className: return L.divIcon.svgIcon.virtualSensorIcon(props);
        case defaultVibrationSensorIconProps.className: return L.divIcon.svgIcon.vibrationSensorIcon(props);
        case defaultInclinometerChainPositionIconProps.className: return L.divIcon.svgIcon.inclinometerChainPositionIcon(props);
        case defaultHydrostaticLevelSensorIconProps.className: return L.divIcon.svgIcon.hydrostaticLevelSensorIcon(props);
        case defaultCurrentLoopIconProps.className: return L.divIcon.svgIcon.currentLoopIcon(props);
        case defaultCounterSensorIconProps.className: return L.divIcon.svgIcon.counterSensorIcon(props);
        case defaultNoiseIconProps.className: return L.divIcon.svgIcon.noiseSensorIcon(props);
        case defaultVSensorIconProps.className: return L.divIcon.svgIcon.vSensorIcon(props);
        case defaultPfVSensorIconProps.className: return L.divIcon.svgIcon.pfvSensorIcon(props);
        case defaultPfHSensorIconProps.className: return L.divIcon.svgIcon.pfhSensorIcon(props);
        case defaultGHSensorIconProps.className: return L.divIcon.svgIcon.ghSensorIcon(props);
        case defaultSettlementSensorIconProps.className: return L.divIcon.svgIcon.settlementSensorIcon(props);
        case defaultGPSSensorIconProps.className: return L.divIcon.svgIcon.gpsSensorIcon(props);
        case defaultRainmeterSensorIconProps.className: return L.divIcon.svgIcon.rainmeterSensorIcon(props);
        case defaultFlowSensorIconProps.className: return L.divIcon.svgIcon.flowSensorIcon(props);
        case defaultVolumeSensorIconProps.className: return L.divIcon.svgIcon.volumeSensorIcon(props);
        case defaultpHSensorIconProps.className: return L.divIcon.svgIcon.pHSensorIcon(props);
        case defaultOxygenLevelSensorIconProps.className: return L.divIcon.svgIcon.oxygenLevelSensorIcon(props);
        case defaultSensorSymbolIconProps.className: return L.divIcon.svgIcon.sensorSymbolIcon(props);
        case defaultMstSensorIconProps.className: return L.divIcon.svgIcon.mstSensorIcon(props);
        case defaultSbSensorIconProps.className: return L.divIcon.svgIcon.sbSensorIcon(props);
        case textIconProps.className: return L.divIcon.svgIcon.textIcon(props);
    }

    Logger.warning(`createSingleSensorSvg: render for ${props.className} not implemented`, "IconActions");

    return L.divIcon.svgIcon.prismIcon(props);
}