import { AGMSDigitalOutputSwitchAlarmAction } from '../../../server/AVTService/TypeLibrary/Alarming/AGMSDigitalOutputSwitchAlarmAction';
import { AlarmAction } from '../../../server/AVTService/TypeLibrary/Alarming/AlarmAction';
import { AlarmActionType } from '../../../server/AVTService/TypeLibrary/Alarming/AlarmActionType';
import { DolphinAlarmAction } from '../../../server/AVTService/TypeLibrary/Alarming/DolphinAlarmAction';
import { EmailAlarmAction } from '../../../server/AVTService/TypeLibrary/Alarming/EmailAlarmAction';
import { SendMessageAlarmAction } from '../../../server/AVTService/TypeLibrary/Alarming/SendMessageAlarmAction';
import { SmsAlarmAction } from '../../../server/AVTService/TypeLibrary/Alarming/SmsAlarmAction';
import { TextAlarmAction } from '../../../server/AVTService/TypeLibrary/Alarming/TextAlarmAction';
import { AlarmLogbookModel } from '../../../server/AVTService/TypeLibrary/Logbook/AlarmLogbookModel';
import { LogbookModel } from '../../../server/AVTService/TypeLibrary/Logbook/LogbookModel';
import ServerRoutesGen from '../../../server/Routes/ServerRoutesGen';
import AuthService from '../../../services/AuthService';

export const getLogbookModelPropertyValue = <TLogbook extends LogbookModel>(record: LogbookModel, propertyName: keyof TLogbook): any => {
    return (record as any)[propertyName] || '';
}

// const getAlarmActionState = (state: AlarmActionExecutionState): string => {
//     switch (state) {
//         case AlarmActionExecutionState.Completed: return "(v)";
//         case AlarmActionExecutionState.Error: return "(e)";
//         case AlarmActionExecutionState.Scheduled: return "(~)";
//         case AlarmActionExecutionState.Canceled: return "(c)";
//         default: return "";
//     }
// }

const getDolphinAlarmActionDescriptions = (action: DolphinAlarmAction): string[] => (
    // [`Action: Notify Dolphin${getAlarmActionState(action.state)}`, action.message]
    [action.message]
)

const getEmailAlarmActionDescriptions = (action: EmailAlarmAction): string[] => (
    //[`Action: Sent EMail to${action.to}${getAlarmActionState(action.state)}`, action.message]
    [action.message]
)

const getTextAlarmActionDescriptions = (action: TextAlarmAction): string[] => (
    [action.message]
)

const getAGMSDigitalOutputSwitchAlarmActionDescriptions = (action: AGMSDigitalOutputSwitchAlarmAction): string[] => (
    //[`Action: AGMS Digital output switch${action.state}`]
    //[`AGMS Digital output switch ${action.state}. Sensorbox Ids: ${action.sensorboxIds}`]
    [`${action.message} Sensorbox Ids: ${action.sensorboxIds} `]
)

const getSmsAlarmActionDescriptions = (action: SmsAlarmAction): string[] => (
    //[`Action: Sent Sms to${action.to}${getAlarmActionState(action.state)}`, action.message]
    [action.message]
)

const getAlarmActionDescriptions = (action: AlarmAction): string[] => {
    switch (action.actionType) {
        case AlarmActionType.Dolphin:
            return getDolphinAlarmActionDescriptions(action as DolphinAlarmAction);

        case AlarmActionType.Email:
            return getEmailAlarmActionDescriptions(action as EmailAlarmAction);

        case AlarmActionType.Sms:
            return getSmsAlarmActionDescriptions(action as SmsAlarmAction);

        case AlarmActionType.Text:
            return getTextAlarmActionDescriptions(action as TextAlarmAction);

        case AlarmActionType.AgmsDigitalOutput:
            return getAGMSDigitalOutputSwitchAlarmActionDescriptions(action as AGMSDigitalOutputSwitchAlarmAction);

        default:
            return [];
    }
}

export const getAlarmLogbookRecordDescriptions = (record: AlarmLogbookModel): string[] => {
    const { actions } = record;
    const descriptions: string[] = [];
    const pairIds: string[] = [];
    if (actions.length === 0) {
        descriptions.push(record.description);
    }
    else {
        actions.forEach((action, index, collection) => {
            let allowToAdd = true;

            if (action.actionType === AlarmActionType.Sms || action.actionType === AlarmActionType.Email) {
                const smAction = <SendMessageAlarmAction>action;
                if (smAction !== null && smAction !== undefined) {
                    if (pairIds.includes(smAction.PairActionId)) {
                        allowToAdd = false;
                    }
                    else {
                        pairIds.push(smAction.PairActionId)
                    }
                }
            }

            if (action.actionType === AlarmActionType.AgmsDigitalOutput && collection.length > 1) {
                allowToAdd = false;
            }

            if (allowToAdd) {
                descriptions.push(...getAlarmActionDescriptions(action));
            }
        });
    }
    return descriptions;
}

export const getAlarmLogbookRecordDescription = (record: AlarmLogbookModel): string => {
    const descriptions = getAlarmLogbookRecordDescriptions(record as AlarmLogbookModel);
    return descriptions.join('\n');
}

export const getLogbookEntryImageUrl = (imageId: string, isThumb: boolean) => {
    const url = isThumb
        ? ServerRoutesGen.ProjectLogbook.LogbookEntryImageThumbnail.patch(imageId).path
        : ServerRoutesGen.ProjectLogbook.LogbookEntryImage.patch(imageId).path;

    return AuthService.getRequestHelper(AuthService).getEndpointServerUrl(url);
}