import { addMinutes } from "date-fns";
import Highcharts from "highcharts";
import moment from "moment";
import { addDays, formattedDateTime, getFormattedDateTime } from "../../../../../../helpers/DateHelper";
import { t } from "../../../../../../i18n";
import { GeovisChartSlimConfig } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartSlimConfig";
import { VibrationBackgroundFrequencyDataModel } from "../../../../../../server/AVTService/TypeLibrary/Computation/VibrationBackgroundFrequencyDataModel";
import { VibrationBackgroundVelocityDataModel } from "../../../../../../server/AVTService/TypeLibrary/Computation/VibrationBackgroundVelocityDataModel";
import { VibrationChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/VibrationChartData";
import { ColorizableElement } from "../../../../../../server/AVTService/TypeLibrary/DB/ColorizableElement";
import { LogbookModel } from "../../../../../../server/AVTService/TypeLibrary/Logbook/LogbookModel";
import { AlarmInfoRecord } from "../../../../../../server/AVTService/TypeLibrary/Model/AlarmInfoRecord";
import { PlotBandModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/PlotBandModel";
import { VibrationChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { HtmlReportSensorDescriptionViewModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportSensorDescriptionViewModel";
import { HtmlReportVibrationBackgroundLineViewModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportVibrationBackgroundLineViewModel";
import { VibrationFrequencyLineDataModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/VibrationFrequencyLineDataModel";
import { SensorSymbol } from "../../../../../../server/AVTService/TypeLibrary/Sensors/SensorSymbol";
import { createChartRenderedFunc } from "../../tools";
import { getGeovisChartSeriesLineWidth, getMarkerSymbol, getSeriesSymbol, getSeriesUnitShortName, getYAxisOptions, getYAxisUnitsAnnotation, LOGBOOK_AXIS_NAME } from "./chartRenderOptions";
import { getBoostOptions } from "./tools";

interface IVibrationBackgroundRenderOptionsSettings {
    chart: VibrationChartModel;
    leftYAxisSensorIds: string[],
    chartDataSlimConfig: GeovisChartSlimConfig;
    startMeasurement: string;
    onChartRender: () => void;
    velocityData: VibrationBackgroundVelocityDataModel[];
    frequencyData: VibrationBackgroundFrequencyDataModel[];
    logbooks: LogbookModel[];
    descriptions: HtmlReportSensorDescriptionViewModel[];
    timeslotTo: string;
    timeslotFrom: string;
    linkedAlarmLines: AlarmInfoRecord[];
    bandModels: PlotBandModel[];
    visualSettings: ProjectVisualSettings;
    onShowEventData: (eventId: string, sensorId: string, eventDate: string) => void;
}

interface IVibrationBackgroundSeriesOptions {
    yAxisNumber: number;
    sensorsVelocityData: VibrationBackgroundVelocityDataModel[];
    sensorsFrequencyData: VibrationBackgroundFrequencyDataModel[];
    sensorsInfo: HtmlReportSensorDescriptionViewModel[];
    needMarkers: boolean;
    numberOfDigits: number;
    visualSettings: ProjectVisualSettings;
    onShowEventData: (eventId: string, sensorId: string, eventDate: string) => void;
}

export const getVibrationBackgroundChartRenderOptions = (pageNum: number, chart: VibrationChartModel, chartData: VibrationChartData, onShowEventData: (eventId: string, fullId: string, eventDate: string) => void): Highcharts.Options => {

    const renderOptionsSettings: IVibrationBackgroundRenderOptionsSettings = {
        chart,
        bandModels: chartData.PlotBands,
        chartDataSlimConfig: chartData.SlimChartConfig,
        descriptions: chartData.Descriptions,
        frequencyData: chartData.FrequencyData,
        leftYAxisSensorIds: chart.LeftYAxisSettings.SensorIds,
        linkedAlarmLines: chartData.LinkedAlarmLines,
        logbooks: chartData.Logbooks,
        onChartRender: createChartRenderedFunc(pageNum, chart.Id),
        startMeasurement: chartData.startMeasurementTime,
        timeslotFrom: chartData.TimeSlotFrom,
        timeslotTo: chartData.TimeSlotTo,
        velocityData: chartData.VelocityData,
        visualSettings: chartData.visualSettings,
        onShowEventData
    }

    return getSingleOptions(renderOptionsSettings);
}

const getSingleOptions = ({
    velocityData,
    bandModels,
    chart,
    chartDataSlimConfig,
    descriptions,
    frequencyData,
    leftYAxisSensorIds,
    linkedAlarmLines,
    logbooks,
    onChartRender,
    startMeasurement,
    timeslotFrom,
    timeslotTo,
    visualSettings,
    onShowEventData
}: IVibrationBackgroundRenderOptionsSettings): Highcharts.Options => {

    const velocitySeries = chart.ShowBackgroundData.value ? getBackgroundVelocitySeries(chart, {
        needMarkers: false,
        numberOfDigits: chart.LeftYAxisSettings.numberOfDigits.value,
        sensorsFrequencyData: [],
        sensorsInfo: descriptions,
        sensorsVelocityData: velocityData,
        visualSettings,
        yAxisNumber: 0,
        onShowEventData
    }) : [];

    const eventSeries = getEventSeries(chart, {
        needMarkers: false,
        numberOfDigits: chart.LeftYAxisSettings.numberOfDigits.value,
        sensorsFrequencyData: [],
        sensorsInfo: descriptions,
        sensorsVelocityData: velocityData,
        visualSettings,
        yAxisNumber: 0,
        onShowEventData
    });

    const frequencySeries = getBackgroundFrequencySeries(chart, {
        needMarkers: false,
        numberOfDigits: chart.LeftYAxisSettings.numberOfDigits.value,
        sensorsFrequencyData: frequencyData,
        sensorsInfo: descriptions,
        sensorsVelocityData: [],
        visualSettings,
        yAxisNumber: 1,
        onShowEventData
    });

    const logbookSeries = getLogbookSeries(logbooks);

    const allSeries: Highcharts.SeriesOptionsType[] = velocitySeries;
    allSeries.push(...eventSeries);
    allSeries.push(...frequencySeries);
    allSeries.push(...logbookSeries);

    let noData = true;

    allSeries.forEach(seria => {
        if (seria.custom && seria.custom.hasData) {
            noData = false;
        }
    });

    const annotations = [...logbooks.map(logbook => getLogbookAnnotations(logbook))];
    annotations.push(getYAxisUnitsAnnotation(chart.LeftYAxisSettings, 0));
    if (chart.ShowFrequencyScaling) {
        annotations.push(getYAxisUnitsAnnotation(chart.RightYAxisSettings, 1));
    }

    const options: Highcharts.Options = {
        boost: getBoostOptions(chart.Type, chartDataSlimConfig),
        chart: {
            zooming: {
                type: undefined
            },
            reflow: true,
            alignTicks: true,
            showAxes: true,
            events: {
                render: onChartRender,
            }
        },
        title: {
            floating: false,
            text: "<div style='height:10px'></div>",
            useHTML: true
        },
        lang: {
            noData: `<div style='z-index:20'>${leftYAxisSensorIds.length > 0 ? t('No data is available in the chart') : t('No sensor selected for drawing the chart')}</div>`
        },
        noData: {
            useHTML: true
        },
        legend: {
            enabled: true,
        },
        xAxis: {
            type: 'datetime',
            crosshair: true,
            startOnTick: false,
            endOnTick: false,
            minRange: 10000,
            lineWidth: 2,
            lineColor: "black",
            minorTickInterval: 'auto',
            zIndex: 5,
            min: getMinXValue(chart, timeslotFrom),
            max: getMaxXValue(chart, timeslotTo),
            plotBands: getPlotBands(bandModels, false),
            labels: {
                style: {
                    fontSize: visualSettings.chartAxisLabelFontSize
                },
                formatter() {
                    const labelData = this.value;
                    if (this.axis.max !== null && this.axis.min !== null) {

                        const dateMin = new Date(this.axis.min);
                        const dateMax = new Date(this.axis.max);

                        const dateDiff = Math.abs(dateMax.getTime() - dateMin.getTime());
                        const diffDays = dateDiff / (1000 * 3600 * 24);

                        if (diffDays > 4) {
                            return getFormattedDateTime(new Date(labelData), "DD.MM.YYYY")
                        }
                        else if (diffDays > 1) {
                            return getFormattedDateTime(new Date(labelData), "DD.MM.YYYY HH:mm:ss")
                        }
                        else if (diffDays < 1) {
                            return getFormattedDateTime(new Date(labelData), "HH:mm:ss")
                        }
                    }
                    return getFormattedDateTime(new Date(labelData), "DD.MM.YYYY HH:mm:ss")
                }
            },
            tickPositioner() {
                if (this.max !== null && this.min !== null) {
                    const positions = [];
                    let tick = this.min;
                    const increment = Math.ceil((this.max - this.min) / 10);

                    const diapasonLengthInDays = (this.max - this.min) / 1000 / 3600 / 24;

                    if (diapasonLengthInDays > 10) {
                        const dateInc = Math.floor(diapasonLengthInDays / 10);
                        while (tick < this.max) {
                            const tickDate = new Date(tick);
                            tickDate.setHours(0, 0, 0, 0);
                            if (tick === this.min && tickDate < new Date(tick)) {
                                positions.push(addDays(tickDate, 1).getTime());
                            }
                            else if (tickDate > new Date(this.max)) {
                                positions.push(addDays(tickDate, -1).getTime());
                            }
                            else {
                                positions.push(tickDate.getTime())
                            }
                            tickDate.setDate(tickDate.getDate() + dateInc);
                            tick = tickDate.getTime();
                        }
                    }
                    else if (diapasonLengthInDays > 5) {
                        while (tick < this.max) {
                            const tickDate = new Date(tick);
                            tickDate.setHours(0, 0, 0, 0);
                            if (tick === this.min && tickDate < new Date(tick)) {
                                positions.push(addDays(tickDate, 1).getTime());
                            }
                            else if (tickDate > new Date(this.max)) {
                                positions.push(addDays(tickDate, -1).getTime());
                            }
                            else {
                                positions.push(tickDate.getTime())
                            }
                            tickDate.setDate(tickDate.getDate() + 1);
                            tick = tickDate.getTime();
                        }
                    }
                    else {
                        for (tick; tick - increment <= this.max; tick += increment) {
                            positions.push(tick);
                        }
                    }

                    return positions;
                }

                return this.tickPositions ?? [];
            }
        },
        // yAxis: getYAxisOptions(chart, visualSettings, linkedAlarmLines, undefined, undefined, chart.ShowFrequencyScaling),
        yAxis: getYAxisOptions({
            chart,
            drawAlarmLines: true,
            linkedAlarmLines,
            needSecondAxis: chart.ShowFrequencyScaling,
            visualSettings,
            hideUnitsInLabel: false,
            primaryAxisValueType: undefined,
            secondaryAxisValueType: undefined,
            minValue: -0.1,
            forceMinValue: true,
            noData
        }),
        tooltip: {
            style: {
                padding: '5px'
            },
            snap: 0.01,
            outside: false,
            shared: false,
            split: false,
            useHTML: true,
            formatter() {
                const tooltipDate = moment(new Date(this.x ?? "")).format("DD.MM.YYYY HH:mm:ss");

                const getYString = (point: Highcharts.TooltipFormatterContextObject): string => {
                    const seriesOptions = point.series.options;

                    const isLogbookTooltip: boolean = seriesOptions.custom !== undefined && seriesOptions.custom.logbookId !== undefined;

                    if (isLogbookTooltip) {
                        const logbook = logbooks.find(lb => lb.id === seriesOptions.custom?.logbookId);
                        if (logbook) {
                            return logbook.descriptionText;
                        }
                    }

                    const strPointY =
                        point.y === null || point.y === undefined ? 'null' :
                            seriesOptions.custom ? point.y.toFixed(seriesOptions.custom.numberOfDigits) : point.y.toString();

                    const name = point.series.name;

                    return `${getSeriesSymbol({ symbol: 'circle', color: this.color })} ${name}: ${strPointY} ${getSeriesUnitShortName(chart, point.series.yAxis.side)}<br/>`;
                }

                const defaultTooltip = `${tooltipDate}<br/>${getYString(this)}`;

                return defaultTooltip;

            }
        },
        annotations,
        series: allSeries,
        exporting: {
            enabled: false,
        },
        plotOptions: {
            line: {
                findNearestPointBy: 'xy'
            },
            series: {
                animation: false
            },
            scatter: {
                turboThreshold: 0,
                threshold: 0,
                boostThreshold: 0
            },
        },
        credits: {
            enabled: false
        }
    }

    if (chart.ShowStartMeasurements) {
        const startMeasurementText = startMeasurement ? formattedDateTime(startMeasurement, "DD.MM.YYYY") : 'no data';

        options.subtitle = {
            align: 'center',
            text: `${t("Start of measurements")}: ${startMeasurementText}`
        };
    }

    return options;
}

const getBackgroundVelocitySeries = (chart: VibrationChartModel, settings: IVibrationBackgroundSeriesOptions): Highcharts.SeriesOptionsType[] => {
    const result: Highcharts.SeriesOptionsType[] = [];

    settings.sensorsVelocityData.forEach(data => {
        const sensor = settings.sensorsInfo.find(s => s.id === data.SensorFullId);
        if (sensor) {
            const seriaData = getBackgroundVelocityData(data.Data);
            const seria: Highcharts.SeriesOptionsType = {
                type: 'line',
                lineWidth: getGeovisChartSeriesLineWidth(settings.visualSettings, sensor),
                color: sensor.color,
                name: sensor.name ?? sensor.id,
                zIndex: 5,
                custom: {
                    sensorFullId: data.SensorFullId,
                    hasData: seriaData.length > 0,
                    numberOfDigits: chart.LeftYAxisSettings.numberOfDigits.value
                },
                dashStyle: 'Solid',
                marker: {
                    enabled: false
                },
                yAxis: settings.yAxisNumber,
                xAxis: 0,
                turboThreshold: 0,
                data: seriaData
            }
            result.push(seria);
        }

    });

    return result;
}

const getEventSeries = (chart: VibrationChartModel, settings: IVibrationBackgroundSeriesOptions): Highcharts.SeriesOptionsType[] => {
    const result: Highcharts.SeriesOptionsType[] = [];

    settings.sensorsVelocityData.forEach(data => {

        const sensor = settings.sensorsInfo.find(s => s.id === data.SensorFullId);
        if (!sensor) {
            return;
        }

        const seriesData = getEventData(data.Data, settings.onShowEventData);

        for (const sd of seriesData) {

            const seria: Highcharts.SeriesOptionsType = {
                type: 'scatter',
                color: sensor.color,
                name: sensor.name ?? sensor.id,
                custom: {
                    sensorFullId: data.SensorFullId,
                    hasData: seriesData.length > 0,
                    numberOfDigits: chart.LeftYAxisSettings.numberOfDigits.value
                },
                zIndex: 120,
                dashStyle: 'Solid',
                marker: {
                    enabled: true,
                    color: sensor.color,
                    symbol: getMarkerSymbol({ ... new ColorizableElement(), Symbol: sensor.symbol }),
                    radius: 10,
                    height: 10,
                    width: 10
                },
                yAxis: settings.yAxisNumber,
                xAxis: 0,
                data: [sd],
                showInLegend: false,
                turboThreshold: 0
            }

            result.push(seria);
        }
    });

    return result;
}

const getBackgroundFrequencySeries = (chart: VibrationChartModel, settings: IVibrationBackgroundSeriesOptions): Highcharts.SeriesOptionsType[] => {
    const result: Highcharts.SeriesOptionsType[] = [];

    settings.sensorsFrequencyData.forEach(data => {
        const sensor = settings.sensorsInfo.find(s => s.id === data.SensorFullId);
        if (sensor) {
            const seriaData = getBackgroundFrequencyData(data.Data);
            const seria: Highcharts.SeriesOptionsType = {
                type: 'line',
                color: sensor.color,
                lineWidth: getGeovisChartSeriesLineWidth(settings.visualSettings, sensor),
                name: sensor.name ?? sensor.id,
                custom: {
                    sensorFullId: data.SensorFullId,
                    hasData: seriaData.length > 0,
                    numberOfDigits: chart.RightYAxisSettings.numberOfDigits.value
                },
                dashStyle: 'Solid',
                marker: {
                    enabled: false
                },
                yAxis: settings.yAxisNumber,
                xAxis: 0,
                data: seriaData,
                turboThreshold: 0
            }
            result.push(seria);
        }

    });

    return result;
}

const getLogbookSeries = (logbooks: LogbookModel[]): Highcharts.SeriesOptionsType[] => {
    const series: Highcharts.SeriesOptionsType[] = [];

    logbooks.forEach(logbook => {
        const logbookSeriaOptions: Highcharts.SeriesOptionsType = {
            type: 'scatter',
            color: 'red',
            showInLegend: false,
            data: [[(new Date(logbook.reportDate)).getTime(), 0]],
            lineWidth: 10,
            yAxis: LOGBOOK_AXIS_NAME,
            xAxis: 0,
            custom: {
                logbookId: logbook.id
            },
            states: {
                inactive: {
                    enabled: false
                }
            },
            marker: {
                enabled: true,
                symbol: getMarkerSymbol({ ... new ColorizableElement(), Symbol: SensorSymbol.Circle }),
                lineColor: 'red',
                lineWidth: 2
            },
        };
        series.push(logbookSeriaOptions);
    })

    return series;
}

const getLogbookAnnotations = (logbook: LogbookModel): Highcharts.AnnotationsOptions => {
    return ({
        draggable: '',
        shapes: [
            {
                type: 'path',
                dashStyle: 'Dash',
                points: [
                    {
                        x: (new Date(logbook.reportDate)).getTime(),
                        xAxis: 0,
                        y: 0,
                        yAxis: null
                    },
                    (annotation: any) => ({
                        x: (new Date(logbook.reportDate)).getTime(),
                        xAxis: 0,
                        y: annotation.chart.plotHeight - 10,
                        yAxis: null
                    })
                ]
            }]
    })
}

const getBackgroundVelocityData = (data: HtmlReportVibrationBackgroundLineViewModel[]): Highcharts.PointOptionsObject[] => {
    const result: Highcharts.PointOptionsObject[] = [];
    data.forEach(item => {
        item.points.forEach(point => {

            const pointObject: Highcharts.PointOptionsObject = {
                x: prepareDate(point.x),
                y: point.value[0]
            }
            result.push(pointObject)
        });
    });

    return result;
}

const getEventData = (data: HtmlReportVibrationBackgroundLineViewModel[], onShowEventData: (eventId: string, sensorId: string, eventDate: string) => void): Highcharts.PointOptionsObject[] => {
    const result: Highcharts.PointOptionsObject[] = [];
    data.forEach(item => {
        item.events.forEach(event => {
            const pointObject: Highcharts.PointOptionsObject = {
                x: prepareDate(event.x),
                y: event.value[0],
                events: {
                    click: onEventClickHandler(event.recordId, event.fullId, event.eventDate, onShowEventData)
                }
            }
            result.push(pointObject)
        })

    });

    return result;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onEventClickHandler = (recordId: string, sensorFullId: string, eventDate: string, onShowEventData: (eventId: string, sensorId: string, eventDate: string) => void) => (eventData: any) => {
    // eslint-disable-next-line no-console
    console.log(`Event ${recordId} was clicked for ${sensorFullId}`);
    onShowEventData(recordId, sensorFullId, eventDate);
}

const getBackgroundFrequencyData = (data: VibrationFrequencyLineDataModel[]): number[][] => {
    const result: number[][] = [];
    data.forEach(item => {
        item.points.forEach(point => {
            result.push([prepareDate(point.x), point.value[0]])
        });
    });

    return result;
}

const getMinXValue = (chart: VibrationChartModel, firstTimeslot: string): null | number => {
    if (chart.ShowAllMeasurementsSetting.value) {
        return null;
    }
    return prepareDate(firstTimeslot);
}

const getMaxXValue = (chart: VibrationChartModel, lastTimeslot: string): null | number => {
    if (chart.ShowAllMeasurementsSetting.value) {
        return null;
    }
    return prepareDate(lastTimeslot);
}

const getPlotBands = (bandModels: PlotBandModel[], showNoData: boolean): Highcharts.XAxisPlotBandsOptions[] => {
    const bands: Highcharts.XAxisPlotBandsOptions[] = [];

    if (showNoData) {
        return bands;
    }

    bandModels.forEach(bm => {
        const from = new Date(bm.From);
        const to = new Date(bm.To);

        bands.push({
            from: addMinutes(from, from.getTimezoneOffset()).getTime(),
            to: addMinutes(to, to.getTimezoneOffset()).getTime(),
            color: bm.Color
        })
    })

    return bands;
}

const prepareDate = (dateString: string): number => {
    const date = new Date(dateString);
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
}