//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.


import Route from "../../helpers/Route";

class ServerRoutes {

	public About = {
		GetReleaseNotes: new Route({ path: "about/releaseNotes" }),
		GetReleaseNotesMore: new Route({ path: "about/releaseNotesMore/:releaseId" }),
		GetUploadedReleaseNotesFile: new Route({ path: "about/uploadedReleaseNoteFile/:fileId" }),
		DeleteUploadedReleaseNoteFile: new Route({ path: "about/deleteUploadedReleaseNoteFile/:fileId" }),
	};

	public Alarms = {
		Alarms: new Route({ path: "alarm/:projectId/all" }),
		GetLabels: new Route({ path: "alarm/:projectId/labels" }),
		GetSensors: new Route({ path: "alarm/:projectId/sensors" }),
		RaisedAlarms: new Route({ path: "alarm/:projectId/raisedAlarms" }),
		RaisedAlarmsOfView: new Route({ path: "alarm/:projectId/raisedAlarmsOfView/:viewId" }),
		AlarmHistory: new Route({ path: "alarm/:projectId/alarmHistory" }),
		AlarmHistoryOfView: new Route({ path: "alarm/:projectId/alarmHistoryOfView/:viewId" }),
		InternalAlarms: new Route({ path: "alarm/:projectId/internalAlarms" }),
		InternalAlarmsOfView: new Route({ path: "alarm/:projectId/internalAlarmsOfView/:viewId" }),
		ConfirmSensorAlarm: new Route({ path: "alarm/:projectId/confirmSensorAlarm" }),
		SwitchOffAlarmDigitalOutput: new Route({ path: "alarm/:projectId/switchOffDigitalOutput" }),
		ConfirmProjectAlarm: new Route({ path: "alarm/:projectId/confirm/:alarmId" }),
		ActivateAlarm: new Route({ path: "alarm/:projectId/active/:alarmId/:isActive" }),
		AlarmCopyName: new Route({ path: "alarm/:projectId/copyName/:alarmId" }),
		RemoveAlarm: new Route({ path: "alarm/:projectId/remove/:alarmId" }),
		CreateAlarm: new Route({ path: "alarm/:projectId/create" }),
		CopyAlarm: new Route({ path: "alarm/:projectId/copy" }),
		SaveAlarm: new Route({ path: "alarm/:projectId/save" }),
		TestAlarmActionPrepare: new Route({ path: "alarm/:projectId/testActionPrepare" }),
		TestAlarmActionExecute: new Route({ path: "alarm/:projectId/testActionExecute" }),
		AlarmTemplates: new Route({ path: "alarm/:projectId/allTemplates" }),
		CreateAlarmTemplate: new Route({ path: "alarm/:projectId/createTemplate" }),
		CopyAlarmTemplate: new Route({ path: "alarm/:projectId/copyTemplate" }),
		SaveAlarmTemplate: new Route({ path: "alarm/:projectId/saveTemplate" }),
		AlarmTemplateCopyName: new Route({ path: "alarm/:projectId/templateCopyName/:alarmTemplateId" }),
		RemoveAlarmTemplate: new Route({ path: "alarm/:projectId/removeTemplate/:templateId" }),
		CreateAlarmTemplateFromAlarm: new Route({ path: "alarm/:projectId/createTemplateFromAlarm" }),
		GetAlarmForEdit: new Route({ path: "alarm/:projectId/edit" }),
		GetPossibleTemplatesIds: new Route({ path: "alarm/:projectId/getPossibleTemplatesIds" }),
		GetAlarmSensors: new Route({ path: "alarm/:projectId/getAlarmSensors" }),
		GetUsersPreset: new Route({ path: "alarm/:projectId/getAlarmActionsUsersPreset/:presetId" }),
		GetUsersPresetsForProject: new Route({ path: "alarm/:projectId/getAlarmActionsUsersPresets" }),
		CreateUsersPreset: new Route({ path: "alarm/:projectId/createAlarmActionsUsersPreset" }),
		SaveUsersPreset: new Route({ path: "alarm/:projectId/saveAlarmActionsUsersPreset" }),
		DuplicateUsersPresets: new Route({ path: "alarm/:projectId/duplicateAlarmActionsUsersPreset/:presetId" }),
		RemoveUsersPresets: new Route({ path: "alarm/:projectId/removeAlarmActionsUsersPreset/:presetId" }),
	};

	public AttachedDocument = {
		Get: new Route({ path: "attachedDocument/:documentId" }),
		GetByLink: new Route({ path: "attachedDocument/:projectId/byLink/:linkId" }),
		Remove: new Route({ path: "attachedDocument/:projectId/remove/:documentId" }),
		ChangeUseHistory: new Route({ path: "attachedDocument/:documentId/useHistory/:use" }),
		ChangeIsPublic: new Route({ path: "attachedDocument/:projectId/changeIsPublic/:documentId/:isPublic" }),
		DownloadHistoryAttachedDocument: new Route({ path: "attachedDocument/:projectId/downloadHistoryDocument/:documentId/:fileId" }),
		LinkDocumentToSensors: new Route({ path: "attachedDocument/:projectId/linkDocumentToSensors/:documentId" }),
	};

	public AttachedDocuments = {
		Get: new Route({ path: "attachedDocuments/:projectId" }),
	};

	public ArchiveFtpSettings = {
		GetSettings: new Route({ path: "archiveFtpSettings/settings" }),
		UpdateProperty: new Route({ path: "archiveFtpSettings/updateProperty" }),
		CheckFtpConnection: new Route({ path: "archiveFtpSettings/checkFtpConnection" }),
		CheckBackupDirectoryExists: new Route({ path: "archiveFtpSettings/checkBackupDirectoryExists" }),
		CheckFileZillaFileAccess: new Route({ path: "archiveFtpSettings/checkFileZillaFileAccess" }),
		ManualArchive: new Route({ path: "archiveFtpSettings/manualArchive" }),
		ManualRestore: new Route({ path: "archiveFtpSettings/manualRestore" }),
		GetRestoreStoragesInfo: new Route({ path: "archiveFtpSettings/restoreStoragesInfo" }),
	};

	public Backup = {
		CheckFTPSettings: new Route({ path: "backup/checkFtpConnection" }),
		GetSettings: new Route({ path: "backup/settings" }),
		GetBackupStandardHistory: new Route({ path: "backup/standardHistory" }),
		GetPrevBackupStandardHistory: new Route({ path: "backup/standardHistory/prev" }),
		GetBackupYearlyHistoryYears: new Route({ path: "backup/yearBackups" }),
		UpdateServiceSettingsProperty: new Route({ path: "backup/updateServiceSettingsProperty" }),
		GetState: new Route({ path: "backup/state/full" }),
		ManualStartFull: new Route({ path: "backup/manualStart" }),
		ManualStopFull: new Route({ path: "backup/manualStop" }),
		TestSendMessage: new Route({ path: "backup/testSendMessage/:notificationType/of/:backupType" }),
	};

	public Company = {
		LogosInfo: new Route({ path: "company/:companyId/logosInfo" }),
		GetCompanyInfo: new Route({ path: "company/:companyId/info" }),
		GetCompanySupportTemplates: new Route({ path: "company/:companyId/supportTemplates" }),
		GetCompanySupportTemplate: new Route({ path: "company/:companyId/supportTemplate" }),
		SaveCompany: new Route({ path: "company/saveCompany" }),
		UpdateCompany: new Route({ path: "company/updateCompany" }),
		DeleteCompany: new Route({ path: "company/deleteCompany/:companyId" }),
	};

	public CostCalculation = {
		CalculateCost: new Route({ path: "costCalculation/calculateCost" }),
	};

	public Dashboard = {
		GetWDInfo: new Route({ path: "dashboard/wdInfo" }),
		GetExpiredWDInfo: new Route({ path: "dashboard/wdExpiredInfo" }),
		GetTaskErrors: new Route({ path: "dashboard/taskErrors" }),
		GetTaskErrorsExtended: new Route({ path: "dashboard/taskErrorsExt" }),
		GetGlobalInfo: new Route({ path: "dashboard/global" }),
		GetActiveFa411: new Route({ path: "dashboard/fa411" }),
		GetFailedNotifications: new Route({ path: "dashboard/failedNotifications" }),
		GetProjectsWithoutVANotifications: new Route({ path: "dashboard/projectWithoutVaNotifications" }),
		GetProjectsWithALotOfWDs: new Route({ path: "dashboard/tooManyWDs" }),
		ServiceState: new Route({ path: "dashboard/serviceState" }),
	};

	public DtsConfiguration = {
		GetAll: new Route({ path: "dtsConfiguration/:projectId/all/:reportId" }),
		GetDtsSectionInfos: new Route({ path: "dtsConfiguration/:projectId/dtsSectionInfos/:reportId" }),
		GetDtsSectionInfo: new Route({ path: "dtsConfiguration/:projectId/dtsSectionInfo/:sectionId/:reportId" }),
		GetDtsSectionDefaultInfo: new Route({ path: "dtsConfiguration/:projectId/dtsSectionDefaultInfo" }),
		SaveSection: new Route({ path: "dtsConfiguration/:projectId/saveSection" }),
		RemoveSection: new Route({ path: "dtsConfiguration/:projectId/removeSection/:sectionId" }),
		CalculateSectionProperties: new Route({ path: "dtsConfiguration/:projectId/calculateProperties" }),
		GetDtsCloseTemperatureSensorsInfo: new Route({ path: "dtsConfiguration/:projectId/GetDtsCloseTemperatureSensorsInfo" }),
		ScanTemperatureSensors: new Route({ path: "dtsConfiguration/:projectId/scanTemperatureSensors" }),
	};

	public DxfLayers = {
		DeleteDXFLayer: new Route({ path: "dxfLayers/:projectId/delete/:fileId" }),
		GetDXFLayerInfo: new Route({ path: "dxfLayers/:projectId/dxfLayerInfo/:layerId" }),
		GetRasterLayerInfo: new Route({ path: "dxfLayers/:projectId/rasterLayerInfo/:layerId" }),
		TestRasterFtpSettings: new Route({ path: "dxfLayers/testFtp" }),
		GetAllDXFLayers: new Route({ path: "dxfLayers/:projectId/all" }),
		GetGeoJSONLayers: new Route({ path: "dxfLayers/:projectId" }),
		GetGeoJSONLayersOfView: new Route({ path: "dxfLayers/:projectId/ofView/:viewId" }),
		GetProfileViews: new Route({ path: "dxfLayers/:projectId/profileViews" }),
		GetProfileViewLayersOfView: new Route({ path: "dxfLayers/:projectId/profileViewsofView/:viewId" }),
		GetSideViews: new Route({ path: "dxfLayers/:projectId/sideViews" }),
		InvertSideViewXAxis: new Route({ path: "dxfLayers/:projectId/invertXAxis/:sideViewId" }),
		ChangeShowInOverview: new Route({ path: "dxfLayers/:projectId/changeShowInOverview/:mapViewId" }),
		GetSideViewLayersOfView: new Route({ path: "dxfLayers/:projectId/sideViewsofView/:viewId" }),
		GeoJSONContent: new Route({ path: "dxfLayers/:projectId/geoJsonContent/:layerId" }),
		GeoRasterFiles: new Route({ path: "dxfLayers/:projectId/geoRaster/all" }),
		GeoRasterFilesOfView: new Route({ path: "dxfLayers/:projectId/geoRaster/ofView/:viewId" }),
		DeleteGeoRaster: new Route({ path: "dxfLayers/:projectId/geoRaster/delete/:rasterId" }),
		RebuildGeoRaster: new Route({ path: "dxfLayers/:projectId/geoRaster/rebuild/:rasterId" }),
		UpdateGeoRaster: new Route({ path: "dxfLayers/:projectId/geoRaster/update" }),
		GeoRasterSettings: new Route({ path: "dxfLayers/geoRasterSettings" }),
		SetGeoRasterSettings: new Route({ path: "dxfLayers/setGeoRasterSettings" }),
	};

	public ExportTask = {
		GetTasks: new Route({ path: "exportTask/:projectId/tasks" }),
		UpdateProperty: new Route({ path: "exportTask/:projectId/task/:taskId/updateProperty" }),
		Update: new Route({ path: "exportTask/:projectId/task/update" }),
		Create: new Route({ path: "exportTask/:projectId/create" }),
		Get: new Route({ path: "exportTask/:projectId/task/:taskId/get" }),
		Run: new Route({ path: "exportTask/:projectId/task/:taskId/run" }),
		Delete: new Route({ path: "exportTask/:projectId/task/:taskId/delete" }),
		Copy: new Route({ path: "exportTask/:projectId/task/:taskId/copy" }),
		CheckFtpConnection: new Route({ path: "exportTask/:projectId/checkFtp" }),
		CheckMqttConnection: new Route({ path: "exportTask/:projectId/checkMqtt" }),
		CheckLocalConnection: new Route({ path: "exportTask/:projectId/checkLocal" }),
	};

	public GeneralSettings = {
		Get: new Route({ path: "generalSettings/get" }),
		UpdateProperty: new Route({ path: "generalSettings/updateProperty" }),
	};

	public Geovis4Table = {
		GetInitialConfig: new Route({ path: "geovis4Table/:projectId/getInitialConfig" }),
		Get: new Route({ path: "geovis4Table/:projectId/get/:tableId" }),
		Save: new Route({ path: "geovis4Table/:projectId/save" }),
		SaveTemplate: new Route({ path: "geovis4Table/:projectId/saveTemplate" }),
	};

	public GeovisChart = {
		GetChartInfo: new Route({ path: "geovisChart/:projectId/chartInfo/:chartId" }),
		GetChartInitialModel: new Route({ path: "geovisChart/:projectId/chartInitialModel/:chartType/:kindOfElementUsing" }),
		GetChartInitialModelOfTemplate: new Route({ path: "geovisChart/:projectId/chartInitialModelOfTemplate/:templateId" }),
		SaveGeovisChart: new Route({ path: "geovisChart/:projectId/saveGeovisChart" }),
		SaveUpdatedSensors: new Route({ path: "geovisChart/:projectId/saveSensors" }),
		GetDtsChartSensorsInfoBySectionIds: new Route({ path: "geovisChart/:projectId/getDtsChartSensorsInfoBySectionIds" }),
		GetAliases: new Route({ path: "geovisChart/:projectId/getAliases" }),
		GetDtsSectionsInfoSlim: new Route({ path: "geovisChart/:projectId/getDtsChartSectionsInfo" }),
	};

	public GeovisColorSchemes = {
		GetSchemes: new Route({ path: "geovisColorSchemes/:projectId/getSchemes" }),
		SetScheme: new Route({ path: "geovisColorSchemes/:projectId/setScheme/:schemeId" }),
	};

	public GeovisComment = {
		Get: new Route({ path: "geovisComment/:projectId/get/:commentId" }),
		Save: new Route({ path: "geovisComment/:projectId/save" }),
		Delete: new Route({ path: "geovisComment/:projectId/delete/:commentId" }),
		UploadFile: new Route({ path: "geovisComment/:projectId/:hash/uploadFile" }),
		DeleteDirtyAttachments: new Route({ path: "geovisComment/:projectId/deleteDirtyAttachments" }),
	};

	public GeovisHeader = {
		Get: new Route({ path: "geovisHeader/:projectId/get/:headerId" }),
		Save: new Route({ path: "geovisHeader/:projectId/save" }),
	};

	public GeovisImages = {
		Get: new Route({ path: "geovisImages/:projectId/get/:imageId" }),
		GetImagesByIds: new Route({ path: "geovisImages/:projectId/getImagesByIds" }),
		GetForProject: new Route({ path: "geovisImages/get/:projectId" }),
		Save: new Route({ path: "geovisImages/:projectId/save" }),
		SaveTemplate: new Route({ path: "geovisImages/:projectId/saveTemplate" }),
		Delete: new Route({ path: "geovisImages/:projectId/delete/:imageId" }),
		ImageInfoByLinkId: new Route({ path: "geovisImages/:projectId/backgroundFileInfoByLink/:linkId" }),
		RemoveDirtyImageFile: new Route({ path: "geovisImages/:projectId/removeDirtyBackgroundFile/:fileId" }),
	};

	public GeovisImageStream = {
		RasterStream: new Route({ path: "geovisImageStream/:projectId/:objectId/rasterStream/:fileId/:isDirty/:sKey" }),
		RasterStreamOfSize: new Route({ path: "geovisImageStream/:projectId/:objectId/rasterStreamOfSize/:fileId/:isDirty/:sKey/size/:width/:height" }),
	};

	public GeovisLogbooks = {
		Get: new Route({ path: "geovisLogbooks/:projectId/get/:logbookId" }),
		Save: new Route({ path: "geovisLogbooks/:projectId/save" }),
	};

	public GeovisTemplates = {
		GetChartTemplateInfo: new Route({ path: "geovisTemplate/:projectId/chartTemplateInfo/:templateId" }),
		GetChartTemplateInitialModel: new Route({ path: "geovisTemplate/:projectId/chartTemplateInitialModel/:chartType/:kind" }),
		SaveGeovisChartTemplate: new Route({ path: "geovisTemplate/:projectId/saveGeovisChartTemplate" }),
		GeovisChartTemplateFromGeovisChart: new Route({ path: "geovisTemplate/:projectId/chartTemplateFromChart" }),
		GetTemplatesForChart: new Route({ path: "geovisTemplate/:projectId/templatesForChart/:chartType/:isDefault" }),
		GetTemplatesForComments: new Route({ path: "geovisTemplate/:projectId/templatesForComment" }),
		SaveGeovisCommentTemplate: new Route({ path: "geovisTemplate/:projectId/saveGeovisCommentTemplate" }),
		GeovisCommentTemplateFromGeovisComment: new Route({ path: "geovisTemplate/:projectId/commentTemplateFromComment" }),
		GetTemplatesForImage: new Route({ path: "geovisTemplate/:projectId/templatesForImage" }),
		SaveGeovisImageTemplate: new Route({ path: "geovisTemplate/:projectId/saveGeovisImageTemplate" }),
		GeovisImageTemplateFromGeovisImage: new Route({ path: "geovisTemplate/:projectId/imageTemplateFromImage" }),
		GeovisImageFromImageTemplate: new Route({ path: "geovisTemplate/:projectId/imageFromImageTemplate/:templateId" }),
		GetTemplatesForTables: new Route({ path: "geovisTemplate/:projectId/templatesForTables" }),
		SaveGeovis4TableTemplate: new Route({ path: "geovisTemplate/:projectId/saveGeovis4TableTemplate" }),
		GeovisTableTemplateFromGeovisTable: new Route({ path: "geovisTemplate/:projectId/tableTemplateFromTable" }),
		GeovisTableFromTableTemplate: new Route({ path: "geovisTemplate/:projectId/tableFromTableTemplate/:templateId" }),
		GetTemplatesForReport: new Route({ path: "geovisTemplate/:projectId/templatesForReport" }),
	};

	public GeovisFeedback = {
		GetRecordsInfo: new Route({ path: "geovisFeedback/recordsInfo/:type" }),
		GetRecord: new Route({ path: "geovisFeedback/record/:recordId" }),
		GetUsersInfoOfState: new Route({ path: "geovisFeedback/usersInfo/ofState/:state" }),
		RegisterRecord: new Route({ path: "geovisFeedback/registerApplicationError" }),
		UpdateFeedbackProperty: new Route({ path: "geovisFeedback/updateFeedbackProperty/:recordId" }),
		GetStatistic: new Route({ path: "geovisFeedback/statistic" }),
		FeedbackAttachmentInfoByLinkId: new Route({ path: "geovisFeedback/feedbackAttachmentInfoByLinkId/:linkId" }),
		GetFeedbackAttachmentInfos: new Route({ path: "geovisFeedback/getFeedbackAttachmentInfos" }),
		Download: new Route({ path: "geovisFeedback/download/:attachmentId" }),
	};

	public GlobalImports = {
		GetSwissMeteoSensors: new Route({ path: "globalImports/swissMeteo/getSensors" }),
		GetSwissMeteoSensorsData: new Route({ path: "globalImports/swissMeteo/getSensorsData" }),
		AssignSensorsToProject: new Route({ path: "globalImports/swissMeteo/assignSensorsToProject" }),
		GetProjectSwissMeteoSensors: new Route({ path: "globalImports/swissMeteo/getProjectSensors/:projectId" }),
		GetSettings: new Route({ path: "globalImports/settings" }),
		SetSettings: new Route({ path: "globalImports/setSettings" }),
	};

	public GlobalStatistic = {
		UpdateProjectsStatisticNextNight: new Route({ path: "globalStatistic/updateProjectsStatisticNextNight/:value" }),
		UpdateProjectsStatisticAtNextMonth: new Route({ path: "globalStatistic/updateProjectsStatisticAtNextMonth/:value" }),
		UpdateProjectsStatisticAtNextWeek: new Route({ path: "globalStatistic/updateProjectsStatisticAtNextWeek/:value" }),
		GetStatisticSettings: new Route({ path: "globalStatistic/statisticSettings" }),
		SetStatisticSettings: new Route({ path: "globalStatistic/setStatisticSettings" }),
		ResumeStatisticsCollecting: new Route({ path: "globalStatistic/resumeStatisticsCollecting" }),
		CheckForUncompletedStatistics: new Route({ path: "globalStatistic/checkForUncompletedStatistics" }),
	};

	public ImportTask = {
		GetAllowedImportSettings: new Route({ path: "importTask/:projectId/allowedImportSettings/:importMode" }),
		GetTasks: new Route({ path: "importTask/:projectId/tasks" }),
		Create: new Route({ path: "importTask/:projectId/create" }),
		Update: new Route({ path: "importTask/:projectId/task/:taskId/update" }),
		UpdateProperty: new Route({ path: "importTask/:projectId/task/:taskId/updateProperty" }),
		Get: new Route({ path: "importTask/:projectId/task/:taskId/get" }),
		Run: new Route({ path: "importTask/:projectId/task/:taskId/run" }),
		Delete: new Route({ path: "importTask/:projectId/task/:taskId/delete" }),
		Copy: new Route({ path: "importTask/:projectId/task/:taskId/copy" }),
		CheckSourceConnection: new Route({ path: "importTask/:projectId/task/checkSourceConnection" }),
		CheckSourceFiles: new Route({ path: "importTask/:projectId/task/:taskId/checkSourceFiles" }),
		GetTaskWatchdogState: new Route({ path: "importTask/:projectId/task/:taskId/watchdogState" }),
		GetListOfAxisFiles: new Route({ path: "importTask/:projectId/axisFiles" }),
		ManualImport: new Route({ path: "importTask/:projectId/manual/import" }),
		GetManualImportTasksShortInfo: new Route({ path: "importTask/:projectId/manual/tasksShortInfo" }),
		GetManualImportTaskShortInfo: new Route({ path: "importTask/:projectId/manual/taskShortInfo/:taskId" }),
		SetManualTaskAsConfirmed: new Route({ path: "importTask/:projectId/manual/:taskId/confirm" }),
		SetAllManualTasksAsConfirmed: new Route({ path: "importTask/:projectId/manual/confirmAll" }),
	};

	public Invoice = {
		GetCompanyInvoice: new Route({ path: "invoice/:companyId" }),
		ExportToCsv: new Route({ path: "invoice/export/:kind/:companyId/:period" }),
		CustomExport: new Route({ path: "invoice/export/:kind/:companyId/:from/:to" }),
		QuartalExport: new Route({ path: "invoice/exportQuartal/:kind/:companyId/:year/:quartal" }),
	};

	public Account = {
		CurrentServerInfo: new Route({ path: "account/currentServerInfo" }),
		GetApplicationVersion: new Route({ path: "account/getVersion" }),
		GetApplicationBuildNumber: new Route({ path: "account/getBuildNumber" }),
		ChangePassword: new Route({ path: "account/changePassword" }),
		SetPassword: new Route({ path: "account/setPassword/:userId" }),
		ForgotPassword: new Route({ path: "account/forgotPassword" }),
		ResetPassword: new Route({ path: "account/resetPassword" }),
		RelatedCompanies: new Route({ path: "account/relatedCompanies" }),
		RelatedCompanies2: new Route({ path: "account/:userId/relatedCompanies" }),
		RelatedParthnerCompanies: new Route({ path: "account/relatedParthnerCompanies" }),
		CompanyRelatedData: new Route({ path: "account/companyRelatedData/:companyId" }),
		CompanyOwnData: new Route({ path: "account/companyOwnData/:companyId" }),
		RelatedCompanyUsers: new Route({ path: "account/relatedCompanyUsers/:companyId" }),
		AddRelatedCompany: new Route({ path: "account/addRelatedCompany/:companyId" }),
		RemoveRelatedCompany: new Route({ path: "account/removeRelatedCompany/:companyId" }),
		CreateRelatedCompany: new Route({ path: "account/createRelatedCompany" }),
		GetCompaniesBySearchQuery: new Route({ path: "account/companiesBySearchQuery" }),
		UsersInfo: new Route({ path: "account/usersInfo" }),
		OwnerAdmins: new Route({ path: "account/ownerAdmins" }),
		RelatedUsers: new Route({ path: "account/relatedUsers" }),
		RelatedUsersForProject: new Route({ path: "account/relatedUsersProject/:projectId" }),
		GetRelatedUsersOfMessageTemplates: new Route({ path: "account/relatedUsersOfMessageTemplates" }),
		GetUserByEmail: new Route({ path: "account/getUserByEmail/:email" }),
		UserHeadInfoById: new Route({ path: "account/:userId/userHeadInfo" }),
		AddRelatedUser: new Route({ path: "account/addRelatedUser" }),
		RemoveRelatedUser: new Route({ path: "account/removeRelatedUser/:userId" }),
		DeleteUser: new Route({ path: "account/deleteUser/:userId" }),
		CreateRelatedUser: new Route({ path: "account/createRelatedUser" }),
		LoadUserProfile: new Route({ path: "account/loadUserProfile/:userId" }),
		AllowedDbsOfProjects: new Route({ path: "account/allowedDbsOfProjects/:userId" }),
		SaveRelatedUser: new Route({ path: "account/saveUser" }),
		ChangeLanguage: new Route({ path: "account/changeLanguage/:langCode" }),
		BusinessProjects: new Route({ path: "account/selectProjectsList/:withLeaders" }),
		BusinessProjectsWithStatistic: new Route({ path: "account/businessProjectsWithStatistic" }),
		UserAvatarSrc: new Route({ path: "account/userAvatarSrc/:userId" }),
		UpdateUserProperty: new Route({ path: "account/updateUserProperty" }),
		ExportUsers: new Route({ path: "account/exportUsers/:projectId/:companyId?" }),
		ExportCompanies: new Route({ path: "account/exportCompanies/:projectId/:companyId?" }),
		ImportUsers: new Route({ path: "account/importUsers/:linkId" }),
		ImportUserList: new Route({ path: "account/importUserList" }),
		UsersForImport: new Route({ path: "account/usersForImport/:linkId" }),
		ImportCompanies: new Route({ path: "account/importCompanies/:linkId" }),
		ImportCompanyList: new Route({ path: "account/importCompanyList" }),
		CompaniesForImport: new Route({ path: "account/companiesForImport/:linkId" }),
	};

	public Inventory = {
		GetChildren: new Route({ path: "inventory/getChildren/:id/projectId/:sendingId" }),
		PossibleParents: new Route({ path: "inventory/getPossibleParents/projectId/:projectId" }),
		InventoryObjectInfo: new Route({ path: "inventory/:id/inventoryObjectInfo" }),
		GetObjectsForProject: new Route({ path: "inventory/getObjectsForProject/:projectId" }),
		GetProjects: new Route({ path: "inventory/projects" }),
		MSTsAndSBsForProject: new Route({ path: "inventory/devices/mstsAndSbsForProject/:projectId" }),
		AmbergTrackControl: new Route({ path: "inventory/ambergTrackControl/:projectId" }),
		AmbergTrackControlCreate: new Route({ path: "inventory/ambergTrackControl/create" }),
		AmbergTrackControlSave: new Route({ path: "inventory/ambergTrackControl/save" }),
		AmbergTrackControlRemove: new Route({ path: "inventory/ambergTrackControl/:id/remove" }),
		MSTs: new Route({ path: "inventory/msts/:projectId" }),
		CreateMst: new Route({ path: "inventory/mst/create" }),
		MstSave: new Route({ path: "inventory/mst/save" }),
		MstRemove: new Route({ path: "inventory/mst/:id/delete" }),
		Sensorboxes: new Route({ path: "inventory/sensorboxes/:projectId" }),
		SensorboxSave: new Route({ path: "inventory/sensorboxes/create" }),
		SensorboxCreate: new Route({ path: "inventory/sensorboxes/save" }),
		SensorboxRemove: new Route({ path: "inventory/sensorbox/:id/delete" }),
		Tachymeters: new Route({ path: "inventory/tachymeters/:projectId" }),
		TachymeterCreate: new Route({ path: "inventory/tachymeter/create" }),
		TachymeterSave: new Route({ path: "inventory/tachymeter/save" }),
		TachymeterRemove: new Route({ path: "inventory/tachymeter/:id/delete" }),
		Afms: new Route({ path: "inventory/afm/:projectId" }),
		AfmCreate: new Route({ path: "inventory/afm/create" }),
		AfmSave: new Route({ path: "inventory/afm/save" }),
		AfmRemove: new Route({ path: "inventory/afm/:id/remove" }),
		LoRaCreate: new Route({ path: "inventory/lora/create" }),
		LoRaSave: new Route({ path: "inventory/lora/save" }),
		GetUsagePlan: new Route({ path: "inventory/usagePlan/:itemId" }),
		GetUsagePlanRecords: new Route({ path: "inventory/usagePlan/records" }),
		UpdateUsagePlan: new Route({ path: "inventory/usagePlan/update" }),
		AddToUsagePlan: new Route({ path: "inventory/usagePlan/add" }),
		RemoveFromUsagePlan: new Route({ path: "inventory/usagePlan/remove/:recordId" }),
		ConfirmUsagePlanStatusChange: new Route({ path: "inventory/usagePlan/:recordId/confirmStatusChange" }),
		ExtendUsagePlanTimeRangeAffectedRecords: new Route({ path: "inventory/usagePlan/:recordId/extendTimeRangeAffectedRecords" }),
		ExtendUsagePlanTimeRange: new Route({ path: "inventory/usagePlan/:recordId/extendTimeRange" }),
		CalendarViewData: new Route({ path: "inventory/usagePlan/calendarViewData" }),
		RemoveItemFromReservation: new Route({ path: "inventory/usagePlan/removeFormReservation/:recordId" }),
		ExtendUsagePlansTimeRange: new Route({ path: "inventory/usagePlan/extendUsagePlansTimeRange" }),
		MoveToStorageForProjectUsagePlan: new Route({ path: "inventory/usagePlan/moveToStorageForProject/:projectId/:storageId" }),
		ExtendUsagePlanTimeRangeForObjects: new Route({ path: "inventory/usagePlan/extendUsagePlanTimeRangeForObjects/:projectId" }),
		RemoveItemsFromProject: new Route({ path: "inventory/usagePlan/RemoveItemsFromProject/:projectId" }),
		CheckExpandPossibility: new Route({ path: "inventory/usagePlan/checkExpandPossibility/:projectId" }),
		GetStartExpandingDate: new Route({ path: "inventory/usagePlan/getStartExpandingDate/:projectId" }),
		GetDevicesInfo: new Route({ path: "inventory/reservation/devices" }),
		GetReservations: new Route({ path: "inventory/reservations" }),
		GetReservation: new Route({ path: "inventory/reservation/:reservationId" }),
		AddReservation: new Route({ path: "inventory/reservation/add" }),
		UpdateReservation: new Route({ path: "inventory/reservation/update" }),
		RemoveReservation: new Route({ path: "inventory/reservation/:reservationId/remove" }),
		ConvertToProject: new Route({ path: "inventory/reservation/:reservationId/to/:projectId" }),
		GetPropertyHistory: new Route({ path: "inventory/history/:historyId/property/:propertyName" }),
	};

	public LocalMapObject = {
		Get: new Route({ path: "localMapObject/:objectId" }),
		Save: new Route({ path: "localMapObject/:projectId/save/:viewId" }),
		Delete: new Route({ path: "localMapObject/:projectId/delete/:objectId" }),
		DeleteDirtyGeoJSON: new Route({ path: "localMapObject/:projectId/deleteDirtyGeoJson/:geoJsonFileId" }),
		Download: new Route({ path: "localMapObject/:projectId/download/:objectId" }),
		DownloadAttachedDocument: new Route({ path: "localMapObject/:projectId/downloadDocument/:documentId" }),
		ChangeIsPublic: new Route({ path: "localMapObject/:projectId/changeIsPublic/:objectId" }),
		UpdateSinglePointLocation: new Route({ path: "localMapObject/:projectId/updateSinglePointLocation/:objectId" }),
		UpdateMultiPointsLocation: new Route({ path: "localMapObject/:projectId/updateMultiPointsLocation/:objectId" }),
		UpdateRotationAngle: new Route({ path: "localMapObject/:projectId/updateRotationAngle/:objectId/:angle" }),
		UpdatePolygonLocation: new Route({ path: "localMapObject/:projectId/updatePolygonLocation/:objectId" }),
		UpdateCircleLocation: new Route({ path: "localMapObject/:projectId/updateCircleLocation/:objectId/:radius" }),
		RemoveDirtyBackgroundFile: new Route({ path: "localMapObject/:projectId/removeDirtyBackgroundFile/:fileId" }),
		RemoveDirtyGeoJsonFile: new Route({ path: "localMapObject/:projectId/removeDirtyGeoJsonFile/:fileId" }),
		BackgroundFileInfoByLinkId: new Route({ path: "localMapObject/:projectId/backgroundFileInfoByLink/:linkId" }),
		BackgroundDxfStream: new Route({ path: "localMapObject/:projectId/backgroundDxfStream/:objectId/:fileId/:isDirty" }),
		DirtyBackgroundGeoJsonDxfStream: new Route({ path: "localMapObject/:projectId/dirtyBackgroundDxfGeoJsonStream/:fileId" }),
		GetSensorsInfoOfLocalMapObject: new Route({ path: "localMapObject/:projectId/sensorsInfo/:lmoId" }),
		GetSensorsLastMeasurementsOfLocalMapObject: new Route({ path: "localMapObject/:projectId/sensorsLastMeasurements/:lmoId" }),
		GetAvailableSensorsInfo: new Route({ path: "localMapObject/:projectId/availableSensorsInfo" }),
		GetLmoSensorsInfoOfSensorIds: new Route({ path: "localMapObject/:projectId/getLmoSensorsInfoOfSensorIds" }),
		GetLmoSensorsInfoOfChainIds: new Route({ path: "localMapObject/:projectId/getLmoSensorsInfoOfChainIds" }),
		GetProjectViewLmoSensorsInfo: new Route({ path: "localMapObject/:projectId/projectView/:viewId/lmoSensorsInfo" }),
	};

	public LocalMapObjects = {
		Get: new Route({ path: "localMapObjects/:projectId" }),
		GetLocalMapObjectsByIds: new Route({ path: "localMapObjects/:projectId/lmoByIds" }),
		GetLocalMapObjectsOfView: new Route({ path: "localMapObjects/:projectId/lmoOfView/:viewId" }),
		UpdateProperties: new Route({ path: "localMapObjects/:projectId/updateProperties" }),
	};

	public LogosManager = {
		GetAllLogoFiles: new Route({ path: "logosManager/all/logo" }),
		GetAllCompaniesWithLogos: new Route({ path: "logosManager/all/companies" }),
		GetAllProjectsLogoInfo: new Route({ path: "logosManager/all/projects" }),
		LogoFileInfoByLinkId: new Route({ path: "logosManager/:linkId/logoInfo" }),
		LogoImage: new Route({ path: "logosManager/:logoId/logoImage" }),
		LogoFullImage: new Route({ path: "logosManager/:logoId/logoFullImage" }),
		CanDeleteLogo: new Route({ path: "logosManager/:logoId/canDeleteLogo" }),
		DeleteLogo: new Route({ path: "logosManager/:logoId/deleteLogo" }),
	};

	public Logs = {
		GvLogEntryPoints: new Route({ path: "logs/gvLogEntryPoints" }),
		GvLogRecords: new Route({ path: "logs/gvLogRecords" }),
		GetGvLogRecord: new Route({ path: "logs/gvLogRecord/:projectId/:recordId/:type" }),
		GetGvLogParent: new Route({ path: "logs/gvLogParent/:projectId/:recordId" }),
		SearchParentEventRecord: new Route({ path: "logs/:projectId/searchParentOf/:recordId" }),
		GetGvLogScheduleTaskInfo: new Route({ path: "logs/gvLogScheduleTaskInfo/:module/:taskId" }),
		GetGvLogAlarmInfo: new Route({ path: "logs/gvLogAlarmInfo/:alarmId" }),
		GetGvLogEntryPoint: new Route({ path: "logs/:projectId/gvLogEntryPoint/:entryId" }),
		GetGvLogFollowRecords: new Route({ path: "logs/gvLogFollowRecords" }),
		GetCommonSettings: new Route({ path: "logs/common/settings" }),
		UpdateCommonSettingsProperty: new Route({ path: "logs/common/updateProperty" }),
		ExecuteSystemReindexTask: new Route({ path: "logs/common/executeSystemReindexTask" }),
		ExecuteAllReindexTask: new Route({ path: "logs/common/executeAllReindexTask" }),
		ExecuteCleanupTask: new Route({ path: "logs/common/executeCleanupTask" }),
		GetProjectLogModulesSettings: new Route({ path: "logs/project/:projectId/modulesSettings" }),
		UpdateProjectModuleProperty: new Route({ path: "logs/project/updateProperty" }),
		ExecuteProjectReindexTask: new Route({ path: "logs/project/:projectId/executeReindexTask" }),
	};

	public LoRaUI = {
		GetRootConfiguration: new Route({ path: "lora/ui/:projectId/rootConfig" }),
		GetAlg: new Route({ path: "lora/ui/:projectId/getAlg/:algId" }),
		GetSwisscomAlg: new Route({ path: "lora/ui/:projectId/getSwisscomAlg" }),
		GetDefaultSwisscomAlgConfig: new Route({ path: "lora/ui/:projectId/getDefaultSwisscomAlgConfig" }),
		InitSwisscomAlg: new Route({ path: "lora/ui/:projectId/initSwisscomAlg" }),
		CreateAlg: new Route({ path: "lora/ui/:projectId/createAlg" }),
		SaveAlg: new Route({ path: "lora/ui/:projectId/saveAlg" }),
		RemoveAlg: new Route({ path: "lora/ui/:projectId/removeAlg/:algId" }),
		InitAlgConfiguration: new Route({ path: "lora/ui/:projectId/initAlgConfig/:algId" }),
		GetAlgRemoteConfig: new Route({ path: "lora/ui/:projectId/getAlgRemoteConfig/:algId" }),
		SetAlgRemoteConfig: new Route({ path: "lora/ui/:projectId/setAlgRemoteConfig/:algId" }),
		CreateLoRaNode: new Route({ path: "lora/ui/:projectId/createLoRaNode" }),
		GetAln: new Route({ path: "lora/ui/:projectId/getAln/:alnId" }),
		GetAlnSensors: new Route({ path: "lora/ui/:projectId/getAlnSensors/:alnId" }),
		RemoveLoRaNode: new Route({ path: "lora/ui/:projectId/removeLoRaNode/:lrnId" }),
		SaveAlnProperty: new Route({ path: "lora/ui/:projectId/saveAlnProperty/:alnId" }),
		CreateOrUpdateAlnSensor: new Route({ path: "lora/ui/:projectId/createOrUpdateAlnSensor/:alnId/:isInternalSensor" }),
		CreateLrnSensors: new Route({ path: "lora/ui/:projectId/createLrnSensors/:lrnId" }),
		UpdateAlnPredefinedSensor: new Route({ path: "lora/ui/:projectId/updateAlnPredefinedSensor/:alnId" }),
		DeleteLrnSensor: new Route({ path: "lora/ui/:projectId/deleteLrnSensor/:alnId" }),
		SendAllNodesToGateway: new Route({ path: "lora/ui/:projectId/sendAllNodesToGateway/:algId" }),
		SendOneNodeToGateway: new Route({ path: "lora/ui/:projectId/sendOneNodeToGateway/:algId/:alnId" }),
		SendDownlink: new Route({ path: "lora/ui/:projectId/sendDownlink/:lrnId" }),
		ChangeAlnName: new Route({ path: "lora/ui/:projectId/changeAlnName/:alnId" }),
		ChangeAlnNotActiveFlag: new Route({ path: "lora/ui/:projectId/changeAlnNotActiveFlag/:alnId/:notActive" }),
		MoveAlnToAlg: new Route({ path: "lora/ui/:projectId/moveAlnToAlg/:sourceAlnId/:destAlgDevEUI" }),
		ReOrderAlns: new Route({ path: "lora/ui/:projectId/reOrderAlns/:parentAlgDevEUI" }),
		GetAlnDeviceDefaultConfig: new Route({ path: "lora/ui/:projectId/getAlnDeviceDefaultConfig/:deviceType" }),
	};

	public LoRaV3 = {
		GetAuthToken: new Route({ path: "lora/v3/GetAuthToken" }),
		GetALGConfiguration: new Route({ path: "lora/v3/GetALGConfiguration" }),
		GetAllALNConfigurations: new Route({ path: "lora/v3/GetAllALNConfigurations" }),
		ALNStarted: new Route({ path: "lora/v3/ALNStarted" }),
		MeasurementValues: new Route({ path: "lora/v3/MeasurementValues" }),
		ALNErrorMessage: new Route({ path: "lora/v3/ALNErrorMessage" }),
		FLNPayloadUplink: new Route({ path: "lora/v3/FLNPayloadUplink" }),
		SwisscomFrame: new Route({ path: "lora/v3/SwisscomFrame" }),
		TestSwisscomFrame: new Route({ path: "lora/v3/TestSwisscomFrame" }),
		SwisscomFrameSecure: new Route({ path: "lora/v3/SwisscomFrameSecure" }),
	};

	public MaintenanceService = {
		GetInstanceState: new Route({ path: "maintenanceService/instance/state" }),
		UpdateConfigProperty: new Route({ path: "maintenanceService/updateConfigProperty" }),
		PingMaintenanceService: new Route({ path: "maintenanceService/instance/pingMaintenanceService" }),
		PingApplicationService: new Route({ path: "maintenanceService/instance/pingApplicationService" }),
		PingMaintenanceServiceToApplicationService: new Route({ path: "maintenanceService/instance/pingMaintenanceServiceToApplicationService" }),
		PingApplicationServiceToMaintenanceService: new Route({ path: "maintenanceService/instance/pingApplicationServiceToMaintenanceService" }),
		PingDataSyncService: new Route({ path: "maintenanceService/instance/pingDataSyncService" }),
		PingApplicationServiceToDataSyncService: new Route({ path: "maintenanceService/instance/pingApplicationServiceToDataSyncService" }),
		NotificationEventDetails: new Route({ path: "maintenanceService/notification/details" }),
		GetWatchdogInfo: new Route({ path: "maintenanceService/appServiceWD/state" }),
		GetBucketStateInfo: new Route({ path: "maintenanceService/buckets/state" }),
		GetBucketTaskDetailsInfo: new Route({ path: "maintenanceService/buckets/info/:taskId" }),
		GetBucketTaskDatabaseSensorsInfo: new Route({ path: "maintenanceService/bucket/task/:taskId/databaseInfo/:alias/isAxis/:isOneDay" }),
		SaveBucketTask: new Route({ path: "maintenanceService/buckets/save" }),
		LoadBucketTask: new Route({ path: "maintenanceService/buckets/:taskId/load" }),
		RunBucketTask: new Route({ path: "maintenanceService/buckets/:taskId/run" }),
		StopBucketTask: new Route({ path: "maintenanceService/buckets/:taskId/stop" }),
		DeleteBucketTask: new Route({ path: "maintenanceService/buckets/:taskId/delete" }),
	};

	public ManualDocument = {
		Get: new Route({ path: "manualDocument/:documentId/get" }),
		GetByLink: new Route({ path: "manualDocument/byLink/:linkId" }),
		Delete: new Route({ path: "manualDocument/delete/:documentId" }),
		Download: new Route({ path: "manualDocument/download/:documentId" }),
		ChangeVisibleForUserRole: new Route({ path: "manualDocument/changeVisibleForUserRole/:documentId/:newUserRole" }),
	};

	public ManualDocuments = {
		Get: new Route({ path: "manualDocuments/get" }),
	};

	public MapSection = {
		GetMapSectionEditInfo: new Route({ path: "mapSection/:projectId/mapSectionEditInfo/:mapSectionId" }),
		GetMapSection: new Route({ path: "mapSection/:projectId/get/:sectionId" }),
		GetDefaultMapSectionEditInfo: new Route({ path: "mapSection/:projectId/defaultMapSectionEditInfo" }),
		GetAll: new Route({ path: "mapSection/:projectId/all/:reportId" }),
		SaveMapSection: new Route({ path: "mapSection/:projectId/saveMapSection" }),
		RemoveMapSection: new Route({ path: "mapSection/:projectId/removeMapSection/:mapSectionId" }),
	};

	public MapTiles = {
		SingInToken: new Route({ path: "mapTiles/signInToken" }),
		Check: new Route({ path: "mapTiles/check" }),
		DeleteDirtyLmoMapTiles: new Route({ path: "mapTiles/:projectId/deleteDirtyLmoMapTiles/:lmoId" }),
		RebuildMapTiles: new Route({ path: "mapTiles/tilesProcessing/:projectId/rebuild/:layerId" }),
		BuildByLinkId: new Route({ path: "mapTiles/tilesProcessing/:projectId/buildByLinkId/:linkId" }),
		BuildProfileViewByLinkId: new Route({ path: "mapTiles/tilesProcessing/:projectId/buildProfileViewByLinkId/:linkId" }),
		BuildLmoMapTiles: new Route({ path: "mapTiles/tilesProcessing/:projectId/buildLmoMapTiles/:lmoId" }),
		BuildLmoMapTilesByLinkId: new Route({ path: "mapTiles/tilesProcessing/:projectId/buildLmoDxfByLinkId/:linkId" }),
		BuildGeovisImageMapTiles: new Route({ path: "mapTiles/tilesProcessing/:projectId/buildGeovisImageMapTiles/:imageId" }),
		BuildGeovisImageMapTilesByLinkId: new Route({ path: "mapTiles/tilesProcessing/:projectId/buildGeovisImageDxfByLinkId/:linkId" }),
		DeleteDirtyGeovisImageMapTiles: new Route({ path: "mapTiles/:projectId/deleteDirtyGeovisImageMapTiles/:imageId" }),
	};

	public MessageTemplates = {
		Get: new Route({ path: "messageTemplates/get/:all" }),
		Template: new Route({ path: "messageTemplates/:templateId" }),
		Save: new Route({ path: "messageTemplates/save" }),
		Delete: new Route({ path: "messageTemplates/:templateId/delete" }),
		Share: new Route({ path: "messageTemplates/:templateId/share" }),
		Unshare: new Route({ path: "messageTemplates/:templateId/unshare" }),
		Unlink: new Route({ path: "messageTemplates/:templateId/unlink" }),
		SendNotification: new Route({ path: "messageTemplates/sendNotification" }),
		UploadFile: new Route({ path: "messageTemplates/:templateHash/uploadFile" }),
		DeleteDirtyAttachments: new Route({ path: "messageTemplates/deleteDirtyAttachments" }),
		GetHistory: new Route({ path: "messageTemplates/getHistory" }),
	};

	public GeovisCommentStream = {
		Image: new Route({ path: "geovisCommentStream/image/:fileId" }),
	};

	public MessageTemplatesStream = {
		Image: new Route({ path: "messageTemplatesStream/image/:fileId" }),
	};

	public Migrations = {
		GetGeneralInfo: new Route({ path: "migrations/generalInfo" }),
		Get: new Route({ path: "migrations/get" }),
		CancelMigrationExecution: new Route({ path: "migrations/:mstId/cancelMigrationExecuting/:migration" }),
		ExecuteStorageSettingsMigration: new Route({ path: "migrations/:projectId/executeMigration" }),
		GetMstMigrationDetails: new Route({ path: "migrations/:mstId/mstMigrationDetails" }),
		GetMstsMigrationStatistic: new Route({ path: "migrations/migrationStatistic/:migration" }),
		GetMstMigrationSettings: new Route({ path: "migrations/:projectId/mstMigrationSettings/:mstId" }),
		SetProjectElementsMigrationState: new Route({ path: "migrations/:projectId/elements/:elementType/setState/:state" }),
		GetProjectElementsInfo: new Route({ path: "migrations/projectsElements" }),
		GetProjectElementMigrationDetails: new Route({ path: "migrations/:projectId/getElementsMigrationDetails/:elementType" }),
		MigrateElements: new Route({ path: "migrations/:projectId/migrate/:elementType" }),
		RollbackElementsMigrations: new Route({ path: "migrations/:projectId/rollback/:elementType" }),
		RemoveOldElements: new Route({ path: "migrations/:projectId/removeOld/:elementType" }),
		MigrateProject: new Route({ path: "migrations/:projectId/projectMigrate" }),
		MigrateProjects: new Route({ path: "migrations/allProjectsMigrate" }),
		RollbackProjects: new Route({ path: "migrations/allProjectsRollback" }),
		RemoveOldForAllProjects: new Route({ path: "migrations/allProjectsRemoveOld" }),
	};

	public NotificationsSettings = {
		GetSettings: new Route({ path: "notificationsSettings/getSettings" }),
		UpdateDolphinSettings: new Route({ path: "notificationsSettings/save/dolphin" }),
		UpdateEmailSettings: new Route({ path: "notificationsSettings/save/email" }),
		UpdateSmsSettings: new Route({ path: "notificationsSettings/save/sms" }),
		UpdateNotificationSettings: new Route({ path: "notificationsSettings/save/:nType/notification" }),
		SendEmailPingMessage: new Route({ path: "notificationsSettings/sendEmailPing/:address" }),
		SendSmsPingMessage: new Route({ path: "notificationsSettings/sendSmsPing/:number" }),
		GetTestReleaseNotificationsSettings: new Route({ path: "notificationsSettings/getTestReleaseNotificationsSettings" }),
		SetTestReleaseNotificationsSettings: new Route({ path: "notificationsSettings/setTestReleaseNotificationsSettings/:value" }),
	};

	public NotificationsStatistic = {
		CollectNotificationStatistics: new Route({ path: "notificationsStats/collectStatistics" }),
		GetProjects: new Route({ path: "notificationsStats/projects" }),
	};

	public PartnerCompaniesManager = {
		GetPartners: new Route({ path: "partnerCompanyManager/partners" }),
		GetLicensedFeatures: new Route({ path: "partnerCompanyManager/licensedFeatures" }),
		UpdatePartner: new Route({ path: "partnerCompanyManager/updatePartner" }),
		UpdateLicensedFeature: new Route({ path: "partnerCompanyManager/updateFeature" }),
	};

	public Project = {
		Get: new Route({ path: "project/:projectId" }),
		GetSlim: new Route({ path: "project/:projectId/slim" }),
		GetRelatedProjectsSlim: new Route({ path: "project/relatedProjects/slim" }),
		Save: new Route({ path: "project/save" }),
		Create: new Route({ path: "project/create" }),
		Remove: new Route({ path: "project/:projectId/remove" }),
		GetLastMeasurement: new Route({ path: "project/:projectId/lastMeas/:fullId" }),
		GetSensorsInfo: new Route({ path: "project/:projectId/sensorsInfo" }),
		GetSensorsMiniInfo: new Route({ path: "project/:projectId/sensorsMiniInfo" }),
		GetAllSensorsMiniInfo: new Route({ path: "project/:projectId/allSensorsMiniInfo" }),
		GetVirtualSensorsMiniInfo: new Route({ path: "project/:projectId/virtualSensorsMiniInfo" }),
		GetSensorsInfoOfDatabases: new Route({ path: "project/:projectId/sensorsInfoOfDatabases" }),
		GetChainsInfoOfDatabases: new Route({ path: "project/:projectId/chainsInfoOfDatabases" }),
		GetSensorsInfoOfView: new Route({ path: "project/:projectId/sensorsInfoOfView/:viewId" }),
		GetChainsInfoByIds: new Route({ path: "project/:projectId/chainsInfoByIds" }),
		GetSensorsInfoByRequest: new Route({ path: "project/:projectId/sensorsInfoByRequest" }),
		GetSensorsInfoByRequestMini: new Route({ path: "project/:projectId/sensorsInfoByRequestMini" }),
		GetSensorsInfoInSelectedArea: new Route({ path: "project/:projectId/sensorsInfoInSelectedArea" }),
		GetChainsInfoInSelectedArea: new Route({ path: "project/:projectId/chainsInfoInSelectedArea" }),
		GetAllProjectSensorsInfo: new Route({ path: "project/:projectId/allProjectSensors" }),
		GetLastMeasurements: new Route({ path: "project/:projectId/lastMeasurements" }),
		GetLastMeasurementsOfView: new Route({ path: "project/:projectid/lastMeasurementsOfView/:viewId" }),
		GetLastMeasurementsOfSensorIds: new Route({ path: "project/:projectId/lastMeasurementsOfSensorIds" }),
		GetChainsInfo: new Route({ path: "project/:projectId/chainsInfo" }),
		GetChainsInfoByRequest: new Route({ path: "project/:projectId/chainsInfoByRequest" }),
		GetAllChainsInfo: new Route({ path: "project/:projectId/allChainsInfo" }),
		GetChainsInfoOfView: new Route({ path: "project/:projectId/chainsInfoOfView/:viewId" }),
		GetChainsLastMeasurements: new Route({ path: "project/:projectId/chainsLastMeasurements" }),
		GetChainsLastMeasurementsOfView: new Route({ path: "project/:projectId/chainsLastMeasurementsOfView/:viewId" }),
		SetSensorsSymbolSettings: new Route({ path: "project/:projectId/setSensorsSymbolSettings" }),
		GetSensorsSymbolSettings: new Route({ path: "project/:projectId/getSensorsSymbolSettings" }),
		SetProjectVisualSettings: new Route({ path: "project/:projectId/setProjectVisualSettings" }),
		GetProjectVisualSettings: new Route({ path: "project/:projectId/getProjectVisualSettings" }),
		SetDefaultMapViewport: new Route({ path: "project/:projectId/setDefaultMapViewport" }),
		ProjectCustomLogo: new Route({ path: "project/:projectId/customLogo/:logoId" }),
		ProjectHeaderLogo: new Route({ path: "project/:projectId/headerLogo" }),
		LogoByLinkId: new Route({ path: "project/:projectId/logoByLinkId/:linkId" }),
		LogosInfo: new Route({ path: "project/:projectId/logosInfo" }),
		Statistic: new Route({ path: "project/:projectId/statistic" }),
		GenerateStatistic: new Route({ path: "project/:projectId/generateStatistic" }),
		GetProjectCreationDate: new Route({ path: "project/:projectId/projectCreationDate" }),
		StopCollectingStatistics: new Route({ path: "project/:projectId/stopCollectingStatistics" }),
		RestartMonthStatistics: new Route({ path: "project/:projectId/restatMonthStatistics" }),
		GetStatisticsTotalSize: new Route({ path: "project/:projectId/statisticTotalSize" }),
		PossibleLeaders: new Route({ path: "project/:projectId/leaders" }),
		NewProjectPossibleLeaders: new Route({ path: "project/newProjectLeaders" }),
		Users: new Route({ path: "project/:projectId/users" }),
		ProjectViewers: new Route({ path: "project/:projectId/projectViewers" }),
		GetUsersAccessInfo: new Route({ path: "project/:projectId/usersAccessInfo" }),
		AddUserProjectRelation: new Route({ path: "project/:projectId/user/:userId/role/:role/addUserProjectRelation" }),
		AddUserProjectRelations: new Route({ path: "project/user/:userId/addManyUserProjectRelations" }),
		RemoveUserToProjectRelation: new Route({ path: "project/:projectId/user/:userId/removeUserProjectRelation" }),
		AddUsersToProjectRelation: new Route({ path: "project/:projectId/addUsersToProjectRelation/:role" }),
		UpdateUserProjectRelation: new Route({ path: "project/:projectId/user/:userId/role/:role/updateUserProjectRelation" }),
		ExportProjectUsersAsCsv: new Route({ path: "project/:projectId/exportProjectUsersAsCsv/:exportType" }),
		Companies: new Route({ path: "project/:projectId/companies" }),
		StoreNotifications: new Route({ path: "project/:projectId/storeNotifications" }),
		StoreFaNotifications: new Route({ path: "project/:projectId/storeFaNotifications" }),
		UpdateDolphinId: new Route({ path: "project/:projectId/updateDolphin/:dolphinId" }),
		GetSensorDefaultChartPdf: new Route({ path: "project/:projectId/getSensorDefaultChartPdf/:settingsId/viewMode/:viewMode" }),
		ShareSensorDefaultChartPdf: new Route({ path: "project/:projectId/shareSensorDefaultChartPdf/:settingsId/viewMode/:viewMode" }),
		GetSensorDefaultChartReportSettings: new Route({ path: "project/:projectId/defaultChartReportSettings/:chartId/with/:sensorId" }),
		GetSensorDefaultGeovisChartReportSettings: new Route({ path: "project/:projectId/defaultGeovisChartReportSettings/:chartId/with/:sensorId" }),
		GetSelectedSensorsDefaultReportSettings: new Route({ path: "project/:projectId/defaultReportSettings/:storedSettingsId" }),
		GetSelectedSensorsDefaultGeovisReportSettings: new Route({ path: "project/:projectId/defaultGeovisReportSettings" }),
		GetSelectedSensorsDefaultReportPdf: new Route({ path: "project/:projectId/getSelectedSensorsDefaultReportPdf/:settingsId/viewMode/:viewMode" }),
		ShareSelectedSensorsDefaultReportPdf: new Route({ path: "project/:projectId/shareSelectedSensorsDefaultReportPdf/:settingsId/viewMode/:viewMode" }),
		GetPng: new Route({ path: "project/:projectId/report/:hash/png/:settingsId/viewMode/:viewMode" }),
		ReportSettings: new Route({ path: "project/:projectId/report/:reportId/settings" }),
		GeovisReportSettings: new Route({ path: "project/:projectId/geovisReport/:reportId/settings" }),
		StoreCustomerSettings: new Route({ path: "project/:projectId/report/:reportId/storeSettings" }),
		ValidateReportChartData: new Route({ path: "project/:projectId/validateCharts/:sensorsValidationRequired" }),
		Label: new Route({ path: "project/:projectId/label/:labelId" }),
		Labels: new Route({ path: "project/:projectId/labels" }),
		SaveLabel: new Route({ path: "project/:projectId/saveLabel" }),
		DeleteLabel: new Route({ path: "project/:projectId/deleteLabel/:labelId" }),
		SaveNote: new Route({ path: "project/:projectId/saveNote" }),
		SaveInternalInformation: new Route({ path: "project/:projectId/saveInternalInformation" }),
		SaveProjectAdminInformation: new Route({ path: "project/:projectId/saveProjectAdminInformation" }),
		GetAvailableSensorsInfo: new Route({ path: "project/:projectId/availableSensorsInfo/:withCoordinatesOnly" }),
		ChangeKeepZoomLevel: new Route({ path: "project/:projectId/changeKeepZoomLevel" }),
		GetCorrectProjectFileName: new Route({ path: "project/:projectId/getCorrectProjectFileName" }),
	};

	public ProjectDatabase = {
		Create: new Route({ path: "projectDb/:projectId/create" }),
		Edit: new Route({ path: "projectDb/:projectId/edit" }),
		SaveNotificationsSettings: new Route({ path: "projectDb/:projectId/saveNotificationsSettings/:alias" }),
		Remove: new Route({ path: "projectDb/:projectId/remove/:alias" }),
		GetAllForProject: new Route({ path: "projectDb/:projectId/getAll" }),
		GetByAlias: new Route({ path: "projectDb/:projectId/get/:alias" }),
		GetDatabasesShortInfo: new Route({ path: "projectDb/:projectId/getDatabasesShortInfo" }),
		GetAllDatabasesShortInfo: new Route({ path: "projectDb/getAllDatabasesShortInfo" }),
		ConnectMst23Battery: new Route({ path: "projectDb/:projectId/connectMst23Battery/:alias" }),
		DisconnectMst23Battery: new Route({ path: "projectDb/:projectId/disconnectMst23Battery/:alias" }),
	};

	public ProjectElement = {
		GetElementInfo: new Route({ path: "projectElement/:projectId/getElementInfo/:elementType/:elementId" }),
		GetElementCopyName: new Route({ path: "projectElement/:projectId/getElementCopyName" }),
		CopyElement: new Route({ path: "projectElement/:projectId/copyElement" }),
		RemoveElement: new Route({ path: "projectElement/:projectId/removeElement/:elementId/ofType/:elementType" }),
		RemoveElementFromGroupOnly: new Route({ path: "projectElement/:projectId/removeElementFromGroupOnly/:elementId/ofType/:elementType" }),
		AddElementToGroups: new Route({ path: "projectElement/:projectId/addElementToGroups/:elementId/ofType/:elementType" }),
		GetElementGroupsInfo: new Route({ path: "projectElement/:projectId/getElementGroupsInfo/:elementId/ofType/:elementType" }),
		GetTemplatesCount: new Route({ path: "projectElement/:projectId/getTemplatesCount/:elementType/:chartType" }),
	};

	public ProjectElements = {
		GetElements: new Route({ path: "projectsElements/:projectId/getElements/:forCustomView" }),
		GetCustom: new Route({ path: "projectsElements/:projectId/getCustom" }),
		CreateCustomGroup: new Route({ path: "projectsElements/:projectId/createCustomGroup" }),
		UpdateCustomGroup: new Route({ path: "projectsElements/:projectId/updateCustomGroup" }),
		DeleteCustomGroup: new Route({ path: "projectsElements/:projectId/deleteCustomGroup" }),
		UpdateCustomGroupElementsInfo: new Route({ path: "projectsElements/:projectId/updateCustomGroupElementsInfo" }),
		ReorderCustomGroups: new Route({ path: "projectsElements/:projectId/reorderCustomGroups/:sourceIndex/:destinationIndex" }),
		GetDefault: new Route({ path: "projectsElements/:projectId/getDefault" }),
		GetProjectTemplates: new Route({ path: "projectsElements/:projectId/getProjectTemplates" }),
		GetCompanyTemplates: new Route({ path: "projectsElements/:projectId/getCompanyTemplates" }),
	};

	public ProjectLogbook = {
		GetProjectElementLogbook: new Route({ path: "projectLogbooks/:projectId/projectElementLogbook" }),
		GetProjectElementLogbooksOfView: new Route({ path: "projectLogbooks/:projectId/projectElementLogbooksOfView/:viewId" }),
		LogbookElementRecords: new Route({ path: "projectLogbooks/:projectId/geovisLogbookRecords" }),
		GetLogbook: new Route({ path: "projectLogbooks/:projectId/record/:logbookId" }),
		LogbookRecords: new Route({ path: "projectLogbooks/:projectId/allRecords" }),
		ManualLogbookRecords: new Route({ path: "projectLogbooks/:projectId/manualLogbooks" }),
		RemoveLogbookRecords: new Route({ path: "projectLogbooks/:projectId/removeLogbooks" }),
		SaveLogbookElementEntry: new Route({ path: "projectLogbooks/:projectId/saveLogbookElementEntry/:elementId" }),
		SaveLogbookEntry: new Route({ path: "projectLogbooks/:projectId/saveLogbookEntry" }),
		GetLogbookAuthors: new Route({ path: "projectLogbooks/:projectId/getLogbookAuthors" }),
		GetLogbookChartsInfo: new Route({ path: "projectLogbooks/:projectId/getLogbookChartsInfo" }),
		ConfirmLogbook: new Route({ path: "projectLogbooks/:projectId/confirmLogbook/:logbookId" }),
		MarkRecordAsReaded: new Route({ path: "projectLogbooks/:projectId/markRecordAsReaded/:logbookId" }),
		MarkRecordsAsReaded: new Route({ path: "projectLogbooks/:projectId/markRecordsAsReaded" }),
		GetUnreadedRecordsInfo: new Route({ path: "projectLogbooks/:projectId/getUnreadedRecordsInfo/:elementId" }),
		LogbookEntryImageInfoByLinkId: new Route({ path: "projectLogbooks/:projectId/logbookEntryImageInfoByLinkId/:linkId" }),
		LogbookEntryImage: new Route({ path: "projectLogbooks/logbookEntryImage/:imageId" }),
		LogbookEntryImageThumbnail: new Route({ path: "projectLogbooks/logbookEntryImageThumbnail/:imageId" }),
		GetEmailNotificationsHistoryRecord: new Route({ path: "projectLogbooks/:projectId/getEmailNotificationsHistory/:messageId" }),
		GetDolphinNotificationsHistoryRecord: new Route({ path: "projectLogbooks/:projectId/getDolphinNotificationsHistory/:messageId" }),
		GetSmsNotificationsHistoryRecord: new Route({ path: "projectLogbooks/:projectId/getSmsNotificationsHistory/:messageId" }),
	};

	public ProjectReport = {
		StoreGeovisReportCustomSettings: new Route({ path: "projectReport/:projectId/storeGeovisReportCustomSettings" }),
		GetGeovisReportCustomSettings: new Route({ path: "projectReport/:projectId/getGeovisReportCustomSettings/:settingId" }),
		GetGeovisReportPdf: new Route({ path: "projectReport/:projectId/geovisReportPdf/:reportId/settings/:settingsId" }),
		ShareGeovisReportPdf: new Route({ path: "projectReport/:projectId/shareGeovisReportPdf/:reportId/settings/:settingsId" }),
		GetGeovisReportChartToPng: new Route({ path: "projectReport/:projectId/geovisReportChartToPng/:reportId/settings/:settingsId/page/:pageNum/chart/:chartId/h/:height/w/:width" }),
		ExportChartDataToCSV: new Route({ path: "projectReport/:projectId/exportChartDataToCSV" }),
		ExportChartDataToAGD: new Route({ path: "projectReport/:projectId/exportChartDataToAGD" }),
		ExportGeovisChartDataToCSV: new Route({ path: "projectReport/:projectId/exportGeovisChartDataToCSV" }),
		ExportListGeovisChartDataToCSV: new Route({ path: "projectReport/:projectId/exportListGeovisChartsDataToCSV" }),
		ExportGeovisChartDataToAGD: new Route({ path: "projectReport/:projectId/exportGeovisChartDataToAGD" }),
		GetExportGeovisVibrationEventDataFileName: new Route({ path: "projectReport/:projectId/getExportGeovisVibrationEventDataFileName" }),
		ExportGeovisVibrationEventDataToCSV: new Route({ path: "projectReport/:projectId/exportGeovisVibrationEventDataToCSV" }),
		GetGeovisChartExportProgress: new Route({ path: "projectReport/:projectId/getGeovisChartExportProgress/:commandId" }),
		CancelDataExport: new Route({ path: "projectReport/:projectId/cancelDataExport/:commandId" }),
		GetExportChartDataStream: new Route({ path: "projectReport/:projectId/getExportChartDataStream/:commandId" }),
		ShareExportChartData: new Route({ path: "projectReport/:projectId/shareExportChartData/:commandId" }),
		GetExistChartDataExportResult: new Route({ path: "projectReport/:projectId/getExistChartDataExportResult/:reportId/chart/:chartId/exportFormat/:exportFormat" }),
		DismissExistChartDataExportResult: new Route({ path: "projectReport/:projectId/dismissExistChartDataExportResult/:commandId" }),
	};

	public ProjectReports = {
		GetReportInfo: new Route({ path: "projectReports/:projectId/reportInfo/:reportId" }),
		GetReports: new Route({ path: "projectReports/:projectId/all" }),
		GetReportsOfView: new Route({ path: "projectReports/:projectId/reportsOfView/:viewId" }),
		GetGeovisReports: new Route({ path: "projectReports/:projectId/geovisReports" }),
		GetGeovisCompanyReportTemplates: new Route({ path: "projectReports/geovisCompanyReportTemplates" }),
		GetGeovisReport: new Route({ path: "projectReports/:projectId/geovisReport/:reportId" }),
		GetGeovisCompanyReportTemplate: new Route({ path: "projectReports/:projectId/geovisCompanyReportTemplate/:reportId" }),
		GetInitialGeovisReport: new Route({ path: "projectReports/:projectId/geovisReportInit" }),
		GetInitialGeovisReportFromTemplate: new Route({ path: "projectReports/:projectId/geovisReportInit/:templateId" }),
		GetInitialGeovisCompanyReportTemplate: new Route({ path: "projectReports/geovisCompanyReportTemplateInit" }),
		SaveGeovisReport: new Route({ path: "projectReports/:projectId/save" }),
		SaveGeovisCompanyReportTemplate: new Route({ path: "projectReports/saveTemplate" }),
		ChangeReportIsPublic: new Route({ path: "projectReports/:projectId/changePublic/:reportId" }),
		RemoveGeovisReport: new Route({ path: "projectReports/:projectId/remove/:reportId" }),
		RemoveGeovisCompanyReportTemplate: new Route({ path: "projectReports/removeTemplate/:reportId" }),
		GetPossibleReportElements: new Route({ path: "projectReports/:projectId/possibleElements" }),
		GetPossibleReportTemplateElements: new Route({ path: "projectReports/possibleTemplateElements" }),
		GetReportSensors: new Route({ path: "projectReports/:projectId/getSensorsInfo" }),
		Copy: new Route({ path: "projectReports/:projectId/copy" }),
		CopyTemplate: new Route({ path: "projectReports/copyTemplate" }),
		ValidateReportGeovisChartData: new Route({ path: "projectReports/:projectId/validateGeovisCharts/:sensorsValidationRequired" }),
	};

	public ProjectsBackup = {
		Archive: new Route({ path: "projectsBackup/archive" }),
		GetProjects: new Route({ path: "projectsBackup/projects" }),
		UpdateDefaultSettings: new Route({ path: "projectsBackup/updateDefaults" }),
		GetDefaultSettings: new Route({ path: "projectsBackup/getDefaults" }),
		GetListing: new Route({ path: "projectsBackup/listing/:requestId" }),
		CheckFtpConnection: new Route({ path: "projectsBackup/checkFTP" }),
		Restore: new Route({ path: "projectsBackup/restore" }),
		GetDownloadFileStream: new Route({ path: "projectsBackup/getDownloadFileStream/:filename" }),
		InitUserArchiveFilesStorage: new Route({ path: "projectsBackup/initUserArchiveFilesStorage" }),
		InitUserRestoreFilesStorage: new Route({ path: "projectsBackup/initUserRestoreFilesStorage" }),
		ClearUserLastTaskProgressInfo: new Route({ path: "projectsBackup/clearUserLastTaskProgressInfo" }),
		CancelArchivingTask: new Route({ path: "projectsBackup/cancelArchivingTask/:taskId" }),
	};

	public Projects = {
		Get: new Route({ path: "projects/overview/:onlyUserProjects" }),
		GetRunning: new Route({ path: "projects/overviewRunning" }),
		GetProjectsInfo: new Route({ path: "projects/projectsInfo" }),
		GetForCompany: new Route({ path: "projects/overviewForCompany" }),
	};

	public LocalMapObjectStream = {
		BackgroundStream: new Route({ path: "localMapObjectStream/:objectId/backgroundStream/:fileId/:isDirty/:sKey" }),
	};

	public ProjectEditSensors = {
		GetPageOfSensors: new Route({ path: "projectSensors/:projectId/sensorsPage" }),
		GetProjectFilterData: new Route({ path: "projectSensors/:projectId/filterData" }),
		ImportSensorsDescriptions: new Route({ path: "projectSensors/:projectId/importSensorsDescriptions" }),
		RemoveImportSensorsDescriptionsFile: new Route({ path: "projectSensors/:projectId/removeImportSensorsDescriptionsFile/:fileLink" }),
		ExportSensorsDescriptions: new Route({ path: "projectSensors/:projectId/exportSensorsDescriptions" }),
		ConfigMultipleSensors: new Route({ path: "projectSensors/:projectId/configMultipleSensors" }),
		DeleteMultipleSensors: new Route({ path: "projectSensors/:projectId/deleteMultipleSensors" }),
		RecalculateVirtualSensors: new Route({ path: "projectSensors/:projectId/recalculateVS" }),
		TemperatureCorrection: new Route({ path: "projectSensors/:projectId/temperatureCorrection" }),
		DownloadCompensationResults: new Route({ path: "projectSensors/:projectId/getCompensationResults" }),
		DownloadCompensationLogs: new Route({ path: "projectSensors/:projectId/getCompensationLogs" }),
		GetSensorConfig: new Route({ path: "projectSensors/:projectId/getSensorConfig" }),
		TransformCoordinates: new Route({ path: "projectSensors/transformCoordinates" }),
		GetRelatedSensors: new Route({ path: "projectSensors/:projectId/getRelatedSensors" }),
		ValidateSensor: new Route({ path: "projectSensors/:projectId/validateSensor/:isNew" }),
		SaveSensor: new Route({ path: "projectSensors/:projectId/saveSensor" }),
		CreateSensor: new Route({ path: "projectSensors/:projectId/createSensor" }),
		DeleteSensor: new Route({ path: "projectSensors/:projectId/deleteSensor/:alias/:sensorId" }),
		RemoveDirtyImageFile: new Route({ path: "projectSensors/:projectId/removeDirtyBackgroundFile/:fileId" }),
		ImageInfoByLinkId: new Route({ path: "projectSensors/:projectId/backgroundFileInfoByLink/:linkId" }),
		GetAxisFilesInfo: new Route({ path: "projectSensors/:projectId/axisFilesInfo" }),
		DeleteAxisFile: new Route({ path: "projectSensors/:projectId/deleteAxisFile/:fileId" }),
		RecalculateAxisData: new Route({ path: "projectSensors/:projectId/recalculateAxisData" }),
		CalculateNagraLocalCoordinates: new Route({ path: "projectSensors/:projectId/calculateNagraLocalCoordinates" }),
	};

	public ProjectSensorsData = {
		GetData: new Route({ path: "projectSensorsData/:projectId/sensorsData" }),
		UpdateRecord: new Route({ path: "projectSensorsData/:projectId/updateRecord" }),
		DeleteRecord: new Route({ path: "projectSensorsData/:projectId/deleteRecord" }),
		DeleteSelectedRecords: new Route({ path: "projectSensorsData/:projectId/deleteSelectedRecords" }),
		DeleteManySensorsData: new Route({ path: "projectSensorsData/:projectId/deleteManySensorData" }),
		ChangeSensorsState: new Route({ path: "projectSensorsData/:projectId/changeSensorsState" }),
		ChangeSelectedSensorsDataState: new Route({ path: "projectSensorsData/:projectId/changeSelectedSensorsDataState" }),
		SetAsOffset: new Route({ path: "projectSensorsData/:projectId/:filterDataType/setAsOffset" }),
		SetAsReference: new Route({ path: "projectSensorsData/:projectId/:filterDataType/setAsReference" }),
		SetAsZeroMeasurement: new Route({ path: "projectSensorsData/:projectId/:filterDataType/setAsZeroMeasurement" }),
		SetAsNoOffset: new Route({ path: "projectSensorsData/:projectId/setAsNoOffset" }),
		SetAsNoReference: new Route({ path: "projectSensorsData/:projectId/setAsNoReference" }),
		SetAsNoZeroMeasurement: new Route({ path: "projectSensorsData/:projectId/setAsNoZeroMeasurement" }),
		ExportAsAgd: new Route({ path: "projectSensorsData/:projectId/exportAsAgd" }),
		ExportAsCsv: new Route({ path: "projectSensorsData/:projectId/exportAsCsv" }),
		GetSensorsHorizontalMovementVectors: new Route({ path: "projectSensorsData/:projectId/getSensorsHorizontalMovementVectors" }),
		GetImageSensorsHorizontalMovementVectors: new Route({ path: "projectSensorsData/:projectId/getImageSensorsHorizontalMovementVectors" }),
		GetSensorsVerticalMovementVectors: new Route({ path: "projectSensorsData/:projectId/getSensorsVerticalMovementVectors" }),
		GetImageSensorsVerticalMovementVectors: new Route({ path: "projectSensorsData/:projectId/getImageSensorsVerticalMovementVectors" }),
		GetSensorsHeightMovementVectors: new Route({ path: "projectSensorsData/:projectId/getSensorsHeightMovementVectors" }),
		GetImageSensorsHeightMovementVectors: new Route({ path: "projectSensorsData/:projectId/getImageSensorsHeightMovementVectors" }),
	};

	public ProjectsHistory = {
		GetCompanies: new Route({ path: "projectsHistory/companies" }),
		Fetch: new Route({ path: "projectsHistory/fetch" }),
		GetHistoryOfProperty: new Route({ path: "projectsHistory/:historyId/historyOfProperty/:propertyName" }),
		SaveProjectPropertyRecord: new Route({ path: "projectsHistory/savePropertyRecord" }),
	};

	public ProjectStatistic = {
		GetNoGeovisPackageProjectStatistic: new Route({ path: "projectStatistic/:projectId/getNoGeovisPackage" }),
		UpdateNoGeovisPackageProjectStatistic: new Route({ path: "projectStatistic/:projectId/updateNoGeovisPackage" }),
		UpdateProjectInvoiceTypeValues: new Route({ path: "projectStatistic/:projectId/updateProjectInvoiceTypeValues" }),
		UpdateProjectInvoiceRange: new Route({ path: "projectStatistic/:projectId/updateProjectInvoiceRange" }),
		GetWeeklyPdf: new Route({ path: "projectStatistic/:projectId/exportPdf/:firstWeek/to/:lastWeek" }),
		GetWeeklyXls: new Route({ path: "projectStatistic/:projectId/exportXls/:firstWeek/to/:lastWeek" }),
		RecollectNoGeovisPackageProjectStatistic: new Route({ path: "projectStatistic/:projectId/recollectNoGeovisPackage" }),
		GetGeovisPackageProjectStatistic: new Route({ path: "projectStatistic/:projectId/getGeovisPackage" }),
		UpdateGeovisPackageProjectStatistic: new Route({ path: "projectStatistic/:projectId/updateGeovisPackage" }),
	};

	public ProjectSupport = {
		GetSupportMessage: new Route({ path: "projectSupport/:projectId/message" }),
		GetTemplates: new Route({ path: "projectSupport/:projectId/templates" }),
		SetTemplateAsMessage: new Route({ path: "projectSupport/:projectId/setTemplateAsMessage/:templateId" }),
		SaveSupportTemplate: new Route({ path: "projectSupport/:projectId/saveTemplate" }),
		DeleteTemplate: new Route({ path: "projectSupport/:projectId/deleteTemplate/:templateId" }),
	};

	public ProjectView = {
		Get: new Route({ path: "projectView/:projectId/:viewId" }),
		Delete: new Route({ path: "projectView/:projectId/delete/:viewId" }),
		ChangePublic: new Route({ path: "projectView/:projectId/changePublic/:viewId" }),
		Create: new Route({ path: "projectView/:projectId/create" }),
		Save: new Route({ path: "projectView/:projectId/save" }),
		SaveOverview: new Route({ path: "projectView/:projectId/saveOverview" }),
		SetDefaultProjectView: new Route({ path: "projectView/:projectId/setDefaultProjectView/:viewId" }),
		SetDefaultViewMapData: new Route({ path: "projectView/:projectId/setDefaultViewMapData/:viewId" }),
		SetIsDefaultExpanded: new Route({ path: "projectView/:projectId/setIsDefaultExpanded/:viewId/:isDefaultExpanded" }),
		ResetMapViewport: new Route({ path: "projectView/:projectId/resetMapViewport/:viewId" }),
		UpdateUsersForView: new Route({ path: "projectView/:projectId/updateUsers/:viewId" }),
		SetViewsParentInfo: new Route({ path: "projectView/:projectId/setViewsParentInfo" }),
		GetProjectViewEditData: new Route({ path: "projectView/:projectId/loadEditData/:itemType" }),
	};

	public ProjectViews = {
		GetViewsConfig: new Route({ path: "projectViews/:projectId/configs" }),
		GetViewsInfo: new Route({ path: "projectViews/:projectId/info" }),
	};

	public ReportPdfRenderData = {
		GetPdfReportInitialState: new Route({ path: "reportPdfRenderData/:projectId/pdfReportInitialState/:reportId/user/:userId/:userHash/settings/:settingsId" }),
		GetPdfDefaultReportInitialState: new Route({ path: "reportPdfRenderData/:projectId/pdfDefaultReportInitialState/user/:userId/:userHash/settings/:settingsId" }),
		GetGeovisReportCustomSettings: new Route({ path: "reportPdfRenderData/:projectId/:userId/:userHash/getGeovisReportCustomSettings/:settingId" }),
		GetGeovisChartData: new Route({ path: "reportPdfRenderData/:projectId/:reportId/:userHash/:userId/geovisChartData" }),
		GetGeovisVibrationEventData: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/vibrationEventData" }),
		GetGeovisReportChartToPng: new Route({ path: "reportPdfRenderData/:projectId/geovisReportChartToPng/:reportId/settings/:settingsId/page/:pageNum/chart/:chartId/h/:height/w/:width/u/:userId/:userHash" }),
		GetHeaderInfo: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/headerInfo/:reportId" }),
		GetDtsSectionInfos: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/dtsSectionInfos/:reportId" }),
		GetGeovis4TableData: new Route({ path: "reportPdfRenderData/:projectId/:userToken/:userId/geovis4TableData/:reportId" }),
		GetTimeSearchDistanceReportData: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/getTimeSearchDistanceReportData" }),
		GetGeovisComment: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/geovisComment/:commentId" }),
		GetSensorsInfo: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/sensorsInfo" }),
		GetSensorsInfoByRequest: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/sensorsInfoByRequest" }),
		GetChainsInfoByIds: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/chainsInfoByIds" }),
		GetLastMeasurementsOfSensorIds: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/lastMeasurementsOfSensorIds" }),
		GetDXFLayerInfo: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/layerInfo/:layerId" }),
		GetGeoRastersInfo: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/geoRasters" }),
		GetGeoRastersOfViewInfo: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/geoRastersOfView/:viewId" }),
		GetRasterLayerInfo: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/rasterInfo/:layerId" }),
		GetGeoJSONLayers: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/geoJSONLayers" }),
		GetGeoJSONLayersOfView: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/ofView/:viewId" }),
		GetProfileViews: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/profileViews" }),
		GetSideViews: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/sideViews" }),
		GetSensorsSymbolSettings: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/getSensorsSymbolSettings" }),
		GetProjectVisualSettings: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/getProjectVisualSettings" }),
		GetViewsInfo: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/info" }),
		GetAttachedDocuments: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/attachedDocuments" }),
		GetLocalMapObjectsByIds: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/lmoByIds" }),
		GetLocalMapObjectsOfView: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/lmoOfView/:viewId" }),
		GetProjectViewLmoSensorsInfo: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/:viewId/lmoSensorsInfo" }),
		GetGeovisLogbookData: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/geovisLogbookData/:reportId" }),
		GetSensorsHorizontalMovementVectors: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/getSensorsHorizontalMovementVectors" }),
		GetImageSensorsHorizontalMovementVectors: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/getImageSensorsHorizontalMovementVectors/:geovisImageId" }),
		GetSensorsVerticalMovementVectors: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/getSensorsVerticalMovementVectors" }),
		GetSensorsHeightMovementVectors: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/getSensorsHeightMovementVectors" }),
		GetImageSensorsHeightMovementVectors: new Route({ path: "reportPdfRenderData/:projectId/:userHash/:userId/getImageSensorsHeightMovementVectors/:geovisImageId" }),
		GetServerInfo: new Route({ path: "reportPdfRenderData/:userHash/:userId/userInfo" }),
	};

	public ReportRenderData = {
		GetHeaderInfo: new Route({ path: "reportRenderData/:projectId/headerInfo/:reportId" }),
		GetGeovisChartCustomData: new Route({ path: "reportRenderData/:projectId/:reportId/chartCustomData" }),
		GetGeovisPreviewChartCustomData: new Route({ path: "reportRenderData/:projectId/previewChartCustomData" }),
		GetGeovisVibrationEventData: new Route({ path: "reportRenderData/:projectId/vibrationEventData" }),
		GetGeovisChartData: new Route({ path: "reportRenderData/:projectId/chartData/:reportId/:chartId" }),
		GetGeovisLogbookData: new Route({ path: "reportRenderData/:projectId/geovisLogbookData/:reportId/:logbookElementId" }),
		GetGeovisLogbookCustomData: new Route({ path: "reportRenderData/:projectId/geovisLogbookCustomData/:reportId" }),
		GetGeovis4TableData: new Route({ path: "reportRenderData/:projectId/geovis4TableData/:tableId" }),
		GetGeovis4TableCustomData: new Route({ path: "reportRenderData/:projectId/geovis4TableCustomData" }),
		GetTimeSearchReportData: new Route({ path: "reportRenderData/:projectId/getTimeSearchReportData" }),
	};

	public RestApi = {
		Login: new Route({ path: "rest/login" }),
		GetProjects: new Route({ path: "rest/projects" }),
		GetProject: new Route({ path: "rest/project/:projectId" }),
		GetSensors: new Route({ path: "rest/sensors/:projectId" }),
		GetSensorsData: new Route({ path: "rest/sensorsData/:projectId" }),
		GetSensorsProperties: new Route({ path: "rest/sensorsProperties/:projectId" }),
		Sync: new Route({ path: "rest/sync/:projectId" }),
		ChangeStates: new Route({ path: "rest/changeStates/:projectId" }),
		Delete: new Route({ path: "rest/delete/:projectId" }),
		GetListOfViews: new Route({ path: "rest/views/:projectId" }),
		GetViewInfo: new Route({ path: "rest/view/:projectId/:viewId" }),
		AddSensorsToView: new Route({ path: "rest/addSensorsToView/:projectId/:viewId" }),
		RemoveSensorsFromView: new Route({ path: "rest/removeSensorsFromView/:projectId/:viewId" }),
	};

	public SensorImageStream = {
		BackgroundStream: new Route({ path: "sensorImagesStream/:projectId/:sensorFullId/backgroundStream/:fileId/:isDirty/:sKey" }),
	};

	public SyncTimeslots = {
		GetSyncSensorsTimeslots: new Route({ path: "syncTimeslots/:projectId/syncSensorsTimeslots/:alias/fromBottom/:fromBottom/limit/:limit/page/:page" }),
		GetSyncChainsTimeslots: new Route({ path: "syncTimeslots/:projectId/syncChainsTimeslots/:alias/fromBottom/:fromBottom/limit/:limit/page/:page" }),
		RunSyncSensorsTimeslot: new Route({ path: "syncTimeslots/:projectId/runSyncSensorsTimeslot/:alias/timeslot/:timeslotId" }),
		RunSyncChainsTimeslot: new Route({ path: "syncTimeslots/:projectId/runSyncChainsTimeslot/:alias/timeslot/:timeslotId" }),
		RemoveSyncSensorsTimeslot: new Route({ path: "syncTimeslots/:projectId/removeSyncSensorsTimeslot/:alias/timeslot/:timeslotId" }),
		RemoveSyncChainsTimeslot: new Route({ path: "syncTimeslots/:projectId/removeSyncChainsTimeslot/:alias/timeslot/:timeslotId" }),
		RunAllTimeslots: new Route({ path: "syncTimeslots/:projectId/runAllTimeslots/:alias" }),
		DeleteAllTimeslots: new Route({ path: "syncTimeslots/:projectId/deleteAllTimeslots/:alias/timeslotType/:timeslotType" }),
		GetDisableDeleteSuccessTimeslots: new Route({ path: "syncTimeslots/:projectId/getDeleteSuccessTimeslots/:alias" }),
		SetDisableDeleteSuccessTimeslots: new Route({ path: "syncTimeslots/:projectId/setDeleteSuccessTimeslot/:alias/value/:value" }),
		GetProjectsSyncTimeslotsInfo: new Route({ path: "syncTimeslots/getProjectsSyncTimeslotsInfo" }),
		UpdateProjectInfo: new Route({ path: "syncTimeslots/updateProjectInfo/:projectId" }),
		CheckProjectFitRequirements: new Route({ path: "syncTimeslots/CheckProjectFitRequirements/:projectId" }),
		GetQueueInfo: new Route({ path: "syncTimeslots/queueInfo" }),
		CancelQueueTask: new Route({ path: "syncTimeslots/queue/task/:taskId/cancel" }),
		GetQueueHistory: new Route({ path: "syncTimeslots/queue/getHistory" }),
		GetSyncTimeslotsProcessingServiceConfig: new Route({ path: "syncTimeslots/service/getProcessingSettings" }),
		CreateTestSyncTimeslotsProcessingHistory: new Route({ path: "syncTimeslots/service/createTestSyncTimeslotsProcessingHistory" }),
		UpdateProcessingQueueSettingsProperty: new Route({ path: "syncTimeslots/server/updateProcessingQueueProperty" }),
		GetCleanupSyncTimeslotsServiceSettings: new Route({ path: "syncTimeslots/service/getCleanupSettings" }),
		UpdateCleanupSyncTimeslotsServiceSettingsProperty: new Route({ path: "syncTimeslots/service/updateCleanupSettingsProperty" }),
		RunCleanupSyncTimeslotsTask: new Route({ path: "syncTimeslots/service/runCleanupTask" }),
	};

	public Upload = {
		CreateWithFiles: new Route({ path: "upload/createWithFiles" }),
		Probe: new Route({ path: "chunk/probe" }),
		Chunk: new Route({ path: "chunk/:chunkHash" }),
		Chunks: new Route({ path: "upload/:uploadId/chunks" }),
		Upload: new Route({ path: "file/upload" }),
		Items: new Route({ path: "items" }),
		ProjectionFrom: new Route({ path: "file/:linkId/projectionFrom/:sourceCoordinateSystem" }),
		CancelUploadProgress: new Route({ path: "file/:fileId/cancelUploadProgress" }),
		DeleteUploadedFiles: new Route({ path: "file/deleteUploaded" }),
	};

	public Watchdog = {
		GetSettings: new Route({ path: "watchdog/get" }),
		SetSettings: new Route({ path: "watchdog/set" }),
	};
}

export default new ServerRoutes();

