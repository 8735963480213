import L, {
    DivIcon,
    Icon,
    IconOptions,
    latLng,
    LatLng
} from 'leaflet';
import { DefaultZoomLevel } from '../components/map/types';
import { getLocXYLocation, getSideViewCoordinates } from '../components/projectOverview/sensors/tools';
import { AlarmInfo } from '../server/AlarmInfo';
import { GEOvisDXFLayerType } from '../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType';
import { SensorMapTextOrientation } from '../server/AVTService/TypeLibrary/Common/SensorMapTextOrientation';
import { GeoJSONMapTilesLayerBounds } from '../server/AVTService/TypeLibrary/Model/GeoJSONMapTilesLayerBounds';
import { SensorCategory } from '../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { SensorSymbol } from '../server/AVTService/TypeLibrary/Sensors/SensorSymbol';
import { ChainInfo } from '../server/ChainInfo';
import { GeoLocation } from "../server/GeoLocation";
import { GeoPoint } from '../server/GeoPoint';
import { ProjectViewInfo } from '../server/GEOvis3/Model/ProjectViews/ProjectViewInfo';
import { SensorInfo } from '../server/GEOvis3/Model/SensorInfo';
import { ProjectInfo } from '../server/ProjectInfo';
import { SensorBase } from '../server/SensorBase';
import AuthService from '../services/AuthService';
import {
    IChainsInfoStorage,
    IGeovisProjectDataState,
    ISensorFilter,
    ISensorsInfoStorage
} from '../store/data.types';
import { IMapViewLayersVisibility, IMapViewSensorsLayerState } from '../store/types';
import { ambergTechnologiesCoordinates } from './Constants';
import { getProjectViewId } from './FiltersHelper';
import { getLocalMapObjectBounds } from './LocalMapObjectsHelper';
import { getFilterOfChainsFunc, getFilterOfSensorsFunc } from './ProjectViewsHelper';
import { getProjectRoleAllowedSensors, getSensorAlarmsForViewer } from './SensorHelper';
import { mapToListOfElements } from './StorageHelper';

/**
 * Minimal distance between sensors to group in Near sensors group (in pixels)
 */
// export const MinDistanceBetweenNearGeoPointsToGroup = 24;

/**
 * Min distance to add sensor in stacked sensors group (even coordinates a bit different)
 */
// export const MinDistanceBetweenStackedGeoPointsToGroup = 2;

export type MapItemObject = GeoPoint & {
    Id: string,
    Name: string,
    WatchdogEnabled: boolean;
    CausedAlarms: AlarmInfo[];
    Color: string;
    UseColorOnMap: boolean;
    MapTextOrientation: SensorMapTextOrientation;
}

export type MapSensorObject = MapItemObject & {
    PhysicalType: SensorCategory;
    Symbol: SensorSymbol;
};

export type GeovisMapIconType = DivIcon | Icon<IconOptions> | undefined;

export type SensorInfosCustomFilterFunc = (sensorInfo: SensorInfo) => boolean;
export type ChainInfosCustomFilterFunc = (chainInfo: ChainInfo) => boolean;

export interface IGroupNearSensorsOptions {
    groupNearSensors: boolean;
    radiusOfNearSensorsInPixels: number;
}

export function getMapCenter(location: GeoLocation): L.LatLng {

    // if (location && location.Latitude > 0 && location.Longitude > 0) {
    //     return latLng(location.Latitude, location.Longitude);
    // }

    if (!location || location && location.Latitude === 0 && location.Longitude === 0) {
        return ambergTechnologiesCoordinates();
    }

    return latLng(location.Latitude, location.Longitude);
}

export const hasZeroCoordinates = (l: GeoLocation): boolean => {
    return l.Height === 0 && l.Latitude === 0 && l.Longitude === 0;
}

export const locationToMapCoordinates = (l: GeoLocation): L.LatLng => {
    return latLng(l.Latitude, l.Longitude);
}

export const locationToLeafletPoint = (l: GeoLocation): L.Point => {
    return L.point(l.Latitude, l.Longitude);
}

export const latLngToLocation = (point: L.LatLng): GeoLocation => {
    return {
        Latitude: point.lat,
        Longitude: point.lng,
        Height: point.alt || 0
    };
}

export function fixLeafletMapBounds(bounds: L.LatLngBounds): L.LatLngBounds {
    const nw = bounds.getNorthWest();
    const se = bounds.getSouthEast();

    if (nw.lat === se.lat && nw.lng === se.lng) {
        nw.lat -= 0.00001;
        nw.lng -= 0.00001;

        se.lat += 0.00001;
        se.lng += 0.00001;

        const destBounds = L.latLngBounds(bounds.getSouthWest(), bounds.getNorthEast());

        destBounds.extend(nw);
        destBounds.extend(se);

        return destBounds;
    }

    return bounds;
}

export const latLngToPositionKey2d = (latLong: L.LatLng): string => {
    return `${latLong.lat}_${latLong.lng}`;
}

export const getGeoPointPositionKey2d = (pos: GeoLocation): string => {
    return `${pos.Latitude}_${pos.Longitude}`;
}

export const getGeopointsMapBounds = <TElement extends GeoPoint>(sensors: TElement[]): L.LatLngBounds => {
    const bounds = L.latLngBounds([]);

    if (sensors.length === 0) {
        return bounds;
    }

    sensors.map(s => {
        bounds.extend([s.Coordinates.Latitude, s.Coordinates.Longitude]);
    })

    return fixLeafletMapBounds(bounds);
}

export const getViewSensorLocation = (type: GEOvisDXFLayerType, sensor: SensorBase, invertXAxis: boolean, offsetsBounds?: GeoJSONMapTilesLayerBounds, leafletElement?: L.Map, deltaCoefficient?: number): LatLng => {
    switch (type) {
        case GEOvisDXFLayerType.Map: return geoLocationToLatLng(sensor.Coordinates, deltaCoefficient);
        case GEOvisDXFLayerType.ProfileView: return geoLocationToLatLng(getLocXYLocation(sensor, offsetsBounds, leafletElement));
        case GEOvisDXFLayerType.SideView: return geoLocationToLatLng(getSideViewCoordinates(sensor, invertXAxis, offsetsBounds));
        case GEOvisDXFLayerType.Nothing: return new LatLng(0, 0);
    }
}

export const getGeopointsMapBoundsDXFView = (sensors: SensorBase[], viewType: GEOvisDXFLayerType, invertXAxis: boolean, offsetsBounds?: GeoJSONMapTilesLayerBounds): L.LatLngBounds => {
    const bounds = L.latLngBounds([]);

    if (sensors.length === 0) {
        return bounds;
    }

    sensors.map(s => {
        const coord = getViewSensorLocation(viewType, s, invertXAxis, offsetsBounds);
        bounds.extend([coord.lat, coord.lng]);
    })

    return fixLeafletMapBounds(bounds);
}


export const isEmptyLocation2D = (location: GeoLocation): boolean => {
    if (!location) {
        return true;
    }

    return location.Latitude === 0 && location.Longitude === 0;
}

export const convertGeoLocationToString2D = (location: GeoLocation): string => {
    return `[${location.Latitude.toFixed(4)}, ${location.Longitude.toFixed(4)}]`;
}

export const geoLocationToLatLng = (location: GeoLocation, deltaCoefficient?: number): LatLng => {
    const coefficient = deltaCoefficient ?? 1;
    return L.latLng(location.Latitude * coefficient, location.Longitude * coefficient);
}

export const filterSensorsWithCoordinates = (sensors: SensorInfo[]): SensorInfo[] => {
    return sensors.filter(s => !isEmptyLocation2D(s.Coordinates));
}

export const filterChainsWithCoordinates = (chains: ChainInfo[]): ChainInfo[] => {
    return chains.filter(c => !isEmptyLocation2D(c.Coordinates));
}

export const isSensorWithCoordinates = (sensor: SensorInfo): boolean => {
    return !isEmptyLocation2D(sensor.Coordinates);
}

export const filterSensorsOfTypes = (sensors: SensorInfo[], visibleSensorTypes: Map<SensorCategory, boolean>): SensorInfo[] => {
    if (visibleSensorTypes.size === 0) {
        return sensors;
    }
    return sensors.filter(s => isSensorVisibleByType(s, visibleSensorTypes));
}

export const isSensorVisibleByType = (sensor: SensorInfo, visibleSensorTypes: Map<SensorCategory, boolean>): boolean => {
    if (visibleSensorTypes.has(sensor.PhysicalType)) {
        return visibleSensorTypes.get(sensor.PhysicalType) || false;
    }

    return true;
}

export const filterSensorsToDrawOnMap = (
    projectId: number,
    sensorsInfoStorage: ISensorsInfoStorage,
    layersVisibility: IMapViewLayersVisibility,
    sensorsLayerState: IMapViewSensorsLayerState,
    searchQuery: string | undefined,
    nonTextFilter: ISensorFilter,
    viewType?: GEOvisDXFLayerType,
    customFilter?: SensorInfosCustomFilterFunc): SensorInfo[] => {

    if (sensorsInfoStorage.isLoading) {
        return [];
    }

    if (!layersVisibility.showSensorsLayer) {
        return [];
    }

    const { visibleSensorTypes } = sensorsLayerState;
    const { sensorsInfo } = sensorsInfoStorage;
    const isPublicOnly = AuthService.isActualViewerOfProject(projectId);
    const filterSensorsFunc = getFilterOfSensorsFunc(searchQuery, isPublicOnly, nonTextFilter, AuthService.isNagraDistribution(), false, true, viewType, customFilter);
    const sensorsWithCoordinate = filterSensorsWithCoordinates(mapToListOfElements(sensorsInfo, filterSensorsFunc));
    const sensorsToDraw = filterSensorsOfTypes(sensorsWithCoordinate, visibleSensorTypes);

    const result = getProjectRoleAllowedSensors(projectId, sensorsToDraw);

    return result;
}

/**
 * Filter project chains to draw on the map
 * @param projectId 
 * @param chainsInfoStorage 
 * @param mapState 
 * @param mapViewportState 
 * @param view
 * @param searchQuery
 */
export const filterChainsToDrawOnMap = (
    projectId: number,
    chainsInfoStorage: IChainsInfoStorage,
    layersVisibility: IMapViewLayersVisibility,
    searchQuery: string | undefined,
    nonTextFilter: ISensorFilter,
    viewType?: GEOvisDXFLayerType,
    customFilter?: ChainInfosCustomFilterFunc
): ChainInfo[] => {

    if (chainsInfoStorage.isLoading) {
        return [];
    }

    if (!layersVisibility.showInclinometerChains) {
        return [];
    }
    const isPublicOnly = AuthService.isActualViewerOfProject(projectId);
    const filterChainsFunc = getFilterOfChainsFunc(searchQuery, isPublicOnly, nonTextFilter, viewType, customFilter);
    const result = filterChainsWithCoordinates(mapToListOfElements(chainsInfoStorage.chainsInfo, filterChainsFunc));

    return result.map(chain => ({ ...chain, CausedAlarms: getSensorAlarmsForViewer(projectId, chain.CausedAlarms) }));
}

export const getDefaultMapViewportState = (project: ProjectInfo): LatLng | undefined => {

    const { DefaultMapViewport, Location } = project;

    if (DefaultMapViewport && DefaultMapViewport.Latitude !== 0 && DefaultMapViewport.Longitude !== 0) {
        return L.latLng(DefaultMapViewport.Latitude, DefaultMapViewport.Longitude);
    }
    else {
        if (Location && Location.Latitude !== 0 && Location.Longitude !== 0) {
            return L.latLng(Location.Latitude, Location.Longitude);
        }
    }

    return undefined;
}
export const getDefaultMapViewportStateForView = (projectView: ProjectViewInfo): LatLng | undefined => {
    const { DefaultMapData } = projectView;
    if (DefaultMapData && DefaultMapData.Latitude !== 0 && DefaultMapData.Longitude !== 0) {
        return L.latLng(DefaultMapData.Latitude, DefaultMapData.Longitude);
    }

    return undefined;
}

/**
 * Get default map center
 * @param dataState
 */
export const getProjectOverviewMapCenter = ({ projectInfo, projectViewsStorage }: IGeovisProjectDataState): L.LatLng | false => {

    const viewId = projectViewsStorage.viewId || getProjectViewId(projectInfo, projectViewsStorage.projectViewsInfo);
    const view = projectViewsStorage.projectViewsInfo.get(viewId);

    if (!view) {
        // throw Error(`getProjectOverviewMapCenter: Incorrect behavior, view=${viewId} not found`);
        return false;
    }

    if (view.DefaultMapData) {
        const { Latitude, Longitude } = view.DefaultMapData;
        if (!isEmptyLocation2D({ Latitude, Longitude, Height: 0 })) {
            return L.latLng(Latitude, Longitude);
        }
    }

    const { project } = projectInfo;

    if (project.DefaultMapViewport) {
        const { Latitude, Longitude } = project.DefaultMapViewport;
        if (!isEmptyLocation2D({ Latitude, Longitude, Height: 0 })) {
            return L.latLng(Latitude, Longitude);
        }
    }

    // it means that project overview map center is not defined yet (fresh project)
    return false;
}

/**
 * Get default map zoom level
 * @param dataState
 */
export const getProjectOverviewMapZoom = ({ projectInfo, projectViewsStorage }: IGeovisProjectDataState): number => {

    const viewId = projectViewsStorage.viewId || getProjectViewId(projectInfo, projectViewsStorage.projectViewsInfo);
    const view = projectViewsStorage.projectViewsInfo.get(viewId);

    if (!view) {
        // throw Error(`getProjectOverviewMapZoom: incorrect behavior. view=${viewId} not found`);
        return 0;
    }

    if (view.DefaultMapData) {
        const { Latitude, Longitude, ZoomLevel } = view.DefaultMapData;

        if (!isEmptyLocation2D({ Latitude, Longitude, Height: 0 })) {
            return ZoomLevel;
        }
    }

    const { project } = projectInfo;

    if (project.DefaultMapViewport) {
        const { Latitude, Longitude, ZoomLevel } = project.DefaultMapViewport;
        if (!isEmptyLocation2D({ Latitude, Longitude, Height: 0 })) {
            return ZoomLevel;
        }
    }

    return DefaultZoomLevel;
}

/**
 * Calculate project 
 * @param dataState 
 */
export const calculateMapElementsBounds = (dataState: IGeovisProjectDataState): L.LatLngBounds => {

    let bounds = L.latLngBounds([]);

    const {
        projectInfo: { project },
        sensorsInfoStorage: { sensorsInfo },
        chainsInfoStorage: { chainsInfo },
        localMapObjectsDataStorage: { localMapObjects }
    } = dataState;

    const sensors = mapToListOfElements(sensorsInfo, m => isSensorWithCoordinates(m));

    if (sensors.length > 0 || chainsInfo.size > 0) {
        sensors.map(sensor => {
            bounds.extend([sensor.Coordinates.Latitude, sensor.Coordinates.Longitude]);
        })

        chainsInfo.forEach(chain => {
            bounds.extend([chain.Coordinates.Latitude, chain.Coordinates.Longitude]);
        })
    } else {
        bounds = L.latLngBounds([getMapCenter(project.Location)]);
    }

    // local map objects
    mapToListOfElements(localMapObjects).map(obj => {
        bounds.extend(getLocalMapObjectBounds(obj));
    });

    return bounds;
}