/**
 * Project edit actions
 */

// project edit Properties
export const PROJECT_EDIT_PROPERTIES_SAVING = "PROJECT_EDIT_PROPERTIES_SAVING";
export const PROJECT_EDIT_PROPERTIES_INIT_STATE = "PROJECT_EDIT_PROPERTIES_INIT_STATE";

// logo edit
export const LOGO_EDIT_SHOW_LOGO_PICKER = "LOGO_EDIT_SHOW_LOGO_PICKER";
export const LOGO_EDIT_HIDE_LOGO_PICKER = "LOGO_EDIT_HIDE_LOGO_PICKER";
export const LOGO_EDIT_SET_SELECTED_LOGO = "LOGO_EDIT_SET_SELECTED_LOGO";
export const LOGO_EDIT_SET_LOGO_TO_PROJECT = "LOGO_EDIT_SET_LOGO_TO_PROJECT";
export const LOGO_EDIT_UPLOAD_STARTED = "LOGO_EDIT_UPLOAD_STARTED";
export const LOGO_EDIT_UPLOAD_PROCESSING = "LOGO_EDIT_UPLOAD_PROCESSING";
export const LOGO_EDIT_UPLOAD_ERROR = "LOGO_EDIT_UPLOAD_ERROR";
export const LOGO_EDIT_UPLOAD_END = "LOGO_EDIT_UPLOAD_END";

// project edit DXF layers
export const PROJECT_EDIT_DXF_UPLOAD_FILES_STATE = "PROJECT_EDIT_DXF_UPLOAD_FILES_STATE";
export const PROJECT_EDIT_DXF_UPLOAD_DIALOG = "PROJECT_EDIT_DXF_UPLOAD_DIALOG";
export const PROJECT_EDIT_DXF_UPLOAD_DIALOG_TYPE = "PROJECT_EDIT_DXF_UPLOAD_DIALOG_TYPE";
export const PROJECT_EDIT_DXF_DELETE_DIALOG = "PROJECT_EDIT_DXF_DELETE_DIALOG";
export const PROJECT_EDIT_DXF_HIDE_DIALOGS = "PROJECT_EDIT_DXF_HIDE_DIALOGS";
export const PROJECT_EDIT_DXF_REBUILD_MAP_TILES_DIALOG = "PROJECT_EDIT_DXF_REBUILD_MAP_TILES_DIALOG";
export const PROJECT_EDIT_DXF_DELETING_FILE_MODEL = "PROJECT_EDIT_DXF_DELETING_FILE_MODEL";
export const PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MAX_ZOOM = "PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MAX_ZOOM";
export const PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MIN_ZOOM = "PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MIN_ZOOM";
export const PROJECT_EDIT_DXF_REBUILD_MAP_TILES_SEGMENT_LENGTH = "PROJECT_EDIT_DXF_REBUILD_MAP_TILES_SEGMENT_LENGTH";

// project edit Symbol settings

// project edit Local Map Objects
export const PROJECT_EDIT_LOCAL_MAP_OBJECTS_SET_VIEW = "PROJECT_EDIT_LOCAL_MAP_OBJECTS_SET_VIEW";
export const PROJECT_EDIT_LOCAL_MAP_OBJECTS_CLEANUP_EDIT_STATE = "PROJECT_EDIT_LOCAL_MAP_OBJECT_CLEANUP_EDIT_STATE";
export const PROJECT_EDIT_LOCAL_MAP_OBJECTS_SHOW_DELETE_DIALOG = "PROJECT_EDIT_LOCAL_MAP_OBJECT_SHOW_DELETE_DIALOG";
export const PROJECT_EDIT_LOCAL_MAP_OBJECTS_SEARCH_QUERY = "PROJECT_EDIT_LOCAL_MAP_OBJECTS_SEARCH_QUERY";
export const PROJECT_EDIT_LOCAL_MAP_OBJECTS_CHOOSE_COORDINATES_FOR = "PROJECT_EDIT_LOCAL_MAP_OBJECTS_CHOOSE_COORDINATES_FOR";
export const PROJECT_EDIT_LOCAL_MAP_OBJECTS_CREATE_NEW_ELEMENT = "PROJECT_EDIT_LOCAL_MAP_OBJECTS_CREATE_NEW_ELEMENT";
export const PROJECT_EDIT_LOCAL_MAP_OBJECTS_EDIT = "PROJECT_EDIT_LOCAL_MAP_OBJECTS_EDIT";
export const PROJECT_EDIT_LOCAL_MAP_OBJECT_START_EDITING_OBJECTS = "PROJECT_EDIT_LOCAL_MAP_OBJECT_START_EDITING_OBJECTS";
export const PROJECT_EDIT_LOCAL_MAP_OBJECT_STOP_EDITING_OBJECTS = "PROJECT_EDIT_LOCAL_MAP_OBJECT_STOP_EDITING_OBJECTS";

export const PROJECT_EDIT_LOCAL_MAP_OBJECT_CHANGE_IS_PUBLIC = "PROJECT_EDIT_LOCAL_MAP_OBJECT_CHANGE_IS_PUBLIC";
export const PROJECT_EDIT_LOCAL_MAP_OBJECT_ADD = "PROJECT_EDIT_LOCAL_MAP_OBJECT_ADD";
export const PROJECT_EDIT_LOCAL_MAP_OBJECT_REMOVE = "PROJECT_EDIT_LOCAL_MAP_OBJECT_REMOVE";
export const PROJECT_EDIT_LOCAL_MAP_OBJECT_UPDATE = "PROJECT_EDIT_LOCAL_MAP_OBJECT_UPDATE";

// project edit Attached Documents
export const PROJECT_EDIT_ATTACHED_DOCUMENT_DELETE_DIALOG = "PROJECT_EDIT_ATTACHED_DOCUMENTS_DELETE_DIALOG";
export const PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_DIALOG = "PROJECT_EDIT_ATTACHED_DOCUMENTS_HIDE_DIALOG";

export const PROJECT_EDIT_ATTACHED_DOCUMENT_SHOW_SENSORS_SELECT_DIALOG = "PROJECT_EDIT_ATTACHED_DOCUMENT_SHOW_SENSORS_SELECT_DIALOG";
export const PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_SENSORS_SELECT_DIALOG = "PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_SENSORS_SELECT_DIALOG";

export const PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_START = "PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_START";
export const PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_END = "PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_END";
export const PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_ERROR = "PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_ERROR";
export const PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_PROCESSING = "PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_PROCESSING";
export const PROJECT_EDIT_ATTACHED_DOCUMENT_CLEANUP_UPLOADING_PROGRESS = "PROJECT_EDIT_ATTACHED_DOCUMENT_CLEANUP_UPLOADING_PROGRESS";

export const PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_START = "PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_START";
export const PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_END = "PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_END";

export const PROJECT_EDIT_ATTACHED_DOCUMENTS_SWITCH_ROWS_PER_PAGE = "PROJECT_EDIT_ATTACHED_DOCUMENTS_SWITCH_ROWS_PER_PAGE";

// project views
export const PROJECT_EDIT_VIEW_CREATE = "PROJECT_EDIT_VIEW_CREATE";
export const PROJECT_EDIT_VIEW_DELETE_DIALOG = "PROJECT_EDIT_VIEW_DELETE_DIALOG";
export const PROJECT_EDIT_VIEWS_HIDE_DIALOGS = "PROJECT_EDIT_VIEWS_HIDE_DIALOGS";
export const PROJECT_EDIT_VIEW = "PROJECT_EDIT_VIEW";

// access control
export const PROJECT_EDIT_ACCESS_CONTROL_SHOW_USERS_DIALOG = 'PROJECT_EDIT_ACCESS_CONTROL_SHOW_USERS_DIALOG';
export const PROJECT_EDIT_ACCESS_CONTROL_HIDE_USERS_DIALOG = 'PROJECT_EDIT_ACCESS_CONTROL_HIDE_USERS_DIALOG';
export const PROJECT_EDIT_ACCESS_CONTROL_SHOW_USER_DIALOG = 'PROJECT_EDIT_ACCESS_CONTROL_SHOW_USER_DIALOG';
export const PROJECT_EDIT_ACCESS_CONTROL_HIDE_USER_DIALOG = 'PROJECT_EDIT_ACCESS_CONTROL_HIDE_USER_DIALOG';
export const PROJECT_EDIT_ACCESS_CONTROL_SHOW_EXPORT_USERS_DIALOG = 'PROJECT_EDIT_ACCESS_CONTROL_SHOW_EXPORT_USERS_DIALOG';
export const PROJECT_EDIT_ACCESS_CONTROL_HIDE_EXPORT_USERS_DIALOG = 'PROJECT_EDIT_ACCESS_CONTROL_HIDE_EXPORT_USERS_DIALOG';

// project views configs
export const PROJECT_EDIT_VIEWS_CONFIG = "PROJECT_EDIT_VIEWS_CONFIG";
export const PROJECT_EDIT_VIEWS_CONFIG_DATA = "PROJECT_EDIT_VIEWS_CONFIG_DATA";
export const PROJECT_EDIT_VIEWS_CONFIG_ERROR = "PROJECT_EDIT_VIEWS_CONFIG_ERROR";

export const PROJECT_EDIT_VIEW_DELETE = "PROJECT_EDIT_VIEW_DELETE";
export const PROJECT_EDIT_VIEW_CHANGE_PUBLIC = "PROJECT_EDIT_VIEW_CHANGE_PUBLIC";
export const PROJECT_EDIT_VIEW_ADD_OR_UPDATE = "PROJECT_EDIT_VIEW_ADD_OR_UPDATE";
export const PROJECT_EDIT_VIEW_SET_AS_DEFAULT = "PROJECT_EDIT_VIEW_SET_AS_DEFAULT";
export const PROJECT_EDIT_VIEW_SET_IS_DEFAULT_EXPANDED = 'PROJECT_EDIT_VIEW_SET_IS_DEFAULT_EXPANDED';
export const PROJECT_EDIT_VIEW_UPDATE_PARENT_INFO = 'PROJECT_EDIT_VIEW_UPDATE_PARENT_INFO';
export const PROJECT_EDIT_VIEW_CHANGE_USERS = "PROJECT_EDIT_VIEW_CHANGE_USERS";
export const PROJECT_EDIT_OVERVIEW_CHANGED = "PROJECT_EDIT_OVERVIEW_CHANGED";

//#region Label edit actions

export const PROJECT_EDIT_LABELS_SHOW = 'PROJECT_EDIT_LABELS_SHOW';
export const PROJECT_EDIT_LABELS_HIDE = 'PROJECT_EDIT_LABELS_HIDE';
export const PROJECT_EDIT_LABELS_EDIT_SHOW = 'PROJECT_EDIT_LABELS_EDIT_SHOW';
export const PROJECT_EDIT_LABELS_EDIT_HIDE = 'PROJECT_EDIT_LABELS_EDIT_HIDE';
export const PROJECT_EDIT_LABELS_DELETE_SHOW = 'PROJECT_EDIT_LABELS_DELETE_SHOW';
export const PROJECT_EDIT_LABELS_DELETE_HIDE = 'PROJECT_EDIT_LABELS_DELETE_HIDE';

//#endregion

//#region Databases edit actions

export const PROJECT_EDIT_EDIT_DATABASE_SHOW = 'PROJECT_EDIT_EDIT_DATABASE_SHOW';
export const PROJECT_EDIT_EDIT_DATABASE_HIDE = 'PROJECT_EDIT_EDIT_DATABASE_HIDE';
export const PROJECT_EDIT_REMOVE_DATABASE_SHOW = 'PROJECT_EDIT_REMOVE_DATABASE_SHOW';
export const PROJECT_EDIT_REMOVE_DATABASE_HIDE = 'PROJECT_EDIT_REMOVE_DATABASE_HIDE';

//#endregion

//#region Project remove

export const PROJECT_EDIT_REMOVE_PROJECT_SHOW = 'PROJECT_EDIT_REMOVE_PROJECT_SHOW';
export const PROJECT_EDIT_REMOVE_PROJECT_HIDE = 'PROJECT_EDIT_REMOVE_PROJECT_HIDE';
export const PROJECT_EDIT_REMOVE_PROJECT = 'PROJECT_EDIT_REMOVE_PROJECT';

//#endregion

//#region 

export const PROJECT_EDIT_SENSORS_DATA_FILTER_CHANGED = 'PROJECT_EDIT_SENSORS_DATA_FILTER_CHANGED';
export const PROJECT_EDIT_SENSORS_DATA_LOADING = 'PROJECT_EDIT_SENSORS_DATA_LOADING';
export const PROJECT_EDIT_SENSORS_DATA_LOADED = 'PROJECT_EDIT_SENSORS_DATA_LOADED';
export const PROJECT_EDIT_SENSORS_DATA_EDIT_RECORD = 'PROJECT_EDIT_SENSORS_DATA_EDIT_RECORD';
export const PROJECT_EDIT_SENSORS_DATA_HIDE_EDIT_DIALOG = 'PROJECT_EDIT_SENSORS_DATA_HIDE_EDIT_DIALOG';
export const PROJECT_EDIT_SENSORS_DATA_UPDATE_RECORD = 'PROJECT_EDIT_SENSORS_DATA_UPDATE_RECORD';
export const PROJECT_EDIT_SENSORS_DATA_REMOVE_RECORD = 'PROJECT_EDIT_SENSORS_DATA_REMOVE_RECORD';
export const PROJECT_EDIT_SENSORS_DATA_RELOAD = 'PROJECT_EDIT_SENSORS_DATA_RELOAD';
export const PROJECT_EDIT_SENSORS_DATA_DATABASES_SELECTED = 'PROJECT_EDIT_SENSORS_DATA_DATABASES_SELECTED';
export const PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION_OF_ALL = 'PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION_OF_ALL';
export const PROJECT_EDIT_SENSORS_DATA_CHANGE_SORT = 'PROJECT_EDIT_SENSORS_DATA_CHANGE_SORT';
export const PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION = 'PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION';
export const PROJECT_EDIT_SENSORS_DATA_SHOW_REMOVE_ATTRIBUTES_DIALOG = "PROJECT_EDIT_SENSORS_DATA_SHOW_REMOVE_ATTRIBUTES_DIALOG";
export const PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG = "PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG";
export const PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG_AND_RELOAD = "PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG_AND_RELOAD";

//#endregion

//#region Reports

export const PROJECT_EDIT_REPORT_ADD_OR_UPDATE = "PROJECT_EDIT_REPORT_ADD_OR_UPDATE";
export const PROJECT_EDIT_REPORT_SHOW_REMOVE_DIALOG = "PROJECT_EDIT_REPORT_SHOW_REMOVE_DIALOG";
export const PROJECT_EDIT_REPORT_SHOW_COPY_DIALOG = "PROJECT_EDIT_REPORT_SHOW_COPY_DIALOG";
export const PROJECT_EDIT_REPORT_HIDE_ANY_DIALOG = "PROJECT_EDIT_REPORT_HIDE_ANY_DIALOG";
export const PROJECT_EDIT_REPORT_REMOVED = "PROJECT_EDIT_REPORT_REMOVED";
export const PROJECT_EDIT_REPORT_ELEMENTS_LOADING = "PROJECT_EDIT_REPORT_ELEMENTS_LOADING";
export const PROJECT_EDIT_REPORT_ELEMENTS_LOADED = "PROJECT_EDIT_REPORT_ELEMENTS_LOADED";
export const PROJECT_EDIT_REPORT_UPDATE_ELEMENT_ENTRY = "PROJECT_EDIT_REPORT_UPDATE_ELEMENT_ENTRY";
export const PROJECT_EDIT_REPORT_ADD_TO_GROUPS_DIALOG_SHOW = "PROJECT_EDIT_REPORT_ADD_TO_GROUPS_DIALOG_SHOW";

//#endregion

// #region Project edit actions

export const PROJECT_ELEMENT_COPY_DIALOG_SHOW = "PROJECT_ELEMENT_COPY_DIALOG_SHOW";
export const PROJECT_ELEMENT_REMOVE_DIALOG_SHOW = 'PROJECT_ELEMENT_REMOVE_DIALOG_SHOW';
export const PROJECT_ELEMENT_HIDE_ELEMENT_DIALOG = "PROJECT_ELEMENT_HIDE_ELEMENT_DIALOG";

export const PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_SHOW = "PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_SHOW";
export const PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_HIDE = "PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_HIDE";

export const PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_SHOW = "PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_SHOW";
export const PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_HIDE = "PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_HIDE";

export const PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_SHOW = "PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_SHOW";
export const PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_HIDE = "PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_HIDE";

// #endregion


// #region Project edit actions

export const PROJECT_EDIT_GEORASTER_HIDE_DIALOGS = "PROJECT_EDIT_GEORASTER_HIDE_DIALOGS";
export const PROJECT_EDIT_GEORASTER_SHOW_REMOVE_DIALOG = "PROJECT_EDIT_GEORASTER_SHOW_REMOVE_DIALOG";
export const PROJECT_EDIT_GEORASTER_CHANGE_UPLOAD_STATE = "PROJECT_EDIT_GEORASTER_CHANGE_UPLOAD_STATE";

// #endregion

// #region Project edit sensors

// sensors
export const PROJECT_EDIT_SENSORS_LIST_LOADING = "PROJECT_EDIT_SENSORS_LIST_LOADING";
export const PROJECT_EDIT_SENSORS_LIST_LOADED = "PROJECT_EDIT_SENSORS_LIST_LOADED";

export const PROJECT_EDIT_SENSORS_LIST_SENSOR_ADDED = "PROJECT_EDIT_SENSORS_LIST_SENSOR_ADDED";
export const PROJECT_EDIT_SENSORS_LIST_SENSOR_UPDATED = "PROJECT_EDIT_SENSORS_LIST_SENSOR_UPDATED";
export const PROJECT_EDIT_SENSORS_LIST_SENSOR_REMOVED = "PROJECT_EDIT_SENSORS_LIST_SENSOR_REMOVED";

export const PROJECT_EDIT_SENSORS_LIST_FILTER_CHANGED = "PROJECT_EDIT_SENSORS_LIST_FILTER_CHANGED";
export const PROJECT_EDIT_SENSORS_LIST_FILTER_LOADING = "PROJECT_EDIT_SENSORS_LIST_FILTER_LOADING";
export const PROJECT_EDIT_SENSORS_LIST_FILTER_LOADED = "PROJECT_EDIT_SENSORS_LIST_FILTER_LOADED";

export const PROJECT_EDIT_SENSORS_MANUAL_IMPORT_RELOAD = "PROJECT_EDIT_SENSORS_MANUAL_IMPORT_RELOAD";

//#endregion