//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ElementHeightType {
	Smallest = 0,
	Autofit = 1,
	Custom = 2
}

const ElementHeightTypeToName = new Map<number, string>([
	[ 0, 'Smallest' ],
	[ 1, 'Autofit' ],
	[ 2, 'Custom' ],
])

export function getElementHeightTypeToName(element: ElementHeightType) : string {
    return ElementHeightTypeToName.get(element) || '';
}

const ElementHeightTypeByName = new Map<string, number>([
	[ 'Smallest', 0 ],
	[ 'Autofit', 1 ],
	[ 'Custom', 2 ],
]);

export function getElementHeightTypeByName(name: string): ElementHeightType {
    return ElementHeightTypeByName.get(name)!;
}


export const ElementHeightTypeList : Readonly<ElementHeightType[]> = [
	ElementHeightType.Smallest,
	ElementHeightType.Autofit,
	ElementHeightType.Custom,
];



const ElementHeightTypeToDescription = new Map<number, string>([
	[ 0, 'Smallest' ],
	[ 1, 'Autofit' ],
	[ 2, 'Custom' ],
])

export function getElementHeightTypeToDescription(element: ElementHeightType) : string {
    return ElementHeightTypeToDescription.get(element) || '';
}

