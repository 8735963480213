/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 30.06.2020
 * @description Project overview report data reducer
 */

import { cloneDeep } from "lodash";
import { Reducer } from "redux";
import {
    legacy_fillSensorsForCharts,
    chartSensorsDialog_fillSensorsForGeovisElements,
    initGeovisReportPageSelectionInfo,
    legacy_initReportSensorsSelection,
    initGeovisReportPageSelectionInfoFromElements
} from "../../components/projectOverview/reportOverlay/tools";
import { IReportSensorsSelectionInfoLegacy } from "../../components/projectOverview/reportOverlay/types";
import { compareDates, formattedDateTime } from "../../helpers/DateHelper";
import { elementsConvertToDestMap, elementsToMap, elementsToMapOfCustomKey, elementsToMapWithConverted } from "../../helpers/StorageHelper";
import { ChartType } from "../../server/AVTService/TypeLibrary/Common/ChartType";
import { Geovis4TableData } from "../../server/AVTService/TypeLibrary/Computation/Geovis4TableData";
import { GeovisChartData } from "../../server/AVTService/TypeLibrary/Computation/GeovisChartData";
import { AxisScaleLimit } from "../../server/AVTService/TypeLibrary/Model/AxisScaleLimit";
import { ChartAxisSettings } from "../../server/AVTService/TypeLibrary/Model/ChartAxisSettings";
import { ChartModel } from "../../server/AVTService/TypeLibrary/Model/ChartModel";
import { CustomerReportSettingsModel } from "../../server/AVTService/TypeLibrary/Model/CustomerReportSettingsModel";
import { Geovis4Table } from "../../server/AVTService/TypeLibrary/Model/Geovis4Tables/Geovis4Table";
import { GeovisChartModel } from "../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { GeovisReportModel } from "../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportModel";
import {
    PROJECT_DEFAULT_REPORT_GEOVIS_LOADED,
    PROJECT_GEOVIS_REPORT_CHART_DATA_LOADED,
    PROJECT_GEOVIS_REPORT_CHART_DATA_LOADING,
    PROJECT_GEOVIS_REPORT_CHART_REDRAW,
    PROJECT_GEOVIS_REPORT_DEFAULT_SET_REPORT_SETTINGS,
    PROJECT_GEOVIS_REPORT_RESET_REPORT_SETTINGS,
    PROJECT_GEOVIS_REPORT_SERVICE_DATA_CHANGED,
    PROJECT_GEOVIS_REPORT_SET_CHART_DIRTY_SETTINGS,
    PROJECT_GEOVIS_REPORT_SET_REPORT_SETTINGS,
    PROJECT_GEOVIS_REPORT_TABLE_LOADED,
    PROJECT_GEOVIS_REPORT_TABLE_LOADING,
    PROJECT_REPORT_APPLY_CUSTOM_SETTINGS,
    PROJECT_REPORT_APPLY_CUSTOMER_PROPERTIES,
    PROJECT_REPORT_CHART_UPDATE_TIMES,
    PROJECT_REPORT_CLEAN_INVALID_PROPERTIES,
    PROJECT_REPORT_CLEAR_CUSTOMER_PROPERTIES,
    PROJECT_REPORT_CUSTOMER_SETTINGS_DIRTY,
    PROJECT_REPORT_DEFAULT_SET_VALIDATION_RESULT,
    PROJECT_REPORT_GEOVIS_LOADED,
    PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTIES,
    PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTY,
    PROJECT_REPORT_LOADED,
    PROJECT_REPORT_LOADING,
    PROJECT_REPORT_PDF_LOADED,
    PROJECT_REPORT_PDF_START_LOADING,
    PROJECT_REPORT_PNG_LOADED,
    PROJECT_REPORT_PNG_START_LOADING,
    PROJECT_REPORT_SENSOR_DIALOG,
    PROJECT_GEOVIS_REPORT_RELOAD_DATA_WITHOUT_BLINK,
    PROJECT_REPORT_MAP_SECTION_DATA_LOADING,
    PROJECT_REPORT_MAP_SECTION_DATA_LOADED,
    PROJECT_REPORT_UPDATE_LEGACY_CHART_SENSORS_SELECTION_INFO,
    PROJECT_REPORT_UPDATE_GEOVIS_ELEMENTS_SENSORS_SELECTION_INFO,
    PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADED,
    PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADING,
    PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_SHOW,
    PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_HIDE,
    PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADING,
    PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADED
} from "../actions/projectReportActions";
import { GEOVIS_PROJECT_REPORT_PDF_INITIAL_STATE } from "../actions/projectReportActions.pdf";
import { processFetchedData } from "../helpers/DataHelper";
import {
    getGeovisReportDistinctElements,
    processHighlighInvalidProperties,
    processHighlightInvalidProperty
} from "../helpers/ProjectReportDataHelper";
import {
    IGeovis4TableReportInfo,
    IGeovisChartReportData,
    IGeovisChartReportInfo,
    IGeovisImageReportData,
    IGeovisImageReportInfo,
    IGeovisLogbookReportData,
    IGeovisLogbookReportInfo,
    IGeovisMapSectionReportData,
    IGeovisMapSectionReportInfo,
    IGeovisProjectReportAction,
    IGEOvisProjectReportState,
    IGeovisReportImageSelectionInfo,
    IGeovisReportMapSectionSelectionInfo,
    IGeovisReportPageConfig,
    IGeovisReportPageData,
    IGeovisVibrationEventSlimInfo,
    IReportChartSensorsSelectionInfo
} from "../projectReport.types";
import { IGeovisProjectReportPdfAction } from "../projectReport.types.pdf";
import { defaultSomethingStorageState, errorSomethingStorageState, ISomethingStorageBaseEx, loadedSomethingStorageState } from "../types";
import { HeatmapChartModel } from "../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel";
import { GeovisMapSectionReportInfo } from "../../server/AVTService/TypeLibrary/Model/MapSection/GeovisMapSectionReportInfo";
import {
    chainsInfoStorageInitialState,
    chainsLastDataStorageInitialState,
    sensorsInfoStorageInitialState,
    sensorsLastDataStorageInitialState
} from "./dataReducer";
import { getLoadedStorage } from "../../helpers/ProjectDataHelper";
import { GeovisLogbookModel } from "../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisLogbookModel";

const geovisChartDataInitialState: IGeovisChartReportData<GeovisChartData> = {
    ...defaultSomethingStorageState,
    data: { ...new GeovisChartData() },
    dtsSectionInfos: [],
    Timestamp: 0,
    isLoading: false
}

export const geovis4TableDataInitialState: ISomethingStorageBaseEx<Geovis4TableData> = {
    ...defaultSomethingStorageState,
    data: { ...new Geovis4TableData() }
}

const getGeovisReportPageDataInitialState = (): IGeovisReportPageData => ({
    geovisChartsData: new Map<number, IGeovisChartReportData<GeovisChartData>>(),
    geovisEventChartsData: new Map<number, IGeovisChartReportData<GeovisChartData>>(),
    geovis4TablesData: new Map<number, ISomethingStorageBaseEx<Geovis4TableData>>(),
    geovisMapSectionsData: new Map<number, IGeovisMapSectionReportData>(),
    geovisImageData: new Map<number, IGeovisImageReportData>(),
    geovisLogbookData: new Map<number, IGeovisLogbookReportData>()
})

/**
 * Map section data initial state
 */
export const geovisMapSectionReportDataInitialState: IGeovisMapSectionReportData = {
    ...defaultSomethingStorageState,
    GlobalTimeslot: '',
    chainsInfoStorage: chainsInfoStorageInitialState,
    sensorsInfoStorage: sensorsInfoStorageInitialState,
    sensorsLastDataStorage: sensorsLastDataStorageInitialState,
    chainsLastDataStorage: chainsLastDataStorageInitialState
}

export const geovisImageReportDataInitialState: IGeovisImageReportData = {
    ...defaultSomethingStorageState,
    chainsInfoStorage: chainsInfoStorageInitialState,
    sensorsInfoStorage: sensorsInfoStorageInitialState,
    sensorsLastDataStorage: sensorsLastDataStorageInitialState,
    chainsLastDataStorage: chainsLastDataStorageInitialState
}

export const geovisLogbookReportDataInitialState: IGeovisLogbookReportData = {
    ...defaultSomethingStorageState,
    geovisLogbookDataModel: {
        Model: new GeovisLogbookModel(),
        Data: []
    }
}

/**
 * Initial project report state
 */
export const projectReportInitialState: IGEOvisProjectReportState = {
    ...defaultSomethingStorageState,
    legacy_chartSensorDetails: {},
    invalidPropertiesInfo: [],
    isDirty: false,
    isInProgress: false,
    geovisReportSettings: {
        isDefaultChartView: false,
        isDefaultReportView: false,
        detailedReportInfo: { ...new GeovisReportModel() },
        geovisPages: new Map<number, IGeovisReportPageConfig>(),
        geovisPagesData: new Map<number, IGeovisReportPageData>(),
        originalChartConfigs: [],
        originalMapSections: [],
        originalTables: [],
        originalGeovisImages: [],
        originalGeovisLogbooks: [],
        dtsSectionCloseTSensorIdsMap: {},
        allAvailableSensorsFullIds: []
    },
    legacy_reportSettings: {
        activeVibrationEvents: [],
        changedChartsIds: [],
        charts: [],
        chartzoomSettings: [],
        defaultReportTitle: '',
        geovisTables: [],
        isSelectedSensorsDefaultReportView: false,
        isSensorDefaultReportView: false,
        lastUpdateTime: '',
        sensorsByChartRelations: {},
        projectId: 0,
        reportId: 0,
        reportLanguageCode: '',
        sensorFullIds: [],
        sensorsValidationRequired: false,
        settingsId: '',
        timeSlot: '',
        userId: '',
        dtsSectionCloseTSensorIdsMap: {}
    },
    sensorsPropertyId: '',
    legacy_sensorsSelectionInfo: {
        charts: new Map<number, IReportChartSensorsSelectionInfo>()
    },
    visualSettings: {
        chartAxisLabelFontSize: '12px',
        chartLineWidth: '2px',
        negativeHeightBarColor: "#91bfdb",
        positiveHeightBarColor: "#fc8d59",
        movementVectorColor: "#FF0000"
    },
    showSensorsList: false,
    reportSettingsKey: 0,
    reportPagesInfo: [],
    endReportDate: '',
    pagesToReportPage: new Map<number, number>(),
    startReportDate: ''
};

const getValidAxisScaleLimit = (scaleLimit: AxisScaleLimit): AxisScaleLimit => {
    return { ...scaleLimit, value: +scaleLimit.value };
}

const getValidChartAxisSettings = (settings: ChartAxisSettings): ChartAxisSettings => {
    return {
        ...settings,
        minScaleLimit: getValidAxisScaleLimit(settings.minScaleLimit),
        maxScaleLimit: getValidAxisScaleLimit(settings.maxScaleLimit)
    };
}

const getValidCharts = (charts: ChartModel[]): ChartModel[] => {
    const result: ChartModel[] = [];


    for (const ch of charts) {
        result.push({
            ...ch,
            numberOfMeasurements: +ch.numberOfMeasurements,
            unitAAxisSettings: getValidChartAxisSettings(ch.unitAAxisSettings),
            unitBAxisSettings: getValidChartAxisSettings(ch.unitBAxisSettings)
        });
    }

    return result;
}

/*const getValidGeovisCharts = (charts: GeovisChartModel[]): GeovisChartModel[] => {
    const result: GeovisChartModel[] = [];
    if (!charts) {
        return result;
    }
    for (const ch of charts) {
        result.push({
            ...ch,
            LeftYAxisSettings: {
                ...ch.LeftYAxisSettings,
                minScaleLimit: getValidAxisScaleLimit(ch.LeftYAxisSettings.minScaleLimit),
                maxScaleLimit: getValidAxisScaleLimit(ch.LeftYAxisSettings.maxScaleLimit)
            },
            RightYAxisSettings: {
                ...ch.RightYAxisSettings,
                minScaleLimit: getValidAxisScaleLimit(ch.RightYAxisSettings.minScaleLimit),
                maxScaleLimit: getValidAxisScaleLimit(ch.RightYAxisSettings.maxScaleLimit)
            },
        });
    }

    return result;
}*/

const changeSensorsSelections = (customReportSettings: CustomerReportSettingsModel, originalSelection: IReportSensorsSelectionInfoLegacy): IReportSensorsSelectionInfoLegacy => {

    if (customReportSettings.charts) {
        customReportSettings.charts.forEach(ch => {
            const chartSensorSelection = originalSelection.charts.get(ch.id);
            if (chartSensorSelection) {
                chartSensorSelection.unitASensors.forEach((value, key) => {
                    chartSensorSelection.unitASensors.set(key, ch.unitASensorIds.includes(key));
                });
                chartSensorSelection.unitBSensors.forEach((value, key) => {
                    chartSensorSelection.unitBSensors.set(key, ch.unitBSensorIds.includes(key));
                });
            }
        });
    }

    return originalSelection;
}

const getActualChartConfig = (chart: GeovisChartModel, chartData: GeovisChartData): GeovisChartModel => {
    if (chart.Type === ChartType.HeatMap && chart.UseLastMeasurementTime) {
        const heatmap = chart as HeatmapChartModel;
        heatmap.Timeslot.Value = chartData.TimeSlotTo;
        return heatmap;
    }
    return chart;
}

/**
 * Project report state reducer
 * @param state 
 * @param action 
 */
const projectReportReducer: Reducer<IGEOvisProjectReportState> = (
    state: IGEOvisProjectReportState = projectReportInitialState,
    action: IGeovisProjectReportAction): IGEOvisProjectReportState => {

    switch (action.type) {
        case PROJECT_REPORT_LOADING:
            return {
                ...projectReportInitialState,
                isLoaded: false,
                isLoading: true
            };

        case PROJECT_REPORT_LOADED: {
            if (!action.reportSettings) {
                break;
            }

            if (!action.reportSettings.Success) {

                return {
                    ...state,
                    ...errorSomethingStorageState(...action.reportSettings.Messages),
                }
            }

            const { serviceSettings, sensorDetails, visualSettings } = action.reportSettings.Data;

            const updState: IGEOvisProjectReportState = {
                ...projectReportInitialState,
                ...loadedSomethingStorageState,
                reportSettingsKey: Date.now(),
                legacy_sensorsSelectionInfo: legacy_initReportSensorsSelection(serviceSettings),
                visualSettings,
                legacy_chartSensorDetails: sensorDetails,
                legacy_reportSettings: serviceSettings
            }

            return updState;
        }

        /**
         * Geovis report elements configurations has been loaded
         * First put elements to the report configuration
         */
        case PROJECT_REPORT_GEOVIS_LOADED: {

            const { geovisReportSettings, reportElementsChanges } = action;

            if (!geovisReportSettings) {
                return state;
            }

            const {
                changedChartsIds,
                changedGeovis4TableIds,
                changedMapSectionIds,
                changedPageGeovisImagesIds,
                changedPageGeovisLogbookIds,
                originalCharts,
                originalGeovis4Tables,
                originalMapSections,
                originalGeovisImages,
                originalGeovisLogbooks
            } = reportElementsChanges || {
                changedChartsIds: [],
                changedGeovis4TableIds: [],
                changedMapSectionIds: [],
                originalGeovisImages: [],
                originalGeovisLogbooks: []
            };

            if (!geovisReportSettings.Success) {
                return {
                    ...state,
                    ...errorSomethingStorageState(...geovisReportSettings.Messages)
                };
            }

            // these distinct report elements needs only to remember original configurations
            // in other cases should be used

            const distinctCharts = getGeovisReportDistinctElements(geovisReportSettings.Data.Pages, p => p.GeovisCharts);
            const originalChartConfigs = originalCharts && originalCharts.length > 0 ? cloneDeep(originalCharts) : cloneDeep(distinctCharts);

            const distinctMapSections = getGeovisReportDistinctElements(geovisReportSettings.Data.Pages, p => p.MapSectionsInfo);
            const originalMapSectionsConfig = originalMapSections && originalMapSections.length > 0 ? cloneDeep(originalMapSections) : cloneDeep(distinctMapSections);

            const distinctTables = getGeovisReportDistinctElements(geovisReportSettings.Data.Pages, p => p.Tables);
            const originalGeovis4TablesConfig = originalGeovis4Tables && originalGeovis4Tables.length > 0 ? cloneDeep(originalGeovis4Tables) : cloneDeep(distinctTables);

            const distinctGeovisImages = getGeovisReportDistinctElements(geovisReportSettings.Data.Pages, p => p.GeovisImages);
            const originalGeovisImagesConfig = originalGeovisImages && originalGeovisImages.length > 0 ? cloneDeep(originalGeovisImages) : cloneDeep(distinctGeovisImages);

            const distinctGeovisLogbooks = getGeovisReportDistinctElements(geovisReportSettings.Data.Pages, p => p.GeovisLogbooks);
            const originalGeovisLogbooksConfig = originalGeovisLogbooks && originalGeovisLogbooks.length > 0 ? cloneDeep(originalGeovisLogbooks) : cloneDeep(distinctGeovisLogbooks);

            const geovisPages = elementsToMapWithConverted(geovisReportSettings.Data.Pages, page => [page.PageNum, {
                geovisCharts: elementsToMapWithConverted(page.GeovisCharts, chart => [chart.Id, {
                    Chart: chart,
                    IsDirty: isReportElementDirty(page.PageNum, chart.Id, changedChartsIds),
                    Timestamp: new Date().getTime(),
                    SuppressLoadingState: false
                }]),
                mapSections: elementsToMapWithConverted<GeovisMapSectionReportInfo, number, IGeovisMapSectionReportInfo>(page.MapSectionsInfo, mapSectionInfo => [mapSectionInfo.Id, {
                    MapSection: mapSectionInfo.MapSection,
                    IsDirty: isReportElementDirty(page.PageNum, mapSectionInfo.Id, changedMapSectionIds),
                    Timestamp: new Date().getTime(),
                    DxfLayerType: mapSectionInfo.LayerType,
                    DxfMapTileLayerBounds: mapSectionInfo.LayerBounds,
                    InvertXAxis: mapSectionInfo.InvertXAxis
                }]),
                showEventChart: new Map<number, IGeovisVibrationEventSlimInfo>(),
                tables: elementsToMapWithConverted(page.Tables, table => [table.Id, {
                    Table: table,
                    IsDirty: isReportElementDirty(page.PageNum, table.Id, changedGeovis4TableIds),
                    Timestamp: new Date().getTime()
                }]),
                geovisImages: elementsToMapWithConverted(page.GeovisImages, image => [image.Id, {
                    GeovisImage: image,
                    IsDirty: isGeovisImageDirty(page.PageNum, image.Id, changedPageGeovisImagesIds),
                    Timestamp: new Date().getTime()
                }]),
                geovisLogbooks: elementsToMapWithConverted(page.GeovisLogbooks, logbook => [logbook.Id, {
                    GeovisLogbook: logbook,
                    IsDirty: isReportElementDirty(page.PageNum, logbook.Id, changedPageGeovisLogbookIds),
                    Timestamp: new Date().getTime()
                }]),
                selectionInfo: initGeovisReportPageSelectionInfo(page, geovisReportSettings.Data.DtsSectionCloseTSensorIdsMap, reportElementsChanges)
            }]);

            return {
                ...state,
                ...loadedSomethingStorageState,
                geovisReportSettings: {
                    isDefaultChartView: geovisReportSettings.Data.IsDefaultChartView,
                    isDefaultReportView: geovisReportSettings.Data.IsDefaultReportView,
                    detailedReportInfo: geovisReportSettings.Data.Report,
                    geovisPages: geovisPages,
                    originalChartConfigs,
                    originalMapSections: originalMapSectionsConfig,
                    originalTables: originalGeovis4TablesConfig,
                    originalGeovisImages: originalGeovisImagesConfig,
                    originalGeovisLogbooks: originalGeovisLogbooksConfig,
                    dtsSectionCloseTSensorIdsMap: geovisReportSettings.Data.DtsSectionCloseTSensorIdsMap,
                    geovisPagesData: elementsToMapWithConverted(geovisReportSettings.Data.Pages, page => [page.PageNum, {
                        geovisChartsData: elementsConvertToDestMap<GeovisChartModel, number, IGeovisChartReportData<GeovisChartData>>(page.GeovisCharts, chart => [chart.Id, {
                            ...defaultSomethingStorageState,
                            isLoading: false,
                            dtsSectionInfos: [],
                            Timestamp: new Date().getTime(),
                            data: { ...new GeovisChartData() },
                        }]),
                        geovisEventChartsData: new Map<number, IGeovisChartReportData<GeovisChartData>>(),
                        geovis4TablesData: new Map<number, ISomethingStorageBaseEx<Geovis4TableData>>(),
                        geovisMapSectionsData: new Map<number, IGeovisMapSectionReportData>(),
                        geovisImageData: new Map<number, IGeovisImageReportData>(),
                        geovisLogbookData: new Map<number, IGeovisLogbookReportData>()
                    }]),
                    allAvailableSensorsFullIds: []
                }
                // legacy_sensorsSelectionInfo: initGeovisReportSensorsSelection(originalChartConfigs, distinctCharts, geovisReportSettings.Data.DtsSectionCloseTSensorIdsMap),
                // mapSectionSensorsSelectionInfo: initMapSectionSensorsSelection(originalMapSectionsConfig, distinctMapSections),
                // geovisImageSensorsSelectionInfo: initGeovisImageSensorsSelection(originalGeovisImagesConfig, distinctGeovisImages)
            }
        }

        /**
         * Default report loaded
         * The same as for Geovis report, first put elements to the report configuration
         */
        case PROJECT_DEFAULT_REPORT_GEOVIS_LOADED: {

            const { geovisDefaultReportSettings } = action;

            if (!geovisDefaultReportSettings) {
                return state;
            }

            if (!geovisDefaultReportSettings.Success) {
                return {
                    ...state,
                    ...errorSomethingStorageState(...geovisDefaultReportSettings.Messages)
                };
            }

            /*
            const distinctCharts = getGeovisReportDistinctElements(geovisDefaultReportSettings.Data.Pages, p => p.GeovisCharts);
            
            const distinctMapSections = getGeovisReportDistinctElements(geovisDefaultReportSettings.Data.Pages, p => p.MapSectionsInfo);
            const distinctGeovisImages = getGeovisReportDistinctElements(geovisDefaultReportSettings.Data.Pages, p => p.GeovisImages);

            const sensorsSelectionInfo = initGeovisReportSensorsSelection(distinctCharts, distinctCharts, geovisDefaultReportSettings.Data.DtsSectionCloseTSensorIdsMap);
            */

            return {
                ...state,
                ...loadedSomethingStorageState,
                geovisReportSettings: {
                    isDefaultChartView: geovisDefaultReportSettings.Data.IsDefaultChartView,
                    isDefaultReportView: geovisDefaultReportSettings.Data.IsDefaultReportView,
                    detailedReportInfo: geovisDefaultReportSettings.Data.Report,
                    geovisPages: elementsToMapWithConverted(geovisDefaultReportSettings.Data.Pages, page => [page.PageNum, {
                        geovisCharts: elementsToMapWithConverted(page.GeovisCharts, chart => [chart.Id, {
                            Chart: chart,
                            IsDirty: false,
                            Timestamp: new Date().getTime(),
                            SuppressLoadingState: false
                        }]),
                        mapSections: elementsToMapWithConverted<GeovisMapSectionReportInfo, number, IGeovisMapSectionReportInfo>(page.MapSectionsInfo, mapSectionInfo => [mapSectionInfo.Id, {
                            MapSection: mapSectionInfo.MapSection,
                            IsDirty: false,
                            Timestamp: new Date().getTime(),
                            DxfLayerType: mapSectionInfo.LayerType,
                            DxfMapTileLayerBounds: mapSectionInfo.LayerBounds,
                            InvertXAxis: mapSectionInfo.InvertXAxis
                        }]),
                        showEventChart: new Map<number, IGeovisVibrationEventSlimInfo>(),
                        tables: elementsToMapWithConverted<Geovis4Table, number, IGeovis4TableReportInfo>(page.Tables, table => [table.Id, {
                            Table: table,
                            IsDirty: false,
                            Timestamp: new Date().getTime()
                        }]),
                        geovisImages: elementsToMapWithConverted(page.GeovisImages, image => [image.Id, {
                            GeovisImage: image,
                            IsDirty: false,
                            Timestamp: new Date().getTime()
                        }]),
                        geovisLogbooks: elementsToMapWithConverted(page.GeovisLogbooks, logbook => [logbook.Id, {
                            GeovisLogbook: logbook,
                            IsDirty: false,
                            Timestamp: new Date().getTime()
                        }]),
                        selectionInfo: initGeovisReportPageSelectionInfo(page, geovisDefaultReportSettings.Data.DtsSectionCloseTSensorIdsMap),
                    }]),
                    dtsSectionCloseTSensorIdsMap: geovisDefaultReportSettings.Data.DtsSectionCloseTSensorIdsMap,
                    geovisPagesData: elementsToMapWithConverted(geovisDefaultReportSettings.Data.Pages, page => [page.PageNum, {
                        geovisChartsData: elementsConvertToDestMap<GeovisChartModel, number, IGeovisChartReportData<GeovisChartData>>(page.GeovisCharts, chart => [chart.Id, {
                            ...defaultSomethingStorageState,
                            isLoading: false,
                            dtsSectionInfos: [],
                            Timestamp: new Date().getTime(),
                            data: { ...new GeovisChartData() },
                        }]),
                        geovisEventChartsData: new Map<number, IGeovisChartReportData<GeovisChartData>>(),
                        geovis4TablesData: new Map<number, ISomethingStorageBaseEx<Geovis4TableData>>(),
                        geovisMapSectionsData: new Map<number, IGeovisMapSectionReportData>(),
                        geovisImageData: new Map<number, IGeovisImageReportData>(),
                        geovisLogbookData: new Map<number, IGeovisLogbookReportData>()
                    }]),
                    originalChartConfigs: [],
                    originalMapSections: [],
                    originalTables: [],
                    originalGeovisImages: [],
                    originalGeovisLogbooks: [],
                    allAvailableSensorsFullIds: geovisDefaultReportSettings.Data.AvailableFullIds
                },
                // legacy_sensorsSelectionInfo: initGeovisReportSensorsSelection(distinctCharts, distinctCharts, geovisDefaultReportSettings.Data.DtsSectionCloseTSensorIdsMap)
            }
        }

        /**
         * Special action for Legacy report
         */
        case PROJECT_REPORT_UPDATE_LEGACY_CHART_SENSORS_SELECTION_INFO: {

            const { legacyChartChangedIds, legacySensorsSelectionInfo } = action;

            if (!legacyChartChangedIds || !legacySensorsSelectionInfo) {
                return state;
            }

            return {
                ...state,
                legacy_sensorsSelectionInfo: legacySensorsSelectionInfo,
                showSensorsList: false,
                sensorsPropertyId: '',
                isDirty: true,
                legacy_reportSettings: {
                    ...state.legacy_reportSettings,
                    changedChartsIds: legacyChartChangedIds,
                    charts: legacy_fillSensorsForCharts(state.legacy_reportSettings.charts, legacySensorsSelectionInfo)
                }
            };
        }

        /**
         * Calls when need to update sensors selection info
         */
        case PROJECT_REPORT_UPDATE_GEOVIS_ELEMENTS_SENSORS_SELECTION_INFO: {

            const { geovisSensorsSelectionInfo, reportElementsChanges } = action;

            if (!geovisSensorsSelectionInfo || !reportElementsChanges) {
                return state;
            }

            const originalChartConfigsMap = elementsToMap<number, GeovisChartModel>(state.geovisReportSettings.originalChartConfigs);

            return {
                ...state,
                showSensorsList: false,
                sensorsPropertyId: '',
                isDirty: true,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPages: chartSensorsDialog_fillSensorsForGeovisElements(state.geovisReportSettings.geovisPages, originalChartConfigsMap, geovisSensorsSelectionInfo, reportElementsChanges)
                }
            };
        }

        /**
         * Set map section data to loading state
         */
        case PROJECT_REPORT_MAP_SECTION_DATA_LOADING: {

            const { pageNum, numberValue: sectionId } = action;
            if (pageNum === undefined || sectionId === undefined) {
                return state;
            }

            const pageData = state.geovisReportSettings.geovisPagesData.get(pageNum);
            if (!pageData) {
                return state;
            }

            const sectionInfo = pageData.geovisMapSectionsData.get(sectionId);
            if (!sectionInfo) {
                return state;
            }

            // https://ai7d.atlassian.net/browse/AGMS-4625
            // disable visual reload chains and sensors on the map section
            // it leads to incorrect rendering during reload configurations
            // this DISABLE looks like "silent" reload, changes will appears immediately, without disappears and appears again

            // pageData.geovisMapSectionsData.set(sectionId, { ...geovisMapSectionReportDataInitialState });
            pageData.geovisMapSectionsData.set(sectionId, {
                ...sectionInfo,
                // ...geovisMapSectionReportDataInitialState
                chainsLastDataStorage: geovisMapSectionReportDataInitialState.chainsLastDataStorage,
                sensorsLastDataStorage: geovisMapSectionReportDataInitialState.sensorsLastDataStorage
            });

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings
                }
            }
        }

        /**
         * Set map section data to loaded state, to storage
         */
        case PROJECT_REPORT_MAP_SECTION_DATA_LOADED: {

            const { pageNum, numberValue: sectionId, timeSearchData } = action;
            if (pageNum === undefined || sectionId === undefined || !timeSearchData) {
                return state;
            }

            // update map section timeslot in the configuration
            {
                const pageConfig = state.geovisReportSettings.geovisPages.get(pageNum);
                if (!pageConfig) {
                    return state;
                }

                const mapSectionInfo = pageConfig.mapSections.get(sectionId);
                if (!mapSectionInfo) {
                    return state;
                }

                const timeslotInfo = mapSectionInfo.MapSection.TimeslotSetting;

                mapSectionInfo.MapSection.TimeslotSetting = { ...timeslotInfo, Value: timeSearchData.Data.GlobalTimeslot };
            }

            // update data
            {
                let pageData = state.geovisReportSettings.geovisPagesData.get(pageNum);
                if (!pageData) {
                    pageData = getGeovisReportPageDataInitialState();

                    state.geovisReportSettings.geovisPagesData.set(pageNum, pageData);
                }

                const originalSectionData = pageData.geovisMapSectionsData.get(sectionId) || geovisMapSectionReportDataInitialState;

                pageData.geovisMapSectionsData.set(sectionId, processFetchedData(timeSearchData, originalSectionData, originalSectionData, st => ({
                    GlobalTimeslot: st.GlobalTimeslot,
                    sensorsInfoStorage: processFetchedData(st.SensorsInfo, originalSectionData.sensorsInfoStorage, sensorsInfoStorageInitialState, si => ({ sensorsInfo: elementsToMap(si) })),
                    chainsInfoStorage: processFetchedData(st.ChainsInfo, originalSectionData.chainsInfoStorage, chainsInfoStorageInitialState, ci => ({ chainsInfo: elementsToMap(ci) })),
                    chainsLastDataStorage: processFetchedData(st.ChainMeasurementInfo, originalSectionData.chainsLastDataStorage, chainsLastDataStorageInitialState, sm => ({ chainsLastMeasurements: elementsToMapOfCustomKey(sm, l => l.FullId) })),
                    sensorsLastDataStorage: processFetchedData(st.SensorMeasurementList, originalSectionData.sensorsLastDataStorage, sensorsLastDataStorageInitialState, sm => ({ sensorsLastMeasurements: elementsToMap(sm) }))
                })));
            }

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings
                }
            }
        }

        /**
         * Geovis image loading state
         */
        case PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADING: {
            const { pageNum, elementId } = action;
            if (pageNum === undefined || elementId === undefined) {
                return state;
            }

            const pageData = state.geovisReportSettings.geovisPagesData.get(pageNum);
            if (!pageData) {
                return state;
            }

            pageData.geovisImageData.set(elementId, { ...geovisImageReportDataInitialState });

            return {
                ...state,
                geovisReportSettings: { ...state.geovisReportSettings }
            }
        }

        /**
         * Geovis image loaded data
         */
        case PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADED: {
            const { pageNum, elementId, timeSearchData } = action;
            if (pageNum === undefined || elementId === undefined || !timeSearchData) {
                return state;
            }

            let dataPage = state.geovisReportSettings.geovisPagesData.get(pageNum);

            if (!dataPage) {
                dataPage = getGeovisReportPageDataInitialState();

                state.geovisReportSettings.geovisPagesData.set(pageNum, dataPage);
            }

            const originalImageData = dataPage.geovisImageData.get(elementId) || geovisImageReportDataInitialState;

            dataPage.geovisImageData.set(elementId, processFetchedData(timeSearchData, originalImageData, geovisImageReportDataInitialState, st => ({
                sensorsInfoStorage: processFetchedData(st.SensorsInfo, originalImageData.sensorsInfoStorage, sensorsInfoStorageInitialState, si => ({ sensorsInfo: elementsToMap(si) })),
                chainsInfoStorage: processFetchedData(st.ChainsInfo, originalImageData.chainsInfoStorage, chainsInfoStorageInitialState, ci => ({ chainsInfo: elementsToMap(ci) })),
                sensorsLastDataStorage: processFetchedData(st.SensorMeasurementList, originalImageData.sensorsLastDataStorage, sensorsLastDataStorageInitialState, sm => ({ sensorsLastMeasurements: elementsToMap(sm) })),
                chainsLastDataStorage: getLoadedStorage(chainsLastDataStorageInitialState)
            })));

            return {
                ...state,
                geovisReportSettings: { ...state.geovisReportSettings }
            };
        }

        case PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADING: {
            const { pageNum, elementId } = action;
            if (pageNum === undefined || elementId === undefined) {
                return state;
            }

            let pageData = state.geovisReportSettings.geovisPagesData.get(pageNum);
            if (!pageData) {
                pageData = getGeovisReportPageDataInitialState();
                state.geovisReportSettings.geovisPagesData.set(pageNum, pageData);
            }

            pageData.geovisLogbookData.set(elementId, geovisLogbookReportDataInitialState);

            return {
                ...state,
                geovisReportSettings: { ...state.geovisReportSettings }
            }
        }

        /**
         * Geovis logbook loaded data
         */
        case PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADED: {
            const { elementId: logbookId, pageNum, geovisLogbookData } = action;

            if (!logbookId || pageNum === undefined || !geovisLogbookData) {
                return state;
            }

            let dataPage = state.geovisReportSettings.geovisPagesData.get(pageNum);
            if (!dataPage) {
                dataPage = getGeovisReportPageDataInitialState();
                state.geovisReportSettings.geovisPagesData.set(pageNum, dataPage);
            }

            const logbookDataState = processFetchedData(geovisLogbookData, geovisLogbookReportDataInitialState, geovisLogbookReportDataInitialState, st => ({
                geovisLogbookDataModel: st
            }));

            dataPage.geovisLogbookData.set(logbookId, logbookDataState);

            const configPage = state.geovisReportSettings.geovisPages.get(pageNum);
            if (configPage) {
                const logbookInfo = configPage.geovisLogbooks.get(logbookId);
                if (logbookInfo) {
                    configPage.geovisLogbooks.set(logbookId, {
                        ...logbookInfo,
                        GeovisLogbook: logbookDataState.geovisLogbookDataModel.Model
                    })
                }
            }

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings
                }
            }
        }

        case PROJECT_REPORT_SENSOR_DIALOG:
            {
                if (action.showSensorsList === undefined || action.sensorsPropertyId === undefined) {
                    break;
                }

                return {
                    ...state,
                    showSensorsList: action.showSensorsList,
                    sensorsPropertyId: action.sensorsPropertyId
                };
            }

        case PROJECT_REPORT_CUSTOMER_SETTINGS_DIRTY: {
            if (!action.customerReportSettings) {
                break;
            }

            return {
                ...state,
                legacy_reportSettings: {
                    ...action.customerReportSettings,
                    charts: getValidCharts(action.customerReportSettings.charts)
                },
                legacy_sensorsSelectionInfo: changeSensorsSelections(action.customerReportSettings, state.legacy_sensorsSelectionInfo),
                isDirty: true,
                isLoaded: true,
                isLoading: false
            };
        }

        case PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTY: {
            if (!action.propertyId || !action.errorMessage) {
                break;
            }

            const invalidPropertiesInfo = processHighlightInvalidProperty(state.invalidPropertiesInfo, action.propertyId, action.errorMessage);
            if (invalidPropertiesInfo) {
                return { ...state, invalidPropertiesInfo };
            }

            break;
        }

        case PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTIES: {
            if (!action.invalidPropertiesInfo) {
                break;
            }

            const invalidPropertiesInfo = processHighlighInvalidProperties(state.invalidPropertiesInfo, action.invalidPropertiesInfo);
            if (invalidPropertiesInfo) {
                return { ...state, invalidPropertiesInfo };
            }

            break;
        }

        case PROJECT_REPORT_CLEAN_INVALID_PROPERTIES:
            return { ...state, invalidPropertiesInfo: [] };

        case PROJECT_REPORT_APPLY_CUSTOM_SETTINGS: {
            if (!action.customerReportSettings) {
                return state;
            }

            return {
                ...state,
                legacy_reportSettings: action.customerReportSettings,
                isDirty: true
            }
        }

        case PROJECT_REPORT_APPLY_CUSTOMER_PROPERTIES: {
            return { ...state, legacy_reportSettings: { ...state.legacy_reportSettings, changedChartsIds: [] } };
        }

        case PROJECT_REPORT_CLEAR_CUSTOMER_PROPERTIES: {
            return { ...state, legacy_reportSettings: { ...state.legacy_reportSettings, changedChartsIds: [] } };
        }

        case PROJECT_REPORT_PNG_LOADED: {
            return { ...state, pdfDownloading: false };
        }
        case PROJECT_REPORT_PNG_START_LOADING: {
            return { ...state, pdfDownloading: true };
        }
        case PROJECT_REPORT_PDF_LOADED: {
            return { ...state, pdfDownloading: false };
        }
        case PROJECT_REPORT_PDF_START_LOADING: {
            return { ...state, pdfDownloading: true };
        }

        case PROJECT_REPORT_CHART_UPDATE_TIMES: {
            if (!action.updTimesInfo) {
                return state;
            }
            const updCharts = state.legacy_reportSettings.charts;

            updCharts.forEach(ch => {
                if (action.updTimesInfo && ch.id === +action.updTimesInfo.id) {
                    ch.startDate = formattedDateTime(action.updTimesInfo.start, "yyyy/MM/DD HH:mm");
                    ch.endDate = formattedDateTime(action.updTimesInfo.end, "yyyy/MM/DD HH:mm");
                }
            });

            return {
                ...state,
                legacy_reportSettings: {
                    ...state.legacy_reportSettings,
                    charts: updCharts
                }
            }
        }

        case PROJECT_REPORT_DEFAULT_SET_VALIDATION_RESULT: {
            if (!action.invalidProperties) {
                return state;
            }

            return {
                ...state,
                invalidPropertiesInfo: action.invalidProperties
            }
        }

        case PROJECT_GEOVIS_REPORT_DEFAULT_SET_REPORT_SETTINGS: {
            if (!action.defaultReportSettings || !action.sensorDetails) {
                return state;
            }

            const updGeovisPages = new Map<number, IGeovisReportPageConfig>();
            action.defaultReportSettings.geovisPages.forEach((page, pageNum) => {

                const updPage: IGeovisReportPageConfig = {
                    geovisCharts: new Map<number, IGeovisChartReportInfo<GeovisChartModel>>(),
                    mapSections: new Map<number, IGeovisMapSectionReportInfo>(),
                    showEventChart: new Map<number, IGeovisVibrationEventSlimInfo>(),
                    tables: new Map<number, IGeovis4TableReportInfo>(),
                    geovisImages: new Map<number, IGeovisImageReportInfo>(),
                    geovisLogbooks: new Map<number, IGeovisLogbookReportInfo>(),
                    selectionInfo: {
                        geovisCharts: new Map<number, IReportChartSensorsSelectionInfo>(),
                        geovisImageInfo: new Map<number, IGeovisReportImageSelectionInfo>(),
                        mapSectionInfo: new Map<number, IGeovisReportMapSectionSelectionInfo>()
                    }
                };

                page.geovisCharts.forEach(chartStruct => {

                    updPage.geovisCharts.set(chartStruct.Chart.Id, {
                        Chart: chartStruct.Chart,
                        IsDirty: true,
                        Timestamp: new Date().getTime(),
                        SuppressLoadingState: false
                    })
                });

                updGeovisPages.set(pageNum, updPage);
            });

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPages: updGeovisPages
                },
                legacy_chartSensorDetails: action.sensorDetails,
                isLoading: false,
                isLoaded: true,
                isError: false
            }
        }

        case PROJECT_GEOVIS_REPORT_SET_CHART_DIRTY_SETTINGS: {
            const { geovisChart, pageNum } = action;
            if (!geovisChart || pageNum === undefined) {
                return state;
            }

            const page = state.geovisReportSettings.geovisPages.get(pageNum);
            if (!page) {
                return state;
            }

            const chart = page.geovisCharts.get(geovisChart.Id);

            if (!chart) {
                return state;
            }

            // the chart and page should be updated, because that maps were passed by reference, but not by value
            page.geovisCharts.set(geovisChart.Id, {
                IsDirty: true,
                Chart: geovisChart,
                Timestamp: new Date().getTime(),
                SuppressLoadingState: false
            });

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPages: state.geovisReportSettings.geovisPages
                }
            }
        }

        case PROJECT_GEOVIS_REPORT_SET_REPORT_SETTINGS: {
            const { changedGeovisChartPages } = action;
            if (!changedGeovisChartPages) {
                return state;
            }

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPages: changedGeovisChartPages
                }
            }
        }

        case PROJECT_GEOVIS_REPORT_RESET_REPORT_SETTINGS: {

            const clearPages = new Map<number, IGeovisReportPageConfig>();

            const originalConfigs = state.geovisReportSettings.originalChartConfigs;
            const pages = state.geovisReportSettings.geovisPages;
            // go by pages
            pages.forEach((page, pageNum) => {

                const clearCharts = new Map<number, IGeovisChartReportInfo<GeovisChartModel>>();

                // go by charts
                page.geovisCharts.forEach((chartInfo, chartId) => {
                    const originalConfig = originalConfigs.find(c => c.Id === chartId);
                    if (originalConfig) {

                        clearCharts.set(chartId, {
                            Chart: { ...originalConfig },
                            IsDirty: false,
                            Timestamp: new Date().getTime(),
                            SuppressLoadingState: false
                        });

                    } else {
                        clearCharts.set(chartId, chartInfo);
                    }
                });

                // TODO: clear map sections of the page

                // save page charts configs
                clearPages.set(pageNum, {
                    geovisCharts: clearCharts,
                    mapSections: page.mapSections,
                    showEventChart: new Map<number, IGeovisVibrationEventSlimInfo>(),
                    tables: page.tables,
                    geovisImages: page.geovisImages,
                    geovisLogbooks: page.geovisLogbooks,
                    selectionInfo: initGeovisReportPageSelectionInfoFromElements(page, clearCharts, state.geovisReportSettings.dtsSectionCloseTSensorIdsMap)
                });
            });

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPages: clearPages
                }
            }
        }

        /**
         * Report chart data loading event
         */
        case PROJECT_GEOVIS_REPORT_CHART_DATA_LOADING: {
            const { numberValue: chartId, pageNum, booleanValue } = action;
            if (!chartId || pageNum === undefined || pageNum < 0) {
                return state;
            }

            let dataPage = state.geovisReportSettings.geovisPagesData.get(pageNum);
            if (!dataPage) {
                dataPage = getGeovisReportPageDataInitialState();

                state.geovisReportSettings.geovisPagesData.set(pageNum, dataPage);
            }

            if (booleanValue) {
                dataPage.geovisEventChartsData.set(chartId, { ...geovisChartDataInitialState, isLoading: true });
            }
            else {
                dataPage.geovisChartsData.set(chartId, { ...geovisChartDataInitialState, isLoading: true });
            }


            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPagesData: state.geovisReportSettings.geovisPagesData
                }
            }
        }

        case PROJECT_GEOVIS_REPORT_CHART_DATA_LOADED: {

            const { numberValue: chartId, geovisChartData, dtsSectionData, pageNum, booleanValue } = action;
            if (!chartId || !geovisChartData || pageNum === undefined) {
                return state;
            }

            let dataPage = state.geovisReportSettings.geovisPagesData.get(pageNum);
            if (!dataPage) {
                dataPage = {
                    geovisChartsData: new Map<number, IGeovisChartReportData<GeovisChartData>>(),
                    geovisEventChartsData: new Map<number, IGeovisChartReportData<GeovisChartData>>(),
                    geovis4TablesData: new Map<number, ISomethingStorageBaseEx<Geovis4TableData>>(),
                    geovisMapSectionsData: new Map<number, IGeovisMapSectionReportData>(),
                    geovisImageData: new Map<number, IGeovisImageReportData>(),
                    geovisLogbookData: new Map<number, IGeovisLogbookReportData>()
                };

                state.geovisReportSettings.geovisPagesData.set(pageNum, dataPage);
            }

            // process error state
            if (!geovisChartData.Success) {
                if (booleanValue) {
                    dataPage.geovisEventChartsData.set(chartId, {
                        ...geovisChartDataInitialState,
                        ...errorSomethingStorageState(...geovisChartData.Messages)
                    })
                }
                else {
                    dataPage.geovisChartsData.set(chartId, {
                        ...geovisChartDataInitialState,
                        ...errorSomethingStorageState(...geovisChartData.Messages)
                    })
                }


                return state;
            }

            const configPage = state.geovisReportSettings.geovisPages.get(pageNum);
            if (!configPage) {
                return state;
            }
            const chartInfo = configPage.geovisCharts.get(chartId);

            if (!chartInfo) {
                return state;
            }

            let dtsSectionInfos = geovisChartDataInitialState.dtsSectionInfos;

            // check that DTS section loaded
            if (chartInfo.Chart.Type === ChartType.DtsChart) {

                if (!dtsSectionData) {
                    dataPage.geovisChartsData.set(chartId, {
                        ...geovisChartDataInitialState,
                        ...errorSomethingStorageState("DTS Section info not delivered")
                    });

                    return state;
                } else if (!dtsSectionData.Success) {
                    dataPage.geovisChartsData.set(chartId, {
                        ...geovisChartDataInitialState,
                        ...errorSomethingStorageState(...dtsSectionData.Messages)
                    });

                    return state;
                }

                // save dts section info for DTS chart
                dtsSectionInfos = dtsSectionData.Data;
            }

            const { SlimChartConfig } = geovisChartData.Data;
            const { Chart } = chartInfo;

            // if chart changed, then update if in store, but keep Timestamp to avoid unnecessary rendering
            // dts chart settings should stay unchanged
            // vibration event chart data should not change any chart settings
            if (!booleanValue && Chart.Type !== ChartType.DtsChart && (Chart.Period !== SlimChartConfig.Period || Chart.StartDateSetting.Value !== SlimChartConfig.StartDate || Chart.EndDateSetting.Value !== SlimChartConfig.EndDate)) {

                chartInfo.Chart = {
                    ...getActualChartConfig(Chart, geovisChartData.Data),
                    Period: SlimChartConfig.Period,
                    StartDateSetting: { ...Chart.StartDateSetting, Value: SlimChartConfig.StartDate },
                    EndDateSetting: { ...Chart.EndDateSetting, Value: SlimChartConfig.EndDate }
                };

                configPage.geovisCharts.set(Chart.Id, chartInfo);
            }

            if (booleanValue) {
                dataPage.geovisEventChartsData.set(chartId, {
                    ...loadedSomethingStorageState,
                    data: geovisChartData.Data,
                    dtsSectionInfos,
                    Timestamp: new Date().getTime()
                })
            }
            else {
                dataPage.geovisChartsData.set(chartId, {
                    ...loadedSomethingStorageState,
                    data: geovisChartData.Data,
                    dtsSectionInfos,
                    Timestamp: new Date().getTime()
                })
            }


            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPages: state.geovisReportSettings.geovisPages,
                    geovisPagesData: state.geovisReportSettings.geovisPagesData
                }
            }
        }

        case PROJECT_GEOVIS_REPORT_CHART_REDRAW: {
            const { numberValue: chartId, pageNum } = action;
            if (!chartId || pageNum === undefined || pageNum < 0) {
                return state;
            }

            const pageData = state.geovisReportSettings.geovisPagesData.get(pageNum);
            if (!pageData) {
                return state;
            }

            const chartData = pageData.geovisChartsData.get(chartId);
            if (!chartData) {
                return state;
            }

            pageData.geovisChartsData.set(chartId, { ...chartData, Timestamp: new Date().getTime() });

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPagesData: state.geovisReportSettings.geovisPagesData
                }
            }
        }

        case PROJECT_GEOVIS_REPORT_RELOAD_DATA_WITHOUT_BLINK: {
            const geovisPages = new Map<number, IGeovisReportPageConfig>();
            state.geovisReportSettings.geovisPages.forEach((pageConfig, pageNum) => {
                const newCharts = new Map<number, IGeovisChartReportInfo<GeovisChartModel>>();

                pageConfig.geovisCharts.forEach((chart, chartId) => {
                    newCharts.set(chartId, {
                        ...chart,
                        Timestamp: new Date().getTime(),
                        SuppressLoadingState: true
                    })
                })

                geovisPages.set(pageNum, {
                    ...pageConfig,
                    geovisCharts: newCharts
                });
            })

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPages
                }
            }
        }

        /**
         * Geovis4Table loading
         * что бы визуально различать блоки, а то накодено уже больше 1000 строк
         */
        case PROJECT_GEOVIS_REPORT_TABLE_LOADING: {
            const { numberValue: tableId, pageNum } = action;

            if (!tableId || pageNum === undefined) {
                return state;
            }

            let dataPage = state.geovisReportSettings.geovisPagesData.get(pageNum);
            if (!dataPage) {
                dataPage = getGeovisReportPageDataInitialState();
                state.geovisReportSettings.geovisPagesData.set(pageNum, dataPage);
            }

            dataPage.geovis4TablesData.set(tableId, geovis4TableDataInitialState);

            return {
                ...state,
                geovisReportSettings: { ...state.geovisReportSettings } // trigger recalculate props for renders
            }
        }

        /**
         * Geovis4Table loaded
         */
        case PROJECT_GEOVIS_REPORT_TABLE_LOADED: {
            const { numberValue: tableId, pageNum, geovis4TableData } = action;

            if (!tableId || pageNum === undefined || !geovis4TableData) {
                return state;
            }

            if (geovis4TableData.Success && geovis4TableData.Data) {
                const pageConfig = state.geovisReportSettings.geovisPages.get(pageNum);
                if (!pageConfig) {
                    return state;
                }

                const tableConfig = pageConfig.tables.get(tableId);
                if (!tableConfig) {
                    return state;
                }

                tableConfig.Table.Timeslot = { ...tableConfig.Table.Timeslot, Value: geovis4TableData.Data.Timeslot };
            }

            let dataPage = state.geovisReportSettings.geovisPagesData.get(pageNum);
            if (!dataPage) {
                dataPage = getGeovisReportPageDataInitialState();
                state.geovisReportSettings.geovisPagesData.set(pageNum, dataPage);
            }

            const tableDataState = processFetchedData(geovis4TableData, geovis4TableDataInitialState, geovis4TableDataInitialState, st => ({
                data: st
            }));

            dataPage.geovis4TablesData.set(tableId, tableDataState);

            // update table title, but don't trigger reloading the data
            {
                const configPage = state.geovisReportSettings.geovisPages.get(pageNum);
                if (configPage) {
                    const tableInfo = configPage.tables.get(tableId);
                    if (tableInfo) {
                        configPage.tables.set(tableId, {
                            ...tableInfo,
                            Table: {
                                ...tableInfo.Table,
                                Title: geovis4TableData.Data.Title
                            }
                        })
                    }
                }
            }

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings
                }
            }
        }

        case PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_SHOW: {
            if (!action.elementId || !action.stringValue || !action.stringValue2 || action.pageNum === undefined || !action.eventDate) {
                return state;
            }

            const updPages = cloneDeep(state.geovisReportSettings.geovisPages);
            const page = updPages.get(action.pageNum);
            if (!page) {
                return state;
            }
            page.showEventChart.set(action.elementId, { eventDate: action.eventDate, eventId: action.stringValue, fullId: action.stringValue2 })

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPages: updPages
                }
            }
        }

        case PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_HIDE: {
            if (!action.elementId || action.pageNum === undefined) {
                return state;
            }
            const updPages = cloneDeep(state.geovisReportSettings.geovisPages);
            const page = updPages.get(action.pageNum);
            if (!page) {
                return state;
            }
            page.showEventChart.delete(action.elementId);

            return {
                ...state,
                geovisReportSettings: {
                    ...state.geovisReportSettings,
                    geovisPages: updPages
                }
            }
        }

        /**
         * Set PDF report initial state
         */
        case GEOVIS_PROJECT_REPORT_PDF_INITIAL_STATE: {
            const pdfAction = action as IGeovisProjectReportPdfAction;
            if (!pdfAction.pdfReportInitialState || !pdfAction.pdfReportInitialState.Success) {
                return state;
            }

            const { DirtyChartIds, ReportSettings } = pdfAction.pdfReportInitialState.Data;

            // const distinctCharts = getGeovisReportDistinctElements(ReportSettings.Pages, m => m.GeovisCharts);
            // const distinctMapSections = getGeovisReportDistinctElements(ReportSettings.Pages, m => m.MapSectionsInfo);

            const res = processFetchedData(pdfAction.pdfReportInitialState, state, projectReportInitialState, () => ({
                ...state,
                ...loadedSomethingStorageState,
                geovisReportSettings: {
                    geovisEventChartsData: new Map<number, IGeovisChartReportData<GeovisChartData>>(),
                    //showEventChart: new Map<number, string>(),
                    isDefaultChartView: ReportSettings.IsDefaultChartView,
                    isDefaultReportView: ReportSettings.IsDefaultReportView,
                    detailedReportInfo: ReportSettings.Report,
                    dtsSectionCloseTSensorIdsMap: ReportSettings.DtsSectionCloseTSensorIdsMap,
                    geovisPages: elementsToMapWithConverted(ReportSettings.Pages, page => [page.PageNum, {
                        geovisCharts: elementsToMapWithConverted(page.GeovisCharts, chart => [chart.Id, {
                            Chart: chart,
                            IsDirty: DirtyChartIds.indexOf(chart.Id) > -1,
                            Timestamp: new Date().getTime(),
                            SuppressLoadingState: false
                        }]),
                        mapSections: elementsToMapWithConverted<GeovisMapSectionReportInfo, number, IGeovisMapSectionReportInfo>(page.MapSectionsInfo, ({ Id, LayerBounds, LayerType, MapSection, InvertXAxis }) => [Id, {
                            MapSection: MapSection,
                            IsDirty: false, // TODO: Implement a function for getting the dirty state of the map section
                            Timestamp: new Date().getTime(),
                            DxfLayerType: LayerType,
                            DxfMapTileLayerBounds: LayerBounds,
                            InvertXAxis
                        }]),
                        showEventChart: new Map<number, IGeovisVibrationEventSlimInfo>(),
                        tables: elementsToMapWithConverted(page.Tables, table => [table.Id, {
                            Table: table,
                            IsDirty: false, // TODO: Implement a function for getting the dirty state of the map section
                            Timestamp: new Date().getTime()
                        }]),
                        geovisImages: elementsToMapWithConverted(page.GeovisImages, image => [image.Id, {
                            GeovisImage: image,
                            IsDirty: false,
                            Timestamp: new Date().getTime()
                        }]),
                        geovisLogbooks: elementsToMapWithConverted(page.GeovisLogbooks, logbook => [logbook.Id, {
                            GeovisLogbook: logbook,
                            IsDirty: false,
                            Timestamp: new Date().getTime()
                        }]),
                        selectionInfo: initGeovisReportPageSelectionInfo(page, ReportSettings.DtsSectionCloseTSensorIdsMap)
                    }]),
                    originalChartConfigs: [], // not needed in PDF report,
                    originalMapSections: [],
                    originalTables: [],
                    originalGeovisImages: [],
                    originalGeovisLogbooks: [],
                    geovisPagesData: new Map<number, IGeovisReportPageData>(),
                    allAvailableSensorsFullIds: []
                }
            }));

            return res;
        }

        case PROJECT_GEOVIS_REPORT_SERVICE_DATA_CHANGED: {
            if (action.numberValue2 === undefined || action.pageNum === undefined || action.stringValue2 === undefined || action.stringValue === undefined) {
                return state;
            }
            // set real count of pages on 1 report page
            state.pagesToReportPage.set(action.pageNum, action.numberValue2);

            // set dates for report header
            if (!state.startReportDate && action.stringValue || state.startReportDate && action.stringValue && compareDates(action.stringValue, state.startReportDate) < 0) {
                state.startReportDate = action.stringValue;
            }

            if (!state.endReportDate && action.stringValue2 || state.endReportDate && action.stringValue2 && compareDates(action.stringValue2, state.startReportDate) > 0) {
                state.endReportDate = action.stringValue2;
            }

            return {
                ...state,
                startReportDate: state.startReportDate,
                endReportDate: state.endReportDate,
                pagesToReportPage: new Map<number, number>(state.pagesToReportPage)
            }
        }
    }

    return state;
}

const isReportElementDirty = (pageNum: number, chartId: number, dirtyElementsByPage?: { [key: number]: number[] } | undefined): boolean => {
    if (!dirtyElementsByPage) {
        return false;
    }

    const page = dirtyElementsByPage[pageNum];
    if (!page) {
        return false;
    }

    return page.includes(chartId);
}

const isGeovisImageDirty = (pageNum: number, imageId: number, dirtyGeovisImagesByPage?: { [key: number]: number[] }): boolean => {
    if (!dirtyGeovisImagesByPage) {
        return false;
    }

    const page = dirtyGeovisImagesByPage[pageNum];
    if (!page) {
        return false;
    }

    return page.includes(imageId);
}


export default projectReportReducer;