//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SensorHeatpulseOption {
	Default = 0,
	Heatpulse_Temperature = 1,
	Heatpulse_Stoke_Antistoke = 2,
	Heatpulse_Attenuation = 3,
	Heatpulse_dTNorm = 4,
	Heatpulse_T0 = 5,
	Heatpulse_dT = 6,
	Heatpulse_tc = 7
}

const SensorHeatpulseOptionToName = new Map<number, string>([
	[ 0, 'Default' ],
	[ 1, 'Heatpulse_Temperature' ],
	[ 2, 'Heatpulse_Stoke_Antistoke' ],
	[ 3, 'Heatpulse_Attenuation' ],
	[ 4, 'Heatpulse_dTNorm' ],
	[ 5, 'Heatpulse_T0' ],
	[ 6, 'Heatpulse_dT' ],
	[ 7, 'Heatpulse_tc' ],
])

export function getSensorHeatpulseOptionToName(element: SensorHeatpulseOption) : string {
    return SensorHeatpulseOptionToName.get(element) || '';
}

const SensorHeatpulseOptionByName = new Map<string, number>([
	[ 'Default', 0 ],
	[ 'Heatpulse_Temperature', 1 ],
	[ 'Heatpulse_Stoke_Antistoke', 2 ],
	[ 'Heatpulse_Attenuation', 3 ],
	[ 'Heatpulse_dTNorm', 4 ],
	[ 'Heatpulse_T0', 5 ],
	[ 'Heatpulse_dT', 6 ],
	[ 'Heatpulse_tc', 7 ],
]);

export function getSensorHeatpulseOptionByName(name: string): SensorHeatpulseOption {
    return SensorHeatpulseOptionByName.get(name)!;
}


export const SensorHeatpulseOptionList : Readonly<SensorHeatpulseOption[]> = [
	SensorHeatpulseOption.Default,
	SensorHeatpulseOption.Heatpulse_Temperature,
	SensorHeatpulseOption.Heatpulse_Stoke_Antistoke,
	SensorHeatpulseOption.Heatpulse_Attenuation,
	SensorHeatpulseOption.Heatpulse_dTNorm,
	SensorHeatpulseOption.Heatpulse_T0,
	SensorHeatpulseOption.Heatpulse_dT,
	SensorHeatpulseOption.Heatpulse_tc,
];



const SensorHeatpulseOptionToDescription = new Map<number, string>([
	[ 0, 'Default' ],
	[ 1, 'Heatpulse_Temperature' ],
	[ 2, 'Heatpulse_Stoke/Antistoke' ],
	[ 3, 'Heatpulse_Attenuation' ],
	[ 4, 'Heatpulse_dTNorm' ],
	[ 5, 'Heatpulse_T0' ],
	[ 6, 'Heatpulse_dT' ],
	[ 7, 'Heatpulse_tc' ],
])

export function getSensorHeatpulseOptionToDescription(element: SensorHeatpulseOption) : string {
    return SensorHeatpulseOptionToDescription.get(element) || '';
}

