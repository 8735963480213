//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LocalMapObjectContentType {
	Background = 0,
	Link = 1,
	Document = 2,
	Text = 3,
	Report = 4,
	View = 5,
	Empty = 6
}

const LocalMapObjectContentTypeToName = new Map<number, string>([
	[ 0, 'Background' ],
	[ 1, 'Link' ],
	[ 2, 'Document' ],
	[ 3, 'Text' ],
	[ 4, 'Report' ],
	[ 5, 'View' ],
	[ 6, 'Empty' ],
])

export function getLocalMapObjectContentTypeToName(element: LocalMapObjectContentType) : string {
    return LocalMapObjectContentTypeToName.get(element) || '';
}

const LocalMapObjectContentTypeByName = new Map<string, number>([
	[ 'Background', 0 ],
	[ 'Link', 1 ],
	[ 'Document', 2 ],
	[ 'Text', 3 ],
	[ 'Report', 4 ],
	[ 'View', 5 ],
	[ 'Empty', 6 ],
]);

export function getLocalMapObjectContentTypeByName(name: string): LocalMapObjectContentType {
    return LocalMapObjectContentTypeByName.get(name)!;
}


export const LocalMapObjectContentTypeList : Readonly<LocalMapObjectContentType[]> = [
	LocalMapObjectContentType.Background,
	LocalMapObjectContentType.Link,
	LocalMapObjectContentType.Document,
	LocalMapObjectContentType.Text,
	LocalMapObjectContentType.Report,
	LocalMapObjectContentType.View,
	LocalMapObjectContentType.Empty,
];



const LocalMapObjectContentTypeToDescription = new Map<number, string>([
	[ 0, 'Background' ],
	[ 1, 'Link' ],
	[ 2, 'Document' ],
	[ 3, 'Text' ],
	[ 4, 'Report' ],
	[ 5, 'View' ],
	[ 6, '--' ],
])

export function getLocalMapObjectContentTypeToDescription(element: LocalMapObjectContentType) : string {
    return LocalMapObjectContentTypeToDescription.get(element) || '';
}

