/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 01.07.2020
 * @description the project report state data helper
 */

import { toPropertyId } from "../../components/projectOverview/reportOverlay/tools";
import { IInvalidPropertyInfo, ReportObjectType } from "../../components/projectOverview/reportOverlay/types";
import { elementsToMapOfHash, mapToListOfElements } from "../../helpers/StorageHelper";
import { t } from "../../i18n";
import { GeovisReportPageSettings } from "../../server/AVTService/TypeLibrary/Model/GeovisReportPageSettings";
import { ChartInvalidFieldDescription } from "../../server/AVTService/TypeLibrary/Validation/ChartInvalidFieldDescription";


/**
 * Get distinct elements of report
 * @param pages 
 * @returns 
 */
export const getGeovisReportDistinctElements = <TElement extends { Id: number }>(pages: GeovisReportPageSettings[], getPageElements: (page: GeovisReportPageSettings) => TElement[]): TElement[] => {

    const result: TElement[] = [];
    if (!pages) {
        return result;
    }

    const keysMap = new Map<number, boolean>();

    for (const page of pages) {

        const elements = getPageElements(page);
        if (!elements) {
            continue;
        }

        for (const element of elements) {
            const { Id } = element;

            if (!keysMap.get(Id)) {
                keysMap.set(Id, true);
                result.push(element);
            }
        }
    }

    return result;
}

/**
 * process highlight invalid properties
 * @param invalidPropertiesInfo current list of invalid properties
 * @param propertyId invalid property id
 * @param message error description
 */
export const processHighlightInvalidProperty = (invalidPropertiesInfo: IInvalidPropertyInfo[], propertyId: string, message: string): IInvalidPropertyInfo[] | false => {

    // nothing to do with empty property path
    if (propertyId.length === 0) {
        return false;
    }

    const invalidPropertiesMap = elementsToMapOfHash<string, IInvalidPropertyInfo>(invalidPropertiesInfo, p => toPropertyId(p.propertyPath));
    const propertyInfo = invalidPropertiesMap.get(propertyId);

    if (propertyInfo) {
        if (propertyInfo.messages.includes(message)) {
            return false;
        }

        const messages = [...propertyInfo.messages, message];
        invalidPropertiesMap.set(propertyId, { propertyPath: propertyId.split('.'), messages });
    }
    else {
        invalidPropertiesMap.set(propertyId, { propertyPath: propertyId.split('.'), messages: [message] });
    }

    return mapToListOfElements(invalidPropertiesMap);
}

/**
 * 
 * @param invalidPropertiesInfo - current invalid properties info
 * @param propertiesInfo 
 */
export const processHighlighInvalidProperties = (invalidPropertiesInfo: IInvalidPropertyInfo[], propertiesInfo: ChartInvalidFieldDescription[]): IInvalidPropertyInfo[] | false => {

    if (!propertiesInfo || propertiesInfo.length === 0) {
        return false;
    }

    const invalidPropertiesMap = elementsToMapOfHash<string, IInvalidPropertyInfo>(invalidPropertiesInfo, p => toPropertyId(p.propertyPath));

    // some propertiesInfo can reference to the same property.
    // in this case the result must have more that 1 message

    let hasChanges = false;

    for (const propertyInfo of propertiesInfo) {
        const propertyPath = [ReportObjectType.Chart, propertyInfo.chartId.toString(), propertyInfo.name];
        const propertyId = toPropertyId(propertyPath);
        const existPropertyInfo = invalidPropertiesMap.get(propertyId);

        const message = t(propertyInfo.message);

        if (existPropertyInfo) {
            if (existPropertyInfo.messages.includes(message)) {
                continue;
            }

            const messages = [...existPropertyInfo.messages, message];
            invalidPropertiesMap.set(propertyId, { propertyPath, messages });
            hasChanges = true;
        }
        else {
            invalidPropertiesMap.set(propertyId, { propertyPath, messages: [message] });
            hasChanges = true;
        }
    }

    if (hasChanges) {
        return mapToListOfElements(invalidPropertiesMap);
    }

    return false;
}