/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 16.07.2020
 * @description Common tool methods for user dialog
 */

import { userProfileReadonly } from '../../helpers/UsersHelper';
import { t } from '../../i18n';
import { UserEditorMode } from "./types";

export const getModalDialogHeading = (mode: UserEditorMode): string => {

    if (mode === UserEditorMode.Create) {
        return t("Create a new user");
    }

    if (userProfileReadonly(mode)) {
        return t("User information")
    }
    return t("Edit user");
}