/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.12.2020
 * @description Projects history table view
 */

import { Checkbox } from '@atlaskit/checkbox';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { HeadType, RowType } from '@atlaskit/dynamic-table/types';
import { dateTimeToReactMoment } from '../../../../helpers/DateHelper';
import { mapToListOfElements } from '../../../../helpers/StorageHelper';
import { t } from '../../../../i18n';
import { getProjectTypeToName } from '../../../../server/AVTService/TypeLibrary/Common/ProjectType';
import { getServiceModelToName } from '../../../../server/AVTService/TypeLibrary/Common/ServiceModel';
import { ProjectHistoryModel } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectHistoryModel';
import { getProjectHistoryStateToName } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectHistoryState';
import { CompanyInfo } from '../../../../server/GEOvis3/Model/Company/CompanyInfo';
import { IGeovisProjectsHistoryViewState } from '../../../../store/statistics.types';
import { ProjectHistoryTableCell } from './ProjectHistoryTableCell';
import { getProjectManualStateToName } from '../../../../server/AVTService/TypeLibrary/Common/ProjectManualState';

export const ProjectNumberKey = 'project_number';
const ProjectNameKey = 'project_name';
const CreatorCompanyKey = 'creator_company';
const InvoiceCompanyKey = 'invoice_company';
const ProjectManualStateKey = 'project_manual_state';
const OwnerCompanyKey = 'owner_company';
const Geovis4PackageKey = 'is_geovis4_package';
const ProjectStateKey = 'project_state';
export const ProjectCreationDateKey = 'project_creation_date';
const ProjectEndDateKey = 'project_end_data';
const ProjectNumberOfDaysKey = 'project_number_of_days';
const ProjectTypeKey = 'project_type';
const ServiceModelKey = 'service_model';

interface IProjectsHistoryTableViewProps {
    projects: Map<string, ProjectHistoryModel>;
    companies: Map<string, CompanyInfo>;
    viewState: IGeovisProjectsHistoryViewState;

    onSortChanged: (sortKey: string, sortOrder: "ASC" | "DESC") => void;
}

export const ProjectsHistoryTableView = (props: IProjectsHistoryTableViewProps) => {

    const {
        viewState,
        onSortChanged
    } = props;

    const onSortHandler = ({ key, sortOrder }: any) => onSortChanged(key, sortOrder);

    return (
        <DynamicTableStateless
            head={getProjectsTableHead()}
            rows={getProjectsTableRows(props)}
            onSort={onSortHandler}
            sortKey={viewState.sortKey}
            sortOrder={viewState.sortOrder}
        />
    )
}

const getProjectsTableHead = (): HeadType => ({
    cells: [{
        key: ProjectNumberKey,
        content: t("Project Number"),
        isSortable: true
    }, {
        key: ProjectNameKey,
        content: t("Project Name"),
        isSortable: true
    }, {
        key: CreatorCompanyKey,
        content: t("Creator company"),
        isSortable: true
    }, {
        key: OwnerCompanyKey,
        content: t("Owner company"),
        isSortable: true
    }, {
        key: InvoiceCompanyKey,
        content: t("Invoice company"),
        isSortable: true
    }, {
        key: Geovis4PackageKey,
        content: t("GEOvis 4 Package"),
        isSortable: true
    }, {
        key: ProjectTypeKey,
        content: t("Project type"),
        isSortable: true
    }, {
        key: ServiceModelKey,
        content: t("Service model"),
        isSortable: true
    }, {
        key: ProjectStateKey,
        content: t("State"),
        isSortable: true
    }, {
        key: ProjectManualStateKey,
        content: t("Manual State"),
        isSortable: true
    }, {
        key: ProjectCreationDateKey,
        content: t("Creation date"),
        isSortable: true
    }, {
        key: ProjectEndDateKey,
        content: t("End date"),
        isSortable: true
    }, {
        key: ProjectNumberOfDaysKey,
        content: t('Number of days'),
        isSortable: true
    }]
});

const getProjectsTableRows = (props: IProjectsHistoryTableViewProps): RowType[] => {

    const {
        projects,
        companies
    } = props;

    return mapToListOfElements(projects).map<RowType>(history => {

        const ownerName = getProjectCompany(history.CompanyOwnerId, companies);
        const invoiceName = getProjectCompany(history.InvoiceCompanyId, companies);
        const creatorName = getProjectCreatorsCompanies(history.CreatorsCompaniesIds, companies);

        const stateName = getProjectHistoryStateToName(history.ProjectState);
        const manualStateName = getProjectManualStateToName(history.ManualState);

        const projectType = getProjectTypeToName(history.ProjectType);
        const serviceModel = getServiceModelToName(history.ServiceModel);

        const row: RowType = {
            key: `project-row-${history.ProjectGUID}`,
            cells: [{
                key: history.ProjectNumber,
                content: history.ProjectNumber
            }, {
                key: history.ProjectName,
                content: history.ProjectName
            }, {
                key: creatorName,
                content: (
                    <ProjectHistoryTableCell
                        historyId={history.HistoryId}
                        propertyName="CreatorsCompaniesIds"
                        content={creatorName} />
                )
            }, {
                key: ownerName,
                content: (
                    <ProjectHistoryTableCell
                        historyId={history.HistoryId}
                        propertyName="CompanyOwnerId"
                        content={ownerName}
                    />
                )
            }, {
                key: invoiceName,
                content: (
                    <ProjectHistoryTableCell
                        historyId={history.HistoryId}
                        propertyName="InvoiceCompanyId"
                        content={invoiceName}
                    />
                )
            }, {
                key: history.IsGeovis4Package ? 'on' : 'off',
                content: (
                    <ProjectHistoryTableCell
                        showChevron={true}
                        content={
                            <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center' }}>
                                <Checkbox
                                    label=""
                                    isChecked={history.IsGeovis4Package}
                                />
                            </div>
                        }
                        historyId={history.HistoryId}
                        propertyName="IsGeovis4Package"
                    />
                )
            }, {
                key: projectType,
                content: (
                    <ProjectHistoryTableCell
                        content={projectType}
                        historyId={history.HistoryId}
                        propertyName="ProjectType"
                    />
                )
            }, {
                key: serviceModel,
                content: (
                    <ProjectHistoryTableCell
                        content={serviceModel}
                        historyId={history.HistoryId}
                        propertyName="ServiceModel"
                    />
                )
            }, {
                key: stateName,
                content: (
                    <ProjectHistoryTableCell
                        content={stateName}
                        historyId={history.HistoryId}
                        propertyName="ProjectState"
                    />
                )
            }, {
                key: manualStateName,
                content: (
                    <ProjectHistoryTableCell
                        content={manualStateName}
                        historyId={history.HistoryId}
                        propertyName="ManualState"
                    />
                )
            }, {
                key: history.ProjectCreationDate,
                content: <div style={{ whiteSpace: 'nowrap' }}>{dateTimeToReactMoment(history.ProjectCreationDate)}</div>
            }, {
                key: history.ProjectEndDate,
                content: (
                    <ProjectHistoryTableCell
                        historyId={history.HistoryId}
                        propertyName="ProjectEndDate"
                        content={<div style={{ whiteSpace: 'nowrap' }}>{dateTimeToReactMoment(history.ProjectEndDate)}</div>}
                        showChevron={true}
                    />
                )
            }, {
                key: history.NumberOfDays,
                content: history.NumberOfDays
            }],
            testId: `project-${history.ProjectGUID}`
        }

        return row;
    });
}

const getProjectCompany = (companyId: string, companies: Map<string, CompanyInfo>): string => {
    if (!companyId) {
        return '';
    }

    const company = companies.get(companyId);
    if (!company) {
        return t("-- not found --");
    }

    return company.Name;
}

const getProjectCreatorsCompanies = (companiesIds: string[], companies: Map<string, CompanyInfo>): string => {

    let result = '';
    let counter = 0;
    companiesIds.forEach(id => {
        const company = companies.get(id);
        if (counter < 2) {
            if (company) {
                result += `${company.Name}; `;
            }
            else {
                result += `${t("-- not found --")}; `
            }
            counter++;
        }
        else if (counter === 2) {
            result += "...";
            counter++;
        }
    });

    return result;
}