import { TileLayer, TileLayerProps } from "react-leaflet";
import { GeoRasterFileModel } from "../../server/AVTService/TypeLibrary/Model/GeoRasterFileModel";
import { LeafletMapTilesRoutes } from "../../helpers/MapTilesServiceRoutes";
import AuthService from "../../services/AuthService";
import { MAP_TILES_API_ROOT } from "../../ApiConfig";
import { MaxMapZoomLevel } from "./types";

interface IGeoRasterTileLayerProps {
    raster: GeoRasterFileModel;
}

export const GeoRasterTileLayer = ({ raster }: IGeoRasterTileLayerProps) => {

    const routeUrl = LeafletMapTilesRoutes.GeoRasterMapTilesRoute.patch(raster.ProjectId, raster.Id).path;
    const tilesUrl = AuthService.getFullUrl(MAP_TILES_API_ROOT, routeUrl);

    const tileLayerProps: TileLayerProps = {
        url: tilesUrl,
        zIndex: 30,
        tms: true,
        maxZoom: MaxMapZoomLevel,
        maxNativeZoom: 21,
        opacity: raster.Opacity
    };

    return (
        <TileLayer {...tileLayerProps} />
    );
}