/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 20.05.2022
 * @description Common types for chart renders
 */

import { nameofFactory } from "../../../../../helpers/NameofFactory";
import { ChartRegressionSettings } from "../../../../../server/AVTService/TypeLibrary/Common/ChartRegressionSettings";
import { GeovisChartData } from "../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartData";
import { AxisScaleLimit } from "../../../../../server/AVTService/TypeLibrary/Model/AxisScaleLimit";
import { ChartSettingBool } from "../../../../../server/AVTService/TypeLibrary/Model/ChartSettingBool";
import { ChartSettingHeatmapRenderingMode } from "../../../../../server/AVTService/TypeLibrary/Model/ChartSettingHeatmapRenderingMode";
import { DtsChartModel } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel";
import { GeovisChartAxisSettings } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { GeovisChartModel } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { HeatmapChartModel } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel";
import { HeatmapValueSettings } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapValueSettings";
import { XyChartModel } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import { IGeovisChartReportData } from "../../../../../store/projectReport.types";


export interface IChartRenderStateToProps<TChartData extends GeovisChartData> {
    chartData: IGeovisChartReportData<TChartData> | undefined;
}



const chartProp = nameofFactory<GeovisChartModel>();
const chartSettingsBool = nameofFactory<ChartSettingBool>();
const axisScaleProp = nameofFactory<AxisScaleLimit>();
// const timeProp = nameofFactory<DateTimeChartSetting>();
const axisProp = nameofFactory<GeovisChartAxisSettings>();
const regressionProp = nameofFactory<ChartRegressionSettings>();

const xyChartProp = nameofFactory<XyChartModel>()
const dtsChartProp = nameofFactory<DtsChartModel>();

const heatmapChartProp = nameofFactory<HeatmapChartModel>();
const heatmapRenderingProp = nameofFactory<ChartSettingHeatmapRenderingMode>();
const heatmapValueProp = nameofFactory<HeatmapValueSettings>();

const getPropPath = (...properties: string[]): string => properties.join('.');

const axisScaleProperties: ReadonlyArray<string> = [
    axisScaleProp('auto'),
    axisScaleProp('value'),
    axisScaleProp('exact'),
];


const chartAxesProperties: ReadonlyArray<string> = [
    chartProp('LeftYAxisSettings'),
    chartProp('RightYAxisSettings'),
    xyChartProp('XAxisSettings'),
    dtsChartProp('XAxisSettings'),
    heatmapChartProp('ValueSettings')
]

const getListOfPropPathForPrefix = (prefixes: ReadonlyArray<string>, path: string): string[] => {
    return prefixes.map(p => getPropPath(p, path));
}

const getListOfPropPathsEx = (mainProps: ReadonlyArray<string>, middleProps: ReadonlyArray<string>, endProps: ReadonlyArray<string>): string[] => {

    const result: string[] = [];

    for (const mainProp of mainProps) {
        for (const middleProp of middleProps) {
            for (const endProp of endProps) {
                result.push(getPropPath(mainProp, middleProp, endProp));
            }
        }
    }

    return result;
}

export const GeovisNoDataProperties: ReadonlyArray<string> = [
    getPropPath(chartProp('ShowLegend'), chartSettingsBool('value')),
    // getPropPath(chartProp('DrawLineBetweenPoints'), chartSettingsBool('value')), // removed from this list because this property requires validation 

    // axes scale limits
    ...getListOfPropPathsEx(chartAxesProperties, [axisProp('minScaleLimit'), axisProp('maxScaleLimit')], axisScaleProperties),
    ...getListOfPropPathForPrefix(chartAxesProperties, getPropPath(axisProp('ReverseAxis'), chartSettingsBool('value'))),

    // axes other properties
    // ...getListOfPropPathForPrefix([chartProp('LeftYAxisSettings'), chartProp('RightYAxisSettings')], getPropPath(axisProp('ShowPointSymbols'), chartSettingsBool('value'))),
    ...getListOfPropPathForPrefix([chartProp('LeftYAxisSettings'), chartProp('RightYAxisSettings')], getPropPath(axisProp('ShowRegression'), chartSettingsBool('value'))),
    ...getListOfPropPathForPrefix([chartProp('LeftYAxisSettings'), chartProp('RightYAxisSettings')], getPropPath(axisProp('RegressionSettings'), regressionProp('FunctionType'))),
    ...getListOfPropPathForPrefix([chartProp('LeftYAxisSettings'), chartProp('RightYAxisSettings')], getPropPath(axisProp('RegressionSettings'), regressionProp('NumberPolinomMembers'))),
    // heatmap props
    getPropPath(heatmapChartProp('RenderingMode'), heatmapRenderingProp('value')),
    getPropPath(heatmapChartProp("ValueSettings"), heatmapValueProp("MinValue"), axisScaleProp("value")),
    getPropPath(heatmapChartProp("ValueSettings"), heatmapValueProp("MaxValue"), axisScaleProp("value"))

];

export const GeovisChartPeriodPath = (): string => {
    return getPropPath(chartProp('Period'));
}

export const GetGeovisChartPath = (propertyName: keyof GeovisChartModel): string => {
    return getPropPath(chartProp(propertyName));
}