/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 06.11.2019
 * @description The map layers to draw raster image as background of local map object content
 */

import { ImageOverlay } from 'react-leaflet';
import sha1 from 'sha1';
import { ImageBackgroundInfo } from '../../server/AVTService/TypeLibrary/ImageAsMap/ImageBackgroundInfo';
import ServerRoutesGen from '../../server/Routes/ServerRoutesGen';
import { IImageMapViewBounds, ImageMapContentType } from './types';
import AuthService from '../../services/AuthService';

interface IImageLayerProps {
    objectId: string;
    backgroundInfo: ImageBackgroundInfo;
    contentType: ImageMapContentType;
    projectId: number;

    viewPortToMapBounds: (backgroundInfo: ImageBackgroundInfo) => L.LatLngBounds;

    viewMapBounds?: IImageMapViewBounds;
}

export const GeovisImageLayer = ({ objectId, backgroundInfo, viewPortToMapBounds, contentType, projectId, viewMapBounds }: IImageLayerProps) => {

    const COLON_REPLACEMENT = "_COLON_";

    let fileId = backgroundInfo.FileId;
    const { IsFileDirty } = backgroundInfo;

    if (!objectId) {
        objectId = "objectId";
    }

    if (!fileId) {
        fileId = "fileId";
    }

    const secureKey = sha1(objectId + fileId);
    let url = "";

    switch (contentType) {
        case 'GeovisImage':
            if (viewMapBounds) {
                url = ServerRoutesGen.GeovisImageStream.RasterStreamOfSize.patch(projectId, objectId, fileId, IsFileDirty, secureKey, Math.floor(viewMapBounds.width), Math.floor(viewMapBounds.height)).path;
            } else {
                url = ServerRoutesGen.GeovisImageStream.RasterStream.patch(projectId, objectId, fileId, IsFileDirty, secureKey).path;
            }
            break;
        case 'LMO':
            url = ServerRoutesGen.LocalMapObjectStream.BackgroundStream.patch(objectId, fileId, IsFileDirty, secureKey).path;
            break;
        case 'SensorImage':
            url = ServerRoutesGen.SensorImageStream.BackgroundStream.patch(projectId, objectId.replace(":", COLON_REPLACEMENT), fileId, IsFileDirty, secureKey).path;
            break;
    }



    return (
        <ImageOverlay
            url={AuthService.getRequestHelper(AuthService).getEndpointServerUrl(url)}
            bounds={viewPortToMapBounds(backgroundInfo)}
        />
    );
}