//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartModel } from './GeovisChartModel';
import { GeovisChartAxisSettings } from './GeovisChartAxisSettings';
import { ChartSettingBool } from '../ChartSettingBool';

export class VibrationChartModel extends GeovisChartModel
{
	public LeftEventAxisSettings: GeovisChartAxisSettings;
	public RightEventAxisSettings: GeovisChartAxisSettings;
	public ShowEventGraph: ChartSettingBool;
	public ShowOccurrence: boolean;
	public ShowDominantFreq: boolean;
	public ShowFrequencyScaling: boolean;
	public ShowBackgroundData: ChartSettingBool;
}
