//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ProjectManualState {
	Running = 0,
	Finished = 1,
	OnHold = 2
}

const ProjectManualStateToName = new Map<number, string>([
	[ 0, 'Running' ],
	[ 1, 'Finished' ],
	[ 2, 'OnHold' ],
])

export function getProjectManualStateToName(element: ProjectManualState) : string {
    return ProjectManualStateToName.get(element) || '';
}

const ProjectManualStateByName = new Map<string, number>([
	[ 'Running', 0 ],
	[ 'Finished', 1 ],
	[ 'OnHold', 2 ],
]);

export function getProjectManualStateByName(name: string): ProjectManualState {
    return ProjectManualStateByName.get(name)!;
}


export const ProjectManualStateList : Readonly<ProjectManualState[]> = [
	ProjectManualState.Running,
	ProjectManualState.Finished,
	ProjectManualState.OnHold,
];



const ProjectManualStateToDescription = new Map<number, string>([
	[ 0, 'Running' ],
	[ 1, 'Finished' ],
	[ 2, 'On hold' ],
])

export function getProjectManualStateToDescription(element: ProjectManualState) : string {
    return ProjectManualStateToDescription.get(element) || '';
}

