/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 26.10.2020
 * @description Tool methods for Geovis inventory page
 */

import { UploadEndEventPayload, UploadsStartEventPayload } from "@atlaskit/media-picker/dist/cjs/types"
import ProgressBar from '@atlaskit/progress-bar';
import moment from "moment";
import { Dispatch } from 'redux';
import { IErrorUploadFileInfo, IProcessingUploadFileInfo, IProgressUploadFileInfo } from "../../../components/FileBrowser"
import { IDynamicTableRow } from "../../../components/types";
import { nameofFactory } from "../../../helpers/NameofFactory";
import { mapToListOfElements } from "../../../helpers/StorageHelper";
import { t } from '../../../i18n';
import { GeovisCompanyType } from "../../../server/AVTService/TypeLibrary/Common/GeovisCompanyType";
import { InventoryAfmBoxModel } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryAfmBoxModel";
import { InventoryAtcSensorModel } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryAtcSensorModel";
import { InventoryAtcSensorOnlineStatus } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryAtcSensorOnlineStatus";
import { InventoryLoRaGatewayModel } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryLoRaGatewayModel";
import { InventoryMstModel } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryMstModel";
import { InventoryObject } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryObject";
import { InventoryObjectType } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryObjectType";
import { InventoryReservation } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryReservation";
import { InventorySensorboxModel } from "../../../server/AVTService/TypeLibrary/Inventory/InventorySensorboxModel";
import { getInventoryStatusToName, InventoryStatus } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryStatus";
import { InventoryTachymeterModel } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryTachymeterModel";
import { InventoryUsagePlanRecord } from "../../../server/AVTService/TypeLibrary/Inventory/InventoryUsagePlanRecord";
import { InventorySensorboxPowerSourceUnit } from "../../../server/AVTService/TypeLibrary/Inventory/Sync/InventorySensorboxPowerSourceUnit";
import { CompanyInfo } from "../../../server/GEOvis3/Model/Company/CompanyInfo";
import { InventoryAfmBoxInfo } from "../../../server/GEOvis3/Model/Inventory/InventoryAfmBoxInfo";
import { InventoryAtcSensorInfo } from "../../../server/GEOvis3/Model/Inventory/InventoryAtcSensorInfo";
import { InventoryLoRaGatewayInfo } from "../../../server/GEOvis3/Model/Inventory/InventoryLoRaGatewayInfo";
import { InventoryMstInfo } from "../../../server/GEOvis3/Model/Inventory/InventoryMstInfo";
import { InventoryObjectInfo } from "../../../server/GEOvis3/Model/Inventory/InventoryObjectInfo";
import { InventoryRecordsInfo } from "../../../server/GEOvis3/Model/Inventory/InventoryRecordsInfo";
import { InventorySensorboxInfo } from "../../../server/GEOvis3/Model/Inventory/InventorySensorboxInfo";
import { InventoryTachymeterInfo } from "../../../server/GEOvis3/Model/Inventory/InventoryTachymeterInfo";
import { IBusinessProjectsStorage } from "../../../store/businessData.types";
import { geovisInventorySetUploadState } from "../../../store/creators/inventoryCreators";
import { IGeovisInventoryDataState, IGeovisInventoryUploadFileState } from "../../../store/inventory.types";
import { IGeovisAction } from "../../../store/types";
import { IInventoryDataSources } from "./types";


//#region Upload methods

export const onUploadInventorySensorsStartFn = (dispatch: Dispatch<IGeovisAction>) => (payload: UploadsStartEventPayload) =>
    dispatch(geovisInventorySetUploadState({
        inProgress: true,
        progress: payload.files.map(f => ({
            fileId: f.id,
            fileName: f.name,
            progress: 0
        }))
    }));

export const onUploadInventorySensorsEndFn = (dispatch: Dispatch<IGeovisAction>) => ({ file }: UploadEndEventPayload) =>
    dispatch(geovisInventorySetUploadState({
        processing: [{
            fileId: file.id,
            fileName: file.name
        }]
    }));

export const onUploadInventorySensorsStatusUpdateFn = (dispatch: Dispatch<IGeovisAction>) => ({ fileId, progress, fileName }: IProgressUploadFileInfo) =>
    dispatch(geovisInventorySetUploadState({
        progress: [{ fileId, progress, fileName }]
    }));

export const onUploadInventorySensorsProcessingFn = (dispatch: Dispatch<IGeovisAction>) => ({ fileId, fileName }: IProcessingUploadFileInfo) =>
    dispatch(geovisInventorySetUploadState({
        complete: [{ fileId, fileName }],
    }));

export const onUploadInventorySensorsErrorFn = (dispatch: Dispatch<IGeovisAction>) => ({ fileId, errorDescription, fileName }: IErrorUploadFileInfo) =>
    dispatch(geovisInventorySetUploadState({
        error: [{ fileId, fileName, errorDescription }]
    }));

//#endregion

export const uploadingFilesTableHead = (): IDynamicTableRow => ({
    key: 'uploading-files-head',
    cells: [{
        key: 'fileName',
        content: t("File")
    }, {
        key: 'fileState',
        content: t("State")
    }]
});

export const uploadingFilesTableRows = ({ complete, error, processing, progress }: IGeovisInventoryUploadFileState): IDynamicTableRow[] => {

    const rows: IDynamicTableRow[] = [];

    // progress
    for (const file of progress) {
        const progressPercent = file.progress * 100;

        rows.push({
            key: `progress-${file.fileId}-label`,
            cells: [{
                key: `progress-${file.fileId}-label-name`,
                content: file.fileName
            }, {
                key: `progress-${file.fileId}-label-value`,
                content: `${progressPercent.toFixed(2)}%`
            }]
        });

        rows.push({
            key: `progress-${file.fileId}-progress`,
            cells: [{
                key: `progress-${file.fileId}-bar`,
                colSpan: 2,
                content: <ProgressBar value={file.progress} />
            }]
        });
    }

    // processing
    for (const { fileId, fileName } of processing) {
        rows.push({
            key: `processing-${fileId}-label`,
            cells: [
                { key: `processing-${fileId}-label-name`, content: fileName },
                { key: `processing-${fileId}-label-state`, content: t("Processing...") }
            ]
        });

        rows.push({
            key: `processing-${fileId}-progress`,
            cells: [{
                key: `processing-${fileId}-bar`,
                colSpan: 2,
                content: <ProgressBar isIndeterminate={true} />
            }]
        });
    }

    // error
    for (const { fileId, errorDescription, fileName } of error) {
        rows.push({
            key: `error-${fileId}-label`,
            cells: [
                { key: `error-${fileId}-label-name`, content: fileName },
                { key: `error-${fileId}-label-state`, content: t("Error!") }
            ]
        });

        rows.push({
            key: `error=${fileId}-value`,
            cells: [{
                key: `error-${fileId}-description`,
                colSpan: 2,
                content: errorDescription
            }]
        });
    }

    // completed
    for (const { fileId, fileName } of complete) {
        rows.push({
            key: `complete-${fileId}-label`,
            cells: [
                { key: `complete-${fileId}-name`, content: fileName },
                { key: `complete-${fileId}- state`, content: t("Done!") }
            ]
        });
    }

    return rows;
}

export const getInventoryTypeName = (type: InventoryObjectType): string => {
    switch (type) {
        case InventoryObjectType.Afm: return t("AFM box");
        case InventoryObjectType.Atc: return t("ATC sensor");
        case InventoryObjectType.MST: return t("MST");
        case InventoryObjectType.SB: return t("Sensorbox");
        case InventoryObjectType.Tachymeter: return t("Tachymeter");
        case InventoryObjectType.LoRaGateway: return t("LoRa");
    }
}

/**
 * Get inventory edit dialog header text
 * @param createMode 
 * @param record 
 * @returns 
 */
export const getInventoryEditDialogHeader = (createMode: boolean, record: InventoryObject): string => {
    switch (record.ObjectType) {

        case InventoryObjectType.Afm:
            return createMode ? t("Create AFM box") : `${t("Edit AFM box")} ${getInventoryObjectProperty<InventoryAfmBoxModel>(record, 'SerialNumber')}`;

        case InventoryObjectType.Atc: {
            const sn = getInventoryObjectProperty<InventoryAtcSensorModel>(record, 'SerialNumber');
            const uid = getInventoryObjectProperty<InventoryAtcSensorModel>(record, 'Uid');

            return createMode ? t("Create ATC sensor") : `${t("Edit ATC sensor")} ${sn}:${uid}`;
        }

        case InventoryObjectType.MST:
            return createMode ? t("Create MST") : `${t("Edit MST")} ${getInventoryObjectProperty<InventoryMstModel>(record, 'SerialNumber')}`;

        case InventoryObjectType.SB:
            return createMode ? t("Create sensorbox") : `${t("Edit sensorbox")} ${getInventoryObjectProperty<InventorySensorboxModel>(record, 'SerialNumber')}`;

        case InventoryObjectType.Tachymeter:
            return createMode ? t("Create tachymeter") : `${t("Edit tachymeter")} ${getInventoryObjectProperty<InventoryTachymeterModel>(record, 'SerialNumber')}`;

        case InventoryObjectType.LoRaGateway:
            return createMode ? t("Create LoRa Gateway") : `${t("Edit LoRa Gateway")} ${getInventoryObjectProperty<InventoryLoRaGatewayModel>(record, 'SerialNumber')}`;
    }
}

/**
 * Gets inventory popup header text
 * @param record 
 * @returns 
 */
export const getInventoryHistoryPopupHeader = (record: InventoryObject): string => {
    switch (record.ObjectType) {
        case InventoryObjectType.Afm:
            return `${t("Usage plan of AFM box. Serial number")}: ${getInventoryObjectProperty<InventoryAfmBoxModel>(record, 'SerialNumber')}`;

        case InventoryObjectType.Atc: {
            const sn = getInventoryObjectProperty<InventoryAtcSensorModel>(record, 'SerialNumber');
            const uid = getInventoryObjectProperty<InventoryAtcSensorModel>(record, 'Uid');

            return `${t("Usage plan of ATC sensor. Serial number")}: ${sn}:${uid}`;
        }

        case InventoryObjectType.MST:
            return `${t("Usage plan of MST. Serial number")}: ${getInventoryObjectProperty<InventoryMstModel>(record, 'SerialNumber')}`;

        case InventoryObjectType.SB:
            return `${t("Usage plan of sensorbox. Serial number")}: ${getInventoryObjectProperty<InventorySensorboxModel>(record, 'SerialNumber')}`;

        case InventoryObjectType.Tachymeter: {
            const sn = getInventoryObjectProperty<InventoryTachymeterModel>(record, 'SerialNumber');
            const model = getInventoryObjectProperty<InventoryTachymeterModel>(record, 'Model');

            return `${t("Usage plan of tachymeter. Serial number")}: ${sn}. ${t("Model")}: ${model}`;
        }

        case InventoryObjectType.LoRaGateway:
            return `${t("Usage plan of LoRa Gateway. Serial number")}: ${getInventoryObjectProperty<InventoryLoRaGatewayModel>(record, 'SerialNumber')}`;
    }
}

/**
 * Gets the inventory edit dialog readonly fields, e.g. disabled for editing
 * @param type 
 * @param createMode 
 * @returns 
 */
export const getInventoryEditDialogReadonlyFields = (type: InventoryObjectType, createMode: boolean): string[] => {

    const baseListOfProperties: Array<keyof InventoryObjectInfo> = ["Id", "StatusDescription", "StatusEndDate", "UsagePlanComment", "ObjectType", "DeviceName"];
    const result: string[] = baseListOfProperties;

    switch (type) {
        case InventoryObjectType.Afm: {
            const props = nameofFactory<InventoryAfmBoxInfo>();

            if (!createMode) {
                result.push(props("SerialNumber"));
            }
            return result;
        }

        case InventoryObjectType.Atc: {
            const props = nameofFactory<InventoryAtcSensorInfo>();
            if (!createMode) {
                result.push(...[props("Uid"), props("SerialNumber")]);
            }
            return result;
        }

        case InventoryObjectType.MST: {

            const props = nameofFactory<InventoryMstInfo>();
            if (!createMode) {
                result.push(props("SerialNumber"))
            }

            return result;
        }

        case InventoryObjectType.SB: {

            const props = nameofFactory<InventorySensorboxInfo>();
            if (!createMode) {
                result.push(props("SerialNumber"));
            }
            return result;
        }

        case InventoryObjectType.Tachymeter: {
            const props = nameofFactory<InventoryTachymeterInfo>();
            if (!createMode) {
                result.push(props("SerialNumber"));
            }
            return result;
        }

        case InventoryObjectType.LoRaGateway: {
            const props = nameofFactory<InventoryLoRaGatewayInfo>();
            if (!createMode) {
                result.push(props("SerialNumber"));
            }
            return result;
        }
    }
}

/**
 * Gets the remove dialog header
 * @param record 
 * @returns 
 */
export const getInventoryRemoveDialogHeaderName = (record: InventoryObject): string => {
    const questionString = t("Are you sure to remove inventory record %1?");

    switch (record.ObjectType) {
        case InventoryObjectType.Afm: {
            return questionString.replace("%1", getInventoryObjectProperty<InventoryAfmBoxModel>(record, "SerialNumber"));
        }

        case InventoryObjectType.Atc: {
            const sn = getInventoryObjectProperty<InventoryAtcSensorModel>(record, "SerialNumber");
            const uid = getInventoryObjectProperty<InventoryAtcSensorModel>(record, 'Uid');
            return questionString.replace("%1", `${sn}:${uid}`);
        }

        case InventoryObjectType.MST: {
            return questionString.replace("%1", getInventoryObjectProperty<InventoryMstModel>(record, 'SerialNumber'));
        }

        case InventoryObjectType.SB: {
            return questionString.replace("%1", getInventoryObjectProperty<InventorySensorboxModel>(record, 'SerialNumber'));
        }

        case InventoryObjectType.Tachymeter: {
            return questionString.replace("%1", getInventoryObjectProperty<InventoryTachymeterModel>(record, 'Model'));
        }

        case InventoryObjectType.LoRaGateway: {
            return questionString.replace("%1", getInventoryObjectProperty<InventoryLoRaGatewayModel>(record, 'SerialNumber'));
        }
    }
}

/**
 * Gets the typed device property value
 * @param inventoryObject 
 * @param propertyName 
 * @returns 
 */
export const getInventoryObjectProperty = <TObject extends InventoryObject>(inventoryObject: InventoryObject, propertyName: keyof TObject): any => {
    return (inventoryObject as TObject)[propertyName];
}

export const getInventoryObjectOf = <TObject extends InventoryObject>(objectId: string, objects: TObject[]): TObject | undefined => {
    if (!objects) {
        return undefined;
    }

    return objects.find(f => f.Id === objectId);
}

export const getDefaultCompanyId = (companies: Map<string, CompanyInfo>): string => {
    const owners = mapToListOfElements(companies).filter(ac => ac.CompanyType === GeovisCompanyType.Owner);
    if (owners && owners.length) {
        return owners[0].Id;
    }
    return "";
}

export const getProjectName = (projectId: number, projectsStorage: IBusinessProjectsStorage): string => {
    if (!projectId || projectId === 0) {
        return t("Not in project");
    }
    const project = projectsStorage.projects.get(projectId);
    if (project) {
        return project.Name;
    }
    return t("Unknown project");
}

const getInitAfmItem = (): InventoryAfmBoxInfo => ({
    ObjectType: InventoryObjectType.Afm,
    Status: InventoryStatus.Storage,
    StatusDescription: '',
    StatusEndDate: '',
    SerialNumber: 0,
    Name: '',
    SimId: '',
    FirmwareVersion: '',
    IsOnline: InventoryAtcSensorOnlineStatus.Offline,
    LastUpdate: '',
    UserComment: '',
    Id: '',
    ParentId: '-',
    DeviceName: '',
    UsagePlanComment: '',
});

const getInitAtcItem = (): InventoryAtcSensorInfo => ({
    ObjectType: InventoryObjectType.Atc,
    Status: InventoryStatus.Storage,
    Id: '',
    SerialNumber: 0,
    Uid: '',
    Address: 0,
    ID: 0,
    Chip: 0,
    Type: '',
    TCompX: '',
    TCompY: '',
    FirmwareVersion: '',
    IsOnline: InventoryAtcSensorOnlineStatus.Offline,
    LastUpdate: '',
    StatusDescription: '',
    StatusEndDate: '',
    UserComment: '',
    ParentId: '-',
    DeviceName: '',
    UsagePlanComment: ''
});

const getInitMstItem = (): InventoryMstInfo => ({
    ObjectType: InventoryObjectType.MST,
    Status: InventoryStatus.Storage,
    SerialNumber: 0,
    Name: '',
    SimId: '',
    FirmwareVersion: '',
    HardwareVersion: '',
    IsOnline: InventoryAtcSensorOnlineStatus.Offline,
    LastUpdate: '',
    UserComment: '',
    Id: '',
    ParentId: '-',
    StatusDescription: '',
    StatusEndDate: '',
    DeviceName: '',
    AccessUrl: '',
    ServiceVersion: "Not set",
    UsagePlanComment: '',
    ASIF: false,
    RS485Female: 0,
    RS485Male: 0,
    AmphenolRS485Female: 0,
    ASIFPlug: 0,
    CameraEthernet: 0
});

const getInitSbItem = (): InventorySensorboxInfo => ({
    ObjectType: InventoryObjectType.SB,
    Status: InventoryStatus.Storage,
    Id: '',
    SerialNumber: 0,
    Name: '',
    FirmwareVersion: '',
    IsOnline: InventoryAtcSensorOnlineStatus.Offline,
    LastUpdate: '',
    UserComment: '',
    ParentId: '-',
    StatusDescription: '',
    StatusEndDate: '',
    PowerSourceType: InventorySensorboxPowerSourceUnit.NONE,
    DeviceName: '',
    UsagePlanComment: '',
    ASIF: false,
    RS485Female: 0,
    RS485Male: 0,
    ASIFPlug: 0
});

const getInitTachymeterItem = (): InventoryTachymeterInfo => ({
    ObjectType: InventoryObjectType.Tachymeter,
    Status: InventoryStatus.Storage,
    Id: '',
    SerialNumber: '',
    Model: '',
    InstrumentName: '',
    IsOnline: InventoryAtcSensorOnlineStatus.Offline,
    LastUpdate: '',
    UserComment: '',
    IsATProperty: true,
    ParentId: '-',
    BT: true,
    RL: true,
    Navigator: true,
    IMG: true,
    AngleAccuracy: 1,
    StatusDescription: '',
    StatusEndDate: '',
    LastCalibration: '',
    DeviceName: '',
    UsagePlanComment: ''
});

const getInitLoRaItem = (): InventoryLoRaGatewayInfo => ({
    ObjectType: InventoryObjectType.LoRaGateway,
    Status: InventoryStatus.Storage,
    Id: '',
    IsOnline: InventoryAtcSensorOnlineStatus.Offline,
    LastUpdate: '',
    Name: '',
    ParentId: '',
    SerialNumber: -1,
    StatusDescription: '',
    StatusEndDate: '',
    UserComment: '',
    DeviceName: '',
    UsagePlanComment: ''
});

export const getInitialInventoryItem = (objectType: InventoryObjectType): InventoryObjectInfo => {

    switch (objectType) {
        case InventoryObjectType.Afm: return getInitAfmItem();
        case InventoryObjectType.Atc: return getInitAtcItem();
        case InventoryObjectType.MST: return getInitMstItem();
        case InventoryObjectType.SB: return getInitSbItem();
        case InventoryObjectType.Tachymeter: return getInitTachymeterItem();
        case InventoryObjectType.LoRaGateway: return getInitLoRaItem();
    }
}

export const getCurrentStatus = (currentRecordId: string, records: InventoryUsagePlanRecord[], projectsStorage: IBusinessProjectsStorage, companies: Map<string, CompanyInfo>, reservations: InventoryReservation[]): string => {
    let result: string = "";
    records.forEach(r => {
        if (r.Id === currentRecordId) {
            switch (r.Status) {
                case InventoryStatus.Project: {
                    const project = projectsStorage.projects.get(r.ProjectId);
                    if (project) {
                        result = project.Name;
                    }
                    else {
                        result = "Unknown Project";
                    }
                    break;
                }

                case InventoryStatus.Storage: {
                    const company = companies.get(r.StorageCompanyId);
                    if (company) {
                        result = "Stored in " + company.Name;
                    }
                    else {
                        result = "On Storage";
                    }
                    break;
                }

                case InventoryStatus.Service:
                    result = "On Service";
                    break;
                case InventoryStatus.Defect:
                    result = "Defected";
                    break;
                case InventoryStatus.Unusable:
                    result = "Unusable";
                    break;
                case InventoryStatus.Bought:
                    break;
                case InventoryStatus.Reservation:
                    reservations.forEach(res => {
                        if (res.Id === r.ReservationId) {
                            result = res.ReservationName;
                        }
                    })
                    break;
            }
        }
    });
    return result;
}

export const getCurrentStatusEndTime = (currentRecordId: string, records: InventoryUsagePlanRecord[]): string => {
    let result: string = "";
    records.forEach(r => {
        if (r.Id === currentRecordId) {
            result = processDate(r.EndDate);
        }
    });
    return result;
}

export const processDate = (date: string): string => {
    const tDate = new Date(date);
    if (tDate.getFullYear() >= 9999) {
        return "Open-period";
    }
    const mDate = moment(date);
    return mDate.format('DD.MM.YYYY');
}

export const getInventoryObjectOfStorage = ({ objectId, objectType, afm, atc, tachy, sensorboxes, loraGateway, mst }: IGeovisInventoryDataState): InventoryObjectInfo | undefined => {

    switch (objectType) {
        case InventoryObjectType.MST: return getInventoryObjectRecordOfStorage(objectId, mst.data);
        case InventoryObjectType.SB: return getInventoryObjectRecordOfStorage(objectId, sensorboxes.data);
        case InventoryObjectType.Tachymeter: return getInventoryObjectRecordOfStorage(objectId, tachy.data);
        case InventoryObjectType.Atc: return getInventoryObjectRecordOfStorage(objectId, atc.data);
        case InventoryObjectType.Afm: return getInventoryObjectRecordOfStorage(objectId, afm.data);
        case InventoryObjectType.LoRaGateway: return getInventoryObjectRecordOfStorage(objectId, loraGateway.data);
    }
}

const getInventoryObjectRecordOfStorage = <TObj extends InventoryObjectInfo>(objectId: string, data: InventoryRecordsInfo<TObj>): InventoryObjectInfo | undefined => {
    return data.Records.find(r => r.Id === objectId);
}

export const dummyInventoryObject: InventoryObjectInfo = {
    ...new InventoryMstInfo(),
    Id: 'empty_object',
    ObjectType: InventoryObjectType.MST
}

export const getSelectedInventoryObjectInStorage = (state: IGeovisInventoryDataState, isShowValue: boolean): InventoryObjectInfo => {
    if (!isShowValue) {
        return dummyInventoryObject;
    }

    const inventoryObject = getInventoryObjectOfStorage(state);

    if (inventoryObject) {
        return inventoryObject;
    }

    return dummyInventoryObject;
}

export const getInventoryObjectStatusText = (
    { Status, ProjectId, ReservationId, StorageCompanyId }: InventoryUsagePlanRecord,
    { projects, reservations, companies }: IInventoryDataSources): string => {

    if (Status === InventoryStatus.Storage) {
        if (StorageCompanyId === getDefaultCompanyId(companies)) {
            return t("Stored in AI7D")
        }
        else {
            const company = companies.get(StorageCompanyId);
            if (!company) {
                return "Stored in unknown company";
            }
            return `Stored in ${company.Name}`;
        }
    }

    if (Status === InventoryStatus.Project) {
        const project = projects.get(ProjectId)
        if (project) {
            return `${project.Number} ${project.Name}`;
        }
        return t("Unknown project");
    }


    if (Status === InventoryStatus.Reservation) {
        const reservation = reservations.find(r => r.Id === ReservationId);
        if (reservation) {
            return reservation.ReservationName;
        }
        return t("On Reservation")
    }

    if (Status === InventoryStatus.Service) {
        return t("On Service");
    }

    if (Status === InventoryStatus.Bought) {
        return t("Bought");
    }

    if (Status === InventoryStatus.Unusable) {
        return t("Unusable");
    }

    if (Status === InventoryStatus.Defect) {
        return t("Defected");
    }

    return getInventoryStatusToName(Status);
}