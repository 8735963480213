import { compareDates, formattedDateTime } from "../../../../../../../helpers/DateHelper";
import { LogbookModel } from "../../../../../../../server/AVTService/TypeLibrary/Logbook/LogbookModel";

const COUNT_LOGBOOKS_IN_ONE_COLUMN = 6;

interface IComponentProps {
    logbooks: LogbookModel[]
}

export const TimeValueLogbooksControl = ({
    logbooks
}: IComponentProps) => {

    const getLogbookRows = (logbookModels: LogbookModel[], isLast: boolean) => {
        return (
            <div style={{ borderRight: isLast ? "" : 'solid 1px black', height: '100%', paddingRight: '10px' }}>
                {logbookModels.map((logbook, index) => (
                    <div key={`div-${index}`} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <span style={{ marginRight: '5px', width: '135px', flexShrink: 0 }}>{formattedDateTime(logbook.reportDate, "DD.MM.YYYY HH:mm:ss")}:</span>
                        <span>{logbook.description}</span>
                    </div>
                ))}
            </div>
        )
    }

    const compareLogbooks = (logbookA: LogbookModel, logbookB: LogbookModel): number => {
        return compareDates(logbookA.reportDate, logbookB.reportDate);
    }

    const splitLogbooks = (): LogbookModel[][] => {
        const result: LogbookModel[][] = [];
        let tempContainer: LogbookModel[] = [];

        logbooks.sort(compareLogbooks).forEach((logbook, index, collection) => {
            if (tempContainer.length === COUNT_LOGBOOKS_IN_ONE_COLUMN) {
                result.push([...tempContainer]);
                tempContainer = [logbook];
            }
            else {
                tempContainer.push(logbook);
            }
            if (index === collection.length - 1 && tempContainer.length > 0) {
                result.push([...tempContainer]);
            }
        });

        return result;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {splitLogbooks().map<JSX.Element>((lbs, index, collection) => {
                return (
                    <div key={`logbook-table-${index}`} style={{ marginLeft: '10px', flexGrow: 1, width: '100%' }}>
                        {getLogbookRows(lbs, index === collection.length - 1)}
                    </div>
                )
            })}
        </div>
    )
}