import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { IGeovisChartReportData } from "../../../../../../store/projectReport.types";
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../../../../../../store/types";
import { LoadingContainerSkeleton } from "../../../../../LoadingContainerSkeleton";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../../helpers/GeovisHooks";
import { t } from "../../../../../../i18n";
import { VibrationEventChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/VibrationEventChartData";
import { VibrationChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { getVibrationEventRenderOptionsSettings } from "../options/vibrationEventRenderOptions";
import { VIBRATION_EVENT_ACCELERATION, VIBRATION_EVENT_OCCURRENCE, VIBRATION_EVENT_VECTOR } from "../renders/VibrationEventChartRender";
import { SensorValueAttribute, getSensorValueAttributeToName } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { ErrorContainerSkeleton } from "../../../../../ErrorContainerSkeleton";
import ServerRoutesGen from "../../../../../../server/Routes/ServerRoutesGen";
import { fetchServerElementsByPost } from "../../../../../../helpers/ProjectDataHelper";
import { VibrationEventInfoRequestModel } from "../../../../../../server/GEOvis3/Model/GeovisChart/VibrationEventInfoRequestModel";
import { GeovisChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartData";


interface IComponentProps extends IWithGeovisServerProps {
    chart: VibrationChartModel;
    eventId: string;
    fullId: string;
}

interface IComponentState {
    chartData: IGeovisChartReportData<VibrationEventChartData>;
}

const Component = ({
    chart,
    Server,
    eventId,
    fullId
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        chartData: {
            ...defaultSomethingStorageState,
            dtsSectionInfos: [],
            Timestamp: 0,
            data: {
                ...new VibrationEventChartData()
            }
        }
    });

    useEffect(() => {
        (async function loadChartData() {
            const url = ServerRoutesGen.ReportRenderData.GetGeovisVibrationEventData.patch(chart.ProjectId);
            const payload: VibrationEventInfoRequestModel = {
                ChartId: 0,
                EventId: eventId,
                FullId: fullId,
                GeovisChart: chart
            };
            const response = await fetchServerElementsByPost<GeovisChartData, VibrationEventInfoRequestModel>(Server, url, payload);
            if (!response.Success) {
                setState({
                    ...state,
                    chartData: {
                        ...errorSomethingStorageState(...response.Messages),
                        dtsSectionInfos: [],
                        Timestamp: 0,
                        data: {
                            ...new VibrationEventChartData()
                        }
                    }
                });
                return;
            }
            setState({
                ...state,
                chartData: {
                    ...loadedSomethingStorageState,
                    data: response.Data as VibrationEventChartData,
                    dtsSectionInfos: [],
                    Timestamp: 0
                }
            })
        })()
    }, [1])

    if (!state.chartData.isLoaded) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <LoadingContainerSkeleton loadingText={t("Loading Vibration event preview chart data")} />
            </div>
        )
    }

    if (state.chartData.isError) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <ErrorContainerSkeleton errorText={`${t("Failed to load Vibration event chart data")}: ${state.chartData.errorDescription}`} />
            </div>
        )
    }

    const getSingleContainerHeight = (collectionLength: number): number => {
        return collectionLength > 1 ? 100 / collectionLength : 100;
    }

    const { data, Timestamp } = state.chartData

    const renderOptions = getVibrationEventRenderOptionsSettings(0, cloneDeep(chart), data, eventId);

    const accelerationRenderOptions = renderOptions.get(VIBRATION_EVENT_ACCELERATION);
    const freqRenderOptions = renderOptions.get(VIBRATION_EVENT_OCCURRENCE);
    const vectorRenderOptions = renderOptions.get(VIBRATION_EVENT_VECTOR);

    const eventHeader = data.EventHeader;

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);
    HighchartsBoost(Highcharts);

    return (
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>

            {chart.LeftEventAxisSettings.sensorValue === getSensorValueAttributeToName(SensorValueAttribute.ValuesXYZAndVector) &&
                <div style={{ display: 'grid', gridTemplateColumns: '22% 22% 22% 22% 12%', gridTemplateRows: '20px 20px 20px 20px', borderBottom: 'solid 2px black' }}>
                    <div style={{ fontWeight: 'bold' }}>
                        {t("Measurement place")}
                    </div>
                    <div>
                        {eventHeader.description}
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        {t("Vector Sum (VSum)")}
                    </div>
                    <div>
                        {`${eventHeader.vsum} [mm/s]`}
                    </div>
                    <div>
                        {/*  */}
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        {t("Sensor name")}
                    </div>
                    <div>
                        {eventHeader.name}
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        {t("Max X")}
                    </div>
                    <div>
                        {`${eventHeader.peakX} [mm/s]`}
                    </div>
                    <div>
                        {`${eventHeader.domFreqX} [Hz]`}
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        {t("Event number")}
                    </div>
                    <div>
                        {eventHeader.eventNumber}
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        {t("Max Y")}
                    </div>
                    <div>
                        {`${eventHeader.peakY} [mm/s]`}
                    </div>
                    <div>
                        {`${eventHeader.domFreqY} [Hz]`}
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        {t("Event trigger time")}
                    </div>
                    <div>
                        {eventHeader.eventTriggerTime}
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        {t("Max Z")}
                    </div>
                    <div>
                        {`${eventHeader.peakZ} [mm/s]`}
                    </div>
                    <div>
                        {`${eventHeader.domFreqZ} [Hz]`}
                    </div>
                </div>
            }
            <div className="report-chart-render" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', height: '100%', flexGrow: 3 }}>
                {accelerationRenderOptions &&
                    <div style={{ height: '100%', width: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
                        {accelerationRenderOptions.map<JSX.Element | null>((option, index, collection) => (
                            <HighchartsReact
                                key={`highcharts-event-acceleration-${chart.Id}-${index}-${Timestamp}`}
                                highcharts={Highcharts}
                                options={option}
                                constructorType={'chart'}
                                containerProps={{ style: { flexGrow: 1, height: `${getSingleContainerHeight(collection.length)}%` } }}
                            />
                        ))}
                    </div>
                }
                {freqRenderOptions &&
                    <div style={{ height: '100%', width: '100%', flexShrink: 2, borderLeft: 'solid 2px black', display: 'flex', flexFlow: 'column nowrap' }}>
                        {freqRenderOptions.map<JSX.Element | null>((option, index, collection) => (
                            <HighchartsReact
                                key={`highcharts-event-freq-${chart.Id}-${index}-${Timestamp}`}
                                highcharts={Highcharts}
                                options={option}
                                constructorType={'chart'}
                                containerProps={{ style: { flexGrow: 1, height: `${getSingleContainerHeight(collection.length)}%` } }}
                            />
                        ))}
                    </div>
                }
            </div>

            {vectorRenderOptions &&
                <div style={{ height: '100%', flexGrow: 1, flexShrink: 3, borderTop: 'solid 2px black', display: 'flex', flexFlow: 'column nowrap' }}>
                    {vectorRenderOptions.map<JSX.Element | null>((option, index, collection) => (
                        <HighchartsReact
                            key={`highcharts-event-vector-${chart.Id}-${index}-${Timestamp}`}
                            highcharts={Highcharts}
                            options={option}
                            constructorType={'chart'}
                            containerProps={{ style: { flexGrow: 1, height: `${getSingleContainerHeight(collection.length)}%` } }}
                        />
                    ))}
                </div>
            }

        </div>
    )

}

export const VibrationEventPreviewChartRender = withGeovisServer(Component);

