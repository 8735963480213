//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ProjectManualState } from './AVTService/TypeLibrary/Common/ProjectManualState';
import { ProjectLocalCoordinatesType } from './AVTService/TypeLibrary/Model/ProjectLocalCoordinatesType';
import { ProjectBusinessStatisticInfo } from './GEOvis3/Model/Project/ProjectBusinessStatisticInfo';
import { LicensedFeatures } from './AVTService/TypeLibrary/Licensing/LicensedFeatures';
import { GeovisLicenseType } from './AVTService/TypeLibrary/Common/GeovisLicenseType';

export class ProjectBusinessInfo
{
	public Id: number;
	public ProjectGuid: string;
	public Name: string;
	public IsPartOfGEOvis4: boolean;
	public LastStatisticTime: string;
	public BusinessState: ProjectManualState;
	public Number: string;
	public LocalCoordinatesType: ProjectLocalCoordinatesType;
	public LeaderName: string;
	public LeaderId: string;
	public UpdateStatisticNextNight: boolean;
	public UpdateStatisticEndOfMonth: boolean;
	public UpdateStatisticEndOfWeek: boolean;
	public StatisticInfo: ProjectBusinessStatisticInfo;
	public OwnerCompanyId: string;
	public CreatorCompaniesIds: string[];
	public InvoiceCompanyId: string;
	public AvailableFeatures: LicensedFeatures[];
	public InvoiceCompanyLicenseType: GeovisLicenseType;
	public IsNewInvoiceForCompanyEnabled: boolean;
}
