/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.04.2022
 * @description Timevalue Y-axis settings tab
 */

import { t } from '../../../../i18n';
import { ChartType } from '../../../../server/AVTService/TypeLibrary/Common/ChartType';
import { DtsChartSettings } from '../../../../server/AVTService/TypeLibrary/DtsConfiguration/DtsChartSettings';
import { GeovisChartAxisSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings';
import { TimeValueChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel';
import { SensorCategory, SensorCategoryOrdered } from '../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { DtsSectionInfoSlim } from '../../../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfoSlim';
import { SensorChangesModel } from '../../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel';
import { ChartAxisSensorsControl } from '../ChartAxisSensorsControl';
import { GeovisChartAxisSettingsControl } from '../GeovisChartAxisSettingsControl';
import { isChartTemplate, isDtsSensorTypeOfSeries } from '../tools';
import { IGeovisChartPropertyChangedProps, IGeovisChartSensorsPropertyProps } from '../types';
import { TimeValueChartDtsSectionsControl } from './TimeValueChartDtsSectionsControl';

interface ITimeValueChartYAxisTab extends IGeovisChartPropertyChangedProps<TimeValueChartModel>, IGeovisChartSensorsPropertyProps {
    chart: TimeValueChartModel;
    DtsSections: DtsSectionInfoSlim[];
}

/**
 * TimeValue chart Y-axis settings
 * @param props ITimeValueChartYAxisTab
 * @returns 
 */
export const TimeValueChartYAxisTab = ({ chart, onChartPropertyChanged, onChartPropertiesChanged, sensorsChanges, DtsSections }: ITimeValueChartYAxisTab) => {

    const { LeftYAxisSettings, RightYAxisSettings, DtsSettings } = chart;

    const getDtsSections = (typeOfSensor: SensorCategory) => {
        if (typeOfSensor === SensorCategory.DTS_ThermalConductivity) {
            return DtsSections.filter(s => s.IsHeatable);
        }
        return DtsSections;
    }

    const onAxisPropertyChangedHandler = (axisPropertyName: keyof TimeValueChartModel, axisSettings: GeovisChartAxisSettings) => (propertyName: keyof GeovisChartAxisSettings, value: any) => {

        const changedAxisSettings: Partial<GeovisChartAxisSettings> = { ...axisSettings };
        changedAxisSettings[propertyName] = value;

        onChartPropertyChanged(axisPropertyName, changedAxisSettings);
    }

    const onAxisPropertiesChangedHandlerFunc = (axisPropertyName: keyof TimeValueChartModel, axisSettings: GeovisChartAxisSettings) => (properties: Partial<GeovisChartAxisSettings>) => {
        const changedAxisSettings: GeovisChartAxisSettings = { ...axisSettings, ...properties };

        if (axisPropertyName === "LeftYAxisSettings" && properties.TypeOfSensor && isDtsSensorTypeOfSeries(properties.TypeOfSensor)) {
            const possibleDtsSectionIds = getDtsSections(properties.TypeOfSensor).map(section => section.Id);
            const newDtsSettings: DtsChartSettings = { ...DtsSettings, DtsSectionIds: DtsSettings.DtsSectionIds.filter(sectionId => possibleDtsSectionIds.includes(sectionId)) };
            const update: Partial<TimeValueChartModel> = {};
            update.LeftYAxisSettings = changedAxisSettings;
            update.DtsSettings = newDtsSettings;
            onChartPropertiesChanged(update);
        }
        else {
            onChartPropertyChanged<GeovisChartAxisSettings>(axisPropertyName, changedAxisSettings);
        }
    }

    const onAxisSensorsChangedHandler = (axisPropertyName: keyof TimeValueChartModel, axisSettings: GeovisChartAxisSettings) => (sensorIds: string[], customerChangeable: boolean, sensorChanges: SensorChangesModel[]) => {

        const newAxisSettings: GeovisChartAxisSettings = {
            ...axisSettings,
            SensorIds: [...sensorIds],
            IsSensorsCustomerChangeable: customerChangeable
        }

        // onChangeSensorsChanges(sensorChanges);
        onChartPropertyChanged(axisPropertyName, newAxisSettings, sensorChanges);
    }

    const showNagraSectionSelection = (): boolean => {
        return isDtsSensorTypeOfSeries(LeftYAxisSettings.TypeOfSensor);
    }

    const onAxisChainsChangedHandler = (axisPropertyName: keyof TimeValueChartModel, axisSettings: GeovisChartAxisSettings) => (chainIds: string[]) => {

        const newAxisSettings: GeovisChartAxisSettings = {
            ...axisSettings,
            ChainIds: [...chainIds],
        }

        onChartPropertyChanged(axisPropertyName, newAxisSettings);
    }

    const isChainPositionChart = LeftYAxisSettings.TypeOfSensor === SensorCategory.InclinometerChainPosition;
    const isTemplate = isChartTemplate(chart);

    const sensorTypesToSelect = SensorCategoryOrdered.filter(c => c !== SensorCategory.DTS_Position);

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={{ width: '49%' }}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t("Left Y-Axis")}</div>
                    <GeovisChartAxisSettingsControl
                        chartType={ChartType.TimeValue}
                        onAxisPropertiesChanged={onAxisPropertiesChangedHandlerFunc("LeftYAxisSettings", LeftYAxisSettings)}
                        onAxisPropertyChanged={onAxisPropertyChangedHandler("LeftYAxisSettings", LeftYAxisSettings)}
                        axisSettings={LeftYAxisSettings}
                        sensorTypesToSelect={sensorTypesToSelect}
                        isXAxis={false}
                        isRightAxis={false}
                        isDisabled={false}
                    />

                    {!isTemplate && isChainPositionChart &&
                        <ChartAxisSensorsControl
                            isChain={true}
                            chartType={chart.Type}
                            dtsSectionIds={[]}
                            projectId={chart.ProjectId}
                            ySensorType={LeftYAxisSettings.TypeOfSensor}
                            sensorIds={LeftYAxisSettings.ChainIds}
                            customerChangeable={LeftYAxisSettings.IsSensorsCustomerChangeable}
                            onChange={onAxisChainsChangedHandler("LeftYAxisSettings", chart.LeftYAxisSettings)}
                            sensorChanges={sensorsChanges}
                            showPairsDialog={false}
                            selectedChainsIds={LeftYAxisSettings.ChainIds}
                            isSingle={false}
                            hideCustomerChangeable={true}
                        />
                    }

                    {!isTemplate && !showNagraSectionSelection() &&
                        <ChartAxisSensorsControl
                            isChain={false}
                            chartType={chart.Type}
                            dtsSectionIds={[]}
                            projectId={chart.ProjectId}
                            ySensorType={LeftYAxisSettings.TypeOfSensor}
                            sensorIds={LeftYAxisSettings.SensorIds}
                            customerChangeable={LeftYAxisSettings.IsSensorsCustomerChangeable}
                            onChange={onAxisSensorsChangedHandler("LeftYAxisSettings", chart.LeftYAxisSettings)}
                            sensorChanges={sensorsChanges}
                            showPairsDialog={false}
                            selectedChainsIds={LeftYAxisSettings.ChainIds}
                            isSingle={false}
                        />
                    }
                </div>

                <div className="flexCellContainer_g1 separator">
                    {/** */}
                </div>

                <div className="flexCellContainer" style={{ width: '49%' }}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t("Right Y-Axis")}</div>
                    <GeovisChartAxisSettingsControl
                        chartType={ChartType.TimeValue}
                        onAxisPropertiesChanged={onAxisPropertiesChangedHandlerFunc("RightYAxisSettings", RightYAxisSettings)}
                        onAxisPropertyChanged={onAxisPropertyChangedHandler("RightYAxisSettings", RightYAxisSettings)}
                        axisSettings={RightYAxisSettings}
                        sensorTypesToSelect={sensorTypesToSelect}
                        isXAxis={false}
                        isRightAxis={true}
                        isDisabled={false}
                    />

                    {!isTemplate && !showNagraSectionSelection() &&
                        <ChartAxisSensorsControl
                            isChain={false}
                            chartType={chart.Type}
                            dtsSectionIds={[]}
                            projectId={chart.ProjectId}
                            ySensorType={RightYAxisSettings.TypeOfSensor}
                            // xSensorType={compareChartTypes(Chart.Type, [ChartType.XYDiagram]) ? (Chart as XyChartModel).XAxisSettings.TypeOfSensor : undefined} // undefined for DtsChartModel
                            sensorIds={RightYAxisSettings.SensorIds}
                            customerChangeable={RightYAxisSettings.IsSensorsCustomerChangeable}
                            onChange={onAxisSensorsChangedHandler("RightYAxisSettings", RightYAxisSettings)}
                            sensorChanges={sensorsChanges}
                            showPairsDialog={false} // pairs dialog for XyChart
                            selectedChainsIds={[]} // in this dialog we do not need those ids
                            isSingle={false}
                        // onChangeXyChartSensorsPairs={undefined}
                        // sensorsPairs={(Chart as XyChartModel) ? (Chart as XyChartModel).SensorPairs : undefined} // only for XyChart
                        />
                    }
                </div>
            </div>
            {showNagraSectionSelection() &&
                <div className="flexRowContainer">
                    <TimeValueChartDtsSectionsControl
                        isTemplate={isTemplate}
                        DtsSections={getDtsSections(LeftYAxisSettings.TypeOfSensor)}
                        chartDtsInfo={chart.DtsSettings}
                        onChartPropertyChanged={onChartPropertyChanged}
                    />
                </div>
            }
        </div>
    )
}