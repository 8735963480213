//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ProjectElementMigrationState {
	NotMigrated = 0,
	Ok = 1,
	Warning = 2,
	Error = 3,
	Rollback = 4
}

const ProjectElementMigrationStateToName = new Map<number, string>([
	[ 0, 'NotMigrated' ],
	[ 1, 'Ok' ],
	[ 2, 'Warning' ],
	[ 3, 'Error' ],
	[ 4, 'Rollback' ],
])

export function getProjectElementMigrationStateToName(element: ProjectElementMigrationState) : string {
    return ProjectElementMigrationStateToName.get(element) || '';
}

const ProjectElementMigrationStateByName = new Map<string, number>([
	[ 'NotMigrated', 0 ],
	[ 'Ok', 1 ],
	[ 'Warning', 2 ],
	[ 'Error', 3 ],
	[ 'Rollback', 4 ],
]);

export function getProjectElementMigrationStateByName(name: string): ProjectElementMigrationState {
    return ProjectElementMigrationStateByName.get(name)!;
}


export const ProjectElementMigrationStateList : Readonly<ProjectElementMigrationState[]> = [
	ProjectElementMigrationState.NotMigrated,
	ProjectElementMigrationState.Ok,
	ProjectElementMigrationState.Warning,
	ProjectElementMigrationState.Error,
	ProjectElementMigrationState.Rollback,
];



const ProjectElementMigrationStateToDescription = new Map<number, string>([
	[ 0, 'NotMigrated' ],
	[ 1, 'Ok' ],
	[ 2, 'Warning' ],
	[ 3, 'Error' ],
	[ 4, 'Rollback' ],
])

export function getProjectElementMigrationStateToDescription(element: ProjectElementMigrationState) : string {
    return ProjectElementMigrationStateToDescription.get(element) || '';
}

