/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 29.06.2020
 * @description Sensors and chains popup elements implementation
 */

import Button from '@atlaskit/button';
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left';
import { Popup } from 'react-leaflet';
import { t } from '../../../i18n';
import { ChainInfo } from '../../../server/ChainInfo';
import { SensorInfo } from '../../../server/GEOvis3/Model/SensorInfo';
import AuthService from '../../../services/AuthService';
import ChainLastMeasurement, { ICustomChainTooltipContentProps } from './ChainLastMeasurement';
import {
    AlarmPlanPopupContent,
    AlarmPopupContent,
    ChartsPopupContent,
    DefaultChartsPopupContent,
    DocumentsPopupContent,
    ImagePopupContent,
    MapItemPopupHeaderAsLink,
    PopupChainHeader,
    ReportsPopupContent,
    SensorDataLinkPopupContent,
    SensorLinkPopupContent,
    SettingsPopupContent
} from './PopupContentControls';
import SensorLastMeasurement, { ICustomSensorTooltipContentProps } from './SensorLastMeasurement';
import { SensorCategory } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';


//#region Single sensors popup content

interface ISingleSensorPopupProps extends ICustomSensorTooltipContentProps {
    projectId: number;
    sensor: SensorInfo;
    onBack?: () => void;
    onShowChartConfig: (chartId: number) => void;
    onShowAlarmConfig: (alarmId: string) => void;
}

/**
 * Single sensor popup content
 * @param props
 */
export const SingleSensorPopup = ({
    projectId,
    sensor,
    onBack,
    onShowChartConfig,
    onShowAlarmConfig,
    customSensorsLastDataStorage
}: ISingleSensorPopupProps) => (
    <Popup
        key={`sensor_popup_${sensor.Id}`}
        keepInView={true}>
        {onBack && (<PopupBackButton onClick={onBack} />)}

        {/* popup header */}
        <MapItemPopupHeaderAsLink sensor={sensor} />

        {/* last measurement data */}
        <hr />
        <SensorLastMeasurement
            sensor={sensor}
            style={{ minWidth: '250px' }}
            isPopup={true}
            customSensorsLastDataStorage={customSensorsLastDataStorage}
        />

        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            {/* draw default chart popup content */}
            <DefaultChartsPopupContent projectId={projectId} sensor={sensor} />

            {sensor.ImageInfo !== null && sensor.ImageInfo.FileId !== '' &&
                <ImagePopupContent projectId={projectId} sensor={sensor} />
            }

            {AuthService.isActualAdminOfProject(projectId) && <SensorDataLinkPopupContent projectId={projectId} sensor={sensor} />}

            {AuthService.isActualAdminOfProject(projectId) && sensor.PhysicalType !== SensorCategory.VibrationSensor && <SettingsPopupContent projectId={projectId} sensor={sensor} />}

        </div>

        <hr />

        {/* draw alarm popup content */}
        <AlarmPopupContent projectId={projectId} sensor={sensor} onOpenAlarmEditDialog={onShowAlarmConfig} />

        {/* draw chart popup content */}
        <ChartsPopupContent projectId={projectId} sensor={sensor} onShowChartConfig={onShowChartConfig} />

        {/* draw chart popup content */}
        <AlarmPlanPopupContent projectId={projectId} sensor={sensor} onOpenAlarmEditDialog={onShowAlarmConfig} />

        {/* draw reports popup content */}
        <ReportsPopupContent projectId={projectId} sensor={sensor} />

        {/* draw related documents popup content */}
        <DocumentsPopupContent documents={sensor.RelatedDocuments} projectId={projectId} />

        {/* draw sensor link content */}
        <SensorLinkPopupContent sensorLink={sensor.SensorLink} sensorLinkName={sensor.SensorLinkName} />
    </Popup>
);

//#endregion

//#region  Chain popup components

interface ISingleChainPopupProps extends ICustomChainTooltipContentProps {
    projectId: number;
    chain: ChainInfo;
    onBack?: () => void;
    onShowChartConfig: (chartId: number) => void;
}

export const SingleChainPopup = (props: ISingleChainPopupProps) => (
    <Popup
        key={`chain_popup_${props.projectId}`}
        keepInView={true}>
        <SingleChainPopupContent {...props} />
    </Popup>
)

const SingleChainPopupContent = (props: ISingleChainPopupProps) => {
    const { chain, projectId, onBack, onShowChartConfig } = props;

    return (
        <div>
            {onBack && <PopupBackButton onClick={onBack} />}
            <PopupChainHeader {...chain} />
            <hr />
            <ChainLastMeasurement chain={props.chain} customChainsLastDataStorage={props.customChainsLastDataStorage} />
            {/* draw default chart popup content */}
            <DefaultChartsPopupContent projectId={projectId} sensor={chain} />
            <hr />
            {/* draw alarm popup content */}
            <AlarmPopupContent projectId={projectId} sensor={chain} />

            {/* draw chart popup content */}
            <ChartsPopupContent projectId={projectId} sensor={chain} onShowChartConfig={onShowChartConfig} />

            {/* draw reports popup content */}
            <ReportsPopupContent projectId={projectId} sensor={chain} />
        </div>
    )
}

//#endregion

interface IPopupBackButtonProps {
    onClick: () => void;
}

const PopupBackButton = (props: IPopupBackButtonProps) => (
    <div>
        <Button
            appearance="subtle"
            iconBefore={<ChevronLeftIcon label="Back" />}
            spacing="compact"
            label={t("Back")}
            {...props} />
    </div>
)