//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PhysicalUnit {
	Undefined = 0,
	Meter = 1,
	Millimeter = 2,
	Pascal = 3,
	Radiant = 4,
	Degree = 5,
	GON = 6,
	Kelvin = 7,
	Celsius = 8,
	Milliamper = 9,
	Ohm = 10,
	Hectopascal = 11,
	Megapascal = 12,
	MeterPerSecond = 13,
	WattPerSquareMeter = 14,
	Newton = 15,
	Volt = 16,
	M3ps = 17,
	M3ph = 18,
	Percent = 19,
	Promille = 20,
	Kilometer = 21,
	Herz = 22,
	MillimeterPerSecondInQuad = 23,
	MillimeterPerSecond = 24,
	Second = 25,
	hPa = 26,
	KiloNewton = 27,
	m3ps = 28,
	m3ph = 29,
	m3pm = 30,
	NTU = 31,
	pH = 32,
	M3 = 33,
	MillimeterPerMeter = 34,
	dB = 35,
	mmph = 36,
	Centimeter = 37,
	MeterPerMeter = 38,
	LiterPerHour = 39,
	kOhm = 40,
	MOhm = 41,
	KilometerPerHour = 42,
	mgon = 43,
	cgon = 44,
	KiloPascal = 45,
	mlnm = 46,
	VolumePercent = 47,
	Amper = 48,
	Watt = 49,
	pWperpW = 50,
	WpmpK = 51,
	RHPercent = 52,
	CmpW = 53,
	WC = 55,
	MilliSecond = 56,
	Minute = 57,
	Hour = 58,
	Day = 59,
	VPM = 60,
	PromileVPDB = 61,
	PromileVSMOW = 62,
	PromileVCDT = 63,
	MilliBar = 64,
	KiloHerz = 65,
	MegaHerz = 66,
	CcmpW = 67,
	CM3STPW = 68,
	NanoSecond = 69,
	KiloWatt = 70,
	VDC = 71,
	VAC = 72,
	MilliVolt = 73,
	NTemp = 74,
	Decimeter = 75,
	Microstrain = 76,
	Bar = 77,
	SiemensPerMeter = 78,
	MilliSiemensPerMeter = 79,
	MilliSiemensPerCentimeter = 80,
	MillimeterH2O = 81,
	MeterH2O = 82,
	dBm = 83,
	mmPerH = 84,
	cmPerH = 85,
	dmPerH = 86,
	mPerH = 87,
	mmPerDay = 88,
	cmPerDay = 89,
	dmPerDay = 90,
	mPerDay = 91,
	mmPerWeek = 92,
	cmPerWeek = 93,
	dmPerWeek = 94,
	mPerWeek = 95,
	mmPerMonth = 96,
	cmPerMonth = 97,
	dmPerMonth = 98,
	mPerMonth = 99,
	mmPerYear = 100,
	cmPerYear = 101,
	dmPerYear = 102,
	mPerYear = 103,
	MeterPerSecondInQuad = 104,
	VoltAmpereReactiveHour = 105,
	KiloVoltAmpereReactiveHour = 106,
	Lux = 107,
	PartsPerHundert = 108,
	PartsPerMillion = 109,
	PartsPerBillion = 110,
	PartsPerTrillion = 111,
	Ton = 112,
	KiloGramm = 113,
	Gramm = 114,
	MilliGramm = 115,
	MilliliterPerMinute = 116,
	Milliliter = 117,
	MicroSiemensPerMeter = 118,
	GrammPerHour = 119
}

const PhysicalUnitToDigitsAfterDecimalPoint = new Map<number, number>([
    [ PhysicalUnit.Undefined, 0 ],
    [ PhysicalUnit.Meter, 3 ],
    [ PhysicalUnit.Millimeter, 1 ],
    [ PhysicalUnit.Pascal, 0 ],
    [ PhysicalUnit.Radiant, 6 ],
    [ PhysicalUnit.Degree, 3 ],
    [ PhysicalUnit.GON, 3 ],
    [ PhysicalUnit.Kelvin, 1 ],
    [ PhysicalUnit.Celsius, 1 ],
    [ PhysicalUnit.Milliamper, 1 ],
    [ PhysicalUnit.Ohm, 1 ],
    [ PhysicalUnit.Hectopascal, 1 ],
    [ PhysicalUnit.Megapascal, 3 ],
    [ PhysicalUnit.MeterPerSecond, 1 ],
    [ PhysicalUnit.WattPerSquareMeter, 1 ],
    [ PhysicalUnit.Newton, 1 ],
    [ PhysicalUnit.Volt, 1 ],
    [ PhysicalUnit.M3ps, 3 ],
    [ PhysicalUnit.M3ph, 2 ],
    [ PhysicalUnit.Percent, 1 ],
    [ PhysicalUnit.Promille, 1 ],
    [ PhysicalUnit.Kilometer, 3 ],
    [ PhysicalUnit.Herz, 1 ],
    [ PhysicalUnit.MillimeterPerSecondInQuad, 2 ],
    [ PhysicalUnit.MillimeterPerSecond, 1 ],
    [ PhysicalUnit.Second, 1 ],
    [ PhysicalUnit.hPa, 2 ],
    [ PhysicalUnit.KiloNewton, 1 ],
    [ PhysicalUnit.m3ps, 3 ],
    [ PhysicalUnit.m3ph, 2 ],
    [ PhysicalUnit.m3pm, 3 ],
    [ PhysicalUnit.NTU, 0 ],
    [ PhysicalUnit.pH, 1 ],
    [ PhysicalUnit.M3, 3 ],
    [ PhysicalUnit.MillimeterPerMeter, 1 ],
    [ PhysicalUnit.dB, 0 ],
    [ PhysicalUnit.mmph, 1 ],
    [ PhysicalUnit.Centimeter, 1 ],
    [ PhysicalUnit.MeterPerMeter, 3 ],
    [ PhysicalUnit.LiterPerHour, 1 ],
    [ PhysicalUnit.kOhm, 2 ],
    [ PhysicalUnit.MOhm, 2 ],
    [ PhysicalUnit.KilometerPerHour, 0 ],
    [ PhysicalUnit.mgon, 1 ],
    [ PhysicalUnit.cgon, 1 ],
    [ PhysicalUnit.KiloPascal, 3 ],
    [ PhysicalUnit.mlnm, 4 ],
    [ PhysicalUnit.VolumePercent, 2 ],
    [ PhysicalUnit.Amper, 3 ],
    [ PhysicalUnit.Watt, 3 ],
    [ PhysicalUnit.pWperpW, 2 ],
    [ PhysicalUnit.WpmpK, 1 ],
    [ PhysicalUnit.RHPercent, 1 ],
    [ PhysicalUnit.CmpW, 1 ],
    [ PhysicalUnit.WC, 3 ],
    [ PhysicalUnit.MilliSecond, 1 ],
    [ PhysicalUnit.Minute, 1 ],
    [ PhysicalUnit.Hour, 1 ],
    [ PhysicalUnit.Day, 1 ],
    [ PhysicalUnit.VPM, 1 ],
    [ PhysicalUnit.PromileVPDB, 4 ],
    [ PhysicalUnit.PromileVSMOW, 4 ],
    [ PhysicalUnit.PromileVCDT, 4 ],
    [ PhysicalUnit.MilliBar, 2 ],
    [ PhysicalUnit.KiloHerz, 1 ],
    [ PhysicalUnit.MegaHerz, 1 ],
    [ PhysicalUnit.CcmpW, 1 ],
    [ PhysicalUnit.CM3STPW, 4 ],
    [ PhysicalUnit.NanoSecond, 2 ],
    [ PhysicalUnit.KiloWatt, 3 ],
    [ PhysicalUnit.VDC, 2 ],
    [ PhysicalUnit.VAC, 2 ],
    [ PhysicalUnit.MilliVolt, 2 ],
    [ PhysicalUnit.NTemp, 2 ],
    [ PhysicalUnit.Decimeter, 3 ],
    [ PhysicalUnit.Microstrain, 3 ],
    [ PhysicalUnit.Bar, 3 ],
    [ PhysicalUnit.SiemensPerMeter, 3 ],
    [ PhysicalUnit.MilliSiemensPerMeter, 3 ],
    [ PhysicalUnit.MilliSiemensPerCentimeter, 3 ],
    [ PhysicalUnit.MillimeterH2O, 1 ],
    [ PhysicalUnit.MeterH2O, 3 ],
    [ PhysicalUnit.dBm, 1 ],
    [ PhysicalUnit.mmPerH, 1 ],
    [ PhysicalUnit.cmPerH, 1 ],
    [ PhysicalUnit.dmPerH, 1 ],
    [ PhysicalUnit.mPerH, 1 ],
    [ PhysicalUnit.mmPerDay, 1 ],
    [ PhysicalUnit.cmPerDay, 1 ],
    [ PhysicalUnit.dmPerDay, 1 ],
    [ PhysicalUnit.mPerDay, 1 ],
    [ PhysicalUnit.mmPerWeek, 1 ],
    [ PhysicalUnit.cmPerWeek, 1 ],
    [ PhysicalUnit.dmPerWeek, 1 ],
    [ PhysicalUnit.mPerWeek, 1 ],
    [ PhysicalUnit.mmPerMonth, 1 ],
    [ PhysicalUnit.cmPerMonth, 1 ],
    [ PhysicalUnit.dmPerMonth, 1 ],
    [ PhysicalUnit.mPerMonth, 1 ],
    [ PhysicalUnit.mmPerYear, 1 ],
    [ PhysicalUnit.cmPerYear, 1 ],
    [ PhysicalUnit.dmPerYear, 1 ],
    [ PhysicalUnit.mPerYear, 1 ],
    [ PhysicalUnit.MeterPerSecondInQuad, 1 ],
    [ PhysicalUnit.VoltAmpereReactiveHour, 1 ],
    [ PhysicalUnit.KiloVoltAmpereReactiveHour, 1 ],
    [ PhysicalUnit.Lux, 1 ],
    [ PhysicalUnit.PartsPerHundert, 1 ],
    [ PhysicalUnit.PartsPerMillion, 1 ],
    [ PhysicalUnit.PartsPerBillion, 1 ],
    [ PhysicalUnit.PartsPerTrillion, 1 ],
    [ PhysicalUnit.Ton, 1 ],
    [ PhysicalUnit.KiloGramm, 1 ],
    [ PhysicalUnit.Gramm, 1 ],
    [ PhysicalUnit.MilliGramm, 1 ],
    [ PhysicalUnit.MilliliterPerMinute, 4 ],
    [ PhysicalUnit.Milliliter, 4 ],
    [ PhysicalUnit.MicroSiemensPerMeter, 3 ],
    [ PhysicalUnit.GrammPerHour, 1 ]]);

export function getDigitsAfterDecimalPoint(enumValue: PhysicalUnit): number {
    return PhysicalUnitToDigitsAfterDecimalPoint.get(enumValue) || 0;
}


const PhysicalUnitToPhysicalUnitShortName = new Map<number, string>([
    [ PhysicalUnit.Undefined, '--' ],
    [ PhysicalUnit.Meter, 'm' ],
    [ PhysicalUnit.Millimeter, 'mm' ],
    [ PhysicalUnit.Pascal, 'Pa' ],
    [ PhysicalUnit.Radiant, 'rad' ],
    [ PhysicalUnit.Degree, '°' ],
    [ PhysicalUnit.GON, 'gon' ],
    [ PhysicalUnit.Kelvin, 'K' ],
    [ PhysicalUnit.Celsius, '°C' ],
    [ PhysicalUnit.Milliamper, 'mA' ],
    [ PhysicalUnit.Ohm, 'Ω' ],
    [ PhysicalUnit.Hectopascal, 'hPa' ],
    [ PhysicalUnit.Megapascal, 'MPa' ],
    [ PhysicalUnit.MeterPerSecond, 'm/s' ],
    [ PhysicalUnit.WattPerSquareMeter, 'W/m²' ],
    [ PhysicalUnit.Newton, 'N' ],
    [ PhysicalUnit.Volt, 'V' ],
    [ PhysicalUnit.Percent, '%' ],
    [ PhysicalUnit.Promille, '‰' ],
    [ PhysicalUnit.Kilometer, 'km' ],
    [ PhysicalUnit.Herz, 'Hz' ],
    [ PhysicalUnit.MillimeterPerSecondInQuad, 'mm/s²' ],
    [ PhysicalUnit.MillimeterPerSecond, 'mm/s' ],
    [ PhysicalUnit.Second, 's' ],
    [ PhysicalUnit.KiloNewton, 'kN' ],
    [ PhysicalUnit.m3ps, 'm³/s' ],
    [ PhysicalUnit.m3ph, 'm³/h' ],
    [ PhysicalUnit.m3pm, 'm³/min' ],
    [ PhysicalUnit.NTU, 'NTU' ],
    [ PhysicalUnit.pH, 'pH' ],
    [ PhysicalUnit.M3, 'm³' ],
    [ PhysicalUnit.MillimeterPerMeter, 'mm/m' ],
    [ PhysicalUnit.dB, 'dB' ],
    [ PhysicalUnit.mmph, 'mm/h' ],
    [ PhysicalUnit.Centimeter, 'cm' ],
    [ PhysicalUnit.MeterPerMeter, 'm.ü.M' ],
    [ PhysicalUnit.LiterPerHour, 'dm³/h' ],
    [ PhysicalUnit.kOhm, 'kΩ' ],
    [ PhysicalUnit.MOhm, 'MΩ' ],
    [ PhysicalUnit.KilometerPerHour, 'km/h' ],
    [ PhysicalUnit.mgon, 'mgon' ],
    [ PhysicalUnit.cgon, 'cgon' ],
    [ PhysicalUnit.KiloPascal, 'kPa' ],
    [ PhysicalUnit.mlnm, 'mlₙ/min' ],
    [ PhysicalUnit.VolumePercent, 'Vol-%' ],
    [ PhysicalUnit.Amper, 'A' ],
    [ PhysicalUnit.Watt, 'W' ],
    [ PhysicalUnit.pWperpW, 'pW/pW' ],
    [ PhysicalUnit.WpmpK, 'W/(m*K)' ],
    [ PhysicalUnit.RHPercent, '%RH' ],
    [ PhysicalUnit.CmpW, '(K*m)/W' ],
    [ PhysicalUnit.WC, 'm3/m3' ],
    [ PhysicalUnit.MilliSecond, 'ms' ],
    [ PhysicalUnit.Minute, 'm' ],
    [ PhysicalUnit.Hour, 'h' ],
    [ PhysicalUnit.Day, 'd' ],
    [ PhysicalUnit.VPM, 'vpm' ],
    [ PhysicalUnit.PromileVPDB, '‰-VPDB' ],
    [ PhysicalUnit.PromileVSMOW, '‰-VSMOW' ],
    [ PhysicalUnit.PromileVCDT, '‰-VCDT' ],
    [ PhysicalUnit.MilliBar, 'mbar' ],
    [ PhysicalUnit.KiloHerz, 'kHz' ],
    [ PhysicalUnit.MegaHerz, 'MHz' ],
    [ PhysicalUnit.CcmpW, '(K*cm)/W' ],
    [ PhysicalUnit.CM3STPW, 'cm3STP/cm3STP' ],
    [ PhysicalUnit.NanoSecond, 'ns' ],
    [ PhysicalUnit.KiloWatt, 'kW' ],
    [ PhysicalUnit.VDC, 'VDC' ],
    [ PhysicalUnit.VAC, 'VAC' ],
    [ PhysicalUnit.MilliVolt, 'mV' ],
    [ PhysicalUnit.NTemp, '(m*mK)/(ln(s)*W)' ],
    [ PhysicalUnit.Decimeter, 'dm' ],
    [ PhysicalUnit.Microstrain, 'ue' ],
    [ PhysicalUnit.Bar, 'Bar' ],
    [ PhysicalUnit.SiemensPerMeter, 'S/m' ],
    [ PhysicalUnit.MilliSiemensPerMeter, 'mS/m' ],
    [ PhysicalUnit.MilliSiemensPerCentimeter, 'mS/cm' ],
    [ PhysicalUnit.MillimeterH2O, 'mmH2O' ],
    [ PhysicalUnit.MeterH2O, 'mH2O' ],
    [ PhysicalUnit.dBm, 'dBm' ],
    [ PhysicalUnit.mmPerH, 'mm/h' ],
    [ PhysicalUnit.cmPerH, 'cm/h' ],
    [ PhysicalUnit.dmPerH, 'dm/h' ],
    [ PhysicalUnit.mPerH, 'm/h' ],
    [ PhysicalUnit.mmPerDay, 'mm/d' ],
    [ PhysicalUnit.cmPerDay, 'cm/d' ],
    [ PhysicalUnit.dmPerDay, 'dm/d' ],
    [ PhysicalUnit.mPerDay, 'm/d' ],
    [ PhysicalUnit.mmPerWeek, 'mm/w' ],
    [ PhysicalUnit.cmPerWeek, 'cm/w' ],
    [ PhysicalUnit.dmPerWeek, 'dm/w' ],
    [ PhysicalUnit.mPerWeek, 'm/w' ],
    [ PhysicalUnit.mmPerMonth, 'mm/Month' ],
    [ PhysicalUnit.cmPerMonth, 'cm/Month' ],
    [ PhysicalUnit.dmPerMonth, 'dm/Month' ],
    [ PhysicalUnit.mPerMonth, 'm/Month' ],
    [ PhysicalUnit.mmPerYear, 'mm/Year' ],
    [ PhysicalUnit.cmPerYear, 'cm/Year' ],
    [ PhysicalUnit.dmPerYear, 'dm/Year' ],
    [ PhysicalUnit.mPerYear, 'm/Year' ],
    [ PhysicalUnit.MeterPerSecondInQuad, 'm/s²' ],
    [ PhysicalUnit.VoltAmpereReactiveHour, 'VArh' ],
    [ PhysicalUnit.KiloVoltAmpereReactiveHour, 'kVArh' ],
    [ PhysicalUnit.Lux, 'lux' ],
    [ PhysicalUnit.PartsPerHundert, 'pph' ],
    [ PhysicalUnit.PartsPerMillion, 'ppm' ],
    [ PhysicalUnit.PartsPerBillion, 'ppb' ],
    [ PhysicalUnit.PartsPerTrillion, 'ppt' ],
    [ PhysicalUnit.Ton, 'T' ],
    [ PhysicalUnit.KiloGramm, 'kg' ],
    [ PhysicalUnit.Gramm, 'g' ],
    [ PhysicalUnit.MilliGramm, 'mg' ],
    [ PhysicalUnit.MilliliterPerMinute, 'ml/min' ],
    [ PhysicalUnit.Milliliter, 'ml' ],
    [ PhysicalUnit.MicroSiemensPerMeter, 'microS/m' ],
    [ PhysicalUnit.GrammPerHour, 'g/h' ]]);

export function getPhysicalUnitShortName(enumValue: PhysicalUnit): string {
    return PhysicalUnitToPhysicalUnitShortName.get(enumValue) || '';
}


const PhysicalUnitToPhysicalUnitFilter = new Map<number, string>([
    [ PhysicalUnit.Undefined, '--' ],
    [ PhysicalUnit.Meter, 'm' ],
    [ PhysicalUnit.Millimeter, 'mm' ],
    [ PhysicalUnit.Pascal, 'Pa' ],
    [ PhysicalUnit.Radiant, 'rad' ],
    [ PhysicalUnit.Degree, 'deg' ],
    [ PhysicalUnit.GON, 'gon' ],
    [ PhysicalUnit.Kelvin, 'K' ],
    [ PhysicalUnit.Celsius, 'C' ],
    [ PhysicalUnit.Milliamper, 'mA' ],
    [ PhysicalUnit.Ohm, 'Ohm' ],
    [ PhysicalUnit.Hectopascal, 'hPa' ],
    [ PhysicalUnit.Megapascal, 'MPa' ],
    [ PhysicalUnit.MeterPerSecond, 'mps' ],
    [ PhysicalUnit.WattPerSquareMeter, 'Wpm2' ],
    [ PhysicalUnit.Newton, 'N' ],
    [ PhysicalUnit.Volt, 'V' ],
    [ PhysicalUnit.Percent, 'ppc' ],
    [ PhysicalUnit.Promille, 'ppm' ],
    [ PhysicalUnit.Kilometer, 'km' ],
    [ PhysicalUnit.Herz, 'Hz' ],
    [ PhysicalUnit.MillimeterPerSecondInQuad, 'mmps2' ],
    [ PhysicalUnit.MillimeterPerSecond, 'mmps' ],
    [ PhysicalUnit.Second, 'sec' ],
    [ PhysicalUnit.KiloNewton, 'kN' ],
    [ PhysicalUnit.m3ps, 'm3ps' ],
    [ PhysicalUnit.m3ph, 'm3ph' ],
    [ PhysicalUnit.m3pm, 'm3pm' ],
    [ PhysicalUnit.NTU, 'NTU' ],
    [ PhysicalUnit.pH, 'pH' ],
    [ PhysicalUnit.M3, 'm3' ],
    [ PhysicalUnit.MillimeterPerMeter, 'mmpm' ],
    [ PhysicalUnit.dB, 'dB' ],
    [ PhysicalUnit.mmph, 'mmph' ],
    [ PhysicalUnit.Centimeter, 'cm' ],
    [ PhysicalUnit.MeterPerMeter, 'm' ],
    [ PhysicalUnit.LiterPerHour, 'd3ph' ],
    [ PhysicalUnit.kOhm, 'kOhm' ],
    [ PhysicalUnit.MOhm, 'Mohm' ],
    [ PhysicalUnit.KilometerPerHour, 'kmph' ],
    [ PhysicalUnit.mgon, 'mgon' ],
    [ PhysicalUnit.cgon, 'cgon' ],
    [ PhysicalUnit.KiloPascal, 'kPa' ],
    [ PhysicalUnit.mlnm, 'mlnm' ],
    [ PhysicalUnit.VolumePercent, 'VOL-P' ],
    [ PhysicalUnit.Amper, 'A' ],
    [ PhysicalUnit.Watt, 'Watt' ],
    [ PhysicalUnit.pWperpW, 'Stoke' ],
    [ PhysicalUnit.WpmpK, 'WpMpK' ],
    [ PhysicalUnit.RHPercent, 'RHP' ],
    [ PhysicalUnit.CmpW, 'CmpW' ],
    [ PhysicalUnit.WC, 'WC' ],
    [ PhysicalUnit.MilliSecond, 'ms' ],
    [ PhysicalUnit.Minute, 'min' ],
    [ PhysicalUnit.Hour, 'hour' ],
    [ PhysicalUnit.Day, 'day' ],
    [ PhysicalUnit.VPM, 'VPM' ],
    [ PhysicalUnit.PromileVPDB, 'PPMVPDB' ],
    [ PhysicalUnit.PromileVSMOW, 'PPMVSMOW' ],
    [ PhysicalUnit.PromileVCDT, 'PPMVCDT' ],
    [ PhysicalUnit.MilliBar, 'mbar' ],
    [ PhysicalUnit.KiloHerz, 'kHz' ],
    [ PhysicalUnit.MegaHerz, 'MHz' ],
    [ PhysicalUnit.CcmpW, 'CcmpW' ],
    [ PhysicalUnit.CM3STPW, 'CM3STP' ],
    [ PhysicalUnit.NanoSecond, 'ns' ],
    [ PhysicalUnit.KiloWatt, 'kW' ],
    [ PhysicalUnit.VDC, 'VDC' ],
    [ PhysicalUnit.VAC, 'VAC' ],
    [ PhysicalUnit.MilliVolt, 'mV' ],
    [ PhysicalUnit.NTemp, 'NTemp' ],
    [ PhysicalUnit.Decimeter, 'dm' ],
    [ PhysicalUnit.Microstrain, 'ue' ],
    [ PhysicalUnit.Bar, 'Bar' ],
    [ PhysicalUnit.SiemensPerMeter, 'S/m' ],
    [ PhysicalUnit.MilliSiemensPerMeter, 'mS/m' ],
    [ PhysicalUnit.MilliSiemensPerCentimeter, 'mS/cm' ],
    [ PhysicalUnit.MillimeterH2O, 'mmH2O' ],
    [ PhysicalUnit.MeterH2O, 'mH2O' ],
    [ PhysicalUnit.dBm, 'dBm' ],
    [ PhysicalUnit.mmPerH, 'mmph' ],
    [ PhysicalUnit.cmPerH, 'cmph' ],
    [ PhysicalUnit.dmPerH, 'dmph' ],
    [ PhysicalUnit.mPerH, 'mph' ],
    [ PhysicalUnit.mmPerDay, 'mmpd' ],
    [ PhysicalUnit.cmPerDay, 'cmpd' ],
    [ PhysicalUnit.dmPerDay, 'dmpd' ],
    [ PhysicalUnit.mPerDay, 'mpd' ],
    [ PhysicalUnit.mmPerWeek, 'mmpw' ],
    [ PhysicalUnit.cmPerWeek, 'cmpw' ],
    [ PhysicalUnit.dmPerWeek, 'dmpw' ],
    [ PhysicalUnit.mPerWeek, 'mpw' ],
    [ PhysicalUnit.mmPerMonth, 'mmpMonth' ],
    [ PhysicalUnit.cmPerMonth, 'cmpMonth' ],
    [ PhysicalUnit.dmPerMonth, 'dmpMonth' ],
    [ PhysicalUnit.mPerMonth, 'mpMonth' ],
    [ PhysicalUnit.mmPerYear, 'mmpYear' ],
    [ PhysicalUnit.cmPerYear, 'cmpYear' ],
    [ PhysicalUnit.dmPerYear, 'dmpYear' ],
    [ PhysicalUnit.mPerYear, 'mpYear' ],
    [ PhysicalUnit.MeterPerSecondInQuad, 'mps2' ],
    [ PhysicalUnit.VoltAmpereReactiveHour, 'VArh' ],
    [ PhysicalUnit.KiloVoltAmpereReactiveHour, 'kVArh' ],
    [ PhysicalUnit.Lux, 'lux' ],
    [ PhysicalUnit.PartsPerHundert, 'pph' ],
    [ PhysicalUnit.PartsPerMillion, 'ppm' ],
    [ PhysicalUnit.PartsPerBillion, 'ppb' ],
    [ PhysicalUnit.PartsPerTrillion, 'ppt' ],
    [ PhysicalUnit.Ton, 'T' ],
    [ PhysicalUnit.KiloGramm, 'kg' ],
    [ PhysicalUnit.Gramm, 'g' ],
    [ PhysicalUnit.MilliGramm, 'mg' ],
    [ PhysicalUnit.MilliliterPerMinute, 'mlm' ],
    [ PhysicalUnit.Milliliter, 'ml' ],
    [ PhysicalUnit.MicroSiemensPerMeter, 'microS/m' ],
    [ PhysicalUnit.GrammPerHour, 'g/h' ]]);

export function getPhysicalUnitFilter(enumValue: PhysicalUnit): string {
    return PhysicalUnitToPhysicalUnitFilter.get(enumValue) || '';
}

export const PhysicalUnitSupported : PhysicalUnit[] = [
    PhysicalUnit.Millimeter,
    PhysicalUnit.Centimeter,
    PhysicalUnit.Decimeter,
    PhysicalUnit.Meter,
    PhysicalUnit.Kilometer,
    PhysicalUnit.MeterPerMeter,
    PhysicalUnit.Degree,
    PhysicalUnit.GON,
    PhysicalUnit.Radiant,
    PhysicalUnit.MillimeterPerMeter,
    PhysicalUnit.m3ph,
    PhysicalUnit.m3pm,
    PhysicalUnit.m3ps,
    PhysicalUnit.mlnm,
    PhysicalUnit.LiterPerHour,
    PhysicalUnit.M3,
    PhysicalUnit.Newton,
    PhysicalUnit.KiloNewton,
    PhysicalUnit.Celsius,
    PhysicalUnit.Kelvin,
    PhysicalUnit.Herz,
    PhysicalUnit.dB,
    PhysicalUnit.pH,
    PhysicalUnit.NTU,
    PhysicalUnit.Percent,
    PhysicalUnit.Promille,
    PhysicalUnit.Pascal,
    PhysicalUnit.Hectopascal,
    PhysicalUnit.Megapascal,
    PhysicalUnit.KiloPascal,
    PhysicalUnit.Ohm,
    PhysicalUnit.kOhm,
    PhysicalUnit.MOhm,
    PhysicalUnit.MillimeterPerSecond,
    PhysicalUnit.mmph,
    PhysicalUnit.MeterPerSecond,
    PhysicalUnit.KilometerPerHour,
    PhysicalUnit.MeterPerSecondInQuad,
    PhysicalUnit.MillimeterPerSecondInQuad,
    PhysicalUnit.Second,
    PhysicalUnit.Volt,
    PhysicalUnit.Milliamper,
    PhysicalUnit.WattPerSquareMeter,
    PhysicalUnit.cgon,
    PhysicalUnit.mgon,
    PhysicalUnit.VolumePercent,
    PhysicalUnit.Amper,
    PhysicalUnit.Watt,
    PhysicalUnit.pWperpW,
    PhysicalUnit.WpmpK,
    PhysicalUnit.RHPercent,
    PhysicalUnit.CcmpW,
    PhysicalUnit.CmpW,
    PhysicalUnit.CM3STPW,
    PhysicalUnit.PromileVCDT,
    PhysicalUnit.PromileVPDB,
    PhysicalUnit.PromileVSMOW,
    PhysicalUnit.VPM,
    PhysicalUnit.Undefined,
    PhysicalUnit.NanoSecond,
    PhysicalUnit.MilliSecond,
    PhysicalUnit.Minute,
    PhysicalUnit.Hour,
    PhysicalUnit.Day,
    PhysicalUnit.KiloWatt,
    PhysicalUnit.VDC,
    PhysicalUnit.VAC,
    PhysicalUnit.MilliVolt,
    PhysicalUnit.WC,
    PhysicalUnit.MilliBar,
    PhysicalUnit.NTemp,
    PhysicalUnit.MegaHerz,
    PhysicalUnit.KiloHerz,
    PhysicalUnit.Microstrain,
    PhysicalUnit.Bar,
    PhysicalUnit.SiemensPerMeter,
    PhysicalUnit.MilliSiemensPerMeter,
    PhysicalUnit.MilliSiemensPerCentimeter,
    PhysicalUnit.MeterH2O,
    PhysicalUnit.MillimeterH2O,
    PhysicalUnit.dBm,
    PhysicalUnit.mmPerH,
    PhysicalUnit.cmPerH,
    PhysicalUnit.dmPerH,
    PhysicalUnit.mPerH,
    PhysicalUnit.mmPerDay,
    PhysicalUnit.cmPerDay,
    PhysicalUnit.dmPerDay,
    PhysicalUnit.mPerDay,
    PhysicalUnit.mmPerWeek,
    PhysicalUnit.cmPerWeek,
    PhysicalUnit.dmPerWeek,
    PhysicalUnit.mPerWeek,
    PhysicalUnit.mmPerMonth,
    PhysicalUnit.cmPerMonth,
    PhysicalUnit.dmPerMonth,
    PhysicalUnit.mPerMonth,
    PhysicalUnit.mmPerYear,
    PhysicalUnit.cmPerYear,
    PhysicalUnit.dmPerYear,
    PhysicalUnit.mPerYear,
    PhysicalUnit.VoltAmpereReactiveHour,
    PhysicalUnit.KiloVoltAmpereReactiveHour,
    PhysicalUnit.Lux,
    PhysicalUnit.PartsPerHundert,
    PhysicalUnit.PartsPerMillion,
    PhysicalUnit.PartsPerBillion,
    PhysicalUnit.PartsPerTrillion,
    PhysicalUnit.Ton,
    PhysicalUnit.KiloGramm,
    PhysicalUnit.Gramm,
    PhysicalUnit.MilliGramm,
    PhysicalUnit.GrammPerHour,
    PhysicalUnit.MilliliterPerMinute,
    PhysicalUnit.Milliliter,
    PhysicalUnit.MicroSiemensPerMeter,
    PhysicalUnit.GrammPerHour,
];


const PhysicalUnitSupportedToPhysicalUnitDefaultName = new Map<number, string>([
    [ PhysicalUnit.Millimeter, 'Length' ],
    [ PhysicalUnit.Centimeter, 'Length' ],
    [ PhysicalUnit.Decimeter, 'Length' ],
    [ PhysicalUnit.Meter, 'Length' ],
    [ PhysicalUnit.Kilometer, 'Length' ],
    [ PhysicalUnit.MeterPerMeter, 'm.ü.M' ],
    [ PhysicalUnit.Degree, 'Angle' ],
    [ PhysicalUnit.GON, 'Angle' ],
    [ PhysicalUnit.Radiant, 'Angle' ],
    [ PhysicalUnit.MillimeterPerMeter, 'Slope' ],
    [ PhysicalUnit.m3ph, 'Flow' ],
    [ PhysicalUnit.m3pm, 'Flow' ],
    [ PhysicalUnit.m3ps, 'Flow' ],
    [ PhysicalUnit.mlnm, 'Flow' ],
    [ PhysicalUnit.LiterPerHour, 'Flow' ],
    [ PhysicalUnit.M3, 'Volume' ],
    [ PhysicalUnit.Newton, 'Force' ],
    [ PhysicalUnit.KiloNewton, 'Force' ],
    [ PhysicalUnit.Celsius, 'Temperature' ],
    [ PhysicalUnit.Kelvin, 'Temperature' ],
    [ PhysicalUnit.Herz, 'Frequency' ],
    [ PhysicalUnit.dB, 'Noise' ],
    [ PhysicalUnit.pH, 'pH Value' ],
    [ PhysicalUnit.NTU, 'Turbidity' ],
    [ PhysicalUnit.Percent, 'Percent' ],
    [ PhysicalUnit.Promille, 'Promile' ],
    [ PhysicalUnit.Pascal, 'Pressure' ],
    [ PhysicalUnit.Hectopascal, 'Pressure' ],
    [ PhysicalUnit.Megapascal, 'Pressure' ],
    [ PhysicalUnit.KiloPascal, 'Pressure' ],
    [ PhysicalUnit.Ohm, 'Resistance' ],
    [ PhysicalUnit.kOhm, 'Resistance' ],
    [ PhysicalUnit.MOhm, 'Resistance' ],
    [ PhysicalUnit.MillimeterPerSecond, 'Speed' ],
    [ PhysicalUnit.mmph, 'Speed' ],
    [ PhysicalUnit.MeterPerSecond, 'Speed' ],
    [ PhysicalUnit.KilometerPerHour, 'Speed' ],
    [ PhysicalUnit.MeterPerSecondInQuad, 'Acceleration' ],
    [ PhysicalUnit.MillimeterPerSecondInQuad, 'Acceleration' ],
    [ PhysicalUnit.Second, 'Time' ],
    [ PhysicalUnit.Volt, 'Voltage' ],
    [ PhysicalUnit.Milliamper, 'Current' ],
    [ PhysicalUnit.WattPerSquareMeter, 'Power' ],
    [ PhysicalUnit.cgon, 'Angle' ],
    [ PhysicalUnit.mgon, 'Angle' ],
    [ PhysicalUnit.VolumePercent, 'VolumetricPercentage' ],
    [ PhysicalUnit.Amper, 'Current' ],
    [ PhysicalUnit.Watt, 'Power' ],
    [ PhysicalUnit.pWperpW, 'Stoke/Antistoke' ],
    [ PhysicalUnit.WpmpK, 'Thermal Conductivity' ],
    [ PhysicalUnit.RHPercent, 'HumidityRel' ],
    [ PhysicalUnit.CcmpW, 'Thermal Resisitivity' ],
    [ PhysicalUnit.CmpW, 'Thermal Resisitivity' ],
    [ PhysicalUnit.CM3STPW, 'VolumetricPercentage' ],
    [ PhysicalUnit.PromileVCDT, 'VolumetricPercentage' ],
    [ PhysicalUnit.PromileVPDB, 'VolumetricPercentage' ],
    [ PhysicalUnit.PromileVSMOW, 'VolumetricPercentage' ],
    [ PhysicalUnit.VPM, 'VolumetricPercentage' ],
    [ PhysicalUnit.Undefined, 'Undefined' ],
    [ PhysicalUnit.NanoSecond, 'Time' ],
    [ PhysicalUnit.MilliSecond, 'Time' ],
    [ PhysicalUnit.Minute, 'Time' ],
    [ PhysicalUnit.Hour, 'Time' ],
    [ PhysicalUnit.Day, 'Time' ],
    [ PhysicalUnit.KiloWatt, 'Power' ],
    [ PhysicalUnit.VDC, 'Voltage' ],
    [ PhysicalUnit.VAC, 'Voltage' ],
    [ PhysicalUnit.MilliVolt, 'Voltage' ],
    [ PhysicalUnit.WC, 'WaterContent' ],
    [ PhysicalUnit.MilliBar, 'Pressure' ],
    [ PhysicalUnit.NTemp, 'Normalized Temperature' ],
    [ PhysicalUnit.MegaHerz, 'Frequency' ],
    [ PhysicalUnit.KiloHerz, 'Frequency' ],
    [ PhysicalUnit.Microstrain, 'Microstrain' ],
    [ PhysicalUnit.Bar, 'Pressure' ],
    [ PhysicalUnit.SiemensPerMeter, 'Electrical conductivity' ],
    [ PhysicalUnit.MilliSiemensPerMeter, 'Electrical conductivity' ],
    [ PhysicalUnit.MilliSiemensPerCentimeter, 'Electrical conductivity' ],
    [ PhysicalUnit.MeterH2O, 'Meters of Water' ],
    [ PhysicalUnit.MillimeterH2O, 'Meters of Water' ],
    [ PhysicalUnit.dBm, 'Signal Strength' ],
    [ PhysicalUnit.mmPerH, 'Speed' ],
    [ PhysicalUnit.cmPerH, 'Speed' ],
    [ PhysicalUnit.dmPerH, 'Speed' ],
    [ PhysicalUnit.mPerH, 'Speed' ],
    [ PhysicalUnit.mmPerDay, 'Speed' ],
    [ PhysicalUnit.cmPerDay, 'Speed' ],
    [ PhysicalUnit.dmPerDay, 'Speed' ],
    [ PhysicalUnit.mPerDay, 'Speed' ],
    [ PhysicalUnit.mmPerWeek, 'Speed' ],
    [ PhysicalUnit.cmPerWeek, 'Speed' ],
    [ PhysicalUnit.dmPerWeek, 'Speed' ],
    [ PhysicalUnit.mPerWeek, 'Speed' ],
    [ PhysicalUnit.mmPerMonth, 'Speed' ],
    [ PhysicalUnit.cmPerMonth, 'Speed' ],
    [ PhysicalUnit.dmPerMonth, 'Speed' ],
    [ PhysicalUnit.mPerMonth, 'Speed' ],
    [ PhysicalUnit.mmPerYear, 'Speed' ],
    [ PhysicalUnit.cmPerYear, 'Speed' ],
    [ PhysicalUnit.dmPerYear, 'Speed' ],
    [ PhysicalUnit.mPerYear, 'Speed' ],
    [ PhysicalUnit.VoltAmpereReactiveHour, 'Reactive Energy' ],
    [ PhysicalUnit.KiloVoltAmpereReactiveHour, 'Reactive Energy' ],
    [ PhysicalUnit.Lux, 'Illuminance' ],
    [ PhysicalUnit.PartsPerHundert, 'Parts per hundred' ],
    [ PhysicalUnit.PartsPerMillion, 'Parts per million' ],
    [ PhysicalUnit.PartsPerBillion, 'Parts per billion' ],
    [ PhysicalUnit.PartsPerTrillion, 'Parts per trillion' ],
    [ PhysicalUnit.Ton, 'Ton' ],
    [ PhysicalUnit.KiloGramm, 'Kilogramm' ],
    [ PhysicalUnit.Gramm, 'Gramm' ],
    [ PhysicalUnit.MilliGramm, 'Milligramm' ],
    [ PhysicalUnit.GrammPerHour, 'Gramm per hour' ],
    [ PhysicalUnit.MilliliterPerMinute, 'Flow' ],
    [ PhysicalUnit.Milliliter, 'Volume' ],
    [ PhysicalUnit.MicroSiemensPerMeter, 'Electrical conductivity' ],
    [ PhysicalUnit.GrammPerHour, 'Gramm per hour' ],
]);

export function getPhysicalUnitSupportedDefaultName(enumValue: PhysicalUnit): string {
    return PhysicalUnitSupportedToPhysicalUnitDefaultName.get(enumValue) || '';
}

const PhysicalUnitSupportedToPhysicalUnitName = new Map<number, string>([
    [ PhysicalUnit.Millimeter, 'Millimeter' ],
    [ PhysicalUnit.Centimeter, 'Centimeter' ],
    [ PhysicalUnit.Decimeter, 'Decimeter' ],
    [ PhysicalUnit.Meter, 'Meter' ],
    [ PhysicalUnit.Kilometer, 'Kilometer' ],
    [ PhysicalUnit.MeterPerMeter, 'MeterPerMeter' ],
    [ PhysicalUnit.Degree, 'Degree' ],
    [ PhysicalUnit.GON, 'GON' ],
    [ PhysicalUnit.Radiant, 'Radiant' ],
    [ PhysicalUnit.MillimeterPerMeter, 'MillimeterPerMeter' ],
    [ PhysicalUnit.m3ph, 'm3ph' ],
    [ PhysicalUnit.m3pm, 'm3pm' ],
    [ PhysicalUnit.m3ps, 'm3ps' ],
    [ PhysicalUnit.mlnm, 'mlnm' ],
    [ PhysicalUnit.LiterPerHour, 'LiterPerHour' ],
    [ PhysicalUnit.M3, 'M3' ],
    [ PhysicalUnit.Newton, 'Newton' ],
    [ PhysicalUnit.KiloNewton, 'KiloNewton' ],
    [ PhysicalUnit.Celsius, 'Celsius' ],
    [ PhysicalUnit.Kelvin, 'Kelvin' ],
    [ PhysicalUnit.Herz, 'Herz' ],
    [ PhysicalUnit.dB, 'dB' ],
    [ PhysicalUnit.pH, 'pH' ],
    [ PhysicalUnit.NTU, 'NTU' ],
    [ PhysicalUnit.Percent, 'Percent' ],
    [ PhysicalUnit.Promille, 'Promille' ],
    [ PhysicalUnit.Pascal, 'Pascal' ],
    [ PhysicalUnit.Hectopascal, 'Hectopascal' ],
    [ PhysicalUnit.Megapascal, 'Megapascal' ],
    [ PhysicalUnit.KiloPascal, 'KiloPascal' ],
    [ PhysicalUnit.Ohm, 'Ohm' ],
    [ PhysicalUnit.kOhm, 'kOhm' ],
    [ PhysicalUnit.MOhm, 'MOhm' ],
    [ PhysicalUnit.MillimeterPerSecond, 'MillimeterPerSecond' ],
    [ PhysicalUnit.mmph, 'mmph' ],
    [ PhysicalUnit.MeterPerSecond, 'MeterPerSecond' ],
    [ PhysicalUnit.KilometerPerHour, 'KilometerPerHour' ],
    [ PhysicalUnit.MeterPerSecondInQuad, 'MeterPerSecondInQuad' ],
    [ PhysicalUnit.MillimeterPerSecondInQuad, 'MillimeterPerSecondInQuad' ],
    [ PhysicalUnit.Second, 'Second' ],
    [ PhysicalUnit.Volt, 'Volt' ],
    [ PhysicalUnit.Milliamper, 'Milliamper' ],
    [ PhysicalUnit.WattPerSquareMeter, 'WattPerSquareMeter' ],
    [ PhysicalUnit.cgon, 'cgon' ],
    [ PhysicalUnit.mgon, 'mgon' ],
    [ PhysicalUnit.VolumePercent, 'VolumePercent' ],
    [ PhysicalUnit.Amper, 'Amper' ],
    [ PhysicalUnit.Watt, 'Watt' ],
    [ PhysicalUnit.pWperpW, 'pWperpW' ],
    [ PhysicalUnit.WpmpK, 'WpmpK' ],
    [ PhysicalUnit.RHPercent, 'RHPercent' ],
    [ PhysicalUnit.CcmpW, 'CcmpW' ],
    [ PhysicalUnit.CmpW, 'CmpW' ],
    [ PhysicalUnit.CM3STPW, 'CM3STPW' ],
    [ PhysicalUnit.PromileVCDT, 'PromileVCDT' ],
    [ PhysicalUnit.PromileVPDB, 'PromileVPDB' ],
    [ PhysicalUnit.PromileVSMOW, 'PromileVSMOW' ],
    [ PhysicalUnit.VPM, 'VPM' ],
    [ PhysicalUnit.Undefined, 'Undefined' ],
    [ PhysicalUnit.NanoSecond, 'NanoSecond' ],
    [ PhysicalUnit.MilliSecond, 'MilliSecond' ],
    [ PhysicalUnit.Minute, 'Minute' ],
    [ PhysicalUnit.Hour, 'Hour' ],
    [ PhysicalUnit.Day, 'Day' ],
    [ PhysicalUnit.KiloWatt, 'KiloWatt' ],
    [ PhysicalUnit.VDC, 'VDC' ],
    [ PhysicalUnit.VAC, 'VAC' ],
    [ PhysicalUnit.MilliVolt, 'MilliVolt' ],
    [ PhysicalUnit.WC, 'WC' ],
    [ PhysicalUnit.MilliBar, 'MilliBar' ],
    [ PhysicalUnit.NTemp, 'NTemp' ],
    [ PhysicalUnit.MegaHerz, 'MegaHerz' ],
    [ PhysicalUnit.KiloHerz, 'KiloHerz' ],
    [ PhysicalUnit.Microstrain, 'Microstrain' ],
    [ PhysicalUnit.Bar, 'Bar' ],
    [ PhysicalUnit.SiemensPerMeter, 'SiemensPerMeter' ],
    [ PhysicalUnit.MilliSiemensPerMeter, 'MilliSiemensPerMeter' ],
    [ PhysicalUnit.MilliSiemensPerCentimeter, 'MilliSiemensPerCentimeter' ],
    [ PhysicalUnit.MeterH2O, 'MeterH2O' ],
    [ PhysicalUnit.MillimeterH2O, 'MillimeterH2O' ],
    [ PhysicalUnit.dBm, 'dBm' ],
    [ PhysicalUnit.mmPerH, 'mmPerH' ],
    [ PhysicalUnit.cmPerH, 'cmPerH' ],
    [ PhysicalUnit.dmPerH, 'dmPerH' ],
    [ PhysicalUnit.mPerH, 'mPerH' ],
    [ PhysicalUnit.mmPerDay, 'mmPerDay' ],
    [ PhysicalUnit.cmPerDay, 'cmPerDay' ],
    [ PhysicalUnit.dmPerDay, 'dmPerDay' ],
    [ PhysicalUnit.mPerDay, 'mPerDay' ],
    [ PhysicalUnit.mmPerWeek, 'mmPerWeek' ],
    [ PhysicalUnit.cmPerWeek, 'cmPerWeek' ],
    [ PhysicalUnit.dmPerWeek, 'dmPerWeek' ],
    [ PhysicalUnit.mPerWeek, 'mPerWeek' ],
    [ PhysicalUnit.mmPerMonth, 'mmPerMonth' ],
    [ PhysicalUnit.cmPerMonth, 'cmPerMonth' ],
    [ PhysicalUnit.dmPerMonth, 'dmPerMonth' ],
    [ PhysicalUnit.mPerMonth, 'mPerMonth' ],
    [ PhysicalUnit.mmPerYear, 'mmPerYear' ],
    [ PhysicalUnit.cmPerYear, 'cmPerYear' ],
    [ PhysicalUnit.dmPerYear, 'dmPerYear' ],
    [ PhysicalUnit.mPerYear, 'mPerYear' ],
    [ PhysicalUnit.VoltAmpereReactiveHour, 'VoltAmpereReactiveHour' ],
    [ PhysicalUnit.KiloVoltAmpereReactiveHour, 'KiloVoltAmpereReactiveHour' ],
    [ PhysicalUnit.Lux, 'Lux' ],
    [ PhysicalUnit.PartsPerHundert, 'PartsPerHundert' ],
    [ PhysicalUnit.PartsPerMillion, 'PartsPerMillion' ],
    [ PhysicalUnit.PartsPerBillion, 'PartsPerBillion' ],
    [ PhysicalUnit.PartsPerTrillion, 'PartsPerTrillion' ],
    [ PhysicalUnit.Ton, 'Ton' ],
    [ PhysicalUnit.KiloGramm, 'KiloGramm' ],
    [ PhysicalUnit.Gramm, 'Gramm' ],
    [ PhysicalUnit.MilliGramm, 'MilliGramm' ],
    [ PhysicalUnit.GrammPerHour, 'GrammPerHour' ],
    [ PhysicalUnit.MilliliterPerMinute, 'MilliliterPerMinute' ],
    [ PhysicalUnit.Milliliter, 'Milliliter' ],
    [ PhysicalUnit.MicroSiemensPerMeter, 'MicroSiemensPerMeter' ],
    [ PhysicalUnit.GrammPerHour, 'GrammPerHour' ],
]);

export function getPhysicalUnitSupportedName(enumValue: PhysicalUnit): string {
    return PhysicalUnitSupportedToPhysicalUnitName.get(enumValue) || '';
}

const PhysicalUnitByName = new Map<string, number>([
	[ 'Undefined', 0 ],
	[ 'Meter', 1 ],
	[ 'Millimeter', 2 ],
	[ 'Pascal', 3 ],
	[ 'Radiant', 4 ],
	[ 'Degree', 5 ],
	[ 'GON', 6 ],
	[ 'Kelvin', 7 ],
	[ 'Celsius', 8 ],
	[ 'Milliamper', 9 ],
	[ 'Ohm', 10 ],
	[ 'Hectopascal', 11 ],
	[ 'Megapascal', 12 ],
	[ 'MeterPerSecond', 13 ],
	[ 'WattPerSquareMeter', 14 ],
	[ 'Newton', 15 ],
	[ 'Volt', 16 ],
	[ 'M3ps', 17 ],
	[ 'M3ph', 18 ],
	[ 'Percent', 19 ],
	[ 'Promille', 20 ],
	[ 'Kilometer', 21 ],
	[ 'Herz', 22 ],
	[ 'MillimeterPerSecondInQuad', 23 ],
	[ 'MillimeterPerSecond', 24 ],
	[ 'Second', 25 ],
	[ 'hPa', 26 ],
	[ 'KiloNewton', 27 ],
	[ 'm3ps', 28 ],
	[ 'm3ph', 29 ],
	[ 'm3pm', 30 ],
	[ 'NTU', 31 ],
	[ 'pH', 32 ],
	[ 'M3', 33 ],
	[ 'MillimeterPerMeter', 34 ],
	[ 'dB', 35 ],
	[ 'mmph', 36 ],
	[ 'Centimeter', 37 ],
	[ 'MeterPerMeter', 38 ],
	[ 'LiterPerHour', 39 ],
	[ 'kOhm', 40 ],
	[ 'MOhm', 41 ],
	[ 'KilometerPerHour', 42 ],
	[ 'mgon', 43 ],
	[ 'cgon', 44 ],
	[ 'KiloPascal', 45 ],
	[ 'mlnm', 46 ],
	[ 'VolumePercent', 47 ],
	[ 'Amper', 48 ],
	[ 'Watt', 49 ],
	[ 'pWperpW', 50 ],
	[ 'WpmpK', 51 ],
	[ 'RHPercent', 52 ],
	[ 'CmpW', 53 ],
	[ 'WC', 55 ],
	[ 'MilliSecond', 56 ],
	[ 'Minute', 57 ],
	[ 'Hour', 58 ],
	[ 'Day', 59 ],
	[ 'VPM', 60 ],
	[ 'PromileVPDB', 61 ],
	[ 'PromileVSMOW', 62 ],
	[ 'PromileVCDT', 63 ],
	[ 'MilliBar', 64 ],
	[ 'KiloHerz', 65 ],
	[ 'MegaHerz', 66 ],
	[ 'CcmpW', 67 ],
	[ 'CM3STPW', 68 ],
	[ 'NanoSecond', 69 ],
	[ 'KiloWatt', 70 ],
	[ 'VDC', 71 ],
	[ 'VAC', 72 ],
	[ 'MilliVolt', 73 ],
	[ 'NTemp', 74 ],
	[ 'Decimeter', 75 ],
	[ 'Microstrain', 76 ],
	[ 'Bar', 77 ],
	[ 'SiemensPerMeter', 78 ],
	[ 'MilliSiemensPerMeter', 79 ],
	[ 'MilliSiemensPerCentimeter', 80 ],
	[ 'MillimeterH2O', 81 ],
	[ 'MeterH2O', 82 ],
	[ 'dBm', 83 ],
	[ 'mmPerH', 84 ],
	[ 'cmPerH', 85 ],
	[ 'dmPerH', 86 ],
	[ 'mPerH', 87 ],
	[ 'mmPerDay', 88 ],
	[ 'cmPerDay', 89 ],
	[ 'dmPerDay', 90 ],
	[ 'mPerDay', 91 ],
	[ 'mmPerWeek', 92 ],
	[ 'cmPerWeek', 93 ],
	[ 'dmPerWeek', 94 ],
	[ 'mPerWeek', 95 ],
	[ 'mmPerMonth', 96 ],
	[ 'cmPerMonth', 97 ],
	[ 'dmPerMonth', 98 ],
	[ 'mPerMonth', 99 ],
	[ 'mmPerYear', 100 ],
	[ 'cmPerYear', 101 ],
	[ 'dmPerYear', 102 ],
	[ 'mPerYear', 103 ],
	[ 'MeterPerSecondInQuad', 104 ],
	[ 'VoltAmpereReactiveHour', 105 ],
	[ 'KiloVoltAmpereReactiveHour', 106 ],
	[ 'Lux', 107 ],
	[ 'PartsPerHundert', 108 ],
	[ 'PartsPerMillion', 109 ],
	[ 'PartsPerBillion', 110 ],
	[ 'PartsPerTrillion', 111 ],
	[ 'Ton', 112 ],
	[ 'KiloGramm', 113 ],
	[ 'Gramm', 114 ],
	[ 'MilliGramm', 115 ],
	[ 'MilliliterPerMinute', 116 ],
	[ 'Milliliter', 117 ],
	[ 'MicroSiemensPerMeter', 118 ],
	[ 'GrammPerHour', 119 ],
]);

export function getPhysicalUnitByName(name: string): PhysicalUnit {
    return PhysicalUnitByName.get(name)!;
}

