import { t } from '../../../i18n';
import { ChartType } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { KindOfElementUsing } from "../../../server/AVTService/TypeLibrary/Common/KindOfElementUsing";
import { DefaultTimeValueChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DefaultTimeValueChartModel";
import { DtsChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel";
import { HeatmapChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel";
import { InclinometerChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { TimeValueChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel";
import { VibrationChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { XyChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import { XYVibrationChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XYVibrationChartModel";
import { IGeovisChartCommonPropertyChangedProps } from "./ChartTabControl.types";
import { ChartTextSetting } from "./ChartTab_tools";
import { DefaultTimeValueChartMainSettings } from "./default/DefaultChartMainSettings";
import { DtsChartMainSettings, IGeovisDtsSectionLoadActions } from "./dtschart/dtsChartMainSettings";
import { ChartHeatmapMainSettings } from "./heatmap/ChartHeatmapMainSettings";
import { InclinometerChartMainSettings } from "./inclinometer/InclinometerChartMainSettings";
import { TimeValueChartMainSettings } from "./timevalue/timeValueChartMainSettings";
import { isDtsChart, onChartPropertyChangedFunc } from "./tools";
import { defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IChartInfoStorage } from "./types";
import { VibrationChartMainSettings } from "./vibration/VibrationChartMainSettings";
import { XYChartMainSettings } from "./xychart/xyChartMainSettings";
import { XYVibrationChartMainSettings } from "./xyVibration/XYVibrationChartMainSettings";

interface IComponentProps extends IGeovisDtsSectionLoadActions, IGeovisChartCommonPropertyChangedProps {
    chartInfoStorage: IChartInfoStorage;
}

export const ChartMainSettingsTab = ({
    chartInfoStorage,
    onChartPropertyChanged,
    onDtsChartSectionLoading,
    onChartPropertiesChanged,
    onDtsChartSectionLoaded
}: IComponentProps) => {

    const { Chart, DtsSectionInfos } = chartInfoStorage.chartInfo;

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    const onChangeFunc = onChartPropertyChangedFunc(onChartPropertyChanged);

    const getTooltipContent = (): React.ReactNode | undefined => {
        if (Chart.Type === ChartType.HeatMap) {
            return (
                <div>
                    <ul>
                        <li>Use template "{'{'}timeslot{'}'}" to insert the chart time in the header</li>
                    </ul>
                </div>
            )
        }

        return undefined;
    }

    return (
        <div className="geovisChartTab">

            <ChartTextSetting
                labelColumnStyle={labelColumnStyle}
                settingsStyle={settingRowStyle}
                labelText={t("Name")}
                value={Chart.Name}
                htmlName="tbName"
                onChangeTextField={onChangeFunc("Name")} />

            <ChartTextSetting
                labelColumnStyle={labelColumnStyle}
                settingsStyle={settingRowStyle}
                labelText={t("Title")}
                value={Chart.Title}
                htmlName="tbTitle"
                tooltipContent={getTooltipContent()}
                onChangeTextField={onChangeFunc("Title")} />

            {isDtsChart(Chart.Type) && (
                <DtsChartMainSettings
                    chart={Chart as DtsChartModel}
                    onChartPropertyChanged={onChartPropertyChanged}
                    dtsChartSections={DtsSectionInfos}
                    onDtsChartSectionLoaded={onDtsChartSectionLoaded}
                    onDtsChartSectionLoading={onDtsChartSectionLoading}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                />
            )}
            {Chart.Type === ChartType.TimeValue && Chart.KindOfElementUsing !== KindOfElementUsing.Default && Chart.KindOfElementUsing !== KindOfElementUsing.DefaultTemplate &&
                <TimeValueChartMainSettings
                    chart={Chart as TimeValueChartModel}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                />
            }

            {Chart.Type === ChartType.VibrationDiagramm &&
                <VibrationChartMainSettings
                    chart={Chart as VibrationChartModel}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                    sensorsChanges={chartInfoStorage.sensorsChanges}
                />
            }

            {Chart.Type === ChartType.XYVibrationEventDiagram &&
                <XYVibrationChartMainSettings
                    chart={Chart as XYVibrationChartModel}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                />
            }

            {Chart.Type === ChartType.TimeValue && (Chart.KindOfElementUsing === KindOfElementUsing.Default || Chart.KindOfElementUsing === KindOfElementUsing.DefaultTemplate) &&
                <DefaultTimeValueChartMainSettings
                    chart={Chart as DefaultTimeValueChartModel}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                    aliases={chartInfoStorage.aliases}
                />
            }

            {Chart.Type === ChartType.XYDiagram &&
                <XYChartMainSettings
                    chart={Chart as XyChartModel}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                />
            }

            {Chart.Type === ChartType.HeatMap && (
                <ChartHeatmapMainSettings
                    chart={Chart as HeatmapChartModel}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                />
            )}

            {Chart.Type === ChartType.Inclinometer &&
                <InclinometerChartMainSettings
                    chart={Chart as InclinometerChartModel}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                />
            }

        </div>
    );
}