//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GeovisLicenseType {
	None = 0,
	Basic = 5,
	Advanced = 10,
	Expert = 20
}

const GeovisLicenseTypeToName = new Map<number, string>([
	[ 0, 'None' ],
	[ 5, 'Basic' ],
	[ 10, 'Advanced' ],
	[ 20, 'Expert' ],
])

export function getGeovisLicenseTypeToName(element: GeovisLicenseType) : string {
    return GeovisLicenseTypeToName.get(element) || '';
}

const GeovisLicenseTypeByName = new Map<string, number>([
	[ 'None', 0 ],
	[ 'Basic', 5 ],
	[ 'Advanced', 10 ],
	[ 'Expert', 20 ],
]);

export function getGeovisLicenseTypeByName(name: string): GeovisLicenseType {
    return GeovisLicenseTypeByName.get(name)!;
}


export const GeovisLicenseTypeList : Readonly<GeovisLicenseType[]> = [
	GeovisLicenseType.None,
	GeovisLicenseType.Basic,
	GeovisLicenseType.Advanced,
	GeovisLicenseType.Expert,
];



const GeovisLicenseTypeToDescription = new Map<number, string>([
	[ 0, 'No License' ],
	[ 5, 'Basic' ],
	[ 10, 'Advanced' ],
	[ 20, 'Expert' ],
])

export function getGeovisLicenseTypeToDescription(element: GeovisLicenseType) : string {
    return GeovisLicenseTypeToDescription.get(element) || '';
}

