/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 17.07.2023
 * @description The data layer to load or reload sensors/chains measurements to draw on the map section
 */

import { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../../helpers/GeovisHooks";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { IGeovisAction } from "../../../../../../store/types";
import { IGeovisMapSectionReportInfo, IGeovisReportPageConfig } from "../../../../../../store/projectReport.types";
import { DataActionResponse } from "../../../../../../server/DataActionResponse";
import ServerRoutesGen from "../../../../../../server/Routes/ServerRoutesGen";
import { fetchServerElementsByPost } from "../../../../../../helpers/ProjectDataHelper";
import { TimeSearchDistanceDataRequest } from "../../../../../../server/AVTService/Computation/TimeSearchDistance/TimeSearchDistanceDataRequest";
import { projectReportMapSectionDataLoaded, projectReportMapSectionDataLoading } from "../../../../../../store/creators/projectReportCreators";
import { getMapSectionTimeSearchDistanceElementDataRequest } from "../../../../mapSection/tools";
import { GeovisTimeSearchReportData } from "../../../../../../server/GEOvis3/Model/TimeSearchDistance/GeovisTimeSearchReportData";

interface IGeovisMapSectionReportDataLayerProps extends IDispatchToProps, IWithGeovisServerProps {
    mapSectionInfo: IGeovisMapSectionReportInfo;
    reportPageConfig: IGeovisReportPageConfig;
    projectId: number;
    pageNum: number;
    isPrinting: boolean;
    userId?: string,
    userToken?: string,
    reportId?: number;
}

export const GeovisMapSectionReportDataLayer = withGeovisServer(({
    Server,
    mapSectionInfo,
    reportPageConfig,
    pageNum,
    projectId,
    onMapSectionDataLoaded,
    onMapSectionDataLoading,
    isPrinting,
    userId,
    userToken,
    reportId
}: IGeovisMapSectionReportDataLayerProps) => {

    const { Timestamp, MapSection: { Id } } = mapSectionInfo;

    // ths execute every change of the MapSection, usually it must be referenced to Timestamp of the MapSection
    useEffect(() => {

        onMapSectionDataLoading();

        (async function loadData() {

            const url = isPrinting
                ? ServerRoutesGen.ReportPdfRenderData.GetTimeSearchDistanceReportData.patch(projectId, userToken, userId)
                : ServerRoutesGen.ReportRenderData.GetTimeSearchReportData.patch(projectId);

            const payload = getMapSectionTimeSearchDistanceElementDataRequest(reportPageConfig, mapSectionInfo, reportId);
            const response = await fetchServerElementsByPost<GeovisTimeSearchReportData, TimeSearchDistanceDataRequest>(Server, url, payload);

            onMapSectionDataLoaded(response);
        })();

    }, [Timestamp]);

    return (
        <div id={`geovis-map-section-${Id}-data-layer-elements-of-page-${pageNum}`} style={{ display: 'none' }}>
            {/* GeovisMapSection elements data layer */}
        </div>
    )
})


interface IStateToProps {
    mapSectionInfo: IGeovisMapSectionReportInfo | false;
    reportPageConfig: IGeovisReportPageConfig | false;
}

interface IDispatchToProps {
    onMapSectionDataLoading: () => void;
    onMapSectionDataLoaded: (response: DataActionResponse<GeovisTimeSearchReportData>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IOwnProps {
    sectionId: number;
    projectId: number;
    pageNum: number;
    isPrinting: boolean;
    userToken: string | undefined;
    userId: string | undefined;
    reportId: number;
}

interface IConnectedComponentProps extends IStateToProps, IDispatchToProps, IOwnProps {

}

const DataLayerConnected = ({ mapSectionInfo, reportPageConfig, pageNum, sectionId, ...restProps }: IConnectedComponentProps) => {

    if (!reportPageConfig) {
        throw Error(`MapSectionDataLayer: Expected report page ${pageNum} not found. sectionId=${sectionId}`);
    }

    if (!mapSectionInfo) {
        throw Error(`MapSectionDataLayer: Expected map section info not found. Page=${pageNum}, sectionId=${sectionId}`);
    }

    return (
        <GeovisMapSectionReportDataLayer
            reportPageConfig={reportPageConfig}
            mapSectionInfo={mapSectionInfo}
            pageNum={pageNum}
            {...restProps}
        />
    )
}

/**
 * Get's map section report info
 * @param page 
 * @param sectionId 
 * @returns 
 */
const getMapSectionInfo = (page: IGeovisReportPageConfig | undefined, sectionId: number): IGeovisMapSectionReportInfo | false => {
    if (!page) {
        return false;
    }

    return page.mapSections.get(sectionId) || false;
}

/**
 * Map state to props of component
 * @param param0 
 * @param param1 
 * @returns 
 */
const mapStateToProps = ({ projectReport: { geovisReportSettings } }: IGeovisStoreState, { pageNum, sectionId }: IOwnProps): IStateToProps => ({
    reportPageConfig: geovisReportSettings.geovisPages.get(pageNum) || false,
    mapSectionInfo: getMapSectionInfo(geovisReportSettings.geovisPages.get(pageNum), sectionId)
});

/**
 * Map dispatch methods to component props
 * @param dispatch 
 * @param param1 
 * @returns 
 */
const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>, { pageNum, sectionId }: IOwnProps): IDispatchToProps => ({
    onMapSectionDataLoading: () => dispatch(projectReportMapSectionDataLoading(pageNum, sectionId)),
    onMapSectionDataLoaded: response => dispatch(projectReportMapSectionDataLoaded(pageNum, sectionId, response))
});

export const GeovisMapSectionDataLayer = connect<IStateToProps, IDispatchToProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(DataLayerConnected);