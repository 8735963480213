/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 15.07.2020
 * @description Properties changes helper
 */

// TODO: we need to make such handlers to be more generic

import { OptionType } from '@atlaskit/select';
import { debounce } from 'lodash';
import { SyntheticEvent } from "react";
import { IGvOptionType } from '../components/select/GeovisSelect_tools';
import { GeovisUserProjectRelationModel } from '../server/AVTService/TypeLibrary/Identity/GeovisUserProjectRelationModel';
import { CompanyInfo } from '../server/GEOvis3/Model/Company/CompanyInfo';

type GeovisUserProfileInfoTypes = number | string | boolean | GeovisUserProjectRelationModel[] | CompanyInfo | string[];

export type PropertyChangedHandler<TObject> = (n: keyof TObject, v: GeovisUserProfileInfoTypes) => void;

export type PropertyChangedHandler2<TObject, TValue> = (propertyName: keyof TObject, value: TValue) => void;

export type PropertyChangedHandlerTyped<TObject, TValue> = (propertyName: keyof TObject, value: TValue) => void;

export const onTextfieldPropertyChangedHandler = <TObject>(propertyName: keyof TObject, handler: PropertyChangedHandler<TObject>) => (event: SyntheticEvent<HTMLInputElement>) => {
    handler(propertyName, event.currentTarget.value);
}

export const onTextfieldPropertyChangedHandlerDebounced = <TObject>(propertyName: keyof TObject, handler: PropertyChangedHandlerTyped<TObject, string>) => {

    const debounced = debounce((value: string) => handler(propertyName, value), 500);

    return (event: SyntheticEvent<HTMLInputElement>) => debounced(event.currentTarget.value);
}

export const onStringPropertyChangedHandler = <TObject>(propertyName: keyof TObject, handler: PropertyChangedHandler<TObject>) => (propertyValue: string) => {
    handler(propertyName, propertyValue);
}

export const onNumberPropertyChangedHandler = <TObject>(propertyName: keyof TObject, handler: PropertyChangedHandler<TObject>) => (propertyValue: number) => {
    handler(propertyName, propertyValue);
}

export const onGeovisSelectPropertyChangedHandler = <TObject, TValue extends number | string>(propertyName: keyof TObject, handler: PropertyChangedHandler<TObject>) => ({ value }: IGvOptionType<TValue>) => {
    handler(propertyName, value)
}

export const onSelectPropertyChangedHandler = <TObject>(propertyName: keyof TObject, handler: PropertyChangedHandler<TObject>) => ({ value }: OptionType) => {
    handler(propertyName, value);
}

export const onMultySelectPropertyChangedHandler = <TObject>(propertyName: keyof TObject, handler: PropertyChangedHandler<TObject>) => (values: OptionType[]) => {
    if (!values || values === null) {
        handler(propertyName, []);
    }
    else {
        handler(propertyName, values.map<string>(m => m.value.toString()));
    }

}

export const onCheckboxPropertyChangedHandler = <TObject>(propertyName: keyof TObject, handler: PropertyChangedHandler2<TObject, boolean>) => (event: SyntheticEvent<HTMLInputElement>) => {
    handler(propertyName, event.currentTarget.checked)
}

export const getLastItemInMap = <TKey, TValue>(map: Map<TKey, TValue>) => Array.from(map).pop();

export const getLastKeyInMap = <TKey, TValue>(map: Map<TKey, TValue>) => Array.from(map.keys()).pop();

export const getLastValueInMap = <TKey, TValue>(map: Map<TKey, TValue>) => Array.from(map.values()).pop();
