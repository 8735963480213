//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum BucketReportValueKind {
	MinMax = 0,
	Median = 1
}

const BucketReportValueKindToName = new Map<number, string>([
	[ 0, 'MinMax' ],
	[ 1, 'Median' ],
])

export function getBucketReportValueKindToName(element: BucketReportValueKind) : string {
    return BucketReportValueKindToName.get(element) || '';
}

const BucketReportValueKindByName = new Map<string, number>([
	[ 'MinMax', 0 ],
	[ 'Median', 1 ],
]);

export function getBucketReportValueKindByName(name: string): BucketReportValueKind {
    return BucketReportValueKindByName.get(name)!;
}


export const BucketReportValueKindList : Readonly<BucketReportValueKind[]> = [
	BucketReportValueKind.MinMax,
	BucketReportValueKind.Median,
];



const BucketReportValueKindToDescription = new Map<number, string>([
	[ 0, 'MinMax' ],
	[ 1, 'Median' ],
])

export function getBucketReportValueKindToDescription(element: BucketReportValueKind) : string {
    return BucketReportValueKindToDescription.get(element) || '';
}

