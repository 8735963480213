//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ExportDataType {
	Raw = 0,
	RawWithOffsets = 1,
	Relative = 2
}

const ExportDataTypeToName = new Map<number, string>([
	[ 0, 'Raw' ],
	[ 1, 'RawWithOffsets' ],
	[ 2, 'Relative' ],
])

export function getExportDataTypeToName(element: ExportDataType) : string {
    return ExportDataTypeToName.get(element) || '';
}

const ExportDataTypeByName = new Map<string, number>([
	[ 'Raw', 0 ],
	[ 'RawWithOffsets', 1 ],
	[ 'Relative', 2 ],
]);

export function getExportDataTypeByName(name: string): ExportDataType {
    return ExportDataTypeByName.get(name)!;
}


export const ExportDataTypeList : Readonly<ExportDataType[]> = [
	ExportDataType.Raw,
	ExportDataType.RawWithOffsets,
	ExportDataType.Relative,
];



const ExportDataTypeToDescription = new Map<number, string>([
	[ 0, 'Raw' ],
	[ 1, 'RawWithOffsets' ],
	[ 2, 'Relative' ],
])

export function getExportDataTypeToDescription(element: ExportDataType) : string {
    return ExportDataTypeToDescription.get(element) || '';
}

