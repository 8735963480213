/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 07.02.2023
 * @description The user feedback dialog view component
 */

import Textfield from '@atlaskit/textfield';
import TextArea from '@atlaskit/textarea';
import { Fragment, SyntheticEvent } from 'react';
import { t } from '../../../../i18n';
import { IUserFeedbackViewProps } from './types';
import { GeovisUserFeedbackModel } from '../../../../server/AVTService/TypeLibrary/Model/ApplicationFeedback/GeovisUserFeedbackModel';
import { GeovisSelect } from '../../../../components/select/GeovisSelect';
import { IGvOptionType } from '../../../../components/select/GeovisSelect_tools';

const View = ({ model, allowValidation, allowProjectSelect, projectOptionsStorage, onPropertyChanged }: IUserFeedbackViewProps) => {

    const { Name, Message, AttachmentInfos } = model;

    const onTextPropertyChangedFactory = (propertyName: keyof GeovisUserFeedbackModel) => (event: SyntheticEvent<HTMLInputElement>) => {
        onPropertyChanged(propertyName, event.currentTarget.value);
    }

    const onTextAreaPropertyChangedFactory = (propertyName: keyof GeovisUserFeedbackModel) => (event: SyntheticEvent<HTMLTextAreaElement>) => {
        onPropertyChanged(propertyName, event.currentTarget.value);
    }

    const onProjectChanged = (option: IGvOptionType<number>) => {
        const projectId = option ? option.value : 0;
        onPropertyChanged("ProjectId", projectId);
    }

    const selectedOption = projectOptionsStorage.data.find(o => o.value === model.ProjectId);

    return (
        <Fragment>
            <div className="flexRowContainer">
                <div className='flexCellContainer_g1'>
                    <label>{t("Feedback title")}</label>
                    <Textfield
                        value={Name}
                        isInvalid={allowValidation && !Name}
                        isCompact={true}
                        onChange={onTextPropertyChangedFactory("Name")}
                        placeholder={t("Type title of the feedback there")}
                    />
                </div>
            </div>
            <div className='flexRowContainer'>
                <div className='flexCellContainer_g1'>
                    <label>{t("Related project")}</label>
                    <GeovisSelect
                        isDisabled={!allowProjectSelect}
                        validationState={projectOptionsStorage.isError ? 'error' : 'default'}
                        isLoading={projectOptionsStorage.isLoading}
                        value={selectedOption}
                        options={projectOptionsStorage.data}
                        onChange={onProjectChanged}
                    />
                </div>
            </div>
            <div className="flexRowContainer">
                <div className='flexCellContainer_g1'>
                    <label>{t("Feedback description")}</label>
                    <TextArea
                        minimumRows={15}
                        value={Message}
                        isInvalid={allowValidation && !Message}
                        onChange={onTextAreaPropertyChangedFactory("Message")}
                        placeholder={t("Type content of the feedback there")}
                    />
                </div>
            </div>

            {AttachmentInfos && AttachmentInfos.length > 0 && (
                <div className="flexRowContainer">
                    <div className='flexCellContainer_g1'>
                        <label>{t("Attachments")}</label>
                        <div style={{ border: '2px solid #DFE1E6' }}>
                            {AttachmentInfos.map((value, key) => (
                                <div key={key} className="flexRowContainer">
                                    <div className='flexCellContainer'>
                                        <div style={{ marginLeft: '5px' }}>- {value.FileName}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default View;