/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 20.05.2022
 * @description The host component to call exact render of the chart
 */

import React from 'react';
import { connect } from 'react-redux';
import { ChartType } from '../../../../../server/AVTService/TypeLibrary/Common/ChartType';
import Logger from '../../../../../services/Logger';
import { IGeovisStoreState } from '../../../../../store/store.types';
import { compareChartTypes } from '../../../charts/tools';
import { IReportElementRenderOwnProps } from '../types';
import GeovisChartRenderDataLayer from './GeovisChartRenderDataLayer';
import DtsChartRender from './renders/DtsChartRender';
import HeatmapChartRender from './renders/HeatmapChartRender';
import InclinometerChartRender from './renders/InclinometerChartRender';
import TimeValueChartRender from './renders/TimeValueChartsRender';
import VibrationChartRender from './renders/VibrationChartRender';
import VibrationEventChartRender from './renders/VibrationEventChartRender';
import XyChartRender from './renders/XyChartRender';
import XYVibrationChartRender from './renders/XYVibrationChartRender';
import { getChartType } from './tools';

interface IStateToProps {
    chartType: ChartType | undefined;
}

interface IOwnProps extends IReportElementRenderOwnProps {
    chartId: number;
}


interface IComponentProps extends IStateToProps, IOwnProps {

}

const HostComponent = (props: IComponentProps) => {

    const { chartId, chartType, eventInfo, isVibrationEventChart } = props;

    if (chartType === undefined) {
        return (
            <div>Chart {chartId} not found</div>
        )
    }

    Logger.render(`Rendering chart host of chartId=${chartId}`, 'GeovisChartRenderHost');

    return (
        <React.Fragment>
            {/* load data here only for Event Charts */}
            {isVibrationEventChart && <GeovisChartRenderDataLayer {...props} />}

            {/* heatmap */}
            {compareChartTypes(chartType, ChartType.HeatMap) && (
                <HeatmapChartRender {...props} />
            )}

            {/* timevalue */}
            {compareChartTypes(chartType, ChartType.TimeValue) && (
                <TimeValueChartRender {...props} />
            )}

            {/* xy chart */}
            {compareChartTypes(chartType, ChartType.XYDiagram) && (
                <XyChartRender {...props} />
            )}

            {/* inclinometer chart */}
            {compareChartTypes(chartType, ChartType.Inclinometer) && (
                <InclinometerChartRender {...props} />
            )}

            {/* dts chart */}
            {compareChartTypes(chartType, ChartType.DtsChart) && (
                <DtsChartRender {...props} />
            )}

            {/* vibration event chart */}
            {compareChartTypes(chartType, ChartType.VibrationDiagramm, ChartType.XYVibrationEventDiagram) && isVibrationEventChart && eventInfo && (
                <VibrationEventChartRender {...props} />
            )}

            {/* vibration chart */}
            {compareChartTypes(chartType, ChartType.VibrationDiagramm) && !isVibrationEventChart && (
                <VibrationChartRender {...props} />
            )}

            {/* xy vibration chart */}
            {compareChartTypes(chartType, ChartType.XYVibrationEventDiagram) && !isVibrationEventChart && (
                <XYVibrationChartRender {...props} />
            )}

        </React.Fragment>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { pageNum, chartId }: IOwnProps): IStateToProps => ({
    chartType: getChartType(pageNum, chartId, projectReport.geovisReportSettings)
});

export default connect<IStateToProps, never, IOwnProps>(mapStateToProps)(HostComponent);