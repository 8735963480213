import Button from "@atlaskit/button";
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import HipchatChevronDoubleDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-double-down';
import HipchatChevronDoubleUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-double-up';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { cloneDeep } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { formattedDateTime } from "../../../../../../helpers/DateHelper";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../../helpers/GeovisHooks";
import { t } from "../../../../../../i18n";
import { TimePeriod } from "../../../../../../server/AVTService/TypeLibrary/Common/TimePeriod";
import { TimeValueChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/TimeValueChartData";
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { TimeValueChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel";
import Logger from "../../../../../../services/Logger";
import { setDirtyGeovisChartSettings } from "../../../../../../store/creators/projectReportCreators";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { IGeovisAction } from "../../../../../../store/types";
import { LoadingContainerSkeleton } from "../../../../../LoadingContainerSkeleton";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { autoRefreshIntervalToSeconds } from "../../tools";
import { IReportElementRenderOwnProps } from "../../types";
import { getTimeValueChartRenderOptions } from "../options/timeValueChartRenderOptions";
import { getGeovisChartConfigFromStorage } from "../options/tools";
import { getGeovisChartData } from "../tools";
import { IChartRenderStateToProps } from "../types";
import { ChartLoadingSkeleton } from "./ChartLoadingComponent";
import { TimeValueLogbooksControl } from "./extraComponents/TimeValueLogbooksControl";

interface IStateToProps extends IChartRenderStateToProps<TimeValueChartData> {
    autoRefreshInSeconds: number;
}

interface IDispatchToProps {
    setDirtyChartConfig: (config: GeovisChartModel) => void;
}

interface IOwnProps extends IReportElementRenderOwnProps {
    chartId: number;
}


interface IComponentProps extends IStateToProps, IDispatchToProps, IOwnProps, IWithGeovisServerProps {

}

type PossibleChartTimeRangeChanges = "start" | "prev" | "next" | "end";

const TimeValueChartRender = ({ chartData, chartId, isPrinting, setDirtyChartConfig, userToken, pageNum }: IComponentProps) => {

    if (!chartData) {
        return (<ChartLoadingSkeleton text={t("Initializing timevalue chart data")} />)
    }

    const { data, errorDescription, isError, isLoading, isLoaded } = chartData;

    // getting changed chart config directly from storage, do not subscribe on UPDATE event
    const chart = getGeovisChartConfigFromStorage<TimeValueChartModel>(pageNum, chartId);
    if (!chart) {
        return null;
    }

    if (isError) {
        return (<LoadingPageErrorSkeleton errorText={t("Error load timevalue chart data")} errorDescription={errorDescription} />);
    }

    if (!isLoaded) {
        return (<ChartLoadingSkeleton text={t("Loading timevalue chart data...")} />)
    }

    Logger.render(`Rendering timevalue chartId=${chartId}`, 'TimeValueChartRender');

    const changeTimeRange = (type: PossibleChartTimeRangeChanges) => async () => {
        const chartConfig = cloneDeep(chart);
        switch (type) {
            case "end":
                if (data.LastEndTimeslot && data.LastStartTimeslot) {
                    chartConfig.Period = TimePeriod.Custom
                    chartConfig.StartDateSetting.Value = formattedDateTime(data.LastStartTimeslot, "yyyy/MM/DD HH:mm");
                    chartConfig.EndDateSetting.Value = formattedDateTime(data.LastEndTimeslot, "yyyy/MM/DD HH:mm");
                }
                break;
            case "start":
                if (data.FirstEndTimeslot && data.FirstStartTimeslot) {
                    chartConfig.Period = TimePeriod.Custom
                    chartConfig.StartDateSetting.Value = formattedDateTime(data.FirstStartTimeslot, "yyyy/MM/DD HH:mm");
                    chartConfig.EndDateSetting.Value = formattedDateTime(data.FirstEndTimeslot, "yyyy/MM/DD HH:mm");
                }
                break;
            case "prev":
                if (data.PrevEndDate && data.PrevStartDate) {
                    chartConfig.Period = TimePeriod.Custom
                    chartConfig.StartDateSetting.Value = formattedDateTime(data.PrevStartDate, "yyyy/MM/DD HH:mm");
                    chartConfig.EndDateSetting.Value = formattedDateTime(data.PrevEndDate, "yyyy/MM/DD HH:mm");
                }
                break;
            case "next":
                if (data.NextStartDate && data.NextEndDate) {
                    chartConfig.Period = TimePeriod.Custom
                    chartConfig.StartDateSetting.Value = formattedDateTime(data.NextStartDate, "yyyy/MM/DD HH:mm");
                    chartConfig.EndDateSetting.Value = formattedDateTime(data.NextEndDate, "yyyy/MM/DD HH:mm");
                }
                break;
        }
        setDirtyChartConfig(chartConfig);
    }

    const renderOptions = getTimeValueChartRenderOptions(pageNum, cloneDeep(chart), data);

    const singleContainerHeight = renderOptions.length > 1 ? 100 / renderOptions.length - (isPrinting ? 3 : 0) : 100;

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);

    // disable boost if chart is in Printing mode
    if (!isPrinting) {
        HighchartsBoost(Highcharts);
    }

    return (
        <React.Fragment>
            <div key='hc_react_render_root' className="report-chart-render" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>

                {isLoading && (
                    <LoadingContainerSkeleton loadingText={t("Loading timevalue chart data...")} />
                )}

                {isLoaded && renderOptions.map<JSX.Element | null>((option, index) => (
                    // <div>
                    //     time value chart has been rendered
                    // </div>
                    <React.Fragment key={`time_value_chart_container_${index}`}>
                        <HighchartsReact
                            key={`highcharts-${chart.Id}-${index}-${chartData.Timestamp}`}
                            highcharts={Highcharts}
                            options={option}
                            constructorType={'chart'}
                            containerProps={{ style: { flexGrow: 1, height: `${singleContainerHeight}%` } }}
                        />
                    </React.Fragment>
                ))}

                {isLoaded && chart.ShowLogbooksInLegend.value && data.Logbooks.length > 0 &&
                    <div>
                        <TimeValueLogbooksControl
                            logbooks={data.Logbooks}
                        />
                    </div>
                }
            </div>
            {!userToken &&
                <div key='hc_timevalue_navigation_arrows' style={{ display: 'flex', flexDirection: 'row', width: '100%', flexShrink: 1 }}>

                    <div key='arrows_left' style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                        <Button
                            appearance="subtle-link"
                            isDisabled={isLoading || data.PrevEndDate === undefined || data.PrevStartDate === undefined}
                            style={{ marginRight: '10px', transform: 'rotate(90deg)' }}
                            iconBefore={<HipchatChevronDoubleDownIcon label="toStart" size='xlarge' />}
                            onClick={changeTimeRange('start')}
                        />
                        <Button
                            appearance="subtle-link"
                            isDisabled={isLoading || data.PrevEndDate === undefined || data.PrevStartDate === undefined}
                            iconBefore={<ChevronLeftIcon label='prev' size='xlarge' />}
                            onClick={changeTimeRange('prev')}
                        />
                    </div>

                    <div key='arrows_right' style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            appearance="subtle-link"
                            isDisabled={isLoading || data.NextEndDate === undefined || data.NextStartDate === undefined}
                            style={{ marginRight: '10px' }}
                            iconBefore={<ChevronRightIcon label='next' size='xlarge' />}
                            onClick={changeTimeRange('next')}
                        />
                        <Button
                            appearance="subtle-link"
                            isDisabled={isLoading || data.NextEndDate === undefined || data.NextStartDate === undefined}
                            style={{ transform: 'rotate(90deg)' }}
                            iconBefore={<HipchatChevronDoubleUpIcon label="toEnd" size='xlarge' />}
                            onClick={changeTimeRange('end')}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    )
};

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { chartId, pageNum }: IOwnProps): IStateToProps => ({
    chartData: getGeovisChartData<TimeValueChartData>(projectReport.geovisReportSettings, pageNum, chartId),
    autoRefreshInSeconds: autoRefreshIntervalToSeconds(projectReport.geovisReportSettings.detailedReportInfo.AutoRefreshInterval)
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>, { pageNum }: IOwnProps): IDispatchToProps => ({
    setDirtyChartConfig: (config) => dispatch(setDirtyGeovisChartSettings(pageNum, config))
});

export default connect<IStateToProps, IDispatchToProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(withGeovisServer(TimeValueChartRender));