/**
 * Sensor alarm content
 * Can be used in Popup and Tooltip
 */
import React from 'react';
import { dateTimeToReactMoment } from '../../helpers/DateHelper';
import i18next from '../../i18n';
import { AlarmInfo } from '../../server/AlarmInfo';
import { AlarmType } from '../../server/AVTService/TypeLibrary/Alarming/AlarmType';
import { NotificationsTypes } from '../../server/AVTService/TypeLibrary/Notifications/NotificationsTypes';
import { getDigitsAfterDecimalPoint, getPhysicalUnitShortName } from '../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit';
import { SensorCategory } from '../../server/AVTService/TypeLibrary/Sensors/SensorCategory';

const getFA1TooltipAlarmText = (alarm: AlarmInfo): string => {
    const mask = i18next.t("FA1TooltipAlarmText");
    return mask.replace("%1", alarm.FailsCounter.toString());
}

const getFA2TooltipAlarmText = (alarm: AlarmInfo): string => {
    const mask = i18next.t("FA2TooltipAlarmText");
    return mask.replace("%1", alarm.FailsCounter.toString());
}

const getFA3TooltipAlarmText = (alarm: AlarmInfo): string => {
    const mask = i18next.t("FA3TooltipAlarmText");
    return mask.replace("%1", alarm.FailsCounter.toString());
}

const getFA4or5TooltipAlarmText = (alarm: AlarmInfo): string => {
    return alarm.ConditionName;
}

const getWDSensorAlarmText = (alarm: AlarmInfo, sensorType: SensorCategory): any => {
    const mask = isVirtualSensor(sensorType) ? i18next.t("WDVirtualSensorAlarmText") : i18next.t("WDSensorAlarmText");
    return (
        <div>{mask.replace("%1", "")}{dateTimeToReactMoment(alarm.SinceDate)}</div>
    );
}

const isVirtualSensor = (sensorType: SensorCategory) => {
    return sensorType === SensorCategory.VirtualSensor || sensorType === SensorCategory.AgmsVirtualSensor;
}

interface ISensorAlarmContentProps {
    alarm: AlarmInfo;
    sensorType: SensorCategory;
    sensorUnitDecimals: number;
}

/**
 * Sensor alarm content component
 * @param props of @type ISensorAlarmContentProps
 */
export const SensorAlarmContent = ({ alarm, sensorType }: ISensorAlarmContentProps) => {

    switch (alarm.AlarmType) {
        case AlarmType.AGMSAlarm:
            switch (alarm.AGMSAlarmType) {
                case NotificationsTypes.PrismNotMeasured:
                case NotificationsTypes.DimetrixNoData:
                    return getFA1TooltipAlarmText(alarm);
                case NotificationsTypes.PrismNotAccurate: return getFA2TooltipAlarmText(alarm);
                case NotificationsTypes.InclinometerChainNotMeasured: return getFA3TooltipAlarmText(alarm);
                default:
                    return getFA4or5TooltipAlarmText(alarm);
            }
            break;
        case AlarmType.WDSensorAlarm:
            return getWDSensorAlarmText(alarm, sensorType);
        default: {
            const name = alarm.SensorName ? alarm.SensorName : alarm.SensorId;

            return (
                <React.Fragment>
                    {alarm.ConditionName}&nbsp;{i18next.t("exceeded")}:&nbsp;{name}&nbsp;({alarm.SensorValue.toFixed(getDigitsAfterDecimalPoint(alarm.AlarmUnit))}&nbsp;{getPhysicalUnitShortName(alarm.AlarmUnit)})
                </React.Fragment>);
        }
    }
}