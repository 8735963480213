import Button from "@atlaskit/button";
import Checkbox from "@atlaskit/checkbox";
import DynamicTable from "@atlaskit/dynamic-table";
import { HeadType, RowCellType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import { OptionType } from "@atlaskit/select";
import Textfield from '@atlaskit/textfield';
import Tooltip from '@atlaskit/tooltip';
import React, { CSSProperties, SyntheticEvent } from "react";
import { t } from "../../../i18n";
import { AlarmChartValueType, AlarmChartValueTypeList, getAlarmChartValueTypeToDescription } from "../../../server/AVTService/TypeLibrary/Common/AlarmChartValueType";
import { ChartAlarmLineType, ChartAlarmLineTypeList, getChartAlarmLineTypeByName, getChartAlarmLineTypeToName } from "../../../server/AVTService/TypeLibrary/Common/ChartAlarmLineType";
import { ChartType } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { getSensorValueAttributeToName, SensorValueAttribute } from "../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { AlarmInfoRecord } from "../../../server/AVTService/TypeLibrary/Model/AlarmInfoRecord";
import { getPhysicalUnitByName, getPhysicalUnitShortName, PhysicalUnit } from "../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import GeovisColorPicker from "../../GeovisColorPicker";
import { GeovisSelect } from "../../select/GeovisSelect";
import { compareChartTypes } from "./tools";

const cellSelectStyle: CSSProperties = { width: '150px', textAlign: 'center' };
const cellDefaultStyle: CSSProperties = { width: '75px', textAlign: 'center' };
const valueCellStyle: CSSProperties = { textAlign: 'center' };
const valueFromCellStyle: CSSProperties = { width: '50%', float: 'left' };
const valueToCellStyle: CSSProperties = { width: '50%', float: 'right', textAlign: 'center' };

export interface IChartAlarmLinesControlActionProps {
    onChangeAlarmLines: (alarmLines: AlarmInfoRecord[]) => void;
    onNewAlarmLinkClickHandler: (alarmLine: AlarmInfoRecord) => void;
    onAlarmLineDeleteClickHandler: (index: number) => void;
}

interface IComponentProps extends IChartAlarmLinesControlActionProps {
    yUnit: PhysicalUnit;
    xUnit?: PhysicalUnit;
    AlarmLines: AlarmInfoRecord[];
    chartType: ChartType;
}

export const ChartAlarmLinesControl = (settings: IComponentProps) => {

    const defaultAlarmInfoRecord: AlarmInfoRecord = {
        ...new AlarmInfoRecord(),
        enabled: true,
        alarmId: '',
        swapXY: false,
        label: '',
        xvalueFrom: '0',
        xvalueTo: "0",
        yvalueFrom: "0",
        yvalueTo: "0",
        lineColor: '#000000',
        lineWeight: 1,
        lineType: ChartAlarmLineType.Solid,
        alarmChart: getSensorValueAttributeToName(SensorValueAttribute.ValuesXYZ),
        alarmChartValueType: AlarmChartValueType.AllCharts,
        drawLine: true,
        fillAlarmArea: false
    };

    const getYValueUnit = (): string => {
        let unit = settings.yUnit;
        if (typeof unit === "string") {
            unit = getPhysicalUnitByName(unit);
        }
        return getPhysicalUnitShortName(unit);
    }

    const getXValueUnit = (): string => {
        let unit = settings.xUnit ?? PhysicalUnit.Undefined;
        if (typeof unit === "string") {
            unit = getPhysicalUnitByName(unit);
        }
        return getPhysicalUnitShortName(unit);
    }

    const getAlarmTableHead = (): HeadType => {
        switch (settings.chartType) {
            case ChartType.DtsChart: return getDtsChartHead();
            case ChartType.XYDiagram: return getXYChartHead();
            case ChartType.Inclinometer: return getXYChartHead();
            case ChartType.XYVibrationEventDiagram: return getXYChartHead();
            case ChartType.VibrationDiagramm: return getVibrationChartTableHead();
            default: return getTimeValueChartTableHead();
        }
    }

    const getAlarmTableRows = (): RowType[] => {
        switch (settings.chartType) {
            case ChartType.DtsChart: return getDtsChartRows();
            case ChartType.XYDiagram: return getXYChartRows();
            case ChartType.Inclinometer: return getXYChartRows();
            case ChartType.XYVibrationEventDiagram: return getXYChartRows();
            case ChartType.VibrationDiagramm: return getVibrationChartRows();
            default: return getTimeValueChartRows();
        }
    }

    const getTimeValueChartTableHead = (): HeadType => ({
        cells: [{
            key: 'use',
            content: t("Enabled")
        }, {
            key: 'label',
            content: (
                <div style={cellDefaultStyle}>
                    {t("Label")}
                </div>
            )
        }, {
            key: 'yValue',
            content: (
                <div style={valueCellStyle}>
                    <div>{t("Y-Value [%1]").replace('%1', getYValueUnit())}</div>
                </div>
            )
        }, {
            key: 'valueType',
            width: 5,
            content: (
                <div style={cellSelectStyle}>
                    {t("Chart")}
                </div>
            )
        }, {
            key: 'color',
            content: (
                <div style={cellDefaultStyle}>
                    {t("Color")}
                </div>
            )
        }, {
            key: 'weight',
            content: (
                <div style={{ width: '80px', textAlign: 'center' }}>
                    {t("Weight")}
                </div>
            )
        }, {
            key: 'type',
            content: (
                <div style={{ width: '100px', textAlign: 'center' }}>
                    {t("Type")}
                </div>
            )
        }, {
            key: 'actions'
        }]
    });

    const getVibrationChartTableHead = (): HeadType => ({
        cells: [{
            key: 'use',
            content: t("Enabled")
        }, {
            key: 'label',
            content: (
                <div style={cellDefaultStyle}>
                    {t("Label")}
                </div>
            )
        }, {
            key: 'yValue',
            content: (
                <div style={valueCellStyle}>
                    <div>{t("Y-Value [%1]").replace('%1', getYValueUnit())}</div>
                </div>
            )
        }, {
            key: 'color',
            content: (
                <div style={cellDefaultStyle}>
                    {t("Color")}
                </div>
            )
        }, {
            key: 'weight',
            content: (
                <div style={{ width: '80px', textAlign: 'center' }}>
                    {t("Weight")}
                </div>
            )
        }, {
            key: 'type',
            content: (
                <div style={{ width: '100px', textAlign: 'center' }}>
                    {t("Type")}
                </div>
            )
        }, {
            key: 'actions'
        }]
    });

    const getDtsChartHead = (): HeadType => ({
        cells: [{
            key: 'use',
            content: t("Use")
        }, {
            key: 'label',
            content: (
                <div style={cellDefaultStyle}>
                    {t("Label")}
                </div>
            )
        }, {
            key: 'yValue',
            colSpan: 2,
            content: (
                <div style={valueCellStyle}>
                    <div>{t("Y-Value [%1]").replace('%1', getYValueUnit())}</div>
                    <div>
                        <div style={valueFromCellStyle}>{t("From")}</div>
                        <div style={valueToCellStyle}>{t("To")}</div>
                    </div>
                </div>
            )
        }, {
            key: 'color',
            content: (
                <div style={cellDefaultStyle}>
                    {t("Color")}
                </div>
            )
        }, {
            key: 'weight',
            content: (
                <div style={{ width: '80px', textAlign: 'center' }}>
                    {t("Weight")}
                </div>
            )
        }, {
            key: 'type',
            content: (
                <div style={{ width: '100px', textAlign: 'center' }}>
                    {t("Type")}
                </div>
            )
        }, {
            key: 'actions'
        }]
    });

    const getXYChartHead = (): HeadType => ({
        cells: [{
            key: 'use',
            content: t("Use")
        }, {
            key: 'label',
            content: (
                <div style={cellDefaultStyle}>
                    {t("Label")}
                </div>
            )
        }, {
            key: 'yValue',
            colSpan: 2,
            content: (
                <div style={valueCellStyle}>
                    <div>{t("Y-Value [%1]").replace('%1', getYValueUnit())}</div>
                    <div>
                        <div style={valueFromCellStyle}>{t("From")}</div>
                        <div style={valueToCellStyle}>{t("To")}</div>
                    </div>
                </div>
            )
        }, {
            key: 'xValue',
            colSpan: 2,
            content: (
                <div style={valueCellStyle}>
                    <div>{t("X-Value [%1]").replace('%1', getXValueUnit())}</div>
                    <div>
                        <div style={valueFromCellStyle}>{t("From")}</div>
                        <div style={valueToCellStyle}>{t("To")}</div>
                    </div>
                </div>
            )
        }, {
            key: 'color',
            content: (
                <div style={cellDefaultStyle}>
                    {t("Color")}
                </div>
            )
        }, {
            key: 'weight',
            content: (
                <div style={{ width: '80px', textAlign: 'center' }}>
                    {t("Weight")}
                </div>
            )
        }, {
            key: 'type',
            content: (
                <div style={{ width: '100px', textAlign: 'center' }}>
                    {t("Type")}
                </div>
            )
        }, {
            key: 'actions'
        }]
    });

    const onUseChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (index > -1 && index < settings.AlarmLines.length) {
            settings.AlarmLines[index].enabled = event.currentTarget.checked;
            settings.onChangeAlarmLines(settings.AlarmLines);
        }
    }

    const updateAlarmLine = (index: number, propertyName: keyof AlarmInfoRecord, value: string | number) => {
        const update = {};
        update[propertyName] = value;
        settings.AlarmLines[index] = { ...settings.AlarmLines[index], ...update };
        settings.onChangeAlarmLines(settings.AlarmLines);
    }

    const onStringPropertyChanged = (index: number, propertyName: keyof AlarmInfoRecord) => (event: SyntheticEvent<HTMLInputElement>) => {
        if (index > -1 && index < settings.AlarmLines.length) {
            updateAlarmLine(index, propertyName, event.currentTarget.value);
        }
    }

    const onLineTypeChanged = (index: number) => (option: OptionType) => {
        if (index > -1 && index < settings.AlarmLines.length) {
            settings.AlarmLines[index].lineType = option.value as ChartAlarmLineType;
            settings.onChangeAlarmLines(settings.AlarmLines);
        }
    }

    const getLineTypeValue = (lineType: any) => {
        let value = lineType as ChartAlarmLineType;
        if (typeof (lineType) === 'string') {
            value = getChartAlarmLineTypeByName(lineType);
        }
        return value;
    }

    const lineTypeOption = (lineType: ChartAlarmLineType) => {
        const lineTypeAsStr = getChartAlarmLineTypeToName(getLineTypeValue(lineType));
        return ({ label: lineTypeAsStr, value: lineTypeAsStr });
    }
    const getLineTypeOptions = (): OptionType[] => ChartAlarmLineTypeList.map<OptionType>(lineType => lineTypeOption(lineType));

    const onLineWeightChanged = (index: number) => (option: OptionType) => {
        if (index > -1 && index < settings.AlarmLines.length) {
            settings.AlarmLines[index].lineWeight = +option.value;
            settings.onChangeAlarmLines(settings.AlarmLines);
        }
    }

    const lineWeightOption = (lineWeight: number) => ({ label: lineWeight.toString(), value: lineWeight });
    const getLineWeightOptions = (): OptionType[] => {
        const possibleWeights: number[] = [];
        for (let i = 0.5; i <= 3; i = i + 0.5) {
            possibleWeights.push(i);
        }
        return possibleWeights.map<OptionType>(w => lineWeightOption(w));
    }

    // const getValueTypeOptions = (): OptionType[] => {
    //     return [
    //         { value: getSensorValueAttributeToName(SensorValueAttribute.Axis), label: getSensorValueAttributeToName(SensorValueAttribute.Axis) },
    //         { value: getSensorValueAttributeToName(SensorValueAttribute.AxisValue1), label: getSensorValueAttributeToName(SensorValueAttribute.AxisValue1) },
    //         { value: getSensorValueAttributeToName(SensorValueAttribute.AxisValue2), label: getSensorValueAttributeToName(SensorValueAttribute.AxisValue2) },
    //         { value: getSensorValueAttributeToName(SensorValueAttribute.AxisValue3), label: getSensorValueAttributeToName(SensorValueAttribute.AxisValue3) },
    //         { value: getSensorValueAttributeToName(SensorValueAttribute.ValuesXYZ), label: getSensorValueAttributeToName(SensorValueAttribute.ValuesXYZ) },
    //         { value: getSensorValueAttributeToName(SensorValueAttribute.Value1), label: getSensorValueAttributeToName(SensorValueAttribute.Value1) },
    //         { value: getSensorValueAttributeToName(SensorValueAttribute.Value2), label: getSensorValueAttributeToName(SensorValueAttribute.Value2) },
    //         { value: getSensorValueAttributeToName(SensorValueAttribute.Value3), label: getSensorValueAttributeToName(SensorValueAttribute.Value3) },
    //         { value: getSensorValueAttributeToName(SensorValueAttribute.Deviation), label: getSensorValueAttributeToName(SensorValueAttribute.Deviation) },
    //         { value: getSensorValueAttributeToName(SensorValueAttribute.DeviationXY), label: getSensorValueAttributeToName(SensorValueAttribute.DeviationXY) },
    //     ]
    // }
    const getValueTypeOptions = (): OptionType[] => {
        return AlarmChartValueTypeList.map(a => ({ label: getAlarmChartValueTypeToDescription(a), value: a }))
    }

    const getSelectedValueType = (val: AlarmChartValueType): OptionType | undefined => {
        return getValueTypeOptions().find(o => o.value === val);
    }

    const onValueTypeChanged = (index: number) => (val: OptionType) => {
        if (index > -1 && index < settings.AlarmLines.length) {
            settings.AlarmLines[index].alarmChartValueType = +val.value;
            settings.onChangeAlarmLines(settings.AlarmLines);
        }
    }

    const onColorChangeHandler = (index: number) => (hexColor: string, isPickerClosed: boolean) => {
        if (!isPickerClosed)
            return;

        if (index > -1 && index < settings.AlarmLines.length) {
            settings.AlarmLines[index].lineColor = hexColor;
            settings.onChangeAlarmLines(settings.AlarmLines);
        }
    }

    const onAlarmLineDeleteClick = (index: number) => () => {
        settings.onAlarmLineDeleteClickHandler(index);
    }

    const onNewAlarmLineClick = () => {
        settings.onNewAlarmLinkClickHandler(defaultAlarmInfoRecord);
    }

    const getDtsChartRows = (): RowType[] => {
        const getRowCells = (line: AlarmInfoRecord, index: number): RowCellType[] => {
            return [{
                key: 'use',
                content: (
                    <Checkbox
                        key={`cb-use-${index}-key`}
                        name={`cb-use-${index}-name`}
                        isChecked={line.enabled}
                        onChange={onUseChange(index)} />
                )
            }, {
                key: 'label',
                content: (
                    <Textfield
                        value={line.label}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "label")} />
                )
            }, {
                key: 'yValueFrom',
                content: (
                    <Textfield
                        value={line.yvalueFrom}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "yvalueFrom")} />
                )
            }, {
                key: 'yValueTo',
                content: (
                    <Textfield
                        value={line.yvalueTo}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "yvalueTo")} />
                )
            }, {
                key: 'color',
                content: (
                    <GeovisColorPicker
                        hexColor={line.lineColor}
                        onColorChange={onColorChangeHandler(index)} />
                )
            }, {
                key: 'weight',
                content: (
                    <GeovisSelect
                        id={"cmbLineWeight"}
                        options={getLineWeightOptions()}
                        isCompact={true}
                        value={lineWeightOption(line.lineWeight)}
                        onChange={onLineWeightChanged(index)} />
                )
            }, {
                key: 'type',
                content: (
                    <GeovisSelect
                        id={"cmbLineType"}
                        options={getLineTypeOptions()}
                        spacing='compact'
                        isCompact={true}
                        value={lineTypeOption(line.lineType)}
                        onChange={onLineTypeChanged(index)} />
                )
            }, {
                key: 'actions',
                content: (
                    <Button
                        spacing="none"
                        appearance="subtle-link"
                        title={t("Delete alarm line")}
                        iconBefore={<CrossCircleIcon label={""} />}
                        onClick={onAlarmLineDeleteClick(index)}
                    />
                )
            }]
        }

        const rows = settings.AlarmLines.map((alarmLine, index) => ({
            key: `row-${index}`,
            cells: getRowCells(alarmLine, index)
        }));

        return rows;
    }

    const getXYChartRows = (): RowType[] => {
        const getRowCells = (line: AlarmInfoRecord, index: number): RowCellType[] => {
            return [{
                key: 'use',
                content: (
                    <Checkbox
                        key={`cb-use-${index}-key`}
                        name={`cb-use-${index}-name`}
                        isChecked={line.enabled}
                        onChange={onUseChange(index)} />
                )
            }, {
                key: 'label',
                content: (
                    <Textfield
                        value={line.label}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "label")} />
                )
            }, {
                key: 'yValueFrom',
                content: (
                    <Textfield
                        value={line.yvalueFrom}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "yvalueFrom")} />
                )
            }, {
                key: 'yValueTo',
                content: (
                    <Textfield
                        value={line.yvalueTo}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "yvalueTo")} />
                )
            }, {
                key: 'xValueFrom',
                content: (
                    <Textfield
                        value={line.xvalueFrom}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "xvalueFrom")} />
                )
            }, {
                key: 'xValueTo',
                content: (
                    <Textfield
                        value={line.xvalueTo}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "xvalueTo")} />
                )
            }, {
                key: 'color',
                content: (
                    <GeovisColorPicker
                        hexColor={line.lineColor}
                        onColorChange={onColorChangeHandler(index)} />
                )
            }, {
                key: 'weight',
                content: (
                    <GeovisSelect
                        id={"cmbLineWeight"}
                        options={getLineWeightOptions()}
                        spacing='compact'
                        isCompact={true}
                        value={lineWeightOption(line.lineWeight)}
                        onChange={onLineWeightChanged(index)} />
                )
            }, {
                key: 'type',
                content: (
                    <GeovisSelect
                        id={"cmbLineType"}
                        options={getLineTypeOptions()}
                        isCompact={true}
                        spacing='compact'
                        value={lineTypeOption(line.lineType)}
                        onChange={onLineTypeChanged(index)} />
                )
            }, {
                key: 'actions',
                content: (
                    <Button
                        spacing="none"
                        appearance="subtle-link"
                        title={t("Delete alarm line")}
                        iconBefore={<CrossCircleIcon label={""} />}
                        onClick={onAlarmLineDeleteClick(index)}
                    />
                )
            }]
        }

        const rows = settings.AlarmLines.map((alarmLine, index) => ({
            key: `row-${index}`,
            cells: getRowCells(alarmLine, index)
        }));

        return rows;
    }

    const getTimeValueChartRows = (): RowType[] => {
        const getRowCells = (line: AlarmInfoRecord, index: number): RowCellType[] => {
            return [{
                key: 'use',
                content: (
                    <Checkbox
                        key={`cb-use-${index}-key`}
                        name={`cb-use-${index}-name`}
                        isChecked={line.enabled}
                        onChange={onUseChange(index)} />
                )
            }, {
                key: 'label',
                content: (
                    <Textfield
                        value={line.label}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "label")} />
                )
            }, {
                key: 'yValue',
                content: (
                    <Textfield
                        value={line.yvalueFrom}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "yvalueFrom")} />
                )
            }, {
                key: 'chartValueType',
                content: (
                    <GeovisSelect
                        id={"chartValueType"}
                        options={getValueTypeOptions()}
                        isCompact={true}
                        value={getSelectedValueType(line.alarmChartValueType)}
                        onChange={onValueTypeChanged(index)} />
                )
            }, {
                key: 'color',
                content: (
                    <GeovisColorPicker
                        key={`cp-${line.lineColor}-${index}`}
                        hexColor={line.lineColor}
                        onColorChange={onColorChangeHandler(index)} />
                )
            }, {
                key: 'weight',
                content: (
                    <GeovisSelect
                        id={"cmbLineWeight"}
                        options={getLineWeightOptions()}
                        isCompact={true}
                        value={lineWeightOption(line.lineWeight)}
                        onChange={onLineWeightChanged(index)} />
                )
            }, {
                key: 'type',
                content: (
                    <GeovisSelect
                        id={"cmbLineType"}
                        options={getLineTypeOptions()}
                        isCompact={true}
                        value={lineTypeOption(line.lineType)}
                        onChange={onLineTypeChanged(index)} />
                )
            }, {
                key: 'actions',
                content: (
                    <Button
                        spacing="none"
                        appearance="subtle-link"
                        title={t("Delete alarm line")}
                        iconBefore={<CrossCircleIcon label={""} />}
                        onClick={onAlarmLineDeleteClick(index)}
                    />
                )
            }]
        }

        const rows = settings.AlarmLines.map((alarmLine, index) => ({
            key: `row-${index}`,
            cells: getRowCells(alarmLine, index)
        }));

        return rows;
    }

    const getVibrationChartRows = (): RowType[] => {
        const getRowCells = (line: AlarmInfoRecord, index: number): RowCellType[] => {
            return [{
                key: 'use',
                content: (
                    <Checkbox
                        key={`cb-use-${index}-key`}
                        name={`cb-use-${index}-name`}
                        isChecked={line.enabled}
                        onChange={onUseChange(index)} />
                )
            }, {
                key: 'label',
                content: (
                    <Textfield
                        value={line.label}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "label")} />
                )
            }, {
                key: 'yValue',
                content: (
                    <Textfield
                        value={line.yvalueFrom}
                        isCompact={true}
                        onChange={onStringPropertyChanged(index, "yvalueFrom")} />
                )
            }, {
                key: 'color',
                content: (
                    <GeovisColorPicker
                        key={`cp-${line.lineColor}-${index}`}
                        hexColor={line.lineColor}
                        onColorChange={onColorChangeHandler(index)} />
                )
            }, {
                key: 'weight',
                content: (
                    <GeovisSelect
                        id={"cmbLineWeight"}
                        options={getLineWeightOptions()}
                        isCompact={true}
                        value={lineWeightOption(line.lineWeight)}
                        onChange={onLineWeightChanged(index)} />
                )
            }, {
                key: 'type',
                content: (
                    <GeovisSelect
                        id={"cmbLineType"}
                        options={getLineTypeOptions()}
                        isCompact={true}
                        value={lineTypeOption(line.lineType)}
                        onChange={onLineTypeChanged(index)} />
                )
            }, {
                key: 'actions',
                content: (
                    <Button
                        spacing="none"
                        appearance="subtle-link"
                        title={t("Delete alarm line")}
                        iconBefore={<CrossCircleIcon label={""} />}
                        onClick={onAlarmLineDeleteClick(index)}
                    />
                )
            }]
        }

        const rows = settings.AlarmLines.map((alarmLine, index) => ({
            key: `row-${index}`,
            cells: getRowCells(alarmLine, index)
        }));

        return rows;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '15px' }}>
            <div className="flexRowContainer" style={{ justifyContent: 'flex-start' }}>
                <div>
                    <h2>{t("Alarm lines")}</h2>
                </div>
                {compareChartTypes(settings.chartType, ChartType.TimeValue, ChartType.DtsChart) &&
                    <div>
                        <Tooltip content={t("Alarm lines will be displayed only for left chart axis")}>
                            <Button appearance={'subtle-link'} iconBefore={<EditorInfoIcon label={''} size='small' />} />
                        </Tooltip>
                    </div>
                }
            </div>
            <div className="flexRowContainer" style={{ justifyContent: 'flex-end' }}>
                <div className="flexCellContainer">
                    <Tooltip
                        content={t("Add new alarm line")}>
                        <Button
                            appearance="primary"
                            spacing="compact"
                            onClick={onNewAlarmLineClick}>
                            {t("Add alarm line")}
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <div className="flexRowContainer">
                <div className="flexCellContainer_g1">
                    <DynamicTable
                        head={getAlarmTableHead()}
                        rows={getAlarmTableRows()} />
                </div>
            </div>
        </div>
    )
}