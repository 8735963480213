/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 29.04.2022
 * @description Heatmap chart render options in report
 */

import { Guid } from "guid-typescript";
import { AxisTickPositionsArray } from "highcharts";
import { formattedDateTime } from "../../../../../../helpers/DateHelper";
import { t } from "../../../../../../i18n";
import { GeovisHeatmapSensorData } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisHeatmapSensorData";
import { HeatmapChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/HeatmapChartData";
import { HeatmapSensorInfo } from "../../../../../../server/AVTService/TypeLibrary/Computation/HeatmapSensorInfo";
import { GeovisChartAxisSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { HeatmapChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel";
import { HeatmapRenderingMode } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapRenderingMode";
import { HeatmapTemperatureColorEntry } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapTemperatureColorEntry";
import { HeatmapValueSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapValueSettings";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { getPhysicalUnitByName, getPhysicalUnitShortName, PhysicalUnit } from "../../../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { createChartRenderedFunc } from "../../tools";
import { getSeriesSymbol, getYAxisUnitsAnnotation } from "./chartRenderOptions";
import { setHeatmapContourSeriesOptions } from './heatmapChartRenderOptionsJs';
import { renderChartSensorAdditionalAttributes } from "./tools";
import { AxisScaleLimit } from "../../../../../../server/AVTService/TypeLibrary/Model/AxisScaleLimit";

interface IHeatmapChartRenderOptions {
    pageNum: number;
    chart: HeatmapChartModel;
    data: HeatmapChartData;
}

/**
 * Gets heatmap chart render options for report
 * @param chart 
 * @returns 
 */
export const getHeatmapChartRenderOptions = ({ pageNum, chart, data }: IHeatmapChartRenderOptions): Highcharts.Options => {

    const { Data, startMeasurementTime, visualSettings } = data;

    const {
        XAxisSettings,
        LeftYAxisSettings: YAxisSettings,
        ValueSettings
    } = chart;

    const hmOptions: Highcharts.Options = {
        chart: {
            zooming: {
                type: chart.IsChartZoomAllow ? 'xy' : undefined,
                resetButton: { position: { x: 0, y: 0, verticalAlign: "top" } }
            },
            type: 'heatmap',
            showAxes: true,
            events: {
                render: createChartRenderedFunc(pageNum, chart.Id)
            }
        },
        annotations: [getYAxisUnitsAnnotation(YAxisSettings, 0)],
        // boost: getBoostOptions(chart.Type, data.SlimChartConfig),
        boost: { enabled: false },
        title: {
            floating: false,
            text: "<div style='height:10px'></div>",
            useHTML: true
        },
        xAxis: {
            type: 'linear',
            title: getAxisTitleOptions(XAxisSettings, visualSettings),
            min: XAxisSettings.minScaleLimit.auto ? undefined : XAxisSettings.minScaleLimit.value,
            max: XAxisSettings.maxScaleLimit.auto ? undefined : XAxisSettings.maxScaleLimit.value,
            // softMax: getSoftLimitValue(XAxisSettings.maxScaleLimit),
            // softMin: getSoftLimitValue(XAxisSettings.minScaleLimit),
            reversed: XAxisSettings.ReverseAxis.value,
            startOnTick: true,
            endOnTick: true,
            crosshair: { zIndex: 7 },
            labels: {
                formatter(ctx): string {
                    return (+ctx.value).toFixed(XAxisSettings.numberOfDigits.value);
                },
                style: {
                    fontSize: visualSettings.chartAxisLabelFontSize
                }
            }
        },
        yAxis: {
            type: 'linear',
            crosshair: { zIndex: 7 },
            title: getAxisTitleOptions(YAxisSettings, visualSettings),
            min: YAxisSettings.minScaleLimit.auto ? undefined : YAxisSettings.minScaleLimit.value,
            max: YAxisSettings.maxScaleLimit.auto ? undefined : YAxisSettings.maxScaleLimit.value,
            // softMax: getSoftLimitValue(YAxisSettings.maxScaleLimit),
            // softMin: getSoftLimitValue(YAxisSettings.minScaleLimit),
            startOnTick: false,
            endOnTick: false,
            labels: {
                style: {
                    fontSize: visualSettings.chartAxisLabelFontSize
                },
                useHTML: true,
                formatter(ctx): string {
                    // if (this.isLast) {
                    //     const axisUnit = YAxisSettings.DestConverter.enable
                    //         ? getPhysicalUnitByName(YAxisSettings.DestConverter.unit)
                    //         : YAxisSettings.Unit;

                    //     // return `<div style="margin-top: -15px;">${getUnitShortName(axisUnit)}</div><div>${this.value}</div>`;
                    //     return `<div>${getUnitShortName(axisUnit)}&nbsp;${(+this.value).toFixed(YAxisSettings.numberOfDigits.value)}</div>`;
                    // }
                    return (+ctx.value).toFixed(YAxisSettings.numberOfDigits.value);
                }
            },
            tickPositioner() {
                return getTickPositions(YAxisSettings.minScaleLimit, YAxisSettings.maxScaleLimit, this.tickPositions);
            }
        },
        // colorAxis: {
        //     stops: getHeatmapPaletteStops(ValueSettings),
        //     min: ValueSettings.MinValue.value,
        //     max: ValueSettings.MaxValue.value,
        //     labels: {
        //         format: `{value}${getValueUnitShortName(ValueSettings)}`
        //     }
        // },
        plotOptions: {
            series: {
                animation: false,
                marker: {
                    // enabled: isSharpMode,
                    // radius: isSharpMode ? undefined : 0,
                    enabled: true,
                    symbol: 'circle',
                    lineColor: 'black',
                    lineWidth: 0.5,
                    animation: false
                }
            }
        },
        lang: {
            noData: `<div style='z-index:20'>${chart.LeftYAxisSettings.SensorIds.length > 0 || chart.DtsSettings.DtsSectionIds.length > 0 ? t('No data is available in the chart') : t('No sensors selected for drawing the chart')}</div>`
        },
        noData: {
            useHTML: true
        },
        credits: {
            enabled: false
        },
        legend: getLegendSettings(ValueSettings),
        series: getHeatmapChartSeries(chart, Data)
    };

    let minData: number | undefined;
    let maxData: number | undefined;
    for (const point of Data) {
        if (minData === undefined || minData > point.Value) {
            minData = point.Value;
        }
        if (maxData === undefined || maxData < point.Value) {
            maxData = point.Value;
        }
    }

    hmOptions.colorAxis = {
        stops: getHeatmapPaletteStops(ValueSettings, minData, maxData),
        min: ValueSettings.MinValue.auto ? minData : ValueSettings.MinValue.value,
        max: ValueSettings.MaxValue.auto ? maxData : ValueSettings.MaxValue.value,
        startOnTick: ValueSettings.MinValue.auto ? undefined : false,
        endOnTick: ValueSettings.MaxValue.auto ? undefined : false,
        labels: {
            format: `{value}${getValueUnitShortName(ValueSettings)}`
        },
        tickPositioner() {
            return getTickPositions(ValueSettings.MinValue, ValueSettings.MaxValue, this.tickPositions);
        }
    };

    if (chart.ShowStartMeasurements) {

        hmOptions.subtitle = {
            text: t('Start of measurements') + `: ${formattedDateTime(startMeasurementTime, "DD.MM.YYYY")}`,
            align: 'center',
            verticalAlign: 'top'
        };
    }

    if (chart.RenderingMode.value === HeatmapRenderingMode.Smooth) {
        return setHeatmapContourSeriesOptions(hmOptions);
    }

    // Sharp mode by default
    return hmOptions;
}

const getTickPositions = (minScaleLimit: AxisScaleLimit, maxScaleLimit: AxisScaleLimit,sourceTickPositions?: AxisTickPositionsArray) => {
    let tickPositions = sourceTickPositions;
    if (!tickPositions) {
        return [];
    }

    if(!minScaleLimit.auto){
        const minValue = minScaleLimit.value;

        // keep only upper than min value
        tickPositions = tickPositions.filter(p => p >= minValue);
        if (tickPositions.indexOf(minValue) === -1) {
            tickPositions.push(minValue);
        }
    }

    if (!maxScaleLimit.auto) {
        const maxValue = maxScaleLimit.value;

        // keep only lower that max value
        tickPositions = tickPositions.filter(p => p <= maxValue);
        if (tickPositions.indexOf(maxValue) === -1) {
            tickPositions.push(maxValue);
        }
    }

    return tickPositions;
}

export const getHeatmapDefaultPalette = (minValue: number, maxValue: number): HeatmapTemperatureColorEntry[] => [
    { Temperature: minValue, ColorHex: '#3060cf', Id: Guid.create().toString() },
    { Temperature: +(minValue + (maxValue - minValue) * 0.5).toFixed(1), ColorHex: '#fffbbc', Id: Guid.create().toString() },
    { Temperature: maxValue, ColorHex: '#c4463a', Id: Guid.create().toString() }
];

const getHeatmapPaletteStops = ({ TemperatureColorSettings: { ColorEntries, EnableCustomColorScheme }, MinValue, MaxValue }: HeatmapValueSettings, minData: number | undefined, maxData: number | undefined): Array<[number, string]> => {

    if (!EnableCustomColorScheme || ColorEntries.length < 2) {
        return defaultHeatmapPaletteStops();
    }

    const minValue = MinValue.auto && minData !== undefined ? minData : MinValue.value;
    const maxValue = MaxValue.auto && maxData !== undefined ? maxData : MaxValue.value;

    return ColorEntries.map<[number, string]>(e => [calculateColorStopValue(minValue, maxValue, e.Temperature), e.ColorHex]);
}

const calculateColorStopValue = (minValue: number, maxValue: number, value: number): number => {

    const range = maxValue - minValue;
    const result = (value - minValue) / range;

    return +result.toFixed(2);
}

const defaultHeatmapPaletteStops = (): Array<[number, string]> => [
    [0, '#3060cf'],
    [0.5, '#fffbbc'],
    [1, '#c4463a']
]

const getValueUnitShortName = ({ DestConverter, Unit }: HeatmapValueSettings): string => {
    const unit = DestConverter.enable ? getPhysicalUnitByName(DestConverter.unit) : Unit;
    return getPhysicalUnitShortName(unit)
}

const getLegendSettings = (valueAxis: HeatmapValueSettings): Highcharts.LegendOptions => {

    const { ShowAxisLabel, ShowUnitInAxisLabel, AxisLabel } = valueAxis;

    const result: Highcharts.LegendOptions = {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemStyle: {
            fontSize: "14px"
        }
    };

    if (ShowAxisLabel) {
        result.title = { text: AxisLabel };

        if (ShowUnitInAxisLabel) {
            result.title.text += ', ' + getValueUnitShortName(valueAxis);
        }
    }

    return result;
}

const getAxisTitleOptions = (chartAxis: GeovisChartAxisSettings, visualSettings: ProjectVisualSettings): Highcharts.XAxisTitleOptions | undefined => {
    if (!chartAxis.showAxisLabels.value || !chartAxis.axisXLabel) {
        return undefined;
    }

    let label = chartAxis.axisXLabel;

    if (chartAxis.showAxisLabelsUnit) {
        label = label + ` [${getPhysicalUnitShortName(chartAxis.Unit)}]`;
    }

    return {
        text: label,
        style: {
            fontSize: visualSettings.chartAxisLabelFontSize
        }
    };
}

const getHeatmapChartSeries = (chart: HeatmapChartModel, data: GeovisHeatmapSensorData[]): Highcharts.SeriesHeatmapOptions[] => {

    const { ValueSettings, CustomTooltip, ShowCustomTooltip, RenderingMode, LeftYAxisSettings } = chart;

    const series: Highcharts.SeriesHeatmapOptions = {
        type: 'heatmap',
        rowsize: 3,
        nullColor: '#EFEFEF',
        tooltip: {
            headerFormat: getValueHeaderFormat(ValueSettings),
            pointFormatter() {

                let content = '';

                if (this.options.custom) {
                    if (this.options.custom.info) {
                        const info = this.options.custom.info as HeatmapSensorInfo;

                        content += '<b>' + info.Name + '</b></br>';
                    }
                }

                const { value } = this.options;
                if (!value) {
                    return content;
                }

                content += getSeriesSymbol({ color: this.color, symbol: 'circle' }) + value.toFixed(ValueSettings.NumberOfDigits) + ' ' + getValueUnitShortName(ValueSettings);

                if (this.options.custom && this.options.custom.info) {

                    if (ShowCustomTooltip) {
                        content += renderChartSensorAdditionalAttributes(CustomTooltip, this.options.custom.info as HeatmapSensorInfo);
                    }
                }

                return content;
            }
        },
        data: convertToHeatmapDataPoints(RenderingMode.value, ValueSettings, LeftYAxisSettings, data)
    }

    return [series];
}

const convertToHeatmapDataPoints = (renderingMode: HeatmapRenderingMode, axisSettings: HeatmapValueSettings, yAxisSettings: GeovisChartAxisSettings, data: GeovisHeatmapSensorData[]): Highcharts.PointOptionsObject[] => {

    const result: Highcharts.PointOptionsObject[] = [];

    for (const point of data) {

        // over 360 (+360)
        if (yAxisSettings.Unit === PhysicalUnit.Degree) {
            const destPoint = getHeatmapPoint(axisSettings, movePointVertical(point, 'up'));

            if (renderingMode === HeatmapRenderingMode.Smooth || yAxisSettings.maxScaleLimit.auto || destPoint.y! <= yAxisSettings.maxScaleLimit.value) {
                result.push(destPoint);
            }
        }

        // current angle
        result.push(getHeatmapPoint(axisSettings, point));

        // below 0 (-360)
        if (yAxisSettings.Unit === PhysicalUnit.Degree) {
            const destPoint = getHeatmapPoint(axisSettings, movePointVertical(point, 'down'));

            if (renderingMode === HeatmapRenderingMode.Smooth || yAxisSettings.minScaleLimit.auto || destPoint.y! >= yAxisSettings.minScaleLimit.value) {
                result.push(destPoint);
            }
        }
    }

    return result;
}

const movePointVertical = (data: GeovisHeatmapSensorData, move: 'up' | 'down' | 'none'): GeovisHeatmapSensorData => {
    if (move === 'up') {
        return { ...data, YAxisValue: data.YAxisValue + 360 };
    }

    if (move === 'down') {
        return { ...data, YAxisValue: data.YAxisValue - 360 };
    }

    return data;
}

const getHeatmapPoint = (axisSettings: HeatmapValueSettings, p: GeovisHeatmapSensorData): Highcharts.PointOptionsObject => ({
    x: p.XAxisValue,
    y: p.YAxisValue,
    // value: getDestHeatmapValue(axisSettings.DestConverter, p.Value),
    value: p.Value,
    custom: { info: p.SensorInfo, exactTime: p.ExactTime }
})

const getValueHeaderFormat = (axisSettings: HeatmapValueSettings): string | undefined => {
    if (!axisSettings.ShowAxisLabel) {
        return undefined;
    }

    return axisSettings.AxisLabel + '</br>';
}