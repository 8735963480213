import Button from '@atlaskit/button';
import Modal, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog';
import { AppearanceType } from '@atlaskit/modal-dialog/dist/cjs/types';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { LayoutManager, modeGenerator, NavigationProvider, ThemeProvider } from '@atlaskit/navigation-next';
import Spinner from '@atlaskit/spinner';
import TextArea from '@atlaskit/textarea';
import H from 'history';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Redirect,
    Route,
    RouteComponentProps,
    Switch,
    useLocation,
    useRouteMatch,
    withRouter
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dispatch } from 'redux';
import { IS_DEVELOPMENT, IS_TEST } from './ApiConfig';
import { CheckMapTilesConnectionComponent } from './components/CheckMapTilesConnectionComponent';
import GlobalNavigation from './components/navigation/GlobalNavigation';
import ProjectEditNavigation from './components/navigation/ProjectEditNavigation';
import UserProfileNavigation from './components/navigation/UserProfileNavigation';
import UsersAndCompaniesNavigation from './components/navigation/UsersAndCompaniesNavigation';
import { notifyAboutNewVersion } from './components/notification/NewVersionNotification';
import { isClientVersionUpToDate } from './components/notification/tools';
import PageTitleItem from './components/PageTitleItem';
import GeovisRoute from './components/routing/GeovisRoute';
import {
    AboutPageLazy,
    AddCompanyPageLazy,
    AddUserPageLazy,
    ArchivingNavigationLazy,
    ArchivingProjectsPageLazy,
    CompaniesPageLazy,
    DashboardPageLazy,
    DataMigrationPageLazy,
    ExportPageLazy,
    FaqPageLazy,
    FeedbacksPageLazy,
    ForgotPasswordConfirmationPageLazy,
    ForgotPasswordPageLazy,
    FormulaSyntaxHelpPageLazy,
    GeovisInventoryCalendarPageLazy,
    GeovisInventoryPageLazy,
    GeovisInventoryReservationPageLazy,
    GeovisInventoryTreePageLazy,
    GlobalInventoryNavigationLazy,
    GlobalInvoicePageLazy,
    GlobalNotificationsStatisticPageLazy,
    GlobalProjectsHistoryPageLazy,
    GlobalStatisticsNavigationLazy,
    GlobalStatisticsPageLazy,
    ImportPageLazy,
    LogosManagerPageLazy,
    LogsFollowPageLazy,
    LogsNavigationLazy,
    LogsPageLazy,
    LogsSettingsPageLazy,
    MessagesOverviewPageLazy,
    MessageTemplateEditPageLazy,
    NotificationsSettingsPageLazy,
    ProjectCreationPageLazy,
    ProjectDocumentsPageLazy,
    ProjectEditNavigationPageLazy,
    ProjectInventoryPageLazy,
    ProjectLogbookElementRecordsPageLazy,
    ProjectLogbookPageLazy,
    ProjectOverviewNavigationLazy,
    ProjectOverviewPageLazy,
    ProjectReportPageLazy,
    ProjectReportsPageLazy,
    ProjectsOverviewPageLazy,
    ProjectSupportPageLazy,
    ReleaseNoteEditPageLazy,
    ReleaseNotesPageLazy,
    ReportOverlayNavigationLazy,
    ResetPasswordPageLazy,
    RestoringProjectsPageLazy,
    SensorTypesHelpPageLazy, SettingsBackupPageLazy,
    SettingsNavigationLazy,
    SoftwareHelpNavigationLazy,
    SyncTimeslotOverviewPageLazy,
    SyncTimeslotProcessingQueuePageLazy,
    SyncTimeslotsNavigationLazy,
    SyncTimeslotsProjectInfoPageLazy,
    SyncTimeslotsServiceSettingsPageLazy,
    UnitsHelpPageLazy,
    UserFeedbackPageLazy,
    UserProfileChangePasswordPageLazy,
    UserProfileEditPageLazy,
    UsersPageLazy,
    WatchdogServiceSettingsPageLazy,
    DocumentationPageLazy,
    MaintenanceServicePageLazy,
    ProjectStatisticNavigationPageLazy,
    PartnerCompanyManagerPageLazy,
    CostCalculatorPageLazy,
    GeneralSettingsPageLaze,
    ErrorPageNavigationLazy,
    GeoRasterSettingsPageLazy
} from './GeovisLazyComponents';
import Constants, { GeovisName } from './helpers/Constants';
import Routes from './helpers/Routes';
import { t } from './i18n';
import ProjectElementsMigrationsPage from './pages/business/settings/elementsMigrations/ProjectElementsMigrationsPage';
import LoginPage from './pages/LoginPage';
import ReportRenderPage from './pages/pdfReportRendering/ReportPdfRenderPage';
import GeovisChartReportRenderToImagePage from './pages/reports/GeovisChartReportRenderToImagePage';
import { InventoryObjectType } from './server/AVTService/TypeLibrary/Inventory/InventoryObjectType';
import { GvLogEntryType } from './server/Geovis/Logger/Model/Abstract/GvLogEntryType';
import AuthService from './services/AuthService';
import FlagService, { FlagType, IFlag } from './services/FlagService';
import Logger from './services/Logger';
import { loginOut, refreshNavigationState } from './store/creators/navigationCreators';
import { IGeovisStoreState } from './store/store.types';
import {
    IGeovisAction,
    IGeovisLoginState,
    IGeovisNavigationState,
    IGeovisServiceWorkerState
} from './store/types';
import ProjectStatisticNavigation from './components/navigation/ProjectStatisticNavigation';

const LoggerSource = "GeovisNavigationRouter";

type LType = H.Location<any>;

interface IGeovisNavigationRouterStateProps {
    navigationState: IGeovisNavigationState;
    login: IGeovisLoginState;
    serviceWorkerState: IGeovisServiceWorkerState;
}

interface IGeovisNavigationRouterDispatchProps {
    handleForcedSignOut: () => void;
    dispatch: (action: IGeovisAction) => void;
}

interface IGeovisNavigationRouterProps extends IGeovisNavigationRouterStateProps, IGeovisNavigationRouterDispatchProps, RouteComponentProps<{}> {

}

const GeovisNavigationRouter = ({
    dispatch,
    handleForcedSignOut,
    navigationState,
    serviceWorkerState,
    location
}: IGeovisNavigationRouterProps) => {

    const [drawMapTilesCheckConnectionEx, setDrawMapTilesCheckConnectionEx] = useState<boolean>(false);
    const [fetchingServerInfo, setFetchingServerInfo] = useState<boolean>(true);

    // process component mount/unmount events
    useEffect(() => {
        Logger.nav('Mounting GeovisNavigationRouter....')

        AuthService.registerSignOutHandler(handleForcedSignOut);

        // load user info if user is authenticated already
        // it is happens when page reloading by Ctrl+F5
        if (AuthService.isAuthenticatedUser()) {

            (async function checkParams() {

                if (!AuthService.getUserInfo()) {
                    setFetchingServerInfo(true);

                    // fetching user info, it should be done always
                    await AuthService.fetchServerInfo();

                    setFetchingServerInfo(false);
                }

                if (!await isClientVersionUpToDate()) {
                    window.location.reload();
                }

                if (await AuthService.checkAuthenticationInMapTilesServiceAsync()) {

                    if (AuthService.isNagraDistribution()) {
                        setDrawMapTilesCheckConnectionEx(true);
                    }

                } else {
                    // hide notification only for development and not admin users
                    if (IS_DEVELOPMENT || !AuthService.hasUserTypeAsAdmin()) {
                        Logger.warning("Cannot establish connection to MapTilesService", LoggerSource);
                    }
                    else {
                        FlagService.addError(
                            'Connecting to MapTilesService',
                            'Cannot establish connection to MapTilesService, some features will not work');

                    }
                }

                dispatch(refreshNavigationState());
            })();
        } else {
            setFetchingServerInfo(false);
        }

        Logger.nav('GeovisNavigationRouter mount');

        return function unm() {
            Logger.nav('GeovisNavigationRouter will unmount');
        }
    }, [AuthService.currentUserId()]);

    useEffect(() => {
        if (serviceWorkerState.isNewVersionAvailable) {
            (async function notifyVersion() {
                const serverVersion = await AuthService.getServerVersion();
                setTimeout(() => notifyAboutNewVersion({ serverVersion, serviceWorkerState, dispatch }), 50);
            })()

        }
    }, [serviceWorkerState.isNewVersionAvailable]);

    const isRenderReportPage = useRouteMatch(Routes.geovisReportPdfPage);

    if (isRenderReportPage) {
        return (
            <ReportRenderPage />
        )
    }

    if (useRouteMatch(Routes.renderGeovisChartToPng)) {
        return (
            <GeovisChartReportRenderToImagePage />
        )
    }


    if (showLogin(location) && AuthService.isAuthenticatedUser()) {
        return (<Redirect to="/" />);
    }

    Logger.render(GeovisNavigationRouter.name);

    // const signalRService = new SignalRConnectionService();
    // signalRService.start();
    return (
        <ThemeProvider theme={{
            context: (location.pathname !== Routes.home.path ? "product" : "container"),
            mode: modeGenerator({
                product: {
                    text: Constants.colorNavForegroundGlobal,
                    background: getProductionNavigationColor()
                }
            })
        }}>
            <div id="page_ready" style={{ height: "100%" }}>
                {!fetchingServerInfo && showNavigation(location) && (
                    <NavigationProvider>

                        {/* show navigation, but not login page there*/}
                        <LayoutManager
                            initialUIController={{ isCollapsed: true }}
                            globalNavigation={GeovisGlobalNavigation}
                            productNavigation={() => (null)}
                            // containerNavigation={isShowContainer(location) ? GeovisContainerNavigation : null}>
                            containerNavigation={GeovisContainerNavigation}>
                            {geovisCreateRoutes()}
                        </LayoutManager>
                    </NavigationProvider>
                )}

                {fetchingServerInfo && drawLoadingUserInfoSkeleton()}

                {!fetchingServerInfo && (
                    <React.Fragment>
                        {/* show login page */}
                        {showLogin(location) && (
                            <div className="routingContent">
                                <Route {...Routes.login} component={loginPage} />
                            </div>
                        )}

                        {showForgotPassword(location) && (
                            <div className="routingContent">
                                <Route {...Routes.forgotPassword} component={forgotPasswordPage} />
                            </div>
                        )}

                        {showForgotPasswordConfirmation(location) && (
                            <div className="routingContent">
                                <Route {...Routes.forgotPasswordConfirmation} component={forgotPasswordConfirmationPage} />
                            </div>
                        )}

                        {showResetPassword(location) && (
                            <div className="routingContent">
                                <Route {...Routes.resetPassword} component={resetPasswordPage} />
                            </div>
                        )}

                        {/* show flags group */}
                        <div id="id-flag-group">
                            {createFlagGroup(navigationState)}
                        </div>

                        <ToastContainer style={{ width: '340px' }} />
                        {/* <SignalRConfiguration /> */}

                        {drawMapTilesCheckConnectionEx && (
                            <CheckMapTilesConnectionComponent />
                        )}
                    </React.Fragment>
                )}
            </div>
        </ThemeProvider>
    )
}

const getProductionNavigationColor = (): string => {

    if (IS_TEST) {
        return Constants.colorNavBackgroundGlobalTestEnvironment;
    }

    if (IS_DEVELOPMENT) {
        return Constants.colorNavBackgroundGlobalDevEnvironment;
    }

    return Constants.colorNavBackgroundGlobal;
}


const showNavigation = (location: LType): boolean => {

    return !(showLogin(location) || showResetPassword(location) || showForgotPassword(location) || showForgotPasswordConfirmation(location));
}

const showLogin = ({ pathname }: LType): boolean => {
    return pathname === Routes.login.path;
}

const showForgotPassword = ({ pathname }: LType): boolean => {
    return pathname === Routes.forgotPassword.path;
}

const showForgotPasswordConfirmation = ({ pathname }: LType): boolean => {
    return pathname === Routes.forgotPasswordConfirmation.path;
}

const showResetPassword = ({ pathname }: LType): boolean => {
    return pathname === Routes.resetPassword.path;
}

// const isShowContainer = ({ pathname }: LType) => {
//     return pathname !== Routes.home.path;
// }

const drawLoadingUserInfoSkeleton = () => {

    const versionJson = require("./version.json");
    const aboutString = `Amberg ${t("GEOvis 4.0")} | ${t("labelVersion")}: ${versionJson.version} | © Amberg Infra 7D AG`;

    return (
        <div id="login-page">
            <div className="panel">
                <div className="header">
                    <PageTitleItem title={GeovisName} />
                </div>

                <Spinner size="xlarge" />
                <span style={{ marginLeft: '20px' }}>{t("Loading user info")}...</span>
            </div>
            <div className="footer">
                <br />
                {aboutString}
            </div>
        </div>
    )
}

const GeovisGlobalNavigation = () => (
    <GlobalNavigation />
)

const GeovisContainerNavigation = () => (
    <Switch>
        {/* <GeovisRoute {...Routes.projectCreate} component={projectEditNavigation} /> */}

        <GeovisRoute {...Routes.project} component={projectOverviewNavigation} />
        <GeovisRoute {...Routes.projectView} component={projectOverviewNavigation} />
        <GeovisRoute {...Routes.projectLogbookElement} component={projectOverviewNavigation} />
        <GeovisRoute {...Routes.projectEdit} exact={false} component={projectEditNavigation} />
        <GeovisRoute {...Routes.projectReports} component={projectOverviewNavigation} />
        <GeovisRoute {...Routes.projectDocuments} component={projectOverviewNavigation} />
        <GeovisRoute {...Routes.projectLogbook} component={projectEditNavigation} />
        <GeovisRoute {...Routes.projectStatistic} component={projectStatisticNavigation} />
        <GeovisRoute {...Routes.projectDetailedStatistic} component={projectStatisticNavigation} />
        <GeovisRoute {...Routes.projectQuickStatistic} component={projectStatisticNavigation} />

        <GeovisRoute {...Routes.projectReport} component={reportOverlayNavigation} />
        <GeovisRoute {...Routes.projectReportWithViewId} component={reportOverlayNavigation} />

        <GeovisRoute {...Routes.userProfile} component={userProfileNavigation} />
        <GeovisRoute {...Routes.changePassword} component={userProfileNavigation} />

        {/* Global statistic */}
        <GeovisRoute {...Routes.globalStatistics} component={globalStatisticsNavigation} />
        <GeovisRoute {...Routes.globalProjectsHistory} component={globalStatisticsNavigation} />
        <GeovisRoute {...Routes.globalInvoice} component={globalStatisticsNavigation} />
        <GeovisRoute {...Routes.globalNotificationsStat} component={globalStatisticsNavigation} />


        {/* Global inventory navigation */}
        <GeovisRoute {...Routes.inventoryMst} exact={false} component={globalInventoryNavigation} />
        <GeovisRoute {...Routes.inventoryAfm} exact={false} component={globalInventoryNavigation} />
        <GeovisRoute {...Routes.inventoryAtc} exact={false} component={globalInventoryNavigation} />
        <GeovisRoute {...Routes.inventoryTachy} exact={false} component={globalInventoryNavigation} />
        <GeovisRoute {...Routes.inventorySb} exact={false} component={globalInventoryNavigation} />
        <GeovisRoute {...Routes.inventoryLora} exact={false} component={globalInventoryNavigation} />
        <GeovisRoute {...Routes.inventoryTree} exact={false} component={globalInventoryNavigation} />
        <GeovisRoute {...Routes.inventoryCalendarView} exact={false} component={globalInventoryNavigation} />
        <GeovisRoute {...Routes.inventoryReservation} exact={false} component={globalInventoryNavigation} />


        <GeovisRoute {...Routes.companies} component={usersAndCompaniesNavigation} />
        <GeovisRoute {...Routes.users} component={usersAndCompaniesNavigation} />
        <GeovisRoute {...Routes.addCompany} component={usersAndCompaniesNavigation} />
        <GeovisRoute {...Routes.addUser} component={usersAndCompaniesNavigation} />
        <GeovisRoute {...Routes.exportUsersCompanies} component={usersAndCompaniesNavigation} />
        <GeovisRoute {...Routes.importUsersCompanies} component={usersAndCompaniesNavigation} />
        <GeovisRoute {...Routes.partnerCompanyManager} component={usersAndCompaniesNavigation} />
        <GeovisRoute {...Routes.messagesToUsers} component={usersAndCompaniesNavigation} />

        <GeovisRoute {...Routes.aboutSoftware} component={softwareHelpNavigation} />
        <GeovisRoute {...Routes.unitsHelp} component={softwareHelpNavigation} />
        <GeovisRoute {...Routes.sensorTypesHelp} component={softwareHelpNavigation} />
        <GeovisRoute {...Routes.formulaSyntaxHelp} component={softwareHelpNavigation} />
        <GeovisRoute {...Routes.faq} component={softwareHelpNavigation} />
        <GeovisRoute {...Routes.releaseNotes} component={softwareHelpNavigation} />
        <GeovisRoute {...Routes.costCalculator} component={softwareHelpNavigation} />
        <GeovisRoute {...Routes.releaseNoteEdit} component={softwareHelpNavigation} />
        <GeovisRoute {...Routes.userFeedback} component={softwareHelpNavigation} />
        <GeovisRoute {...Routes.documentation} component={softwareHelpNavigation} />

        {/* logs */}
        <GeovisRoute {...Routes.logs} exact={false} component={logsNavigation} />

        {/* Global imports */}
        {/* <GeovisRoute {...Routes.globalImports} exact={false} component={globalImportsNavigation} /> */}

        {/* settings */}
        <GeovisRoute {...Routes.settingsRoot} exact={false} component={settingsNavigation} />

        {/* sync timeslots */}
        <GeovisRoute {...Routes.syncTimeslotsRoot} exact={false} component={syncTimeslotsNavigation} />

        <GeovisRoute {...Routes.archivingProjects} exact={false} component={archivingNavigation} />
        <GeovisRoute {...Routes.restoringProjects} exact={false} component={archivingNavigation} />
    </Switch>
)

const projectOverviewNavigation = () => (<ProjectOverviewNavigationLazy />)

const projectEditNavigation = () => (
    <ProjectEditNavigation />
)

const projectStatisticNavigation = () => (
    <ProjectStatisticNavigation />
)

const userProfileNavigation = () => (
    <UserProfileNavigation />
)

const globalStatisticsNavigation = () => (<GlobalStatisticsNavigationLazy />)

const globalInventoryNavigation = () => (<GlobalInventoryNavigationLazy />)

const usersAndCompaniesNavigation = () => (
    <UsersAndCompaniesNavigation />
)

const softwareHelpNavigation = () => (<SoftwareHelpNavigationLazy />)

const reportOverlayNavigation = () => (<ReportOverlayNavigationLazy />)

const geovisCreateRoutes = () => {
    return (
        <div id="routingContainer" className="routingContainer">
            <Switch>
                <GeovisRoute {...Routes.home} component={projectsOverviewPage} />
                <GeovisRoute {...Routes.projectCreate} component={projectCreationPage} />
                <GeovisRoute {...Routes.dashboard} component={dashboardPage} />

                <GeovisRoute {...Routes.userProfile} component={userProfileEditPage} />
                <GeovisRoute {...Routes.changePassword} component={userProfileChangePasswordPage} />

                <GeovisRoute {...Routes.companies} component={companiesPage} />
                <GeovisRoute {...Routes.addCompany} component={addCompanyPage} />
                <GeovisRoute {...Routes.users} component={usersPage} />
                <GeovisRoute {...Routes.addUser} component={addUserPage} />
                <GeovisRoute {...Routes.exportUsersCompanies} component={exportUsersCompaniesPage} />
                <GeovisRoute {...Routes.importUsersCompanies} component={importUsersCompaniesPage} />
                <GeovisRoute {...Routes.partnerCompanyManager} component={partnerCompaniesManagerPage} />

                {/* Global statistic */}
                <GeovisRoute {...Routes.globalStatistics} component={globalStatisticsPage} />
                <GeovisRoute {...Routes.globalProjectsHistory} component={globalProjectHistoryPage} />
                <GeovisRoute {...Routes.globalInvoice} component={globalInvoicePage} />
                <GeovisRoute {...Routes.globalNotificationsStat} component={globalNotificationsStatisticPage} />

                <GeovisRoute {...Routes.messagesToUsers} component={messagesOverviewPage} />
                <GeovisRoute {...Routes.messageTemplate} component={messageTemplateEditPage} />
                <GeovisRoute {...Routes.inventoryMst} component={inventoryPageMst} />
                <GeovisRoute {...Routes.inventoryAfm} component={inventoryPageAfm} />
                <GeovisRoute {...Routes.inventoryAtc} component={inventoryPageAtc} />
                <GeovisRoute {...Routes.inventoryTachy} component={inventoryPageTachy} />
                <GeovisRoute {...Routes.inventorySb} component={inventoryPageSb} />
                <GeovisRoute {...Routes.inventoryLora} component={inventoryPageLora} />
                <GeovisRoute {...Routes.inventoryTree} component={inventoryTreePage} />
                <GeovisRoute {...Routes.inventoryReservation} component={inventoryReservationPage} />
                <GeovisRoute {...Routes.inventoryCalendarView} component={inventoryCalendarViewPage} />

                <GeovisRoute {...Routes.project} component={projectOverviewPage} />
                <GeovisRoute {...Routes.projectView} component={projectOverviewPage} />
                <GeovisRoute {...Routes.projectSupport} component={projectSupportPage} />
                <GeovisRoute {...Routes.projectEdit} exact={false} component={projectEditPage} />
                <GeovisRoute {...Routes.projectStatistic} exact={false} component={projectStatisticPage} />

                <GeovisRoute {...Routes.projectInventoryMst} component={projectInventoryMstPage} />
                <GeovisRoute {...Routes.projectInventoryAtc} component={projectInventoryAtcPage} />
                <GeovisRoute {...Routes.projectInventoryAfm} component={projectInventoryAfmPage} />
                <GeovisRoute {...Routes.projectInventorySb} component={projectInventorySbPage} />
                <GeovisRoute {...Routes.projectInventoryTachy} component={projectInventoryTachyPage} />
                <GeovisRoute {...Routes.projectInventoryLora} component={projectInventoryLoraPage} />


                <GeovisRoute {...Routes.projectReports} component={projectReportsPage} />
                <GeovisRoute {...Routes.projectDocuments} component={projectDocumentsPage} />
                <GeovisRoute {...Routes.projectLogbook} component={projectLogbookPage} />
                <GeovisRoute {...Routes.projectReport} component={projectReportPage} />
                <GeovisRoute {...Routes.projectReportWithViewId} component={projectReportPage} />

                <GeovisRoute {...Routes.projectLogbookElement} component={projectLogbookElementRecordsPage} />


                <GeovisRoute {...Routes.aboutSoftware} component={aboutSoftwarePage} />
                <GeovisRoute {...Routes.unitsHelp} component={unitsHelpPage} />
                <GeovisRoute {...Routes.sensorTypesHelp} component={sensorTypesHelpPage} />
                <GeovisRoute {...Routes.formulaSyntaxHelp} component={formulaSyntaxHelpPage} />
                <GeovisRoute {...Routes.faq} component={faqPage} />
                <GeovisRoute {...Routes.releaseNotes} component={releaseNotesPage} />
                <GeovisRoute {...Routes.costCalculator} component={costCalculatorPage} />
                <GeovisRoute {...Routes.releaseNoteEdit} component={releaseNoteEditPage} />
                <GeovisRoute {...Routes.userFeedback} component={userFeedbackPage} />
                <GeovisRoute {...Routes.documentation} component={documentationPage} />

                {/* service pages */}
                <GeovisRoute {...Routes.logosManager} component={logosManagerPage} />
                <GeovisRoute {...Routes.feedbacks} component={feedbacksPage} />
                <GeovisRoute {...Routes.applicationFeedbacks} component={feedbacksPage} />
                <GeovisRoute {...Routes.applicationFeedbacksEntry} component={feedbacksPage} />
                <GeovisRoute {...Routes.usersFeedbacks} component={feedbacksPage} />
                <GeovisRoute {...Routes.usersFeedbacksEntry} component={feedbacksPage} />
                <GeovisRoute {...Routes.errorPage} component={errorPage} />

                {/* logs */}
                <GeovisRoute {...Routes.logsEntryPoints} component={logsEntryPointsPage} />
                <GeovisRoute {...Routes.logsRecords} component={logsRecordsPage} />
                <GeovisRoute {...Routes.logsFollowEntryPoint} component={logsFollowPage} />

                {/* settings, todo: move logs settings there too */}
                <GeovisRoute {...Routes.settingsRoot} exact={true} component={generalSettingsPageLazy} />
                <GeovisRoute {...Routes.settingsBackup} component={settingsBackupPage} />

                <GeovisRoute {...Routes.settingsLogs} component={settingsLogsPage} />
                <GeovisRoute {...Routes.settingsNotifications} component={settingsNotificationsPage} />
                <GeovisRoute {...Routes.settingsWatchdogService} component={settingsWatchdogServicePage} />
                <GeovisRoute {...Routes.settingsMigrations} component={settingsMigrationsPage} />
                <GeovisRoute {...Routes.settingsGeoRaster} component={settingsGeoRasterServicePage} />
                <GeovisRoute {...Routes.settingsProjectElementsMigrations} component={settingsChartsModePage} />

                <GeovisRoute {...Routes.syncTimeslotsProjectInfoPage} exact={false} component={syncTimeslotsProjectInfoPage} />
                <GeovisRoute {...Routes.syncTimeslotsOverviewPage} component={syncTimeslotsProjectOverviewPage} />
                <GeovisRoute {...Routes.syncTimeslotsProcessingQueuePage} component={syncTimeslotsProcessingQueuePage} />
                <GeovisRoute {...Routes.syncTimeslotsServiceSettingsPage} component={syncTimeslotsServiceSettingsPage} />

                <GeovisRoute {...Routes.settingsMaintenanceServiceWatchdogPage} component={maintenanceServicePageLazy} />
                <GeovisRoute {...Routes.settingsMaintenanceServiceBucketsPage} component={maintenanceServicePageLazy} />
                <GeovisRoute {...Routes.settingsMaintenanceServiceBucketsPageWithEditDialog} component={maintenanceServicePageLazy} />

                {/* archive and restore project */}
                <GeovisRoute {...Routes.archivingProjects} component={archivingProjectsPage} />
                <GeovisRoute {...Routes.restoringProjects} component={restoringProjectsPage} />

                {/* <GeovisRoute {...Routes.globalImportsSettings} component={globalImportSettingsPage} /> */}
                {/* <GeovisRoute {...Routes.swissMeteoGlobalImport} component={globalImportSwissMeteoPage} /> */}
                {/* <GeovisRoute {...Routes.swissMeteoSensorsData} component={globalImportSwissMeteoSensorDataPage} /> */}


            </Switch>
        </div>
    );
}


const loginPage = () => (
    <LoginPage />
)

const forgotPasswordPage = () => (<ForgotPasswordPageLazy />)

const forgotPasswordConfirmationPage = () => (<ForgotPasswordConfirmationPageLazy />)

const resetPasswordPage = () => (<ResetPasswordPageLazy query={useLocation().search} />)

const projectsOverviewPage = () => (<ProjectsOverviewPageLazy />)

const projectCreationPage = () => (<ProjectCreationPageLazy />)

const dashboardPage = () => (<DashboardPageLazy />)

const projectOverviewPage = () => (<ProjectOverviewPageLazy />)

const projectSupportPage = () => (<ProjectSupportPageLazy />)

const projectEditPage = () => (<ProjectEditNavigationPageLazy />)

const projectStatisticPage = () => (<ProjectStatisticNavigationPageLazy />)

const projectReportsPage = () => (<ProjectReportsPageLazy />)

const projectDocumentsPage = () => (<ProjectDocumentsPageLazy />)

const projectLogbookPage = () => (<ProjectLogbookPageLazy />)

const projectReportPage = () => (<ProjectReportPageLazy />)

const projectLogbookElementRecordsPage = () => (<ProjectLogbookElementRecordsPageLazy />)

// const projectInventoryPage = () => (<ProjectInventoryPageLazy />)

const projectInventoryMstPage = () => (<ProjectInventoryPageLazy inventoryObjectType={InventoryObjectType.MST} />)

const projectInventoryAtcPage = () => (<ProjectInventoryPageLazy inventoryObjectType={InventoryObjectType.Atc} />)

const projectInventoryAfmPage = () => (<ProjectInventoryPageLazy inventoryObjectType={InventoryObjectType.Afm} />)

const projectInventorySbPage = () => (<ProjectInventoryPageLazy inventoryObjectType={InventoryObjectType.SB} />)

const projectInventoryTachyPage = () => (<ProjectInventoryPageLazy inventoryObjectType={InventoryObjectType.Tachymeter} />)

const projectInventoryLoraPage = () => (<ProjectInventoryPageLazy inventoryObjectType={InventoryObjectType.LoRaGateway} />)

const aboutSoftwarePage = () => (<AboutPageLazy />)

const unitsHelpPage = () => (<UnitsHelpPageLazy />)

const sensorTypesHelpPage = () => (<SensorTypesHelpPageLazy />)

const formulaSyntaxHelpPage = () => (<FormulaSyntaxHelpPageLazy />)

const userProfileEditPage = () => (<UserProfileEditPageLazy />)

const userProfileChangePasswordPage = () => (<UserProfileChangePasswordPageLazy />)

const companiesPage = () => (<CompaniesPageLazy />)

const addCompanyPage = () => (<AddCompanyPageLazy />)

const usersPage = () => (<UsersPageLazy />)

const addUserPage = () => (<AddUserPageLazy />)

const exportUsersCompaniesPage = () => (<ExportPageLazy />)

const partnerCompaniesManagerPage = () => (<PartnerCompanyManagerPageLazy />)

const importUsersCompaniesPage = () => (<ImportPageLazy />)

const messagesOverviewPage = () => (<MessagesOverviewPageLazy />)

const messageTemplateEditPage = () => (<MessageTemplateEditPageLazy />)

// const inventoryPage = () => (<GeovisInventoryPageLazy />)

const inventoryPageMst = () => (<GeovisInventoryPageLazy inventoryObjectType={InventoryObjectType.MST} />)

const inventoryPageAfm = () => (<GeovisInventoryPageLazy inventoryObjectType={InventoryObjectType.Afm} />)

const inventoryPageAtc = () => (<GeovisInventoryPageLazy inventoryObjectType={InventoryObjectType.Atc} />)

const inventoryPageSb = () => (<GeovisInventoryPageLazy inventoryObjectType={InventoryObjectType.SB} />)

const inventoryPageTachy = () => (<GeovisInventoryPageLazy inventoryObjectType={InventoryObjectType.Tachymeter} />)

const inventoryPageLora = () => (<GeovisInventoryPageLazy inventoryObjectType={InventoryObjectType.LoRaGateway} />)

const inventoryTreePage = () => (<GeovisInventoryTreePageLazy />)

const inventoryReservationPage = () => (<GeovisInventoryReservationPageLazy />)

const inventoryCalendarViewPage = () => <GeovisInventoryCalendarPageLazy />

const faqPage = () => (<FaqPageLazy />)

const releaseNotesPage = () => (<ReleaseNotesPageLazy />);

const costCalculatorPage = () => (<CostCalculatorPageLazy />);

const releaseNoteEditPage = () => (<ReleaseNoteEditPageLazy />)

const userFeedbackPage = () => (<UserFeedbackPageLazy />)

const documentationPage = () => (<DocumentationPageLazy />)

const logosManagerPage = () => (<LogosManagerPageLazy />)

// const projectDetailedStatisticPage = () => (<ProjectStatisticPageLazy />)

// const projectInvoiceStatisticPage = () => (<ProjectInvoiceStatisticPageLazy />)

const globalStatisticsPage = () => (<GlobalStatisticsPageLazy />)

const globalProjectHistoryPage = () => (<GlobalProjectsHistoryPageLazy />)

const globalInvoicePage = () => (<GlobalInvoicePageLazy />)

const globalNotificationsStatisticPage = () => (<GlobalNotificationsStatisticPageLazy />);

const feedbacksPage = () => (<FeedbacksPageLazy />)

const errorPage = () => (<ErrorPageNavigationLazy />);

const logsNavigation = () => (<LogsNavigationLazy />)

// const globalImportsNavigation = () => (<GlobalImportsNavigationLazy />)

// const globalImportSwissMeteoPage = () => (<GlobalImportsSwissMeteoPageLazy />)

// const globalImportSwissMeteoSensorDataPage = () => (<GlobalImportsSwissMeteoDataPageLazy />)

// const globalImportSettingsPage = () => (<GlobalImportsSettingsPageLazy />)

const settingsNavigation = () => (<SettingsNavigationLazy />)

const syncTimeslotsNavigation = () => (<SyncTimeslotsNavigationLazy />)

const archivingNavigation = () => (<ArchivingNavigationLazy />)

const logsEntryPointsPage = () => (
    <LogsPageLazy
        recordTypeMode={GvLogEntryType.EntryPoint}
    />
)

const logsRecordsPage = () => (
    <LogsPageLazy
        recordTypeMode={GvLogEntryType.Record}
    />
)

const settingsLogsPage = () => (<LogsSettingsPageLazy />)

const logsFollowPage = () => <LogsFollowPageLazy />;

const settingsNotificationsPage = () => (<NotificationsSettingsPageLazy />)

const settingsWatchdogServicePage = () => (<WatchdogServiceSettingsPageLazy />)

const settingsGeoRasterServicePage = () => (<GeoRasterSettingsPageLazy />)

const settingsMigrationsPage = () => (<DataMigrationPageLazy />)

const settingsChartsModePage = () => (<ProjectElementsMigrationsPage />)

const generalSettingsPageLazy = () => (<GeneralSettingsPageLaze />)

const settingsBackupPage = () => (<SettingsBackupPageLazy />)

const syncTimeslotsProjectInfoPage = () => (<SyncTimeslotsProjectInfoPageLazy />)

const syncTimeslotsProcessingQueuePage = () => (<SyncTimeslotProcessingQueuePageLazy />)

const syncTimeslotsServiceSettingsPage = () => (<SyncTimeslotsServiceSettingsPageLazy />)

const syncTimeslotsProjectOverviewPage = () => (<SyncTimeslotOverviewPageLazy />);

const maintenanceServicePageLazy = () => (<MaintenanceServicePageLazy />);

const archivingProjectsPage = () => (<ArchivingProjectsPageLazy />)

const restoringProjectsPage = () => (<RestoringProjectsPageLazy />)

const createFlagGroup = ({ flags }: IGeovisNavigationState) => {

    const message = flags.length > 0 ? flags[0] : null;
    // const actions = [
    //     { text: 'Ok', onClick: dismissFlags },
    // ];
    // if (message && message.sendMessageButton) {
    //     actions.push({ text: `Contact Administrator`, onClick: () => window.open("mailto:geovis@amberg.ch", "_blank") })
    // }



    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const CustomFooter = (mes: IFlag | null) => () => {
        const onContactButtonClicked = () => {
            if (message && message.sendMessageButtonAction) {
                message.sendMessageButtonAction();
            }
            else {
                window.open("mailto:geovis@amberg.ch", "_blank");
            }
        }
        return (
            <ModalFooter>
                <div style={{ display: 'inline-flex', width: '100%' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                        {message && message.sendMessageButton &&
                            <Button
                                appearance="primary"
                                testId="btn_contact"
                                //onClick={() => window.open("mailto:geovis@amberg.ch", "_blank")}
                                onClick={onContactButtonClicked}
                                style={{ marginRight: '10px' }}>
                                {t("Contact Administrator")}
                            </Button>
                        }
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                        <Button testId="btn_ok" onClick={dismissFlags} appearance='warning'>
                            {t("Ok")}
                        </Button>
                    </div>
                </div>

            </ModalFooter>
        );
    };

    return (
        <ModalTransition>
            {message && (
                <Modal
                    // actions={actions}
                    appearance={getModalAppearance(message.type)}
                    onClose={dismissFlags}
                    shouldCloseOnOverlayClick={false}
                    heading={message.title}
                    components={{
                        Footer: CustomFooter(message),
                    }}>
                    {/* {message.description} */}
                    <div>
                        <TextArea
                            resize="smart"
                            isReadOnly={true}
                            value={message.description}
                            appearance='subtle'
                        />
                    </div>
                </Modal>
            )}
        </ModalTransition>
    );
}

const getModalAppearance = (type: FlagType): AppearanceType | undefined => {
    switch (type) {
        case FlagType.Error:
            return "danger";

        case FlagType.Warning:
            return "warning";

        default:
            return undefined;
    }
}

const dismissFlags = () => {
    FlagService.clearFlags();
}

const mapStateToProps = (state: IGeovisStoreState): IGeovisNavigationRouterStateProps => ({
    login: state.login,
    navigationState: state.navigation,
    serviceWorkerState: state.serviceWorkerState
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IGeovisNavigationRouterDispatchProps => ({
    handleForcedSignOut() {
        dispatch(loginOut());
    },
    dispatch(action: IGeovisAction) {
        dispatch(action);
    }
});

export default connect<IGeovisNavigationRouterStateProps, IGeovisNavigationRouterDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(GeovisNavigationRouter));