//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LocalMapObjectTextDirection {
	ToRight = 0,
	ToBottom = 1
}

const LocalMapObjectTextDirectionToName = new Map<number, string>([
	[ 0, 'ToRight' ],
	[ 1, 'ToBottom' ],
])

export function getLocalMapObjectTextDirectionToName(element: LocalMapObjectTextDirection) : string {
    return LocalMapObjectTextDirectionToName.get(element) || '';
}

const LocalMapObjectTextDirectionByName = new Map<string, number>([
	[ 'ToRight', 0 ],
	[ 'ToBottom', 1 ],
]);

export function getLocalMapObjectTextDirectionByName(name: string): LocalMapObjectTextDirection {
    return LocalMapObjectTextDirectionByName.get(name)!;
}


export const LocalMapObjectTextDirectionList : Readonly<LocalMapObjectTextDirection[]> = [
	LocalMapObjectTextDirection.ToRight,
	LocalMapObjectTextDirection.ToBottom,
];



const LocalMapObjectTextDirectionToDescription = new Map<number, string>([
	[ 0, 'To right' ],
	[ 1, 'To bottom' ],
])

export function getLocalMapObjectTextDirectionToDescription(element: LocalMapObjectTextDirection) : string {
    return LocalMapObjectTextDirectionToDescription.get(element) || '';
}

