import moment from "moment";
import { mapToListOfElements, mapToListOfElementsAndSortByKeys, mapToListOfKeys } from "../../../../helpers/StorageHelper";
import { CustomerReportSettingsModel } from "../../../../server/AVTService/TypeLibrary/Model/CustomerReportSettingsModel";
import { GeovisReportPageSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisReportPageSettings";
import { AutoRefreshInterval } from "../../../../server/AVTService/TypeLibrary/Model/Reports/AutoRefreshInterval";
import { GeovisReportModel } from "../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportModel";
import { GeovisReportPage } from "../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportPage";
import { IGeovisReportPageConfig } from "../../../../store/projectReport.types";
import { IGeovisReportChanges } from "../ReportOverlayPropertiesWriteHelper.GeovisCharts";
import { IGeovisSettingsLocalStorageModel } from "../types";
import { formattedDateTime } from "../../../../helpers/DateHelper";
import { GeovisMapSectionReportInfo } from "../../../../server/AVTService/TypeLibrary/Model/MapSection/GeovisMapSectionReportInfo";
import { getReportCustomerSettingsKey, setLocalStorageItem } from "../../../utils";
import { Geovis4Table } from "../../../../server/AVTService/TypeLibrary/Model/Geovis4Tables/Geovis4Table";
import { Geovis4TableColumnsWidthMode } from "../../../../server/AVTService/TypeLibrary/Model/Geovis4Tables/Geovis4TableColumnsWidthMode";

const versionJson = require("../../../../version.json");

/**
 * Creator of function to notify the Puppeteer that chart was rendered, use it inside of chart renders options creator
 * @param chartId 
 * @returns 
 */
export const createChartRenderedFunc = (pageNum: number, chartId: number, eventId?: string) => () => {
    if (!eventId) {
        // eslint-disable-next-line no-console
        console.info(`Chart was rendered: pageNum=${pageNum}&elementId=${chartId}`);
    }
    else {
        // eslint-disable-next-line no-console
        console.info(`Event was rendered: pageNum=${pageNum}&elementId=${eventId}`);
    }
}

export const createLogbookRenderedFunc = (pageNum: number, logbookId: number) => {
    // eslint-disable-next-line no-console
    console.info(`Logbook was rendered: pageNum=${pageNum}&elementId=${logbookId}`);
}

export const createCommentRenderedFunc = (pageNum: number, commentId: number) => {
    // eslint-disable-next-line no-console
    console.info(`Comment was rendered: pageNum=${pageNum}&elementId=${commentId}`);
}

export const createGeovis4TableRenderedFunc = (pageNum: number, tableId: number) => {
    // eslint-disable-next-line no-console
    console.info(`Geovis4Table was rendered: pageNum=${pageNum}&elementId=${tableId}`);
}

/*
For convert mm to px
A4 - 210mm x 297mm - (210 / factor)px x (297 / factor)px
block - 500px x 800px - (500 * factor)mm x (800 * factor)mm

Height report page is constant:

top margin =    10px
top border =     2px
header =       120px
content =     1500px
footer =        32px
bottom border =  2px
bottom margin = 10px

TOTAL =       1676px

Width = 1676px * 210 / 297 = 1185.05px = 1185px

Coeff = [A4 Height] / [Page height] = 297 / 1676 = 0.1772076
*/
export const printPageBorderWidthInPx = 2;
export const printPageMarginWidthInPx = 10;
export const printPageContentHeightInPx = GeovisReportPage.CONTENT_HEIGHT; // 1500
export const printPageHeightInPx = 1676;
export const printPageWidthInPx = 1185;
export const printPageCoefficient = 0.1772;

/*export const setCustomReportConfig = (reportSettings: CustomerReportSettingsModel) => {
    localStorage.setItem(getReportCustomerSettingsKey(reportSettings.reportId), JSON.stringify(reportSettings));
}*/

export const setCustomGeovisReportConfig = (reportChanges: IGeovisReportChanges, detailedReportInfo: GeovisReportModel, dtsSectionCloseTSensorIdsMap: { [key: string]: string[] }) => {

    const model: IGeovisSettingsLocalStorageModel = {
        detailedReportInfo: { ...detailedReportInfo, lastUpdateTime: moment().format() },
        dtsSectionCloseTSensorIdsMap,
        originalCharts: reportChanges.originalChartConfigs,
        originalMapSections: reportChanges.originalMapSections,
        originalGeovis4Tables: reportChanges.originalTables,
        originalGeovisImages: reportChanges.originalGeovisImages,
        originalGeovisLogbooks: reportChanges.originalGeovisLogbooks,
        customerPages: mapToListOfElementsAndSortByKeys(reportChanges.geovisPages, (a, b) => a - b).map<GeovisReportPageSettings>((p, pageNum) => ({
            PageNum: pageNum,
            GeovisCharts: mapToListOfElements(p.geovisCharts).map(c => c.Chart),
            MapSectionsInfo: mapToListOfElements(p.mapSections).map<GeovisMapSectionReportInfo>(m => ({
                MapSection: m.MapSection,
                LayerBounds: m.DxfMapTileLayerBounds,
                LayerType: m.DxfLayerType,
                Id: m.MapSection.Id,
                InvertXAxis: m.InvertXAxis
            })),
            Tables: mapToListOfElements(p.tables).map(m => m.Table),
            GeovisImages: mapToListOfElements(p.geovisImages).map(m => m.GeovisImage),
            GeovisLogbooks: mapToListOfElements(p.geovisLogbooks).map(m => m.GeovisLogbook)
        })),
        changedChartsIds: getChangedElementIdsByPage(reportChanges.geovisPages, p => mapToListOfKeys(p.geovisCharts, e => e.IsDirty)),
        changedMapSectionIds: getChangedElementIdsByPage(reportChanges.geovisPages, p => mapToListOfKeys(p.mapSections, e => e.IsDirty)),
        changedGeovis4TableIds: getChangedElementIdsByPage(reportChanges.geovisPages, p => mapToListOfKeys(p.tables, e => e.IsDirty)),
        changedPageGeovisImagesIds: getChangedGeovisImagesIdsByPage(reportChanges.geovisPages),
        changedPageGeovisLogbookIds: getChangedElementIdsByPage(reportChanges.geovisPages, p => mapToListOfKeys(p.geovisLogbooks, e => e.IsDirty)),
        version: versionJson.build
    }

    setLocalStorageItem(getReportCustomerSettingsKey(detailedReportInfo.Id), JSON.stringify(model));
}

const getChangedElementIdsByPage = (pages: Map<number, IGeovisReportPageConfig>, getDirtyElementIdsOfPage: (p: IGeovisReportPageConfig) => number[]): { [key: number]: number[] } => {

    const result: { [key: number]: number[] } = {};

    pages.forEach((page, pageNum) => {
        result[pageNum] = getDirtyElementIdsOfPage(page);
    })

    return result;
}

const getChangedGeovisImagesIdsByPage = (pages: Map<number, IGeovisReportPageConfig>): { [key: number]: number[] } => {
    const result: { [key: number]: number[] } = {};

    pages.forEach((page, pageNum) => {
        page.geovisImages.forEach(image => {
            if (image.IsDirty) {
                result[pageNum] = [image.GeovisImage.Id, ...result[pageNum] || []];
            }
        });
    });

    return result;
}

export const getCustomReportConfig = (reportId: number): CustomerReportSettingsModel | null => {
    const reportConfig = localStorage.getItem(getReportCustomerSettingsKey(reportId));
    if (!reportConfig) {
        return null;
    }
    const parsedConfig = JSON.parse(reportConfig) as CustomerReportSettingsModel;
    return parsedConfig;
}

export const getCustomGeovisReportConfig = (reportId: number): IGeovisSettingsLocalStorageModel | null => {
    const reportConfig = localStorage.getItem(getReportCustomerSettingsKey(reportId));
    if (!reportConfig) {
        return null;
    }
    const parsedConfig = JSON.parse(reportConfig) as IGeovisSettingsLocalStorageModel;

    if (!parsedConfig || parsedConfig === null) {
        return null;
    }

    if (parsedConfig.version !== versionJson.build) {
        return null;
    }

    return parsedConfig;
}

export const clearCustomReportConfig = (reportId: number) => {
    localStorage.removeItem(getReportCustomerSettingsKey(reportId));
}

export const autoRefreshIntervalToSeconds = (interval: AutoRefreshInterval): number => {
    switch (interval) {
        case AutoRefreshInterval.Interval10s: return 10;
        case AutoRefreshInterval.Interval20s: return 20;
        case AutoRefreshInterval.Interval30s: return 30;
        case AutoRefreshInterval.Interval1min: return 60;
        case AutoRefreshInterval.Interval2min: return 60 * 2;
        case AutoRefreshInterval.Interval5min: return 60 * 5;
        default: return 0;
    }
}

export const round = (value: number, decimalPlaces: number) => {
    if (!decimalPlaces) {
        return Math.round(value);
    }

    const decimalFactor = Math.pow(10, decimalPlaces);
    return Math.round(value * decimalFactor) / decimalFactor;
}

export const processTitleTimeslotTemplate = (title: string, timeslot: string) => {
    const formattedTimeslot = formattedDateTime(timeslot);
    return title.replace("{timeslot}", formattedTimeslot);
}

export interface IUpdateGeovis4TableReportViewOptions {
    destContentWidth?: number;
}

/**
 * Update table properties before rendering in a PDF report page
 * @param originalTable 
 */
export const updateGeovis4TablePropertiesReportView = (originalTable: Geovis4Table, options?: IUpdateGeovis4TableReportViewOptions): Geovis4Table => {

    if (originalTable.ColumnWidthMode === Geovis4TableColumnsWidthMode.Manually) {

        if (options && options.destContentWidth && options.destContentWidth > 0) {
            originalTable.ColumnWidths = updateGeovis4TableColumnWidthOfManualWidthMode(originalTable.ColumnWidths, options.destContentWidth);
        }
    }

    return originalTable;
}

/**
 * Update column widths to fit page width
 * @param columnWidths 
 */
const updateGeovis4TableColumnWidthOfManualWidthMode = (columnWidths: number[], destContentWidth: number): number[] => {

    const originalSize = columnWidths.reduce((prev, width) => prev + width, 0);

    if (Math.abs(originalSize - destContentWidth) < 1) {
        return columnWidths;
    }

    const decreaseRation = 1.0 * destContentWidth / originalSize;
    const destColumnWidths = columnWidths.map(w => w * decreaseRation);

    return destColumnWidths;
}