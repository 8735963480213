/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 19.05.2021
 * @description LoRa common types
 */

import Route from "../../../helpers/Route";
import { GeovisLoRaSensorConfig } from "../../../server/Geovis/LoRa/Model/GeovisLoRaSensorConfig";
import { GeovisAlgInfo } from "../../../server/GEOvis3/Model/LoRa/GeovisAlgInfo";
import { GeovisAlnInfo } from "../../../server/GEOvis3/Model/LoRa/GeovisAlnInfo";

export enum LoRaTreeItemType {
    Root,
    ALG,
    ALN
}

export interface ILoRaTreeItemData {
    type: LoRaTreeItemType,
    alg?: GeovisAlgInfo;
    aln?: GeovisAlnInfo;
}

export interface IGeovisLoRaNetworkActions {
    onAddGateway: () => void;
    onAddNode: (algId: string) => void;
    onGoToRoute: (route: Route) => void;
}

export interface ILoadLoRaSensorConfigsDispatchProps {
    onSensorConfigsLoading: () => void;
    onSensorConfigsLoaded: (sensorConfigs: GeovisLoRaSensorConfig[]) => void;
    onSensorConfigsLoadingError: (errorDescription: string) => void;
}

export const LORA_VALUE_IS_EMPTY = 'IS_EMPTY';
export const LORA_VALUE_IS_INCORRECT = 'IS_INCORRECT';
export const LORA_VALUE_IS_OUT_OF_RANGE = 'IS_OUT_OF_RANGE';
export const LORA_VALUE_IS_INCORRECT_RANGE = 'IS_INCORRECT_RANGE';

export type SetFieldValue = (name: string, value: any) => void;

export interface IAlnDeviceFormCommonProps {
    Name: string;
    SensorAddress: number;
}