//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export const enum AuthorizationCode {
	OK = 0,
	AuthorizationFailed = 1,
	NoReadPermissions = 2,
	NoWritePermissions = 3,
	ElementNotFound = 4,
	UnexpectedError = 5,
	ImpossibleOperation = 6,
	Cancelled = 7,
	NotImplemented = 8,
	NotInitialized = 9,
	EmptyRequest = 10,
	Failed = 11,
	ValidationFailed = 12,
	TooBigResponse = 13,
	LicensingFailed = 14
}
