import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import { useParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { HeadType, RowType, SortOrderType } from "@atlaskit/dynamic-table/dist/types/types";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Textfield from "@atlaskit/textfield";
import { debounce } from 'lodash';
import { t } from "../../../../i18n";
import IRouteParams from "../../../../helpers/IRouteParams";
import { GeovisAlarmTemplateModelSlim } from "../../../../server/GEOvis3/Model/Alarms/GeovisAlarmTemplateModelSlim";
import { ISomethingStorageBaseEx, defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../../../../store/types";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { fetchServerElements } from "../../../../helpers/ProjectDataHelper";
import { LoadingContainerSkeleton } from "../../../../components/LoadingContainerSkeleton";
import { getSensorCategoryName } from "../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { isMatchToSearchString } from '../../../../helpers/FiltersHelper';
import { GeovisTableWithSelectableRows } from '../../../../components/DynamicTableWithSelectableRows';


interface IComponentProps extends IWithGeovisServerProps {

    onClose: () => void;
    onApply: (templateId: string) => void;
}

interface IComponentState extends ISomethingStorageBaseEx<GeovisAlarmTemplateModelSlim[]> {
    selectedTemplateId: string;
    sortOrder: SortOrderType;
    sortKey: string;
    searchText: string;
}

const Component = ({
    onClose,
    onApply,
    Server
}: IComponentProps) => {

    const searchFieldRef = useRef<HTMLInputElement>(null);

    const { projectId } = useParams<IRouteParams>();

    const [state, setState] = useState<IComponentState>({
        ...defaultSomethingStorageState,
        selectedTemplateId: "",
        sortOrder: "ASC",
        data: [],
        sortKey: 'name',
        searchText: ''
    });

    useEffect(() => {
        (async function loadTemplatesInfo() {
            setState({
                ...state,
                ...defaultSomethingStorageState,
                data: []
            });
            const url = ServerRoutesGen.Alarms.AlarmTemplates.patch(projectId);
            const response = await fetchServerElements<GeovisAlarmTemplateModelSlim[]>(Server, url);
            if (!response.Success) {
                setState({
                    ...state,
                    ...errorSomethingStorageState(response.Messages.join(". ")),
                    data: []
                });
                return;
            }
            setState({
                ...state,
                ...loadedSomethingStorageState,
                data: response.Data
            });
        })();
    }, [1])

    const sortTemplate = (a: GeovisAlarmTemplateModelSlim, b: GeovisAlarmTemplateModelSlim): number => {
        switch (state.sortKey) {
            case "name": {
                return a.Name === b.Name
                    ? 0
                    : a.Name > b.Name
                        ? state.sortOrder === "ASC" ? 1 : -1
                        : state.sortOrder === "ASC" ? -1 : 1
            }
            case 'sensorTypeCol': {
                return a.SensorType === b.SensorType ? 0 : (getSensorCategoryName(a.SensorType) > getSensorCategoryName(b.SensorType) ? (state.sortOrder === "ASC" ? 1 : -1) : (state.sortOrder === "ASC" ? -1 : 1));
            }
        }

        return 0;
    }

    const getHead = (): HeadType => {
        return ({
            cells: [{
                key: "selected",
                isSortable: false,
                width: 2,
            }, {
                key: "name",
                isSortable: true,
                width: 15,
                content: t("Name")
            }, {
                key: 'sensorTypeCol',
                content: t("Sensor type"),
                isSortable: true,
                width: 6
            }]
        })
    }

    const onAfterSort = (sortKey: string, sortOrder: SortOrderType) => {
        setState({
            ...state,
            sortOrder,
            sortKey
        })
    }

    const onSelectTemplateHandler = () => {
        onApply(state.selectedTemplateId)
    }

    const filterTemplatesFunc = (sensor: GeovisAlarmTemplateModelSlim): boolean => {
        if (state.searchText === "") {
            return true;
        }

        return isMatchToSearchString(sensor.Name, state.searchText);
    }

    const onClearSearchField = () => {
        if (searchFieldRef !== undefined && searchFieldRef.current) {
            searchFieldRef.current.value = "";
        }
        setState({
            ...state,
            searchText: ""
        });
    }

    const onFilterChangedDebounced = debounce((value: string) => { setState({ ...state, searchText: value }) }, 400);

    const onFilterTextChangedHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onFilterChangedDebounced(event.currentTarget.value);
    }

    const getIdFunc = (template: GeovisAlarmTemplateModelSlim): string => template.Id;

    const allItems = (): GeovisAlarmTemplateModelSlim[] => state.data.filter(e => filterTemplatesFunc(e)).sort((a, b) => sortTemplate(a, b));

    const itemToRowFunc = (template: GeovisAlarmTemplateModelSlim): RowType => ({
        key: `row-${template.Id}`,
        cells: [{
            key: "name",
            content: (
                <span>{template.Name}</span>
            )
        }, {
            key: 'sensorTypeCol',
            content: (
                <span>{getSensorCategoryName(template.SensorType)}</span>
            )
        }]
    })

    const onSelectionChanged = (selected: GeovisAlarmTemplateModelSlim[]) => {
        if (selected.length === 0) {
            setState({
                ...state,
                selectedTemplateId: ""
            });
        }
        else {
            setState({
                ...state,
                selectedTemplateId: selected[selected.length - 1].Id
            });
        }
    }

    const selectedItems = (): GeovisAlarmTemplateModelSlim[] => {
        const selected = state.data.find(s => s.Id === state.selectedTemplateId);
        if (!selected) {
            return [];
        }

        return [selected];
    }

    return (
        <ModalTransition>
            <Modal
                width="60%"
                height={"70%"}
                heading={t("Select template for creating alarm")}
                actions={[
                    { text: t("Select"), appearance: 'primary', onClick: onSelectTemplateHandler, isDisabled: state.selectedTemplateId === "" },
                    { text: t("Close"), onClick: onClose }
                ]}
            >
                {state.isLoading && <LoadingContainerSkeleton />}
                {state.isLoaded &&
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <Textfield
                                ref={searchFieldRef}
                                placeholder={t("Filter")}
                                defaultValue={state.searchText}
                                onChange={onFilterTextChangedHandler}
                                isCompact={true}
                                elemAfterInput={
                                    <div style={{ marginRight: '5px', cursor: 'default' }} onClick={onClearSearchField}>
                                        <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                                    </div>
                                }
                            />
                        </div>
                        <div className="flexRowMiddleContainer" style={{ height: "100%" }}>
                            <GeovisTableWithSelectableRows
                                getIdFunc={getIdFunc}
                                allItems={allItems()}
                                isLoading={state.isLoading}
                                itemToRowFunc={itemToRowFunc}
                                needSelectAll={false}
                                onSelectionChanged={onSelectionChanged}
                                selectedItems={selectedItems()}
                                head={getHead()}
                                onAfterSort={onAfterSort}
                                defaultSortKey={state.sortKey}
                                defaultSortOrder={state.sortOrder}
                            />
                        </div>
                    </div>

                }
            </Modal>
        </ModalTransition>
    )
}

export default withGeovisServer(Component)

