/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 30.12.2020
 * @description Project history property user name component
 */

import React, { useEffect, useState } from 'react';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../helpers/GeovisHooks';
import { fetchServerElements } from '../../../../helpers/ProjectDataHelper';
import { GeovisUserCommonInfo } from '../../../../server/GEOvis3/Model/User/GeovisUserCommonInfo';
import ServerRoutesGen from '../../../../server/Routes/ServerRoutesGen';

interface IProjectPropertyHistoryUserNameProps extends IWithGeovisServerProps {
    UserId: string;
}

export const ProjectPropertyHistoryUserName = withGeovisServer(({ Server, UserId }: IProjectPropertyHistoryUserNameProps) => {

    const [userInfo, setUserInfo] = useState<GeovisUserCommonInfo | false>(false);

    useEffect(() => {
        (async function loadUserInfo() {
            const response = await fetchServerElements<GeovisUserCommonInfo>(Server, ServerRoutesGen.Account.UserHeadInfoById.patch(UserId));

            if (response.Success) {
                setUserInfo(response.Data);
            }
        })();
    }, [UserId]);


    return (
        <React.Fragment>
            {userInfo && (
                <span>{userInfo.Name} {userInfo.Forename}</span>
            )}
        </React.Fragment>
    )
})