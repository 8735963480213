import Button from "@atlaskit/button";
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Modal, { ModalFooter, ModalTransition, } from "@atlaskit/modal-dialog";
import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import Routes from "../../../../helpers/Routes";
import { t } from "../../../../i18n";
import { GeovisAlarmModel } from "../../../../server/GEOvis3/Model/Alarms/GeovisAlarmModel";
import { projectLabelsShow } from "../../../../store/creators/projectEditCreators";
import { IGeovisProjectLabelStorage, IGeovisProjectUsersStorage } from "../../../../store/data.types";
import { IGeovisStoreState } from "../../../../store/store.types";
import {
    IGeovisAction,
    ISomethingStoreBase,
    defaultSomethingStorageState,
    errorSomethingStorageState,
    loadedSomethingStorageState
} from "../../../../store/types";
import { AlarmEditDialogTabControl } from "./AlarmEditDialogTabControl";
import { CompanyInfo } from "../../../../server/GEOvis3/Model/Company/CompanyInfo";
import { mapToListOfElements } from "../../../../helpers/StorageHelper";
import AlarmEditSelectTemplateDialog from "./AlarmEditSelectTemplateDialog";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import { fetchServerElements, fetchServerElementsByPost, sendServerGetRequest } from "../../../../helpers/ProjectDataHelper";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import FlagService from "../../../../services/FlagService";
import { LoadingContainerSkeleton } from "../../../../components/LoadingContainerSkeleton";
import { EditAlarmPayload } from "../../../../server/GEOvis3/Model/Alarms/EditAlarmPayload";
import { LoadingPageErrorSkeleton } from "../../../../components/LoadingPageErrorSkeleton";
import { AlarmActivationBlockModel } from "../../../../server/GEOvis3/Model/Alarms/UsersPresets/AlarmActivationBlockModel";
import { GeovisAlarmModelSlim } from "../../../../server/GEOvis3/Model/Alarms/GeovisAlarmModelSlim";
import { GeovisAlarmTemplateModelSlim } from "../../../../server/GEOvis3/Model/Alarms/GeovisAlarmTemplateModelSlim";
import { GeovisAlarmTemplateModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisAlarmTemplateModel";
import { ProjectElementEditHeader } from "../../../../components/projectOverview/ProjectElementEditHeader";
import { AlarmEditResponse } from "../../../../server/GEOvis3/Model/Alarms/AlarmEditResponse";
import { LicensedFeatures } from "../../../../server/AVTService/TypeLibrary/Licensing/LicensedFeatures";
import { GeovisModalDialog } from "../../../../components/editDialogs/GeovisModalDialog";

interface IStateToProps {
    labelStorage: IGeovisProjectLabelStorage;
    projectUsersStorage: IGeovisProjectUsersStorage;
    companies: Map<string, CompanyInfo>;
}

interface IDispatchToProps {
    showLabelsList: () => void;
}

interface IOwnProps {
    createMode: boolean;
    alarmId: string;

    projectId: number;
    isTemplate: boolean;
    baseItemId: string;

    onUpdateAlarmInList?: (alarm: GeovisAlarmModelSlim) => void;
    onUpdateAlarmTemplateInList?: (template: GeovisAlarmTemplateModelSlim) => void;
    onChangeAlarmActiveState?: (alarmId: string, isActive: boolean) => void;
    onChangeAlarmActivationPossibility?: (config: AlarmActivationBlockModel) => void;
    onClose: () => void;
}

interface IComponentProps extends IStateToProps, IDispatchToProps, IOwnProps, IWithGeovisServerProps {

}

interface IAlarmState extends ISomethingStoreBase {
    editedAlarm: GeovisAlarmModel;
    isReadonly: boolean;
    showTemplatesSelectDialog: boolean;
    licenseFeatures: LicensedFeatures[]
}

interface ITemplatesState extends ISomethingStoreBase {
    templatesIds: string[];
}

const Component = ({
    labelStorage,
    createMode,
    alarmId,
    projectId,
    showLabelsList,
    companies,
    isTemplate,
    baseItemId,
    Server,
    onChangeAlarmActiveState,
    onChangeAlarmActivationPossibility,
    onClose,
    onUpdateAlarmInList,
    onUpdateAlarmTemplateInList
}: IComponentProps) => {

    const history = useHistory();

    const [state, setState] = useState<IAlarmState>({
        ...defaultSomethingStorageState,
        editedAlarm: new GeovisAlarmModel(),
        isReadonly: false,
        showTemplatesSelectDialog: false,
        licenseFeatures: []
    })

    const [templatesState, setTemplatesState] = useState<ITemplatesState>({
        ...defaultSomethingStorageState,
        templatesIds: []
    })

    const loadEditedItem = async () => {
        setState({
            ...state,
            ...defaultSomethingStorageState,
            editedAlarm: new GeovisAlarmModel()
        });

        const url = ServerRoutesGen.Alarms.GetAlarmForEdit.patch(projectId);
        const payload: EditAlarmPayload = {
            ItemId: alarmId,
            BaseItemId: baseItemId,
            IsTemplate: isTemplate
        };

        const response = await fetchServerElementsByPost<AlarmEditResponse, EditAlarmPayload>(Server, url, payload);

        if (!response.Success) {
            setState({
                ...state,
                ...errorSomethingStorageState(response.Messages.join(". "))
            });
            return;
        }

        setState({
            ...state,
            ...loadedSomethingStorageState,
            editedAlarm: response.Data.Alarm,
            isReadonly: response.Data.Alarm.IsActive,
            licenseFeatures: response.Data.LicensedFeatures
        });
    }

    const loadAvailableTemplatesIds = async () => {
        setTemplatesState({
            ...templatesState,
            ...defaultSomethingStorageState,
            templatesIds: []
        });

        const url = ServerRoutesGen.Alarms.GetPossibleTemplatesIds.patch(projectId);
        const response = await fetchServerElements<string[]>(Server, url);

        if (!response.Success) {
            setTemplatesState({
                ...templatesState,
                ...errorSomethingStorageState(response.Messages.join(". "))
            });
            return;
        }

        setTemplatesState({
            ...templatesState,
            ...loadedSomethingStorageState,
            templatesIds: response.Data
        });

    }

    useEffect(() => {
        // load info about edited/created alarm/template from server
        (async function loadItemForEdit() {
            await loadEditedItem();
        })();
        // load info about existence of alarm templates
        (async function loadTemplatesInfo() {
            await loadAvailableTemplatesIds();
        })();
    }, [1])


    const onSaveAlarmHandler = (activate: boolean) => async () => {
        const result = await saveAlarm(activate);

        if (result) {
            if (onUpdateAlarmInList) {
                onUpdateAlarmInList(result);
            }
            else {
                onClose();
            }
        }
    }

    const onSaveAlarmTemplateHandler = async () => {
        const result = await saveAlarmTemplate();

        if (result) {
            if (onUpdateAlarmTemplateInList) {
                onUpdateAlarmTemplateInList(result);
            }
            else {
                onClose();
            }
        }
    }

    const saveAlarm = async (activate: boolean): Promise<GeovisAlarmModelSlim | undefined> => {
        const url = createMode
            ? ServerRoutesGen.Alarms.CreateAlarm.patch(projectId)
            : ServerRoutesGen.Alarms.SaveAlarm.patch(projectId);

        if (activate) {
            state.editedAlarm.IsActive = true;
        }

        const response = await fetchServerElementsByPost<GeovisAlarmModelSlim, GeovisAlarmModel>(Server, url, state.editedAlarm);

        if (!response.Success) {
            FlagService.addError("Failed to save alarm", response.Messages.join('; '));
            return undefined;
        }

        return response.Data;
    }

    const saveAlarmTemplate = async (): Promise<GeovisAlarmTemplateModelSlim | undefined> => {
        const url = createMode
            ? ServerRoutesGen.Alarms.CreateAlarmTemplate.patch(projectId)
            : ServerRoutesGen.Alarms.SaveAlarmTemplate.patch(projectId);

        const templateToSave = state.editedAlarm as GeovisAlarmTemplateModel;

        const response = await fetchServerElementsByPost<GeovisAlarmTemplateModelSlim, GeovisAlarmTemplateModel>(Server, url, templateToSave);

        if (!response.Success) {
            FlagService.addError("Failed to save alarm", response.Messages.join('; '));
            return undefined;
        }

        return response.Data;
    }

    const getHeaderText = (): string => {
        if (createMode) {
            return isTemplate ? t("Create alarm template") : t("Create alarm");
        }

        if (state.isReadonly) {
            return `${t("Watch alarm")} ${state.editedAlarm.Name}`
        }

        return isTemplate ? `${t("Edit alarm template")} ${state.editedAlarm.Name}` : `${t("Edit alarm")} ${state.editedAlarm.Name}`
    }

    const getDialogHeader = (): React.ReactNode => {
        if (createMode) {
            return (
                <React.Fragment>
                    {getHeaderText()}
                </React.Fragment>
            )
        }

        return (
            <ProjectElementEditHeader
                headerText={getHeaderText()}
                onCopyToClipboard={copyToClipboard}
                tooltipContent={isTemplate ? t("Copy link to this alarm template") : t("Copy the link to this alarm")}
            />
        );
    }

    const copyToClipboard = () => {
        const url = isTemplate ? Routes.projectEditAlarmTemplatesEditDialog.patch(projectId, alarmId) : Routes.projectEditAlarmsEditDialog.patch(projectId, alarmId);
        const copyText = `${window.location.origin}${url.path}`;
        navigator.clipboard.writeText(copyText);
    }

    const onConvertTemplateToAlarmClick = async () => {
        const result = await saveAlarmTemplate();

        if (result) {
            const url = Routes.projectEditAlarmsCreateDialog.patch(projectId, state.editedAlarm.Id);
            history.push(url.path);
        }
    }

    const onConvertAlarmToTemplateClick = async () => {
        const result = await saveAlarm(state.editedAlarm.IsActive)

        if (result) {
            const url = Routes.projectEditAlarmTemplatesCreateDialog.patch(projectId, state.editedAlarm.Id);
            history.push(url.path);
        }
    }

    const showTemplatesDialog = () => {
        setState({
            ...state,
            showTemplatesSelectDialog: true
        })
    }

    const onApplyTemplate = async (templateId: string) => {

        const url = ServerRoutesGen.Alarms.GetAlarmForEdit.patch(projectId);
        const payload: EditAlarmPayload = {
            ItemId: state.editedAlarm.Id,
            BaseItemId: templateId,
            IsTemplate: isTemplate
        };

        const response = await fetchServerElementsByPost<AlarmEditResponse, EditAlarmPayload>(Server, url, payload);

        if (!response.Success) {
            FlagService.addError("Failed to load template data", response.Messages.join(". "));
            setState({
                ...state,
                ...errorSomethingStorageState(response.Messages.join(". ")),
                showTemplatesSelectDialog: false
            });
            return;
        }

        const updAlarm: GeovisAlarmModel = {
            ...state.editedAlarm,
            ...response.Data.Alarm,
            Id: ""
        }

        setState({
            ...state,
            ...loadedSomethingStorageState,
            editedAlarm: updAlarm,
            licenseFeatures: response.Data.LicensedFeatures,
            showTemplatesSelectDialog: false,
        });
    }

    const hideTemplatesDialog = () => {
        setState({
            ...state,
            showTemplatesSelectDialog: false
        })
    }

    const getCustomFooter = () => {
        return (
            <ModalFooter>
                <div style={{ display: 'inline-flex', width: '100%' }}>
                    {!createMode && !isTemplate &&
                        <div style={{ display: 'flex' }}>
                            <div>
                                <Button onClick={onConvertAlarmToTemplateClick} appearance='default' style={{ marginRight: '5px' }}>
                                    {t("Save as company template")}
                                </Button>
                            </div>
                        </div>
                    }

                    {!createMode && isTemplate &&
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                            <Button onClick={onConvertTemplateToAlarmClick} appearance='primary' style={{ marginRight: '5px' }}>
                                {t("Create alarm")}
                            </Button>
                        </div>
                    }
                    {!isTemplate && createMode &&
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                            <Button onClick={showTemplatesDialog} isDisabled={templatesState.templatesIds.length === 0}>
                                {t("Load settings from template")}
                            </Button>
                        </div>
                    }
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                        <Button onClick={isTemplate ? onSaveAlarmTemplateHandler : onSaveAlarmHandler(false)} appearance='primary' style={{ marginRight: '5px' }} isDisabled={state.isReadonly}>
                            {t("Save")}
                        </Button>
                        {!isTemplate &&
                            <Button onClick={onSaveAlarmHandler(true)} appearance='primary' style={{ marginRight: '5px' }} isDisabled={state.isReadonly || !state.editedAlarm.CanBeActivated}>
                                {t("Save and activate")}
                            </Button>
                        }
                        <Button onClick={onClose} appearance='default' style={{ width: '105px' }}>
                            {t("Cancel")}
                        </Button>
                    </div>
                </div>

            </ModalFooter>
        );
    };

    const onAlarmChanged = (updAlarm: GeovisAlarmModel) => {
        setState({
            ...state,
            editedAlarm: updAlarm
        })
    }

    const changeAlarmActiveState = async (isActive: boolean) => {

        const url = ServerRoutesGen.Alarms.ActivateAlarm.patch(projectId, alarmId, isActive);
        const response = await sendServerGetRequest(Server, url);
        if (!response.Success) {
            FlagService.addError("Failed to change alarm active state", response.Messages.join("; "));
            return;
        }

        if (onChangeAlarmActiveState) {
            onChangeAlarmActiveState(alarmId, isActive);
        }

        setState({
            ...state,
            editedAlarm: { ...state.editedAlarm, IsActive: isActive },
            isReadonly: isActive
        })
    }

    const onDeactivateAlarmHandler = async () => {
        await changeAlarmActiveState(false);
    }

    const { isLoading, isError, errorDescription } = state;

    if (!isLoading && isError) {
        return (
            <ModalTransition>
                <Modal
                    heading={t("Error load data")}
                    width="50%"
                    height="80%"
                    actions={[{ text: t("Close"), appearance: "primary", onClick: onClose }]}>
                    <LoadingPageErrorSkeleton errorDescription={errorDescription} />
                </Modal>
            </ModalTransition>
        )
    }

    const onChangeAlarmActivationPossibilityHandler = (config: AlarmActivationBlockModel) => {
        if (onChangeAlarmActivationPossibility) {
            onChangeAlarmActivationPossibility(config);
        }
    }

    return (
        <GeovisModalDialog
            heading={getDialogHeader()}
            components={{
                Footer: getCustomFooter
            }}
        >
            <Fragment>
                {isLoading && !isError && <LoadingContainerSkeleton />}
                {!isLoading && !isError && (
                    <Fragment>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            {state.isReadonly &&
                                <div style={{ display: 'flex', background: '#ffab00', alignItems: 'center', margin: '5px', padding: '5px', height: '35px' }}>
                                    <div style={{ flexGrow: 4, justifyContent: 'center', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <WarningIcon label="" secondaryColor="#ffab00" />
                                        <span >{t("Alarm is active. To change alarm setting, deactivate alarm.")}</span>
                                    </div>
                                    <Button
                                        onClick={onDeactivateAlarmHandler}
                                        spacing="compact"
                                        appearance="primary"
                                    >
                                        {t("Deactivate alarm")}
                                    </Button>

                                </div>
                            }
                            {!state.editedAlarm.CanBeActivated &&
                                <div style={{ display: 'flex', background: '#ffab00', alignItems: 'center', margin: '5px', padding: '5px', height: '35px' }}>
                                    <div style={{ flexGrow: 4, justifyContent: 'center', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <WarningIcon label="" secondaryColor="#ffab00" />
                                        <span >{t("Alarm can not be activated because some Email&Sms actions use receivers presets with different count of conditions. Change count of alarm conditions or remove presets to activate alarm.")}</span>
                                    </div>
                                </div>
                            }
                            <AlarmEditDialogTabControl
                                alarm={state.editedAlarm}
                                isReadonly={state.isReadonly}
                                labelStorage={labelStorage}
                                onAlarmChanged={onAlarmChanged}
                                onShowLabelsListView={showLabelsList}
                                companies={mapToListOfElements(companies)}
                                isTemplate={isTemplate}
                                onChangeAlarmActivationPossibility={onChangeAlarmActivationPossibilityHandler}
                                availableFeatures={state.licenseFeatures}
                            />
                        </div>

                        {state.showTemplatesSelectDialog &&
                            <AlarmEditSelectTemplateDialog
                                onClose={hideTemplatesDialog}
                                onApply={onApplyTemplate}
                            />
                        }
                    </Fragment>
                )}
            </Fragment>
        </GeovisModalDialog>
    )
}

const mapStateToProps = (state: IGeovisStoreState): IStateToProps => ({
    labelStorage: state.data.projectLabelStorage,
    projectUsersStorage: state.data.projectUsersStorage,
    companies: state.businessData.companiesStore.companies
})

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDispatchToProps => ({
    showLabelsList: () => dispatch(projectLabelsShow())
})

export default connect<IStateToProps, IDispatchToProps, IOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(Component))
