import { colors } from '@atlaskit/theme';
import L from 'leaflet';

export const ambergTechnologiesCoordinates = (): L.LatLng => new L.LatLng(47.440214, 8.475016);

export const defaultProjectCoordinates = (): L.LatLng => new L.LatLng(0, 0);

export const GeovisName = "GEOvis 4.0";

export default class Constants {

    // theme colors
    public static colorForegroundM1Blue: string = "#09ACE3";
    public static colorForegroundM2Dark: string = "#16222A";
    public static colorForegroundM3White: string = "#FFFFFF";
    public static colorBackgroundM1Blue: string = "#4D79FF";
    public static colorBackgroundM2Dark: string = "#506E78";
    public static colorBackgroundM3White: string = "#F4F5F7";

    public static colorLogbookLabelDefault: string = "#5bbfde";

    public static colorNavBackgroundGlobal: string = "#294050";
    public static colorNavForegroundGlobal: string = "#EEEEEE";
    public static colorNavBackgroundGlobalTestEnvironment: string = "#FF4500";
    public static colorNavBackgroundGlobalDevEnvironment: string = "#013220";

    public static colorAmbergInfra7DGreen: string = "#00af66";
    public static colorAmbergInfra7DBlack: string = "#000000";

    public static backgroundUnselected: string = "#FAFBFC";
    public static borderUnselected: string = "#DFE1E6";

    public static tableRowGrayColor: string = colors.N20;

    // other colors
    public static successColor: string = colors.G300;
    public static errorColor: string = colors.R400;

    // sizes
    public static minSizeForExpandableNavigation = 1000;

    public static UnitTextByte = "B";
    public static UnitTextKilobyte = "kB";
    public static UnitTextMegabyte = "MB";
    public static UnitTextGigabyte = "GB";
    public static UnitTextTerabyte = "TB";
    public static UnitTextPetabyte = "PB";


    // languages
    public static English = "en";
    public static German = "de";
}