/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 21.04.2022
 * @description Heatmap axis settings, two columns for X and Y axes
 */

import { t } from '../../../../i18n';
import { HeatmapChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel';
import { defaultChartTabContentHeaderStyle, IGeovisChartPropertyChangedProps } from '../types';
import { ChartHeatmapAxisControl } from './ChartHeatmapAxisControl';

interface IHeatmapAxisTabProps extends IGeovisChartPropertyChangedProps<HeatmapChartModel> {
    chart: HeatmapChartModel;
}

export const ChartHeatmapAxisTab = ({ chart, onChartPropertyChanged }: IHeatmapAxisTabProps) => (
    <div className='geovisChartTab'>
        <div className='flexRowContainer'>

            {/* Y-Axis */}
            <div className='flexCellContainer' style={{ width: '49%' }}>
                <div style={defaultChartTabContentHeaderStyle}>{t("Y-Axis")}</div>
                <ChartHeatmapAxisControl
                    axisPropertyName='LeftYAxisSettings'
                    axisType='YAxis'
                    chartAxisSettings={chart.LeftYAxisSettings}
                    onChangeAxisSettings={onChartPropertyChanged}
                />
            </div>

            {/* middle vertical separator */}
            <div className='flexCellContainer_g1 separator'>
                {/* nothing there */}
            </div>

            {/* X-Axis */}
            <div className='flexCellContainer' style={{ width: '49%' }}>
                <div style={defaultChartTabContentHeaderStyle}>{t("X-Axis")}</div>
                <ChartHeatmapAxisControl
                    axisPropertyName='XAxisSettings'
                    axisType='XAxis'
                    chartAxisSettings={chart.XAxisSettings}
                    onChangeAxisSettings={onChartPropertyChanged}
                />
            </div>

        </div>
    </div>
)