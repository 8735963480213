//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Geovis4TableCellMeta } from '../Model/Geovis4Tables/Geovis4TableCellMeta';

export class Geovis4TableData
{
	public Data: string[][];
	public CellsMeta: { [key:number]: { [key:number]: Geovis4TableCellMeta } };
	public Title: string;
	public Timeslot: string;
}
