import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import React from "react";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { t } from "../../../../../../i18n";
import { getSensorValueAttributeToName, SensorValueAttribute } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { VibrationEventChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/VibrationEventChartData";
import { VibrationChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import Logger from "../../../../../../services/Logger";
import { IGeovisChartReportData, IGeovisReportPageData } from "../../../../../../store/projectReport.types";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { LoadingContainerSkeleton } from "../../../../../LoadingContainerSkeleton";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { IReportElementRenderOwnProps } from "../../types";
import { getGeovisChartConfigFromStorage } from "../options/tools";
import { getVibrationEventRenderOptionsSettings } from "../options/vibrationEventRenderOptions";
import { IChartRenderStateToProps } from "../types";
import { ChartLoadingSkeleton } from "./ChartLoadingComponent";

export const VIBRATION_EVENT_ACCELERATION = "VIBRATION_EVENT_ACCELERATION";
export const VIBRATION_EVENT_VECTOR = "VIBRATION_EVENT_VECTOR";
export const VIBRATION_EVENT_OCCURRENCE = "VIBRATION_EVENT_OCCURRENCE";

type IStateToProps = IChartRenderStateToProps<VibrationEventChartData>

interface IOwnProps extends IReportElementRenderOwnProps {
    chartId: number;
}

interface IComponentProps extends IStateToProps, IOwnProps {

}

const VibrationEventChartRender = ({
    chartData,
    chartId,
    pageNum,
    isPrinting,
    eventInfo
}: IComponentProps) => {

    if (!eventInfo) {
        return (<LoadingPageErrorSkeleton errorText={t("Error load vibration event chart data")} errorDescription={"No event info found"} />)
    }

    if (!chartData) {
        return (<ChartLoadingSkeleton text={t("Initializing vibration event chart data")} />)
    }

    const { data, errorDescription, isError, isLoading, isLoaded } = chartData;

    if (isError) {
        return (<LoadingPageErrorSkeleton errorText={t("Error load vibration event chart data")} errorDescription={errorDescription} />);
    }

    if (!isLoaded) {
        return (<ChartLoadingSkeleton text={t("Loading vibration event chart data...")} />)
    }

    Logger.render('VibrationEventChartRender');

    // getting changed chart config directly from storage, do not subscribe on UPDATE event
    const chart = getGeovisChartConfigFromStorage<VibrationChartModel>(pageNum, chartId);
    if (!chart) {
        return null;
    }

    const getSingleContainerHeight = (collectionLength: number): number => {
        return collectionLength > 1 ? 100 / collectionLength - (isPrinting ? 3 : 0) : 100;
    }

    const renderOptions = getVibrationEventRenderOptionsSettings(pageNum, cloneDeep(chart), data, eventInfo.eventId);

    const accelerationRenderOptions = renderOptions.get(VIBRATION_EVENT_ACCELERATION);
    const freqRenderOptions = renderOptions.get(VIBRATION_EVENT_OCCURRENCE);
    const vectorRenderOptions = renderOptions.get(VIBRATION_EVENT_VECTOR);

    const eventHeader = data.EventHeader;

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);

    // disable boost if chart is in Printing mode
    if (!isPrinting) {
        HighchartsBoost(Highcharts);
    }

    return (
        <React.Fragment>
            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>

                {isLoading && (
                    <LoadingContainerSkeleton loadingText={t("Loading vibration event chart data...")} />
                )}
                {isLoaded && chart.LeftEventAxisSettings.sensorValue === getSensorValueAttributeToName(SensorValueAttribute.ValuesXYZAndVector) &&
                    <div style={{ display: 'grid', gridTemplateColumns: '22% 22% 22% 22% 12%', gridTemplateRows: '20px 20px 20px 20px', borderBottom: 'solid 2px black' }}>
                        <div style={{ fontWeight: 'bold' }}>
                            {t("Measurement place")}
                        </div>
                        <div>
                            {eventHeader.description}
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                            {t("Vector Sum (VSum)")}
                        </div>
                        <div>
                            {`${eventHeader.vsum} [mm/s]`}
                        </div>
                        <div>
                            {/*  */}
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                            {t("Sensor name")}
                        </div>
                        <div>
                            {eventHeader.name}
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                            {t("Max X")}
                        </div>
                        <div>
                            {`${eventHeader.peakX} [mm/s]`}
                        </div>
                        <div>
                            {`${eventHeader.domFreqX} [Hz]`}
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                            {t("Event number")}
                        </div>
                        <div>
                            {eventHeader.eventNumber}
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                            {t("Max Y")}
                        </div>
                        <div>
                            {`${eventHeader.peakY} [mm/s]`}
                        </div>
                        <div>
                            {`${eventHeader.domFreqY} [Hz]`}
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                            {t("Event trigger time")}
                        </div>
                        <div>
                            {eventHeader.eventTriggerTime}
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                            {t("Max Z")}
                        </div>
                        <div>
                            {`${eventHeader.peakZ} [mm/s]`}
                        </div>
                        <div>
                            {`${eventHeader.domFreqZ} [Hz]`}
                        </div>
                    </div>
                }
                <div className="report-chart-render" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', height: '100%', flexGrow: 3 }}>
                    {accelerationRenderOptions && isLoaded &&
                        <div style={{ height: '100%', width: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
                            {accelerationRenderOptions.map<JSX.Element | null>((option, index, collection) => (
                                <HighchartsReact
                                    key={`highcharts-event-acceleration-${chart.Id}-${index}-${chartData.Timestamp}`}
                                    highcharts={Highcharts}
                                    options={option}
                                    constructorType={'chart'}
                                    containerProps={{ style: { flexGrow: 1, height: `${getSingleContainerHeight(collection.length)}%` } }}
                                />
                            ))}
                        </div>
                    }
                    {freqRenderOptions && isLoaded &&
                        <div style={{ height: '100%', width: '100%', flexShrink: 2, borderLeft: 'solid 2px black', display: 'flex', flexFlow: 'column nowrap' }}>
                            {freqRenderOptions.map<JSX.Element | null>((option, index, collection) => (
                                <HighchartsReact
                                    key={`highcharts-event-freq-${chart.Id}-${index}-${chartData.Timestamp}`}
                                    highcharts={Highcharts}
                                    options={option}
                                    constructorType={'chart'}
                                    containerProps={{ style: { flexGrow: 1, height: `${getSingleContainerHeight(collection.length)}%` } }}
                                />
                            ))}
                        </div>
                    }
                </div>

                {vectorRenderOptions && isLoaded &&
                    <div style={{ height: '100%', flexGrow: 1, flexShrink: 3, borderTop: 'solid 2px black', display: 'flex', flexFlow: 'column nowrap' }}>
                        {vectorRenderOptions.map<JSX.Element | null>((option, index, collection) => (
                            <HighchartsReact
                                key={`highcharts-event-vector-${chart.Id}-${index}-${chartData.Timestamp}`}
                                highcharts={Highcharts}
                                options={option}
                                constructorType={'chart'}
                                containerProps={{ style: { flexGrow: 1, height: `${getSingleContainerHeight(collection.length)}%` } }}
                            />
                        ))}
                    </div>
                }

            </div>
        </React.Fragment>
    )
}

const getChartData = (chartId: number, pageNum: number, pagesData: Map<number, IGeovisReportPageData>): IGeovisChartReportData<VibrationEventChartData> | undefined => {

    const chartsOnPageData = pagesData.get(pageNum);
    if (chartsOnPageData) {
        const chartData = chartsOnPageData.geovisEventChartsData.get(chartId);
        if (chartData) {
            return chartData as IGeovisChartReportData<VibrationEventChartData>;
        }
    }
    return undefined;
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { chartId, pageNum }: IOwnProps): IStateToProps => ({
    chartData: getChartData(chartId, pageNum, projectReport.geovisReportSettings.geovisPagesData)
});

export default connect<IStateToProps, never, IOwnProps>(mapStateToProps)(VibrationEventChartRender);