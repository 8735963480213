import Modal, { ModalTransition } from "@atlaskit/modal-dialog"
import Textfield from '@atlaskit/textfield';
import { SyntheticEvent, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IWithGeovisServerProps, withGeovisServer } from "../../../helpers/GeovisHooks";
import { sendServerPostRequestData } from "../../../helpers/ProjectDataHelper";
import { t } from "../../../i18n";
import { LabelModel } from "../../../server/AVTService/TypeLibrary/Model/LabelModel";
import { DataActionResponse } from "../../../server/DataActionResponse";
import ServerRoutesGen from "../../../server/Routes/ServerRoutesGen";
import FlagService from "../../../services/FlagService";
import { projectDataLabelUpdated } from "../../../store/creators/dataCreators";
import { projectLabelsEditHide } from "../../../store/creators/projectEditCreators";
import { IGeovisProjectLabelStorage } from "../../../store/data.types";
import { IGeovisStoreState } from "../../../store/store.types";
import { IGeovisAction } from "../../../store/types";

interface ILabelEditViewStateProps {
    labelId: string;
    showDialog: boolean;
    projectLabelsStorage: IGeovisProjectLabelStorage;
    projectId: number;
}

interface ILabelEditViewDispatchProps {
    onSave: (label: LabelModel) => void;
    onClose: () => void;
}

interface ILabelEditViewProps extends ILabelEditViewStateProps, ILabelEditViewDispatchProps, IWithGeovisServerProps {

}

const LabelEditView = ({
    labelId,
    showDialog,
    projectLabelsStorage,
    projectId,
    onClose,
    Server,
    onSave
}: ILabelEditViewProps) => {
    if (!showDialog) {
        return null;
    }

    const getEmptyLabel = (): LabelModel => ({
        ...new LabelModel(),
        projectId
    });

    const getLabel = (): LabelModel => {
        if (labelId) {
            const exLabel = projectLabelsStorage.labels.get(labelId);
            if (exLabel) {
                return exLabel;
            }
        }
        return getEmptyLabel();
    }

    const [label, setLabel] = useState<LabelModel>(getLabel());

    const editMode = label.id !== "";

    const onSaveLabel = async () => {
        const url = ServerRoutesGen.Project.SaveLabel.patch(projectId);
        const response = await sendServerPostRequestData<LabelModel, DataActionResponse<LabelModel>>(Server, url, label);
        if (!response.Success) {
            FlagService.addError("Failed to save label", response.Messages.join(", "));
            return;
        }
        onSave(response.Data);
        onClose();
    }

    const onChangedWrapped = (event: SyntheticEvent<HTMLInputElement>) => {
        setLabel({
            ...label,
            name: event.currentTarget.value
        });
    }

    return (
        <ModalTransition>
            <Modal
                heading={editMode ? t("Edit label") : t("Add new label")}
                actions={[
                    { text: t("Save"), onClick: onSaveLabel },
                    { text: t("Cancel"), onClick: onClose }
                ]}>
                <Textfield
                    defaultValue={label.name}
                    onChange={onChangedWrapped}
                />
            </Modal>
        </ModalTransition>
    )
}

const mapStateToProps = ({ data, projectEdit }: IGeovisStoreState): ILabelEditViewStateProps => ({
    labelId: projectEdit.projectEditLabelState.labelId,
    projectId: data.projectInfo.project.Id,
    projectLabelsStorage: data.projectLabelStorage,
    showDialog: projectEdit.projectEditLabelState.showEditLabelDialog
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): ILabelEditViewDispatchProps => ({
    onClose: () => dispatch(projectLabelsEditHide()),
    onSave: label => dispatch(projectDataLabelUpdated(label))
});

export default connect<ILabelEditViewStateProps, ILabelEditViewDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(LabelEditView))