import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import { HeadType, RowType } from '@atlaskit/dynamic-table/types';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { useState } from "react";
import CircleSensorSymbol from 'src/resources/icons/SensorsSymbols/circle-transparent.png'
import PlusSensorSymbol from 'src/resources/icons/SensorsSymbols/plus-transparent.png'
import RombSensorSymbol from 'src/resources/icons/SensorsSymbols/romb-transparent.png'
import SquareSensorSymbol from 'src/resources/icons/SensorsSymbols/square-transparent.png'
import StarSensorSymbol from 'src/resources/icons/SensorsSymbols/star-transparent.png'
import TriangleDownSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-down-transparent.png'
import TriangleLeftSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-left-transparent.png'
import TriangleRightSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-right-transparent.png'
import TriangleUpSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-up-transparent.png'
import { t } from '../../../i18n';
import { XYSensorPair } from "../../../server/AVTService/TypeLibrary/Common/XYSensorPair";
import { getPhysicalUnitShortName } from '../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit';
import { getSensorCategoryName } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { SensorSymbol } from '../../../server/AVTService/TypeLibrary/Sensors/SensorSymbol';
import { GeovisMstShortInfoModel } from '../../../server/GEOvis3/Model/Database/GeovisMstShortInfoModel';
import { SensorChangesModel } from "../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel";
import { SensorInfoMini } from '../../../server/GEOvis3/Model/Sensors/SensorInfoMini';
import FlagService from '../../../services/FlagService';
import { EditIcon } from '../../Icons';
import { LoadingPageSkeleton } from '../../LoadingPageSkeleton';
import { SelectSingleSensorDialog } from './SelectSingleSensorDialog';

interface IDialogProps {
    selectedPairs: XYSensorPair[]
    allYSensors: SensorInfoMini[];
    allXSensors: SensorInfoMini[];
    heading: string;
    isLoading?: boolean;
    projectMsts: GeovisMstShortInfoModel[];

    onSelected: (pairs: XYSensorPair[], sensorChanges: SensorChangesModel[]) => void;
    onCanceled: () => void;
}

interface ISelectSensorsPairsDialogState {
    selectedPairs: XYSensorPair[];
    sensorChanges: SensorChangesModel[];
    showSelectionDialog: boolean;
    isXAxisDialog: boolean;
    lineIndex: number;

}

export const SelectSensorsPairsDialog = ({
    allYSensors,
    heading,
    onCanceled,
    onSelected,
    projectMsts,
    selectedPairs,
    isLoading,
    allXSensors
}: IDialogProps) => {

    const initState: ISelectSensorsPairsDialogState = {
        selectedPairs,
        sensorChanges: [],
        isXAxisDialog: false,
        showSelectionDialog: false,
        lineIndex: 0
    }

    if (isLoading) {
        return (
            <ModalTransition>
                <Modal
                    heading={heading}
                    width="70%">
                    <LoadingPageSkeleton />
                </Modal>
            </ModalTransition>
        );
    }

    const [state, setState] = useState<ISelectSensorsPairsDialogState>(initState);

    const getTableHead = (): HeadType => {
        return ({
            cells: [{
                key: 'y-name',
                width: 3,
                content: t("Y-Sensor Name")
            }, {
                key: 'y-unit',
                width: 3,
                content: t("Y-Sensor Unit")
            }, {
                key: 'y-type',
                width: 3,
                content: t("Y-Sensor Type")
            }, {
                key: 'y-action',
                width: 1,
                content: ''
            }, {
                key: 'x-name',
                width: 3,
                content: t("X-Sensor Name")
            }, {
                key: 'x-unit',
                width: 3,
                content: t("X-Sensor Unit")
            }, {
                key: 'x-type',
                width: 3,
                content: t("X-Sensor Type")
            }, {
                key: 'x-action',
                width: 1,
                content: ''
            }, {
                key: 'symbol',
                width: 1,
                content: t("Symbol")
            }, {
                key: 'actions',
                width: 1,
                content: ''
            }]
        })
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const sensorTypeIcon = (element: SensorInfoMini, index: number) => {

        switch (element.Symbol) {
            case SensorSymbol.Star:
                return (
                    <img src={StarSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.Plus:
                return (
                    <img src={PlusSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.Romb:
                return (
                    <img src={RombSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.Square:
                return (
                    <img src={SquareSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleDown:
                return (
                    <img src={TriangleDownSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleLeft:
                return (
                    <img src={TriangleLeftSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleRight:
                return (
                    <img src={TriangleRightSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleUp:
                return (
                    <img src={TriangleUpSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            default:
                return (
                    <img src={CircleSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
        }

    }

    const onAddNewPair = () => {
        state.selectedPairs.push({ ...new XYSensorPair(), xSensorId: "", ySensorId: "" });
        setState({
            ...state,
            selectedPairs: state.selectedPairs
        })
    }

    const onRemovePair = (index: number) => () => {
        state.selectedPairs.splice(index, 1);
        setState({
            ...state,
            selectedPairs: state.selectedPairs
        })
    }

    const onShowSensorsSelectDialog = (index: number, isXAxis: boolean) => () => {
        setState({
            ...state,
            isXAxisDialog: isXAxis,
            lineIndex: index,
            showSelectionDialog: true
        })
    }

    const onSelectedSensor = (sensorId: string) => {
        if (state.isXAxisDialog) {
            state.selectedPairs[state.lineIndex].xSensorId = sensorId;
        }
        else {
            state.selectedPairs[state.lineIndex].ySensorId = sensorId;
        }

        setState({
            ...state,
            selectedPairs: state.selectedPairs,
            lineIndex: 0,
            showSelectionDialog: false,
            isXAxisDialog: false
        })
    }

    const onCloseDialog = () => {
        setState({
            ...state,
            isXAxisDialog: false,
            lineIndex: 0,
            showSelectionDialog: false
        })
    }

    const getTableRows = (): RowType[] => {
        return state.selectedPairs.map((pair, index) => {

            const ySensor = allYSensors.find(s => s.Id === pair.ySensorId);
            const xSensor = allXSensors.find(s => s.Id === pair.xSensorId);

            return ({
                key: `row-${index}`,
                cells: [{
                    key: 'ySensor-csName',
                    content: (<span>{ySensor ? ySensor.Name : "No Sensor"}</span>)
                }, {
                    key: 'ySensor-type',
                    content: (<span>{ySensor ? getSensorCategoryName(ySensor.PhysicalType) : "--"}</span>)
                }, {
                    key: 'ySensor-unit',
                    content: (<span>{ySensor ? getPhysicalUnitShortName(ySensor.Unit) : "--"}</span>)
                }, {
                    key: 'xSensor-select',
                    content: (
                        <div>
                            <Button
                                iconAfter={<EditIcon label="remove" size='small' />}
                                spacing='compact'
                                onClick={onShowSensorsSelectDialog(index, false)}
                                appearance='subtle'
                            />
                        </div>
                    )
                }, {
                    key: 'xSensor-csName',
                    content: (<span>{xSensor ? xSensor.Name : "No Sensor"}</span>)
                }, {
                    key: 'xSensor-type',
                    content: (<span>{xSensor ? getSensorCategoryName(xSensor.PhysicalType) : "--"}</span>)
                }, {
                    key: 'xSensor-unit',
                    content: (<span>{xSensor ? getPhysicalUnitShortName(xSensor.Unit) : "--"}</span>)
                }, {
                    key: 'xSensor-select',
                    content: (
                        <div>
                            <Button
                                iconAfter={<EditIcon label="remove" size='small' />}
                                spacing='compact'
                                onClick={onShowSensorsSelectDialog(index, true)}
                                appearance='subtle'
                            />
                        </div>
                    )
                }, {
                    key: 'symbol',
                    content: (<div>
                        {ySensor ? sensorTypeIcon(ySensor, index) : "--"}
                    </div>)
                }, {
                    key: 'actions',
                    content: (
                        <div>
                            <Button
                                iconAfter={<EditorCloseIcon label="remove" size='small' />}
                                spacing='compact'
                                onClick={onRemovePair(index)}
                                appearance='subtle'
                            />
                        </div>
                    )
                }]


            })
        })
    }

    const onDone = () => {

        let canBeSaved = true;

        state.selectedPairs.forEach(sp => {
            if (sp.xSensorId === "" || sp.ySensorId === "") {
                canBeSaved = false;
            }
        });

        if (!canBeSaved) {
            FlagService.addError("Configuration error", "Some pairs have less then 2 sensors");
            return;
        }

        onSelected(state.selectedPairs, []);
        onCanceled();
    }

    return (
        <ModalTransition>
            <Modal
                heading={heading}
                actions={[
                    { text: t("Done"), onClick: onDone, isDisabled: isLoading },
                    { text: t("Cancel"), onClick: onCanceled }
                ]}
                width='80%'
                height={'60%'}>
                <div style={{ height: '100%', overflow: 'hidden' }}>
                    <div className="flexRowContainer" style={{ justifyContent: 'right', marginBottom: '10px' }}>
                        <Button
                            appearance='primary'
                            onClick={onAddNewPair}
                        >
                            {t("Add new sensors pair")}
                        </Button>
                    </div>
                    <div className="flexRowContainer" style={{ display: 'flex', height: '100%', width: '100%' }}>
                        <div style={{ height: '90%', overflow: 'auto', width: '100%' }}>
                            <DynamicTable
                                rows={getTableRows()}
                                head={getTableHead()}
                            />
                        </div>
                    </div>
                    {state.showSelectionDialog &&
                        <SelectSingleSensorDialog
                            onSelected={onSelectedSensor}
                            onCanceled={onCloseDialog}
                            allItems={state.isXAxisDialog ? allXSensors : allYSensors}
                            heading={t(`Select ${state.isXAxisDialog ? "X" : "Y"} sensor for pair`)}
                            projectMsts={projectMsts}
                            selectedSensorId={state.isXAxisDialog ? state.selectedPairs[state.lineIndex].xSensorId : state.selectedPairs[state.lineIndex].ySensorId}
                        />
                    }
                </div>
            </Modal>
        </ModalTransition>
    );
}