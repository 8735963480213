/*
  Create Redux store with application state description
*/

import {
    applyMiddleware,
    createStore
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { applicationStorageInitialState } from './reducers/applicationReducer';
import { archivingInitialState } from './reducers/archivingReducer';
import { businessDataInitialState } from './reducers/businessDataReducer';
import { companiesDataInitialState } from './reducers/companiesReducer';
import { getDefaultDashboardState } from './reducers/dashboardReducer';
import { dataMigrationInitialState } from './reducers/dataMigrationsReducer';
import { projectDataInitialState } from './reducers/dataReducer';
import { dtsConfigurationInitialState } from './reducers/dtsConfigurationReducer';
import { elementsMigrationsInitState } from './reducers/elementsMigrationsReducer';
import { inventoryDataInitialState } from './reducers/inventoryReducer';
import { logbookEntryImagesInitialState } from './reducers/logbookEntryImagesReducer';
import { logosManagerInitialState } from './reducers/logosManagerReducer';
import { logsStorageInitialState } from './reducers/logsDataReducer';
import { messageTemplateViewStateInitialState } from './reducers/messageTemplateViewStateReducer';
import { projectCreateInitialState } from './reducers/projectCreateReducer';
import { projectEditInitialState } from './reducers/projectEditReducer';
import { projectLoRaInitialState } from './reducers/projectLoRaReducer';
import { projectOverviewInitialState } from './reducers/projectOverviewReducer';
import { projectReportInitialState } from './reducers/projectReportReducer';
import { reportRenderInitialState } from './reducers/reportRenderReducer';
import { settingsStateInitialState } from './reducers/settingsReducer';
import { statisticsInitialState } from './reducers/statisticsReducer';
import { IGeovisStoreState } from './store.types';
import {
    geovisNavigationInitialState,
    geovisServiceWorkerInitialState,
    loginInitialState,
    projectsOverviewInitialState
} from './types';
import { documentationInitialState } from './reducers/documentationReducer';

const initialState: IGeovisStoreState = {
    navigation: { ...geovisNavigationInitialState },
    projectsOverview: { ...projectsOverviewInitialState },
    projectOverview: { ...projectOverviewInitialState },
    projectEdit: { ...projectEditInitialState },
    login: { ...loginInitialState },
    data: { ...projectDataInitialState },
    logosManager: { ...logosManagerInitialState },
    companiesViewState: { ...companiesDataInitialState },
    projectReport: { ...projectReportInitialState },
    businessData: { ...businessDataInitialState },
    messageTemplateViewState: { ...messageTemplateViewStateInitialState },
    inventoryDataState: { ...inventoryDataInitialState },
    statisticsState: { ...statisticsInitialState },
    serviceWorkerState: { ...geovisServiceWorkerInitialState },
    applicationState: { ...applicationStorageInitialState },
    logs: { ...logsStorageInitialState },
    projectLoRa: { ...projectLoRaInitialState },
    logbookEntryImagesState: { ...logbookEntryImagesInitialState },
    archiving: { ...archivingInitialState },
    creationProjectState: { ...projectCreateInitialState },
    dataMigration: { ...dataMigrationInitialState },
    dtsConfigurationState: { ...dtsConfigurationInitialState },
    settingsState: { ...settingsStateInitialState },
    reportRenderState: { ...reportRenderInitialState },
    projectElementsMigrations: { ...elementsMigrationsInitState },
    dashboardState: { ...getDefaultDashboardState() },
    documentationState: { ...documentationInitialState }
};

const middleware = applyMiddleware(thunk);

const enhancer = composeWithDevTools(middleware);

const store = createStore(rootReducer, initialState, enhancer);

export default store;

/**
 * Create empty store 
 */
export const configureStore = () => createStore(rootReducer, initialState, enhancer);