//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { MigratebleElements } from './MigratebleElements';
import { ProjectElementMigrationDetailError } from './ProjectElementMigrationDetailError';
import { ProjectElementMigrationDetailsInfo } from './ProjectElementMigrationDetailsInfo';

export class ProjectElementMigrationDetails
{
	public Id: string;
	public ProjectId: number;
	public Type: MigratebleElements;
	public Errors: ProjectElementMigrationDetailError[];
	public MigratedElementsInfo: ProjectElementMigrationDetailsInfo[];
}
