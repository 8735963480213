/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @create 24.10.2022
 * @description Dialog to ask user if he/she agree to downgrade the user type
 */

import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { t } from '../../i18n';
import { GeovisUserRoleEnum, getGeovisUserRoleEnumToName } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserRoleEnum';

interface IComponentProps {

    fromRole: GeovisUserRoleEnum;
    toRole: GeovisUserRoleEnum;

    onDowngrade: () => void;
    onClose: () => void;
}

export const DowngradeUserRoleDialog = ({ fromRole, toRole, onClose, onDowngrade }: IComponentProps) => {

    return (
        <ModalTransition>
            <Modal
                heading={t("Degrating the user role")}
                shouldCloseOnEscapePress={true}
                shouldCloseOnOverlayClick={false}
                actions={[
                    { text: t("Downgrade"), appearance: 'danger', onClick: onDowngrade },
                    { text: t("Cancel"), onClick: onClose }
                ]}>
                <div>
                    {t("degratingUserRoleWarning").replace("%1", getGeovisUserRoleEnumToName(fromRole)).replace("%2", getGeovisUserRoleEnumToName(toRole))}
                </div>
            </Modal>
        </ModalTransition>
    )
}