//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DayOfWeek {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6
}

const DayOfWeekToName = new Map<number, string>([
	[ 0, 'Sunday' ],
	[ 1, 'Monday' ],
	[ 2, 'Tuesday' ],
	[ 3, 'Wednesday' ],
	[ 4, 'Thursday' ],
	[ 5, 'Friday' ],
	[ 6, 'Saturday' ],
])

export function getDayOfWeekToName(element: DayOfWeek) : string {
    return DayOfWeekToName.get(element) || '';
}

const DayOfWeekByName = new Map<string, number>([
	[ 'Sunday', 0 ],
	[ 'Monday', 1 ],
	[ 'Tuesday', 2 ],
	[ 'Wednesday', 3 ],
	[ 'Thursday', 4 ],
	[ 'Friday', 5 ],
	[ 'Saturday', 6 ],
]);

export function getDayOfWeekByName(name: string): DayOfWeek {
    return DayOfWeekByName.get(name)!;
}


export const DayOfWeekList : Readonly<DayOfWeek[]> = [
	DayOfWeek.Sunday,
	DayOfWeek.Monday,
	DayOfWeek.Tuesday,
	DayOfWeek.Wednesday,
	DayOfWeek.Thursday,
	DayOfWeek.Friday,
	DayOfWeek.Saturday,
];



const DayOfWeekToDescription = new Map<number, string>([
	[ 0, 'Sunday' ],
	[ 1, 'Monday' ],
	[ 2, 'Tuesday' ],
	[ 3, 'Wednesday' ],
	[ 4, 'Thursday' ],
	[ 5, 'Friday' ],
	[ 6, 'Saturday' ],
])

export function getDayOfWeekToDescription(element: DayOfWeek) : string {
    return DayOfWeekToDescription.get(element) || '';
}

