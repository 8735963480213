//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LocalMapObjectType {
	Text = 1,
	Link = 2,
	Document = 3,
	Polyline = 4,
	Circle = 5,
	Camera = 6,
	Polygon = 7
}

const LocalMapObjectTypeToName = new Map<number, string>([
	[ 1, 'Text' ],
	[ 2, 'Link' ],
	[ 3, 'Document' ],
	[ 4, 'Polyline' ],
	[ 5, 'Circle' ],
	[ 6, 'Camera' ],
	[ 7, 'Polygon' ],
])

export function getLocalMapObjectTypeToName(element: LocalMapObjectType) : string {
    return LocalMapObjectTypeToName.get(element) || '';
}

const LocalMapObjectTypeByName = new Map<string, number>([
	[ 'Text', 1 ],
	[ 'Link', 2 ],
	[ 'Document', 3 ],
	[ 'Polyline', 4 ],
	[ 'Circle', 5 ],
	[ 'Camera', 6 ],
	[ 'Polygon', 7 ],
]);

export function getLocalMapObjectTypeByName(name: string): LocalMapObjectType {
    return LocalMapObjectTypeByName.get(name)!;
}


export const LocalMapObjectTypeList : Readonly<LocalMapObjectType[]> = [
	LocalMapObjectType.Text,
	LocalMapObjectType.Link,
	LocalMapObjectType.Document,
	LocalMapObjectType.Polyline,
	LocalMapObjectType.Circle,
	LocalMapObjectType.Camera,
	LocalMapObjectType.Polygon,
];



const LocalMapObjectTypeToDescription = new Map<number, string>([
	[ 1, 'Text' ],
	[ 2, 'Link' ],
	[ 3, 'Document' ],
	[ 4, 'Polyline' ],
	[ 5, 'Circle' ],
	[ 6, 'Camera' ],
	[ 7, 'Polygon' ],
])

export function getLocalMapObjectTypeToDescription(element: LocalMapObjectType) : string {
    return LocalMapObjectTypeToDescription.get(element) || '';
}

