import { isMobile } from "react-device-detect";
import { Reducer } from "redux";
import AuthService from "../../services/AuthService";
import { DASHBOARD_ADD_ERROR, DASHBOARD_CHANGE_EXPIRED_COUNT, DASHBOARD_CHANGE_TABLE, DASHBOARD_CHANGE_TOO_MANY_WD_COUNT, DASHBOARD_COMPANIES_LOADED, DASHBOARD_COMPANIES_LOADING, DASHBOARD_GLOBAL_LOADED_CHANGED, DASHBOARD_GLOBAL_TIMESTAMP_CHANGED, DASHBOARD_HIDE_FA411_POPUP, DASHBOARD_HIDE_NOTIFICATIONS_ERRORS_POPUP, DASHBOARD_HIDE_PROJECT_WITHOUT_VA_POPUP, DASHBOARD_HIDE_TASKS_ERRORS_POPUP, DASHBOARD_PROJECTS_LOADED, DASHBOARD_PROJECTS_LOADING, DASHBOARD_PROJECT_UPDATED, DASHBOARD_SELECTED_COMPANIES_CHANGED, DASHBOARD_SELECTED_PROJECTS_CHANGED, DASHBOARD_SHOW_FA411_POPUP, DASHBOARD_SHOW_NOTIFICATIONS_ERRORS_POPUP, DASHBOARD_SHOW_PROJECT_WITHOUT_VA_POPUP, DASHBOARD_SHOW_TASKS_ERRORS_POPUP, DASHBOARD_TIMESTAMP_CHANGED } from "../actions/dashboardActions";
import { IDashboardAction, IDashboardState } from "../dashboard.types";


export const getDefaultDashboardState = (): IDashboardState => {
    return {
        commonRefreshTime: new Date(),
        selectedCompaniesIds: [AuthService.currentUserCompanyId()],
        selectedProjectsIds: [],
        globalDataLoaded: false,
        showProjectsWithoutVAPopup: false,
        currentTableToDisplay: isMobile ? 'all' : 'expired',
        errors: [],
        expiredWDCount: 0,
        tooManyWDsCount: 0,
        componentRefreshTimes: new Map<string, Date>(),
        displayDate: new Date(),
        showFA411Popup: false,
        showNotificationsErrorsPopup: false,
        showTasksErrorsPopup: false,
        projects: [],
        companies: [],
        companiesLoaded: false,
        projectsLoaded: false
    }
}

const maxRefreshTimes = (common: Date, controls: Map<string, Date>): Date => {
    let result = common;

    controls.forEach((value) => {
        if (value > result) {
            result = value;
        }
    })

    return result;
}

const dashboardReducer: Reducer<IDashboardState> = (
    state: IDashboardState = getDefaultDashboardState(),
    action: IDashboardAction
): IDashboardState => {
    switch (action.type) {
        case DASHBOARD_SELECTED_COMPANIES_CHANGED: {
            if (!action.companies) {
                return state;
            }

            return ({
                ...state,
                selectedCompaniesIds: action.companies,
                commonRefreshTime: new Date(),
                displayDate: new Date()
            })
        }

        case DASHBOARD_SELECTED_PROJECTS_CHANGED: {
            if (!action.projects) {
                return state;
            }

            return ({
                ...state,
                selectedProjectsIds: action.projects,
                commonRefreshTime: new Date(),
                displayDate: new Date()
            })
        }

        case DASHBOARD_TIMESTAMP_CHANGED: {
            if (!action.timestamp || action.stringProperty === undefined) {
                return state;
            }

            state.componentRefreshTimes.set(action.stringProperty, action.timestamp);

            const displayDate = maxRefreshTimes(state.commonRefreshTime, state.componentRefreshTimes);

            return ({
                ...state,
                componentRefreshTimes: state.componentRefreshTimes,
                displayDate
            })
        }

        case DASHBOARD_GLOBAL_TIMESTAMP_CHANGED: {
            if (!action.timestamp) {
                return state;
            }

            const displayDate = maxRefreshTimes(action.timestamp, state.componentRefreshTimes);

            return ({
                ...state,
                commonRefreshTime: action.timestamp,
                displayDate
            })
        }

        case DASHBOARD_GLOBAL_LOADED_CHANGED: {
            if (action.booleanValue === undefined) {
                return state;
            }

            return ({
                ...state,
                globalDataLoaded: action.booleanValue
            })
        }

        case DASHBOARD_SHOW_PROJECT_WITHOUT_VA_POPUP: {
            return ({
                ...state,
                showProjectsWithoutVAPopup: true
            })
        }

        case DASHBOARD_HIDE_PROJECT_WITHOUT_VA_POPUP: {
            return ({
                ...state,
                showProjectsWithoutVAPopup: false
            })
        }

        case DASHBOARD_ADD_ERROR: {
            if (action.error === undefined) {
                return state;
            }

            state.errors.push(action.error);

            return ({
                ...state,
                errors: [...state.errors]
            })
        }

        case DASHBOARD_CHANGE_TABLE: {
            if (action.tableType === undefined) {
                return state;
            }

            return ({
                ...state,
                currentTableToDisplay: action.tableType
            })
        }

        case DASHBOARD_CHANGE_EXPIRED_COUNT: {
            if (action.numberProperty === undefined) {
                return state;
            }

            return ({
                ...state,
                expiredWDCount: action.numberProperty
            })
        }

        case DASHBOARD_CHANGE_TOO_MANY_WD_COUNT: {
            if (action.numberProperty === undefined) {
                return state;
            }

            return ({
                ...state,
                tooManyWDsCount: action.numberProperty
            })
        }

        case DASHBOARD_SHOW_FA411_POPUP: {
            return ({
                ...state,
                showFA411Popup: true
            })
        }

        case DASHBOARD_HIDE_FA411_POPUP: {
            return ({
                ...state,
                showFA411Popup: false
            })
        }

        case DASHBOARD_SHOW_NOTIFICATIONS_ERRORS_POPUP: {
            return ({
                ...state,
                showNotificationsErrorsPopup: true
            })
        }

        case DASHBOARD_HIDE_NOTIFICATIONS_ERRORS_POPUP: {
            return ({
                ...state,
                showNotificationsErrorsPopup: false
            })
        }

        case DASHBOARD_HIDE_TASKS_ERRORS_POPUP: {
            return ({
                ...state,
                showTasksErrorsPopup: false
            })
        }

        case DASHBOARD_SHOW_TASKS_ERRORS_POPUP: {
            return ({
                ...state,
                showTasksErrorsPopup: true
            })
        }

        case DASHBOARD_PROJECTS_LOADING: {
            return ({
                ...state,
                projectsLoaded: false,
                projects: []
            })
        }

        case DASHBOARD_PROJECTS_LOADED: {
            if (action.projectsModels === undefined) {
                return state;
            }

            return ({
                ...state,
                projects: action.projectsModels,
                projectsLoaded: true
            })
        }

        case DASHBOARD_COMPANIES_LOADING: {
            return ({
                ...state,
                companies: [],
                companiesLoaded: false
            })
        }

        case DASHBOARD_COMPANIES_LOADED: {
            if (action.companiesModels === undefined) {
                return state;
            }

            return ({
                ...state,
                companies: action.companiesModels,
                companiesLoaded: true,
                globalDataLoaded: true
            })
        }

        case DASHBOARD_PROJECT_UPDATED: {
            if (!action.project) {
                return state;
            }

            state.projects.forEach(prj => {
                if (prj.Id === action.project?.Id) {
                    prj.AdminInformation = action.project?.AdminInformation;
                    prj.InternalInformation = action.project?.InternalInformation;
                    prj.Note = action.project?.Note;
                }
            })

            return ({
                ...state,
                projects: state.projects
            })
        }
    }

    return state;
}

export default dashboardReducer;