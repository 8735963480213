/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 07.02.2023
 * @description The user feedback data layer: store model, push request to register the feedback
 */

import { Fragment, ReactNode, useEffect, useState } from 'react';
import { match, useRouteMatch } from 'react-router';
import { IGvOptionType } from '../../../../components/select/GeovisSelect_tools';
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import IRouteParams from '../../../../helpers/IRouteParams';
import { fetchServerElements, sendServerPostRequestData } from '../../../../helpers/ProjectDataHelper';
import { getProjectDisplayName } from '../../../../helpers/ProjectHelper';
import Routes from '../../../../helpers/Routes';
import { t } from '../../../../i18n';
import { FeedbackState } from '../../../../server/AVTService/TypeLibrary/Common/FeedbackState';
import { GeovisFeedbackType } from '../../../../server/AVTService/TypeLibrary/Model/ApplicationFeedback/GeovisFeedbackType';
import { GeovisUserFeedbackModel } from '../../../../server/AVTService/TypeLibrary/Model/ApplicationFeedback/GeovisUserFeedbackModel';
import { DataActionResponse } from '../../../../server/DataActionResponse';
import { ProjectInfoSlim } from '../../../../server/GEOvis3/Model/Project/ProjectInfoSlim';
import ServerRoutesGen from '../../../../server/Routes/ServerRoutesGen';
import FlagService from '../../../../services/FlagService';
import { processFetchedData } from '../../../../store/helpers/DataHelper';
import { defaultSomethingStorageState } from '../../../../store/types';
import FeedbackDataLayer from '../FeedbackDataLayer';
import { isAllowSubmitUserFeedback } from './tools';
import { IFeedbackModelState, IUserFeedbackChildrenFactoryProps, IUserFeedbackViewProps } from './types';
import { FeedbackAttachmentInfo } from '../../../../server/AVTService/TypeLibrary/Feedback/FeedbackAttachmentInfo';

interface IComponentProps extends IWithGeovisServerProps {
    initialFeedbackTitle: string;
    children: (props: IUserFeedbackChildrenFactoryProps) => ReactNode;

    onCancel?: () => void;
}

interface IComponentState extends Omit<IUserFeedbackViewProps, 'model' | 'onPropertyChanged'> {
    isInProgress: boolean
}

const getFeedbackModelInitial = (initialName: string, projectRouteInfo: match<IRouteParams> | null): IFeedbackModelState => {

    const projectId: number = projectRouteInfo
        ? +projectRouteInfo.params.projectId!
        : 0;

    return {
        ...new GeovisUserFeedbackModel(),
        FeedbackType: GeovisFeedbackType.User,
        Name: initialName,
        Message: '',
        ProjectId: projectId,
        State: FeedbackState.Open,
        AttachmentIds: [],
        AttachmentInfos: []
    };
}

const DataLayer = ({ Server, initialFeedbackTitle, children, onCancel }: IComponentProps) => {

    const projectRouteInfo = useRouteMatch<IRouteParams>({ ...Routes.project, exact: false });
    const [model, setModel] = useState<IFeedbackModelState>(getFeedbackModelInitial(initialFeedbackTitle, projectRouteInfo));

    const [state, setState] = useState<IComponentState>({
        allowProjectSelect: projectRouteInfo === null,
        allowValidation: false,
        projectOptionsStorage: { ...defaultSomethingStorageState, data: [] },
        isInProgress: false,
    });

    // load projects info
    useEffect(() => {

        (async function loadProjectsInfo() {

            if (projectRouteInfo) {
                const projectId = +projectRouteInfo.params.projectId!;
                const url = ServerRoutesGen.Project.GetSlim.patch(projectId);
                const response = await fetchServerElements<ProjectInfoSlim>(Server, url);

                setState({
                    ...state,
                    projectOptionsStorage: processFetchedData(response, state.projectOptionsStorage, state.projectOptionsStorage, st => ({
                        data: [{ value: st.Id, label: getProjectDisplayName(st) }]
                    }))
                });
            }
            else {
                const url = ServerRoutesGen.Project.GetRelatedProjectsSlim;
                const response = await fetchServerElements<ProjectInfoSlim[]>(Server, url);

                setState({
                    ...state,
                    projectOptionsStorage: processFetchedData(response, state.projectOptionsStorage, state.projectOptionsStorage, st => ({
                        data: [
                            { value: 0, label: t("General GEOvis4.0") },
                            ...st.map<IGvOptionType<number>>(p => ({ value: p.Id, label: getProjectDisplayName(p) }))
                        ]
                    }))
                })
            }
        })();
    }, []);


    const onPropertyChanged = (propertyName: keyof GeovisUserFeedbackModel, value: any) => {
        setModel({ ...model, [propertyName]: value });
    }

    const onAddAttachment = (attachment: FeedbackAttachmentInfo) => {
        if (model.AttachmentIds.indexOf(attachment.Id) === -1) {
            setModel({ 
                ...model, 
                AttachmentIds: [...model.AttachmentIds, attachment.Id],  
                AttachmentInfos: [...model.AttachmentInfos, attachment]               
            });
        }
    }

    /**
     * Push model to the server
     * @returns 
     */
    const onSubmit = async () => {

        if (!isAllowSubmitUserFeedback(model)) {
            setState({ ...state, allowValidation: true });
            return;
        }


        setState({ ...state, isInProgress: true });

        const url = ServerRoutesGen.GeovisFeedback.RegisterRecord;
        const response = await sendServerPostRequestData<GeovisUserFeedbackModel, DataActionResponse<string>>(Server, url, model);

        setState({ ...state, isInProgress: false });

        if (!response.Success) {
            FlagService.addErrors(t("Cannot submit the feedback"), response.Messages);
            return;
        }

        setModel(getFeedbackModelInitial('', projectRouteInfo));

        FlagService.addInfo(t("New feedback"), t("Your feedback has been registered, thanks. Your personal feedback id is %1").replace("%1", response.Data));
        onCancelHandler();
    }

    /**
     * Cancel submit: hide dialog
     */
    const onCancelHandler = () => {
        if (onCancel && typeof onCancel === 'function') {
            onCancel();
        }
    }

    return (
        <Fragment>
            {children({
                ...state,
                model,
                onCancel: onCancelHandler,
                onSubmit,
                onPropertyChanged,
                onAddAttachment
            })}
            <FeedbackDataLayer refreshDependencies={[state.isInProgress]} />
        </Fragment>
    )
}

export default withGeovisServer(DataLayer);