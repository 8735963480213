import React from "react";
import { IGeoRasterLayersStorage } from "../../store/data.types";
import { GeoRasterTileLayer } from "./GeoRasterTileLayer";

interface IGeoRasterLayersProps {
    geoRasterStorage: IGeoRasterLayersStorage;
    rasterToDisplay: string;
}

export const GeoRasterLayers = ({
    geoRasterStorage,
    rasterToDisplay
}: IGeoRasterLayersProps) => {
    const { isLoading, isError, rasters } = geoRasterStorage;

    if (isLoading || isError) {
        return null;
    }

    const raster = rasters.get(rasterToDisplay);

    if (!raster) {
        return null;
    }

    return (
        <React.Fragment>
            <GeoRasterTileLayer
                key={`geo_map_tiles_layer_${raster.Id}`}
                raster={raster} />
        </React.Fragment>
    );
}