import moment from 'moment';
import Moment from 'react-moment';
import { pickerValueToString } from '../components/dateTimePickers/time.tools';
import { TimePeriod } from '../server/AVTService/TypeLibrary/Common/TimePeriod';

/**
 * 
 * @param date 
 * @param format Date format. default="DD.MM.YYYY HH:mm"
 */
export const dateTimeToReactMoment = (date: any, format: string = "DD.MM.YYYY HH:mm"): JSX.Element => {
    if (!date) {
        return (<span>&nbsp;</span>);
    }
    if (date === '0001-01-01T00:00:00') {
        return (<span>&nbsp;</span>);
    }
    return (<Moment format={format}>{date}</Moment>);
}

export const formattedDateTime = (dateTime: string, format: string = "DD.MM.YYYY HH:mm"): string => {
    if (!dateTime) {
        return '';
    }

    return moment(new Date(dateTime)).format(format);
}

export const formattedDateTimeMoment = (dateTime: string, format: string = "DD.MM.YYYY HH:mm"): string => {
    if (!dateTime) {
        return '';
    }

    return moment(dateTime, format).format(format);
}

export const formatDateTime = (date: Date, format: string = "DD.MM.YYYY HH:mm"): string => {
    return moment(date).format(format);
}

export const getFirstDateOfWeek = (date: Date, format: string = "DD.MM.YYYY HH:mm"): string => {
    const momentDate = moment(date);
    const weekday = momentDate.weekday();
    return momentDate.days(1 - weekday).format(format);
}

/**
 * Get current datetime string in GeovisDateTime format (currently it is simple JSON format to parse on server)
 * @returns 
 */
export const getGeovisCurrentDateTime = (): string => {
    return pickerValueToString(new Date());
}

// /** @deprecated - use getGeovisCurrentDateTime instead of it */
// export const getFormattedCurrentDateTime = (): string => {
//     return moment(new Date()).format("YYYY/MM/DD HH:mm");
// }

export const getFormattedCurrentDate = (format: string = "DD.MM.YYYY"): string => {
    return moment(new Date()).format(format);
}

export const getFormattedDateTime = (date: Date, format: string = "DD.MM.YYYY HH:mm"): string => {
    return moment(date).format(format);
}

/**
 * 
 * @param date 
 * @param shift 
 * @param unit 
 * @returns 
 */
export const getGeovisDateTimeShift = (date: Date, shift: number, unit: moment.unitOfTime.DurationConstructor): string => {
    return pickerValueToString(moment(date).add(shift, unit).toDate(), true);
}

// export const getFormattedDateTimeShift = (date: Date, shift: number, unit: moment.unitOfTime.DurationConstructor, format: string = "DD.MM.YYYY HH:mm"): string => {
//     const mDate = moment(date).add(shift, unit);
//     return mDate.format(format);
// }

export const formatUnixNumberToDateTime = (dateTime: number, format: string = "DD.MM.YYYY HH:mm"): string => {
    return moment.unix(dateTime).format(format);
}

export const getBeginDateOfThisYear = (format: string = "YYYY-MM-DD"): string => {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), 0, 1); // begin of this year

    return getFormattedDateTime(startDate, format);
}

export const getEndDateOfNextYear = (format: string = "YYYY-MM-DD"): string => {
    const now = new Date();
    const endDate = new Date(now.getFullYear() + 1, 11, 31); // end of next year

    return getFormattedDateTime(endDate, format);
}

export const getCalendarStartDay = (format: string = "YYYY-MM-DD"): string => {
    const now = moment(new Date());
    const startDate = now.add(-3, 'months').add(-1 * now.date() + 1, 'days');
    return startDate.format(format);
}

export const getCalendarEndDay = (format: string = "YYYY-MM-DD"): string => {
    const now = moment(new Date());
    const endDate = now.add(-3, 'months').add(-1 * now.date(), 'days').add(2, 'years');
    return endDate.format(format);
}
/**
 * 
 * @param date1 First date to compare
 * @param date2 Second date to compare
 * @param dateFormat format of dates
 * @returns If dates are equal - 0, if date1 > date2: 1, otherwise: -1. In case of empty(undefined) dates -1 will be returned
 */
export const compareDates = (date1: string, date2: string, dateFormat?: string): number => {

    if (!date1 || !date2) {
        return -1;
    }

    const date1Parsed = moment(date1, dateFormat);
    const date2Parsed = moment(date2, dateFormat);

    if (date1Parsed.isBefore(date2Parsed)) {
        return -1
    }
    else if (date1Parsed.isAfter(date2Parsed)) {
        return 1;
    }

    return 0
}

export const addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

/**
 * Add hours to date
 * @param date 
 * @param hours 
 * @returns 
 */
export const addHours = (date: Date, hours: number): Date => {

    const mDate = moment(date);
    const result = mDate.add(hours, 'hours').toDate();

    return result;
}

/**
 * Get number of hours in the dateTime period
 * @param period 
 */
export const dateTimeHelperGetHours = (period: TimePeriod): number => {

    switch (period) {
        case TimePeriod.Hour1: return 1;
        case TimePeriod.Hour2: return 2;
        case TimePeriod.Hour6: return 6;
        case TimePeriod.Hour12: return 12;
        case TimePeriod.Day1: return 24;
        case TimePeriod.Day2: return 48;
        case TimePeriod.Day4: return 4 * 24;
        case TimePeriod.Week1: return 7 * 24;
        case TimePeriod.Week2: return 2 * 7 * 24;
        case TimePeriod.Month1: return getHoursInMonthBefore(1);
        case TimePeriod.Month2: return getHoursInMonthBefore(2);
        case TimePeriod.Month3: return getHoursInMonthBefore(3);
        case TimePeriod.Month6: return getHoursInMonthBefore(6);
        case TimePeriod.Year1: return getHoursInYearBefore(1);
        case TimePeriod.Year2: return getHoursInYearBefore(2);
        case TimePeriod.Year3: return getHoursInYearBefore(3);
        case TimePeriod.Year5: return getHoursInYearBefore(5);
        case TimePeriod.Year10: return getHoursInYearBefore(10);
        case TimePeriod.Custom: return 0;
    }
}

export const getTimeSpanSeconds = (timeSpanString: string): number => {
    const splitedByDot = timeSpanString.split(".");
    if (splitedByDot.length === 0) {
        return 0;
    }

    const hasDays = splitedByDot.length > 1;

    const days = !hasDays ? 0 : isNaN(+splitedByDot[0]) ? 0 : +splitedByDot[0];
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    const dataIndex = hasDays ? 1 : 0;

    const splitedByColon = splitedByDot[dataIndex].split(":");

    hours = isNaN(+splitedByColon[0]) ? 0 : +splitedByColon[0];
    if (splitedByColon.length > 1) {
        minutes = isNaN(+splitedByColon[1]) ? 0 : +splitedByColon[1];
    }
    if (splitedByColon.length > 2) {
        seconds = isNaN(+splitedByColon[2]) ? 0 : +splitedByColon[2];
    }

    return days * 86400 + hours * 3600 + minutes * 60 + seconds;
}

export const getTimeSpanString = (tsSeconds: number): string => {
    const days = wholeNumberDividing(tsSeconds, 86400);
    const hours = wholeNumberDividing(tsSeconds - 86400 * days, 3600);
    const minutes = wholeNumberDividing(tsSeconds - 86400 * days - 3600 * hours, 60);
    const seconds = tsSeconds - 86400 * days - 3600 * hours - 60 * minutes;

    const result = days
        ? `${days.toString()}.${getTsFormatterValue(hours)}:${getTsFormatterValue(minutes)}:${getTsFormatterValue(seconds)}`
        : `${getTsFormatterValue(hours)}:${getTsFormatterValue(minutes)}:${getTsFormatterValue(seconds)}`;

    return result;
}

const wholeNumberDividing = (dividend: number, divider: number): number => {
    return (dividend - dividend % divider) / divider;
}

const getTsFormatterValue = (val: number): string => {
    return val >= 10 ? val.toString() : `0${val.toString()}`;
}

/**
 * Get number of hours for number of months ago
 * @param countMonthsBefore 
 * @returns 
 */
const getHoursInMonthBefore = (countMonthsBefore: number): number => {
    const now = moment().toDate();
    const monthBefore = moment().subtract(countMonthsBefore, 'months').toDate();

    const diffHours = Math.abs(now.getTime() - monthBefore.getTime()) / 1000 / 3600;

    return diffHours;
}

/**
 * Get number of hours for number of years ago
 * @param countYearsBefore 
 */
const getHoursInYearBefore = (countYearsBefore: number): number => {

    const now = moment().toDate();
    const yearsBefore = moment().subtract(countYearsBefore, 'years').toDate();

    const diffHours = Math.abs(now.getTime() / 1000 - yearsBefore.getTime() / 1000) / 3600;

    return diffHours;
}