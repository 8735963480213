import Button from '@atlaskit/button/standard-button';
import DynamicTable from "@atlaskit/dynamic-table";
import { HeadType, RowType } from "@atlaskit/dynamic-table/types";
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import Modal, { ModalFooter, ModalTransition } from "@atlaskit/modal-dialog"
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { t } from "../../../i18n";
import { LabelModel } from '../../../server/AVTService/TypeLibrary/Model/LabelModel';
import { projectLabelsDeleteShow, projectLabelsEditShow, projectLabelsHide } from '../../../store/creators/projectEditCreators';
import { IGeovisProjectLabelStorage } from "../../../store/data.types";
import { IGeovisStoreState } from '../../../store/store.types';
import { IGeovisAction } from '../../../store/types';

interface ILabelListViewStateProps {
    showDialog: boolean;
    projectLabelsStorage: IGeovisProjectLabelStorage;
}

interface ILabelListViewDispatchProps {
    onShowAddEditDialog: (labelId: string) => void;
    onDeleteLabel: (labelId: string) => void;
    onClose: () => void;
}

interface ILabelListViewProps extends ILabelListViewStateProps, ILabelListViewDispatchProps {

}

const LabelListView = ({
    projectLabelsStorage,
    showDialog,
    onClose,
    onShowAddEditDialog,
    onDeleteLabel
}: ILabelListViewProps) => {

    if (!showDialog) {
        return (
            <div>
                {/*  */}
            </div>
        )
    }

    const onEditLabelClick = (labelId: string) => () => {
        onShowAddEditDialog(labelId);
    }

    const onDeleteLabelClick = (labelId: string) => () => {
        onDeleteLabel(labelId);
    }

    const onAddLabelClick = () => {
        onShowAddEditDialog("");
    }

    const getFooter = () => {
        return (
            <ModalFooter>
                <div>
                    <Button appearance={'primary'} onClick={onAddLabelClick}>
                        {t("Add label")}
                    </Button>
                </div>
                <div>
                    <Button appearance={'primary'} onClick={onClose}>
                        {t("Close")}
                    </Button>
                </div>
            </ModalFooter>
        );
    }

    const getTableHead = (): HeadType => ({
        cells: [{
            key: 'labelText',
            content: t("Label"),
            isSortable: false,
            width: 10
        }, {
            key: 'label-actions',
            content: "",
            isSortable: false,
            width: 1
        },]
    });

    const getTableRows = (): RowType[] => {
        const rows: RowType[] = [];

        const createRow = (label: LabelModel): RowType => ({
            key: `${label.id}_row`,
            cells: [{
                key: 'labelText',
                content: (
                    <div>
                        <span>{label.name}</span>
                    </div>
                )
            }, {
                key: 'label-actions',
                content: (
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '5px' }}>
                            <Button
                                onClick={onEditLabelClick(label.id)}
                                iconBefore={<EditFilledIcon label="edit" />}
                            />
                        </div>
                        <div>
                            <Button
                                onClick={onDeleteLabelClick(label.id)}
                                iconBefore={<CrossCircleIcon label="edit" />}
                            />
                        </div>
                    </div>
                )
            }]
        });

        projectLabelsStorage.labels.forEach((val) => {
            rows.push(createRow(val));
        });

        return rows;
    }

    return (
        <ModalTransition>
            <Modal
                heading={t("Project's labels")}
                components={{
                    Footer: getFooter,
                }}>
                <div>
                    <DynamicTable
                        head={getTableHead()}
                        rows={getTableRows()}
                    />
                </div>
            </Modal>
        </ModalTransition>
    );

}

const mapStateToProps = ({ data, projectEdit }: IGeovisStoreState): ILabelListViewStateProps => ({
    projectLabelsStorage: data.projectLabelStorage,
    showDialog: projectEdit.projectEditLabelState.showLabelsDialog
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): ILabelListViewDispatchProps => ({
    onClose: () => dispatch(projectLabelsHide()),
    onDeleteLabel: labelId => dispatch(projectLabelsDeleteShow(labelId)),
    onShowAddEditDialog: labelId => dispatch(projectLabelsEditShow(labelId))
});

export default connect<ILabelListViewStateProps, ILabelListViewDispatchProps>(mapStateToProps, mapDispatchToProps)(LabelListView);