/**
 * The Authentication service
 */
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { API_ROOT } from "../ApiConfig";
import IGeovisComponent from '../components/abstract/IGeovisComponent';
import Route from "../helpers/Route";
import i18next, { t } from '../i18n';
import { ActionResponse } from "../server/ActionResponse";
import { AuthorizationCode } from "../server/AuthorizationCode";
import { Distributors } from "../server/AVTService/TypeLibrary/DB/Distributors";
import { GeovisUserRoleEnum } from "../server/AVTService/TypeLibrary/Identity/GeovisUserRoleEnum";
import { GeovisUserToProjectRole } from "../server/AVTService/TypeLibrary/Identity/GeovisUserToProjectRole";
import { DataActionResponse } from "../server/DataActionResponse";
import { GeovisServerInfo } from "../server/GEOvis3/Model/Server/GeovisServerInfo";
import { GeovisUserProfileInfo } from "../server/GEOvis3/Model/User/GeovisUserProfileInfo";
import ServerRoutesGen from "../server/Routes/ServerRoutesGen";
import FlagService from "./FlagService";
import Logger from "./Logger";
import RequestHelper, { IRequestHelper } from "./RequestHelper";
import { fetchServerElements } from "../helpers/ProjectDataHelper";
import { setLocalStorageItem } from "../components/utils";
import { LicensedFeatures } from "../server/AVTService/TypeLibrary/Licensing/LicensedFeatures";
import { GeovisCompanyType } from "../server/AVTService/TypeLibrary/Common/GeovisCompanyType";

const LoggerSource = "AuthService";

export interface ITokenStorage {
    getToken(): string | null;
}

export enum ApplicationId {
    GEOvis4,
    MapTilesService
}

class AuthService implements IGeovisComponent, ITokenStorage {
    private signedInHandler: (userId: string) => void;
    private signOutHandler: () => void;

    private readonly IdUserName: string = "id_username";
    private readonly IdPassword: string = "id_password";
    private readonly IdToken: string = "id_token";
    private readonly IdMapTilesToken: string = "id_mapTilesToken";

    private serverInfo: GeovisServerInfo | undefined;
    private resignTriesNumber: number = 0;

    private readonly ResignTriesMaxNumber: number = 3;

    /**
     * This variable store changes of user to project relation.
     * It is used to change view of project view for project admin.
     */
    private readonly actualProjectRelations: GeovisUserToProjectRole[];

    constructor() {
        this.serverInfo = undefined;
        this.actualProjectRelations = [];

        // add interceptors to refresh expired token
        axios.interceptors.response.use(
            response => {
                if (response.status === 200) {
                    const actionResponse = response.data as ActionResponse;

                    if (actionResponse && !actionResponse.Success &&
                        actionResponse.AuthorizationCode === AuthorizationCode.AuthorizationFailed) {
                        FlagService.addError("Authorization failed", "Error code 200");
                        this.signOut();
                    }
                }
                return response;
            },
            async error => {
                if (error.response && error.response.status === 401) {
                    const responseURL = error.response.request ? error.response.request.responseURL : '';

                    if (this.resignTriesNumber > this.ResignTriesMaxNumber) {
                        Logger.error(`Cannot login in to the server 3 times when executing the URL '${responseURL}': resignTriesNumber = ${this.resignTriesNumber}. Forcing SignOut`, this.constructor.name);
                        this.signOut();
                        return;
                    }

                    const userName = this.getUserName();
                    const password = this.getPassword();

                    if (userName && password) {
                        Logger.info(`Trying to refresh token when executing the URL '${responseURL}'`, "AuthService");

                        const successful = await this.signInImplementation(userName, password);
                        if (!successful) {
                            Logger.info("Refresh token failed", "AuthService");
                            return Promise.reject(error);
                        }

                        const { applicationId } = error.config.headers;
                        if (applicationId === ApplicationId.MapTilesService) {
                            if (await this.signInToMapTiles()) {
                                return true;
                            }

                            throw Error("Failed second authentication in MapTiles Service");
                        }

                        error.config.headers = {
                            access_token: "Bearer " + this.getToken()
                        };
                        Logger.info("Refresh token success, sending request again", "AuthService");
                        return axios.request(error.config);
                    }
                } else if (error.response && error.response.status === 419) {
                    Logger.error("Concurrent login detected, sign out");
                    FlagService.addError(t("Authorization failed"), t("concurentlogin"));
                    this.signOut();
                }

                return Promise.reject(error);
            }
        );
    }

    public registerSignedInHandler = (
        signedInHandler: (userId: string) => void
    ) => {
        this.signedInHandler = signedInHandler;
    };

    public registerSignOutHandler = (signedOutHandler: () => void) => {
        this.signOutHandler = signedOutHandler;
    };

    public getFullUrl = (apiRoot: string, route: string): string => {
        return `${apiRoot}/api${route}`;
    }

    /**
     * Realization of interface IGeovisComponent
     */
    public isComponentMounted = () => true;

    public signIn = async (userName: string, password: string): Promise<boolean> => {

        // reset counter of tries to signIn when it called by user
        this.resignTriesNumber = 0;

        return this.signInImplementation(userName, password);
    }

    /**
     * Do signIn user on the server
     * @param userName user name or email
     * @param password user password
     */
    public signInImplementation = async (userName: string, password: string): Promise<boolean> => {
        const endpointUrl = `${API_ROOT}/api/connect/token`;
        const cancellationToken = axios.CancelToken.source();

        try {
            const params = new URLSearchParams();
            params.append("grant_type", "password");
            params.append("username", userName);
            params.append("password", password);

            const response = await this.executeAxiosAnonymousRequest(endpointUrl, params, cancellationToken);

            Logger.trace(response, LoggerSource);

            this.setToken(response.data.access_token);
            this.setCredentials(userName, password);

            // get ProjectRelationsInfo from the userInfo after its loading
            // this.setProjectRelations(response.data.userAdminProjectIds, response.data.userViewerProjectIds);

            Logger.info("SignIn success", LoggerSource);

            if (this.signedInHandler) {
                this.signedInHandler(userName);
            }

            Logger.info("SignIn advance", "Load user info");
            const requestHelper = new RequestHelper(API_ROOT, this, this, ApplicationId.GEOvis4);
            this.setServerInfo(await requestHelper.loadServerInfo());

            Logger.info("SignIn advance", "Load user info completed");

            Logger.info("SignIn MapTilesService", "Logging in to MapTilesService");

            const mapTilesResult = await this.signInToMapTiles();

            if (mapTilesResult) {
                // Logger.info("SignIn MapTilesService", "SignIn in to MapTilesService completed");
            } else {
                // Logger.info("SignIn MapTilesService", "SignIn in to MapTilesService failed");
            }

            return true;
        } catch (error) {
            console.error(`signInImplementation failed with error: ${error}`);
            cancellationToken.cancel();

            this.signOut();
            throw error;
        }
        finally {
            this.resignTriesNumber++;
            Logger.info(`Increased resignTriesNumber. New value = ${this.resignTriesNumber}`);
        }
    };

    /**
     * Sign out and cleanup local storage
     */
    public signOut = (): void => {
        localStorage.removeItem(this.IdUserName);
        localStorage.removeItem(this.IdPassword);
        localStorage.removeItem(this.IdToken);

        this.signOutMapTilesService();

        if (this.signOutHandler) {
            this.signOutHandler();
        }

        Logger.info("Signed out", LoggerSource);
    };

    public signOutMapTilesService = (): void => {
        localStorage.removeItem(this.IdMapTilesToken);

        Logger.info("Signed Out of MapTilesService", LoggerSource)
    }

    public isAuthenticatedUser = (): boolean => {
        return this.getToken() !== null;
    };

    public isAuthenticatedMapTilesService = (): boolean => {
        return this.getMapTilesToken() !== null;
    }

    public getRequestHelper = (owner: IGeovisComponent): IRequestHelper => {
        return new RequestHelper(API_ROOT, owner, this, ApplicationId.GEOvis4);
    }

    /**
     * Get MapTiles Token Storage
     */
    /** @deprecated */
    public getMapTilesServiceTokenStorage = (): ITokenStorage => ({
        getToken: (): string => {
            return this.getMapTilesToken() || '';
        }
    });

    public signInToMapTiles = async (): Promise<boolean> => {

        if (!this.serverInfo) {
            Logger.warning("Cannot login to map tiles service, UserInfo not loaded", LoggerSource);
            return false;
        }

        try {

            const url = ServerRoutesGen.MapTiles.SingInToken;
            const response = await fetchServerElements<string>(this.getRequestHelper(this), url);

            if (!response.Success) {
                Logger.error('An error to sign in to map tiles');
                return false;
            }

            this.setMapTilesToken(response.Data);
            Logger.trace('Login in to MapTilesService success', LoggerSource);

            return true;
        }
        catch (error) {
            Logger.error(`Cannot connect to MapTilesService, please check connection, ${error}`, LoggerSource);
            this.signOutMapTilesService();
            return false;
        }
    }

    public checkAuthenticationInMapTilesServiceAsync = async (): Promise<boolean> => {
        try {

            const token = this.getMapTilesToken();

            if (!token) {
                if (!await this.signInToMapTiles()) {
                    return false;
                }
                Logger.trace('Refresh of MapTiles token has been complete success');
                return true;
            }

            // make test request to map tiles service
            const url = ServerRoutesGen.MapTiles.Check.path;
            const response = await this.getRequestHelper(this).get<ActionResponse>(url, { headers: { access_token_mt: token } });

            if (!response.Success) {
                if (response.AuthorizationCode === AuthorizationCode.AuthorizationFailed && await this.signInToMapTiles()) {
                    Logger.trace('Refresh of MapTiles token has been complete success');
                    return true;
                }

                Logger.error('Error of get request to map tiles service');
                return false;
            }

            Logger.trace('All fine with authentication to map tiles service');
            return true;

        } catch (error) {
            Logger.error('error check the map tiles service authentication', LoggerSource);
            return false;
        }
    }

    public fetchServerInfo = async () => {
        const request = new RequestHelper(API_ROOT, this, this, ApplicationId.GEOvis4);
        this.setServerInfo(await request.loadServerInfo());
    }

    public lazyLoadServerInfoByUrl = async (url: Route) => {
        if (this.serverInfo === undefined) {
            const request = new RequestHelper(API_ROOT, this, this, ApplicationId.GEOvis4);
            this.setServerInfo(await request.loadServerInfoByUrl(url));
        }
    }

    public userName = (): string => {
        return this.getUserName() || "";
    };

    public getUserRoles = (): GeovisUserRoleEnum[] => {
        return [
            GeovisUserRoleEnum.User,
            GeovisUserRoleEnum.CompanyAdmin,
            GeovisUserRoleEnum.Admin,
            GeovisUserRoleEnum.Developer
        ];
    }

    public hasUserTypeAsUser = (): boolean => {
        const userInfo = this.getUserInfo();
        return userInfo ? userInfo.GeovisUserRole >= GeovisUserRoleEnum.Zero : false;
    };

    public hasUserTypeAsCompanyAdmin = (): boolean => {
        const userInfo = this.getUserInfo();
        return userInfo ? userInfo.GeovisUserRole >= GeovisUserRoleEnum.CompanyAdmin : false;
    }

    public hasUserTypeAsAdmin = (): boolean => {
        const userInfo = this.getUserInfo();
        return userInfo ? userInfo.GeovisUserRole >= GeovisUserRoleEnum.Admin : false;
    }

    public hasUserTypeAsDeveloper = (): boolean => {
        const userInfo = this.getUserInfo();
        return userInfo ? userInfo.GeovisUserRole >= GeovisUserRoleEnum.Developer : false;
    }

    public hasUsersCompanyNewLicense = (): boolean => {
        const userInfo = this.getUserInfo();
        return userInfo ? userInfo.CompanyNewInvoiceEnabled : false;
    }

    public getCurrentUserCompanyType = (): GeovisCompanyType => {
        const userInfo = this.getUserInfo();
        return userInfo ? userInfo.CompanyType : GeovisCompanyType.Viewer;
    }

    public isUnderAdminRole = (): boolean => {
        return this.serverInfo !== undefined && this.serverInfo.CurrentUser.GeovisUserRole < GeovisUserRoleEnum.Admin;
    }

    public isUnderCompanyAdminRole = (): boolean => {
        return this.serverInfo !== undefined && this.serverInfo.CurrentUser.GeovisUserRole < GeovisUserRoleEnum.CompanyAdmin;
    }

    public isNagraDistribution = (): boolean => {
        return this.getDistribution() === Distributors.Nagra;
    }

    public isAmbergDistribution = (): boolean => {
        return this.getDistribution() === Distributors.Amberg;
    }

    public hasUsersCompanyLicenseFor = (feature: LicensedFeatures): boolean => {

        const userInfo = this.getUserInfo();
        if (!userInfo) {
            return false;

        }
        const features = userInfo.CompanyAvailableFeatures;

        if (!feature || !features.includes) {
            return false;
        }

        return features.includes(feature) ?? false;
    }

    public getDistribution = (): Distributors | undefined => {
        if (this.serverInfo !== undefined) {
            return this.serverInfo.Distributor;
        }
        return undefined;
    }

    public setActualProjectRelationRole = (projectId: number, role: GeovisUserToProjectRole) => {
        this.actualProjectRelations[projectId] = role;
    }

    public isActualAdminOfProject = (projectId: number): boolean => {

        if (this.actualProjectRelations[projectId]) {
            return this.actualProjectRelations[projectId] === GeovisUserToProjectRole.Admin;
        }

        return this.isRealAdminOfProject(projectId);
    }

    public isRealAdminOfProject = (projectId: number): boolean => {
        const userAdminProjectIds = this.getUserProjectsIdsOf(GeovisUserToProjectRole.Admin);
        return userAdminProjectIds && userAdminProjectIds.indexOf(projectId) !== -1;
    }

    public isActualViewerOfProject = (projectId: number): boolean => {

        if (this.actualProjectRelations[projectId]) {
            return this.actualProjectRelations[projectId] === GeovisUserToProjectRole.Viewer;
        }

        return this.isRealViewerOfProject(projectId);
    }

    public isRealViewerOfProject = (projectId: number): boolean => {
        const userViewerProjectIds = this.getUserProjectsIdsOf(GeovisUserToProjectRole.Viewer);
        return userViewerProjectIds && userViewerProjectIds.indexOf(projectId) !== -1;
    }

    public isActualEmailReceiverOfProject = (projectId: number): boolean => {
        if (this.actualProjectRelations[projectId] !== undefined) {
            return this.actualProjectRelations[projectId] === GeovisUserToProjectRole.EmailReceiver;
        }
        return this.isRealEmailReceiverOfProject(projectId);
    }

    public isRealEmailReceiverOfProject = (projectId: number): boolean => {
        const userEReceiversProjectIds = this.getUserProjectsIdsOf(GeovisUserToProjectRole.EmailReceiver);
        return userEReceiversProjectIds.indexOf(projectId) !== -1;
    }

    public hasReadPermissions = (projectId: number): boolean => {
        return this.isActualViewerOfProject(projectId) || this.isActualAdminOfProject(projectId);
    }

    public isAllowedShowSensorWarningState = (projectId: number): boolean => {
        return this.isActualAdminOfProject(projectId)
    }

    public isChangePasswordForbidden = (): boolean => {
        return this.serverInfo === undefined ? false : this.serverInfo.CurrentUser.ChangePasswordForbidden;
    }

    public getProjectViewerMode = (projectId: number): GeovisUserToProjectRole | null => {
        if (this.actualProjectRelations[projectId]) {
            return this.actualProjectRelations[projectId];
        }

        const userAdminProjectIds = this.getUserProjectsIdsOf(GeovisUserToProjectRole.Admin);
        if (userAdminProjectIds.indexOf(projectId) !== -1) {
            return GeovisUserToProjectRole.Admin;
        }

        const userViewerProjectIds = this.getUserProjectsIdsOf(GeovisUserToProjectRole.Viewer);
        if (userViewerProjectIds.indexOf(projectId) !== -1) {
            return GeovisUserToProjectRole.Viewer;
        }

        return null;
    }

    public isProjectViewerMode = (projectId: number): boolean => {
        if (this.actualProjectRelations[projectId]) {
            return this.actualProjectRelations[projectId] === GeovisUserToProjectRole.Viewer;
        }

        return false;
    }

    public currentUserId = (): string => {
        const userInfo = this.getUserInfo();
        if (userInfo !== undefined) {
            return userInfo.Id;
        }
        return "";
    }

    public currentUserRole = (): GeovisUserRoleEnum => {
        const userInfo = this.getUserInfo();
        if (userInfo !== undefined) {
            return userInfo.GeovisUserRole;
        }

        return GeovisUserRoleEnum.Zero;
    }

    public currentUserCompanyId = (): string => {
        if (this.serverInfo !== undefined) {
            return this.serverInfo.CurrentUser.CompanyId;
        }
        return "";
    }

    public getTokenSafety = (): string => {
        return this.getToken() || '';
    }

    public getToken = (): string | null => {
        return localStorage.getItem(this.IdToken) || null;
    };

    public getMapTilesToken = (): string | null => {
        return localStorage.getItem(this.IdMapTilesToken);
    }

    public getUserInfo = (): GeovisUserProfileInfo | undefined => {
        if (this.serverInfo) {
            return this.serverInfo.CurrentUser;
        }
        return undefined;
    }

    public isClientVersionUpToDate = async (): Promise<boolean> => {
        try {
            const url = ServerRoutesGen.Account.GetApplicationBuildNumber.path;
            const requestHelper = this.getRequestHelper(this);
            const response = await requestHelper.get<DataActionResponse<string>>(url);
            if (response.Success) {
                const serverBuild = response.Data;
                const clientVersionJson = require("../version.json");
                return serverBuild === clientVersionJson.build;
            }
            return false;
        }
        catch (error) {
            return false;
        }
    }

    public getServerVersion = async (): Promise<string> => {
        try {
            const url = ServerRoutesGen.Account.GetApplicationVersion.path;
            const requestHelper = this.getRequestHelper(this);
            const response = await requestHelper.get<DataActionResponse<string>>(url);
            if (response.Success) {
                return response.Data;
            }
            return "";

        }
        catch (error) {
            return "";
        }
    }

    private setServerInfo = (serverInfo: GeovisServerInfo) => {
        if (!serverInfo) {
            this.signOut();
            return;
        }

        this.serverInfo = serverInfo;

        // if (serverInfo.CurrentUser.ProjectRelationInfos) {

        //     const getProjectIds = (projectRelations: ProjectRelationInfo[]): string => (projectRelations.map(r => r.ProjectId).join(","));

        //     const userAdminProjectIds = `[${getProjectIds(serverInfo.ProjectRelationInfos.filter(r => r.Role === GeovisUserToProjectRole.Admin))}]`;
        //     const userViewerProjectIds = `[${getProjectIds(serverInfo.ProjectRelationInfos.filter(r => r.Role === GeovisUserToProjectRole.Viewer))}]`;
        //     const userEReceiverProjectIds = `[${getProjectIds(serverInfo.ProjectRelationInfos.filter(r => r.Role === GeovisUserToProjectRole.EmailReceiver))}]`;
        //     this.setProjectRelations(userAdminProjectIds, userViewerProjectIds, userEReceiverProjectIds);
        // }

        if (this.serverInfo) {

            const { CurrentUser } = this.serverInfo;
            if (CurrentUser) {
                if (CurrentUser.LanguageCode && CurrentUser.LanguageCode.length > 0 && i18next.language !== CurrentUser.LanguageCode) {
                    setLocalStorageItem("id_language", CurrentUser.LanguageCode);
                    i18next.changeLanguage(CurrentUser.LanguageCode);
                }
            }
        }
    }

    private getUserName = (): string | null => {
        return localStorage.getItem(this.IdUserName) || null;
    };

    private getPassword = (): string | null => {
        return localStorage.getItem(this.IdPassword) || null;
    };

    private setToken = (token: string): void => {
        setLocalStorageItem(this.IdToken, token);
    };

    private setCredentials = (username: string, password: string): void => {
        setLocalStorageItem(this.IdUserName, username);
        setLocalStorageItem(this.IdPassword, password);
    };

    private getUserProjectsIdsOf = (role: GeovisUserToProjectRole): number[] => {

        if (!this.serverInfo) {
            return [];
        }

        const { CurrentUser } = this.serverInfo;
        if (!CurrentUser || !CurrentUser.ProjectRelationInfos) {
            return [];
        }

        return CurrentUser.ProjectRelationInfos
            .filter(r => r.Role === role)
            .map(r => r.ProjectId);
    }

    private setMapTilesToken = (token: string) => {
        setLocalStorageItem(this.IdMapTilesToken, token);
    }

    private executeAxiosAnonymousRequest = async (url: string, data: any, cancellationToken: CancelTokenSource): Promise<AxiosResponse<any>> => {

        const response = await axios({
            cancelToken: cancellationToken.token,
            data,
            headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/x-www-form-urlencoded"
            },
            method: "post",
            url
        });

        return response;
    }
}

export default new AuthService();


