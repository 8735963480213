/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 09.10.2020
 * @description Inventory reducer tool methods
 */

import { addOrUpdateElementInArray } from "../../helpers/StorageHelper";
import { InventoryAfmBoxModel } from "../../server/AVTService/TypeLibrary/Inventory/InventoryAfmBoxModel";
import { InventoryAfmFilterOptions } from "../../server/AVTService/TypeLibrary/Inventory/InventoryAfmFilterOptions";
import { InventoryAtcFilterOptions } from "../../server/AVTService/TypeLibrary/Inventory/InventoryAtcFilterOptions";
import { InventoryAtcSensorModel } from "../../server/AVTService/TypeLibrary/Inventory/InventoryAtcSensorModel";
import { InventoryFilterOptions } from "../../server/AVTService/TypeLibrary/Inventory/InventoryFilterOptions";
import { InventoryLoRaGatewayFilterOptions } from "../../server/AVTService/TypeLibrary/Inventory/InventoryLoRaGatewayFilterOptions";
import { InventoryLoRaGatewayModel } from "../../server/AVTService/TypeLibrary/Inventory/InventoryLoRaGatewayModel";
import { InventoryMstFilterOptions } from "../../server/AVTService/TypeLibrary/Inventory/InventoryMstFilterOptions";
import { InventoryMstModel } from "../../server/AVTService/TypeLibrary/Inventory/InventoryMstModel";
import { InventoryObjectDescription } from "../../server/AVTService/TypeLibrary/Inventory/InventoryObjectDescription";
import { InventoryObjectType } from "../../server/AVTService/TypeLibrary/Inventory/InventoryObjectType";
import { InventorySensorboxFilterOptions } from "../../server/AVTService/TypeLibrary/Inventory/InventorySensorboxFilterOptions";
import { InventorySensorboxModel } from "../../server/AVTService/TypeLibrary/Inventory/InventorySensorboxModel";
import { InventoryTachymeterFilterOptions } from "../../server/AVTService/TypeLibrary/Inventory/InventoryTachymeterFilterOptions";
import { InventoryTachymeterModel } from "../../server/AVTService/TypeLibrary/Inventory/InventoryTachymeterModel";
import { InventoryAfmBoxInfo } from "../../server/GEOvis3/Model/Inventory/InventoryAfmBoxInfo";
import { InventoryAtcSensorInfo } from "../../server/GEOvis3/Model/Inventory/InventoryAtcSensorInfo";
import { InventoryLoRaGatewayInfo } from "../../server/GEOvis3/Model/Inventory/InventoryLoRaGatewayInfo";
import { InventoryMstInfo } from "../../server/GEOvis3/Model/Inventory/InventoryMstInfo";
import { InventoryObjectInfo } from "../../server/GEOvis3/Model/Inventory/InventoryObjectInfo";
import { InventorySensorboxInfo } from "../../server/GEOvis3/Model/Inventory/InventorySensorboxInfo";
import { InventoryTachymeterInfo } from "../../server/GEOvis3/Model/Inventory/InventoryTachymeterInfo";
import {
    IGeovisInventoryAction,
    IGeovisInventoryAfmStorage,
    IGeovisInventoryAtcStorage,
    IGeovisInventoryDataFilterAction,
    IGeovisInventoryDataState,
    IGeovisInventoryDescriptionsStorage,
    IGeovisInventoryLoRaGatewayStorage,
    IGeovisInventoryMstStorage,
    IGeovisInventoryObjectStorage,
    IGeovisInventorySensorboxStorage,
    IGeovisInventoryTachymetersStorage
} from "../inventory.types";
import { errorSomethingStorageState, loadedSomethingStorageState } from "../types";
import {
    inventoryAfmInitialState,
    inventoryAtcInitialState,
    inventoryMstInitialState,
    inventorySensorboxesInitialState,
    inventoryTachymetersInitialState
} from "./inventoryReducer.types";

/**
 * dispatch data loading action
 */
export const dispatchDataLoadingAction = (state: IGeovisInventoryDataState, { objectType }: IGeovisInventoryAction): IGeovisInventoryDataState => {
    if (objectType === undefined) {
        return state;
    }

    switch (objectType) {
        case InventoryObjectType.Afm:
            return { ...state, afm: { ...inventoryAfmInitialState } };

        case InventoryObjectType.Atc:
            return { ...state, atc: { ...inventoryAtcInitialState } };

        case InventoryObjectType.MST:
            return { ...state, mst: { ...inventoryMstInitialState } };

        case InventoryObjectType.SB:
            return { ...state, sensorboxes: { ...inventorySensorboxesInitialState } };

        case InventoryObjectType.Tachymeter:
            return { ...state, tachy: { ...inventoryTachymetersInitialState } };

        case InventoryObjectType.LoRaGateway:
            return { ...state, }
    }
}

/**
 * dispatch and process data loaded state
 * @param state 
 * @param action 
 */
export const dispatchDataLoadedAction = (state: IGeovisInventoryDataState, action: IGeovisInventoryAction): IGeovisInventoryDataState => {

    switch (action.objectType) {

        case InventoryObjectType.Atc:
            return {
                ...state,
                atc: processDataWithFilterAction<InventoryAtcSensorInfo, InventoryAtcFilterOptions, IGeovisInventoryAtcStorage>(state.atc, action)
            };

        case InventoryObjectType.Afm:
            return {
                ...state,
                afm: processDataWithFilterAction<InventoryAfmBoxInfo, InventoryAfmFilterOptions, IGeovisInventoryAfmStorage>(state.afm, action)
            };

        case InventoryObjectType.MST:
            return {
                ...state,
                mst: processDataWithFilterAction<InventoryMstInfo, InventoryMstFilterOptions, IGeovisInventoryMstStorage>(state.mst, action)
            };

        case InventoryObjectType.SB:
            return {
                ...state,
                sensorboxes: processDataWithFilterAction<InventorySensorboxInfo, InventorySensorboxFilterOptions, IGeovisInventorySensorboxStorage>(state.sensorboxes, action)
            };

        case InventoryObjectType.Tachymeter:
            return {
                ...state,
                tachy: processDataWithFilterAction<InventoryTachymeterInfo, InventoryTachymeterFilterOptions, IGeovisInventoryTachymetersStorage>(state.tachy, action)
            };

        case InventoryObjectType.LoRaGateway:
            return {
                ...state,
                loraGateway: processDataWithFilterAction<InventoryLoRaGatewayInfo, InventoryLoRaGatewayFilterOptions, IGeovisInventoryLoRaGatewayStorage>(state.loraGateway, action)
            };
    }

    return state;
}

/**
 * dispatch error action
 * @param state 
 * @param action 
 */
export const dispatchDataErrorAction = (state: IGeovisInventoryDataState, { objectType, errorDescription }: IGeovisInventoryAction): IGeovisInventoryDataState => {
    if (objectType === undefined) {
        return state;
    }

    switch (objectType) {
        case InventoryObjectType.Afm:
            return { ...state, afm: { ...state.afm, ...errorSomethingStorageState(errorDescription) } };

        case InventoryObjectType.Atc:
            return { ...state, atc: { ...state.atc, ...errorSomethingStorageState(errorDescription) } };

        case InventoryObjectType.MST:
            return { ...state, mst: { ...state.mst, ...errorSomethingStorageState(errorDescription) } };

        case InventoryObjectType.SB:
            return { ...state, sensorboxes: { ...state.sensorboxes, ...errorSomethingStorageState(errorDescription) } };

        case InventoryObjectType.Tachymeter:
            return { ...state, tachy: { ...state.tachy, ...errorSomethingStorageState(errorDescription) } };

        case InventoryObjectType.LoRaGateway:
            return { ...state, loraGateway: { ...state.loraGateway, ...errorSomethingStorageState(errorDescription) } };
    }
}

export const dispatchDescriptionsLoadedAction = (state: IGeovisInventoryDataState, action: IGeovisInventoryAction): IGeovisInventoryDataState => {
    if (!action.stringProperty) {
        return state;
    }
    return { ...state, descriptions: processDescriptions(state.descriptions, action, action.stringProperty) };
}

export const dispatchDescriptionsErrorAction = (state: IGeovisInventoryDataState, { errorDescription }: IGeovisInventoryAction): IGeovisInventoryDataState => {
    return { ...state, descriptions: { ...state.descriptions, ...errorSomethingStorageState(errorDescription) } };
}

/**
 * Process data with filter actions
 * @param storage
 * @param action 
 */
const processDataWithFilterAction = <TData, TFilter, TStorage extends IGeovisInventoryObjectStorage<TData, TFilter>>(
    storage: TStorage,
    { data, filter, numberProperty }: IGeovisInventoryDataFilterAction<TData, TFilter>): TStorage => {

    if (!data) {
        return storage;
    }

    return {
        ...storage,
        ...loadedSomethingStorageState,
        data,
        filter: filter || {},
        projectId: numberProperty
    }
}

const processDescriptions = (storage: IGeovisInventoryDescriptionsStorage, { data }: IGeovisInventoryDataFilterAction<InventoryObjectDescription, null>, parentId: string): IGeovisInventoryDescriptionsStorage => {
    if (data) {
        if (parentId === "-") {
            return {
                ...storage,
                ...loadedSomethingStorageState,
                data
            }
        }
        const wholeRecords = storage.data.Records;
        const newRecords = data.Records;
        let wholeCount = storage.data.Total;
        newRecords.forEach(r => {
            if (wholeRecords.findIndex(tr => tr.Id === r.Id) < 0) {
                wholeRecords.push(r);
            }
        })
        wholeCount = wholeCount + data.Total;
        return {
            ...storage,
            ...loadedSomethingStorageState,
            data: {
                ...storage.data,
                Records: wholeRecords,
                Total: wholeCount
            }
        }
    }
    return storage;
}

const processDataUpdateAction = <TData extends InventoryObjectInfo, TStorage extends IGeovisInventoryObjectStorage<TData, {}>>(
    storage: TStorage,
    { inventoryObjectInfo }: IGeovisInventoryAction): TStorage => {

    if (!inventoryObjectInfo) {
        return storage;
    }

    return {
        ...storage,
        data: {
            Records: addOrUpdateElementInArray<TData>(storage.data.Records, r => r.Id === inventoryObjectInfo.Id, { ...({} as TData), ...inventoryObjectInfo }),
            Total: storage.data.Total
        }
    };
}

/**
 * Dispatch update record action
 * @param state 
 * @param action 
 */
export const dispatchInventoryUpdateAction = (state: IGeovisInventoryDataState, action: IGeovisInventoryAction): IGeovisInventoryDataState => {

    if (!action.inventoryObjectInfo) {
        return state;
    }

    switch (action.inventoryObjectInfo.ObjectType) {
        case InventoryObjectType.Afm:
            return { ...state, afm: processDataUpdateAction<InventoryAfmBoxInfo, IGeovisInventoryAfmStorage>(state.afm, action) };

        case InventoryObjectType.Atc:
            return { ...state, atc: processDataUpdateAction<InventoryAtcSensorInfo, IGeovisInventoryAtcStorage>(state.atc, action) };

        case InventoryObjectType.MST:
            return { ...state, mst: processDataUpdateAction<InventoryMstInfo, IGeovisInventoryMstStorage>(state.mst, action) };

        case InventoryObjectType.SB:
            return { ...state, sensorboxes: processDataUpdateAction<InventorySensorboxInfo, IGeovisInventorySensorboxStorage>(state.sensorboxes, action) };

        case InventoryObjectType.Tachymeter:
            return { ...state, tachy: processDataUpdateAction<InventoryTachymeterInfo, IGeovisInventoryTachymetersStorage>(state.tachy, action) };

        case InventoryObjectType.LoRaGateway:
            return { ...state, loraGateway: processDataUpdateAction<InventoryLoRaGatewayInfo, IGeovisInventoryLoRaGatewayStorage>(state.loraGateway, action) };
    }
}

export const dispatchInventoryRemoveAction = (state: IGeovisInventoryDataState, { objectType, objectId }: IGeovisInventoryAction): IGeovisInventoryDataState => {

    if (objectId === undefined || objectType === undefined) {
        return state;
    }

    switch (objectType) {
        case InventoryObjectType.Afm:
            return {
                ...state,
                afm: processRemoveElement<InventoryAfmBoxModel, IGeovisInventoryAfmStorage>(state.afm, objectId)
            };

        case InventoryObjectType.Atc:
            return {
                ...state,
                atc: processRemoveElement<InventoryAtcSensorModel, IGeovisInventoryAtcStorage>(state.atc, objectId)
            };

        case InventoryObjectType.MST:
            return {
                ...state,
                mst: processRemoveElement<InventoryMstModel, IGeovisInventoryMstStorage>(state.mst, objectId)
            };

        case InventoryObjectType.SB:
            return {
                ...state,
                sensorboxes: processRemoveElement<InventorySensorboxModel, IGeovisInventorySensorboxStorage>(state.sensorboxes, objectId)
            };

        case InventoryObjectType.Tachymeter:
            return {
                ...state,
                tachy: processRemoveElement<InventoryTachymeterModel, IGeovisInventoryTachymetersStorage>(state.tachy, objectId)
            };

        case InventoryObjectType.LoRaGateway:
            return {
                ...state,
                loraGateway: processRemoveElement<InventoryLoRaGatewayModel, IGeovisInventoryLoRaGatewayStorage>(state.loraGateway, objectId)
            };
    }
}

const processRemoveElement = <TData extends { Id: string }, TStorage extends IGeovisInventoryObjectStorage<TData, {}>>(storage: TStorage, id: string): TStorage => {

    const records = storage.data.Records.filter(r => r.Id !== id);

    return {
        ...storage,
        data: {
            Records: records,
            Total: records.length < storage.data.Records.length ? storage.data.Total - 1 : storage.data.Total
        }
    }

}

export const dispatchDataAddAction = (state: IGeovisInventoryDataState, action: IGeovisInventoryAction): IGeovisInventoryDataState => {
    if (action.inventoryObjectInfo === undefined) {
        return state;
    }

    const { ObjectType } = action.inventoryObjectInfo;
    switch (ObjectType) {
        case InventoryObjectType.Afm:
            return { ...state, afm: processDataAddAction<InventoryAfmBoxInfo, IGeovisInventoryAfmStorage>(state.afm, action, state.afm.filter) };

        case InventoryObjectType.Atc:
            return { ...state, atc: processDataAddAction<InventoryAtcSensorInfo, IGeovisInventoryAtcStorage>(state.atc, action, state.atc.filter) };

        case InventoryObjectType.MST:
            return { ...state, mst: processDataAddAction<InventoryMstInfo, IGeovisInventoryMstStorage>(state.mst, action, state.mst.filter) };

        case InventoryObjectType.SB:
            return { ...state, sensorboxes: processDataAddAction<InventorySensorboxInfo, IGeovisInventorySensorboxStorage>(state.sensorboxes, action, state.sensorboxes.filter) };

        case InventoryObjectType.Tachymeter:
            return { ...state, tachy: processDataAddAction<InventoryTachymeterInfo, IGeovisInventoryTachymetersStorage>(state.tachy, action, state.tachy.filter) };

        // Add LoRa gateway inventory is not allowed there yet
        case InventoryObjectType.LoRaGateway:
            return state;
    }
}

const processDataAddAction = <TData extends { Id: string }, TStorage extends IGeovisInventoryObjectStorage<TData, {}>>(storage: TStorage, { inventoryObjectInfo }: IGeovisInventoryAction, filter: Partial<InventoryFilterOptions>): TStorage => {

    if (!inventoryObjectInfo) {
        return storage;
    }

    const records = addOrUpdateElementInArray(storage.data.Records, o => o.Id === inventoryObjectInfo.Id, { ...({} as TData), ...inventoryObjectInfo });

    const sortFieldName = filter.SortFieldName ?? "SerialNumber";
    const isAscending = filter.IsAscending ?? true;

    const sortedRecords = records.sort((r1, r2) => {
        if (isAscending && r1[sortFieldName] > r2[sortFieldName] || !isAscending && r1[sortFieldName] < r2[sortFieldName]) {
            return 1;
        }
        if (isAscending && r1[sortFieldName] < r2[sortFieldName] || !isAscending && r1[sortFieldName] > r2[sortFieldName]) {
            return -1;
        }
        return 0;
    });

    return {
        ...storage,
        data: {
            Records: sortedRecords,
            Total: records.length,
        }
    }
}