/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 05.12.2022
 * @description Geovis4Table borders toolbar component
 */
// rc-tooltip don't work
// look https://mui.com/material-ui/react-tooltip/
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';

const DefaultDelay = 0.5;

interface IComponentProps {
    content: string;
    children: React.ReactElement;
    placement?: string;
    visible?: boolean;

    onVisibleChange?: (visible: boolean) => void;
}

export const GeovisTooltip = ({ visible, children, content, placement, onVisibleChange }: IComponentProps) => {

    const tooltipProps: Partial<IComponentProps> = {
        onVisibleChange,
        placement: placement || 'top'
    };

    // do not set property visible, leave it unset
    if (visible !== undefined) {
        tooltipProps.visible = visible
    }

    return (
        <Tooltip
            {...tooltipProps}
            trigger={['hover']}
            mouseEnterDelay={DefaultDelay}
            overlay={content}
            overlayInnerStyle={{ minHeight: '10px' }}>
            {children}
        </Tooltip>
    )
}