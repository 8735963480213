//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ServiceModel {
	None = 0,
	SM1 = 1,
	SM2 = 2,
	SM3 = 3,
	SM4 = 4,
	SM5 = 5
}

const ServiceModelToName = new Map<number, string>([
	[ 0, 'None' ],
	[ 1, 'SM1' ],
	[ 2, 'SM2' ],
	[ 3, 'SM3' ],
	[ 4, 'SM4' ],
	[ 5, 'SM5' ],
])

export function getServiceModelToName(element: ServiceModel) : string {
    return ServiceModelToName.get(element) || '';
}

const ServiceModelByName = new Map<string, number>([
	[ 'None', 0 ],
	[ 'SM1', 1 ],
	[ 'SM2', 2 ],
	[ 'SM3', 3 ],
	[ 'SM4', 4 ],
	[ 'SM5', 5 ],
]);

export function getServiceModelByName(name: string): ServiceModel {
    return ServiceModelByName.get(name)!;
}


export const ServiceModelList : Readonly<ServiceModel[]> = [
	ServiceModel.None,
	ServiceModel.SM1,
	ServiceModel.SM2,
	ServiceModel.SM3,
	ServiceModel.SM4,
	ServiceModel.SM5,
];



const ServiceModelToDescription = new Map<number, string>([
	[ 0, 'None' ],
	[ 1, 'SM1' ],
	[ 2, 'SM2' ],
	[ 3, 'SM3' ],
	[ 4, 'SM4' ],
	[ 5, 'SM5' ],
])

export function getServiceModelToDescription(element: ServiceModel) : string {
    return ServiceModelToDescription.get(element) || '';
}

