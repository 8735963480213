/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 22.09.2020
 * @description Types for message templates pages
 */

import { MessageTemplateFileInfo } from "../../../server/AVTService/TypeLibrary/MessageTemplates/MessageTemplateFileInfo";
import { MessageTemplateInfo } from "../../../server/AVTService/TypeLibrary/MessageTemplates/MessageTemplateInfo";
import { IRequestHelper } from "../../../services/RequestHelper";

export interface IMessageTemplateRouteParams {
    templateId?: string;
}

export enum MessageTemplateNotifyDialogMode {
    AllUsers = 'all_users',
    AllCompanyAdmins = 'all_company_admins',
    AllUsersOfProject = 'all_users_of_project',
    AllAdminsOfProject = 'all_admins_of_project',
    AllUsersOfCompany = 'all_users_of_company',
    AllCompanyAdminsOfCompany = 'all_company_admins_of_company',
    AllProjectAdminsAndViewers = 'all_project_admins_and_viewers',
    CustomSelection = 'custom_selection'
}

export interface IProcessDataTransferParam {
    server: IRequestHelper;
    templateInfo: MessageTemplateInfo;

    onAddAttachment: (templateId: string, attachment: MessageTemplateFileInfo) => void;
    onUploadFailed: (templateId: string, fileName: string, message: string) => void;
}