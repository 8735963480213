/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 07.07.2020
 * @description Map item maker stateful
 */

import 'leaflet.markercluster';
import React from 'react';
import { MaxMapTilesZoomLevel, MaxProfileViewZoomLevel } from '../../../components/map/types';
import { GEOvisDXFLayerType } from '../../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType';
import { IGeovisEventDispatcherAction } from '../../../services/GeovisEventDispatcher';
import Logger from '../../../services/Logger';
import { ISensorsSymbolsStorage } from '../../../store/data.types';

export interface IMapItemMarkerProps {
    projectId: number;
    sensorsSymbolsSettings: ISensorsSymbolsStorage;
    viewType: GEOvisDXFLayerType;
    invertXAxis: boolean;
    markerClusterGroup: L.MarkerClusterGroup | null;
}

const LoggerName = 'MapItemMarker';

export default abstract class MapItemMarker<TMapItemMarkerProps extends IMapItemMarkerProps> extends React.Component<TMapItemMarkerProps>{

    protected marker: L.Marker | null = null;
    private itemId: string = '';

    constructor(props: TMapItemMarkerProps) {
        super(props);
    }

    protected notMapView = () => this.props.viewType !== GEOvisDXFLayerType.Map;

    protected setMarkerRefFn = (itemId: string) => (marker: L.Marker | null) => {

        if (marker === null) {
            this.itemId = '';
        } else {
            this.itemId = itemId;
        }

        this.marker = marker;
    }

    protected geovisDispatchedEvent = (action: IGeovisEventDispatcherAction<string>) => {

        if (this.itemId === action.data) {
            Logger.trace(`dispatched action: ${action.actionId} has been processed`, LoggerName)
            this.onShowStackedItemPopup();
        }
    }

    private onShowStackedItemPopup = () => {
        if (!this.marker) {
            return
        }

        const { leafletMap } = this.marker.options as any;
        if (!leafletMap) {
            return;
        }

        const markerLeaflet = this.marker;
        if (!markerLeaflet) {
            return;
        }

        let origMaxZoom: number | undefined;
        const currentZoom = leafletMap.getZoom();
        if ((currentZoom < MaxMapTilesZoomLevel && !this.notMapView()) || (currentZoom < MaxProfileViewZoomLevel && this.notMapView())) {
            origMaxZoom = leafletMap.getMaxZoom();
            leafletMap.setMaxZoom(!this.notMapView() ? MaxMapTilesZoomLevel : MaxProfileViewZoomLevel);
        }

        const { markerClusterGroup } = this.props;
        if(markerClusterGroup){
            markerClusterGroup.zoomToShowLayerGeovisEdition(markerLeaflet, () => {
                markerLeaflet.openPopup();
            });
        }

        if (origMaxZoom) {
            leafletMap.setMaxZoom(origMaxZoom);
        }
    }

    public abstract render(): JSX.Element;
}

