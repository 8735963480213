/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 20.05.2022
 * @description Geovis report one page
 */

import React, { useEffect, useRef, useState } from 'react';
import { ReportElementType } from '../../../../server/AVTService/TypeLibrary/Common/ReportElementType';
import { GeovisReportElementInfo } from '../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo';
import { GeovisReportPage } from '../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportPage';
import { IGeovisVibrationEventSlimInfo } from '../../../../store/projectReport.types';
import { IMapSectionAreaSize } from '../../mapSection/tools';
import GeovisChartRenderDataLayer from './charts/GeovisChartRenderDataLayer';
import FooterRender from './FooterRender';
import HeaderRender from './header/HeaderRender';
import Geovis4TableReportDataLayer from './tables/Geovis4TableReport.DataLayer';
import Geovis4TableReportRenderer from './tables/Geovis4TableReport.Render';
import { HotTableRenderMode } from './tables/renderTypes';
import { printPageContentHeightInPx, printPageWidthInPx } from './tools';
import { IReportElementRenderOwnProps } from './types';
import { GeovisReportPageRenderFreeSpacer } from './GeovisReportPageRender.FreeSpace';
import GeovisReportPageRenderVibrationEvent from './GeovisReportPageRender.VibrationEvent';
import GeovisReportPageRenderProjectElements from './GeovisReportPageRender.ProjectElements';
import { GeovisMapSectionDataLayer } from './mapSection/data/GeovisMapSection.ReportDataLayer';
import { GeovisImageReportDataLayer } from './geovisImage/data/GeovisImage.ReportDataLayer';
import ServerRoutesGen from '../../../../server/Routes/ServerRoutesGen';
import { fetchServerElements } from '../../../../helpers/ProjectDataHelper';
import { GeovisReportCustomSettingsModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisReportCustomSettingsModel';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../helpers/GeovisHooks';
import { LoadingContainerSkeleton } from '../../../LoadingContainerSkeleton';
import GeovisLogbookReportDataLayer from './geovisLogbook/data/GeovisLogbook.ReportDataLayer';
import GeovisLogbookReportRender from './geovisLogbook/view/GeovisLogbookReportRender';

export const REPORT_CONTENT_HEIGHT = GeovisReportPage.CONTENT_HEIGHT;
// const REPORT_PAGE_CONTENT_BLOCK_ID = 'report-page-content-block';
export const REPORT_PAGE_CONTAINER_ID = "report-page-container-id";

interface IComponentProps extends IReportElementRenderOwnProps, IWithGeovisServerProps {
    page: GeovisReportPage;
    isLastPage: boolean;
    countPages: number;
    projectId: number;
    settingsId?: string;
}

interface IComponentState {
    isVibrationEventMode: boolean;
    elementId: number;
    eventInfo: IGeovisVibrationEventSlimInfo;
    settingsLoaded: boolean;
}

interface IReportPageRootContainer extends IMapSectionAreaSize {
    initialized: boolean;
    windowWidth: number;
}

const Component = ({
    page,
    isLastPage,
    countPages,
    reportId,
    userId,
    userToken,
    projectId,
    settingsId,
    pageNum,
    Server,
}: IComponentProps) => {

    const reportContentContainerRef = useRef<HTMLDivElement>(null);

    const isPrinting = userId !== undefined && userId !== "" && userToken !== undefined && userToken !== "";

    const [state, setState] = useState<IComponentState>({
        elementId: 0,
        isVibrationEventMode: false,
        eventInfo: { eventId: '', fullId: '', eventDate: '' },
        settingsLoaded: !isPrinting || settingsId === "none"
    });

    const onShowVibrationEvent = (chartId: number, eventId: string, fullId: string, eventDate: string) => {
        setState({ ...state, elementId: chartId, isVibrationEventMode: true, eventInfo: { eventId, fullId, eventDate } });
    }

    const onHideVibrationEvent = () => {
        setState({ ...state, elementId: 0, isVibrationEventMode: false, eventInfo: { eventId: '', fullId: '', eventDate: '' } });
    }

    if (page.Elements.length === 0) {
        return null;
    }

    // calc free space
    let busySpace = 0;
    {
        for (const element of page.Elements) {
            busySpace += element.Height;
        }
    }

    //const refPageContent = useRef<HTMLDivElement>(null);
    const [rootContainerSize, setRootContainerSize] = useState<IReportPageRootContainer>({
        initialized: false,
        height: printPageContentHeightInPx,
        width: printPageWidthInPx,
        windowWidth: window.innerWidth
    });

    const delay = (ms: number) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const getSettingsData = async () => {
        if (!settingsId || !userId || !userToken) {
            setState({
                ...state,
                settingsLoaded: true
            });
            return;
        }
        const url = ServerRoutesGen.ReportPdfRenderData.GetGeovisReportCustomSettings.patch(projectId, userId, userToken, settingsId);
        const response = await fetchServerElements<GeovisReportCustomSettingsModel>(Server, url);
        if (!response.Success || response.Success && response.Data === null) {
            setState({
                ...state,
                settingsLoaded: true
            });
            return;
        }

        const eventForPrint = response.Data.EventPrintInfo ? response.Data.EventPrintInfo.find(info => info.PageNumber === pageNum) : undefined;
        if (!eventForPrint) {
            setState({
                ...state,
                settingsLoaded: true
            });
            return;
        }

        setState({
            elementId: eventForPrint.EventChartId,
            eventInfo: {
                eventDate: eventForPrint.EventDate,
                eventId: eventForPrint.EventId,
                fullId: eventForPrint.FullId
            },
            isVibrationEventMode: true,
            settingsLoaded: true
        })
    }

    useEffect(() => {

        (async function init() {
            if (!isPrinting) {
                await delay(500); // without this delay we get wrong root container size 
            }

            if (isPrinting) {
                await getSettingsData();
            }

            if (reportContentContainerRef.current && reportContentContainerRef.current.clientWidth > 0) {
                const contState: IReportPageRootContainer = {
                    initialized: true,
                    height: reportContentContainerRef.current.clientHeight,
                    width: reportContentContainerRef.current.clientWidth,
                    windowWidth: window.innerWidth
                }
                if (!isPrinting) {
                    // we have to listen for the 'resize' event to catch the browser zoom in / zoom out events
                    // but we don't need in printing mode
                    resizeWindow();
                }
                else {
                    setRootContainerSize(contState);
                }

            }
        })();

        return () => window.removeEventListener("resize", resizeWindow);

    }, [1]);

    const resizeWindow = () => {
        window.addEventListener("resize", resizeWindow, { once: true });
        // setContainerSizeDebounced(contState, window.innerWidth);
        if (reportContentContainerRef.current && reportContentContainerRef.current.clientWidth > 0) {
            const contState: IReportPageRootContainer = {
                initialized: true,
                height: reportContentContainerRef.current.clientHeight,
                width: reportContentContainerRef.current.clientWidth,
                windowWidth: window.innerWidth
            }
            setRootContainerSize({
                ...contState
            })
        }

    };

    // const setContainerSizeDebounced = debounce((contState: IReportPageRootContainer, size: number) => {
    //     setRootContainerSize({
    //         ...contState,
    //         windowWidth: size
    //     })
    // }, 400)

    // vibration event chart should take all space on the page
    const freePageSpace = state.isVibrationEventMode ? 0 : REPORT_CONTENT_HEIGHT - busySpace;

    // const timestamp = (new Date()).toISOString();

    /**
     * Gets elements render data layers
     * @param element 
     * @returns 
     */
    const getElementRenderDataLayer = (element: GeovisReportElementInfo) => {

        if (element.ElementType === ReportElementType.GeovisTable) {
            return (
                <Geovis4TableReportDataLayer
                    key={`geovis4table-report-data-layer-${pageNum}-${element.ElementType}-${element.Id}`}
                    reportId={reportId}
                    userId={userId}
                    userToken={userToken}
                    pageNum={pageNum}
                    isPrinting={isPrinting}
                    tableId={element.Id}
                />
            )

        }

        if (element.ElementType === ReportElementType.Chart || element.ElementType === ReportElementType.GeovisChart) {
            return (
                <GeovisChartRenderDataLayer
                    key={`element-render-data-layer-${pageNum}-${element.ElementType}-${element.Id}`}
                    reportId={reportId}
                    userId={userId}
                    userToken={userToken}
                    isPrinting={isPrinting}
                    pageNum={pageNum}
                    eventInfo={state.eventInfo}
                    isVibrationEventChart={state.isVibrationEventMode}
                    chartId={element.Id}
                    isDefault={false}
                />
            )
        }

        if (element.ElementType === ReportElementType.MapSection) {
            return (
                <GeovisMapSectionDataLayer
                    key={`map-section-elements-data-layer-${pageNum}-${element.Id}`}
                    pageNum={pageNum}
                    projectId={projectId}
                    sectionId={element.Id}
                    isPrinting={isPrinting}
                    userId={userId}
                    userToken={userToken}
                    reportId={reportId}
                />
            )
        }

        if (element.ElementType === ReportElementType.GeovisImage) {
            return (
                <GeovisImageReportDataLayer
                    key={`geovis-image-elements-data-layer-${pageNum}-${element.Id}`}
                    pageNum={pageNum}
                    elementId={element.Id}
                    isPrinting={isPrinting}
                    userId={userId}
                    reportId={reportId}
                    userToken={userToken}
                />
            )
        }

        if (element.ElementType === ReportElementType.Logbook) {
            return (
                <GeovisLogbookReportDataLayer
                    key={`geovis-logbook-elements-data-layer-${pageNum}-${element.Id}`}
                    reportId={reportId}
                    pageNum={pageNum}
                    elementId={element.Id}
                    isPrinting={isPrinting}
                    userId={userId}
                    userToken={userToken} />
            )
        }

        return null;
    }

    const getDataLayersForElements = () => (
        <React.Fragment>
            {page.Elements.map((element, index) => {

                if (element.ElementType === ReportElementType.ElementsGroup) {
                    return (
                        <div key={`page-element-${index}`} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%' }}>
                            {element.Children.map(child => {
                                const childElement: GeovisReportElementInfo = { ...element, ...child };
                                return getElementRenderDataLayer(childElement)
                            })}
                        </div>

                    )
                }

                return getElementRenderDataLayer(element);
            })}
        </React.Fragment>
    )

    const logbook = page.Elements.find(e => e.ElementType === ReportElementType.Logbook);
    if (logbook) {
        return (
            <>
                <GeovisLogbookReportRender
                    isPrinting={isPrinting}
                    reportId={reportId}
                    userId={userId}
                    userToken={userToken}
                    elementId={logbook.Id}
                    reportPagesCount={countPages}
                    pageNum={pageNum}
                />

                {getDataLayersForElements()}
            </>
        )
    }

    const geovis4Table = page.Elements.find(e => e.ElementType === ReportElementType.GeovisTable);
    if (geovis4Table) {
        return (
            <div>
                <Geovis4TableReportRenderer
                    key={`geovis-4-table-${pageNum}-${geovis4Table.Id}`}
                    elementInfo={geovis4Table}
                    renderMode={isPrinting ? HotTableRenderMode.PDF : HotTableRenderMode.REPORT}
                    pageNum={pageNum}
                    reportPagesCount={countPages}
                    isLastReportPage={isLastPage}
                    reportId={reportId}
                    userId={userId}
                    userToken={userToken}
                />
                {getElementRenderDataLayer(geovis4Table)}
            </div>
        )
    }

    /**
     * Get vibration chart element info
     * @param pageToSearch 
     * @returns 
     */
    const getVibrationEventChartToDisplay = (pageToSearch: GeovisReportPage): GeovisReportElementInfo | undefined => {
        let elementToDisplay: GeovisReportElementInfo | undefined;

        pageToSearch.Elements.forEach(element => {

            if (element.ElementType === ReportElementType.ElementsGroup) {

                element.Children.forEach(child => {

                    if (child.Id === state.elementId && child.ElementType === ReportElementType.GeovisChart) {
                        const childElement: GeovisReportElementInfo = { ...element, ...child };
                        elementToDisplay = { ...childElement, Height: REPORT_CONTENT_HEIGHT };
                    }
                })
            }

            if (element.Id === state.elementId && element.ElementType === ReportElementType.GeovisChart) {
                elementToDisplay = { ...element, Height: REPORT_CONTENT_HEIGHT };
            }
        });

        return elementToDisplay;
    }

    if (!state.settingsLoaded) {
        return (
            <div>
                <LoadingContainerSkeleton />
            </div>
        )
    }

    return (
        <div className={isPrinting ? "reportContentContainerPrint" : "reportContentContainer"}>
            <div id={REPORT_PAGE_CONTAINER_ID} key={`geovis-charts-report-page-${pageNum}`} ref={reportContentContainerRef} className={"reportPageContainer"}>

                <HeaderRender isDefaultReport={false} />

                {/* it calls when page must show the Vibration event chart */}
                {state.isVibrationEventMode && (
                    <GeovisReportPageRenderVibrationEvent
                        key={`geovis-report-page-vibration-event-${state.eventInfo.eventId}`}
                        elementInfo={getVibrationEventChartToDisplay(page)}
                        eventInfo={state.eventInfo}
                        isPrinting={isPrinting}
                        onHideEvent={onHideVibrationEvent}
                        pageNum={pageNum}
                        reportId={reportId}
                        userId={userId}
                        userToken={userToken}
                        projectId={projectId}
                        settingsId={settingsId}
                    />
                )}

                {/* it calls when page must draw usual content, like Charts, MapSections and so on */}
                {!state.isVibrationEventMode && (
                    <GeovisReportPageRenderProjectElements
                        key={`report-page-elements-${pageNum}-${rootContainerSize.windowWidth}`}
                        elements={page.Elements}
                        isPrinting={isPrinting}
                        pageNum={pageNum}
                        reportId={reportId}
                        showVibrationEventChartOnPage={onShowVibrationEvent}
                        userId={userId}
                        userToken={userToken}
                        projectId={projectId}
                        settingsId={settingsId}
                        rootContainerWidth={rootContainerSize.width}
                    />
                )}

                <GeovisReportPageRenderFreeSpacer height={freePageSpace} />

                {getDataLayersForElements()}

                <FooterRender
                    countPages={countPages}
                    pageNumber={pageNum + 1}
                />
            </div>
        </div>
    )
}

export default withGeovisServer(Component);