import Button from "@atlaskit/button";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Routes from "../../helpers/Routes";
import { t } from "../../i18n";
import { newVersionNotified } from "../../store/creators/serviceWorkerCreators";
import { IGeovisAction, IGeovisServiceWorkerState } from "../../store/types";
import { isCacheVersionUpToDate } from "./tools";

const skipServiceWorkerWaiting = (serviceWorkerRegistration?: ServiceWorkerRegistration, reloadOnActivated?: boolean) => {
    let registrationWaiting: ServiceWorker | null = null;
    if (serviceWorkerRegistration) {
        registrationWaiting = serviceWorkerRegistration.waiting;
        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (e: any) => {
                if (e.target && e.target.state && e.target.state === 'activated') {
                    if (reloadOnActivated) {
                        window.location.reload();
                    }
                }
            });
        }
    }
}

const getClientVersion = (): string => {
    const clientVersionJson = require("../../version.json");
    return clientVersionJson.version;
}

const getVersionInfo = (isNewVersionWaiting: boolean) => {
    const clientVersion = getClientVersion();
    const versionInfo = isNewVersionWaiting
        ? t("newVersionAvailableLabel")
        : t("GEOvis4 has been updated to version %1");
    return versionInfo.replace("%1", clientVersion);
}

interface INewVersionNotificationProps {
    serviceWorkerState: IGeovisServiceWorkerState;
}

const NewVersionNotification = ({
    serviceWorkerState
}: INewVersionNotificationProps) => {
    const { toastId, serviceWorkerRegistration } = serviceWorkerState;
    const isNewVersionWaiting = serviceWorkerRegistration !== undefined && serviceWorkerRegistration.waiting !== null;

    const history = useHistory();

    const onUpdateButtonClick = () => {
        skipServiceWorkerWaiting(serviceWorkerRegistration, true);
        toast.dismiss(toastId);
    }

    const onReleaseNotesButtonClick = () => {
        toast.dismiss(toastId);
        skipServiceWorkerWaiting(serviceWorkerRegistration, false);
        history.push(Routes.releaseNotes.path);
    }

    return (
        <div>
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{t("Update")}</div>
            <div>{getVersionInfo(isNewVersionWaiting)}</div>
            <div>
                {isNewVersionWaiting && (
                    <Button
                        key="update-button"
                        style={{ backgroundColor: 'white', marginRight: '5px' }}
                        onClick={onUpdateButtonClick}>
                        {t("Update")}
                    </Button>
                )}
                <Button
                    key="release-notes-button"
                    style={{ backgroundColor: 'white' }}
                    onClick={onReleaseNotesButtonClick} >
                    {t("Show release notes & update")}
                </Button>
            </div>
        </div>
    )
}

interface INotificationProps {
    serverVersion: string;
    serviceWorkerState: IGeovisServiceWorkerState;
    dispatch: (action: IGeovisAction) => void;
}

export const notifyAboutNewVersion = async ({
    serverVersion,
    serviceWorkerState,
    dispatch
}: INotificationProps) => {
    const isUpToDate = await isCacheVersionUpToDate(serverVersion);
    if (isUpToDate) {
        const { serviceWorkerRegistration } = serviceWorkerState;
        skipServiceWorkerWaiting(serviceWorkerRegistration, false);
        dispatch(newVersionNotified(""));
    }
    else {
        const toastId = toast((<NewVersionNotification serviceWorkerState={serviceWorkerState} />), {
            position: 'bottom-right',
            hideProgressBar: true,
            autoClose: false,
            closeOnClick: false,
            style: { backgroundColor: 'rgb(41, 64, 80)', color: '#fff' }
        });
        dispatch(newVersionNotified(toastId));
    }
}