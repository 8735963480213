//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisCompanyType } from '../../../AVTService/TypeLibrary/Common/GeovisCompanyType';

export class CompanySlimInfo
{
	public Id: string;
	public Name: string;
	public CompanyType: GeovisCompanyType;
}
