import Highcharts, { XAxisPlotBandsOptions, YAxisPlotLinesOptions } from "highcharts";
import { formattedDateTime } from "../../../../../../helpers/DateHelper";
import { t } from "../../../../../../i18n";
import { getSensorValueAttributeByName, SensorValueAttribute } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { GeovisChartSlimConfig } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartSlimConfig";
import { XLabelModel } from "../../../../../../server/AVTService/TypeLibrary/Computation/XLabelModel";
import { XyChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/XyChartData";
import { XySeriaData } from "../../../../../../server/AVTService/TypeLibrary/Computation/XySeriaData";
import { XySeriaPoint } from "../../../../../../server/AVTService/TypeLibrary/Computation/XySeriaPoint";
import { ColorizableElement } from "../../../../../../server/AVTService/TypeLibrary/DB/ColorizableElement";
import { AlarmInfoRecord } from "../../../../../../server/AVTService/TypeLibrary/Model/AlarmInfoRecord";
import { XyChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { HtmlReportSensorDescriptionViewModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportSensorDescriptionViewModel";
import { getPhysicalUnitByName } from "../../../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { SensorSymbol } from "../../../../../../server/AVTService/TypeLibrary/Sensors/SensorSymbol";
import { createChartRenderedFunc } from "../../tools";
import { getRegressionSettings } from "../../tools.regression";
import {
    getAxisLabel,
    getAxisLimitValue,
    getCustomTooltipInfo,
    getDashStyleValue,
    getExtremumValue,
    getFirstDashStyleValueExcept,
    getGeovisChartSeriesLineWidth,
    getMarkerSymbol,
    getSeriesUnitShortName,
    getSoftLimitValue,
    getUnitShortName,
    getXAxisUnitsAnnotation,
    getYAxisOptions,
    getYAxisUnitsAnnotation,
    startEndOnTick
} from "./chartRenderOptions";
import { getBoostOptions } from "./tools";

const Color = require('color');

export interface IXyChartRenderSettings {
    chart: XyChartModel;
    chartDataSlimConfig: GeovisChartSlimConfig;
    onChartRender: () => void;
    seriesData: XySeriaData[];
    xLabels: XLabelModel[];
    startMeasurements: string;
    referenceDates: string[];
    descriptions: HtmlReportSensorDescriptionViewModel[];
    warningSensors: string[];
    linkedAlarmLines: AlarmInfoRecord[];
    visualSettings: ProjectVisualSettings;
    minXValue: number;
    minYValue: number;
    maxXValue: number;
    maxYValue: number;
}

export const getXyChartRenderOptions = (pageNum: number, chart: XyChartModel, chartData: XyChartData): Highcharts.Options => {

    const settingsToBeProcessed = getXyChartSingleValueTypeSettings(pageNum, chart, chartData);

    const options = getSingleValueTypeOptions(settingsToBeProcessed);

    return options;
}

const getXyChartSingleValueTypeSettings = (pageNum: number, chart: XyChartModel, chartData: XyChartData): IXySingleValueTypeSettings => {
    // common settings
    const settings: IXyChartRenderSettings = {
        chart,
        chartDataSlimConfig: chartData.SlimChartConfig,
        descriptions: chartData.Descriptions,
        startMeasurements: chartData.startMeasurementTime,
        referenceDates: chartData.ReferenceTimes,
        seriesData: chartData.Series,
        warningSensors: chartData.WarningSensors,
        xLabels: chartData.XLabels,
        linkedAlarmLines: chartData.LinkedAlarmLines,
        onChartRender: createChartRenderedFunc(pageNum, chart.Id),
        visualSettings: { ...chartData.visualSettings, chartLineWidth: `${chart.LeftYAxisSettings.LineWeight}px` },
        maxXValue: chartData.MaxX,
        maxYValue: chartData.MaxY,
        minXValue: chartData.MinX,
        minYValue: chartData.MinY
    }

    const yAxisValueType = getSensorValueAttributeByName(chart.LeftYAxisSettings.sensorValue);
    const xAxisValueType = getSensorValueAttributeByName(chart.XAxisSettings.sensorValue);

    return {
        ...settings,
        firstInList: true,
        lastInList: true,
        xAxisValueType,
        yAxisValueType,
    };
}

interface IXySingleValueTypeSettings extends IXyChartRenderSettings {
    firstInList: boolean;
    lastInList: boolean;
    yAxisValueType: SensorValueAttribute;
    xAxisValueType: SensorValueAttribute;
}

const getSingleValueTypeOptions = (settings: IXySingleValueTypeSettings): Highcharts.Options => {

    const {
        chart,
        onChartRender,
        lastInList,
        seriesData,
        yAxisValueType,
        xLabels,
        startMeasurements,
        referenceDates,
        descriptions,
        warningSensors,
        linkedAlarmLines,
        visualSettings,
        maxYValue,
        minYValue,
    } = settings;

    const series = getSeriesOptions(chart, seriesData, descriptions, visualSettings);

    const noData = series.length === 0;

    const annotations = getAlarmLinesAnnotations(chart.AlarmLines);
    annotations.push(getYAxisUnitsAnnotation(chart.LeftYAxisSettings, 0));
    annotations.push(getXAxisUnitsAnnotation(chart.XAxisSettings));

    const options: Highcharts.Options = {
        boost: getBoostOptions(chart.Type, settings.chartDataSlimConfig),
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        chart: {
            animation: false,
            zooming: {
                type: chart.IsChartZoomAllow ? 'xy' : undefined
            },
            reflow: true,
            showAxes: true,
            alignTicks: true,
            events: {
                render: lastInList ? onChartRender : undefined,
            }
        },
        title: {
            floating: false,
            text: "<div style='height:10px'></div>",
            useHTML: true
        },
        annotations,
        lang: {
            noData: `<div style='z-index:20'>${chart.LeftYAxisSettings.SensorIds.length > 0 || chart.SensorPairs.length > 0 ? t('No data is available in the chart') : t('No sensors selected for drawing the chart')}</div>`
        },
        noData: {
            useHTML: true
        },
        legend: {
            enabled: chart.ShowLegend.value && lastInList,
            reversed: true,
            itemStyle: {
                fontSize: "14px"
            }
        },
        xAxis: getXyChartXAxisOptions(chart, noData, settings),
        // yAxis: getYAxisOptions(chart, visualSettings, linkedAlarmLines, yAxisValueType, undefined, false),
        yAxis: getYAxisOptions({
            chart,
            drawAlarmLines: true,
            linkedAlarmLines,
            needSecondAxis: false,
            visualSettings,
            hideUnitsInLabel: false,
            primaryAxisValueType: yAxisValueType,
            secondaryAxisValueType: undefined,
            maxValue: getExtremumValue([maxYValue], 0),
            minValue: getExtremumValue([minYValue], 0),
            noData
        }),
        series,
        tooltip: getXyChartTooltipOptions(chart, settings),
        plotOptions: {
            line: {
                findNearestPointBy: 'xy'
            },
            series: {
                animation: false,
                events: {
                    legendItemClick() {
                        return false;
                    }
                }
            }
        },
    }

    const startMeasurementText = chart.ShowStartMeasurements ? t("Start of measurements") + ": " + (startMeasurements ? formattedDateTime(startMeasurements, "DD.MM.YYYY") : 'no data') : "";
    const referenceDateText = chart.ShowReferenceDate ? t("Date of reference") + ": " + (referenceDates.map(rd => formattedDateTime(rd, "DD.MM.YYYY HH:mm")).join('; ')) : "";
    const warningSensorsText = chart.DivideIntervalAlarmIfNoData && warningSensors.length > 0 ? t("Following sensors has no data") + ": " + (warningSensors.map(ws => getWarningSensorsName(descriptions, ws)).join('; ')) : "";

    if (startMeasurementText || referenceDateText || warningSensorsText) {

        const subtitleHtml = `${startMeasurementText ? startMeasurementText + "<br />" : ""} ${referenceDateText ? referenceDateText + "<br />" : ""} ${warningSensorsText ?? ""}`

        options.subtitle = {
            align: 'center',
            useHTML: true,
            text: subtitleHtml
        };
    }
    if (chart.XAxisSettings.reduceAxisLabelsCount) {
        options.xAxis = {
            ...options.xAxis,
            tickPositioner() {
                const positions: number[] = [];

                const sortLabelFunc = (a: XLabelModel, b: XLabelModel): number => {
                    if (a.Value === b.Value) {
                        return 0;
                    }

                    return a.Value > b.Value ? 1 : -1;
                }

                const sortedLabels = xLabels.sort((a, b) => sortLabelFunc(a, b));

                for (let index = chart.XAxisSettings.reduceAxisLabelsStartAt; index < xLabels.length; index += chart.XAxisSettings.reduceAxisLabelsInterval + 1) {
                    if (index >= 0) {
                        positions.push(sortedLabels[index].Value);
                    }
                }
                return positions;
            }
        }
    }

    const xAxisUnitAnnotation: Highcharts.AnnotationsOptions = {
        draggable: '',
        labels: [{
            backgroundColor: 'rgba(252, 255, 255, 0.0)',
            borderWidth: 0,
            point(annotation: any) {
                const xValue = annotation.chart.plotWidth - 10;
                const yValue = annotation.chart.plotHeight + 10;
                return {
                    x: xValue,
                    xAxis: null,
                    y: yValue,
                    yAxis: null
                }
            },
            text: `${chart.XAxisSettings.DestConverter.enable ? getUnitShortName(getPhysicalUnitByName(chart.XAxisSettings.DestConverter.unit)) : getUnitShortName(chart.XAxisSettings.Unit)}`
        }],
    }

    if (chart.XAxisSettings.showAxisLabelsUnit) {
        if (options.annotations) {
            options.annotations.push(xAxisUnitAnnotation);
        }
        else {
            options.annotations = [xAxisUnitAnnotation];
        }
    }

    return options;
}

const getXyChartXAxisOptions = (chart: XyChartModel, noData: boolean, { xAxisValueType, linkedAlarmLines, xLabels, visualSettings, maxXValue, minXValue, descriptions }: IXySingleValueTypeSettings): Highcharts.XAxisOptions => {

    const verticalAlarmLines = linkedAlarmLines.filter(al => al.swapXY);
    const plotLines = getXPlotLines(verticalAlarmLines);
    const plotBands = getXPlotBands(verticalAlarmLines);

    const xMin = getExtremumValue([minXValue], 0);
    const xMax = getExtremumValue([maxXValue], 0);

    const limitDelta = xMin !== undefined && xMax !== undefined ? xMax - xMin : undefined;

    return {
        title: {
            text: getAxisLabel(chart.XAxisSettings, xAxisValueType),
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            }
        },
        type: 'linear',
        crosshair: true,
        lineWidth: 2,
        lineColor: "black",
        minRange: 0.0001,
        minorTickInterval: 'auto',
        min: getAxisLimitValue(chart.XAxisSettings.minScaleLimit, false, noData, undefined, xMin, limitDelta),
        max: getAxisLimitValue(chart.XAxisSettings.maxScaleLimit, true, noData, undefined, xMax, limitDelta),
        softMax: getSoftLimitValue(chart.XAxisSettings.maxScaleLimit),
        softMin: getSoftLimitValue(chart.XAxisSettings.minScaleLimit),
        startOnTick: startEndOnTick(chart.XAxisSettings.minScaleLimit),
        endOnTick: startEndOnTick(chart.XAxisSettings.maxScaleLimit),
        plotLines,
        plotBands,
        tickPositioner() {
            const { tickPositions } = this;
            if (!tickPositions) {
                return [];
            }

            if (chart.XAxisSettings.ShowXAxisSensorName.value && !chart.UseXYAxisDataBasedOnDifferentSensors) {
                const positions: number[] = [];

                xLabels.forEach(xLabel => {
                    positions.push(xLabel.Value);
                })
                positions.sort();

                return positions;
            }

            return tickPositions;
        },
        labels: {
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            },
            useHTML: chart.XAxisSettings.ShowXAxisSensorName.value,
            autoRotation: undefined,
            rotation: chart.XAxisSettings.UseVerticalAxisLabels ? -45 : 0,
            formatter(ctx) {
                let labelValue = "";
                const ctxValue = ctx.pos.toFixed(chart.XAxisSettings.numberOfDigits.value);
                if (!chart.XAxisSettings.ShowXAxisSensorName.value || chart.UseXYAxisDataBasedOnDifferentSensors) {
                    labelValue = ctxValue;
                }
                else {
                    const label = xLabels.find(l => l.Value === ctx.pos);
                    labelValue = label ? `<div>${getNameByFullId(label.Label, descriptions)}</div>` : `<div>${ctxValue}</div>`;
                }

                return labelValue;
            }
        }
    }

}

const sortSeries = (a: XySeriaData, b: XySeriaData, descriptions: HtmlReportSensorDescriptionViewModel[]): number => {
    const aDescription = descriptions.find(d => d.id === a.SeriaId);
    const bDescription = descriptions.find(d => d.id === b.SeriaId);

    if (!aDescription || !bDescription) {
        return 0;
    }

    return aDescription.name === bDescription.name ? 0 : aDescription.name > bDescription.name ? 1 : -1;
}

const getSeriesOptions = (chart: XyChartModel, seriesData: XySeriaData[], descriptions: HtmlReportSensorDescriptionViewModel[], visualSettings: ProjectVisualSettings): Highcharts.SeriesOptionsType[] => {
    const series: Highcharts.SeriesOptionsType[] = [];

    const yAxisSettings = chart.LeftYAxisSettings;

    seriesData.sort((a, b) => sortSeries(a, b, descriptions)).forEach(sd => {

        const singleSeries = getXyChartDataSeriesOption(chart, sd, descriptions, visualSettings);

        series.unshift(singleSeries);

        if (yAxisSettings.ShowRegression !== undefined && yAxisSettings.ShowRegression.value) {
            const regressionSeriesSettings = getRegressionSettings(singleSeries, yAxisSettings.RegressionSettings);

            if (regressionSeriesSettings) {
                series.push(regressionSeriesSettings);
            }
        }
    });

    return series;
}

const getXyChartDataSeriesOption = (
    chart: XyChartModel,
    seriesData: XySeriaData,
    descriptions: HtmlReportSensorDescriptionViewModel[],
    visualSettings: ProjectVisualSettings): Highcharts.SeriesLineOptions | Highcharts.SeriesScatterOptions => {

    const { LeftYAxisSettings: yAxisSettings } = chart;
    const isReferenceSeria = seriesData.IsReference;

    // const yAxisValueType = getSensorValueAttributeByName(yAxisSettings.sensorValue);
    const yUseConversion = yAxisSettings.DestConverter.enable;
    const yMultiplier = yUseConversion ? yAxisSettings.DestConverter.multiplicatorValue : 1;

    // const xAxisValueType = getSensorValueAttributeByName(chart.XAxisSettings.sensorValue);
    const xUseConversion = chart.XAxisSettings.DestConverter.enable;
    const xMultiplier = xUseConversion ? chart.XAxisSettings.DestConverter.multiplicatorValue : 1;

    // const data = getSeriesData(chart, seriesData.Points, yAxisValueType, baseValueType, yMultiplier, xMultiplier);
    const data = getSeriaData(chart, seriesData.SeriaData, yMultiplier, xMultiplier, isReferenceSeria);
    const description = descriptions.find(de => de.id === seriesData.SeriaId);

    return {
        // little hint to avoid highcharts error #15 (unsorted data by X axis)
        // this error occurs only for line and stock charts
        // and scatter chart with lineWidth > 0 looks exactly the same
        // made for AGMS-3663
        type: chart.SortByXAxis ? "line" : 'scatter',
        name: isReferenceSeria
            ? `${formattedDateTime(seriesData.Time, "DD.MM.YYYY HH:mm")} (${t("Reference")})`
            : chart.LegendText
                ? chart.LegendText
                : chart.UseXYAxisDataBasedOnDifferentSensors
                    ? getPairName(seriesData.SeriaId, descriptions)
                    : formattedDateTime(seriesData.Time, "DD.MM.YYYY HH:mm"),
        color: seriesData.Color,
        lineWidth: chart.DrawLineBetweenPoints.value ? getGeovisChartSeriesLineWidth(visualSettings, description) : 0,
        dashStyle: isReferenceSeria ? getFirstDashStyleValueExcept(chart.LeftYAxisSettings.LineStyle) : getDashStyleValue(chart.LeftYAxisSettings.LineStyle),
        data,
        dataSorting: {
            enabled: false,
        },
        id: seriesData.SeriaId,
        custom: {
            seriaName: chart.UseXYAxisDataBasedOnDifferentSensors ? getPairName(seriesData.SeriaId, descriptions) : formattedDateTime(seriesData.Time, "DD.MM.YYYY HH:mm"),
            sensorId: chart.UseXYAxisDataBasedOnDifferentSensors ? "" : seriesData.SeriaId,
            isReference: isReferenceSeria
        },
        yAxis: 0,
        marker: {
            enabled: yAxisSettings.ShowPointSymbols.value,
            symbol: getMarkerSymbol({ ... new ColorizableElement(), Symbol: description ? description.symbol : SensorSymbol.Circle }),
            lineColor: seriesData.Color,
            lineWidth: 2
        },
        /* 
        threshold disabled, this option makes impossible to draw long time range with ~2000 points,
        chart just show "no data" message even data was delivered
        solution - disable turboThreshold for XyChart
        */
        // other possible thresholds, but they no any effect
        // threshold: null,
        // cropThreshold: undefined,
        // softThreshold: false,
        // boostThreshold: 0,
        turboThreshold: 0,
        /* end of threshold region */
        states: {
            hover: {
                lineWidthPlus: chart.DrawLineBetweenPoints.value ? 1 : 0
            }
        }
    }
}

const getNameByFullId = (fullId: string, descriptions: HtmlReportSensorDescriptionViewModel[]): string => {
    const sensor = descriptions.find(d => d.id === fullId);
    if (!sensor) {
        return fullId;
    }
    return sensor.name;
}

const getPairName = (pairId: string, descriptions: HtmlReportSensorDescriptionViewModel[]): string => {
    const splitedIds = pairId.split('/');
    let result: string = "";
    splitedIds.forEach((id, index) => {
        const sensor = descriptions.find(d => d.id.split(".").includes(id));
        // const sensor = descriptions.find(d => d.id === id);
        if (sensor) {
            result += sensor.name;
        }
        else {
            result += id;
        }
        if (index === 0) {
            result += "/";
        }
    });

    return result;
}

/**
 * Sort point by Y value
 * @param point1 
 * @param point2 
 * @returns 
 */
const sortPointsByY = (point1: Highcharts.PointOptionsObject, point2: Highcharts.PointOptionsObject): number => {
    if (point1.y === undefined || point2.y === undefined || point1.y === null || point2.y === null) {
        return 0;
    }
    return point1.y > point2.y ? 1 : -1;
}

/**
 * Sort points by X value
 * @param point1 
 * @param point2 
 * @returns 
 */
const sortPointsByX = (point1: Highcharts.PointOptionsObject, point2: Highcharts.PointOptionsObject): number => {
    if (point1.x === undefined || point2.x === undefined || point1.x === null || point2.x === null) {
        return 0;
    }
    return point1.x > point2.x ? 1 : -1;
}


const getSeriaData = (chart: XyChartModel, seriaData: XySeriaPoint[], yConvMult: number, xConvMult: number, isReference: boolean): Highcharts.PointOptionsObject[] => {
    const result: Highcharts.PointOptionsObject[] = [];

    const { UseXYAxisDataBasedOnDifferentSensors, SortByXAxis, DrawLineBetweenPoints } = chart;

    seriaData.forEach(sd => {
        result.push({
            x: sd.X * xConvMult,
            y: sd.Y * yConvMult,
            custom: {
                pointId: sd.PointId,
                pointTimeslot: sd.TimeSlot,
                isReference
            }
        })
    })

    // sort always when draw line between points even sensor pairs are selected
    if (DrawLineBetweenPoints || !UseXYAxisDataBasedOnDifferentSensors) {
        if (SortByXAxis) {
            return result.sort(sortPointsByX);
        } else {
            return result.sort(sortPointsByY);
        }
    }

    return result;
}



/**
 * Get plot lines options for linked alarm lines
 * @param alarmInfos
 * @returns Vertical plot lines options
 */
const getXPlotLines = (alarmInfos: AlarmInfoRecord[]): YAxisPlotLinesOptions[] => {
    const result: YAxisPlotLinesOptions[] = [];

    alarmInfos.forEach(ai => {
        if (ai.enabled) {
            result.push({
                value: +ai.yvalueFrom,
                color: ai.lineColor,
                dashStyle: getDashStyleValue(ai.lineType),
                width: ai.lineWeight * 2,
                zIndex: 3,
                label: {
                    text: ai.label,
                    style: {
                        color: ai.lineColor,
                        //border: 'black'
                    },
                    rotation: 0
                }
            })
        }
    });

    return result;
}

const getXPlotBands = (alarmInfos: AlarmInfoRecord[]): XAxisPlotBandsOptions[] => {
    const axisPlodBands: XAxisPlotBandsOptions[] = [];

    alarmInfos.forEach(ai => {
        const color = Color(ai.lineColor).alpha(0.4);
        if (ai.fillAlarmArea) {
            const plotArea: XAxisPlotBandsOptions = {
                from: ai.fillFrom,
                to: ai.fillTo,
                color: color.rgb().string(),
                zIndex: 3,
            }
            axisPlodBands.push(plotArea);
        }
    })

    return axisPlodBands;
}

/**
 * Get annotations for alarm lines with fixed start and end points
 * @param alarmInfos 
 * @returns 
 */
const getAlarmLinesAnnotations = (alarmInfos: AlarmInfoRecord[]): Highcharts.AnnotationsOptions[] => {
    const result: Highcharts.AnnotationsOptions[] = [];

    alarmInfos.forEach(ai => {
        if (ai.enabled) {
            result.push({
                draggable: '',
                shapes: ai.drawLine ? [{
                    type: 'path',
                    points: [{
                        x: +ai.xvalueFrom,
                        xAxis: 0,
                        y: +ai.yvalueFrom,
                        yAxis: 0
                    }, {
                        x: +ai.xvalueTo,
                        xAxis: 0,
                        y: +ai.yvalueTo,
                        yAxis: 0
                    }],
                    dashStyle: getDashStyleValue(ai.lineType),
                    width: 0,
                    fill: ai.lineColor,
                    stroke: ai.lineColor,
                    strokeWidth: ai.lineWeight,
                }] : [],
                labels: [{
                    point: {
                        x: +ai.xvalueTo,
                        xAxis: 0,
                        y: +ai.yvalueTo,
                        yAxis: 0
                    },
                    backgroundColor: 'rgba(252, 255, 255, 0.0)',
                    borderWidth: 0,
                    style: {
                        color: ai.lineColor
                    },
                    text: ai.label,
                    formatter() {
                        // From Highcharts documentation:
                        // "Note that if a `format` or `text` are defined, the format or text take precedence and the formatter is ignored"
                        // An alarm with no label is treated as no `text` that leads to call the formatter.
                        // See also "highcharts\es-modules\Extensions\Annotations\Controllables\ControllableLabel.js" 
                        // The default formatter return the y value in this case. 
                        // But we want no label in this case. That's why we need this hook.
                        return ai.label;
                    }
                }]
            })
        }
    })

    return result;
}

const getWarningSensorsName = (descriptions: HtmlReportSensorDescriptionViewModel[], sensorId: string): string => {
    const sensor = descriptions.find(des => des.id === sensorId);
    if (sensor) {
        return sensor.name;
    }
    return sensorId;
}

const getXyChartTooltipOptions = (chart: XyChartModel, { descriptions }: IXySingleValueTypeSettings): Highcharts.TooltipOptions => {
    return {
        outside: false,
        useHTML: true,
        shared: chart.ShowCommonTooltip.value,
        split: false,
        snap: 3,
        formatter() {
            if (this.x !== undefined && this.y !== undefined && this.x !== null && this.y !== null) {

                //#region Common tooltip
                if (chart.ShowCommonTooltip.value && this.points && this.points.length > 0) {
                    //const points = this.points ?? this.series.points;
                    const basePoint = this.points[0].point;
                    const pointId = basePoint.options.custom && basePoint.options.custom.pointId ? basePoint.options.custom.pointId : "";
                    const pointTimeString = basePoint.options.custom && basePoint.options.custom.pointTimeslot
                        ? formattedDateTime(basePoint.options.custom.pointTimeslot, "DD.MM.YYYY HH:mm")
                        : "";

                    const tooltipHeaderLine = chart.UseXYAxisDataBasedOnDifferentSensors
                        ? pointTimeString ?? ""
                        : getNameByFullId(pointId, descriptions);


                    let tooltipHtml = `<div> <span>${tooltipHeaderLine}</span></br>`;

                    this.points.forEach(point => {
                        if (point.x !== undefined && point.y !== undefined && point.y !== null) {
                            const p = point.point;
                            const pId = p.options.custom && p.options.custom.pointId ? p.options.custom.pointId : "";
                            const pTimeString = p.options.custom && p.options.custom.pointTimeslot
                                ? `${formattedDateTime(p.options.custom.pointTimeslot, "DD.MM.YYYY HH:mm")}${p.options.custom.isReference ? ` (${t("Reference")})` : ""}`
                                : "";
                            const pairName = chart.UseXYAxisDataBasedOnDifferentSensors ? getPairName(pId, descriptions) : "";
                            const seriesOptions = point.series.options;

                            const xStringValue = `${(+point.x).toFixed(chart.XAxisSettings.numberOfDigits.value)} ${getSeriesUnitShortName(chart, point.series.xAxis.side)}`;
                            const yStringValue = `${(+point.y).toFixed(chart.LeftYAxisSettings.numberOfDigits.value)} ${getSeriesUnitShortName(chart, point.series.yAxis.side)}`;

                            tooltipHtml += chart.UseXYAxisDataBasedOnDifferentSensors
                                ? `<span style="font-weight:700; color:${seriesOptions.color}">${pairName}:</span><span> X: ${xStringValue}; Y: ${yStringValue}</span></br>`
                                : `<span style="font-weight:700; color:${seriesOptions.color}">${pTimeString}:</span><span> X: ${xStringValue}; Y: ${yStringValue}</span></br>`;
                        }
                    });

                    tooltipHtml += "</div>";
                    return tooltipHtml;
                }
                //#endregion

                //#region Single tooltip
                else {
                    const seriesOptions = this.series.options;
                    const isRegression = seriesOptions.custom && seriesOptions.custom.regression;
                    let baseX = +this.x;
                    let baseY = +this.y;

                    const point: any = this.point;
                    if (isRegression) {
                        baseX = +point.custom.x;
                        baseY = +point.custom.y;
                    }
                    const pId = point.options.custom && point.options.custom.pointId ? point.options.custom.pointId : "";
                    const pTimeString = point.options.custom && point.options.custom.pointTimeslot ? formattedDateTime(point.options.custom.pointTimeslot, "DD.MM.YYYY HH:mm") : "";
                    const displayedName = chart.UseXYAxisDataBasedOnDifferentSensors
                        ? `${getPairName(pId, descriptions)}${point.options.custom.isReference ? ` (${t("Reference")})` : ""}`
                        : `${getNameByFullId(pId, descriptions)}${point.options.custom.isReference ? ` (${t("Reference")})` : ""}`;

                    const xStringValue = `${(baseX).toFixed(chart.XAxisSettings.numberOfDigits.value)} ${getSeriesUnitShortName(chart, point.series.xAxis.side)}`;
                    const yStringValue = `${(baseY).toFixed(chart.LeftYAxisSettings.numberOfDigits.value)} ${getSeriesUnitShortName(chart, point.series.yAxis.side)}`;

                    const customTooltipInfo = getCustomTooltipInfo(pId, descriptions);

                    const tooltipHtml = `<div><span>${pTimeString}</span></br>
                                        <span style="font-weight:700; color:${this.series.options.color}">${displayedName}</span></br>
                                        <span>X: ${xStringValue}</span></br>
                                        <span>Y: ${yStringValue}</span></br>
                                        ${chart.ShowCustomTooltip && !chart.UseXYAxisDataBasedOnDifferentSensors ? `<span>${customTooltipInfo}</span></br>` : ""}
                                        </div>`;

                    return tooltipHtml;
                }
                //#endregion

            }

            return undefined;
        }
    }
}