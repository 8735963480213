//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum Geovis4TableColumnsWidthMode {
	Manually = 0,
	StretchAll = 1,
	StretchLast = 2
}

const Geovis4TableColumnsWidthModeToName = new Map<number, string>([
	[ 0, 'Manually' ],
	[ 1, 'StretchAll' ],
	[ 2, 'StretchLast' ],
])

export function getGeovis4TableColumnsWidthModeToName(element: Geovis4TableColumnsWidthMode) : string {
    return Geovis4TableColumnsWidthModeToName.get(element) || '';
}

const Geovis4TableColumnsWidthModeByName = new Map<string, number>([
	[ 'Manually', 0 ],
	[ 'StretchAll', 1 ],
	[ 'StretchLast', 2 ],
]);

export function getGeovis4TableColumnsWidthModeByName(name: string): Geovis4TableColumnsWidthMode {
    return Geovis4TableColumnsWidthModeByName.get(name)!;
}


export const Geovis4TableColumnsWidthModeList : Readonly<Geovis4TableColumnsWidthMode[]> = [
	Geovis4TableColumnsWidthMode.Manually,
	Geovis4TableColumnsWidthMode.StretchAll,
	Geovis4TableColumnsWidthMode.StretchLast,
];



const Geovis4TableColumnsWidthModeToDescription = new Map<number, string>([
	[ 0, 'Manually' ],
	[ 1, 'Stretch all' ],
	[ 2, 'Stretch last column' ],
])

export function getGeovis4TableColumnsWidthModeToDescription(element: Geovis4TableColumnsWidthMode) : string {
    return Geovis4TableColumnsWidthModeToDescription.get(element) || '';
}

