import Spinner from '@atlaskit/spinner';

interface ILoadingContainerSkeletonProps {
    loadingText?: string;
    spinnerTestId?: string;
}

export const LoadingContainerSkeleton = ({ loadingText, spinnerTestId }: ILoadingContainerSkeletonProps) => {
    return (
        <div className="flexRowMiddleContainer" style={{ width: "100%", height: '100%' }}>
            <div className="skeletonContainer">
                <div style={{ display: "inline-block" }} >
                    <Spinner size="xlarge" testId={spinnerTestId} />
                </div>
                {loadingText && (
                    <span style={{ marginLeft: '20px' }}>{loadingText}</span>
                )}
            </div>
        </div>
    );
}