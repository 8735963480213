import Highcharts from "highcharts";

Highcharts.SVGRenderer.prototype.symbols.TriangleLeft = function(x, y, w, h){
    return ['M', x, y + h / 2.0, 'L', x + w, y, 'L', x + w, y + h, 'Z'];
}

Highcharts.SVGRenderer.prototype.symbols.TriangleRight = function(x, y, w, h){
    return ['M', x + w, y + h / 2.0, 'L', x, y, 'L', x, y + h,  'Z'];
}

Highcharts.SVGRenderer.prototype.symbols.Romb = function(x, y, w, h){
    const halfWidth = w / 2.0;
    const halfHeight = h / 2.0
    return ['M', x + 1, y + halfHeight, 'L', x + halfWidth, y, 'L', x + w - 1, y + halfHeight, 'L', x + halfWidth, y + h, 'Z']
}

Highcharts.SVGRenderer.prototype.symbols.Star = function(x, y, w, h){
    const width = 8;
    const halfWidth = width / 2.0;
    const height = 8;

    return ['M', x + halfWidth, y + 1, 
        'L', x + 2, y + height - 1, 
        'L', x + width - 1, y + 3 ,
        'L', x + 1, y + 3, 
        'L', x + width - 2, y + height - 1, 'Z'];
}

Highcharts.SVGRenderer.prototype.symbols.Plus = function(x, y, w, h){
    const halfWidth = w / 2.0;
    const width1 = halfWidth - 1;
    const width2 = halfWidth + 1;
    
    const halfHeight = h / 2.0;
    const height1 = halfHeight - 1;
    const height2 = halfHeight + 1;


    return ['M', x,      y + height1,
        'L', x + width1, y + height1,
        'L', x + width1, y,
        'L', x + width2, y,
        'L', x + width2, y + height1,
        'L', x + w,      y + height1,

        'L', x + w,      y + height2,
        'L', x + width2, y + height2, 
        'L', x + width2, y + h,
        'L', x + width1, y + h,
        'L', x + width1, y + height2,
        'L', x,          y + height2, 'Z'];
}

if (Highcharts.VMLRenderer) {
    Highcharts.VMLRenderer.prototype.symbols.TriangleLeft = Highcharts.SVGRenderer.prototype.symbols.TriangleLeft;
    Highcharts.VMLRenderer.prototype.symbols.TriangleRight = Highcharts.SVGRenderer.prototype.symbols.TriangleRight;
    Highcharts.VMLRenderer.prototype.symbols.Romb = Highcharts.SVGRenderer.prototype.symbols.Romb;
    Highcharts.VMLRenderer.prototype.symbols.Star = Highcharts.SVGRenderer.prototype.symbols.Star;
    Highcharts.VMLRenderer.prototype.symbols.Plus = Highcharts.SVGRenderer.prototype.symbols.Plus;
}