//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class EmailHistoryRequest
{
	public StartDate?: string;
	public EndDate?: string;
	public UserId?: string;
	public CompanyId?: string;
	public Recievers?: string[];
	public SubjectQuery?: string;
	public PageNumber?: number;
	public RowsPerPage?: number;
	public OrderBy?: string;
	public IsAscending: boolean;
}
