// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { Item, MenuSection } from '@atlaskit/navigation-next';
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import IRouteParams from '../../helpers/IRouteParams';
import { geovisMatchPath } from '../../helpers/NavigationHelper';
import Route from '../../helpers/Route';
import Routes from '../../helpers/Routes';
import i18next from '../../i18n';
import AuthService from '../../services/AuthService';
import { IGeovisAction } from "../../store/types";

interface IUsersAndCompaniesNavigationProps extends WithTranslation, RouteComponentProps<IRouteParams> {
    dispatch: (action: IGeovisAction) => void;
}

class UsersAndCompaniesNavigation extends React.Component<IUsersAndCompaniesNavigationProps> {
    constructor(props: IUsersAndCompaniesNavigationProps) {
        super(props);
    }

    public render = () => {
        return (
            <MenuSection>
                {({ className }: any) => (
                    <div className={className} style={{ marginTop: '15px' }}>
                        <Link
                            to={Routes.users.path}
                            style={{ textDecoration: 'none' }}>
                            <Item
                                key="users"
                                id="users"
                                isSelected={this.isRouteSelected(Routes.users)}
                                text={i18next.t("Users")}
                            />
                        </Link>
                        <Link
                            to={Routes.companies.path}
                            style={{ textDecoration: 'none' }}>
                            <Item
                                key="companies"
                                id="companies"
                                isSelected={this.isRouteSelected(Routes.companies)}
                                text={i18next.t("Companies")}
                            />
                        </Link>
                        {AuthService.hasUserTypeAsAdmin() && (
                            <div>
                                <Link
                                    to={Routes.partnerCompanyManager.path}
                                    style={{ textDecoration: 'none' }}>
                                    <Item
                                        key="manager"
                                        id="manager"
                                        isSelected={this.isRouteSelected(Routes.partnerCompanyManager)}
                                        text={i18next.t("Partner Company Manager")}
                                    />
                                </Link>
                                <Link
                                    to={Routes.exportUsersCompanies.path}
                                    style={{ textDecoration: 'none' }}>
                                    <Item
                                        key="export"
                                        id="export"
                                        isSelected={this.isRouteSelected(Routes.exportUsersCompanies)}
                                        text={i18next.t("Export Users / Companies")}
                                    />
                                </Link>
                                <Link
                                    to={Routes.importUsersCompanies.path}
                                    style={{ textDecoration: 'none' }}>
                                    <Item
                                        key="import"
                                        id="import"
                                        isSelected={this.isRouteSelected(Routes.importUsersCompanies)}
                                        text={i18next.t("Import User / Company Data")}
                                    />
                                </Link>
                            </div>
                        )}
                        {AuthService.hasUserTypeAsCompanyAdmin() && (
                            <div>
                                <Link
                                    to={Routes.messagesToUsers.path}
                                    style={{ textDecoration: 'none' }}>
                                    <Item
                                        key="send_message"
                                        id="send_message"
                                        isSelected={this.isRouteSelected(Routes.messagesToUsers)}
                                        text={i18next.t("Send message")}
                                    />
                                </Link>
                            </div>
                        )}
                    </div>
                )}
            </MenuSection>
        );
    }

    private isRouteSelected = (route: Route): boolean => {
        if (geovisMatchPath(this.props, route)) {
            return true;
        }

        return false;
    }
}

const UsersAndCompaniesNavigationComposed = compose(
    withTranslation(),
    withRouter
)(UsersAndCompaniesNavigation);

export default UsersAndCompaniesNavigationComposed;