
export enum SupportTemplateEditorMode {
    /** mode to view a template (default mode) */
    View,

    /** mode to create a new template */
    Create,

    /** mode to delete a template  */
    Delete,

    /** mode to edit a template */
    Edit
}