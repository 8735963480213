/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 20.12.2019
 * @description Project views common helper, some methods to generate Project View Tree Data
 */

import { ItemId, TreeData, TreeItem } from "@atlaskit/tree";
import { TreeItemMutation } from "@atlaskit/tree/dist/types/utils/mutateTree";
import { isSupportInternalOverlay } from "../components/localMapObjects/types";
import { getErrorProjectViewTreeData } from "../components/navigation/projectViews/ProjectViewsRender";
import { AttachedDocumentModel } from "../server/AttachedDocumentModel";
import { GEOvisDXFLayerType } from "../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { LocalMapObject } from "../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject";
import { LocalMapObjectType } from "../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectType";
import {
    SensorCategory,
    SensorCategoryOrdered
} from "../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { SensorManufacturer } from "../server/AVTService/TypeLibrary/Sensors/SensorManufacturer";
import { ChainInfo } from "../server/ChainInfo";
import { ProjectViewInfo } from "../server/GEOvis3/Model/ProjectViews/ProjectViewInfo";
import { SensorInfo } from "../server/GEOvis3/Model/SensorInfo";
import { DoubleRangeModel } from "../server/GEOvis3/Model/Sensors/DoubleRangeModel";
import { LocalMapObjectContent } from "../server/LocalMapObjectContent";
import { LocalMapObjectContentType } from "../server/LocalMapObjectContentType";
import { LocalMapObjectDocument } from "../server/LocalMapObjectDocument";
import { ProjectReportInfo } from "../server/ProjectReportInfo";
import AuthService from "../services/AuthService";
import {
    IAttachedDocumentsStorage,
    IChainsInfoStorage,
    ILocalMapObjectsStorage,
    IProjectViewFilter,
    IProjectViewsStorage,
    IReportsStorage,
    ISensorFilter,
    ISensorsInfoStorage
} from "../store/data.types";
import { ProjectViewOverviewId } from "../store/projectOverview.types";
import { ChainInfosCustomFilterFunc, isSensorWithCoordinates, SensorInfosCustomFilterFunc } from "./MapHelper";
import { getElementWithAllAlarmStates, getElementWithHighestAlarm, getProjectRoleAllowedSensors } from "./SensorHelper";
import { comparerFnOfElementsInTheProjectViewTree } from "./SortHelper";
import { mapToListOfElements, mapToListOfElementsOfIds } from "./StorageHelper";

const SensorTypePrefix = "sensorTypes";
const LocalMapObjectPrefix = "localMapObject";

/*
 * Special constant for inclinometers chain type in the filter
 * All sensor categories are passed by number, but special trick only for chain, its type passed by string
 */
export const InclinometerChainsType = -1;
export const InclinometerChainsStringType = 'Inclinometer chains';
export const InclinometerChainsPhysicalType = SensorCategory.Inclinometer;

export enum TreeItemContentType {
    Item,
    Sensors,
    Reports,
    Chains,
    LMOs
}

export interface ISensorGroupSelectionData {
    selected: boolean;
    groupId: string;
}

/**
 * Project tree data interface (by default in AtlasKit it has type "any")
 */
export interface IProjectViewTreeItemData {
    error?: Error;
    sensorInfo?: SensorInfo;
    localMapObject?: LocalMapObject;
    report?: ProjectReportInfo;
    chain?: ChainInfo;
    contentType: TreeItemContentType;
    numberOfChildren?: number;
}

/**
 * Get tree item data for sensor sensors group
 * @param sensorInfo
 * @param numberOfChildren number of children
 */
const getTreeItemSensorsGroupData = (sensorInfo: SensorInfo, numberOfChildren: number): IProjectViewTreeItemData => ({
    contentType: TreeItemContentType.Sensors,
    sensorInfo,
    numberOfChildren
});

/**
 * Get report tree item data
 * @param report 
 */
const getTreeItemReportData = (report: ProjectReportInfo): IProjectViewTreeItemData => ({
    contentType: TreeItemContentType.Item,
    report
});

/**
 * Get reports tree item data
 * @param numberOfChildren
 */
const getTreeItemReportsGroupData = (numberOfChildren: number): IProjectViewTreeItemData => ({
    contentType: TreeItemContentType.Reports,
    numberOfChildren
});

/**
 * Get LMO root tree item
 * @param numberOfChildren 
 */
const getTreeItemLocalMapObjectsGroupData = (numberOfChildren: number): IProjectViewTreeItemData => ({
    contentType: TreeItemContentType.LMOs,
    numberOfChildren
})

/**
 * Get the chains tree item data
 * @param chain 
 * @param numberOfChildren
 */
const getTreeItemChainsGroupData = (chain: ChainInfo, numberOfChildren: number): IProjectViewTreeItemData => ({
    contentType: TreeItemContentType.Chains,
    chain,
    numberOfChildren
})

/**
 * Get tree item error data from Error
 * @param error 
 */
export const getTreeItemErrorData = (error: Error): IProjectViewTreeItemData => ({
    error,
    contentType: TreeItemContentType.Item
});

/**
 * Get specific sensor info tree item data
 * @param sensorInfo 
 */
const getSensorInfoTreeItemData = (sensorInfo: SensorInfo): IProjectViewTreeItemData => ({
    sensorInfo,
    contentType: TreeItemContentType.Item
});

/**
 * Get specific chain info tree item data
 * @param chain 
 */
const getChainInfoTreeItemData = (chain: ChainInfo): IProjectViewTreeItemData => ({
    chain,
    contentType: TreeItemContentType.Item
});

/**
 * Get specific local map object tree item data
 * @param localMapObject 
 */
const getLocalMapObjectTreeItemData = (localMapObject: LocalMapObject): IProjectViewTreeItemData => ({
    localMapObject,
    contentType: TreeItemContentType.Item
});

interface ICreateProjectViewTreeDataProps {
    projectViewsStorage: IProjectViewsStorage;
    sensorsInfoStorage: ISensorsInfoStorage;
    chainsInfoStorage: IChainsInfoStorage;
    localMapObjectsStorage: ILocalMapObjectsStorage;
    reportsStorage: IReportsStorage;
    attachedDocumentsStorage: IAttachedDocumentsStorage;
    projectViewFilter: IProjectViewFilter;
}

/**
 * Create GEOvis project overview tree data
 * @param props
 */
export const createProjectViewTreeData = (props: ICreateProjectViewTreeDataProps): TreeData => {
    try {

        return createProjectViewTreeDataImpl(props);

    } catch (error) {
        // return { items: {}, rootId: '' };
        return getErrorProjectViewTreeData(error instanceof Error ? error : new Error(`${error}`));
    }
}

/**
 * Create project view tree data implementation
 * @param view - project view
 * @param dataState 
 * @param searchQuery
 */
const createProjectViewTreeDataImpl = ({
    projectViewsStorage: { viewId, projectViewsInfo, isLoading, expandInfo, },
    projectViewFilter: { searchElementQuery, sensorFilter },
    sensorsInfoStorage,
    chainsInfoStorage,
    localMapObjectsStorage,
    reportsStorage,
    attachedDocumentsStorage
}: ICreateProjectViewTreeDataProps): TreeData => {

    const view = projectViewsInfo.get(viewId);
    if (!view) {
        throw Error('Expected project view not found');
    }

    const rootId = `root_view_${view.Id}`;
    const result: TreeData = {
        "rootId": rootId,
        items: {}
    };

    result.items[rootId] = {
        id: rootId,
        isExpanded: true,
        children: []
    };

    // if isLoading, then return empty result
    if (isLoading) {
        return result;
    }

    const rootTreeItem = result.items[rootId];

    // mutation tree item as expanded if search query is not empty
    const mutation: TreeItemMutation | undefined = searchElementQuery ? { isExpanded: true } : undefined;

    // TODO: Add functionality for filter with more parameters

    // sensors
    createSensorCategoryTreeItemsOfView(result, rootTreeItem, view, sensorsInfoStorage, searchElementQuery, expandInfo, sensorFilter, mutation);

    // chains
    createChainsTreeItemsOfView(result, rootTreeItem, view, chainsInfoStorage, searchElementQuery, expandInfo, sensorFilter, mutation);

    // local map objects
    createLocalMapObjectTreeItemsOfView(result, rootTreeItem, view, sensorsInfoStorage, localMapObjectsStorage, attachedDocumentsStorage, searchElementQuery, expandInfo, sensorFilter, mutation);

    // reports
    createReportsTreeItemsOfView(result, rootTreeItem, view, reportsStorage, searchElementQuery, expandInfo, sensorFilter, mutation);

    return result;
}

/**
 * Filter sensors
 * @param searchQuery 
 * @param publicOnly return only public sensors
 * @param withCoordinatesOnly - true by default, if false, then return sensors without coordinates too
 */
export const getFilterOfSensorsFunc = (
    searchQuery: string | undefined,
    publicOnly: boolean,
    sensorsFilter: ISensorFilter,
    isNagraDistribution: boolean,
    showUnselected: boolean,
    withCoordinatesOnly: boolean = true,
    viewType?: GEOvisDXFLayerType,
    customFilter?: SensorInfosCustomFilterFunc) => (sensorInfo: SensorInfo): boolean => {

        if (publicOnly && !sensorInfo.IsPublic) {
            return false;
        }

        if (searchQuery) {
            const lowerQuery = searchQuery.toLowerCase();
            try {
                if (sensorInfo.Name.toLowerCase().search(lowerQuery) === -1) {
                    return false;
                }
            }
            catch {
                return false;
            }
        }

        // check type if needed
        if (sensorsFilter.sensorTypeFilter.length > 0 && !sensorsFilter.sensorTypeFilter.includes(sensorInfo.PhysicalType)) {
            return false;
        }
        // check unit if needed
        if (sensorsFilter.unitFilter.length > 0 && !sensorsFilter.unitFilter.includes(sensorInfo.Unit)) {
            return false;
        }

        if (sensorsFilter.databasesFilter.length > 0 && !sensorsFilter.databasesFilter.includes(sensorInfo.DatabaseId)) {
            return false;
        }

        if (isNagraDistribution
            && !checkSensorsForNagra(sensorInfo, sensorsFilter.manufacturerFilter, sensorsFilter.tunnelMeterFilter, sensorsFilter.angleFilter, sensorsFilter.radiusFilter, showUnselected)) {
            return false;
        }

        if (viewType !== undefined && viewType !== GEOvisDXFLayerType.Map) {
            // do not show sensors without loc coordinates in Nagra distribution mode
            if (isNagraDistribution && sensorInfo.LocX === 0 && sensorInfo.LocY === 0) {
                return false
            }

            // do not show sensors without reference coordinates in Amberg distribution mode
            if (!isNagraDistribution && (!sensorInfo.ActualAxisReference && sensorInfo.ActualAxisReference === null)) {
                return false;
            }
        }

        if (customFilter) {
            if (!customFilter(sensorInfo)) {
                return false;
            }
        }

        if (withCoordinatesOnly) {
            return isSensorWithCoordinates(sensorInfo);
        }

        return true;
    }

export const checkNonTextFilterSet = (sensorsFilter: ISensorFilter, isNagraDistribution: boolean): boolean => {

    if (sensorsFilter.sensorTypeFilter.length > 0 || sensorsFilter.unitFilter.length > 0) {
        return true;
    }

    if (isNagraDistribution) {
        return sensorsFilter.manufacturerFilter.length > 0 ||
            sensorsFilter.tunnelMeterFilter.Start !== undefined ||
            sensorsFilter.tunnelMeterFilter.End !== undefined ||
            sensorsFilter.angleFilter.Start !== undefined ||
            sensorsFilter.angleFilter.End !== undefined ||
            sensorsFilter.radiusFilter.Start !== undefined ||
            sensorsFilter.radiusFilter.End !== undefined;
    }

    return false;
}

const checkSensorsForNagra = (
    sensor: SensorInfo,
    manufacturerFilter: SensorManufacturer[],
    tunnelMeterFilter: DoubleRangeModel,
    angleFilter: DoubleRangeModel,
    radiusFilter: DoubleRangeModel,
    showUnselected: boolean): boolean => {

    if (!sensor.Selected && !showUnselected) {
        return false;
    }

    if (manufacturerFilter.length > 0 && !manufacturerFilter.includes(sensor.Manufacturer)) {
        return false;
    }

    if (tunnelMeterFilter.Start && tunnelMeterFilter.Start > sensor.TunnelMeter) {
        return false;
    }

    if (tunnelMeterFilter.End && tunnelMeterFilter.End < sensor.TunnelMeter) {
        return false;
    }

    if (!isAngleValueInRange(sensor.LocalAngle, angleFilter)) {
        return false;
    }

    if (radiusFilter.Start && radiusFilter.Start > sensor.LocalRadius) {
        return false;
    }

    if (radiusFilter.End && radiusFilter.End < sensor.LocalRadius) {
        return false;
    }

    return true;
}

const isAngleValueInRange = (angleValue: number, range: DoubleRangeModel): boolean => {
    // Only start filter
    if (range.Start !== undefined && range.End === undefined) {
        return angleValue >= range.Start;
    }

    // Only end filter
    if (range.Start === undefined && range.End !== undefined) {
        if (range.End < 0) {
            return angleValue >= 0 && angleValue <= (360 + range.End);
        }
        else {
            return angleValue <= range.End;
        }
    }

    // Both start and end filters
    if (range.Start !== undefined && range.End !== undefined) {
        if (range.Start > range.End) {
            return true; // if range is wrong value will pass it
        }
        else {
            if (range.Start >= 0 && range.End >= 0) {
                return angleValue >= range.Start && angleValue <= range.End; // Normal case
            }
            else if (range.Start < 0 && range.End >= 0) {
                return angleValue >= 360 + range.Start || angleValue >= 0 && angleValue <= range.End; // When start value less than 0, but End value greater or equal 0
            }
            else {
                return angleValue >= 360 + range.Start && angleValue <= 360 + range.End; // Both start and end negative
            }
        }
    }

    // No filters set
    return true;
}

/**
 * Create sensor items, grouped by sensor type
 * @param treeData the whole tree data
 * @param rootTreeItem the root tree item for this elements
 * @param view 
 * @param sensorsInfoStorage
 * @param searchQuery
 * @param expandInfo
 * @param mutation common mutation for all elements
 */
const createSensorCategoryTreeItemsOfView = (
    treeData: TreeData,
    rootTreeItem: TreeItem,
    view: ProjectViewInfo,
    sensorsInfoStorage: ISensorsInfoStorage,
    searchQuery: string,
    expandInfo: Map<ItemId, boolean>,
    nonTextFilters: ISensorFilter,
    mutation: TreeItemMutation | undefined): void => {

    if (sensorsInfoStorage.isLoading) {
        return;
    }

    const { ProjectId } = view;
    const isPublicOnly = AuthService.isActualViewerOfProject(ProjectId);
    const isNagraDistribution = AuthService.isNagraDistribution();

    const filterOfSensorsFunc = getFilterOfSensorsFunc(searchQuery, isPublicOnly, nonTextFilters, isNagraDistribution, true);
    const sensorsOfTheView = mapToListOfElements(sensorsInfoStorage.sensorsInfo, filterOfSensorsFunc);

    const allowedSensors = getProjectRoleAllowedSensors(ProjectId, sensorsOfTheView);

    createSensorCategoryTreeItems(SensorTypePrefix, view, allowedSensors, expandInfo, treeData, rootTreeItem, mutation);
}

/**
 * Create groups for sensors info by sensor category (sensor type)
 * @param idPrefix 
 * @param sensorsInfo 
 * @param expandInfo
 * @param treeData
 * @param rootTreeItem
 * @param mutation special mutation, which applies for all tree items
 */
const createSensorCategoryTreeItems = (idPrefix: ItemId, view: ProjectViewInfo, sensorsInfo: SensorInfo[], expandInfo: Map<ItemId, boolean>, treeData: TreeData, rootTreeItem: TreeItem, mutation: TreeItemMutation | undefined): void => {
    const sensorsByTypeMap: any = {};

    // group sensors by physical type
    sensorsInfo.forEach(sensorInfo => {
        const physType = sensorInfo.PhysicalType;

        // const physType = sensorInfo.PhysicalType;

        if (!sensorsByTypeMap[physType]) {
            sensorsByTypeMap[physType] = [];
        }

        sensorsByTypeMap[physType].push(sensorInfo);
    });

    for (const category of SensorCategoryOrdered) {
        if (!sensorsByTypeMap[category]) {
            continue;
        }

        const sensorsOfType = sensorsByTypeMap[category] as SensorInfo[];
        if (!sensorsOfType) {
            continue;
        }

        const rootSensorInfo = getSensorsGroupRootInfo(category, sensorsOfType, getElementWithAllAlarmStates(view.ProjectId, sensorsOfType));
        const sensorCategoryNode = createSensorCategoryTreeItem(idPrefix, category, rootSensorInfo, expandInfo, sensorsOfType.length, mutation);
        const sensorsInfoNodes = createSensorInfoTreeItems(sensorCategoryNode.id, sensorsOfType, expandInfo, mutation);

        // store children items to root category node
        sensorCategoryNode.children.push(...sensorsInfoNodes.map(n => n.id));
        sensorCategoryNode.hasChildren = sensorsInfoNodes.length > 0;

        // store in result
        rootTreeItem.children.push(sensorCategoryNode.id);
        treeData.items[sensorCategoryNode.id] = sensorCategoryNode;

        sensorsInfoNodes.forEach(sensorInfoTreeItem => {
            treeData.items[sensorInfoTreeItem.id] = sensorInfoTreeItem;
        });
    }

    // update children's property of the root node
    rootTreeItem.hasChildren = rootTreeItem.children.length > 0;
}
/**
 * Get sensor group root info, within included alarm state
 * @param category 
 * @param sensorsOfType - it is needed to set correct color for root node 
 * @param dangerousSensorInfo 
 */
const getSensorsGroupRootInfo = (category: SensorCategory, sensorsOfType: SensorInfo[], dangerousSensorInfo: SensorInfo | false): SensorInfo => {

    if (dangerousSensorInfo) {
        return setGroupSelection({ ...dangerousSensorInfo }, sensorsOfType);
    }

    if (sensorsOfType.length === 1) {
        return setGroupSelection({ ...sensorsOfType[0] }, sensorsOfType);
    }

    const sensorWithActiveWD = sensorsOfType.find(s => s.WatchdogEnabled);
    if (sensorWithActiveWD) {
        return setGroupSelection({ ...sensorWithActiveWD }, sensorsOfType);
    }

    const sensorInfo = new SensorInfo();
    sensorInfo.PhysicalType = category;

    if (category === SensorCategory.AgmsVirtualSensor) {
        sensorInfo.PhysicalType = SensorCategory.Prism;
    }

    return setGroupSelection(sensorInfo, sensorsOfType);
}

const setGroupSelection = (sensorInfo: SensorInfo, sensorsOfType: SensorInfo[]): SensorInfo => {
    if (!AuthService.isNagraDistribution()) {
        return sensorInfo;
    }
    const selectedSensorsCount = sensorsOfType.filter(s => s.Selected).length;
    sensorInfo.SelectedSensorsCount = selectedSensorsCount;
    sensorInfo.Selected = selectedSensorsCount > 0;

    return sensorInfo;
}

/**
 * Create sensor category tree item (root tree item for sensors with the same type)
 * @param idPrefix 
 * @param sensorInfo 
 * @param expandInfo
 * @param numberSensorsOfGroup
 * @param mutation tree item mutation
 */
const createSensorCategoryTreeItem = (idPrefix: ItemId, category: SensorCategory, sensorInfo: SensorInfo, expandInfo: Map<ItemId, boolean>, numberSensorsOfGroup: number, mutation: TreeItemMutation | undefined): TreeItem => {

    const id = `${idPrefix}_${category}`;
    return {
        isExpanded: expandInfo.get(id),
        ...mutation,
        id,
        children: [],
        data: getTreeItemSensorsGroupData(sensorInfo, numberSensorsOfGroup),
    };
}

/**
 * Create sensors info tree items
 * @param idPrefix 
 * @param sensorsInfo 
 * @param expandInfo
 * @param mutation Tree item mutation
 */
const createSensorInfoTreeItems = (idPrefix: ItemId, sensorsInfo: SensorInfo[], expandInfo: Map<ItemId, boolean>, mutation: TreeItemMutation | undefined): TreeItem[] =>
    sensorsInfo
        .sort(comparerFnOfElementsInTheProjectViewTree)
        .map<TreeItem>(info => createSensorInfoTreeItem(idPrefix, info, expandInfo, mutation));

/**
 * Get sensor info tree item
 * @param idPrefix 
 * @param sensorInfo 
 * @param expandInfo
 * @param mutation Tree item mutation
 */
const createSensorInfoTreeItem = (idPrefix: ItemId, sensorInfo: SensorInfo, expandInfo: Map<ItemId, boolean>, mutation: TreeItemMutation | undefined): TreeItem => {

    const id = `${idPrefix}_${sensorInfo.Id}`;
    return {
        isExpanded: expandInfo.get(id),
        ...mutation,
        id,
        children: [],
        data: getSensorInfoTreeItemData(sensorInfo)
    };
}

/**
 * Create filter function for local map object
 * @param sensorsInfoStorage sensors info storage
 * @param searchQuery the search query
 * @param isPublic
 * @param attachedDocumentsStorage
 */
export const getFilterOfLocalMapObjectsFunc = (
    sensorsInfoStorage: ISensorsInfoStorage,
    searchQuery: string,
    isPublic: boolean,
    attachedDocumentsStorage: IAttachedDocumentsStorage,
    nonTextFilters: ISensorFilter,) => (localMapObject: LocalMapObject): boolean => {

        const lmoContent = localMapObject as LocalMapObjectContent;
        let document: AttachedDocumentModel | undefined;
        // const document = lmoContent.ContentType === LocalMapObjectContentType.Document ? documentStorage.documents.get(lmoContent.DocumentId) : false;
        // const documentPublic = document ? document.IsPublic : false;

        // if (((!localMapObject.IsPublic && !document) || (document && !documentPublic)) && publicOnly) {
        //     return false;
        // }

        if (localMapObject.ObjectType === LocalMapObjectType.Document) {

            const docLmo = localMapObject as LocalMapObjectDocument;
            document = attachedDocumentsStorage.documents.get(docLmo.DocumentId);
            // document without document will ve shown only for project-admin

            if (!document && isPublic) {
                return false;
            }
            // documents with private document will be visible only for project-admin
            else if (document && !document.IsPublic && isPublic) {
                return false;
            }
        }
        else if (lmoContent.ContentType === LocalMapObjectContentType.Document) {

            document = attachedDocumentsStorage.documents.get(lmoContent.DocumentId);
            // document without document will ve shown only for project-admin
            if (!document && isPublic) {
                return false;
            }
            // documents with private document will be visible only for project-admin
            else if (document && !document.IsPublic && isPublic) {
                return false;
            }

        }
        else if (!localMapObject.IsPublic && isPublic) {
            return false;
        }

        if (searchQuery) {

            const lowerQuery = searchQuery.toLowerCase();
            // check the internal background overlay of the LMO, if any sensors is match to search string, 
            // then approve this LMO to be visible in the Tree

            if (isSupportInternalOverlay(localMapObject) && sensorsInfoStorage.isLoaded) {
                const backgroundOverlay = localMapObject as LocalMapObjectContent;

                if (backgroundOverlay && backgroundOverlay.Sensors) {
                    const isNagraDistribution = AuthService.isNagraDistribution();
                    const filterSensorsFunc = getFilterOfSensorsFunc(searchQuery, isPublic, nonTextFilters, isNagraDistribution, true);
                    const sensorIds = backgroundOverlay.Sensors.map(s => s.FullId);
                    const matchedSensors = mapToListOfElementsOfIds(sensorsInfoStorage.sensorsInfo, sensorIds, filterSensorsFunc);

                    if (matchedSensors.length > 0) {
                        return true;
                    }
                }
            }
            try {
                return localMapObject.Name.toLowerCase().search(lowerQuery) > -1;
            } catch (error) {
                return false;
            }

        }

        return true;
    }

/**
 * Create local map objects tree items
 * @param treeData
 * @param rootTreeItem
 * @param view 
 * @param sensorsInfoStorage
 * @param localMapObjectsDataStorage
 * @param searchQuery
 * @param expandInfo
 * @param mutation
 */
const createLocalMapObjectTreeItemsOfView = (
    treeData: TreeData,
    rootTreeItem: TreeItem,
    view: ProjectViewInfo,
    sensorsInfoStorage: ISensorsInfoStorage,
    localMapObjectsDataStorage: ILocalMapObjectsStorage,
    attachedDocumentsStorage: IAttachedDocumentsStorage,
    searchQuery: string,
    expandInfo: Map<ItemId, boolean>,
    nonTextFilters: ISensorFilter,
    mutation: TreeItemMutation | undefined): void => {

    const { isLoading: sensorsIsLoading } = sensorsInfoStorage;
    const { isLoading: lmoIsLoading, localMapObjects, localMapObjectSensorsInfo } = localMapObjectsDataStorage;

    if (lmoIsLoading || sensorsIsLoading) {
        return;
    }

    const { ProjectId } = view;
    const isPublicOnly = AuthService.isActualViewerOfProject(ProjectId);

    const filterLocalMapObjectFunc = getFilterOfLocalMapObjectsFunc(sensorsInfoStorage, searchQuery, isPublicOnly, attachedDocumentsStorage, nonTextFilters);
    const objects = mapToListOfElements(localMapObjects, filterLocalMapObjectFunc).sort((a, b) => a.Name.localeCompare(b.Name));

    if (objects.length === 0) {
        return;
    }

    const isNagraDistribution = AuthService.isNagraDistribution();
    const lmoRootTreeItem = createLocalMapObjectsTreeItem(LocalMapObjectPrefix, objects.length, expandInfo, mutation);
    const filterSensorsFunc = getFilterOfSensorsFunc(searchQuery, false, nonTextFilters, isNagraDistribution, true, false);

    objects.forEach(localMapObject => {

        const lmoTreeItem = createLocalMapObjectTreeItem(LocalMapObjectPrefix, localMapObject, expandInfo, mutation);
        lmoRootTreeItem.children.push(lmoTreeItem.id);
        treeData.items[lmoTreeItem.id] = lmoTreeItem;

        if (isSupportInternalOverlay(localMapObject)) {
            const lmoSensorsInfo = localMapObjectSensorsInfo.get(localMapObject.Id);

            if (lmoSensorsInfo && lmoSensorsInfo.SensorsInfo.length > 0) {

                const lmoSensorsGroupPrefix = lmoTreeItem.id + '_sensorsGroup';
                const lmoSensors = lmoSensorsInfo.SensorsInfo.filter(filterSensorsFunc);
                createSensorCategoryTreeItems(lmoSensorsGroupPrefix, view, lmoSensors, expandInfo, treeData, lmoTreeItem, mutation);
            }
        }
    });

    lmoRootTreeItem.hasChildren = lmoRootTreeItem.children.length > 0;
    rootTreeItem.children.push(lmoRootTreeItem.id);
    treeData.items[lmoRootTreeItem.id] = lmoRootTreeItem;

    // update "hasChildren" for root tree item
    rootTreeItem.hasChildren = rootTreeItem.children.length > 0;
}

/**
 * Create local map object tree item
 * @param idPrefix 
 * @param localMapObject 
 * @param expandInfo
 * @param mutation
 */
const createLocalMapObjectTreeItem = (idPrefix: string, localMapObject: LocalMapObject, expandInfo: Map<ItemId, boolean>, mutation: TreeItemMutation | undefined): TreeItem => {

    const id = `${idPrefix}_${localMapObject.Id}`;
    return {
        ...mutation,
        id,
        children: [],
        data: getLocalMapObjectTreeItemData(localMapObject),
        isExpanded: expandInfo.get(id)
    }
}

/**
 * Create local map objects group tree item
 * @param idPrefix 
 * @param numberOfChildren
 * @param expandInfo
 * @param mutation
 */
const createLocalMapObjectsTreeItem = (idPrefix: string, numberOfChildren: number, expandInfo: Map<ItemId, boolean>, mutation: TreeItemMutation | undefined): TreeItem => {
    const id = `${idPrefix}_group`;

    return {
        isExpanded: expandInfo.get(id),
        ...mutation,
        id,
        children: [],
        data: getTreeItemLocalMapObjectsGroupData(numberOfChildren)
    };
}

/**
 * Create report tree item
 * @param idPrefix 
 * @param report 
 * @param expandInfo
 */
const createReportTreeItem = (idPrefix: ItemId, report: ProjectReportInfo, expandInfo: Map<ItemId, boolean>): TreeItem => {
    const id = `${idPrefix}_${report.Id}`;
    return {
        id,
        children: [],
        data: getTreeItemReportData(report),
        isExpanded: expandInfo.get(id)
    };
}

/**
 * Create reports group tree item
 * @param idPrefix 
 * @param numberOfChildren
 * @param expandInfo
 * @param mutation
 */
const createReportsTreeItem = (idPrefix: string, numberOfChildren: number, expandInfo: Map<ItemId, boolean>, mutation: TreeItemMutation | undefined): TreeItem => {
    const id = `${idPrefix}_group`;
    return {
        isExpanded: expandInfo.get(id),
        ...mutation,
        id,
        children: [],
        data: getTreeItemReportsGroupData(numberOfChildren)
    };
}


const getFilterOfReportsFunc = (viewId: string, searchQuery: string, projectId: number, nonTextFilter: ISensorFilter) => (report: ProjectReportInfo): boolean => {
    const isAdminOfProject = AuthService.isActualAdminOfProject(projectId);

    if (searchQuery) {

        const lowerQuery = searchQuery.toLowerCase();
        const name = report.Title || report.Name;

        try {
            return name.toLowerCase().search(lowerQuery) > -1;
        }
        catch {
            return false;
        }
    }

    if (!isAdminOfProject && !report.IsPublic) {
        return false;
    }

    if (nonTextFilter.angleFilter.Start !== undefined
        || nonTextFilter.angleFilter.End !== undefined
        || nonTextFilter.manufacturerFilter.length > 0
        || nonTextFilter.radiusFilter.Start !== undefined
        || nonTextFilter.radiusFilter.End !== undefined
        || nonTextFilter.sensorTypeFilter.length > 0
        || nonTextFilter.tunnelMeterFilter.Start !== undefined
        || nonTextFilter.tunnelMeterFilter.End !== undefined
        || nonTextFilter.unitFilter.length > 0) {
        return false;
    }

    if (isProjectViewOverviewId(viewId)) {
        return true;
    }

    return true;
}

/**
 * Create project view reports tree items
 * @param treeData 
 * @param rootTreeItem 
 * @param view 
 * @param reportsStorage
 * @param searchQuery
 * @param expandInfo
 * @param mutation
 */
const createReportsTreeItemsOfView = (
    treeData: TreeData,
    rootTreeItem: TreeItem,
    { Id: viewId, ProjectId }: ProjectViewInfo,
    { isLoading, reports }: IReportsStorage,
    searchQuery: string,
    expandInfo: Map<ItemId, boolean>,
    nonTextFilter: ISensorFilter,
    mutation: TreeItemMutation | undefined): void => {

    if (isLoading) {
        return;
    }


    // if view has empty id (selected "Overview"), then pass all reports
    // otherwise show only view reports
    const filterReportsFunc = getFilterOfReportsFunc(viewId, searchQuery, ProjectId, nonTextFilter);
    const reportsToDraw = mapToListOfElements(reports, filterReportsFunc);

    if (reportsToDraw.length === 0) {
        return;
    }

    const reportsToDrawSorted = reportsToDraw.sort((a, b) => a.Title.localeCompare(b.Title))

    const ReportsPrefix = "reports";
    const reportsRootTreeItem = createReportsTreeItem(ReportsPrefix, reportsToDrawSorted.length, expandInfo, mutation);
    const reportTreeItems = reportsToDrawSorted.map<TreeItem>(report => createReportTreeItem(reportsRootTreeItem.id, report, expandInfo));

    reportsRootTreeItem.children.push(...reportTreeItems.map(rt => rt.id));
    reportsRootTreeItem.hasChildren = true;

    rootTreeItem.children.push(reportsRootTreeItem.id);
    treeData.items[reportsRootTreeItem.id] = reportsRootTreeItem;

    reportTreeItems.forEach(item => {
        treeData.items[item.id] = item;
    });
}

export const getFilterOfChainsFunc = (
    searchQuery: string | undefined,
    publicOnly: boolean,
    nonTextFilter: ISensorFilter,
    viewType?: GEOvisDXFLayerType,
    customFilter?: ChainInfosCustomFilterFunc) => (chain: ChainInfo): boolean => {
        if (publicOnly && !chain.IsPublic) {
            return false;
        }

        if (searchQuery) {
            const lowerQuery = searchQuery.toLowerCase();

            try {
                return chain.Name.toLowerCase().search(lowerQuery) > -1;
            }
            catch {
                return false;
            }
        }

        // following code is exact trick with string type of sensor
        // this is implemented in right way, no errors
        {
            const sensorTypeFilter: any[] = nonTextFilter.sensorTypeFilter;

            if (nonTextFilter.sensorTypeFilter.length > 0 && !sensorTypeFilter.includes(InclinometerChainsStringType)) {
                return false;
            }
        }

        if (nonTextFilter.angleFilter.Start !== undefined
            || nonTextFilter.angleFilter.End !== undefined
            || nonTextFilter.manufacturerFilter.length > 0
            || nonTextFilter.radiusFilter.Start !== undefined
            || nonTextFilter.radiusFilter.End !== undefined
            || nonTextFilter.tunnelMeterFilter.Start !== undefined
            || nonTextFilter.tunnelMeterFilter.End !== undefined
            || nonTextFilter.unitFilter.length > 0) {
            return false;
        }

        if (viewType !== undefined && viewType !== GEOvisDXFLayerType.Map) {
            return false;
        }

        if (customFilter) {
            if (!customFilter(chain)) {
                return false;
            }
        }

        return true;
    }

/**
 * Create chains tree items
 * @param treeData 
 * @param rootTreeItem 
 * @param view 
 * @param chainsInfoStorage
 * @param searchQuery
 * @param expandInfo
 * @param mutation common mutation for all elements
 */
const createChainsTreeItemsOfView = (
    treeData: TreeData,
    rootTreeItem: TreeItem,
    view: ProjectViewInfo,
    chainsInfoStorage: IChainsInfoStorage,
    searchQuery: string,
    expandInfo: Map<ItemId, boolean>,
    nonTextFilter: ISensorFilter,
    mutation: TreeItemMutation | undefined): void => {

    const { isLoading, chainsInfo } = chainsInfoStorage;

    if (isLoading) {
        return;
    }

    const isPublicOnly = AuthService.isActualViewerOfProject(view.ProjectId);
    const filterChainsFunc = getFilterOfChainsFunc(searchQuery, isPublicOnly, nonTextFilter);
    const chains = mapToListOfElements(chainsInfo, filterChainsFunc);

    if (chains.length === 0) {
        return;
    }

    const rootNodeId = `chains_${view.Id}`;
    const rootChainInfo = getChainsGroupRootNode(getElementWithHighestAlarm(view.ProjectId, chains), chains);
    const chainsRootNode = createChainRootTreeItem(rootNodeId, rootChainInfo, expandInfo, chains.length, mutation);
    const chainsTreeNodes = createChainsTreeItems(rootNodeId, chains, expandInfo, mutation);

    // store chains tree items to chains root node
    chainsRootNode.children.push(...chainsTreeNodes.map(n => n.id));
    chainsRootNode.hasChildren = chainsTreeNodes.length > 0;

    // store all items in result
    rootTreeItem.children.push(chainsRootNode.id);
    treeData.items[chainsRootNode.id] = chainsRootNode;

    chainsTreeNodes.forEach(node => {
        treeData.items[node.id] = node;
    })

    // update "hasChildren" for root tree item
    rootTreeItem.hasChildren = rootTreeItem.children.length > 0;
}

/**
 * Create chains tree items
 * @param idPrefix 
 * @param chains 
 * @param expandInfo
 * @param mutation
 */
const createChainsTreeItems = (idPrefix: string, chains: ChainInfo[], expandInfo: Map<ItemId, boolean>, mutation: TreeItemMutation | undefined): TreeItem[] =>
    chains
        .sort(comparerFnOfElementsInTheProjectViewTree)
        .map<TreeItem>(chain => createChainInfoTreeItem(idPrefix, chain, expandInfo, mutation));

/**
 * Create chain info tree item
 * @param idPrefix 
 * @param chain 
 */
const createChainInfoTreeItem = (idPrefix: string, chain: ChainInfo, expandInfo: Map<ItemId, boolean>, mutation: TreeItemMutation | undefined): TreeItem => {
    const id = `${idPrefix}_${chain.Id}`;
    return {
        isExpanded: expandInfo.get(id),
        ...mutation,
        id,
        children: [],
        data: getChainInfoTreeItemData(chain)
    };
}

/**
 * Create chains root node
 * @param chains 
 */
const getChainsGroupRootNode = (dangerousChain: ChainInfo | false, chains: ChainInfo[] | undefined): ChainInfo => {

    if (dangerousChain) {
        return dangerousChain;
    }
    let WatchdogEnabled = false;
    if (chains && chains.length > 0) {
        chains.forEach(c => {
            if (c.WatchdogEnabled) {
                WatchdogEnabled = true;
            }
        })
    }

    const result = new ChainInfo();
    result.WatchdogEnabled = WatchdogEnabled;
    result.PhysicalType = SensorCategory.BH_Inclinometer;

    return result;
}

/**
 * Create chain root tree item
 * @param rootId 
 * @param chain 
 * @param expandInfo
 * @param numberOfChildren
 * @param mutation
 */
const createChainRootTreeItem = (rootId: string, chain: ChainInfo, expandInfo: Map<ItemId, boolean>, numberOfChildren: number, mutation: TreeItemMutation | undefined): TreeItem => ({
    ...mutation,
    id: rootId,
    children: [],
    data: getTreeItemChainsGroupData(chain, numberOfChildren),
    isExpanded: expandInfo.get(rootId)
});

export const isProjectViewOverviewId = (viewId: string | ItemId): boolean => {
    return viewId === ProjectViewOverviewId;
}