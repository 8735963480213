/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 19.06.2021
 * @description GeovisSelect tool methods
 */

import { OptionType } from "@atlaskit/select";
import { getInventoryObjectTypeToName, InventoryObjectType } from "../../server/AVTService/TypeLibrary/Inventory/InventoryObjectType";
import { getInventoryStatusToName, InventoryStatus } from "../../server/AVTService/TypeLibrary/Inventory/InventoryStatus";
import { getPhysicalUnitShortName, PhysicalUnit, PhysicalUnitSupported } from "../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import {
    getSensorCategoryName,
    SensorCategory,
    SensorCategoryOrdered
} from "../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { GeovisMstModel } from "../../server/GEOvis3/Model/Database/GeovisMstModel";

export interface IGvOptionType<TValue> extends Omit<OptionType, 'value'> {
    label: string;
    value: TValue;
}

export interface IGvOptionTypeEx<TValue, TPayload> extends Omit<OptionType, 'value'> {
    label: string;
    value: TValue;
    payload: TPayload;
}

export const getGvValue = <TValue>(option: IGvOptionType<TValue>): TValue => {
    if (typeof option === "object") {
        return option.value;
    }

    return option;
}

export const getSelectedGvValue = <TValue>(options: Array<IGvOptionType<TValue>>, value: TValue, defaultValue: IGvOptionType<TValue>): IGvOptionType<TValue> => {
    for (const option of options) {
        if (option.value === value) {
            return option;
        }
    }

    return defaultValue;
}

export const getSensorCategoryOption = (value: SensorCategory): IGvOptionType<SensorCategory> => ({
    value, label: getSensorCategoryName(value)
});

export const getSensorCategoryOptions = (): Array<IGvOptionType<SensorCategory>> =>
    SensorCategoryOrdered.map<IGvOptionType<SensorCategory>>(getSensorCategoryOption);

export const getPhysicalUnitOptionsOfList = (values: PhysicalUnit[]): Array<IGvOptionType<PhysicalUnit>> =>
    values.map<IGvOptionType<PhysicalUnit>>(getPhysicalUnitOption);

export const getPhysicalUnitOption = (value: PhysicalUnit): IGvOptionType<PhysicalUnit> => ({
    value, label: getPhysicalUnitShortName(value)
});

export const getPhysicalUnitOptions = (): Array<IGvOptionType<PhysicalUnit>> =>
    PhysicalUnitSupported.map<IGvOptionType<PhysicalUnit>>(getPhysicalUnitOption);

export const getInventoryObjectTypeOption = (value: InventoryObjectType): IGvOptionType<InventoryObjectType> => ({
    label: getInventoryObjectTypeToName(value), value
});

export const getInventoryObjectTypeOptions = (items: Readonly<InventoryObjectType[]>): Array<IGvOptionType<InventoryObjectType>> => items.map(getInventoryObjectTypeOption);

export const getInventoryStatusOption = (value: InventoryStatus): IGvOptionType<InventoryStatus> => ({
    label: getInventoryStatusToName(value), value
});

export const getInventoryStatusOptions = (items: Readonly<InventoryStatus[]>): ReadonlyArray<IGvOptionType<InventoryStatus>> => items.map(getInventoryStatusOption);

export const getDatabaseOption = (item: GeovisMstModel): IGvOptionType<string> => ({
    label: item.Name || item.Id, value: item.Id
})

export const getDatabasesOptions = (items: GeovisMstModel[]): Array<IGvOptionType<string>> => items.map(getDatabaseOption);

export const getIGvOptionType = <TValue extends string | number>(value: TValue, getLabelFunc: (v: TValue) => string): IGvOptionType<TValue> => ({
    value, label: getLabelFunc(value)
});

/**
 * 
 * @param items list of items for options creation
 * @param getLabelFunc item to string func
 * @param sortingFunc sorting items func
 * @param filterFunc filtering items func
 * @returns 
 */
export const getIGvOptionTypeList = <TValue extends string | number>(items: ReadonlyArray<TValue>, getLabelFunc: (item: TValue) => string, sortingFunc?: (item1: TValue, item2: TValue) => number, filterFunc?: (item: TValue) => boolean): ReadonlyArray<IGvOptionType<TValue>> =>
    sortingFunc === undefined
        ? filterFunc === undefined
            ? items.map<IGvOptionType<TValue>>(value => ({ value, label: getLabelFunc(value) }))
            : items.filter(i => filterFunc(i)).map<IGvOptionType<TValue>>(value => ({ value, label: getLabelFunc(value) }))
        : filterFunc === undefined
            ? items.slice().sort((a, b) => sortingFunc(a, b)).map<IGvOptionType<TValue>>(value => ({ value, label: getLabelFunc(value) }))
            : items.filter(i => filterFunc(i)).slice().sort((a, b) => sortingFunc(a, b)).map<IGvOptionType<TValue>>(value => ({ value, label: getLabelFunc(value) }));

export const getIGvOptionTypeEx = <TValue extends string | number, TPayload>(
    payload: TPayload | undefined,
    getValueFunc: (pl: TPayload) => TValue,
    getLabelFunc: (pl: TPayload) => string): IGvOptionTypeEx<TValue, TPayload> | null => {

    if (payload === undefined) {
        return null;
    }

    return ({
        label: getLabelFunc(payload),
        value: getValueFunc(payload),
        payload
    });
}

/**
 * Get list of GeovisSelectTExt options
 * @param payloadList 
 * @param getValueFunc 
 * @param getLabelFunc 
 * @returns 
 */
export const getIGvOptionTypeExList = <TValue extends string | number, TPayload>(payloadList: readonly TPayload[], getValueFunc: (pl: TPayload) => TValue, getLabelFunc: (pl: TPayload) => string): Array<IGvOptionTypeEx<TValue, TPayload>> => {
    // payloadList.map<IGvOptionTypeEx<TValue, TPayload> | null>(pl => ).filter<IGvOptionTypeEx<TValue, TPayload>>(v => v);

    const result: Array<IGvOptionTypeEx<TValue, TPayload>> = [];

    for (const payload of payloadList) {
        const option = getIGvOptionTypeEx<TValue, TPayload>(payload, getValueFunc, getLabelFunc);

        if (option) {
            result.push(option);
        }
    }

    return result;
}