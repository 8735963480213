//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MapProviders {
	None = 0,
	OSM = 1,
	EsriWorldTopo = 2,
	EsriWorldImagery = 3,
	CartoDBPositron = 4,
	SwissTopo = 5,
	SwissTopoGray = 6,
	SwissTopoPhoto = 7,
	SwissTopoCadastral = 8,
	SwissTopoOpenData = 9,
	BasemapDE = 10,
	BasemapDEGray = 11,
	BasemapATGray = 12,
	BasemapATOrtophoto = 13
}

const MapProvidersToName = new Map<number, string>([
	[ 0, 'None' ],
	[ 1, 'OSM' ],
	[ 2, 'EsriWorldTopo' ],
	[ 3, 'EsriWorldImagery' ],
	[ 4, 'CartoDBPositron' ],
	[ 5, 'SwissTopo' ],
	[ 6, 'SwissTopoGray' ],
	[ 7, 'SwissTopoPhoto' ],
	[ 8, 'SwissTopoCadastral' ],
	[ 9, 'SwissTopoOpenData' ],
	[ 10, 'BasemapDE' ],
	[ 11, 'BasemapDEGray' ],
	[ 12, 'BasemapATGray' ],
	[ 13, 'BasemapATOrtophoto' ],
])

export function getMapProvidersToName(element: MapProviders) : string {
    return MapProvidersToName.get(element) || '';
}

const MapProvidersByName = new Map<string, number>([
	[ 'None', 0 ],
	[ 'OSM', 1 ],
	[ 'EsriWorldTopo', 2 ],
	[ 'EsriWorldImagery', 3 ],
	[ 'CartoDBPositron', 4 ],
	[ 'SwissTopo', 5 ],
	[ 'SwissTopoGray', 6 ],
	[ 'SwissTopoPhoto', 7 ],
	[ 'SwissTopoCadastral', 8 ],
	[ 'SwissTopoOpenData', 9 ],
	[ 'BasemapDE', 10 ],
	[ 'BasemapDEGray', 11 ],
	[ 'BasemapATGray', 12 ],
	[ 'BasemapATOrtophoto', 13 ],
]);

export function getMapProvidersByName(name: string): MapProviders {
    return MapProvidersByName.get(name)!;
}


export const MapProvidersList : Readonly<MapProviders[]> = [
	MapProviders.None,
	MapProviders.OSM,
	MapProviders.EsriWorldTopo,
	MapProviders.EsriWorldImagery,
	MapProviders.CartoDBPositron,
	MapProviders.SwissTopo,
	MapProviders.SwissTopoGray,
	MapProviders.SwissTopoPhoto,
	MapProviders.SwissTopoCadastral,
	MapProviders.SwissTopoOpenData,
	MapProviders.BasemapDE,
	MapProviders.BasemapDEGray,
	MapProviders.BasemapATGray,
	MapProviders.BasemapATOrtophoto,
];



const MapProvidersToDescription = new Map<number, string>([
	[ 0, 'No base map' ],
	[ 1, 'Open Street Map' ],
	[ 2, 'ESRI World Topo' ],
	[ 3, 'ESRI World Imagery' ],
	[ 4, 'CartoDB Positron' ],
	[ 5, 'Swisstopo Pixelkarte Colored' ],
	[ 6, 'Swisstopo Pixelkarte Grey' ],
	[ 7, 'Swisstopo Swissimage' ],
	[ 8, 'Swisstopo Cadastral' ],
	[ 9, 'Geodienste AV (s/w)' ],
	[ 10, 'Basemap DE' ],
	[ 11, 'Basemap DE Grey' ],
	[ 12, 'Basemap AT Grey' ],
	[ 13, 'Basemap AT Ortophoto' ],
])

export function getMapProvidersToDescription(element: MapProviders) : string {
    return MapProvidersToDescription.get(element) || '';
}

