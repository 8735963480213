/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.01.2019
 * @description Geovis GeoJSON layers with lazy loading
 */

import React, { useEffect, useState } from 'react';
import { IWithGeovisServerProps, withGeovisServer } from '../../helpers/GeovisHooks';
import { fetchDxfLayersGeoJSONContent } from '../../helpers/ProjectDataHelper';
import { elementsToMap, mapToListOfElements, mapToListOfElementsOfIds } from '../../helpers/StorageHelper';
import { ProjectDXFLayerDocument } from '../../server/GEOvis3/Model/Layers/ProjectDXFLayerDocument';
import Logger from '../../services/Logger';
import { IDXFLayersStorage } from '../../store/data.types';
import GeovisGeoJsonTextPoints from './GeovisGeoJsonTextPoints';
import GeovisGeoJSONVTLayer from './GeovisGeoJSONVTLayer';
import { GEOvisTileLayer } from './GEOvisTileLayer';

interface IGeovisGeoJSONLayersProps extends IWithGeovisServerProps {
    projectId: number;
    dxfLayersStorage: IDXFLayersStorage;
}

const GeovisGeoJSONLayers = ({ projectId, dxfLayersStorage, Server }: IGeovisGeoJSONLayersProps) => {

    const [dxfLayerDocuments, setDxfLayersDocuments] = useState<Map<string, ProjectDXFLayerDocument>>(new Map<string, ProjectDXFLayerDocument>());

    const { isLoading, isError, dxfLayers } = dxfLayersStorage;

    useEffect(() => {

        setDxfLayersDocuments(new Map<string, ProjectDXFLayerDocument>());

        (async function loadDxfLayersContent() {

            Logger.data('Start loading dxf GeoJSON layers content', GeovisGeoJSONLayers.name);

            // get expected list of 
            const dxfLayerIds = mapToListOfElements(dxfLayers, l => !l.IsMapTilesLayer).map(l => l.Id);

            const documents: ProjectDXFLayerDocument[] = [];

            for (const layerId of dxfLayerIds) {

                const result = await fetchDxfLayersGeoJSONContent(Server, projectId, layerId)

                Logger.data(`layer=${layerId} has been loaded success=${result.Success}`, GeovisGeoJSONLayers.name);

                if (result.Success) {
                    documents.push(result.Data);
                }
            }

            setDxfLayersDocuments(elementsToMap(documents));

            Logger.data('Loading dxf GeoJSON layers content finished', GeovisGeoJSONLayers.name);
        })();

    }, [isLoading, isError, dxfLayers]);


    if (isLoading || isError) {
        return null;
    }

    return (
        <React.Fragment>
            {mapToListOfElementsOfIds(dxfLayersStorage.dxfLayers).map(layerInfo => {
                // if layer is map tiles, then create a map tiles layer for it and return
                if (layerInfo.IsMapTilesLayer) {
                    return (
                        <GEOvisTileLayer
                            key={`geo_map_tiles_layer_${layerInfo.Id}`}
                            layer={layerInfo} />
                    )
                }

                const content = dxfLayerDocuments.get(layerInfo.Id);

                // content not loaded yet
                if (!content) {
                    return (null);
                }

                return (
                    <React.Fragment key={`geo_json_vt_points_${layerInfo.Id}`}>
                        <GeovisGeoJSONVTLayer
                            key={`geo_jsonvt_layer_${layerInfo.Id}`}
                            document={content.GeoJsonDocument}
                        />
                        <GeovisGeoJsonTextPoints
                            key={`geo_text_points_${layerInfo.Id}`}
                            layerContent={content}
                        />
                    </React.Fragment>
                );

            })}
        </React.Fragment>
    );
}

export default withGeovisServer(GeovisGeoJSONLayers);