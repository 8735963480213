/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 26.04.2022
 * @description Dest unit converter component
 */

import Textfield from '@atlaskit/textfield';
import React, { SyntheticEvent } from 'react';
import { t } from '../../../i18n';
import { DestinationUnitConverter } from '../../../server/AVTService/TypeLibrary/Model/DestinationUnitConverter';
import { getPhysicalUnitByName, getPhysicalUnitSupportedName, PhysicalUnit } from '../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit';
import { SensorCategory } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { GeovisSelect } from '../../select/GeovisSelect';
import { getPhysicalUnitOption, IGvOptionType } from '../../select/GeovisSelect_tools';
import { ChartSettingCheckbox } from './ChartTab_tools';
import { getPhysicalUnitsOptionsOfCategory } from './tools';
import { defaultChartRowSettingsStyle } from './types';


interface IDestUnitConverterComponentProps {
    converterValue: DestinationUnitConverter;
    isDisabled: boolean;
    onConverterChanged: (converterValue: DestinationUnitConverter) => void;
}

export const DestUnitConverterComponent = ({ converterValue, onConverterChanged, isDisabled }: IDestUnitConverterComponentProps) => {

    const settingsRowStyle = defaultChartRowSettingsStyle;

    const onEnableChanged = (enable: boolean) => {
        onConverterChanged({ ...converterValue, enable });
    }

    const onUnitChanged = (option: IGvOptionType<PhysicalUnit>) => {
        if (!option) {
            return;
        }
        onConverterChanged({ ...converterValue, unit: getPhysicalUnitSupportedName(option.value) });
    }

    const onMultChanged = (event: SyntheticEvent<HTMLInputElement>) => {
        onConverterChanged({ ...converterValue, multiplicatorValue: +event.currentTarget.value });
    }

    return (
        <React.Fragment>
            <div className="flexRowContainer" style={settingsRowStyle}>
                <ChartSettingCheckbox
                    label={t("Use manual unit convertion")}
                    isChecked={converterValue.enable}
                    onChange={onEnableChanged}
                    isDisabled={isDisabled}
                />
            </div>

            <div className="flexRowContainer" style={{ alignItems: 'center', display: 'flex' }}>
                <div className="flexCellContainer" style={{ width: '30%' }}>
                    <label htmlFor="cmbManConvTypes">{t("Manual convertion unit")}:</label>
                </div>
                <div className="flexCellContainer_g1" style={{ width: "100px", flexGrow: 4 }}>
                    <GeovisSelect
                        id="cmbManConvTypes"
                        value={getPhysicalUnitOption(getPhysicalUnitByName(converterValue.unit))}
                        options={getPhysicalUnitsOptionsOfCategory(SensorCategory.Unknown)}
                        onChange={onUnitChanged}
                        spacing='compact'
                        isDisabled={!converterValue.enable || isDisabled}
                    />
                </div>
                <div>
                    <span>{t("coeff.")}:</span>
                </div>
                <div style={{ width: "80px", flexShrink: 2, marginLeft: "5px" }}>
                    <Textfield
                        value={converterValue.multiplicatorValue}
                        onChange={onMultChanged}
                        isDisabled={!converterValue.enable || isDisabled}
                        isCompact={true}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}