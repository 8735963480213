/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 19.06.2020
 * @description Tool methods to work with Report Overlay
 */

import { CSSProperties } from "react";
import { GEOVIS_LIVE_API_ROOT } from "../../../ApiConfig";
import { mapToListOfElements, mapToListOfElementsOfIds, mapToListOfKeys } from "../../../helpers/StorageHelper";
import { t } from "../../../i18n";
import { ChartType, getChartTypeByName, getChartTypeToName } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { XYSensorPair } from "../../../server/AVTService/TypeLibrary/Common/XYSensorPair";
import { ChartModel } from "../../../server/AVTService/TypeLibrary/Model/ChartModel";
import { CustomerReportSettingsModel } from "../../../server/AVTService/TypeLibrary/Model/CustomerReportSettingsModel";
import { DtsChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel";
import { GeovisChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { HeatmapChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel";
import { HeatmapSensorsSource } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapSensorsSource";
import { XyChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import { GeovisImageModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisImageModel";
import { GeovisTable } from "../../../server/AVTService/TypeLibrary/Model/GeovisTable";
import { MapSectionModel } from "../../../server/AVTService/TypeLibrary/Model/MapSectionModel";
import { ChartSensorsDetails } from "../../../server/AVTService/TypeLibrary/Report/GEOvis4/Model/ChartSensorsDetails";
import { SensorCategory } from "../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import Logger from "../../../services/Logger";
import {
    IGeovisChartReportInfo,
    IGeovisProjectReportChangedElementIds,
    IGeovisProjectReportChangedElements,
    IGeovisReportImageSelectionInfo,
    IGeovisReportMapSectionSelectionInfo,
    IGeovisReportPageConfig,
    IGeovisReportPageSelectionInfo,
    IGeovisReportSettings,
    IReportChartSensorsSelectionInfo
} from "../../../store/projectReport.types";
import { compareChartTypes } from "../charts/tools";
import {
    COMMON_CHART_ID,
    COMMON_PAGE_ID,
    IChartsSensorsDetails,
    IReportPropertyItem,
    IReportSensorsSelectionInfoLegacy,
    ISendMessageToReportFrameRequest,
    ISendMessageToReportFrameResponse,
    ReportItemType,
    ReportObjectType,
    ReportElementItemsSelectionMap,
    ChartSensorsSelectionMap,
    ChartSelectionCollectionName,
    DTS_CLOSE_T_SENSORS_COLLECTION_NAME,
    DTS_SECTIONS_COLLECTION_NAME,
    PAIRS_COLLECTION_NAME,
    UNIT_A_SENSORS_COLLECTION_NAME,
    UNIT_B_SENSORS_COLLECTION_NAME,
    DtsSectionCloseTSensorIdsMap,
    CHAINS_COLLECTION_NAME
} from "./types";
import { GeovisReportPageSettings } from "../../../server/AVTService/TypeLibrary/Model/GeovisReportPageSettings";
import { GeovisMapSectionReportInfo } from "../../../server/AVTService/TypeLibrary/Model/MapSection/GeovisMapSectionReportInfo";


export const chartAs = <TChart extends GeovisChartModel>(chart: GeovisChartModel): TChart => chart as TChart;

export const geovisReportPageSelectionInfoInitialState: IGeovisReportPageSelectionInfo = {
    mapSectionInfo: new Map<number, IGeovisReportMapSectionSelectionInfo>(),
    geovisImageInfo: new Map<number, IGeovisReportImageSelectionInfo>(),
    geovisCharts: new Map<number, IReportChartSensorsSelectionInfo>()
}

export const createReportChartEmptySensorsSelectionInfo = (): IReportChartSensorsSelectionInfo => ({
    unitASensors: new Map<string, boolean>(),
    unitBSensors: new Map<string, boolean>(),
    pairs: new Map<string, boolean>(),
    dtsCloseTSensors: new Map<string, boolean>(),
    dtsSections: new Map<string, boolean>(),
    chains: new Map<string, boolean>()
});

/**
 * Report element property path info
 */
export interface IReportPropertyPath {
    type: ReportObjectType;
    pageNum: number;
    elementId: number;
    restPath: string[];
}

/**
 * Get common report property id
 * @param properties 
 */
export const getCommonReportPropertyId = (...properties: string[]): string => {
    return `${ReportObjectType.All}.${properties.join('.')}`;
}

/**
 * Get chart property id as path to the chart property
 * @param chart 
 * @param propertyName 
 */
export const getChartReportPropertyId = (chart: ChartModel, ...properties: string[]): string => {
    return `${ReportObjectType.Chart}.${chart.id}.${properties.join('.')}`;
}

/**
 * Get geovis chart report property id
 * @param pageNum 
 * @param chartId 
 * @param properties 
 * @returns 
 */
export const getGeovisChartReportPropertyId = (pageNum: number, chartId: number, ...properties: string[]): string => {
    return chartId === COMMON_CHART_ID
        ? `${ReportObjectType.All}.${properties.join('.')}`
        : `${ReportObjectType.GeovisChart}.${pageNum}.${chartId}.${properties.join('.')}`;
}

/**
 * Get geovis report page property id
 * @param pageNum 
 * @returns 
 */
export const getGeovisPageReportPropertyId = (pageNum: number): string => {
    return `page.${pageNum}`
}

/**
 * Create report page property item with children
 * @param pageNum 
 * @param pageItems 
 * @returns 
 */
export const getGeovisReportPageReportItem = (pageNum: number, pageItems: IReportPropertyItem[]): IReportPropertyItem => ({
    itemId: getGeovisPageReportPropertyId(pageNum),
    label: t("Page %1").replace('%1', (pageNum + 1).toString()),
    type: ReportItemType.Page,
    children: pageItems,
    depth: 1
})

/**
 * Get geovis table id as path to the table property
 * @param table 
 * @param properties 
 */
export const getGeovisTableReportPropertyId = (table: GeovisTable, ...properties: string[]): string => {
    return `${ReportObjectType.GEOvisTable}.${table.id}.${properties.join('.')}`;
}

/**
 * Get report property id (legacy code)
 * @param chart 
 * @param isCommon 
 * @param properties 
 * @returns 
 */
export const getReportPropertyId = (chart: ChartModel, isCommon: boolean, ...properties: string[]) => {
    return isCommon
        ? getCommonReportPropertyId(...properties)
        : getChartReportPropertyId(chart, ...properties);
}

const itemTypeToObjectType = (itemType: ReportItemType): ReportObjectType => {
    switch(itemType){
        case ReportItemType.MapSection: return ReportObjectType.MapSection;
        case ReportItemType.GEOvis4Table: return ReportObjectType.Geovis4Table;
        case ReportItemType.GeovisImage: return ReportObjectType.GeovisImage;
        case ReportItemType.GeovisLogbook: return ReportObjectType.GeovisLogbook;
        default: throw new Error(`Not support ReportITemType '${itemType}'`);
    }
}

/**
 * Parse property path to object with required information
 * @param propertyId 
 */
export const parseReportPropertyPath = (propertyId: string): IReportPropertyPath | false => {

    const parts = propertyId.split('.');

    const type = parts.shift();
    if (!type) {
        return false;
    }

    switch (type) {
        case ReportObjectType.All: return {
            elementId: COMMON_CHART_ID,
            pageNum: COMMON_PAGE_ID,
            restPath: parts,
            type: ReportObjectType.All
        }

        case ReportObjectType.Chart: {
            const chartId = parts.shift();
            if (!chartId) {
                return false;
            }

            return {
                elementId: +chartId,
                pageNum: 0,
                restPath: parts,
                type: ReportObjectType.Chart
            }
        }

        case ReportObjectType.GEOvisTable: {
            const tableId = parts.shift();
            if (!tableId) {
                return false;
            }

            return {
                elementId: +tableId,
                pageNum: 0,
                restPath: parts,
                type: ReportObjectType.GEOvisTable
            };
        }

        case ReportObjectType.GeovisChart: {
            const pageNum = parts.shift();
            const chartId = parts.shift();

            if (!pageNum || !chartId) {
                return false;
            }

            return {
                elementId: +chartId,
                pageNum: +pageNum,
                restPath: parts,
                type: ReportObjectType.GeovisChart
            }
        }

        case ReportObjectType.Page: {
            const pageNum = parts.shift();

            if (!pageNum) {
                return false;
            }

            return {
                elementId: 0,
                pageNum: +pageNum,
                restPath: parts,
                type: ReportObjectType.Page
            }
        }


        case ReportItemType.MapSection:
        case ReportItemType.GEOvis4Table:
        case ReportItemType.GeovisImage:
        case ReportItemType.GeovisLogbook: {
            const pageNum = parts.shift();
            const elementId = parts.shift();

            if (!pageNum || !elementId) {
                return false;
            }

            return {
                elementId: +elementId,
                pageNum: +pageNum,
                restPath: parts,
                type: itemTypeToObjectType(type)
            }
        }
    }

    return false;
}

/**
 * Build propertyPath array to propertyId string
 * @param propertyPath 
 */
export const toPropertyId = (propertyPath: string[]): string => {
    return propertyPath.join('.');
}

export const rowStyleFn = (depth: number): CSSProperties => {
    return { paddingLeft: `${depth * 2}em` };
}

export const hasObjectProperty = <TObject>(object: TObject, propertyName: string, propertyType: string): boolean => {
    const propertyValue = object[propertyName];
    return typeof propertyValue === propertyType;
}

export const getXySensorPairId = (pair: XYSensorPair): string => {
    return `${pair.xSensorId}-${pair.ySensorId}`;
}

/**
 * Initialize report settings for legacy charts
 * @param reportSettings 
 * @deprecated - related to Legacy report
 */
// eslint-disable-next-line camelcase
export const legacy_initReportSensorsSelection = (reportSettings: CustomerReportSettingsModel): IReportSensorsSelectionInfoLegacy => {

    const result: IReportSensorsSelectionInfoLegacy = {
        charts: new Map<number, IReportChartSensorsSelectionInfo>()
    };

    for (const chart of reportSettings.charts) {

        const chartSelection: IReportChartSensorsSelectionInfo = {
            unitASensors: new Map<string, boolean>(),
            unitBSensors: new Map<string, boolean>(),
            pairs: new Map<string, boolean>(),
            dtsCloseTSensors: new Map<string, boolean>(),
            dtsSections: new Map<string, boolean>(),
            chains: new Map<string, boolean>()
        };

        chart.unitASensorIds.forEach(fullId => {
            chartSelection.unitASensors.set(fullId, true);
        });

        chart.unitBSensorIds.forEach(fullId => {
            chartSelection.unitBSensors.set(fullId, true);
        });

        result.charts.set(chart.id, chartSelection);
    }

    return result;
}

const isElementSelected = (elementId: string, selectedElementIds?: string[]): boolean => {
    return selectedElementIds ? selectedElementIds.includes(elementId) : true;
}

const initGeovisChartSensorsSelectionInfo = (originalChart: GeovisChartModel, dtsSectionCloseTSensorIdsMap: DtsSectionCloseTSensorIdsMap, customerChart?: GeovisChartModel): IReportChartSensorsSelectionInfo => {

    const chartSelection: IReportChartSensorsSelectionInfo = {
        unitASensors: new Map<string, boolean>(),
        unitBSensors: new Map<string, boolean>(),
        pairs: new Map<string, boolean>(),
        dtsCloseTSensors: new Map<string, boolean>(),
        dtsSections: new Map<string, boolean>(),
        chains: new Map<string, boolean>()
    };

    if (originalChart.Type === ChartType.HeatMap) {
        const heatmap = originalChart as HeatmapChartModel;

        if (heatmap.ValueSettings.SensorsSource === HeatmapSensorsSource.Sensors) {
            heatmap.ValueSettings.SensorIds.forEach(fullId => {
                chartSelection.unitASensors.set(fullId, true);
            });
        }
    }
    else if (compareChartTypes(originalChart.Type, ChartType.DtsChart)) {
        const originalDtsChart = originalChart as DtsChartModel;
        const customerDtsChart = customerChart && compareChartTypes(customerChart.Type, ChartType.DtsChart) ? customerChart as DtsChartModel : undefined;

        const customerSectionIds = customerDtsChart ? customerDtsChart.DtsSectionIds : undefined;
        const closeTSensorIds: string[] = [];
        if (originalDtsChart.DtsSectionIds) {
            originalDtsChart.DtsSectionIds.forEach(sectionId => {
                closeTSensorIds.push(...dtsSectionCloseTSensorIdsMap[sectionId]);
                chartSelection.dtsSections.set(sectionId, isElementSelected(sectionId, customerSectionIds));
            });
        }

        const customerCloseTSensorIds = customerDtsChart && !customerDtsChart.UseAllDtsCloseTSensors ? customerDtsChart.DtsCloseTSensorIds : undefined;
        closeTSensorIds.forEach(fullId => {
            chartSelection.dtsCloseTSensors.set(fullId, isElementSelected(fullId, customerCloseTSensorIds));
        })
    }
    else {
        const customerLeftAxisSensorIds = customerChart ? customerChart.LeftYAxisSettings.SensorIds : undefined;
        originalChart.LeftYAxisSettings.SensorIds.forEach(fullId => {
            chartSelection.unitASensors.set(fullId, isElementSelected(fullId, customerLeftAxisSensorIds));
        });

        const customerRightAxisSensorIds = customerChart ? customerChart.RightYAxisSettings.SensorIds : undefined;
        originalChart.RightYAxisSettings.SensorIds.forEach(fullId => {
            chartSelection.unitBSensors.set(fullId, isElementSelected(fullId, customerRightAxisSensorIds));
        });

        if (compareChartTypes(originalChart.Type, ChartType.XYDiagram) && chartAs<XyChartModel>(originalChart).UseXYAxisDataBasedOnDifferentSensors) {
            (originalChart as XyChartModel).SensorPairs.forEach(pair => {
                chartSelection.pairs.set(getXySensorPairId(pair), true);
            })
        }
    }

    return chartSelection;
}

/**
 * Initialize report settings
 * 
 * v1: Base implementation, initialize charts selection from scratch and using exists configs
 * 
 * @param reportSettings 
 */
const initGeovisChartsSensorsSelectionOfSettingsReportPage = (page: GeovisReportPageSettings, dtsSectionCloseTSensorIdsMap: DtsSectionCloseTSensorIdsMap,
    originalCharts?: GeovisChartModel[]): Map<number, IReportChartSensorsSelectionInfo> => {

    const result = new Map<number, IReportChartSensorsSelectionInfo>();

    if (originalCharts && originalCharts.length > 0) {
        // fill sensor ids from original charts and set isSelection to true if custom chart has same sensor id
        return new Map<number, IReportChartSensorsSelectionInfo>(page.GeovisCharts.map(customChart => {
            const originalChart = originalCharts.find(info => info.Id === customChart.Id);

            if(!originalChart) {
                 Logger.error(`Couldn't find original chart, page=${page.PageNum}, chart id = ${customChart.Id}`);

                return [
                    customChart.Id, 
                    initGeovisChartSensorsSelectionInfo(customChart, dtsSectionCloseTSensorIdsMap, undefined)
                ]
            }

            return [
                customChart.Id, 
                initGeovisChartSensorsSelectionInfo(originalChart, dtsSectionCloseTSensorIdsMap, customChart)
            ]
        }));
    }
    else {
        page.GeovisCharts.forEach(chart => {
            // customer chart currently not defined, will be done in next iteration
            const chartSelection = initGeovisChartSensorsSelectionInfo(chart, dtsSectionCloseTSensorIdsMap, undefined);
            result.set(chart.Id, chartSelection)
        });
    }

    return result;
}

const initGeovisChartsSensorsSelectionOfGeovisReportPage = (charts: Map<number, IGeovisChartReportInfo<GeovisChartModel>>, dtsSectionCloseTSensorIdsMap: DtsSectionCloseTSensorIdsMap): Map<number, IReportChartSensorsSelectionInfo> => {

    const result = new Map<number, IReportChartSensorsSelectionInfo>();

    charts.forEach(({ Chart }) => {
        result.set(Chart.Id, initGeovisChartSensorsSelectionInfo(Chart, dtsSectionCloseTSensorIdsMap));
    });

    return result;
}

/**
 * Initialize geovis report page selection
 * For now it works only for MapSections and GeovisImages
 * Later it should be implemented for other elements, like GeovisChart and so on
 * @param page 
 * @param dtsSectionCloseTSensorIdsMap - dts sensors map
 * @returns 
 */
export const initGeovisReportPageSelectionInfo = (page: GeovisReportPageSettings, dtsSectionCloseTSensorIdsMap: DtsSectionCloseTSensorIdsMap, 
    reportElementsChanges?: IGeovisProjectReportChangedElements): IGeovisReportPageSelectionInfo => 
({
    mapSectionInfo: initMapSectionSensorsSelectionOfSettingsReportPage(page, reportElementsChanges?.originalMapSections),
    geovisImageInfo: initGeovisImageSensorsSelection(page.GeovisImages, reportElementsChanges?.originalGeovisImages),
    geovisCharts: initGeovisChartsSensorsSelectionOfSettingsReportPage(page, dtsSectionCloseTSensorIdsMap, reportElementsChanges?.originalCharts)
});

/**
 * Initialize geovis report page selection
 * Now this implementation for types of page elements, but not for models, which comes from Server
 * @param page 
 * @param charts 
 * @param dtsSectionCloseTSensorIdsMap 
 * @returns 
 */
export const initGeovisReportPageSelectionInfoFromElements = (page: IGeovisReportPageConfig, charts: Map<number, IGeovisChartReportInfo<GeovisChartModel>>, dtsSectionCloseTSensorIdsMap: DtsSectionCloseTSensorIdsMap): IGeovisReportPageSelectionInfo => ({
    mapSectionInfo: initMapSectionSensorsSelectionOfGeovisReportPage(page),
    geovisImageInfo: initGeovisImageSensorsSelection(mapToListOfElements(page.geovisImages).map(info => info.GeovisImage)),
    geovisCharts: initGeovisChartsSensorsSelectionOfGeovisReportPage(charts, dtsSectionCloseTSensorIdsMap)
})

/**
 * Create element selection map
 */
const createElementsSelectionMap = (elementIds: string[], isSelected: boolean): ReportElementItemsSelectionMap => {

    const result = new Map<string, boolean>();

    for (const elementId of elementIds) {
        result.set(elementId, isSelected);
    }
    return result;
}

/**
 * Init geovis report MapSection selection info
 * 
 * v1: simple collect data
 * @param page
 * @returns 
 */
const initMapSectionSensorsSelectionOfSettingsReportPage = (page: GeovisReportPageSettings, originalMapSections?: GeovisMapSectionReportInfo[]): Map<number, IGeovisReportMapSectionSelectionInfo> => {
    const result = new Map<number, IGeovisReportMapSectionSelectionInfo>();

    // if page has not custom map sections then break
    if (page.MapSectionsInfo === undefined) {
        return result;
    }

    if (originalMapSections && originalMapSections.length > 0) {
        // fill sensor ids from original map section and set isSelection to true if custom map section has same sensor id
        return new Map<number, IGeovisReportMapSectionSelectionInfo>(page.MapSectionsInfo.map(customMapSection => {
            const originalMapSection = originalMapSections.find(info => info.Id === customMapSection.Id);

            if(!originalMapSection) {
                Logger.error(`Couldn't find original map section, page=${page.PageNum}, map section id = ${customMapSection.Id}`);

                return [
                    customMapSection.Id, 
                    {
                        sensorsInfo: new Map<string, boolean>(customMapSection.MapSection.SensorIds.map(sensorId => [sensorId, true])),
                        chainsInfo: new Map<string, boolean>(customMapSection.MapSection.ChainIds.map(chainId => [chainId, true])),
                    }
                ]
            }

            return [
                customMapSection.Id, 
                {
                    sensorsInfo: new Map<string, boolean>(originalMapSection.MapSection.SensorIds.map(sensorId => [
                        sensorId, 
                        customMapSection.MapSection.SensorIds.includes(sensorId)
                    ])),
                    chainsInfo: new Map<string, boolean>(originalMapSection.MapSection.ChainIds.map(chainId => [
                        chainId, 
                        customMapSection.MapSection.ChainIds.includes(chainId)
                    ]))
                }
            ]
        }));
    }
    else {
        // fill sensor ids from custom map section and set isSelection to true
        for (const { MapSection } of page.MapSectionsInfo) {

            result.set(MapSection.Id, initMapSectionSensorsSelectionOfMap(MapSection, page.GeovisCharts))
        }
    }

    return result;
}

const initMapSectionSensorsSelectionOfGeovisReportPage = (page: IGeovisReportPageConfig): Map<number, IGeovisReportMapSectionSelectionInfo> => {

    const result = new Map<number, IGeovisReportMapSectionSelectionInfo>();
    const charts = mapToListOfElements(page.geovisCharts).map(info => info.Chart);

    page.mapSections.forEach(({ MapSection }) => {
        result.set(MapSection.Id, initMapSectionSensorsSelectionOfMap(MapSection, charts));
    });

    return result;
}

/**
 * Init sensors selection of one map section
 * @param mapSection 
 * @param charts 
 * @returns 
 */
const initMapSectionSensorsSelectionOfMap = (mapSection: MapSectionModel, charts: GeovisChartModel[]): IGeovisReportMapSectionSelectionInfo => {

    // if use only manual selected sensors and chains
    if (mapSection.UseManualSelectedSensors) {
        return {
            sensorsInfo: createElementsSelectionMap(mapSection.SensorIds, true),
            chainsInfo: createElementsSelectionMap(mapSection.ChainIds, true),
        }
    }

    const sensorIds = [];
    const chainIds = [];

    // collect all sensors from charts on this page
    for (const chart of charts) {
        sensorIds.push(...chart.LeftYAxisSettings.SensorIds, ...chart.RightYAxisSettings.SensorIds);
        chainIds.push(...chart.LeftYAxisSettings.ChainIds, ...chart.RightYAxisSettings.ChainIds);
    }

    return {
        sensorsInfo: createElementsSelectionMap(sensorIds, true),
        chainsInfo: createElementsSelectionMap(chainIds, true)
    }
}

/**
 * Init geovis image sensors selection
 * 
 * v1: Simple collect sensors of images
 * @param customerImages 
 * @param originalImages 
 * @returns 
 */
const initGeovisImageSensorsSelection = (customerImages: GeovisImageModel[], originalImages?: GeovisImageModel[]): Map<number, IGeovisReportImageSelectionInfo> => {
    const result = new Map<number, IGeovisReportImageSelectionInfo>();

    if (originalImages && originalImages.length > 0) {
        // fill sensor ids from original images and set isSelection to true if custom image has same sensor id
        return new Map<number, IGeovisReportImageSelectionInfo>(customerImages.map(customImage => {

            const originalImage = originalImages.find(info => info.Id === customImage.Id);

            if(!originalImage) {
                Logger.error(`Couldn't find original image, id = ${customImage.Id}`);

                return [
                    customImage.Id, 
                    {
                        sensorsInfo: new Map<string, boolean>(customImage.SensorsIds.map(sensorId => [sensorId, true])),
                        chainsInfo: new Map<string, boolean>(customImage.Chains.map(chain => [chain.FullId, true])),
                    }
                ]
            }

            return [
                customImage.Id, 
                {
                    sensorsInfo: new Map<string, boolean>(originalImage.SensorsIds.map(sensorId => [
                        sensorId, 
                        customImage.SensorsIds.includes(sensorId)
                    ])),
                    chainsInfo: new Map<string, boolean>(originalImage.Chains.map(originalChain => [
                        originalChain.FullId, 
                        !!customImage.Chains.find(customChain => originalChain.FullId === customChain.FullId)
                    ]))
                }
            ]
        }));
    }
    else {
        for (const image of customerImages) {
            result.set(image.Id, {
                sensorsInfo: createElementsSelectionMap(image.SensorsIds, true)
            });
        }
    }

    return result;
}

const searchGeovisChartSelectionInfo = (pageNum: number, chartId: number, geovisReportSettings: IGeovisReportSettings): IReportChartSensorsSelectionInfo | false => {

    if (pageNum > 0) {
        const page = geovisReportSettings.geovisPages.get(pageNum);
        if (!page) {
            return false;
        }

        return page.selectionInfo.geovisCharts.get(chartId) || false;
    }

    let result: IReportChartSensorsSelectionInfo | false = false;

    geovisReportSettings.geovisPages.forEach(page => {

        const selectionInfo = page.selectionInfo.geovisCharts.get(chartId);
        if (selectionInfo) {
            result = selectionInfo;
        }
    });

    return result;
}

/**
 * Get sensors selection of
 * Supports only Charts, no GeovisTable
 * @param propertyPath
 * @param sensorsSelectionInfo 
 * @param geovisReportSettings
 * @param charts - legacy charts
 * @returns the map of <kind of sensors, <fullId, isSelected>> in report
 * kind of sensors see before this function
 */
// eslint-disable-next-line camelcase
export const allSensorsDialog_collectSensorsSelectionInfo = (propertyPath: IReportPropertyPath, reportSensorsSelection: IReportSensorsSelectionInfoLegacy, geovisReportSettings: IGeovisReportSettings, charts: ChartModel[]): ChartSensorsSelectionMap => {

    const result = new Map<ChartSelectionCollectionName, Map<string, boolean>>();

    const { type: objectType, elementId, pageNum } = propertyPath;

    result.set(UNIT_A_SENSORS_COLLECTION_NAME, new Map<string, boolean>());
    result.set(UNIT_B_SENSORS_COLLECTION_NAME, new Map<string, boolean>());
    result.set(PAIRS_COLLECTION_NAME, new Map<string, boolean>());
    result.set(DTS_CLOSE_T_SENSORS_COLLECTION_NAME, new Map<string, boolean>());
    result.set(DTS_SECTIONS_COLLECTION_NAME, new Map<string, boolean>());
    result.set(CHAINS_COLLECTION_NAME, new Map<string, boolean>());

    if (!elementId) {
        return result;
    }

    switch (objectType) {
        // process legacy chart model
        case ReportObjectType.Chart: {
            const chartId = elementId;

            if (!chartId || !reportSensorsSelection.charts.has(chartId)) {
                return result;
            }

            const chartEx = charts.find(ch => ch.id === chartId);

            if (!chartEx) {
                return result;
            }

            const chartSelectionInfo = reportSensorsSelection.charts.get(chartId)!;

            result.set(UNIT_A_SENSORS_COLLECTION_NAME, chartSelectionInfo.unitASensors);

            // we should remove axis B sensors for some types
            if (chartEx.type !== getChartTypeToName(ChartType.XYVibrationEventDiagram)) {
                result.set(UNIT_B_SENSORS_COLLECTION_NAME, chartSelectionInfo.unitBSensors);
            }

            break;
        }

        // process geovis chart model
        case ReportObjectType.GeovisChart: {
            const chartId = elementId;

            if (!chartId) {
                return result;
            }

            const chartSelectionInfo = searchGeovisChartSelectionInfo(pageNum, chartId, geovisReportSettings);
            if (!chartSelectionInfo) {
                return result;
            }
            // const chartSelectionInfo = reportSensorsSelection.geovisCharts.get(chartId)!;

            result.set(UNIT_A_SENSORS_COLLECTION_NAME, chartSelectionInfo.unitASensors);
            result.set(UNIT_B_SENSORS_COLLECTION_NAME, chartSelectionInfo.unitBSensors);
            result.set(PAIRS_COLLECTION_NAME, chartSelectionInfo.pairs);
            result.set(DTS_CLOSE_T_SENSORS_COLLECTION_NAME, chartSelectionInfo.dtsCloseTSensors);
            result.set(DTS_SECTIONS_COLLECTION_NAME, chartSelectionInfo.dtsSections);

            break;
        }

        /**
         * Map section selection info
         */
        case ReportObjectType.MapSection: {

            const page = geovisReportSettings.geovisPages.get(pageNum);
            if (!page) {
                return result;
            }

            const selectionInfo = page.selectionInfo.mapSectionInfo.get(elementId);
            if (!selectionInfo) {
                return result;
            }

            result.set(UNIT_A_SENSORS_COLLECTION_NAME, selectionInfo.sensorsInfo);
            result.set(CHAINS_COLLECTION_NAME, selectionInfo.chainsInfo);

            return result;
        }

        /**
         * Geovis images selection info
         */
        case ReportObjectType.GeovisImage: {

            const page = geovisReportSettings.geovisPages.get(pageNum);
            if (!page) {
                return result;
            }

            const selectionInfo = page.selectionInfo.geovisImageInfo.get(elementId);
            if (!selectionInfo) {
                return result;
            }

            result.set(UNIT_A_SENSORS_COLLECTION_NAME, selectionInfo.sensorsInfo);

            return result;
        }

        // process all elements, include legacy charts and geovis report elements
        // there are 2 ways: 
        // - legacy report contains legacy charts
        // - geovis report contains geovis report elements
        // I really hope that it is as it is 
        case ReportObjectType.All: {

            const unitASensors = new Map<string, boolean>();
            const unitBSensors = new Map<string, boolean>();
            const dtsCloseTSensors = new Map<string, boolean>();
            const dtsSections = new Map<string, boolean>();
            const chains = new Map<string, boolean>();

            // check legacy charts
            reportSensorsSelection.charts.forEach((chart, chartId) => {
                const chartEx = charts.find(ch => ch.id === chartId);

                if (chartEx) {
                    chart.unitASensors.forEach((value, key) => {
                        if (!unitASensors.has(key)) {
                            unitASensors.set(key, value)
                        }
                    });

                    // we should remove axis B sensors for some types
                    if (chartEx.type !== getChartTypeToName(ChartType.XYVibrationEventDiagram)) {
                        chart.unitBSensors.forEach((value, key) => {
                            if (!unitBSensors.has(key)) {
                                unitBSensors.set(key, value)
                            }
                        });
                    }
                }
            });

            // check geovis elements
            geovisReportSettings.geovisPages.forEach(page => {

                // check charts
                page.selectionInfo.geovisCharts.forEach((geovisChart) => {

                    geovisChart.unitASensors.forEach((value, key) => {
                        if (!unitASensors.has(key)) {
                            unitASensors.set(key, value);
                        }
                    });

                    geovisChart.unitBSensors.forEach((value, key) => {
                        if (!unitBSensors.has(key)) {
                            unitBSensors.set(key, value);
                        }
                    });

                    geovisChart.dtsCloseTSensors.forEach((value, key) => {
                        if (!dtsCloseTSensors.has(key)) {
                            dtsCloseTSensors.set(key, value);
                        }
                    });

                    geovisChart.dtsSections.forEach((value, key) => {
                        if (!dtsSections.has(key)) {
                            dtsSections.set(key, value);
                        }
                    })
                });

                // check map sections
                page.selectionInfo.mapSectionInfo.forEach(selectionInfo => {
                    selectionInfo.sensorsInfo.forEach((value, key) => {
                        if (!unitASensors.has(key)) {
                            unitASensors.set(key, value);
                        }
                    });

                    selectionInfo.chainsInfo.forEach((value, key) => {
                        chains.set(key, value);
                    });
                })

                // TODO: why no check geovis images?

            });


            result.set(UNIT_A_SENSORS_COLLECTION_NAME, unitASensors);
            result.set(UNIT_B_SENSORS_COLLECTION_NAME, unitBSensors);
            result.set(DTS_CLOSE_T_SENSORS_COLLECTION_NAME, dtsCloseTSensors);
            result.set(DTS_SECTIONS_COLLECTION_NAME, dtsSections);
            result.set(CHAINS_COLLECTION_NAME, chains);

            break;
        }

        default:
            throw Error(`allSensorsDialog_collectSensorsSelectionInfo: collect elements for object type ${objectType} not supported yet`);
    }

    return result;
}

/**
 * 
 * @param propertyPath
 * @param charts 
 * @returns 
 */
export const getXYSensorPairs = (propertyPath: IReportPropertyPath, charts: ChartModel[]): XYSensorPair[] | undefined => {

    const { type, elementId } = propertyPath;

    if (type === ReportObjectType.Chart) {
        if (!elementId) {
            return undefined;
        }

        const chart = charts.find(c => c.id === elementId);
        if (!chart) {
            return undefined;
        }

        const chartType = getChartTypeByName(chart.type);
        if (chartType === ChartType.XYDiagram && chart.useXYAxisDataBasedOnDifferentSensors) {
            return chart.xyPairs;
        }
    }

    return undefined;
}

/**
 * 
 * @param propertyPath
 * @param pages 
 * @returns 
 */
export const getGeovisXYSensorPairs = (propertyPath: IReportPropertyPath, pages: Map<number, IGeovisReportPageConfig>): XYSensorPair[] | undefined => {

    const { elementId, type } = propertyPath;

    if (type === ReportObjectType.GeovisChart) {
        if (!elementId) {
            return undefined;
        }

        for (const page of mapToListOfElements(pages)) {

            const chart = page.geovisCharts.get(elementId);
            if (!chart) {
                return undefined;
            }

            if (compareChartTypes(chart.Chart.Type, ChartType.XYDiagram) && chartAs<XyChartModel>(chart.Chart).UseXYAxisDataBasedOnDifferentSensors) {
                return (chart.Chart as XyChartModel).SensorPairs;
            }
        }
    }
    return undefined;
}

/**
 * Get sensor details of the object
 * Supports only Charts, no GeovisTable
 * @param propertyPath
 * @param chartsSensorsDetail
 * @returns The chart sensors details object
 */
export const getSensorsDetails = (propertyPath: IReportPropertyPath, chartsSensorsDetails: IChartsSensorsDetails): ChartSensorsDetails => {

    const { type: objectType, elementId: chartId } = propertyPath;

    switch (objectType) {

        case ReportObjectType.Chart: {
            if (!chartId) {
                return { SensorDetails: {} };
            }

            const chartSensorDetails = chartsSensorsDetails[chartId];
            return chartSensorDetails || { SensorDetails: {} };
        }

        case ReportObjectType.GeovisChart: {
            if (!chartId) {
                return { SensorDetails: {} };
            }

            const chartSensorDetails = chartsSensorsDetails[chartId];
            return chartSensorDetails || { SensorDetails: {} };
        }

        case ReportObjectType.All: {
            const result: ChartSensorsDetails = { SensorDetails: {} };

            for (const key of Object.keys(chartsSensorsDetails)) {
                result.SensorDetails = { ...result.SensorDetails, ...chartsSensorsDetails[+key].SensorDetails }

            }
            return result;
        }
    }

    return { SensorDetails: {} };
}

/**
 * Remove unselected sensors of charts
 * @param customerSettings 
 * @param sensorsSelectionInfo 
 */
export const removeUnselectedSensorsOfCharts = (customerSettings: CustomerReportSettingsModel, sensorsSelectionInfo: IReportSensorsSelectionInfoLegacy) => {

    for (const chart of customerSettings.charts) {
        const chartSensorsSelectionInfo = sensorsSelectionInfo.charts.get(chart.id);
        if (!chartSensorsSelectionInfo) {
            continue;
        }

        chart.unitASensorIds = chart.unitASensorIds.filter(fullId => chartSensorsSelectionInfo.unitASensors.get(fullId));
        chart.unitBSensorIds = chart.unitBSensorIds.filter(fullId => chartSensorsSelectionInfo.unitBSensors.get(fullId));
    }
}

// eslint-disable-next-line camelcase
export const legacy_fillSensorsForCharts = (charts: ChartModel[], selection: IReportSensorsSelectionInfoLegacy): ChartModel[] => {

    charts.forEach(ch => {
        const data = selection.charts.get(ch.id);
        if (data) {
            ch.unitASensorIds = [];
            ch.unitBSensorIds = [];
            data.unitASensors.forEach((val, uas) => {
                if (val) {
                    ch.unitASensorIds.push(uas);
                }
            });
            data.unitBSensors.forEach((val, ubs) => {
                if (val) {
                    ch.unitBSensorIds.push(ubs);
                }
            });
        }
    });
    return charts;
}

/**
 * Fill selection info and selected sensors for changed chart
 * And for other report elements, which depends of charts
 * For example, if MapSection draws sensors on the same report page
 * 
 * There is a situation, or only one chart on page has changed, or all charts in report has changed
 * But "chartSensorsSelection" only one, no matter wheat case in line before
 * @param pages 
 * @param originalChartConfig
 * @param sensorsSelectionInfo 
 * @param changedChartsIdsByPageNum 
 * @returns 
 */
// eslint-disable-next-line camelcase
export const chartSensorsDialog_fillSensorsForGeovisElements = (
    pages: Map<number, IGeovisReportPageConfig>,
    originalChartConfigs: Map<number, GeovisChartModel>,
    sensorsSelectionInfo: IReportChartSensorsSelectionInfo,
    changedIdsByPage: IGeovisProjectReportChangedElementIds): Map<number, IGeovisReportPageConfig> => {

    const { changedChartsIds, changedMapSectionIds, changedPageGeovisImagesIds } = changedIdsByPage;

    // Process changed charts
    if (changedChartsIds) {

        const changedChartPageNumbers = Object.keys(changedChartsIds);

        // go through pages with changed charts
        // do not create page config again
        for (const pageNum of changedChartPageNumbers) {

            const page = pages.get(+pageNum);
            if (!page) {
                continue;
            }

            const chartIds = changedChartsIds[pageNum];
            if (!chartIds) {
                continue;
            }

            // update charts on the page
            // tough only changed charts
            for (const chartId of chartIds) {

                const sourceChartInfo = page.geovisCharts.get(chartId);
                if (!sourceChartInfo) {
                    throw Error(`chartSensorsDialog_fillSensorsForGeovisElements: Initialization error, chart id=${chartId} on page ${pageNum} not found`);
                }

                const originalChart = originalChartConfigs.get(chartId);
                if (!originalChart) {
                    throw Error(`chartSensorsDialog_fillSensorsForGeovisElements: Initialization error, chart ${sourceChartInfo.Chart.Name} on page ${pageNum} doesn't have initialized sensors selection info`);
                }

                const { Chart } = sourceChartInfo;

                const chartSelectionInfo = createReportChartEmptySensorsSelectionInfo();

                // check heatmap chart
                if (Chart.Type === ChartType.HeatMap) {

                    const heatmap = chartAs<HeatmapChartModel>(Chart);
                    if (heatmap.ValueSettings.SensorsSource !== HeatmapSensorsSource.Sensors) {
                        continue;
                    }

                    const originalHeatmapChart = chartAs<HeatmapChartModel>(originalChart);
                    const { unitASensors } = sensorsSelectionInfo;

                    if (originalHeatmapChart.ValueSettings && typeof originalHeatmapChart.ValueSettings.SensorIds === "object") {

                        heatmap.ValueSettings.SensorIds = [];

                        for (const sensorId of originalHeatmapChart.ValueSettings.SensorIds) {

                            const isSelected = unitASensors.get(sensorId) || false;
                            chartSelectionInfo.unitASensors.set(sensorId, isSelected);

                            if (isSelected) {
                                heatmap.ValueSettings.SensorIds.push(sensorId);
                            }
                        }

                        // save chart in store with new Timestamp
                        page.geovisCharts.set(chartId, {
                            Chart: heatmap,
                            IsDirty: true,
                            SuppressLoadingState: false,
                            Timestamp: Date.now()
                        });
                    }

                    continue;
                }

                // process not-heatmap chart
                {
                    const { unitASensors, unitBSensors, dtsSections, dtsCloseTSensors, pairs: xyPairs } = sensorsSelectionInfo;

                    // if it is XYDiagramm
                    if (compareChartTypes(Chart.Type, ChartType.XYDiagram)) {
                        const xyChart = chartAs<XyChartModel>(Chart);

                        // if XYChart used pairs, then check them
                        if (xyChart.UseXYAxisDataBasedOnDifferentSensors) {

                            xyChart.SensorPairs = [];

                            const originalXyChart = chartAs<XyChartModel>(originalChart);
                            for (const pair of originalXyChart.SensorPairs) {
                                const pairId = getXySensorPairId(pair);

                                const isSelected = xyPairs.get(pairId) || false;
                                chartSelectionInfo.pairs.set(pairId, isSelected);

                                if (isSelected) {
                                    xyChart.SensorPairs.push(pair);
                                }
                            }
                        }
                    }

                    // Check DTS Chart
                    if (compareChartTypes(Chart.Type, ChartType.DtsChart)) {
                        const dtsChart = chartAs<DtsChartModel>(Chart);

                        dtsChart.DtsCloseTSensorIds = [];
                        dtsChart.UseAllDtsCloseTSensors = false;

                        const originalDtsChart = chartAs<DtsChartModel>(originalChart);

                        // DTS Closed TSensors
                        for (const sensorId of originalDtsChart.DtsCloseTSensorIds) {

                            const isSelected = dtsCloseTSensors.get(sensorId) || false;
                            chartSelectionInfo.dtsCloseTSensors.set(sensorId, isSelected);

                            if (isSelected) {
                                dtsChart.DtsCloseTSensorIds.push(sensorId);
                            }
                        }

                        // DTS Sections
                        for (const sectionId of originalDtsChart.DtsSectionIds) {

                            const isSelected = dtsSections.get(sectionId) || false;
                            chartSelectionInfo.dtsSections.set(sectionId, isSelected);

                            if (isSelected) {
                                dtsChart.DtsSectionIds.push(sectionId);
                            }
                        }
                    }

                    // check Left / Right axes
                    Chart.LeftYAxisSettings.SensorIds = [];
                    Chart.RightYAxisSettings.SensorIds = [];

                    // copy-paste, fix later with common method
                    for (const sensorId of originalChart.LeftYAxisSettings.SensorIds) {
                        const isSelected = unitASensors.get(sensorId) || false;
                        chartSelectionInfo.unitASensors.set(sensorId, isSelected);

                        if (isSelected) {
                            Chart.LeftYAxisSettings.SensorIds.push(sensorId);
                        }
                    }

                    for (const sensorId of originalChart.RightYAxisSettings.SensorIds) {
                        const isSelected = unitBSensors.get(sensorId) || false;
                        chartSelectionInfo.unitBSensors.set(sensorId, isSelected);

                        if (isSelected) {
                            Chart.RightYAxisSettings.SensorIds.push(sensorId);
                        }
                    }

                    page.selectionInfo.geovisCharts.set(chartId, chartSelectionInfo);

                    // save chart in store with new Timestamp
                    page.geovisCharts.set(chartId, {
                        Chart,
                        IsDirty: true,
                        SuppressLoadingState: false,
                        Timestamp: Date.now()
                    });
                }
            }
        }
    }

    // update map sections on this page
    if (changedMapSectionIds) {

        const { unitASensors, chains } = sensorsSelectionInfo;
        const changedMapSectionPageNumbers = Object.keys(changedMapSectionIds);

        for (const pageNum of changedMapSectionPageNumbers) {

            const page = pages.get(+pageNum);
            if (!page) {
                continue;
            }

            const mapSectionIds = changedMapSectionIds[pageNum];
            if (!mapSectionIds) {
                continue;
            }

            for (const { MapSection, ...restProps } of mapToListOfElementsOfIds(page.mapSections, mapSectionIds)) {

                // const destSelectionInfo = new a
                const sourceSelectionInfo = page.selectionInfo.mapSectionInfo.get(MapSection.Id);
                if (!sourceSelectionInfo) {
                    throw Error(`chartSensorsDialog_fillSensorsForGeovisElements: Initialization error, map section ${MapSection.Name} on page ${pageNum} doesn't have initialized sensors selection info`);
                }

                let bChanged = false;

                unitASensors.forEach((willUse, sensorId) => {

                    const wasUsed = sourceSelectionInfo.sensorsInfo.get(sensorId);

                    // TODO: check it.
                    // but if no info about this sensor in the map, then it is not used in the map
                    if (wasUsed === undefined) {
                        return;
                    }

                    if (willUse !== wasUsed) {
                        sourceSelectionInfo.sensorsInfo.set(sensorId, willUse)
                        bChanged = true;
                    }
                });

                chains.forEach((willUse, chainId) => {
                    const wasUsed = sourceSelectionInfo.chainsInfo.get(chainId);
                    if (wasUsed === undefined) {
                        return;
                    }
                    if (willUse !== wasUsed) {
                        sourceSelectionInfo.chainsInfo.set(chainId, willUse)
                        bChanged = true;
                    }
                })

                // save if map section has changed
                if (bChanged) {

                    MapSection.SensorIds = mapToListOfKeys(sourceSelectionInfo.sensorsInfo, v => v);
                    MapSection.ChainIds = mapToListOfKeys(sourceSelectionInfo.chainsInfo, v => v);

                    page.selectionInfo.mapSectionInfo.set(MapSection.Id, sourceSelectionInfo); // just formal refresh, I'm guess it is not required
                    page.mapSections.set(MapSection.Id, {
                        ...restProps,
                        MapSection,
                        IsDirty: true,
                        Timestamp: Date.now()
                    });
                }
            }
        }
    }

    // update geovis images on this page
    if (changedPageGeovisImagesIds) {

        // !!!!!!!!!!!!!!
        // WARNING: there is the same copy-paste code, it is needed to change it in second iteration
        const { unitASensors } = sensorsSelectionInfo;

        const changedGeovisImagePageNumbers = Object.keys(changedPageGeovisImagesIds);

        for (const pageNum of changedGeovisImagePageNumbers) {

            const page = pages.get(+pageNum);
            if (!page) {
                continue;
            }

            const imageIds = changedPageGeovisImagesIds[pageNum];
            if (!imageIds) {
                continue;
            }

            for (const { GeovisImage, ...restProps } of mapToListOfElementsOfIds(page.geovisImages, imageIds)) {

                const sourceSelectionInfo = page.selectionInfo.geovisImageInfo.get(GeovisImage.Id);
                if (!sourceSelectionInfo) {
                    throw Error(`chartSensorsDialog_fillSensorsForGeovisElements: Initialization error, geovis image ${GeovisImage.Name} on page ${pageNum} doesn't have initialized sensors selection info`);
                }

                let bChanged = false;

                unitASensors.forEach((willUse, sensorId) => {

                    const wasUsed = sourceSelectionInfo.sensorsInfo.get(sensorId);

                    // TODO: check it.
                    // but if no info about this sensor in the map, then it is not used in the map
                    // the same as for MapSection
                    if (wasUsed === undefined) {
                        return;
                    }

                    if (willUse !== wasUsed) {
                        sourceSelectionInfo.sensorsInfo.set(sensorId, willUse)
                        bChanged = true;
                    }
                });

                // save if map section has changed
                if (bChanged) {

                    GeovisImage.SensorsIds = mapToListOfKeys(sourceSelectionInfo.sensorsInfo, v => v);
                    // всю логику расковырять не удалось, поэтому заккоментировано
                    // если раскоментировать: при выключение сенсоров изменения срабатывают, а при включение обратно нет
                    // GeovisImage.Sensors = GeovisImage.Sensors.filter(s => GeovisImage.SensorsIds.includes(s.FullId))

                    page.selectionInfo.geovisImageInfo.set(GeovisImage.Id, sourceSelectionInfo); // just formal refresh, I'm guess it is not required
                    page.geovisImages.set(GeovisImage.Id, {
                        ...restProps,
                        GeovisImage,
                        IsDirty: true,
                        Timestamp: Date.now()
                    });
                }
            }
        }
    }

    return pages;
}

// #region Communication with iframe of the report

/**
 * Send request to the report frame and get its response
 * @param frame 
 * @param request 
 */
export const sendMessageToReportFrameAsync = async <TResponse>(frame: HTMLIFrameElement, request: ISendMessageToReportFrameRequest): Promise<ISendMessageToReportFrameResponse<TResponse>> => {

    const id = Date.now().toString();
    request.id = id;
    let response: ISendMessageToReportFrameResponse<TResponse> = { id, status: "Pending" };

    const responseListener = (event: MessageEvent) => {

        Logger.trace(`incoming request: ${event.origin}`);

        if (!event.data || !event.data.id || event.data.id !== id) {
            return;
        }

        response = event.data as ISendMessageToReportFrameResponse<TResponse>;
    };

    window.addEventListener("message", responseListener);

    let timerIntervalId;
    let timerTimeoutId;

    const promise = new Promise<boolean>((resolve, reject) => {

        if (!frame.contentWindow) {
            reject("No frame content window");
            return;
        }

        frame.contentWindow.postMessage(request, GEOVIS_LIVE_API_ROOT);

        let timeoutCalled = false;
        let intervalCalled = false;
        const msInterval = 100;
        const msTimeout = 20000; // increased timeout to 20 seconds, on some slow machines it cause reject of promise

        timerIntervalId = setInterval(() => {
            if (intervalCalled) {
                return;
            }

            if (timeoutCalled) {
                intervalCalled = true;
                reject(`Timeout (${msTimeout}ms) finished`);
            } else if (response.status !== "Pending") {
                intervalCalled = true;
                Logger.trace(`timer resolved. response.status=${response.status}`);
                resolve(true);
            }

            Logger.trace('timer tick');
        }, msInterval);

        timerTimeoutId = setTimeout(() => {
            timeoutCalled = true;
        }, msTimeout);
    });

    await promise;

    clearInterval(timerIntervalId);
    clearTimeout(timerTimeoutId);

    window.removeEventListener("message", responseListener);
    return response;
}


//#endregion

//#region  Validation

/**
 * Get invalid chart property info after json serialization error
 * @param jsonPath 
 * @param charts 
 * @returns array with property path
 */
// todo: make sense to cover with test. it is easy and important function
export const getInvalidChartPropertyPath = (jsonPath: string, charts: ChartModel[]): string[] => {
    const parts = jsonPath.split('.');

    if (parts.length < 2) {
        return [];
    }

    const chartIndex = +parts[0].trim().replace('[', '').replace(']', '');
    if (chartIndex < 0 || chartIndex >= charts.length) {
        return [];
    }

    const chart = charts[chartIndex];
    return [ReportObjectType.Chart, chart.id.toString(), ...parts.slice(1)];
}

//#endregion

export const allowedDtsCloseTSensors = (dtsChart: DtsChartModel) => {
    if (dtsChart.LeftYAxisSettings.TypeOfSensor === SensorCategory.Temperature || dtsChart.RightYAxisSettings.TypeOfSensor === SensorCategory.Temperature) {
        return dtsChart.ShowCloseTemperatureSensorsAsPoints.value;
    }
    return false;
}

export const isChartSettingsBool = (toCheck: any): boolean => {
    if (toCheck === undefined || toCheck === null) {
        return false;
    }

    if (toCheck.customerChangeable === undefined || toCheck.value === undefined) {
        return false;
    }
    return typeof toCheck.value === "boolean";
}

// export const getMapSectionTimeslot = (mapSection: MapSectionModel) => {
//     return mapSection.TimeslotSetting ? mapSection.TimeslotSetting.Value : "";
// }