/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 28.12.2020
 * @description Project history table cell component
 */

import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import Popup, { TriggerProps } from '@atlaskit/popup';
import React, { useState } from 'react';
import { ProjectHistoryModel } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectHistoryModel';
import { ProjectHistoryPropertyHistory } from './ProjectHistoryPropertyHistory';

interface IProjectHistoryTableCell {
    historyId: string;
    propertyName: keyof ProjectHistoryModel;
    showChevron?: boolean;

    content: string | React.ReactNode;
}

export const ProjectHistoryTableCell = ({
    content,
    historyId,
    propertyName,
    showChevron
}: IProjectHistoryTableCell) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const setIsOpenHandler = () => setIsOpen(!isOpen);
    const onCloseHandler = () => setIsOpen(false);

    return (
        <Popup
            isOpen={isOpen}
            onClose={onCloseHandler}
            placement="bottom-end"
            content={() => (
                <ProjectHistoryPropertyHistory
                    historyId={historyId}
                    propertyName={propertyName}
                />
            )}
            trigger={(props: TriggerProps) => (
                <Button
                    {...props}
                    appearance={showChevron ? "subtle" : "subtle-link"}
                    spacing="compact"
                    iconAfter={showChevron ? <ChevronDownIcon label="more" size="medium" /> : undefined}
                    onClick={setIsOpenHandler} >
                    {content}
                </Button>
            )}
        />
    )
}