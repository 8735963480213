//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GEOvisDXFLayerType {
	Map = 0,
	ProfileView = 1,
	SideView = 2,
	Nothing = 100
}

const GEOvisDXFLayerTypeToName = new Map<number, string>([
	[ 0, 'Map' ],
	[ 1, 'ProfileView' ],
	[ 2, 'SideView' ],
	[ 100, 'Nothing' ],
])

export function getGEOvisDXFLayerTypeToName(element: GEOvisDXFLayerType) : string {
    return GEOvisDXFLayerTypeToName.get(element) || '';
}

const GEOvisDXFLayerTypeByName = new Map<string, number>([
	[ 'Map', 0 ],
	[ 'ProfileView', 1 ],
	[ 'SideView', 2 ],
	[ 'Nothing', 100 ],
]);

export function getGEOvisDXFLayerTypeByName(name: string): GEOvisDXFLayerType {
    return GEOvisDXFLayerTypeByName.get(name)!;
}


export const GEOvisDXFLayerTypeList : Readonly<GEOvisDXFLayerType[]> = [
	GEOvisDXFLayerType.Map,
	GEOvisDXFLayerType.ProfileView,
	GEOvisDXFLayerType.SideView,
	GEOvisDXFLayerType.Nothing,
];



const GEOvisDXFLayerTypeToDescription = new Map<number, string>([
	[ 0, 'Map' ],
	[ 1, 'Profile view' ],
	[ 2, 'Side view' ],
	[ 100, 'Nothing' ],
])

export function getGEOvisDXFLayerTypeToDescription(element: GEOvisDXFLayerType) : string {
    return GEOvisDXFLayerTypeToDescription.get(element) || '';
}

