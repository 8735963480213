//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisUserCommonInfo } from './GeovisUserCommonInfo';
import { GeovisUserRoleEnum } from '../../../AVTService/TypeLibrary/Identity/GeovisUserRoleEnum';
import { GeovisUserProjectRelationModel } from '../../../AVTService/TypeLibrary/Identity/GeovisUserProjectRelationModel';
import { UserToProjectRelationChangeModel } from './UserToProjectRelationChangeModel';
import { LicensedFeatures } from '../../../AVTService/TypeLibrary/Licensing/LicensedFeatures';
import { GeovisCompanyType } from '../../../AVTService/TypeLibrary/Common/GeovisCompanyType';
import { GeovisUserRESTAPIEnum } from '../../../AVTService/TypeLibrary/Identity/GeovisUserRESTAPIEnum';

export class GeovisUserProfileInfo extends GeovisUserCommonInfo
{
	public GeovisUserRole: GeovisUserRoleEnum;
	public ProjectRelationInfos: GeovisUserProjectRelationModel[];
	public ChangePasswordForbidden: boolean;
	public LanguageCode: string;
	public AvatarBase64Content: string;
	public RelationChanges: UserToProjectRelationChangeModel[];
	public CompanyAvailableFeatures: LicensedFeatures[];
	public CompanyNewInvoiceEnabled: boolean;
	public CompanyType: GeovisCompanyType;
	public RESTApiAllowed: boolean;
	public RESTApiRole: GeovisUserRESTAPIEnum;
	public RESTApiRestrictDbs: boolean;
	public RESTApiAllowedDbs: string[];
}
