//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ChartAlarmLineType {
	Solid = 0,
	Dash = 1,
	DotDash = 2,
	Dot = 3
}

const ChartAlarmLineTypeToName = new Map<number, string>([
	[ 0, 'Solid' ],
	[ 1, 'Dash' ],
	[ 2, 'DotDash' ],
	[ 3, 'Dot' ],
])

export function getChartAlarmLineTypeToName(element: ChartAlarmLineType) : string {
    return ChartAlarmLineTypeToName.get(element) || '';
}

const ChartAlarmLineTypeByName = new Map<string, number>([
	[ 'Solid', 0 ],
	[ 'Dash', 1 ],
	[ 'DotDash', 2 ],
	[ 'Dot', 3 ],
]);

export function getChartAlarmLineTypeByName(name: string): ChartAlarmLineType {
    return ChartAlarmLineTypeByName.get(name)!;
}


export const ChartAlarmLineTypeList : Readonly<ChartAlarmLineType[]> = [
	ChartAlarmLineType.Solid,
	ChartAlarmLineType.Dash,
	ChartAlarmLineType.DotDash,
	ChartAlarmLineType.Dot,
];



const ChartAlarmLineTypeToDescription = new Map<number, string>([
	[ 0, 'Solid' ],
	[ 1, 'Dash' ],
	[ 2, 'DotDash' ],
	[ 3, 'Dot' ],
])

export function getChartAlarmLineTypeToDescription(element: ChartAlarmLineType) : string {
    return ChartAlarmLineTypeToDescription.get(element) || '';
}

