/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @crated 28.07.2023
 * @description Leaflet pane creator component
 */

import { useEffect } from "react";
import { useMap } from "react-leaflet";

export const MovementVectorsPaneName = "geovis-movement-vectors";
export const MovementBarsPaneName = "geovis-movement-bars";
export const SensorMarkersPaneName = "geovis-markers";

const OrderedMarkerPaneNames = [MovementBarsPaneName, SensorMarkersPaneName, MovementVectorsPaneName];

/**
 * This component creates main pane: Sensors and Chains, Movement vectors and Movement bars
 * @returns 
 */
export const GeovisMapPaneCreator = () => {

    const map = useMap();

    useEffect(() => {

        map.invalidateSize();

        const panes = OrderedMarkerPaneNames
            .map<HTMLElement>(name => map.createPane(name))
            .map<HTMLElement>(el => {
                // el.style.display = "contents";
                return el;
            });

        return function unmount() {

            for (const pane of panes) {
                pane.remove();
            }
        }
    }, []);

    return null;
}