import { ReactRenderer } from '@atlaskit/renderer';
import { useEffect, useState } from 'react';
import { LoadingPageErrorSkeleton } from '../../../../components/LoadingPageErrorSkeleton';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../helpers/GeovisHooks';
import { fetchServerElements } from '../../../../helpers/ProjectDataHelper';
import { t } from '../../../../i18n';
import { GeovisCommentModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisCommentModel';
import ServerRoutesGen from '../../../../server/Routes/ServerRoutesGen';
import { processFetchedData } from '../../../../store/helpers/DataHelper';
import { defaultSomethingStorageState, ISomethingStorageBaseEx } from '../../../../store/types';
import { ChartLoadingSkeleton } from './charts/renders/ChartLoadingComponent';
import "./geovis.Highcharts.SVGRenderer"; // required for drawing the sensors symbols
import { createCommentRenderedFunc } from './tools';
import { IReportElementRenderHostOwnProp } from './types';

type ICommentState = ISomethingStorageBaseEx<GeovisCommentModel>

interface IComponentProps extends IWithGeovisServerProps, Omit<IReportElementRenderHostOwnProp, 'settingsId' | 'isDefault' | 'isVibrationEventChart' | 'eventInfo' | 'showVibrationEventChartOnPage'> {

}

const commentInitialState: ICommentState = ({
    ...defaultSomethingStorageState,
    data: { ...new GeovisCommentModel() }
});

const CommentRenderComponent = ({
    Server,
    elementInfo: { Height, Id, DrawBorder, HideTitle },
    projectId, pageNum, elementIndexOnPage, lastInGroup, isPrinting, userId, userToken }: IComponentProps) => {

    const [state, setState] = useState<ICommentState>(commentInitialState);

    useEffect(() => {
        if (Id <= 0) {
            return;
        }

        (async function loadGeovisCommentInfo() {
            const route =
                isPrinting
                    ? ServerRoutesGen.ReportPdfRenderData.GetGeovisComment.patch(projectId, userToken, userId, Id)
                    : ServerRoutesGen.GeovisComment.Get.patch(projectId, Id);

            const response = await fetchServerElements<GeovisCommentModel>(Server, route);

            setState(processFetchedData(response, state, commentInitialState, st => ({
                data: st
            })));
        })();

    }, [Id]);

    if (state.isLoaded) {
        setTimeout(() => {
            createCommentRenderedFunc(pageNum, Id);
        }, 1000);
    }

    return (
        <div style={{ height: `${Height}px`, boxSizing: `border-box`, /*width: '100%',*/ overflow: 'hidden', padding: '10px', borderRight: DrawBorder && !lastInGroup ? '2px solid black' : '' }}>
            {/* <div style={{ display: 'flex', flexDirection: 'column', height: `100%` }}> */}
            {state.isLoading && (
                <ChartLoadingSkeleton text={t("Loading comment...")} />
            )}
            {state.isError && (
                <LoadingPageErrorSkeleton errorText={t("Error load comment")} errorDescription={state.errorDescription} />
            )}
            {state.isLoaded && !state.isError && state.data.Title && !HideTitle && (
                <div className='report-render-element-title' style={{ borderTop: (elementIndexOnPage > 0 ? '2px solid black' : '') }}>{state.data.Title}</div>
            )}
            {state.isLoaded && !state.isError && (
                // <EditorContext>
                //     <div style={{ display: 'flex', flexDirection: 'column', height: `100%`, width: `100%` }}>
                //         <ReactRenderer
                //             document={state.data.Document}
                //             maxHeight={Height}
                //         />
                //     </div>
                //     <div style={{ clear: 'both' }}/> // Error: React.Children.only expected to receive a single React element child.
                // </EditorContext>

                // <>
                //     <div style={{ display: 'flex', flexDirection: 'column', height: `100%`, width: `100%` }}>
                //         <ReactRenderer
                //             document={state.data.Document}
                //             maxHeight={Height}
                //         />
                //     </div>
                //     <div style={{ clear: 'both' }}/>
                // </>

                <ReactRenderer
                    document={state.data.Document}
                    maxHeight={Height}
                />
                // <div>comment</div>
            )}
            {/* </div> */}
        </div>
    );
}

export const CommentRender = withGeovisServer(CommentRenderComponent);