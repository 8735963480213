/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.07.2023
 * @description Geovis report free space component
 */

export const GeovisReportPageRenderFreeSpacer = ({ height }: { height: number }) => (
    <div style={{ height: `${height}px` }}>
        {/* free space there */}
    </div>
)