/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.04.2021
 * @description Logs actions
 */

export const LOGS_ENTRY_POINTS = 'LOGS_ENTRY_POINTS';
export const LOGS_ENTRY_POINTS_DATA = 'LOGS_ENTRY_POINTS_DATA';

export const LOGS_RECORDS = "LOGS_RECORDS";
export const LOGS_RECORDS_DATA = "LOGS_RECORDS_DATA";

export const LOGS_PROJECTS = 'LOGS_PROJECTS';
export const LOGS_PROJECTS_DATA = 'LOGS_PROJECTS_DATA';

export const LOGS_USERS_ADD = 'LOGS_USERS_ADD';

export const LOG_FILTER_CHANGED = 'LOG_FILTER_CHANGED';

export const LOGS_DETAILS_DIALOG_SHOW = 'LOGS_DETAILS_DIALOG_SHOW';
export const LOGS_DETAILS_DIALOG_CLOSE = 'LOGS_DETAILS_DIALOG_CLOSE';

export const LOGS_FOLLOW_EP_LOADING = 'LOGS_FOLLOW_EP_LOADING';
export const LOGS_FOLLOW_EP_LOADED = 'LOGS_FOLLOW_EP_LOADED';
export const LOGS_FOLLOW_RECORDS_LOADING = 'LOGS_FOLLOW_RECORDS_LOADING';
export const LOGS_FOLLOW_RECORDS_LOADED = 'LOGS_FOLLOW_RECORDS_LOADED';
export const LOGS_FOLLOW_RECORDS_ADD = 'LOGS_FOLLOW_RECORDS_ADD';

export const LOGS_FOLLOW_FILTER_CHANGED = 'LOGS_FOLLOW_FILTER_CHANGED';
export const LOGS_FOLLOW_DISMISS_EP = 'LOGS_FOLLOW_DISMISS_EP';