//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ImageBackgroundFileType {
	Blank = 0,
	DXF = 1,
	JPEG = 2,
	PNG = 3
}
