//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisUserCommonInfo } from '../User/GeovisUserCommonInfo';
import { ProjectBusinessInfo } from '../../../ProjectBusinessInfo';

export class CompanyOwnData
{
	public Users: GeovisUserCommonInfo[];
	public Projects: ProjectBusinessInfo[];
}
