//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisReportElementInfo } from './GeovisReportElementInfo';

export class GeovisReportPage
{
	public static CONTENT_HEIGHT: number = 1500;
	public static SMALLEST_ELEMENT_HEIGHT: number = 400;
	public static SMALLEST_COMMENT_HEIGHT: number = 40;
	public static MINIMUM_ELEMENT_SIZE: number = 250;
	public Elements: GeovisReportElementInfo[];
}
