/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 27.01.2023
 * @description The error dialog. Reworked
 */

import Button from '@atlaskit/button';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import IRouteParams from '../../helpers/IRouteParams';
import Routes from '../../helpers/Routes';
import i18next from '../../i18n';
import PageTitleItem from "../PageTitleItem";
import GeovisErrorReportDialog from './GeovisErrorReportDialog';

interface IGeovisFallbackComponentProps {
    error?: Error;
    componentStack?: string;
}

interface IFeedbackDialogState {
    isFeedbackSent: boolean;
    feedbackRecordId: string;
    showFeedbackDialog: boolean;
}

export const GeovisFallbackComponent = ({ error, componentStack }: IGeovisFallbackComponentProps) => {

    const [feedbackDialogState, setFeedbackDialogState] = useState<IFeedbackDialogState>({ feedbackRecordId: '', isFeedbackSent: false, showFeedbackDialog: false });

    useEffect(() => {
        if (error && error.stack) {
            if (error.stack.includes('CrashApplicationButton')) {

                (async function initSendData() {
                    await onSendFeedback();
                })();

            }
        }
    }, []);

    const projectRoute = useRouteMatch<IRouteParams>({ ...Routes.project, exact: false });
    const projectId = projectRoute === null ? undefined : projectRoute.params.projectId === undefined ? undefined : +projectRoute.params.projectId;

    const onSendFeedback = () => {
        setFeedbackDialogState({
            ...feedbackDialogState,
            showFeedbackDialog: true
        });
    }

    const onCloseFeedbackDialog = (recordId: string) => {
        setFeedbackDialogState({
            showFeedbackDialog: false,
            feedbackRecordId: recordId,
            isFeedbackSent: true
        });
    }

    return (
        <div className="page">
            <div className="flexRowContainer">
                <PageTitleItem title={i18next.t("An unexpected error occured")} />
            </div>

            {!feedbackDialogState.isFeedbackSent && (
                <div>
                    <div className="flexRowContainer" style={{ marginTop: '10px' }} >
                        <div className="flexCellContainer">
                            <div>{i18next.t("geovisFallbackUserMessage")}</div>
                        </div>
                    </div>

                    <div className="flexRowContainer" style={{ marginTop: '10px' }} >
                        <div className="flexCellContainer">
                            <div>{i18next.t("geovisFallbackReloadMessage")}</div>
                        </div>
                    </div>

                    <div className="flexRowContainer" style={{ marginTop: '10px' }}>
                        <Button
                            appearance="default"
                            type="button"
                            onClick={onSendFeedback} >
                            {i18next.t("Send Information to development team")}
                        </Button>
                    </div>
                </div>
            )}

            {feedbackDialogState.isFeedbackSent && (
                <div>
                    <div className="flexRowContainer" style={{ marginTop: '10px' }} >
                        <div className="flexCellContainer">
                            <div>{i18next.t("geovisFallbackFeedbackMesage").replace("%1", feedbackDialogState.feedbackRecordId)}</div>
                        </div>
                    </div>

                    <div className="flexRowContainer" style={{ marginTop: '10px' }} >
                        <div className="flexCellContainer">
                            <div>{i18next.t("geovisFallbackReloadMessage")}</div>
                        </div>
                    </div>
                </div>
            )}
            {feedbackDialogState.showFeedbackDialog &&
                <GeovisErrorReportDialog
                    onClose={onCloseFeedbackDialog}
                    componentStack={componentStack}
                    error={error}
                    projectId={projectId}
                />
            }
        </div>
    )
}