//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { InventoryObjectType } from '../../../AVTService/TypeLibrary/Inventory/InventoryObjectType';
import { InventoryAtcSensorOnlineStatus } from '../../../AVTService/TypeLibrary/Inventory/InventoryAtcSensorOnlineStatus';
import { InventoryStatus } from '../../../AVTService/TypeLibrary/Inventory/InventoryStatus';

export abstract class InventoryObjectInfo
{
	public Id: string;
	public ParentId: string;
	public ObjectType: InventoryObjectType;
	public IsOnline: InventoryAtcSensorOnlineStatus;
	public LastUpdate: string;
	public UserComment: string;
	public UsagePlanComment: string;
	public Status: InventoryStatus;
	public StatusDescription: string;
	public StatusEndDate: string;
	public DeviceName: string;
}
