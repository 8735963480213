/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.10.2022
 * @description Simple hooks to reduce code size
 */

import { useState } from "react";

/**
 * Dialog visibility simple hook
 * @param initialValue 
 * @returns [visibility, set visible handler, set hidden handler]
 */
export const useGeovisDialogVisibilityHook = (initialValue: boolean = false): [boolean, () => void, () => void] => {

    const [showDialog, setShowDialog] = useState<boolean>(initialValue);

    return [
        showDialog,
        () => setShowDialog(true),
        () => setShowDialog(false)
    ];
}

/**
 * Dialog visibility hook with state to pass to this dialog
 * @param initialState 
 * @param initialValue 
 * @returns 
 */
export const useGeovisDialogVisibilityHookWithState = <TState extends {}>(initialState: TState, initialValue: boolean = false): [boolean, (state: TState) => void, () => void, TState] => {

    const [{ show, state }, setHookState] = useState<{ show: boolean, state: TState }>({ state: initialState, show: initialValue });

    return [
        show,
        st => setHookState({ state: st, show: true }),
        () => setHookState({ show: false, state: initialState }),
        state
    ]
}

/**
 * Use toggle hook for a value
 * @param initialValue 
 * @returns 
 */
export const useGeovisToggleVisibilityHook = (initialValue: boolean = false): [boolean, () => void] => {

    const [show, setShow] = useState<boolean>(initialValue);

    return [
        show,
        () => setShow(!show)
    ]
}