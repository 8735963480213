export const DOCUMENTATION_DATA_LOADED = "DOCUMENTATION_DATA_LOADED";
export const DOCUMENTATION_DATA_LOADING_ERROR = "DOCUMENTATION_DATA_LOADING_ERROR";

export const DOCUMENTATION_ADD_DOCUMENT = "DOCUMENTATION_ADD_DOCUMENT";
export const DOCUMENTATION_UPDATE_DOCUMENT = "DOCUMENTATION_UPDATE_DOCUMENT";

export const DOCUMENTATION_SHOW_DELETE_DIALOG = "DOCUMENTATION_SHOW_DELETE_DIALOG";
export const DOCUMENTATION_HIDE_DELETE_DIALOG = "DOCUMENTATION_HIDE_DELETE_DIALOG";
export const DOCUMENTATION_ENTRY_DELETE = "DOCUMENTATION_ENTRY_DELETE";

export const DOCUMENTATION_UPLOAD_STARTED = "DOCUMENTATION_UPLOAD_STARTED";
export const DOCUMENTATION_UPLOAD_PROGRESS = "DOCUMENTATION_UPLOAD_PROGRESS";
export const DOCUMENTATION_UPLOAD_FINISHED = "DOCUMENTATION_UPLOAD_FINISHED";
export const DOCUMENTATION_UPLOAD_ERROR = "DOCUMENTATION_UPLOAD_ERROR";
