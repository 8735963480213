//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartData } from './GeovisChartData';
import { GeovisHeatmapSensorData } from './GeovisHeatmapSensorData';

export class HeatmapChartData extends GeovisChartData
{
	public Data: GeovisHeatmapSensorData[];
	public GlobalTimeslot: string;
}
