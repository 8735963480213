
/**
 * @author Vyacheslav Skripin <vs@iesk.ru>
 * @created 08.12.2019
 * @description Project overview page state definition
 */

import { ProjectEditSensorsTab } from "../pages/edit/sensors/types";
import { AlarmAction } from "../server/AVTService/TypeLibrary/Alarming/AlarmAction";
import { ChartType } from "../server/AVTService/TypeLibrary/Common/ChartType";
import { InventoryObjectType } from "../server/AVTService/TypeLibrary/Inventory/InventoryObjectType";
import { LogbookElementsRequest } from "../server/GEOvis3/Model/Logbook/LogbookElementsRequest";
import { SensorInfo } from "../server/GEOvis3/Model/SensorInfo";
import { IGeovisAction, } from "./types";

export type LogbookRecordActionType = "view" | "remove" | "create" | "confirm" | "edit"; // maybe "edit", but layer...

export const ProjectViewOverviewId = 'Overview';

export enum ProjectOverviewOverlay {
    None = 0,
    Layers = 1
}
export interface ILogbookRecordsTableState {
    showPublicFlag: boolean;
    canDeleteOwnRecordsOnly: boolean;
}

/**
 * Project overview action
 */
export interface IGeovisProjectOverviewAction extends IGeovisAction {
    popupOfStackedSensor?: string;
    booleanProperty?: boolean;
    stringProperty?: string;
    numberProperty?: number;
    activeViewId?: string;
    error?: Error;
    logbookRecordsViewState?: LogbookElementsRequest;
    searchElementQuery?: string;
    logbookAction?: LogbookRecordActionType;
    inventoryObjectType?: InventoryObjectType;
    logbookRecordsTableState?: ILogbookRecordsTableState;
    chartType?: ChartType;
    sensorEditType?: ProjectEditSensorsTab;
    sensor?: SensorInfo
}

/**
 * Final definition of Project Overview storage.
 */
export interface IGeovisProjectOverviewState {
    isPostInProgress: boolean;
    showLocalMapObjectContent: boolean;
    localMapObjectId: string;
    contextSensorId: string;
    rowsPerPage: number;
    activeStatisticMstAlias: string;
    drawOverlay: ProjectOverviewOverlay;
    reportId: number;
    logbookRecordsViewState: Partial<LogbookElementsRequest>;
    showLogbookRecordDialog: boolean;
    showLogbookRecordRemoveDialog: boolean;
    showAddLogbookEntryDialog: boolean;
    removeAll: boolean;
    logbookRecordDialogMode: LogbookRecordActionType;
    logbookId: string;
    embeddedDataName: string;
    informationName: string;
    inventoryViewName: string;
    inventoryObjectType: InventoryObjectType;
    alarmAction: AlarmAction;
    logbookRecordsTableState: ILogbookRecordsTableState;

    /** show the chart chooser, in case if sensor contains in more that 1 default chart */
    showDefaultChartChooseWindow: boolean;

    /**
     * Show the default charts of selected sensors (sensors in the tree, like report)
     */
    showSelectedSensorsDefaultChartView: boolean;

    /** 
     * old name 'showDefaultChartReport' 
     * show default chart of sensor
     */
    showSensorDefaultChartView: boolean;
    /**
     * old name 'sensorFullId'
     * the full sensor id to show in the default chart report dialog (see one property up)
     */
    defaultReportSensorFullId: string;

    /**
     * old name 'chartId'
     * the chart id to show in the default chart
     */
    defaultReportChartId: number;

    showAlarmEditDialog: boolean;

    alarmId: string;

    sensorFullId: string;
    showSensorEditDialog: boolean;
    sensorEditTabType: ProjectEditSensorsTab;

    showSensorImageDialog: boolean;
    sensor: SensorInfo;
}
