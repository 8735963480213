/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 05.08.2022
 * @description Project element migration details: errors and migrated elements info
 */

import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { HeadType, RowType } from '@atlaskit/dynamic-table/dist/types/types';
import EditorErrorIcon from '@atlaskit/icon/glyph/editor/error'
import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import React, { useEffect, useState } from 'react';
import Constants from '../../../../helpers/Constants';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../helpers/GeovisHooks';
import { fetchServerElements } from '../../../../helpers/ProjectDataHelper';
import { t } from '../../../../i18n';
import { getMigratebleElementsToName, MigratebleElements } from '../../../../server/AVTService/TypeLibrary/Common/ElementsMigrations/MigratebleElements';
import { ProjectElementMigrationDetails } from '../../../../server/AVTService/TypeLibrary/Common/ElementsMigrations/ProjectElementMigrationDetails';
import ServerRoutesGen from '../../../../server/Routes/ServerRoutesGen';
import { processFetchedData } from '../../../../store/helpers/DataHelper';
import { defaultSomethingStorageState, ISomethingStorageBaseEx } from '../../../../store/types';


interface IComponentProps extends IWithGeovisServerProps {
    projectId: number;
    elementType: MigratebleElements;

    onClose: () => void;
}

type IDetailsState = ISomethingStorageBaseEx<ProjectElementMigrationDetails>

const errorDetailsHead: HeadType = ({
    cells: [{
        content: 'Errors'
    }]
});

const elementsDetailsHead: HeadType = ({
    cells: [{
        content: 'State'
    }, {
        content: 'Element name'
    }, {
        content: 'Description'
    }]
});

const detailsStorageInitialState: IDetailsState = { ...defaultSomethingStorageState, data: new ProjectElementMigrationDetails() };

export const ProjectElementMigrationDetailsDialog = withGeovisServer(({ Server, elementType, projectId, onClose }: IComponentProps) => {

    const [details, setDetails] = useState<IDetailsState>(detailsStorageInitialState);

    useEffect(() => {

        (async function loadDetails() {
            const url = ServerRoutesGen.Migrations.GetProjectElementMigrationDetails.patch(projectId, elementType);
            const response = await fetchServerElements<ProjectElementMigrationDetails>(Server, url);

            setDetails(processFetchedData(response, details, detailsStorageInitialState, st => ({ data: st })));
        })();

    }, [projectId, elementType]);

    const getDetailsErrorsRows = (): RowType[] => {
        if (details.isLoading || details.isError) {
            return [];
        }

        const { Errors } = details.data;

        if (Errors.length === 0) {
            return [{ cells: [{ content: 'No errors' }] }];
        }

        return Errors.map<RowType>((e, index) => ({ key: `error-${index}`, cells: [{ content: e.ErrorDescription }] }));
    }

    const getElementsDetailsRows = (): RowType[] => {

        if (details.isLoading || details.isError) {
            return [];
        }

        const { MigratedElementsInfo } = details.data;

        return MigratedElementsInfo.map<RowType>((e, index) => ({
            key: `element-${index}`,
            cells: [{
                content: (
                    <React.Fragment>
                        {e.Success && (
                            <EditorSuccessIcon label='' primaryColor={Constants.successColor} />
                        )}
                        {!e.Success && (
                            <EditorErrorIcon label='' primaryColor={Constants.errorColor} />
                        )}
                    </React.Fragment>
                )
            }, {
                content: e.ElementName
            }, {
                content: e.ErrorDescription
            }]
        }))
    }

    return (
        <ModalTransition>
            <Modal
                heading={`${getMigratebleElementsToName(elementType)} migration details`}
                onClose={onClose}
                actions={[{ text: t("Close"), onClick: onClose }]}
                width="x-large">
                <div className='flexCellContainer'>
                    <DynamicTableStateless
                        isLoading={details.isLoading}
                        caption="Migration errors"
                        head={errorDetailsHead}
                        rows={getDetailsErrorsRows()}
                    />

                    <DynamicTableStateless
                        isLoading={details.isLoading}
                        caption="Elements details"
                        head={elementsDetailsHead}
                        rows={getElementsDetailsRows()}
                    />
                </div>
            </Modal>
        </ModalTransition>
    )
})