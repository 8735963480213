export const ELEMENTS_MIGRATIONS_PROJECTS_LOADING = 'ELEMENTS_MIGRATIONS_PROJECTS_LOADING';
export const ELEMENTS_MIGRATIONS_PROJECTS_LOADED = 'ELEMENTS_MIGRATIONS_PROJECTS_LOADED';

export const ELEMENTS_MIGRATIONS_SHOW_PROJECT_DIALOG = 'ELEMENTS_MIGRATIONS_SHOW_PROJECT_DIALOG';
export const ELEMENTS_MIGRATIONS_HIDE_PROJECT_DIALOG = 'ELEMENTS_MIGRATIONS_HIDE_PROJECT_DIALOG';

export const ELEMENTS_MIGRATIONS_UPDATE_PROJECT_INFO = 'ELEMENTS_MIGRATIONS_UPDATE_PROJECT_INFO';

export const ELEMENTS_MIGRATIONS_FILTER_CHANGED = 'ELEMENTS_MIGRATIONS_FILTER_CHANGED';

export const ELEMENTS_MIGRATIONS_SHOW_MIGRATE_ALL_DIALOG = "ELEMENTS_MIGRATIONS_SHOW_MIGRATE_ALL_DIALOG";
export const ELEMENTS_MIGRATIONS_HIDE_MIGRATE_ALL_DIALOG = "ELEMENTS_MIGRATIONS_HIDE_MIGRATE_ALL_DIALOG";