// logo
export const PROJECT_CREATE_LOGO_EDIT_SHOW_LOGO_PICKER = "PROJECT_CREATE_LOGO_EDIT_SHOW_LOGO_PICKER";
export const PROJECT_CREATE_LOGO_EDIT_HIDE_LOGO_PICKER = "PROJECT_CREATE_LOGO_EDIT_HIDE_LOGO_PICKER";
export const PROJECT_CREATE_LOGO_EDIT_SET_LOGO = "PROJECT_CREATE_LOGO_EDIT_SET_LOGO";
export const PROJECT_CREATE_LOGO_EDIT_SET_SELECTED_LOGO = "PROJECT_CREATE_LOGO_EDIT_SET_SELECTED_LOGO";
export const PROJECT_CREATE_LOGO_EDIT_UPLOAD_STARTED = "PROJECT_CREATE_LOGO_EDIT_UPLOAD_STARTED";
export const PROJECT_CREATE_LOGO_EDIT_UPLOAD_PROCESSING = "PROJECT_CREATE_LOGO_EDIT_UPLOAD_PROCESSING";
export const PROJECT_CREATE_LOGO_EDIT_UPLOAD_ERROR = "PROJECT_CREATE_LOGO_EDIT_UPLOAD_ERROR";
export const PROJECT_CREATE_LOGO_EDIT_UPLOAD_END = "PROJECT_CREATE_LOGO_EDIT_UPLOAD_END";

export const PROJECT_CREATE_LOGOS_LOADING = "PROJECT_CREATE_LOGOS_LOADING";
export const PROJECT_CREATE_LOGOS_LOADING_ERROR = "PROJECT_CREATE_LOGOS_LOADING_ERROR";
export const PROJECT_CREATE_LOGOS_LOADED = "PROJECT_CREATE_LOGOS_LOADED";

// data

export const PROJECT_CREATE_LEADERS_LOADING = "PROJECT_CREATE_LEADERS_LOADING";
export const PROJECT_CREATE_LEADERS_LOADED = "PROJECT_CREATE_LEADERS_LOADED";
export const PROJECT_CREATE_LEADERS_LOADING_ERROR = "PROJECT_CREATE_LEADERS_LOADING_ERROR";

export const PROJECT_CREATE_COMPANIES_LOADING = "PROJECT_CREATE_COMPANIES_LOADING";
export const PROJECT_CREATE_COMPANIES_LOADED = "PROJECT_CREATE_COMPANIES_LOADED";
export const PROJECT_CREATE_COMPANIES_LOADING_ERROR = "PROJECT_CREATE_COMPANIES_LOADING_ERROR";

export const PROJECT_CREATE_PROJECT_CHANGED = "PROJECT_CREATE_PROJECT_CHANGED";

export const PROJECT_CREATE_CLEAN_STORAGE = "PROJECT_CREATE_CLEAN_STORAGE";

export const PROJECT_CREATE_SHOW_MAP_MODAL = "PROJECT_CREATE_SHOW_MAP_MODAL";
export const PROJECT_CREATE_HIDE_MAP_MODAL = "PROJECT_CREATE_HIDE_MAP_MODAL";
export const PROJECT_CREATE_SELECTED_LOCATION_CHANGED = "PROJECT_CREATE_SELECTED_LOCATION_CHANGED";


