import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { AlarmActionTestPayload } from "../../../../server/GEOvis3/Model/Alarms/AlarmActionTestPayload";
import { t } from "../../../../i18n";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import { sendServerPostRequestData } from "../../../../helpers/ProjectDataHelper";
import FlagService from "../../../../services/FlagService";

interface IComponentProps extends IWithGeovisServerProps {
    payload: AlarmActionTestPayload;
    showDialog: boolean;
    projectId: number
    forConfirm: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const AlarmActionTestConfirmAGMSDODialog = withGeovisServer(({
    payload,
    showDialog,
    Server,
    projectId,
    onClose,
    onConfirm,
    forConfirm
}: IComponentProps) => {
    if (!showDialog) {
        return null;
    }

    const onConfirmClick = async () => {
        const url = ServerRoutesGen.Alarms.TestAlarmActionExecute.patch(projectId);
        const response = await sendServerPostRequestData(Server, url, payload);

        if (!response.Success) {
            FlagService.addError("Failed to test action", response.Messages.join("; "));
        }

        onConfirm();
    }

    return (
        <ModalTransition>
            <Modal
                heading={t("Confirm changing AGMS Digital output state")}
                actions={[
                    { text: t("Confirm"), appearance: 'primary', onClick: onConfirmClick },
                    { text: t("Cancel"), appearance: 'default', onClick: onClose }
                ]}
            >
                <div>
                    <span>{t(`Are you sure to test this AGMS Digital output ${forConfirm ? "confirmation" : "action"}?`)}</span>
                </div>

            </Modal>
        </ModalTransition>
    )
})