import Textfield from "@atlaskit/textfield";
import { SyntheticEvent } from "react"
import { t } from '../../../i18n';
import { AxisScaleLimit } from "../../../server/AVTService/TypeLibrary/Model/AxisScaleLimit"
import { ChartSettingCheckbox } from "./ChartTab_tools";
import { onChangeTextPropertyDebounced } from "./tools";
import { ElementSettingAllowCheckbox } from "../ElementSettingAllowCheckbox";

interface IAxisScaleLimitControlProps {
    scaleLimit: AxisScaleLimit;
    tbLabel: string;

    hideExact?: boolean;
    hideAuto?: boolean;
    hideCustomerChangeable?: boolean;

    isDisabled?: boolean;

    onChange: (value: AxisScaleLimit) => void;
}

const AxisScaleLimitControl = ({
    scaleLimit,
    tbLabel,
    hideAuto,
    hideExact,
    onChange,
    hideCustomerChangeable,
    isDisabled
}: IAxisScaleLimitControlProps) => {
    const onChangeLimitValueField = (event: SyntheticEvent<HTMLInputElement>) => {
        onChangeTextPropertyDebounced(+event.currentTarget.value, (value: number) => {
            onChange({ ...scaleLimit, value });
        });
    }

    const onChangeExactValue = (exact: boolean) => onChange({ ...scaleLimit, exact });
    const onChangeAutoValue = (auto: boolean) => onChange({ ...scaleLimit, auto });
    const onChangeAllowValue = (customerChangeable: boolean) => onChange({ ...scaleLimit, customerChangeable });

    return (
        <div className="flexRowContainer" style={{ display: 'flex', alignItems: "center" }}>
            <div className="flexCellContainer" style={{ width: '30%' }}>
                <label htmlFor="tbLimitValue">{tbLabel}:</label>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", flexGrow: 1 }}>
                <div className="flexCellContainer" style={{ width: '75px' }}>
                    <Textfield
                        name="tbLimitValue"
                        isCompact={true}
                        defaultValue={scaleLimit.value || 0}
                        isDisabled={isDisabled}
                        onChange={onChangeLimitValueField} />
                </div>

                {!hideExact && (
                    <ChartSettingCheckbox
                        label={t("Exact")}
                        isChecked={scaleLimit.exact}
                        onChange={onChangeExactValue}
                        isDisabled={isDisabled ?? false}
                    />
                )}

                {!hideAuto && (
                    <ChartSettingCheckbox
                        label={t("Auto")}
                        isChecked={scaleLimit.auto}
                        onChange={onChangeAutoValue}
                        isDisabled={isDisabled ?? false}
                    />
                )}
            </div>

            {!hideCustomerChangeable &&
                <ElementSettingAllowCheckbox
                    isChecked={scaleLimit.customerChangeable}
                    onChange={onChangeAllowValue}
                    isDisabled={isDisabled ?? false}
                />
            }

        </div>
    )
}

export default AxisScaleLimitControl;