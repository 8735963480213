//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ChainFixedPoint {
	None = 0,
	Start = 1,
	End = 2
}

const ChainFixedPointToName = new Map<number, string>([
	[ 0, 'None' ],
	[ 1, 'Start' ],
	[ 2, 'End' ],
])

export function getChainFixedPointToName(element: ChainFixedPoint) : string {
    return ChainFixedPointToName.get(element) || '';
}

const ChainFixedPointByName = new Map<string, number>([
	[ 'None', 0 ],
	[ 'Start', 1 ],
	[ 'End', 2 ],
]);

export function getChainFixedPointByName(name: string): ChainFixedPoint {
    return ChainFixedPointByName.get(name)!;
}


export const ChainFixedPointList : Readonly<ChainFixedPoint[]> = [
	ChainFixedPoint.None,
	ChainFixedPoint.Start,
	ChainFixedPoint.End,
];



const ChainFixedPointToDescription = new Map<number, string>([
	[ 0, 'None' ],
	[ 1, 'Start' ],
	[ 2, 'End' ],
])

export function getChainFixedPointToDescription(element: ChainFixedPoint) : string {
    return ChainFixedPointToDescription.get(element) || '';
}

