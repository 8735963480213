//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartData } from './GeovisChartData';
import { HtmlReportSensorDescriptionViewModel } from '../Report/Model/HtmlReportSensorDescriptionViewModel';
import { TimePeriod } from '../Common/TimePeriod';
import { HtmlReportXyChartLineInfo } from '../Report/Model/HtmlReportXyChartLineInfo';

export class XYVibrationChartData extends GeovisChartData
{
	public WarningSensors: string[];
	public SensorsIds: { [key:number]: string };
	public Descriptions: HtmlReportSensorDescriptionViewModel[];
	public Period: TimePeriod;
	public Lines: HtmlReportXyChartLineInfo[];
	public ColorIntensivityPercentageMap: { [key:number]: number };
	public ColorIntensivityBrightnessCoefficientMap: { [key:number]: number };
	public IntensivityToDates: { [key:number]: string };
}
