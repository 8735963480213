
import { cloneDeep } from 'lodash';
import { defaultSomethingStorageState, ISomethingStorageBaseEx } from '../store/types';


/**
 * Create initial data storage state
 * @param defaultData 
 * @returns 
 */
export const getInitialStorageState = <TData extends any>(defaultData: TData): ISomethingStorageBaseEx<TData> => ({
    ...defaultSomethingStorageState,
    data: defaultData
})

/**
 * Convert map all elements to list
 * @param storage 
 * @param filterFunc the filter function for each storage element
 */
export const mapToListOfElements = <TKey, TValue>(storage: Map<TKey, TValue>, filterFunc?: (tval: TValue, tkey: TKey) => boolean): TValue[] => {
    const result: TValue[] = [];

    storage.forEach((value: TValue, key: TKey) => {
        if (filterFunc && filterFunc(value, key)) {
            result.push(value);
        }
        else if (!filterFunc) {
            result.push(value);
        }
    });

    return result;
}

/**
 * Convert map to key-value array
 * @param map 
 * @returns 
 */
export const mapToListOfKeyValuePairs = <TKey, TValue>(map: Map<TKey, TValue>): Array<[TKey, TValue]> => {

    const result: Array<[TKey, TValue]> = [];

    map.forEach((value, key) => {
        result.push([key, value]);
    })

    return result;
}

/**
 * Convert map to list of elements and apply sorting
 * @param storage 
 * @param sortFunc 
 * @param filterFunc 
 */
export const mapToListOfElementsAndSort = <TKey, TValue>(storage: Map<TKey, TValue>, sortFunc: (a: TValue, b: TValue) => number, filterFunc?: (tval: TValue, tkey: TKey) => boolean): TValue[] => {
    return mapToListOfElements(storage, filterFunc).sort(sortFunc);
}

/**
 * Convert map to list and sort them by key
 * @param storage 
 * @param sortFunc 
 * @param filterFunc 
 * @returns 
 */
export const mapToListOfElementsAndSortByKeys = <TKey, TValue>(storage: Map<TKey, TValue>, sortFunc?: (a: TKey, b: TKey) => number, filterFunc?: (tval: TValue, tkey: TKey) => boolean): TValue[] => {

    const keys = mapToListOfKeys(storage, filterFunc);

    if (sortFunc) {
        keys.sort(sortFunc);
    }
    else {
        keys.sort();
    }

    const result: TValue[] = [];

    for (const key of keys) {
        const item = storage.get(key);
        if (item) {
            result.push(item);
        }
    }

    return result;

}

/**
 * Convert map elements to list and return only expected elements
 * If list of elements is null or undefined, then will be returns all elements of the map, used mapToListOfElements
 * Otherwise will be returns list of elements, which are in <param name="elementIds" />
 * @param storage 
 * @param elementIds expected element ids
 * @param filterFunc the filter functions for storage elements
 */
export const mapToListOfElementsOfIds = <TKey, TValue>(storage: Map<TKey, TValue>, elementIds?: TKey[], filterFunc?: (tval: TValue, tkey: TKey) => boolean): TValue[] => {
    const result: TValue[] = [];

    if (!elementIds) {
        return mapToListOfElements(storage, filterFunc);
    }

    elementIds.forEach(elementId => {
        const element = storage.get(elementId);
        if (element) {
            if (filterFunc && filterFunc(element, elementId)) {
                result.push(element);
            }
            else if (!filterFunc) {
                result.push(element);
            }
        }
    });

    return result;
}

/**
 * Get list of map elements without expected element ids
 * @param storage 
 * @param elementIds 
 */
export const mapToListOfElementsWithoutIds = <TKey, TValue>(storage: Map<TKey, TValue>, elementIds: TKey[], filterFunc?: (tval: TValue) => boolean): TValue[] => {
    const result: TValue[] = [];

    storage.forEach((value, id) => {
        if (!elementIds || elementIds.indexOf(id) === -1) {
            // skip elements, which are not 
            if (filterFunc && !filterFunc(value)) {
                return;
            }

            result.push(value);
        }
    });

    return result;
}

/**
 * Convert list of elements to map, key is the element id
 * @param elements 
 */
export const elementsToMap = <TKey, TValue extends { Id: TKey }>(elements: TValue[], sortFunc?: (a: TValue, b: TValue) => number): Map<TKey, TValue> => {
    const map = new Map<TKey, TValue>();

    if (!elements) {
        return map;
    }

    if (sortFunc) {
        elements = elements.sort(sortFunc);
    }

    for (const element of elements) {
        map.set(element.Id, element);
    }

    return map;
}

export const elementsToMapWithValue = <TKey, TValue>(keys: TKey[], getValueFunc: (v: TKey) => TValue): Map<TKey, TValue> => {

    const result = new Map<TKey, TValue>();
    for (const key of keys) {
        result.set(key, getValueFunc(key));
    }

    return result;
}

/**
 * Make map of elements by custom key
 * @param elements 
 * @param makeKey 
 */
export const elementsToMapOfCustomKey = <TElement, TCommonKey>(elements: TElement[], makeKey: (e: TElement) => TCommonKey, sortFunc?: (a: TElement, b: TElement) => number): Map<TCommonKey, TElement> => {
    const map = new Map<TCommonKey, TElement>();

    const sortedElements = sortFunc ? elements.sort(sortFunc) : elements;

    for (const element of sortedElements) {
        map.set(makeKey(element), element);
    }

    return map;
}

/**
 * Group elements by custom key
 * @param elements 
 * @param makeKey 
 * @returns 
 */
export const elementsToMapOfListByCustomKey = <TElement, TCommonKey>(elements: TElement[], makeKey: (e: TElement) => TCommonKey): Map<TCommonKey, TElement[]> => {

    const result = new Map<TCommonKey, TElement[]>();

    for (const element of elements) {
        const key = makeKey(element);
        const values = result.get(key);

        if (!values) {
            result.set(key, [element]);
        } else {
            result.set(key, [...values, element]);
        }
    }

    return result;
}

/**
 * Make a map from array of elements
 * Convert source element to Key-Value pair for map
 * @param elements 
 * @param convertFn 
 */
export const elementsToMapWithConverted = <TSourceElement, TDestKey, TDestValue>(elements: TSourceElement[], convertFn: (e: TSourceElement) => [TDestKey, TDestValue]): Map<TDestKey, TDestValue> => {

    const result = new Map<TDestKey, TDestValue>();

    if (elements) {
        for (const element of elements) {
            const [key, value] = convertFn(element);
            result.set(key, value);
        }
    }

    return result;
}

/**
 * Convert source map to dest map
 * @param source 
 * @param convertFn 
 */
export const elementsConvertToDestMap = <TSourceElement, TDestKey, TDestValue>(
    source: TSourceElement[],
    convertFn: (e: TSourceElement) => [TDestKey, TDestValue]): Map<TDestKey, TDestValue> => {

    const result = new Map<TDestKey, TDestValue>();

    if (!source) {
        return result;
    }

    source.forEach(e => {

        const [dKey, dValue] = convertFn(e);
        result.set(dKey, dValue);

    });

    return result;
}

/**
 * Converts the key-value object to map
 * @param source 
 * @returns 
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const keyValueObjectToMap = <TKey, TValue>(source: { [key: string]: TValue }): Map<string, TValue> => {
    const result = new Map<string, TValue>();
    for (const property of Object.keys(source)) {
        result.set(property, source[property]);
    }

    return result;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const numberKeyValueObjectToMap = <TKey, TValue>(source?: { [key: number]: TValue }): Map<number, TValue> => {
    if (!source) {
        return new Map<number, TValue>();
    }
    const result = new Map<number, TValue>();
    for (const property of Object.keys(source)) {
        result.set(+property, source[property]);
    }

    return result;
}

/**
 * Convert number keyed map to object
 * @param map 
 * @returns 
 */
export const numberMapToKeyValueObject = <TValue>(map: Map<number, TValue>): { [key: number]: TValue } => {

    const result: { [key: number]: TValue } = {};

    map.forEach((value, key) => {
        result[key] = value;
    });

    return result;
}

/**
 * Add or replace elements in array
 * @param sourceElements 
 * @param newElements 
 * @param elementToKeyFn 
 */
export const addOrUpdateElementsInArray = <TElement extends any>(sourceElements: TElement[], newElements: TElement[], elementToKeyFn: (e: TElement) => string): TElement[] => {
    if (!newElements || newElements.length === 0) {
        return sourceElements;
    }

    const newElementsMap = elementsToMapOfCustomKey(newElements, elementToKeyFn);

    const resultElements = sourceElements.map<TElement>(element => {
        const eKey = elementToKeyFn(element);
        const newElement = newElementsMap.get(eKey);

        if (newElement) {
            newElementsMap.delete(eKey);
            return newElement;
        }

        return element;
    });

    if (newElementsMap.size > 0) {
        resultElements.push(...mapToListOfElements(newElementsMap));
    }

    return resultElements;
}

/**
 * Update elements in the array
 * @param elements 
 * @param changed
 * @param makeKeyFn 
 */
export const updateElementsInArray = <TElement, TKey>(elements: TElement[], changes: TElement[], makeKeyFn: (e: TElement) => TKey): TElement[] => {

    const changesMap = elementsToMapOfCustomKey(changes, makeKeyFn);
    const storageMap = elementsToMapOfCustomKey(elements, makeKeyFn);

    for (const key of mapToListOfKeys(changesMap)) {
        if (storageMap.has(key)) { // update only exist elements
            storageMap.set(key, changesMap.get(key)!);
        }
    }

    return mapToListOfElements(storageMap);
}

/**
 * Convert list of elements to map, key is the element id
 * Copy of method "elementsToMap", but in case when element has property "id" instead of "Id"
 * @param elements 
 */
export const elementToMapLowerCase = <TKey, TValue extends { id: TKey }>(elements: TValue[]): Map<TKey, TValue> => {
    const map = new Map<TKey, TValue>();
    elements.forEach(element => {
        map.set(element.id, element);
    });

    return map;
}

/**
 * Create a map of elements with generated key
 * @param elements 
 * @param keyFunc 
 */
export const elementsToMapOfHash = <TKey, TValue>(elements: TValue[], keyFunc: (element: TValue) => TKey): Map<TKey, TValue> => {
    const map = new Map<TKey, TValue>();

    elements.forEach(element => {
        const key = keyFunc(element);
        map.set(key, element);
    });

    return map;
}

/**
 * Insert elements in to map, returns new instance of the map
 * @param map 
 * @param elements 
 */
export const joinMapElements = <TKey, TValue extends { Id: TKey }>(map: Map<TKey, TValue>, elements: TValue[]): Map<TKey, TValue> => {
    const result = new Map<TKey, TValue>(map);
    elements.forEach(element => {
        result.set(element.Id, element);
    });

    return result;
}

/**
 * Delete element of the map
 * @param map 
 * @param objectId 
 */
export const deleteElementOfMap = <TKey, TValue>(map: Map<TKey, TValue>, objectId: TKey): Map<TKey, TValue> => {
    map.delete(objectId);
    return map;
}

/**
 * Delete elements of the map
 * @param map 
 * @param elementIds 
 */
export const deleteElementsOfMap = <TKey, TValue>(map: Map<TKey, TValue>, ...elementIds: TKey[]): Map<TKey, TValue> => {
    for (const elementId of elementIds) {
        map.delete(elementId);
    }

    return map;
}

/**
 * Returns list of map keys
 * @param map 
 */
export const mapToListOfKeys = <TKey, TValue>(map: Map<TKey, TValue>, filterFunc?: (tval: TValue, tKey: TKey) => boolean): TKey[] => {
    const result: TKey[] = [];
    map.forEach((value, key) => {
        if (filterFunc && !filterFunc(value, key)) {
            return;
        }

        result.push(key);
    });

    return result;
}

/**
 * Search and copy of the element, otherwise returns empty element
 * @param map the map with elements
 * @param key the key of the element in the map
 */
export const getCopyOfElement = <TKey, TValue>(map: Map<TKey, TValue>, key: TKey): TValue | undefined => {
    const element = map.get(key);

    if (element) {
        return { ...element };
    }

    return undefined;
}

/**
 * Update element in the map and return its instance
 * @param map 
 * @param element 
 */
export const addOrUpdateElementInMap = <TKey, TValue extends { Id: TKey }>(map: Map<TKey, TValue>, ...elements: TValue[]): Map<TKey, TValue> => {
    for (const element of elements) {
        map.set(element.Id, element);
    }
    return map;
}

export const addOrUpdateElementInMapWithRebuild = <TKey, TValue>(map: Map<TKey, TValue>, id: TKey, element: TValue): Map<TKey, TValue> => {
    const result = new Map<TKey, TValue>();
    map.forEach((v, key) => result.set(key, v));

    result.set(id, element);

    return result;
}

export const deleteElementInMap = <TKey, TValue>(map: Map<TKey, TValue>, id: TKey): Map<TKey, TValue> => {
    map.delete(id);
    return map;
}

/**
 * Join elements in to map with creating new instance of the map
 * @param map 
 * @param elements 
 */
export const joinMapAndElements = <TKey, TValue extends { Id: TKey }>(map: Map<TKey, TValue>, ...elements: TValue[]): Map<TKey, TValue> => {

    const result = new Map<TKey, TValue>(map);
    for (const element of elements) {
        result.set(element.Id, element);
    }

    return result;
}

/**
 * Sort elements in the map
 * @param map 
 * @param sortFunc 
 */
export const sortElementsInMap = <TKey, TValue extends { Id: TKey }>(map: Map<TKey, TValue>, sortFunc: (a: TValue, b: TValue) => number): Map<TKey, TValue> => {
    const elements = mapToListOfElements(map).sort(sortFunc);
    return elementsToMap(elements);
}

/**
 * Update element properties in the map
 * @param map 
 * @param id 
 * @param changes 
 */
export const updateElementPropertiesInMap = <TKey, TValue extends { Id: TKey }>(map: Map<TKey, TValue>, id: TKey, changes: Partial<TValue>): Map<TKey, TValue> => {
    const element = map.get(id);
    if (!element) {
        return map;
    }

    const updated: TValue = {
        ...element,
        ...changes
    };

    map.set(id, updated);
    return map;
}

/**
 * Update elements properties in the map
 * @param map 
 * @param elementIds 
 * @param changes 
 */
export const updateElementsPropertiesInMap = <TKey, TValue extends { Id: TKey }>(map: Map<TKey, TValue>, elementIds: TKey[], changes: Partial<TValue>): Map<TKey, TValue> => {

    for (const elementId of elementIds) {
        const element = map.get(elementId);

        if (!element) {
            continue;
        }

        const updated: TValue = {
            ...element,
            ...changes
        };

        map.set(elementId, updated);
    }

    return map;
}

/**
 * Add or update exist element in the given array
 * @param array 
 * @param filter 
 * @param changed 
 * @returns 
 */
export const addOrUpdateElementInArray = <TElement>(array: TElement[], filter: (e: TElement) => boolean, changed: TElement): TElement[] => {

    let isUpdated = false;
    const updatedArray = array.map<TElement>(element => {
        if (filter(element)) {
            isUpdated = true;
            return changed;
        }

        return element;
    });

    if (!isUpdated) {
        updatedArray.push(changed);
    }

    return updatedArray;
}

/**
 * Update elements of the array by specific condition
 * @param array 
 * @param filter 
 * @param changes 
 */
export const updateElementsOfArrayIf = <TElement>(array: TElement[], filter: (e: TElement) => boolean, changes: Partial<TElement>): TElement[] => {
    return array.map<TElement>(element => {
        if (filter(element)) {
            return {
                ...element,
                ...changes
            };
        }

        return element;
    });
}

/**
 * Makes deep copy of the map with copy of map keys and values
 * @param map 
 */
export const deepCopyOfTheMap = <TKey, TValue>(map: Map<TKey, TValue>): Map<TKey, TValue> => {
    const result = new Map<TKey, TValue>();

    map.forEach((value, key) => {
        if (typeof value === "object") {
            result.set(key, { ...value });
            return;
        }

        result.set(key, value);
    });

    return result;
}

/**
 * Get element of the map, or false if element not found
 * @param map 
 * @param id 
 */
export const getMapElement = <TKey, TValue>(map: Map<TKey, TValue>, id: TKey): TValue | false => {
    if (!map.has(id)) {
        return false;
    }

    const result = map.get(id);
    return result !== undefined ? result : false;
}

/**
 * Get one element of map or false
 * @param map 
 * @param searchFn 
 */
export const getMapElementOfFilter = <TKey, TValue>(map: Map<TKey, TValue>, searchFn: (value: TValue) => boolean): TValue | false => {

    const elementsOfMap = mapToListOfElements(map, searchFn);
    if (elementsOfMap.length > 0) {
        return elementsOfMap[0];
    }

    return false;
}

/**
 * Update elements of the map
 * @param map 
 * @param itemIds 
 * @param propertyName 
 * @param value 
 */
export const updatePropertyOfMapElements = <TKey, TValue>(map: Map<TKey, TValue>, itemIds: TKey[], propertyName: keyof TValue, value: any): Map<TKey, TValue> => {

    for (const itemId of itemIds) {

        const item = map.get(itemId);

        if (!item) {
            continue;
        }

        const changedItem: TValue = cloneDeep(item);
        changedItem[propertyName] = value;

        map.set(itemId, changedItem);
    }

    return map;
}

/**
 * Remove duplicates in array
 * @param elements 
 */
export const distinctArray = <TValue>(...elements: TValue[]): TValue[] => {
    const result: TValue[] = [];

    for (const element of elements) {
        if (result.indexOf(element) === -1) {
            result.push(element);
        }
    }

    return result;
}

/**
 * Group list of elements to map by property of key
 * @param records 
 * @param keyFunc 
 */
export const elementsToMapByKey = <TKey, TRecord>(records: TRecord[], keyFunc: (record: TRecord) => TKey): Map<TKey, TRecord[]> => {

    const result = new Map<TKey, TRecord[]>();

    for (const record of records) {
        const key = keyFunc(record);

        if (!result.has(key)) {
            result.set(key, [record]);
        } else {
            const items = [...result.get(key)!, record];
            result.set(key, items);
        }
    }

    return result;
}

/**
 * Group elements by keys,
 * each element can be set to many keys
 * @param records 
 * @param keysFunc 
 * @returns 
 */
export const elementsToMapByManyKeys = <TKey, TRecord>(records: TRecord[], keysFunc: (record: TRecord) => TKey[]): Map<TKey, TRecord[]> => {

    const result = new Map<TKey, TRecord[]>();

    for (const record of records) {
        const keys = keysFunc(record);

        for (const key of keys) {

            if (result.has(key)) {
                result.set(key, [...result.get(key)!, record]);
            }
            else {
                result.set(key, [record]);
            }
        }
    }

    return result;
}