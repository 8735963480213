import { ImageBackgroundInfo } from "../../server/AVTService/TypeLibrary/ImageAsMap/ImageBackgroundInfo";
import { ChainInfo } from "../../server/ChainInfo";
import { LmoSensorInfo } from "../../server/GEOvis3/Model/LocalMapObjects/LmoSensorInfo";
import { SensorInfo } from "../../server/GEOvis3/Model/SensorInfo";
import { ImageBackgroundFileType } from "../../server/ImageBackgroundFileType";
import { ImageBackgroundViewPort } from "../../server/ImageBackgroundViewPort";
import { ImageSensorModel } from "../../server/ImageSensorModel";

export const ImageRasterFileTypes = [
    ImageBackgroundFileType.JPEG,
    ImageBackgroundFileType.PNG
];

export const ImageDXFFileTypes = [
    ImageBackgroundFileType.DXF
];

export type ImageMapContentType = "LMO" | "GeovisImage" | "SensorImage";

export const isRasterBackgroundFileType = (fileType: ImageBackgroundFileType): boolean => {
    return ImageRasterFileTypes.indexOf(fileType) > -1;
}

export const isDxfBackgroundFileType = (info: ImageBackgroundInfo): boolean => {
    return !info.IsMapTiles && ImageDXFFileTypes.indexOf(info.FileType) > -1;
}

/**
 * Returns true if background is DXF and MapTiles
 * @param info 
 */
export const isDxfMapTilesBackgroundFileType = (info: ImageBackgroundInfo): boolean => {
    return info.IsMapTiles && ImageDXFFileTypes.indexOf(info.FileType) > -1;
}

export const viewPortToMapBounds = (viewPort: ImageBackgroundViewPort): L.LatLngBounds => {
    const southWest = L.latLng(viewPort.Bottom, viewPort.Left);
    const northEast = L.latLng(viewPort.Top, viewPort.Right);
    const bounds = new L.LatLngBounds(southWest, northEast);

    return bounds;
}

export const viewPortMapCenter = ({ ViewPort, IsMapTiles }: ImageBackgroundInfo): L.LatLng => {

    if (IsMapTiles) {
        return L.latLng(-128, 128);
    }

    const horizontal = ViewPort.Left + ViewPort.Right / 2;
    const vertical = ViewPort.Top + ViewPort.Bottom / 2;

    return L.latLng(horizontal, vertical);
}

export const isEmptyViewPort = (viewPort: ImageBackgroundViewPort): boolean => {
    return viewPort.Right === 0 && viewPort.Bottom === 0;
}

/**
 * Apply LMO sensors relative coordinates and return them
 * @param element
 * @param sensors 
 */
export const applyImageSensorsRelativeCoordinates = (elementsSensors: ImageSensorModel[], sensors: LmoSensorInfo[]): LmoSensorInfo[] =>
    sensors.map<LmoSensorInfo>(lmoSensorInfo => {
        const elementSensor = elementsSensors.find(s => s.FullId === lmoSensorInfo.FullId);

        if (elementSensor) {
            return { ...lmoSensorInfo, RelativeLocation: elementSensor.RelativeLocation };
        }
        return lmoSensorInfo;
    });

export const applyImageSensorsToSensorsInfo = (elementsSensors: ImageSensorModel[], sensors: SensorInfo[]): SensorInfo[] => {
    return sensors.map(sensorInfo => {
        const elementSensor = elementsSensors.find(s => s.FullId === sensorInfo.Id);
        if (elementSensor) {
            return { ...sensorInfo, Coordinates: elementSensor.RelativeLocation }
        }

        return { ...sensorInfo, Coordinates: { Height: 0, Latitude: 0, Longitude: 0 } };
    });
}

export const applyImageSensorsToChainsInfo = (elementsChains: ImageSensorModel[], chains: ChainInfo[]): ChainInfo[] => {
    return chains.map(chainInfo => {
        const elementChain = elementsChains.find(s => s.FullId === chainInfo.Id);
        if (elementChain) {
            return { ...chainInfo, Coordinates: elementChain.RelativeLocation }
        }

        return { ...chainInfo, Coordinates: { Height: 0, Latitude: 0, Longitude: 0 } };
    });
}

export interface IImageMapViewBounds {
    height: number;
    width: number;
}