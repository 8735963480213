/**
 * Login Page
 */
import Button, { LoadingButton } from '@atlaskit/button';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { FieldTextStateless } from '@atlaskit/field-text';
import Form, { ErrorMessage, Field, FormFooter } from '@atlaskit/form';
import Spinner from '@atlaskit/spinner';
import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { compose } from 'recompose';
import GeovisComponent from '../components/abstract/GeovisComponent';
import { withGeovisErrorBoundary } from '../components/errorBoundary/GeovisErrorBoundary';
import PageTitleItem from '../components/PageTitleItem';
import { GeovisName } from '../helpers/Constants';
import Routes from '../helpers/Routes';
import i18next from '../i18n';
import AuthService from '../services/AuthService';
import Logger from '../services/Logger';
import {
    loginFailed,
    loginInProgress,
    loginMessageLanguage,
    loginSuccess
} from '../store/creators/navigationCreators';
import { IGeovisStoreState } from '../store/store.types';
import {
    IGeovisLoginState,
    IGeovisNavigationAction
} from '../store/types';

interface ILoginPageProps extends WithTranslation, IGeovisLoginState {
    dispatch: (action: IGeovisNavigationAction) => void;
}

interface ILoginPageSubmitData {
    userName: string;
    password: string;
}

const LoggerName = "LoginPage";

class LoginPage extends GeovisComponent<ILoginPageProps, IGeovisLoginState>{

    constructor(props: any) {
        super(props);
    }

    public geovisComponentDidMount = async () => { /* */ }

    public geovisComponentWillUnmount = async () => { /* */ }

    public render() {
        const { isLoginInProgress, isLoginFailed, errorMessage } = this.props;

        if (AuthService.isAuthenticatedUser()) {
            return (<Redirect to="/" />);
        }

        const versionJson = require("../version.json");
        const aboutString = `Amberg ${i18next.t("GEOvis 4.0")} | ${i18next.t("labelVersion")}: ${versionJson.version} | © Amberg Infra 7D AG`;

        return (
            <div id="login-page">

                <div className="panel">
                    <div className="header">
                        <PageTitleItem title={GeovisName} />
                    </div>

                    {isLoginInProgress && (
                        <React.Fragment>
                            <Spinner size="xlarge" />
                            <span style={{ marginLeft: '20px' }}>{i18next.t("Loading user info")}...</span>
                        </React.Fragment>
                    )}

                    {!isLoginInProgress && (
                        <Form onSubmit={this.doSignIn}>
                            {({ formProps }: any) => (
                                <form {...formProps} name="login-form">
                                    <React.Fragment>
                                        <Field name="userName" defaultValue="" label={i18next.t("labelUserName")} >
                                            {({ fieldProps: { isRequired, isDisabled, ...others } }: any) => (
                                                <FieldTextStateless
                                                    isLabelHidden={true}
                                                    shouldFitContainer={true}
                                                    disabled={isDisabled}
                                                    required={isRequired}
                                                    {...others} />
                                            )}
                                        </Field>
                                        <Field name="password" defaultValue="" label={i18next.t("labelPassword")} >
                                            {({ fieldProps: { isRequired, isDisabled, ...others } }: any) => (
                                                <Fragment>
                                                    <FieldTextStateless
                                                        type="password"
                                                        isLabelHidden={true}
                                                        shouldFitContainer={true}
                                                        disabled={isDisabled}
                                                        required={isRequired}
                                                        {...others} />

                                                    {isLoginFailed && (
                                                        <ErrorMessage>{errorMessage}</ErrorMessage>
                                                    )}
                                                </Fragment>
                                            )}
                                        </Field>
                                        <FormFooter>
                                            <Button
                                                appearance="link"
                                                href="mailto:geovis@amberg.ch"
                                                target="_blank">
                                                {i18next.t("Contact")}
                                            </Button>
                                            <Button
                                                appearance="link"
                                                href={this.forgotPasswordLink()} >
                                                {i18next.t("forgotPasswordLabel")}
                                            </Button>

                                            <LoadingButton
                                                className="login-button"
                                                isLoading={isLoginInProgress}
                                                id="login-button"
                                                type="submit"
                                                appearance="primary">
                                                {i18next.t("SignIn")}
                                            </LoadingButton>
                                        </FormFooter>
                                    </React.Fragment>

                                </form>
                            )}
                        </Form>
                    )}

                </div>
                {/*  Don't remove this code. It's used to present some Welcome text on login page
                <div style={{ width: "800px", marginTop: "10px" }}>
                    <SectionMessage
                        actions={[
                            {
                                key: "german",
                                text: "Deutsch",
                                onClick: () => { this.setMessageLanguage("german") }
                            },
                            {
                                key: "french",
                                text: "Francais",
                                onClick: () => { this.setMessageLanguage("french") }
                            },
                            {
                                key: "italian",
                                text: "Italiano",
                                onClick: () => { this.setMessageLanguage("italian") }
                            },
                            {
                                key: "english",
                                text: "English",
                                onClick: () => { this.setMessageLanguage("english") }
                            }
                        ]}
                    >
                        <div style={{ overflowX: "auto", overflowY: "auto", height: "340px" }}>

                            {messageLanguage === undefined || messageLanguage === "german" && (
                                <div>
                                    <p>Liebe Amberg GEOvis Benutzer</p>
                                    <p>Mehr als 10 Jahre lang haben wir die Daten aus Monitoring-Projekten mit dem bekannten GEOvis 2.0 präsentiert. Während wir mit GEOvis 2.0 auf ein zuverlässiges und leistungsstarkes Produkt zählen konnten, haben uns die Bedürfnisse unserer Kunden, sowie die Möglichkeiten moderner Kartendienste zu einer Neuentwicklung inspiriert. Das Resultat sehen sie nun hier.</p>
                                    <p>Haben Sie noch keinen Benutzeraccount erhalten, sollten aber Einsicht haben auf ein Projekt, welches Sie bislang mit GEOvis 2.0 eingesehen haben, melden Sie sich bitte unter <a href="mailto:geovis@amberg.ch">geovis@amberg.ch</a>, mit Angabe über ihre Person, Firma und um welches Projekt es sich handelt.</p>
                                    <p>Ebenfalls gerne nehmen wir ihre Anregungen, Kritik oder Wünsche an GEOvis unter dieser E-Mail Adresse entgegen und lassen diese gerne in die weitere Entwicklung einfliessen.</p>
                                    <p>Wir wünschen Ihnen erfolgreiche Projekte mit GEOvis!</p>
                                    <p>Mit freundlichen Grüssen</p>
                                    <div>Das AMBERG Geo-Monitoring Team</div>
                                </div>
                            )}
                            {messageLanguage === "french" && (
                                <div>
                                    <p>Cher utilisateur d’Amberg GEOvis,</p>
                                    <p>Depuis plus de 10 ans, les données des projets de géomonitoring sont représentés à l’aide de la plateforme reconnue GEOvis 2.0. Alors que GEOvis 2.0 a démontré sa fiabilité et sa puissance, les besoins de nos clients ainsi que les possibilités des services cartographiques modernes nous ont donné l’impulsion pour un nouveau développement donc vous pouvez voir le résultat ici.</p>
                                    <p>Si vous n’avez pas encore reçu votre compte d’utilisateur et que vous souhaitez continuer à visualiser vos projets déjà visibles sur GEOvis 2.0, merci de nous contacter à l’adresse <a href="mailto:geovis@amberg.ch">geovis@amberg.ch</a> tout en indiquant votre nom, société ainsi que le projet lié.</p>
                                    <p>Nous serions également heureux de recevoir vos suggestions, critiques ou désirs par à rapport à GEOvis à cette même adresse mail.</p>
                                    <p>Nous vous souhaitons beaucoup de succès dans la réalisation de vos projets avec GEOvis!</p>
                                    <div>L’équipe Geomonitoring Amberg</div>
                                </div>
                            )}
                            {messageLanguage === "italian" && (
                                <div>
                                    <p>Gentile utilizzatore di Amberg GEOvis</p>
                                    <p>Sono più di 10 anni che presentiamo i dati dei progetti di monitoraggio con il riconosciuto GEOvis 2.0. Mentre con GEOvis 2.0 potevamo contare su un prodotto affidabile e potente, le esigenze dei nostri clienti e le possibilità dei moderni servizi di cartografia ci hanno ispirato ad un nuovo sviluppo.</p>
                                    <p>Il risultato è disponibile qui</p>
                                    <p>Nel caso in cui non abbiate ancora ricevuto un conto utente, ma desiderate visualizzare un progetto che avete già visto con GEOvis 2.0, vi preghiamo di contattarci all'indirizzo <a href="mailto:geovis@amberg.ch">geovis@amberg.ch</a>, indicando la vostra persona, l'azienda e di quale progetto si tratta.</p>
                                    <p>Tramite questo indirizzo e-mail saremo inoltre lieti di ricevere i vostri suggerimenti, critiche o richieste rivolte a GEOvis e di farli confluire nell'ulteriore sviluppo.</p>
                                    <p>Vi auguriamo realizzazioni di successo con GEOvis!</p>
                                    <p>Cordiali saluti</p>
                                    <div>Il team AMBERG Geo-Monitoring</div>
                                </div>
                            )}
                            {messageLanguage === "english" && (
                                <div>
                                    <p>Dear Amberg GEOvis users,</p>
                                    <p>For more than 10 years we've been providing the results from monitoring projects on our well known platform GEOvis 2.0. While it was a stable and feature rich solution in the past, the demands of our customers and the possibility of modern map services did inspire us to develop the next generation of GEOvis from scratch. The result can be seen here.</p>
                                    <p>If you don’t have received an account yet, but you think you should have access to one of the projects which previously was displayed on GEOvis 2.0, then please write an E-Mail to <a href="mailto:geovis@amberg.ch">geovis@amberg.ch</a>, including your name, company and project which you should have access.</p>
                                    <p>We also appreciate your suggestions, critics or wishes for GEOvis sent to this E-Mail address, which we kindly respect in our further development process of GEOvis.</p>
                                    <p>We wish you successful projects with the new GEOvis!</p>
                                    <p>Kind regards</p>
                                    <div>The AMBERG Geo-Monitoring Team</div>
                                </div>
                            )}
                        </div>

                    </SectionMessage>
                </div>
                */}
                <div className="footer">
                    <br />
                    {aboutString}
                </div>
            </div>
        );
    }

    private setMessageLanguage = (language: string) => {
        this.props.dispatch(loginMessageLanguage(language));
    }

    private forgotPasswordLink = () => {
        return Routes.forgotPassword.path;
    }

    private doSignIn = async (formData: ILoginPageSubmitData) => {

        const errorMessage = i18next.t("Username or Password incorrect");
        const { userName, password } = formData;
        if (!userName || !password) {
            this.props.dispatch(loginFailed(errorMessage));
            return;
        }

        // login and load user data into AuthService store
        try {
            this.props.dispatch(loginInProgress());

            const success = await AuthService.signIn(formData.userName, formData.password);

            this.props.dispatch(success ? loginSuccess() : loginFailed(errorMessage));
        }
        catch (error) {
            AuthService.signOut()
            this.props.dispatch(loginFailed(errorMessage));

            Logger.trace(error, LoggerName);
            return;
        }
    }
}

const mapStateToProps = (store: IGeovisStoreState) => ({
    isLoginInProgress: store.login.isLoginInProgress,
    isLoginFailed: store.login.isLoginFailed,
    errorMessage: store.login.errorMessage,
    messageLanguage: store.login.messageLanguage
});

const LoginPageTranslated = compose<any, any>(
    withTranslation(),
    withGeovisErrorBoundary
)(LoginPage);

export default connect(mapStateToProps)(LoginPageTranslated);