// two lines below must be first always
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import "./i18n";
import './index.css';
import FlagService from './services/FlagService';
import { registerSw } from './serviceWorkerRegistration';
import store from './store/configureStore';
import { serviceWorkerOnUpdate } from './store/creators/serviceWorkerCreators';

// import Highcharts from 'highcharts';
// import HC_Regression from 'highcharts-regression';

// HC_Regression(Highcharts);

FlagService.init(store);

const root = createRoot(document.getElementById('root')!);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

registerSw({
    // onSuccess: (registration: ServiceWorkerRegistration) => store.dispatch({ type: SERVICE_WORKER_ON_SUCCESS }),
    onUpdate: (registration: ServiceWorkerRegistration) => store.dispatch(serviceWorkerOnUpdate(registration))
});

// store.subscribe(() => { console.log("Store updated", store.getState().navigation.type, store.getState()) });