/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 19.04.2022
 * @description Project elements data storage help methods
 */

import { getMigratebleElementsToName, MigratebleElements } from "../server/AVTService/TypeLibrary/Common/ElementsMigrations/MigratebleElements";
import { ProjectElementType } from "../server/AVTService/TypeLibrary/Common/ProjectElementType";
import { GeovisProjectElementEntryModel } from "../server/GEOvis3/Model/ProjectElements/GeovisProjectElementEntryModel";
import { GeovisProjectElementsCustomGroupModel } from "../server/GEOvis3/Model/ProjectElements/GeovisProjectElementsCustomGroupModel";
import { ProjectElementsModel } from "../server/GEOvis3/Model/ProjectElements/ProjectElementsModel";
import { ProjectInfo } from "../server/ProjectInfo";

/**
 * Return true if project elements migration complete
 * @param project
 * @param migration
 */
export const isProjectElementsMigrationComplete = (project: ProjectInfo, migration: MigratebleElements): boolean => {

    const name = getMigratebleElementsToName(migration);
    return project && project.ProjectElementsMigrationInfo && project.ProjectElementsMigrationInfo[name];
}

/**
 * Add a project element in to storage
 * @param elements
 * @param element 
 */
export const addProjectElementToStore = (elements: ProjectElementsModel, element: GeovisProjectElementEntryModel, customGroups: GeovisProjectElementsCustomGroupModel[]): ProjectElementsModel => {
    const result = executeProjectElementAction(elements, element.Type, c => [...c, element]);
    result.CustomGroups = [...customGroups];
    return result;
}

/**
 * Update project element in storage
 * @param elements
 * @param element
 * @param customGroups 
 */
export const updateProjectElementInStorage = (elements: ProjectElementsModel, element: GeovisProjectElementEntryModel, customGroups: GeovisProjectElementsCustomGroupModel[]): ProjectElementsModel => {
    const result = executeProjectElementAction(elements, element.Type, c => c.map(e => e.Id === element.Id ? element : e));
    result.CustomGroups = [...customGroups];
    return result;
}


/**
 * Remove project element in storage
 * @param elements
 * @param elementId 
 * @param elementType 
 * @param customGroups
 */
export const removeProjectElementFromStorage = (elements: ProjectElementsModel, elementId: number, elementType: ProjectElementType, customGroups: GeovisProjectElementsCustomGroupModel[]): ProjectElementsModel => {
    const result = executeProjectElementAction(elements, elementType, c => c.filter(e => e.Id !== elementId));
    result.CustomGroups = [...customGroups];
    return result;
}

/**
 * Find project element entry
 * @param elements
 * @param elementId 
 * @param elementType 
 */
export const findProjectElement = (elements: ProjectElementsModel, elementId: number, elementType: ProjectElementType): GeovisProjectElementEntryModel | undefined => {

    let result: GeovisProjectElementEntryModel | undefined;
    let wasError = false;

    executeProjectElementAction(elements, elementType, collection => {
        result = collection.find(e => e.Id === elementId);
        return collection;
    }, () => {
        wasError = true;
    });

    if (wasError) {
        return undefined;
    }

    return result;
}

/**
 * Execute action for specific element type
 * @param elements 
 * @param elementType 
 * @param action 
 * @returns 
 */
const executeProjectElementAction = (
    elements: ProjectElementsModel,
    elementType: ProjectElementType,
    action: (collection: GeovisProjectElementEntryModel[]) => GeovisProjectElementEntryModel[],
    onError?: () => void): ProjectElementsModel => {

    switch (elementType) {
        case ProjectElementType.ReportElementLogbook:
            elements.ReportElementLogbooks = action(elements.ReportElementLogbooks);
            break;

        case ProjectElementType.Map:
            elements.Maps = action(elements.Maps);
            break;

        case ProjectElementType.DefaultChart:
        case ProjectElementType.Chart:
            elements.GeovisCharts = action(elements.GeovisCharts);
            break;

        case ProjectElementType.ProfileTemplate:
            elements.ProfileTemplates = action(elements.ProfileTemplates);
            break;

        case ProjectElementType.Profile:
            elements.Profiles = action(elements.Profiles);
            break;

        case ProjectElementType.Header:
            elements.GeovisHeaders = action(elements.GeovisHeaders);
            break;

        case ProjectElementType.Footer:
            elements.Footers = action(elements.Footers);
            break;

        case ProjectElementType.Comment:
            elements.GeovisComments = action(elements.GeovisComments);
            break;

        case ProjectElementType.Geovis4Table:
            elements.GeovisTables = action(elements.GeovisTables);
            break;

        case ProjectElementType.MapSection:
            elements.MapSections = action(elements.MapSections);
            break;

        case ProjectElementType.ProjectChartTemplate:
            elements.GeovisChartProjectTemplates = action(elements.GeovisChartProjectTemplates);
            break;

        case ProjectElementType.CompanyChartTemplate:
            elements.GeovisChartCompanyTemplates = action(elements.GeovisChartCompanyTemplates);
            break;

        case ProjectElementType.CompanyCommentTemplate:
            elements.GeovisCommentsCompanyTemplates = action(elements.GeovisCommentsCompanyTemplates);
            break;

        case ProjectElementType.ProjectCommentTemplate:
            elements.GeovisCommentsProjectTemplates = action(elements.GeovisCommentsProjectTemplates);
            break;

        case ProjectElementType.Report:
            elements.GeovisReports = action(elements.GeovisReports);
            break;

        case ProjectElementType.GeovisImage:
            // elements.Geovis
            elements.GeovisImages = action(elements.GeovisImages);
            break;

        case ProjectElementType.CompanyGeovisImageTemplate:
            elements.GeovisImagesCompanyTemplates = action(elements.GeovisImagesCompanyTemplates);
            break;

        case ProjectElementType.ProjectGeovisImageTemplate:
            elements.GeovisImageProjectTemplates = action(elements.GeovisImageProjectTemplates);
            break;

        case ProjectElementType.ProjectGeovis4TableTemplate:
            elements.GeovisTablesProjectTemplates = action(elements.GeovisTablesProjectTemplates);
            break;

        case ProjectElementType.CompanyGeovis4TableTemplate:
            elements.GeovisTablesCompanyTemplates = action(elements.GeovisTablesCompanyTemplates);
            break;

        default:
            if (onError) {
                onError();
            }
            break;
    }

    return elements;
}