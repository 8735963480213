import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import offsetInfo from 'src/images/dlg-alarm-edit-info.png';
import { t } from "../../../../i18n";

interface IComponentProps {
    onClose: () => void;
}

export const AlarmOffsetInfoModal = ({
    onClose
}: IComponentProps) => {

    return (
        <ModalTransition>
            <Modal
                width={'850px'}
                height={'650px'}
                onClose={onClose}
                actions={[
                    { text: t("Close"), onClick: onClose }
                ]}
                shouldCloseOnEscapePress={true}
                shouldCloseOnOverlayClick={true}
            >
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <img src={offsetInfo} alt="Offset Info" />
                </div>
            </Modal>
        </ModalTransition>
    )
}