import { GroupType, OptionType, OptionsType } from "@atlaskit/select/types";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BoolEditField, SingleSelectEditField, TextEditField } from "../../../../../components/editDialogs/EditDialogTools";
import { t } from "../../../../../i18n";
import { IGeovisProjectUsersStorage } from "../../../../../store/data.types";
import { CompanyInfo } from "../../../../../server/GEOvis3/Model/Company/CompanyInfo";
import { DeviceSlimInfo } from "../../../../../server/GEOvis3/Model/Inventory/DeviceSlimInfo";
import { GeovisAlarmActionModel } from "../../../../../server/AVTService/TypeLibrary/Alarming/GeovisAlarmActionModel";
import { AlarmActionUIType, AlarmActionUITypeList, getAlarmActionUITypeToDescription } from "../../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUIType";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../helpers/GeovisHooks";
import ServerRoutesGen from "../../../../../server/Routes/ServerRoutesGen";
import IRouteParams from "../../../../../helpers/IRouteParams";
import { ISomethingStorageBaseEx, defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../../../../../store/types";
import { GeovisMstShortInfoModel } from "../../../../../server/GEOvis3/Model/Database/GeovisMstShortInfoModel";
import { fetchServerElements } from "../../../../../helpers/ProjectDataHelper";
import FlagService from "../../../../../services/FlagService";
import { getInventoryObjectTypeToDescription } from "../../../../../server/AVTService/TypeLibrary/Inventory/InventoryObjectType";
import { GeovisCheckboxSelect } from "../../../../../components/select/GeovisSelect";
import { AlarmCondition } from "../../../../../server/AVTService/TypeLibrary/Alarming/AlarmCondition";
import { AgmsActionConditionEnableFlag } from "./AgmsActionConditionEnableFlag";
import { invertArray } from "./tools";
import { IGeovisStoreState } from "../../../../../store/store.types";
import { LoadingContainerSkeleton } from "../../../../../components/LoadingContainerSkeleton";

interface IComponentStateProps {
    projectUsersStorage: IGeovisProjectUsersStorage;
}

interface IComponentOwnProps {
    isReadonly: boolean;
    actionState: GeovisAlarmActionModel;
    forConfirm: boolean;
    companies: CompanyInfo[];
    conditions: AlarmCondition[];
    updateAction: (propertyName: keyof GeovisAlarmActionModel, value: any) => void;
    updateFullAction: (upd: GeovisAlarmActionModel) => void;
}

interface IComponentProps extends IWithGeovisServerProps, IComponentStateProps, IComponentOwnProps {

}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IComponentState extends ISomethingStorageBaseEx<GeovisMstShortInfoModel[]> {

}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDevicesStorageState extends ISomethingStorageBaseEx<DeviceSlimInfo[]> {

}

const AlarmActionEditMainSettings = ({
    actionState,
    forConfirm,
    isReadonly,
    updateAction,
    Server,
    conditions,
    updateFullAction
}: IComponentProps) => {

    const { projectId } = useParams<IRouteParams>();

    useEffect(() => {
        (async function loadInfo() {
            await getDevices();
            await getDatabases();
        })();
    }, [1])

    const [state, setState] = useState<IComponentState>({
        ...defaultSomethingStorageState,
        data: []
    })

    const [devicesState, setDevicesState] = useState<IDevicesStorageState>({
        ...defaultSomethingStorageState,
        data: []
    })



    const getDevices = async () => {
        setDevicesState({
            ...defaultSomethingStorageState,
            data: []
        })

        const url = ServerRoutesGen.Inventory.MSTsAndSBsForProject.patch(projectId);
        const response = await fetchServerElements<DeviceSlimInfo[]>(Server, url);

        if (!response.Success) {
            setDevicesState({
                ...errorSomethingStorageState(response.Messages.join("; ")),
                data: []
            });
            return;
        }

        setDevicesState({
            ...loadedSomethingStorageState,
            data: response.Data
        });
    }

    const getDatabases = async () => {

        setState({
            ...defaultSomethingStorageState,
            data: []
        })

        const url = ServerRoutesGen.ProjectDatabase.GetDatabasesShortInfo.patch(projectId);

        const response = await fetchServerElements<GeovisMstShortInfoModel[]>(Server, url);

        if (!response.Success) {
            FlagService.addError("Failed to load database info", response.Messages.join(". "));
            setState({
                ...errorSomethingStorageState(response.Messages.join(". ")),
                data: []
            });
            return;
        }

        setState({
            ...loadedSomethingStorageState,
            data: response.Data
        })
    }

    const filterDeviceData = (source: DeviceSlimInfo): boolean => {
        if (actionState.Alias === "") {
            return false;
        }

        return actionState.Alias === source.RelatedAlias;
    }

    const getDevicesOptions = (): ReadonlyArray<GroupType<OptionType>> => {
        const result: Map<string, OptionType[]> = new Map<string, OptionType[]>();
        if (!devicesState.isLoaded) {
            return [];
        }

        for (const device of devicesState.data.filter(d => filterDeviceData(d))) {
            const option = result.get(getInventoryObjectTypeToDescription(device.DeviceType));
            if (option) {
                option.push({ value: device.DeviceId, label: device.DeviceName })
                result.set(getInventoryObjectTypeToDescription(device.DeviceType), option);
            }
            else {
                result.set(getInventoryObjectTypeToDescription(device.DeviceType), [{ value: device.DeviceId, label: device.DeviceName }]);
            }
        }

        const group: Array<GroupType<OptionType>> = [];

        result.forEach((val, key) => {
            group.push({ options: val, label: key });
        });

        const orderedResult = group.sort((a, b) => a.label === b.label ? 0 : a.label > b.label ? 1 : -1);

        return orderedResult;
    }

    const onDeviceSelectionChanged = (options: OptionsType<OptionType>) => {
        actionState.SensorboxIds = options.map(o => o.value).join(";");
        let devNames = "";
        options.map(o => o.value).forEach(v => {
            const device = devicesState.data.find(d => d.DeviceId === v);
            if (device) {
                devNames += `${device.DeviceName};`;
            }
        })
        actionState.SensorboxNames = devNames;
        updateFullAction(actionState);
    }

    const getSelectedDevices = (): OptionType[] => {
        if (!devicesState.isLoaded) {
            return [];
        }

        const result: OptionType[] = [];

        const selectedIds = actionState.SensorboxIds.split(";");

        devicesState.data.forEach(d => {
            if (selectedIds.includes(d.DeviceId)) {
                result.push({ value: d.DeviceId, label: d.DeviceName })
            }
        })

        return result;
    }

    const onTypeChanged = (selected: OptionType) => {
        updateAction("ActionType", +selected.value)
    }

    const getTypeOptions = (): OptionType[] => {
        return AlarmActionUITypeList.map(a => ({
            label: getAlarmActionUITypeToDescription(a),
            value: a
        }))
    }

    const getSelectedTypeOptions = (): OptionType => {
        return ({
            label: getAlarmActionUITypeToDescription(actionState.ActionType),
            value: actionState.ActionType
        })
    }

    const getDatabasesOptions = (): OptionType[] => {
        const result: OptionType[] = [];
        state.data.forEach((db) => {
            result.push({
                label: db.Name,
                value: db.Alias
            })
        });
        return result;
    }

    const getSelectedDatabase = (): OptionType | undefined => {
        if (!actionState.Alias) {
            return undefined;
        }
        const db = getDatabasesOptions().find(d => d.value === actionState.Alias);
        return db;
    }

    const onSelectedDatabaseChanged = (selected: OptionType) => {
        updateAction("Alias", selected.value.toString())
    }

    const onConditionAddedRemovedToAction = (conditionId: string) => (value: boolean) => {
        if (value && !actionState.ConditionsIds.includes(conditionId)) {
            actionState.ConditionsIds.push(conditionId);
        }
        else {
            actionState.ConditionsIds = actionState.ConditionsIds.filter(c => c !== conditionId);
        }

        updateAction("ConditionsIds", actionState.ConditionsIds)
    }

    const onStringValueChanged = (propertyName: keyof GeovisAlarmActionModel) => (value: string) => {
        updateAction(propertyName, value)
    }

    const onBoolChanged = (propertyName: keyof GeovisAlarmActionModel) => (value: boolean) => {
        updateAction(propertyName, value)
    }

    // const onSelectedUsersChanged = (propertyName: keyof IAlarmActionEditState) => (selection: string[]) => {
    //     updateAction(propertyName, selection);
    // }    

    const getInfoContent = () => (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<AlarmplanName>"}</span>
                <span style={{ width: '60%' }}>{"Name of current alarm plan"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<ProjectNumber>"}</span>
                <span style={{ width: '60%' }}>{"e.g. 40-G-01234"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<ProjectName>"}</span>
                <span style={{ width: '60%' }}>{"GEOvis Project Name"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<ProjectURL>"}</span>
                <span style={{ width: '60%' }}>{"Link to GEOvis Map"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<SensorName>"}</span>
                <span style={{ width: '60%' }}>{"Regular Name of Sensor"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<SensorUnit>"}</span>
                <span style={{ width: '60%' }}>{"Sensor Unit"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<SensorValue>"}</span>
                <span style={{ width: '60%' }}>{"Last measured Value"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<AlarmSeverity>"}</span>
                <span style={{ width: '60%' }}>{"Level1, Level2, as defined by user"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<LimitName>"}</span>
                <span style={{ width: '60%' }}>{"High or Low"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<ExceededThresholdLevel>"}</span>
                <span style={{ width: '60%' }}>{"Defined limit of Alarm level, which was exceeded"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<CustomerName>"}</span>
                <span style={{ width: '60%' }}>{"Customer Name of the sensor"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<Km>"}</span>
                <span style={{ width: '60%' }}>{"Position information if defined"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<Param{1-5}>"}</span>
                <span style={{ width: '60%' }}>{"As defined in Sensor settings"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<ValueTime>"}</span>
                <span style={{ width: '60%' }}>{"Time stamp of the measurement in DD.MM.YYYY HH:mm"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<ExceededFrequencyBand>"}</span>
                <span style={{ width: '60%' }}>{"Defined frequency band of Alarm level, which was exceeded"}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ width: '40%' }}>{"<VibrationMeasuredFrequency>"}</span>
                <span style={{ width: '60%' }}>{"Measured frequency value of the relevant frequency"}</span>
            </div>
        </div>
    )

    const getCounter = () => {
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flexShrink: 0 }}>
                    <span>{`${t("Count of characters")}:${actionState.Message.length}. (Count of characters can be different when using tags)`}</span>
                </div>

                <div style={{ flexShrink: 0 }}>
                    <span>{`${t("Maximum characters in one SMS message")}: 160`}</span>
                </div>
            </div>
        )
    }

    const loaded = devicesState.isLoaded && state.isLoaded;

    if (!loaded) {
        return (
            <LoadingContainerSkeleton />
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <BoolEditField
                isChecked={actionState.IsActive}
                label={t("Active")}
                onChange={onBoolChanged("IsActive")}
                isDisabled={isReadonly}
            />
            <SingleSelectEditField
                label={t("Type")}
                onValueChanged={onTypeChanged}
                options={getTypeOptions()}
                value={getSelectedTypeOptions()}
                isDisabled={isReadonly}
                labelColumnStyle={{ width: '100px' }}
            />

            {actionState.ActionType === AlarmActionUIType.Message &&
                <TextEditField
                    htmlName="subjectEdit"
                    onChangeTextField={onStringValueChanged("Subject")}
                    value={actionState.Subject}
                    labelText={t("Subject")}
                    isDisabled={isReadonly}
                    labelColumnStyle={{ width: '100px' }}
                />
            }
            {(actionState.ActionType === AlarmActionUIType.Dolphin || actionState.ActionType === AlarmActionUIType.Message) &&
                <TextEditField
                    htmlName="messageEdit"
                    onChangeTextField={onStringValueChanged("Message")}
                    value={actionState.Message}
                    isDisabled={isReadonly}
                    labelText={t("Message")}
                    isMultiLine={true}
                    lineCount={10}
                    popupContent={getInfoContent()}
                    labelColumnStyle={{ width: '100px' }}
                    popupContainerStyle={{ width: '500px' }}
                    popupHeaderText={t("Special words list")}
                    popupPlacement="bottom-start"
                    underControlContent={actionState.ActionType === AlarmActionUIType.Message ? getCounter() : undefined}
                />
            }
            {actionState.ActionType === AlarmActionUIType.AgmsDigitalOutput &&
                <SingleSelectEditField
                    label={t("DB Name")}
                    onValueChanged={onSelectedDatabaseChanged}
                    options={getDatabasesOptions()}
                    value={getSelectedDatabase()}
                    isDisabled={isReadonly}
                    labelColumnStyle={{ width: '100px' }}
                />
            }
            {actionState.ActionType === AlarmActionUIType.AgmsDigitalOutput &&
                <div className="flexRowContainerLine" style={{ alignItems: 'center', gap: '5px', gridGap: '5px' }}>
                    <div className="flexCellContainer" style={{ width: '100px' }}>
                        <label htmlFor="cmbValuesMultiEditor" >{t("AGMS Box Ids")}:</label>
                    </div>
                    <div className="flexCellContainer_g1">
                        <GeovisCheckboxSelect
                            id="cmbValuesMultiEditor"
                            options={getDevicesOptions()}
                            value={getSelectedDevices()}
                            spacing='compact'
                            placeholder={t("Select devices")}
                            isDisabled={isReadonly}
                            onChange={onDeviceSelectionChanged}
                        />
                    </div>
                </div>
            }
            {actionState.ActionType === AlarmActionUIType.AgmsDigitalOutput &&
                <div className="flexRowContainerLine" style={{ alignItems: 'center', gap: '5px', gridGap: '5px' }}>
                    <div className="flexCellContainer" style={{ width: '100px' }}>
                        <label>{t("Trigger AGMS Digital output")}:</label>
                    </div>
                    <div className="flexCellContainer_g1" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', gap: '5px' }}>
                        {invertArray(conditions).map<JSX.Element>(c => (
                            <AgmsActionConditionEnableFlag condition={c} onChange={onConditionAddedRemovedToAction(c.Id)} value={actionState.ConditionsIds.includes(c.Id) || actionState.ConditionsIds.length === 0} key={c.Id} />
                        ))}
                    </div>
                </div>
            }
            {actionState.ActionType === AlarmActionUIType.AgmsDigitalOutput && forConfirm &&
                <BoolEditField
                    isChecked={actionState.CanBeSwitchedOffSeparately}
                    label={t("Add custom DigitalOutput confirm button to GEOvis4.0")}
                    onChange={onBoolChanged("CanBeSwitchedOffSeparately")}
                    isDisabled={isReadonly}
                    tooltipContent={t("This will NOT confirm the alarm, this will only turn off the AgmsDigitalOutput. For example is used to turn off an alert siren connected to the AGMS.")}
                />
            }
            {actionState.ActionType === AlarmActionUIType.AgmsDigitalOutput && forConfirm &&
                <BoolEditField
                    isChecked={actionState.AllowProjectViewerToSwitchOff}
                    label={t("Allow project-viewer to confirm DigitalOutput on GEOvis4.0")}
                    onChange={onBoolChanged("AllowProjectViewerToSwitchOff")}
                    isDisabled={isReadonly || !actionState.CanBeSwitchedOffSeparately}
                />
            }
            {actionState.ActionType === AlarmActionUIType.AgmsDigitalOutput &&
                <div style={{ margin: "20px" }}>
                    <div>
                        {t("IMPORTANT")}: {t("Access to AGMS System must be configured in next way")}
                    </div>
                    <div>
                        <ul>
                            <li>{t('Login - "Admin"')}</li>
                            <li>{t('Password - The project number')}</li>
                        </ul>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state: IGeovisStoreState): IComponentStateProps => ({
    projectUsersStorage: state.data.projectUsersStorage
})

export default connect<IComponentStateProps, never, IComponentOwnProps>(
    mapStateToProps
)(withGeovisServer(AlarmActionEditMainSettings));