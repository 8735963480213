//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LicensedFeatures {
	User_Company_creation = 0,
	Manual_projects_creation = 1,
	Automatic_Projects_creation = 2,
	Unlimited_projects = 3,
	Manual_import_export = 4,
	Automatic_import_export = 5,
	Elements_reports = 6,
	Elements_reports_templates = 7,
	Logbook = 8,
	Local_Map_Object = 9,
	DXF_Overlay = 10,
	Virtual_sensors = 11,
	Document_storage = 12,
	Sensor_watchdog = 13,
	Rest_api = 14,
	LoRa = 15,
	Alarms_visualization = 16,
	Views = 17,
	Dashboard = 18,
	Meteodata = 19,
	Wmts_Image_overlay = 20,
	Alarm_actions = 21
}

const LicensedFeaturesToName = new Map<number, string>([
	[ 0, 'User_Company_creation' ],
	[ 1, 'Manual_projects_creation' ],
	[ 2, 'Automatic_Projects_creation' ],
	[ 3, 'Unlimited_projects' ],
	[ 4, 'Manual_import_export' ],
	[ 5, 'Automatic_import_export' ],
	[ 6, 'Elements_reports' ],
	[ 7, 'Elements_reports_templates' ],
	[ 8, 'Logbook' ],
	[ 9, 'Local_Map_Object' ],
	[ 10, 'DXF_Overlay' ],
	[ 11, 'Virtual_sensors' ],
	[ 12, 'Document_storage' ],
	[ 13, 'Sensor_watchdog' ],
	[ 14, 'Rest_api' ],
	[ 15, 'LoRa' ],
	[ 16, 'Alarms_visualization' ],
	[ 17, 'Views' ],
	[ 18, 'Dashboard' ],
	[ 19, 'Meteodata' ],
	[ 20, 'Wmts_Image_overlay' ],
	[ 21, 'Alarm_actions' ],
])

export function getLicensedFeaturesToName(element: LicensedFeatures) : string {
    return LicensedFeaturesToName.get(element) || '';
}

const LicensedFeaturesByName = new Map<string, number>([
	[ 'User_Company_creation', 0 ],
	[ 'Manual_projects_creation', 1 ],
	[ 'Automatic_Projects_creation', 2 ],
	[ 'Unlimited_projects', 3 ],
	[ 'Manual_import_export', 4 ],
	[ 'Automatic_import_export', 5 ],
	[ 'Elements_reports', 6 ],
	[ 'Elements_reports_templates', 7 ],
	[ 'Logbook', 8 ],
	[ 'Local_Map_Object', 9 ],
	[ 'DXF_Overlay', 10 ],
	[ 'Virtual_sensors', 11 ],
	[ 'Document_storage', 12 ],
	[ 'Sensor_watchdog', 13 ],
	[ 'Rest_api', 14 ],
	[ 'LoRa', 15 ],
	[ 'Alarms_visualization', 16 ],
	[ 'Views', 17 ],
	[ 'Dashboard', 18 ],
	[ 'Meteodata', 19 ],
	[ 'Wmts_Image_overlay', 20 ],
	[ 'Alarm_actions', 21 ],
]);

export function getLicensedFeaturesByName(name: string): LicensedFeatures {
    return LicensedFeaturesByName.get(name)!;
}


export const LicensedFeaturesList : Readonly<LicensedFeatures[]> = [
	LicensedFeatures.User_Company_creation,
	LicensedFeatures.Manual_projects_creation,
	LicensedFeatures.Automatic_Projects_creation,
	LicensedFeatures.Unlimited_projects,
	LicensedFeatures.Manual_import_export,
	LicensedFeatures.Automatic_import_export,
	LicensedFeatures.Elements_reports,
	LicensedFeatures.Elements_reports_templates,
	LicensedFeatures.Logbook,
	LicensedFeatures.Local_Map_Object,
	LicensedFeatures.DXF_Overlay,
	LicensedFeatures.Virtual_sensors,
	LicensedFeatures.Document_storage,
	LicensedFeatures.Sensor_watchdog,
	LicensedFeatures.Rest_api,
	LicensedFeatures.LoRa,
	LicensedFeatures.Alarms_visualization,
	LicensedFeatures.Views,
	LicensedFeatures.Dashboard,
	LicensedFeatures.Meteodata,
	LicensedFeatures.Wmts_Image_overlay,
	LicensedFeatures.Alarm_actions,
];



const LicensedFeaturesToDescription = new Map<number, string>([
	[ 0, 'User_Company_creation' ],
	[ 1, 'Manual_projects_creation' ],
	[ 2, 'Automatic_Projects_creation' ],
	[ 3, 'Unlimited_projects' ],
	[ 4, 'Manual_import_export' ],
	[ 5, 'Automatic_import_export' ],
	[ 6, 'Elements_reports' ],
	[ 7, 'Elements_reports_templates' ],
	[ 8, 'Logbook' ],
	[ 9, 'Local_Map_Object' ],
	[ 10, 'DXF_Overlay' ],
	[ 11, 'Virtual_sensors' ],
	[ 12, 'Document_storage' ],
	[ 13, 'Sensor_watchdog' ],
	[ 14, 'Rest_api' ],
	[ 15, 'LoRa' ],
	[ 16, 'Alarms_visualization' ],
	[ 17, 'Views' ],
	[ 18, 'Dashboard' ],
	[ 19, 'Meteodata' ],
	[ 20, 'Wmts_Image_overlay' ],
	[ 21, 'Alarm_actions' ],
])

export function getLicensedFeaturesToDescription(element: LicensedFeatures) : string {
    return LicensedFeaturesToDescription.get(element) || '';
}

