/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 21.06.2022
 * @description Geovis header logo file render
 */

import ServerRoutesGen from '../../../../../server/Routes/ServerRoutesGen';
import { GEOvisLogoComponent } from '../../../../GEOvisProjectLogo';

interface IComponentProps {
    projectId: number;
    logoId: string;
}

/**
 * Custom project logo render for header
 * @param param0 
 * @returns 
 */
export const GeovisHeaderCustomLogoRender = ({ projectId, logoId }: IComponentProps) => {

    const url = ServerRoutesGen.Project.ProjectCustomLogo.patch(projectId, logoId).path;

    return (
        <GEOvisLogoComponent logoUrl={url} />
    )
}

/**
 * Project logo render for header
 * @param param0 
 * @returns 
 */
export const GeovisHeaderProjectLogoRender = ({ projectId }: { projectId: number }) => {

    const url = ServerRoutesGen.Project.ProjectHeaderLogo.patch(projectId).path;

    return (
        <GEOvisLogoComponent logoUrl={url} />
    )
}