/**
 * WARNING: this file is copied from Geovis3\GEOvis3\ClientEnv\
 * changes will be overwritten within next build event 
 * 
 * RELEASE: EUROPE HOST SERVER
 */


const l = window && window.location;

export const API_ROOT = l && l.origin;

const firstDotIndex = API_ROOT.indexOf('.');
const domain = API_ROOT.substring(firstDotIndex + 1);

// export const MAP_TILES_API_ROOT = "https://maptiles.amberg.ch";
const MAP_TILES_API_SUB = "maptiles";

// export const MAP_TILES_API_ROOT = "https://maptiles.amberg.ch";
export const MAP_TILES_API_ROOT = `${l.protocol}//${MAP_TILES_API_SUB}.${domain}`;

// export const GEOVIS_LIVE_API_ROOT = "https://geovis-live.amberg.ch";
const GEOVIS_LIVE_API_SUB = "geovis-live";
export const GEOVIS_LIVE_API_ROOT = `${l.protocol}//${GEOVIS_LIVE_API_SUB}.${domain}`;

export const GEOVIS_LIVE_PORT = 80;

export const IS_DEVELOPMENT = false;
export const IS_PRODUCTION = true;
export const IS_TEST = false;