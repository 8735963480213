//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { AlarmActionType } from './AlarmActionType';
import { AlarmActionExecutionState } from './AlarmActionExecutionState';
import { AlarmActionActivityPeriod } from './AlarmActionActivityPeriod';

export abstract class AlarmAction
{
	public active: boolean;
	public actionType: AlarmActionType;
	public forConfirm: boolean;
	public state: AlarmActionExecutionState;
	public activityPeriods: AlarmActionActivityPeriod[];
}
