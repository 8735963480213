import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import MapProviderHelper from "../../helpers/MapProviderHelper";
import { getMapProvidersToDescription, MapProviders, MapProvidersList } from "../../server/AVTService/TypeLibrary/Map/MapProviders";
import { GeoLocation } from "../../server/GeoLocation";
import './geovis.leaflet.gridlayer.js';
import './geovis.leaflet.domUtils.js';

interface IMapLayersControlProps {
    streetsMapMinZoom?: number;
    streetsMapMaxZoom?: number;
    mapProvider: MapProviders;
    streetsMapMaxNativeZoom?: number;
    projectLocation?: GeoLocation;
}

const MapLayersControl = ({
    mapProvider,
    streetsMapMaxNativeZoom,
    streetsMapMaxZoom,
    streetsMapMinZoom,
    projectLocation
}: IMapLayersControlProps) => {

    const streetsMapMinZoomValue = streetsMapMinZoom || 0;
    const streetsMapMaxZoomValue = streetsMapMaxZoom || 19;
    const streetsMapMaxNativeZoomValue = streetsMapMaxNativeZoom || 19;

    const availableProviders = MapProviderHelper.getProvidersByLocation(false, projectLocation);

    const isInAvailableProviders = (provider: MapProviders): boolean => {
        return availableProviders.includes(provider);
    }

    return (
        <LayersControl position="topright">
            {
                MapProvidersList.filter(p => isInAvailableProviders(p)).map(provider => {
                    if (MapProviderHelper.IsWMSLayer(provider)) {
                        return (
                            <LayersControl.BaseLayer
                                key={`provider-${provider}`}
                                checked={mapProvider === provider}
                                name={getMapProvidersToDescription(provider)} >
                                <WMSTileLayer
                                    url={MapProviderHelper.urlTemplateForMapProvider(provider)}
                                    // layers={"Aggregierte_Amtliche_Vermessung"} // just black/white strokes
                                    // layers={"avc"}   // colored strokes
                                    layers={"av_situationsplan"} // nice black/white/grey map with background
                                    // layers={"av_situationsplan_oereb"} // black/white map with background
                                    minZoom={streetsMapMinZoomValue}
                                    maxZoom={streetsMapMaxZoomValue}
                                    maxNativeZoom={streetsMapMaxNativeZoomValue}
                                    attribution={MapProviderHelper.attributionForMapProvider(provider)}
                                />
                            </LayersControl.BaseLayer>
                        )
                    }
                    return (
                        <LayersControl.BaseLayer
                            key={`provider-${provider}`}
                            checked={mapProvider === provider}
                            name={getMapProvidersToDescription(provider)} >
                            <TileLayer
                                url={MapProviderHelper.urlTemplateForMapProvider(provider)}
                                minZoom={streetsMapMinZoomValue}
                                maxZoom={streetsMapMaxZoomValue}
                                maxNativeZoom={streetsMapMaxNativeZoomValue}
                                attribution={MapProviderHelper.attributionForMapProvider(provider)} />
                        </LayersControl.BaseLayer>
                    )
                })
            }
        </LayersControl>
    );
}

export default MapLayersControl;