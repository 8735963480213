/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.04.2021
 * @description Logs actions
 */

import { Reducer } from 'redux';
import { deleteElementOfMap, elementsToMap, joinMapAndElements } from '../../helpers/StorageHelper';
import { GvLogApp } from '../../server/Geovis/Logger/GvLogApp';
import { GvModule } from '../../server/Geovis/Logger/GvModule';
import { GvSeverity } from '../../server/Geovis/Logger/GvSeverity';
import { GvLogEntryType } from '../../server/Geovis/Logger/Model/Abstract/GvLogEntryType';
import { GvLogEntryPoint } from '../../server/Geovis/Logger/Model/GvLogEntryPoint';
import { GvLogFilterData } from '../../server/Geovis/Logger/Reader/GvLogFilterData';
import { GvLogFollowRecordsFilter } from '../../server/Geovis/Logger/Reader/GvLogFollowRecordsFilter';
import { LogReadPredefinedPeriod } from '../../server/Geovis/Logger/Reader/LogReadPredefinedPeriod';
import { ProjectInfoSlim } from '../../server/GEOvis3/Model/Project/ProjectInfoSlim';
import { GeovisUserCommonInfo } from '../../server/GEOvis3/Model/User/GeovisUserCommonInfo';
import {
    LOG_FILTER_CHANGED,
    LOGS_DETAILS_DIALOG_CLOSE,
    LOGS_DETAILS_DIALOG_SHOW,
    LOGS_ENTRY_POINTS,
    LOGS_ENTRY_POINTS_DATA,
    LOGS_FOLLOW_DISMISS_EP,
    LOGS_FOLLOW_EP_LOADED,
    LOGS_FOLLOW_EP_LOADING,
    LOGS_FOLLOW_FILTER_CHANGED,
    LOGS_FOLLOW_RECORDS_ADD,
    LOGS_FOLLOW_RECORDS_LOADED,
    LOGS_FOLLOW_RECORDS_LOADING,
    LOGS_PROJECTS,
    LOGS_PROJECTS_DATA,
    LOGS_RECORDS,
    LOGS_RECORDS_DATA,
    LOGS_USERS_ADD
} from '../actions/logsActions';
import { processFetchedData } from '../helpers/DataHelper';
import {
    ILogsAction,
    ILogsEntryPointsStorage,
    ILogsFollowEntryPointDataStorage,
    ILogsFollowEntryPointInfoStorage,
    ILogsFollowStorage,
    ILogsProjectsStorage,
    ILogsRecordDetailsStorage,
    ILogsRecordsStorage,
    ILogsStorage
} from '../logs.types';
import { defaultSomethingStorageState, loadedSomethingStorageState } from '../types';
import { DataActionResponse } from '../../server/DataActionResponse';
import { GvLogFollowRecordsOutputModel } from '../../server/Geovis/Logger/Model/GvLogFollowRecordsOutputModel';

const logsEntryPointsStorageInitState: ILogsEntryPointsStorage = {
    ...defaultSomethingStorageState,
    entryPoints: [],
    countRecords: 0,
    pageNumber: 1,
    recordsPerPage: 100
};

const logsRecordsStorageInitState: ILogsRecordsStorage = {
    ...defaultSomethingStorageState,
    logRecords: [],
    countRecords: 0,
    pageNumber: 1,
    recordsPerPage: 100
}

const logsProjectsStorageInitState: ILogsProjectsStorage = {
    ...defaultSomethingStorageState,
    projects: new Map<number, ProjectInfoSlim>()
}

const detailsInitialState: ILogsRecordDetailsStorage = {
    projectId: -1,
    recordId: '',
    recordType: GvLogEntryType.Record
}

export const logFollowRecordsFilterDefault: GvLogFollowRecordsFilter = {
    EntryId: '',
    ProjectId: 0,
    Message: '',
    Severity: undefined,
    PageNumber: 1,
    PageSize: 20
};

const logsEntryPointFollowInfoStorageInitialState: ILogsFollowEntryPointInfoStorage = {
    ...defaultSomethingStorageState,
    Id: '',
    entryPoint: new GvLogEntryPoint(),
    filter: { ...logFollowRecordsFilterDefault }
}

const logsEntryPointFollowDataStorageInitialState: ILogsFollowEntryPointDataStorage = {
    ...defaultSomethingStorageState,
    records: [],
    totalRecords: 0
}

const logsFollowInitialState: ILogsFollowStorage = {
    entryPointsInfo: new Map<string, ILogsFollowEntryPointInfoStorage>(),
    entryPointsData: new Map<string, ILogsFollowEntryPointDataStorage>(),
    filterChangeFlag: false
};

const initLogFilterState: GvLogFilterData = {
    App: GvLogApp.GEOvisLive,
    EndDate: '',
    MinSeverity: GvSeverity.Info,
    Module: GvModule.All,
    ProjectId: 0,
    StartDate: '',
    Period: LogReadPredefinedPeriod.Hours6,
    Message: '',
    OnlyRecords: false,
    PageNumber: 1,
    PageSize: 100,
    AlnDevEUI: '',
    FPort: 0
};

const processFetchedFollowDataToMap = (originalMap: Map<string, ILogsFollowEntryPointDataStorage>, response: DataActionResponse<GvLogFollowRecordsOutputModel>, entryId: string) => {
    if (response.Success) {
        originalMap.set(entryId, { 
            ...loadedSomethingStorageState, 
            records: response.Data.Data,
            totalRecords: response.Data.CountRecords,
        });
    }

    return originalMap;
}

const initFollowDataStorage = (dataMap: Map<string, ILogsFollowEntryPointDataStorage>, entryId: string) => {
    dataMap.set(entryId, logsEntryPointFollowDataStorageInitialState);
    return dataMap;
}

const addFollowDataRecords = (dataMap: Map<string, ILogsFollowEntryPointDataStorage>, entryId: string, data: GvLogFollowRecordsOutputModel) => {
    const existData = dataMap.get(entryId);
    if (existData) {
        dataMap.set(entryId, { ...existData, records: [...existData.records, ...data.Data], totalRecords: data.CountRecords });
    }
    else {
        dataMap.set(entryId, { ...loadedSomethingStorageState, records: data.Data, totalRecords: data.CountRecords });
    }
    return dataMap;
}

export const logsStorageInitialState: ILogsStorage = {
    entryPointsStorage: logsEntryPointsStorageInitState,
    recordsStorage: logsRecordsStorageInitState,
    logsUsers: new Map<string, GeovisUserCommonInfo>(),
    projectsStorage: logsProjectsStorageInitState,
    details: detailsInitialState,
    follow: logsFollowInitialState,
    entryPointFilter: initLogFilterState,
    recordsFilter: initLogFilterState,
    currentType: GvLogEntryType.EntryPoint
}

const logsDataReducer: Reducer<ILogsStorage> = (
    state: ILogsStorage = logsStorageInitialState,
    action: ILogsAction): ILogsStorage => {

    switch (action.type) {
        case LOGS_ENTRY_POINTS:
            return { ...state, entryPointsStorage: logsEntryPointsStorageInitState }

        case LOGS_ENTRY_POINTS_DATA:
            return {
                ...state,
                entryPointsStorage: processFetchedData(action.entryPointData, state.entryPointsStorage, logsEntryPointsStorageInitState, st => ({
                    entryPoints: st.Data,
                    countRecords: st.CountRecords,
                    pageNumber: st.PageNumber,
                    recordsPerPage: st.RowsPerPage,
                    projectId: action.projectId || 0
                }))
            }

        case LOGS_RECORDS:
            return { ...state, recordsStorage: logsRecordsStorageInitState }

        case LOGS_RECORDS_DATA:
            return {
                ...state,
                recordsStorage: processFetchedData(action.logData, state.recordsStorage, logsRecordsStorageInitState, st => ({
                    logRecords: st.Data,
                    countRecords: st.CountRecords,
                    pageNumber: st.PageNumber,
                    recordsPerPage: st.RowsPerPage,
                    projectId: action.projectId || 0
                }))
            }

        case LOGS_PROJECTS:
            return { ...state, projectsStorage: logsProjectsStorageInitState };

        case LOGS_PROJECTS_DATA:
            return {
                ...state,
                projectsStorage: processFetchedData(action.projects, state.projectsStorage, logsProjectsStorageInitState, st => ({ projects: elementsToMap(st) }))
            }

        case LOGS_USERS_ADD:
            if (action.users && action.users.length > 0) {
                return {
                    ...state,
                    logsUsers: joinMapAndElements(state.logsUsers, ...action.users)
                }
            }
            break;

        //#region Details

        case LOGS_DETAILS_DIALOG_SHOW:
            if (action.projectId !== undefined && action.projectId >= 0 && action.recordId && action.recordType !== undefined) {
                return {
                    ...state,
                    details: {
                        projectId: action.projectId,
                        recordId: action.recordId,
                        recordType: action.recordType
                    }
                }
            }
            break;

        case LOGS_DETAILS_DIALOG_CLOSE:
            return { ...state, details: detailsInitialState };

        //#endregion

        //#region Following

        case LOGS_FOLLOW_EP_LOADING: {
            if (!action.recordId) {
                throw Error('Cannot initialize the following data storage. Incorrect action parameters');
            }

            const { recordId } = action;

            return {
                ...state,
                details: detailsInitialState,
                follow: {
                    ...state.follow,
                    entryPointsInfo: joinMapAndElements(state.follow.entryPointsInfo, {
                        ...logsEntryPointFollowInfoStorageInitialState,
                        Id: recordId
                    }),
                    entryPointsData: initFollowDataStorage(state.follow.entryPointsData, recordId) // logsEntryPointFollowDataStorageInitialState,
                }
            }
        }

        case LOGS_FOLLOW_EP_LOADED: {

            if (!action.entryPoint) {
                return state;
            }

            const entryPointStore = processFetchedData(action.entryPoint, logsEntryPointFollowInfoStorageInitialState, logsEntryPointFollowInfoStorageInitialState, st => ({
                ...logsEntryPointFollowInfoStorageInitialState,
                ...loadedSomethingStorageState,
                Id: st.Id,
                entryPoint: st
            }));

            return {
                ...state,
                follow: {
                    ...state.follow,
                    entryPointsInfo: joinMapAndElements(state.follow.entryPointsInfo, entryPointStore)
                }
            }
        }

        case LOGS_FOLLOW_RECORDS_LOADING:
            if (!action.recordId) {
                return state;
            }

            return {
                ...state,
                details: detailsInitialState,
                follow: {
                    ...state.follow,
                    entryPointsData: initFollowDataStorage(state.follow.entryPointsData, action.recordId) // logsEntryPointFollowDataStorageInitialState
                }
            }

        case LOGS_FOLLOW_RECORDS_LOADED: {
            if (!action.recordId || !action.logRecords) {
                return state;
            }

            return {
                ...state,
                follow: {
                    ...state.follow,
                    entryPointsData: processFetchedFollowDataToMap(state.follow.entryPointsData, action.logRecords, action.recordId)
                }
            }
        }

        case LOGS_FOLLOW_RECORDS_ADD:
            if (!action.recordId || !action.logRecords) {
                return state;
            }

            // append records in to the store
            return {
                ...state,
                follow: {
                    ...state.follow,
                    entryPointsData: addFollowDataRecords(state.follow.entryPointsData, action.recordId, action.logRecords.Data)
                }
            }

        case LOGS_FOLLOW_FILTER_CHANGED: {
            if (!action.recordId) {
                return state;
            }

            const { recordId } = action;
            const epStorage = state.follow.entryPointsInfo.get(recordId);
            if (!epStorage) {
                throw Error('Cannot update the follow entry point filter. Entry point data storage is not initialized properly');
            }

            const eps = joinMapAndElements(state.follow.entryPointsInfo, { ...epStorage, filter: action.filterFollow || logFollowRecordsFilterDefault });

            return {
                ...state,
                follow: {
                    ...state.follow,
                    entryPointsInfo: eps,
                    filterChangeFlag: !state.follow.filterChangeFlag
                }
            };
        }

        // case LOGS_FOLLOW_FILTER_MESSAGE_CHANGED: {
        //     if (!action.recordId) {
        //         return state;
        //     }

        //     const { recordId } = action;
        //     const epStorage = state.follow.entryPoints.get(recordId);
        //     if (!epStorage) {
        //         throw Error('Cannot update the follow entry point filter. Entry point data storage is not initialized properly');
        //     }

        //     const eps = joinMapAndElements(state.follow.entryPoints, { ...epStorage, message: action.message || '' });

        //     return {
        //         ...state,
        //         follow: {
        //             ...state.follow,
        //             entryPoints: eps
        //         }
        //     };
        // }

        case LOGS_FOLLOW_DISMISS_EP: {
            if (!action.recordId) {
                return state;
            }

            const { recordId } = action;
            const entryPoint = state.follow.entryPointsInfo.get(recordId);
            if (!entryPoint) {
                return state;
            }

            return {
                ...state,
                follow: {
                    ...state.follow,
                    entryPointsInfo: deleteElementOfMap(state.follow.entryPointsInfo, recordId)
                }
            };
        }

        case LOG_FILTER_CHANGED: {
            if (action.recordType === undefined || !action.filter || action.booleanProperty === undefined) {
                return state;
            }

            const changeEntryPoints = action.recordType !== GvLogEntryType.Record;
            if (action.booleanProperty) {
                action.filter.PageNumber = 1;
            }

            return {
                ...state,
                entryPointFilter: changeEntryPoints ? action.filter : state.entryPointFilter,
                recordsFilter: !changeEntryPoints ? action.filter : state.recordsFilter,
                currentType: action.recordType
            }
        }

        //#endregion
    }

    return state;
}

export default logsDataReducer;