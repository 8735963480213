/**
 * Project overview navigation actions
 */

// Project overview change page state actions
export const PROJECT_OVERVIEW_SHOW_LAYERS_DIALOG = "PROJECT_OVERVIEW_SHOW_LAYERS_DIALOG";
export const PROJECT_OVERVIEW_HIDE_ANY_OVERLAY = "PROJECT_OVERVIEW_HIDE_ANY_OVERLAY";

export const PROJECT_OVERVIEW_SHOW_LOCAL_MAP_OBJECT_CONTENT = "PROJECT_OVERVIEW_SHOW_LOCAL_MAP_OBJECT_CONTENT";
export const PROJECT_OVERVIEW_HIDE_LOCAL_MAP_OBJECT_CONTENT = "PROJECT_OVERVIEW_HIDE_LOCAL_MAP_OBJECT_CONTENT";

/**
 * Show the sensor default chart view (like report)
 */
export const PROJECT_OVERVIEW_SHOW_SENSOR_DEFAULT_CHART_VIEW = "PROJECT_OVERVIEW_SHOW_SENSOR_DEFAULT_CHART_VIEW";
export const PROJECT_OVERVIEW_HIDE_SENSOR_DEFAULT_CHART_VIEW = "PROJECT_OVERVIEW_HIDE_SENSOR_DEFAULT_CHART_VIEW";

/**
 * Show the sensor settings
 */
export const PROJECT_OVERVIEW_SHOW_SENSOR_SETTINGS = "PROJECT_OVERVIEW_SHOW_SENSOR_SETTINGS";
export const PROJECT_OVERVIEW_HIDE_SENSOR_SETTINGS = "PROJECT_OVERVIEW_HIDE_SENSOR_SETTINGS";

/**
 * Show the sensor settings
 */
export const PROJECT_OVERVIEW_SHOW_SENSOR_IMAGE = "PROJECT_OVERVIEW_SHOW_SENSOR_IMAGE";
export const PROJECT_OVERVIEW_HIDE_SENSOR_IMAGE = "PROJECT_OVERVIEW_HIDE_SENSOR_IMAGE";

/**
 * Show selected sensors (in the tree view) charts as report
 */
export const PROJECT_OVERVIEW_SHOW_SELECTED_SENSORS_REPORT_VIEW = "PROJECT_OVERVIEW_SHOW_SELECTED_SENSORS_REPORT_VIEW";
export const PROJECT_OVERVIEW_HIDE_SELECTED_SENSORS_REPORT_VIEW = "PROJECT_OVERVIEW_HIDE_SELECTED_SENSORS_REPORT_VIEW";

/**
 * Show the chart chooser dialog when sensor includes in more than one default chart
 */
export const PROJECT_OVERVIEW_SHOW_DEFAULT_CHART_CHOOSER = "PROJECT_OVERVIEW_SHOW_DEFAULT_CHART_CHOOSER";
export const PROJECT_OVERVIEW_HIDE_DEFAULT_CHART_CHOOSER = "PROJECT_OVERVIEW_HIDE_DEFAULT_CHART_CHOOSER";

export const PROJECT_OVERVIEW_INIT_LOGBOOK_VIEW_STATE = 'PROJECT_OVERVIEW_INIT_LOGBOOK_VIEW_STATE';
export const PROJECT_OVERVIEW_SET_LOGBOOK_VIEW_STATE = 'PROJECT_OVERVIEW_SET_LOGBOOK_VIEW_STATE';

export const PROJECT_OVERVIEW_SHOW_ALARM_EDIT_DIALOG = "PROJECT_OVERVIEW_SHOW_ALARM_EDIT_DIALOG";
export const PROJECT_OVERVIEW_HIDE_ALARM_EDIT_DIALOG = "PROJECT_OVERVIEW_HIDE_ALARM_EDIT_DIALOG";

//#region statistic

export const PROJECT_OVERVIEW_STATISTIC_MST_TAB_SELECTED = "PROJECT_OVERVIEW_STATISTIC_MST_TAB_SELECTED";

//#endregion

//#region logbook

export const PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_DIALOG = "PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_DIALOG";
export const PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_DIALOG = "PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_DIALOG";

export const PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_EDIT_DIALOG = "PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_EDIT_DIALOG";

export const PROJECT_OVERVIEW_LOGBOOK_CHANGED_TEXT_SEARCH_STRING = "PROJECT_OVERVIEW_LOGBOOK_CHANGED_TEXT_SEARCH_STRING";
export const PROJECT_OVERVIEW_LOGBOOK_CHANGED_PAGE_NUMBER = "PROJECT_OVERVIEW_LOGBOOK_CHANGED_PAGE_NUMBER";
export const PROJECT_OVERVIEW_LOGBOOK_CHANGED_ROWS_PER_PAGE = "PROJECT_OVERVIEW_LOGBOOK_CHANGED_ROWS_PER_PAGE";
export const PROJECT_OVERVIEW_LOGBOOK_CHANGED_SORTING = "PROJECT_OVERVIEW_LOGBOOK_CHANGED_SORTING";

export const PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_REMOVE_DIALOG = "PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_REMOVE_DIALOG";
export const PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_REMOVE_DIALOG = "PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_REMOVE_DIALOG";
export const PROJECT_OVERVIEW_LOGBOOK_REMOVED = "PROJECT_OVERVIEW_LOGBOOK_REMOVED";

export const PROJECT_OVERVIEW_LOGBOOK_CONFIRMED = "PROJECT_OVERVIEW_LOGBOOK_CONFIRMED";

export const PROJECT_OVERVIEW_SHOW_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG = "PROJECT_OVERVIEW_SHOW_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG";
export const PROJECT_OVERVIEW_HIDE_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG = "PROJECT_OVERVIEW_HIDE_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG";

//#endregion

//#region  embedded data

export const PROJECT_OVERVIEW_EMBEDDED_DATA_TAB_CHANGED = "PROJECT_OVERVIEW_EMBEDDED_DATA_TAB_CHANGED";

//#endregion

//#region  information

export const PROJECT_OVERVIEW_INFORMATION_TAB_CHANGED = "PROJECT_OVERVIEW_INFORMATION_TAB_CHANGED";

export const PROJECT_OVERVIEW_INVENTORY_TAB_CHANGED = "PROJECT_OVERVIEW_INVENTORY_TAB_CHANGED";
export const PROJECT_OVERVIEW_INVENTORY_OBJECT_TYPE_CHANGED = "PROJECT_OVERVIEW_INVENTORY_OBJECT_TYPE_CHANGED";

//#endregion