/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.03.2024
 * @description Project element edit modal dialog header
 */

import LinkIcon from '@atlaskit/icon/glyph/link'
import Button from "@atlaskit/button";
import Tooltip from "@atlaskit/tooltip";

interface IComponentProps {
    headerText: string;
    tooltipContent: string;
    hideButton?: boolean;

    onCopyToClipboard: () => void;
}

/**
 * Project element edit header, common component for all dialogs
 * @param param0 
 * @returns 
 */
export const ProjectElementEditHeader = ({ headerText, tooltipContent, onCopyToClipboard, hideButton }: IComponentProps) => (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ paddingTop: '5px' }}>{headerText}</div>
        {!hideButton &&
            <div style={{ height: '20px' }} tabIndex={-1}>
                <Tooltip content={tooltipContent}>
                    <Button
                        onClick={onCopyToClipboard}
                        appearance='subtle-link'
                        iconBefore={<LinkIcon label='link' size='small' />}
                        height={20}
                        style={{ height: '20px' }}
                        autoFocus={false}
                        tabIndex={-1}
                    />
                </Tooltip>
            </div>
        }
    </div>
)