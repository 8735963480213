import Avatar from '@atlaskit/avatar';
import Dropdown, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Routes from '../helpers/Routes';
import i18next from '../i18n';
import AuthService from '../services/AuthService'

interface IUserMenuItem {
    title: string;
    handler?: () => void;
    href?: string;
}

const UserIconMenu = () => {
    const avatarSrc = AuthService.getUserInfo()?.AvatarBase64Content;

    const menuItems: IUserMenuItem[] = [
        { title: i18next.t("Profile"), href: Routes.userProfile.path },
        { title: i18next.t("SignOut"), handler: AuthService.signOut }
    ];


    const history = useHistory();

    const onClickHandlerFunc = (item: IUserMenuItem) => () => {

        if (item.href) {
            history.push(item.href);
        }

        if (item.handler) {
            item.handler();
        }
    }

    return (
        <Dropdown trigger={<Avatar src={avatarSrc} size="small" />} position="right bottom">
            <DropdownItemGroup title={AuthService.userName()}>
                {menuItems.map(item =>
                    <DropdownItem key={item.title} onClick={onClickHandlerFunc(item)} >{item.title}</DropdownItem>
                )}
            </DropdownItemGroup>
        </Dropdown>
    );
}

export default withTranslation()(UserIconMenu);
