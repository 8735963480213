//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ReferenceGroupType {
	ShowFromTheListOfSensors = 0,
	ShowFromSelectedSensors = 1
}

const ReferenceGroupTypeToName = new Map<number, string>([
	[ 0, 'ShowFromTheListOfSensors' ],
	[ 1, 'ShowFromSelectedSensors' ],
])

export function getReferenceGroupTypeToName(element: ReferenceGroupType) : string {
    return ReferenceGroupTypeToName.get(element) || '';
}

const ReferenceGroupTypeByName = new Map<string, number>([
	[ 'ShowFromTheListOfSensors', 0 ],
	[ 'ShowFromSelectedSensors', 1 ],
]);

export function getReferenceGroupTypeByName(name: string): ReferenceGroupType {
    return ReferenceGroupTypeByName.get(name)!;
}


export const ReferenceGroupTypeList : Readonly<ReferenceGroupType[]> = [
	ReferenceGroupType.ShowFromTheListOfSensors,
	ReferenceGroupType.ShowFromSelectedSensors,
];



const ReferenceGroupTypeToDescription = new Map<number, string>([
	[ 0, 'ShowFromTheListOfSensors' ],
	[ 1, 'ShowFromSelectedSensors' ],
])

export function getReferenceGroupTypeToDescription(element: ReferenceGroupType) : string {
    return ReferenceGroupTypeToDescription.get(element) || '';
}

