import React from "react";
import { HtmlReportSensorDescriptionViewModel } from "../../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportSensorDescriptionViewModel";

interface IComponentProps {
    descriptions: HtmlReportSensorDescriptionViewModel[];
}

export const XYVibrationChartLegend = ({
    descriptions
}: IComponentProps) => {

    /*const getSensorSymbol = (description: HtmlReportSensorDescriptionViewModel) => {
        const iconProps: ISVGIconProps = { ...getDefaultSensorSymbolIconProps(getSensorSymbolByName(description.symbol.toString())) };
        iconProps.fillColors = [description.color];
        iconProps.iconScale = 1.5;

        const svg = { __html: `${createSingleSensorIcon(iconProps)._createSVG()}` };

        return (
            <div dangerouslySetInnerHTML={svg} />
        );
    }*/

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', marginLeft: '10px', marginRight: '10px', justifyContent: 'center' }}>
            {descriptions.map<JSX.Element | null>((description, index) => (
                <React.Fragment key={`element-${index}`}>
                    <div key={`div-${index}`} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', marginRight: '10px', alignItems: 'center' }}>
                        {/* {getSensorSymbol(description)} */}
                        <div style={{
                            display: 'inline-block',
                            width: '15px',
                            height: '3px',
                            background: description.color,
                            margin: '2px',
                            verticalAlign: 'middle'
                        }}>
                            {/*  */}
                        </div>
                        <span style={{
                            fontWeight: 'bold',
                            marginLeft: '5px',
                            fontFamily: 'sans-serif',
                            textAnchor: 'middle',
                            fill: "#333333",
                            color: '#333333',
                            fontSize: '12px'
                        }}>{description.name}</span>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}