import Button from "@atlaskit/button";
import DownloadIcon from '@atlaskit/icon/glyph/download';
import { ReactComponent as InvisibleIcon } from 'src/resources/icons/eye-off.svg';
import { IWithGeovisServerProps } from "../../helpers/GeovisHooks";
import { mapToListOfElements } from "../../helpers/StorageHelper";
import i18next from "../../i18n";
import { AttachedDocumentModel } from "../../server/AttachedDocumentModel";
import ServerRoutesGen from "../../server/Routes/ServerRoutesGen";
import AuthService from "../../services/AuthService";
import { IAttachedDocumentsStorage, IProjectViewsStorage } from "../../store/data.types";
import { DynamicTableWithFixedHeader } from "../DynamicTableWithFixedHeader";
import { LoadingPageErrorSkeleton } from "../LoadingPageErrorSkeleton";

export interface IDocumentsListProps extends IWithGeovisServerProps {
    title: string;
    searchQuery?: string;
    documentsStorage: IAttachedDocumentsStorage;
    projectId: number;
    projectViewsStorage: IProjectViewsStorage;
}

export const DocumentsList = ({
    documentsStorage,
    title,
    projectId,
    Server,
    searchQuery,
    projectViewsStorage
}: IDocumentsListProps) => {

    const isAdminOfProject = AuthService.isActualAdminOfProject(projectId);
    const { documents, isLoading } = documentsStorage;
    const { viewId, projectViewsInfo } = projectViewsStorage;

    const documentUrl = (documentId: string) => (ServerRoutesGen.LocalMapObject.DownloadAttachedDocument.patch(projectId, documentId).path);

    const onOpenDocument = async (document: AttachedDocumentModel) => {
        const fileUrl = await Server.getFileObjectUrl(documentUrl(document.Id), document.Name);
        window.open(fileUrl, "_blank");
    }

    const openDocument = (document: AttachedDocumentModel) => {

        const onOpenDocumentClick = () => onOpenDocument(document);
        if (!document.IsPublic) {
            return (
                <div>
                    <Button
                        appearance="link"
                        spacing="compact"
                        onClick={onOpenDocumentClick}>
                        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <span style={{ lineHeight: "24px", float: "left" }}>{document.Name}</span>
                            <InvisibleIcon style={{ float: "left", marginLeft: 5, height: '20px', width: '20px', marginTop: '2px' }} />
                        </div>
                    </Button>
                </div>
            );
        }
        return (
            <div>
                <Button
                    appearance="link"
                    spacing="compact"
                    onClick={onOpenDocumentClick}>
                    {document.Name}
                </Button>
            </div>
        );
    }

    const onDownloadDocument = async (document: AttachedDocumentModel) => {
        await Server.downloadFile(documentUrl(document.Id), document.Name);
    }

    const downloadDocument = (document: AttachedDocumentModel) => {
        const onDownloadDocumentClick = () => onDownloadDocument(document);

        return (
            <div>
                <Button
                    spacing="none"
                    appearance="subtle-link"
                    title={i18next.t("Download document")}
                    iconBefore={<DownloadIcon label={""} />}
                    onClick={onDownloadDocumentClick}
                />
            </div>
        );
    }

    const filterDocumentsFunc = (document: AttachedDocumentModel): boolean => {
        if (viewId !== "Overview") {
            const view = projectViewsInfo.get(viewId);
            if (view && !view.DocumentsIds.includes(document.Id)) {
                return false;
            }
        }

        if (searchQuery && document.Name) {
            const lowerQuery = searchQuery.toLowerCase();
            const name = document.Name;

            try {
                if (name.toLowerCase().search(lowerQuery) === -1) {
                    return false;
                }
            } catch {
                return false;
            }
        }

        if (!document.IsPublic && !isAdminOfProject) {
            return false;
        }

        return true;
    }

    const head = {
        cells: [{
            key: 'documentTitle',
            content: i18next.t("reportTitleColumnName"),
            isSortable: true
        }, {
            key: 'downloadIcon',
            content: ""
        }]
    };

    const rows = mapToListOfElements(documents, filterDocumentsFunc).map(document => ({
        key: `row-${document.Id}`,
        cells: [{
            key: document.Name,
            content: openDocument(document)
        }, {
            content: downloadDocument(document)
        }]
    }));

    if (documentsStorage.isError) {
        return (
            <div className="page">
                <LoadingPageErrorSkeleton errorText={documentsStorage.errorDescription} />
            </div>
        );
    }
    else {
        return (
            <div style={{ height: '100%' }}>
                <div className="caption">
                    {title}
                </div>
                <DynamicTableWithFixedHeader
                    defaultSortKey='documentTitle'
                    defaultSortOrder='ASC'
                    isLoading={isLoading}
                    head={head}
                    rows={rows}
                />
            </div>
        );
    }

}
