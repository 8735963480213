import { Reducer } from 'redux';
import { ProjectLogoKind } from "../../components/logosManager/types";
import { defaultProjectCoordinates } from '../../helpers/Constants';
import { latLngToLocation } from '../../helpers/MapHelper';
import { addOrUpdateElementInMap, elementsToMap } from '../../helpers/StorageHelper';
import { ProjectManualState } from '../../server/AVTService/TypeLibrary/Common/ProjectManualState';
import { ProjectType } from '../../server/AVTService/TypeLibrary/Common/ProjectType';
import { ServiceModel } from '../../server/AVTService/TypeLibrary/Common/ServiceModel';
import { LogoFileInfo } from "../../server/AVTService/TypeLibrary/Logo/LogoFileInfo";
import { GeoLocation } from '../../server/GeoLocation';
import { CompanyInfo } from "../../server/GEOvis3/Model/Company/CompanyInfo";
import { GeovisUserTableInfo } from '../../server/GEOvis3/Model/User/GeovisUserTableInfo';
import { ProjectInfo } from "../../server/ProjectInfo";
import AuthService from '../../services/AuthService';
import {
    PROJECT_CREATE_CLEAN_STORAGE,
    PROJECT_CREATE_COMPANIES_LOADED,
    PROJECT_CREATE_COMPANIES_LOADING,
    PROJECT_CREATE_COMPANIES_LOADING_ERROR,
    PROJECT_CREATE_HIDE_MAP_MODAL,
    PROJECT_CREATE_LEADERS_LOADED,
    PROJECT_CREATE_LEADERS_LOADING,
    PROJECT_CREATE_LEADERS_LOADING_ERROR,
    PROJECT_CREATE_LOGO_EDIT_HIDE_LOGO_PICKER,
    PROJECT_CREATE_LOGO_EDIT_SET_LOGO,
    PROJECT_CREATE_LOGO_EDIT_SET_SELECTED_LOGO,
    PROJECT_CREATE_LOGO_EDIT_SHOW_LOGO_PICKER,
    PROJECT_CREATE_LOGO_EDIT_UPLOAD_END,
    PROJECT_CREATE_LOGO_EDIT_UPLOAD_ERROR,
    PROJECT_CREATE_LOGO_EDIT_UPLOAD_PROCESSING,
    PROJECT_CREATE_LOGO_EDIT_UPLOAD_STARTED,
    PROJECT_CREATE_LOGOS_LOADED,
    PROJECT_CREATE_LOGOS_LOADING,
    PROJECT_CREATE_LOGOS_LOADING_ERROR,
    PROJECT_CREATE_PROJECT_CHANGED,
    PROJECT_CREATE_SELECTED_LOCATION_CHANGED,
    PROJECT_CREATE_SHOW_MAP_MODAL
} from '../actions/projectCreateActions';
import { IGeovisProjectCreateAction, IGeovisProjectCreateState } from "../projectCreate.types";
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../types";
import { GeovisUploadFileState } from '../uploading.types';
import { ProjectLocalCoordinatesType } from '../../server/AVTService/TypeLibrary/Model/ProjectLocalCoordinatesType';
import { LicensedFeatures } from '../../server/AVTService/TypeLibrary/Licensing/LicensedFeatures';

const initProjectState: ProjectInfo = {
    ... new ProjectInfo(),
    Id: 0,
    BusinessState: ProjectManualState.Running,
    IsPartOfGEOvis4: !AuthService.hasUserTypeAsAdmin(),
    Location: latLngToLocation(defaultProjectCoordinates()),
    CreatorCompaniesIds: [AuthService.currentUserCompanyId()],
    ProjectType: AuthService.hasUsersCompanyLicenseFor(LicensedFeatures.Automatic_Projects_creation) ? ProjectType.None : ProjectType.Manual,
    ServiceModel: ServiceModel.None,
    InvoiceCompanyId: AuthService.hasUserTypeAsAdmin() ? "" : AuthService.currentUserCompanyId(),
    LocalCoordinatesType: ProjectLocalCoordinatesType.Polar
}

export const projectCreateInitialState: IGeovisProjectCreateState = {
    companiesStorage: {
        ...defaultSomethingStorageState,
        isLoading: false,
        companies: new Map<string, CompanyInfo>()
    },
    leadersStorage: {
        ...defaultSomethingStorageState,
        isLoading: false,
        users: new Map<string, GeovisUserTableInfo>()
    },
    logoEditState: {
        isPostInProgress: false,
        logoKind: ProjectLogoKind.Company,
        selectedLogoId: '',
        showSelectLogoPicker: false,
        uploadingFiles: []
    },
    logoFilesStorage: {
        ...defaultSomethingStorageState,
        isLoading: false,
        filesInfo: new Map<string, LogoFileInfo>()
    },
    project: {
        ...initProjectState
    },
    showLogoPickerPopup: false,
    locationState: {
        showPicker: false,
        location: new GeoLocation()
    }
}

const projectCreateReducer: Reducer<IGeovisProjectCreateState> = (
    state: IGeovisProjectCreateState = projectCreateInitialState,
    action: IGeovisProjectCreateAction): IGeovisProjectCreateState => {

    switch (action.type) {

        case PROJECT_CREATE_PROJECT_CHANGED: {
            if (!action.projectInfo) {
                return state;
            }
            return {
                ...state,
                project: action.projectInfo
            }
        }

        case PROJECT_CREATE_COMPANIES_LOADED: {
            if (!action.companies) {
                return state;
            }
            return {
                ...state,
                companiesStorage: {
                    ...loadedSomethingStorageState,
                    companies: elementsToMap(action.companies)
                }
            }
        }

        case PROJECT_CREATE_COMPANIES_LOADING: {
            return {
                ...state,
                companiesStorage: {
                    ...defaultSomethingStorageState,
                    companies: new Map<string, CompanyInfo>()
                }
            }
        }

        case PROJECT_CREATE_COMPANIES_LOADING_ERROR: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                companiesStorage: {
                    ...errorSomethingStorageState(action.stringProperty),
                    companies: new Map<string, CompanyInfo>()
                }
            }
        }

        case PROJECT_CREATE_LEADERS_LOADED: {
            if (!action.leaders) {
                return state;
            }
            return {
                ...state,
                leadersStorage: {
                    ...loadedSomethingStorageState,
                    users: elementsToMap(action.leaders)
                }
            }
        }

        case PROJECT_CREATE_LEADERS_LOADING: {
            return {
                ...state,
                leadersStorage: {
                    ...defaultSomethingStorageState,
                    users: new Map<string, GeovisUserTableInfo>()
                }
            }
        }

        case PROJECT_CREATE_LEADERS_LOADING_ERROR: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                leadersStorage: {
                    ...errorSomethingStorageState(action.stringProperty),
                    users: new Map<string, GeovisUserTableInfo>()
                }
            }
        }

        case PROJECT_CREATE_CLEAN_STORAGE: {
            return projectCreateInitialState;
        }

        // LOGO

        case PROJECT_CREATE_LOGO_EDIT_SHOW_LOGO_PICKER: {
            if (action.logoKind === undefined) {
                return state;
            }
            return {
                ...state,
                showLogoPickerPopup: true,
                logoEditState: {
                    ...state.logoEditState,
                    logoKind: action.logoKind,
                    selectedLogoId: action.objectId || ''
                }
            }
        }

        case PROJECT_CREATE_LOGO_EDIT_HIDE_LOGO_PICKER: {
            return {
                ...state,
                showLogoPickerPopup: false,
                logoEditState: {
                    ...state.logoEditState,
                    selectedLogoId: '',
                    logoKind: ProjectLogoKind.Company,
                }
            }
        }

        case PROJECT_CREATE_LOGO_EDIT_SET_LOGO: {
            if (!action.objectId || action.logoKind === undefined) {
                return state;
            }

            switch (action.logoKind) {
                case ProjectLogoKind.Company:
                    return {
                        ...state,
                        project: {
                            ...state.project,
                            CompanyLogoId: action.objectId
                        },
                        showLogoPickerPopup: false
                    };

                case ProjectLogoKind.User:
                    return {
                        ...state,
                        project: {
                            ...state.project,
                            UserLogoId: action.objectId
                        },
                        showLogoPickerPopup: false
                    }

                default:
                    return state;
            }
        }

        case PROJECT_CREATE_LOGO_EDIT_SET_SELECTED_LOGO: {
            if (!action.objectId) {
                return state;
            }

            return {
                ...state,
                logoEditState: {
                    ...state.logoEditState,
                    selectedLogoId: action.objectId
                }
            };
        }

        case PROJECT_CREATE_LOGO_EDIT_UPLOAD_END: {
            if (!action.objectId || !action.logoFileInfo) {
                return state;
            }

            return {
                ...state,
                logoEditState: {
                    ...state.logoEditState,
                    uploadingFiles: state.logoEditState.uploadingFiles.filter(f => f.id !== action.objectId)
                },
                logoFilesStorage: {
                    ...loadedSomethingStorageState,
                    filesInfo: addOrUpdateElementInMap(state.logoFilesStorage.filesInfo, action.logoFileInfo)
                }
            }
        }
        case PROJECT_CREATE_LOGO_EDIT_UPLOAD_ERROR: {
            if (!action.objectId) {
                return state;
            }

            return {
                ...state,
                logoEditState: {
                    ...state.logoEditState,
                    uploadingFiles: state.logoEditState.uploadingFiles.filter(f => f.id !== action.objectId)
                },
                logoFilesStorage: {
                    ...errorSomethingStorageState(action.objectId),
                    filesInfo: new Map<string, LogoFileInfo>()
                }
            }
        }

        case PROJECT_CREATE_LOGO_EDIT_UPLOAD_PROCESSING:
        case PROJECT_CREATE_LOGO_EDIT_UPLOAD_STARTED: {
            if (!action.uploadingFiles) {
                return state;
            }

            return {
                ...state,
                logoEditState: {
                    ...state.logoEditState,
                    uploadingFiles: action.uploadingFiles.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f }))
                }
            };
        }

        case PROJECT_CREATE_SHOW_MAP_MODAL: {
            return {
                ...state,
                locationState: {
                    showPicker: true,
                    location: undefined
                }
            }
        }

        case PROJECT_CREATE_HIDE_MAP_MODAL: {
            return {
                ...state,
                locationState: {
                    showPicker: false,
                    location: undefined
                }
            }
        }

        case PROJECT_CREATE_SELECTED_LOCATION_CHANGED: {
            if (!action.location) {
                return state;
            }
            return {
                ...state,
                locationState: {
                    ...state.locationState,
                    location: action.location
                }
            }
        }

        case PROJECT_CREATE_LOGOS_LOADED: {
            if (!action.logos) {
                return state;
            }
            return {
                ...state,
                logoFilesStorage: {
                    ...loadedSomethingStorageState,
                    filesInfo: elementsToMap(action.logos)
                }
            }
        }

        case PROJECT_CREATE_LOGOS_LOADING: {
            return {
                ...state,
                logoFilesStorage: {
                    ...defaultSomethingStorageState,
                    filesInfo: new Map<string, LogoFileInfo>()
                }
            }
        }

        case PROJECT_CREATE_LOGOS_LOADING_ERROR: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                logoFilesStorage: {
                    ...errorSomethingStorageState(action.stringProperty),
                    filesInfo: new Map<string, LogoFileInfo>()
                }
            }
        }

    }
    return state;
}

export default projectCreateReducer;