//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartData } from './GeovisChartData';
import { PlotBandModel } from '../Model/GeovisCharts/PlotBandModel';
import { GeovisChartSensorDataModel } from './GeovisChartSensorDataModel';
import { LogbookModel } from '../Logbook/LogbookModel';
import { HtmlReportSensorDescriptionViewModel } from '../Report/Model/HtmlReportSensorDescriptionViewModel';

export class TimeValueChartData extends GeovisChartData
{
	public PlotBands: PlotBandModel[];
	public LeftYAxisSensorIds: string[];
	public RightYAxisSensorIds: string[];
	public SensorsData: GeovisChartSensorDataModel[];
	public SecondaryAxisSensorsData: GeovisChartSensorDataModel[];
	public FirstStartTimeslot: string;
	public FirstEndTimeslot: string;
	public LastStartTimeslot: string;
	public LastEndTimeslot: string;
	public NextStartDate?: string;
	public NextEndDate?: string;
	public PrevStartDate?: string;
	public PrevEndDate?: string;
	public Logbooks: LogbookModel[];
	public MainAxisDescriptions: HtmlReportSensorDescriptionViewModel[];
	public SecondaryAxisDescriptions: HtmlReportSensorDescriptionViewModel[];
	public ShowNoData: boolean;
	public MinYValues: number[];
	public MaxYValues: number[];
	public MaxYSecondaryValues: number[];
	public MinYSecondaryValues: number[];
}
