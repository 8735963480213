import { hasFlag } from "country-flag-icons";
import Flags from "country-flag-icons/react/3x2";
import { Country, getCountryCodeByCountry, getCountryToName } from "../../server/AVTService/TypeLibrary/Sms/Country";
import { GeovisUserCommonInfo } from "../../server/GEOvis3/Model/User/GeovisUserCommonInfo";

export const drawFlag = (countryCode: Country): JSX.Element => {

    if (countryCode === Country.Unknown || !hasFlag(getCountryToName(countryCode))) {
        return (
            <div style={{ width: '30px', height: '18px', background: 'grey', display: 'flex', border: 'grey solid 1px' }}>
                {/*  */}
            </div>
        )
    }

    const Flag = Flags[getCountryToName(countryCode)];
    return (
        <div style={{ width: '30px', display: 'flex', boxShadow: '0 0 5px grey' }}>
            <Flag />
        </div>
    )
}

export const getUserNumber = (user: GeovisUserCommonInfo): string => {
    if (user.CountryCode === Country.Unknown) {
        return user.PhoneNumber;
    }
    return `+${getCountryCodeByCountry(user.CountryCode)}${user.PhoneNumber}`;
}