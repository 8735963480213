import { t } from "../../../../i18n";
import { ChartSettingBool } from "../../../../server/AVTService/TypeLibrary/Model/ChartSettingBool";
import { InclinometerChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { InclinometerVisibleRangeSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerVisibleRangeSettings";
import { ChartOptionsTab } from "../ChartOptionsTab";
import { CustomerChangeableChartBooleanSetting } from "../ChartTab_tools";
import { IGeovisChartPropertyChangedProps } from "../types";
import { InclinometerChartOptionSettings } from "./InclinometerChartOptionSettings";

type IInclinometerChartOptionsTabProps = IGeovisChartPropertyChangedProps<InclinometerChartModel>

export const InclinometerChartOptionsTab = ({ chart, onChartPropertyChanged, onChartPropertiesChanged }: IInclinometerChartOptionsTabProps) => {
    const {
        VisibleRangeSettings
    } = chart;
    const {
        ShowJunctionPoints,
        ShowSensors
    } = VisibleRangeSettings

    const onVisibleRangeSettingsChangedFunc = (propertyName: keyof InclinometerVisibleRangeSettings) => (value: any) => {
        VisibleRangeSettings[propertyName] = (value as never);
        onChartPropertyChanged("VisibleRangeSettings", { ...new InclinometerVisibleRangeSettings(), ...VisibleRangeSettings });
    }

    const getBooleanSetting = (setting: ChartSettingBool): ChartSettingBool => {
        return setting || { value: false, customerChangeable: false };
    }


    return (
        <div className='geovisChartTab'>
            {/* Show junction points */}
            <CustomerChangeableChartBooleanSetting
                setting={getBooleanSetting(ShowJunctionPoints)}
                settingLabel={t("Show junction points")}
                onChange={onVisibleRangeSettingsChangedFunc("ShowJunctionPoints")}
                isDisabled={false}
            />
            {/* Show sensors */}
            <CustomerChangeableChartBooleanSetting
                setting={getBooleanSetting(ShowSensors)}
                settingLabel={t("Show sensors")}
                onChange={onVisibleRangeSettingsChangedFunc("ShowSensors")}
                isDisabled={false}
            />
            <ChartOptionsTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />

            <InclinometerChartOptionSettings
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        </div>
    )
}