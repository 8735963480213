//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { KindOfElementUsing } from '../Common/KindOfElementUsing';
import { TimePeriod } from '../Common/TimePeriod';
import { ReportColorizableElement } from './ReportColorizableElement';
import { BucketReportValueKind } from '../DB/ArgusDataStorage/Bucket/Types/BucketReportValueKind';

export abstract class ReportModelBase
{
	public name: string;
	public title: string;
	public hashId: string;
	public isPreviewConfig: boolean;
	public projectId: number;
	public languageCode: string;
	public crazyFilter: boolean;
	public kindOfElementUsing: KindOfElementUsing;
	public isPublic: boolean;
	public useReportTimeSettings: boolean;
	public showAllMeasurements: boolean;
	public period: TimePeriod;
	public useLastMeasurementTime: boolean;
	public allowDisabledValues: boolean;
	public allowDisabledValuesCustomerChangeable: boolean;
	public timeoutGeneration: string;
	public reportColorizableElements: ReportColorizableElement[];
	public useBucketReportValueKind: boolean;
	public bucketReportValueKind: BucketReportValueKind;
	public lastUpdateTime: string;
}
