/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 17.12.2020
 * @description Geovis select control
 */

import Select, { CheckboxSelect, ActionMeta, AsyncSelect, OptionType, SelectProps } from '@atlaskit/select';
import { SelectComponentsConfig } from 'react-select';
import { IGvOptionType, IGvOptionTypeEx } from './GeovisSelect_tools';

/**
 * The base geovis select props interface
 */
type IGeovisSelectProps<TOptionType extends OptionType, IsMulti extends boolean> = SelectProps<TOptionType, IsMulti>

export interface IGeovisSingleSelectProps<TOptionType extends OptionType> extends IGeovisSelectProps<TOptionType, false> {
    useAllWidth?: boolean;
}

export interface IGeovisMultiSelectProps<TOptionType extends OptionType> extends IGeovisSelectProps<TOptionType, true> {
    components?: SelectComponentsConfig<TOptionType, true>;
    fontSize?: string;
    valueContainerHeight?: string;
    useAllWidth?: boolean;
}

export const GeovisSelect = <TOptionType extends OptionType>({ useAllWidth, ...props }: IGeovisSingleSelectProps<TOptionType>) => (
    <Select
        menuPortalTarget={document.body}
        styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            container: base => useAllWidth ? { ...base, width: '100%' } : base
        }}
        spacing='compact'
        {...props}
    />
)

export const GeovisAsyncSelect = <TOptionType extends OptionType>({ useAllWidth, ...props }: IGeovisSingleSelectProps<TOptionType>) => (
    <AsyncSelect
        menuPortalTarget={document.body}
        spacing='compact'
        styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            container: base => useAllWidth ? { ...base, width: '100%' } : base
        }}
        {...props}
    />
)

export interface IGeovisSelectTProps<TValue extends number | string> extends IGeovisSingleSelectProps<IGvOptionType<TValue>> {
    onChange: (value: IGvOptionType<TValue>) => void;
}

export const GeovisSelectT = <TValue extends number | string>({ useAllWidth, ...props }: IGeovisSelectTProps<TValue>) => (
    <Select
        menuPortalTarget={document.body}
        spacing='compact'
        styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            container: base => useAllWidth ? { ...base, width: '100%' } : base
        }}
        {...props} />
)

export const GeovisSelectMulti = ({ useAllWidth, ...props }: IGeovisMultiSelectProps<OptionType>) => (
    <Select
        menuPortalTarget={document.body}
        spacing='compact'
        styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999, fontSize: props.fontSize ?? base.fontSize }),
            multiValueLabel: (base) => ({ ...base, fontSize: props.fontSize ?? base.fontSize }),
            placeholder: (base) => ({ ...base, fontSize: props.fontSize ?? base.fontSize }),
            container: base => useAllWidth ? { ...base, width: '100%' } : base,
            valueContainer: (base) => ({ ...base, height: props.valueContainerHeight ?? base.height }),
        }}
        {...props}
        isMulti={true}
    />
)

/**
 * GeovisSelectMultiT properties
 */
export interface IGeovisSelectTMultiProps<TValue extends number | string> extends IGeovisMultiSelectProps<IGvOptionType<TValue>> {
    onChange: (values: Array<IGvOptionType<TValue>>, action: ActionMeta<IGvOptionType<TValue>>) => void;
}

/**
 * Geovis select component to select Many items
 * @param props 
 * @returns 
 */
export const GeovisSelectMultiT = <TValue extends number | string>({ useAllWidth, ...props }: IGeovisSelectTMultiProps<TValue>) => (
    <Select
        menuPortalTarget={document.body}
        styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            container: base => useAllWidth ? { ...base, width: '100%' } : base
        }}
        spacing='compact'
        {...props}
        isMulti={true}
    />
)


export interface IGeovisSelectTExtProps<TValue extends number | string, TPayload> extends IGeovisSingleSelectProps<IGvOptionTypeEx<TValue, TPayload>> {
    onChange: (value: IGvOptionTypeEx<TValue, TPayload>) => void;
}

export const GeovisSelectTExt = <TValue extends number | string, TPayload>({ useAllWidth, ...props }: IGeovisSelectTExtProps<TValue, TPayload>) => (
    <Select
        menuPortalTarget={document.body}
        spacing='compact'
        styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            container: base => useAllWidth ? { ...base, width: '100%' } : base
        }}
        {...props}
    />
)

export interface IGeovisSelectMultiTExtProps<TValue extends number | string, TPayload> extends IGeovisMultiSelectProps<IGvOptionTypeEx<TValue, TPayload>> {
    onChange: (options: Array<IGvOptionTypeEx<TValue, TPayload>>, action: ActionMeta<IGvOptionTypeEx<TValue, TPayload>>) => void;
}

export const GeovisSelectMultiTExt = <TValue extends number | string, TPayload>({ styles, useAllWidth, ...restProps }: IGeovisSelectMultiTExtProps<TValue, TPayload>) => (
    <Select
        spacing='compact'
        menuPortalTarget={document.body}
        styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 1000 }),
            container: base => useAllWidth ? { ...base, width: '100%' } : base,
            ...styles
        }}
        {...restProps}
        isMulti={true}
    />
)

export interface IGeovisCheckboxSelectProps extends SelectProps<OptionType, true> {
    useAllWidth?: boolean;
}

export const GeovisCheckboxSelect = ({ useAllWidth, ...props }: IGeovisCheckboxSelectProps) => (
    <CheckboxSelect
        menuPortalTarget={document.body}
        styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            container: base => useAllWidth ? { ...base, width: '100%' } : base
        }}
        spacing='compact'
        {...props}
    />
)

export interface IGeovisCheckboxSelectTMultiProps<TValue extends number | string> extends IGeovisMultiSelectProps<IGvOptionType<TValue>> {
    onChange: (values: Array<IGvOptionType<TValue>>, action: ActionMeta<IGvOptionType<TValue>>) => void;
}

export const GeovisCheckboxSelectMultiT = <TValue extends number | string>({ useAllWidth, ...props }: IGeovisCheckboxSelectTMultiProps<TValue>) => (
    <CheckboxSelect
        menuPortalTarget={document.body}
        styles={{
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
            container: base => useAllWidth ? { ...base, width: '100%' } : base
        }}
        spacing='compact'
        {...props}
        isMulti={true}
    />
)