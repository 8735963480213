/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.10.2021
 * @description Data migrations actions for store
 */


export const DATA_MIGRATION_GENERAL_INFO = 'DATA_MIGRATION_GENERAL_INFO';
export const DATA_MIGRATION_GENERAL_INFO_LOADED = 'DATA_MIGRATION_GENERAL_INFO_LOADED';

export const DATA_MIGRATION_PROJECTS_INFO = 'DATA_MIGRATION_PROJECTS_INFO';
export const DATA_MIGRATION_PROJECTS_INFO_LOADED = 'DATA_MIGRATION_PROJECTS_INFO_LOADED';

export const DATA_MIGRATION_FILTER_CHANGED = 'DATA_MIGRATION_FILTER_CHANGED';
export const DATA_MIGRATION_UPDATE_ARGUS_MIGRATION_INFO = 'DATA_MIGRATION_UPDATE_ARGUS_MIGRATION_INFO';

export const DATA_MIGRATION_MSTS_OVERVIEW_LOADED = 'DATA_MIGRATION_MSTS_OVERVIEW_LOADED';
export const DATA_MIGRATION_UPDATE_MST_MIGRATIONS_INFO = 'DATA_MIGRATION_UPDATE_MST_MIGRATIONS_INFO';
export const DATA_MIGRATION_UPDATE_MST_MIGRATIONS_SETTINGS = 'DATA_MIGRATION_UPDATE_MST_MIGRATIONS_SETTINGS';