/**
 * Default route description
 */
export default class Route {
    public exact: boolean;
    public path: string;

    constructor({ path, exact = true, route }: {
        path: string,
        exact?: boolean,
        route?: Route
    }) {
        this.exact = exact;
        this.path = path;

        if (route) {
            this.path = route.path + path;
        }
    }

    /**
     * Path the route template
     * @param args String arguments
     */
    public patch(...args: any[]) {
        let patchedPath = this.path.slice(0);
        for (const arg of args) {
            // eslint-disable-next-line no-useless-escape
            patchedPath = patchedPath.replace(/:.[^\/&]*/, arg);
        }

        return new Route({
            exact: this.exact,
            path: patchedPath
        });
    }

    /**
     * Get piece of route by index
     * @param index Route index
     */
    public getPiece(index: number): string | undefined {
        const parts = this.path.split("/");

        if (index + 1 < parts.length) {
            return parts[index + 1];
        }

        return undefined;
    }
}