import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { t } from '../../../../i18n';
import { ChartType } from '../../../../server/AVTService/TypeLibrary/Common/ChartType';
import { IGeovisReportSettings } from '../../../../store/projectReport.types';
import { IGeovisStoreState } from '../../../../store/store.types';
import { compareChartTypes } from '../../charts/tools';
import { ChartImageRender } from './ChartImageRender';
import GeovisChartRenderHost from './charts/GeovisChartRenderHost';
import GeovisChartReportTitle from './charts/GeovisChartReportTitle';
import "./geovis.Highcharts.SVGRenderer"; // required for drawing the sensors symbols
import { IReportElementRenderHostOwnProp } from './types';
import { IGeovisAction } from '../../../../store/types';
import { projectGeovisReportVibrationEventHide } from '../../../../store/creators/projectReportCreators';

interface IStateToProps {
    chartType: ChartType | undefined;
}

interface IDispatchToProps {
    onHideVibrationEvent: (pageNum: number, chartId: number) => void;
}

interface IOwnProps extends IReportElementRenderHostOwnProp {

    /**
     * This property used when chart renders in to Raster image to draw on PDF page (actual for Heatmap, for any other kind of charts not used yet)
     * If it true, then this component asks the server to return the Image content of expected chart
     * 
     * Used only if isPrinting = true
     */
    /**
     * This property used when chart renders in to Raster image to draw on PDF page (actual for Heatmap, for any other kind of charts not used yet)
     * If it true, then this component asks the server to return the Image content of expected chart
     * 
     * Used only if isPrinting = true
     */
    useClientRenderer?: boolean;

    rootContainerWidth: number;

    hideVibrationEventChartOnPage?: () => void;
}

interface IComponentProps extends IStateToProps, IDispatchToProps, IOwnProps {
}

const ChartElementRenderComponent = ({
    elementInfo: { Height, HideTitle, Id, DrawBorder },
    elementIndexOnPage, projectId, settingsId, isInGroup,
    reportId, userId, userToken, isPrinting, isDefault, lastInGroup,
    useClientRenderer, chartType, pageNum, eventInfo, isVibrationEventChart,
    showVibrationEventChartOnPage, hideVibrationEventChartOnPage,
    onHideVibrationEvent, rootContainerWidth
}: IComponentProps) => {

    if (chartType === undefined) {
        // eslint-disable-next-line no-console
        console.error(`REPORT ERROR: chart type is undefined, id=${Id}`);
        return (<div>{t('Chart type is undefined') + `, id=${Id}`}</div>)
    }

    if (isVibrationEventChart && !eventInfo) {
        // eslint-disable-next-line no-console
        console.error(`REPORT ERROR: vibration event chart without event info, id=${Id}`);
        return (<div>{t('Event chart without event info') + `, id=${Id}`}</div>)
    }

    if (!useClientRenderer && isPrinting && compareChartTypes(chartType, ChartType.HeatMap)) {
        return (
            <ChartImageRender
                reportId={reportId}
                chartId={Id}
                projectId={projectId}
                settingsId={settingsId}
                userId={userId}
                userToken={userToken}
                drawBorder={DrawBorder && !lastInGroup}
                height={Height}
                pageNum={pageNum}
            />
        )
    }

    const onHideVibrationChartHandler = () => {
        onHideVibrationEvent(pageNum, Id);
        if (hideVibrationEventChartOnPage) {
            hideVibrationEventChartOnPage();
        }
    }

    return (
        <div style={{ height: `${Height}px`, width: '100%', borderRight: DrawBorder && !lastInGroup ? '2px solid black' : '' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: `100%` }}>
                {(!HideTitle || isVibrationEventChart) && (
                    <GeovisChartReportTitle
                        pageNum={pageNum}
                        chartId={Id}
                        chartIndexOnPage={elementIndexOnPage}
                        isInGroup={isInGroup}
                        lastInGroup={lastInGroup || DrawBorder}
                        vibrationEventInfo={eventInfo}
                        onHideVibrationEvent={onHideVibrationChartHandler}
                        isVibrationEventChart={isVibrationEventChart}
                        isPrinting={isPrinting}
                        reportId={reportId}
                    />
                )}
                <GeovisChartRenderHost
                    key={`chart-host-id-${Id}-${rootContainerWidth}`}
                    chartId={Id}
                    reportId={reportId}
                    pageNum={pageNum}
                    userId={userId}
                    userToken={userToken}
                    isPrinting={isPrinting}
                    isDefault={isDefault}
                    showVibrationEventChartOnPage={showVibrationEventChartOnPage}
                    eventInfo={eventInfo}
                    isVibrationEventChart={isVibrationEventChart}
                />
            </div>
        </div>);
}

const getChartType = (pageNum: number, chartId: number, { geovisPages }: IGeovisReportSettings): ChartType | undefined => {
    const page = geovisPages.get(pageNum);
    if (!page) {
        return undefined;
    }

    const chart = page.geovisCharts.get(chartId);
    if (!chart) {
        return undefined;
    }

    return chart.Chart.Type;
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { elementInfo, pageNum }: IOwnProps): IStateToProps => ({
    chartType: getChartType(pageNum, elementInfo.Id, projectReport.geovisReportSettings)
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDispatchToProps => ({
    onHideVibrationEvent: (pageNum, chartId) => dispatch(projectGeovisReportVibrationEventHide(pageNum, chartId))
})

export const ChartElementRender = connect<IStateToProps, IDispatchToProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(ChartElementRenderComponent);