/**
 * Project edit navigation in the container
 */

import BitbucketBuildsIcon from '@atlaskit/icon/glyph/bitbucket/builds';
import CameraTakePictureIcon from '@atlaskit/icon/glyph/camera-take-picture';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';
import EditIcon from '@atlaskit/icon/glyph/edit';
import EditorAddonIcon from '@atlaskit/icon/glyph/editor/addon';
import EmailIcon from '@atlaskit/icon/glyph/email';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent';
import FileIcon from '@atlaskit/icon/glyph/file';
import LocationIcon from '@atlaskit/icon/glyph/location';
import NotificationAllIcon from '@atlaskit/icon/glyph/notification-all';
import OverviewIcon from '@atlaskit/icon/glyph/overview';
import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group';
import RoadmapIcon from '@atlaskit/icon/glyph/roadmap';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { Item, MenuSection, Separator, SkeletonContainerView } from '@atlaskit/navigation-next';
import { connect } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { ReactComponent as DatabaseIcon } from 'src/resources/icons/database.svg';
import DtsIcon from 'src/resources/icons/dts.png';
import { ReactComponent as LogbookIcon } from 'src/resources/icons/notebook.svg';
import { ReactComponent as WifiTowerIcon } from 'src/resources/icons/radio-tower.svg';
import { ReactComponent as WifiTowerIconDisabled } from 'src/resources/icons/radio-tower-disabled.svg';
import {
    geovisLiveProjectUrl,
    geovisLiveReportsUrl,
    geovisLiveSensorsUrl
} from '../../helpers/GeovisLiveHelper';
import IRouteParams from '../../helpers/IRouteParams';
import { isProjectElementsMigrationComplete } from '../../helpers/ProjectElementsHelper';
import Routes from '../../helpers/Routes';
import i18next from '../../i18n';
import { MigratebleElements } from '../../server/AVTService/TypeLibrary/Common/ElementsMigrations/MigratebleElements';
import AuthService from '../../services/AuthService';
import { IProjectInfoStorage } from '../../store/data.types';
import { IGeovisStoreState } from '../../store/store.types';
import { BackIcon } from '../Icons';
import { LicensedFeatures } from '../../server/AVTService/TypeLibrary/Licensing/LicensedFeatures';
import { GeovisLicenseType } from '../../server/AVTService/TypeLibrary/Common/GeovisLicenseType';
import { ProjectEditSensorsTab } from '../../pages/edit/sensors/types';

const ELEMENT_PART = "Elements";
const SENSOR_PART = "Sensors";
const REPORT_PART = "Reports";

interface IProjectEditNavigationProps {
    projectInfo: IProjectInfoStorage;
}

const ProjectEditNavigation = ({ projectInfo: { isLoading, project } }: IProjectEditNavigationProps) => {

    if (isLoading) {
        return (<SkeletonContainerView type="content" />);
    }

    if (!project) {
        throw new Error("Cannot show Project Edit Navigation. Project is not set");
    }

    const elementsUrl = geovisLiveProjectUrl(project.Id);
    const sensorsUrl = geovisLiveSensorsUrl(project.Id);
    const reportsUrl = geovisLiveReportsUrl(project.Id);

    const isEditProperties = useRouteMatch<IRouteParams>(Routes.projectEditProperties) || useRouteMatch<IRouteParams>(Routes.projectEdit);

    const isEditDxfLayers = useRouteMatch<IRouteParams>(Routes.projectEditDxfLayers) || useRouteMatch<IRouteParams>(Routes.projectEditGeoImageLayers);
    const isEditVisualSettings = useRouteMatch<IRouteParams>(Routes.projectEditVisualSettings);
    const isEditAttachedDocs = useRouteMatch<IRouteParams>(Routes.projectEditAttachedDocuments);
    const isEditLmo = useRouteMatch<IRouteParams>(Routes.projectEditLocalMapObjects);
    const isEditViews = useRouteMatch<IRouteParams>(Routes.projectEditViews);
    const isEditAlarms = useRouteMatch<IRouteParams>(Routes.projectEditAlarms);
    const isEditAC = useRouteMatch<IRouteParams>(Routes.projectEditAccessControl);
    const isEditNotify = useRouteMatch<IRouteParams>(Routes.projectEditNotifications);
    const isEditElements = useRouteMatch<IRouteParams>(Routes.projectEditElements);
    const isEditReports = useRouteMatch<IRouteParams>(Routes.projectEditReports);
    const isEditDatabases = useRouteMatch<IRouteParams>(Routes.projectEditDatabases);
    const isLora = useRouteMatch<IRouteParams>(Routes.projectLoRa);
    const isEditLogbooks = useRouteMatch<IRouteParams>(Routes.projectLogbook);
    const isEditDtsConfiguration = useRouteMatch<IRouteParams>(Routes.projectDtsConfiguration);
    const isEditSensors = useRouteMatch<IRouteParams>(Routes.projectEditSensorsList);

    const isEditTasks = useRouteMatch<IRouteParams>(Routes.projectEditTasks);

    const openGeovisLivePage = (part: string) => () => {
        let url = "";
        switch (part) {
            case ELEMENT_PART: {
                url = elementsUrl;
                break;
            }
            case REPORT_PART: {
                url = reportsUrl;
                break;
            }
            case SENSOR_PART: {
                url = sensorsUrl;
                break;
            }
        }
        window.open(url, '_blank');
    }

    const dtsIconItemBefore = () => (<img style={{ float: "left" }} src={DtsIcon} />);

    const showGEOvisLiveElementsLink = !project.AllElementsMigrationCompleted;
    const showGEOvisLiveReportsLink = !isProjectElementsMigrationComplete(project, MigratebleElements.Reports);

    const projectAllowedToUseFeature = (feature: LicensedFeatures): boolean => {
        return project.AvailableFeatures.includes(feature);
    }

    const projectHasNoneLicenseInvoiceCompany = project.InvoiceCompanyLicenseType === GeovisLicenseType.None && project.IsPartOfGEOvis4 && project.IsNewInvoiceForCompanyEnabled;

    return (
        <MenuSection>
            {({ className }: any) => (
                <div className={className} style={{ marginTop: '15px' }}>
                    <Link
                        to={Routes.project.patch(project.Id).path}
                        style={{ textDecoration: 'none' }}>
                        <Item
                            before={() => <BackIcon label={i18next.t("Back to Project")} />}
                            text={i18next.t("Back to Project")}
                        />
                    </Link>

                    <div style={{ marginTop: '10px' }}>
                        {!projectHasNoneLicenseInvoiceCompany &&
                            <Link
                                to={Routes.projectEditProperties.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditProperties}
                                    before={() => <EditIcon label={i18next.t("Properties")} />}
                                    text={i18next.t("Properties")}
                                />
                            </Link>
                        }

                        {projectHasNoneLicenseInvoiceCompany &&
                            <Item
                                isSelected={isEditProperties}
                                before={() => <EditIcon label={i18next.t("Properties")} primaryColor="#a5adba" />}
                                text={i18next.t("Properties")} />
                        }

                        {!projectHasNoneLicenseInvoiceCompany &&
                            <Link
                                to={Routes.projectEditNotifications.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditNotify}
                                    before={() => <EmailIcon label="" />}
                                    text={i18next.t("Notifications")} />
                            </Link>
                        }

                        {projectHasNoneLicenseInvoiceCompany &&
                            <Item
                                isSelected={isEditNotify}
                                before={() => <EmailIcon label={i18next.t("Notifications")} primaryColor="#a5adba" />}
                                text={i18next.t("Notifications")} />
                        }
                        <Separator />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        {!projectHasNoneLicenseInvoiceCompany &&
                            <Link
                                to={Routes.projectEditDatabases.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditDatabases}
                                    before={() =>
                                        <DatabaseIcon className='project-edit-navigation-icon' style={{ stroke: '#42526E' }} />
                                    }
                                    text={i18next.t("Databases")} />
                            </Link>}

                        {projectHasNoneLicenseInvoiceCompany &&
                            <Item
                                isSelected={isEditSensors}
                                before={() => <DatabaseIcon className='project-edit-navigation-icon' style={{ stroke: "#a5adba" }} />}
                                text={i18next.t("Databases")} />
                        }

                        {/* <Link
                            to={Routes.projectEditSensorsList.patch(project.Id).path}
                            style={{ textDecoration: 'none' }}>
                            <Item
                                isSelected={isEditSensors}
                                before={() => <CameraTakePictureIcon label={i18next.t("Sensors")} />}
                                text={i18next.t("Sensors")} />
                        </Link> */}

                        {!projectHasNoneLicenseInvoiceCompany &&
                            // <Item
                            //     isSelected={isEditSensors}
                            //     onClick={openGeovisLivePage(SENSOR_PART)}
                            //     before={() => <CameraTakePictureIcon label={i18next.t("Sensors")} />}
                            //     text={i18next.t("Sensors")} />

                            <Link
                                to={Routes.projectEditSensorsList.patch(project.Id, ProjectEditSensorsTab.Standard).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditSensors}
                                    before={() => <CameraTakePictureIcon label="" />}
                                    text={i18next.t("Sensors")} />
                            </Link>
                        }
                        {projectHasNoneLicenseInvoiceCompany &&
                            <Item
                                isSelected={isEditSensors}
                                before={() => <CameraTakePictureIcon label={i18next.t("Sensors")} primaryColor="#a5adba" />}
                                text={i18next.t("Sensors")} />
                        }

                        {projectAllowedToUseFeature(LicensedFeatures.LoRa) &&
                            <Link
                                to={Routes.projectLoRa.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isLora}

                                    before={() =>
                                        <WifiTowerIcon className='project-edit-navigation-icon' />
                                    }
                                    text={i18next.t("LORA")} />
                            </Link>
                        }

                        {!projectAllowedToUseFeature(LicensedFeatures.LoRa) &&
                            <Item
                                isSelected={isLora}
                                before={() => <WifiTowerIconDisabled className='project-edit-navigation-icon' style={{ fill: '#a5adba' }} />}
                                text={i18next.t("LORA")} />
                        }

                        {AuthService.isNagraDistribution() && (
                            <Link
                                to={Routes.projectDtsConfiguration.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditDtsConfiguration}
                                    before={dtsIconItemBefore}
                                    text={i18next.t("DTS Configuration")} />
                            </Link>
                        )}

                        {/* tasks style when feature enabled */}
                        {projectAllowedToUseFeature(LicensedFeatures.Automatic_import_export) && (
                            <Link
                                to={Routes.projectEditTasks.patch(project.Id, 'import').path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditTasks}
                                    before={() => <EmojiFrequentIcon label={i18next.t("Tasks")} />}
                                    text={i18next.t("Tasks")} />
                            </Link>
                        )}

                        {/* tasks style when feature disabled, this is not a copy */}
                        {!projectAllowedToUseFeature(LicensedFeatures.Automatic_import_export) &&
                            <Item
                                isSelected={false}
                                before={() => <EmojiFrequentIcon label={i18next.t("Tasks")} primaryColor="#a5adba" />}
                                text={i18next.t("Tasks")} />
                        }

                        {(!project.IsNewInvoiceForCompanyEnabled && !project.AllowAlarms || !projectAllowedToUseFeature(LicensedFeatures.Alarms_visualization) && project.IsNewInvoiceForCompanyEnabled) &&
                            <Item
                                isSelected={isEditAlarms}
                                before={() => <NotificationAllIcon label={i18next.t("Alarms")} primaryColor="#a5adba" />}
                                text={i18next.t("Alarms")} />
                        }

                        {(!project.IsNewInvoiceForCompanyEnabled && project.AllowAlarms || projectAllowedToUseFeature(LicensedFeatures.Alarms_visualization) && project.IsNewInvoiceForCompanyEnabled) &&
                            <Link
                                to={Routes.projectEditAlarms.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditAlarms}
                                    before={() => <NotificationAllIcon label={i18next.t("Alarms")} />}
                                    text={i18next.t("Alarms")} />
                            </Link>
                        }

                        {showGEOvisLiveElementsLink && projectAllowedToUseFeature(LicensedFeatures.Elements_reports) && (
                            <Item
                                isSelected={false}
                                onClick={openGeovisLivePage(ELEMENT_PART)}
                                before={() => <EditorAddonIcon label={i18next.t("Elements")} />}
                                text={i18next.t("Elements")} />
                        )}

                        {projectAllowedToUseFeature(LicensedFeatures.Elements_reports) &&
                            <Link
                                to={Routes.projectEditElements.patch(project.Id, 'All', 'none', 0).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditElements}
                                    before={() => <EditorAddonIcon label={i18next.t("Elements")} />}
                                    text={i18next.t(showGEOvisLiveElementsLink ? "elementsGeovis4_0Label" : "Elements")} />
                            </Link>
                        }

                        {!projectAllowedToUseFeature(LicensedFeatures.Elements_reports) &&
                            <Item
                                isSelected={isEditElements}
                                before={() => <EditorAddonIcon label={i18next.t("Elements")} primaryColor="#a5adba" />}
                                text={i18next.t("Elements")} />
                        }

                        {projectAllowedToUseFeature(LicensedFeatures.Elements_reports) &&
                            <Link
                                to={Routes.projectEditReports.patch(project.Id, "Normal").path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditReports}
                                    before={() => <FileIcon label={i18next.t("Reports")} />}
                                    text={i18next.t(showGEOvisLiveReportsLink ? "reportsGeovis4_0Label" : "Reports")} />
                            </Link>
                        }

                        {!projectAllowedToUseFeature(LicensedFeatures.Elements_reports) &&
                            <Item
                                isSelected={isEditReports}
                                before={() => <FileIcon label={i18next.t("Reports")} primaryColor="#a5adba" />}
                                text={i18next.t("Reports")} />
                        }

                        {showGEOvisLiveReportsLink && projectAllowedToUseFeature(LicensedFeatures.Elements_reports) && (
                            <Item
                                isSelected={false}
                                onClick={openGeovisLivePage(REPORT_PART)}
                                before={() => <FileIcon label={i18next.t("Reports")} />}
                                text={i18next.t("Reports")} />
                        )}

                        {projectAllowedToUseFeature(LicensedFeatures.Document_storage) && <Link
                            to={Routes.projectEditAttachedDocuments.patch(project.Id).path}
                            style={{ textDecoration: 'none' }}>
                            <Item
                                isSelected={isEditAttachedDocs}
                                before={() => <DocumentsIcon label="" />}
                                text={i18next.t("Documents")} />
                        </Link>
                        }

                        {!projectAllowedToUseFeature(LicensedFeatures.Document_storage) &&
                            <Item
                                isSelected={isEditAttachedDocs}
                                before={() => <DocumentsIcon label={i18next.t("Documents")} primaryColor="#a5adba" />}
                                text={i18next.t("Documents")} />
                        }

                        {projectAllowedToUseFeature(LicensedFeatures.Logbook) &&
                            <Link
                                to={Routes.projectLogbook.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditLogbooks}
                                    before={() =>
                                        <LogbookIcon className='project-edit-navigation-icon' style={{ fill: '#42526E' }} />
                                    }
                                    text={i18next.t("Logbook")} />
                            </Link>
                        }
                        {!projectAllowedToUseFeature(LicensedFeatures.Logbook) &&
                            <Item
                                isSelected={isEditLogbooks}
                                before={() => <LogbookIcon className='project-edit-navigation-icon' style={{ fill: '#a5adba' }} />}
                                text={i18next.t("Logbook")} />
                        }
                        <Separator />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        {projectAllowedToUseFeature(LicensedFeatures.DXF_Overlay) &&
                            <Link
                                to={Routes.projectEditDxfLayers.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditDxfLayers}
                                    before={() => <RoadmapIcon label={i18next.t("Background Layers")} />}
                                    text={i18next.t("Background Layers")} />
                            </Link>
                        }

                        {!projectAllowedToUseFeature(LicensedFeatures.DXF_Overlay) &&
                            <Item
                                isSelected={isEditDxfLayers}
                                before={() => <RoadmapIcon label={i18next.t("Background Layers")} primaryColor="#a5adba" />}
                                text={i18next.t("Background Layers")} />
                        }

                        {projectAllowedToUseFeature(LicensedFeatures.Local_Map_Object) &&
                            <Link
                                to={Routes.projectEditLocalMapObjects.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditLmo}
                                    before={() => <LocationIcon label={i18next.t("Local Map Objects")} />}
                                    text={i18next.t("Local Map Objects")} />
                            </Link>
                        }

                        {!projectAllowedToUseFeature(LicensedFeatures.Local_Map_Object) &&
                            <Item
                                isSelected={isEditLmo}
                                before={() => <LocationIcon label={i18next.t("Local Map Objects")} primaryColor="#a5adba" />}
                                text={i18next.t("Local Map Objects")} />
                        }

                        {!projectHasNoneLicenseInvoiceCompany &&
                            <Link
                                to={Routes.projectEditVisualSettings.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditVisualSettings}
                                    before={() => <BitbucketBuildsIcon label={i18next.t("Visual settings")} />}
                                    text={i18next.t("Visual settings")} />
                            </Link>
                        }

                        {projectHasNoneLicenseInvoiceCompany &&
                            <Item
                                isSelected={isEditVisualSettings}
                                before={() => <BitbucketBuildsIcon label={i18next.t("Visual settings")} primaryColor="#a5adba" />}
                                text={i18next.t("Visual settings")} />
                        }

                        {!projectHasNoneLicenseInvoiceCompany && <Link
                            to={Routes.projectEditViews.patch(project.Id).path}
                            style={{ textDecoration: 'none' }}>
                            <Item
                                isSelected={isEditViews}
                                before={() => <OverviewIcon label="" />}
                                text={i18next.t("Views")} />
                        </Link>
                        }

                        {projectHasNoneLicenseInvoiceCompany &&
                            <Item
                                isSelected={isEditViews}
                                before={() => <OverviewIcon label={i18next.t("Views")} primaryColor="#a5adba" />}
                                text={i18next.t("Views")} />
                        }

                        {!projectHasNoneLicenseInvoiceCompany && AuthService.hasUserTypeAsCompanyAdmin() && (
                            <Link
                                to={Routes.projectEditAccessControl.patch(project.Id).path}
                                style={{ textDecoration: 'none' }}>
                                <Item
                                    isSelected={isEditAC}
                                    before={() => <PeopleGroupIcon label="" />}
                                    text={i18next.t("Access control")} />
                            </Link>
                        )}

                        {projectHasNoneLicenseInvoiceCompany &&
                            <Item
                                isSelected={isEditAC}
                                before={() => <PeopleGroupIcon label={i18next.t("Access control")} primaryColor="#a5adba" />}
                                text={i18next.t("Access control")} />
                        }

                    </div>
                </div>
            )}
        </MenuSection>
    );
};

const mapStateToProps = ({ data }: IGeovisStoreState): IProjectEditNavigationProps => ({
    projectInfo: data.projectInfo
});

export default connect<IProjectEditNavigationProps>(mapStateToProps)(ProjectEditNavigation);