//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GeovisHeaderLogoSource {
	Project = 0,
	Custom = 1
}

const GeovisHeaderLogoSourceToName = new Map<number, string>([
	[ 0, 'Project' ],
	[ 1, 'Custom' ],
])

export function getGeovisHeaderLogoSourceToName(element: GeovisHeaderLogoSource) : string {
    return GeovisHeaderLogoSourceToName.get(element) || '';
}

const GeovisHeaderLogoSourceByName = new Map<string, number>([
	[ 'Project', 0 ],
	[ 'Custom', 1 ],
]);

export function getGeovisHeaderLogoSourceByName(name: string): GeovisHeaderLogoSource {
    return GeovisHeaderLogoSourceByName.get(name)!;
}


export const GeovisHeaderLogoSourceList : Readonly<GeovisHeaderLogoSource[]> = [
	GeovisHeaderLogoSource.Project,
	GeovisHeaderLogoSource.Custom,
];



const GeovisHeaderLogoSourceToDescription = new Map<number, string>([
	[ 0, 'Project' ],
	[ 1, 'Custom' ],
])

export function getGeovisHeaderLogoSourceToDescription(element: GeovisHeaderLogoSource) : string {
    return GeovisHeaderLogoSourceToDescription.get(element) || '';
}

