/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 28.09.2020
 * @description Geovis Message Template author component
 */

import { getUserFullName } from '../../../helpers/UsersHelper';
import { IBusinessUsersStorage } from '../../../store/businessData.types';

interface IMessageTemplateAuthorProps {
    userId: string;
    storage: IBusinessUsersStorage;
}

/**
 * Message template user component
 */
export const MessageTemplateAuthor = ({ storage, userId }: IMessageTemplateAuthorProps) => {

    if (!storage.isLoaded) {
        return (<div>&nbsp;</div>);
    }

    const user = storage.users.get(userId);
    if (!user) {
        return (<div>&nbsp;</div>);
    }

    return (
        <div>{getUserFullName(user)}</div>
    )
}