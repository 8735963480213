//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GvLogEntryType {
	EntryPoint = 0,
	Record = 1
}

const GvLogEntryTypeToName = new Map<number, string>([
	[ 0, 'EntryPoint' ],
	[ 1, 'Record' ],
])

export function getGvLogEntryTypeToName(element: GvLogEntryType) : string {
    return GvLogEntryTypeToName.get(element) || '';
}

const GvLogEntryTypeByName = new Map<string, number>([
	[ 'EntryPoint', 0 ],
	[ 'Record', 1 ],
]);

export function getGvLogEntryTypeByName(name: string): GvLogEntryType {
    return GvLogEntryTypeByName.get(name)!;
}


export const GvLogEntryTypeList : Readonly<GvLogEntryType[]> = [
	GvLogEntryType.EntryPoint,
	GvLogEntryType.Record,
];



const GvLogEntryTypeToDescription = new Map<number, string>([
	[ 0, 'EntryPoint' ],
	[ 1, 'Record' ],
])

export function getGvLogEntryTypeToDescription(element: GvLogEntryType) : string {
    return GvLogEntryTypeToDescription.get(element) || '';
}

