import AddIcon from '@atlaskit/icon/glyph/add'
import Button from "@atlaskit/button";
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { HeadType, RowCellType, RowType } from '@atlaskit/dynamic-table/dist/types/types';
import { Checkbox } from '@atlaskit/checkbox';
import { SyntheticEvent } from 'react';
import { TimePicker } from '@atlaskit/datetime-picker';
import { ToggleStateless } from '@atlaskit/toggle';
import { t } from "../../../../../i18n";
import { GeovisDynamicTable } from '../../../../../components/GeovisDynamicTable';
import { GeovisAlarmActionModel } from '../../../../../server/AVTService/TypeLibrary/Alarming/GeovisAlarmActionModel';
import { DayOfWeek, DayOfWeekList, getDayOfWeekToName } from '../../../../../server/System/DayOfWeek';


interface IComponentProps {
    actionState: GeovisAlarmActionModel;
    isReadonly: boolean;
    updateAction: (propertyName: keyof GeovisAlarmActionModel, value: any) => void;
}

export const AlarmActionEditTimeScheduleSettings = ({
    actionState,
    isReadonly,
    updateAction
}: IComponentProps) => {

    const onAddNewPeriod = () => {
        actionState.ActivityPeriods.push({
            Days: [...DayOfWeekList],
            From: "00:00",
            To: "23:59",
            IsActive: true
        });

        updateAction("ActivityPeriods", actionState.ActivityPeriods);
    }

    const getPeriodsHead = (): HeadType => {
        return ({
            cells: [{
                key: 'use',
                content: t("Use"),
                width: 5
            }, {
                key: 'from',
                content: t("From time"),
                width: 25
            }, {
                key: 'to',
                content: t("To time"),
                width: 25
            }, {
                key: 'mo',
                content: (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <span>{t("Mo")}</span>
                    </div>
                ),
                width: 5
            }, {
                key: 'tu',
                content: (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <span>{t("Tu")}</span>
                    </div>
                ),
                width: 5
            }, {
                key: 'we',
                content: (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <span>{t("We")}</span>
                    </div>
                ),
                width: 5
            }, {
                key: 'th',
                content: (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <span>{t("Th")}</span>
                    </div>
                ),
                width: 5
            }, {
                key: 'fr',
                content: (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <span>{t("Fr")}</span>
                    </div>
                ),
                width: 5
            }, {
                key: 'sa',
                content: (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <span>{t("Sa")}</span>
                    </div>
                ),
                width: 5
            }, {
                key: 'su',
                content: (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <span>{t("Su")}</span>
                    </div>
                ),
                width: 5
            }, {
                key: 'actions',
                width: 10
            },]
        })
    }

    const onChangeActivityPeriodUse = (index: number) => (event: SyntheticEvent<HTMLInputElement>) => {
        actionState.ActivityPeriods[index].IsActive = event.currentTarget.checked;
        updateAction("ActivityPeriods", actionState.ActivityPeriods);
    }

    const getTimes = (): string[] => {
        const result: string[] = [];
        for (let index = 0; index < 24; index++) {
            result.push(`${index < 10 ? "0" : ""}${index.toString()}:00`);
            result.push(`${index < 10 ? "0" : ""}${index.toString()}:30`);
        }
        result.push("23:59")
        return result;
    }

    const getDaysCells = (index: number): RowCellType[] => {

        const orderedDaysOfWeek: DayOfWeek[] = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday];

        const getDayValue = (dow: DayOfWeek): boolean => {
            return actionState.ActivityPeriods[index].Days.includes(dow);
        }

        const onChangeCellValue = (dow: DayOfWeek) => (event: SyntheticEvent<HTMLInputElement>) => {
            if (event.currentTarget.checked) {
                actionState.ActivityPeriods[index].Days.push(dow);
            }
            else {
                actionState.ActivityPeriods[index].Days = actionState.ActivityPeriods[index].Days.filter(d => d !== dow);
            }
            updateAction("ActivityPeriods", actionState.ActivityPeriods)
        }

        return orderedDaysOfWeek.map(day => {
            return ({
                key: getDayOfWeekToName(day),
                content: (
                    <div>
                        <ToggleStateless
                            isChecked={getDayValue(day)}
                            onChange={onChangeCellValue(day)}
                            isDisabled={isReadonly}
                        />
                    </div>
                )
            })
        })
    }

    const onRemoveActivityPeriodClick = (index: number) => () => {
        actionState.ActivityPeriods.splice(index, 1);
        updateAction("ActivityPeriods", actionState.ActivityPeriods);
    }

    const onChangeFromTimeRange = (index: number) => (value: string) => {
        actionState.ActivityPeriods[index].From = value
        updateAction("ActivityPeriods", actionState.ActivityPeriods);
    }

    const onChangeToTimeRange = (index: number) => (value: string) => {
        actionState.ActivityPeriods[index].To = value
        updateAction("ActivityPeriods", actionState.ActivityPeriods);
    }

    const getPeriodsRows = (): RowType[] => {
        return actionState.ActivityPeriods.map((period, index) => {
            return (
                {
                    key: `row-activity-${index}`,
                    cells: [{
                        key: 'use',
                        content: (
                            <Checkbox
                                isChecked={period.IsActive}
                                onChange={onChangeActivityPeriodUse(index)}
                                isDisabled={isReadonly}
                            />
                        )
                    }, {
                        key: 'from',
                        content: (
                            <TimePicker
                                timeIsEditable={true}
                                times={getTimes()}
                                value={period.From}
                                hideIcon={true}
                                timeFormat='HH:mm'
                                onChange={onChangeFromTimeRange(index)}
                                isDisabled={isReadonly}
                            />
                        )
                    }, {
                        key: 'to',
                        content: (
                            <TimePicker
                                timeIsEditable={true}
                                times={getTimes()}
                                value={period.To}
                                hideIcon={true}
                                timeFormat='HH:mm'
                                onChange={onChangeToTimeRange(index)}
                                isDisabled={isReadonly}
                            />
                        )
                    }, ...getDaysCells(index), {
                        key: 'actions',
                        content: (
                            <Button
                                iconBefore={<TrashIcon label={'remove'} />}
                                onClick={onRemoveActivityPeriodClick(index)}
                                isDisabled={isReadonly}
                            />
                        )
                    }]
                }
            )
        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div style={{ flexGrow: 2 }}>
                    <span style={{ fontWeight: 'bold' }}>{t(`Alarm ${actionState.ForConfirm ? "confirmation" : "action"} activity periods`)}</span>
                </div>
                <div>
                    <Button
                        iconBefore={<AddIcon label={'newPeriod'} />}
                        appearance={isReadonly ? 'default' : 'primary'}
                        isDisabled={isReadonly}
                        onClick={onAddNewPeriod}
                    >
                        {t("New Period")}
                    </Button>
                </div>
            </div>
            <div>
                <GeovisDynamicTable
                    rows={getPeriodsRows()}
                    head={getPeriodsHead()}
                />
            </div>
        </div>
    )
}