/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 19.12.2019
 * @description Common types to draw project view tree
 */

import Button, { CustomThemeButton } from '@atlaskit/button';
import Checkbox from '@atlaskit/checkbox';
import BitbucketReposIcon from '@atlaskit/icon/glyph/bitbucket/repos';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import EditorErrorIcon from '@atlaskit/icon/glyph/editor/error';
import LocationIcon from '@atlaskit/icon/glyph/location';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { Item } from '@atlaskit/navigation-next';
import Spinner from '@atlaskit/spinner';
import {
    ItemId,
    RenderItemParams,
    TreeData,
    TreeItem
} from "@atlaskit/tree";
import { SyntheticEvent } from 'react';
import { ReactComponent as InvisibleIcon } from 'src/resources/icons/eye-off.svg';
import { SensorIconHtml } from '../../../components/SensorIconHtml';
import {
    getTreeItemErrorData,
    IProjectViewTreeItemData,
    TreeItemContentType
} from '../../../helpers/ProjectViewsHelper';
import { getReportName } from '../../../helpers/types';
import i18next from '../../../i18n';
import { getSensorCategoryName, SensorCategory } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { Geovis4SensorSymbolSettings } from '../../../server/AVTService/TypeLibrary/Sensors/SymbolSettings/Geovis4SensorSymbolSettings';
import AuthService from '../../../services/AuthService';
import {
    InclinometerChainObjectIcon,
    LocalMapObjectIcon
} from './ProjectViewTreeIcons';

export const getIcon = (
    item: TreeItem,
    onExpand: (itemId: ItemId) => void,
    onCollapse: (itemId: ItemId) => void
) => {

    const onCollapseFunc = (itemId: ItemId) => (event: SyntheticEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onCollapse(itemId);
    }
    const onExpandFunc = (itemId: ItemId) => (event: SyntheticEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onExpand(itemId);
    }

    if (item.children && item.children.length > 0) {

        if (item.isExpanded) {
            return (
                <span className="treeItemIcon" onClick={onCollapseFunc(item.id)}>
                    <ChevronDownIcon label="" size="medium" />
                </span>
            );
        }

        return (
            <span className="treeItemIcon" onClick={onExpandFunc(item.id)}>
                <ChevronRightIcon label="" size="medium" />
            </span>
        );
    }

    return (<span>&nbsp;</span>);
};

const treeItemButtonThemeFunc = (currentTheme: any, themeProps: any) => {
    const { buttonStyles, ...rest } = currentTheme(themeProps);

    return {
        buttonStyles: {
            ...buttonStyles,
            width: '100%',
            padding: '0',
            margin: '0 0 0 -4px'
        },
        ...rest
    };
}

/**
 * Get overview view tree data with error text
 * @param error 
 */
export const getErrorProjectViewTreeData = (error: Error): TreeData => ({
    rootId: 'rootId',
    items: {
        'rootId': {
            id: 'rootId',
            hasChildren: true,
            children: ['errorItem']
        },
        'errorItem': {
            id: 'errorItem',
            isChildrenLoading: false,
            data: getTreeItemErrorData(error),
            children: []
        }
    }
});

interface ITreeItemIconProps extends IProjectViewTreeItemData {
    className: string;
    iconSettings: Map<SensorCategory, Geovis4SensorSymbolSettings>
}

const TreeItemIcon = ({
    contentType,
    localMapObject,
    report,
    sensorInfo,
    chain,
    className,
    iconSettings
}: ITreeItemIconProps) => {

    switch (contentType) {
        case TreeItemContentType.Item: {
            if (sensorInfo) {
                return (<SensorIconHtml className={className} sensorInfo={sensorInfo} iconSettings={iconSettings} />);
            }

            if (localMapObject) {
                return (<LocalMapObjectIcon className={className} localMapObject={localMapObject} />);
            }

            if (report) {
                return (
                    <span className={className} >
                        <BitbucketReposIcon label="" size="small" />
                    </span>
                );
            }

            if (chain) {
                return (
                    <span className={className}>
                        <InclinometerChainObjectIcon className={className} chain={chain} />
                    </span>
                )
            }

            break;
        }

        case TreeItemContentType.Sensors:
            if (sensorInfo) {
                return (<SensorIconHtml className={className} sensorInfo={sensorInfo} iconSettings={iconSettings} />);
            }
            break;

        case TreeItemContentType.Reports:
            return (
                <span className={className} >
                    <BitbucketReposIcon label="" size="small" />
                </span>
            );

        case TreeItemContentType.Chains:
            if (chain) {
                return (<InclinometerChainObjectIcon className={className} chain={chain} />);
            }
            break;

        case TreeItemContentType.LMOs:
            return (
                <span className={className}>
                    <LocationIcon label="" size="small" />
                </span>);

    }

    return (<span>&bull;</span>);
};

const TreeItemText = ({
    contentType,
    localMapObject,
    report,
    sensorInfo,
    chain,
    numberOfChildren
}: IProjectViewTreeItemData) => {

    switch (contentType) {
        case TreeItemContentType.Item: {
            if (sensorInfo) {
                return (<span>{sensorInfo.Name}</span>);
            }

            if (localMapObject) {
                return (<span>{localMapObject.Name}</span>);
            }

            if (report) {
                return (<span>{report.Title || report.Name}</span>);
            }

            if (chain) {
                return (<span>{chain.Name}</span>)
            }

            break;
        }

        case TreeItemContentType.Sensors:
            if (sensorInfo) {
                return (<span>{i18next.t(getSensorCategoryName(sensorInfo.PhysicalType))}&nbsp;({numberOfChildren})</span>);
            }
            break;

        case TreeItemContentType.Reports:
            return (<span>{i18next.t("Reports")}&nbsp;({numberOfChildren})</span>);

        case TreeItemContentType.Chains:
            return (<span>{i18next.t("Inclinometer chains")}&nbsp;({numberOfChildren})</span>);

        case TreeItemContentType.LMOs:
            return (<span>{i18next.t("Local map objects")}&nbsp;({numberOfChildren})</span>);
    }

    return (<span>&bull;</span>);
}

interface IRenderProjectViewTreeItemFuncParams {
    onClick: (item: TreeItem) => void;
    onChangedSelection: (itemsIds: string[], selected: boolean) => void;
    iconSettings: Map<SensorCategory, Geovis4SensorSymbolSettings>
}

const drawReportItem = (itemData: IProjectViewTreeItemData, onClick: () => void) => {

    const { report } = itemData;
    if (!report) {
        return (<div>undefined</div>);
    }
    if (!report.IsPublic) {
        return (
            <Button
                appearance="subtle-link"
                spacing="compact"
                onClick={onClick}>
                <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <span style={{ lineHeight: "24px", float: "left" }}>{getReportName(report)}</span>
                    <InvisibleIcon style={{ float: "left", marginLeft: 5, height: '20px', width: '20px', marginTop: '2px' }} />
                </div>
            </Button>
        );
    }
    return (
        <Button
            appearance="subtle-link"
            spacing="compact"
            onClick={onClick}>
            {getReportName(report)}
        </Button>
    );
}

/**
 * Render project tree item
 * @param render params
 */
export const renderProjectViewTreeItemFunc = ({ onClick, iconSettings, onChangedSelection }: IRenderProjectViewTreeItemFuncParams) => ({ item, onExpand, onCollapse, provided }: RenderItemParams) => {

    if (item.isChildrenLoading) {
        return (
            <div
                ref={provided.innerRef} {...provided.draggableProps}
                className="projectViewTreeItemIsLoading">
                <Spinner size={16} />
            </div>)
    }

    const itemData = item.data as IProjectViewTreeItemData;

    if (itemData.error) {
        return (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}>
                <Item
                    before={() => <EditorErrorIcon label="" primaryColor="red" />}
                    text={itemData.error.message}
                />
            </div>)
    }

    /**
     * Handle click event and call expected method
     */
    const onClickHandler = () => {
        onClick(item);
    }

    const isReport = (): boolean => {
        return itemData.contentType === TreeItemContentType.Item && itemData.report !== undefined;
    }

    const isSensor = (): boolean => {
        return itemData.contentType === TreeItemContentType.Item && !item.hasChildren && itemData.sensorInfo !== undefined;
    }

    const isSensorGroup = (): boolean => {
        return itemData.contentType === TreeItemContentType.Sensors && (item.hasChildren ?? false);
    }

    const isGroupIndeterminant = (): boolean => {
        if (!itemData.sensorInfo) {
            return false;
        }
        return itemData.sensorInfo.SelectedSensorsCount !== item.children.length && itemData.sensorInfo.SelectedSensorsCount > 0;
    }

    const isSensorChecked = (): boolean => {
        if (!itemData.sensorInfo) {
            return false;
        }
        return itemData.sensorInfo.Selected
    }

    const onChangeSelection = (itemOfTree: TreeItem) => (event: SyntheticEvent<HTMLInputElement>) => {
        if (itemOfTree.hasChildren) {
            onChangedSelection(itemOfTree.children.map(ch => ch.toString().replace(`${itemOfTree.id.toString()}_`, "")), event.currentTarget.checked);
        }
        else {
            const itemOfTreeData = itemOfTree.data as IProjectViewTreeItemData
            if (itemOfTreeData.sensorInfo) {
                onChangedSelection([itemOfTreeData.sensorInfo.Id], event.currentTarget.checked);
            }
        }
    }

    return (
        <div
            ref={provided.innerRef}
            className="treeItem"
            {...provided.draggableProps}
            {...provided.dragHandleProps}>

            {/* icon */}
            {getIcon(item, onExpand, onCollapse)}

            {/* report link */}
            {isReport() && drawReportItem(itemData, onClickHandler)}

            {/* Sensor group checkbox */}
            {isSensorGroup() && AuthService.isNagraDistribution() &&
                <Checkbox
                    isIndeterminate={isGroupIndeterminant()}
                    isChecked={isSensorChecked()}
                    onChange={onChangeSelection(item)}
                />
            }

            {/* Sensor's checkbox */}
            {isSensor() && AuthService.isNagraDistribution() &&
                <Checkbox
                    isChecked={isSensorChecked()}
                    style={{ marginLeft: '8px' }}
                    onChange={onChangeSelection(item)}
                />
            }

            {/* text button */}
            {!isReport() && (
                <CustomThemeButton
                    appearance="subtle"
                    spacing="compact"
                    theme={treeItemButtonThemeFunc}
                    onClick={onClickHandler}>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-start' }}>
                        <TreeItemIcon {...itemData} className="treeItemElementIcon" iconSettings={iconSettings} />
                        <TreeItemText {...itemData} />
                    </div>
                </CustomThemeButton>
            )}
        </div>
    );
}