import { CSSProperties } from "react";
import { nameofFactory } from "../../../helpers/NameofFactory";
import { GeovisChartAxisSettings } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { GeovisChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { XyChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import { SensorCategory } from "../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { GeovisMstModel } from "../../../server/GEOvis3/Model/Database/GeovisMstModel";
import { DtsChartSensorsInfo } from "../../../server/GEOvis3/Model/GeovisChart/DtsChartSensorsInfo";
import { GeovisChartInfo } from "../../../server/GEOvis3/Model/GeovisChart/GeovisChartInfo";
import { SensorChangesModel } from "../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel";
import { defaultSomethingStorageState, ISomethingStoreBase } from "../../../store/types";
import { SensorValueAttribute } from "../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";

// /** @deprecated */
// export type GeovisChartPropertyType = keyof DtsChartModel | keyof XyChartModel | keyof TimeValueChartModel;
export type TextValueType = number | string;

export const AxisSettingsPropertyName = nameofFactory<GeovisChartAxisSettings>();
export const defaultChartLabelColumnStyle: CSSProperties = { width: '30%' };
export const defaultChartRowSettingsStyle: CSSProperties = { alignItems: 'center' };

export const defaultChartTabContentHeaderStyle: CSSProperties = { textAlign: 'center', fontWeight: 'bold' };

export interface IChartInfoStorage extends ISomethingStoreBase {
    chartInfo: GeovisChartInfo;
    sensorsChanges: SensorChangesModel[];
    aliases: GeovisMstModel[];
    templatesCount: number;
}

export interface IDtsChartSensorsInfoStorage extends ISomethingStoreBase {
    dtsChartSensorInfos: DtsChartSensorsInfo;
}

/** @deprecated use direct styles or default */
export interface IGeovisChartStyleProps {
    labelColumnStyle: CSSProperties;
    settingRowStyle: CSSProperties;
}

export interface IGeovisChartPropertyChangedProps<TChart extends GeovisChartModel> {
    chart: TChart;

    onChartPropertyChanged: <TValue extends any> (propertyName: keyof TChart, value: TValue, sensorsChanges?: SensorChangesModel[]) => void;
    onChartPropertiesChanged: (properties: Partial<TChart>) => void;
}

export interface IGeovisChartAxisPropertyChangedProps<TAxis extends GeovisChartAxisSettings> {
    onAxisPropertyChanged: (propertyName: keyof TAxis, value: any) => void;
    onAxisPropertiesChanged: (properties: Partial<TAxis>) => void;
}

/** @deprecated Move to simple small property onChartPropertyChanged */
export interface IGeovisChartAxisPropertyProps<TChart extends GeovisChartModel> {
    onChangeAxisSettings: (propertyName: keyof TChart, axisSettings: GeovisChartAxisSettings) => void;
}
export interface IGeovisChartSensorsPropertyProps {
    sensorsChanges: SensorChangesModel[];
    // onChangeSensorsChanges: (changes: SensorChangesModel[]) => void;
}

export const dtsChartSensorsInfoStorageInitialState: IDtsChartSensorsInfoStorage = {
    ...defaultSomethingStorageState,
    dtsChartSensorInfos: {
        AttenuationSensors: [],
        StokeSensors: [],
        TemperatureSensors: [],
        NormalizedTemperatureSensors: [],
        ThermalConductivitySensors: []
    }
}

export const chartInfoStorageInitialState: IChartInfoStorage = {
    ...defaultSomethingStorageState,
    sensorsChanges: [],
    templatesCount: 0,
    aliases: [],
    chartInfo: {
        AlarmPlansInfo: [],
        DtsSectionInfos: [],
        Chart: {
            ... new XyChartModel(),
            AlarmPlans: [],
            AlarmLines: [],
            BackgroundAreas: []
        }
    }
}


/**
 * Dts section sensor types for charts
 */
export const dtsSectionAxisTypes: ReadonlyArray<SensorCategory> = [
    SensorCategory.DTS_Stoke_Antistoke,
    SensorCategory.DTS_Temperature,
    SensorCategory.DTS_Attenuation,
    SensorCategory.DTS_ThermalConductivity
];

export const availableValueTypesForRelativeValues: SensorValueAttribute[] = [
    SensorValueAttribute.Axis,
    SensorValueAttribute.AxisValue1,
    SensorValueAttribute.AxisValue2,
    SensorValueAttribute.AxisValue3,
    SensorValueAttribute.ValuesXYZ,
    SensorValueAttribute.Value1,
    SensorValueAttribute.Value2,
    SensorValueAttribute.Value3,
];