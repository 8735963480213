/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.07.2020
 * @description Users management methods
 */

import { UserEditorMode } from "../components/users/types";
import { GeovisUserRoleEnum } from "../server/AVTService/TypeLibrary/Identity/GeovisUserRoleEnum";
import { GeovisUserCommonInfo } from "../server/GEOvis3/Model/User/GeovisUserCommonInfo";
import { GeovisUserProfileInfo } from "../server/GEOvis3/Model/User/GeovisUserProfileInfo";
import AuthService from "../services/AuthService";


const emailRegex = "^([a-zA-Zß0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Zß0-9\\-]+\\.)+))([a-zA-Zß]{2,10}|[0-9]{1,3})(\\]?)$";

export const isEmailValid = (email: string): boolean => {
    return email !== undefined && email.match(emailRegex) !== null;
}

export const userProfileReadonly = (mode: UserEditorMode) => (!(mode === UserEditorMode.Create || mode === UserEditorMode.Edit) && !AuthService.hasUserTypeAsAdmin());
export const userProfileNumberCanNotBeChanged = (mode: UserEditorMode, editedUser: GeovisUserProfileInfo, currentUserRole: GeovisUserRoleEnum) =>
    (!AuthService.hasUserTypeAsCompanyAdmin() || (editedUser.GeovisUserRole > currentUserRole && !AuthService.hasUserTypeAsAdmin()));

export const getEditUserDialogMode = (): UserEditorMode => {
    if (AuthService.hasUserTypeAsAdmin()) {
        return UserEditorMode.Edit;
    }

    if (AuthService.hasUserTypeAsCompanyAdmin()) {
        return UserEditorMode.EditProjectRelation;
    }

    return UserEditorMode.View;
}

export const isOwnUserRecord = (userId: string): boolean => {
    const currentUserId = AuthService.currentUserId()
    return userId === currentUserId;
};

export const readonlyUserRecord = (userId: string): boolean => {
    return !isOwnUserRecord(userId) && !AuthService.hasUserTypeAsAdmin();
}

/**
 * Create user full name line
 * @param user 
 * @returns 
 */
export const getUserFullName = (user: GeovisUserCommonInfo): string => {
    return `${user.Name} ${user.Forename}`;
}