//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartModel } from './GeovisCharts/GeovisChartModel';
import { GeovisMapSectionReportInfo } from './MapSection/GeovisMapSectionReportInfo';
import { Geovis4Table } from './Geovis4Tables/Geovis4Table';
import { GeovisImageModel } from './GeovisProjectElements/GeovisImageModel';
import { GeovisLogbookModel } from './GeovisProjectElements/GeovisLogbookModel';

export class GeovisReportPageSettings
{
	public PageNum: number;
	public GeovisCharts: GeovisChartModel[];
	public MapSectionsInfo: GeovisMapSectionReportInfo[];
	public Tables: Geovis4Table[];
	public GeovisImages: GeovisImageModel[];
	public GeovisLogbooks: GeovisLogbookModel[];
}
