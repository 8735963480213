//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ListingElementInfo } from './ListingElementInfo';

export class FolderListingModel
{
	public PathToRoot: string[];
	public Content: ListingElementInfo[];
	public RequestId: string;
}
