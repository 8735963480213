/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 09.04.2020
 * @description Logos manager actions
 */

/**
 * Loading logo files information
 */
export const LM_LOGO_FILES = "LM_LOGO_FILES";

/**
 * Logo files information data
 */
export const LM_LOGO_FILES_DATA = "LM_LOGO_FILES_DATA";
export const LM_LOGO_FILES_ERROR = "LM_LOGO_FILES_ERROR";
export const LM_VIEW_MODE = "LM_VIEW_MODE";
export const LM_LOGO_FILE_DELETE = "LM_LOGO_FILE_DELETE";
export const LM_LOGO_FILE_DELETE_IMPOSSIBLE = "LM_LOGO_FILE_DELETE_IMPOSSIBLE";
export const LM_LOGO_FILE_DELETE_IMPOSSIBLE_DISMISS = "LM_LOGO_FILE_DELETE_IMPOSSIBLE_DISMISS";

// upload logo files
export const LM_UPLOAD_LOGO_STARTED = "LM_UPLOAD_LOGO_STARTED";
export const LM_UPLOAD_LOGO_PROGRESS = "LM_UPLOAD_LOGO_PROGRESS";
export const LM_UPLOAD_LOGO_FINISHED = "LM_UPLOAD_LOGO_FINISHED";
export const LM_UPLOAD_LOGO_ERROR = "LM_UPLOAD_LOGO_ERROR";

// companies with logo files
export const LM_COMPANIES_WITH_LOGOS = "LM_COMPANIES_WITH_LOGOS";
export const LM_COMPANIES_WITH_LOGOS_ERROR = "LM_COMPANIES_WITH_LOGOS_ERROR";
export const LM_COMPANIES_WITH_LOGOS_DATA = "LM_COMPANIES_WITH_LOGOS_DATA";