//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisFeedbackModel } from './GeovisFeedbackModel';

export class GeovisApplicationFeedbackModel extends GeovisFeedbackModel
{
	public ErrorMessage: string;
	public ErrorStack: string;
	public ComponentStack: string;
}
