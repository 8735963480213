import Button, { ButtonProps } from '@atlaskit/button';
import { MediaClient } from '@atlaskit/media-client';
import { Auth, AuthContext } from '@atlaskit/media-core';
import { Browser } from '@atlaskit/media-picker';
import {
    BrowserConfig,
    UploadEndEventPayload,
    UploadErrorEventPayload,
    UploadPreviewUpdateEventPayload,
    UploadsStartEventPayload
} from '@atlaskit/media-picker/types';
import React from 'react';
import { Subscription } from 'rxjs/Subscription';
import AuthService from '../services/AuthService';
import Logger from '../services/Logger';
import "./FileBrowser.fetch";

const LoggerSource = 'FileBrowser';

export interface IUploadFileInfo {
    fileId: string;
    fileName: string;
}

export interface IProgressUploadFileInfo extends IUploadFileInfo {
    progress: number;
}

export interface IErrorUploadFileInfo extends IUploadFileInfo {
    errorDescription: string;
}

export interface IProcessingUploadFileInfo extends IUploadFileInfo {
    artifacts?: any;
}
export interface IUploadProcessedFileInfo extends IUploadFileInfo {
    size: number
}

export interface IFileBrowserProps {
    collection: string;
    baseUrl: string;
    fileExtensions: string[];
    multiple?: boolean;
    buttonProps?: ButtonProps;
    isButton?: boolean;
    buttonText: string;

    onButtonClick?: () => void;
    onUploadsStart?: (payload: UploadsStartEventPayload) => void;
    onUploadEnd?: (payload: UploadEndEventPayload) => void;
    onUploadStatusUpdate?: (statusUpdateEvent: IProgressUploadFileInfo) => void;
    onUploadProcessing?: (uploadProcessing: IProcessingUploadFileInfo) => void;
    onError?: (errorEvent: IErrorUploadFileInfo) => void;
    onUploadProcessed?: (status: IUploadProcessedFileInfo) => void;
}

interface IFileBrowserState {
    mediaClient?: MediaClient;
    browserConfig?: BrowserConfig;
}

export default class FileBrowser extends React.Component<IFileBrowserProps, IFileBrowserState>{
    private browseFn: () => void;
    private cancelFn: (uniqueIdentifier: string) => void;
    private subscription?: Subscription;

    constructor(props: IFileBrowserProps) {
        super(props);

        this.browseFn = () => { /** */ }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.cancelFn = (uniqueIdentifier) => { /** */ }
        this.state = ({});
    }

    public componentDidMount = () => {
        this.createClient();
    }

    public UNSAFE_componentWillMount = () => {
        this.unsubscribe();
    }

    public componentDidUpdate = (prevProps: IFileBrowserProps) => {
        if (this.state.browserConfig && prevProps.collection !== this.props.collection) {
            this.setState({
                browserConfig: {
                    ...this.state.browserConfig,
                    uploadParams: {
                        collection: this.props.collection
                    }
                }
            })
        }
    }

    public render() {
        const { mediaClient, browserConfig } = this.state;
        if (!mediaClient || !browserConfig) {
            return null;
        }

        const { buttonProps, buttonText, isButton } = this.props;
        const { collection } = browserConfig.uploadParams;
        const showButton = isButton === true || isButton === undefined;

        return (
            <div>
                {showButton && (
                    <Button
                        {...buttonProps}
                        onClick={this.onClick}>
                        {buttonText}
                    </Button>
                )}
                {!showButton && (
                    <span onClick={this.onClick}>
                        {buttonText}
                    </span>
                )}
                {collection && collection.length && (
                    <Browser
                        onBrowseFn={this.onBrowseFn}
                        onCancelFn={this.onCancelFn}
                        mediaClientConfig={mediaClient.config}
                        config={browserConfig}
                        onUploadsStart={this.onUploadsStart}
                        onEnd={this.onUploadEnd}
                        onError={this.onError}
                        onPreviewUpdate={this.onPreviewUpdate}
                    />
                )}
            </div>
        )
    }

    public triggerBrowseFn = () => {
        this.browseFn();
    }

    public triggerCancelFn = (uniqueIdentifier: string) => {
        this.cancelFn(uniqueIdentifier);
    }

    private createClient = () => {
        const { collection, baseUrl, fileExtensions, multiple } = this.props;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const authMediaPickerProvider = ({ collectionName }: AuthContext) => new Promise<Auth>((resolve) => {
            resolve({
                token: AuthService.getTokenSafety(),
                clientId: 'clientId',
                baseUrl
            })
        });

        const browserConfig: BrowserConfig = {
            fileExtensions: [...fileExtensions],
            multiple,
            uploadParams: {
                collection
            }
        }

        this.setState({
            mediaClient: new MediaClient({ authProvider: authMediaPickerProvider }),
            browserConfig,
        });
    }

    private onBrowseFn = (browse: () => void) => {
        this.browseFn = browse;
    }

    private onCancelFn = (cancel: (uniqueIdentifier: string) => void) => {
        this.cancelFn = cancel;
    }

    private onClick = () => {
        const { onButtonClick } = this.props;
        if (onButtonClick) {
            onButtonClick();
        }

        if (this.browseFn) {
            this.browseFn();
        }
    }

    private onUploadsStart = (payload: UploadsStartEventPayload) => {
        const { mediaClient } = this.state;
        const { onUploadStatusUpdate, onUploadProcessing, onUploadProcessed } = this.props;

        if (mediaClient) {
            this.unsubscribe();

            payload.files.map((file) => {
                this.subscription = mediaClient.file.getFileState(file.id)
                    .subscribe({
                        next(state) {
                            if (state.status === 'uploading') {
                                if (onUploadStatusUpdate) {
                                    onUploadStatusUpdate({ fileId: state.id, fileName: state.name, progress: state.progress });
                                }
                            }
                            else if (state.status === 'processing') {
                                if (onUploadProcessing) {
                                    onUploadProcessing({ fileId: state.id, fileName: state.name, artifacts: state.artifacts });
                                }
                            }
                            else if (state.status === 'failed-processing') {
                                Logger.trace(`FileBrowser: subscription failed-processing ${state}`, LoggerSource);
                            }
                            else if (state.status === 'processed') {
                                if (onUploadProcessed) {
                                    onUploadProcessed({ fileId: state.id, fileName: state.name, size: state.size })
                                }
                            }
                        },

                        error(err) {
                            if (err === 'canceled') {
                                Logger.trace(`FileBrowser: subscription canceled error`, LoggerSource);
                            }
                            else {
                                Logger.warning(`FileBrowser: subscription error ${err}`, LoggerSource);
                            }
                        }
                    });
            });

            if (this.props.onUploadsStart) {
                this.props.onUploadsStart(payload);
            }
        }
    }

    private onUploadEnd = (payload: UploadEndEventPayload) => {
        if (this.props.onUploadEnd) {
            this.props.onUploadEnd(payload);
        }
    }

    private onError = (payload: UploadErrorEventPayload) => {
        this.onAnyError(payload.fileId, payload.error.description);
    }

    private onAnyError = (fileId: string, error: any) => {
        if (this.props.onError) {
            const errorDescription = typeof error === "string" ? error
                : (error.error && error.error instanceof Error ? error.error.message : "unexpected error");
            this.props.onError({ fileId, fileName: "", errorDescription });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private onPreviewUpdate = (data: UploadPreviewUpdateEventPayload) => {
        /** */
        // eslint-disable-next-line no-console
        // console.log("asdasd");
    }

    private unsubscribe = () => {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
}