//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GEOvisDXFLayerType } from '../Common/GEOvisDXFLayerType';

export abstract class ProjectViewBase
{
	public Id: string;
	public ProjectId: number;
	public Name: string;
	public IsPublic: boolean;
	public Order: number;
	public ParentViewId: string;
	public IsDefaultExpanded: boolean;
	public LeftsideDisplayType: GEOvisDXFLayerType;
	public RightsideDisplayType: GEOvisDXFLayerType;
}
