//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { InventoryMstInfo } from './InventoryMstInfo';
import { InventorySensorboxInfo } from './InventorySensorboxInfo';
import { InventoryTachymeterInfo } from './InventoryTachymeterInfo';
import { InventoryAtcSensorInfo } from './InventoryAtcSensorInfo';
import { InventoryAfmBoxInfo } from './InventoryAfmBoxInfo';
import { InventoryLoRaGatewayInfo } from './InventoryLoRaGatewayInfo';

export class InventoryCalendarViewObjectsData
{
	public Msts: InventoryMstInfo[];
	public SBs: InventorySensorboxInfo[];
	public Tachymeters: InventoryTachymeterInfo[];
	public Atcs: InventoryAtcSensorInfo[];
	public AFMs: InventoryAfmBoxInfo[];
	public LoRaGateways: InventoryLoRaGatewayInfo[];
}
