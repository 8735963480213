import { connect } from 'react-redux';
import { t } from '../../../../i18n';
import { IGeovisStoreState } from "../../../../store/store.types";

const FooterText = "©GEOvis by Amberg Infra 7D AG";

interface IComponentStateProps {
    pagesToReportPage: Map<number, number>;
}

interface IComponentProps extends IComponentStateProps {
    pageNumber: number;
    countPages: number;

    useCustomPageNumber?: boolean;
    customPageNumber?: number;
}

const FooterRender = ({ countPages, pageNumber, pagesToReportPage, useCustomPageNumber, customPageNumber }: IComponentProps) => {

    const getRealPageNumber = (): number => {

        if (useCustomPageNumber && customPageNumber !== undefined) {
            return customPageNumber;
        }

        let pagesBefore = 0;
        for (let index = 0; index < pageNumber; index++) {
            const pagesForPage = pagesToReportPage.get(index);
            if (pagesForPage) {
                pagesBefore += pagesForPage;
            }
            else {
                pagesBefore++;
            }
        }
        return pagesBefore;
    }

    const getRealPagesCount = (): number => {
        let pagesRealCount = 0;
        const pagesInReport = countPages;

        for (let index = 0; index < pagesInReport; index++) {
            const pagesForPage = pagesToReportPage.get(index);
            if (pagesForPage) {
                pagesRealCount += pagesForPage;
            }
            else {
                pagesRealCount++;
            }
        }

        return pagesRealCount;
    }

    return (
        <div className="reportFooterContentContainer">
            <div className="flexRowContainer">
                <div className="reportFooterTextCell">
                    <label>{FooterText}</label>
                </div>
                <div className="reportFooterPageNumCell">
                    {`${t("Page")} ${getRealPageNumber()}/${getRealPagesCount()}`}
                </div>
            </div>
        </div>

    );
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState): IComponentStateProps => ({
    pagesToReportPage: projectReport.pagesToReportPage
});

export default connect<IComponentStateProps, never>(
    mapStateToProps
)(FooterRender)