import { TileLayer, TileLayerProps } from 'react-leaflet';
import { MAP_TILES_API_ROOT } from '../../../ApiConfig';
import { LeafletMapTilesRoutes } from '../../../helpers/MapTilesServiceRoutes';
import AuthService from '../../../services/AuthService';
import { IGeovisProjectLayersStorage } from '../../../store/data.types';
import { LoadingContainerSkeleton } from '../../LoadingContainerSkeleton';
import { MaxProfileViewNativeZoomLevel } from '../../map/types';


// DXF Viewer of layer info
interface IDXFViewerOfLayerInfoProps {
    projectId: number;
    layerId: string;

    onLoad?: (event: L.LeafletEvent) => void;
}


export const DXFViewerOfLayerInfo = ({ projectId, layerId, onLoad }: IDXFViewerOfLayerInfoProps) => {

    const url = LeafletMapTilesRoutes.DxfProfileViewMapTilesRoute.patch(projectId, layerId).path;
    const tilesUrl = AuthService.getFullUrl(MAP_TILES_API_ROOT, url);
    const tileLayerProps: TileLayerProps = {
        url: tilesUrl,
        noWrap: true,
        zIndex: 100,
        maxNativeZoom: MaxProfileViewNativeZoomLevel,
        eventHandlers: !onLoad  ? undefined : {
                load: onLoad
            }
        // bounds: boundsDXF
    };

    return (
        <TileLayer {...tileLayerProps} />
    );

}


// DXF Viewer of storage
interface IDXFViewDXFLayerProps {
    layerStorage: IGeovisProjectLayersStorage;
    onLoad?: (event: L.LeafletEvent) => void;
}

const DXFViewerOfStorage = ({ layerStorage, onLoad }: IDXFViewDXFLayerProps) => {

    if (!layerStorage.isLoaded) {
        return (<LoadingContainerSkeleton />)
    }

    const dxfToShow = layerStorage.layers.get(layerStorage.currentLayerId);

    if (!dxfToShow) {
        return (
            <div>
                {/*  */}
            </div>
        )
    }

    return (
        <DXFViewerOfLayerInfo
            projectId={dxfToShow.ProjectId}
            layerId={dxfToShow.Id}
            onLoad={onLoad}
        />
    )
}

export default DXFViewerOfStorage;