/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 08.12.2019
 * @description Data actions for IGeovisDataState
 */

export const PROJECT_DATA_SET_MAP_VIEW_STATE = 'PROJECT_DATA_SET_MAP_VIEW_STATE';
export const PROJECT_DATA_SHOW_LEGEND = "PROJECT_DATA_SHOW_LEGEND";
export const PROJECT_DATA_RESET_MAP_VIEWPORT_OF_VIEW = "PROJECT_DATA_RESET_MAP_VIEWPORT_OF_VIEW";

// data configuration
export const PROJECT_DATA_OVERVIEW_CONFIGURATION = "PROJECT_DATA_OVERVIEW_CONFIGURATION";
export const PROJECT_DATA_OVERVIEW_CONFIGURATION_DATA = "PROJECT_DATA_OVERVIEW_CONFIGURATION_DATA";

// project info
export const PROJECT_DATA_PROPERTIES = "PROJECT_DATA_PROPERTIES";
export const PROJECT_DATA_PROPERTIES_DATA = "PROJECT_DATA_PROPERTIES_DATA";
export const PROJECT_DATA_PROPERTIES_LOADED = "PROJECT_DATA_PROPERTIES_LOADED";
export const PROJECT_DATA_PROPERTIES_ERROR = "PROJECT_DATA_PROPERTIES_ERROR";
export const PROJECT_DATA_UPDATE_PROJECT_VIEWPORT = "PROJECT_DATA_UPDATE_PROJECT_VIEWPORT";
export const PROJECT_DATA_OVERVIEW_CHANGED = "PROJECT_DATA_OVERVIEW_CHANGED";


// project companies info
export const PROJECT_COMPANIES_INFO = "PROJECT_COMPANIES_INFO";
export const PROJECT_COMPANIES_INFO_LOADED = "PROJECT_COMPANIES_INFO_LOADED";

// notifications settings
export const PROJECT_SETTINGS_NOTIFICATIONS_CHANGED = "PROJECT_SETTINGS_NOTIFICATIONS_CHANGED";
// export const PROJECT_SETTINGS_DOLPHIN_ID_CHANGED = "PROJECT_SETTINGS_DOLPHIN_ID_CHANGED";

// attached documents
export const PROJECT_DATA_ATTACHED_DOCUMENTS = "PROJECT_DATA_ATTACHED_DOCUMENTS";
export const PROJECT_DATA_ATTACHED_DOCUMENTS_DATA = "PROJECT_DATA_ATTACHED_DOCUMENTS_DATA";
export const PROJECT_DATA_ATTACHED_DOCUMENTS_ERROR = "PROJECT_DATA_ATTACHED_DOCUMENTS_ERROR";
export const PROJECT_DATA_ATTACHED_DOCUMENTS_LOADED = "PROJECT_DATA_ATTACHED_DOCUMENTS_LOADED";

export const PROJECT_DATA_ATTACHED_DOCUMENT_ADD = "PROJECT_DATA_ATTACHED_DOCUMENT_ADD";
export const PROJECT_DATA_ATTACHED_DOCUMENT_UPDATE = "PROJECT_DATA_ATTACHED_DOCUMENT_UPDATE";
export const PROJECT_DATA_ATTACHED_DOCUMENT_DELETED = "PROJECT_DATA_ATTACHED_DOCUMENT_DELETED";


// DXF layers
export const PROJECT_DATA_DXF_LAYERS = "PROJECT_DATA_DXF_LAYERS";
export const PROJECT_DATA_DXF_LAYERS_DATA = "PROJECT_DATA_DXF_LAYERS_DATA";
export const PROJECT_DATA_DXF_LAYERS_ERROR = "PROJECT_DATA_DXF_LAYERS_ERROR";

export const PROJECT_DATA_DXF_LAYER_DELETE = "PROJECT_DATA_DXF_LAYER_DELETE";

// Local map objects
export const PROJECT_DATA_LOCAL_MAP_OBJECTS = "PROJECT_DATA_LOCAL_MAP_OBJECTS";
export const PROJECT_DATA_LOCAL_MAP_OBJECTS_DATA = "PROJECT_DATA_LOCAL_MAP_OBJECTS_DATA";

// sensors and chains 
export const PROJECT_DATA_SENSORS_INFO = "PROJECT_DATA_SENSORS_INFO";
export const PROJECT_DATA_SENSORS_INFO_DATA = "PROJECT_DATA_SENSORS_INFO_DATA";
export const PROJECT_DATA_SENSORS_INFO_ERROR = "PROJECT_DATA_SENSORS_INFO_ERROR";

export const PROJECT_DATA_SENSOR_INFO_UPDATED = "PROJECT_DATA_SENSOR_INFO_UPDATED";
export const PROJECT_DATA_SENSOR_INFO_DELETED = "PROJECT_DATA_SENSOR_INFO_DELETED";

export const PROJECT_DATA_CHAINS_INFO = "PROJECT_DATA_CHAINS_INFO";
export const PROJECT_DATA_CHAINS_INFO_DATA = "PROJECT_DATA_CHAINS_INFO_DATA";
export const PROJECT_DATA_CHAINS_INFO_ERROR = "PROJECT_DATA_CHAINS_INFO_ERROR";

export const PROJECT_DATA_SENSORS_CHAINS_LOADING = "PROJECT_DATA_SENSORS_CHAINS_LOADING"
export const PROJECT_DATA_SENSORS_CHAINS_LOADED = "PROJECT_DATA_SENSORS_CHAINS_INFO";
export const PROJECT_DATA_SENSORS_CHAINS_MEAS_LOADED = "PROJECT_DATA_SENSORS_CHAINS_MEAS_LOADED";
export const PROJECT_DATA_APPEND_SENSORS_MEAS = 'PROJECT_DATA_APPEND_SENSORS_MEAS';

export const PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADING = "PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADING"
export const PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADED = "PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADED";
export const PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADING_ERROR = "PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADING_ERROR";

// project visual settings
export const PROJECT_DATA_VISUAL_SETTINGS = "PROJECT_DATA_VISUAL_SETTINGS";
export const PROJECT_DATA_VISUAL_SETTINGS_DATA = "PROJECT_DATA_VISUAL_SETTINGS_DATA";
export const PROJECT_DATA_VISUAL_SETTINGS_ERROR = "PROJECT_DATA_VISUAL_SETTINGS_ERROR";

// sensor symbols
export const PROJECT_DATA_SYMBOL_SETTINGS = "PROJECT_DATA_SYMBOL_SETTINGS";
export const PROJECT_DATA_SYMBOL_SETTINGS_DATA = "PROJECT_DATA_SYMBOL_SETTINGS_DATA";
export const PROJECT_DATA_SYMBOL_SETTINGS_ERROR = "PROJECT_DATA_SYMBOL_SETTINGS_ERROR";

export const PROJECT_DATA_SYMBOLS_SETTINGS_RESPONSE_LOADED = 'PROJECT_DATA_SYMBOLS_SETTINGS_RESPONSE_LOADED';

// views
// keep this action, because it is used in ProjectOverviewPage
export const PROJECT_DATA_VIEW_UPDATE_VIEWPORT = "PROJECT_DATA_VIEW_UPDATE_VIEWPORT";

// recentAlarmsStorage
export const PROJECT_DATA_ALARMS = "PROJECT_DATA_ALARMS";
export const PROJECT_DATA_ALARMS_DATA = "PROJECT_DATA_ALARMS_DATA";
export const PROJECT_DATA_ALARM_CONFIRMED = "PROJECT_DATA_ALARM_CONFIRMED";
export const PROJECT_DATA_ALARM_DO_SWITCHED_OFF = "PROJECT_DATA_ALARM_DO_SWITCHED_OFF";

// reportsStorage 
export const PROJECT_DATA_REPORTS = "PROJECT_DATA_REPORTS";
export const PROJECT_DATA_REPORTS_DATA = "PROJECT_DATA_REPORTS_DATA";
export const PROJECT_DATA_ADD_REPORT_INFO = 'PROJECT_DATA_ADD_REPORT_INFO';
export const PROJECT_DATA_REPORT_PUBLIC_CHANGED = 'PROJECT_DATA_REPORT_PUBLIC_CHANGED';

// logbookElementsStorage
export const PROJECT_DATA_LOGBOOK_ELEMENTS = "PROJECT_DATA_LOGBOOK_ELEMENTS";
export const PROJECT_DATA_LOGBOOK_ELEMENTS_ERROR = 'PROJECT_DATA_LOGBOOK_ELEMENTS_ERROR';
export const PROJECT_DATA_LOGBOOK_ELEMENTS_DATA = "PROJECT_DATA_LOGBOOK_ELEMENTS_DATA";

export const PROJECT_DATA_LOGBOOK_RECORDS = "PROJECT_DATA_LOGBOOK_RECORDS";
export const PROJECT_DATA_LOGBOOK_RECORDS_DATA = "PROJECT_DATA_LOGBOOK_RECORDS_DATA";
export const PROJECT_DATA_LOGBOOK_RECORDS_ERROR = "PROJECT_DATA_LOGBOOK_RECORDS_ERROR";

export const PROJECT_DATA_LOGBOOK_CHECK_ALL = 'PROJECT_DATA_LOGBOOK_CHECK_ALL';
export const PROJECT_DATA_LOGBOOK_RECORD_SELECTION_CHANGED = 'PROJECT_DATA_LOGBOOK_RECORD_SELECTION_CHANGED';

export const PROJECT_DATA_LOGBOOK_ELEMENT_SAVE_ENTRY = "PROJECT_DATA_LOGBOOK_ELEMENT_SAVE_ENTRY";

export const PROJECT_DATA_LOGBOOK_AUTHORS_LOADING = 'PROJECT_DATA_LOGBOOK_AUTHORS_LOADING';
export const PROJECT_DATA_LOGBOOK_AUTHORS_LOADING_ERROR = 'PROJECT_DATA_LOGBOOK_AUTHORS_LOADING_ERROR';
export const PROJECT_DATA_LOGBOOK_AUTHORS_LOADED = 'PROJECT_DATA_LOGBOOK_AUTHORS_LOADED';

export const PROJECT_DATA_LOGBOOK_MARK_AS_READED = 'PROJECT_DATA_LOGBOOK_MARK_AS_READED';
export const PROJECT_DATA_LOGBOOK_MARK_AS_READED_SELECTED = 'PROJECT_DATA_LOGBOOK_MARK_AS_READED_SELECTED';

// relatedCompanies
export const GEOVIS_BUSINESS_COMPANIES = 'GEOVIS_BUSINESS_COMPANIES';
export const GEOVIS_BUSINESS_COMPANIES_DATA = 'GEOVIS_BUSINESS_COMPANIES_DATA';
export const GEOVIS_BUSINESS_COMPANIES_ROWS_PER_PAGE = "GEOVIS_BUSINESS_COMPANIES_ROWS_PER_PAGE";
export const GEOVIS_BUSINESS_SHOW_EDIT_COMPANY_DIALOG = "GEOVIS_BUSINESS_SHOW_EDIT_COMPANY_DIALOG";
export const GEOVIS_BUSINESS_HIDE_EDIT_COMPANY_DIALOG = "GEOVIS_BUSINESS_HIDE_EDIT_COMPANY_DIALOG";
export const GEOVIS_BUSINESS_SHOW_DELETE_COMPANY_DIALOG = "GEOVIS_BUSINESS_SHOW_DELETE_COMPANY_DIALOG";
export const GEOVIS_BUSINESS_HIDE_DELETE_COMPANY_DIALOG = "GEOVIS_BUSINESS_HIDE_DELETE_COMPANY_DIALOG";
export const GEOVIS_BUSINESS_COMPANIES_NAMES = "GEOVIS_BUSINESS_COMPANIES_NAMES";
export const GEOVIS_BUSINESS_COMPANIES_NAMES_DATA = "GEOVIS_BUSINESS_COMPANIES_NAMES_DATA";
export const GEOVIS_BUSINESS_REMOVING_COMPANY_IN_PROGRESS = "GEOVIS_BUSINESS_REMOVING_COMPANY_IN_PROGRESS";
export const GEOVIS_BUSINESS_REMOVE_RELATED_COMPANY = "GEOVIS_BUSINESS_REMOVE_RELATED_COMPANY";
export const GEOVIS_BUSINESS_UPDATE_RELATED_COMPANY = "GEOVIS_BUSINESS_UPDATE_RELATED_COMPANY";
export const GEOVIS_BUSINESS_COMPANY_RELATED_DATA_IN_PROGRESS = "GEOVIS_BUSINESS_COMPANY_RELATED_DATA_IN_PROGRESS";
export const GEOVIS_BUSINESS_COMPANY_RELATED_DATA = "GEOVIS_BUSINESS_COMPANY_RELATED_DATA";
export const GEOVIS_BUSINESS_COMPANY_OWN_DATA_IN_PROGRESS = "GEOVIS_BUSINESS_COMPANY_OWN_DATA_IN_PROGRESS";
export const GEOVIS_BUSINESS_COMPANY_OWN_DATA = "GEOVIS_BUSINESS_COMPANY_OWN_DATA";
export const GEOVIS_BUSINESS_COMPANY_OWN_DATA_FAILED = "GEOVIS_BUSINESS_COMPANY_OWN_DATA_FAILED";
export const GEOVIS_COMPANIES_SEARCH = "GEOVIS_COMPANIES_SEARCH";
export const GEOVIS_COMPANIES_SET_PAGE = "GEOVIS_COMPANIES_SET_PAGE";
export const GEOVIS_COMPANIES_SORT = "GEOVIS_COMPANIES_SORT";
export const GEOVIS_COMPANIES_SET_ROW_PER_PAGES = "GEOVIS_COMPANIES_SET_ROW_PER_PAGES";

export const GEOVIS_COMPANY_STORAGE_UPDATE = "GEOVIS_COMPANY_STORAGE_UPDATE";
export const GEOVIS_COMPANY_LOGOS_INFO = "GEOVIS_COMPANY_LOGOS_INFO";
export const GEOVIS_COMPANY_LOGOS_INFO_ERROR = "GEOVIS_COMPANY_LOGOS_INFO_ERROR";
export const GEOVIS_COMPANY_LOGOS_INFO_DATA = "GEOVIS_COMPANY_LOGOS_INFO_DATA";

export const GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA_IN_PROGRESS = "GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA_IN_PROGRESS";
export const GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA_ERROR = "GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA_ERROR";
export const GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA = "GEOVIS_COMPANY_SUPPORTS_TEMPLATE_DATA";

// project logos info
export const PROJECT_DATA_LOGOS_INFO = "PROJECT_DATA_LOGOS_INFO";
export const PROJECT_DATA_LOGOS_INFO_ERROR = "PROJECT_DATA_LOGOS_INFO_ERROR";
export const PROJECT_DATA_LOGOS_INFO_DATA = "PROJECT_DATA_LOGOS_INFO_DATA";

// project statistic
export const PROJECT_DATA_STATISTIC = "PROJECT_DATA_STATISTIC";
export const PROJECT_DATA_STATISTIC_ERROR = "PROJECT_DATA_STATISTIC_ERROR";
export const PROJECT_DATA_STATISTIC_DATA = "PROJECT_DATA_STATISTIC_DATA";

// #region  tree data actions

export const PROJECT_DATA_VIEW_TREE_FILTER_CHANGED = "PROJECT_DATA_VIEW_TREE_FILTER_CHANGED";
export const PROJECT_DATA_VIEW_TREE_SEARCH = "PROJECT_DATA_VIEW_TREE_SEARCH";
export const PROJECT_DATA_VIEW_TREE_FILTER_NODE_EXPANDED = "PROJECT_DATA_VIEW_TREE_FILTER_NODE_EXPANDED";
export const PROJECT_DATA_VIEW_TREE_FILTER_NODE_COLLAPSED = "PROJECT_DATA_VIEW_TREE_FILTER_NODE_COLLAPSED";

export const PROJECT_DATA_VIEW_SELECTED = "PROJECT_DATA_VIEW_SELECTED";
export const PROJECT_DATA_VIEW_TREE_ITEM_EXPAND = "PROJECT_DATA_VIEW_TREE_ITEM_EXPAND";
export const PROJECT_DATA_VIEW_TREE_ITEM_COLLAPSE = "PROJECT_DATA_VIEW_TREE_ITEM_COLLAPSE";

export const PROJECT_DATA_VIEW_TREE_ITEM_SELECTION_CHANGE = "PROJECT_DATA_VIEW_TREE_ITEM_SELECTION_CHANGE";

// #endregion

//#region Project users

export const PROJECT_DATA_USERS = "PROJECT_DATA_USERS";
export const PROJECT_DATA_USERS_ERROR = "PROJECT_DATA_USERS_ERROR";
export const PROJECT_DATA_USERS_DATA = "PROJECT_DATA_USERS_DATA";
export const PROJECT_DATA_USERS_UPDATE = 'PROJECT_DATA_USERS_UPDATE';

//#endregion

// labels
export const PROJECT_DATA_LABELS = 'PROJECT_DATA_LABELS';
export const PROJECT_DATA_LABELS_DATA = 'PROJECT_DATA_LABELS_DATA';

export const PROJECT_DATA_INFORMATION_UPDATE = 'PROJECT_DATA_INFORMATION_UPDATE'

export const PROJECT_DATA_KEEP_ZOOM_CHANGED = 'PROJECT_DATA_KEEP_ZOOM_CHANGED';

export const PROJECT_DATA_LABEL_ADDED = 'PROJECT_DATA_LABEL_ADDED';
export const PROJECT_DATA_LABEL_UPDATED = 'PROJECT_DATA_LABEL_UPDATED';
export const PROJECT_DATA_LABEL_REMOVED = 'PROJECT_DATA_LABEL_REMOVED';

//#region Project support

export const PROJECT_DATA_SUPPORT_LOADING = "PROJECT_DATA_SUPPORT_LOADING";
export const PROJECT_DATA_SUPPORT_LOADED = "PROJECT_DATA_SUPPORT_LOADED";

export const PROJECT_DATA_SUPPORT_SELECT_TEMPLATE_ID = "PROJECT_DATA_SUPPORT_SELECT_TEMPLATE_ID";
export const PROJECT_DATA_SUPPORT_SAVE_MESSAGE_TEMPLATE = "PROJECT_DATA_SUPPORT_SAVE_MESSAGE_TEMPLATE";

export const PROJECT_DATA_SUPPORT_START_EDIT_TEMPLATE = "PROJECT_DATA_SUPPORT_START_EDIT_TEMPLATE";
export const PROJECT_DATA_SUPPORT_CANCEL_EDIT = "PROJECT_DATA_SUPPORT_CANCEL_EDIT";
export const PROJECT_DATA_SUPPORT_CREATE_NEW = "PROJECT_DATA_SUPPORT_CREATE_NEW";

export const PROJECT_DATA_SUPPORT_SHOW_DELETE_DIALOG = "PROJECT_DATA_SUPPORT_SHOW_DELETE_DIALOG";
export const PROJECT_DATA_SUPPORT_HIDE_DELETE_DIALOG = "PROJECT_DATA_SUPPORT_HIDE_DELETE_DIALOG";
export const PROJECT_DATA_SUPPORT_DELETE_TEMPLATE = "PROJECT_DATA_SUPPORT_DELETE_TEMPLATE";

//#endregion

//#region LoRa actions

export const PROJECT_LORA_ROOT_CONFIGURATION = 'PROJECT_LORA_ROOT_CONFIGURATION';
export const PROJECT_LORA_ROOT_CONFIGURATION_DATA = 'PROJECT_LORA_ROOT_CONFIGURATION_DATA';

//#endregion

//#region Profile views actions

export const PROJECT_PROFILE_VIEWS = 'PROJECT_PROFILE_VIEWS';
export const PROJECT_PROFILE_VIEWS_DATA = 'PROJECT_PROFILE_VIEWS_DATA';
export const PROJECT_PROFILE_VIEWS_ERROR = 'PROJECT_PROFILE_VIEWS_ERROR';
export const PROJECT_PROFILE_VIEW_CHANGED = 'PROJECT_PROFILE_VIEW_CHANGED';

//#endregion

//#region project Databases actions

export const PROJECT_DATABASES_IN_PROGRESS = 'PROJECT_DATABASES_IN_PROGRESS';
export const PROJECT_DATABASES_LOADED = 'PROJECT_DATABASES_LOADED';
export const PROJECT_DATABASES_LOADING_ERROR = 'PROJECT_DATABASES_LOADING_ERROR';

export const PROJECT_DATABASE_ADDED = 'PROJECT_DATABASE_ADDED';
export const PROJECT_DATABASE_REMOVED = 'PROJECT_DATABASE_REMOVED';
export const PROJECT_DATABASE_UPDATED = 'PROJECT_DATABASE_UPDATED';

//#endregion

//#region

export const PROJECT_DTS_SECTIONS_LOADING = 'PROJECT_DTS_SECTIONS_LOADING';
export const PROJECT_DTS_SECTIONS_LOADED = 'PROJECT_DTS_SECTIONS_LOADED';
export const PROJECT_DTS_SECTIONS_LOADING_ERROR = 'PROJECT_DTS_SECTIONS_LOADING_ERROR';

export const PROJECT_DTS_SECTION_ADDED = 'PROJECT_DTS_SECTION_ADDED';
export const PROJECT_DTS_SECTION_REMOVED = 'PROJECT_DTS_SECTION_REMOVED';
export const PROJECT_DTS_SECTION_UPDATED = 'PROJECT_DTS_SECTION_UPDATED';

//#endregion

//#region project elements

export const PROJECT_ELEMENT_ENTRIES_LOADING = 'PROJECT_ELEMENT_ENTRIES_LOADING';
export const PROJECT_ELEMENT_ENTRIES_LOADED = 'PROJECT_ELEMENT_ENTRIES_LOADED';

export const PROJECT_ELEMENT_ENTRY_ADDED = 'PROJECT_ELEMENT_ENTRY_ADDED';
export const PROJECT_ELEMENT_ENTRY_REMOVED = 'PROJECT_ELEMENT_ENTRY_REMOVED';
export const PROJECT_ELEMENT_ENTRY_UPDATED = 'PROJECT_ELEMENT_ENTRY_UPDATED';

export const PROJECT_ELEMENTS_CUSTOM_GROUPS_UPDATED = "PROJECT_ELEMENTS_CUSTOM_GROUPS_UPDATED";

//#endregion

//#region project views

export const PROJECT_VIEWS_LOADING = 'PROJECT_VIEWS_LOADING';
export const PROJECT_VIEWS_LOADED = 'PROJECT_VIEWS_LOADED';
export const PROJECT_VIEWS_LOADING_ERROR = 'PROJECT_VIEWS_LOADING_ERROR';

//#endregion

//#region georasters

export const PROJECT_DATA_GEORASTER_LOADING = 'PROJECT_DATA_GEORASTER_LOADING';
export const PROJECT_DATA_GEORASTER_LOADED = 'PROJECT_DATA_GEORASTER_LOADED';
export const PROJECT_DATA_GEORASTER_LOADING_ERROR = 'PROJECT_DATA_GEORASTER_LOADING_ERROR';
export const PROJECT_DATA_GEORASTER_ADDED = "PROJECT_DATA_GEORASTER_ADDED";
export const PROJECT_DATA_GEORASTER_REMOVED = "PROJECT_DATA_GEORASTER_REMOVED";
export const PROJECT_DATA_GEORASTER_UPDATED = "PROJECT_DATA_GEORASTER_UPDATED";

export const PROJECT_VIEW_DATA_GEORASTERS_LOADING = 'PROJECT_VIEW_DATA_GEORASTERS_LOADING';
export const PROJECT_VIEW_DATA_GEORASTERS_LOADING_ERROR = 'PROJECT_VIEW_DATA_GEORASTERS_LOADING_ERROR';
export const PROJECT_VIEW_DATA_GEORASTERS_LOADED = 'PROJECT_VIEW_DATA_GEORASTERS_LOADED';
export const PROJECT_VIEW_DATA_CURRENT_GEORASTER_CHANGED = 'PROJECT_VIEW_DATA_CURRENT_GEORASTER_CHANGED';

//#endregion