//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DefaultChartSensorFilterOption {
	UseAllSensorsOfType = 1,
	UseManuallySelectedSensors = 2,
	UseFilter = 3
}

const DefaultChartSensorFilterOptionToName = new Map<number, string>([
	[ 1, 'UseAllSensorsOfType' ],
	[ 2, 'UseManuallySelectedSensors' ],
	[ 3, 'UseFilter' ],
])

export function getDefaultChartSensorFilterOptionToName(element: DefaultChartSensorFilterOption) : string {
    return DefaultChartSensorFilterOptionToName.get(element) || '';
}

const DefaultChartSensorFilterOptionByName = new Map<string, number>([
	[ 'UseAllSensorsOfType', 1 ],
	[ 'UseManuallySelectedSensors', 2 ],
	[ 'UseFilter', 3 ],
]);

export function getDefaultChartSensorFilterOptionByName(name: string): DefaultChartSensorFilterOption {
    return DefaultChartSensorFilterOptionByName.get(name)!;
}


export const DefaultChartSensorFilterOptionList : Readonly<DefaultChartSensorFilterOption[]> = [
	DefaultChartSensorFilterOption.UseAllSensorsOfType,
	DefaultChartSensorFilterOption.UseManuallySelectedSensors,
	DefaultChartSensorFilterOption.UseFilter,
];



const DefaultChartSensorFilterOptionToDescription = new Map<number, string>([
	[ 1, 'Use all sensors of this type' ],
	[ 2, 'Use a custom selection of sensors' ],
	[ 3, 'Use filter for automatic selection of sensors' ],
])

export function getDefaultChartSensorFilterOptionToDescription(element: DefaultChartSensorFilterOption) : string {
    return DefaultChartSensorFilterOptionToDescription.get(element) || '';
}

