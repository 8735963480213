import { ChangeAllProjectsType } from "../../pages/business/settings/elementsMigrations/types";
import { DataActionResponse } from "../../server/DataActionResponse";
import { ProjectElementsMigrationsInfo } from "../../server/GEOvis3/Model/ElementsMigrations/ProjectElementsMigrationsInfo";
import {
    ELEMENTS_MIGRATIONS_FILTER_CHANGED,
    ELEMENTS_MIGRATIONS_HIDE_MIGRATE_ALL_DIALOG,
    ELEMENTS_MIGRATIONS_HIDE_PROJECT_DIALOG,
    ELEMENTS_MIGRATIONS_PROJECTS_LOADED,
    ELEMENTS_MIGRATIONS_PROJECTS_LOADING,
    ELEMENTS_MIGRATIONS_SHOW_MIGRATE_ALL_DIALOG,
    ELEMENTS_MIGRATIONS_SHOW_PROJECT_DIALOG,
    ELEMENTS_MIGRATIONS_UPDATE_PROJECT_INFO
} from "../actions/elementsMigrationsActions";
import { IElementsMigrationsAction } from "../elementsMigrations.types";

export const elementsMigrationsProjectsLoading = (): IElementsMigrationsAction => ({
    type: ELEMENTS_MIGRATIONS_PROJECTS_LOADING
});

export const elementsMigrationsProjectsLoaded = (migrationsInfo: DataActionResponse<ProjectElementsMigrationsInfo[]>): IElementsMigrationsAction => ({
    type: ELEMENTS_MIGRATIONS_PROJECTS_LOADED, migrationsInfo
});


export const elementsMigrationsShowProjectDialog = (projectId: number): IElementsMigrationsAction => ({
    type: ELEMENTS_MIGRATIONS_SHOW_PROJECT_DIALOG, projectId
});
export const elementsMigrationsHideProjectDialog = (): IElementsMigrationsAction => ({
    type: ELEMENTS_MIGRATIONS_HIDE_PROJECT_DIALOG
});

export const elementsMigrationsUpdateProjectInfo = (projectMigrationsInfo: ProjectElementsMigrationsInfo): IElementsMigrationsAction => ({
    type: ELEMENTS_MIGRATIONS_UPDATE_PROJECT_INFO, projectMigrationsInfo
});

export const elementsMigrationsFilterChanged = (projectNameFilter: string): IElementsMigrationsAction => ({
    type: ELEMENTS_MIGRATIONS_FILTER_CHANGED, stringProperty: projectNameFilter
});

export const elementsMigrationsShowMigrateAll = (type: ChangeAllProjectsType): IElementsMigrationsAction => ({
    type: ELEMENTS_MIGRATIONS_SHOW_MIGRATE_ALL_DIALOG, migrationType: type
});

export const elementsMigrationsHideMigrateAll = (): IElementsMigrationsAction => ({
    type: ELEMENTS_MIGRATIONS_HIDE_MIGRATE_ALL_DIALOG
});