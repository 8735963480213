/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 24.12.2019
 * @description Geovis 4 events dispatcher (to dispatch events between components without re-rendering)
 */

import Logger from './Logger';
const LoggerSource = "GeovisEventDispatcher";

export interface IGeovisEventDispatcherAction<TDataType extends any> {
    actionId: string;
    data: TDataType;
}

export interface IGeovisEventDispatcherUnsubscribe {
    // eslint-disable-next-line @typescript-eslint/prefer-function-type
    (): void;
}

interface IGeovisEventListener {
    // eslint-disable-next-line @typescript-eslint/prefer-function-type
    (event: IGeovisEventDispatcherAction<any>): void;
}

export interface IGeovisEventDispatcher {
    dispatch: (event: IGeovisEventDispatcherAction<any>) => void;
    subscribe: (listener: IGeovisEventListener, expectedActions: string[]) => IGeovisEventDispatcherUnsubscribe;
}

const Unsubscribe = (listenerId: number, gd: GeovisEventDispatcher) => () => {
    gd.unsubscribe(listenerId);
}

class GeovisEventDispatcher implements IGeovisEventDispatcher {

    // #region Members

    private readonly listeners: Map<number, IGeovisEventListener>;
    private readonly actionsToListener: Map<string, number[]>;

    // #endregion

    // #region Constructor

    constructor() {

        this.listeners = new Map<number, IGeovisEventListener>();
        this.actionsToListener = new Map<string, number[]>();

        Logger.trace("Initialized", LoggerSource);
    }

    // #endregion

    // #region Dispatching


    /**
     * Dispatch event
     * @param action Event to dispatch
     */
    public dispatch = (action: IGeovisEventDispatcherAction<any>) => {

        if (!this.actionsToListener.has(action.actionId)) {
            Logger.warning(`Attempt to dispatch action ${action.actionId}, which not listen any subscribers`);
            return;
        }

        const listeners = this.actionsToListener.get(action.actionId);
        if (!listeners) {
            Logger.warning(`Incorrect list of listeners of action ${action.actionId}`);
            return;
        }

        for (const listenerId of listeners) {
            const listener = this.listeners.get(listenerId);

            if (!listener) {
                Logger.warning(`Cannot notify listener with id ${listenerId}, its method not found`);
                continue;
            }

            try {
                listener(action);
            } catch (error) {
                Logger.error(`An error to dispatch event to listener ${listenerId}, ${error}`);
            }
        }
    }

    // #endregion

    // #region Subscribing

    /**
     * Register listener for event
     * @param listener the listener function
     * @param actions the list of actions which you want to receive
     * @returns The unsubscribe if you want do not receive it
     */
    public subscribe = (listener: IGeovisEventListener, actions: string[]): IGeovisEventDispatcherUnsubscribe => {

        if (!actions || actions.length === 0) {
            Logger.warning(`Incorrect subscription on ${listener}, actions list are empty`);
        }

        const listenerId = this.getNextKey();
        this.listeners.set(listenerId, listener);

        // register the listener actions
        for (const action of actions) {

            // if it is the first registration of this action
            if (!this.actionsToListener.has(action)) {
                this.actionsToListener.set(action, [listenerId]);
                continue;
            }

            // add listener to list of exists listeners of such action
            const existListenersIds = this.actionsToListener.get(action);
            this.actionsToListener.set(action, [...existListenersIds!, listenerId]);
        }

        return Unsubscribe(listenerId, this);
    }

    /**
     * Remove listener from the queue
     * @param listenerId the listener identifier
     */
    public unsubscribe = (listenerId: number) => {
        if (this.listeners.has(listenerId)) {

            this.listeners.delete(listenerId);

            for (const action of Array.from(this.actionsToListener.keys())) {

                const listeners = this.actionsToListener.get(action);
                if (listeners && listeners.indexOf(listenerId) > -1) {

                    const newListeners = listeners.filter(l => l !== listenerId);
                    // if no any listeners of this action, then remove it from known actions
                    if (newListeners.length === 0) {
                        this.actionsToListener.delete(action);
                    }
                    else {
                        this.actionsToListener.set(action, newListeners);
                    }
                }
            }
        }
    }

    // #endregion

    private getNextKey = (): number => {

        if (this.listeners.size === 0) {
            return 1;
        }

        let maxKey = 0;
        this.listeners.forEach((list, key) => {
            if (maxKey < key) {
                maxKey = key;
            }
        });

        return maxKey + 1;
    }
}

const dispatcher: IGeovisEventDispatcher = new GeovisEventDispatcher();

export default dispatcher;