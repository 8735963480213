/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 17.02.2021
 * @description The common application actions reducer
 */

import { Reducer } from 'redux';
import {
    addOrUpdateElementInMap,
    elementsToMap,
    sortElementsInMap
} from '../../helpers/StorageHelper';
import { GeovisReleaseNote } from '../../server/GEOvis3/Model/ReleaseNotes/GeovisReleaseNote';
import {
    APP_RELEASE_NOTES_LOADED,
    APP_RELEASE_NOTES_LOADED_MORE,
    APP_RELEASE_NOTES_LOADING
} from '../actions/applicationActions';
import {
    IApplicationAction,
    IApplicationStorage,
    IReleaseNotesStorage
} from '../application.types';
import { processFetchedData } from '../helpers/DataHelper';
import { defaultSomethingStorageState } from '../types';

const releaseNotesInitialState: IReleaseNotesStorage = {
    ...defaultSomethingStorageState,
    notes: new Map<string, GeovisReleaseNote>(),
    numberOfMore: 0
}

export const applicationStorageInitialState: IApplicationStorage = {
    releaseNotes: releaseNotesInitialState
}

const compareReleases = (r1: GeovisReleaseNote, r2: GeovisReleaseNote) => Date.parse(r2.ReleaseDate) - Date.parse(r1.ReleaseDate)

const applicationReducer: Reducer<IApplicationStorage> = (
    state: IApplicationStorage = applicationStorageInitialState,
    action: IApplicationAction): IApplicationStorage => {

    switch (action.type) {

        case APP_RELEASE_NOTES_LOADING:
            return { ...state, releaseNotes: releaseNotesInitialState };

        case APP_RELEASE_NOTES_LOADED:
            if (action.releaseNotes) {
                return {
                    ...state,
                    releaseNotes: processFetchedData(action.releaseNotes, state.releaseNotes, releaseNotesInitialState, st => ({
                        notes: elementsToMap(st.Notes, compareReleases),
                        noteId: '',
                        showEditDialog: false,
                        showDeleteDialog: false,
                        numberOfMore: st.More
                    }))
                }
            }
            break;

        case APP_RELEASE_NOTES_LOADED_MORE:
            if (action.releaseNotes && action.releaseNotes.Success) {
                return {
                    ...state,
                    releaseNotes: {
                        ...state.releaseNotes,
                        notes: sortElementsInMap(addOrUpdateElementInMap(state.releaseNotes.notes, ...action.releaseNotes.Data.Notes), compareReleases),
                        numberOfMore: action.releaseNotes.Data.More
                    }
                }
            }
            break;
    }

    return state;
};

export default applicationReducer;