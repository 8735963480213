//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ProjectHistoryState {
	Active = 0,
	OnHold = 1,
	Finished = 2,
	Archived = 3,
	Deleted = 4
}

const ProjectHistoryStateToName = new Map<number, string>([
	[ 0, 'Active' ],
	[ 1, 'OnHold' ],
	[ 2, 'Finished' ],
	[ 3, 'Archived' ],
	[ 4, 'Deleted' ],
])

export function getProjectHistoryStateToName(element: ProjectHistoryState) : string {
    return ProjectHistoryStateToName.get(element) || '';
}

const ProjectHistoryStateByName = new Map<string, number>([
	[ 'Active', 0 ],
	[ 'OnHold', 1 ],
	[ 'Finished', 2 ],
	[ 'Archived', 3 ],
	[ 'Deleted', 4 ],
]);

export function getProjectHistoryStateByName(name: string): ProjectHistoryState {
    return ProjectHistoryStateByName.get(name)!;
}


export const ProjectHistoryStateList : Readonly<ProjectHistoryState[]> = [
	ProjectHistoryState.Active,
	ProjectHistoryState.OnHold,
	ProjectHistoryState.Finished,
	ProjectHistoryState.Archived,
	ProjectHistoryState.Deleted,
];



const ProjectHistoryStateToDescription = new Map<number, string>([
	[ 0, 'Active' ],
	[ 1, 'OnHold' ],
	[ 2, 'Finished' ],
	[ 3, 'Archived' ],
	[ 4, 'Deleted' ],
])

export function getProjectHistoryStateToDescription(element: ProjectHistoryState) : string {
    return ProjectHistoryStateToDescription.get(element) || '';
}

