import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import Textfield from '@atlaskit/textfield';
import { debounce } from 'lodash';
import { connect } from "react-redux";
import { Dispatch } from "redux";
import GeovisReactDateTimePicker from '../../../components/dateTimePickers/GeovisReactDateTimePicker';
import { t } from '../../../i18n';
import { EmailHistoryRequest } from "../../../server/AVTService/TypeLibrary/Email/EmailHistoryRequest";
import { geovisBusinessMessageHistoryFilterChanged } from "../../../store/creators/businessDataCreators";
import { IGeovisStoreState } from "../../../store/store.types";
import { IGeovisAction } from "../../../store/types";

let searchField: HTMLInputElement | null = null;

interface IMessagesHistoryFilterControlStateProps {
    filter: EmailHistoryRequest
}

interface IMessagesHistoryFilterControlDispatchProps {
    onFilterChanged: (filter: EmailHistoryRequest) => void;
}

interface IMessagesHistoryFilterControlProps extends IMessagesHistoryFilterControlStateProps, IMessagesHistoryFilterControlDispatchProps {

}

const MessagesHistoryFilterControl = ({
    filter,
    onFilterChanged
}: IMessagesHistoryFilterControlProps) => {

    const onStartDateChanged = (value: string) => {
        onFilterChanged({ ...filter, StartDate: value === "" ? undefined : value })
    }

    const onEndDateChanged = (value: string) => {
        onFilterChanged({ ...filter, EndDate: value === "" ? undefined : value })
    }

    const searchQueryChanged = debounce((target: any) => {
        if (filter.SubjectQuery !== target.value) {
            onFilterChanged({ ...filter, SubjectQuery: target.value === "" ? undefined : target.value })
        }
    }, 500);

    const onClearSearchQuery = () => {
        onFilterChanged({ ...filter, SubjectQuery: undefined })
        if (searchField) {
            searchField.value = "";
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: 'row', flexWrap: 'nowrap', marginBottom: '10px' }}>
            <div style={{ width: '100%', marginRight: '10px' }}>
                <GeovisReactDateTimePicker
                    value={filter.StartDate}
                    showTime={false}
                    placeholderText={t("Set start date")}
                    onChange={onStartDateChanged}
                />
            </div>
            <div style={{ width: '100%', marginRight: '10px' }}>
                <GeovisReactDateTimePicker
                    value={filter.EndDate}
                    showTime={false}
                    placeholderText={t("Set end date")}
                    onChange={onEndDateChanged}
                />
            </div>
            <div style={{ width: '100%' }}>
                <Textfield
                    id={"subjectSearch"}
                    ref={field => searchField = field}
                    placeholder={t("Search")}
                    onChange={({ target }: any) => searchQueryChanged(target)}
                    isCompact={true}
                    defaultValue={filter.SubjectQuery}
                    elemAfterInput={
                        <div style={{ cursor: 'default', marginRight: '5px' }} onClick={onClearSearchQuery}>
                            <SelectClearIcon label="" size="small" primaryColor="gray" />
                        </div>
                    }
                />
            </div>
        </div>
    )
}

const mapStateToProps = ({ businessData }: IGeovisStoreState): IMessagesHistoryFilterControlStateProps => ({
    filter: businessData.messagesStore.historyStore.filter
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IMessagesHistoryFilterControlDispatchProps => ({
    onFilterChanged: (filter) => dispatch(geovisBusinessMessageHistoryFilterChanged(filter))
});

export default connect<IMessagesHistoryFilterControlStateProps, IMessagesHistoryFilterControlDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(MessagesHistoryFilterControl)