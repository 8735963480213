/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 29.07.2020
 * @description Row per page component
 */

import Select, { OptionType } from '@atlaskit/select';
import { t } from '../i18n';

interface IRowsPerPageSelectProps {
    options?: OptionType[];
    selectedValue: number | string;

    onChange: (option: OptionType) => void;
}

const defaultOptions = (): OptionType[] => ([
    { label: '10', value: '10' },
    { label: '25', value: '25' },
    { label: '50', value: '50' },
    { label: '100', value: '100' }
]);

const selectStyle = {
    container(styles: any) {
        return { ...styles, width: '100px' };
    },
};

export const RowsPerPageSelect = ({
    options,
    selectedValue,
    onChange
}: IRowsPerPageSelectProps) => {

    const selectOptions = options || defaultOptions();
    // eslint-disable-next-line eqeqeq
    const defaultOption = selectOptions.filter(o => o.value == selectedValue)[0];

    return (
        <div className="flexRowContainer" style={{ alignItems: 'center' }}>
            <div className="flexCellContainer">
                <span>{t("chooseNumberOfRowsPerPageLabel")}</span>
            </div>
            <div className="flexCellContainer">
                <Select
                    class="single-select"
                    options={selectOptions}
                    defaultValue={defaultOption}
                    styles={selectStyle}
                    isSearchable={false}
                    onChange={onChange}
                    spacing="compact"
                    menuPlacement="top"
                />
            </div>
        </div>
    )
}