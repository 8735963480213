//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ImageBackgroundFileType } from '../../../ImageBackgroundFileType';
import { ImageBackgroundViewPort } from '../../../ImageBackgroundViewPort';

export class ImageBackgroundInfo
{
	public FileId: string;
	public GeoJSONFileId: string;
	public IsGeoJSONFileDirty: boolean;
	public FileName: string;
	public FileType: ImageBackgroundFileType;
	public IsFileDirty: boolean;
	public IsMapTiles: boolean;
	public MapTilesId: string;
	public IsMapTilesDirty: boolean;
	public ViewPort: ImageBackgroundViewPort;
}
