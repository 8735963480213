import Button from "@atlaskit/button";
import DynamicTable from "@atlaskit/dynamic-table";
import { HeadType, RowCellType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import EditorErrorIcon from '@atlaskit/icon/glyph/editor/error'
import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success'
import EditorUndoIcon from '@atlaskit/icon/glyph/editor/undo'
import HipchatAudioOnlyIcon from '@atlaskit/icon/glyph/hipchat/audio-only'
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play'
import WarningIcon from '@atlaskit/icon/glyph/warning'
import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import PageTitleItem from "../../../../components/PageTitleItem";
import Constants from "../../../../helpers/Constants";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { fetchServerElements } from "../../../../helpers/ProjectDataHelper";
import { t } from "../../../../i18n";
import { getMigratebleElementsToName, MigratebleElements, MigratebleElementsList } from "../../../../server/AVTService/TypeLibrary/Common/ElementsMigrations/MigratebleElements";
import { ProjectElementMigrationModel } from "../../../../server/AVTService/TypeLibrary/Common/ElementsMigrations/ProjectElementMigrationModel";
import { ProjectElementMigrationState } from "../../../../server/AVTService/TypeLibrary/Common/ElementsMigrations/ProjectElementMigrationState";
import { DataActionResponse } from "../../../../server/DataActionResponse";
import { ProjectElementsMigrationsInfo } from "../../../../server/GEOvis3/Model/ElementsMigrations/ProjectElementsMigrationsInfo";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import FlagService from "../../../../services/FlagService";
import {
    elementsMigrationsProjectsLoaded,
    elementsMigrationsProjectsLoading,
    elementsMigrationsShowProjectDialog,
    elementsMigrationsUpdateProjectInfo
} from "../../../../store/creators/elementsMigrationsCreator";
import { IElementsMigrationsProjectsStorage } from "../../../../store/elementsMigrations.types";
import { IGeovisStoreState } from "../../../../store/store.types";
import { IGeovisAction } from "../../../../store/types";
import ProjectElementsMigrateAllDialog from "./ProjectElementsMigrateAllDialog";
import ProjectElementsMigrationsFilter from "./ProjectElementsMigrationsFilter";
import SingleProjectElementsMigrationDialog from "./SingleProjectElementsMigrationDialog";
import { implementedMigrationsListElements } from "./types";

interface IPageStateProps {
    projectsMigrationsStorage: IElementsMigrationsProjectsStorage;
    projectNameFilter: string;
}

interface IPageDispatchProps {
    onProjectsDataLoading: () => void;
    onProjectsDataLoaded: (data: DataActionResponse<ProjectElementsMigrationsInfo[]>) => void;

    onProjectMigrated: (changes: ProjectElementsMigrationsInfo) => void;

    onShowProjectDialog: (projectId: number) => void;
}

interface IPageProps extends IPageStateProps, IPageDispatchProps, IWithGeovisServerProps {

}

const ProjectElementsMigrationsPage = ({
    projectsMigrationsStorage,
    Server,
    onProjectsDataLoaded,
    onProjectsDataLoading,
    onShowProjectDialog,
    onProjectMigrated,
    projectNameFilter
}: IPageProps) => {

    const migrateProject = (selectedProjectId: number) => async () => {

        const url = ServerRoutesGen.Migrations.MigrateProject.patch(selectedProjectId);
        const response = await fetchServerElements<ProjectElementsMigrationsInfo>(Server, url);
        if (!response.Success) {
            FlagService.addError("Fail to migrate elements for project", response.Messages.join(". "));
            return;
        }

        onProjectMigrated(response.Data);
    }

    useEffect(() => {
        (async function loadData() {
            onProjectsDataLoading();

            const url = ServerRoutesGen.Migrations.GetProjectElementsInfo;
            const response = await fetchServerElements<ProjectElementsMigrationsInfo[]>(Server, url);

            onProjectsDataLoaded(response);
        })()
    }, [1])


    const getTableHead = (): HeadType => {
        const result: HeadType = {
            cells: [{
                key: 'projectName',
                content: t("Project")
            }]
        };

        MigratebleElementsList.forEach(me => {
            if (implementedMigrationsListElements.includes(me)) {
                result.cells.push({
                    key: `elem-${me}`,
                    content: (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {t(getMigratebleElementsToName(me))}
                        </div>
                    )
                })
            }

        })

        result.cells.push({
            key: 'actions'
        });

        return result;
    }

    const getElementMigratedItem = (itemType: MigratebleElements, items: ProjectElementMigrationModel[]) => {
        if (!implementedMigrationsListElements.includes(itemType)) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {t("N/I")}
                </div>
            )
        }

        const item = items.find(i => i.ElementType === itemType);

        if (!item || item.State === ProjectElementMigrationState.NotMigrated) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        iconBefore={<EditorErrorIcon label="notMigrated" />}
                        appearance='subtle-link'
                    />
                </div>
            )
        }

        if (item.State === ProjectElementMigrationState.Rollback) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        iconBefore={<EditorUndoIcon label="rolledBack" />}
                        appearance='subtle-link'
                    />
                </div>
            )
        }

        if (item.State === ProjectElementMigrationState.Warning) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        iconBefore={<WarningIcon label="rolledBack" primaryColor={Constants.errorColor} />}
                        appearance='subtle-link'
                    />
                </div>
            )
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    iconBefore={<EditorSuccessIcon label="migrated" />}
                    appearance='subtle-link'
                />
            </div>
        )
    }

    const match = (source: string, pattern: string): boolean => (source !== undefined && source !== null && source.toLowerCase().search(pattern.toLowerCase()) > -1)

    const getTableRows = (): RowType[] => {

        const getCells = (element: ProjectElementsMigrationsInfo): RowCellType[] => {

            const onShowProjectDetailsHandler = () => onShowProjectDialog(element.ProjectId);

            const result: RowCellType[] = [{
                key: 'projectName',
                content: (<span>{element.ProjectName}</span>)
            }];

            MigratebleElementsList.forEach(me => {
                if (implementedMigrationsListElements.includes(me)) {
                    result.push({
                        key: `elem-${me}`,
                        content: getElementMigratedItem(me, element.Migrations)
                    })
                }
            });

            result.push({
                key: 'actions',
                content: (
                    <div>
                        <Button
                            iconBefore={<VidPlayIcon label='edit' />}
                            appearance='subtle'
                            onClick={migrateProject(element.ProjectId)}
                        />
                        <Button
                            iconBefore={<HipchatAudioOnlyIcon label='edit' />}
                            appearance='subtle'
                            onClick={onShowProjectDetailsHandler}
                        />
                    </div>
                )
            })
            return result;
        }

        return projectsMigrationsStorage.projectsMigrationsData.filter(d => match(d.ProjectName, projectNameFilter)).map(md => ({
            cells: getCells(md)
        }));
    }

    return (
        <div className="page">
            <div className="flexRowContainer">
                <PageTitleItem title={t("Elements migrations")} />
            </div>
            <div className="flexRowContainer">
                <ProjectElementsMigrationsFilter />
            </div>
            <div className="flexRowMiddleContainer">
                <DynamicTable
                    head={getTableHead()}
                    rows={getTableRows()}
                    isLoading={projectsMigrationsStorage.isLoading}
                />
            </div>
            <SingleProjectElementsMigrationDialog />
            <ProjectElementsMigrateAllDialog />
        </div>
    )
}

const mapStateToProps = ({ projectElementsMigrations }: IGeovisStoreState): IPageStateProps => ({
    projectsMigrationsStorage: projectElementsMigrations.projectsMigrationsStorage,
    projectNameFilter: projectElementsMigrations.projectNameFilter
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IPageDispatchProps => ({
    onProjectsDataLoaded: (data) => dispatch(elementsMigrationsProjectsLoaded(data)),
    onProjectsDataLoading: () => dispatch(elementsMigrationsProjectsLoading()),
    onShowProjectDialog: (projectId) => dispatch(elementsMigrationsShowProjectDialog(projectId)),
    onProjectMigrated: migrationsInfo => dispatch(elementsMigrationsUpdateProjectInfo(migrationsInfo))
});

export default connect<IPageStateProps, IPageDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(ProjectElementsMigrationsPage))
