import Modal, { ModalTransition, } from "@atlaskit/modal-dialog";
import { t } from "../../../../i18n";


interface IComponentProps {
    onConfirm: () => void;
    onClose: () => void;
}

export const AlarmConditionRemoveDialog = ({
    onConfirm,
    onClose,
}: IComponentProps) => {
    return (
        <ModalTransition>
            <Modal
                heading={t("Confirm removing condition")}
                actions={[
                    { text: t("Remove"), onClick: onConfirm, appearance: 'danger' },
                    { text: t("Cancel"), onClick: onClose },
                ]}
            >
                <div>
                    {t("Are you sure to remove this alarm condition?")}
                </div>
            </Modal>
        </ModalTransition>
    )
}