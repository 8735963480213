/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 12.01.2023
 * @description Project help methods
 */

import { ProjectInfoSlim } from "../server/GEOvis3/Model/Project/ProjectInfoSlim";

/**
 * Get project display name
 * Use this function in all places of the application
 * @param param0 
 * @returns 
 */
export const getProjectDisplayName = ({ Name, Number }: ProjectInfoSlim): string => {

    if (!Number) {
        return Name;
    }

    return `${Number} ${Name}`;
}