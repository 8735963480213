//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { DtsSectionLoop } from './DtsSectionLoop';
import { ColorizableElement } from '../DB/ColorizableElement';
import { DtsCloseTSensorInfo } from './DtsCloseTSensorInfo';

export class DtsSection
{
	public Id: string;
	public Name: string;
	public ProjectId: number;
	public Alias: string;
	public Loop: DtsSectionLoop;
	public StartPos: number;
	public EndPos: number;
	public TemperatureColorElement: ColorizableElement;
	public AttenuationColorElement: ColorizableElement;
	public StokeColorElement: ColorizableElement;
	public CloseTSensorsColorElement: ColorizableElement;
	public SearchRadius: number;
	public DtsCloseTSensors: DtsCloseTSensorInfo[];
	public IsHeatable: boolean;
	public HeatpulseTemperature: ColorizableElement;
	public HeatpulseStockAntistoke: ColorizableElement;
	public HeatpulseT0: ColorizableElement;
	public HeatpulseDT: ColorizableElement;
	public HeatpulseDTNormalized: ColorizableElement;
	public HeatpulseThermalConductivity: ColorizableElement;
	public HeatpulseAttenuation: ColorizableElement;
}
