//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TaskProgressState {
	Pending = 0,
	Running = 1,
	CancelFinished = 2,
	FailedFinished = 3,
	SuccessFinished = 4
}

const TaskProgressStateToName = new Map<number, string>([
	[ 0, 'Pending' ],
	[ 1, 'Running' ],
	[ 2, 'CancelFinished' ],
	[ 3, 'FailedFinished' ],
	[ 4, 'SuccessFinished' ],
])

export function getTaskProgressStateToName(element: TaskProgressState) : string {
    return TaskProgressStateToName.get(element) || '';
}

const TaskProgressStateByName = new Map<string, number>([
	[ 'Pending', 0 ],
	[ 'Running', 1 ],
	[ 'CancelFinished', 2 ],
	[ 'FailedFinished', 3 ],
	[ 'SuccessFinished', 4 ],
]);

export function getTaskProgressStateByName(name: string): TaskProgressState {
    return TaskProgressStateByName.get(name)!;
}


export const TaskProgressStateList : Readonly<TaskProgressState[]> = [
	TaskProgressState.Pending,
	TaskProgressState.Running,
	TaskProgressState.CancelFinished,
	TaskProgressState.FailedFinished,
	TaskProgressState.SuccessFinished,
];



const TaskProgressStateToDescription = new Map<number, string>([
	[ 0, 'Pending' ],
	[ 1, 'Running' ],
	[ 2, 'CancelFinished' ],
	[ 3, 'FailedFinished' ],
	[ 4, 'SuccessFinished' ],
])

export function getTaskProgressStateToDescription(element: TaskProgressState) : string {
    return TaskProgressStateToDescription.get(element) || '';
}

