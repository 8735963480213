/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.10.2022
 * @description User profile editor as modal dialog. All changes will be applied after pressing OK
 */

import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { useEffect, useState } from 'react';
import { IWithGeovisServerProps, withGeovisServer } from '../../helpers/GeovisHooks';
import { fetchServerElements, sendServerPostRequestData } from '../../helpers/ProjectDataHelper';
import { t } from '../../i18n';
import { ActionResponse } from '../../server/ActionResponse';
import { GeovisUserProfileChangePasswordModel } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserProfileChangePasswordModel';
import { GeovisUserProfilePasswordModel } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserProfilePasswordModel';
import { GeovisUserProjectRelationModel } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserProjectRelationModel';
import { GeovisUserToProjectRole } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserToProjectRole';
import { DataActionResponse } from '../../server/DataActionResponse';
import { GeovisUserProfileCreateInfo } from '../../server/GEOvis3/Model/User/GeovisUserProfileCreateInfo';
import { GeovisUserProfileInfo } from '../../server/GEOvis3/Model/User/GeovisUserProfileInfo';
import { GeovisUserTableInfo } from '../../server/GEOvis3/Model/User/GeovisUserTableInfo';
import ServerRoutesGen from '../../server/Routes/ServerRoutesGen';
import FlagService from '../../services/FlagService';
import { processFetchedData } from '../../store/helpers/DataHelper';
import { defaultSomethingStorageState, ISomethingStorageBaseEx, loadedSomethingStorageState } from '../../store/types';
import { LoadingContainerSkeleton } from '../LoadingContainerSkeleton';
import { LoadingPageErrorSkeleton } from '../LoadingPageErrorSkeleton';
import { getModalDialogHeading } from './tools';
import { UserEditorMode } from './types';
import { UserProfileEditorTabs } from './UserProfileEditorTabs';
import { UserToProjectRelationChangeModel } from '../../server/GEOvis3/Model/User/UserToProjectRelationChangeModel';
import { RelationChangeState } from '../../server/GEOvis3/Model/User/RelationChangeState';
import { verifyPhoneNumber } from '../../pages/edit/alarms/alarmEdit/AlarmActionsEdit/tools';
import { AuthorizationCode } from '../../server/AuthorizationCode';

interface IComponentProps extends IWithGeovisServerProps {
    userId: string;

    editMode: UserEditorMode;
    createUserModel?: GeovisUserProfileInfo;

    onSave: (user: GeovisUserTableInfo) => void;
    onClose: () => void;
}

type IComponentState = ISomethingStorageBaseEx<GeovisUserProfileInfo>

const dialogInitialState: IComponentState = { ...defaultSomethingStorageState, data: { ...new GeovisUserProfileInfo() } };

const UserProfileEditorDialog = ({ Server, onClose, onSave, userId, editMode, createUserModel }: IComponentProps) => {

    const [userState, setUserState] = useState<IComponentState>(dialogInitialState);
    const [settingUserPasswordInProgress, setSettingsUserPasswordInProgress] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [passwordInfo, setPasswordInfo] = useState<GeovisUserProfilePasswordModel>({ ConfirmPassword: '', Password: '' });

    useEffect(() => {

        if (editMode === UserEditorMode.Create && createUserModel) {

            setUserState({
                ...loadedSomethingStorageState,
                data: createUserModel
            })
            return;
        }

        (async function loadUserProfileInfo() {

            const url = ServerRoutesGen.Account.LoadUserProfile.patch(userId);
            const response = await fetchServerElements<GeovisUserProfileInfo>(Server, url);

            setUserState(processFetchedData(response, userState, dialogInitialState, st => ({ data: st })));
        })();

    }, [userId])

    const getSaveButtonText = (): string => {

        switch (editMode) {
            case UserEditorMode.Create: return t("Create");
            case UserEditorMode.Edit: return t("Save");
            case UserEditorMode.AddRelation: return t("Add relation");
        }

        return t("Ok");
    }

    const verifyUser = (): string => {
        if (userState.data.PhoneNumber === "") {
            return "";
        }

        return verifyPhoneNumber(userState.data.PhoneNumber, userState.data.CountryCode, false) ? "" : "Phone number is not valid";
    }

    const saveRelatedUser = async (): Promise<DataActionResponse<GeovisUserTableInfo>> => {
        const error = verifyUser();
        if (error !== "") {
            return ({
                AuthorizationCode: AuthorizationCode.Cancelled,
                Data: { ... new GeovisUserTableInfo() },
                FailedFields: [],
                JsonPath: '',
                Messages: [error],
                Success: false
            })
        }

        const url = ServerRoutesGen.Account.SaveRelatedUser;
        const response = await sendServerPostRequestData<GeovisUserProfileInfo, DataActionResponse<GeovisUserTableInfo>>(Server, url, userState.data);

        return response;
    }

    const addRelatedUser = async (): Promise<DataActionResponse<GeovisUserTableInfo>> => {
        const error = verifyUser();
        if (error !== "") {
            return ({
                AuthorizationCode: AuthorizationCode.Cancelled,
                Data: { ... new GeovisUserTableInfo() },
                FailedFields: [],
                JsonPath: '',
                Messages: [error],
                Success: false
            })
        }

        const url = ServerRoutesGen.Account.AddRelatedUser;
        const response = await sendServerPostRequestData<GeovisUserProfileInfo, DataActionResponse<GeovisUserTableInfo>>(Server, url, userState.data);

        return response;
    }

    const createRelatedUser = async (): Promise<DataActionResponse<GeovisUserTableInfo>> => {
        const error = verifyUser();
        if (error !== "") {
            return ({
                AuthorizationCode: AuthorizationCode.Cancelled,
                Data: { ... new GeovisUserTableInfo() },
                FailedFields: [],
                JsonPath: '',
                Messages: [error],
                Success: false
            })
        }

        const url = ServerRoutesGen.Account.CreateRelatedUser;
        const payload: GeovisUserProfileCreateInfo = {
            User: userState.data,
            PasswordInfo: passwordInfo
        };

        const response = await sendServerPostRequestData<GeovisUserProfileCreateInfo, DataActionResponse<GeovisUserTableInfo>>(Server, url, payload);

        return response;
    }

    /**
     * Apply changes to server
     */
    const onOkClick = async () => {

        try {
            setIsSaving(true);

            const response = editMode === UserEditorMode.Create
                ? await createRelatedUser()
                : editMode === UserEditorMode.AddRelation
                    ? await addRelatedUser()
                    : await saveRelatedUser();

            if (!response.Success) {
                FlagService.addErrors(t("Cannot save user changed"), response.Messages);
                return;
            }

            const user = response.Data;

            // push to update in external storage
            onSave(user);
        }
        catch (error) {
            FlagService.addError(t("An error to save the user"), error);
        }
        finally {
            setIsSaving(false);
        }
    }

    /**
     * Update user model property
     * @param propertyName 
     * @param value 
     * @returns 
     */
    const onUserPropertyChangedHandler = (propertyName: keyof GeovisUserProfileInfo, value: any) => setUserState({
        ...userState,
        data: { ...userState.data, [propertyName]: value }
    });

    /**
     * Update many user properties
     * @param changes 
     * @returns 
     */
    const onUserPropertiesChangedHandler = (changes: Partial<GeovisUserProfileInfo>) => setUserState({
        ...userState,
        data: { ...userState.data, ...changes }
    });

    /**
     * Update password property (needs when create a user)
     * @param propertyName 
     * @param value 
     * @returns 
     */
    const onPasswordPropertyChangedHandler = (propertyName: keyof GeovisUserProfileChangePasswordModel, value: string) => setPasswordInfo({
        ...passwordInfo,
        [propertyName]: value
    })

    /**
     * Add project relation to this user
     * @param projectId 
     * @param role 
     */
    const onProjectRelationChangedHandler = (projectId: number, role: GeovisUserToProjectRole) => {

        userState.data.RelationChanges.push({
            ProjectId: projectId,
            State: RelationChangeState.Changed
        });

        setUserState({
            ...userState,
            data: {
                ...userState.data,
                ProjectRelationInfos: userState.data.ProjectRelationInfos.map(r => {
                    if (r.ProjectId === projectId) {
                        return { ...r, Role: role };
                    }

                    return r;
                }),
                RelationChanges: userState.data.RelationChanges
            }
        })
    }

    /**
     * Add many project relations to user
     * @param projectRelationsInfo
     * @returns 
     */
    const onAddProjectRelationsHandler = (projectRelationsInfo: GeovisUserProjectRelationModel[]) => {

        const relationsChanges: UserToProjectRelationChangeModel[] = projectRelationsInfo.map(r => ({
            ProjectId: r.ProjectId,
            State: RelationChangeState.Added
        }));

        userState.data.RelationChanges.push(...relationsChanges);

        setUserState({
            ...userState,
            data: {
                ...userState.data,
                ProjectRelationInfos: [
                    ...userState.data.ProjectRelationInfos,
                    ...projectRelationsInfo
                ],
                RelationChanges: userState.data.RelationChanges
            }
        });
    }

    /**
     * Delete project relation
     * @param projectId 
     * @returns 
     */
    const onDeleteProjectRelationHandler = (projectId: number) => {

        userState.data.RelationChanges.push({
            ProjectId: projectId,
            State: RelationChangeState.Removed
        });

        setUserState({
            ...userState,
            data: {
                ...userState.data,
                ProjectRelationInfos: userState.data.ProjectRelationInfos.filter(r => r.ProjectId !== projectId),
                RelationChanges: userState.data.RelationChanges
            }
        })
    }

    /**
     * Set user password info at edit the dialog
     * @param pi 
     */
    const onSetUserPassword = async (pi: GeovisUserProfileChangePasswordModel) => {

        setSettingsUserPasswordInProgress(true);
        const url = ServerRoutesGen.Account.SetPassword.patch(userState.data.Id);
        const response = await sendServerPostRequestData<GeovisUserProfileChangePasswordModel, ActionResponse>(Server, url, pi);

        setSettingsUserPasswordInProgress(false);

        if (response.Success) {
            FlagService.addInfo(t("Reset password"), t("Password has been reset"));
            setPasswordInfo({ ConfirmPassword: '', Password: '' });
        }
        else {
            FlagService.addErrors(t("Change user password"), response.Messages);
        }
    }

    return (
        <ModalTransition>
            <Modal
                actions={[
                    { text: getSaveButtonText(), onClick: onOkClick, appearance: 'primary', isLoading: isSaving },
                    { text: t("Close"), onClick: onClose }
                ]}
                heading={getModalDialogHeading(editMode)}
                shouldCloseOnOverlayClick={false}
                autoFocus={false}
                height='100%'
                width="large"
            >
                {userState.isLoading && (
                    <LoadingContainerSkeleton />
                )}

                {userState.isError && (
                    <LoadingPageErrorSkeleton errorDescription={userState.errorDescription} />
                )}
                {!userState.isError && userState.isLoaded && (
                    <UserProfileEditorTabs
                        editMode={editMode}
                        user={userState.data}
                        isSettingUserPasswordInProgress={settingUserPasswordInProgress}
                        onAddProjectRelations={onAddProjectRelationsHandler}
                        onDeleteProjectRelation={onDeleteProjectRelationHandler}
                        onPasswordPropertyChanged={onPasswordPropertyChangedHandler}
                        onProjectRelationChanged={onProjectRelationChangedHandler}
                        onSetUserPassword={onSetUserPassword}
                        onUserPropertyChanged={onUserPropertyChangedHandler}
                        onUserPropertiesChanged={onUserPropertiesChangedHandler}
                    />
                )}
            </Modal>
        </ModalTransition>
    )
}

const ComponentWithServer = withGeovisServer(UserProfileEditorDialog);

export default ComponentWithServer;