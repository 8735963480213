import { Checkbox } from "@atlaskit/checkbox";
import { SyntheticEvent } from "react";
import { AlarmCondition } from "../../../../../server/AVTService/TypeLibrary/Alarming/AlarmCondition";

interface IComponentProps {
    value: boolean;
    condition: AlarmCondition;
    onChange: (value: boolean) => void;
}

export const AgmsActionConditionEnableFlag = ({
    condition,
    onChange,
    value
}: IComponentProps) => {

    const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.checked);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
            <div style={{ width: '100%', backgroundColor: condition.color, textAlign: 'center' }}>
                <span>{condition.severityName}</span>
            </div>
            <div>
                <Checkbox
                    isChecked={value}
                    onChange={onChangeHandler}
                />
            </div>
        </div>
    )
}