/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 17.10.2022
 * @description Geovis password field
 */

import { Field } from '@atlaskit/form';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled';
import Textfield from '@atlaskit/textfield';
import { debounce } from 'lodash';
import React from 'react';
import { useGeovisToggleVisibilityHook } from '../../helpers/Hooks.Toggles';

interface IComponentProps {
    name: string;
    label: string;

    value: string;

    isRequired?: boolean;
    isDisabled?: boolean;

    disableHide?: boolean;

    onFieldChanged: (value: string) => void;
}

export const GeovisPasswordField = ({ label, name, value, isRequired, disableHide, isDisabled, onFieldChanged }: IComponentProps) => {

    const [hide, switchHide] = useGeovisToggleVisibilityHook(true);

    const onChangeMiddleHandler = debounce(onFieldChanged, 500);

    const isInvalid = (): boolean => {
        if (!isRequired) {
            return false;
        }

        return !(!value);
    }

    return (
        <Field name={name} label={label} defaultValue={value} isRequired={true} >
            {() => (
                <Textfield
                    type={!disableHide && hide ? "password" : "input"}
                    disabled={isDisabled}
                    required={isRequired}
                    isInvalid={isInvalid()}
                    onChange={({ currentTarget }) => onChangeMiddleHandler(currentTarget.value)}
                    elemAfterInput={
                        <React.Fragment>
                            {!disableHide && (
                                <div style={{ marginRight: '5px', cursor: 'default' }} onClick={switchHide}>
                                    {hide && (
                                        <WatchFilledIcon label='' size='small' />
                                    )}
                                    {!hide && (
                                        <WatchIcon label='' size='small' />
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    }
                />
            )}
        </Field>
    )
}