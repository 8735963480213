import AddItemIcon from '@atlaskit/icon/glyph/add-item';
import BulletListIcon from '@atlaskit/icon/glyph/bullet-list';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';
import HipchatDialOutIcon from '@atlaskit/icon/glyph/hipchat/dial-out';
import LabelIcon from '@atlaskit/icon/glyph/label';
import WorldIcon from '@atlaskit/icon/glyph/world';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { GlobalItem, GlobalNav } from '@atlaskit/navigation-next';
import Tooltip from '@atlaskit/tooltip';
import { History } from 'history';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import DashboardIcon from 'src/resources/icons/dashboard.png';
import UserIconMenu from '../../components/UserIconMenu';
import IRouteParams from '../../helpers/IRouteParams';
import {
    navigateToProjectInventoryPageFn,
    navigateToProjectSupportPageFn
} from '../../helpers/NavigationHelper';
import Route from '../../helpers/Route';
import Routes from '../../helpers/Routes';
import i18next from '../../i18n';
import { InventoryObjectType } from '../../server/AVTService/TypeLibrary/Inventory/InventoryObjectType';
import AuthService from '../../services/AuthService';
import { switchProjectsOverviewView } from '../../store/creators/navigationCreators';
import { IGeovisProjectDataState } from '../../store/data.types';
import { IGeovisProjectOverviewState } from '../../store/projectOverview.types';
import { IGeovisStoreState } from '../../store/store.types';
import { IGeovisAction, IGeovisProjectsOverviewState, ProjectsViewAsMap, ProjectsViewAsTable } from '../../store/types';
import SwitchUserViewMenu from '../development/SwitchUserViewMenu';
import { EditIcon, HelpIcon, AmbergInfra7DIcon } from '../Icons';
import LanguageSelector from '../LanguageSelector';
import SettingsMenu from '../SettingsMenu';
import { LicensedFeatures } from '../../server/AVTService/TypeLibrary/Licensing/LicensedFeatures';


interface IStateToProps {
    dataState: IGeovisProjectDataState;
    projectOverviewState: IGeovisProjectOverviewState;
    projectsOverviewState: IGeovisProjectsOverviewState;
}

interface IDispatchToProps {
    dispatch: (action: IGeovisAction) => void;
    onSwitchProjectsOverviewView: (view: string) => void;
}

interface IGlobalNavigationProps extends IStateToProps, IDispatchToProps {

}


const GlobalNavigation = ({
    dataState,
    dispatch,
    projectsOverviewState,
    onSwitchProjectsOverviewView
}: IGlobalNavigationProps) => {

    const history = useHistory();

    const primaryItems = [{
        component: () => <GlobalItem
            icon={AmbergInfra7DIcon}
            onClick={goToProjectsOverviewFunc(history)} />,
        id: "logo",
        tooltip: "GEOvis"
    }];

    const { isLoaded } = dataState.projectInfo;
    const projectScopeParams = useRouteMatch<IRouteParams>(new Route({ ...Routes.project, exact: false }));

    const onSwitchViewClickHandler = (view: string) => () => {
        onSwitchProjectsOverviewView(view);
        history.push(Routes.home.path);
    }

    const allowToDisplayStatisticControl = (): boolean => {
        if (AuthService.hasUserTypeAsAdmin()) {
            return true;
        }
        if (!dataState.projectInfo.isLoaded) {
            return false;
        }
        const { project } = dataState.projectInfo;

        if (!project.IsPartOfGEOvis4) {
            return AuthService.isActualAdminOfProject(project.Id);
        }

        return project.IsNewInvoiceForCompanyEnabled && AuthService.isActualAdminOfProject(project.Id);
    }

    // Show as map
    if ((projectScopeParams === null || !projectScopeParams.params.projectId)) {
        primaryItems.push({
            component: () => <Tooltip content={i18next.t("Show projects on map")} position='left'>
                <GlobalItem
                    onClick={onSwitchViewClickHandler(ProjectsViewAsMap)}
                    icon={projectsOnMapIcon}
                    isSelected={isMapProjectsViewSelected(projectsOverviewState)}
                />
            </Tooltip>
            ,
            id: "switchToMap",
            tooltip: i18next.t("Show projects on map"),
        })
    }

    // Show as list
    if ((projectScopeParams === null || !projectScopeParams.params.projectId)) {
        primaryItems.push({
            component: () => <Tooltip content={i18next.t("Show projects as list")} position='left'>
                <GlobalItem
                    onClick={onSwitchViewClickHandler(ProjectsViewAsTable)}
                    icon={projectsAsListIcon}
                    isSelected={isListProjectViewSelected(projectsOverviewState)}
                />
            </Tooltip>
            ,
            id: "switchToList",
            tooltip: i18next.t("Show projects as list"),
        })
    }

    // Create new project item
    if (AuthService.hasUserTypeAsCompanyAdmin() && AuthService.hasUsersCompanyLicenseFor(LicensedFeatures.Manual_projects_creation) && (projectScopeParams === null || !projectScopeParams.params.projectId)) {
        primaryItems.push({
            component: () => <Tooltip content={i18next.t("Create a new project")} position='left'>
                <GlobalItem
                    onClick={goToProjectCreateFunc(history)}
                    icon={projectCreateIcon}
                />
            </Tooltip>
            ,
            id: "navigationCreateProject",
            tooltip: i18next.t("Create a new project"),
        })
    }

    // Open dashboard
    if (AuthService.hasUserTypeAsCompanyAdmin() && AuthService.hasUsersCompanyLicenseFor(LicensedFeatures.Dashboard) && (projectScopeParams === null || !projectScopeParams.params.projectId)) {
        primaryItems.push({
            component: () => <Tooltip content={i18next.t("Dashboard")} position='left'>
                <GlobalItem
                    onClick={goToDashboardFunc(history)}
                    icon={dashboardIcon}
                    isSelected={isDashboardOpened()}
                />
            </Tooltip>
            ,
            id: "navigationDashboard",
            tooltip: i18next.t("Dashboard"),
        })
    }

    if (isLoaded && projectScopeParams) {
        const { params: { projectId } } = projectScopeParams;

        if (projectId && AuthService.isActualAdminOfProject(+projectId)) {
            primaryItems.push({
                component: () => <GlobalItem
                    icon={EditIcon}
                    onClick={goToProjectEditFunc(history, +projectId)}
                    isSelected={isProjectPropertiesSelected(+projectId)}
                />,
                id: "navigationEdit",
                tooltip: i18next.t("Edit"),

            });

            if (allowToDisplayStatisticControl()) {
                primaryItems.push({
                    component: () => <GlobalItem
                        icon={GraphLineIcon}
                        onClick={goToProjectStatisticFunc(history, +projectId)}
                        isSelected={isProjectStatisticSelected(+projectId)}
                    />,
                    id: 'projectStatistic',
                    tooltip: i18next.t("Project statistic")
                });
            }
        }

        if (projectId && AuthService.hasReadPermissions(+projectId)) {

            primaryItems.push({
                component: () => <GlobalItem
                    icon={projectSupportIcon}
                    onClick={navigateToProjectSupportPageFn(history, +projectId)}
                    isSelected={isProjectSupportPageSelected()}
                />,
                id: 'navigationProjectSupportPage',
                tooltip: i18next.t("Project support")
            })

        }
        if (projectId && AuthService.hasUserTypeAsAdmin() && AuthService.isActualAdminOfProject(+projectId)) {

            primaryItems.push({
                component: () => <GlobalItem
                    icon={projectInventoryIcon}
                    onClick={navigateToProjectInventoryPageFn(history, +projectId, InventoryObjectType.MST)}
                    isSelected={isProjectInventoryPageSelected()}
                />,
                id: 'navigationProjectInventoryPage',
                tooltip: i18next.t("Project inventory")
            })

        }
    }

    const secondaryItems = [
        {
            component: () => <SettingsMenu key="navigationSettings" />,
            id: "navigationSettings",
            tooltip: i18next.t("labelSettings"),
        }, {
            component: () => <LanguageSelector key="navigationLanguageSelector" dispatch={dispatch} />,
            id: "navigationLanguageSelector",
            tooltip: i18next.t("Select a language")
        }, {
            component: () => <GlobalItem key="navigationAbout" icon={HelpIcon} size="small" onClick={goToAboutSoftwareFunc(history)} />,
            id: "navigationAbout",
            tooltip: i18next.t("About")
        }
    ];

    /* additional menu to switch visual design depends of user role */
    if (isLoaded && projectScopeParams) {

        const { params: { projectId } } = projectScopeParams;
        if (projectId && isShowSwitchProjectViewDropdown(projectId, dataState)) {

            secondaryItems.push({
                component: () => <SwitchUserViewMenu />,
                id: "navigationSwitchUserViewByRole",
                tooltip: ""
            });
        }
    }

    // user profile icon
    secondaryItems.push({
        component: () => <UserIconMenu />,
        id: "navigationUser",
        tooltip: ""
    });

    return (
        <GlobalNav
            primaryItems={primaryItems}
            secondaryItems={secondaryItems} />
    );
}

const projectSupportIcon = () => (
    <HipchatDialOutIcon label="" size="large" />
)

const projectCreateIcon = () => (
    <AddItemIcon label="" size="medium" />
)

const dashboardIcon = () => (
    <img style={{ width: '24px', height: '24px', paddingTop: '1px' }} src={DashboardIcon} />
)

const projectsOnMapIcon = () => (
    <WorldIcon label="" size="large" />
)

const projectsAsListIcon = () => (
    <BulletListIcon label="" size="large" />
)

const projectInventoryIcon = () => (
    <LabelIcon label="" size="large" />
)

const goToProjectEditFunc = (history: History, projectId: number) => () => {
    const { path } = Routes.projectEdit.patch(projectId.toString());
    history.push(path);
}

const goToProjectCreateFunc = (history: History) => () => {
    const { path } = Routes.projectCreate.patch();
    history.push(path);
}

const goToDashboardFunc = (history: History) => () => {
    const { path } = Routes.dashboard.patch();
    history.push(path);
}


const goToAboutSoftwareFunc = (history: History) => () => {
    history.push(Routes.aboutSoftware.path);
}

const isShowSwitchProjectViewDropdown = (projectId: string | undefined, dataState: IGeovisProjectDataState): boolean => {
    const { isLoading } = dataState.projectInfo;
    return !isLoading && projectId !== undefined && AuthService.isRealAdminOfProject(+projectId);
}

const goToProjectStatisticFunc = (history: History, projectId: number) => () => {
    // if (AuthService.hasUserTypeAsAdmin()) {
    //     history.push(Routes.projectDetailedStatistic.patch(projectId).path);
    // }
    // else {
    //     history.push(Routes.projectQuickStatistic.patch(projectId).path);
    // }
    history.push(Routes.projectStatistic.patch(projectId).path);
}

const isProjectStatisticSelected = (projectId: number): boolean => {
    const params = useRouteMatch([Routes.projectDetailedStatistic.patch(projectId).path, Routes.projectQuickStatistic.patch(projectId).path, Routes.projectStatistic.patch(projectId).path]);
    return params !== null;
}

const isProjectPropertiesSelected = (projectId: number): boolean => {
    const params = useRouteMatch([
        Routes.projectEdit.patch(projectId).path,
        Routes.projectEditNotifications.patch(projectId).path,
        Routes.projectEditElements.patch(projectId, 'All', 'none', 0).path,
        Routes.projectEditElementEditDialog.patch(projectId).path,
        Routes.projectEditElementCreateDialog.patch(projectId).path,
        Routes.projectEditElementCreateChartDialog.patch(projectId).path,
        Routes.projectEditElementCreateChartTemplateDialog.patch(projectId).path,
        Routes.projectEditElementCreateTemplateDialog.patch(projectId).path,
        Routes.projectEditDatabases.patch(projectId).path,
        Routes.projectEditReports.patch(projectId).path,
        Routes.projectEditReport.patch(projectId).path,
        Routes.projectEditReportCreate.patch(projectId).path,
        Routes.projectEditVisualSettings.patch(projectId).path,
        Routes.projectEditAttachedDocuments.patch(projectId).path,
        Routes.projectEditAccessControl.patch(projectId).path,
        Routes.projectEditViews.patch(projectId).path,
        Routes.projectEditAlarms.patch(projectId).path,
        Routes.projectEditDxfLayers.patch(projectId).path,
        Routes.projectEditGeoImageLayers.patch(projectId).path,
        Routes.projectLogbook.patch(projectId).path
    ]);

    return params !== null;
}

const isHome = (): boolean => {
    const params = useRouteMatch(Routes.home);
    return params !== null;
}

const isProjectSupportPageSelected = (): boolean => {
    const params = useRouteMatch(Routes.projectSupport);
    return params !== null;
}

const isProjectInventoryPageSelected = (): boolean => {
    const params = useRouteMatch(Routes.projectInventoryMst)
        || useRouteMatch(Routes.projectInventoryAfm)
        || useRouteMatch(Routes.projectInventoryAtc)
        || useRouteMatch(Routes.projectInventoryLora)
        || useRouteMatch(Routes.projectInventoryTachy)
        || useRouteMatch(Routes.projectInventorySb);
    return params !== null;
}

const goToProjectsOverviewFunc = (history: History) => () => {
    history.push(Routes.home.path);
}

const isMapProjectsViewSelected = (projectsOverviewState: IGeovisProjectsOverviewState): boolean => {
    return isHome() && projectsOverviewState.view === ProjectsViewAsMap;
}

const isListProjectViewSelected = (projectsOverviewState: IGeovisProjectsOverviewState): boolean => {
    return isHome() && projectsOverviewState.view !== ProjectsViewAsMap;
}

const isDashboardOpened = (): boolean => {
    const params = useRouteMatch(Routes.dashboard);
    return params !== null;
}

const mapStateToProps = (state: IGeovisStoreState): IStateToProps => ({
    dataState: state.data,
    projectOverviewState: state.projectOverview,
    projectsOverviewState: state.projectsOverview
})

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDispatchToProps => ({
    dispatch: action => dispatch(action),
    onSwitchProjectsOverviewView: view => dispatch(switchProjectsOverviewView(view))
})

export default connect<IStateToProps, IDispatchToProps>(mapStateToProps, mapDispatchToProps)(GlobalNavigation);