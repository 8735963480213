/**
 * @author Vyacheslav Skripin <vs@iesk.ru>
 * @created 09.11.2020
 * @description Common types for inventory reducer
 */

import { defaultInventoryCalendarFilters } from "../../pages/business/inventory/calendarView/tools";
import {
    IGeovisInventoryAfmStorage,
    IGeovisInventoryAtcStorage,
    IGeovisInventoryCalendarStorage,
    IGeovisInventoryDescriptionsStorage,
    IGeovisInventoryLoRaGatewayStorage,
    IGeovisInventoryMstStorage,
    IGeovisInventoryPropertyHistoryStorage,
    IGeovisInventoryReservationStorage,
    IGeovisInventorySensorboxStorage,
    IGeovisInventoryTachymetersStorage,
    IGeovisInventoryUsagePlanStorage
} from "../inventory.types";
import { defaultSomethingStorageState } from "../types";

export const inventoryMstInitialState: Readonly<IGeovisInventoryMstStorage> = {
    ...defaultSomethingStorageState,
    data: { Records: [], Total: 0 },
    filter: { Limit: 50, IsAscending: true },
    recordId: '',
    projectId: undefined
}

export const inventoryAtcInitialState: Readonly<IGeovisInventoryAtcStorage> = {
    ...defaultSomethingStorageState,
    data: { Records: [], Total: 0 },
    filter: { Limit: 50, IsAscending: true },
    recordId: '',
    projectId: undefined
};

export const inventoryAfmInitialState: Readonly<IGeovisInventoryAfmStorage> = {
    ...defaultSomethingStorageState,
    data: { Records: [], Total: 0 },
    filter: { Limit: 50, IsAscending: true },
    recordId: '',
    projectId: undefined
}

export const inventorySensorboxesInitialState: Readonly<IGeovisInventorySensorboxStorage> = {
    ...defaultSomethingStorageState,
    data: { Records: [], Total: 0 },
    filter: { Limit: 50, IsAscending: true },
    recordId: '',
    projectId: undefined
};

export const inventoryLoRaGatewayInitialState: IGeovisInventoryLoRaGatewayStorage = {
    ...defaultSomethingStorageState,
    data: { Records: [], Total: 0 },
    filter: { Limit: 50, IsAscending: true },
    recordId: '',
    projectId: undefined
}

export const inventoryTachymetersInitialState: Readonly<IGeovisInventoryTachymetersStorage> = {
    ...defaultSomethingStorageState,
    data: { Records: [], Total: 0 },
    filter: { Limit: 50, IsAscending: true },
    recordId: '',
    projectId: undefined
};
export const inventoryDescriptionsInitialState: Readonly<IGeovisInventoryDescriptionsStorage> = {
    ...defaultSomethingStorageState,
    data: { Records: [], Total: 0 },
    filter: null,
    recordId: '',
    displayNodeId: "-",
    expandInfo: new Map<string, boolean>(),
    possibleParents: [],
    projectId: -1
};

export const inventoryUsagePlanInitialState: Readonly<IGeovisInventoryUsagePlanStorage> = {
    ...defaultSomethingStorageState,
    records: [],
    showEditDialog: false,
    showRemoveDialog: false,
    createEntryMode: false,
    // showActionDialog: false,
    recordId: '',
    showConfirmStatusChangeDialog: false,
    showExtendTimeRangeDialog: false
};

export const inventoryCalendarDataInitialState: Readonly<IGeovisInventoryCalendarStorage> = {
    ...defaultSomethingStorageState,
    records: [],
    showEditDialog: false,
    showRemoveDialog: false,
    createEntryMode: false,
    showActionDialog: false,
    recordId: '',
    showConfirmStatusChangeDialog: false,
    showExtendTimeRangeDialog: false,
    filters: defaultInventoryCalendarFilters,
    showOpenPeriod: false,
    showReservationItemRemoveDialog: false
};

export const inventoryReservationInitialState: Readonly<IGeovisInventoryReservationStorage> = {
    ...defaultSomethingStorageState,
    reservations: [],
    createMode: false,
    reservationCrossIntervalsMessage: '',
    reservationId: '',
    showEditDialog: false,
    showRemoveDialog: false,
    showReservationToProjectDialog: false
};

export const inventoryPropertyHistoryInitialState: Readonly<IGeovisInventoryPropertyHistoryStorage> = {
    ...defaultSomethingStorageState,
    records: [],
    historyId: "",
    propertyName: ""
}