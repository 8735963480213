/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 05.08.2021
 * @description Chain last data component, connected to GeovisDataStorage
 */

import Spinner from '@atlaskit/spinner';
import React from 'react';
import { connect } from 'react-redux';
import { dateTimeToReactMoment } from '../../../helpers/DateHelper';
import { toFixedPhysicalUnitValue } from '../../../helpers/SensorHelper';
import { t } from '../../../i18n';
import { getPhysicalUnitShortName, PhysicalUnit } from '../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit';
import { ChainInfo } from '../../../server/ChainInfo';
import { ChainMeasurementInfo } from '../../../server/ChainMeasurementInfo';
import { ChainMovement } from '../../../server/ChainMovement';
import { ChainType } from '../../../server/ChainType';
import { IChainsLastDataStorage } from '../../../store/data.types';
import { IGeovisStoreState } from '../../../store/store.types';
import { drawUnitValues, getChainLastMeasurement } from './tools';
import { LastMeasOf } from './types';

/**
 * Custom chain tooltip or popup content properties
 */
export interface ICustomChainTooltipContentProps {
    /**
     * Custom chains last data storage
     * If it set, then component will use it as data source, if not, then the global storage will be used
     */
    customChainsLastDataStorage?: IChainsLastDataStorage;
}

/**
 * The chain last measurement own props
 * That properties required to initialize and draw the chain last measurement data
 */
interface IChainLastMeasurementOwnProps extends ICustomChainTooltipContentProps {
    chain: ChainInfo;
}

/**
 * The chain last measurement properties with measurement data
 * Used only as private interface, only there in this file
 */
interface IChainLastMeasurementComponentProps extends IChainLastMeasurementOwnProps {
    lastMeasurement: LastMeasOf<ChainMeasurementInfo>
}

/**
 * Chain last measurement content
 * @param props IChainLastMeasurementProps
 */
const ChainLastMeasurementComponent = ({ chain, lastMeasurement }: IChainLastMeasurementComponentProps) => (
    <div key={`chain_${chain.Id}_last_measurement`}>
        {/* if no data yet */}
        {lastMeasurement === undefined && (
            <div>
                <Spinner size="small" />
            </div>
        )}
        <ChainLength chain={chain} lastMeasurement={lastMeasurement} />
        <ChainHighestMovement chain={chain} lastMeasurement={lastMeasurement} />
        {lastMeasurement === false && (
            <span><b>{t("No data")}</b></span>
        )}
    </div>
)


/**
 * Chain highest movement content
 * @param param0 
 */
const ChainHighestMovement = ({ chain, lastMeasurement }: IChainLastMeasurementComponentProps) => (
    <React.Fragment>
        {lastMeasurement && (
            <div>
                <div>{t("Highest movement at last measurement")}:&nbsp;</div>
                <div>{dateTimeToReactMoment(lastMeasurement.TimeSlot)}</div>
                {lastMeasurement.HighestMovementX !== null && <ChainAxisMovement axisName={t("X Axis")} movement={lastMeasurement.HighestMovementX} chain={chain} />}
                {lastMeasurement.HighestMovementY !== null && <ChainAxisMovement axisName={t("Y Axis")} movement={lastMeasurement.HighestMovementY} chain={chain} />}
            </div>
        )}
    </React.Fragment>
)

/**
 * Chain length content for Tooltip and Popup
 * @param props IChainLastMeasurementProps
 */
const ChainLength = ({ chain, lastMeasurement }: IChainLastMeasurementComponentProps) => {
    if (!lastMeasurement || lastMeasurement === null || chain.ChainType !== ChainType.SlopeControl) {
        return null;
    }

    return (
        <div>
            {t("Length")}:&nbsp;{isNaN(lastMeasurement.Length) ? "NaN" : lastMeasurement.Length.toFixed(2)}&nbsp;{t("meterShortLabel")}
        </div>
    );
}

interface IChainAxisMovementProps {
    chain: ChainInfo;
    axisName: string;
    movement: ChainMovement;
}

const ChainAxisMovement = ({ chain, axisName, movement }: IChainAxisMovementProps) => (
    <div>
        {axisName}: {drawUnitValues([movement.Value], chain)} {getPhysicalUnitShortName(chain.Unit)} {t("at")} {toFixedPhysicalUnitValue(chain.Unit, movement.Distance)} {getPhysicalUnitShortName(PhysicalUnit.Meter)}
    </div>
)

//#region Connected component implementation

/**
 * Connected component state props
 */
interface IStateToProps {
    chainsLastDataStorage: IChainsLastDataStorage;
}

/**
 * Connected component props
 */
interface IComponentProps extends IStateToProps, IChainLastMeasurementOwnProps {

}

/**
 * This component is used with connection to GeovisDataStorage
 * It should be changed only if chain data changed
 * @param param0 
 * @returns 
 */
const ChainLastMeasurementData = ({ chainsLastDataStorage, ...rest }: IComponentProps) => {

    const { chain } = rest;
    const lastMeasurement = getChainLastMeasurement(chainsLastDataStorage, chain.Id);

    return (
        <ChainLastMeasurementComponent
            lastMeasurement={lastMeasurement}
            {...rest}
        />
    )
}

const mapStateToProps = ({ data: { chainsLastDataStorage } }: IGeovisStoreState, { customChainsLastDataStorage }: IChainLastMeasurementOwnProps): IStateToProps => ({
    chainsLastDataStorage: customChainsLastDataStorage ?? chainsLastDataStorage
});

const ChainLastMeasurementDataConnected = connect<IStateToProps, never, IChainLastMeasurementOwnProps>(mapStateToProps)(ChainLastMeasurementData);

export default ChainLastMeasurementDataConnected;

//#endregion