
import React, { CSSProperties } from 'react';


interface IPageTitleComponentProps {
    title: string;
    children?: React.ReactNode;
    onClick?: () => void;
    style?: CSSProperties;
}

// {props.children}

const PageTitleComponent = (props: IPageTitleComponentProps) => {

    const baseStyle: React.CSSProperties = props.onClick ? {} : {
        textDecoration: 'none',
        cursor: 'default'
    };

    return (
        <div
            className="pageHeader"
            onClick={props.onClick}
            style={{ ...baseStyle, ...props.style }}>
            {props.children}
        </div>
    );
}

export default function PageTitleItem(props: Omit<IPageTitleComponentProps, 'children'>) {
    return (
        <PageTitleComponent {...props}>
            {/* {<h1 style={{ flex: 1 }}>{props.title}</h1>} */}
            {<span className="projectHeaderFont">{props.title}</span>}
        </PageTitleComponent>
    )
}


export const PageSubTitleItem = (props: Omit<IPageTitleComponentProps, 'children'>) => (
    <PageTitleComponent {...props}>
        {<h3 style={{ flex: 1 }}>{props.title}</h3>}
    </PageTitleComponent>
);