//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ChartAlarmLineType } from '../Common/ChartAlarmLineType';
import { VibrationFrequencyBand } from './VibrationFrequencyBand';

export class AlarmCondition
{
	public Id: string;
	public min: number;
	public minEnabled: boolean;
	public max: number;
	public maxEnabled: boolean;
	public color: string;
	public severityName: string;
	public lineEnabled: boolean;
	public lineWeight: number;
	public lineType: ChartAlarmLineType;
	public fillEnabled: boolean;
	public frequencyBands: VibrationFrequencyBand[];
	public isVibration: boolean;
}
