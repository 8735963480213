import L, { Point } from 'leaflet';
import { MapItemObject, MapSensorObject } from '../../helpers/MapHelper';
import { getAlarmedSensorColors, getAlarmedSensorsColors } from '../../helpers/SensorHelper';
import { SensorMapTextOrientation } from '../../server/AVTService/TypeLibrary/Common/SensorMapTextOrientation';
import { SensorCategory } from '../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { getSensorSymbolToName, SensorSymbol } from '../../server/AVTService/TypeLibrary/Sensors/SensorSymbol';
import {
    getIconScale,
    getMultipleSensorsIconScale,
    getShowSensorName,
    getShowSensorNames,
    ICreateSensorStateIconOptions
} from '../types';

export const MinMapZoomLevel = 0;

// 19 - is max level, where map tiles are visible
// 21 - increased to 21 by AGMS-1188
export const MaxMapTilesZoomLevel = 24;
export const MaxNativeTilesZoomLevel = 23;
export const MaxNativeZoomLevel = 21;

export const MaxMapZoomLevel = 24;
export const MaxLocalMapObjectZoomLevel = 24;


export const DefaultZoomLevel = 17;

export const MaxProfileViewRenderZoomLevel = 6;
export const MaxProfileViewZoomLevel = 10;
export const MaxProfileViewNativeZoomLevel = 6;


// TODO : it is needed to synchronize that color with server
// make sense to generate that constants from server
export const ProjectStateColorGreen = "rgb(0, 176, 80)";
export const ProjectStateColorRed = "rgb(255, 0, 0)";
export const ProjectStateColorBlue = "rgb(0, 112, 192)";
export const ProjectStateColorOrange = "rgb(255, 192, 0)";
export const ProjectStateColorGray = "rgb(127, 127, 127)";
export const ProjectStateColorLightGray = "rgb(211, 211, 211)";
export const ProjectStateColorViolet = "rgb(112, 48, 150)";
export const ProjectStateColorFinished = "rgb(182, 215, 168)";

export const SensorStateColorWarning = "rgb(224, 219, 234)";
export const SensorStateColorAlarm = "rgba(255, 255, 255, .4)";

/**
 * The base SVG icon interface with required elements
 */
export interface IBaseSvgIconProps {
    iconAnchor: Point;          // default = [iconSize.x / 2, iconSize.y]	The point to align over the marker's geographic location
    iconSize: Point;            // default = [32, 48]	                    The size of the icon
    popupAnchor: Point;         // the Popup anchor
}

export interface ISVGIconProps extends IBaseSvgIconProps {
    className: string;         // default = "svg-icon"	        Class prefix to use for icon elements
    color: string;             // default = "rgb(0,102,255)"	Color of the icon's border
    fillColors: string[];      // default = list of "color"	    Color's of the icon's interior
    fillOpacity: number;       // default = 0.4	                Opacity of the icon's interior
    opacity: number;           // default = 1	                Opacity of the icon's border
    weight: number;            // default = 2	                The width of the icon's border
    iconScale: number;         // default = 1

    fontColor: string;         // default = "rgb(0,0,0)"	    Color of the icon's center text
    fontOpacity: number;       // default = 1	                Opacity of the icon's center text
    fontSize: number;          // default = iconSize.x / 4	    Font size in pixels of the icon's center text
    fontWeight: string;        // default = "normal"	        The CSS font - weight of the icon's center text

    iconText: string | false;  // The text to draw near with pointer, if false, then no draw
    textOrientation: SensorMapTextOrientation | false;
    noScale: boolean;          // Don't scale the icon, event if functional alarm
    transformTo?: Point;        // Transform the icon to destination coordinates additionally. If noSvgWrap, then apply to the figure
    stackedIconMode?: boolean;  // Stacked sensor icon mode (do not draw the root tag "svg")
    key?: string;               // The unique key of the icon. Normally sensorId

    countLinesUnder?: number;          // count of lines under current line in case of many lines of text
    backgroundColor?: string;             // color of background default is lightyellow
    showBorder?: boolean;           // show border on icon text
    sensorSymbol?: SensorSymbol;
    iconCustomSize?: Point;
    topAddition?: number;
    leftAddition?: number;
}

export interface IProjectsOverviewIconMapIconProps {
    circle: ISVGIconProps,
    flags: ISVGIconProps[],
    className: string;
    iconSize: Point;        // Project state icon size, default 48x32
    iconAnchor: Point;      // Project state icon anchor
}

/**
 * Get fill color or default of colors
 * @param colors 
 */
export const getFillColor = (colors: string[]): string => {
    if (colors && colors.length > 0) {
        return colors[0];
    }

    return defaultLeafletMapIconProps.fillColors[0];
}

export const mergeSensorIconProps = <T extends MapItemObject>(defaultProps: ISVGIconProps, sensor: T, options?: ICreateSensorStateIconOptions): ISVGIconProps => ({
    ...defaultProps,
    ...options,
    key: sensor.Id,
    iconText: getShowSensorName(sensor, options),
    textOrientation: options && options.mandatoryOrientation ? options.mandatoryOrientation : sensor.MapTextOrientation,
    fillColors: options && options.useSensorColor ? [sensor.Color] : getAlarmedSensorColors(sensor),
    iconScale: getIconScale(sensor, defaultProps, options),
    fontColor: options && options.useSensorColorForText ? sensor.Color : "rgb(0,0,0)",
    color: options && options.useSensorColor ? sensor.Color : "rgb(0,0,0)",
    backgroundColor: options ? options.backgroundColor : undefined,
    fontWeight: options && options.fontWeight ? options.fontWeight : 'normal'
});

export const mergeTextIconProps = (defaultProps: ISVGIconProps, id: string, orientation: SensorMapTextOrientation, color: string, text: string, options?: ICreateSensorStateIconOptions): ISVGIconProps => ({
    ...defaultProps,
    ...options,
    key: id,
    iconText: text,
    textOrientation: options && options.mandatoryOrientation ? options.mandatoryOrientation : orientation,
    fontColor: options && options.useSensorColorForText ? color : "rgb(0,0,0)",
    color: options && options.useSensorColor ? color : "rgb(0,0,0)",
    countLinesUnder: options && options.countLinesUnder ? options.countLinesUnder : 0,
    showBorder: options && options.showBorder !== undefined ? options.showBorder : false
});

// const addLinesToSingleDiv = (textLines: string[]): string => {
//     let text = "<div>";
//     textLines.forEach(tl => {
//         text += `<span>${tl}</span>`;
//     });
//     text += "</div>";

//     return text;
// }

/**
 * Merge sensors props with default icon properties
 * @param defaultProps 
 * @param points 
 * @param options 
 */
export const mergeSensorsIconProps = <TPoint extends MapItemObject>(defaultProps: ISVGIconProps, points: TPoint[], options?: ICreateSensorStateIconOptions): ISVGIconProps => ({
    ...defaultProps,
    ...options,
    key: points.map<string>(p => p.Id).join('_'),
    iconText: getShowSensorNames(points, options),
    fillColors: options && options.useSensorColor ? [...points.map(p => p.Color)] : getAlarmedSensorsColors(points),
    iconScale: getMultipleSensorsIconScale(points, defaultProps, options)
});

/**
 * Create sensor icon props
 * @param point 
 * @param options 
 */
export const createSensorIconProps = <T extends MapSensorObject>(point: T, options?: ICreateSensorStateIconOptions): ISVGIconProps => {
    const defaultProps = getDefaultSensorIconProps(point.PhysicalType);
    return mergeSensorIconProps(defaultProps, point, options);
}

export const createTextIconProps = (id: string, orientation: SensorMapTextOrientation, color: string, text: string, xSize: number, ySize: number, xAnchor: number, yAnchor: number, options?: ICreateSensorStateIconOptions): ISVGIconProps => {
    const props = mergeTextIconProps(textIconProps, id, orientation, color, text, options);

    if (options && options.movementVectorMode) {
        props.iconCustomSize = new L.Point(xSize, ySize);
        props.iconSize = new L.Point(0, 0);
        props.iconAnchor = new L.Point(xAnchor, yAnchor);
    }

    return props;
}


export const createSensorSymbolIconProps = (fillColors: string[], symbol: SensorSymbol): ISVGIconProps => {
    return {
        ...getDefaultSensorSymbolIconProps(symbol),
        fillColors,
        key: getSensorSymbolToName(symbol),
        iconScale: 2,
        iconSize: new L.Point(16, 16)
    }
}

/**
 * Create default sensor icon props
 * @param category sensor category (PhysicalType)
 */
export const getDefaultSensorIconProps = (category: SensorCategory): ISVGIconProps => {

    switch (category) {
        case SensorCategory.Unknown: return defaultUnknownIconProps;
        case SensorCategory.Prism: return defaultPrismIconProps;

        case SensorCategory.Theodolite:
        case SensorCategory.Leveling:
        case SensorCategory.Orientation:
            return defaultTheodoliteSensorIconProps;

        // case SensorCategory.Crackmeter: return defaultCrack
        case SensorCategory.Extensometer: return defaultExtensometerSensorIconProps;
        case SensorCategory.BH_Inclinometer: return defaultInclinometerSensorIconProps;
        case SensorCategory.Inclinometer: return defaultInclinometerSensorIconProps;
        case SensorCategory.Temperature: return defaultTemperatureSensorIconProps;
        case SensorCategory.Piezometer: return defaultPiezometerSensorIconProps;
        case SensorCategory.Air_Pressure: return defaultAirPressureSensorIconProps;
        case SensorCategory.Wind_Speed: return defaultWindSpeedSensorIconProps;
        case SensorCategory.Solar_Radiation: return defaultSolarRadiationIconProps;
        case SensorCategory.Distance: return defaultDistanceSensorIconProps;
        case SensorCategory.Load_Cell: return defaultLoadCellSensorIconProps;
        case SensorCategory.Voltage: return defaultVoltageIconProps;

        case SensorCategory.VirtualSensor:
        case SensorCategory.AgmsVirtualSensor:
            return defaultVirtualSensorIconProps;

        case SensorCategory.VibrationSensor: return defaultVibrationSensorIconProps;
        case SensorCategory.InclinometerChainPosition: return defaultInclinometerChainPositionIconProps;
        case SensorCategory.HydrostaticLevel: return defaultHydrostaticLevelSensorIconProps;
        case SensorCategory.CurrentLoop: return defaultCurrentLoopIconProps;
        case SensorCategory.Counter: return defaultCounterSensorIconProps;
        case SensorCategory.Noise: return defaultNoiseIconProps;
        case SensorCategory.V: return defaultVSensorIconProps;
        case SensorCategory.PfV: return defaultPfVSensorIconProps;
        case SensorCategory.PfH: return defaultPfHSensorIconProps;
        case SensorCategory.GH: return defaultGHSensorIconProps;
        case SensorCategory.Settlement: return defaultSettlementSensorIconProps;
        case SensorCategory.GPS: return defaultGPSSensorIconProps;
        case SensorCategory.Rainfall: return defaultRainmeterSensorIconProps;
        case SensorCategory.Flow: return defaultFlowSensorIconProps;
        case SensorCategory.Volume: return defaultVolumeSensorIconProps;
        case SensorCategory.pH: return defaultpHSensorIconProps;
        case SensorCategory.Turbidity: return defaultPiezometerSensorIconProps;
        case SensorCategory.Crackmeter: return defaultDistanceSensorIconProps;
        case SensorCategory.GasConcentration: return defaultOxygenLevelSensorIconProps;
        case SensorCategory.StrainGauge: return defaultDistanceSensorIconProps;
        case SensorCategory.MST: return defaultMstSensorIconProps;
        case SensorCategory.SB: return defaultSbSensorIconProps;
        case SensorCategory.Gauge: return defaultDistanceSensorIconProps;
    }

    return defaultPrismIconProps;
}

export const getDefaultSensorSymbolIconProps = (symbol: SensorSymbol): ISVGIconProps => {
    switch (symbol) {
        case SensorSymbol.Plus:
            return {
                ...defaultSensorSymbolIconProps,
                popupAnchor: L.point(-3, -6),
                transformTo: L.point(2, 2)
            }

        case SensorSymbol.TriangleUp:
            return {
                ...defaultSensorSymbolIconProps,
                popupAnchor: L.point(-3, -12),
                transformTo: L.point(2, 0.5)
            }

        case SensorSymbol.TriangleDown:
            return {
                ...defaultSensorSymbolIconProps,
                popupAnchor: L.point(-3, -12),
                transformTo: L.point(2, 3)
            }

        case SensorSymbol.TriangleRight:
            return {
                ...defaultSensorSymbolIconProps,
                transformTo: L.point(4, 2)
            }

        case SensorSymbol.TriangleLeft:
            return {
                ...defaultSensorSymbolIconProps,
                transformTo: L.point(0.5, 2)
            }

        case SensorSymbol.Romb:
            return {
                ...defaultSensorSymbolIconProps,
                transformTo: L.point(2, 2)
            }

        case SensorSymbol.Star:
            return {
                ...defaultSensorSymbolIconProps,
                transformTo: L.point(0.2, -0.5)
            }

        case SensorSymbol.Circle:
            return {
                ...defaultSensorSymbolIconProps,
                transformTo: L.point(0, 0)
            }

        default:
            return defaultSensorSymbolIconProps;
    }

}


/**
 * Create near geopoints icon props
 * @param geopoints 
 * @param options 
 */

export const createMultipleSensorsIconProps = <TPoint extends MapSensorObject>(points: TPoint[], options?: ICreateSensorStateIconOptions): ISVGIconProps => ({
    ...defaultMultiplePointsIconProps,
    ...options,
    iconText: points.length.toString(),
    fillColors: options && options.useSensorColor ? [...points.map(p => p.Color)] : getAlarmedSensorsColors(points)
});

/**
 * Default leaflet map icon properties
 * Size: 32x32
 * 
 */
export const defaultLeafletMapIconProps: ISVGIconProps = ({
    className: 'default-leaflet-map-icon-svg',
    color: 'black',
    fillColors: ['orange'],
    fillOpacity: 1,
    iconAnchor: L.point(8, 8),
    iconSize: L.point(16, 16),
    opacity: 1,
    popupAnchor: L.point(0, -8),
    weight: 0.5,
    iconScale: 1,
    fontColor: 'black',
    fontOpacity: 1,
    fontSize: 10,
    fontWeight: 'normal',
    iconText: false,
    textOrientation: SensorMapTextOrientation.East,
    noScale: false,
    stackedIconMode: false
});

/**
 * Project view alarm flag
 * Used in project view page, represent alarms for one project view
 */
/** @deprecated */
export const defaultProjectViewAlarmFlagIconProps: ISVGIconProps = ({
    className: 'project-view-alarm-icon-svg',
    color: 'black',
    fillColors: ['transparent'],
    fillOpacity: 1,
    iconAnchor: L.point(8, 8),
    iconSize: L.point(16, 16),
    opacity: 1,
    popupAnchor: L.point(8, -16),
    weight: 0.5,
    iconScale: 1,
    fontColor: 'black',
    fontOpacity: 1,
    fontSize: 8,
    fontWeight: 'normal',
    iconText: false,
    textOrientation: SensorMapTextOrientation.East,
    noScale: false,
    stackedIconMode: false
});

export const defaultProjectOverviewAlarmFlagsIconProps: ISVGIconProps = ({
    ...defaultProjectViewAlarmFlagIconProps,
    className: 'geovis-view-alarms-icon-svg',
    iconSize: L.point(17, 30),
    iconAnchor: L.point(0, 1)
})

// export const getDefaultProjectOverviewGreenSvgIconProps = (projectName: string | false): IProjectsOverviewIconMapIconProps => ({
//     className: 'projects-overview-project-icon-svg',
//     circle: {
//         ...defaultLeafletMapIconProps,
//         iconAnchor: L.point(6.5, 6.5),
//         iconSize: L.point(13, 13),
//         className: 'projects-overview-circle-svg',
//         fontSize: 14,
//         fillColors: [ProjectStateColorGreen],
//         iconText: projectName || false
//     },
//     flags: [],
//     iconAnchor: L.point(6.5, 6.5),
//     iconSize: L.point(13, 13)
// });

/**
 * Page: ProjectsOverview
 * Project icon on the map(default properties)
 */
export const defaultProjectsOverviewSvgIconProps: IProjectsOverviewIconMapIconProps = ({
    className: 'projects-overview-project-icon-svg',
    circle: {
        ...defaultLeafletMapIconProps,
        iconAnchor: L.point(16, 36),
        iconSize: L.point(13, 13),
        className: 'projects-overview-circle-svg',
        fontSize: 14
    },
    flags: [],
    iconSize: L.point(32, 48),
    iconAnchor: L.point(18, 37)
});

export const defaultProjectSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'default-project-sensor-icon-svg',
    weight: 0.5,
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5),
    popupAnchor: L.point(0, -13),
    fontSize: 12
});

export const defaultTheodoliteSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-theodolite-icon-svg",
    iconAnchor: L.point(12, 12),
    iconSize: L.point(24, 24),
    popupAnchor: L.point(0, -12),
    fillColors: ['gray'],
    weight: 0.75
});

export const defaultSettlementSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-settlement-icon-svg",
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6, 6)
});

export const defaultGHSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-gh-icon-svg"
});

export const defaultPfVSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-pfv-icon-svg"
});

export const defaultPfHSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-pfh-icon-svg",
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5),
    popupAnchor: L.point(0, -7)
});

export const defaultGPSSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-gps-icon-svg"
});

export const defaultRainmeterSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-rainmeter-icon-svg"
});

export const defaultFlowSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-flow-icon-svg"
});

export const defaultExtensometerSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-extensometer-icon-svg",
    weight: 0.3
});

export const defaultHydrostaticLevelSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-hydrostatic-level-icon-svg",
});

export const defaultPiezometerSensorIconProps: ISVGIconProps = ({
    ...defaultProjectSensorIconProps,
    className: "geovis-piezometer-icon-svg"
});

export const defaultTemperatureSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: "geovis-temperature-icon-svg",
    weight: 0.5,
    iconSize: L.point(12, 12),
    iconAnchor: L.point(6, 6),
    iconScale: 1.5
});

export const defaultVibrationSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: "geovis-vibration-icon-svg",
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6, 6),
    iconScale: 1.5
});

export const defaultVolumeSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: "geovis-volume-icon-svg",
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5),
    popupAnchor: L.point(0, -7)
});

export const defaultDistanceSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: "geovis-distance-icon-svg",
    iconSize: L.point(26, 14),
    iconAnchor: L.point(12, 7),
    popupAnchor: L.point(0, -7)
});

export const defaultAirPressureSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: "geovis-air-pressure-icon-svg",
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5),
    weight: 0.5
});

export const defaultInclinometerSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: "geovis-inclinometer-icon-svg",
    weight: 0.5,
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5)
});

export const defaultWindSpeedSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: "geovis-windspeed-icon-svg",
    iconSize: L.point(20, 14),
    iconAnchor: L.point(10, 7),
    popupAnchor: L.point(0, -7),
    weight: 0.5
});

export const defaultLoadCellSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: "geovis-loadcell-icon-avg",
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5)
});

export const defaultVirtualSensorIconProps: ISVGIconProps = ({
    ...defaultWindSpeedSensorIconProps,
    className: "geovis-virtualsensor-icon-svg"
});

export const defaultCounterSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: "geovis-counter-icon-svg",
    iconSize: L.point(19, 9),
    iconAnchor: L.point(9.5, 9),
    popupAnchor: L.point(0, -4)
});

export const defaultVSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-v-icon-svg',
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5),
    popupAnchor: L.point(0, -7)
});

export const defaultSolarRadiationIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-solar-radiation-icon-svg',
    iconAnchor: L.point(9, 9),
    iconSize: L.point(18, 18),
    popupAnchor: L.point(0, -9)
});

export const defaultVoltageIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-voltage-icon-svg',
    iconAnchor: L.point(8.5, 8.5),
    iconSize: L.point(17, 17),
    popupAnchor: L.point(0, -9)
});

export const defaultInclinometerChainPositionIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-inclinometer-chain-position-icon-svg',
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5)
});

export const defaultCurrentLoopIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-current-loop-svg',
    iconAnchor: L.point(8.5, 6.5),
    iconSize: L.point(17, 13),
    popupAnchor: L.point(0, -6)
});

/**
 * Prism icon default properties
 */
export const defaultPrismIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-prism-icon-svg',
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5),
    popupAnchor: L.point(0, -8)
});

export const defaultUnknownIconProps: ISVGIconProps = ({
    // ...defaultLeafletMapIconProps,
    ...defaultPrismIconProps,
    className: 'geovis-unknown-icon-svg',
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5)
});

export const textIconProps: ISVGIconProps = ({
    // ...defaultLeafletMapIconProps,
    ...defaultPrismIconProps,
    className: 'geovis-text-icon-svg',
    iconSize: L.point(13, 13),
    iconAnchor: L.point(6.5, 6.5)
});

export const defaultNoiseIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-noise-icon-svg',
    iconSize: L.point(12, 12),
    iconAnchor: L.point(6, 6),
    iconScale: 1.5
})
export const defaultpHSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    iconSize: L.point(18, 24),
    iconAnchor: L.point(9, 12),
    className: 'geovis-ph-icon-svg',
})

/**
 * The default properties for icon of Near sensors group
 */
export const defaultMultiplePointsIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-near-geopoints-icon-svg',
    iconAnchor: L.point(11.5, 11.5),
    iconSize: L.point(24, 24),
    popupAnchor: L.point(0, -11.5),
    fillColors: ['white'],
    fontSize: 9
});

export const defaultOxygenLevelSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-oxygen-icon-svg',
    iconSize: L.point(12, 12),
    iconAnchor: L.point(6, 6),
    iconScale: 1.5
    // popupAnchor: L.point(0, -8)
});

export const defaultSensorSymbolIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-sensor-symbol-svg',
    transformTo: L.point(0.5, 0.5)
});
export const defaultMstSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-mst-icon-svg',
    iconSize: L.point(18, 19),
    iconScale: 1
});

export const defaultSbSensorIconProps: ISVGIconProps = ({
    ...defaultLeafletMapIconProps,
    className: 'geovis-sb-icon-svg',
    iconSize: L.point(14, 16),
    iconScale: 1
});