/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 23.05.2022
 * @description Tool methods to load data of Geovis charts
 */

import { fetchServerElementsByPost, fetchServerElementsByPostCancellable, fetchServerElementsCancellable } from "../../../../../../helpers/ProjectDataHelper";
import { GeovisChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartData";
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { DataActionResponse } from "../../../../../../server/DataActionResponse";
import { VibrationEventInfoRequestModel } from "../../../../../../server/GEOvis3/Model/GeovisChart/VibrationEventInfoRequestModel";
import ServerRoutesGen from "../../../../../../server/Routes/ServerRoutesGen";
import { IRequestHelper } from "../../../../../../services/RequestHelper";
import { IGeovisChartReportInfo } from "../../../../../../store/projectReport.types";
import { IReportElementRenderOwnProps } from "../../types";

interface IGetGeovisChartDataProps<TChart extends GeovisChartModel> extends Omit<IGeovisChartReportInfo<TChart>, 'Timestamp'>, Omit<IReportElementRenderOwnProps, 'isPrinting'> {

}

/**
 * Load geovis chart data from the server
 * @param server - the server to request data
 * @param props - properties of the method
 */
export const getGeovisChartDataAsync = async<TChart extends GeovisChartModel, TChartData extends GeovisChartData>(server: IRequestHelper, {
    Chart,
    IsDirty,
    reportId,
    userId,
    userToken,
    isDefault,
    eventInfo,
    isVibrationEventChart,
    pageNum
}: IGetGeovisChartDataProps<TChart>): Promise<DataActionResponse<TChartData>> => {

    // this is for PDF rendering
    if (userId && userToken) {

        if (isVibrationEventChart && eventInfo) {
            const url = ServerRoutesGen.ReportPdfRenderData.GetGeovisVibrationEventData.patch(Chart.ProjectId, userToken, userId);
            const eventInfoRequest: VibrationEventInfoRequestModel = { ChartId: Chart.Id, EventId: eventInfo.eventId, FullId: eventInfo.fullId };
            const response = await fetchServerElementsByPostCancellable<TChartData, VibrationEventInfoRequestModel>(server, url, getRequestId(Chart.Id, reportId, pageNum), eventInfoRequest);

            return response;
        }

        const url = ServerRoutesGen.ReportPdfRenderData.GetGeovisChartData.patch(Chart.ProjectId, reportId, userToken, userId);
        const response = await fetchServerElementsByPost<TChartData, TChart>(server, url, Chart);
        // const response = await fetchServerElementsByPostCancellable<TChartData, TChart>(server, url, getRequestId(Chart.Id, reportId, pageNum), Chart);

        return response;
    }

    if (isVibrationEventChart && eventInfo) {
        const url = ServerRoutesGen.ReportRenderData.GetGeovisVibrationEventData.patch(Chart.ProjectId);
        const eventInfoRequest: VibrationEventInfoRequestModel = { ChartId: Chart.Id, EventId: eventInfo.eventId, FullId: eventInfo.fullId };
        const response = await fetchServerElementsByPostCancellable<TChartData, VibrationEventInfoRequestModel>(server, url, getRequestId(Chart.Id, reportId, pageNum), eventInfoRequest);

        return response;
    }


    if (IsDirty || isDefault) {
        const url = ServerRoutesGen.ReportRenderData.GetGeovisChartCustomData.patch(Chart.ProjectId, reportId);
        const response = await fetchServerElementsByPostCancellable<TChartData, TChart>(server, url, getRequestId(Chart.Id, reportId, pageNum), Chart);

        return response;
    }

    // just get the chart data
    {
        const url = ServerRoutesGen.ReportRenderData.GetGeovisChartData.patch(Chart.ProjectId, reportId, Chart.Id);
        const response = await fetchServerElementsCancellable<TChartData>(server, url, getRequestId(Chart.Id, reportId, pageNum));

        return response;
    }
}

const getRequestId = (chartId: number, reportId: number, pageNum: number): string => {
    return `GET_DATA_FOR_REP_${reportId}_PAGE_${pageNum}_CHART_${chartId}`;
}