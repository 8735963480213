/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 23.12.2019
 * @description Representation the SVG map icon as HTML element
 */

import { SensorCategory } from '../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { Geovis4SensorSymbolSettings } from '../server/AVTService/TypeLibrary/Sensors/SymbolSettings/Geovis4SensorSymbolSettings';
import { SensorInfo } from '../server/GEOvis3/Model/SensorInfo';
import { createSensorStateIcon } from './map/IconFactory';

interface ISensorIconHtmlProps {
    sensorInfo: SensorInfo;
    className: string;
    iconSettings?: Map<SensorCategory, Geovis4SensorSymbolSettings>
}

export const SensorIconHtml = ({
    sensorInfo,
    className,
    iconSettings
}: ISensorIconHtmlProps) => {

    const sensorIcon = createSensorStateIcon(sensorInfo, { noScale: true, replaceIconMap: iconSettings });
    const svg = sensorIcon._createSVG();

    return (
        <span className={className} dangerouslySetInnerHTML={{ __html: svg }}>
            {/*  */}
        </span>
    );
};

