/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 21.04.2022
 * @description One axis settings control
 */

import { debounce } from 'lodash';
import { t } from '../../../../i18n';
import { getSensorValueAttributeByName, getSensorValueAttributeToName, SensorValueAttribute } from '../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute';
import { AxisScaleLimit } from '../../../../server/AVTService/TypeLibrary/Model/AxisScaleLimit';
import { ChartSettingUint } from '../../../../server/AVTService/TypeLibrary/Model/ChartSettingUint';
import { GeovisChartAxisSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings';
import { HeatmapChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel';
import { getPhysicalUnitShortName, PhysicalUnit, PhysicalUnitSupported } from '../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit';
import AuthService from '../../../../services/AuthService';
import { GeovisSelect } from '../../../select/GeovisSelect';
import { getIGvOptionTypeList, IGvOptionType } from '../../../select/GeovisSelect_tools';
import AxisScaleLimitControl from '../AxisScaleLimitControl';
import { ChartBooleanSettingProps, ChartSettingCheckbox, ChartTextSetting, CustomerChangeableChartBooleanSetting } from '../ChartTab_tools';
import { onChangeTextPropertyDebounced } from '../tools';
import { AxisSettingsPropertyName, defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IGeovisChartAxisPropertyProps } from '../types';

export type ChartHeatmapAxisType = "YAxis" | "XAxis";

interface IChartHeatmapAxisControlProps<TChart extends HeatmapChartModel> extends IGeovisChartAxisPropertyProps<HeatmapChartModel> {
    axisPropertyName: keyof TChart;
    chartAxisSettings: GeovisChartAxisSettings;
    axisType: ChartHeatmapAxisType;
}

export const ChartHeatmapAxisControl = ({
    axisType,
    chartAxisSettings,
    axisPropertyName,
    onChangeAxisSettings
}: IChartHeatmapAxisControlProps<HeatmapChartModel>) => {

    const settingsRowStyle = defaultChartRowSettingsStyle;
    const labelColumnStyle = defaultChartLabelColumnStyle;

    const sensorValueOptions = getPossibleSensorAttributes();
    const sensorValue = getSensorValueAttributeByName(chartAxisSettings.sensorValue);
    const selectedSensorValueOption = sensorValueOptions.find(v => v.value === sensorValue);

    const sensorUnitOptions = getIGvOptionTypeList(getPossibleSensorUnits(selectedSensorValueOption), getPhysicalUnitShortName);
    const selectedUnitOption = sensorUnitOptions.find(v => v.value === chartAxisSettings.Unit);

    const onChartAxisPropertyChangedHandler = (propertyName: keyof GeovisChartAxisSettings, value: any) => {
        const update = {};
        update[propertyName] = value;

        onChangeAxisSettings(axisPropertyName, { ...chartAxisSettings, ...update });
    }

    const onSensorValueChanged = (option: IGvOptionType<SensorValueAttribute>) => {

        const destUnits = getPossibleSensorUnits(option);
        onChangeAxisSettings(axisPropertyName, {
            ...chartAxisSettings,
            sensorValue: getSensorValueAttributeToName(option.value),
            Unit: destUnits.length > 0 ? destUnits[0] : chartAxisSettings.Unit
        });
    }

    const onTextPropertyChanged = (propertyName: keyof GeovisChartAxisSettings) => debounce((v1: string) => {

        onChangeTextPropertyDebounced(v1, (v2) => {
            onChartAxisPropertyChangedHandler(propertyName, v2);
        });

    })

    const onSensorUnitChanged = (option: IGvOptionType<PhysicalUnit>) => {
        onChartAxisPropertyChangedHandler("Unit", option.value);
    }

    const onChangeScaleLimit = (axisSettingPropertyName: keyof GeovisChartAxisSettings) => (scaleLimit: AxisScaleLimit) => {
        onChartAxisPropertyChangedHandler(axisSettingPropertyName, scaleLimit);
    }

    const onChangeAxisNumberOfDigits = (value: string) => {
        const update: ChartSettingUint = { ...chartAxisSettings.numberOfDigits, value: +value }
        onChartAxisPropertyChangedHandler("numberOfDigits", update);
    }

    const onChangeShowAxisLabels = (value: boolean) => {
        onChartAxisPropertyChangedHandler("showAxisLabels", { ...chartAxisSettings.showAxisLabels, value });
    }

    const onChangeBooleanProperty = (propertyName: keyof GeovisChartAxisSettings) => (value: boolean) => {
        onChartAxisPropertyChangedHandler(propertyName, value);
    }

    const onAxisPropertyChangedFunc = <TValue extends any>(propertyName: keyof GeovisChartAxisSettings) => (value: TValue) => {
        onChartAxisPropertyChangedHandler(propertyName, value);
    }

    return (
        <div>
            {/* value */}
            <div className='flexRowContainer' style={settingsRowStyle}>
                <div className='flexCellContainer' style={labelColumnStyle}>
                    <label htmlFor='cmdAttribute'>{t("Value")}</label>
                </div>
                <div className='flexCellContainer_g1'>
                    <GeovisSelect
                        id='cmdAttribute'
                        options={sensorValueOptions}
                        value={selectedSensorValueOption}
                        onChange={onSensorValueChanged}
                    />
                </div>
            </div>

            {/* unit */}
            <div className='flexRowContainer' style={settingsRowStyle}>
                <div className='flexCellContainer' style={labelColumnStyle}>
                    <label htmlFor='cmdUnit'>{t("Unit")}</label>
                </div>
                <div className='flexCellContainer_g1'>
                    <GeovisSelect
                        id='cmbUnit'
                        options={sensorUnitOptions}
                        value={selectedUnitOption}
                        onChange={onSensorUnitChanged}
                        isDisabled={sensorUnitOptions.length < 2}
                    />
                </div>
            </div>

            <AxisScaleLimitControl
                scaleLimit={chartAxisSettings.minScaleLimit}
                tbLabel={t("Min value")}
                hideAuto={false}
                hideExact={true}
                onChange={onChangeScaleLimit(AxisSettingsPropertyName("minScaleLimit"))} />

            <AxisScaleLimitControl
                scaleLimit={chartAxisSettings.maxScaleLimit}
                tbLabel={t("Max value")}
                hideAuto={false}
                hideExact={true}
                onChange={onChangeScaleLimit(AxisSettingsPropertyName("maxScaleLimit"))} />

            <ChartTextSetting
                labelColumnStyle={{ width: '50%' }}
                labelText={t("Axis number of digits")}
                settingsStyle={settingsRowStyle}
                value={chartAxisSettings.numberOfDigits.value}
                type="number"
                htmlName="tbAxisNumberOfDigits"
                onChangeTextField={onChangeAxisNumberOfDigits} />


            <ChartBooleanSettingProps
                label={t("Show axis labels")}
                isChecked={chartAxisSettings.showAxisLabels.value}
                onChange={onChangeShowAxisLabels}
                isDisabled={false}
            />

            <ChartSettingCheckbox
                label={t("Show unit in labels")}
                isChecked={chartAxisSettings.showAxisLabelsUnit}
                onChange={onChangeBooleanProperty(AxisSettingsPropertyName("showAxisLabelsUnit"))}
                isDisabled={false}
            />

            <ChartTextSetting
                labelText={t("Axis label")}
                htmlName='tbAxisLabel'
                onChangeTextField={onTextPropertyChanged("axisXLabel")}
                value={chartAxisSettings.axisXLabel}
            />

            {axisType === 'XAxis' &&
                <CustomerChangeableChartBooleanSetting
                    isDisabled={false}
                    onChange={onAxisPropertyChangedFunc("ReverseAxis")}
                    setting={chartAxisSettings.ReverseAxis}
                    settingLabel={t("Ascending from right to left instead from left to right")}
                    alignToSecondRow={false}
                />
            }
        </div>
    )
}

const getPossibleSensorAttributes = (): ReadonlyArray<IGvOptionType<SensorValueAttribute>> => {
    const result: Array<IGvOptionType<SensorValueAttribute>> = [];

    if (AuthService.isNagraDistribution()) {

        result.push({ value: SensorValueAttribute.LocalCoordTunnelmeter, label: t("TM") });
        result.push({ value: SensorValueAttribute.LocalCoordAngle, label: t("Angle") });
    }

    result.push({ value: SensorValueAttribute.Km, label: t("Km") });
    result.push({ value: SensorValueAttribute.LocationNorth, label: t("Location North") });
    result.push({ value: SensorValueAttribute.LocationEast, label: t("Location East") });
    result.push({ value: SensorValueAttribute.LocationHeight, label: t("Location Height") });

    result.push({ value: SensorValueAttribute.Param1, label: t("Param 1") });
    result.push({ value: SensorValueAttribute.Param2, label: t("Param 2") });
    result.push({ value: SensorValueAttribute.Param3, label: t("Param 3") });
    result.push({ value: SensorValueAttribute.Param4, label: t("Param 4") });
    result.push({ value: SensorValueAttribute.Param5, label: t("Param 5") });

    return result;
}

const getPossibleSensorUnits = (attrOption: IGvOptionType<SensorValueAttribute> | undefined): ReadonlyArray<PhysicalUnit> => {

    if (attrOption) {
        switch (attrOption.value) {
            case SensorValueAttribute.LocalCoordRadius:
            case SensorValueAttribute.LocalCoordTunnelmeter: return [PhysicalUnit.Meter];
            case SensorValueAttribute.LocalCoordAngle: return [PhysicalUnit.Degree];
            case SensorValueAttribute.Km: return [PhysicalUnit.Meter, PhysicalUnit.Kilometer];

            case SensorValueAttribute.LocationNorth:
            case SensorValueAttribute.LocationEast: return [PhysicalUnit.Meter, PhysicalUnit.Degree];

            case SensorValueAttribute.LocationHeight: return [PhysicalUnit.Meter];

            case SensorValueAttribute.Param1:
            case SensorValueAttribute.Param2:
            case SensorValueAttribute.Param3:
            case SensorValueAttribute.Param4:
            case SensorValueAttribute.Param5: return PhysicalUnitSupported;
        }
    }

    return [];
}