/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 08.11.2019
 * @description The map layer to draw dxf (geojson) as background of local map object content
 */

import "leaflet/dist/leaflet.css";
import { GeoJSON } from 'react-leaflet';
import { ImageBackgroundInfo } from "../../server/AVTService/TypeLibrary/ImageAsMap/ImageBackgroundInfo";
import { DataActionResponse } from "../../server/DataActionResponse";
import ServerRoutesGen from "../../server/Routes/ServerRoutesGen";
import FlagService from "../../services/FlagService";
import GeovisComponent from "../abstract/GeovisComponent";
import { ImageMapContentType, viewPortToMapBounds } from "./types";

interface IDXFLayerProps {
    projectId: number;
    objectId: string;
    backgroundInfo: ImageBackgroundInfo;
    contentType: ImageMapContentType
}

interface IDXFLayerState {
    document: any;
}

export class ImageMapDXFLayer extends GeovisComponent<IDXFLayerProps, IDXFLayerState> {

    constructor(props: IDXFLayerProps) {
        super(props);

        this.state = { document: undefined };
    }

    public geovisComponentDidMount = async () => {
        try {
            const url = this.getUrl();
            const response = await this.Server.get<DataActionResponse<any>>(url);

            if (!response.Success) {
                FlagService.addErrors("Cannot load DXF stream", response.Messages);
                return;
            }

            this.setState({ document: response.Data });
        }
        catch (error) {
            FlagService.addError("An error occurred during loading DXF content", error);
        }
    }

    public geovisComponentWillUnmount = async () => {
        /** */
    }

    public render = () => {
        const { backgroundInfo: { ViewPort } } = this.props;
        const { document } = this.state;

        if (!document) {
            return (
                <div>Loading in progress...</div>
            );
        }

        const geoJsonProps: any = {
            bounds: viewPortToMapBounds(ViewPort)
        }

        return (
            <GeoJSON
                style={{
                    stroke: true,
                    weight: 1,
                    fillOpacity: 0.2,
                    color: 'black'
                }}
                {...geoJsonProps} // bounds={viewPortToMapBounds(ViewPort)}
                data={document} />
        );
    }


    private getUrl = (): string => {

        const { projectId, backgroundInfo, contentType } = this.props;

        if (backgroundInfo.IsGeoJSONFileDirty) {
            let dUrl = "";

            switch (contentType) {
                case "GeovisImage":
                    dUrl = "";
                    break;
                case "LMO":
                    dUrl = ServerRoutesGen.LocalMapObject.DirtyBackgroundGeoJsonDxfStream.patch(projectId, backgroundInfo.GeoJSONFileId).path;
                    break;
            }

            return dUrl;
        }

        const { backgroundInfo: { FileId, IsFileDirty } } = this.props;
        const objectId = this.props.objectId || "objectId";

        let url = "";

        switch (contentType) {
            case "GeovisImage":
                url = "";
                break;
            case "LMO":
                url = ServerRoutesGen.LocalMapObject.BackgroundDxfStream.patch(projectId, objectId, FileId, IsFileDirty).path;
                break;
        }
        return url;
    }
}