//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class XYSensorPair
{
	public xSensorId: string;
	public ySensorId: string;
	public interpolationMode: number;
}
