/**
 * @author Ivan Kozlov <ik@ieskr.ru>
 * @created 08.07.2021
 * @description Archiving and restoring actions
 */

export const GEOVIS_ARCHIVING_CHANGED_SETTING = "GEOVIS_ARCHIVING_CHANGED_SETTING";
export const GEOVIS_ARCHIVING_CHANGED_SETTINGS = "GEOVIS_ARCHIVING_CHANGED_SETTINGS";

export const GEOVIS_ARCHIVING_SETTINGS_SET = "GEOVIS_ARCHIVING_SETTINGS_SET";
export const GEOVIS_ARCHIVING_DEFAULT_SETTINGS_LOADED = "GEOVIS_ARCHIVING_DEFAULT_SETTINGS_LOADED";
export const GEOVIS_ARCHIVING_RESTORING_CHANGE = "GEOVIS_ARCHIVING_RESTORING_CHANGE";

export const GEOVIS_ARCHIVING_PROJECTS_LOADING = "GEOVIS_ARCHIVING_PROJECTS_LOADING";
export const GEOVIS_ARCHIVING_PROJECTS_LOADED = "GEOVIS_ARCHIVING_PROJECTS_LOADED";
export const GEOVIS_ARCHIVING_PROJECTS_LOADING_ERROR = "GEOVIS_ARCHIVING_PROJECTS_LOADING_ERROR";


export const GEOVIS_ARCHIVING_LISTING_LOADING = "GEOVIS_ARCHIVING_LISTING_LOADING";
export const GEOVIS_ARCHIVING_LISTING_LOADED = "GEOVIS_ARCHIVING_LISTING_LOADED";
export const GEOVIS_ARCHIVING_LISTING_LOADING_ERROR = "GEOVIS_ARCHIVING_LISTING_LOADING_ERROR";

export const GEOVIS_ARCHIVING_SHOW_DIALOG = "GEOVIS_ARCHIVING_SHOW_DIALOG";
export const GEOVIS_ARCHIVING_HIDE_DIALOG = "GEOVIS_ARCHIVING_HIDE_DIALOG";

export const GEOVIS_ARCHIVING_SHOW_CONFIRM_DIALOG = "GEOVIS_ARCHIVING_SHOW_CONFIRM_DIALOG";
export const GEOVIS_ARCHIVING_HIDE_CONFIRM_DIALOG = "GEOVIS_ARCHIVING_HIDE_CONFIRM_DIALOG";

export const GEOVIS_ARCHIVING_SHOW_FEEDBACK_DIALOG = "GEOVIS_ARCHIVING_SHOW_FEEDBACK_DIALOG";

export const GEOVIS_ARCHIVING_PROGRESS_STARTED = "GEOVIS_ARCHIVING_PROGRESS_STARTED";
export const GEOVIS_ARCHIVING_FILE_DOWNLOAD_FINISHED = "GEOVIS_ARCHIVING_FILE_DOWNLOAD_FINISHED";

export const GEOVIS_RESTORE_FILES_UPLOAD_STARTED = "GEOVIS_RESTORE_FILES_UPLOAD_STARTED";
export const GEOVIS_RESTORE_FILE_UPLOAD_PROGRESS = "GEOVIS_RESTORE_FILE_UPLOAD_PROGRESS";
export const GEOVIS_RESTORE_FILE_UPLOAD_FINISHED = "GEOVIS_RESTORE_FILE_UPLOAD_FINISHED";
export const GEOVIS_RESTORE_FILE_UPLOAD_ERROR = "GEOVIS_RESTORE_FILE_UPLOAD_ERROR";