/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.04.2022
 * @description XyChart y-axis tab
 */

import { XYSensorPair } from '../../../../server/AVTService/TypeLibrary/Common/XYSensorPair';
import { GeovisChartAxisSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings';
import { XyChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel';
import { SensorCategory, SensorCategoryOrdered } from '../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { SensorChangesModel } from '../../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel';
import { ChartAxisSensorsControl } from '../ChartAxisSensorsControl';
import { GeovisChartAxisSettingsControl } from '../GeovisChartAxisSettingsControl';
import { isChartTemplate } from '../tools';
import { IGeovisChartPropertyChangedProps, IGeovisChartSensorsPropertyProps } from '../types';

interface IXyChartYAxisTabProps extends IGeovisChartPropertyChangedProps<XyChartModel>, IGeovisChartSensorsPropertyProps {
    chart: XyChartModel;
}

/**
 * XyChart Y-axis settings
 * @param props IXyChartYAxisTabProps
 * @returns 
 */
export const XyChartYAxisTab = ({ chart, onChartPropertyChanged, onChartPropertiesChanged, sensorsChanges }: IXyChartYAxisTabProps) => {

    const { LeftYAxisSettings } = chart;

    const onAxisPropertyChangedHandler = (axisPropertyName: keyof XyChartModel, axisSettings: GeovisChartAxisSettings) => (propertyName: keyof GeovisChartAxisSettings, value: any) => {

        const changedAxisSettings: Partial<GeovisChartAxisSettings> = { ...axisSettings };
        changedAxisSettings[propertyName] = value;

        onChartPropertyChanged(axisPropertyName, changedAxisSettings);
    }

    const onAxisPropertiesChangedHandlerFunc = (axisPropertyName: keyof XyChartModel, axisSettings: GeovisChartAxisSettings) => (properties: Partial<GeovisChartAxisSettings>) => {


        // synchronize YAxis and XAxis if 
        if (!chart.UseXYAxisDataBasedOnDifferentSensors && properties.TypeOfSensor !== undefined) {

            const changes: Partial<XyChartModel> = {
                LeftYAxisSettings: { ...axisSettings, ...properties },
                XAxisSettings: { ...chart.XAxisSettings, ...properties }
            };

            onChartPropertiesChanged(changes);
            return;
        }

        onChartPropertyChanged<GeovisChartAxisSettings>(axisPropertyName, { ...axisSettings, ...properties });
    }

    const onAxisSensorsChangedHandler = (axisPropertyName: keyof XyChartModel, axisSettings: GeovisChartAxisSettings) => (sensorIds: string[], customerChangeable: boolean, sensorChanges: SensorChangesModel[]) => {

        const newAxisSettings: GeovisChartAxisSettings = {
            ...axisSettings,
            SensorIds: [...sensorIds],
            IsSensorsCustomerChangeable: customerChangeable
        }

        // onChangeSensorsChanges(sensorChanges);
        onChartPropertyChanged(axisPropertyName, newAxisSettings, sensorChanges);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onAxisSensorsPairsChanged = (pairs: XYSensorPair[], customerChangeable: boolean, sensorChanges: SensorChangesModel[]) => {
        onChartPropertyChanged("SensorPairs", pairs);
    }

    const onAxisChainsChangedHandler = (axisPropertyName: keyof XyChartModel, axisSettings: GeovisChartAxisSettings) => (chainIds: string[]) => {

        const newAxisSettings: GeovisChartAxisSettings = {
            ...axisSettings,
            ChainIds: [...chainIds],
        }

        onChartPropertyChanged(axisPropertyName, newAxisSettings);
    }

    const isChainPositionChart = LeftYAxisSettings.TypeOfSensor === SensorCategory.InclinometerChainPosition;
    const isTemplate = isChartTemplate(chart);

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={{ width: '100%' }}>
                    <GeovisChartAxisSettingsControl
                        chartType={chart.Type}
                        axisSettings={LeftYAxisSettings}
                        onAxisPropertiesChanged={onAxisPropertiesChangedHandlerFunc("LeftYAxisSettings", LeftYAxisSettings)}
                        onAxisPropertyChanged={onAxisPropertyChangedHandler("LeftYAxisSettings", LeftYAxisSettings)}
                        sensorTypesToSelect={SensorCategoryOrdered}
                        isXAxis={false}
                        isRightAxis={false}
                        isDisabled={false}
                    />

                    {!isTemplate && isChainPositionChart &&
                        <ChartAxisSensorsControl
                            isChain={true}
                            chartType={chart.Type}
                            dtsSectionIds={[]}
                            projectId={chart.ProjectId}
                            ySensorType={LeftYAxisSettings.TypeOfSensor}
                            sensorIds={LeftYAxisSettings.ChainIds}
                            customerChangeable={LeftYAxisSettings.IsSensorsCustomerChangeable}
                            onChange={onAxisChainsChangedHandler("LeftYAxisSettings", chart.LeftYAxisSettings)}
                            sensorChanges={sensorsChanges}
                            showPairsDialog={false}
                            selectedChainsIds={LeftYAxisSettings.ChainIds}
                            isSingle={false}
                            hideCustomerChangeable={true}
                        />
                    }

                    {!isTemplate &&
                        <ChartAxisSensorsControl
                            isChain={false}
                            chartType={chart.Type}
                            dtsSectionIds={[]}
                            projectId={chart.ProjectId}
                            ySensorType={LeftYAxisSettings.TypeOfSensor}
                            xSensorType={chart.XAxisSettings.TypeOfSensor}
                            sensorIds={LeftYAxisSettings.SensorIds}
                            customerChangeable={LeftYAxisSettings.IsSensorsCustomerChangeable}
                            onChange={onAxisSensorsChangedHandler("LeftYAxisSettings", LeftYAxisSettings)}
                            sensorChanges={sensorsChanges}
                            showPairsDialog={chart.UseXYAxisDataBasedOnDifferentSensors}
                            onChangeXyChartSensorsPairs={onAxisSensorsPairsChanged}
                            sensorsPairs={chart.SensorPairs}
                            needXAxisCheck={chart.UseXYAxisDataBasedOnDifferentSensors}
                            selectedChainsIds={LeftYAxisSettings.ChainIds}
                            isSingle={false}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

