import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { compareDates, formattedDateTime } from "../../../../../../helpers/DateHelper";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../../helpers/GeovisHooks";
import { t } from "../../../../../../i18n";
import { DatesGrouping } from "../../../../../../server/AVTService/TypeLibrary/Report/DatesGrouping";
import { GeovisLogbookRecordsGroup } from "../../../../../../server/GEOvis3/Model/GeovisLogbooks/GeovisLogbookRecordsGroup";
import { LogbookModelSlim } from "../../../../../../server/GEOvis3/Model/GeovisLogbooks/LogbookModelSlim";
import Logger from "../../../../../../services/Logger";
import { projectReportServiceDataChanged } from "../../../../../../store/creators/projectReportCreators";
import { IGeovisAction } from "../../../../../../store/types";
import FooterRender from "../../FooterRender";
import { REPORT_CONTENT_HEIGHT } from "../../GeovisReportPageRender";
import HeaderRender from "../../header/HeaderRender";
import { createLogbookRenderedFunc } from "../../tools";
import { IReportElementRenderSimpleOwnProps } from "../../types";
import { IGeovisLogbookReportData, IGeovisLogbookReportInfo } from "../../../../../../store/projectReport.types";
import { IReportHeaderState } from "../../../../../../store/reportRender.types";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { getLogbookEntryImageUrl } from "../../../../logbook/tools";

// const NAVIGATION_WIDTH = 514;

interface IStateToProps {
    headerState: IReportHeaderState;
}

interface IDispatchProps {
    onServiceSettingsChanged: (elementId: number, reportPageNumber: number, pagesCount: number, startDate: string, endDate: string) => void
}

interface IOwnProps extends IReportElementRenderSimpleOwnProps {
    reportPagesCount: number;

    geovisLogbookInfo: IGeovisLogbookReportInfo;
    geovisLogbookData: IGeovisLogbookReportData;
}

interface IComponentProps extends IStateToProps, IDispatchProps, IOwnProps, IWithGeovisServerProps {

}
interface ILogbookReportPage {
    index: number;
    groups: GeovisLogbookRecordsGroup[];
}

interface IComponentState {
    calculated: boolean;
    logbookPages: ILogbookReportPage[];
    maximumPageHeight: number;
}

const GeovisLogbookRender = ({
    headerState,
    geovisLogbookInfo,
    geovisLogbookData,
    userId,
    userToken,
    onServiceSettingsChanged,
    reportPagesCount,
    pageNum
}: IComponentProps) => {

    const isPrintingMode = userId !== undefined && userToken !== undefined;

    const { GeovisLogbook } = geovisLogbookInfo;
    const { geovisLogbookDataModel } = geovisLogbookData;

    const isLoading = geovisLogbookData.isLoading || headerState.isLoading;
    const isLoaded = geovisLogbookData.isLoaded && headerState.isLoaded;

    const [state, setState] = useState<IComponentState>({
        calculated: false,
        logbookPages: [],
        maximumPageHeight: REPORT_CONTENT_HEIGHT,
    })

    useEffect(() => {
        if (state.calculated) {
            setTimeout(() => {
                createLogbookRenderedFunc(pageNum, GeovisLogbook.Id);
            }, 2000);
        }
    }, [state.calculated]);

    useLayoutEffect(() => {
        if (!isLoaded) {
            return;
        }

        const { Data, Model } = geovisLogbookDataModel;

        const sorted = parseGroupsToPages(Data);

        const startDate = Data.length > 0 && Data[0].Records.length > 0 ? Data[0].Records[0].Date : Model.StartDate;
        const endDate = Data.length > 0 && Data[0].Records.length > 0 ? Data[Data.length - 1].Records[Data[Data.length - 1].Records.length - 1].Date : Model.EndDate;

        onServiceSettingsChanged(GeovisLogbook.Id, pageNum, sorted.length, startDate, endDate);
        setState({ ...state, calculated: true, logbookPages: sorted });

        Logger.info("Calculated")
    }, [2, isLoaded])

    const parseGroupsToPages = (groups: GeovisLogbookRecordsGroup[]) => {
        const result: ILogbookReportPage[] = [];

        let freeSpace = state.maximumPageHeight;

        let itemIndex: number = 0;
        let rowIndex: number = 0;

        if (groups.length === 0) {
            return [];
        }

        let currentPage: ILogbookReportPage = {
            groups: [],
            index: 0
        }
        const tempItemsHeight = new Map<string, number>();

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const groupElement = document.getElementById(getGroupId(itemIndex));
            if (!groupElement) {
                result.push({ ...currentPage });
                break;
            }
            const groupHeight = groupElement.clientHeight;
            tempItemsHeight.set(getGroupId(itemIndex), groupHeight);
            const groupHeaderElementTemp = document.getElementById(getGroupHeaderId(itemIndex));
            tempItemsHeight.set(getGroupHeaderId(itemIndex), groupHeaderElementTemp ? groupHeaderElementTemp.clientHeight : 0);
            groups[itemIndex].Records.forEach(record => {
                const elementTemp = document.getElementById(getLogbookRowId(record, itemIndex));
                tempItemsHeight.set(getLogbookRowId(record, itemIndex), elementTemp ? elementTemp.clientHeight : 0);
            })

            if (freeSpace - groupHeight >= 0 && rowIndex === 0) {
                // whole group can be placed on current page
                currentPage.groups.push(groups[itemIndex]);
                itemIndex++;
                freeSpace = freeSpace - groupHeight;
            }
            else {
                const groupHeaderElement = document.getElementById(getGroupHeaderId(itemIndex));

                if (groupHeaderElement) {
                    const groupHeaderHeight = groupHeaderElement.clientHeight;
                    if (freeSpace - groupHeaderHeight >= 0) {
                        // header can be placed on current page
                        freeSpace -= groupHeaderHeight;

                        const groupToAdd: GeovisLogbookRecordsGroup = {
                            ...groups[itemIndex],
                            Records: []
                        }

                        for (let i = rowIndex; i < groups[itemIndex].Records.length; i++) {
                            const element = groups[itemIndex].Records[rowIndex];
                            const elementRow = document.getElementById(getLogbookRowId(element, itemIndex));
                            if (elementRow) {
                                const elementRowHeight = elementRow.clientHeight;
                                if (freeSpace - elementRowHeight >= 0) {
                                    // row can be placed on current page
                                    freeSpace -= elementRowHeight;
                                    groupToAdd.Records.push(element);
                                    rowIndex++;
                                }
                                else {
                                    // element can not be placed on current page
                                    if (groupToAdd.Records.length > 0) {
                                        // some records of this group can be placed on current page
                                        currentPage.groups.push(groupToAdd);
                                    }
                                    else {
                                        // no elements can be placed on this page -> so all should be moved to next page
                                        rowIndex = 0;
                                    }
                                    result.push({ ...currentPage });
                                    currentPage = {
                                        groups: [],
                                        index: currentPage.index + 1
                                    };
                                    freeSpace = state.maximumPageHeight;
                                    break;
                                }
                            }
                            if (rowIndex === groups[itemIndex].Records.length - 1) {
                                // if all elements of this group can be added to this page
                                currentPage.groups.push(groupToAdd);
                                rowIndex = 0;
                                itemIndex++;
                                break;
                            }
                        }
                    }
                    else {
                        // header can not be placed on this page
                        // prepare one more page
                        // set free space to maximum available space
                        result.push({ ...currentPage });
                        currentPage = {
                            groups: [],
                            index: currentPage.index + 1
                        };
                        freeSpace = state.maximumPageHeight;
                    }
                }

            }
        }

        return result;
    }

    const getLogbookRowId = (record: LogbookModelSlim, groupIndex: number): string => {
        return `${pageNum}-logbook-row-${groupIndex}-${record.Id}`;
    }

    const getGroupId = (groupIndex: number): string => {
        return `${pageNum}-group-of-logbooks-${groupIndex}`
    }

    const getGroupHeaderId = (groupIndex: number): string => {
        return `${pageNum}-group-header-${groupIndex}`;
    }

    const getGroupHeader = (group: GeovisLogbookRecordsGroup): string => {
        switch (GeovisLogbook.Group) {
            case DatesGrouping.Day:
                return `${formattedDateTime(group.StartDate, "DD.MM.YYYY")}`;

            default:
                return `${formattedDateTime(group.StartDate, "DD.MM.YYYY")} - ${formattedDateTime(group.EndDate, "DD.MM.YYYY")}`;
        }
    }

    const sortLogbookInGroup = (a: LogbookModelSlim, b: LogbookModelSlim): number => {
        return compareDates(a.Date, b.Date);
    }

    const drawSingleLogbookLine = (record: LogbookModelSlim, groupIndex: number) => {
        return (
            <div id={getLogbookRowId(record, groupIndex)}
                className={record.ImageIds.length > 0 && GeovisLogbook.ShowPicturesInReport.value ? "geovisLogbookReportPictureDataLine" : "geovisLogbookReportDataLine"}
                style={{ background: record.BackgroundColor }}>
                <div className="geovisLogbookReportDateColumn">
                    <span>{formattedDateTime(record.Date)}</span>
                </div>
                <div className="geovisLogbookReportMainColumn">
                    <span>{record.Description}</span>
                </div>
                {GeovisLogbook.ShowComment &&
                    <div className="geovisLogbookReportOtherColumn">
                        <span>{record.Comment}</span>
                    </div>
                }
                {GeovisLogbook.ShowPicturesInReport.value &&
                    <div className="geovisLogbookReportPictureColumn">
                        {record.ImageIds.length > 0 &&
                            <img src={getLogbookEntryImageUrl(record.ImageIds[0], false)} style={{ width: '95%', height: '95%', objectFit: 'contain' }} />
                        }
                    </div>
                }
                {GeovisLogbook.ShowAuthor &&
                    <div className="geovisLogbookReportOtherColumn">
                        <span>{record.Author}</span>
                    </div>
                }
            </div>
        )
    }

    const drawGroup = (group: GeovisLogbookRecordsGroup, groupIndex: number) => {
        // const mainColumnWidth
        return (
            <div id={getGroupId(groupIndex)}>
                <div id={getGroupHeaderId(groupIndex)}>
                    <div className="geovisLogbookReportGroupHeaderLine">
                        <span>{getGroupHeader(group)}</span>
                    </div>
                    <div className="geovisLogbookReportTableHeaderLine">
                        <div className="geovisLogbookReportDateColumn" style={{ fontWeight: 'bold', justifyContent: 'center' }}>
                            <span>{t("Date/Time")}</span>
                        </div>
                        <div className="geovisLogbookReportMainColumn" style={{ fontWeight: 'bold', justifyContent: 'center' }}>
                            <span>{t("Description")}</span>
                        </div>
                        {GeovisLogbook.ShowComment &&
                            <div className="geovisLogbookReportOtherColumn" style={{ fontWeight: 'bold', justifyContent: 'center' }}>
                                <span>{t("Comment")}</span>
                            </div>
                        }
                        {GeovisLogbook.ShowPicturesInReport.value &&
                            <div className="geovisLogbookReportPictureColumn" style={{ fontWeight: 'bold', justifyContent: 'center' }}>
                                <span>{t("Image")}</span>
                            </div>
                        }
                        {GeovisLogbook.ShowAuthor &&
                            <div className="geovisLogbookReportOtherColumn" style={{ fontWeight: 'bold', justifyContent: 'center' }}>
                                <span>{t("Author")}</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="geovisLogbookReportGroup">
                    {group.Records.sort((a, b) => sortLogbookInGroup(a, b)).map((record) => {
                        return (
                            <React.Fragment key={record.Id}>
                                {drawSingleLogbookLine(record, groupIndex)}
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        )
    }

    if (isLoading) {
        return (
            <div>
                {/*  */}
            </div>
        )
    }

    if (!state.calculated) {
        return (
            <div style={{
                borderLeft: 'solid 2px black',
                borderRight: 'solid 2px black',
                borderBottom: 'solid 2px black',
                width: '100%'
            }}>
                {geovisLogbookDataModel.Data.map((group, index) => {
                    return (
                        <React.Fragment key={`group-${index}`}>
                            {drawGroup(group, index)}
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }

    return (
        <>
            {state.logbookPages.map((page, pageIndex) => (
                <div key={`page-${pageIndex}`} className={isPrintingMode ? "reportContentContainerPrint" : "reportContentContainer"}>
                    <div className={"reportPageContainer"}>

                        <HeaderRender isDefaultReport={false} />

                        <div style={{ height: `${state.maximumPageHeight}px` }}>
                            {page.groups.map((group, index) => {
                                return (
                                    <React.Fragment key={`group-${index}`}>
                                        {drawGroup(group, index)}
                                    </React.Fragment>
                                )
                            })}
                        </div>

                        <FooterRender
                            countPages={reportPagesCount}
                            pageNumber={pageNum}
                            useCustomPageNumber={true}
                            customPageNumber={pageNum + pageIndex + 1}
                        />
                    </div>
                </div>
            ))}
        </>
    )

}

const mapStateToProps = ({ reportRenderState }: IGeovisStoreState): IStateToProps => ({
    headerState: reportRenderState.headerState
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDispatchProps => ({
    onServiceSettingsChanged: (elementId, reportPageNumber, pagesCount, startDate, endDate) => dispatch(projectReportServiceDataChanged(elementId, reportPageNumber, pagesCount, startDate, endDate))
});

export default connect<IStateToProps, IDispatchProps, IOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(GeovisLogbookRender));