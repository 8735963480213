/* eslint-disable import/no-duplicates */
import Button from "@atlaskit/button";
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { format, getMonth, getYear, Locale } from "date-fns";
import enGB from "date-fns/locale/en-GB";

import { range } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps, registerLocale, ReactDatePickerProps } from "react-datepicker";
import { t } from "../../i18n";
import GeovisCustomTimeInput from "./GeovisCustomTimeInput";
import { getPureDateTime, isDateTimeInRange, pickerValueToString } from "./time.tools";

import "react-datepicker/dist/react-datepicker.css";
import FlagService from "../../services/FlagService";
import "./geovis.react.datepicker.css";
import { IDateTimeRangeProps } from "./types";
import { geovisDateTimePickerParseDate } from "./tools";

const DefaultPickerDateOnlyFnsFormat = "dd.MM.yyyy";
const DefaultPickerFullDateFnsFormat = "dd.MM.yyyy HH:mm";
const TodayButtonId = "today-button-id";
const RangeStartYear = 1980;

interface IComponentProps extends IDateTimeRangeProps {
    ariaLabelledBy?: string;
    dateFormat?: string | string[];
    disabled?: boolean;
    id?: string;
    locale?: string | Locale;
    name?: string;
    openOnInitialFocus?: boolean;
    placeholderText?: string;
    showTime?: boolean;
    timeIsEditable?: boolean;
    useMinDefaultValueAsPlaceholder?: boolean;
    value?: string;
    withPortal?: boolean;
    saveTimeZone?: boolean;
    hideClearButton?: boolean;

    reactDateTimePickerNativeProps?: ReactDatePickerProps;

    onChange?: (date: string) => void;
}

interface IComponentState {
    initiated: boolean;
    selectedDate: Date | undefined;
    originalDate: Date;
    lastAppliedDate: Date | undefined;
    isFocused: boolean;
    isOpen: boolean | undefined;
    placeholderText?: string;
}

const GeovisReactDateTimePicker = ({
    ariaLabelledBy, dateFormat, disabled, id, locale, name, openOnInitialFocus,
    placeholderText, showTime, timeIsEditable, useMinDefaultValueAsPlaceholder,
    value, withPortal, onChange,
    isPairedPicker, isThisPickerOfStartDate, pairPickerDate, // IDateTimeRangeProps
    reactDateTimePickerNativeProps, saveTimeZone,
    hideClearButton
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        initiated: false,
        selectedDate: undefined,
        originalDate: new Date(),
        lastAppliedDate: undefined,
        isFocused: false,
        isOpen: openOnInitialFocus === true ? undefined : false,
        placeholderText
    });

    const showTimeComponent = showTime !== false;
    const applyOnSelect = !showTimeComponent;

    const getDefaultMinDate = (): Date => {
        return new Date(RangeStartYear, 0, 1, 12, 0, 0);
    }

    const timeIntervalsInMinutes = 5;

    useEffect(() => {

        registerLocale("en-GB", enGB);

        if (value) {
            const parsedDate = geovisDateTimePickerParseDate(value, dateFormat);
            const isDateValid = getYear(parsedDate) >= RangeStartYear;
            const selectedDate = isDateValid ? parsedDate : getDefaultMinDate();

            setState({
                ...state,
                initiated: true,
                selectedDate,
                originalDate: state.initiated ? state.originalDate : selectedDate,
                lastAppliedDate: state.initiated ? state.lastAppliedDate : selectedDate
            })
        }
        else if (useMinDefaultValueAsPlaceholder) {

            const defaultDate = getDefaultMinDate();
            setState({
                ...state,
                initiated: true,
                placeholderText: state.placeholderText === undefined
                    ? format(defaultDate, showTimeComponent ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy")
                    : state.placeholderText,
                originalDate: state.initiated ? state.originalDate : defaultDate
            })
        }
    }, [value, id]);

    const getNearestTimeInterval = (date: Date, isNow: boolean): number => {
        const minutes = date.getMinutes();
        const rest = minutes % timeIntervalsInMinutes;
        if (rest !== 0) {
            const properTimeInterval = (isNow || rest * 2 < timeIntervalsInMinutes) ? 0 : timeIntervalsInMinutes;
            return minutes - rest + properTimeInterval;
        }
        return minutes;
    }

    const getValidDate = (date: Date, isNow: boolean): Date => {
        if (timeIsEditable === false) {
            date.setMinutes(getNearestTimeInterval(date, isNow));
        }
        return date;
    }

    const getTargetId = (target: any): string | undefined => {
        if (typeof target === 'object' && typeof target.offsetParent === 'object') {
            return target.offsetParent.id;
        }
        return undefined;
    }

    const getTargetClassNames = (target: any): DOMTokenList | undefined => {
        return target.classList;
    }

    /**
     * Paired picker Date object
     * Used to set selectsStart and selectsEnd
     */
    const pairPickerParsedDate = isPairedPicker && pairPickerDate ? geovisDateTimePickerParseDate(pairPickerDate) : undefined;

    const onChangeDate = (date: Date | null, event: React.SyntheticEvent<HTMLElement> | undefined) => {

        const isClearEvent = date === null;
        const selectedDate = isClearEvent
            ? getValidDate(state.originalDate, false)
            : getValidDate(date, false);

        if (event && event.type === "click") {
            const eventTargetId = getTargetId(event.target);
            if (eventTargetId === TodayButtonId) {
                // TODO: should the time value be changed too. i.e. to be set to now?                
            }
        }

        const applyRequired = applyOnSelect || isClearEvent;

        setState({
            ...state,
            selectedDate,
            lastAppliedDate: applyRequired ? selectedDate : state.lastAppliedDate
        });

        if (onChange && applyRequired) {
            onChange(pickerValueToString(selectedDate, saveTimeZone))
        }
    }

    const onApplyClick = () => {
        let currentlySelectedDate = state.selectedDate;

        const purePairedPickerDateTime = getPureDateTime(pairPickerParsedDate);
        if (currentlySelectedDate && !isDateTimeInRange(currentlySelectedDate, isPairedPicker && isThisPickerOfStartDate, purePairedPickerDateTime)) {
            FlagService.addError(t("Wrong period"), t("'Start date' can not be greater than 'End date'."));
            currentlySelectedDate = state.lastAppliedDate;
        }

        setState({
            ...state,
            lastAppliedDate: currentlySelectedDate,
            isOpen: false,
            isFocused: false
        });

        if (onChange && currentlySelectedDate) {
            onChange(pickerValueToString(currentlySelectedDate, saveTimeZone));
        }
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter" && state.selectedDate) {
            onApplyClick();
        }
    }

    const onTimeChange = (time: string, applyRequired: boolean) => {
        const date = state.selectedDate ? state.selectedDate : new Date();
        const timeValueParts = time.split(":");
        date.setHours(+timeValueParts[0]);
        date.setMinutes(+timeValueParts[1]);
        date.setSeconds(0);
        date.setMilliseconds(0);

        setState({
            ...state,
            selectedDate: date,
            lastAppliedDate: applyOnSelect ? date : state.lastAppliedDate,
            isOpen: applyRequired ? false : state.isOpen,
            isFocused: applyRequired ? false : state.isFocused
        });

        if (applyRequired && onChange) {
            onChange(pickerValueToString(date, saveTimeZone));
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setState({
            ...state,
            isOpen: undefined,
            isFocused: true
        })
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setState({
            ...state,
            isOpen: false,
            isFocused: false
        })
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        // just reset not applied changes
        setState({
            ...state,
            isOpen: false,
            isFocused: false,
            selectedDate: state.lastAppliedDate
        });
    }

    const onDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const classNames = getTargetClassNames(event.target);
        if (classNames && classNames.contains('react-datepicker__day')) {
            onApplyClick();
        }
    }

    const renderCustomHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }: ReactDatePickerCustomHeaderProps): React.ReactNode => {
        const selectedDateYear = date.getFullYear();

        const getEndDateYear = () => {
            if (isPairedPicker && isThisPickerOfStartDate && pairPickerParsedDate) {
                return getYear(pairPickerParsedDate);
            }

            return getYear(new Date());
        }

        const startDateYear = isPairedPicker && !isThisPickerOfStartDate ? getYear(pairPickerParsedDate || new Date()) : RangeStartYear;

        const getRangeEndYear = () => {
            const endDateYear = getEndDateYear();
            const numOfYearAfterEnd = isPairedPicker && isThisPickerOfStartDate ? 1 : 3;
            return endDateYear + numOfYearAfterEnd;
        }

        const years = range(startDateYear, getRangeEndYear(), 1);
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const onChangeYear = (event: ChangeEvent<HTMLSelectElement>) => {
            const newYear = +event.target.value;
            changeYear(newYear)
        }

        const onChangeMonth = (event: ChangeEvent<HTMLSelectElement>) => {
            const newMonth = months.indexOf(event.target.value);
            changeMonth(newMonth);
        }

        const onDecreaseMonth = () => {
            decreaseMonth();
        }

        const onIncreaseMonth = () => {
            increaseMonth();
        }

        const isMonthDisabled = (monthIndex: number) => {

            if (!isPairedPicker) {
                return false;
            }

            if (pairPickerParsedDate && pairPickerParsedDate.getFullYear() === selectedDateYear) {

                const monthNumber = pairPickerParsedDate.getMonth();

                if (isThisPickerOfStartDate) {
                    return monthNumber < monthIndex;
                }
                else {
                    return monthNumber > monthIndex;
                }
            }

            return false;
        }

        return (
            <div style={{ marginLeft: "5px", marginRight: "5px", display: "flex", justifyContent: "center" }} >
                <Button
                    onClick={onDecreaseMonth}
                    isDisabled={prevMonthButtonDisabled}
                    iconBefore={<ChevronLeftIcon label={''} size="large" />} />

                <select
                    value={months[getMonth(date)]}
                    onChange={onChangeMonth} >
                    {months.map((option, monthIndex) => (
                        <option key={option} value={option} disabled={isMonthDisabled(monthIndex)}>
                            {option}
                        </option>
                    ))}
                </select>

                <select
                    value={getYear(date)}
                    onChange={onChangeYear} >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <Button
                    onClick={onIncreaseMonth}
                    isDisabled={nextMonthButtonDisabled}
                    iconBefore={<ChevronRightIcon label={''} size="large" />} />
            </div>
        )
    }

    const getDateFormat = () => {
        if (dateFormat !== undefined) {
            return dateFormat;
        }

        return showTimeComponent ? DefaultPickerFullDateFnsFormat : DefaultPickerDateOnlyFnsFormat;
    }

    let inputClassName = "geovis-react-datepicker-input";
    if (state.isFocused) {
        inputClassName += " geovis-react-datepicker-input-focused";
    }

    return (
        <div onDoubleClick={onDoubleClick}>
            <DatePicker
                key={id}
                id={id}
                ariaLabelledBy={ariaLabelledBy}
                wrapperClassName="geovis-react-datepicker-wrapper"
                className={inputClassName}
                clearButtonClassName="geovis-react-datepicker-close-icon"
                popperClassName="geovis-react-datepicker-popper"
                calendarStartDay={1}
                placeholderText={state.placeholderText}
                open={state.isOpen}
                locale={locale || "en-GB"}
                name={name}
                dateFormat={getDateFormat()}
                timeFormat="HH:mm"
                timeIntervals={timeIntervalsInMinutes}
                disabled={disabled}
                disabledKeyboardNavigation={true}
                selected={state.selectedDate}
                onBlur={onBlur}
                onChange={onChangeDate}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onClickOutside={onClickOutside}
                renderCustomHeader={renderCustomHeader}
                isClearable={!disabled && !hideClearButton}
                portalId={"root"}
                selectsStart={isPairedPicker && isThisPickerOfStartDate}
                selectsEnd={isPairedPicker && !isThisPickerOfStartDate}
                startDate={isThisPickerOfStartDate ? pairPickerParsedDate : undefined}
                endDate={!isThisPickerOfStartDate ? pairPickerParsedDate : undefined}
                minDate={isPairedPicker && !isThisPickerOfStartDate ? pairPickerParsedDate : undefined}
                maxDate={isPairedPicker && isThisPickerOfStartDate ? pairPickerParsedDate : undefined}
                withPortal={withPortal}
                shouldCloseOnSelect={applyOnSelect}
                todayButton={<Button id={TodayButtonId} style={{ borderWidth: '1px' }}>{t("Today")}</Button>}
                popperPlacement="bottom"
                popperModifiers={[{
                    name: "preventOverflow",
                    options: {
                        // rootBoundary: "document",
                        tether: false,
                        altAxis: true
                    }
                },
                {
                    name: "hide",
                    options: {
                        enabled: false
                    }
                }]}
                {...reactDateTimePickerNativeProps}>
                {showTimeComponent && (
                    <div className="flexRowContainer" style={{ justifyContent: 'center', padding: '5px' }}>
                        <div className="flexCellContainer">
                            <GeovisCustomTimeInput
                                selectedDate={state.selectedDate || new Date()}
                                isPairedPicker={isPairedPicker}
                                isThisPickerOfStartDate={isThisPickerOfStartDate}
                                pairPickerDate={pairPickerParsedDate}
                                onChange={onTimeChange}
                            />
                        </div>

                        <div className="flexCellContainer">
                            <Button onClick={onApplyClick} style={{ borderWidth: '1px' }}>
                                {t("Apply")}
                            </Button>
                        </div>
                    </div>
                )}

            </DatePicker>
        </div>
    )
}

export default GeovisReactDateTimePicker;