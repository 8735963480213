import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import HighchartsHeatmapContour from '../../../../../../libs/highcharts-contour';
import { IGeovisChartReportData } from "../../../../../../store/projectReport.types";
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../../../../../../store/types";
import { LoadingContainerSkeleton } from "../../../../../LoadingContainerSkeleton";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../../helpers/GeovisHooks";
import { t } from "../../../../../../i18n";
import { getHeatmapChartRenderOptions } from "../options/heatmapChartRenderOptions";
import { HeatmapChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel";
import { HeatmapChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/HeatmapChartData";
import { fetchServerElementsByPost } from "../../../../../../helpers/ProjectDataHelper";
import { GeovisChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartData";
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import ServerRoutesGen from "../../../../../../server/Routes/ServerRoutesGen";
import { ErrorContainerSkeleton } from "../../../../../ErrorContainerSkeleton";


interface IComponentProps extends IWithGeovisServerProps {
    chart: HeatmapChartModel;
    projectId: number;
}

interface IComponentState {
    chartData: IGeovisChartReportData<HeatmapChartData>;
}

let isContourImported = false;

const Component = ({
    chart,
    Server,
    projectId
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        chartData: {
            ...defaultSomethingStorageState,
            dtsSectionInfos: [],
            Timestamp: 0,
            data: {
                ...new HeatmapChartData()
            }
        }
    });

    useEffect(() => {
        (async function loadChartData() {
            const url = ServerRoutesGen.ReportRenderData.GetGeovisPreviewChartCustomData.patch(projectId);
            const response = await fetchServerElementsByPost<GeovisChartData, GeovisChartModel>(Server, url, chart);
            if (!response.Success) {
                setState({
                    ...state,
                    chartData: {
                        ...errorSomethingStorageState(...response.Messages),
                        dtsSectionInfos: [],
                        Timestamp: 0,
                        data: {
                            ...new HeatmapChartData()
                        }
                    }
                });
                return;
            }
            setState({
                ...state,
                chartData: {
                    ...loadedSomethingStorageState,
                    data: response.Data as HeatmapChartData,
                    dtsSectionInfos: [],
                    Timestamp: 0
                }
            })
        })()
    }, [1])

    if (!state.chartData.isLoaded) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <LoadingContainerSkeleton loadingText={t("Loading Heatmap preview chart data")} />
            </div>
        )
    }

    if (state.chartData.isError) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <ErrorContainerSkeleton errorText={`${t("Failed to load Heatmap chart data")}: ${state.chartData.errorDescription}`} />
            </div>
        )
    }

    const { data } = state.chartData

    // HighchartsBoost(Highcharts);
    HighchartsNoDataToDisplay(Highcharts);
    HighchartsHeatmap(Highcharts);

    if (!isContourImported) {
        HighchartsHeatmapContour(Highcharts);
        isContourImported = true;
    }

    const options = getHeatmapChartRenderOptions({ pageNum: 0, chart: cloneDeep(chart), data });

    return (
        <div className="report-chart-render" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}>
            <HighchartsReact
                key={`highcharts_heatmap-${chart.Id}`}
                highcharts={Highcharts}
                options={options}
                allowChartUpdate={true}
                updateArgs={[true, true, false]}
                constructorType={'chart'}
                containerProps={{ style: { flexGrow: 1, height: `100%` } }}
            />
        </div>
    )

}

export const HeatmapPreviewChartRender = withGeovisServer(Component);

