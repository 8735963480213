/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 09.04.2020
 * @description Logos Manager data reducer
 */

import { Reducer } from 'redux';
import { LogosViewMode } from '../../components/logosManager/types';
import {
    addCurrentCompanyLogoFile,
    deleteCompanyLogoFile,
    deleteLogoFile,
    updateUploadLogoFileInfoProperties
} from '../../helpers/LogosManagerHelper';
import { elementsToMap, joinMapAndElements } from '../../helpers/StorageHelper';
import { LogoFileInfo } from '../../server/AVTService/TypeLibrary/Logo/LogoFileInfo';
import { CompanyInfoWithLogoFiles } from '../../server/GEOvis3/Model/LogosManager/CompanyInfoWithLogoFiles';
import { ProjectInfoSlimWithLogoFiles } from '../../server/GEOvis3/Model/Project/ProjectInfoSlimWithLogoFiles';
import {
    LM_COMPANIES_WITH_LOGOS,
    LM_COMPANIES_WITH_LOGOS_DATA,
    LM_COMPANIES_WITH_LOGOS_ERROR,
    LM_LOGO_FILE_DELETE,
    LM_LOGO_FILE_DELETE_IMPOSSIBLE,
    LM_LOGO_FILE_DELETE_IMPOSSIBLE_DISMISS,
    LM_LOGO_FILES,
    LM_LOGO_FILES_DATA,
    LM_LOGO_FILES_ERROR,
    LM_UPLOAD_LOGO_ERROR,
    LM_UPLOAD_LOGO_FINISHED,
    LM_UPLOAD_LOGO_PROGRESS,
    LM_UPLOAD_LOGO_STARTED,
    LM_VIEW_MODE
} from '../actions/logosManagerActions';
import {
    IGeovisLogosManagerAction,
    IGeovisLogosManagerState
} from '../logosManager.types';
import { defaultSomethingStorageState, loadedSomethingStorageState } from '../types';
import { GeovisUploadFileState } from '../uploading.types';

/**
 * Logos manager initial state
 */
export const logosManagerInitialState: IGeovisLogosManagerState = {
    viewMode: LogosViewMode.OwnLogos,
    filesInfoStorage: {
        ...defaultSomethingStorageState,
        filesInfo: new Map<string, LogoFileInfo>()
    },
    uploadFilesInfo: [],
    impossibleDeleteLogoFile: false,
    logoToProjectAssignInfo: [],
    logoId: '',
    companiesWithLogoStorage: {
        ...defaultSomethingStorageState,
        companies: new Map<string, CompanyInfoWithLogoFiles>()
    },
    projectStorage: {
        ...defaultSomethingStorageState,
        projects: new Map<number, ProjectInfoSlimWithLogoFiles>()
    }
};

/**
 * Logos manager data reducer
 * @param state 
 * @param action 
 */
const logosManagerReducer: Reducer<IGeovisLogosManagerState> = (
    state: IGeovisLogosManagerState = logosManagerInitialState,
    action: IGeovisLogosManagerAction): IGeovisLogosManagerState => {

    switch (action.type) {

        //#region Logo files info DATA

        case LM_LOGO_FILES:
            return {
                ...logosManagerInitialState
            };

        case LM_LOGO_FILES_ERROR:
            return {
                ...state,
                filesInfoStorage: {
                    ...logosManagerInitialState.filesInfoStorage,
                    isError: true
                }
            };

        case LM_LOGO_FILES_DATA: {
            if (!action.filesInfo) {
                return state;
            }

            return {
                ...state,
                filesInfoStorage: {
                    ...loadedSomethingStorageState,
                    filesInfo: elementsToMap(action.filesInfo),
                }
            };
        }

        case LM_LOGO_FILE_DELETE:
            if (!action.infoId) {
                return state;
            }

            return {
                ...state,
                filesInfoStorage: deleteLogoFile(state.filesInfoStorage, action.infoId),
                companiesWithLogoStorage: {
                    ...state.companiesWithLogoStorage,
                    companies: deleteCompanyLogoFile(state.companiesWithLogoStorage.companies, action.infoId)
                }
            }

        //#endregion

        //#region Loading companies with logo files

        case LM_COMPANIES_WITH_LOGOS:
            return {
                ...state,
                companiesWithLogoStorage: { ...logosManagerInitialState.companiesWithLogoStorage }
            };

        case LM_COMPANIES_WITH_LOGOS_ERROR:
            return {
                ...state,
                companiesWithLogoStorage: {
                    ...logosManagerInitialState.companiesWithLogoStorage,
                    isError: true
                }
            }

        case LM_COMPANIES_WITH_LOGOS_DATA:
            if (!action.companiesWithLogos) {
                return state;
            }

            return {
                ...state,
                companiesWithLogoStorage: {
                    ...loadedSomethingStorageState,
                    companies: elementsToMap(action.companiesWithLogos)
                }
            };

        //#endregion

        case LM_LOGO_FILE_DELETE_IMPOSSIBLE:
            if (!action.logoToProjectAssignInfo || !action.infoId) {
                return state;
            }

            return {
                ...state,
                impossibleDeleteLogoFile: true,
                logoId: action.infoId,
                logoToProjectAssignInfo: action.logoToProjectAssignInfo
            };

        case LM_LOGO_FILE_DELETE_IMPOSSIBLE_DISMISS:
            return {
                ...state,
                impossibleDeleteLogoFile: false,
                logoId: '',
                logoToProjectAssignInfo: []
            };

        case LM_VIEW_MODE: {
            if (action.viewMode === undefined) {
                return state;
            }

            return {
                ...state,
                viewMode: action.viewMode
            };
        }

        //#region Upload new logo files

        case LM_UPLOAD_LOGO_STARTED:
        case LM_UPLOAD_LOGO_PROGRESS:
            if (!action.uploadFilesInfo) {
                return state;
            }

            return {
                ...state,
                uploadFilesInfo: action.uploadFilesInfo.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f }))
            };

        case LM_UPLOAD_LOGO_FINISHED:
            if (!action.linkId || !action.logoFileInfo) {
                return state;
            }

            return {
                ...state,
                uploadFilesInfo: state.uploadFilesInfo.filter(f => f.id !== action.linkId),
                filesInfoStorage: {
                    isError: false,
                    isLoaded: true,
                    isLoading: false,
                    isInProgress: false,
                    filesInfo: joinMapAndElements(state.filesInfoStorage.filesInfo, action.logoFileInfo),
                    errorDescription: ''
                },
                companiesWithLogoStorage: {
                    ...state.companiesWithLogoStorage,
                    companies: addCurrentCompanyLogoFile(state.companiesWithLogoStorage.companies, action.logoFileInfo)
                }
            };

        case LM_UPLOAD_LOGO_ERROR:
            if (!action.linkId || !action.errorDescription) {
                return state;
            }

            return {
                ...state,
                uploadFilesInfo: updateUploadLogoFileInfoProperties(f => f.linkId === action.linkId, state.uploadFilesInfo, {
                    description: action.errorDescription,
                    isError: true,
                    isCompleted: true
                })
            };

        //#endregion
    }

    return state;
}

export default logosManagerReducer;