/**
 * Project overview action creators
 */

import { ProjectEditSensorsTab } from "../../pages/edit/sensors/types";
import { InventoryObjectType } from "../../server/AVTService/TypeLibrary/Inventory/InventoryObjectType";
import { LogbookElementsRequest } from "../../server/GEOvis3/Model/Logbook/LogbookElementsRequest";
import { SensorInfo } from "../../server/GEOvis3/Model/SensorInfo";
import {
    PROJECT_OVERVIEW_EMBEDDED_DATA_TAB_CHANGED,
    PROJECT_OVERVIEW_HIDE_ANY_OVERLAY,
    PROJECT_OVERVIEW_HIDE_DEFAULT_CHART_CHOOSER,
    PROJECT_OVERVIEW_HIDE_LOCAL_MAP_OBJECT_CONTENT,
    PROJECT_OVERVIEW_HIDE_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG,
    PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_DIALOG,
    PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_REMOVE_DIALOG,
    PROJECT_OVERVIEW_HIDE_SELECTED_SENSORS_REPORT_VIEW,
    PROJECT_OVERVIEW_HIDE_SENSOR_DEFAULT_CHART_VIEW,
    PROJECT_OVERVIEW_INFORMATION_TAB_CHANGED,
    PROJECT_OVERVIEW_INIT_LOGBOOK_VIEW_STATE,
    PROJECT_OVERVIEW_INVENTORY_OBJECT_TYPE_CHANGED,
    PROJECT_OVERVIEW_INVENTORY_TAB_CHANGED,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_PAGE_NUMBER,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_ROWS_PER_PAGE,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_SORTING,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_TEXT_SEARCH_STRING,
    PROJECT_OVERVIEW_LOGBOOK_CONFIRMED,
    PROJECT_OVERVIEW_LOGBOOK_REMOVED,
    PROJECT_OVERVIEW_SET_LOGBOOK_VIEW_STATE,
    PROJECT_OVERVIEW_SHOW_DEFAULT_CHART_CHOOSER,
    PROJECT_OVERVIEW_SHOW_LOCAL_MAP_OBJECT_CONTENT,
    PROJECT_OVERVIEW_SHOW_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG,
    PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_DIALOG,
    PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_EDIT_DIALOG,
    PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_REMOVE_DIALOG,
    PROJECT_OVERVIEW_SHOW_SELECTED_SENSORS_REPORT_VIEW,
    PROJECT_OVERVIEW_SHOW_SENSOR_DEFAULT_CHART_VIEW,
    PROJECT_OVERVIEW_STATISTIC_MST_TAB_SELECTED
    , PROJECT_OVERVIEW_SHOW_LAYERS_DIALOG,
    PROJECT_OVERVIEW_SHOW_ALARM_EDIT_DIALOG,
    PROJECT_OVERVIEW_HIDE_ALARM_EDIT_DIALOG,
    PROJECT_OVERVIEW_SHOW_SENSOR_SETTINGS,
    PROJECT_OVERVIEW_HIDE_SENSOR_SETTINGS,
    PROJECT_OVERVIEW_HIDE_SENSOR_IMAGE,
    PROJECT_OVERVIEW_SHOW_SENSOR_IMAGE
} from "../actions/projectOverviewActions";

import {
    IGeovisProjectOverviewAction,
    ILogbookRecordsTableState,
    LogbookRecordActionType
} from "../projectOverview.types";

export function projectOverviewShowLayerOverlay(): IGeovisProjectOverviewAction {
    return { type: PROJECT_OVERVIEW_SHOW_LAYERS_DIALOG };
}

export const projectOverviewHideAnyOverlay = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_ANY_OVERLAY
});

/**
 * Show modal dialog with local map object content
 * @param objectId local map object identifier
 */
export const projectOverviewShowLocalMapObjectContentMap = (objectId: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_LOCAL_MAP_OBJECT_CONTENT,
    stringProperty: objectId
})

/**
 * Hide local map object content map
 */
export const projectOverviewHideLocalMapObjectContentMap = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_LOCAL_MAP_OBJECT_CONTENT
});

/**
 * 
 * @param chartId - chart id, in case if needed to show exact default chart of with the sensor
 * @param sensorId - sensor full id, which must be shown in the chart view (like a report)
 * @returns 
 */
export const projectOverviewShowSensorDefaultChartView = (chartId: number, sensorId: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_SENSOR_DEFAULT_CHART_VIEW,
    numberProperty: chartId,
    stringProperty: sensorId
});

export const projectOverviewHideSensorDefaultChartView = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_SENSOR_DEFAULT_CHART_VIEW
});

export const projectOverviewShowSensorEditDialog = (sensorFullId: string, type: ProjectEditSensorsTab): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_SENSOR_SETTINGS, stringProperty: sensorFullId, sensorEditType: type
});

export const projectOverviewHideSensorEditDialog = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_SENSOR_SETTINGS
});

export const projectOverviewShowSensorImageDialog = (sensor: SensorInfo): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_SENSOR_IMAGE, sensor
});

export const projectOverviewHideSensorImageDialog = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_SENSOR_IMAGE
});

export const projectOverviewShowDefaultChartChooser = (sensorId: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_DEFAULT_CHART_CHOOSER,
    stringProperty: sensorId
})

export const projectOverviewHideDefaultChartChooser = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_DEFAULT_CHART_CHOOSER
})

export const projectOverviewShowAlarmEditDialog = (alarmId: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_ALARM_EDIT_DIALOG,
    stringProperty: alarmId
})

export const projectOverviewHideAlarmEditDialog = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_ALARM_EDIT_DIALOG
})


/**
 * Shows the dialog with sensors and charts, which are selected in the tree view right now
 * @returns 
 */
export const projectOverviewShowSelectedSensorsReportView = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_SELECTED_SENSORS_REPORT_VIEW
});

/**
 * Hide the dialog with sensors and charts, which are selected in the tree view
 * @returns 
 */
export const projectOverviewHideSelectedSensorsReportView = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_SELECTED_SENSORS_REPORT_VIEW
});

/**
 * Set selected project statistic mst tab (by alias)
 * @param alias 
 */
export const projectOverviewStatisticMstTabSelected = (alias: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_STATISTIC_MST_TAB_SELECTED, stringProperty: alias
});

/**
 * Set project logbook view state to default state
 */
export const projectOverviewInitLogbookViewState = (elementId: number | undefined, logbookRecordsTableState: ILogbookRecordsTableState): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_INIT_LOGBOOK_VIEW_STATE, numberProperty: elementId, logbookRecordsTableState
});

export const projectOverviewSetLogbookViewState = (viewState: LogbookElementsRequest): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SET_LOGBOOK_VIEW_STATE, logbookRecordsViewState: viewState
})

/**
 * Show logbook dialog: view, edit and so on
 * @param logbookAction 
 * @param logbookId 
 */
export const projectOverviewShowLogbookRecordDialog = (logbookAction: LogbookRecordActionType, logbookId: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_DIALOG, stringProperty: logbookId, logbookAction
});

/**
 * Hide logbook record dialog
 */
export const projectOverviewHideLogbookRecordDialog = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_DIALOG
});

export const projectOverviewShowLogbookRecordEditDialog = (logbookId: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_EDIT_DIALOG, stringProperty: logbookId
});

export const projectOverviewShowLogbookRemovePopup = (removeAllSelected: boolean, recordId: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_REMOVE_DIALOG, booleanProperty: removeAllSelected, stringProperty: recordId
});

export const projectOverviewHideLogbookRemovePopup = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_REMOVE_DIALOG
});

export const projectOverviewLogbookRemoved = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_LOGBOOK_REMOVED
});

export const projectOverviewLogbookConfirmed = (logbookId: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_LOGBOOK_CONFIRMED, stringProperty: logbookId
});

export const projectOverviewLogbookChangedTextSearchString = (query: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_LOGBOOK_CHANGED_TEXT_SEARCH_STRING, stringProperty: query
});

export const projectOverviewLogbookChangedPageNumber = (value: number): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_LOGBOOK_CHANGED_PAGE_NUMBER, numberProperty: value
});

export const projectOverviewLogbookChangedRowsPerPage = (value: number): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_LOGBOOK_CHANGED_ROWS_PER_PAGE, numberProperty: value
});

export const projectOverviewLogbookChangedSorting = (sortKey: string, isAscending: boolean): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_LOGBOOK_CHANGED_SORTING, stringProperty: sortKey, booleanProperty: isAscending
});

/**
 *  Changed tab in embedded data
 * @param dataName - name of selected tab
 */
export const projectOverviewEmbeddedDataTabChanged = (dataName: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_EMBEDDED_DATA_TAB_CHANGED, stringProperty: dataName
})

/**
 *  Changed tab in information panel
 * @param dataName - name of selected tab
 */
export const projectOverviewInformationTabChanged = (dataName: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_INFORMATION_TAB_CHANGED, stringProperty: dataName
})

/**
 * Changed tab in inventory window
 * @param dataName 
 */
export const projectOverviewInventoryTabChanged = (dataName: string): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_INVENTORY_TAB_CHANGED, stringProperty: dataName
})

export const projectOverviewInventoryObjectTypeChanged = (objectType: InventoryObjectType): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_INVENTORY_OBJECT_TYPE_CHANGED, inventoryObjectType: objectType
})

//#region Logbook add entry dialog

/**
 * 
 * @returns 
 */
export const projectOverviewShowLogbookElementAddEntryDialog = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_SHOW_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG
})

/**
 * 
 * @returns 
 */
export const projectOverviewHideLogbookElementAddEntryDialog = (): IGeovisProjectOverviewAction => ({
    type: PROJECT_OVERVIEW_HIDE_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG
})

//#endregion