//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GeovisCompanyType {
	Viewer = 10,
	Partner = 50,
	Owner = 100
}

const GeovisCompanyTypeToName = new Map<number, string>([
	[ 10, 'Viewer' ],
	[ 50, 'Partner' ],
	[ 100, 'Owner' ],
])

export function getGeovisCompanyTypeToName(element: GeovisCompanyType) : string {
    return GeovisCompanyTypeToName.get(element) || '';
}

const GeovisCompanyTypeByName = new Map<string, number>([
	[ 'Viewer', 10 ],
	[ 'Partner', 50 ],
	[ 'Owner', 100 ],
]);

export function getGeovisCompanyTypeByName(name: string): GeovisCompanyType {
    return GeovisCompanyTypeByName.get(name)!;
}


export const GeovisCompanyTypeList : Readonly<GeovisCompanyType[]> = [
	GeovisCompanyType.Viewer,
	GeovisCompanyType.Partner,
	GeovisCompanyType.Owner,
];



const GeovisCompanyTypeToDescription = new Map<number, string>([
	[ 10, 'Viewer' ],
	[ 50, 'Partner' ],
	[ 100, 'Owner' ],
])

export function getGeovisCompanyTypeToDescription(element: GeovisCompanyType) : string {
    return GeovisCompanyTypeToDescription.get(element) || '';
}

