/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 08.12.2019
 * @descriptions Project data creators
 */

import { ItemId } from "@atlaskit/tree";
import { successRequest } from "../../helpers/ProjectDataHelper";
import {
    IProjectLocalMapObjectsData,
    IProjectOverviewMainData
} from "../../helpers/ProjectDataHelper.types";
import { AttachedDocumentModel } from "../../server/AttachedDocumentModel";
import { AuthorizationCode } from "../../server/AuthorizationCode";
import { GEOvisDXFLayerType } from "../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { ProjectElementType } from "../../server/AVTService/TypeLibrary/Common/ProjectElementType";
import { LogbookModel } from "../../server/AVTService/TypeLibrary/Logbook/LogbookModel";
import { LogoFileInfo } from "../../server/AVTService/TypeLibrary/Logo/LogoFileInfo";
import { LabelModel } from "../../server/AVTService/TypeLibrary/Model/LabelModel";
import { ProjectVisualSettings } from "../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { ProjectSupportMessageTemplate } from "../../server/AVTService/TypeLibrary/ProjectSupport/ProjectSupportMessageTemplate";
import { Geovis4SensorsSymbolSettings } from "../../server/AVTService/TypeLibrary/Sensors/SymbolSettings/Geovis4SensorsSymbolSettings";
import { ChainInfo } from "../../server/ChainInfo";
import { ChainMeasurementInfo } from "../../server/ChainMeasurementInfo";
import { DataActionResponse } from "../../server/DataActionResponse";
import { ProjectCompaniesModel } from "../../server/GEOvis3/Model/Company/ProjectCompaniesModel";
import { GeovisMstModel } from "../../server/GEOvis3/Model/Database/GeovisMstModel";
import { DtsSectionInfo } from "../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfo";
import { LogbookPageRecordsInfo } from "../../server/GEOvis3/Model/Logbook/LogbookPageRecordsInfo";
import { LogbookProjectElement } from "../../server/GEOvis3/Model/Logbook/LogbookProjectElement";
import { MarkAsReadedLogbookRecordsResult } from "../../server/GEOvis3/Model/Logbook/MarkAsReadedLogbookRecordsResult";
import { GeovisLoRaRootConfiguration } from "../../server/GEOvis3/Model/LoRa/GeovisLoRaRootConfiguration";
import { GeovisProjectElementEntryModel } from "../../server/GEOvis3/Model/ProjectElements/GeovisProjectElementEntryModel";
import { GeovisProjectElementsCustomGroupModel } from "../../server/GEOvis3/Model/ProjectElements/GeovisProjectElementsCustomGroupModel";
import { ProjectElementsModel } from "../../server/GEOvis3/Model/ProjectElements/ProjectElementsModel";
import { ProjectViewInfo } from "../../server/GEOvis3/Model/ProjectViews/ProjectViewInfo";
import { SensorInfo } from "../../server/GEOvis3/Model/SensorInfo";
import { GeovisProjectStatistic } from "../../server/GEOvis3/Model/Statistics/GeovisProjectStatistic";
import { GeovisUserCommonInfo } from "../../server/GEOvis3/Model/User/GeovisUserCommonInfo";
import { MapViewport } from "../../server/MapViewport";
import { ProjectDXFLayerInfo } from "../../server/ProjectDXFLayerInfo";
import { ProjectInfo } from "../../server/ProjectInfo";
import { ProjectReportInfo } from "../../server/ProjectReportInfo";
import { ProjectSensorAlarmInfo } from "../../server/ProjectSensorAlarmInfo";
import { SensorMeasurementInfo } from "../../server/SensorMeasurementInfo";
import {
    PROJECT_COMPANIES_INFO,
    PROJECT_COMPANIES_INFO_LOADED,
    PROJECT_DATA_ADD_REPORT_INFO,
    PROJECT_DATA_ALARM_CONFIRMED,
    PROJECT_DATA_ALARM_DO_SWITCHED_OFF,
    PROJECT_DATA_ALARMS,
    PROJECT_DATA_ALARMS_DATA,
    PROJECT_DATA_APPEND_SENSORS_MEAS,
    PROJECT_DATA_ATTACHED_DOCUMENT_ADD,
    PROJECT_DATA_ATTACHED_DOCUMENT_DELETED,
    PROJECT_DATA_ATTACHED_DOCUMENT_UPDATE,
    PROJECT_DATA_ATTACHED_DOCUMENTS,
    PROJECT_DATA_ATTACHED_DOCUMENTS_DATA,
    PROJECT_DATA_ATTACHED_DOCUMENTS_ERROR,
    PROJECT_DATA_ATTACHED_DOCUMENTS_LOADED,
    PROJECT_DATA_CHAINS_INFO,
    PROJECT_DATA_CHAINS_INFO_DATA,
    PROJECT_DATA_CHAINS_INFO_ERROR,
    PROJECT_DATA_DXF_LAYER_DELETE,
    PROJECT_DATA_DXF_LAYERS,
    PROJECT_DATA_DXF_LAYERS_DATA,
    PROJECT_DATA_DXF_LAYERS_ERROR,
    PROJECT_DATA_INFORMATION_UPDATE,
    PROJECT_DATA_KEEP_ZOOM_CHANGED,
    PROJECT_DATA_LABEL_ADDED,
    PROJECT_DATA_LABEL_REMOVED,
    PROJECT_DATA_LABEL_UPDATED,
    PROJECT_DATA_LABELS,
    PROJECT_DATA_LABELS_DATA,
    PROJECT_DATA_LOCAL_MAP_OBJECTS,
    PROJECT_DATA_LOCAL_MAP_OBJECTS_DATA,
    PROJECT_DATA_LOGBOOK_AUTHORS_LOADED,
    PROJECT_DATA_LOGBOOK_AUTHORS_LOADING,
    PROJECT_DATA_LOGBOOK_AUTHORS_LOADING_ERROR,
    PROJECT_DATA_LOGBOOK_CHECK_ALL,
    PROJECT_DATA_LOGBOOK_ELEMENT_SAVE_ENTRY,
    PROJECT_DATA_LOGBOOK_ELEMENTS,
    PROJECT_DATA_LOGBOOK_ELEMENTS_DATA,
    PROJECT_DATA_LOGBOOK_ELEMENTS_ERROR,
    PROJECT_DATA_LOGBOOK_MARK_AS_READED,
    PROJECT_DATA_LOGBOOK_MARK_AS_READED_SELECTED,
    PROJECT_DATA_LOGBOOK_RECORD_SELECTION_CHANGED,
    PROJECT_DATA_LOGBOOK_RECORDS,
    PROJECT_DATA_LOGBOOK_RECORDS_DATA,
    PROJECT_DATA_LOGBOOK_RECORDS_ERROR,
    PROJECT_DATA_LOGOS_INFO,
    PROJECT_DATA_LOGOS_INFO_DATA,
    PROJECT_DATA_LOGOS_INFO_ERROR,
    PROJECT_DATA_OVERVIEW_CONFIGURATION,
    PROJECT_DATA_OVERVIEW_CONFIGURATION_DATA,
    PROJECT_DATA_PROPERTIES,
    PROJECT_DATA_PROPERTIES_DATA,
    PROJECT_DATA_PROPERTIES_ERROR,
    PROJECT_DATA_PROPERTIES_LOADED,
    PROJECT_DATA_REPORTS,
    PROJECT_DATA_REPORTS_DATA,
    PROJECT_DATA_RESET_MAP_VIEWPORT_OF_VIEW,
    PROJECT_DATA_SENSORS_CHAINS_LOADED,
    PROJECT_DATA_SENSORS_CHAINS_LOADING,
    PROJECT_DATA_SENSORS_CHAINS_MEAS_LOADED,
    PROJECT_DATA_SENSORS_INFO,
    PROJECT_DATA_SENSORS_INFO_DATA,
    PROJECT_DATA_SENSORS_INFO_ERROR,
    PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADED,
    PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADING,
    PROJECT_DATA_SET_MAP_VIEW_STATE,
    PROJECT_DATA_SHOW_LEGEND,
    PROJECT_DATA_STATISTIC,
    PROJECT_DATA_STATISTIC_DATA,
    PROJECT_DATA_STATISTIC_ERROR,
    PROJECT_DATA_SUPPORT_CANCEL_EDIT,
    PROJECT_DATA_SUPPORT_CREATE_NEW,
    PROJECT_DATA_SUPPORT_DELETE_TEMPLATE,
    PROJECT_DATA_SUPPORT_HIDE_DELETE_DIALOG,
    PROJECT_DATA_SUPPORT_LOADED,
    PROJECT_DATA_SUPPORT_LOADING,
    PROJECT_DATA_SUPPORT_SAVE_MESSAGE_TEMPLATE,
    PROJECT_DATA_SUPPORT_SELECT_TEMPLATE_ID,
    PROJECT_DATA_SUPPORT_SHOW_DELETE_DIALOG,
    PROJECT_DATA_SUPPORT_START_EDIT_TEMPLATE,
    PROJECT_DATA_SYMBOL_SETTINGS,
    PROJECT_DATA_SYMBOL_SETTINGS_DATA,
    PROJECT_DATA_SYMBOL_SETTINGS_ERROR,
    PROJECT_DATA_SYMBOLS_SETTINGS_RESPONSE_LOADED,
    PROJECT_DATA_UPDATE_PROJECT_VIEWPORT,
    PROJECT_DATA_USERS,
    PROJECT_DATA_USERS_DATA,
    PROJECT_DATA_USERS_ERROR,
    PROJECT_DATA_USERS_UPDATE,
    PROJECT_DATA_VIEW_SELECTED,
    PROJECT_DATA_VIEW_TREE_FILTER_CHANGED,
    PROJECT_DATA_VIEW_TREE_FILTER_NODE_COLLAPSED,
    PROJECT_DATA_VIEW_TREE_FILTER_NODE_EXPANDED,
    PROJECT_DATA_VIEW_TREE_ITEM_COLLAPSE,
    PROJECT_DATA_VIEW_TREE_ITEM_EXPAND,
    PROJECT_DATA_VIEW_TREE_ITEM_SELECTION_CHANGE,
    PROJECT_DATA_VIEW_TREE_SEARCH,
    PROJECT_DATA_VIEW_UPDATE_VIEWPORT,
    PROJECT_DATA_VISUAL_SETTINGS,
    PROJECT_DATA_VISUAL_SETTINGS_DATA,
    PROJECT_DATA_VISUAL_SETTINGS_ERROR,
    PROJECT_DATABASE_ADDED,
    PROJECT_DATABASE_REMOVED,
    PROJECT_DATABASE_UPDATED,
    PROJECT_DATABASES_IN_PROGRESS,
    PROJECT_DATABASES_LOADED,
    PROJECT_DATABASES_LOADING_ERROR,
    PROJECT_DTS_SECTION_ADDED,
    PROJECT_DTS_SECTION_REMOVED,
    PROJECT_DTS_SECTION_UPDATED,
    PROJECT_DTS_SECTIONS_LOADED,
    PROJECT_DTS_SECTIONS_LOADING,
    PROJECT_DTS_SECTIONS_LOADING_ERROR,
    PROJECT_ELEMENT_ENTRIES_LOADED,
    PROJECT_ELEMENT_ENTRIES_LOADING,
    PROJECT_ELEMENT_ENTRY_ADDED,
    PROJECT_ELEMENT_ENTRY_REMOVED,
    PROJECT_ELEMENT_ENTRY_UPDATED,
    PROJECT_ELEMENTS_CUSTOM_GROUPS_UPDATED,
    PROJECT_LORA_ROOT_CONFIGURATION,
    PROJECT_LORA_ROOT_CONFIGURATION_DATA,
    PROJECT_PROFILE_VIEW_CHANGED,
    PROJECT_PROFILE_VIEWS,
    PROJECT_PROFILE_VIEWS_DATA,
    PROJECT_PROFILE_VIEWS_ERROR,
    PROJECT_SETTINGS_NOTIFICATIONS_CHANGED,
    PROJECT_VIEWS_LOADED,
    PROJECT_VIEWS_LOADING,
    PROJECT_VIEWS_LOADING_ERROR,
    PROJECT_DATA_REPORT_PUBLIC_CHANGED,
    PROJECT_DATA_OVERVIEW_CHANGED,
    PROJECT_DATA_GEORASTER_LOADING,
    PROJECT_DATA_GEORASTER_LOADED,
    PROJECT_DATA_GEORASTER_LOADING_ERROR,
    PROJECT_DATA_GEORASTER_REMOVED,
    PROJECT_DATA_GEORASTER_ADDED,
    PROJECT_DATA_GEORASTER_UPDATED,
    PROJECT_VIEW_DATA_GEORASTERS_LOADING,
    PROJECT_VIEW_DATA_GEORASTERS_LOADING_ERROR,
    PROJECT_VIEW_DATA_GEORASTERS_LOADED,
    PROJECT_VIEW_DATA_CURRENT_GEORASTER_CHANGED,
    PROJECT_DATA_SENSOR_INFO_UPDATED,
    PROJECT_DATA_SENSOR_INFO_DELETED,
} from "../actions/dataActions";
import { IGeovisProjectDataAction, IGeovisProjectDataActionEx, ISensorFilter } from "../data.types";
import { IMapViewState } from "../types";
import { ProjectViewConfig } from "../../server/AVTService/TypeLibrary/ProjectViews/ProjectViewConfig";
import { GeoRasterFileModel } from "../../server/AVTService/TypeLibrary/Model/GeoRasterFileModel";
import { GeoRasterSlimModel } from "../../server/GEOvis3/Model/GeoRasters/GeoRasterSlimModel";

export const projectDataSetMapViewState = (mapViewState: IMapViewState): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SET_MAP_VIEW_STATE, mapViewState
});

export const projectDataResetProjectViewMapViewport = (viewId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_RESET_MAP_VIEWPORT_OF_VIEW, viewId
});

/**
 * Switch project overview visibility
 * @param show 
 */
export const projectDataShowLegend = (show: boolean): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SHOW_LEGEND, booleanProperty: show
});

/**
 * Set project overview configuration in to loading state
 */
export const projectDataOverviewConfiguration = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_OVERVIEW_CONFIGURATION
});

/**
 * Project overview main configuration data
 * @param mainData 
 */
export const projectDataOverviewConfigurationData = (mainConfiguration: IProjectOverviewMainData): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_OVERVIEW_CONFIGURATION_DATA, mainConfiguration
});

export const projectCompaniesInfo = (): IGeovisProjectDataAction => ({
    type: PROJECT_COMPANIES_INFO
});

export const projectCompaniesInfoLoaded = (prjCompaniesInfo: ProjectCompaniesModel): IGeovisProjectDataAction => ({
    type: PROJECT_COMPANIES_INFO_LOADED, projectCompaniesInfo: prjCompaniesInfo
});

// #region Project properties

/**
 * Start loading project info
 */
export const projectDataProperties = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_PROPERTIES
});

/**
 * Save project info to data storage
 * @param project 
 */
export const projectDataPropertiesData = (project: ProjectInfo): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_PROPERTIES_DATA, project
})

/**
 * Set project info storage
 * @param projectInfoData 
 */
export const projectDataPropertiesLoaded = (projectInfoData: DataActionResponse<ProjectInfo>): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_PROPERTIES_LOADED, projectInfoData
});

/**
 * Set an error state of the project info storage
 * @returns 
 */
export const projectDataPropertiesError = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_PROPERTIES_ERROR
});

/**
 * Update project default viewport
 * @param viewport 
 */
export const projectDataUpdateProjectViewport = (viewport: MapViewport): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_UPDATE_PROJECT_VIEWPORT, viewport
});

export const projectDataOverviewChanged = (view: ProjectViewConfig): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_OVERVIEW_CHANGED, projectOverview: view
});

// #endregion

// #region Attached documents

/**
 * Start loading attached documents
 */
export const projectDataAttachedDocuments = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ATTACHED_DOCUMENTS
});

/**
 * Set attached documents to storage
 * @param documents Attached documents data
 */
export const projectDataAttachedDocumentsData = (documents: AttachedDocumentModel[]): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ATTACHED_DOCUMENTS_DATA, documents
});

/**
 * Set attached documents storage to error state
 * @param errorDescription 
 */
export const projectDataAttachedDocumentsError = (errorDescription: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ATTACHED_DOCUMENTS_ERROR, errorDescription
});

/**
 * Set attached documents storage
 * @param attachedDocumentsData 
 */
export const projectDataAttachedDocumentsLoaded = (attachedDocumentsData: DataActionResponse<AttachedDocumentModel[]>): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ATTACHED_DOCUMENTS_LOADED, attachedDocumentsData
});

/**
 * Add document to storage
 * @param document Attached document model
 */
export const projectDataAttachedDocumentAdd = (document: AttachedDocumentModel): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ATTACHED_DOCUMENT_ADD, documents: [document]
});

/**
 * Update document to storage
 * @param document Attached document model
 */
export const projectDataAttachedDocumentUpdate = (document: AttachedDocumentModel): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ATTACHED_DOCUMENT_UPDATE, documents: [document]
});

/**
 * Remove attached document in storage
 * @param objectId target document id
 */
export const projectDataAttachedDocumentDeleted = (objectId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ATTACHED_DOCUMENT_DELETED, objectId
});

// #endregion

// #region DXF layers

/**
 * Start loading project dxf layers info
 */
export function projectDataDxfLayers(): IGeovisProjectDataAction {
    return { type: PROJECT_DATA_DXF_LAYERS };
}

/**
 * Store DXF layers info in to data storage
 * @param dxfLayers 
 */
export function projectDataDxfLayersData(dxfLayers: ProjectDXFLayerInfo[]): IGeovisProjectDataAction {
    return { type: PROJECT_DATA_DXF_LAYERS_DATA, dxfLayers };
}

/**
 * Error to load dxf layers
 * @param errorDescription 
 */
export const projectDataDxfLayersError = (errorDescription: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_DXF_LAYERS_ERROR, errorDescription
})

/**
 * Delete DXF layer in storage
 * @param objectId 
 */
export function projectDataDxfLayerDelete(objectId: string): IGeovisProjectDataAction {
    return { type: PROJECT_DATA_DXF_LAYER_DELETE, objectId };
}

// #endregion

// #region Local map objects

/**
 * Creator to navigate to edit project Local Map Objects
 */
export const projectDataLocalMapObjects = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOCAL_MAP_OBJECTS
});

/**
 * Set local map objects to project local map objects store
 * @param localMapObjects 
 */
export const projectDataLocalMapObjectsData = (localMapObjectsData: IProjectLocalMapObjectsData): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOCAL_MAP_OBJECTS_DATA, localMapObjectsData
});

// #endregion

// #region Sensors and chains info/data

/**
 * Switch sensors info storage in to loading state
 */
export const projectDataSensorsInfo = (): IGeovisProjectDataAction => ({ type: PROJECT_DATA_SENSORS_INFO });

/**
 * Set sensors info to data to storage
 * @param sensorsInfo 
 */
export const projectDataSensorsInfoData = (sensorsInfo: DataActionResponse<SensorInfo[]>): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SENSORS_INFO_DATA, sensorsInfo
});

/**
 * Set sensors info store to error state
 * @param errorDescription 
 */
export const projectDataSensorsInfoError = (errorDescription: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SENSORS_INFO_ERROR, errorDescription
});

export const projectDataSensorsUpdateOne = (sensor: SensorInfo): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SENSOR_INFO_UPDATED, sensorInfo: sensor
});

export const projectDataSensorsDeleteOne = (sensorFullID: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SENSOR_INFO_DELETED, stringProperty: sensorFullID
});

/**
 * Set chains info to loading state
 */
export const projectDataChainsInfo = (): IGeovisProjectDataAction => ({ type: PROJECT_DATA_CHAINS_INFO });

/**
 * Set chains info in to storage
 * @param chainsInfo 
 */
export const projectDataChainsInfoData = (chainsInfo: DataActionResponse<ChainInfo[]>): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_CHAINS_INFO_DATA, chainsInfo
});

/**
 * Set chains info storage in to error state
 * @param errorDescription 
 */
export const projectDataChainsInfoError = (errorDescription: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_CHAINS_INFO_ERROR, errorDescription
});

/**
 * Set in to loading state next stores:
 * - sensor info 
 * - chains info
 * - sensor data
 * - chains data
 */
export const projectDataSensorsChainsLoading = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SENSORS_CHAINS_LOADING
});

/**
 * Set sensors and chains loaded storage in to data loaded state/or error
 * @param sensorsInfo 
 * @param chainsInfo 
 */
export const projectDataSensorsChainsLoaded = (sensorsInfo: DataActionResponse<SensorInfo[]>, chainsInfo: DataActionResponse<ChainInfo[]>): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SENSORS_CHAINS_LOADED, sensorsInfo, chainsInfo
});

/**
 * Set sensors and chains data storage in to loaded state/or in to error
 * @param sensorsLastMeasurements 
 * @param chainsLastMeasurements 
 */
export const projectDataSensorsChainsDataLoaded = (
    sensorsLastMeasurements: DataActionResponse<SensorMeasurementInfo[]>,
    chainsLastMeasurements: DataActionResponse<ChainMeasurementInfo[]>): IGeovisProjectDataAction => ({
        type: PROJECT_DATA_SENSORS_CHAINS_MEAS_LOADED, sensorsLastMeasurements, chainsLastMeasurements
    });

/**
 * Append sensors measurements to the storage
 * @returns 
 */
export const projectDataAppendSensorsMeas = (data: SensorMeasurementInfo[]): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_APPEND_SENSORS_MEAS, sensorsLastMeasurements: {
        Data: data,
        AuthorizationCode: AuthorizationCode.OK,
        Success: true,
        JsonPath: '',
        Messages: [],
        FailedFields: []
    }
});

// #region sensors last measurements
export const projectDataSensorsLastMeasurementsLoading = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADING
});

export const projectDataSensorsLastMeasurementsLoaded = (sensorsLastMeasurements: DataActionResponse<SensorMeasurementInfo[]>, append: boolean = false): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADED, sensorsLastMeasurements, booleanProperty: append
});

export const projectDataLastMeasurementsLoadingError = (errorDescription: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADED, errorDescription
});

// #endregion

// #region Project visual settings

/**
 * Start loading visual settings
 */
export function projectDataVisualSettings(): IGeovisProjectDataAction {
    return { type: PROJECT_DATA_VISUAL_SETTINGS };
}

/**
 * Store sensors symbols settings to storage
 * @param symbolSettings 
 */
export const projectDataVisualSettingsData = (visualSettings: ProjectVisualSettings): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VISUAL_SETTINGS_DATA, visualSettings
});

/**
 * Error to load visual settings
 */
export const projectDataVisualSettingsError = (error: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VISUAL_SETTINGS_ERROR, errorDescription: error
});

// #endregion

// #region Sensors symbols

/**
 * Start loading sensors symbols settings
 */
export function projectDataSensorsSymbolsSettings(): IGeovisProjectDataAction {
    return { type: PROJECT_DATA_SYMBOL_SETTINGS };
}

/**
 * Store sensors symbols settings to storage
 * @param symbolSettings 
 */
export const projectDataSensorsSymbolsSettingsData = (symbolSettings: Geovis4SensorsSymbolSettings): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SYMBOL_SETTINGS_DATA, symbolSettings
});

/**
 * Error to load sensors symbols settings
 */
export const projectDataSensorsSymbolsSettingsError = (error: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SYMBOL_SETTINGS_ERROR, errorDescription: error
});


export const projectDataSensorsSymbolsSettingsResponseLoaded = (symbolSettingsResponse: DataActionResponse<Geovis4SensorsSymbolSettings>): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SYMBOLS_SETTINGS_RESPONSE_LOADED, symbolSettingsResponse
});

// #endregion

// #region Project views

/**
 * Update project default viewport
 * @param mapViewport 
 */
export const projectDataViewUpdateViewport = (viewId: string, viewport: MapViewport): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VIEW_UPDATE_VIEWPORT, viewport, viewId
});

// #endregion

// #region recentAlarmsStorage

/**
 * Start loading project alarms
 */
export const projectDataAlarms = (alarmStorageName: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ALARMS, alarmStorageName
});

/**
 * Store project alarms in storage
 * @param alarmStorageName
 * @param alarms
 */
export const projectDataAlarmsData = (alarmStorageName: string, alarms: DataActionResponse<ProjectSensorAlarmInfo[]>): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ALARMS_DATA, alarmStorageName, alarms
});

export const projectDataAlarmsConfirmed = (alarmId: string, stringValue: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ALARM_CONFIRMED, alarmId, stringValue
})

export const projectDataAlarmDOSwitchedOff = (alarmId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ALARM_DO_SWITCHED_OFF, alarmId
})

// #endregion

// #region reportsStorage 

/**
 * Start loading project reports
 */
export const projectDataReports = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_REPORTS
});

/**
 * Store reports in project storage
 * @param reportsInfo 
 */
export const projectDataReportsData = (reportsInfo: DataActionResponse<ProjectReportInfo[]>): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_REPORTS_DATA, reportsInfo
});

/**
 * Add project report info in to storage
 * @param reportInfo
 * @returns 
 */
export const projectDataAddReportInfo = (reportInfo: ProjectReportInfo): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_ADD_REPORT_INFO, reportInfo
})


export const projectDataChangeReportIsPublic = (reportId: number): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_REPORT_PUBLIC_CHANGED, numberProperty: reportId
})

// #endregion

// #region logbookElementsStorage

/**
 * Start loading project public logbook elements
 */
export const projectDataLogbookElements = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_ELEMENTS
});

/**
 * Project public logbook elements
 * @param logbookElements 
 */
export const projectDataLogbookElementsData = (logbookElements: LogbookProjectElement[]): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_ELEMENTS_DATA, logbookElements
});

/**
 * Set project logbook elements in to error state
 * @param errorDescription 
 */
export const projectDataLogbookElementsError = (errorDescription: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_ELEMENTS_ERROR, errorDescription
})

/**
 * Start loading logbook records
 */
export const projectDataLogbookRecords = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_RECORDS
});

/**
 * Project logbook records of one page
 * @param pageData logbook records page data
 */
// export const projectDataLogbookRecordsData = (pageData: LogbookPageRecordsInfo, request: LogbookElementsRequest): IGeovisProjectDataAction => ({
//     type: PROJECT_DATA_LOGBOOK_RECORDS_DATA, logbookRecordsData: pageData, logbookRecordsViewState: request
// });
export const projectDataLogbookRecordsData = (pageData: LogbookPageRecordsInfo): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_RECORDS_DATA, logbookRecordsData: pageData
});

/**
 * Set logbook records store in to error state
 * @param errorDescription 
 */
export const projectDataLogbookRecordsError = (errorDescription: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_RECORDS_ERROR, errorDescription
});

export const projectOverviewLogbookCheckAll = (value: boolean): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_CHECK_ALL, booleanProperty: value
});

export const projectOverviewLogbookRecordSelectionChanged = (recordId: string, value: boolean): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_RECORD_SELECTION_CHANGED, stringProperty: recordId, booleanProperty: value
});

/**
 * Mark logbook records as readed/unreaded
 * @param elementId logbook element id
 * @param result changing logbook records result
 * @returns 
 */
export const projectOverviewLogbookMarkAsReadedSelected = (elementId: number, result: MarkAsReadedLogbookRecordsResult): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_MARK_AS_READED_SELECTED, elementId, changingLogbooksResult: result
})

export const projectDataLogbookElementSaveEntry = (logbookModel: LogbookModel): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_ELEMENT_SAVE_ENTRY, logbookModel
});

export const projectDataLogbookAuthorsLoading = (projectId: number): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_AUTHORS_LOADING, numberProperty: projectId
});

export const projectDataLogbookAuthorsLoadingError = (error: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_AUTHORS_LOADING_ERROR, stringProperty: error
});

export const projectDataLogbookAuthorsLoaded = (authors: GeovisUserCommonInfo[]): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_AUTHORS_LOADED, projectUsers: authors
});

/**
 * Decrease number of unreaded elements for selected project report logbooks element
 * @param elementId
 * @param logbook
 * @param userId
 * @returns 
 */
export const projectDataLogbookMarkAsReaded = (elementId: number, logbook: LogbookModel, userId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGBOOK_MARK_AS_READED, elementId, logbookModel: logbook, stringValue: userId
})

// #endregion

//#region Project logos info

export const projectDataLogosInfo = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGOS_INFO
});

export const projectDataLogosInfoError = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGOS_INFO_ERROR
});

export const projectDataLogosInfoData = (logoFilesInfo: LogoFileInfo[]): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LOGOS_INFO_DATA, logoFilesInfo
});

//#endregion

// #region  Project view tree data

/**
 * Expand project view tree item
 * @param itemId 
 */
export const projectDataViewTreeItemExpand = (itemId: ItemId): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VIEW_TREE_ITEM_EXPAND, itemId
});

/**
 * Collapse project view tree item
 * @param itemId 
 */
export const projectDataViewTreeItemCollapse = (itemId: ItemId): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VIEW_TREE_ITEM_COLLAPSE, itemId
});

/**
 * Create GEOvis project view tree data
 * @param searchQuery 
 */
export const projectDataViewTreeSearch = (searchQuery: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VIEW_TREE_SEARCH, searchQuery
});

export const projectDataViewTreeFilterChanged = (sensorFilter: ISensorFilter): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VIEW_TREE_FILTER_CHANGED, sensorFilter
});

export const projectDataViewTreeFilterNodeExpanded = (nodeId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VIEW_TREE_FILTER_NODE_EXPANDED, stringProperty: nodeId
});

export const projectDataViewTreeFilterNodeCollapsed = (nodeId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VIEW_TREE_FILTER_NODE_COLLAPSED, stringProperty: nodeId
});

/**
 * Switch view id to another
 * @param viewId 
 */
export const projectDataViewSelected = (viewId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VIEW_SELECTED, viewId
});

export const projectDataViewTreeItemSelectionChange = (nodesIds: string[], selected: boolean): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_VIEW_TREE_ITEM_SELECTION_CHANGE, stringValues: nodesIds, booleanProperty: selected
})

// #endregion

//#region  Project statistic

/**
 * Start loading project statistic
 */
export const projectDataStatistic = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_STATISTIC
});

/**
 * Statistic loading error
 */
export const projectDataStatisticError = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_STATISTIC_ERROR
});

/**
 * Set project statistic data
 * @param projectStatistic 
 */
export const projectDataStatisticData = (projectStatistic: GeovisProjectStatistic): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_STATISTIC_DATA, projectStatistic
})

//#endregion

//#region Project users

/**
 * Start loading project users
 */
export const projectDataUsers = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_USERS
});

/**
 * Project users loading error
 */
export const projectDataUsersError = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_USERS_ERROR
});

/**
 * Set project users data
 * @param projectUsers 
 */
export const projectDataUsersData = (projectUsers: GeovisUserCommonInfo[]): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_USERS_DATA, projectUsers
});

/**
 * Update project users in storage
 * @param projectUsers 
 */
export const projectDataUsersUpdate = (...projectUsers: GeovisUserCommonInfo[]): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_USERS_UPDATE, projectUsers
});

//#endregion

//#region Labels

/**
 * Switch project labels storage to loading state
 */
export const projectDataLabels = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LABELS
});

/**
 * Set loaded project labels in the storage
 * @param labels 
 */
export const projectDataLabelsData = (labels: DataActionResponse<LabelModel[]>): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LABELS_DATA, labels
});

export const projectDataLabelAdded = (label: LabelModel): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LABEL_ADDED, label
});

export const projectDataLabelUpdated = (label: LabelModel): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LABEL_UPDATED, label
});

export const projectDataLabelRemoved = (labelId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_LABEL_REMOVED, stringProperty: labelId
});


//#endregion

//#endregion

export const projectDataInformationUpdate = (info: string, tabName: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_INFORMATION_UPDATE, stringValue: info, tabName
})

export const projectDataKeepZoomChanged = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_KEEP_ZOOM_CHANGED
})

//#region Project support

/**
 * Switch project support storage in loading
 */
export const projectDataSupportDataLoading = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SUPPORT_LOADING
});

/**
 * Set project support data state
 * @param data - project support message templates data
 */
export const projectDataSupportDataLoaded = (data: DataActionResponse<ProjectSupportMessageTemplate[]>): IGeovisProjectDataActionEx<ProjectSupportMessageTemplate[]> => ({
    type: PROJECT_DATA_SUPPORT_LOADED, data
});

/**
 * Set selected support message template
 * @param templateId 
 */
export const projectDataSelectSupportTemplateId = (templateId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SUPPORT_SELECT_TEMPLATE_ID, stringValue: templateId
})

/**
 * Save project support message template
 * @param template - project message template
 */
export const projectDataSaveSupportMessageTemplate = (template: ProjectSupportMessageTemplate): IGeovisProjectDataActionEx<ProjectSupportMessageTemplate[]> => ({
    type: PROJECT_DATA_SUPPORT_SAVE_MESSAGE_TEMPLATE, data: successRequest<ProjectSupportMessageTemplate[]>([template])
});

/**
 * Start edit the template
 */
export const projectDataSupportStartEditTemplate = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SUPPORT_START_EDIT_TEMPLATE
});

/**
 * Cancel edit current template and return original state
 */
export const projectDataSupportCancelEdit = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SUPPORT_CANCEL_EDIT
});

/**
 * Start to create new template
 */
export const projectDataSupportCreateNewTemplate = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SUPPORT_CREATE_NEW
});

/**
 * Hide remove template dialog
 */
export const projectDataSupportHideDeleteDialog = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SUPPORT_HIDE_DELETE_DIALOG
});

/**
 * Delete the support message template in storage
 * @param templateId 
 */
export const projectDataSupportDeleteTemplate = (templateId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_SUPPORT_DELETE_TEMPLATE, stringValue: templateId
});

/**
 * Show delete delete message template dialog
 */
export const projectDataSupportShowRemoveTemplateDialog = (): IGeovisProjectDataAction => ({ type: PROJECT_DATA_SUPPORT_SHOW_DELETE_DIALOG });

//#endregion

//#region LoRa

export const projectLoraRootConfiguration = (): IGeovisProjectDataAction => ({
    type: PROJECT_LORA_ROOT_CONFIGURATION
});

export const projectLoraRootConfigurationData = (data: DataActionResponse<GeovisLoRaRootConfiguration>): IGeovisProjectDataActionEx<GeovisLoRaRootConfiguration> => ({
    type: PROJECT_LORA_ROOT_CONFIGURATION_DATA, data
});

//#endregion

export const projectSettingsNotificationsChanged = (project: ProjectInfo): IGeovisProjectDataAction => ({
    type: PROJECT_SETTINGS_NOTIFICATIONS_CHANGED, project
});

//#region Profile Views


export const projectProfileViews = (type: GEOvisDXFLayerType): IGeovisProjectDataAction => ({ type: PROJECT_PROFILE_VIEWS, typeOfDxfView: type });

export const projectProfileViewsData = (profileViews: ProjectDXFLayerInfo[], type: GEOvisDXFLayerType): IGeovisProjectDataAction => ({
    type: PROJECT_PROFILE_VIEWS_DATA, dxfViews: profileViews, typeOfDxfView: type
});

export const projectProfileViewsError = (error: string, type: GEOvisDXFLayerType): IGeovisProjectDataAction => ({
    type: PROJECT_PROFILE_VIEWS_ERROR, stringProperty: error, typeOfDxfView: type
});

export const projectProfileViewChanged = (viewId: string, type: GEOvisDXFLayerType): IGeovisProjectDataAction => ({
    type: PROJECT_PROFILE_VIEW_CHANGED, stringProperty: viewId, typeOfDxfView: type
});

//#endregion

//#region Databases

export const projectDatabasesInProgress = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATABASES_IN_PROGRESS
});

export const projectDatabasesLoaded = (projectId: number, msts: GeovisMstModel[]): IGeovisProjectDataAction => ({
    type: PROJECT_DATABASES_LOADED, msts, numberProperty: projectId
});

export const projectDatabasesLoadingError = (error: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATABASES_LOADING_ERROR, stringProperty: error
});

export const projectDatabaseAdded = (mst: GeovisMstModel): IGeovisProjectDataAction => ({
    type: PROJECT_DATABASE_ADDED, mst
});

export const projectDatabaseRemoved = (mstId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATABASE_REMOVED, mstId
});

export const projectDatabaseUpdated = (mst: GeovisMstModel): IGeovisProjectDataAction => ({
    type: PROJECT_DATABASE_UPDATED, mst
});

//#endregion

//#region DTS Configuration

export const projectDtsSectionsLoading = (): IGeovisProjectDataAction => ({
    type: PROJECT_DTS_SECTIONS_LOADING
});

export const projectDtsSectionsLoaded = (dtsSectionInfos: DtsSectionInfo[]): IGeovisProjectDataAction => ({
    type: PROJECT_DTS_SECTIONS_LOADED, dtsSectionInfos
});

export const projectDtsSectionsLoadingError = (error: string): IGeovisProjectDataAction => ({
    type: PROJECT_DTS_SECTIONS_LOADING_ERROR, stringProperty: error
});

export const projectDtsSectionAdded = (dtsSectionInfo: DtsSectionInfo): IGeovisProjectDataAction => ({
    type: PROJECT_DTS_SECTION_ADDED, dtsSectionInfo
});

export const projectDtsSectionInfoUpdateInList = (dtsSectionInfo: DtsSectionInfo): IGeovisProjectDataAction => ({
    type: PROJECT_DTS_SECTION_UPDATED, dtsSectionInfo
});

export const projectDtsSectionRemoved = (dtsSectionId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DTS_SECTION_REMOVED, dtsSectionId
});

//#endregion

//#region project elements

export const projectElementEntriesLoading = (): IGeovisProjectDataAction => ({
    type: PROJECT_ELEMENT_ENTRIES_LOADING
});

export const projectElementEntriesLoaded = (projectElementsData: DataActionResponse<ProjectElementsModel>): IGeovisProjectDataAction => ({
    type: PROJECT_ELEMENT_ENTRIES_LOADED, projectElementsData
});

/**
 * Add element in to storage and open it to edit
 * @param elementEntry 
 * @returns 
 */
export const projectElementEntryAdded = (elementEntry: GeovisProjectElementEntryModel, customElementsGroups: GeovisProjectElementsCustomGroupModel[]): IGeovisProjectDataAction => ({
    type: PROJECT_ELEMENT_ENTRY_ADDED, elementEntry, customElementsGroups
});

export const projectElementEntryUpdated = (elementEntry: GeovisProjectElementEntryModel, customElementsGroups: GeovisProjectElementsCustomGroupModel[]): IGeovisProjectDataAction => ({
    type: PROJECT_ELEMENT_ENTRY_UPDATED, elementEntry, customElementsGroups
});

export const projectElementEntryRemoved = (elementId: number, elementType: ProjectElementType, customElementsGroups: GeovisProjectElementsCustomGroupModel[]): IGeovisProjectDataAction => ({
    type: PROJECT_ELEMENT_ENTRY_REMOVED, elementType, elementId, customElementsGroups
});

export const projectElementsCustomGroupsUpdated = (customElementsGroups: GeovisProjectElementsCustomGroupModel[]): IGeovisProjectDataAction => ({
    type: PROJECT_ELEMENTS_CUSTOM_GROUPS_UPDATED, customElementsGroups
})

//#endregion

//#region

export const projectViewsLoading = (): IGeovisProjectDataAction => ({
    type: PROJECT_VIEWS_LOADING
});

export const projectViewsLoaded = (projectViewsResponse: DataActionResponse<ProjectViewInfo[]>): IGeovisProjectDataAction => ({
    type: PROJECT_VIEWS_LOADED, projectViewsResponse
});

export const projectViewsLoadingError = (errorDescription: string): IGeovisProjectDataAction => ({
    type: PROJECT_VIEWS_LOADING_ERROR, errorDescription
});

//#endregion

//#region

export const projectGeoRasterLoading = (): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_GEORASTER_LOADING
});

export const projectGeoRasterLoaded = (projectGeoRasterResponse: DataActionResponse<GeoRasterFileModel[]>): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_GEORASTER_LOADED, projectGeoRasterResponse
});

export const projectGeoRasterLoadingError = (errorDescription: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_GEORASTER_LOADING_ERROR, errorDescription
});

export const projectGeoRasterRemoved = (rasterId: string): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_GEORASTER_REMOVED, stringProperty: rasterId
});

export const projectGeoRasterAdded = (raster: GeoRasterFileModel): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_GEORASTER_ADDED, raster
});

export const projectGeoRasterUpdated = (raster: GeoRasterFileModel): IGeovisProjectDataAction => ({
    type: PROJECT_DATA_GEORASTER_UPDATED, raster
});


export const projectViewGeoRastersLoading = (): IGeovisProjectDataAction => ({
    type: PROJECT_VIEW_DATA_GEORASTERS_LOADING
});

export const projectViewGeoRastersLoadingError = (error: string): IGeovisProjectDataAction => ({
    type: PROJECT_VIEW_DATA_GEORASTERS_LOADING_ERROR, stringProperty: error
});

export const projectViewGeoRastersLoaded = (rasters: GeoRasterSlimModel[]): IGeovisProjectDataAction => ({
    type: PROJECT_VIEW_DATA_GEORASTERS_LOADED, slimRasters: rasters
});

export const projectViewCurrentGeoRasterChanged = (rasterId: string): IGeovisProjectDataAction => ({
    type: PROJECT_VIEW_DATA_CURRENT_GEORASTER_CHANGED, stringProperty: rasterId
});

//#endregion