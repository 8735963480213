/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 23.02.2023
 * @description Geovis date time picker tool methods
 */

import { isValid, parse, parseISO } from "date-fns";
import { isArray } from "lodash";

/**
 * Parse string to datetime
 */
export const geovisDateTimePickerParseDate = (strDate: string, dateFormat?: string | string[]): Date => {
    const defaultDate = new Date();

    let parsedDate = defaultDate;
    if (dateFormat) {
        if (isArray(dateFormat)) {
            for(const format of dateFormat) {
                parsedDate = parse(strDate, format, defaultDate);
                if (isValid(parsedDate)) {
                    return parsedDate;
                }
            }
        } else {
            parsedDate = parse(strDate, dateFormat, defaultDate);
            if (isValid(parsedDate)) {
                return parsedDate;
            }
        }
    }

    parsedDate = parse(strDate, 'yyyy/MM/dd HH:mm', defaultDate);
    if (isValid(parsedDate)) {
        return parsedDate;
    }

    parsedDate = parseISO(strDate);
    if (isValid(parsedDate)) {
        return parsedDate;
    }

    parsedDate = parse(strDate, 'dd.MM.yyyy HH:mm', defaultDate);
    if (isValid(parsedDate)) {
        return parsedDate;
    }

    parsedDate = parse(strDate, 'dd.MM.yyyy', defaultDate);
    if (isValid(parsedDate)) {
        return parsedDate;
    }

    parsedDate = parse(strDate, 'yyyy-MM-dd', defaultDate);
    if (isValid(parsedDate)) {
        return parsedDate;
    }

    parsedDate = new Date(strDate);
    if (isValid(parsedDate)) {
        return parsedDate;
    }

    throw Error(`Failed to parse the date ${strDate}`);
}