/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 07.02.2023
 * @description The tool methods for user feedback dialog
 */

import { GeovisUserFeedbackModel } from "../../../../server/AVTService/TypeLibrary/Model/ApplicationFeedback/GeovisUserFeedbackModel";

/**
 * Check if submit user feedback allowed
 * @param param0 
 * @returns 
 */
export const isAllowSubmitUserFeedback = ({ Name, Message }: GeovisUserFeedbackModel): boolean => {
    return Name !== undefined && Message !== undefined && Name.length > 0 && Message.length > 0;
}