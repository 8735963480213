import { IGeovisLogbookReportData, IGeovisLogbookReportInfo, IGeovisReportPageConfig, IGeovisReportPageData } from "../../../../../store/projectReport.types";

export const getGeovisLogbookInfo = (page: IGeovisReportPageConfig | undefined, logbookId: number): IGeovisLogbookReportInfo | false => {
    if (!page) {
        return false;
    }

    return page.geovisLogbooks.get(logbookId) || false;
}

export const getGeovisImageData = (page: IGeovisReportPageData | undefined, logbookId: number): IGeovisLogbookReportData | false => {
    if (!page) {
        return false;
    }

    return page.geovisLogbookData.get(logbookId) || false;
}