/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 20.05.2022
 * @description Heatmap chart renderer
 */

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { t } from "../../../../../../i18n";
import "../../../../../../libs/delaunay"
import HighchartsHeatmapContour from '../../../../../../libs/highcharts-contour';
import { HeatmapChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/HeatmapChartData";
import { HeatmapChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel";
import Logger from "../../../../../../services/Logger";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { withGeovisErrorBoundary } from "../../../../../errorBoundary/GeovisErrorBoundary";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { IReportElementRenderOwnProps } from "../../types";
import { getHeatmapChartRenderOptions } from "../options/heatmapChartRenderOptions";
import { getGeovisChartConfigFromStorage } from "../options/tools";
import { getGeovisChartData } from "../tools";
import { IChartRenderStateToProps } from "../types";
import { ChartLoadingSkeleton } from "./ChartLoadingComponent";

type IStateToProps = IChartRenderStateToProps<HeatmapChartData>

interface IOwnProps extends IReportElementRenderOwnProps {
    chartId: number;
}

interface IComponentProps extends IStateToProps, IOwnProps {

}

let isContourImported = false;

const HeatmapChartRender = ({ chartId, chartData, pageNum }: IComponentProps) => {

    if (!chartData) {
        return (<ChartLoadingSkeleton text={t("Initializing heatmap chart data")} />)
    }

    const { data, errorDescription, isError, isLoaded } = chartData;

    if (isError) {
        return (<LoadingPageErrorSkeleton errorText={t("Error load heatmap chart data")} errorDescription={errorDescription} />);
    }

    if (!isLoaded) {
        return (<ChartLoadingSkeleton text={t("Loading heatmap chart data...")} />)
    }

    Logger.render('HeatmapChartRender');

    // HighchartsBoost(Highcharts);
    HighchartsNoDataToDisplay(Highcharts);
    HighchartsHeatmap(Highcharts);

    if (!isContourImported) {
        HighchartsHeatmapContour(Highcharts);
        isContourImported = true;
    }

    // getting changed chart config directly from storage, do not subscribe on UPDATE event
    const chart = getGeovisChartConfigFromStorage<HeatmapChartModel>(pageNum, chartId);
    if (!chart) {
        return null;
    }

    const options = getHeatmapChartRenderOptions({ pageNum, chart: cloneDeep(chart), data });

    return (
        <div className="report-chart-render" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}>
            <HighchartsReact
                key={`highcharts_heatmap-${chartId}`}
                highcharts={Highcharts}
                options={options}
                allowChartUpdate={true}
                updateArgs={[true, true, false]}
                constructorType={'chart'}
                containerProps={{ style: { flexGrow: 1, height: `100%` } }}
            />
        </div>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { pageNum, chartId }: IOwnProps): IStateToProps => ({
    chartData: getGeovisChartData<HeatmapChartData>(projectReport.geovisReportSettings, pageNum, chartId)
});

export default connect<IStateToProps, never, IOwnProps>(mapStateToProps)(withGeovisErrorBoundary(HeatmapChartRender));