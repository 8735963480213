//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum InventorySensorboxPowerSourceUnit {
	NONE = 0,
	PSU_R = 1,
	PSU_C = 2
}

const InventorySensorboxPowerSourceUnitToName = new Map<number, string>([
	[ 0, 'NONE' ],
	[ 1, 'PSU_R' ],
	[ 2, 'PSU_C' ],
])

export function getInventorySensorboxPowerSourceUnitToName(element: InventorySensorboxPowerSourceUnit) : string {
    return InventorySensorboxPowerSourceUnitToName.get(element) || '';
}

const InventorySensorboxPowerSourceUnitByName = new Map<string, number>([
	[ 'NONE', 0 ],
	[ 'PSU_R', 1 ],
	[ 'PSU_C', 2 ],
]);

export function getInventorySensorboxPowerSourceUnitByName(name: string): InventorySensorboxPowerSourceUnit {
    return InventorySensorboxPowerSourceUnitByName.get(name)!;
}


export const InventorySensorboxPowerSourceUnitList : Readonly<InventorySensorboxPowerSourceUnit[]> = [
	InventorySensorboxPowerSourceUnit.NONE,
	InventorySensorboxPowerSourceUnit.PSU_R,
	InventorySensorboxPowerSourceUnit.PSU_C,
];



const InventorySensorboxPowerSourceUnitToDescription = new Map<number, string>([
	[ 0, 'NONE' ],
	[ 1, 'PSU_R' ],
	[ 2, 'PSU_C' ],
])

export function getInventorySensorboxPowerSourceUnitToDescription(element: InventorySensorboxPowerSourceUnit) : string {
    return InventorySensorboxPowerSourceUnitToDescription.get(element) || '';
}

