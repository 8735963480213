import { t } from "../../../../i18n";
import { ChartType } from "../../../../server/AVTService/TypeLibrary/Common/ChartType";
import { GeovisChartAxisSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { XYVibrationChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XYVibrationChartModel";
import { SensorCategory } from "../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { SensorChangesModel } from "../../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel";
import { ChartAxisSensorsControl } from "../ChartAxisSensorsControl";
import { ChartBooleanSettingProps, CustomerChangeableChartBooleanSetting } from "../ChartTab_tools";
import { GeovisChartAxisSettingsControl } from "../GeovisChartAxisSettingsControl";
import { isChartTemplate, onChartPropertyChangedFunc } from "../tools";
import { IGeovisChartPropertyChangedProps, IGeovisChartSensorsPropertyProps } from "../types";

interface IXYVibrationChartYAxisTab extends IGeovisChartPropertyChangedProps<XYVibrationChartModel>, IGeovisChartSensorsPropertyProps {
    chart: XYVibrationChartModel;
    isEventAxes: boolean;
}

/**
 * TimeValue chart Y-axis settings
 * @param props ITimeValueChartYAxisTab
 * @returns 
 */
export const XYVibrationChartAxesTab = ({ chart, onChartPropertyChanged, sensorsChanges, isEventAxes }: IXYVibrationChartYAxisTab) => {

    const { LeftYAxisSettings, XAxisSettings, LeftEventAxisSettings, RightEventAxisSettings } = chart;

    const onAxisPropertyChangedHandler = (axisPropertyName: keyof XYVibrationChartModel, axisSettings: GeovisChartAxisSettings) => (propertyName: keyof GeovisChartAxisSettings, value: any) => {

        const changedAxisSettings: Partial<GeovisChartAxisSettings> = { ...axisSettings };
        changedAxisSettings[propertyName] = value;

        onChartPropertyChanged(axisPropertyName, changedAxisSettings);
    }

    const onAxisPropertiesChangedHandlerFunc = (axisPropertyName: keyof XYVibrationChartModel, axisSettings: GeovisChartAxisSettings) => (properties: Partial<GeovisChartAxisSettings>) => {
        const changedAxisSettings: GeovisChartAxisSettings = { ...axisSettings, ...properties };

        onChartPropertyChanged<GeovisChartAxisSettings>(axisPropertyName, changedAxisSettings);
    }

    const onAxisSensorsChangedHandler = (axisPropertyName: keyof XYVibrationChartModel, axisSettings: GeovisChartAxisSettings) => (sensorIds: string[], customerChangeable: boolean, sensorChanges: SensorChangesModel[]) => {

        const newAxisSettings: GeovisChartAxisSettings = {
            ...axisSettings,
            SensorIds: [...sensorIds],
            IsSensorsCustomerChangeable: customerChangeable
        }
        onChartPropertyChanged(axisPropertyName, newAxisSettings, sensorChanges);
    }

    const onChangeFunc = onChartPropertyChangedFunc(onChartPropertyChanged);

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={{ width: '49%' }}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{isEventAxes ? t("Velocity scaling") : t("Y axis")}</div>
                    <GeovisChartAxisSettingsControl
                        chartType={ChartType.XYVibrationEventDiagram}
                        onAxisPropertiesChanged={isEventAxes
                            ? onAxisPropertiesChangedHandlerFunc("LeftEventAxisSettings", LeftEventAxisSettings)
                            : onAxisPropertiesChangedHandlerFunc("LeftYAxisSettings", LeftYAxisSettings)}
                        onAxisPropertyChanged={isEventAxes
                            ? onAxisPropertyChangedHandler("LeftEventAxisSettings", LeftEventAxisSettings)
                            : onAxisPropertyChangedHandler("LeftYAxisSettings", LeftYAxisSettings)
                        }
                        axisSettings={isEventAxes ? LeftEventAxisSettings : LeftYAxisSettings}
                        sensorTypesToSelect={[SensorCategory.VibrationSensor]}
                        isXAxis={false}
                        isRightAxis={false}
                        hideTypeOfSensor={true}
                        isEventChart={isEventAxes}
                        isDisabled={false}
                    />

                    {!isChartTemplate(chart) && !isEventAxes &&
                        <ChartAxisSensorsControl
                            isChain={false}
                            chartType={chart.Type}
                            dtsSectionIds={[]}
                            projectId={chart.ProjectId}
                            ySensorType={isEventAxes ? LeftEventAxisSettings.TypeOfSensor : LeftYAxisSettings.TypeOfSensor}
                            sensorIds={isEventAxes ? LeftEventAxisSettings.SensorIds : LeftYAxisSettings.SensorIds}
                            customerChangeable={isEventAxes ? LeftEventAxisSettings.IsSensorsCustomerChangeable : LeftYAxisSettings.IsSensorsCustomerChangeable}
                            onChange={isEventAxes
                                ? onAxisSensorsChangedHandler("LeftEventAxisSettings", chart.LeftEventAxisSettings)
                                : onAxisSensorsChangedHandler("LeftYAxisSettings", chart.LeftYAxisSettings)
                            }
                            sensorChanges={sensorsChanges}
                            showPairsDialog={false}
                            selectedChainsIds={[]}
                            isSingle={false}
                            hideCustomerChangeable={true}
                        />
                    }
                </div>

                <div className="flexCellContainer_g1 separator">
                    {/** */}
                </div>

                <div className="flexCellContainer" style={{ width: '49%' }}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{isEventAxes ? t("Frequency scaling") : t("X axis")}</div>
                    <GeovisChartAxisSettingsControl
                        chartType={ChartType.XYVibrationEventDiagram}
                        onAxisPropertiesChanged={isEventAxes
                            ? onAxisPropertiesChangedHandlerFunc("RightEventAxisSettings", RightEventAxisSettings)
                            : onAxisPropertiesChangedHandlerFunc("XAxisSettings", XAxisSettings)}
                        onAxisPropertyChanged={isEventAxes
                            ? onAxisPropertyChangedHandler("RightEventAxisSettings", RightEventAxisSettings)
                            : onAxisPropertyChangedHandler("XAxisSettings", XAxisSettings)
                        }
                        axisSettings={isEventAxes ? RightEventAxisSettings : XAxisSettings}
                        sensorTypesToSelect={[SensorCategory.Frequency]}
                        isXAxis={true}
                        isRightAxis={true}
                        hideTypeOfSensor={true}
                        isEventChart={isEventAxes}
                        isDisabled={false}
                    />
                </div>
            </div>
            <div style={{ marginTop: '15px' }}>
                <fieldset style={{ border: '1px #ccc solid' }}>
                    {!isEventAxes && <legend style={{ fontSize: '16px' }}>{t("Main chart options")}</legend>}
                    {!isEventAxes &&
                        <div className="flexRowContainer" style={{ flexDirection: 'column' }}>
                            <div className="flexRowContainer">
                                <ChartBooleanSettingProps
                                    label={t("Show timeslots in legend")}
                                    isChecked={chart.ShowTimeslotsInLegend}
                                    onChange={onChangeFunc("ShowTimeslotsInLegend")}
                                    isDisabled={false} />
                            </div>
                            <div className="flexRowContainer">
                                <ChartBooleanSettingProps
                                    label={t("Show start of measurements")}
                                    isChecked={chart.ShowStartMeasurements}
                                    onChange={onChangeFunc("ShowStartMeasurements")}
                                    isDisabled={false} />
                            </div>
                            <div className="flexRowContainer">
                                <CustomerChangeableChartBooleanSetting
                                    settingLabel={t("Show event graph")}
                                    setting={chart.ShowEventGraph}
                                    onChange={onChangeFunc("ShowEventGraph")}
                                    isDisabled={false}
                                />
                            </div>
                        </div>
                    }
                    {isEventAxes && <legend style={{ fontSize: '16px' }}>{t("Event chart options")}</legend>}
                    {isEventAxes &&
                        <div className="flexRowContainer" style={{ flexDirection: 'column' }}>
                            <div className="flexRowContainer">
                                <ChartBooleanSettingProps
                                    label={t("Show occurrence charts (only available for Walesch vibration devices)")}
                                    isChecked={chart.ShowOccurrence}
                                    onChange={onChangeFunc("ShowOccurrence")}
                                    isDisabled={false} />
                            </div>

                            <div className="flexRowContainer">
                                <ChartBooleanSettingProps
                                    label={t("Show the dominant frequency in the eventcharts")}
                                    isChecked={chart.ShowDominantFreq}
                                    onChange={onChangeFunc("ShowDominantFreq")}
                                    isDisabled={false} />
                            </div>

                        </div>
                    }
                </fieldset>
            </div>
        </div>
    )
}