/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 19.05.2021
 * @description LoRa storage helper
 */

import { ItemId, mutateTree, TreeData, TreeItem } from "@atlaskit/tree";
import { ILoRaSensorRouteParams } from "../../components/projectOverview/lora/LoRaNavigation_tools";
import { ILoRaTreeItemData, LoRaTreeItemType } from "../../components/projectOverview/lora/types";
import { GeovisAlgInfo } from "../../server/GEOvis3/Model/LoRa/GeovisAlgInfo";
import { GeovisAlnInfo } from "../../server/GEOvis3/Model/LoRa/GeovisAlnInfo";

const treeRootItemId = 'root_id';

export const getRootTreeId = (): ItemId => `${treeRootItemId}_lora`;

export const getAlgTreeId = (algId: string): ItemId => `alg_${algId}`;

export const getAlnTreeId = (algId: string, alnId: string): ItemId => `alg_${algId}_aln_${alnId}`;

export const getAlnSensorTreeId = (alnIn: string, sensorId: string) => `aln_${alnIn}_sensor_${sensorId}`;

/**
 * Create LoRa tree data
 * @param gateways 
 */
export const createLoRaTreeData = (gateways: GeovisAlgInfo[]): TreeData => {

    let result: TreeData = { items: {}, rootId: treeRootItemId };

    const rootItemData: ILoRaTreeItemData = {
        type: LoRaTreeItemType.Root
    };

    const rootTreeItem: TreeItem = {
        id: getRootTreeId(),
        children: [],
        data: rootItemData,
        hasChildren: false,
        isExpanded: true
    };
    result.items[rootTreeItem.id] = rootTreeItem;

    // set invisible entry point of tree
    result.items[treeRootItemId] = {
        id: treeRootItemId,
        children: [rootTreeItem.id],
        isExpanded: true,
        hasChildren: true
    };

    if (!gateways || gateways.length === 0) {
        return result;
    }

    for (const gateway of gateways) {
        result = createGatewayTreeItem(result, rootTreeItem.id, gateway);
    }

    return result;
}

const getSwisscomAlgItemId = (treeData: TreeData, itemIds: ItemId[]): ItemId | undefined => {
    return itemIds.find(itemId=> {
        const treeItem = treeData.items[itemId];
        if(treeItem){
            const algTreeData = treeItem.data as ILoRaTreeItemData;
            if(algTreeData && algTreeData.alg && algTreeData.alg.IsSwisscomGateway){
                return true;
            }
        }

        return false;
    });

}

const getNewAlgList = (treeData: TreeData, existentItemIds: ItemId[], newItemId: ItemId, isSwisscomAlg: boolean): ItemId[] => {
    if(isSwisscomAlg || existentItemIds.length === 0 ){
        return [...existentItemIds, newItemId];
    }

    const swisscomAlgId = getSwisscomAlgItemId(treeData, existentItemIds);
    if(!swisscomAlgId){
        return [...existentItemIds, newItemId];
    }

    const idsWithoutSwisscom = existentItemIds.filter(i => i !== swisscomAlgId);
    return [...idsWithoutSwisscom, newItemId, swisscomAlgId];
}

/**
 * Create tree item (with children) of the ALG (gateway)
 * @param result 
 * @param parentId 
 * @param gateway 
 */
export const createGatewayTreeItem = (result: TreeData, parentId: ItemId, gateway: GeovisAlgInfo): TreeData => {
    if (gateway.IsSwisscomGateway && gateway.Id) {
        // Remove the default swisscom alg item
        const defaultSwisscomAlgItemId = getAlgTreeId('null');
        const defaultSwisscomAlgItem = result.items[defaultSwisscomAlgItemId];
        if (defaultSwisscomAlgItem) {
            result = removeTreeItem(result, defaultSwisscomAlgItemId);
        }
    }

    const gateItemId = getAlgTreeId(gateway.Id);
    const gateData: ILoRaTreeItemData = {
        type: LoRaTreeItemType.ALG,
        alg: { ...gateway, Nodes: [] }
    };

    const gateItem: TreeItem = {
        id: gateItemId,
        children: [],
        data: gateData,
        hasChildren: false,
        isExpanded: false
    };

    result.items[gateItemId] = gateItem;
    const parentItem = result.items[parentId];

    result = mutateTree(result, parentId, { 
        children: getNewAlgList(result, parentItem.children, gateItemId, gateway.IsSwisscomGateway), // [...parentItem.children, gateItemId], 
        hasChildren: true, 
        isExpanded: true 
    });

    // add LoRa ALN nodes
    if (gateway.Nodes && gateway.Nodes.length) {
        for (const alnConfig of gateway.Nodes.sort((n1, n2) => n1.Order > n2.Order ? 1 : -1)) {
            result = createAlnTreeItem(result, alnConfig);
        }
    }

    return result;
}

/**
 * Update ALG tree item
 * @param loraTreeData 
 * @param algId
 * @param gateway 
 */
export const updateGatewayTreeItem = (loraTreeData: TreeData, algId: string, gateway: Partial<GeovisAlgInfo>): TreeData => {

    const gateItemId = getAlgTreeId(algId);

    const gateItem = loraTreeData.items[gateItemId];
    if (!gateItem) {
        throw Error('An error to update Gateway tree item. The TreeItem data not found');
    }

    const gateData: ILoRaTreeItemData = {
        type: LoRaTreeItemType.ALG,
        alg: { ...gateItem.data.alg, ...gateway }
    };

    return mutateTree(loraTreeData, gateItemId, { data: gateData });
}

export const expandLoRaTreeItems = (loraTreeData: TreeData, routeParams: Partial<ILoRaSensorRouteParams>): TreeData => {

    // make a new object to trigger redraw anyway
    // it is needed in case of navigation to root tree item of the current item
    let result = loraTreeData;
    if (routeParams.algId) {
        result = mutateTree(result, getAlgTreeId(routeParams.algId), { isExpanded: true });

        if (routeParams.alnId) {
            result = mutateTree(result, getAlnTreeId(routeParams.algId, routeParams.alnId), { isExpanded: true });

            if (routeParams.sensorId) {
                result = mutateTree(result, getAlnSensorTreeId(routeParams.alnId, routeParams.sensorId), { isExpanded: true });
            }
        }
    }

    return result;
}

/**
 * Remote the tree item with its children
 * @param treeData 
 * @param itemId
 */
export const removeTreeItem = (treeData: TreeData, itemId: ItemId): TreeData => {

    const item = treeData.items[itemId];
    return removeTreeItemsRecursive(treeData, item);
}

const removeTreeItemsRecursive = (treeData: TreeData, ...items: TreeItem[]): TreeData => {

    for (const item of items) {
        // if item has children, then move deep
        if (item.children.length > 0) {
            treeData = removeTreeItemsRecursive(treeData, ...item.children.map(childItem => treeData.items[childItem]));
        }

        // remove this item out of children of parent items
        for (const pItemId of Object.keys(treeData.items)) {

            const pItem = treeData.items[pItemId];
            if (pItem && pItem.children.length > 0 && pItem.children.indexOf(item.id) > -1) {

                const children = pItem.children.filter(cId => cId !== item.id);
                treeData = mutateTree(treeData, pItemId, { children, hasChildren: children.length > 0 });
            }
        }

        delete treeData.items[item.id];
    }

    return treeData;
}

/**
 * Create an ALN tree item
 * @param treeData 
 * @param alnConfig 
 */
export const createAlnTreeItem = (treeData: TreeData, alnConfig: GeovisAlnInfo): TreeData => {

    const algItemId = getAlgTreeId(alnConfig.ParentAlgId);
    const algTreeItem = treeData.items[algItemId];

    if (!algTreeItem) {
        throw Error('An error to add ALN tree item. Parent ALG item not found');
    }

    const alnItemId = getAlnTreeId(alnConfig.ParentAlgId, alnConfig.Id);
    const alnItemData: ILoRaTreeItemData = {
        type: LoRaTreeItemType.ALN,
        aln: alnConfig
    };

    const alnTreeItem: TreeItem = {
        children: [],
        id: alnItemId,
        hasChildren: false,
        data: alnItemData
    };

    treeData.items[alnItemId] = alnTreeItem;
    treeData = mutateTree(treeData, algItemId, { children: [...algTreeItem.children, alnItemId] });

    // TODO: add sensors nodes if needed

    return treeData;
}

/**
 * 
 * @param treeData 
 * @param alnId 
 * @param alnInfoPartial 
 */
export const updateAlnTreeItem = (treeData: TreeData, algId: string, alnId: string, alnInfoPartial: Partial<GeovisAlnInfo>): TreeData => {
    const alnTreeId = getAlnTreeId(algId, alnId);
    const alnTreeItem = treeData.items[alnTreeId];
    if (!alnTreeItem) {
        return treeData;
    }

    const alnTreeData = alnTreeItem.data as ILoRaTreeItemData;
    if (!alnTreeData || !alnTreeData.aln) {
        return treeData;
    }

    const alnTreeDataChanged: ILoRaTreeItemData = {
        ...alnTreeData,
        aln: { ...alnTreeData.aln, ...alnInfoPartial }
    }

    return mutateTree(treeData, alnTreeId, { data: alnTreeDataChanged });
}

/**
 * Remove ALN tree item
 * @param treeData 
 * @param alnId 
 */
export const removeAlnTreeItem = (treeData: TreeData, alg: string, alnId: string): TreeData => {

    const item = treeData.items[getAlnTreeId(alg, alnId)];
    if (!item) {
        return treeData;
    }

    return removeTreeItemsRecursive(treeData, item);
}

/**
 * Search and get the ALG info from tree data
 * @param treeData 
 * @param algId 
 */
export const getAlgInfoFromTree = (treeData: TreeData, algId: string): GeovisAlgInfo | undefined => {
    const algTreeId = getAlgTreeId(algId);
    const algTreeItem = treeData.items[algTreeId];
    if (!algTreeItem) {
        return undefined;
    }

    const data = algTreeItem.data as ILoRaTreeItemData;
    if (!data || !data.alg) {
        return undefined;
    }

    return data.alg;
}

/**
 * Search and return the ALN info from tree data
 * @param treeData 
 * @param alnId 
 * @returns 
 */
export const getAlnInfoFromTree = (treeData: TreeData, algId: string, alnId: string): GeovisAlnInfo | undefined => {
    const alnTreeId = getAlnTreeId(algId, alnId);
    const alnTreeItem = treeData.items[alnTreeId];
    if (!alnTreeItem) {
        return undefined;
    }

    const data = alnTreeItem.data as ILoRaTreeItemData;
    if (!data || !data.aln) {
        return undefined;
    }

    return data.aln;
}