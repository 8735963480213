import Highcharts from "highcharts";
import { cloneDeep } from "lodash";
import { mapToListOfKeys, numberKeyValueObjectToMap } from "../../../../../../helpers/StorageHelper";
import { t } from "../../../../../../i18n";
import { getSensorValueAttributeByName, SensorValueAttribute } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { VibrationEventChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/VibrationEventChartData";
import { GeovisChartAxisSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { VibrationChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { HtmlReportVibrationEventHeaderViewModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportVibrationEventHeaderViewModel";
import { HtmlReportVibrationEventLineViewModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportVibrationEventLineViewModel";
import { XYChartDataModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/XYChartDataModel";
import { getPhysicalUnitShortName, PhysicalUnit } from "../../../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { VIBRATION_EVENT_ACCELERATION, VIBRATION_EVENT_OCCURRENCE, VIBRATION_EVENT_VECTOR } from "../renders/VibrationEventChartRender";
import { checkPositionInclude, getGeovisChartSeriesLineWidth, getLimitValue, getSoftLimitValue, getYAxisUnitsAnnotation } from "./chartRenderOptions";
import { createChartRenderedFunc } from "../../tools";

const valueAttributes: SensorValueAttribute[] = [SensorValueAttribute.Value1, SensorValueAttribute.Value2, SensorValueAttribute.Value3];
const domFreqAttributes: SensorValueAttribute[] = [SensorValueAttribute.DomFreqX, SensorValueAttribute.DomFreqY, SensorValueAttribute.DomFreqZ];
const peakAttributes: SensorValueAttribute[] = [SensorValueAttribute.PeakX, SensorValueAttribute.PeakX, SensorValueAttribute.PeakY];

const FREQUENCY_SERIA_NAME = 'FREQUENCY_SERIA_NAME';
const ACCELERATION_SERIA_NAME = 'ACCELERATION_SERIA_NAME';

interface IBaseVibrationEventRenderOptionsSettings {
    chart: VibrationChartModel;
    accelerationLine: HtmlReportVibrationEventLineViewModel;
    frequencyLine: HtmlReportVibrationEventLineViewModel;
    eventHeader: HtmlReportVibrationEventHeaderViewModel;
    occurrenceVector: Map<number, number[]>;
    occurrenceData: Map<number, number[]>;
    visualSettings: ProjectVisualSettings;
    color: string;
    onChartRender: () => void;
    eventId: string;
}

interface IVibrationEventRenderOptionsSettings extends IBaseVibrationEventRenderOptionsSettings {
    mainCurrentAttribute: SensorValueAttribute;
    subCurrentAttribute?: SensorValueAttribute;
    globalAccelerationMin: number;
    globalAccelerationMax: number;
}

const getOptionsForAttribute = (mainCurrentAttributes: SensorValueAttribute[], subCurrentAttributes: SensorValueAttribute[], baseProps: IBaseVibrationEventRenderOptionsSettings): Highcharts.Options[] => {
    const result: Highcharts.Options[] = [];

    let globalMin: number = 10000;
    let globalMax: number = -10000;

    if (baseProps.chart.LeftEventAxisSettings.minScaleLimit.auto) {
        baseProps.accelerationLine.points.forEach(point => {
            point.value.forEach(val => {
                if (val < globalMin) {
                    globalMin = val - Math.abs(val) * 0.1;
                }
            })
        })
    }
    else {
        globalMin = baseProps.chart.LeftEventAxisSettings.minScaleLimit.value;
    }

    if (baseProps.chart.LeftEventAxisSettings.maxScaleLimit.auto) {
        baseProps.accelerationLine.points.forEach(point => {
            point.value.forEach(val => {
                if (val > globalMax) {
                    globalMax = val + Math.abs(val) * 0.1;
                }
            })
        })
    }
    else {
        globalMax = baseProps.chart.LeftEventAxisSettings.maxScaleLimit.value;
    }

    mainCurrentAttributes.forEach((va, index) => {
        const subCurrentAttribute = subCurrentAttributes.length > index
            ? subCurrentAttributes[index]
            : subCurrentAttributes.length > 0
                ? subCurrentAttributes[0]
                : undefined;

        const singleSettings: IVibrationEventRenderOptionsSettings = {
            ...baseProps,
            mainCurrentAttribute: va,
            subCurrentAttribute,
            globalAccelerationMax: globalMax,
            globalAccelerationMin: globalMin
        };

        const singleOptions = getSingleOptions(singleSettings)

        result.push(singleOptions);
    });

    return result;
}

const getArrayOfAttributes = (original: SensorValueAttribute): SensorValueAttribute[] => {
    switch (original) {
        case SensorValueAttribute.ValuesXYZ:
        case SensorValueAttribute.ValuesXYZAndVector:
            return valueAttributes;
        case SensorValueAttribute.PeakXYZ:
            return peakAttributes;
        case SensorValueAttribute.DomFreqXYZ:
            return domFreqAttributes;
        default:
            return [original];
    }
}

export const getVibrationEventRenderOptionsSettings = (pageNum: number, chartModel: VibrationChartModel, chartData: VibrationEventChartData, eventId: string): Map<string, Highcharts.Options[]> => {
    const baseProps: IBaseVibrationEventRenderOptionsSettings = {
        chart: chartModel,
        accelerationLine: chartData.AccelerationLine,
        frequencyLine: chartData.FrequencyLine,
        eventHeader: chartData.EventHeader,
        occurrenceData: numberKeyValueObjectToMap(chartData.OccurrenceData),
        occurrenceVector: numberKeyValueObjectToMap(chartData.OccurrenceVector),
        visualSettings: chartData.visualSettings,
        color: chartData.Color,
        onChartRender: createChartRenderedFunc(pageNum, 0, eventId),
        eventId
    }

    const result = new Map<string, Highcharts.Options[]>();

    // for now only Left Event axis attribute matter
    const accelerationSensorValueAttribute: SensorValueAttribute = getSensorValueAttributeByName(chartModel.LeftEventAxisSettings.sensorValue);
    const frequencySensorValueAttribute: SensorValueAttribute = getSensorValueAttributeByName(chartModel.RightEventAxisSettings.sensorValue);

    switch (accelerationSensorValueAttribute) {
        case SensorValueAttribute.ValuesXYZAndVector: {

            // add acceleration lines
            result.set(VIBRATION_EVENT_ACCELERATION, getOptionsForAttribute(valueAttributes, getArrayOfAttributes(frequencySensorValueAttribute), baseProps));

            if (chartModel.ShowOccurrence) {
                // add occurrence lines
                result.set(VIBRATION_EVENT_OCCURRENCE, getOptionsForAttribute(domFreqAttributes, [], baseProps));
            }

            // add vector line
            result.set(VIBRATION_EVENT_VECTOR, getOptionsForAttribute([SensorValueAttribute.ValuesVector], [], baseProps));

            break;
        }

        default: {
            result.set(VIBRATION_EVENT_ACCELERATION, getOptionsForAttribute(getArrayOfAttributes(accelerationSensorValueAttribute), getArrayOfAttributes(frequencySensorValueAttribute), baseProps));
            break;
        }

    }


    return result;
}

const getSingleOptions = ({
    accelerationLine,
    frequencyLine,
    chart: chartModel,
    mainCurrentAttribute,
    subCurrentAttribute,
    occurrenceData,
    occurrenceVector,
    visualSettings,
    color,
    globalAccelerationMax,
    globalAccelerationMin,
    onChartRender
}: IVibrationEventRenderOptionsSettings): Highcharts.Options => {
    let mainSeria: Highcharts.SeriesOptionsType;
    let subSeria: Highcharts.SeriesOptionsType | undefined;
    let xAxisUnit = getPhysicalUnitShortName(PhysicalUnit.Second);
    let yAxisUnit = getPhysicalUnitShortName(PhysicalUnit.MillimeterPerSecond);
    const isOccurrenceChart = mainCurrentAttribute !== SensorValueAttribute.ValuesVector && !subCurrentAttribute;
    const isVSumChart = mainCurrentAttribute === SensorValueAttribute.ValuesVector && !subCurrentAttribute;

    const accelerationAxisSettings = chartModel.LeftEventAxisSettings;
    const frequencyAxisSettings = chartModel.RightEventAxisSettings;

    const mainOriginalAttribute = getSensorValueAttributeByName(accelerationAxisSettings.sensorValue);
    const subOriginalAttribute = getSensorValueAttributeByName(frequencyAxisSettings.sensorValue);

    if (isOccurrenceChart) {
        mainSeria = getOccurrenceSeria(mainCurrentAttribute, mainOriginalAttribute, occurrenceData, visualSettings, color);
        xAxisUnit = getPhysicalUnitShortName(PhysicalUnit.Herz);
        yAxisUnit = getPhysicalUnitShortName(PhysicalUnit.Percent);
    }
    else if (isVSumChart) {
        mainSeria = getVectorSeria(occurrenceVector, visualSettings, color);
    }
    else {
        mainSeria = getLineSeria(mainCurrentAttribute, mainOriginalAttribute, accelerationLine, visualSettings, false, color);
        if (subCurrentAttribute && chartModel.ShowDominantFreq) {
            subSeria = getLineSeria(subCurrentAttribute, subOriginalAttribute, frequencyLine, visualSettings, true, color);
        }
    }

    const annotations: Highcharts.AnnotationsOptions[] = [];

    if (!isOccurrenceChart) {
        annotations.push(getYAxisUnitsAnnotation(accelerationAxisSettings, 0));
        if (chartModel.ShowDominantFreq && subCurrentAttribute) {
            annotations.push(getYAxisUnitsAnnotation(frequencyAxisSettings, 1));
        }
    }

    const xAxisMax = accelerationLine.duration;

    const options: Highcharts.Options = {
        boost: {
            enabled: false
        },
        annotations,
        chart: {
            zooming: {
                type: 'xy'
            },
            reflow: true,
            showAxes: true,
            alignTicks: true,
            events: {
                render: onChartRender,
            }
        },
        title: {
            floating: false,
            text: "<div style='height:10px'></div>",
            useHTML: true
        },
        lang: {
            noData: `<div style='z-index:20'>${t('No data is available in the chart')}</div>`
        },
        noData: {
            useHTML: true
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            type: 'linear',
            startOnTick: true,
            endOnTick: true,
            crosshair: true,
            minRange: 0.01,
            lineWidth: 2,
            lineColor: "black",
            minorTickInterval: 'auto',
            labels: {
                style: {
                    fontSize: visualSettings.chartAxisLabelFontSize
                },
            },
            title: {
                align: 'high',
                offset: -20,
                text: xAxisUnit,
                x: -10
            },
            min: 0,
            max: isOccurrenceChart ? undefined : xAxisMax
        },
        yAxis: isOccurrenceChart
            ? getOccurrenceYAxisOptions(visualSettings, yAxisUnit)
            : getAccelerationYAxisOptions(chartModel, mainCurrentAttribute, visualSettings, yAxisUnit, globalAccelerationMin, globalAccelerationMax, !isOccurrenceChart && !isVSumChart, subCurrentAttribute),
        tooltip: {
            style: {
                padding: '5px',
            },
            useHTML: true,
            formatter() {

                const seriaName = this.point.series.name;

                const defaultTooltip = !isOccurrenceChart
                    ? seriaName === ACCELERATION_SERIA_NAME
                        ? `${this.point.y?.toFixed(accelerationAxisSettings.numberOfDigits.value)} [mm/s] <br/>${this.point.x?.toFixed(accelerationAxisSettings.numberOfDigits.value)} [s]`
                        : `${this.point.y?.toFixed(frequencyAxisSettings.numberOfDigits.value)} [Hz] <br/>${this.point.x?.toFixed(frequencyAxisSettings.numberOfDigits.value)} [s]`
                    : `${this.point.y?.toFixed(accelerationAxisSettings.numberOfDigits.value)} % <br/>${this.point.x?.toFixed(accelerationAxisSettings.numberOfDigits.value)} [Hz]`;

                return defaultTooltip;

            },
            snap: 5,
            outside: false,
            shared: false,
            split: false,
        },
        series: subSeria ? [mainSeria, subSeria] : [mainSeria],
        exporting: {
            enabled: false,
        },
        plotOptions: {
            line: {
                findNearestPointBy: 'xy'
            },
            series: {
                animation: false,
            }
        },
        credits: {
            enabled: false
        }
    }

    return options;
}

const getAccelerationYAxisOptions = (chartModel: VibrationChartModel, mainCurrentAttribute: SensorValueAttribute,
    visualSettings: ProjectVisualSettings, axisUnitName: string, globalMin: number, globalMax: number, isAcceleration: boolean, subCurrentAttribute?: SensorValueAttribute): Highcharts.YAxisOptions[] => {

    const accelerationAxisSettings = chartModel.LeftEventAxisSettings;
    const frequencyAxisSettings = chartModel.RightEventAxisSettings;

    const mainOriginalAttribute = getSensorValueAttributeByName(accelerationAxisSettings.sensorValue);
    const subOriginalAttribute = getSensorValueAttributeByName(frequencyAxisSettings.sensorValue);

    let mainAxisTitleText = accelerationAxisSettings.axisXLabel;
    let subAxisTitleText = frequencyAxisSettings.axisXLabel;

    let isVSum: boolean = false;

    // get title for main Y axis
    if (mainCurrentAttribute !== mainOriginalAttribute) {
        if (mainCurrentAttribute === SensorValueAttribute.ValuesVector) {
            mainAxisTitleText = "VSum [mm/s]";
            isVSum = true;
        }
        else {
            isVSum = false;
            switch (getAttributeIndex(mainCurrentAttribute)) {
                case 1:
                    mainAxisTitleText = accelerationAxisSettings.axisYLabel;
                    break;
                case 2:
                    mainAxisTitleText = accelerationAxisSettings.axisZLabel;
                    break
            }
        }
    }

    // get title for sub Y axis
    if (subCurrentAttribute && subCurrentAttribute !== subOriginalAttribute) {
        switch (getAttributeIndex(subCurrentAttribute)) {
            case 1:
                subAxisTitleText = frequencyAxisSettings.axisYLabel;
                break;
            case 2:
                subAxisTitleText = frequencyAxisSettings.axisZLabel;
                break
        }
    }

    const result: Highcharts.YAxisOptions[] = [];

    const finalAccelerationAxisSettings = cloneDeep(accelerationAxisSettings);

    if (isAcceleration) {
        finalAccelerationAxisSettings.minScaleLimit.value = globalMin;
        finalAccelerationAxisSettings.minScaleLimit.auto = false;
        finalAccelerationAxisSettings.minScaleLimit.exact = false;

        finalAccelerationAxisSettings.maxScaleLimit.value = globalMax;
        finalAccelerationAxisSettings.maxScaleLimit.auto = false;
        finalAccelerationAxisSettings.maxScaleLimit.exact = false;
    }

    if (isVSum) {
        finalAccelerationAxisSettings.minScaleLimit.value = -0.1;
        finalAccelerationAxisSettings.minScaleLimit.auto = false;
        finalAccelerationAxisSettings.minScaleLimit.exact = false;

        finalAccelerationAxisSettings.maxScaleLimit.auto = true;
    }

    result.push(getSingleYAxisOptions(finalAccelerationAxisSettings, mainAxisTitleText, visualSettings, false));

    if (subCurrentAttribute && chartModel.ShowDominantFreq) {
        result.push(getSingleYAxisOptions(frequencyAxisSettings, subAxisTitleText, visualSettings, true))
    }

    return result;
}

const getSingleYAxisOptions = (axisSettings: GeovisChartAxisSettings, axisTitleText: string, visualSettings: ProjectVisualSettings, isOpposite: boolean): Highcharts.YAxisOptions => {

    return {
        title: {
            text: axisSettings.showAxisLabels.value ? axisTitleText : undefined,
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            }
        },
        lineWidth: 2,
        lineColor: "black",
        crosshair: true,
        opposite: isOpposite,
        labels: {
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            },
            useHTML: true,
            formatter() {
                const thisValue = (this.value as number).toFixed(axisSettings.numberOfDigits.value);
                return thisValue;
            }
        },
        tickPositioner() {
            const positions = this.tickPositions;

            if (!positions) {
                return [];
            }

            if (this.min) {
                for (let index = 0; index < positions.length; index++) {
                    if (this.min && positions[index] < this.min) {
                        positions.splice(index, 1);
                        index--;
                    }
                    if (this.max && positions[index] > this.max) {
                        positions.splice(index, 1);
                        index--;
                    }
                }
            }

            // when min or max axis settings EXACT set we should display first/last axis label. To prevent rounding up because of using startOnTick and endOnTick we manually add min or max or both
            // labels. 

            if (this.min && !checkPositionInclude(positions, this.min, axisSettings.numberOfDigits.value) && axisSettings.minScaleLimit.exact && this.min >= axisSettings.minScaleLimit.value && !axisSettings.minScaleLimit.auto) {
                positions.splice(0, 0, this.min);
            }

            if (this.max && !checkPositionInclude(positions, this.max, axisSettings.numberOfDigits.value) && axisSettings.maxScaleLimit.exact && this.max <= axisSettings.maxScaleLimit.value && !axisSettings.maxScaleLimit.auto) {
                positions.push(this.max);
            }

            // if only 1 position is available
            if (positions.length === 1) {
                const step = positions[0] / 10;
                const firstLabel = positions[0] - 7 * step;
                const lastLabel = positions[0] + 3 * step
                this.max = lastLabel;
                this.min = firstLabel;
                positions.splice(0, 1);

                for (let index = 0; index < 10; index++) {
                    positions.push(firstLabel + index * step)
                }

            }

            return positions;
        },
        min: getLimitValue(axisSettings.minScaleLimit),
        max: getLimitValue(axisSettings.maxScaleLimit),
        softMax: getSoftLimitValue(axisSettings.maxScaleLimit),
        softMin: getSoftLimitValue(axisSettings.minScaleLimit),
        startOnTick: false,
        endOnTick: false,
        gridLineWidth: 1,
        gridZIndex: 1,
        minRange: 0.001
    }
}

const getOccurrenceYAxisOptions = (visualSettings: ProjectVisualSettings, axisUnitName: string): Highcharts.YAxisOptions => {


    const options: Highcharts.YAxisOptions = {
        title: {
            align: 'high',
            offset: 0,
            text: axisUnitName,
            rotation: 0,
            y: -10
        },
        lineWidth: 2,
        lineColor: "black",
        crosshair: true,
        labels: {
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            }
        },
        min: -5,
        max: 110,
        startOnTick: false,
        endOnTick: false,
        gridLineWidth: 1,
        gridZIndex: 1,
    }

    return options;
}

const getLineSeria = (valueAttribute: SensorValueAttribute, originalValueAttribute: SensorValueAttribute, pointLine: HtmlReportVibrationEventLineViewModel, visualSettings: ProjectVisualSettings, isSub: boolean, color: string): Highcharts.SeriesOptionsType => {

    const getPointsData = (points: XYChartDataModel[]): number[][] => {
        const resultData: number[][] = [];

        points.forEach(point => {
            const pointValue = selectValue(point.value, valueAttribute, originalValueAttribute);
            if (!isNaN(pointValue)) {
                resultData.push([point.x, pointValue]);
            }
        })

        return resultData.sort(sortResultDataByX);
    }

    const pointsData = getPointsData(pointLine.points);

    const result: Highcharts.SeriesOptionsType = {
        type: 'line',
        color,
        lineWidth: getGeovisChartSeriesLineWidth(visualSettings, undefined),
        xAxis: 0,
        yAxis: isSub ? 1 : 0,
        dashStyle: 'Solid',
        data: pointsData,
        name: isSub ? FREQUENCY_SERIA_NAME : ACCELERATION_SERIA_NAME,
        marker: {
            enabled: false
        },
        custom: {
            hasData: pointsData.length > 0
        }
    }

    return result;
}

const getOccurrenceSeria = (valueAttribute: SensorValueAttribute, originalValueAttribute: SensorValueAttribute, occurrenceDataPoints: Map<number, number[]>, visualSettings: ProjectVisualSettings, color: string): Highcharts.SeriesOptionsType => {

    const getOccurrenceData = (points: Map<number, number[]>): number[][] => {
        const resultData: number[][] = [];

        const keys = mapToListOfKeys(points);

        keys.forEach(key => {
            const keyPoints = points.get(key);
            if (keyPoints) {
                const keyValuePoint = selectValue(keyPoints, valueAttribute, originalValueAttribute);
                if (!isNaN(keyValuePoint)) {
                    resultData.push([key, keyValuePoint])
                }
            }
        })

        return resultData.sort(sortResultDataByX);
    }

    const occurrenceData = getOccurrenceData(occurrenceDataPoints);

    const result: Highcharts.SeriesOptionsType = {
        type: 'line',
        color,
        lineWidth: getGeovisChartSeriesLineWidth(visualSettings, undefined),
        xAxis: 0,
        yAxis: 0,
        dashStyle: 'Solid',
        data: occurrenceData,
        marker: {
            enabled: false
        },
        custom: {
            hasData: occurrenceData.length > 0
        }
    }

    return result;
}

const sortResultDataByX = (a: number[], b: number[]): number => {
    if (a[0] === b[0]) {
        return 0;
    }
    return a[0] > b[0] ? 1 : -1;
}

const getVectorSeria = (occurrenceVector: Map<number, number[]>, visualSettings: ProjectVisualSettings, color: string): Highcharts.SeriesOptionsType => {


    const getVectorData = (points: Map<number, number[]>): number[][] => {
        const resultData: number[][] = [];

        const keys = mapToListOfKeys(points);
        // const sortedKeys = keys.sort();

        keys.forEach(key => {
            const keyPoints = points.get(key);
            if (keyPoints && keyPoints.length > 0) {
                resultData.push([key, keyPoints[0]])
            }
        })

        return resultData.sort(sortResultDataByX);
    }

    const vectorData = getVectorData(occurrenceVector);

    const result: Highcharts.SeriesOptionsType = {
        type: 'line',
        color,
        lineWidth: getGeovisChartSeriesLineWidth(visualSettings, undefined),
        xAxis: 0,
        yAxis: 0,
        dashStyle: 'Solid',
        data: vectorData,
        marker: {
            enabled: false
        },
        custom: {
            hasData: vectorData.length > 0
        }
    }

    return result;
}


const selectValue = (values: number[], currentAttribute: SensorValueAttribute, originalAttribute: SensorValueAttribute): number => {
    if (currentAttribute === originalAttribute && values.length > 0) {
        return values[0];
    }
    const attributeIndex = getAttributeIndex(currentAttribute);
    if (attributeIndex < 0) {
        return NaN;
    }
    if (values.length < attributeIndex - 1) {
        return NaN;
    }

    return values[attributeIndex];
}

const getAttributeIndex = (attribute: SensorValueAttribute): number => {
    let index = valueAttributes.indexOf(attribute);
    if (index >= 0) {
        return index;
    }

    index = peakAttributes.indexOf(attribute);
    if (index >= 0) {
        return index;
    }

    return domFreqAttributes.indexOf(attribute);
}

