import { getHours, getMinutes } from "date-fns";
import { range } from "lodash";
import React, { ChangeEvent, useRef, useState } from "react";
import ClockIcon from 'src/resources/icons/clock-16.png';
import { t } from "../../i18n";
import { getPureDateTime, isDateTimeInRange } from "./time.tools";
import { IDateTimeRangeProps } from "./types";

interface IComponentProps extends Omit<IDateTimeRangeProps, 'pairPickerDate'> {
    selectedDate: Date;
    pairPickerDate: Date | undefined
    onChange: (time: string, apply: boolean) => void;
}

interface IComponentState {
    isOpen: boolean;
    hour: number;
    selectedMinutes: number | undefined;
    originalMinutes: number;
    timeValue: string;
}

const Component = ({
    selectedDate, onChange,
    isPairedPicker, isThisPickerOfStartDate, pairPickerDate
}: IComponentProps) => {


    const purePairPickerDate = getPureDateTime(pairPickerDate);
    const pureSelectedDate = getPureDateTime(selectedDate)!;

    const getFormattedOption = (option: number): string => {
        return option < 10 ? `0${option}` : `${option}`;
    }

    const getTimeValue = (hour: number, minutes: number) => {
        return `${getFormattedOption(hour)}:${getFormattedOption(minutes)}`;
    }

    const getTimeValueFromDate = (date: Date) => {
        return getTimeValue(getHours(date), getMinutes(date));
    }

    const getInitialMinutesInterval = (): number | undefined => {
        const minutes = getMinutes(pureSelectedDate);
        return minutes % 5 === 0 ? minutes : undefined;
    }

    const timeFieldRef = useRef<HTMLInputElement>(null);

    const [state, setState] = useState<IComponentState>({
        isOpen: false,
        hour: getHours(pureSelectedDate),
        selectedMinutes: getInitialMinutesInterval(),
        originalMinutes: getMinutes(pureSelectedDate),
        timeValue: getTimeValueFromDate(pureSelectedDate)
    });

    const getCurrentMinutesValues = (): number => {
        return state.selectedMinutes !== undefined ? state.selectedMinutes : state.originalMinutes;
    }

    const onTimeInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            onChange(state.timeValue, true);
        }
        else if(event.key === "Backspace" || event.key === "Delete"){
            event.preventDefault();
        }
    }

    const isTimeInRange = (hour: number, minutes: number): boolean => {
        if (!isPairedPicker) {
            return true;
        }

        const selectedDateTime = new Date(pureSelectedDate);
        selectedDateTime.setHours(hour);
        selectedDateTime.setMinutes(minutes);

        return isDateTimeInRange(selectedDateTime, isThisPickerOfStartDate, purePairPickerDate);
    }

    const onTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const timeValue = event.target.value;
        const timeValueParts = timeValue.split(":");

        setState({
            ...state,
            hour: timeValueParts.length > 0 ? +timeValueParts[0] : state.hour,
            selectedMinutes: timeValueParts.length > 1 ? +timeValueParts[1] : state.selectedMinutes,
            timeValue
        });
        onChange(timeValue, false);
    }

    const onClockIconClick = () => {
        setState({
            ...state,
            isOpen: !state.isOpen
        })
    }

    const onHourChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const hour = +event.target.value;
        const minutes = getCurrentMinutesValues();

        if (!isTimeInRange(hour, minutes)) {
            return;
        }

        const timeValue = getTimeValue(hour, minutes);
        setState({
            ...state,
            hour,
            timeValue
        })
        onChange(timeValue, false);
    }

    const onMinutesChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const { hour } = state;
        const selectedMinutes = +event.target.value;

        if (!isTimeInRange(hour, selectedMinutes)) {
            return;
        }

        const timeValue = getTimeValue(hour, selectedMinutes);
        setState({
            ...state,
            isOpen: false, // after click on minute selection close hours minute dropdowns
            selectedMinutes,
            timeValue
        })
        onChange(timeValue, false);
    }

    const hoursRange = range(24);
    const minutesRange = range(0, 60, 5);
    const optionsSize = 7;
    const optionsDropdownHeight = optionsSize * 30;

    const isDropdownOnTop = (): boolean => {
        if (timeFieldRef.current) {
            const clientRect = timeFieldRef.current.getBoundingClientRect();
            return clientRect.bottom + optionsDropdownHeight > window.innerHeight;
        }
        return false;
    }

    const isMinutesDisabled = (minutes: number) => {
        return !isTimeInRange(state.hour, minutes);
    }

    const isHourDisabled = (hour: number) => {
        // const minutes: number = isPairedPicker && !isThisPickerOfStartDate ? 55 : 0;
        const minutes: number = isPairedPicker ? getCurrentMinutesValues() : 0;
        return !isTimeInRange(hour, minutes);
    }

    const optionsDropdownClassName = isDropdownOnTop() ? "geovis-custom-time-input-dropdown-on-top-container" : "geovis-custom-time-input-dropdown-container";

    return (
        <div>
            <div className="flexRowContainer">
                <div style={{ margin: '5px' }}>
                    {t("Time")}
                </div>
                <div className="geovis-custom-time-input-container">
                    <input
                        ref={timeFieldRef}
                        type="time"
                        className="geovis-custom-time-input"
                        placeholder="Time"
                        name="time-input"
                        required={true}
                        step={300}
                        value={state.timeValue}
                        onChange={onTimeChange}
                        onKeyDown={onTimeInputKeyDown}
                        style={{ border: "solid 1px pink" }} />
                    <img className="geovis-custom-time-input-icon" src={ClockIcon} onClick={onClockIconClick} />
                </div>
            </div>

            {state.isOpen && (
                <div className="flexRowContainer">
                    <div className={optionsDropdownClassName}>
                        <select
                            className="geovis-custom-time-input-dropdown"
                            size={optionsSize}
                            value={state.hour}
                            onChange={onHourChange} >
                            {hoursRange.map((option) => (
                                <option key={option} value={option} disabled={isHourDisabled(option)}>
                                    {getFormattedOption(option)}
                                </option>
                            ))}
                        </select>

                        <select
                            className="geovis-custom-time-input-dropdown"
                            style={{ paddingLeft: '5px' }}
                            size={optionsSize}
                            value={state.selectedMinutes}
                            onChange={onMinutesChange} >
                            {minutesRange.map((option) => (
                                <option key={option} value={option} disabled={isMinutesDisabled(option)}>
                                    {getFormattedOption(option)}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

        </div>
    )
}

export default Component;