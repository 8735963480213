//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { KindOfElementUsing } from '../../Common/KindOfElementUsing';
import { GeovisHeaderLogoSource } from './GeovisHeaderLogoSource';

export class GeovisHeaderModel
{
	public Id: number;
	public ProjectId: number;
	public Name: string;
	public Title: string;
	public KindOfElementUsing: KindOfElementUsing;
	public TemplateCompanyId: string;
	public Description1: string;
	public Description2: string;
	public Author: string;
	public LogoSource: GeovisHeaderLogoSource;
	public CustomLogoId: string;
	public CreatedFrom: number;
	public LastUpdateTime: string;
	public Hidden: boolean;
}
