import DynamicTable, { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { HeadType, RowType, SortOrderType, StatefulProps, StatelessProps } from '@atlaskit/dynamic-table/types';
import { RowsPerPageSelect } from './RowsPerPageSelect';

export const GeovisDynamicTable = (props: StatefulProps) => (
    <div className="geovisDynamicTable">
        <DynamicTable {...props} />
    </div>
);

/**
 * Geovis stateless dynamic table
 * @param props 
 * @returns 
 */
export const GeovisDynamicTableStateless = (props: StatelessProps) => (
    <div className="geovisDynamicTable">
        <DynamicTableStateless {...props} />
    </div>
)

interface IDynamicTableWithPaginationProps {
    rowsPerPage: number;
    sortKey: string;
    sortOrder: SortOrderType;
    head: HeadType;
    rows: RowType[];
    isLoading: boolean;
    currentPage: number;
    onRowsPerPageChanged: (rowPerPage: number) => void;
    onSort: (columnKey: string, sortOrder: SortOrderType) => void;
    onPageSet: (page: number) => void;
}

export const GeovisDynamicTableStatelessWithPagination = ({
    sortKey,
    sortOrder,
    head,
    isLoading,
    rows,
    onRowsPerPageChanged,
    onSort,
    rowsPerPage,
    currentPage,
    onPageSet,
}: IDynamicTableWithPaginationProps) => {

    const onTableSetPage = (page: number) => {
        onPageSet(page);
    }

    const onTableSort = (data: any) => {
        onSort(data.key, data.sortOrder);
    }

    const onRowsPerPageChangedHandler = (option: any) => {
        onRowsPerPageChanged(option.value);
    }

    return (
        <div className="geovisDynamicTable">
            <DynamicTableStateless
                head={head}
                rows={rows}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                sortKey={sortKey}
                sortOrder={sortOrder}
                onSetPage={onTableSetPage}
                onSort={onTableSort}
                isLoading={isLoading}
            />

            {rows.length > 0 && (
                <RowsPerPageSelect
                    selectedValue={rowsPerPage}
                    onChange={onRowsPerPageChangedHandler}
                />
            )}
        </div>
    );


}