//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum HeatmapRenderingMode {
	Smooth = 0,
	Sharp = 1
}

const HeatmapRenderingModeToName = new Map<number, string>([
	[ 0, 'Smooth' ],
	[ 1, 'Sharp' ],
])

export function getHeatmapRenderingModeToName(element: HeatmapRenderingMode) : string {
    return HeatmapRenderingModeToName.get(element) || '';
}

const HeatmapRenderingModeByName = new Map<string, number>([
	[ 'Smooth', 0 ],
	[ 'Sharp', 1 ],
]);

export function getHeatmapRenderingModeByName(name: string): HeatmapRenderingMode {
    return HeatmapRenderingModeByName.get(name)!;
}


export const HeatmapRenderingModeList : Readonly<HeatmapRenderingMode[]> = [
	HeatmapRenderingMode.Smooth,
	HeatmapRenderingMode.Sharp,
];



const HeatmapRenderingModeToDescription = new Map<number, string>([
	[ 0, 'Smooth' ],
	[ 1, 'Sharp' ],
])

export function getHeatmapRenderingModeToDescription(element: HeatmapRenderingMode) : string {
    return HeatmapRenderingModeToDescription.get(element) || '';
}

