import { Reducer } from 'redux';
import {
    LOGIN_FAILED,
    LOGIN_IN_PROGRESS,
    LOGIN_MESSAGE_LANGUAGE,
    LOGIN_OUT,
    LOGIN_SUCCESS,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_IN_PROGRESS,
    RESET_PASSWORD_SUCCESS
} from '../actions/navigationActions';
import { IGeovisLoginAction, IGeovisLoginState } from '../types';

const loginInitialState: IGeovisLoginState = {
    type: "",
    errorMessage: "",
    isLoginFailed: false,
    isLoginInProgress: false,
    messageLanguage: "german"
};

const loginReducer: Reducer<IGeovisLoginState> = (state: IGeovisLoginState = loginInitialState, action: IGeovisLoginAction): IGeovisLoginState => {
    switch (action.type) {
        case LOGIN_FAILED:
        case RESET_PASSWORD_FAILED:
            return { ...state, ...action, isLoginFailed: true, isLoginInProgress: false };

        case LOGIN_IN_PROGRESS:
        case RESET_PASSWORD_IN_PROGRESS:
            return { ...state, ...action, isLoginFailed: false, isLoginInProgress: true };

        case LOGIN_SUCCESS:
        case RESET_PASSWORD_SUCCESS:
            return { ...state, ...action, isLoginFailed: false, isLoginInProgress: false, };

        case LOGIN_OUT:
            return { ...state, type: "", isLoginFailed: false, isLoginInProgress: false, errorMessage: "" };

        case LOGIN_MESSAGE_LANGUAGE:
            return { ...state, ...action };
    }

    return state;
}

export default loginReducer;