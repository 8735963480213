/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 31.07.2020
 * @description Chain map item marker implementation
 */

import { getGeoPointPositionKey2d, getViewSensorLocation } from '../../../helpers/MapHelper';
import { SensorMapTextOrientation } from '../../../server/AVTService/TypeLibrary/Common/SensorMapTextOrientation';
import { GeoJSONMapTilesLayerBounds } from '../../../server/AVTService/TypeLibrary/Model/GeoJSONMapTilesLayerBounds';
import { ChainInfo } from '../../../server/ChainInfo';
import Dispatcher, { IGeovisEventDispatcherUnsubscribe } from '../../../services/GeovisEventDispatcher';
import { ACTION_SHOW_POPUP_OF_CHAIN } from '../../../services/GeovisEventDispatcherActions';
import { createSingleChainMarkerIcon } from '../../map/IconFactory';
import { ICustomChainTooltipContentProps } from './ChainLastMeasurement';
import { GeovisMarker } from './GeovisMarker';
import MapItemMarker, { IMapItemMarkerProps } from './MapItemMarker';
import { SingleChainPopup } from './PopupControls';
import { isAnyActiveAlarm } from './tools';
import { SingleChainTooltip } from './TooltipControls';

interface IChainMapItemProps extends IMapItemMarkerProps, ICustomChainTooltipContentProps {
    chain: ChainInfo;
    showChainNames: boolean;
    onShowChartConfig: (chartId: number) => void;
    deltaCoefficient?: number;
    offsetsBounds?: GeoJSONMapTilesLayerBounds;
    leafletElement?: L.Map;
    fontSize?: number;
    useSensorColor?: boolean;
    useGeovis4SensorTypeSymbol?: boolean;
    useSensorColorForText?: boolean;
    sensorTextOrientation?: SensorMapTextOrientation;
    useMapTextOrientation?: boolean;
    backgroundColor?: string;
    showBold?: boolean;
    popupEnabled?: boolean;
    tooltipEnabled?: boolean;
    markerPaneName: string;
    showBorder?: boolean;
}

export default class ChainMapItemMarker extends MapItemMarker<IChainMapItemProps> {

    private unsubscribe: IGeovisEventDispatcherUnsubscribe;

    constructor(props: IChainMapItemProps) {
        super(props);

        this.unsubscribe = () => { /** */ };
    }

    public componentDidMount = () => {
        this.unsubscribe = Dispatcher.subscribe(this.geovisDispatchedEvent, [ACTION_SHOW_POPUP_OF_CHAIN]);
    }

    public componentWillUnmount = () => {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    public render = (): JSX.Element => {

        const {
            projectId,
            chain,
            showChainNames,
            sensorsSymbolsSettings,
            fontSize,
            useSensorColor,
            useGeovis4SensorTypeSymbol,
            useSensorColorForText,
            sensorTextOrientation,
            useMapTextOrientation,
            backgroundColor,
            showBold,
            onShowChartConfig,
            deltaCoefficient,
            invertXAxis,
            viewType,
            offsetsBounds,
            leafletElement,
            customChainsLastDataStorage,
            popupEnabled,
            tooltipEnabled,
            markerPaneName,
            showBorder
        } = this.props;

        const { iconSettings } = sensorsSymbolsSettings;
        const key2d = getGeoPointPositionKey2d(chain.Coordinates);
        const hasAnyActiveAlarm = isAnyActiveAlarm(chain.CausedAlarms);

        const position = getViewSensorLocation(viewType, chain, invertXAxis, offsetsBounds, leafletElement, deltaCoefficient);
        const drawPopup = popupEnabled === undefined || popupEnabled === true;
        const drawTooltip = tooltipEnabled === undefined || tooltipEnabled === true;

        const getMarkerChildren = (): JSX.Element[] => {
            const result: JSX.Element[] = [];

            if (drawTooltip) {
                result.push(
                    <SingleChainTooltip
                        key={`single-chain-tooltip-${chain.Id}`}
                        chain={chain}
                        useGeovis4SensorTypeSymbol={useGeovis4SensorTypeSymbol}
                        customChainsLastDataStorage={customChainsLastDataStorage}
                    />
                )
            }

            if (drawPopup) {
                result.push(
                    <SingleChainPopup
                        key={`single-chain-popup-${chain.Id}`}
                        projectId={projectId}
                        chain={chain}
                        onShowChartConfig={onShowChartConfig}
                        customChainsLastDataStorage={customChainsLastDataStorage}
                    />
                );
            }

            return result;
        }

        return (
            <GeovisMarker
                key={`sensors_group_marker_key_${key2d}`}
                makerPaneName={markerPaneName}
                markerProps={{
                    position,
                    zIndexOffset: hasAnyActiveAlarm ? 1000 : undefined,
                    icon: createSingleChainMarkerIcon(chain, {
                        showName: showChainNames,
                        fontSize,
                        replaceIconMap: iconSettings,
                        useSensorColor,
                        useGeovis4SensorTypeSymbol,
                        useSensorColorForText,
                        useMapTextOrientation,
                        mandatoryOrientation: sensorTextOrientation,
                        fontWeight: showBold ? "bold" : undefined,
                        backgroundColor,
                        // IKz: no idea why noScale related to useSensorColor. In case of errors next line should be uncommented
                        // VSk: this is really makes troubles, uncommented
                        noScale: useSensorColor ?? true,
                        // noScale: true,
                        showBorder
                    })
                }}
                setRef={this.setMarkerRefFn(chain.Id)}
                additionalProps={{ chain }}>

                {getMarkerChildren()}

            </GeovisMarker>
        )
    }

}