/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 02.07.2020
 * @description Sensors popup content controls
 */

import Button, { CustomThemeButton } from '@atlaskit/button';
import Icon from '@atlaskit/icon';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';
import TableIcon from '@atlaskit/icon/glyph/table'
import Tooltip from '@atlaskit/tooltip';
import ImageIcon from '@atlaskit/icon/glyph/image';
import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import { geovisLiveEditChartUrl } from '../../../helpers/GeovisLiveHelper';
import { navigateToProjectReport } from '../../../helpers/NavigationHelper';
import Routes from '../../../helpers/Routes';
import { getChainAsSensorInfo } from '../../../helpers/SensorHelper';
import { t } from '../../../i18n';
import { AttachedDocumentModel } from '../../../server/AttachedDocumentModel';
import { getDistanceUnitToDescription } from '../../../server/AVTService/TypeLibrary/Common/DistanceUnit';
import { SensorCategory } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { ChainInfo } from '../../../server/ChainInfo';
import { ChainType } from '../../../server/ChainType';
import ServerRoutesGen from '../../../server/Routes/ServerRoutesGen';
import { SensorBase } from '../../../server/SensorBase';
import { SensorReportInfo } from '../../../server/SensorReportInfo';
import AuthService from '../../../services/AuthService';
import { projectOverviewShowSensorDefaultChartView, projectOverviewShowSensorEditDialog, projectOverviewShowSensorImageDialog } from '../../../store/creators/projectOverviewCreators';
import { SensorAlarmContent } from '../../alarm/SensorAlarmContent';
import { createSensorIconOfSvg } from '../../map/IconFactory';
import { alarmLinkThemeFunc, sensorLinkThemeFunc } from './tools';
import { AlarmInfo } from '../../../server/AlarmInfo';
import { AlarmType } from '../../../server/AVTService/TypeLibrary/Alarming/AlarmType';
import { ICreateSensorStateIconOptions } from '../../types';
import { ProjectEditSensorsTab } from '../../../pages/edit/sensors/types';
import { SensorInfo } from '../../../server/GEOvis3/Model/SensorInfo';

export const KmPositionDescription = ({ Km, DistanceUnit }: SensorBase) => (
    <div style={{ whiteSpace: "nowrap" }}>
        &nbsp;{t("atPosLabel")}&nbsp;{Math.floor(Km * 10000) / 10000}&nbsp;{getDistanceUnitToDescription(DistanceUnit)}
    </div>
)

//#region Chains

/**
 * Chain popup header
 * @param chainInfo as properties of this control
 */
export const PopupChainHeader = (chain: ChainInfo) => {
    const { Name, ChainType: chainType, Km } = chain;
    return (
        <span>
            <b>
                <CustomThemeButton
                    appearance="link"
                    spacing="compact"
                    theme={sensorLinkThemeFunc}
                    iconBefore={
                        <Icon
                            label=""
                            dangerouslySetGlyph={createSensorIconOfSvg(getChainAsSensorInfo(chain))}
                        />}>
                    {Name}
                </CustomThemeButton>
                {chainType === ChainType.SlopeControl && Math.abs(Km) > 0 && (
                    <KmPositionDescription {...chain} />
                )}
            </b>
        </span>
    )
}

//#endregion

interface IBasePopupContent {
    projectId: number;
    sensor: SensorBase;
}

interface IAlarmPopupContentProps extends IBasePopupContent {
    onOpenAlarmEditDialog?: (alarmId: string) => void;
}

interface IChartPopupContentProps extends IBasePopupContent {
    onShowChartConfig: (chartId: number) => void;
}

/**
 * Alarm popup content, connected to Redux dispatch
 * @param props of @type IAlarmPopupContentProps
 */
export const AlarmPopupContent = ({
    projectId,
    sensor: { CausedAlarms, PhysicalType, UnitDecimals },
    onOpenAlarmEditDialog
}: IAlarmPopupContentProps) => {

    if (CausedAlarms.length === 0) {
        return (null);
    }


    const onAlarmClick = (alarm: AlarmInfo) => () => {
        if (AuthService.isActualAdminOfProject(+projectId) && alarm.AlarmType === AlarmType.SensorAlarm && onOpenAlarmEditDialog) {
            //window.open(Routes.projectEditAlarmsEditDialog.patch(projectId, alarm.AlarmId).path, '_blank', 'noopener,noreferrer');
            onOpenAlarmEditDialog(alarm.AlarmId);
        }
    }

    return (
        <div>
            {CausedAlarms
                .filter((alarm, index) => index < 3)
                .map((alarm, index) => (
                    <div key={`sensor_caused_alarm_item_${index}_popup`}>
                        <CustomThemeButton
                            appearance="link"
                            spacing="compact"
                            target="_blank"
                            //href={AuthService.isActualAdminOfProject(+projectId) ? geovisLiveEditAlarmUrl(projectId, alarm) : ''}
                            onClick={onAlarmClick(alarm)}
                            theme={alarmLinkThemeFunc(alarm)}>
                            <SensorAlarmContent alarm={alarm} sensorType={PhysicalType} sensorUnitDecimals={UnitDecimals} />
                        </CustomThemeButton>
                    </div>
                ))}
        </div>
    );
}

/**
 * Alarm charts popup content
 * @param props of @type IAlarmPopupContentProps
 */
export const ChartsPopupContent = ({ projectId, sensor: { Charts }, onShowChartConfig }: IChartPopupContentProps) => {
    if (!Charts || Charts.length === 0 || !AuthService.isActualAdminOfProject(projectId)) {
        return null;
    }

    const onShowNewChartClick = (chartId: number) => () => {
        onShowChartConfig(chartId)
    }

    return (
        <div>
            <span>{t("Members of Charts")}</span>
            {Charts
                .filter((chart, index) => index < 3)
                .map((chart, index) => (
                    <React.Fragment key={`sensor_chart_fragment_popup_${index}`}>
                        {!chart.IsNewGeovisChart &&
                            <div key={`sensor_chart_item_${index}`}>
                                <Button
                                    appearance="link"
                                    spacing="compact"
                                    target="_blank"
                                    href={geovisLiveEditChartUrl(chart)}
                                >{chart.Name}</Button>
                            </div>
                        }
                        {chart.IsNewGeovisChart &&
                            <div key={`sensor_chart_item_${index}`}>
                                <Button
                                    appearance="link"
                                    spacing="compact"
                                    onClick={onShowNewChartClick(chart.ChartId)}
                                >{chart.Name}</Button>
                            </div>
                        }
                    </React.Fragment>
                ))}
        </div>
    );
}


export const AlarmPlanPopupContent = ({ projectId, sensor: { Alarms }, onOpenAlarmEditDialog }: IAlarmPopupContentProps) => {
    if (!Alarms || Alarms.length === 0 || !AuthService.isActualAdminOfProject(projectId)) {
        return null;
    }

    const onShowAlarmClick = (alarmId: string) => () => {
        if (onOpenAlarmEditDialog) {
            onOpenAlarmEditDialog(alarmId);
        }
    }

    return (
        <div>
            <span>{t("Members of Alarm plans")}</span>
            {Alarms
                .filter((alarm, index) => index < 3)
                .map((alarm, index) => (
                    <React.Fragment key={`sensor_alarm_plan_fragment_popup_${index}`}>
                        <div key={`sensor_chart_item_${index}`}>
                            <Button
                                appearance="link"
                                spacing="compact"
                                onClick={onShowAlarmClick(alarm.AlarmId)}
                            >{alarm.Name}</Button>
                        </div>
                    </React.Fragment>
                ))}
        </div>
    );
}

/**
 * Default charts popup content
 * @param props of @type IAlarmPopupContentProps
 */
export const DefaultChartsPopupContent = ({ sensor: { DefaultCharts, Id } }: IBasePopupContent) => {
    if (!DefaultCharts || DefaultCharts.length === 0) {
        return null;
    }

    const dispatch = useDispatch();

    const drawDefaultReportDialog = (cId: number) => () => {
        dispatch(projectOverviewShowSensorDefaultChartView(cId, Id));
    }
    return (
        <div style={{ display: 'inline-flex' }}>
            {DefaultCharts
                .filter((chart, index) => index < 3)
                .map((chart, index) => (
                    <div key={`sensor_chart_item_${index}`}>
                        <Tooltip content={chart.Name} hideTooltipOnClick={true} hideTooltipOnMouseDown={true}>
                            <Button
                                appearance="default"
                                spacing="compact"
                                target="_blank"
                                iconBefore={<GraphLineIcon label="" size="small" primaryColor='gray' secondaryColor='white' />}
                                onClick={drawDefaultReportDialog(chart.ChartId)}
                            >{ }</Button>
                        </Tooltip>

                    </div>
                ))}
        </div>
    );
}

export const SettingsPopupContent = ({ sensor: { Id, PhysicalType } }: IBasePopupContent) => {
    const dispatch = useDispatch();

    const drawSensorSettingsDialog = () => {
        dispatch(projectOverviewShowSensorEditDialog(Id, PhysicalType === SensorCategory.VibrationSensor ? ProjectEditSensorsTab.Vibration : ProjectEditSensorsTab.Standard));
    }
    return (
        <div key={`sensor_settings_item`}>
            <Tooltip content={t("Settings")} hideTooltipOnClick={true} hideTooltipOnMouseDown={true}>
                <Button
                    appearance="default"
                    spacing="compact"
                    target="_blank"
                    iconBefore={<EditFilledIcon label={"settings"} size="small" primaryColor='gray' secondaryColor='white' />}
                    onClick={drawSensorSettingsDialog}
                />
            </Tooltip>

        </div>
    )
}

interface ISensorInfoPopupContent {
    projectId: number;
    sensor: SensorInfo;
}

export const ImagePopupContent = ({ sensor }: ISensorInfoPopupContent) => {
    const dispatch = useDispatch();

    const drawSensorImageDialog = () => {
        dispatch(projectOverviewShowSensorImageDialog(sensor));
    }
    return (
        <div key={`sensor_settings_item`}>
            <Tooltip content={t("Image")} hideTooltipOnClick={true} hideTooltipOnMouseDown={true}>
                <Button
                    appearance="default"
                    spacing="compact"
                    target="_blank"
                    iconBefore={<ImageIcon label={"image"} size="small" primaryColor='gray' secondaryColor='white' />}
                    onClick={drawSensorImageDialog}
                />
            </Tooltip>

        </div>
    )
}

export const SensorDataLinkPopupContent = ({ sensor, projectId }: IBasePopupContent) => {

    if (sensor.PhysicalType === SensorCategory.VibrationSensor) {
        return null;
    }

    const openSensorDataPage = () => {
        window.open(`${Routes.projectEditSensorData.patch(projectId).path}#FullIds=${sensor.Id};UseRaw=1;Type=0;UseAxis=1;`, '_blank', 'noopener,noreferrer');
    }

    return (
        <div>
            <Tooltip content={t("Sensor data")} hideTooltipOnClick={true} hideTooltipOnMouseDown={true}>
                <Button
                    appearance="default"
                    spacing="compact"
                    target="_blank"
                    iconBefore={<TableIcon label="" size="small" primaryColor='gray' secondaryColor='white' />}
                    onClick={openSensorDataPage}
                >{ }</Button>
            </Tooltip>
        </div>
    );
}


interface IReportsPopupContentProps extends RouteComponentProps<{}> {
    projectId: number;
    sensor: SensorBase;
    header?: string;
}

/**
 * Popup reports content
 * @param props of @type IReportsPopupContentProps
 */
export const ReportsPopupContent = withRouter(({
    projectId,
    sensor: { Reports },
    header,
    history
}: IReportsPopupContentProps) => {

    if (!Reports) {
        return null;
    }

    const isAdminOfProject = AuthService.isActualAdminOfProject(projectId);

    const reports = Reports
        .filter((report, index) => index < 3)
        .filter((report) => report.IsPublic || !report.IsPublic && isAdminOfProject);

    if (reports.length === 0) {
        return (null);
    }

    if (!header) {
        header = t("Reports");
    }

    const onReportLinkClickFunc = ({ ReportId }: SensorReportInfo) => () => {
        navigateToProjectReport(history, projectId, ReportId);
    }


    return (
        <div style={{ marginBottom: '5px' }}>
            <span>{header}</span>
            {reports.map((report, index) => (
                <div key={`sensor_report_item_${index}`} id={report.ReportId.toString()}>
                    <Button
                        appearance="link"
                        spacing="compact"
                        onClick={onReportLinkClickFunc(report)}
                    >{report.Name}</Button>
                </div>
            ))}
        </div>
    )
});

interface IDocumentsPopupContentProps {
    documents: AttachedDocumentModel[];
    projectId: number;
}

export const DocumentsPopupContent = ({ documents, projectId }: IDocumentsPopupContentProps) => {
    if (!documents || documents.length === 0) {
        return null;
    }

    const isAdminOfProject = AuthService.isActualAdminOfProject(projectId);
    const documentsToShow = documents.filter((doc) => doc.IsPublic || isAdminOfProject);
    if (documentsToShow.length === 0) {
        return null;
    }

    const onDocumentClickFn = (document: AttachedDocumentModel) => async () => {
        const downloadUrl = ServerRoutesGen.LocalMapObject.DownloadAttachedDocument.patch(document.ProjectId, document.Id).path;

        const fileUrl = await AuthService.getRequestHelper(AuthService).getFileObjectUrl(downloadUrl, document.Name);
        window.open(fileUrl, "_blank");
    }

    return (
        <div>
            <span>{t("Related documents")}</span>
            {documentsToShow
                .filter((doc, index) => index < 3)
                .map((doc, index) => (
                    <div key={`sensor_doc_item_${index}`}>
                        <Button
                            appearance="link"
                            spacing="compact"
                            target="_blank"
                            onClick={onDocumentClickFn(doc)}
                        >{doc.Name}</Button>
                    </div>
                ))}
        </div>
    );
}

interface ISensorLinkPopupContent {
    sensorLink: string;
    sensorLinkName: string;
}

export const SensorLinkPopupContent = ({
    sensorLink,
    sensorLinkName
}: ISensorLinkPopupContent) => {

    if (!sensorLink) {
        return null;
    }

    if (!sensorLinkName) {
        sensorLinkName = sensorLink;
    }

    const onLinkClick = () => {
        let url: string = '';
        if (!/^http[s]?:\/\//.test(sensorLink)) {
            url += 'http://';
        }

        url += sensorLink;
        window.open(url, "_blank");
    }

    return (
        <div>
            <span>{t("Link")}</span>
            <div>
                <Button
                    appearance="link"
                    spacing="compact"
                    target="_blank"
                    onClick={onLinkClick}
                >{sensorLinkName}</Button>
            </div>
        </div>
    )
}

interface IMapItemPopupHeaderAsLinkProps {
    sensor: SensorBase;
    sensorIconOptions?: ICreateSensorStateIconOptions;
    onClick?: () => void;
}

export const MapItemPopupHeaderAsLink = ({ sensor, onClick, sensorIconOptions }: IMapItemPopupHeaderAsLinkProps) => (
    <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
        whiteSpace: "nowrap",
        width: "max-content"
    }}>
        <CustomThemeButton
            appearance="link"
            spacing="compact"
            theme={sensorLinkThemeFunc}
            iconBefore={
                <Icon
                    label=""
                    dangerouslySetGlyph={createSensorIconOfSvg(sensor, sensorIconOptions)}
                />}
            onClick={onClick}>
            {sensor.Name}
        </CustomThemeButton>
        {Math.abs(sensor.Km) > 0 && (<b> <KmPositionDescription {...sensor} /></b>)}
    </div>
)