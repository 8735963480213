/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.02.2020
 * @description MapTiles Service routes
 */

import Route from './Route';

export class LeafletMapTilesRoutes {
    /**
     * Dxf map tile route
     * @param layerId
     */
    public static DxfMapTilesRoute = new Route({
        path: '/tilesContent/:layerId/dxf/{z}/{x}/{y}'
    });

    /**
     * Local map object map tiles
     * @param objectId
     */
    public static DxfLmoMapTilesRoute = new Route({
        path: '/tilesContent/:projectId/dxfLmo/:objectId/{z}/{x}/{y}'
    });

    /**
     * Geovis image map tiles
     * @param objectId
     */
    public static DxfGeovisImageMapTilesRoute = new Route({
        path: '/tilesContent/:projectId/dxfGeovisImage/:objectId/{z}/{x}/{y}'
    });

    /**
     * ProfileVIew object map tiles
     * @param objectId
     */
    public static DxfProfileViewMapTilesRoute = new Route({
        path: '/tilesContent/:projectId/dxfProfileView/:objectId/{z}/{x}/{y}'
    });

    /**
     * Dxf map tile route
     * @param layerId
     */
    public static GeoRasterMapTilesRoute = new Route({
        path: '/tilesContent/:projectId/raster/:layerId/{z}/{x}/{y}'
    });
}