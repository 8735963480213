/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.08.2023
 * @description Tool methods for Project Elements Group on the report page
 */

import { ReportElementType } from "../../../../server/AVTService/TypeLibrary/Common/ReportElementType";
import { GeovisReportElementInfo } from "../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo";
import { IGeovisReportPageConfig } from "../../../../store/projectReport.types";
import { MapArea } from "../../../../server/AVTService/TypeLibrary/Map/MapArea";
import { GeovisReportElementInfoBase } from "../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfoBase";

export interface IGeovisReportGroupElementInfo extends GeovisReportElementInfo {
    width: number;
    isLoading: boolean;
    maxHeight: number;
}

/**
 * Get elements widths for project elements group
 * @param rootRef
 * @param rootElementInfo
 * @param page 
 */
export const getProjectElementsGroupElementsInfo = (rootDiv: HTMLDivElement, rootElementInfo: GeovisReportElementInfo, page: IGeovisReportPageConfig): IGeovisReportGroupElementInfo[] => {

    const containerWidth = rootDiv.clientWidth - 1;

    /**
     * There is problem with mixing GeovisImages and MapSections
     * Current version of algorithm has bad arrange between MapSection and GeovisImage
     * 
     * Temporary solution:
     * - separate computation of widths
     *  - only for GeovisImages
     *  - only for MapSections
     *  - even widths for mixed elements in a group
     */

    if (isOnlyProjectElements(rootElementInfo.Children, [ReportElementType.MapSection])) {
        return getProjectElementsWidthsForMapSections(containerWidth, rootElementInfo, page);
    }

    if (isOnlyProjectElements(rootElementInfo.Children, [ReportElementType.GeovisImage])) {
        return getProjectElementsWidthsForGeovisImages(containerWidth, rootElementInfo);
    }

    {

        const result: Array<IGeovisReportGroupElementInfo> = [];
        const elementsInfo = rootElementInfo.Children.map<GeovisReportElementInfo>(ch => ({ ...rootElementInfo, ...ch }));

        for (const elementInfo of elementsInfo) {
            result.push({ ...elementInfo, isLoading: false, width: containerWidth / elementsInfo.length, maxHeight: elementInfo.Height });
        }

        return convertPixelWidthToPercent(result);
    }
}

const getDefaultLoadingConfig = (containerWidth: number, rootElementInfo: GeovisReportElementInfo): IGeovisReportGroupElementInfo[] => [({
    ...rootElementInfo,
    isLoading: true,
    width: containerWidth,
    maxHeight: rootElementInfo.Height
})];

/**
 * Check is map area is empty
 * @param mapArea 
 * @returns 
 */
const isEmptyMapArea = (mapArea: MapArea): boolean => {

    if (!mapArea) {
        return false;
    }

    return mapArea.Width <= 0 || mapArea.Height <= 0;


}

const isOnlyProjectElements = (elementsInfo: GeovisReportElementInfoBase[], elementTypes: ReportElementType[]): boolean => {
    return elementsInfo.filter(e => elementTypes.includes(e.ElementType)).length === elementsInfo.length;
}

const getMapSectionSize = (page: IGeovisReportPageConfig, elementInfo: GeovisReportElementInfoBase): MapArea | undefined | "not found" => {
    if (elementInfo.ElementType !== ReportElementType.MapSection) {
        return undefined;
    }
    const mapSectionInfo = page.mapSections.get(elementInfo.Id);
    if (!mapSectionInfo) {
        return "not found";
    }

    const { MapSection } = mapSectionInfo;
    const mapArea = MapSection.MapArea;

    if (isEmptyMapArea(mapArea)) {
        return undefined;
    }

    //return mapArea.East >= 0 && mapArea.West >= 0 ? Math.abs(mapArea.East - mapArea.West) : Math.abs(mapArea.East) + Math.abs(mapArea.West);
    return mapArea;
}

/*
const getGeovisImageSize = (page: IGeovisReportPageConfig, elementInfo: GeovisReportElementInfoBase): MapArea | undefined | "not found" => {
    if (elementInfo.ElementType !== ReportElementType.GeovisImage) {
        return undefined;
    }
    const imagesInfo = page.geovisImages.get(elementInfo.Id);
    if (!imagesInfo) {
        return "not found";
    }

    const { GeovisImage } = imagesInfo;
    const viewPort = GeovisImage.ImageInfo.ViewPort;

    const mapArea: MapArea = { East: viewPort.Right, West: viewPort.Left, North: viewPort.Top, South: viewPort.Bottom, ZoomLevel: 0, Height: Math.abs(viewPort.Top - viewPort.Bottom), Width: Math.abs(viewPort.Right - viewPort.Left) }

    if (isEmptyMapArea(mapArea)) {
        return undefined;
    }

    return mapArea;
}

const getMapSectionOrGeovisImageWidth = (page: IGeovisReportPageConfig, elementInfo: GeovisReportElementInfoBase): number | false | "not found" => {

    let mapArea: MapArea | false = false;

    switch (elementInfo.ElementType) {
        case ReportElementType.MapSection: {
            const mapSectionInfo = page.mapSections.get(elementInfo.Id);
            if (!mapSectionInfo) {
                return "not found";
            }

            const { MapSection } = mapSectionInfo;
            mapArea = MapSection.MapArea;

            if (isEmptyMapArea(mapArea)) {
                return false;
            }

            //return mapArea.East >= 0 && mapArea.West >= 0 ? Math.abs(mapArea.East - mapArea.West) : Math.abs(mapArea.East) + Math.abs(mapArea.West);
            return mapArea.Width;
        }

        case ReportElementType.GeovisImage: {
            const geovisImageInfo = page.geovisImages.get(elementInfo.Id);
            if (!geovisImageInfo) {
                return "not found";
            }

            const { GeovisImage } = geovisImageInfo;
            const { ViewPort } = GeovisImage.ImageInfo;

            mapArea = {
                East: ViewPort.Right,
                North: ViewPort.Top,
                South: ViewPort.Bottom,
                West: ViewPort.Left,
                Height: Math.abs(ViewPort.Top - ViewPort.Bottom),
                Width: Math.abs(ViewPort.Right - ViewPort.Left),
                ZoomLevel: 0
            };
            break;
        }
    }

    if (mapArea === false || isEmptyMapArea(mapArea)) {
        return false;
    }

    return mapArea.Width;
}
*/

/**
 * Calculate space for MapSections and GeovisImages in the group
 * @param containerWidth 
 * @param rootElementInfo 
 * @param page 
 * @returns 
 */
const getProjectElementsWidthsForMapSections = (containerWidth: number, rootElementInfo: GeovisReportElementInfo, page: IGeovisReportPageConfig): IGeovisReportGroupElementInfo[] => {

    const result: IGeovisReportGroupElementInfo[] = [];
    const sizeByChildren = new Map<GeovisReportElementInfoBase, MapArea | undefined>();
    let minHeight = -1;
    for (const child of rootElementInfo.Children) {
        const childSize = getMapSectionSize(page, child);

        if (childSize === "not found") {
            return getDefaultLoadingConfig(containerWidth, rootElementInfo);
        }

        sizeByChildren.set(child, childSize);

        if (childSize !== undefined && (minHeight < 0 || minHeight > childSize.Height)) {
            minHeight = childSize.Height;
        }
    }

    sizeByChildren.forEach((value, key) => {
        let width = 0;
        if (value === undefined) {
            width = containerWidth / rootElementInfo.Children.length;
        }
        else {
            width = value.Width * minHeight / value.Height;
        }

        const elementInfo: GeovisReportElementInfo = { ...rootElementInfo, ...key };
        result.push({ ...elementInfo, isLoading: false, width, maxHeight: minHeight > 0 ? minHeight : elementInfo.Height });
    })

    return convertPixelWidthToPercent(result);
}

const getProjectElementsWidthsForGeovisImages = (containerWidth: number, rootElementInfo: GeovisReportElementInfo): IGeovisReportGroupElementInfo[] => {

    const result: IGeovisReportGroupElementInfo[] = [];
    // let totalWidth = 0;


    for (const child of rootElementInfo.Children) {

        const width = containerWidth / rootElementInfo.Children.length;

        const elementInfo: GeovisReportElementInfo = { ...rootElementInfo, ...child };
        result.push({ ...elementInfo, isLoading: false, width, maxHeight: elementInfo.Height });
    }

    return convertPixelWidthToPercent(result);
}

const convertPixelWidthToPercent = (elements: IGeovisReportGroupElementInfo[]): IGeovisReportGroupElementInfo[] => {

    const totalWidth = elements.map(e => e.width).reduce((prev, current) => {
        return prev + current
    });

    //get group width in percent, but each item can not be less then 30% 

    let restSpace = 100;
    const minSpace = 10;

    // const sortElementsFunc = (elemA: IGeovisReportGroupElementInfo, elemB: IGeovisReportGroupElementInfo): number => {
    //     if (elemA.width === elemB.width) {
    //         return 0;
    //     }
    //     return elemA.width > elemB.width ? 1 : -1;
    // }

    for (const elementInfo of elements) {

        let localPercent = Math.round(100 * elementInfo.width / totalWidth);

        // const destWidth = elementInfo.width * containerWidth / totalWidth;
        // const destPart = Math.trunc(100 * destWidth / totalWidth);
        if (localPercent < minSpace) {
            localPercent = minSpace;
        }

        if (localPercent > restSpace) {
            localPercent = restSpace;
        }

        restSpace = restSpace - localPercent;

        elementInfo.width = localPercent;
    }

    return elements;
}