//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { LocalMapObject } from './AVTService/TypeLibrary/LocalMapObjects/LocalMapObject';
import { GeoLocation } from './GeoLocation';

export class LocalMapObjectLink extends LocalMapObject
{
	public Color: string;
	public TextOnTheMap: string;
	public FontSize: number;
	public Location: GeoLocation;
	public Link: string;
	public RotationAngle: number;
}
