/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.08.2021
 * @description Tool methods for calendar view
 */

import { getCalendarEndDay, getCalendarStartDay } from "../../../../helpers/DateHelper";
import { InventoryAfmBoxModel } from "../../../../server/AVTService/TypeLibrary/Inventory/InventoryAfmBoxModel";
import { InventoryAtcSensorModel } from "../../../../server/AVTService/TypeLibrary/Inventory/InventoryAtcSensorModel";
import { InventoryCalendarViewFilterOptions } from "../../../../server/AVTService/TypeLibrary/Inventory/InventoryCalendarViewFilterOptions";
import { InventoryLoRaGatewayModel } from "../../../../server/AVTService/TypeLibrary/Inventory/InventoryLoRaGatewayModel";
import { InventoryMstModel } from "../../../../server/AVTService/TypeLibrary/Inventory/InventoryMstModel";
import { InventoryObject } from "../../../../server/AVTService/TypeLibrary/Inventory/InventoryObject";
import { getInventoryObjectTypeToName, InventoryObjectType, InventoryObjectTypeList } from "../../../../server/AVTService/TypeLibrary/Inventory/InventoryObjectType";
import { InventorySensorboxModel } from "../../../../server/AVTService/TypeLibrary/Inventory/InventorySensorboxModel";
import { InventoryTachymeterModel } from "../../../../server/AVTService/TypeLibrary/Inventory/InventoryTachymeterModel";
import { getInventoryObjectProperty } from "../tools";

const getTypesFromSessionStorage = (): InventoryObjectType[] => {
    const result: InventoryObjectType[] = [];
    InventoryObjectTypeList.forEach(io => {
        const item = sessionStorage.getItem(getInventoryObjectTypeToName(io));
        if (item && item === true.toString()) {
            result.push(io);
        }
    });

    if (result.length === 0) {
        result.push(InventoryObjectType.MST);
    }

    return result;
}

export const defaultInventoryCalendarFilters: InventoryCalendarViewFilterOptions = {
    Limit: 0,
    PageNumber: 0,
    SearchText: '',
    Statuses: [],
    Types: getTypesFromSessionStorage(),
    EndTime: getCalendarEndDay(),
    StartTime: getCalendarStartDay(),
    ShowOnlyUnconfirmed: false,
    ShowOnlyATTachymeter: true,
    IsAscending: true,
    SortFieldName: "SerialNumber",
}

export const getCheckMarkSignCodeIfTrue = (value: boolean): string => {
    return value ? '<span>&#10004;</span>' : '';
}

export const getSerialNumberOfObject = (inventoryObject: InventoryObject): string => {
    switch (inventoryObject.ObjectType) {
        case InventoryObjectType.MST: return getInventoryObjectProperty<InventoryMstModel>(inventoryObject, "SerialNumber");
        case InventoryObjectType.SB: return getInventoryObjectProperty<InventorySensorboxModel>(inventoryObject, "SerialNumber");
        case InventoryObjectType.Tachymeter: return getInventoryObjectProperty<InventoryTachymeterModel>(inventoryObject, "SerialNumber");
        case InventoryObjectType.Atc: return getInventoryObjectProperty<InventoryAtcSensorModel>(inventoryObject, "SerialNumber");
        case InventoryObjectType.Afm: return getInventoryObjectProperty<InventoryAfmBoxModel>(inventoryObject, "SerialNumber");
        case InventoryObjectType.LoRaGateway: return getInventoryObjectProperty<InventoryLoRaGatewayModel>(inventoryObject, "SerialNumber");
    }
}
