import Button from "@atlaskit/button";
import { Radio } from "@atlaskit/radio";
import Textfield from '@atlaskit/textfield';
import React, { useState } from "react"
import { t } from "../../../../i18n"
import { getReferenceGroupTypeByName, getReferenceGroupTypeToName, ReferenceGroupType } from "../../../../server/AVTService/TypeLibrary/Common/ReferenceGroupType";
import { XyChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel"
import { EditIcon } from "../../../Icons";
import { ChartSensorsSelectDialog } from "../ChartSensorsSelectDialog";
import { ChartBooleanSettingProps } from "../ChartTab_tools"
import { defaultChartRowSettingsStyle, IGeovisChartPropertyChangedProps } from "../types";

interface IComponentProps extends IGeovisChartPropertyChangedProps<XyChartModel> {
    chart: XyChartModel;
}

export const XyChartOptionsSettings = ({ chart, onChartPropertyChanged }: IComponentProps) => {

    const [showSelectSensorDialog, setShowSelectSensorDialog] = useState<boolean>(false);

    const onChartPropertyChangedFunc = (propertyName: keyof XyChartModel) => (value: any) => {
        onChartPropertyChanged(propertyName, value);
    }

    const onReferenceGroupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChartPropertyChanged("ReferenceGroup", getReferenceGroupTypeByName(e.currentTarget.value.toString()));
    }

    const onSensorsSelectCanceled = () => setShowSelectSensorDialog(false);
    const onSelectButtonClick = () => setShowSelectSensorDialog(true);

    const onChangeSensorId = (sensorId: string) => {
        onChartPropertyChanged("ReferenceSensorOrChainId", sensorId);
    }

    const settingRowStyle = defaultChartRowSettingsStyle;

    return (
        <div>
            <ChartBooleanSettingProps
                label={t("Show date of reference measurements")}
                isChecked={chart.ShowReferenceDate}
                onChange={onChartPropertyChangedFunc("ShowReferenceDate")}
                isDisabled={false} />

            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer_g1">
                    <Radio
                        name={t("Show the time of the reference measurements from the list of sensors")}
                        value={getReferenceGroupTypeToName(ReferenceGroupType.ShowFromTheListOfSensors)}
                        isChecked={chart.ReferenceGroup === ReferenceGroupType.ShowFromTheListOfSensors}
                        onChange={onReferenceGroupChange}
                        isDisabled={!chart.ShowReferenceDate}
                        label={t("Show the time of the reference measurements from the list of sensors")}
                    />
                </div>
            </div>
            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer_g1" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center' }}>
                    <div style={{ width: '40%' }}>
                        <Radio
                            name={t("Show the time of the reference measurement from") + ":"}
                            value={getReferenceGroupTypeToName(ReferenceGroupType.ShowFromSelectedSensors)}
                            isChecked={chart.ReferenceGroup === ReferenceGroupType.ShowFromSelectedSensors}
                            onChange={onReferenceGroupChange}
                            isDisabled={!chart.ShowReferenceDate}
                            label={t("Show the time of the reference measurement from") + ":"}
                        />
                    </div>
                    <div style={{ width: '40%', marginLeft: '10px', marginRight: '10px' }}>
                        <Textfield
                            isCompact={true}
                            value={chart.ReferenceSensorOrChainId}
                            isReadOnly={true}
                            isDisabled={!chart.ShowReferenceDate || chart.ReferenceGroup !== ReferenceGroupType.ShowFromSelectedSensors}
                        />
                    </div>
                    <div style={{ width: '10%' }}>
                        <Button
                            spacing='compact'
                            appearance="subtle"
                            onClick={onSelectButtonClick}
                            iconBefore={<EditIcon label="" />}
                            isDisabled={!chart.ShowReferenceDate || chart.ReferenceGroup !== ReferenceGroupType.ShowFromSelectedSensors}
                        />
                    </div>
                </div>
            </div>

            {showSelectSensorDialog && (
                <ChartSensorsSelectDialog
                    chartType={chart.Type}
                    dtsSectionIds={[]}
                    projectId={chart.ProjectId}
                    selectedSensorId={chart.ReferenceSensorOrChainId}
                    selectedChainsIds={chart.LeftYAxisSettings.ChainIds}
                    onSelectSingleSensor={onChangeSensorId}
                    dialogType={'single'}
                    sensorChanges={[]}
                    onCanceled={onSensorsSelectCanceled} />
            )}

        </div>
    )
}