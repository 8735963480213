//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GvLogApp {
	GEOvisLive = 0,
	GEOvisLiveService = 1,
	GEOvis4 = 2,
	GEOvisMapTiles = 3,
	GEOvisBackupService = 4,
	GEOvisMaintenanceService = 5,
	GEOvisDataSyncService = 6
}

const GvLogAppToName = new Map<number, string>([
	[ 0, 'GEOvisLive' ],
	[ 1, 'GEOvisLiveService' ],
	[ 2, 'GEOvis4' ],
	[ 3, 'GEOvisMapTiles' ],
	[ 4, 'GEOvisBackupService' ],
	[ 5, 'GEOvisMaintenanceService' ],
	[ 6, 'GEOvisDataSyncService' ],
])

export function getGvLogAppToName(element: GvLogApp) : string {
    return GvLogAppToName.get(element) || '';
}

const GvLogAppByName = new Map<string, number>([
	[ 'GEOvisLive', 0 ],
	[ 'GEOvisLiveService', 1 ],
	[ 'GEOvis4', 2 ],
	[ 'GEOvisMapTiles', 3 ],
	[ 'GEOvisBackupService', 4 ],
	[ 'GEOvisMaintenanceService', 5 ],
	[ 'GEOvisDataSyncService', 6 ],
]);

export function getGvLogAppByName(name: string): GvLogApp {
    return GvLogAppByName.get(name)!;
}


export const GvLogAppList : Readonly<GvLogApp[]> = [
	GvLogApp.GEOvisLive,
	GvLogApp.GEOvisLiveService,
	GvLogApp.GEOvis4,
	GvLogApp.GEOvisMapTiles,
	GvLogApp.GEOvisBackupService,
	GvLogApp.GEOvisMaintenanceService,
	GvLogApp.GEOvisDataSyncService,
];



const GvLogAppToDescription = new Map<number, string>([
	[ 0, 'GEOvisLive' ],
	[ 1, 'GEOvisLiveService' ],
	[ 2, 'GEOvis4' ],
	[ 3, 'GEOvisMapTiles' ],
	[ 4, 'GEOvisBackupService' ],
	[ 5, 'GEOvisMaintenanceService' ],
	[ 6, 'GEOvisDataSyncService' ],
])

export function getGvLogAppToDescription(element: GvLogApp) : string {
    return GvLogAppToDescription.get(element) || '';
}

