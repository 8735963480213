//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IPopupContent } from './IPopupContent';
import { SensorBase } from './SensorBase';
import { ChainType } from './ChainType';
import { GeoLocation } from './GeoLocation';

export class ChainInfo extends SensorBase implements IPopupContent
{
	public ChainType: ChainType;
	public EndSensorLocation: GeoLocation;
	public SensorsIds: string[];
	public IsEmptyEndSensorsLocation: boolean;
}
