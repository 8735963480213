import AddIcon from '@atlaskit/icon/glyph/add'
import Button from "@atlaskit/button";
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import Tooltip from '@atlaskit/tooltip';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { HeadType, RowType } from '@atlaskit/dynamic-table/dist/types/types';
import React, { SyntheticEvent, useRef, useState } from 'react';
import CheckIcon from '@atlaskit/icon/glyph/check'
import Textfield from '@atlaskit/textfield';
import { debounce } from 'lodash';
import { OptionType } from '@atlaskit/select';
import { GeovisAlarmModel } from "../../../../server/GEOvis3/Model/Alarms/GeovisAlarmModel";
import { t } from "../../../../i18n";
import { GeovisTableWithSelectableRows } from '../../../../components/DynamicTableWithSelectableRows';
import { GeovisAlarmSensor } from '../../../../server/GEOvis3/Model/Alarms/GeovisAlarmSensor';
import { GeovisSelectItemsDialog } from '../../../../components/geovisDialogs/GeovisSelectItemsDialog';
import { ViewEditDataItem } from '../../../../server/GEOvis3/Model/ProjectViews/ViewEditDataItem';
import ServerRoutesGen from '../../../../server/Routes/ServerRoutesGen';
import { SensorValueAttribute } from '../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute';
import { fetchServerElementsByPost, sendServerGetRequest, sendServerPostRequestData } from '../../../../helpers/ProjectDataHelper';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../helpers/GeovisHooks';
import { compareDates } from '../../../../helpers/DateHelper';
import { AlarmConfirmationOptions } from '../../../../server/GEOvis3/Model/AlarmConfirmationOptions';
import { ActionResponse } from '../../../../server/ActionResponse';
import FlagService from '../../../../services/FlagService';
import { isMatchToSearchString } from '../../../../helpers/FiltersHelper';
import { GeovisSelect } from '../../../../components/select/GeovisSelect';
import { AlarmSensorsPayload } from '../../../../server/GEOvis3/Model/Alarms/AlarmSensorsPayload';
import { SensorCategory } from '../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';

interface IComponentProps extends IWithGeovisServerProps {
    alarm: GeovisAlarmModel;
    onAlarmChanged: (updAlarm: GeovisAlarmModel) => void;
    isReadonly: boolean;
}

interface IComponentState {
    showSensorsDialog: boolean;
    searchText: string;
    activeSensorsState?: number;
}

const AlarmEditDialogSensorsSettingsTab = ({
    alarm,
    isReadonly,
    onAlarmChanged,
    Server
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        showSensorsDialog: false,
        searchText: '',
        activeSensorsState: undefined
    })

    const searchFieldRef = useRef<HTMLInputElement>(null);

    const getAllItems = (): GeovisAlarmSensor[] => {
        return alarm.Sensors.filter(s => filterSensorFunc(s));
    }

    const getIdFunc = (sensor: GeovisAlarmSensor): string => {
        return sensor.FullId;
    }

    const onRemoveSensor = (sensorId: string) => () => {
        alarm.Sensors = alarm.Sensors.filter(s => s.FullId !== sensorId);
        onAlarmChanged(alarm);
    }

    const onConfirmSensor = (sensorId: string) => async (event: React.MouseEvent) => {
        event.preventDefault();
        const payload: AlarmConfirmationOptions = {
            AlarmId: alarm.Id,
            SensorId: sensorId
        }

        const url = ServerRoutesGen.Alarms.ConfirmSensorAlarm.patch(alarm.ProjectId);
        const response = await sendServerPostRequestData<AlarmConfirmationOptions, ActionResponse>(Server, url, payload);

        if (!response.Success) {
            FlagService.addError("Failed to confirm sensor", response.Messages.join("; "));
            return;
        }

        alarm.Sensors.forEach(s => {
            if (s.FullId === sensorId) {
                s.TriggeredCount = 0;
                s.ConditionId = "";
            }
        })

        onAlarmChanged(alarm);
        event.preventDefault();
    }

    const onConfirmAllSensors = async () => {
        const url = ServerRoutesGen.Alarms.ConfirmProjectAlarm.patch(alarm.ProjectId, alarm.Id);
        const response = await sendServerGetRequest<ActionResponse>(Server, url);
        if (!response.Success) {
            FlagService.addError("Failed to confirm all sensors", response.Messages.join("; "));
            return;
        }
        alarm.Sensors.forEach(s => {
            s.TriggeredCount = 0;
            s.ConditionId = "";
        })
        alarm.MaxTriggerCount = 0;

        onAlarmChanged(alarm);
    }

    const getRowColor = (sensor: GeovisAlarmSensor): string => {

        const condition = alarm.Conditions.find(c => c.Id === sensor.ConditionId);
        if (!condition) {
            return "";
        }

        return condition.color;
    }

    const getRow = (sensor: GeovisAlarmSensor): RowType => {
        return ({
            key: `row-${getIdFunc(sensor)}`,
            cells: [{
                key: 'name',
                content: (
                    <span>{sensor.Name}</span>
                )
            }, {
                key: 'triggered',
                content: (
                    <span>{sensor.TriggeredCount}</span>
                )
            }, {
                key: 'lastValueTimeslot',
                content: (
                    <span>{sensor.LastTimeslot}</span>
                )
            }, {
                key: 'lastValue',
                content: (
                    <span>{sensor.LastValue}</span>
                )
            }, {
                key: 'actions',
                content: (
                    <div style={{ display: 'flex', flexFlow: 'nowrap row', marginRight: '5px', justifyContent: 'flex-end' }}>
                        {!isReadonly && sensor.TriggeredCount > 0 &&
                            <Tooltip content={t("Confirm sensor")}>
                                <Button
                                    iconBefore={<CheckIcon label='confirm' />}
                                    onClick={onConfirmSensor(sensor.FullId)}
                                />
                            </Tooltip>
                        }
                        {!isReadonly &&
                            <Tooltip content={t("Remove sensor")}>
                                <Button
                                    iconBefore={<TrashIcon label='remove' />}
                                    onClick={onRemoveSensor(sensor.FullId)}
                                />
                            </Tooltip>
                        }
                        {isReadonly && sensor.TriggeredCount > 0 &&
                            <Tooltip content={t("Confirm sensor")}>
                                <Button
                                    iconBefore={<CheckIcon label='confirm' primaryColor="#a5adba" />}
                                />
                            </Tooltip>
                        }
                        {isReadonly &&
                            <Tooltip content={t("Can not remove sensor when alarm is active")}>
                                <Button
                                    iconBefore={<TrashIcon label='remove' primaryColor="#a5adba" />}
                                />
                            </Tooltip>
                        }
                    </div>
                )
            },]
        })
    }

    const onSelectionChanged = (selection: GeovisAlarmSensor[]) => {
        const selectedIds = selection.map(s => getIdFunc(s));
        alarm.Sensors.forEach(s => {
            if (selectedIds.includes(getIdFunc(s))) {
                s.Active = true;
            }
            else {
                s.Active = false;
            }
        })

        onAlarmChanged(alarm);
    }

    const getSelectedItems = (): GeovisAlarmSensor[] => {
        return alarm.Sensors.filter(s => s.Active);
    }

    const getHead = (): HeadType => {
        return ({
            cells: [{
                key: 'name',
                isSortable: true,
                content: t("Sensor name")
            }, {
                key: 'triggered',
                isSortable: true,
                content: t("Triggered count")
            }, {
                key: 'lastValueTimeslot',
                isSortable: true,
                content: t("Last value time")
            }, {
                key: 'lastValue',
                isSortable: true,
                content: t("Last value")
            }, {
                key: 'actions',
                content: (
                    <div style={{ display: 'flex', flexFlow: 'nowrap row', marginRight: '5px', justifyContent: 'flex-end' }}>
                        {!isReadonly && alarm.MaxTriggerCount > 0 &&
                            <Tooltip content={t("Confirm all sensors")}>
                                <Button
                                    iconBefore={<CheckIcon label='confirm' />}
                                    onClick={onConfirmAllSensors}
                                />
                            </Tooltip>
                        }
                        {isReadonly && alarm.MaxTriggerCount > 0 &&
                            <Tooltip content={t("Confirm all sensors")}>
                                <Button
                                    iconBefore={<CheckIcon label='confirm' primaryColor="#a5adba" />}
                                />
                            </Tooltip>
                        }
                    </div>
                )
            }]
        })
    }

    const onSelectDeselectAll = (allSelected: boolean) => {
        alarm.Sensors.forEach(s => {
            s.Active = allSelected;
        })

        onAlarmChanged(alarm);
    }

    const onShowSelectSensorsClick = () => {
        setState({
            ...state,
            showSensorsDialog: true
        })
    }

    const onHideSelectSensorsDialog = () => {
        setState({
            ...state,
            showSensorsDialog: false
        })
    }

    const onAddedSensors = (selectedSensors: GeovisAlarmSensor[]) => {

        alarm.Sensors = selectedSensors.map(s => {

            // keep exist selected sensors
            const exists = alarm.Sensors.find(es => es.FullId === s.FullId);
            if (exists) {
                return exists;
            }

            // or return newest selected
            return s;
        });

        onAlarmChanged(alarm);
        onHideSelectSensorsDialog();
    }

    const convertFunc = (sensor: GeovisAlarmSensor): ViewEditDataItem => {
        return {
            SensorCategory: sensor.PhysicalType,
            Name: `${sensor.DatabaseName}.${sensor.Name}`,
            IsPublic: sensor.IsPublic,
            DatabaseId: "",
            DatabaseName: "",
            Id: sensor.FullId,
            ChainId: `${sensor.DatabaseId}.${sensor.ChainId}`,
            ChainName: sensor.ChainName
        }
    }

    const loadDataFunc = async (): Promise<GeovisAlarmSensor[]> => {
        const isInclinometer = alarm.SensorFunction === SensorValueAttribute.InclinometerChainPosition1
            || alarm.SensorFunction === SensorValueAttribute.InclinometerChainPosition2
            || alarm.SensorFunction === SensorValueAttribute.InclinometerChainPosition3;
        const payload: AlarmSensorsPayload = {
            Categories: alarm.SensorType === SensorCategory.MST_SB ? [SensorCategory.MST, SensorCategory.SB] : [alarm.SensorType],
            IsInclinometer: isInclinometer
        };
        const url = ServerRoutesGen.Alarms.GetAlarmSensors.patch(alarm.ProjectId, alarm.SensorType, isInclinometer);
        const response = await fetchServerElementsByPost<GeovisAlarmSensor[], AlarmSensorsPayload>(Server, url, payload);

        if (!response.Success) {
            return [];
        }

        return response.Data;
    }

    const filterSensorFunc = (sensor: GeovisAlarmSensor): boolean => {

        if (state.activeSensorsState !== undefined && (state.activeSensorsState > 0 && !sensor.Active || state.activeSensorsState < 0 && sensor.Active)) {
            return false;
        }

        return isMatchToSearchString(sensor.Name, state.searchText);
    }

    const sortingFunc = (itemA: GeovisAlarmSensor, itemB: GeovisAlarmSensor, sortKey: string, sortOrder: string): number => {
        switch (sortKey) {
            case "name":
                if (itemA.Name === itemB.Name) {
                    return 0;
                }
                return itemA.Name > itemB.Name
                    ? sortOrder === "ASC" ? 1 : -1
                    : sortOrder === "ASC" ? -1 : 1;
            case "triggered":
                if (itemA.TriggeredCount === itemB.TriggeredCount) {
                    return 0;
                }
                return itemA.TriggeredCount > itemB.TriggeredCount
                    ? sortOrder === "ASC" ? 1 : -1
                    : sortOrder === "ASC" ? -1 : 1;
            case "lastValue":
                if (!itemA.LastValue) {
                    return 1;
                }
                if (!itemB.LastValue) {
                    return -1;
                }
                if (itemA.LastValue === itemB.LastValue) {
                    return 0;
                }
                return +itemA.LastValue > +itemB.LastValue
                    ? sortOrder === "ASC" ? 1 : -1
                    : sortOrder === "ASC" ? -1 : 1;
            case "lastValueTimeslot":
                if (!itemA.LastTimeslot) {
                    return sortOrder === "ASC" ? -1 : 1;
                }
                if (!itemB.LastTimeslot) {
                    return sortOrder === "ASC" ? 1 : -1;
                }
                // eslint-disable-next-line no-case-declarations
                const result = compareDates(itemA.LastTimeslot, itemB.LastTimeslot, "DD.MM.yyyy hh:mm");
                return sortOrder === "ASC" ? result : -1 * result;
        }

        return 0;
    }

    const onClearSearchField = () => {
        if (searchFieldRef !== undefined && searchFieldRef.current) {
            searchFieldRef.current.value = "";
        }
        setState({
            ...state,
            searchText: ""
        });
    }

    const onFilterChangedDebounced = debounce((value: string) => { setState({ ...state, searchText: value }) }, 400);

    const onFilterTextChangedHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onFilterChangedDebounced(event.currentTarget.value);
    }

    const getActiveFilterOptions = (): OptionType[] => {
        return [{
            label: t("Active sensors only"), value: 1
        }, {
            label: t("Deactivated sensors only"), value: -1
        }]
    }

    const getSelectedActiveFilterOption = (): OptionType | undefined => {
        return getActiveFilterOptions().find(o => o.value === state.activeSensorsState);
    }

    const onSelectedActiveStateChanged = (selected: OptionType) => {
        setState({
            ...state,
            activeSensorsState: selected === null ? undefined : +selected.value
        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', gap: "10px" }}>
            <div style={{ width: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', gap: '10px', paddingTop: '10px' }}>
                <div style={{ width: '100%' }}>
                    <Textfield
                        ref={searchFieldRef}
                        placeholder={t("Filter")}
                        defaultValue={state.searchText}
                        onChange={onFilterTextChangedHandler}
                        isCompact={true}
                        elemAfterInput={
                            <div style={{ marginRight: '5px', cursor: 'default' }} onClick={onClearSearchField}>
                                <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                            </div>
                        }
                    />
                </div>
                <div style={{ flexShrink: 0, width: '250px' }}>
                    <GeovisSelect
                        options={getActiveFilterOptions()}
                        onChange={onSelectedActiveStateChanged}
                        value={getSelectedActiveFilterOption()}
                        isClearable={true}
                        placeholder={t("Filter by active")}
                    />
                </div>
                <div style={{ flexShrink: 0 }}>
                    <Button
                        isDisabled={isReadonly}
                        iconBefore={<AddIcon label={'newSensors'} />}
                        appearance={isReadonly ? 'default' : 'primary'}
                        onClick={onShowSelectSensorsClick}
                    >
                        {t("Add sensors")}
                    </Button>
                </div>
            </div>

            <div className="flexRowMiddleContainer" style={{ height: "100%" }}>
                <GeovisTableWithSelectableRows
                    allItems={getAllItems()}
                    getIdFunc={getIdFunc}
                    isLoading={false}
                    itemToRowFunc={getRow}
                    needSelectAll={true}
                    onSelectionChanged={onSelectionChanged}
                    selectedItems={getSelectedItems()}
                    head={getHead()}
                    onSelectDeselectAll={onSelectDeselectAll}
                    rowsPerPage={13}
                    showPagination={true}
                    disableSelection={isReadonly}
                    itemToRowColorFunc={getRowColor}
                    sortingFunc={sortingFunc}
                    defaultSortKey='name'
                    defaultSortOrder='ASC'
                    showCountRowsSelect={false}
                />
            </div>
            {state.showSensorsDialog &&
                <GeovisSelectItemsDialog
                    header={t("Select sensors")}
                    onClose={onHideSelectSensorsDialog}
                    onSave={onAddedSensors}
                    selectedDataIds={alarm.Sensors.map(as => as.FullId)}
                    useCategoriesFilter={false}
                    useDatabasesFilter={false}
                    originData={[]}
                    convertFunc={convertFunc}
                    loadDataFunc={loadDataFunc}
                    useChainsFilter={alarm.SensorFunction === SensorValueAttribute.InclinometerChainPosition1 || alarm.SensorFunction === SensorValueAttribute.InclinometerChainPosition2}
                />
            }
        </div>
    )
}

export default withGeovisServer(AlarmEditDialogSensorsSettingsTab)