/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 20.05.2021
 * @description Project LoRa UI actions
 */

export const PROJECT_LORA_COLLAPSE_TREE_ITEM = 'PROJECT_LORA_COLLAPSE_TREE_ITEM';
export const PROJECT_LORA_EXPAND_TREE_ITEM = 'PROJECT_LORA_EXPAND_TREE_ITEM';
export const PROJECT_LORA_EXPAND_TREE_ITEM_AFTER_NAVIGATION = 'PROJECT_LORA_EXPAND_TREE_ITEM_AFTER_NAVIGATION';

export const PROJECT_LORA_SHOW_ADD_ALG_DIALOG = 'PROJECT_LORA_SHOW_ADD_ALG_DIALOG';
export const PROJECT_LORA_SHOW_ADD_ALN_DIALOG = 'PROJECT_LORA_SHOW_ADD_ALN_DIALOG'
export const PROJECT_LORA_HIDE_DIALOG = 'PROJECT_LORA_HIDE_DIALOG';

export const PROJECT_LORA_ADD_ALG_INFO = 'PROJECT_LORA_ADD_ALG_INFO';
export const PROJECT_LORA_ADD_ALN_INFO = 'PROJECT_LORA_ADD_ALN_INFO';

export const PROJECT_LORA_UPDATE_ALG_INFO = 'PROJECT_LORA_UPDATE_ALG_INFO';
export const PROJECT_LORA_UPDATE_ALN_INFO = 'PROJECT_LORA_UPDATE_ALN_INFO';

export const PROJECT_LORA_SHOW_REMOVE_ALG_DIALOG = 'PROJECT_LORA_SHOW_REMOVE_ALG_DIALOG';
export const PROJECT_LORA_SHOW_REMOVE_ALN_DIALOG = 'PROJECT_LORA_SHOW_REMOVE_ALN_DIALOG';

export const PROJECT_LORA_REMOVE_ALG_INFO = 'PROJECT_LORA_REMOVE_ALG_INFO';
export const PROJECT_LORA_REMOVE_ALN_INFO = 'PROJECT_LORA_REMOVE_ALN_INFO';

export const PROJECT_LORA_SHOW_EDIT_ALN_NAME_DIALOG = 'PROJECT_LORA_SHOW_EDIT_ALN_NAME_DIALOG';
export const PROJECT_LORA_HIDE_EDIT_ALN_NAME_DIALOG = 'PROJECT_LORA_HIDE_EDIT_ALN_NAME_DIALOG';

export const PROJECT_LORA_INIT_SWISSCOM_ALG_DIALOG = 'PROJECT_LORA_INIT_SWISSCOM_ALG_DIALOG';

export const PROJECT_LORA_UPDATE_TREE = 'PROJECT_LORA_UPDATE_TREE';

export const PROJECT_LORA_SENSOR_CONFIGS_LOADING = "PROJECT_LORA_SENSOR_CONFIGS_LOADING";
export const PROJECT_LORA_SENSOR_CONFIGS_LOADED = "PROJECT_LORA_SENSOR_CONFIGS_LOADED";
export const PROJECT_LORA_SENSOR_CONFIGS_LOADING_ERROR = "PROJECT_LORA_SENSOR_CONFIGS_LOADING_ERROR";

export const PROJECT_LORA_SENSOR_CONFIG_CHANGED = "PROJECT_LORA_SENSOR_CONFIG_CHANGED";