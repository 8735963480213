import InfoIcon from '@atlaskit/icon/glyph/info';
import { TabData, TabItemComponentProvided } from '@atlaskit/tabs/types';
import { isMobile } from "react-device-detect";
import { t } from '../../../i18n';
import { AAName, AllName, PAName } from './ProjectOverviewInformationDataHub';

export interface IGeovisTData extends TabData {
    storageName: string;
    hasData: boolean;
    onSelect: (tabName: string) => void;
}

interface ITabDataProvided<TData extends IGeovisTData> extends TabItemComponentProvided {
    data: TData;
}

/**
 * Tab item component provided
 * @param props 
 */
export const ProjectOverviewInformationHubTabItemComponent = <TData extends IGeovisTData>(props: ITabDataProvided<TData>) => {
    let labelName: string = AllName;
    switch (props.data.storageName) {
        case AllName:
            labelName = "All users";
            break;
        case PAName:
            labelName = "Project admins";
            break;
        case AAName:
            labelName = "Amberg admins";
            break;
    }
    const getButtonColor = () => {
        switch (props.data.storageName) {
            case AllName:
                return props.data.hasData ? "blue" : "lightblue";
            case PAName:
                return props.data.hasData ? "green" : "lightGreen";
            case AAName:
                return props.data.hasData ? "purple" : "thistle";
        }
        return "black";
    }
    const onButtonClickFunc = () => {
        props.data.onSelect(props.data.storageName);
    }
    return (
        <div className="caption" >
            {/* <Button
                appearance='subtle'
                style={{ color: getButtonColor(), marginLeft: '9px' }}
                onClick={onButtonClickFunc}
                isSelected={props.isSelected}
                color={getButtonColor()}
                iconBefore={<InfoIcon label="Information" primaryColor={getButtonColor()} />}>{labelName}</Button> */}

            <div style={{ display: 'flex', marginLeft: '9px', marginRight: '9px', cursor: 'pointer', alignItems: 'center' }}>
                <div onClick={onButtonClickFunc} >
                    <InfoIcon label="Information" primaryColor={getButtonColor()} size='medium' />
                </div>
                <span style={{ color: getButtonColor(), marginLeft: '5px' }} onClick={onButtonClickFunc}>{isMobile ? "" : t(labelName)}</span>
            </div>
            {props.isSelected &&
                <hr style={{ marginLeft: '9px', marginRight: '7px', color: getButtonColor() }} />
            }
        </div>
    )
}


