import { TabData } from "@atlaskit/tabs/dist/types/types";
import { useState } from "react";
import Tabs from "@atlaskit/tabs";
import { t } from "../../../../../i18n";
import { CompanyInfo } from "../../../../../server/GEOvis3/Model/Company/CompanyInfo";
import { AlarmCondition } from "../../../../../server/AVTService/TypeLibrary/Alarming/AlarmCondition";
import { AlarmActionEditTimeScheduleSettings } from "./AlarmActionEditTimeScheduleSettings";
import { GeovisAlarmActionModel } from "../../../../../server/AVTService/TypeLibrary/Alarming/GeovisAlarmActionModel";
import { AlarmActionUIType } from "../../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUIType";
import AlarmActionEditMainSettings from "./AlarmActionEditMainSettings";
import AlarmActionEditRecieversSettings from "./AlarmActionEditRecieversSettings";
import { AlarmActivationBlockModel } from "../../../../../server/GEOvis3/Model/Alarms/UsersPresets/AlarmActivationBlockModel";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAlarmActionEditTabData extends TabData {

}

interface IComponentProps {
    actionState: GeovisAlarmActionModel;
    conditions: AlarmCondition[]
    forConfirm: boolean;
    isReadonly: boolean;
    companies: CompanyInfo[];
    updateAction: (propertyName: keyof GeovisAlarmActionModel, value: any) => void;
    isTemplate: boolean;
    updateFullAction: (upd: GeovisAlarmActionModel) => void;
    onChangeAlarmActivationPossibility: (config: AlarmActivationBlockModel) => void;
}

export const AlarmActionEditTabControl = ({
    actionState,
    forConfirm,
    isReadonly,
    updateAction,
    companies,
    conditions,
    isTemplate,
    updateFullAction,
    onChangeAlarmActivationPossibility
}: IComponentProps) => {
    const [tabIndex, setTabIndex] = useState<number>(0);

    const getMainTab = (): IAlarmActionEditTabData => {
        return ({
            label: t(`Alarm ${actionState.ForConfirm ? "Confirmation" : "Action"}`),
            content: (
                <AlarmActionEditMainSettings
                    actionState={actionState}
                    forConfirm={forConfirm}
                    isReadonly={isReadonly}
                    updateAction={updateAction}
                    companies={companies}
                    conditions={conditions}
                    updateFullAction={updateFullAction}
                />
            )

        })
    }

    const getReceiversTab = (): IAlarmActionEditTabData => {
        return ({
            label: t("Email/SMS Receiver"),
            content: (
                <AlarmActionEditRecieversSettings
                    actionState={actionState}
                    companies={companies}
                    isReadonly={isReadonly}
                    updateAction={updateAction}
                    conditions={conditions}
                    onChangeAlarmActivationPossibility={onChangeAlarmActivationPossibility}
                />
            )

        })
    }

    const getScheduleTab = (): IAlarmActionEditTabData => {
        return ({
            label: t("Activity Periods"),
            content: (
                <AlarmActionEditTimeScheduleSettings
                    actionState={actionState}
                    isReadonly={isReadonly}
                    updateAction={updateAction}
                />
            )

        })
    }

    const tabs: IAlarmActionEditTabData[] = actionState.ActionType === AlarmActionUIType.Message && !isTemplate
        ? [
            getMainTab(),
            getReceiversTab(),
            getScheduleTab()
        ]
        : [
            getMainTab(),
            getScheduleTab()
        ]

    const onSelectedTabChangedHandler = (tab: TabData, index: number) => setTabIndex(index);
    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Tabs
                tabs={tabs}
                selected={tabs[tabIndex || 0]}
                onSelect={onSelectedTabChangedHandler} />
        </div>
    );
}