import { RefObject, useEffect, useRef } from "react";

export const useOutsideClick = (callback: () => void): RefObject<HTMLElement> => {
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (event.target && ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);

    return ref;
}

/**
 * Determines whether an error is a QuotaExceededError.
 *
 * Browsers love throwing slightly different variations of QuotaExceededError
 * (this is especially true for old browsers/versions), so we need to check different fields and values to ensure we cover every edge-case.
 * @param error - The error to check
 * @returns Is the error a QuotaExceededError?
 */
const isQuotaExceededError = (error: unknown): boolean => {
    return (error instanceof DOMException &&
        // test 'code' field: 
        //                everything except Firefox                  Firefox    
        (error.code === DOMException.QUOTA_EXCEEDED_ERR || error.code === 1014 ||
            // test 'name' field too, because code might not be present:
            //       everything except Firefox                        Firefox
            error.name === "QuotaExceededError" || error.name === "NS_ERROR_DOM_QUOTA_REACHED")
    );
}

export const REPORT_CUSTOMER_SETTINGS_KEY_PREFIX = 'report_customer_settings_';
export const getReportCustomerSettingsKey = (reportId: number) => `${REPORT_CUSTOMER_SETTINGS_KEY_PREFIX}${reportId}`;

const removeHalfOfReportSettingsLocalStorageItems = () => {
    const reportSettingsKeys = Object.keys(localStorage).filter(k => k.startsWith(REPORT_CUSTOMER_SETTINGS_KEY_PREFIX)).sort((a, b) => a.localeCompare(b));

    if (reportSettingsKeys.length > 1) {
        const halfSize = Math.ceil(reportSettingsKeys.length / 2);
        const keysToBeRemoved = reportSettingsKeys.slice(0, halfSize);

        // eslint-disable-next-line no-console
        console.info(`Removing ${halfSize} (a half) localStorage items with keys starting with '${REPORT_CUSTOMER_SETTINGS_KEY_PREFIX}'`);

        for (const key of keysToBeRemoved) {
            localStorage.removeItem(key);
        }
    }
}


export const setLocalStorageItem = (key: string, value: string,) => {
    try {
        localStorage.setItem(key, value);
    }
    catch (error) {
        if (isQuotaExceededError(error)) {
            const storageSize = Math.round(JSON.stringify(localStorage).length / 1024);
            console.error(`LIMIT REACHED. key=${key}, localStorage size=${storageSize}K`);
            console.error(error);

            removeHalfOfReportSettingsLocalStorageItems();
            localStorage.setItem(key, value);
            return;
        }
        throw (error);
    }
}