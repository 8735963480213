/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 06.12.2019
 * @description Methods to help with Attached document storage
 */

import {
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_END,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_ERROR,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_PROCESSING,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_START
} from "../actions/projectEditActions";
import {
    IGeovisProjectEditAction,
    IProjectAttachedDocumentsEditState
} from '../edit.types';
import { GeovisUploadFileState } from "../uploading.types";

/**
 * Update uploading file state
 * @param state 
 * @param action 
 */
export const updateAttachedFilesUploadingState = (state: IProjectAttachedDocumentsEditState, action: IGeovisProjectEditAction): IProjectAttachedDocumentsEditState => {

    switch (action.type) {
        case PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_START:
            if (!action.uploadingFiles) {
                return state;
            }

            return {
                ...state,
                uploadingFiles: action.uploadingFiles.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f }))
            };

        case PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_END: {
            const finishedIds = action.uploadingFiles!.map(f => f.id);
            return { ...state, uploadingFiles: state.uploadingFiles.filter(f => finishedIds.indexOf(f.id) < 0) };
        }

        case PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_ERROR:
        case PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_PROCESSING:
            return {
                ...state,
                uploadingFiles: state.uploadingFiles.map(f => {
                    const actionFile = action.uploadingFiles!.filter(ef => ef.id === f.id)[0];
                    if (actionFile) {
                        return { ...f, ...actionFile };
                    }

                    return f;
                })
            };
    }

    return state;
}
