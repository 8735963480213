//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class LabelModel
{
	public id: string;
	public projectId: number;
	public name: string;
}
