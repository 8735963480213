//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisUserCommonInfo } from './GeovisUserCommonInfo';
import { GeovisUserRoleEnum } from '../../../AVTService/TypeLibrary/Identity/GeovisUserRoleEnum';

export class GeovisUserTableInfo extends GeovisUserCommonInfo
{
	public CompanyName: string;
	public GeovisUserRole: GeovisUserRoleEnum;
	public CountRelatedProjects: number;
	public ChangePasswordForbidden: boolean;
	public LastVisit?: string;
}
