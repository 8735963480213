/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 29.06.2020
 * @description Sensors layer on the map
 */

import { Direction, LeafletEvent } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { connect } from 'react-redux';
import {
    ChainInfosCustomFilterFunc,
    filterChainsToDrawOnMap,
    filterSensorsToDrawOnMap,
    getGeopointsMapBounds,
    getGeopointsMapBoundsDXFView,
    SensorInfosCustomFilterFunc
} from '../../../helpers/MapHelper';
import { GEOvisDXFLayerType } from '../../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType';
import { KindOfElementUsing } from '../../../server/AVTService/TypeLibrary/Common/KindOfElementUsing';
import { GeoJSONMapTilesLayerBounds } from '../../../server/AVTService/TypeLibrary/Model/GeoJSONMapTilesLayerBounds';
import { SensorCategory } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import Dispatcher, { IGeovisEventDispatcherAction } from '../../../services/GeovisEventDispatcher';
import {
    ACTION_SHOW_REGION_OF_CHAINS,
    ACTION_SHOW_REGION_OF_SENSORS
} from '../../../services/GeovisEventDispatcherActions';
import Logger from '../../../services/Logger';
import {
    IChainsInfoStorage,
    ISensorFilter,
    ISensorsInfoStorage,
    ISensorsSymbolsStorage
} from '../../../store/data.types';
import { IGeovisStoreState } from '../../../store/store.types';
import { IMapViewLayersVisibility, IMapViewSensorsLayerState } from '../../../store/types';
import { IWithProjectViewerModeProperty } from '../../abstract/IWithProjectViewerModeProperty';
import '../../map/geovis.leaflet.markercluster';
import { applyClusterMarkerTooltip, createMarkerClusterIcon } from '../../map/GeovisMarkerCluster';
import { MaxMapZoomLevel, MaxProfileViewZoomLevel } from '../../map/types';
import { ICreateSensorStateIconOptions } from '../../types';
import ChartEditDialog from '../charts/ChartEditDialog';
import { ChainTails } from './ChainControls';
import ChainMapItemMarker from './ChainMapItemMarker';
import SensorMapItemMarker from './SensorMapItemMarker';
import SensorsMovementControl from './SensorsMovementControl';
import { getMandatoryOrientation } from './tools';
import { ICloseToBorderSensorsIds, IMovementControlsOptions } from './types';
import { TimeSearchDistanceDataRequest } from '../../../server/AVTService/Computation/TimeSearchDistance/TimeSearchDistanceDataRequest';
import AlarmEditDialog from '../../../pages/edit/alarms/alarmEdit/AlarmEditDialog';
import { ICustomSensorTooltipContentProps } from './SensorLastMeasurement';
import { ICustomChainTooltipContentProps } from './ChainLastMeasurement';
import { SensorMarkersPaneName } from '../../map/GeovisMap.PaneCreator';

const LoggerSource = "SensorsOverviewLayer";

interface IStateToProps {
    projectId: number;
    searchQuery?: string,
    sensorFilter: ISensorFilter;
    layersVisibility: IMapViewLayersVisibility;
    sensorsLayerState: IMapViewSensorsLayerState;

    sensorsSymbolsStorage: ISensorsSymbolsStorage;

    sensorsInfoStorage: ISensorsInfoStorage;
    chainsInfoStorage: IChainsInfoStorage;
}

interface ISensorsOverviewLayerProps extends IStateToProps, ICustomSensorTooltipContentProps, ICustomChainTooltipContentProps {
    viewType: GEOvisDXFLayerType
    offsetsBounds?: GeoJSONMapTilesLayerBounds;
    invertXAxis: boolean;
    customFilter?: SensorInfosCustomFilterFunc;
    customChainFilter?: ChainInfosCustomFilterFunc;
    tooltipEnabled?: boolean;
    popupEnabled?: boolean;
    spiderfyEnabled?: boolean;
    forceSingleMarkerMode?: boolean;
    useMapTextOrientation?: boolean;
    direction?: Direction;
    leafletElement?: L.Map;
    useTooltipSmartPosition?: boolean;
    createCustomizedClusterIcon?: boolean;
    userId?: string;
    userToken?: string;
    deltaCoefficient?: number;
    movementOptions?: IMovementControlsOptions;
    timeSearchDistanceElement: TimeSearchDistanceDataRequest | false;
    reportElementTimestamp?: number;
    geovisImageId?: number;
    closeToBorderSensorsIds?: ICloseToBorderSensorsIds;
    isMapReady?: boolean;
    showLastMeasurementTimeInTooltip?: boolean;
    showIconTextBorder?: boolean;
}

interface IZoomState {
    mapMaxZoom: number;
    maxClusterRadius: number;
    disableClusteringAtZoom: number;
}

export const SensorsOverviewLayer = ({
    projectId,
    sensorsInfoStorage,
    chainsInfoStorage,
    sensorsSymbolsStorage,
    searchQuery,
    sensorFilter,
    viewType,
    offsetsBounds,
    customFilter,
    customChainFilter,
    tooltipEnabled,
    popupEnabled,
    spiderfyEnabled,
    forceSingleMarkerMode,
    useMapTextOrientation,
    layersVisibility,
    sensorsLayerState,
    invertXAxis,
    direction,
    leafletElement,
    useTooltipSmartPosition,
    createCustomizedClusterIcon,
    userId,
    userToken,
    deltaCoefficient,
    movementOptions,
    timeSearchDistanceElement,
    reportElementTimestamp,
    geovisImageId,
    closeToBorderSensorsIds,
    isMapReady,
    customChainsLastDataStorage,
    customSensorsLastDataStorage,
    showLastMeasurementTimeInTooltip,
    showIconTextBorder
}: ISensorsOverviewLayerProps) => {

    const notMapView = viewType !== GEOvisDXFLayerType.Map;
    const initialMapMaxZoom = notMapView ? MaxProfileViewZoomLevel : MaxMapZoomLevel;

    // synthetic const to avoid spiderfying whole project from one stacked marker
    const minAllowedSpiderfyZoom = notMapView ? 5 : 11;

    const getZoomState = (): IZoomState => {
        let mapMaxZoom = initialMapMaxZoom;
        let disableClusteringAtZoom = mapMaxZoom + 2;
        let maxClusterRadius = notMapView ? 40 : sensorsSymbolsStorage.radiusOfNearSensorsInPixels || 24;

        if (forceSingleMarkerMode) {
            disableClusteringAtZoom = 1;
            maxClusterRadius = 0;
            mapMaxZoom += 2;
        }
        else if (!sensorsSymbolsStorage.groupNearSensors) {
            disableClusteringAtZoom = 10;
            maxClusterRadius = 1;
            mapMaxZoom = disableClusteringAtZoom;
        }

        return ({
            mapMaxZoom,
            maxClusterRadius,
            disableClusteringAtZoom

        })
    }

    const zoomState = getZoomState();

    const [chartEditId, setChartEditId] = useState<number>(-1);
    const [alarmEditId, setAlarmEditId] = useState<string>("");

    const leafletMap = useMap();
    const markerClusterGroupRef = useRef<L.MarkerClusterGroup | null>(null);

    // #region Geovis event dispatcher processing

    const onShowRegionOfSensors = ({ actionId, data: category }: IGeovisEventDispatcherAction<SensorCategory>) => {
        Logger.trace(`dispatched action: ${actionId}`, LoggerSource);

        if (!leafletMap) {
            return;
        }

        const sensorsOnMap = filterSensorsToDrawOnMap(projectId, sensorsInfoStorage, layersVisibility, sensorsLayerState, searchQuery, sensorFilter)
            .filter(s => s.PhysicalType === category);

        if (sensorsOnMap.length === 0) {
            return;
        }

        const mapBounds = viewType !== GEOvisDXFLayerType.Map ? getGeopointsMapBoundsDXFView(sensorsOnMap, viewType, invertXAxis, offsetsBounds) : getGeopointsMapBounds(sensorsOnMap);
        leafletMap.fitBounds(mapBounds, { maxZoom: viewType !== GEOvisDXFLayerType.Map ? MaxProfileViewZoomLevel : MaxMapZoomLevel });
    }

    const onShowRegionOfChains = (action: IGeovisEventDispatcherAction<any>) => {
        Logger.trace(`dispatched action: ${action.actionId}`, LoggerSource);

        if (!leafletMap) {
            return;
        }

        const chainsOnMap = filterChainsToDrawOnMap(projectId, chainsInfoStorage, layersVisibility, searchQuery, sensorFilter, undefined, customChainFilter);
        if (chainsOnMap.length === 0) {
            return;
        }

        const mapBounds = viewType !== GEOvisDXFLayerType.Map ? getGeopointsMapBoundsDXFView(chainsOnMap, viewType, invertXAxis, offsetsBounds) : getGeopointsMapBounds(chainsOnMap);
        leafletMap.fitBounds(mapBounds, { maxZoom: viewType !== GEOvisDXFLayerType.Map ? MaxProfileViewZoomLevel : MaxMapZoomLevel });
    }

    useEffect(() => {
        // subscribe on TreeItemClick events
        const onShowRegionOfSensorsUnsubscribe = Dispatcher.subscribe(onShowRegionOfSensors, [ACTION_SHOW_REGION_OF_SENSORS]);
        const onShowRegionOfChainsUnsubscribe = Dispatcher.subscribe(onShowRegionOfChains, [ACTION_SHOW_REGION_OF_CHAINS]);

        Logger.trace("Subscribed on dispatcher events", LoggerSource);

        return () => {
            if (onShowRegionOfSensorsUnsubscribe) {
                onShowRegionOfSensorsUnsubscribe();
            }

            if (onShowRegionOfChainsUnsubscribe) {
                onShowRegionOfChainsUnsubscribe();
            }
        }
    }, []);

    const onShowChartEditDialog = (chartId: number) => {
        setChartEditId(chartId);
    }

    const onCloseChartEditDialog = () => {
        setChartEditId(-1);
    }

    const onClusterClick = (event: LeafletEvent) => {
        const cluster: L.MarkerCluster = event.propagatedFrom;
        if (!cluster || (spiderfyEnabled !== undefined && spiderfyEnabled === false)) {
            return;
        }
        let clickedMarkerIds: number[] = [];
        if (cluster.getChildCount() > 0) {
            const clusterMarkers = cluster.getAllChildMarkers();
            clickedMarkerIds = clusterMarkers.map(m => L.Util.stamp(m));
        }

        if (leafletMap) {
            const boundsZoom = leafletMap.getBoundsZoom(cluster.getBounds(), true);
            const zoom = leafletMap.getZoom();

            if (!cluster.canBeClusterSpiderfiedAtZoom) {
                throw Error('implementation of "canBeClusterSpiderfiedAtZoom" not found. Please check that imported file "geovis.leaflet.markercluster.ts"');
            }

            const contextLayerContainer = event.target; // context.layerContainer

            const canBeSpiderfied = cluster.canBeClusterSpiderfiedAtZoom(leafletMap, zoom, contextLayerContainer, clickedMarkerIds);

            // cluster.spiderfy();

            if ((canBeSpiderfied || zoom >= boundsZoom) && zoom > minAllowedSpiderfyZoom) {
                cluster.spiderfy();
            }
            else {
                cluster.zoomToClusterBounds(leafletMap, contextLayerContainer, zoomEnd);
            }
        }
    }

    const zoomEnd = (map: L.Map, cluster: L.MarkerCluster, layerContainer: any) => () => {
        if (!cluster) {
            return;
        }

        const zoom = map.getZoom();
        let clickedMarkerIds: number[] = [];
        if (cluster.getChildCount() > 0) {
            const clusterMarkers = cluster.getAllChildMarkers();
            clickedMarkerIds = clusterMarkers.map(m => L.Util.stamp(m));
        }
        if (zoom >= zoomState.mapMaxZoom) {
            cluster.spiderfy();
        }
        else if (layerContainer) {

            const distanceGrid: L.DistanceGrid = layerContainer._gridClusters[zoom];
            const canBeSpiderfied = cluster.canBeClusterSpiderfiedAtZoom(map, zoom, layerContainer, clickedMarkerIds);
            if (distanceGrid) {
                const markerPoint = map.project(cluster.getLatLng(), zoom);
                const closest = distanceGrid.getNearObject(markerPoint);
                if (canBeSpiderfied && closest && zoom > minAllowedSpiderfyZoom && clickedMarkerIds.length <= 8) {
                    cluster.spiderfy();
                }
                else {
                    cluster.zoomToClusterBounds(map, layerContainer, zoomEnd);
                }
            }
        }
    }

    const onShowAlarmEditDialog = (alarmId: string) => {
        setAlarmEditId(alarmId);
    }

    const onCloseAlarmEditDialog = () => {
        setAlarmEditId("");
    }

    Logger.render('Rendering', LoggerSource);

    const sensorsToDraw = filterSensorsToDrawOnMap(projectId, sensorsInfoStorage, layersVisibility, sensorsLayerState, searchQuery, sensorFilter, viewType, customFilter);

    const createSensorStateIconOptions: ICreateSensorStateIconOptions = {
        showName: sensorsLayerState.showSensorNames,
        fontSize: sensorsLayerState.fontSize,
        replaceIconMap: sensorsSymbolsStorage.iconSettings,
        useSensorColor: sensorsLayerState.useSensorColor,
        useGeovis4SensorTypeSymbol: sensorsLayerState.useGeovis4SensorTypeSymbol,
        useMapTextOrientation,
        noScale: sensorsLayerState.useSensorColor
    }

    let countLinesUnderSensorName = 0;
    if (movementOptions) {

        if (movementOptions.movementSettings.ShowMovementVector && movementOptions.movementSettings.ShowVectorDeviationValue) {
            countLinesUnderSensorName++;
        }
        if (movementOptions.movementSettings.ShowMovementHeightBars && movementOptions.movementSettings.ShowHeightDeviationValue) {
            countLinesUnderSensorName++;
        }
    }

    const showMovementText = movementOptions !== undefined
        && (movementOptions.movementSettings.ShowMovementVector && movementOptions.movementSettings.ShowVectorDeviationValue
            || movementOptions.movementSettings.ShowMovementHeightBars && movementOptions.movementSettings.ShowHeightDeviationValue);

    const showSensorTextWithBorders = showMovementText || geovisImageId !== undefined || timeSearchDistanceElement !== false;

    const showSensorNameInSensorIcon = sensorsLayerState.showSensorNames && !showSensorTextWithBorders;

    // // eslint-disable-next-line no-console
    // console.log(projectId, chainsInfoStorage, layersVisibility, searchQuery, sensorFilter, viewType, 
    //     filterChainsToDrawOnMap(projectId, chainsInfoStorage, layersVisibility, searchQuery, sensorFilter, viewType));

    return (
        <React.Fragment key="sensors_overview_layer">

            {movementOptions && leafletElement && timeSearchDistanceElement &&
                <SensorsMovementControl
                    projectId={projectId}
                    sensors={sensorsToDraw}
                    layerType={movementOptions.layerType}
                    movementSettings={movementOptions.movementSettings}
                    timeSearchDistanceElement={timeSearchDistanceElement}
                    reportElementTimestamp={reportElementTimestamp}
                    offsetsBounds={offsetsBounds}
                    invertXAxis={invertXAxis}
                    leafletElement={leafletElement}
                    userId={userId}
                    userToken={userToken}
                    deltaCoefficient={deltaCoefficient}
                    fontSize={sensorsLayerState.fontSize ?? 10}
                    geovisImageId={geovisImageId}
                    pictureXCoordinate={movementOptions.pictureXCoordinate}
                    pictureYCoordinate={movementOptions.pictureYCoordinate}
                    imageSensors={movementOptions.imageSensors}
                    closeToBorderSensorsIds={closeToBorderSensorsIds}
                    createSensorStateIconOptions={createSensorStateIconOptions}
                    showSensorsNames={sensorsLayerState.showSensorNames && showSensorTextWithBorders}
                    useSensorColor={sensorsLayerState.useSensorColorForText ?? false}
                    isMapReady={isMapReady}
                    useGeovis4Symbol={sensorsLayerState.useGeovis4SensorTypeSymbol}
                    showBorder={showIconTextBorder ?? false}
                    noIconScale={sensorsLayerState.useSensorColor ?? true}
                />
            }


            <MarkerClusterGroup
                key='marker_cluster_group_'
                ref={markerClusterGroupRef}
                onClick={onClusterClick}
                onMouseOver={applyClusterMarkerTooltip}
                singleMarkerMode={!sensorsSymbolsStorage.groupNearSensors || forceSingleMarkerMode}
                maxClusterRadius={zoomState.maxClusterRadius}
                spiderfyOnMaxZoom={false}
                zoomToBoundsOnClick={false}
                removeOutsideVisibleBounds={true}
                showCoverageOnHover={false}
                disableClusteringAtZoom={zoomState.disableClusteringAtZoom}
                animate={false} // with animate=true spiderfying doesn't work well
                iconCreateFunction={!createCustomizedClusterIcon
                    ? createMarkerClusterIcon({})
                    : createMarkerClusterIcon({
                        showName: showSensorNameInSensorIcon,
                        fontSize: sensorsLayerState.fontSize,
                        useSensorColor: sensorsLayerState.useSensorColor,
                        forceSingleMarkerMode,
                        useMapTextOrientation,
                        useGeovis4SensorTypeSymbol: sensorsLayerState.useGeovis4SensorTypeSymbol,
                        useSensorColorForText: sensorsLayerState.useSensorColorForText,
                        countLinesUnder: countLinesUnderSensorName,
                        backgroundColor: movementOptions !== undefined ? "#ffffffd5" : 'lightyellow',
                        fontWeight: movementOptions !== undefined ? 'bold' : 'normal'
                    })}
            >
                {/* draw sensors markers */}

                {sensorsInfoStorage.isLoaded && sensorsToDraw.map(sensor => (
                    <SensorMapItemMarker
                        key={`sensor_${sensor.Id}_map_item_marker_store`}
                        markerPaneName={SensorMarkersPaneName}
                        markerClusterGroup={markerClusterGroupRef ? markerClusterGroupRef.current : null}
                        projectId={projectId}
                        sensor={sensor}
                        showSensorNames={showSensorNameInSensorIcon}
                        fontSize={sensorsLayerState.fontSize}
                        useSensorColor={sensorsLayerState.useSensorColor}
                        useGeovis4SensorTypeSymbol={sensorsLayerState.useGeovis4SensorTypeSymbol}
                        useMapTextOrientation={useMapTextOrientation}
                        sensorsSymbolsSettings={sensorsSymbolsStorage}
                        viewType={viewType}
                        offsetsBounds={offsetsBounds}
                        invertXAxis={invertXAxis}
                        tooltipEnabled={tooltipEnabled}
                        popupEnabled={popupEnabled}
                        tooltipDirection={direction}
                        leafletElement={leafletElement}
                        useTooltipSmartPosition={useTooltipSmartPosition}
                        onShowChartConfig={onShowChartEditDialog}
                        onShowAlarmConfig={onShowAlarmEditDialog}
                        useSensorColorForText={sensorsLayerState.useSensorColorForText}
                        countLinesUnder={countLinesUnderSensorName}
                        deltaCoefficient={deltaCoefficient}
                        sensorTextOrientation={getMandatoryOrientation(closeToBorderSensorsIds, sensor)}
                        backgroundColor={movementOptions !== undefined ? "#ffffffd5" : undefined}
                        showBold={movementOptions !== undefined}
                        showTooltipContentEvenFaAlarm={sensorsLayerState.showTooltipContentEvenFaAlarm}
                        customSensorsLastDataStorage={customSensorsLastDataStorage}
                        showLastMeasurementTime={showLastMeasurementTimeInTooltip}
                        showBorder={showIconTextBorder ?? false}
                    />
                ))}

                {/* draw chains markers */}
                {chainsInfoStorage.isLoaded && filterChainsToDrawOnMap(projectId, chainsInfoStorage, layersVisibility, searchQuery, sensorFilter, viewType, customChainFilter).map(chain => (
                    <ChainMapItemMarker
                        key={`chain_${chain.Id}_map_item_marker_`}
                        markerPaneName={SensorMarkersPaneName}
                        markerClusterGroup={markerClusterGroupRef ? markerClusterGroupRef.current : null}
                        projectId={projectId}
                        chain={chain}
                        showChainNames={sensorsLayerState.showSensorNames}
                        fontSize={sensorsLayerState.fontSize}
                        sensorsSymbolsSettings={sensorsSymbolsStorage}
                        useSensorColor={sensorsLayerState.useSensorColor}
                        useGeovis4SensorTypeSymbol={sensorsLayerState.useGeovis4SensorTypeSymbol}
                        useSensorColorForText={sensorsLayerState.useSensorColorForText}
                        useMapTextOrientation={useMapTextOrientation}
                        sensorTextOrientation={getMandatoryOrientation(closeToBorderSensorsIds, chain)}
                        viewType={viewType}
                        popupEnabled={popupEnabled}
                        tooltipEnabled={tooltipEnabled}
                        invertXAxis={invertXAxis}
                        deltaCoefficient={deltaCoefficient}
                        offsetsBounds={offsetsBounds}
                        leafletElement={leafletElement}
                        onShowChartConfig={onShowChartEditDialog}
                        backgroundColor={movementOptions !== undefined ? "#ffffffd5" : undefined}
                        showBold={movementOptions !== undefined}
                        customChainsLastDataStorage={customChainsLastDataStorage}
                        showBorder={showIconTextBorder ?? false}
                    />
                ))}

            </MarkerClusterGroup>

            {/* draw chains tails */}
            {chainsInfoStorage.isLoaded && (
                <ChainTails
                    key="sensors_overview_layer_chains_tails"
                    chainsInfoStorage={chainsInfoStorage}
                    paneName={SensorMarkersPaneName}
                />
            )}

            {chartEditId > 0 &&
                <ChartEditDialog
                    projectId={projectId}
                    onClose={onCloseChartEditDialog}
                    onUpdateChartEntry={onCloseChartEditDialog}
                    chartKind={KindOfElementUsing.Element}
                    chartId={chartEditId}
                />
            }

            {alarmEditId !== "" &&
                <AlarmEditDialog
                    alarmId={alarmEditId}
                    baseItemId=''
                    createMode={false}
                    isTemplate={false}
                    onClose={onCloseAlarmEditDialog}
                    projectId={projectId}
                />
            }
        </React.Fragment>
    );
}

interface IOwnProps extends IWithProjectViewerModeProperty {
    offsetsBounds?: GeoJSONMapTilesLayerBounds;
    ownLayersVisibility?: IMapViewLayersVisibility;
    ownSensorsLayerState?: IMapViewSensorsLayerState;
}

const SensorsOverviewLayerConnectedImpl = (props: ISensorsOverviewLayerProps) => {
    return (<SensorsOverviewLayer  {...props} />)
}

const mapStateToProps = ({ data }: IGeovisStoreState, ownProps: IOwnProps): IStateToProps => ({
    projectId: data.projectInfo.project.Id,
    searchQuery: data.projectViewFilter.searchElementQuery,

    sensorFilter: data.projectViewFilter.sensorFilter,

    chainsInfoStorage: data.chainsInfoStorage,
    sensorsInfoStorage: data.sensorsInfoStorage,
    sensorsSymbolsStorage: data.sensorsSymbolsStorage,

    sensorsLayerState: ownProps.ownSensorsLayerState || data.projectMapViewState.sensorsLayerState,
    layersVisibility: ownProps.ownLayersVisibility || data.projectMapViewState.layersVisibility
});

export const SensorsOverviewLayerConnected = connect<IStateToProps, never, IOwnProps>(mapStateToProps)(SensorsOverviewLayerConnectedImpl);