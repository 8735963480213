import { getInventoryObjectTypeToName, InventoryObjectType } from '../server/AVTService/TypeLibrary/Inventory/InventoryObjectType';
import Route from './Route';

export default class Routes {
    public static home = new Route({
        path: "/"
    });

    public static login = new Route({
        path: "/login"
    });

    public static forgotPassword = new Route({
        path: "/forgotPassword"
    });

    public static forgotPasswordConfirmation = new Route({
        path: "/forgotPasswordConfirmation"
    });

    public static resetPassword = new Route({
        path: "/resetPassword"
    });

    public static project = new Route({
        path: "/project/:projectId"
    });

    public static projectView = new Route({
        path: "/project/:projectId/view/:viewId"
    });

    public static projectSupport = new Route({
        path: '/project/:projectId/support'
    });

    public static projectEdit = new Route({
        path: "/project/:projectId/edit"
    });

    public static projectCreate = new Route({
        path: "/newProject"
    });

    public static dashboard = new Route({
        path: "/dashboard"
    });

    public static projectStatistic = new Route({
        path: "/project/:projectId/statistic"
    });

    public static projectDetailedStatistic = new Route({
        path: '/project/:projectId/statistic/detailed',
    });

    public static projectQuickStatistic = new Route({
        path: '/project/:projectId/statistic/quick',
    });

    public static projectEditProperties = new Route({
        path: '/project/:projectId/edit/properties',
    });

    public static projectEditDxfLayers = new Route({
        path: '/project/:projectId/edit/dxfLayers',
    });

    public static projectEditGeoImageLayers = new Route({
        path: '/project/:projectId/edit/geoImageLayers',
    });

    public static projectEditVisualSettings = new Route({
        path: '/project/:projectId/edit/visualSettings'
    });

    public static projectEditLocalMapObjects = new Route({
        path: '/project/:projectId/edit/localMapObjects'
    });

    public static projectEditAttachedDocuments = new Route({
        path: '/project/:projectId/edit/attachedDocuments'
    });

    public static projectEditViews = new Route({
        path: '/project/:projectId/edit/views'
    });

    public static projectEditViewsEditDialog = new Route({
        path: '/project/:projectId/edit/views/:viewId'
    });

    public static projectEditViewsCreateDialog = new Route({
        path: '/project/:projectId/edit/views/create'
    });

    public static projectEditAlarms = new Route({
        path: '/project/:projectId/edit/alarms'
    });

    public static projectEditAlarmsEditDialog = new Route({
        path: '/project/:projectId/edit/alarms/edit/:alarmId'
    });

    public static projectEditAlarmsCreateDialog = new Route({
        path: '/project/:projectId/edit/alarms/create/:baseItemId'
    });

    public static projectEditAlarmTemplatesEditDialog = new Route({
        path: '/project/:projectId/edit/alarmTemplates/edit/:alarmId'
    });

    public static projectEditAlarmTemplatesCreateDialog = new Route({
        path: '/project/:projectId/edit/alarmTemplates/create/:baseItemId'
    });

    public static projectEditAccessControl = new Route({
        path: '/project/:projectId/edit/accessControl'
    });

    public static projectEditNotifications = new Route({
        path: '/project/:projectId/edit/notifications'
    });

    public static projectEditTasks = new Route({
        path: '/project/:projectId/edit/tasks/:type'
    })

    public static projectEditTasksEditDialog = new Route({
        path: '/project/:projectId/edit/tasks/:type/:action/:taskId/'
    });

    public static projectEditElements = new Route({
        path: '/project/:projectId/edit/elements/tab/:tabName/category/:category/id/:id'
    });

    public static projectEditElementEditDialog = new Route({
        path: '/project/:projectId/edit/elements/tab/:tabName/:elementType/edit/:elementId'
    });

    public static projectEditGroupElementEditDialog = new Route({
        path: '/project/:projectId/edit/elements/tab/:tabName/:elementType/edit/:elementId/:groupName'
    });

    public static projectEditElementCreateDialog = new Route({
        path: '/project/:projectId/edit/elements/tab/:tabName/:elementType/create/:templateId'
    });

    public static projectEditElementCreateChartDialog = new Route({
        path: '/project/:projectId/edit/elements/tab/:tabName/:elementType/createChart/:chartType/:templateId'
    });

    public static projectEditElementCreateChartTemplateDialog = new Route({
        path: '/project/:projectId/edit/elements/tab/:tabName/:elementType/createChartTemplate/:chartType/:templateKind'
    });

    public static projectEditElementCreateTemplateDialog = new Route({
        path: '/project/:projectId/edit/elements/tab/:tabName/:elementType/createTemplate'
    });

    // base route of sensors
    public static projectEditSensors = new Route({
        path: '/project/:projectId/edit/sensors/:type',
        exact: false
    })

    public static projectEditSensorsList = new Route({
        path: this.projectEditSensors.path
    });

    public static projectEditSensorsEditSensor = new Route({
        path: this.projectEditSensors.path + '/edit/:fullId'
    });

    public static projectEditSensorsCreateSensor = new Route({
        path: this.projectEditSensors.path + '/create'
    });
    // end of sensors routes

    public static projectEditSensorData = new Route({
        path: '/project/:projectId/edit/sensorData'
    })

    public static projectEditDatabases = new Route({
        path: '/project/:projectId/edit/databases'
    });

    public static projectEditReports = new Route({
        path: '/project/:projectId/edit/reports/:tabName/scrollTo/:reportId'
    });

    public static projectEditReport = new Route({
        path: '/project/:projectId/edit/reports/:tabName/:reportId'
    });

    public static projectEditReportCreate = new Route({
        path: '/project/:projectId/edit/reports/:tabName/create/:baseItemId'
    });

    public static projectReports = new Route({
        path: "/project/:projectId/reports"
    })

    public static projectReport = new Route({
        path: '/project/:projectId/report/:reportId'
    });

    public static projectReportWithViewId = new Route({
        path: '/project/:projectId/report/:reportId/:viewId'
    });

    public static projectDtsConfiguration = new Route({
        path: '/project/:projectId/edit/dtsConfiguration'
    });

    public static projectLoRa = new Route({
        path: '/project/:projectId/edit/lora'
    });

    public static projectLoRaAlg = new Route({
        path: '/project/:projectId/edit/lora/:algId'
    });

    public static projectLoRaAln = new Route({
        path: '/project/:projectId/edit/lora/:algId/aln/:alnId'
    });

    public static projectLoRaSensor = new Route({
        path: '/project/:projectId/edit/lora/:algId/aln/:alnId/sen/:sensorId'
    });

    public static projectLogbook = new Route({
        path: "/project/:projectId/logbook"
    })

    public static projectLogbookElement = new Route({
        path: '/project/:projectId/logbookElement/:elementId'
    })

    public static projectDocuments = new Route({
        path: "/project/:projectId/documents"
    })

    public static projectInventoryMst = new Route({
        path: `/project/:projectId/inventory/${getInventoryObjectTypeToName(InventoryObjectType.MST)}`
    })

    public static projectInventoryAfm = new Route({
        path: `/project/:projectId/inventory/${getInventoryObjectTypeToName(InventoryObjectType.Afm)}`
    })

    public static projectInventoryAtc = new Route({
        path: `/project/:projectId/inventory/${getInventoryObjectTypeToName(InventoryObjectType.Atc)}`
    })

    public static projectInventorySb = new Route({
        path: `/project/:projectId/inventory/${getInventoryObjectTypeToName(InventoryObjectType.SB)}`
    })

    public static projectInventoryTachy = new Route({
        path: `/project/:projectId/inventory/${getInventoryObjectTypeToName(InventoryObjectType.Tachymeter)}`
    })

    public static projectInventoryLora = new Route({
        path: `/project/:projectId/inventory/${getInventoryObjectTypeToName(InventoryObjectType.LoRaGateway)}`
    })

    public static aboutSoftware = new Route({
        path: "/about"
    });

    // don't use 'releaseNotes' in the routes, server has folder 'ReleaseNotes'
    // https://amberg.atlassian.net/browse/AGMS-3445
    public static releaseNotes = new Route({
        path: "/buildsNotes"
    });

    public static costCalculator = new Route({
        path: "/costCalculator"
    });

    public static userFeedback = new Route({
        path: "/userFeedback"
    })

    public static documentation = new Route({
        path: "/documentation"
    })

    public static releaseNoteEdit = new Route({
        path: "/buildsNotes/editMode"
    })

    public static unitsHelp = new Route({
        path: "/about/units"
    })

    public static sensorTypesHelp = new Route({
        path: "/about/sensorTypes"
    })

    public static formulaSyntaxHelp = new Route({
        path: "/about/formulaSyntax"
    })

    public static faq = new Route({
        path: "/about/faq"
    })

    public static userProfile = new Route({
        path: "/userProfile"
    })

    public static changePassword = new Route({
        path: "/changePassword"
    })

    //#region Users and Companies

    public static users = new Route({
        path: "/users"
    })

    public static companies = new Route({
        path: "/companies"
    })

    public static addCompany = new Route({
        path: "/addCompany"
    })

    public static addUser = new Route({
        path: "/addUser"
    })

    public static exportUsersCompanies = new Route({
        path: '/exportUsersCompanies'
    })

    public static partnerCompanyManager = new Route({
        path: '/partnerCompanyManager'
    })

    public static importUsersCompanies = new Route({
        path: '/importUsersCompanies'
    })

    public static messagesToUsers = new Route({
        path: '/messages'
    });

    public static messageTemplate = new Route({
        path: '/message/:templateId'
    })

    //#endregion

    //#region Service pages

    public static logosManager = new Route({
        path: '/service/logosManager'
    });

    public static globalStatistics = new Route({
        path: "/statistics/global"
    });

    public static globalProjectsHistory = new Route({
        path: "/statistics/projectsHistory"
    });

    public static globalInvoice = new Route({
        path: "/statistics/invoice"
    });

    public static globalNotificationsStat = new Route({
        path: "/statistics/notifications"
    });

    public static feedbacks = new Route({
        path: "/feedbacks"
    });

    public static applicationFeedbacks = new Route({
        path: "/feedbacks/application"
    });

    public static applicationFeedbacksEntry = new Route({
        path: "/feedbacks/application/:entryId"
    });

    public static usersFeedbacks = new Route({
        path: "/feedbacks/users"
    });

    public static usersFeedbacksEntry = new Route({
        path: "/feedbacks/users/:entryId"
    });

    public static errorPage = new Route({
        path: "/errorPage"
    });

    public static inventoryMst = new Route({
        path: `/inventory/${getInventoryObjectTypeToName(InventoryObjectType.MST)}`
    });

    public static inventoryAfm = new Route({
        path: `/inventory/${getInventoryObjectTypeToName(InventoryObjectType.Afm)}`
    });

    public static inventoryAtc = new Route({
        path: `/inventory/${getInventoryObjectTypeToName(InventoryObjectType.Atc)}`
    });

    public static inventorySb = new Route({
        path: `/inventory/${getInventoryObjectTypeToName(InventoryObjectType.SB)}`
    });

    public static inventoryTachy = new Route({
        path: `/inventory/${getInventoryObjectTypeToName(InventoryObjectType.Tachymeter)}`
    });

    public static inventoryLora = new Route({
        path: `/inventory/${getInventoryObjectTypeToName(InventoryObjectType.LoRaGateway)}`
    });

    public static inventoryTree = new Route({ path: '/inventory/tree' });

    public static inventoryReservation = new Route({ path: '/inventory/reservation' });

    public static inventoryCalendarView = new Route({ path: '/inventory/calendarView' });

    public static inventoryCalendarViewEditInventory = new Route({ path: '/inventory/calendarView/edit/:inventoryId' });

    // temporary disabled, but, this will be used when we introduce the new backup feature, its settings will be there
    // public static settings = new Route({ path: '/settings' });

    public static logs = new Route({ path: '/logs' });

    public static logsEntryPoints = new Route({ path: '/logs/entryPoints' })

    public static logsFollowEntryPoint = new Route({ path: '/logs/follow/:projectId/:entryId' })

    public static logsRecords = new Route({ path: '/logs/records' });

    //#endregion

    //#region Global imports

    // public static globalImports = new Route({ path: '/globalImports' });

    // public static globalImportsSettings = new Route({ path: '/globalImports/config' });

    // public static swissMeteoGlobalImport = new Route({ path: '/globalImports/swissMeteo' });

    // public static swissMeteoSensorsData = new Route({ path: '/globalImports/swissMeteoData/:sensorId' });



    //#endregion

    //#region Settings

    public static settingsRoot = new Route({ path: '/settings' });

    public static settingsBackup = new Route({ path: '/settings/backup' });

    public static settingsArchiveFtpService = new Route({ path: '/settings/archiveFtpSettings' });

    public static restoreFtpSettings = new Route({ path: '/settings/restoreFtpSettings' });

    public static settingsLogs = new Route({ path: '/settings/logs' });

    public static settingsNotifications = new Route({ path: '/settings/notifications' });

    public static settingsWatchdogService = new Route({ path: '/settings/watchdogService' });

    public static settingsMigrations = new Route({ path: '/settings/migrations' });

    public static settingsGeoRaster = new Route({ path: '/settings/geoRasters' });

    public static settingsProjectElementsMigrations = new Route({ path: '/settings/projectElementsMigrations' });

    //#endregion

    //#region Project sync timeslot routes

    public static syncTimeslotsRoot = new Route({ path: '/syncTimeslots' });

    public static syncTimeslotsOverviewPage = new Route({ path: this.syncTimeslotsRoot.path + '/overview' });

    public static syncTimeslotsProjectInfoPage = new Route({ path: this.syncTimeslotsRoot.path + '/projectInfo/:projectId/db/:alias' });

    public static syncTimeslotsProcessingQueuePage = new Route({ path: this.syncTimeslotsRoot.path + '/processingQueue' });

    public static syncTimeslotsServiceSettingsPage = new Route({ path: this.syncTimeslotsRoot.path + '/serviceSettings' });

    //#endregion

    // #region Maintenance service

    public static settingsMaintenanceServicePageMode = new Route({
        path: this.settingsRoot.path + '/maintenanceService'
    });

    public static settingsMaintenanceServiceWatchdogPage = new Route({
        path: this.settingsRoot.path + '/maintenanceService/wd'
    });

    public static settingsMaintenanceServiceBucketsPage = new Route({
        path: this.settingsRoot.path + '/maintenanceService/buckets'
    });

    public static settingsMaintenanceServiceBucketsPageWithEditDialog = new Route({
        path: this.settingsRoot.path + '/maintenanceService/buckets/edit/:taskId'
    });

    // #endregion

    public static archivingProjects = new Route({ path: '/archiving' });

    public static restoringProjects = new Route({ path: '/restoring' });

    public static geovisReportPdfPage = new Route({ path: '/renderReport/:projectId/:reportId/:settingsId/:userId/:userToken' });

    public static renderGeovisChartToPng = new Route({ path: '/renderGeovisChartToPng/:projectId/r/:reportId/set/:settingsId/pageNum/:pageNum/chart/:chartId/h/:height/w/:width/u/:userId/:userToken' });
}