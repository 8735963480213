/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 12.11.2020
 * @description React custom hooks to be use in the application
 */

import React from 'react';
import { DataActionResponse } from '../server/DataActionResponse';
import AuthService from '../services/AuthService';
import { IRequestHelper } from '../services/RequestHelper';

export interface IDefaultDispatchToProps<TData> {
    onLoading: () => void;
    onLoaded: (data: DataActionResponse<TData>) => void;
}

/**
 * Base type to add server to this component
 */
export interface IWithGeovisServerProps {
    Server: IRequestHelper;
}

/**
 * A wrapper function to add Server to the component
 * @param WrappedComponent Wrapped component
 */
export function withGeovisServer<TProps extends IWithGeovisServerProps>(WrappedComponent: React.ComponentType<TProps>): React.ComponentType<Omit<TProps, "Server">> {

    const withGeovisServerCreator = (props: TProps) => (
        <WrappedComponent
            {...props}
            Server={AuthService.getRequestHelper(AuthService)}
        />
    );

    withGeovisServerCreator.displayName = `withGeovisServer(${WrappedComponent.displayName || WrappedComponent.name})`;

    return withGeovisServerCreator;
}