/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 29.06.2020
 * @description Chain sensor icons implementation
 */

import { Marker, Polyline } from 'react-leaflet';
import { geoLocationToLatLng, isEmptyLocation2D } from '../../../helpers/MapHelper';
import { mapToListOfElements } from '../../../helpers/StorageHelper';
import { SensorCategory } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { ChainInfo } from '../../../server/ChainInfo';
import { ChainType } from '../../../server/ChainType';
import { SensorInfo } from '../../../server/GEOvis3/Model/SensorInfo';
import { IChainsInfoStorage } from '../../../store/data.types';
import { createSensorStateIcon } from '../../map/IconFactory';

interface IChainTailsProps {
    chainsInfoStorage: IChainsInfoStorage; // chains, which can be rendered
    paneName: string;
}

export const ChainTails = ({ chainsInfoStorage: { chainsInfo, isLoaded }, paneName }: IChainTailsProps) => {

    if (!isLoaded) {
        return null;
    }

    const firstInclinometerChains = mapToListOfElements(chainsInfo, c => c.ChainType === ChainType.FirstInclinometer && !isEmptyLocation2D(c.EndSensorLocation) && !c.IsEmptyEndSensorsLocation);

    if (firstInclinometerChains.length === 0) {
        return (null);
    }

    return (
        <div key={`first_inclinometer_chain_tails`}>
            {firstInclinometerChains.map(chain => (
                <div key={`chain_${chain.Id}`}>
                    <ChainEndSensorIconMarker chain={chain} paneName={paneName} />
                    <ChainVector chain={chain} paneName={paneName} />
                </div>
            ))}
        </div>
    )
}

interface IChainEndSensorIconMarkerProps {
    chain: ChainInfo;
    paneName: string;
}

const ChainEndSensorIconMarker = ({ chain: { Id, EndSensorLocation }, paneName }: IChainEndSensorIconMarkerProps) => (
    <Marker
        key={`chain_tail_marker_${Id}`}
        pane={paneName}
        position={geoLocationToLatLng(EndSensorLocation)}
        icon={createSensorStateIcon({ ...new SensorInfo(), PhysicalType: SensorCategory.Prism })}
    />
)

interface IChainVectorProps {
    chain: ChainInfo;
    paneName: string;
}

const ChainVector = ({ chain: { Id, Coordinates, EndSensorLocation }, paneName }: IChainVectorProps) => (
    <Polyline
        key={`chain_tail_vector_${Id}`}
        pane={paneName}
        positions={[geoLocationToLatLng(Coordinates), geoLocationToLatLng(EndSensorLocation)]}
        color="black"
        weight={2}
    />
)