//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisLicenseType } from '../Common/GeovisLicenseType';
import { LicensedFeatures } from '../Licensing/LicensedFeatures';
import { GeovisDataPackage } from '../Common/GeovisDataPackage';
import { ProjectInvoiceModel } from './ProjectInvoiceModel';

export class CompanyInvoiceRecord
{
	public Id: string;
	public CompanyId: string;
	public LicenseType: GeovisLicenseType;
	public AdditionalFeatures: LicensedFeatures[];
	public InvoiceDate: string;
	public NumberOfGeovis4Packages: number;
	public NumberOfSensors: number;
	public NumberOfTheodolites: number;
	public NumberOfVibration: number;
	public NumberOfOthers: number;
	public NumberOfRecords: number;
	public MemoryUsage: number;
	public SumOfDays: number;
	public ImportTasksCount: number;
	public NumberOfManualProjects: number;
	public NumberOfAutomaticProjects: number;
	public DataPackage?: GeovisDataPackage;
	public NumberOfSmsMessages: number;
	public AlarmsSettingsAvailable?: boolean;
	public CreatingVirtualSensorAvailable?: boolean;
	public ProjectsData: ProjectInvoiceModel[];
}
