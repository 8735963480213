import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../../helpers/GeovisHooks";
import { DataActionResponse } from "../../../../../../server/DataActionResponse";
import { IGeovisLogbookReportInfo } from "../../../../../../store/projectReport.types";
import ServerRoutesGen from "../../../../../../server/Routes/ServerRoutesGen";
import { fetchServerElementsByPost, fetchServerElementsByPostCancellable, fetchServerElementsCancellable } from "../../../../../../helpers/ProjectDataHelper";
import { GeovisLogbookDataModel } from "../../../../../../server/GEOvis3/Model/GeovisLogbooks/GeovisLogbookDataModel";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { IGeovisAction } from "../../../../../../store/types";
import { projectReportGeovisLogbookDataLoaded, projectReportGeovisLogbookDataLoading } from "../../../../../../store/creators/projectReportCreators";
import { IReportElementRenderSimpleOwnProps } from "../../types";
import { GeovisLogbookModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisLogbookModel";
import { getGeovisLogbookInfo } from "../tools";

interface IStateToProps {
    geovisLogbookInfo: IGeovisLogbookReportInfo | false;
}

interface IDispatchToProps {
    onLoading: () => void;
    onLoaded: (response: DataActionResponse<GeovisLogbookDataModel>) => void;
}

interface IOwnProps extends IReportElementRenderSimpleOwnProps {
    elementId: number;
}

interface IComponentProps extends IStateToProps, IDispatchToProps, IOwnProps, IWithGeovisServerProps {

}

const Component = ({
    geovisLogbookInfo,
    pageNum,
    reportId,
    userId,
    userToken,
    Server,
    onLoading,
    onLoaded
}: IComponentProps) => {
    if (!geovisLogbookInfo) {
        return null;
    }

    const { IsDirty, Timestamp, GeovisLogbook } = geovisLogbookInfo;
    const { Id, ProjectId } = GeovisLogbook;

    useEffect(() => {        
        onLoading();

        (async function loadData() {
            if (userId && userToken) {
                const url = ServerRoutesGen.ReportPdfRenderData.GetGeovisLogbookData.patch(ProjectId, userToken, userId, reportId, Id);
                const response = await fetchServerElementsByPost<GeovisLogbookDataModel, GeovisLogbookModel>(Server, url, GeovisLogbook);
                onLoaded(response);
                return;
            }

            const requestId = `GET_DATA_FOR_REP_${reportId}_PAGE_${pageNum}_LOGBOOK_${Id}`;

            if (IsDirty) {
                const url = ServerRoutesGen.ReportRenderData.GetGeovisLogbookCustomData.patch(ProjectId, reportId);
                const response = await fetchServerElementsByPostCancellable<GeovisLogbookDataModel, GeovisLogbookModel>(Server, url, requestId, GeovisLogbook);
                onLoaded(response);
                return;
            }

            {
                const url = ServerRoutesGen.ReportRenderData.GetGeovisLogbookData.patch(ProjectId, reportId, Id);
                const response = await fetchServerElementsCancellable<GeovisLogbookDataModel>(Server, url, requestId);
                onLoaded(response);
                return;
            }
        })();

    }, [Timestamp]);

    return (
        <div key={`geovis-logbook-${Id}-data-layer-of-page-${pageNum}`} style={{ display: 'none' }}>
            {/* geovis logbook report data layer */}
        </div>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { pageNum, elementId }: IOwnProps): IStateToProps => ({
    geovisLogbookInfo: getGeovisLogbookInfo(projectReport.geovisReportSettings.geovisPages.get(pageNum), elementId)
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>, { pageNum, elementId }: IOwnProps): IDispatchToProps => ({
    onLoading: () => dispatch(projectReportGeovisLogbookDataLoading(pageNum, elementId)),
    onLoaded: data => dispatch(projectReportGeovisLogbookDataLoaded(pageNum, elementId, data))
});

export default connect<IStateToProps, IDispatchToProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(withGeovisServer(Component))