import Avatar from '@atlaskit/avatar';
import Dropdown, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Avatar as AvatarItem, AvatarPickerDialog } from '@atlaskit/media-avatar-picker';
import { ModalTransition } from '@atlaskit/modal-dialog';
import React from "react";
import i18next from '../../i18n';

interface IUserAvatarProps {
    avatarSrc: string | undefined;
    readonly: boolean;
    onAvatarSrcChanged: (avatarSrc: string) => void;
}

interface IUserAvatarState {
    showAvatarPicker: boolean;
}

export default class UserAvatar extends React.Component<IUserAvatarProps, IUserAvatarState> {
    constructor(props: IUserAvatarProps) {
        super(props);

        this.state = ({
            showAvatarPicker: false
        })
    }

    public render = () => {
        const { showAvatarPicker } = this.state;
        const { avatarSrc, readonly } = this.props;

        return (
            <div>
                <Dropdown trigger={<Avatar src={avatarSrc} size="xxlarge" />} position="center bottom">
                    {!readonly && (
                        <DropdownItemGroup>
                            <DropdownItem
                                key="change-avatar-item-key"
                                onClick={this.onChangeAvatarClick} >
                                {i18next.t("Change profile photo")}
                            </DropdownItem>
                            {avatarSrc && avatarSrc.length > 0 && (
                                <DropdownItem
                                    key="remove-avatar-item-key"
                                    onClick={this.onRemoveAvatarClick} >
                                    {i18next.t("Remove")}
                                </DropdownItem>
                            )}
                        </DropdownItemGroup>
                    )}
                </Dropdown>
                {showAvatarPicker && (
                    <ModalTransition>
                        <AvatarPickerDialog
                            avatars={[]}
                            imageSource={avatarSrc}
                            onImagePicked={this.onImagePicked}
                            onAvatarPicked={this.onAvatarPicked}
                            onCancel={this.onPickerDialogCancel}
                            primaryButtonText={i18next.t("Save")} />
                    </ModalTransition>
                )}
            </div>
        );
    }

    private onChangeAvatarClick = () => {
        this.setState({
            showAvatarPicker: true
        })
    }

    private onRemoveAvatarClick = () => {
        this.onAvatarSrcChanged("");
    }

    private closePickerDialog = () => {
        this.setState({
            showAvatarPicker: false
        })
    }

    private onPickerDialogCancel = () => {
        this.closePickerDialog();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private onImagePicked = (selectedImage: File, crop: any) => {

        const reader = new FileReader();
        reader.readAsDataURL(selectedImage);
        reader.onload = () => {
            if (!reader.result) {
                this.closePickerDialog();
            }
            else {
                const avatarSrc = reader.result.toString();
                this.onAvatarSrcChanged(avatarSrc);
                this.setState({ showAvatarPicker: false })
            }
        }
    }

    private onAvatarPicked = (selectedAvatar: AvatarItem) => {
        this.onAvatarSrcChanged(selectedAvatar.dataURI);
        this.closePickerDialog();
    }

    private onAvatarSrcChanged = (avatarSrc: string) => {
        const { onAvatarSrcChanged } = this.props;
        if (onAvatarSrcChanged) {
            onAvatarSrcChanged(avatarSrc);
        }
    }
}