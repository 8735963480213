//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { DtsSection } from '../../../AVTService/TypeLibrary/DtsConfiguration/DtsSection';
import { DtsSectionProperties } from '../../../AVTService/TypeLibrary/DtsConfiguration/DtsSectionProperties';

export class DtsSectionInfo extends DtsSection
{
	public DtsSectionProperties: DtsSectionProperties;
}
