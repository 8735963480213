import { Checkbox } from '@atlaskit/checkbox';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import { debounce } from 'lodash';
import React, { SyntheticEvent } from 'react';
import { mapToListOfElements, mapToListOfElementsWithoutIds } from '../../helpers/StorageHelper';
import i18next from "../../i18n";
import { ProjectBusinessInfo } from '../../server/ProjectBusinessInfo';
import { IBusinessProjectsStorage } from '../../store/businessData.types';
import { GeovisDynamicTable } from '../GeovisDynamicTable';
import AuthService from '../../services/AuthService';

interface ISelectProjectsDialogProps {
    projectsStorage: IBusinessProjectsStorage;
    excludeProjectIds: number[];
    onSave: (projectInfoList: ProjectBusinessInfo[]) => void;
    onCancel: () => void;
}

interface ISelectProjectsDialogState {
    selectedProjectIds: Map<number, boolean>;
    query: string;
}

export class SelectProjectsDialog extends React.Component<ISelectProjectsDialogProps, ISelectProjectsDialogState>{

    private searchField: HTMLInputElement | null = null;

    constructor(props: ISelectProjectsDialogProps) {
        super(props);

        this.state = {
            selectedProjectIds: new Map<number, boolean>(),
            query: ""
        };
    }

    public render = () => {
        const actions = [
            { text: i18next.t("Ok"), onClick: this.onSave },
            { text: i18next.t("Cancel"), onClick: this.props.onCancel }
        ];

        return (
            <ModalTransition>
                <Modal
                    actions={actions}
                    onClose={this.props.onCancel}
                    heading={i18next.t("Select project")}
                    shouldCloseOnOverlayClick={false}
                    height="100%"
                    width="large">
                    <div className="flexRowContainer">
                        <div className="flexCellContainer">
                            {this.drawSearchField()}
                        </div>
                    </div>
                    <div className="flexRowContainer" style={{ marginTop: '10px' }}>
                        <div className="flexCellContainer_g1">
                            {this.drawProjectsTable()}
                        </div>
                    </div>
                </Modal>
            </ModalTransition>
        );
    }

    //#region Search functionality

    private onClearSearchField = () => {
        if (this.searchField) {
            this.searchField.value = "";
            this.onSearch("");
        }
    }

    private drawSearchField = () => {
        const onSearchDebounce = debounce((searchQuery: string) => this.onSearch(searchQuery), 500);
        const onSearchWrapped = (event: SyntheticEvent<HTMLInputElement>) => onSearchDebounce(event.currentTarget.value);

        const { query } = this.state;

        return (
            <div style={{ minWidth: '300px' }}>
                <Textfield
                    ref={sf => this.searchField = sf}
                    isCompact={true}
                    placeholder={i18next.t("Search a project")}
                    onChange={onSearchWrapped}
                    defaultValue={query}
                    width="300px"
                    elemAfterInput={
                        <div style={{ marginRight: '5px', cursor: 'default' }} onClick={this.onClearSearchField}>
                            <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                        </div>
                    }
                />
            </div>
        )
    }

    private onSearch = (searchQuery: string) => {
        this.setState({
            query: searchQuery
        });
    }

    private getSearchedProjects = (projects: ProjectBusinessInfo[]) => {
        const { query } = this.state;

        if (query === undefined || query.length === 0) {
            return projects;
        }

        const match = (source: string, pattern: string): boolean => (source !== undefined && source !== null && source.toLowerCase().search(pattern.toLowerCase()) > -1);

        try {
            return projects.filter((p) => match(p.Number, query)
                || match(p.Name, query)
                || match(p.LeaderName, query));
        }
        catch {
            return new Array<ProjectBusinessInfo>();
        }

    }

    //#endregion

    private drawProjectsTable = () => {
        const { selectedProjectIds } = this.state;
        const { excludeProjectIds, projectsStorage: { isLoading, projects } } = this.props;

        const companyProjectsIds: number[] = [];
        projects.forEach(p => {
            if (p.OwnerCompanyId === AuthService.currentUserCompanyId() || p.CreatorCompaniesIds.includes(AuthService.currentUserCompanyId()) || AuthService.hasUserTypeAsAdmin()) {
                companyProjectsIds.push(p.Id);
            }
        })

        const projectsToDraw = this.getSearchedProjects(mapToListOfElementsWithoutIds(projects, excludeProjectIds)).filter(p => companyProjectsIds.includes(p.Id));

        const head = {
            cells: [{
                key: 'selected',
                content: (
                    <div>
                        <Checkbox
                            key={"cb-check-all-key"}
                            name={"cb-check-all-name"}
                            isChecked={this.isAllChecked()}
                            onChange={this.onAllChecked} />
                    </div>),
                isSortable: false
            }, {
                key: 'projectNumber',
                content: i18next.t("Project number"),
                isSortable: true
            }, {
                key: 'projectName',
                content: i18next.t("Project name"),
                isSortable: true
            }, {
                key: 'projectLeader',
                content: i18next.t("Project leader"),
                isSortable: true
            }]
        };

        const rows = projectsToDraw.map(project => ({
            key: `row-${project.Id}`,
            cells: [{
                key: 'selected',
                content: (
                    <div>
                        <Checkbox
                            key={`cb-check-${project.Id}-key`}
                            name={`cb-check-${project.Id}-name`}
                            isChecked={selectedProjectIds.get(project.Id)}
                            onChange={this.onProjectSelectedFunc(project.Id)} />
                    </div>
                )
            }, {
                key: project.Number,
                content: (<div>{project.Number}</div>)
            }, {
                key: project.Name,
                content: (<div>{project.Name}</div>)
            }, {
                key: project.LeaderName,
                content: (<div>{project.LeaderName}</div>)
            }]
        }));

        return (
            <GeovisDynamicTable
                isLoading={isLoading}
                head={head}
                rows={rows}
            />
        );
    }

    private isAllChecked = (): boolean => {
        const { isLoaded, projects } = this.props.projectsStorage;
        if (!isLoaded) {
            return false;
        }
        const { selectedProjectIds } = this.state;
        const notSelectedProjects = mapToListOfElements(projects, p => !selectedProjectIds.get(p.Id));
        return notSelectedProjects.length === 0;
    }

    private onAllChecked = (event: SyntheticEvent<HTMLInputElement>) => {
        const { checked } = event.currentTarget;
        const { projects } = this.props.projectsStorage;
        const { selectedProjectIds } = this.state;

        projects.forEach(project => {
            selectedProjectIds.set(project.Id, checked);
        });

        this.setState({ selectedProjectIds });
    }

    private onProjectSelectedFunc = (projectId: number) => (event: SyntheticEvent<HTMLInputElement>) => {
        const { selectedProjectIds } = this.state;
        selectedProjectIds.set(projectId, event.currentTarget.checked);

        this.setState({ selectedProjectIds });
    }

    private onSave = () => {
        const { projects } = this.props.projectsStorage;
        const { selectedProjectIds } = this.state;
        const result: ProjectBusinessInfo[] = [];

        // collect selected projects
        selectedProjectIds.forEach((isSelected, projectId) => {
            if (isSelected) {
                const project = projects.get(projectId);
                if (project) {
                    result.push(project);
                }
            }
        })

        this.props.onSave(result);
    }
}