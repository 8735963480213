import { Fragment, useEffect, useState } from "react";
import { Rectangle } from "react-leaflet";
import { GEOvisDXFLayerType } from "../../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { SensorsMovementSettings } from "../../../server/AVTService/TypeLibrary/Map/SensorsMovementSettings";
import { SensorInfo } from "../../../server/GEOvis3/Model/SensorInfo";
import { MovementBarsPaneName } from "../../map/GeovisMap.PaneCreator";
import { IHeightMovementVectorInfo } from "./types";



interface IComponentProps {
    sensorIdMap: Map<string, SensorInfo>,
    layerType: GEOvisDXFLayerType;
    movementSettings: SensorsMovementSettings;
    leafletElement: L.Map;
    heightMovementVectors: IHeightMovementVectorInfo[];
    reportElementTimestamp?: number;
}

interface IComponentState {
    vectorsToDraw: IHeightMovementVectorInfo[];
    zoom: number;
}

const VectorHeightMovementComponent = ({ sensorIdMap, movementSettings, leafletElement, heightMovementVectors, }: IComponentProps) => {

    const [zoom, setZoom] = useState<number>(leafletElement.getZoom());

    const [state, setState] = useState<IComponentState>({
        vectorsToDraw: [],
        zoom: leafletElement.getZoom()
    });

    const getRectangleBounds = (deviation: number, startPositionLayerPoint: L.Point, endPositionLayerPoint: L.Point, iconOffset: L.Point): L.LatLngBounds => {
        const heightBarHalfWidth = movementSettings.HeightBarsMovementSettings.Weight / 2.0;

        if (deviation < 0) {
            const southWestPoint = new L.Point(startPositionLayerPoint.x + iconOffset.x - heightBarHalfWidth, endPositionLayerPoint.y + iconOffset.y);
            const northEastPoint = new L.Point(startPositionLayerPoint.x + iconOffset.x + heightBarHalfWidth, startPositionLayerPoint.y + iconOffset.y);

            return new L.LatLngBounds(leafletElement.layerPointToLatLng(southWestPoint), leafletElement.layerPointToLatLng(northEastPoint));
        }
        else {
            const southWestPoint = new L.Point(startPositionLayerPoint.x + iconOffset.x - heightBarHalfWidth, startPositionLayerPoint.y - iconOffset.y);
            const northEastPoint = new L.Point(startPositionLayerPoint.x + iconOffset.x + heightBarHalfWidth, endPositionLayerPoint.y - iconOffset.y);

            return new L.LatLngBounds(leafletElement.layerPointToLatLng(southWestPoint), leafletElement.layerPointToLatLng(northEastPoint));
        }
    }

    useEffect(() => {
        leafletElement.on("zoomend", () => {
            setZoom(leafletElement.getZoom());
        })

    }, []);

    useEffect(() => {

        const vectorsToDraw = heightMovementVectors
            .filter(s => sensorIdMap.has(s.sensorFullId))
            .map(vector => {
                const startPositionLayerPoint = leafletElement.latLngToLayerPoint(vector.startPosition);
                const endPositionLayerPoint = leafletElement.latLngToLayerPoint(vector.endPosition);

                return {
                    ...vector,
                    bounds: getRectangleBounds(vector.deviation, startPositionLayerPoint, endPositionLayerPoint, L.point(0, 0)),
                }
            });

        setState({
            vectorsToDraw,
            zoom: leafletElement.getZoom()
        })

    }, [zoom, heightMovementVectors]);

    return (
        <Fragment>
            {state.vectorsToDraw.map(vectorInfo => (
                <Rectangle
                    key={`sensor_height_movement_vector_${vectorInfo.sensorFullId}-div`}
                    bounds={vectorInfo.bounds}
                    color={"black"}
                    fillColor={vectorInfo.color}
                    className="height-movement-rectangle"
                    pane={MovementBarsPaneName}
                />
            ))}
        </Fragment>
    )
}

export default VectorHeightMovementComponent;