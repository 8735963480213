//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DatesGrouping {
	Day = 0,
	Week = 1,
	Month = 2,
	Year = 3
}

const DatesGroupingToName = new Map<number, string>([
	[ 0, 'Day' ],
	[ 1, 'Week' ],
	[ 2, 'Month' ],
	[ 3, 'Year' ],
])

export function getDatesGroupingToName(element: DatesGrouping) : string {
    return DatesGroupingToName.get(element) || '';
}

const DatesGroupingByName = new Map<string, number>([
	[ 'Day', 0 ],
	[ 'Week', 1 ],
	[ 'Month', 2 ],
	[ 'Year', 3 ],
]);

export function getDatesGroupingByName(name: string): DatesGrouping {
    return DatesGroupingByName.get(name)!;
}


export const DatesGroupingList : Readonly<DatesGrouping[]> = [
	DatesGrouping.Day,
	DatesGrouping.Week,
	DatesGrouping.Month,
	DatesGrouping.Year,
];



const DatesGroupingToDescription = new Map<number, string>([
	[ 0, 'Day' ],
	[ 1, 'Week' ],
	[ 2, 'Month' ],
	[ 3, 'Year' ],
])

export function getDatesGroupingToDescription(element: DatesGrouping) : string {
    return DatesGroupingToDescription.get(element) || '';
}

