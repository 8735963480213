/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 07.07.2020
 * @description Sensors tooltip 
 */

import { CustomThemeButton } from '@atlaskit/button';
import { SensorBase } from '../../../server/SensorBase';
import { SensorAlarmContent } from '../../alarm/SensorAlarmContent';
import { alarmLinkThemeFunc } from './tools';

export const SensorTooltipAlarmContent = ({ CausedAlarms, Id, PhysicalType, UnitDecimals }: SensorBase) => (
    <div>
        {CausedAlarms
            .filter((alarm, index) => index < 3)
            .map((alarm, index) => (
                <div key={`sensor_${Id}_tooltip_alarm_content_${index}`}>
                    <CustomThemeButton
                        appearance="link"
                        spacing="compact"
                        theme={alarmLinkThemeFunc(alarm)}>
                        <SensorAlarmContent alarm={alarm} sensorType={PhysicalType} sensorUnitDecimals={UnitDecimals} />
                    </CustomThemeButton>
                </div>
            ))}
    </div>
)