/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 02.11.2023
 * @description The icon text with sensor information
 */

import { Marker } from "react-leaflet";
import { Point, PointExpression } from "leaflet";
import { createOnlyTextIcon, createSingleSensorMarkerIcon } from "../../map/IconFactory";
import { SensorInfo } from "../../../server/GEOvis3/Model/SensorInfo";
import { IMovementIconInfo } from "./types";
import { Geovis4SensorSymbolSettings } from "../../../server/AVTService/TypeLibrary/Sensors/SymbolSettings/Geovis4SensorSymbolSettings";
import { SensorCategory } from "../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { SensorSymbol } from "../../../server/AVTService/TypeLibrary/Sensors/SensorSymbol";

interface IComponentProps {
    vectorInfo: IMovementIconInfo;
    sensorInfo: SensorInfo;

    fontSize: number;
    showSensorName: boolean;
    showVectorDeviation: boolean;
    showBarDeviation: boolean;
    useGeovis4Symbol?: boolean;
    showBorder: boolean;
    noScale: boolean;
    replaceIconMap?: Map<SensorCategory, Geovis4SensorSymbolSettings>;
}

export const SensorMovementIconText = ({ vectorInfo, sensorInfo, ...restProps }: IComponentProps) => {

    /**
     * Get icon text info
     * @returns 
     */
    const getIconTextInfo = (): [number, string] => {
        let insideContent: string = "";
        let countLinesUnder = -1;

        if (restProps.showSensorName) {
            insideContent = `<div style="font-weight:bold;">${sensorInfo.Name}</div>`
            countLinesUnder++;
        }

        if (restProps.showVectorDeviation && vectorInfo.vectorDeviationText) {
            insideContent += `<div>${vectorInfo.vectorDeviationText}</div>`
            countLinesUnder++;
        }

        if (restProps.showBarDeviation && vectorInfo.barDeviationText) {
            insideContent += `<div>${vectorInfo.barDeviationText}</div>`
            countLinesUnder++;
        }

        return [countLinesUnder, `<div style="display:flex; flex-direction:column; align-items:center;">${insideContent}</div>`];
    }

    const [countLinesUnder, iconText] = getIconTextInfo();
    const orientation = vectorInfo.mandatoryOrientation !== undefined ? vectorInfo.mandatoryOrientation : sensorInfo.MapTextOrientation;

    const sensorIcon = createSingleSensorMarkerIcon(
        sensorInfo, {
        useGeovis4SensorTypeSymbol: restProps.useGeovis4Symbol,
        noScale: restProps.noScale,
        countLinesUnder: 0,
        fontSize: restProps.fontSize,
        useSensorColor: restProps.noScale,
        replaceIconMap: restProps.replaceIconMap,
        showName: restProps.showSensorName,
        useMapTextOrientation: true,
        backgroundColor: sensorInfo.Color,
    }
    );

    const getSensorSymbolSize = (baseSize?: PointExpression): PointExpression | undefined => {
        if (restProps.useGeovis4Symbol === undefined || restProps.useGeovis4Symbol === true) {
            return baseSize;
        }

        switch (sensorInfo.Symbol) {
            case SensorSymbol.Plus:
            case SensorSymbol.TriangleUp:
            case SensorSymbol.TriangleDown:
            case SensorSymbol.TriangleLeft:
            case SensorSymbol.TriangleRight:
            case SensorSymbol.Romb: return new Point(24, 24);
            case SensorSymbol.Star: return new Point(24, 24);
            case SensorSymbol.Square:
            case SensorSymbol.Circle: return new Point(19, 19);
            default:
                return baseSize;
        }
    }

    const realIconSize = getSensorSymbolSize(sensorIcon.options.iconSize);

    const getTopAddition = (): number => {
        if (restProps.useGeovis4Symbol === false) {
            return 0;
        }
        switch (sensorInfo.PhysicalType) {
            case SensorCategory.VibrationSensor:
                return 5;
            default:
                return 0;
        }
    }

    const getLeftAddition = (): number => {
        if (restProps.useGeovis4Symbol === false) {
            return 0;
        }
        switch (sensorInfo.PhysicalType) {
            default:
                return 0;
        }
    }


    const icon = createOnlyTextIcon(vectorInfo.sensorFullId,
        orientation,
        vectorInfo.color,
        iconText,
        realIconSize ? (realIconSize as any).x : 0,
        realIconSize ? (realIconSize as any).y : 0,
        sensorIcon.options.iconAnchor ? (sensorIcon.options.iconAnchor as any).x : 6.5,
        sensorIcon.options.iconAnchor ? (sensorIcon.options.iconAnchor as any).y : 6.5,
        {
            useMapTextOrientation: true,
            showName: restProps.showSensorName,
            countLinesUnder,
            fontSize: restProps.fontSize,
            useSensorColorForText: true,
            movementVectorMode: true, // special case for Movement vectors, do not draw not necessary square over the icon,
            showBorder: restProps.showBorder,
            noScale: restProps.noScale,
            sensorSymbol: restProps.useGeovis4Symbol === false ? sensorInfo.Symbol : undefined,
            topAddition: getTopAddition(),
            leftAddition: getLeftAddition()

        });

    if (!restProps.showBarDeviation && !restProps.showVectorDeviation && !restProps.showSensorName) {
        return null;
    }

    return (
        <Marker
            position={vectorInfo.startPosition}
            zIndexOffset={-50}
            icon={icon}
        />
    )
}

