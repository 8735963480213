/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.11.2022
 * @description Geovis4Table report data layer (load data to draw on the page)
 */

import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../../helpers/GeovisHooks';
import { fetchServerElementsByPost, fetchServerElementsByPostCancellable, fetchServerElementsCancellable } from '../../../../../helpers/ProjectDataHelper';
import { Geovis4TableData } from '../../../../../server/AVTService/TypeLibrary/Computation/Geovis4TableData';
import { Geovis4Table } from '../../../../../server/AVTService/TypeLibrary/Model/Geovis4Tables/Geovis4Table';
import { DataActionResponse } from '../../../../../server/DataActionResponse';
import ServerRoutesGen from '../../../../../server/Routes/ServerRoutesGen';
import { projectReportGeovis4TableLoaded, projectReportGeovis4TableLoading } from '../../../../../store/creators/projectReportCreators';
import { IGeovis4TableReportInfo } from '../../../../../store/projectReport.types';
import { IGeovisStoreState } from '../../../../../store/store.types';
import { IGeovisAction } from '../../../../../store/types';
import { IReportElementRenderOwnProps } from '../types';
import { getGeovis4TableReportInfo } from './renderTools';

interface IStateToProps {
    tableInfo: IGeovis4TableReportInfo | undefined;
}

interface IDispatchToProps {
    onTableLoading: () => void;
    onTableLoaded: (response: DataActionResponse<Geovis4TableData>) => void;
}

interface IOwnProps extends Omit<IReportElementRenderOwnProps, 'eventInfo' | 'isDefault' | 'isVibrationEventChart' | 'showVibrationEventChartOnPage'> {
    tableId: number;
}

interface IComponentProps extends IStateToProps, IDispatchToProps, IOwnProps, IWithGeovisServerProps {

}

const getRequestId = (reportId: number, pageNum: number, tableId: number): string => {
    return `GET_DATA_FOR_REP_${reportId}_PAGE_${pageNum}_TABLE_${tableId}`;
}

const Component = ({ Server, pageNum, reportId, tableInfo, userId, userToken, onTableLoaded, onTableLoading }: IComponentProps) => {

    if (!tableInfo) {
        return null;
    }

    const { Timestamp, IsDirty, Table } = tableInfo;

    useEffect(() => {
        onTableLoading();

        const { Id, ProjectId } = Table;

        (async function loadData() {

            // if PDF rendering
            if (userId && userToken) {
                const url = ServerRoutesGen.ReportPdfRenderData.GetGeovis4TableData.patch(ProjectId, userToken, userId, reportId);
                const response = await fetchServerElementsByPost<Geovis4TableData, Geovis4Table>(Server, url, Table);

                onTableLoaded(response);
                return;
            }

            // if table is dirty
            if (IsDirty) {
                const url = ServerRoutesGen.ReportRenderData.GetGeovis4TableCustomData.patch(ProjectId);
                const response = await fetchServerElementsByPostCancellable<Geovis4TableData, Geovis4Table>(Server, url, getRequestId(reportId, pageNum, Id), Table);

                onTableLoaded(response);
                return;
            }

            // get default table data
            {
                const url = ServerRoutesGen.ReportRenderData.GetGeovis4TableData.patch(ProjectId, Id);
                const response = await fetchServerElementsCancellable<Geovis4TableData>(Server, url, getRequestId(reportId, pageNum, Id));

                onTableLoaded(response);
                return;
            }
        })();
    }, [Timestamp])


    return (
        <div style={{ display: 'none' }}>
            {/* Geovis4Table report data layer */}
        </div>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { pageNum, tableId }: IOwnProps): IStateToProps => ({
    tableInfo: getGeovis4TableReportInfo(projectReport.geovisReportSettings, pageNum, tableId)
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>, { tableId, pageNum }: IOwnProps): IDispatchToProps => ({
    onTableLoading: () => dispatch(projectReportGeovis4TableLoading(pageNum, tableId)),
    onTableLoaded: response => dispatch(projectReportGeovis4TableLoaded(pageNum, tableId, response))
});

const ComponentConnected = connect<IStateToProps, IDispatchToProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(withGeovisServer(Component));

export default ComponentConnected;