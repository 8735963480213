/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 31.05.2022
 * @description The chart data layer component (loads, reloads chart data)
 */

import { diff } from 'deep-diff';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { formattedDateTime } from '../../../../../helpers/DateHelper';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../../helpers/GeovisHooks';
import { fetchServerElementsByPost } from '../../../../../helpers/ProjectDataHelper';
import { AuthorizationCode } from '../../../../../server/AuthorizationCode';
import { ChartType, getChartTypeToName } from '../../../../../server/AVTService/TypeLibrary/Common/ChartType';
import { TimePeriod } from '../../../../../server/AVTService/TypeLibrary/Common/TimePeriod';
import { GeovisChartData } from '../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartData';
import { DtsChartModel } from '../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel';
import { GeovisChartModel } from '../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel';
import { DataActionResponse } from '../../../../../server/DataActionResponse';
import { DtsSectionInfo } from '../../../../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfo';
import ServerRoutesGen from '../../../../../server/Routes/ServerRoutesGen';
import FlagService from '../../../../../services/FlagService';
import Logger from '../../../../../services/Logger';
import { projectReportGeovisChartDataLoaded, projectReportGeovisChartDataLoading, projectReportGeovisChartRedraw } from '../../../../../store/creators/projectReportCreators';
import { IGeovisChartReportInfo } from '../../../../../store/projectReport.types';
import { IGeovisStoreState } from '../../../../../store/store.types';
import { IGeovisAction } from '../../../../../store/types';
import { IReportElementRenderOwnProps } from '../types';
import { getGeovisChartDataAsync } from './renders/ChartRendersData.tools';
import { getChartDataIsLoading, getGeovisChartInfo } from './tools';
import { GeovisChartPeriodPath, GeovisNoDataProperties, GetGeovisChartPath } from './types';
import store from '../../../../../store/configureStore'

interface IStateToProps {
    chartInfo: IGeovisChartReportInfo<GeovisChartModel> | undefined;
}

/**
 * Chart data layer dispatch to props
 */
interface IDispatchToProps {
    onChartDataLoading: (isVibrationData: boolean) => void;
    onChartDataLoaded: (response: DataActionResponse<GeovisChartData>, dtsResponse: DataActionResponse<DtsSectionInfo[]> | undefined, isVibrationData: boolean) => void;
    onChartRedraw: () => void;
}

interface IOwnProps extends Omit<IReportElementRenderOwnProps, 'showVibrationEventChartOnPage'> {
    chartId: number;
}

interface IComponentProps extends IStateToProps, IDispatchToProps, IOwnProps, IWithGeovisServerProps {

}

const LoggerSource = 'GeovisChartRenderDataLayer';

const Component = ({
    chartInfo,
    Server,
    onChartDataLoaded,
    onChartDataLoading,
    onChartRedraw,
    reportId,
    userId,
    userToken,
    isDefault,
    pageNum,
    eventInfo,
    isVibrationEventChart
}: IComponentProps) => {

    if (!chartInfo) {
        return null;
    }

    const { Chart, IsDirty, Timestamp, SuppressLoadingState } = chartInfo;
    const [prevChart, setPrevChart] = useState<GeovisChartModel>();

    const isPdfRendering = userId && userToken;

    const loadData = async (isVibrationData: boolean) => {

        let dtsResponse: DataActionResponse<DtsSectionInfo[]> | undefined;

        Logger.data(`page=${pageNum}, chart=${chartInfo.Chart.Id}: start data loading`, LoggerSource);

        const response = await getGeovisChartDataAsync<GeovisChartModel, GeovisChartData>(Server, { Chart, IsDirty, SuppressLoadingState: false, reportId, userId, userToken, isDefault, pageNum, eventInfo, isVibrationEventChart });

        if (!response.Success) {
            if (isPdfRendering) {
                // eslint-disable-next-line no-console
                console.error('REPORT ERROR: ' + response.Messages.join(", "));
            }

            if (response.AuthorizationCode === AuthorizationCode.Cancelled) {
                // Skip loading-rendering round in case of cancelled request
                return;
            }
            FlagService.addError("Failed to load the chart data", response.Messages.join(", "));
            onChartDataLoaded(response, dtsResponse, isVibrationData);
            return;
        }

        Logger.info(`page=${pageNum}, chart=${chartInfo.Chart.Id}: Chart data loaded`, LoggerSource);

        // update left report panel with config come from server
        if (!response.Data.SlimChartConfig && !isVibrationEventChart) {
            throw Error(`${getChartTypeToName(Chart.Type)} chart data has no slim chart config`);
        }

        // load DTS sections info for DTS chart
        if (Chart.Type === ChartType.DtsChart) {

            const { ProjectId, DtsSectionIds } = Chart as DtsChartModel;

            const url = (userToken && userId)
                ? ServerRoutesGen.ReportPdfRenderData.GetDtsSectionInfos.patch(ProjectId, userToken, userId, reportId)
                : ServerRoutesGen.DtsConfiguration.GetDtsSectionInfos.patch(ProjectId, reportId);

            dtsResponse = await fetchServerElementsByPost<DtsSectionInfo[], string[]>(Server, url, DtsSectionIds);
        }

        onChartDataLoaded(response, dtsResponse, isVibrationData);

        // update prev chart config times
        // it avoids reload data when first time changed property, which not related to data
        // do it only at first try
        if (!prevChart && !isVibrationEventChart) {
            setPrevChart({
                ...cloneDeep(Chart),
                StartDateSetting: { ...Chart.StartDateSetting, Value: formattedDateTime(response.Data.SlimChartConfig.StartDate, "YYYY/MM/DD HH:mm") },
                EndDateSetting: { ...Chart.EndDateSetting, Value: formattedDateTime(response.Data.SlimChartConfig.EndDate, "YYYY/MM/DD HH:mm") }
            });
        }
    }

    const isDataLoadingRequired = (isVibrationData: boolean): boolean => {
        if (isVibrationData && eventInfo) {
            Logger.trace(`Started loading data for event ${eventInfo.eventId} with fullId ${eventInfo.fullId} for chart ${Chart.Id}`);
            // setPrevChart(cloneDeep(Chart));
        }
        // check changes, if no changes in "data properties", then just redraw the chart, without loading the data
        else if (prevChart) {
            const diffResult = diff(Chart, prevChart);

            if (!diffResult) {
                Logger.warning(`page=${pageNum}, chart=${chartInfo.Chart.Id}: No diffs between charts`, LoggerSource);
                return false;
            }

            if (diffResult.length > 0) {
                // remember last changes
                setPrevChart(cloneDeep(Chart));

                // compare new changes with expected
                // if no properties, which related to "data properties", then just call reload the data, maybe another Timestamp for GeovisChartData is needed
                const editProperties = diffResult.filter(r => r.path).map(r => r.path!.join('.'));
                if (editProperties.length > 0) {

                    const dataProperties = editProperties.filter(path => GeovisNoDataProperties.indexOf(path) === -1);
                    // special condition for custom period
                    // if period set to custom - just redraw report
                    const isPeriodChanged = editProperties.find(path => GeovisChartPeriodPath() === path) !== undefined;
                    const isDateLimitsChanged = editProperties.find(path => `${GetGeovisChartPath("StartDateSetting")}.Value` === path || `${GetGeovisChartPath("EndDateSetting")}.Value` === path) !== undefined;

                    //If period set to custom by dropdown selection then we don't have to load new data, otherwise we must do that
                    if (dataProperties.length === 0 || (isPeriodChanged && !isDateLimitsChanged && Chart.Period === TimePeriod.Custom && !Chart.ShowAllMeasurementsSetting.value)) {

                        // it means that changed property, which not required to reload data from Server
                        // just redraw the chart
                        Logger.warning(`page=${pageNum}, chart=${chartInfo.Chart.Id}: No data, just redraw`, LoggerSource);
                        onChartRedraw();
                        return false;
                    }

                    Logger.warning(`page=${pageNum}, chart=${chartInfo.Chart.Id}: Changed properties of chart`, LoggerSource);
                }
            }

        }
        else {
            setPrevChart(cloneDeep(Chart));
        }

        return true;
    }

    useEffect(() => {
        const loadingVibrationEventData = isVibrationEventChart !== undefined && isVibrationEventChart && eventInfo !== undefined;
        const dataLoadingRequired = isDataLoadingRequired(loadingVibrationEventData);

        const actualSettings = store.getState().projectReport.geovisReportSettings;
        const chartIsLoading = getChartDataIsLoading(actualSettings, pageNum, Chart.Id, loadingVibrationEventData);

        if (chartIsLoading && isPdfRendering) {
            return;
        }

        if (dataLoadingRequired && !SuppressLoadingState) {
            onChartDataLoading(loadingVibrationEventData);
        }

        (async function loadChartData() {
            if ((dataLoadingRequired || SuppressLoadingState)) {
                await loadData(loadingVibrationEventData);
            }
        })();

    }, [Timestamp]);

    Logger.render(`page=${pageNum}, chart=${chartInfo.Chart.Id}: GeovisChartRenderDataLayer`, LoggerSource);

    return (
        <div style={{ display: 'none' }} />
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { chartId, pageNum }: IOwnProps): IStateToProps => ({
    chartInfo: getGeovisChartInfo(projectReport.geovisReportSettings, pageNum, chartId)
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>, { chartId, pageNum }: IOwnProps): IDispatchToProps => ({
    onChartDataLoading: (isVibration) => dispatch(projectReportGeovisChartDataLoading(pageNum, chartId, isVibration)),
    onChartDataLoaded: (data, dts, isVibration) => dispatch(projectReportGeovisChartDataLoaded(pageNum, chartId, data, dts, isVibration)),
    onChartRedraw: () => dispatch(projectReportGeovisChartRedraw(pageNum, chartId))
});

const ComponentConnected = connect<IStateToProps, IDispatchToProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(withGeovisServer(Component));

export default ComponentConnected;