//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GvSeverity {
	Trace = 0,
	Debug = 1,
	Info = 2,
	Warning = 3,
	Error = 4,
	Fatal = 5
}

const GvSeverityToName = new Map<number, string>([
	[ 0, 'Trace' ],
	[ 1, 'Debug' ],
	[ 2, 'Info' ],
	[ 3, 'Warning' ],
	[ 4, 'Error' ],
	[ 5, 'Fatal' ],
])

export function getGvSeverityToName(element: GvSeverity) : string {
    return GvSeverityToName.get(element) || '';
}

const GvSeverityByName = new Map<string, number>([
	[ 'Trace', 0 ],
	[ 'Debug', 1 ],
	[ 'Info', 2 ],
	[ 'Warning', 3 ],
	[ 'Error', 4 ],
	[ 'Fatal', 5 ],
]);

export function getGvSeverityByName(name: string): GvSeverity {
    return GvSeverityByName.get(name)!;
}


export const GvSeverityList : Readonly<GvSeverity[]> = [
	GvSeverity.Trace,
	GvSeverity.Debug,
	GvSeverity.Info,
	GvSeverity.Warning,
	GvSeverity.Error,
	GvSeverity.Fatal,
];



const GvSeverityToDescription = new Map<number, string>([
	[ 0, 'Trace' ],
	[ 1, 'Debug' ],
	[ 2, 'Info' ],
	[ 3, 'Warning' ],
	[ 4, 'Error' ],
	[ 5, 'Fatal' ],
])

export function getGvSeverityToDescription(element: GvSeverity) : string {
    return GvSeverityToDescription.get(element) || '';
}

