/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.08.2023
 * @description Geovis Image Report Render
 */

import { connect } from "react-redux";
import { IGeovisImageReportData, IGeovisImageReportInfo } from "../../../../../../store/projectReport.types";
import { IReportElementRenderHostOwnProp } from "../../types";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import GeovisImageRenderView from "./GeovisImageReportRenderView";
import { getGeovisImageInfo, getGeovisImageData } from "../tools";
import { LoadingPageSkeleton } from "../../../../../LoadingPageSkeleton";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { GeovisReportElementInfo } from "../../../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo";
import { ISensorsSymbolsStorage } from "../../../../../../store/data.types";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";


interface IStateToProps {
    geovisImageInfo: IGeovisImageReportInfo | false;
    geovisImageData: IGeovisImageReportData | false;
    visualSettings: ProjectVisualSettings;

    sensorsSymbolsStorage: ISensorsSymbolsStorage
}

interface IOwnProps extends Omit<IReportElementRenderHostOwnProp, 'eventInfo' | 'isDefault' | 'isVibrationEventChart' | 'showVibrationEventChartOnPage' | 'settingsId' | 'isPrinting'> {
    pageNum: number;
    elementInfo: GeovisReportElementInfo;
}

interface IComponentProps extends IStateToProps, IOwnProps {

}

const Component = ({ geovisImageData, geovisImageInfo, ...restProps }: IComponentProps) => {

    if (!geovisImageInfo || !geovisImageData) {
        return null;
    }

    const { sensorsInfoStorage, chainsInfoStorage, sensorsLastDataStorage, chainsLastDataStorage, isLoading, isError, errorDescription } = geovisImageData;

    if (isLoading) {
        return (
            <LoadingPageSkeleton loadingText="Rendering..." />
        )
    }

    if (isError) {
        return (
            <LoadingPageErrorSkeleton errorDescription={errorDescription} />
        )
    }

    return (
        <GeovisImageRenderView
            {...restProps}
            chainsInfoStorage={chainsInfoStorage}
            geovisImageInfo={geovisImageInfo}
            sensorsInfoStorage={sensorsInfoStorage}
            sensorsLastDataStorage={sensorsLastDataStorage}
            chainsLastDataStorage={chainsLastDataStorage}
        />
    )
}

/**
 * Map state to props of geovis image
 * @param param0 
 * @param param1 
 * @returns 
 */
const mapStateToProps = ({ projectReport, data }: IGeovisStoreState, { pageNum, elementInfo: { Id } }: IOwnProps): IStateToProps => ({
    geovisImageInfo: getGeovisImageInfo(projectReport.geovisReportSettings, pageNum, Id),
    geovisImageData: getGeovisImageData(projectReport.geovisReportSettings, pageNum, Id),
    sensorsSymbolsStorage: data.sensorsSymbolsStorage,
    visualSettings: data.projectInfo.project.VisualSettings
});


const ComponentConnected = connect<IStateToProps, never, IOwnProps>(mapStateToProps)(Component);

export default ComponentConnected;