import 'leaflet.markercluster';
import './geovis.leaflet.markercluster.jsInc';

L.MarkerCluster.include({
    getClusterMarkerIds(): number[] {
        const thisRef: L.MarkerCluster = this;
        return thisRef._markers.map((m: L.Marker) => L.Util.stamp(m));
    },

    zoomToClusterBounds(map: L.Map, layerContainer: any, onZoomEndEventFn: any) {
        const thisRef: L.MarkerCluster = this;
        let childClusters = thisRef._childClusters.slice();
        const boundsZoom = map.getBoundsZoom(thisRef.getBounds());
        let zoom = thisRef._zoom;
        const mapZoom = map.getZoom();

        const allMarkerIds = thisRef.getClusterMarkerIds();
        let bottomChildCluster = thisRef;

        if (childClusters.length === 0) {
            zoom++;
        }
        else {
            let existNewMarkers = true;
            // calculate how far we need to zoom down to see all of the markers
            while (childClusters.length > 0 && boundsZoom > zoom && existNewMarkers) {
                zoom++;
                const newMarkerIds: number[] = [];
                let newClusters: any[] = [];

                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < childClusters.length; i++) {
                    bottomChildCluster = childClusters[i];
                    const clusterMarkerIds = bottomChildCluster.getClusterMarkerIds().filter(m => allMarkerIds.indexOf(m) === -1);
                    if (clusterMarkerIds.length > 0) {
                        newMarkerIds.push(...clusterMarkerIds);
                        allMarkerIds.push(...newMarkerIds);
                    }

                    newClusters = newClusters.concat(bottomChildCluster._childClusters);
                }

                existNewMarkers = newMarkerIds.length > 0;
                childClusters = newClusters;
            }
        }

        if (allMarkerIds.length === 0 && bottomChildCluster._childCount === thisRef._childCount) {
            map.once('zoomend', onZoomEndEventFn(map, bottomChildCluster, layerContainer));
        }
        // map.once('zoomend', onZoomEndEventFn(map, bottomChildCluster, layerContainer));

        if (boundsZoom > zoom) {
            map.setView(thisRef.getLatLng(), zoom);
        } else if (boundsZoom <= mapZoom) { // If fitBounds wouldn't zoom us down, zoom us down instead
            map.setView(thisRef.getLatLng(), mapZoom + 1);
        } else {
            map.fitBounds(thisRef.getBounds());
        }
    },

    canBeClusterSpiderfied(distanceGrid: L.DistanceGrid, point: L.Point, spiderLegSqLength: number, excludedIds: number[]) {
        const x = distanceGrid._getCoord(point.x);
        const y = distanceGrid._getCoord(point.y);

        const iconSquare = 400; // approx. value as 20 x 20
        const minSqDistance = spiderLegSqLength + iconSquare;
        const xDistance = Math.floor(Math.sqrt(minSqDistance));
        const objectPoints = distanceGrid._objectPoint;

        for (let i = y - 1; i <= y + 1; i++) {
            const rows = distanceGrid._grid[i];
            if (rows) {

                for (let j = x - xDistance; j <= x + xDistance; j++) {
                    const cells = rows[j];
                    if (cells) {

                        for (let k = 0, len = cells.length; k < len; k++) {
                            const obj = cells[k];
                            const id = L.Util.stamp(obj);

                            if (excludedIds.indexOf(id) === -1) {
                                const dist = distanceGrid._sqDist(objectPoints[id], point);
                                if (dist > 1 && dist < minSqDistance) {
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
        return true;
    },

    getSpiderLegSqLength(map: L.Map, zoom: number, distanceGrid: L.DistanceGrid) {
        const thisRef: L.MarkerCluster = this;
        const clusterLanLng = thisRef.getLatLng();
        if (clusterLanLng && distanceGrid) {
            const center = map.latLngToLayerPoint(clusterLanLng);
            center.y += 10;

            const positions = thisRef._generatePointsCircle(2, center);
            const newPos = map.layerPointToLatLng(positions[0]);
            const leg = new L.Polyline([clusterLanLng, newPos]);
            const bounds = leg.getBounds();
            if (bounds.isValid()) {
                const point1 = map.project(bounds.getNorthEast(), zoom);
                const point2 = map.project(bounds.getSouthWest(), zoom);
                return Math.floor(distanceGrid._sqDist(point1, point2));
            }
        }
        return 35 * 35; // default
    },

    canBeClusterSpiderfiedAtZoom(map: L.Map, zoom: number, layerContainer: any, excludedIds: number[]) {

        if (map.getZoom() >= map.getMaxZoom()) {
            return true;
        }

        const thisRef: L.MarkerCluster = this;
        const boundsZoom = map.getBoundsZoom(thisRef.getBounds());
        if (layerContainer && zoom < boundsZoom) {
            const clusterPoint = map.project(thisRef.getLatLng(), zoom);

            const zoomIndex = Math.round(zoom);

            const zoomGridClusters = layerContainer._gridClusters[zoomIndex];
            const zoomGridUnclustered = layerContainer._gridUnclustered[zoomIndex];

            const spiderLegSqLength = thisRef.getSpiderLegSqLength(map, zoom, zoomGridClusters);

            let canBeSpiderfied = thisRef.canBeClusterSpiderfied(zoomGridClusters, clusterPoint, spiderLegSqLength, excludedIds);
            if (canBeSpiderfied) {
                canBeSpiderfied = thisRef.canBeClusterSpiderfied(zoomGridUnclustered, clusterPoint, spiderLegSqLength, excludedIds);
            }
            return canBeSpiderfied;
        }

        return false;
    }
});