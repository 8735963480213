import { TabItem } from '@atlaskit/tabs';
import { TabItemComponentProvided } from '@atlaskit/tabs/types';

/**
 * Tab item component provided
 * @param props 
 */
export const MessagesHubTabItemComponent = (props: TabItemComponentProvided) => (
    <div className="caption">
        <TabItem {...props} />
    </div>
)