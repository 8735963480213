//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ChartRegressionType {
	Linear = 0,
	Exponential = 1,
	Polynomial = 2,
	Power = 3,
	Logarithmic = 4
}

const ChartRegressionTypeToName = new Map<number, string>([
	[ 0, 'Linear' ],
	[ 1, 'Exponential' ],
	[ 2, 'Polynomial' ],
	[ 3, 'Power' ],
	[ 4, 'Logarithmic' ],
])

export function getChartRegressionTypeToName(element: ChartRegressionType) : string {
    return ChartRegressionTypeToName.get(element) || '';
}

const ChartRegressionTypeByName = new Map<string, number>([
	[ 'Linear', 0 ],
	[ 'Exponential', 1 ],
	[ 'Polynomial', 2 ],
	[ 'Power', 3 ],
	[ 'Logarithmic', 4 ],
]);

export function getChartRegressionTypeByName(name: string): ChartRegressionType {
    return ChartRegressionTypeByName.get(name)!;
}


export const ChartRegressionTypeList : Readonly<ChartRegressionType[]> = [
	ChartRegressionType.Linear,
	ChartRegressionType.Exponential,
	ChartRegressionType.Polynomial,
	ChartRegressionType.Power,
	ChartRegressionType.Logarithmic,
];



const ChartRegressionTypeToDescription = new Map<number, string>([
	[ 0, 'Linear' ],
	[ 1, 'Exponential' ],
	[ 2, 'Polynomial' ],
	[ 3, 'Power' ],
	[ 4, 'Logarithmic' ],
])

export function getChartRegressionTypeToDescription(element: ChartRegressionType) : string {
    return ChartRegressionTypeToDescription.get(element) || '';
}

