import { t } from "i18next";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HeadType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import AddIcon from '@atlaskit/icon/glyph/add'
import Button from "@atlaskit/button";
import CopyIcon from '@atlaskit/icon/glyph/copy'
import TrashIcon from '@atlaskit/icon/glyph/trash';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { GeovisUserCommonInfo } from "../../../../server/GEOvis3/Model/User/GeovisUserCommonInfo";
import { IGeovisProjectUsersStorage } from "../../../../store/data.types";
import { IGeovisStoreState } from "../../../../store/store.types";
import { IGeovisAction, ISomethingStorageBaseEx, defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../../../../store/types";
import { projectDataUsersUpdate } from "../../../../store/creators/dataCreators";
import { AlarmActionUsersPreset } from "../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUsersPreset";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import IRouteParams from "../../../../helpers/IRouteParams";
import { fetchServerElements, fetchServerElementsByPost } from "../../../../helpers/ProjectDataHelper";
import { GeovisDynamicTable } from "../../../../components/GeovisDynamicTable";
import FlagService from "../../../../services/FlagService";
import { AlarmActivationBlockModel } from "../../../../server/GEOvis3/Model/Alarms/UsersPresets/AlarmActivationBlockModel";
import EditUserPresetDialog from "./EditUserPresetDialog";
import { RemovePresetDialog } from "./RemovePresetDialog";
import { GeovisModalDialog } from "../../../../components/editDialogs/GeovisModalDialog";

interface IComponentStateProps {
    projectUsersStorage: IGeovisProjectUsersStorage;
}

interface IComponentDispatchProps {
    projectUsersUpdate: (...projectUsers: GeovisUserCommonInfo[]) => void;
}

interface IComponentOwnProps {
    onClose: () => void;
    onChangeAlarmActivationPossibility: (config: AlarmActivationBlockModel) => void;
}

interface IComponentProps extends IWithGeovisServerProps, IComponentStateProps, IComponentDispatchProps, IComponentOwnProps {

}

interface IComponentState extends ISomethingStorageBaseEx<AlarmActionUsersPreset[]> {
    showEditUserDialog: boolean;
    userId: string;
    showEditPresetDialog: boolean;
    preset: AlarmActionUsersPreset;
    showPresetRemoveWarningDialog: boolean
}

const Component = ({
    Server,
    onClose,
    onChangeAlarmActivationPossibility
}: IComponentProps) => {

    const { projectId } = useParams<IRouteParams>();

    const initPreset: AlarmActionUsersPreset = {
        ConditionsCount: 3,
        Id: "",
        Name: "New users preset",
        ProjectId: projectId ? +projectId : 0,
        Users: []
    }

    const [state, setState] = useState<IComponentState>({
        ...defaultSomethingStorageState,
        data: [],
        showEditUserDialog: false,
        userId: '',
        showEditPresetDialog: false,
        preset: initPreset,
        showPresetRemoveWarningDialog: false
    })

    useEffect(() => {
        (async function loadPresets() {
            const url = ServerRoutesGen.Alarms.GetUsersPresetsForProject.patch(projectId);
            const response = await fetchServerElements<AlarmActionUsersPreset[]>(Server, url);
            if (!response.Success) {
                setState({
                    ...state,
                    ...errorSomethingStorageState(response.Messages.join("; ")),
                    data: []
                });
                return;
            }
            setState({
                ...state,
                ...loadedSomethingStorageState,
                data: response.Data
            });
        })();
    }, [1])

    const onRemovePresetClickHandler = (preset: AlarmActionUsersPreset) => () => {
        setState({
            ...state,
            preset,
            showPresetRemoveWarningDialog: true
        });
    }

    const onRemovePreset = async () => {
        const presetId = state.preset.Id;
        const url = ServerRoutesGen.Alarms.RemoveUsersPresets.patch(projectId, presetId);
        const response = await fetchServerElements<AlarmActivationBlockModel>(Server, url);
        if (!response.Success) {
            FlagService.addError("Failed to remove preset", response.Messages.join("; "));
            setState({
                ...state,
                preset: initPreset,
                showPresetRemoveWarningDialog: false
            });
            return;
        }

        onChangeAlarmActivationPossibility(response.Data);
        setState({
            ...state,
            showPresetRemoveWarningDialog: false,
            preset: initPreset,
            data: state.data.filter(d => d.Id !== presetId)
        })
    }

    const onCloseRemovePresetWarningDialog = () => {
        setState({
            ...state,
            preset: initPreset,
            showPresetRemoveWarningDialog: false
        });
    }

    const onDuplicateClickHandler = (preset: AlarmActionUsersPreset) => async () => {
        const url = ServerRoutesGen.Alarms.DuplicateUsersPresets.patch(projectId, preset.Id);
        const response = await fetchServerElements<AlarmActionUsersPreset>(Server, url);
        if (!response.Success) {
            FlagService.addError("Failed to duplicate preset", response.Messages.join("; "));
            return;
        }

        state.data.push(response.Data);

        setState({
            ...state,
            data: state.data
        })
    }

    const onEditPresetClickHandler = (preset: AlarmActionUsersPreset) => () => {
        setState({
            ...state,
            showEditPresetDialog: true,
            preset
        })
    }

    const getPresetsRows = (): RowType[] => {
        if (!state.isLoaded) {
            return [];
        }

        return state.data.map(p => ({
            key: p.Id,
            cells: [{
                key: 'name',
                content: (
                    <span>{p.Name}</span>
                )
            }, {
                key: 'actions',
                content: (
                    <div>
                        <Button
                            iconBefore={<EditFilledIcon label='edit' />}
                            onClick={onEditPresetClickHandler(p)}
                        />
                        <Button
                            iconBefore={<CopyIcon label='dup' />}
                            onClick={onDuplicateClickHandler(p)}
                        />
                        <Button
                            iconBefore={<TrashIcon label='remove' />}
                            onClick={onRemovePresetClickHandler(p)}
                        />
                    </div>
                )
            }]
        }))
    }

    const getPresetsHead = (): HeadType => ({
        cells: [{
            key: 'name',
            isSortable: true,
            width: 12,
            content: t("Template name")
        }, {
            key: 'actions',
            isSortable: false,
            width: 2
        }]
    })

    const onCreatePreset = async (preset: AlarmActionUsersPreset) => {
        const url = ServerRoutesGen.Alarms.CreateUsersPreset.patch(projectId);
        const response = await fetchServerElementsByPost<string, AlarmActionUsersPreset>(Server, url, preset);
        if (!response.Success) {
            FlagService.addError("Failed to create preset", response.Messages.join("; "));
            setState({
                ...state,
                showEditPresetDialog: false,
                preset: initPreset
            })
            return;
        }
        preset.Id = response.Data;

        setState({
            ...state,
            data: [...state.data, preset],
            showEditPresetDialog: false,
            preset: initPreset
        })
    }

    const onUpdatePreset = async (preset: AlarmActionUsersPreset) => {
        const url = ServerRoutesGen.Alarms.SaveUsersPreset.patch(projectId);
        const response = await fetchServerElementsByPost<AlarmActivationBlockModel, AlarmActionUsersPreset>(Server, url, preset);
        if (!response.Success) {
            FlagService.addError("Failed to create preset", response.Messages.join("; "));
            setState({
                ...state,
                showEditPresetDialog: false,
                preset: initPreset
            })
            return;
        }
        onChangeAlarmActivationPossibility(response.Data);

        const exPresetIndex = state.data.findIndex(p => p.Id === preset.Id);
        if (exPresetIndex < 0) {
            setState({
                ...state,
                showEditPresetDialog: false,
                preset: initPreset
            })
            return;
        }

        state.data.splice(exPresetIndex, 1, preset);

        setState({
            ...state,
            data: state.data,
            showEditPresetDialog: false,
            preset: initPreset
        })
    }

    const onShowCreatePresetDialog = () => {
        setState({
            ...state,
            showEditPresetDialog: true,
            preset: initPreset
        })
    }

    const onCloseEditPresetDialog = () => {
        setState({
            ...state,
            showEditPresetDialog: false,
            preset: initPreset
        })
    }

    return (
        <GeovisModalDialog
            heading={t("Manage Email & Sms Receivers presets")}
            actions={[{ text: t("Close"), onClick: onClose }]}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                <div>
                    <Button
                        appearance="primary"
                        iconBefore={<AddIcon label={"add"} />}
                        onClick={onShowCreatePresetDialog}>
                        {t("Add new preset")}
                    </Button>
                </div>
                <div>
                    <GeovisDynamicTable
                        rows={getPresetsRows()}
                        isLoading={state.isLoading}
                        head={getPresetsHead()}
                        emptyView={<span>{t("No users presets were configured")}</span>}
                    />
                </div>
                {state.showEditPresetDialog &&
                    <EditUserPresetDialog
                        onClose={onCloseEditPresetDialog}
                        onSave={onUpdatePreset}
                        onCreate={onCreatePreset}
                        preset={state.preset}
                    />
                }
                {state.showPresetRemoveWarningDialog &&
                    <RemovePresetDialog
                        onClose={onCloseRemovePresetWarningDialog}
                        onRemove={onRemovePreset}
                        preset={state.preset}
                    />
                }
            </div>
        </GeovisModalDialog>
    )
}

const mapStateToProps = (state: IGeovisStoreState): IComponentStateProps => ({
    projectUsersStorage: state.data.projectUsersStorage
})

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IComponentDispatchProps => ({
    projectUsersUpdate: (users) => dispatch(projectDataUsersUpdate(users)),
})

export default connect<IComponentStateProps, IComponentDispatchProps, IComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(Component));