/*
    Flags service, need to draw some messages as Atlaskit flags
*/

import AxiosCancelError from '../errors/AxiosCancelError';
import i18next from '../i18n';
import { SERVICES_ADD_FLAG, SERVICES_CLEAR_FLAGS } from '../store/actions/navigationActions';
import { IGeovisStore } from '../store/store.types';
import { IGeovisNavigationAction } from '../store/types';
import Logger from './Logger';

export enum FlagType {
    Information = "normal",
    Warning = "warning",
    Error = "error"
}

export interface IFlag {
    id: string;
    type: FlagType;
    title: string;
    description: string;
    icon: any;
    sendMessageButton: boolean;
    sendMessageButtonAction?: () => void;
}

const addFlagAction = (flag: IFlag): IGeovisNavigationAction => {
    return {
        type: SERVICES_ADD_FLAG,
        flag
    };
}

const clearFlagsAction = (): IGeovisNavigationAction => {
    return {
        type: SERVICES_CLEAR_FLAGS
    };
};

class FlagService {

    private store: IGeovisStore;

    public init = (store: IGeovisStore) => {
        if (!store) {
            throw Error("FlagService: Geovis store is not defined");
        }

        this.store = store;
    }

    public addInfo = (title: string, description: string, id?: string, showContactButton: boolean = false, sendMessageButtonAction?: () => void) => {
        this.addFlag({
            description,
            icon: "<div></div>",
            id: id || Date.now().toString(),
            title,
            type: FlagType.Information,
            sendMessageButton: showContactButton,
            sendMessageButtonAction
        });
    }

    public addErrors = (message: string, errors: string[], showContactButton: boolean = false) => {
        // errors.map(e => this.addError(message, e));
        let errorString = "";
        errors.forEach(e => {
            errorString += e + "\r\n";
        });
        this.addError(message, errorString, undefined, showContactButton);
    }

    public addError = (message: string, details: any, id?: string, showContactButton: boolean = false, sendMessageButtonAction?: () => void) => {

        if (details instanceof AxiosCancelError ||
            (details instanceof Error && details.name === "AxiosCancelError")) {
            // do nothing, no messages for the user
            return;
        }

        Logger.error(this.getErrorDescription(details));

        const title = details instanceof Error
            ? i18next.t("Internal failure, please contact system administrator")
            : message;

        const description = details instanceof Error
            ? message
            : details;

        this.addFlag({
            description,
            icon: "<div></div>",
            id: id || Date.now().toString(),
            title,
            type: FlagType.Error,
            sendMessageButton: showContactButton,
            sendMessageButtonAction
        });
    }

    public addWarning = (title: string, message: string, showContactButton: boolean = false, sendMessageButtonAction?: () => void) => {
        this.addFlag({
            description: message,
            icon: "<div></div>",
            id: Date.now().toString(),
            title,
            type: FlagType.Warning,
            sendMessageButton: showContactButton,
            sendMessageButtonAction
        });
    }

    public getErrorDescription = (details: any) => {
        let description = "";

        if (details instanceof Error) {
            if ((details as any).response && (details as any).response.data) {
                description = (details as any).response.data;
            } else {
                description = details.message;
            }
        } else {
            description = details;
        }

        return description;
    }

    public getErrorDescriptionFromArray = (defaultMessage: string, messages?: string[]): string => {
        if (messages && messages.length > 0) {
            return messages[0];
        }
        return i18next.t(defaultMessage);
    }

    public addFlag = (flag: IFlag) => {
        this.store.dispatch(addFlagAction(flag));
    }

    public clearFlags = () => {
        this.store.dispatch(clearFlagsAction());
    }
}

export default new FlagService();