/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 17.02.2023
 * @description Update feedback numbers information data layer, do it when component mounted on the page
 */

import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IWithGeovisServerProps, withGeovisServer } from "../../../helpers/GeovisHooks";
import { fetchServerElements } from "../../../helpers/ProjectDataHelper";
import { DataActionResponse } from "../../../server/DataActionResponse";
import { GeovisFeedbackStatistic } from "../../../server/GEOvis3/Model/GeovisFeedback/GeovisFeedbackStatistic";
import ServerRoutesGen from "../../../server/Routes/ServerRoutesGen";
import AuthService from "../../../services/AuthService";
import { navigationFeedbackStatisticData } from "../../../store/creators/navigationCreators";
import { IGeovisAction } from "../../../store/types";

interface IDispatchToProps {
    onFeedbackStatisticLoaded: (response: DataActionResponse<GeovisFeedbackStatistic>) => void;
}

interface IOwnProps {
    refreshDependencies?: any[];
}

interface IComponentProps extends IWithGeovisServerProps, IDispatchToProps, IOwnProps {

}

const Component = ({ Server, onFeedbackStatisticLoaded, refreshDependencies }: IComponentProps) => {

    const dependencies: any[] = [1, ...(refreshDependencies || [])];

    // load admin resources, like feedback records info
    useEffect(() => {

        if (AuthService.hasUserTypeAsAdmin()) {
            (async function loadStatistic() {

                const url = ServerRoutesGen.GeovisFeedback.GetStatistic;
                const response = await fetchServerElements<GeovisFeedbackStatistic>(Server, url);

                onFeedbackStatisticLoaded(response);
            })();
        }
    }, dependencies);

    return (
        <div style={{ display: 'none' }}>
            {/* nothing there */}
        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDispatchToProps => ({
    onFeedbackStatisticLoaded: response => dispatch(navigationFeedbackStatisticData(response))
});

export default connect<never, IDispatchToProps, IOwnProps>(undefined, mapDispatchToProps)(withGeovisServer(Component));