/**
 * @description Common types of components.
 * TODO: refactoring. In generate such types must be moved near to their destination components.
 */
import { SortOrderType } from "@atlaskit/dynamic-table/types";
import { CSSProperties } from "react";
import { MapItemObject } from "../helpers/MapHelper";
import { AlarmInfo } from "../server/AlarmInfo";
import { AlarmType } from "../server/AVTService/TypeLibrary/Alarming/AlarmType";
import { SensorMapTextOrientation } from "../server/AVTService/TypeLibrary/Common/SensorMapTextOrientation";
import { SensorCategory } from "../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { Geovis4SensorSymbolSettings } from "../server/AVTService/TypeLibrary/Sensors/SymbolSettings/Geovis4SensorSymbolSettings";
import {
    ISVGIconProps
} from "./map/types";
import { SensorSymbol } from "../server/AVTService/TypeLibrary/Sensors/SensorSymbol";

interface IDynamicTableCell {
    key: string;
    content: JSX.Element | string;
    width?: string | number;
    isSortable?: boolean;
    colSpan?: number;
}

// @obsolete - needed to replace with RowType of dynamic table package
export interface IDynamicTableRow {
    key: string;
    style?: CSSProperties;
    cells: IDynamicTableCell[];
}

export interface IDynamicTableSortParam {
    item: IDynamicTableCell;
    key: string;
    sortOrder: SortOrderType;
}

/**
 * Create (render) sensor state options
 */
export interface ICreateSensorStateIconOptions {
    showName?: boolean;
    fontSize?: number;
    noScale?: boolean;
    fontWeight?: string;
    backgroundColor?: string;
    replaceIconMap?: Map<SensorCategory, Geovis4SensorSymbolSettings>;
    forceSingleMarkerMode?: boolean;
    useMapTextOrientation?: boolean;
    useSensorColor?: boolean;
    useGeovis4SensorTypeSymbol?: boolean;
    useSensorColorForText?: boolean;
    countLinesUnder?: number; // count lines of text in sensor name (default is 0, but can be more in Map Sections) 
    mandatoryOrientation?: SensorMapTextOrientation; // text orientation which must be set to sensor icon
    movementVectorMode?: boolean; // special case of icon for Text on MapSection (it is not draw)
    showBorder?: boolean // show border on icon text
    sensorSymbol?: SensorSymbol;
    topAddition?: number;
    leftAddition?: number;
}

const hasOnlyAlarmOfTypes = (alarms: AlarmInfo[], alarmTypes: AlarmType[]): boolean => {
    if (!alarms) {
        return false;
    }

    if (alarms.length === 0) {
        return false;
    }

    for (const alarm of alarms) {
        if (alarmTypes.indexOf(alarm.AlarmType) === -1) {
            return false;
        }
    }

    return true;
}

const hasOnlyFunctionalAlarmsOfMultipleSensors = <TPoint extends MapItemObject>(points: TPoint[]): boolean => {

    for (const point of points) {
        if (!hasOnlyFunctionalAlarms(point.CausedAlarms)) {
            return false;
        }
    }

    return true;
}

const hasOnlyFunctionalAlarms = (alarms: AlarmInfo[]): boolean => {
    return hasOnlyAlarmOfTypes(alarms, [
        AlarmType.AGMSAlarm,
        AlarmType.WDImportAlarm,
        AlarmType.WDSensorAlarm,
        AlarmType.WDSystemAlarm
    ]);
}

const hasAnyAlarmsOfMultipleSensors = <TPoint extends MapItemObject>(points: TPoint[]): boolean => {
    for (const point of points) {
        if (point.CausedAlarms && point.CausedAlarms.length > 0) {
            return true;
        }
    }

    return false;
}

const FunctionalAlarmScaleRatio = 1.25;
const SeriousAlarmScaleRate = 1.5;
const NoAlarmsScaleRate = 1;

export const getIconScale = (sensor: MapItemObject, { iconScale }: ISVGIconProps, options?: ICreateSensorStateIconOptions): number => {

    if (options && options.noScale) {
        return iconScale;
    }

    if (hasOnlyFunctionalAlarms(sensor.CausedAlarms)) {
        return iconScale * FunctionalAlarmScaleRatio;
    }

    const rate = sensor.CausedAlarms && sensor.CausedAlarms.length > 0
        ? SeriousAlarmScaleRate
        : NoAlarmsScaleRate;

    return rate * iconScale;
}

export const getMultipleSensorsIconScale = <TPoint extends MapItemObject>(points: TPoint[], { iconScale }: ISVGIconProps, options?: ICreateSensorStateIconOptions): number => {

    if (options && options.noScale) {
        return iconScale;
    }

    if (hasOnlyFunctionalAlarmsOfMultipleSensors(points)) {
        return iconScale * FunctionalAlarmScaleRatio;
    }

    if (hasAnyAlarmsOfMultipleSensors(points)) {
        return iconScale * SeriousAlarmScaleRate;
    }

    return iconScale * NoAlarmsScaleRate;

}

export const getShowSensorName = (sensor: MapItemObject, options?: ICreateSensorStateIconOptions): string | false => {
    if (options && options.showName) {
        return sensor.Name;
    }

    return false;
}

export const getShowSensorNames = (points: MapItemObject[], options?: ICreateSensorStateIconOptions): string | false => {
    if (options && options.showName) {
        return points.map<string>(p => p.Name).join('<br /> ');
    }

    return false;
}