//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AlarmActionUIType {
	Dolphin = 0,
	Message = 1,
	AgmsDigitalOutput = 2
}

const AlarmActionUITypeToName = new Map<number, string>([
	[ 0, 'Dolphin' ],
	[ 1, 'Message' ],
	[ 2, 'AgmsDigitalOutput' ],
])

export function getAlarmActionUITypeToName(element: AlarmActionUIType) : string {
    return AlarmActionUITypeToName.get(element) || '';
}

const AlarmActionUITypeByName = new Map<string, number>([
	[ 'Dolphin', 0 ],
	[ 'Message', 1 ],
	[ 'AgmsDigitalOutput', 2 ],
]);

export function getAlarmActionUITypeByName(name: string): AlarmActionUIType {
    return AlarmActionUITypeByName.get(name)!;
}


export const AlarmActionUITypeList : Readonly<AlarmActionUIType[]> = [
	AlarmActionUIType.Dolphin,
	AlarmActionUIType.Message,
	AlarmActionUIType.AgmsDigitalOutput,
];



const AlarmActionUITypeToDescription = new Map<number, string>([
	[ 0, 'F24' ],
	[ 1, 'Email/Sms' ],
	[ 2, 'AGMS Digital Output' ],
])

export function getAlarmActionUITypeToDescription(element: AlarmActionUIType) : string {
    return AlarmActionUITypeToDescription.get(element) || '';
}

