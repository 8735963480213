/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 04.07.2022
 * @description Geovis validation error component
 */

import { ErrorMessage } from '@atlaskit/form';
import { t } from '../../i18n';
import { GeovisErrorCode, getGeovisErrorCodeToName } from '../../server/Geovis/ClientServerIntermediate/GeovisErrorCode';
import { GeovisFieldFailInfo } from '../../server/Geovis/ClientServerIntermediate/GeovisFieldFailInfo';

interface IComponentProps {
    validationError: GeovisFieldFailInfo;
}

export const GeovisFieldValidationError = ({ validationError }: IComponentProps) => {

    switch (validationError.ErrorCode) {

        case GeovisErrorCode.Duplicate: return (
            <ErrorMessage>
                {t(validationError.ErrorDescription)}
            </ErrorMessage>
        )

        case GeovisErrorCode.EmptyField: return (
            <ErrorMessage>
                {t("Field cannot be empty")}
            </ErrorMessage>
        )

        case GeovisErrorCode.WrongValue: return (
            <ErrorMessage>
                {t("Field has wrong value")}
            </ErrorMessage>
        )
    }
}

export const GetTextGeovisFieldValidationError = (validationError: GeovisFieldFailInfo): string => {
    let textError = `${t('Unknown error code')} ${getGeovisErrorCodeToName(validationError.ErrorCode)}`;

    switch (validationError.ErrorCode) {
        case GeovisErrorCode.Duplicate: textError = t(validationError.ErrorDescription); break;
        case GeovisErrorCode.EmptyField: textError = t("cannot be empty"); break;
        case GeovisErrorCode.WrongValue: textError = t("has wrong value"); break;
    }

    return `Field '${validationError.FieldName}': ${textError}`
}