/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 29.06.2020
 * @description tool method to for sensors overview layer
 */

import { toFixedSensorValue } from "../../../helpers/SensorHelper";
import { t } from '../../../i18n';
import { AlarmInfo } from "../../../server/AlarmInfo";
import { AlarmType } from "../../../server/AVTService/TypeLibrary/Alarming/AlarmType";
import { SensorAttribyteEntry } from "../../../server/AVTService/TypeLibrary/Common/SensorAttribyteEntry";
import { SensorMapTextOrientation } from "../../../server/AVTService/TypeLibrary/Common/SensorMapTextOrientation";
import { GeoJSONMapTilesLayerBounds } from "../../../server/AVTService/TypeLibrary/Model/GeoJSONMapTilesLayerBounds";
import { NotificationsTypes } from "../../../server/AVTService/TypeLibrary/Notifications/NotificationsTypes";
import { SensorCategory } from "../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { ChainInfo } from "../../../server/ChainInfo";
import { ChainMeasurementInfo } from "../../../server/ChainMeasurementInfo";
import { GeoLocation } from "../../../server/GeoLocation";
import { SensorInfo } from "../../../server/GEOvis3/Model/SensorInfo";
import { SensorBase } from "../../../server/SensorBase";
import { SensorMeasurementInfo } from "../../../server/SensorMeasurementInfo";
import { SensorReportInfo } from "../../../server/SensorReportInfo";
import AuthService from "../../../services/AuthService";
import { IChainsInfoStorage, IChainsLastDataStorage, ISensorsLastDataStorage } from "../../../store/data.types";
import { ICloseToBorderSensorsIds, LastMeasOf } from "./types";

export const DXF_AVAILABLE_PIXELS_SIZE = 213.4; // Tile has 256 pixels width but only 213.4 is available for picture

export const PROFILE_VIEW_LATITUDE_OFFSET = 128;
export const PROFILE_VIEW_LONGITUDE_OFFSET = 128;

export const SIDE_VIEW_LATITUDE_OFFSET = 128;
export const SIDE_VIEW_LONGITUDE_OFFSET = 128;
export const SIDE_VIEW_NAGRA_LONGITUDE_OFFSET = 21.3;
export const SIDE_VIEW_NAGRA_INVERT_AXIS_LONGITUDE_OFFSET = 234.7;

export const isChainAsSensorInfo = (sensorInfo: SensorInfo): boolean => {
    return sensorInfo.PhysicalType === SensorCategory.BH_Inclinometer;
}

export const getChainOfStorage = (storage: IChainsInfoStorage, chainId: string): ChainInfo | false => {
    if (storage.isLoading || !storage.isLoaded) {
        return false;
    }

    return storage.chainsInfo.get(chainId) || false;
}

export const getChainLastMeasurement = (storage: IChainsLastDataStorage, chainId: string): LastMeasOf<ChainMeasurementInfo> => {
    if (storage.isLoading || !storage.isLoaded) {
        return undefined;
    }

    return storage.chainsLastMeasurements.get(chainId) || false;
}

export const getSensorLastMeasurement = ({ isLoading, sensorsLastMeasurements }: ISensorsLastDataStorage, sensorId: string): LastMeasOf<SensorMeasurementInfo> => {
    const record = sensorsLastMeasurements.get(sensorId);
    if (!record) {

        // if loading, then sensor data not found yet
        if (isLoading) {
            return undefined;
        }

        return false;
    }

    return record;
}

export const isAnyActiveAlarm = (alarms: AlarmInfo[]): boolean => {
    if (!alarms || alarms.length === 0) {
        return false;
    }

    return alarms.findIndex(a => a.IsPublic && (a.AlarmType === AlarmType.AGMSAlarm || a.AlarmType === AlarmType.SensorAlarm)) !== -1;
}

/**
 * Draw sensor point values as string
 * @param values 
 * @param sensor 
 */
export const drawUnitValues = <TPoint extends SensorBase>(values: number[], sensor: TPoint) => {
    if (values.length > 1) {
        return "[" + values.map(value => toFixedSensorValue(value, sensor)).join(", ") + "]"
    }

    if (values.length === 1) {
        return toFixedSensorValue(values[0], sensor);
    }

    return '';
}

/**
 * Draw deviation values
 * @param deviation 
 * @param sensor 
 */
export const drawDeviation = (deviation: number, sensor: SensorInfo) => {
    return t("Deviation") + " = " + toFixedSensorValue(deviation, sensor);
}

/**
 * Draw axis relative values
 * @param values 
 * @param sensor 
 */
export const drawAxisRelativeValues = (values: number[], sensor: SensorInfo) => {
    if (values.length === 1) {
        return toFixedSensorValue(values[0], sensor);
    }

    const deltaSymbol = "\u0394";

    return [
        deltaSymbol, t("axisL_ValueLabel"),
        " / ",
        deltaSymbol, t("axisT_ValueLabel"),
        " / ",
        deltaSymbol, t("axisZ_ValueLabel"),
        " (", t("Axis"), " ", drawUnitValues(values, sensor), ")"
    ].join('');
}


/**
 * Return true if this alarm about "no data" of sensor
 * @param alarm 
 */
export const isAlarmAboutNoData = (alarm: AlarmInfo) => {
    switch (alarm.AlarmType) {
        case AlarmType.AGMSAlarm:
            switch (alarm.AGMSAlarmType) {
                case NotificationsTypes.PrismNotMeasured:
                case NotificationsTypes.PrismNotAccurate:
                    return true;
                default:
                    return false;
            }
        case AlarmType.WDSensorAlarm:
            return true;
        default:
            return false;
    }
}

/**
 * Get common reports of sensors, which are in all sensors
 * TODO: covert with tests, it is important
 * @param points 
 */
export const getCommonSensorReports = (points: SensorBase[]): SensorReportInfo[] => {

    if (points.length === 0) {
        return [];
    }

    const reportsCounter = new Map<number, number>();
    const reportById = new Map<number, SensorReportInfo>();

    for (const sensor of points) {
        if (sensor.Reports.length === 0) {
            continue;
        }

        for (const report of sensor.Reports) {
            reportsCounter.set(report.ReportId, (reportsCounter.get(report.ReportId) || 0) + 1)

            if (!reportById.has(report.ReportId)) {
                reportById.set(report.ReportId, report);
            }
        }
    }

    const result: SensorReportInfo[] = [];
    reportsCounter.forEach((count, reportId) => {

        if (count >= points.length) {

            const report = reportById.get(reportId);
            if (report) {
                result.push(report);
            }
        }
    });

    return result;
}

const isColorDark = (color: string): boolean => {
    if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        const matchArray = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        if (matchArray && matchArray.length >= 3) {
            const r = parseInt(matchArray[1], 10);
            const g = parseInt(matchArray[2], 10);
            const b = parseInt(matchArray[3], 10);

            // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
            const hsp = Math.sqrt(
                0.299 * (r * r) +
                0.587 * (g * g) +
                0.114 * (b * b)
            );
            return hsp < 127.5;
        }
    }
    return false; // by default let color be "light"
}


export const alarmLinkThemeFunc = (alarm: AlarmInfo) => {
    const { Color } = alarm;
    return (currentTheme: any, themeProps: any) => {
        const { buttonStyles, ...rest } = currentTheme(themeProps);
        return {
            buttonStyles: {
                ...buttonStyles,
                color: isColorDark(Color) ? "white" : "black",
                background: `${Color} !important`,
                padding: '0'
            },
            ...rest,
        };
    }
}

export const sensorLinkThemeFunc = (currentTheme: any, themeProps: any) => {
    const { buttonStyles, ...rest } = currentTheme(themeProps);

    return {
        buttonStyles: {
            ...buttonStyles,
            padding: '0',
            margin: '0 0 0 -4px',
            maxWidth: 'unset'
        },
        ...rest
    };
}

export interface ILocalCoordsProps {
    xLengthReal: number;
    yLengthReal: number;
    pixelsPerMeter: number;
    xPixelsPerMeter: number;
    yPixelsPerMeter: number;
    deltaYAxis: number;
    deltaXAxis: number;
}

export const getProfileCoordsProps = (offsetsBounds: GeoJSONMapTilesLayerBounds): ILocalCoordsProps => {
    const xLengthReal = offsetsBounds.MaxX - offsetsBounds.MinX;
    const yLengthReal = offsetsBounds.MaxY - offsetsBounds.MinY;
    const maxDimension = Math.max(xLengthReal, yLengthReal);

    const pixelsPerMeter = Math.round(DXF_AVAILABLE_PIXELS_SIZE / maxDimension);

    const deltaYAxis = pixelsPerMeter * (offsetsBounds.MaxY - (yLengthReal / 2));

    const deltaXAxis = pixelsPerMeter * (offsetsBounds.MaxX - (xLengthReal / 2));

    return {
        xLengthReal,
        yLengthReal,
        pixelsPerMeter,
        xPixelsPerMeter: Math.round(DXF_AVAILABLE_PIXELS_SIZE / xLengthReal),
        yPixelsPerMeter: Math.round(DXF_AVAILABLE_PIXELS_SIZE / yLengthReal),
        deltaXAxis,
        deltaYAxis
    }
}

export const getProfileViewGeoLocation = (profileCoordsProps: ILocalCoordsProps, locX: number, locY: number, actualAxisReference: SensorAttribyteEntry) => {
    if (AuthService.isNagraDistribution()) {
        const longitude = PROFILE_VIEW_LONGITUDE_OFFSET + locY * profileCoordsProps.pixelsPerMeter;
        const latitude = -PROFILE_VIEW_LATITUDE_OFFSET * (profileCoordsProps.xLengthReal / profileCoordsProps.yLengthReal) + locX * profileCoordsProps.pixelsPerMeter;

        return ({
            Longitude: longitude,
            Latitude: latitude,
            Height: 0
        });
    }

    if (!actualAxisReference) {
        return ({
            Longitude: 0.0,
            Latitude: 0.0,
            Height: 0.0
        })
    }

    const value0 = actualAxisReference.values.length > 1 ? actualAxisReference.values[1] : 0.0;
    const value2 = actualAxisReference.values.length > 2 ? actualAxisReference.values[2] : 0.0;

    return ({
        Longitude: PROFILE_VIEW_LONGITUDE_OFFSET - profileCoordsProps.deltaXAxis + value0 * profileCoordsProps.pixelsPerMeter, // X-coordinate
        Latitude: -PROFILE_VIEW_LATITUDE_OFFSET - profileCoordsProps.deltaYAxis + value2 * profileCoordsProps.pixelsPerMeter, // Y-coordinate
        Height: 0
    });

}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getLocXYLocation = (si: SensorBase, offsetsBounds?: GeoJSONMapTilesLayerBounds, leafletElement?: L.Map): GeoLocation => {
    if (!offsetsBounds) {
        return ({
            Latitude: si.LocX,
            Longitude: si.LocY,
            Height: 0
        })
    }

    const profileCoordsProps = getProfileCoordsProps(offsetsBounds);
    return getProfileViewGeoLocation(profileCoordsProps, si.LocX, si.LocY, si.ActualAxisReference);
};

// export interface ISideViewCoordsProps extends ILocalCoordsProps {
//     deltaYAxis: number;
// }

export const getSideViewCoordsProps = (offsetsBounds: GeoJSONMapTilesLayerBounds): ILocalCoordsProps => {
    const xLengthReal = offsetsBounds.MaxX - offsetsBounds.MinX;
    const yLengthReal = offsetsBounds.MaxY - offsetsBounds.MinY;

    const maxDimension = Math.max(xLengthReal, yLengthReal);

    const pixelsPerMeter = DXF_AVAILABLE_PIXELS_SIZE / maxDimension;

    const xPixelsPerMeter = DXF_AVAILABLE_PIXELS_SIZE / xLengthReal;
    const yPixelsPerMeter = DXF_AVAILABLE_PIXELS_SIZE / yLengthReal;

    const deltaYAxis = pixelsPerMeter * (offsetsBounds.MaxY - (yLengthReal / 2));
    const deltaXAxis = pixelsPerMeter * (offsetsBounds.MaxX - (xLengthReal / 2));

    return {
        xLengthReal,
        yLengthReal,
        pixelsPerMeter,
        xPixelsPerMeter,
        yPixelsPerMeter,
        deltaYAxis,
        deltaXAxis
    }
}

export const getSideViewGeoLocation = (coordsProps: ILocalCoordsProps, locX: number, locY: number, actualAxisReference: SensorAttribyteEntry, invertXAxis: boolean, offsetsBounds: GeoJSONMapTilesLayerBounds) => {
    if (AuthService.isNagraDistribution()) {

        let xCoord = locY - offsetsBounds.MinX;
        const yCoord = locX;

        if (invertXAxis) {
            xCoord = -locY - offsetsBounds.MaxX;
            return ({
                Longitude: SIDE_VIEW_NAGRA_INVERT_AXIS_LONGITUDE_OFFSET - Math.abs(xCoord) * coordsProps.pixelsPerMeter,
                Latitude: -SIDE_VIEW_LATITUDE_OFFSET - coordsProps.deltaYAxis + yCoord * coordsProps.pixelsPerMeter,
                Height: 0
            });
        }
        return ({
            Longitude: SIDE_VIEW_NAGRA_LONGITUDE_OFFSET + xCoord * coordsProps.pixelsPerMeter,
            Latitude: -SIDE_VIEW_LATITUDE_OFFSET - coordsProps.deltaYAxis + yCoord * coordsProps.pixelsPerMeter,
            Height: 0
        });
    }

    return ({
        Longitude: SIDE_VIEW_LONGITUDE_OFFSET - coordsProps.deltaXAxis + actualAxisReference.values[0] * coordsProps.pixelsPerMeter, // X-coord
        Latitude: -SIDE_VIEW_LATITUDE_OFFSET - coordsProps.deltaYAxis + actualAxisReference.values[2] * coordsProps.pixelsPerMeter, // Y-coord
        Height: 0
    });
}

export const getSideViewCoordinates = (si: SensorBase, invertXAxis: boolean, offsetsBounds?: GeoJSONMapTilesLayerBounds): GeoLocation => {
    if (!offsetsBounds) {
        return ({
            Latitude: si.LocX,
            Longitude: si.TunnelMeter,
            Height: 0
        })
    }

    const coordsProps = getSideViewCoordsProps(offsetsBounds);
    return getSideViewGeoLocation(coordsProps, si.LocX, si.TunnelMeter, si.ActualAxisReference, invertXAxis, offsetsBounds);
}

export const getMandatoryOrientation = (closeToBorderSensorsIds: ICloseToBorderSensorsIds | undefined, info: SensorBase): SensorMapTextOrientation | undefined => {
    let mandatoryOrientation = info.MapTextOrientation;

    if (!closeToBorderSensorsIds) {
        return undefined;
    }

    if (closeToBorderSensorsIds.bottom.includes(info.Id)) {
        switch (mandatoryOrientation) {
            case SensorMapTextOrientation.South: mandatoryOrientation = SensorMapTextOrientation.North; break;
            case SensorMapTextOrientation.East:
            case SensorMapTextOrientation.SouthEast: mandatoryOrientation = SensorMapTextOrientation.NorthEast; break;
            case SensorMapTextOrientation.West:
            case SensorMapTextOrientation.SouthWest: mandatoryOrientation = SensorMapTextOrientation.NorthWest; break;
        }
    }

    if (closeToBorderSensorsIds.top.includes(info.Id)) {
        switch (mandatoryOrientation) {
            case SensorMapTextOrientation.North: mandatoryOrientation = SensorMapTextOrientation.South; break;
            case SensorMapTextOrientation.East:
            case SensorMapTextOrientation.NorthEast: mandatoryOrientation = SensorMapTextOrientation.SouthEast; break;
            case SensorMapTextOrientation.West:
            case SensorMapTextOrientation.NorthWest: mandatoryOrientation = SensorMapTextOrientation.SouthWest; break;
        }
    }

    if (closeToBorderSensorsIds.left.includes(info.Id)) {
        switch (mandatoryOrientation) {
            case SensorMapTextOrientation.West: mandatoryOrientation = SensorMapTextOrientation.East; break;
            case SensorMapTextOrientation.North:
            case SensorMapTextOrientation.NorthWest: mandatoryOrientation = SensorMapTextOrientation.NorthEast; break;
            case SensorMapTextOrientation.South:
            case SensorMapTextOrientation.SouthWest: mandatoryOrientation = SensorMapTextOrientation.SouthEast; break;
        }
    }

    if (closeToBorderSensorsIds.right.includes(info.Id)) {
        switch (mandatoryOrientation) {
            case SensorMapTextOrientation.East: mandatoryOrientation = SensorMapTextOrientation.West; break;
            case SensorMapTextOrientation.North:
            case SensorMapTextOrientation.NorthEast: mandatoryOrientation = SensorMapTextOrientation.NorthWest; break;
            case SensorMapTextOrientation.South:
            case SensorMapTextOrientation.SouthEast: mandatoryOrientation = SensorMapTextOrientation.SouthWest; break;
        }
    }

    return mandatoryOrientation;
}