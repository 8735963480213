// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { Item, MenuSection } from '@atlaskit/navigation-next';
import { useState } from "react";
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import Routes from '../../helpers/Routes';
import i18next from '../../i18n';
import AuthService from '../../services/AuthService';
import { BackButtonItem } from './BackButtonItem';

interface IComponentState {
    prevRouteIndex: number;
}

const UserProfileNavigation = () => {

    const history = useHistory();
    const [state] = useState<IComponentState>({ prevRouteIndex: history.length - 1 });

    const isUserProfile = useRouteMatch(Routes.userProfile);
    const isChangePassword = useRouteMatch(Routes.changePassword);

    return (
        <MenuSection>
            {({ className }: any) => (
                <div className={className} style={{ marginTop: '15px' }}>
                    <BackButtonItem
                        history={history}
                        prevRouteIndex={state.prevRouteIndex} />
                    <Link
                        to={Routes.userProfile.path}
                        style={{ textDecoration: 'none' }}>
                        <Item
                            key="profile"
                            id="profile"
                            isSelected={isUserProfile !== null}
                            text={i18next.t("Profile")}
                        />
                    </Link>
                    {!AuthService.isChangePasswordForbidden() && (
                        <Link
                            to={Routes.changePassword.path}
                            style={{ textDecoration: 'none' }}>
                            <Item
                                key="change_password"
                                id="change_password"
                                isSelected={isChangePassword !== null}
                                text={i18next.t("Change password")}
                            />
                        </Link>
                    )}
                </div>
            )}
        </MenuSection>
    );
}

export default UserProfileNavigation;