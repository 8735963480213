/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 15.08.2023
 * @description Geovis image map layer
 */

import { useMap } from "react-leaflet";
import { useEffect } from "react";
import { IGeovisImageReportInfo } from "../../../store/projectReport.types";
import { getDeltaCoefficient, getDxfImageScale } from "../../imageMap/tools";
import { getMapAreaOfGeovisImage } from "../reportOverlay/reportRender/geovisImage/tools";
import { getMapSectionViewLegend } from "../../map/Legend";
import { ProjectVisualSettings } from "../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";

interface IComponentProps {
    geovisImageInfo: IGeovisImageReportInfo;
    rootContainer: HTMLDivElement;
    visualSettings: ProjectVisualSettings;
}

export const GeovisImageMapLegend = ({ rootContainer, geovisImageInfo: { GeovisImage }, visualSettings }: IComponentProps) => {


    const map = useMap();

    const drawLegend = () => {

        const mapArea = getMapAreaOfGeovisImage(GeovisImage);
        const deltaCoefficient = getDeltaCoefficient(rootContainer, mapArea, GeovisImage.ImageInfo.FileType) ?? 1;

        const { MovementSettings } = GeovisImage;
        // const isDxf = ImageInfo.FileType === ImageBackgroundFileType.DXF;

        if (MovementSettings.ShowScaleLegend) {

            const vectorScalingRate = MovementSettings.VectorMovementSettings.ScalingRate;
            const heightScalingRate = MovementSettings.HeightBarsMovementSettings.ScalingRate;
            // const scaleX = isDxf ? getDxfImageScale(leafletElement, vectorScalingRate, 50) : getImageXScale(vectorScalingRate, coefficient);
            const scaleX = getDxfImageScale(map, vectorScalingRate, 50, deltaCoefficient);
            // const scaleY = getImageYScale(heightScalingRate, coefficient);
            const scaleY = getDxfImageScale(map, heightScalingRate, 40, deltaCoefficient);

            const legend = getMapSectionViewLegend({
                showArrowItem: MovementSettings.ShowMovementVector,
                arrowLength: scaleX.pixels,
                arrowDesc: scaleX.label,

                showBarItem: MovementSettings.ShowMovementHeightBars,
                barHeight: scaleY.pixels,
                barDesc: scaleY.label,

                arrowColor: visualSettings.movementVectorColor,
                barNegativeColor: visualSettings.negativeHeightBarColor,
                barPositiveColor: visualSettings.positiveHeightBarColor
            });

            if (legend) {
                map.addControl(legend);
            }
        }
    }

    useEffect(() => {

        drawLegend();

    }, [map]);

    return (null);
}