/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.04.2022
 * @description Common types for DTS chart
 */

import { SensorCategory } from "../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";

export const dtsChartYAxisSensorTypes: ReadonlyArray<SensorCategory> = [
    SensorCategory.Unknown,
    SensorCategory.Temperature,
    SensorCategory.Stoke,
    SensorCategory.Attenuation,
    SensorCategory.NormalizedTemperature,
    SensorCategory.ThermalConductivity
];

export const dtsChartXAxisSensorTypes: ReadonlyArray<SensorCategory> = [
    SensorCategory.TM,
    SensorCategory.DTS_Position
];