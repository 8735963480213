import Button from '@atlaskit/button';
import { HeadType, RowType } from '@atlaskit/dynamic-table/dist/types/types';
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Textfield from '@atlaskit/textfield';
import { debounce } from 'lodash';
import { Fragment, SyntheticEvent, useEffect, useState } from 'react';

import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import { fetchServerElements } from "../../../helpers/ProjectDataHelper";
import Route from '../../../helpers/Route';
import { t } from "../../../i18n";
import { ChartType } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { ProjectElementType } from '../../../server/AVTService/TypeLibrary/Common/ProjectElementType';
import { getSensorCategoryName } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { GeovisProjectElementEntryModel } from "../../../server/GEOvis3/Model/ProjectElements/GeovisProjectElementEntryModel";
import ServerRoutesGen from "../../../server/Routes/ServerRoutesGen";
import { IRequestHelper } from "../../../services/RequestHelper";
import { defaultSomethingStorageState, errorSomethingStorageState, ISomethingStoreBase, loadedSomethingStorageState } from "../../../store/types";
import { GeovisTableWithSelectableRows } from '../../DynamicTableWithSelectableRows';

interface IComponentProps {
    chartType?: ChartType;
    projectElementType: ProjectElementType;
    Server: IRequestHelper;
    projectId: number;
    onSelectTemplate: (templateId: number) => void;
    onClose: () => void;
}

interface IComponentState extends ISomethingStoreBase {
    templates: GeovisProjectElementEntryModel[];
    selectedTemplateId: number
}

export const SelectTemplateDialog = ({
    onClose,
    onSelectTemplate,
    Server,
    chartType,
    projectId,
    projectElementType
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        ...defaultSomethingStorageState,
        selectedTemplateId: 0,
        templates: []
    })

    const [searchString, setSearchString] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect(() => {
        (async function loadTemplates() {

            let url: Route;

            const chartTypeNorm = chartType ?? ChartType.TimeValue;

            switch (projectElementType) {
                case ProjectElementType.Chart:
                case ProjectElementType.DefaultChart:
                case ProjectElementType.GeovisChart:
                case ProjectElementType.CompanyChartTemplate:
                case ProjectElementType.ProjectChartTemplate:
                    url = ServerRoutesGen.GeovisTemplates.GetTemplatesForChart.patch(projectId, +chartTypeNorm, projectElementType === ProjectElementType.DefaultChart);
                    break;
                case ProjectElementType.Comment:
                case ProjectElementType.CompanyCommentTemplate:
                case ProjectElementType.ProjectCommentTemplate:
                    url = ServerRoutesGen.GeovisTemplates.GetTemplatesForComments.patch(projectId);
                    break;
                case ProjectElementType.GeovisImage:
                    url = ServerRoutesGen.GeovisTemplates.GetTemplatesForImage.patch(projectId);
                    break;
                case ProjectElementType.GeovisReport:
                case ProjectElementType.Report:
                    url = ServerRoutesGen.GeovisTemplates.GetTemplatesForReport.patch(projectId);
                    break;
                case ProjectElementType.Geovis4Table:
                    url = ServerRoutesGen.GeovisTemplates.GetTemplatesForTables.patch(projectId);
                    break;
                default:
                    return;
            }

            const response = await fetchServerElements<GeovisProjectElementEntryModel[]>(Server, url);

            if (!response.Success) {
                setState({
                    ...errorSomethingStorageState(response.Messages.join('; ')),
                    selectedTemplateId: 0,
                    templates: []
                })
                return;
            }

            setState({
                ...loadedSomethingStorageState,
                selectedTemplateId: 0,
                templates: response.Data
            })
        })();
    }, [1])



    const onTemplateSelected = () => {
        onSelectTemplate(state.selectedTemplateId)
    }

    const getTableHead = (): HeadType => {
        const result: HeadType = {
            cells: [{
                key: 'selected',
                content: t("Selected")
            }, {
                key: 'nameCol',
                content: t("Name"),
                isSortable: true
            }, {
                key: 'titleCol',
                content: t("Title"),
                isSortable: true
            }, {
                key: 'owner',
                content: t("Owner"),
                isSortable: true
            }]
        }

        if (projectElementType === ProjectElementType.GeovisReport) {
            result.cells.pop();
        }

        if (projectElementType === ProjectElementType.Chart || projectElementType === ProjectElementType.DefaultChart) {
            result.cells.splice(3, 0, {
                key: 'sensorType',
                content: t("Sensor Type"),
                isSortable: true
            });
        }

        return result
    }

    const getIdFunc = (item: GeovisProjectElementEntryModel): string => {
        return item.Id.toString();
    }

    const getOwnerString = (elType: ProjectElementType): string => {
        switch (elType) {
            case ProjectElementType.CompanyChartTemplate:
            case ProjectElementType.CompanyCommentTemplate:
            case ProjectElementType.CompanyGeovisImageTemplate:
            case ProjectElementType.GeovisReport:
            case ProjectElementType.CompanyGeovis4TableTemplate:
                return t("Company");
            case ProjectElementType.ProjectChartTemplate:
            case ProjectElementType.ProjectCommentTemplate:
            case ProjectElementType.ProjectGeovisImageTemplate:
            case ProjectElementType.ProjectGeovis4TableTemplate:
                return t("Project");
            default:
                return "";
        }
    }

    const itemToRowFunc = (item: GeovisProjectElementEntryModel): RowType => {

        const ownerString = getOwnerString(item.Type);

        const result: RowType = {
            key: `row-${item.Id}`,
            cells: [{
                key: item.Name,
                content: (
                    <span>{item.Name}</span>
                )
            }, {
                key: item.Title,
                content: (
                    <span>{item.Title}</span>
                )
            }, {
                key: ownerString,
                content: (
                    <span>{ownerString}</span>
                )
            }]
        }

        if (projectElementType === ProjectElementType.Chart || projectElementType === ProjectElementType.DefaultChart) {
            const sensorType = getSensorCategoryName(item.MainAxisSensorType);

            result.cells.splice(2, 0, {
                key: sensorType,
                content: (
                    <span>{sensorType}</span>
                )
            });
        }

        return result;
    }

    const onSelectedTemplateChanged = (selection: GeovisProjectElementEntryModel[]) => {
        if (selection.length === 0) {
            setState({ ...state, selectedTemplateId: 0 });
            return;
        }
        setState({ ...state, selectedTemplateId: selection[selection.length - 1].Id });
    }

    const getSelectedTemplate = (): GeovisProjectElementEntryModel[] => {
        const result = state.templates.filter(template => template.Id === state.selectedTemplateId);
        return result;
    }

    const templateMeetsTheCondition = (template: GeovisProjectElementEntryModel/*, searchQuery: string*/): boolean => {
        if (!searchQuery || !template) {
            return true;
        }

        const lowerSearch = searchQuery.toLowerCase();

        const nameMatch = template.Name !== undefined && template.Name !== null && template.Name.toLowerCase().includes(lowerSearch);
        const titleMatch = template.Title !== undefined && template.Title !== null && template.Title.toLowerCase().includes(lowerSearch);

        const sensorType = getSensorCategoryName(template.MainAxisSensorType);
        const sensorTypeMatch = sensorType.toLowerCase().includes(lowerSearch);

        const owner = getOwnerString(template.Type);
        const ownerMatch = owner.toLowerCase().includes(lowerSearch);

        return titleMatch || nameMatch || sensorTypeMatch || ownerMatch;
    }

    const getRows = (templates: GeovisProjectElementEntryModel[]/*, searchQuery: string*/): GeovisProjectElementEntryModel[] => {
        return searchQuery ? templates.filter(template => templateMeetsTheCondition(template/*, searchQuery*/)) : templates;
    }

    const onSearchStringChanged = (query: string) => { setSearchQuery(query); }
    const onSearchDebounce = debounce((query: string) => onSearchStringChanged(query), 500);
    const onSearchWrapped = (event: SyntheticEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        setSearchString(value);
        onSearchDebounce(value);
    }

    const onClearSearch = () => {
        setSearchString('');
        setSearchQuery('');
    }

    const getHeader = (elType: ProjectElementType): string => {
        switch (elType) {
            case ProjectElementType.CompanyChartTemplate:
            case ProjectElementType.ProjectChartTemplate:
            case ProjectElementType.GeovisChart:
            case ProjectElementType.DefaultChart:
                return `${t("Select chart template")}`;
            case ProjectElementType.CompanyCommentTemplate:
            case ProjectElementType.ProjectCommentTemplate:
            case ProjectElementType.Comment:
                return `${t("Select comment template")}`;
            case ProjectElementType.CompanyGeovisImageTemplate:
            case ProjectElementType.ProjectGeovisImageTemplate:
            case ProjectElementType.GeovisImage:
                return `${t("Select image template")}`;
            case ProjectElementType.GeovisReport:
                return `${t("Select report template")}`;
            case ProjectElementType.Geovis4Table:
            case ProjectElementType.ProjectGeovis4TableTemplate:
            case ProjectElementType.CompanyGeovis4TableTemplate:
                return `${t("Select table template")}`;
            default:
                return "";
        }
    }

    return (
        <ModalTransition>
            <Modal
                actions={[
                    { text: t("Select"), onClick: onTemplateSelected, appearance: 'primary', isDisabled: state.selectedTemplateId === 0 },
                    { text: t("Close"), onClick: onClose },
                ]}
                heading={getHeader(projectElementType)}
                height={'50%'}
                width='50%'
            >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {!state.isError && (
                        <Fragment>
                            <div>
                                <Textfield
                                    placeholder={t("Search")}
                                    isCompact={true}
                                    // defaultValue={searchString}
                                    value={searchString}
                                    onChange={onSearchWrapped}
                                    style={{ width: '150px' }}
                                    elemAfterInput={
                                        <Button
                                            iconBefore={<SelectClearIcon label={''} size='small' />}
                                            spacing='compact'
                                            appearance='subtle-link'
                                            onClick={onClearSearch} />
                                    }
                                />
                            </div>

                            <GeovisTableWithSelectableRows
                                isLoading={state.isLoading}
                                allItems={getRows(state.templates/*, searchQuery*/)}
                                getIdFunc={getIdFunc}
                                itemToRowFunc={itemToRowFunc}
                                needSelectAll={false}
                                onSelectionChanged={onSelectedTemplateChanged}
                                selectedItems={getSelectedTemplate()}
                                rowsPerPage={15}
                                head={getTableHead()}
                                showPagination={true}
                                showCountRowsSelect={false}
                            />
                        </Fragment>
                    )}
                    {state.isError &&
                        <span>{state.errorDescription}</span>
                    }
                </div>
            </Modal>
        </ModalTransition>
    )

}