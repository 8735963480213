/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 27.05.2022
 * @description Report header render data layer (load data for header)
 */

import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../../helpers/GeovisHooks';
import { fetchServerElements } from '../../../../../helpers/ProjectDataHelper';
import { GeovisHeaderModel } from '../../../../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisHeaderModel';
import { DataActionResponse } from '../../../../../server/DataActionResponse';
import ServerRoutesGen from '../../../../../server/Routes/ServerRoutesGen';
import { projectReportHeaderLoaded, projectReportHeaderLoading } from '../../../../../store/creators/reportRenderCreators';
import { IGeovisStoreState } from '../../../../../store/store.types';
import { IGeovisAction } from '../../../../../store/types';
import { IReportElementRenderOwnProps } from '../types';

interface IStateToProps {
    projectId: number;
}

interface IDispatchProps {
    onHeaderLoading: () => void;
    onHeaderLoaded: (reportHeaderInfo: DataActionResponse<GeovisHeaderModel>) => void;
}

type IOwnProps = Omit<IReportElementRenderOwnProps, 'isPrinting' | 'isDefault' | 'pageNum' | 'eventInfo' | 'isVibrationEventChart'>

interface IComponentProps extends IStateToProps, IDispatchProps, IOwnProps, IWithGeovisServerProps {

}

const Component = ({
    onHeaderLoaded,
    onHeaderLoading,
    projectId,
    reportId,
    userId,
    userToken,
    Server
}: IComponentProps) => {

    useEffect(() => {
        (async function loadData() {

            onHeaderLoading();

            const url = userId && userToken
                ? ServerRoutesGen.ReportPdfRenderData.GetHeaderInfo.patch(projectId, userToken, userId, reportId)
                : ServerRoutesGen.ReportRenderData.GetHeaderInfo.patch(projectId, reportId);

            const response = await fetchServerElements<GeovisHeaderModel>(Server, url)

            onHeaderLoaded(response);

        })();
    }, [reportId]);

    return (
        <div style={{ display: 'none' }}>
            {/* report header data layer */}
        </div>
    )
}

const mapStateToProps = ({ data }: IGeovisStoreState): IStateToProps => ({
    projectId: data.projectInfo.project.Id
})

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDispatchProps => ({
    onHeaderLoading: () => dispatch(projectReportHeaderLoading()),
    onHeaderLoaded: response => dispatch(projectReportHeaderLoaded(response))
});

const ComponentConnected = connect<IStateToProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(withGeovisServer(Component));

export default ComponentConnected;