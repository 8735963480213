import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Spinner from '@atlaskit/spinner';
import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { fetchServerElements } from "../../../../helpers/ProjectDataHelper";
import Route from "../../../../helpers/Route";
import { t } from "../../../../i18n";
import { DataActionResponse } from "../../../../server/DataActionResponse";
import { ProjectElementsMigrationsInfo } from "../../../../server/GEOvis3/Model/ElementsMigrations/ProjectElementsMigrationsInfo";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import FlagService from "../../../../services/FlagService";
import { elementsMigrationsHideMigrateAll, elementsMigrationsProjectsLoaded } from "../../../../store/creators/elementsMigrationsCreator";
import { IGeovisStoreState } from "../../../../store/store.types";
import { IGeovisAction } from "../../../../store/types";
import { ChangeAllProjectsType } from "./types";

interface IDialogStateProps {
    showDialog: boolean;
    migrationType: ChangeAllProjectsType
}

interface IDialogDispatchProps {
    onClose: () => void;
    onUpdateProjects: (data: DataActionResponse<ProjectElementsMigrationsInfo[]>) => void;
}

interface IDialogProps extends IDialogStateProps, IDialogDispatchProps, IWithGeovisServerProps {

}

interface IDialogState {
    migrated: boolean;
    migrating: boolean;
    migrationError: boolean;
    errorText: string;
}

const ProjectElementsMigrateAllDialog = ({ Server, onClose, showDialog, migrationType, onUpdateProjects }: IDialogProps) => {

    const [state, setState] = useState<IDialogState>({ errorText: '', migrated: false, migrating: false, migrationError: false });

    if (!showDialog) {
        return null;
    }

    const { errorText, migrated, migrating, migrationError } = state;

    const getDialogText = (): string => {
        if (migrated) {
            switch (migrationType) {
                case "migrate": return t("Migration complete!");
                case "removeOld": return t("Removing old elements complete!");
                case "rollback": return t("Rollback complete!");
            }
        }
        else if (migrationError) {
            return errorText;
        }
        else {
            switch (migrationType) {
                case "migrate": return t("Are you actually want to migrate elements for all of projects?");
                case "removeOld": return t("Are you actually want to remove old elements for all of projects?");
                case "rollback": return t("Are you actually want to rollback new elements for all of projects?");
            }

        }
    }

    const getHeader = (): string => {
        switch (migrationType) {
            case "migrate": return t("Migrate all project's elements");
            case "removeOld": return t("Remove all old project's elements");
            case "rollback": return t("Rollback all project's elements");
        }
    }

    const getMainButtonName = (): string => {
        switch (migrationType) {
            case "migrate": return t("Migrate");
            case "removeOld": return t("Remove old");
            case "rollback": return t("Rollback");
        }
    }

    const getSpinnerText = (): string => {
        switch (migrationType) {
            case "migrate": return t("Migrating");
            case "removeOld": return t("Removing");
            case "rollback": return t("Rolling back");
        }
    }

    const getUrl = (): Route => {
        switch (migrationType) {
            case "migrate": return ServerRoutesGen.Migrations.MigrateProjects;
            case "removeOld": return ServerRoutesGen.Migrations.RemoveOldForAllProjects;
            case "rollback": return ServerRoutesGen.Migrations.RollbackProjects;
        }
    }

    const onMigrateHandler = async () => {

        setState({ ...state, migrating: true });

        const url = getUrl();
        const response = await fetchServerElements<ProjectElementsMigrationsInfo[]>(Server, url);

        if (!response.Success) {
            FlagService.addError("Error to process request", response.Messages.join("; "))
            setState({ ...state, migrating: false, migrationError: true, errorText: response.Messages.join(', ') });
            return;
        }

        setState({ errorText: '', migrated: true, migrating: false, migrationError: false });
        onUpdateProjects(response);
    }

    const onCloseHandler = () => {
        setState({ errorText: '', migrated: false, migrating: false, migrationError: false });
        onClose();
    }

    return (
        <ModalTransition>
            <Modal
                heading={getHeader()}
                height={migrating ? '200px' : '180px'}
                actions={[
                    { text: getMainButtonName(), onClick: onMigrateHandler, appearance: 'primary', isDisabled: (migrating || migrated) },
                    { text: t("Close"), onClick: onCloseHandler, appearance: 'primary', isDisabled: migrating },
                ]}
            >
                <div>
                    {migrating &&
                        <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', flexDirection: 'row', overflow: 'hidden', alignItems: 'center' }}>
                            <Spinner size="large" testId={"spinnerTestId"} />
                            <span>{getSpinnerText()}</span>
                        </div>
                    }
                    {!migrating && getDialogText()}
                </div>
            </Modal>
        </ModalTransition>
    )
}

const mapStateToProps = ({ projectElementsMigrations }: IGeovisStoreState): IDialogStateProps => ({
    showDialog: projectElementsMigrations.showMigrateAllDialog,
    migrationType: projectElementsMigrations.migrationType
})

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDialogDispatchProps => ({
    onUpdateProjects: data => dispatch(elementsMigrationsProjectsLoaded(data)),
    onClose: () => dispatch(elementsMigrationsHideMigrateAll())

})

export default connect<IDialogStateProps, IDialogDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(ProjectElementsMigrateAllDialog))