import {
    circleIconPrimitive,
    svgIconTextDiv,
    svgIconTextOnlyDiv,
    dotIconPrimitive
} from './IconPrimitives'
import '../../libs/svg.icon.customRenderer';
import { ProjectStateIconRenderer } from './ProjectStateIconRenderers';
import { MultipleSensorsIconRenderer } from './MultipleSensorsIconRenderer';
import {
    UnknownIconRenderer,
    TheodoliteIconRenderer,
    ExtensometerIconRenderer,
    InclinometerIconRenderer,
    TemperatureIconRenderer,
    PiezometerIconRenderer,
    AirPressureIconRenderer,
    WindSpeedIconRenderer,
    SolarRadiationIconRenderer,
    VIconRenderer,
    PfHIconRenderer,
    VoltageIconRenderer,
    CounterIconRenderer,
    CurrentLoopIconRenderer,
    LoadCellIconRenderer,
    VirtualSensorIconRenderer,
    DistanceIconRenderer,
    FlowIconRenderer,
    GHIconRenderer,
    GPSIconRenderer,
    HydrostaticLevelIconRenderer,
    NoiseIconRenderer,
    InclinometerChainPositionIconRender,
    PfVIconRenderer,
    PrismIconRenderer,
    RainmeterIconRenderer,
    SettlementIconRenderer,
    VibrationIconRenderer,
    VolumeIconRenderer,
    PHIconRenderer,
    OxygenIconRenderer,
    SensorSymbolIconRenderer,
    SbIconRenderer,
    MstIconRenderer,
    TextIconRenderer
} from './IconsRenderers';

L.DivIcon.SVGIcon.MapFlagSvgIcon = L.DivIcon.SVGIcon.extend({
    // _createPathDescription() {
    //     alert("_createPathDescription");
    //     return super._createPathDescription();
    // }
});

L.divIcon.svgIcon.mapFlagSvgIcon = function (options) {
    return new L.DivIcon.SVGIcon.MapFlagSvgIcon(options);
};

L.divIcon.svgIcon.projectStateIconExt = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, ProjectStateIconRenderer);
};


// project state icon only (the circle)
L.DivIcon.SVGIcon.ProjectStateIcon = L.DivIcon.SVGIcon.extend({
    _createCircle() {
        return circleIconPrimitive(this.options);
    },
    _createSVG() {
        var circle = this._createCircle();
        var className = this.options.className + "-svg";

        var style = "width:" + this.options.iconSize.x + "px; height:" + this.options.iconSize.y + "px;"

        var svg =
            '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="' + className + '" style="' + style + '">'
            + circle
            + '</svg>'

        if (!this.options.circleText) {
            return svg
        }

        svg += svgIconTextDiv({
            iconText: this.options.circleText,
            fontSize: this.options.fontSize,
            fontWeight: this.options.fontWeight,
            iconSize: this.options.iconSize,
            iconAnchor: this.options.iconAnchor,
            textOrientation: this.options.textOrientation || false,
            fontColor: this.options.fontColor || 'black'
        });

        return svg;
    }
});

L.divIcon.svgIcon.projectStateIcon = function (options) {
    return new L.DivIcon.SVGIcon.ProjectStateIcon(options);
}

// =====================================================================
// Stacked icon
L.DivIcon.SVGIcon.StackedIcon = L.DivIcon.SVGIcon.extend({
    _createCircle() {
        return circleIconPrimitive(this.options);
    },
    _createSVG() {
        var className = this.options.className + "-svg";

        var width = this.options.iconSize.x;
        var height = this.options.iconSize.y;

        var icons = this.options.icons;
        var style = `width:${width}px; height:${height}px`;
        var circles = [];

        for (let index = icons.length - 1; index >= 0; index--) {
            let circleProp = { ...icons[index] };

            let anchorX = circleProp.circleAnchor.x + index * 3;
            let anchorY = circleProp.circleAnchor.y + (icons.length - index - 1) * 3;

            let circleAnchor = L.point(anchorX, anchorY);
            circleProp.circleAnchor = circleAnchor;

            let circle = circleIconPrimitive(circleProp);
            circles.push(circle);
        }

        var svg = '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="' + className + '" style="' + style + '">'
            + circles.join(" ")
            + '</svg>';

        if (!this.options.iconsText || this.options.iconsText.length === 0) {
            return svg;
        }

        for (let iconText of this.options.iconsText) {
            svg += svgIconTextDiv({
                iconText: iconText,
                fontSize: this.options.fontSize,
                fontWeight: this.options.fontWeight,
                iconAnchor: this.options.iconAnchor,
                iconSize: this.options.iconSize,
                textOrientation: this.options.textOrientation || false,
                fontColor: this.options.fontColor || 'black',
                noScale: this.options.noScale || true
            });
        }

        return svg;
    }
})

L.divIcon.svgIcon.stackedIcon = function (options) {
    return new L.DivIcon.SVGIcon.StackedIcon(options);
}

// #region Icons creators with custom icon renderer

L.divIcon.svgIcon.stackedSensorsIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, StackedSensorsIconRenderer)
}

L.divIcon.svgIcon.multipleSensorsIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, MultipleSensorsIconRenderer);
}

L.divIcon.svgIcon.unknownIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, UnknownIconRenderer);
}

L.DivIcon.SVGIcon.TextIcon = L.DivIcon.SVGIcon.extend({
    _createDot() {
        return dotIconPrimitive(this.options);
    },
    _createSVG() {
        var circle = this._createDot();
        var className = this.options.className + "-svg";

        var style = "width:" + 0 + "px; height:" + 0 + "px; z-index: 0;"

        var svg =
            '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="' + className + '" style="' + style + '">'
            + circle
            + '</svg>'

        if (!this.options.iconText) {
            return svg
        }

        let textOrientation = false;

        if (this.options.textOrientation !== undefined) {
            textOrientation = this.options.textOrientation;
        }

        // var updIconSize = { ...this.options.iconSize, x: 16, y: 16 };

        svg += svgIconTextOnlyDiv({
            iconText: this.options.iconText,
            fontSize: this.options.fontSize,
            iconAnchor: this.options.iconAnchor,
            fontWeight: this.options.fontWeight,
            iconSize: this.options.iconCustomSize || new Point(0, 0),
            textOrientation,
            fontColor: this.options.fontColor || 'black',
            linesUnder: this.options.countLinesUnder || 0,
            lineOrder: this.options.lineOrder || 0,
            showBorder: this.options.showBorder || false,
            noScale: this.options.noScale || true,
            sensorSymbol: this.options.sensorSymbol,
            topAddition: this.options.topAddition || 0,
            leftAddition: this.options.leftAddition || 0
        });

        return svg;
    }
});

L.divIcon.svgIcon.textIcon = function (options) {
    return new L.DivIcon.SVGIcon.TextIcon(options);
}

L.divIcon.svgIcon.theodoliteSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, TheodoliteIconRenderer);
}

L.divIcon.svgIcon.extensometerSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, ExtensometerIconRenderer);
}

L.divIcon.svgIcon.inclinometerSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, InclinometerIconRenderer);
}


L.divIcon.svgIcon.temperatureSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, TemperatureIconRenderer);
}

L.divIcon.svgIcon.piezometerSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, PiezometerIconRenderer);
}

L.divIcon.svgIcon.airPressureSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, AirPressureIconRenderer);
}

L.divIcon.svgIcon.windSpeedSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, WindSpeedIconRenderer);
}

L.divIcon.svgIcon.solarRadiationIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, SolarRadiationIconRenderer);
}

L.divIcon.svgIcon.vSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, VIconRenderer);
}

L.divIcon.svgIcon.pfhSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, PfHIconRenderer);
}

L.divIcon.svgIcon.voltageIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, VoltageIconRenderer);
}


L.divIcon.svgIcon.counterSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, CounterIconRenderer);
}

L.divIcon.svgIcon.currentLoopIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, CurrentLoopIconRenderer);
}

L.divIcon.svgIcon.loadCellSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, LoadCellIconRenderer);
}

L.divIcon.svgIcon.virtualSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, VirtualSensorIconRenderer);
}

L.divIcon.svgIcon.distanceSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, DistanceIconRenderer);
}

L.divIcon.svgIcon.flowSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, FlowIconRenderer);
}

L.divIcon.svgIcon.ghSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, GHIconRenderer);
}

L.divIcon.svgIcon.gpsSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, GPSIconRenderer);
}

L.divIcon.svgIcon.hydrostaticLevelSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, HydrostaticLevelIconRenderer);
}

L.divIcon.svgIcon.noiseSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, NoiseIconRenderer);
}

L.divIcon.svgIcon.inclinometerChainPositionIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, InclinometerChainPositionIconRender);
}

L.divIcon.svgIcon.pfvSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, PfVIconRenderer);
}

L.divIcon.svgIcon.prismIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, PrismIconRenderer);
}

L.divIcon.svgIcon.rainmeterSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, RainmeterIconRenderer);
}

L.divIcon.svgIcon.settlementSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, SettlementIconRenderer);
}

L.divIcon.svgIcon.vibrationSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, VibrationIconRenderer);
}

L.divIcon.svgIcon.volumeSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, VolumeIconRenderer);
}

L.divIcon.svgIcon.pHSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, PHIconRenderer);
}

L.divIcon.svgIcon.oxygenLevelSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, OxygenIconRenderer);
}

L.divIcon.svgIcon.sensorSymbolIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, SensorSymbolIconRenderer);
}

L.divIcon.svgIcon.mstSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, MstIconRenderer);
}

L.divIcon.svgIcon.sbSensorIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, SbIconRenderer);
}

// #endregion