export const PROJECT_DTS_ADD_SECTION_DIALOG_SHOW = "PROJECT_DTS_ADD_SECTION_DIALOG_SHOW";
export const PROJECT_DTS_EDIT_SECTION_DIALOG_SHOW = "PROJECT_DTS_EDIT_SECTION_DIALOG_SHOW";
export const PROJECT_DTS_EDIT_SECTION_DIALOG_HIDE = "PROJECT_DTS_EDIT_SECTION_DIALOG_HIDE";

export const PROJECT_DTS_REMOVE_SECTION_DIALOG_SHOW = "PROJECT_DTS_REMOVE_SECTION_DIALOG_SHOW";
export const PROJECT_DTS_REMOVE_SECTION_DIALOG_HIDE = "PROJECT_DTS_REMOVE_SECTION_DIALOG_HIDE";

export const PROJECT_DTS_SECTION_INFO_LOADING = 'PROJECT_DTS_SECTION_INFO_LOADING';
export const PROJECT_DTS_SECTION_INFO_LOADED = 'PROJECT_DTS_SECTION_INFO_LOADED';
export const PROJECT_DTS_SECTION_INFO_LOADING_ERROR = 'PROJECT_DTS_SECTION_INFO_LOADING_ERROR';

export const PROJECT_DTS_T_SENSOR_INFOS_LOADING = "PROJECT_DTS_T_SENSOR_INFOS_LOADING";
export const PROJECT_DTS_T_SENSOR_INFOS_LOADED = "PROJECT_DTS_T_SENSOR_INFOS_LOADED";
export const PROJECT_DTS_T_SENSOR_INFOS_LOADING_ERROR = "PROJECT_DTS_T_SENSOR_INFOS_LOADING_ERROR";

export const PROJECT_DTS_SECTION_INFO_UPDATE_STATE = 'PROJECT_DTS_SECTION_INFO_UPDATE_STATE';
export const PROJECT_DTS_SECTION_TAB_CHANGED = "PROJECT_DTS_SECTION_TAB_CHANGED";

export const PROJECT_DTS_SECTION_PROPERTIES_CALCULATION_STARTED = "PROJECT_DTS_SECTION_PROPERTIES_CALCULATION_STARTED";
export const PROJECT_DTS_SECTION_PROPERTIES_CALCULATION_FINISHED = "PROJECT_DTS_SECTION_PROPERTIES_CALCULATION_FINISHED";

export const PROJECT_DTS_SECTION_T_SENSORS_SELECT_DIALOG_SHOW = "PROJECT_DTS_SECTION_T_SENSORS_SELECT_DIALOG_SHOW";
export const PROJECT_DTS_SECTION_T_SENSORS_SELECT_DIALOG_HIDE = "PROJECT_DTS_SECTION_T_SENSORS_SELECT_DIALOG_HIDE";
