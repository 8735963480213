//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum Distributors {
	Amberg = 0,
	Nagra = 1
}

const DistributorsToName = new Map<number, string>([
	[ 0, 'Amberg' ],
	[ 1, 'Nagra' ],
])

export function getDistributorsToName(element: Distributors) : string {
    return DistributorsToName.get(element) || '';
}

const DistributorsByName = new Map<string, number>([
	[ 'Amberg', 0 ],
	[ 'Nagra', 1 ],
]);

export function getDistributorsByName(name: string): Distributors {
    return DistributorsByName.get(name)!;
}


export const DistributorsList : Readonly<Distributors[]> = [
	Distributors.Amberg,
	Distributors.Nagra,
];



const DistributorsToDescription = new Map<number, string>([
	[ 0, 'Amberg Technologies AG' ],
	[ 1, 'NAGRA HOTBent' ],
])

export function getDistributorsToDescription(element: Distributors) : string {
    return DistributorsToDescription.get(element) || '';
}

