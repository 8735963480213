import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useRouteMatch } from "react-router-dom";
import { Dispatch } from 'redux';
import { LoadingPageErrorSkeleton } from "../../components/LoadingPageErrorSkeleton";
import { LoadingPageSkeleton } from "../../components/LoadingPageSkeleton";
import DefaultChartsRender from "../../components/projectOverview/reportOverlay/reportRender/DefaultChartsRender";
import DefaultReportsRender from "../../components/projectOverview/reportOverlay/reportRender/DefaultReportsRender";
import GeovisReportRender from "../../components/projectOverview/reportOverlay/reportRender/GeovisReportRender";
import { printPageWidthInPx } from "../../components/projectOverview/reportOverlay/reportRender/tools";
import { IWithGeovisServerProps, withGeovisServer } from "../../helpers/GeovisHooks";
import { IReportRenderRouteParams } from "../../helpers/IRouteParams";
import { fetchServerElements } from "../../helpers/ProjectDataHelper";
import Routes from "../../helpers/Routes";
import { GeovisReportModel } from "../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportModel";
import { DataActionResponse } from "../../server/DataActionResponse";
import { PdfReportInitialState } from "../../server/GEOvis3/Model/Reports/PdfReportInitialState";
import ServerRoutesGen from "../../server/Routes/ServerRoutesGen";
import AuthService from "../../services/AuthService";
import { geovisProjectReportPdfInitialState } from "../../store/creators/projectReportCreators.pdf";
import { IGeovisStoreState } from "../../store/store.types";
import {
    defaultSomethingStorageState,
    errorSomethingStorageState,
    IGeovisAction,
    ISomethingStoreBase,
    loadedSomethingStorageState
} from "../../store/types";

interface IPageStateProps {
    isDefaultReportView: boolean;
    isDefaultChartView: boolean;
    detailedReportInfo: GeovisReportModel;
}

interface IPageDispatchProps {
    onReportInitialStateLoaded: (pdfReportInitialState: DataActionResponse<PdfReportInitialState>) => void;
}

interface IPageProps extends IPageStateProps, IPageDispatchProps, IWithGeovisServerProps {

}

export const PRINT_PAGE_BLOCK_ID = 'print-page-block';
export const PRINT_CONTENT_BLOCK_ID = 'print-content-block';

/**
 * Component to draw reports for PDF export
 * @param param0 
 * @returns 
 */
const ReportRenderPage = ({ onReportInitialStateLoaded, Server, isDefaultChartView, isDefaultReportView }: IPageProps) => {

    const routeParams = useRouteMatch<IReportRenderRouteParams>(Routes.geovisReportPdfPage);

    if (!routeParams) {
        return (
            <div>
                Wrong URL
            </div>
        )
    }

    const [localState, setLocalState] = useState<ISomethingStoreBase>(defaultSomethingStorageState);

    const { params: { projectId, reportId, settingsId, userId, userToken } } = routeParams;

    if (!reportId) {
        return (
            <div>
                Wrong URL ( no report ID was found)
            </div>
        )
    }

    useEffect(() => {
        (async function loadReportInitialData() {

            const url = +reportId > 0
                ? ServerRoutesGen.ReportPdfRenderData.GetPdfReportInitialState.patch(projectId, reportId, userId, userToken, settingsId)
                : ServerRoutesGen.ReportPdfRenderData.GetPdfDefaultReportInitialState.patch(projectId, userId, userToken, settingsId);

            const response = await fetchServerElements<PdfReportInitialState>(Server, url);

            if (!response.Success) {
                setLocalState(errorSomethingStorageState(...response.Messages));

                // this message for Puppeteer
                // eslint-disable-next-line no-console
                console.error('REPORT ERROR: ' + response.Messages.join('; '));
                return;
            }

            await AuthService.lazyLoadServerInfoByUrl(ServerRoutesGen.ReportPdfRenderData.GetServerInfo.patch(userToken, userId));

            onReportInitialStateLoaded(response);
            setLocalState(loadedSomethingStorageState);
        })();
    }, [])


    // TODO: add loading sensors symbols if not loaded and if any map sections are there

    if (localState.isLoading) {
        return (
            <LoadingPageSkeleton loadingText="Loading PDF report info" />
        )
    }

    if (localState.isError) {
        return (
            <LoadingPageErrorSkeleton errorText="PDF report error" errorDescription={localState.errorDescription} />
        )
    }

    if (isDefaultChartView) {
        return (
            <div id={PRINT_PAGE_BLOCK_ID} style={{ margin: '0 auto', width: `${printPageWidthInPx}px` }}>
                <div id={PRINT_CONTENT_BLOCK_ID}>
                    <DefaultChartsRender
                        isPrinting={true}
                        userId={userId}
                        userToken={userToken}
                    />
                </div>
            </div>
        )
    }

    if (isDefaultReportView) {
        return (
            <div id={PRINT_PAGE_BLOCK_ID} style={{ margin: '0 auto', width: `${printPageWidthInPx}px` }}>
                <div id={PRINT_CONTENT_BLOCK_ID}>
                    <DefaultReportsRender
                        isPrinting={true}
                        userId={userId}
                        userToken={userToken}
                    />
                </div>
            </div>
        )
    }

    return (
        <div id={PRINT_PAGE_BLOCK_ID} style={{ margin: '0 auto', width: `${printPageWidthInPx}px` }}>
            <div id={PRINT_CONTENT_BLOCK_ID}>
                <GeovisReportRender
                    userId={userId}
                    userToken={userToken}
                    isPrinting={true}
                    projectId={+projectId!}
                    settingsId={settingsId}
                />
            </div>
        </div>
    )
}

const mapStateToProps = ({ projectReport: { geovisReportSettings } }: IGeovisStoreState): IPageStateProps => ({
    detailedReportInfo: geovisReportSettings.detailedReportInfo,
    isDefaultChartView: geovisReportSettings.isDefaultChartView,
    isDefaultReportView: geovisReportSettings.isDefaultReportView
})

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IPageDispatchProps => ({
    onReportInitialStateLoaded: initialState => dispatch(geovisProjectReportPdfInitialState(initialState))
});

const ComponentConnected = connect<IPageStateProps, IPageDispatchProps>(mapStateToProps, mapDispatchToProps)(withGeovisServer(ReportRenderPage))

export default ComponentConnected;