/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.10.2019
 * @description The document shape to render on the map
 */

import L from 'leaflet';
import { Marker } from 'react-leaflet';
import document24 from 'src/resources/icons/document_24px.png';
import document48 from 'src/resources/icons/document_48px.png';
import { locationToMapCoordinates } from '../../../helpers/MapHelper';
import { LocalMapObjectDocument } from '../../../server/LocalMapObjectDocument';
import { ILocalMapShapeOptions } from '../ILocalMapShapeOptions';
import { LocalMapObjectViewMode } from '../types';
import { LocalMapObjectPopup, LocalMapObjectTooltip } from './LocalMapObjectMapElements';

interface ILocalMapObjectDocumentShapeProps {
    element: LocalMapObjectDocument;
    viewMode: LocalMapObjectViewMode;
    onEdit: (id: string) => void;
    onRemove: (id: string) => void;
    onOpenFile: (localMapObjectId: string) => void;
}

export const LocalMapObjectDocumentShape = ({
    element,
    onEdit,
    onRemove,
    viewMode,
    onOpenFile: onDownloadFile
}: ILocalMapObjectDocumentShapeProps) => {

    const documentIcon = new L.Icon({
        iconUrl: document24,
        iconRetinaUrl: document48,
        iconAnchor: [0, 0],
        popupAnchor: [12, 0],
        iconSize: [24, 24]
    });

    const onClickHandler = (event: L.LeafletMouseEvent) => {
        if (viewMode === LocalMapObjectViewMode.Edit && !event.originalEvent.ctrlKey) {
            return;
        }

        onDownloadFile(element.Id);
    };

    const shapeOptions: ILocalMapShapeOptions = {
        localMapObjectId: element.Id,
        localMapObjectType: element.ObjectType
    };

    const getChildren = (): JSX.Element[] => {
        const result: JSX.Element[] = [];
        if (viewMode !== LocalMapObjectViewMode.MapSection) {
            result.push(<LocalMapObjectPopup key={`lmo-${element.Id}-popup-key`} element={element} onEdit={onEdit} onRemove={onRemove} editMode={viewMode === LocalMapObjectViewMode.Edit} />)
        }
        result.push(<LocalMapObjectTooltip key={`lmo-${element.Id}-tooltip-key`} element={element} />);

        return result;
    }

    return (
        <Marker
            key={`local_map_document_${element.Id}_marker`}
            position={locationToMapCoordinates(element.Location)}
            icon={documentIcon}
            eventHandlers={viewMode === LocalMapObjectViewMode.MapSection ? undefined : { 
                click: onClickHandler 
            }}
            {...shapeOptions}>
            {getChildren()}
        </Marker>
    )
}