import { uuid } from '@atlaskit/adf-schema';
import { Reducer } from 'redux';
import i18next from '../../i18n';
import { MapProviders } from '../../server/AVTService/TypeLibrary/Map/MapProviders';
import {
    CHANGE_LANGUAGE,
    CHANGE_MAP_BASE_LAYER,
    LOGIN_SUCCESS,
    NAVIGATION_FEEDBACK_STATISTIC_DATA,
    NAVIGATION_UPDATE_USER_VIEW_MODE,
    PROJECTS_OVERVIEW,
    REFRESH_NAVIGATION_STATE,
    SERVICES_ADD_FLAG,
    SERVICES_CLEAR_FLAGS,
} from '../actions/navigationActions';
import { processFetchedData } from '../helpers/DataHelper';
import {
    feedbackStatisticStorageInitialState,
    IGeovisNavigationAction,
    IGeovisNavigationState
} from "../types";

const navigationInitialState: IGeovisNavigationState = {
    flags: [],
    language: i18next.language,
    mapProvider: MapProviders.CartoDBPositron,
    viewerModeStamp: '',
    feedbackStatisticStorage: feedbackStatisticStorageInitialState
};

const navigationReducer: Reducer<IGeovisNavigationState> = (state: IGeovisNavigationState = navigationInitialState, action: IGeovisNavigationAction): IGeovisNavigationState => {

    switch (action.type) {

        case LOGIN_SUCCESS:
            return { ...navigationInitialState };

        case PROJECTS_OVERVIEW:
            return { ...state, ...action };

        case CHANGE_LANGUAGE: {
            const language = action.language || "en";
            return { ...state, language };
        }

        case REFRESH_NAVIGATION_STATE:
            return { ...state };

        case NAVIGATION_UPDATE_USER_VIEW_MODE:
            return {
                ...state,
                viewerModeStamp: uuid.generate()
            }

        // servicing
        case SERVICES_ADD_FLAG: {
            if (action.flag) {
                return { ...state, flags: [...state.flags, action.flag] };
            }
            break;
        }

        case SERVICES_CLEAR_FLAGS: {
            return { ...state, flags: [] };
        }

        case CHANGE_MAP_BASE_LAYER:
            return { ...state, mapProvider: action.mapProvider! }

        case NAVIGATION_FEEDBACK_STATISTIC_DATA: {
            if (!action.statistic) {
                return state;
            }

            return {
                ...state,
                feedbackStatisticStorage: processFetchedData(action.statistic, state.feedbackStatisticStorage, feedbackStatisticStorageInitialState, st => ({ data: st }))
            }
        }
    }

    return state;
}

export default navigationReducer;