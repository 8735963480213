import { OptionType } from "@atlaskit/select";
import { getSensorHeatpulseOptionToDescription, SensorHeatpulseOption } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SensorHeatpulseOption";
import { SensorCategory } from "../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { GeovisSelect } from "../../select/GeovisSelect";
import { getSensorHeatpulseOptions, isHeatableSensorType } from "./tools";

interface IComponentProps {
    id: string;
    isHeatable: boolean;
    sensorType: SensorCategory;
    heatpulseOption: SensorHeatpulseOption;

    onChangeOption: (heatpulseOption: SensorHeatpulseOption) => void;
}

const Component = ({
    id,
    isHeatable,
    sensorType,
    heatpulseOption,
    onChangeOption
}: IComponentProps) => {
    const getOption = (option: SensorHeatpulseOption): OptionType => {
        if (!isHeatableSensorType(sensorType)) {
            return ({ label: "", value: "" });
        }
        return ({ label: getSensorHeatpulseOptionToDescription(option), value: option });
    }

    const getOptions = () => {
        return getSensorHeatpulseOptions(sensorType).map(o => getOption(o));
    }

    const onChange = (option: OptionType) => {
        onChangeOption(+option.value);
    }

    return (
        <GeovisSelect
            id={id}
            value={getOption(heatpulseOption)}
            isDisabled={!isHeatable || !isHeatableSensorType(sensorType)}
            options={getOptions()}
            spacing='compact'
            onChange={onChange}
        />
    )
}

export default Component;