import Checkbox from "@atlaskit/checkbox";
import Select, { OptionType } from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import { debounce } from "lodash";
import { SyntheticEvent } from "react";
import { getGeovisCurrentDateTime, getGeovisDateTimeShift } from "../../../../helpers/DateHelper";
import { t } from "../../../../i18n";
import { DefaultChartSensorFilterOption, DefaultChartSensorFilterOptionList, getDefaultChartSensorFilterOptionToName } from "../../../../server/AVTService/TypeLibrary/Common/DefaultChartSensorFilterOption";
import { TimePeriod, TimePeriodList } from "../../../../server/AVTService/TypeLibrary/Common/TimePeriod";
import { BucketReportValueKindList, getBucketReportValueKindToName } from "../../../../server/AVTService/TypeLibrary/DB/ArgusDataStorage/Bucket/Types/BucketReportValueKind";
import { DefaultTimeValueChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DefaultTimeValueChartModel";
import { GeovisChartAxisSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { getSensorCategoryName, SensorCategoryOrdered } from "../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { GeovisMstModel } from "../../../../server/GEOvis3/Model/Database/GeovisMstModel";
import GeovisReactDateTimePicker from "../../../dateTimePickers/GeovisReactDateTimePicker";
import { GeovisSelect } from "../../../select/GeovisSelect";
import { getTimeOfPeriodInHours, isChartTemplate, isVisibleSensorType } from "../tools";
import {
    defaultChartLabelColumnStyle,
    defaultChartRowSettingsStyle,
    IGeovisChartPropertyChangedProps
} from "../types";
import { ElementSettingAllowCheckbox } from "../../ElementSettingAllowCheckbox";

type ChartProperty = keyof DefaultTimeValueChartModel;

interface ITimeValueMainSettingsProps extends IGeovisChartPropertyChangedProps<DefaultTimeValueChartModel> {
    aliases: GeovisMstModel[];
}

export const DefaultTimeValueChartMainSettings = ({ chart, onChartPropertyChanged, aliases, onChartPropertiesChanged }: ITimeValueMainSettingsProps) => {

    const {
        StartDateSetting,
        EndDateSetting,
        ShowAllMeasurementsSetting,
        Period,
        IsPeriodCustomerChangeable,
        UseLastMeasurementTime,
        ShowReferenceAndOffset,
        UseRelativeTimeSetting,
        RelativeTime,
        BucketReportValueKind,
        BucketReportValueKindCustomerChangeable,
        FilterType,
        LeftYAxisSettings
    } = chart;

    const isTemplate = isChartTemplate(chart);

    const onBooleanPropertyChangedFunc = (propertyName: ChartProperty) => (event: SyntheticEvent<HTMLInputElement>) => {
        const value = event.currentTarget.checked;

        switch (propertyName) {
            case "ShowAllMeasurementsSetting":
                onChartPropertyChanged(propertyName, { ...ShowAllMeasurementsSetting, value });
                break;
            case "UseRelativeTimeSetting":
                onChartPropertyChanged(propertyName, { ...UseRelativeTimeSetting, value });
                break;

            case "IsPeriodCustomerChangeable":
            case "UseLastMeasurementTime":
            case "ShowReferenceAndOffset":
                onChartPropertyChanged(propertyName, value);
                break;
        }
    }

    const onCustomerChangeablePropertyChangedFunc = (propertyName: ChartProperty) => (value: boolean) => {

        switch (propertyName) {
            case "ShowAllMeasurementsSetting":
                onChartPropertyChanged(propertyName, { ...ShowAllMeasurementsSetting, customerChangeable: value })
                break;
            case "UseRelativeTimeSetting":
                onChartPropertyChanged(propertyName, { ...UseRelativeTimeSetting, customerChangeable: value });
                break;
            case "StartDateSetting":
                onChartPropertyChanged(propertyName, { ...StartDateSetting, customerChangeable: value });
                break;
            case "EndDateSetting":
                onChartPropertyChanged(propertyName, { ...EndDateSetting, customerChangeable: value });
                break;
            case "BucketReportValueKindCustomerChangeable":
            case "IsPeriodCustomerChangeable":
                onChartPropertyChanged(propertyName, value);
                break;
        }
    }

    const onStringPropertyChangedFunc = (propertyName: ChartProperty) => (value: string) => {
        const formattedDateTime = value || "";
        switch (propertyName) {
            case "StartDateSetting":
                onChartPropertyChanged(propertyName, { ...StartDateSetting, Value: formattedDateTime });
                break;
            case "EndDateSetting":
                onChartPropertyChanged(propertyName, { ...EndDateSetting, Value: formattedDateTime });
                break;
            case "RelativeTime":
                onChartPropertyChanged(propertyName, value);
                break
        }
    }

    const onSelectablePropertyChangedFunc = (propertyName: ChartProperty) => (selected: OptionType) => {
        switch (propertyName) {
            case "Period":
                if (+selected.value === TimePeriod.Custom) {
                    onChartPropertyChanged(propertyName, +selected.value);
                }
                else {
                    const delta = getTimeOfPeriodInHours(+selected.value);
                    const endDate = getGeovisCurrentDateTime();
                    const startDate = getGeovisDateTimeShift(new Date(), -delta, 'hours')

                    onChartPropertiesChanged({
                        StartDateSetting: { Value: startDate, customerChangeable: StartDateSetting.customerChangeable },
                        EndDateSetting: { Value: endDate, customerChangeable: EndDateSetting.customerChangeable },
                        Period: +selected.value
                    })
                }
                break;
            case "BucketReportValueKind":
            case "FilterType":
                onChartPropertyChanged(propertyName, +selected.value);
                break;
            case "LeftYAxisSettings": {
                const newAxisSettings: GeovisChartAxisSettings = { ...LeftYAxisSettings, TypeOfSensor: +selected.value };
                onChartPropertyChanged(propertyName, newAxisSettings);
                break;
            }
        }
    }

    const onTextPropertyChangedDebounced = debounce((propertyName: ChartProperty, value: string) => {
        onChartPropertyChanged(propertyName, value);
    })

    const onTextPropertyChangedHandler = (propertyName: ChartProperty) => (event: SyntheticEvent<HTMLInputElement>) => {
        onTextPropertyChangedDebounced(propertyName, event.currentTarget.value);
    }

    const onSelectableArrayPropertyChangedFunc = (propertyName: ChartProperty) => (selected: OptionType[] | undefined) => {
        switch (propertyName) {
            case "Aliases": {
                const data: string[] = selected ? selected.map(op => op.value.toString()) : [];
                onChartPropertyChanged(propertyName, data);
                break;
            }
        }
    }

    const getTimePeriodNiceName = (tp: TimePeriod): string => {
        switch (tp) {
            case TimePeriod.Hour1: return t("1 Hour");
            case TimePeriod.Hour2: return t("2 Hours");
            case TimePeriod.Hour6: return t("6 Hours");
            case TimePeriod.Hour12: return t("12 Hours");
            case TimePeriod.Day1: return t("1 Day");
            case TimePeriod.Day2: return t("2 Days");
            case TimePeriod.Day4: return t("4 Days");
            case TimePeriod.Week1: return t("1 Week");
            case TimePeriod.Week2: return t("2 Weeks");
            case TimePeriod.Month1: return t("1 Month");
            case TimePeriod.Month2: return t("2 Months");
            case TimePeriod.Month3: return t("3 Months");
            case TimePeriod.Month6: return t("6 Months");
            case TimePeriod.Year1: return t("1 Year");
            case TimePeriod.Year2: return t("2 Years");
            case TimePeriod.Year3: return t("3 Years");
            case TimePeriod.Year5: return t("5 Years");
            case TimePeriod.Year10: return t("10 Years");

            default: return t("Custom");
        }
    }

    const getPeriodOptions = (): OptionType[] => {
        return TimePeriodList.map(val => ({ value: val, label: getTimePeriodNiceName(val) }))
    }

    const getSelectedPeriod = (): OptionType => {
        return { value: Period, label: getTimePeriodNiceName(Period) };
    }

    const getBVKOptions = (): OptionType[] => {
        return BucketReportValueKindList.map(val => ({ value: val, label: getBucketReportValueKindToName(val) }))
    }

    const getSelectedBVK = (): OptionType => {
        return { value: BucketReportValueKind, label: getBucketReportValueKindToName(BucketReportValueKind) };
    }

    const getFilterTypeOptions = (): OptionType[] => {
        return DefaultChartSensorFilterOptionList.map(e => ({ label: t(getDefaultChartSensorFilterOptionToName(e)), value: e }));
    }

    const getSelectedFilterType = (): OptionType => {
        return { value: FilterType, label: t(getDefaultChartSensorFilterOptionToName(FilterType)) };
    }

    const getDatabasesFilterOptions = (): OptionType[] => {
        return aliases.map(alias => ({ value: alias.Id, label: alias.Name }));
    }

    const getSelectedDatabases = (): OptionType[] => {
        const results: OptionType[] = [];

        getDatabasesFilterOptions().forEach(op => {
            if (chart.Aliases.includes(op.value.toString())) {
                results.push(op);
            }
        })

        return results;
    }

    const getSensorCategoriesOptions = (): OptionType[] => {
        return SensorCategoryOrdered.filter(st => isVisibleSensorType(st)).map(s => ({ label: getSensorCategoryName(s), value: s }));
    }

    const getSelectedCategory = (): OptionType => {
        return ({ value: LeftYAxisSettings.TypeOfSensor, label: getSensorCategoryName(LeftYAxisSettings.TypeOfSensor) })
    }

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    return (
        <div>

            {/* Show all measurements */}
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1">
                    <Checkbox
                        label={t("Show all measurements")}
                        isChecked={ShowAllMeasurementsSetting.value}
                        onChange={onBooleanPropertyChangedFunc("ShowAllMeasurementsSetting")}
                    />
                </div>
                <ElementSettingAllowCheckbox
                    isChecked={ShowAllMeasurementsSetting.customerChangeable}
                    onChange={onCustomerChangeablePropertyChangedFunc("ShowAllMeasurementsSetting")}
                    isDisabled={false}
                />
            </div>

            {/* Period */}
            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer" style={labelColumnStyle}>
                    <label htmlFor="cmbPeriod">{t("Period")}:</label>
                </div>
                <div className="flexCellContainer_g1">
                    <GeovisSelect
                        id="cmbPeriod"
                        options={getPeriodOptions()}
                        value={getSelectedPeriod()}
                        onChange={onSelectablePropertyChangedFunc("Period")}
                        isCompact={true}
                        spacing='compact'
                        isDisabled={ShowAllMeasurementsSetting.value}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: IsPeriodCustomerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("IsPeriodCustomerChangeable"),
                    isDisabled: ShowAllMeasurementsSetting.value
                })}
            </div>

            {/* Use last measurements */}
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1">
                    <Checkbox
                        label={t("Using the last measurement time for the report end time")}
                        isChecked={UseLastMeasurementTime}
                        isDisabled={ShowAllMeasurementsSetting.value || Period === TimePeriod.Custom}
                        onChange={onBooleanPropertyChangedFunc("UseLastMeasurementTime")}
                    />
                </div>
            </div>

            {/* Start date */}
            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer" style={labelColumnStyle}>
                    <label htmlFor="dtStartDateTime">{t("Start date")}:</label>
                </div>
                <div className="flexCellContainer_g1">
                    <GeovisReactDateTimePicker
                        locale="en-GB"
                        key="dtStartDateTime"
                        value={StartDateSetting.Value}
                        isPairedPicker={true}
                        isThisPickerOfStartDate={true}
                        pairPickerDate={EndDateSetting.Value}
                        disabled={ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom}
                        onChange={onStringPropertyChangedFunc("StartDateSetting")} />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: StartDateSetting.customerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("StartDateSetting"),
                    isDisabled: ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom
                })}
            </div>

            {/* End date */}
            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer" style={labelColumnStyle}>
                    <label htmlFor="dtEndDateTime">{t("End date")}:</label>
                </div>
                <div className="flexCellContainer_g1">
                    <GeovisReactDateTimePicker
                        locale="en-GB"
                        key="dtEndDateTime"
                        value={EndDateSetting.Value}
                        isPairedPicker={true}
                        isThisPickerOfStartDate={false}
                        pairPickerDate={StartDateSetting.Value}
                        onChange={onStringPropertyChangedFunc("EndDateSetting")}
                        disabled={ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: EndDateSetting.customerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("EndDateSetting"),
                    isDisabled: ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom
                })}
            </div>

            {/* Use relative time */}
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1" style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '5px' }}>
                        <Checkbox
                            label={`${t("Use relative time with start at")}: `}
                            isChecked={UseRelativeTimeSetting.value}
                            onChange={onBooleanPropertyChangedFunc("UseRelativeTimeSetting")}
                        />
                    </div>
                    <div style={{ width: '200px' }}>
                        <GeovisReactDateTimePicker
                            locale="en-GB"
                            key="dtRelativeTime"
                            value={RelativeTime}
                            onChange={onStringPropertyChangedFunc("RelativeTime")}
                        />
                    </div>
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: UseRelativeTimeSetting.customerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("UseRelativeTimeSetting"),
                    isDisabled: false
                })}
            </div>

            {/* Show Reference */}
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1">
                    <Checkbox
                        label={t("Show Reference and Offset Timestamp")}
                        isChecked={ShowReferenceAndOffset}
                        onChange={onBooleanPropertyChangedFunc("ShowReferenceAndOffset")}
                    />
                </div>
            </div>

            {/* Bucket value source */}
            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer" style={labelColumnStyle}>
                    <label htmlFor="cmbBVS">{t("Bucket value source")}:</label>
                </div>
                <div className="flexCellContainer_g1">
                    <GeovisSelect
                        id="cmbBVS"
                        options={getBVKOptions()}
                        value={getSelectedBVK()}
                        onChange={onSelectablePropertyChangedFunc("BucketReportValueKind")}
                        isCompact={true}
                        spacing='compact'
                        isDisabled={false}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: BucketReportValueKindCustomerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("BucketReportValueKindCustomerChangeable"),
                    isDisabled: false
                })}
            </div>

            {/* Sensor type */}
            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer" style={labelColumnStyle}>
                    <label htmlFor="cmbSensorType">{t("Sensors type")}:</label>
                </div>
                <div className="flexCellContainer" style={{ width: '60%' }}>
                    <GeovisSelect
                        id="cmbSensorType"
                        options={getSensorCategoriesOptions()}
                        value={getSelectedCategory()}
                        onChange={onSelectablePropertyChangedFunc("LeftYAxisSettings")}
                        isCompact={true}
                        spacing='compact'
                        isDisabled={false}
                    />
                </div>
            </div>

            {/* Filter type */}
            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer" style={labelColumnStyle}>
                    <label htmlFor="cmbFilterType">{t("Filter type")}:</label>
                </div>
                <div className="flexCellContainer" style={{ width: '60%' }}>
                    <GeovisSelect
                        id="cmbFilterType"
                        options={getFilterTypeOptions()}
                        value={getSelectedFilterType()}
                        onChange={onSelectablePropertyChangedFunc("FilterType")}
                        isCompact={true}
                        spacing='compact'
                        isDisabled={false}
                    />
                </div>
            </div>

            {/* Database filter */}
            {!isTemplate &&
                <div className="flexRowContainer" style={settingRowStyle}>
                    <div className="flexCellContainer" style={labelColumnStyle}>
                        <label htmlFor="cmbDbFilter">{t("Databases filter")}:</label>
                    </div>
                    <div className="flexCellContainer_g1">
                        <Select
                            id="cmbDbFilter"
                            options={getDatabasesFilterOptions()}
                            value={getSelectedDatabases()}
                            isMulti={true}
                            onChange={onSelectableArrayPropertyChangedFunc("Aliases")}
                            isCompact={true}
                            spacing='compact'
                            isDisabled={chart.FilterType !== DefaultChartSensorFilterOption.UseFilter}
                        />
                    </div>
                </div>
            }

            {/* Sensor name filter */}
            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer" style={labelColumnStyle}>
                    <label htmlFor="cmbSnFilter">{t("Sensor name filter")}:</label>
                </div>
                <div className="flexCellContainer_g1">
                    <Textfield
                        id="cmbSnFilter"
                        defaultValue={chart.NameFilter}
                        onChange={onTextPropertyChangedHandler("NameFilter")}
                        isCompact={true}
                        isDisabled={chart.FilterType !== DefaultChartSensorFilterOption.UseFilter}
                    />
                </div>
            </div>
        </div>
    );
}