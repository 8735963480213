import { Country } from "../../../../../server/AVTService/TypeLibrary/Sms/Country";
import { IGeovisProjectUsersStorage } from "../../../../../store/data.types";

export const invertArray = <TData>(source: TData[]): TData[] => {
    const result: TData[] = [];

    for (let index = source.length - 1; index >= 0; index--) {
        result.push(source[index]);
    }

    return result;
}

export const getAllowedCountryCodes = (): Country[] => {
    return [
        Country.CH,
        Country.LI
    ]
}

export const verifyUsersPhone = (userId: string, projectUsersStorage: IGeovisProjectUsersStorage): boolean => {

    const user = projectUsersStorage.users.get(userId);

    if (!user) {
        return false;
    }

    return verifyPhoneNumber(user.PhoneNumber, user.CountryCode, true)
}

export const verifyPhoneNumber = (number: string, countryCode: Country, checkCode: boolean): boolean => {
    if (!number) {
        return false;
    }

    if (!getAllowedCountryCodes().includes(countryCode) && checkCode) {
        return false;
    }

    if (number.startsWith("0")) {
        return false;
    }

    const phoneRegExp: RegExp = new RegExp(/^[0-9]+$/)

    // const fullUserNumber = `${getCountryCodeByCountry(countryCode)}${number}`;
    // // eslint-disable-next-line no-useless-escape
    // const phoneRegExp: RegExp = new RegExp(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/igm);

    return phoneRegExp.test(number);


}

export const getPredefinedColors = (): string[] => {
    return [
        "#90ee90", // Light green
        "#ffd700", // Gold
        "#008b8b", // Dark cyan
        "#808000", // Olive
        "#f08080", // Light coral
        "#7b68ee", // Medium state blue
        "#008000", // Green
        "#ffa500", // Orange
        "#0000ff", // Blue
        "#808080", // Gray
        "#4682b4", // Steel blue
        "#ff00ff", // Magenta        
        "#006400", // Dark green
        "#ffff00", // Yellow
        "#00008b", // Dark blue
        "#000000", // Black        
        "#8b0000", // Dark red
        "#800080", // Purple
    ]
}