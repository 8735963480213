import Modal, { ModalTransition, } from "@atlaskit/modal-dialog";
import { OptionType } from "@atlaskit/select";
import { useState } from "react";
import { BoolEditField, EditColorField, TextEditField } from "../../../../components/editDialogs/EditDialogTools";
import { GeovisSelect } from "../../../../components/select/GeovisSelect";
import { t } from "../../../../i18n";
import { AlarmCondition } from "../../../../server/AVTService/TypeLibrary/Alarming/AlarmCondition";
import { ChartAlarmLineTypeList, getChartAlarmLineTypeToDescription } from "../../../../server/AVTService/TypeLibrary/Common/ChartAlarmLineType";
import FlagService from "../../../../services/FlagService";
import { AlarmConditionFrequencyBandsEditor } from "./AlarmConditionFrequencyBandsEditior";
import { VibrationFrequencyBand } from "../../../../server/AVTService/TypeLibrary/Alarming/VibrationFrequencyBand";


interface IComponentProps {
    isNewCondition: boolean;
    isVibration: boolean;
    condition: AlarmCondition;
    isReadonly: boolean;
    hasTriggeredSensors: boolean;
    onClose: () => void;
    onSaveCondition: (cond: AlarmCondition) => void;
}

export const AlarmConditionEditDialog = ({
    condition,
    onClose,
    onSaveCondition,
    isNewCondition,
    isReadonly,
    hasTriggeredSensors,
    isVibration
}: IComponentProps) => {

    const [state, setState] = useState<AlarmCondition>(condition);
    const [validState, setValidState] = useState<Map<string, Map<string, boolean>>>(new Map<string, Map<string, boolean>>());

    const onSaveConditionHandler = () => {
        if (state.minEnabled && state.maxEnabled && state.min >= state.max && !isVibration) {
            FlagService.addWarning(t("Wrong condition configuration"), t("High limit must be higher than Low limit"));
            return;
        }
        if (isVibration) {
            let allValid = true;
            validState.forEach(properties => {
                properties.forEach(property => {
                    if (!property) {
                        allValid = false;
                    }
                })
            });

            if (!allValid) {
                FlagService.addWarning(t("Wrong condition configuration"), t("Please, check frequency bands configuration"));
                return;
            }
        }

        onSaveCondition(state);
    }

    const getLineTypeOptions = (): OptionType[] => {
        return ChartAlarmLineTypeList.map(l => ({
            value: l,
            label: getChartAlarmLineTypeToDescription(l)
        }))
    }

    const getSelectedLineOption = (): OptionType => {
        return ({
            value: state.lineType,
            label: getChartAlarmLineTypeToDescription(state.lineType)
        })
    }

    const onSelectedLineChanged = (selected: OptionType) => {
        setState({
            ...state,
            lineType: +selected.value
        })
    }

    const updateAlarmConditionProperty = (value: any, propertyName: keyof AlarmCondition) => {
        const update: Partial<AlarmCondition> = {};
        update[propertyName] = value;
        setState({ ...state, ...update })
    }

    const onTextPropertyChanged = (propertyName: keyof AlarmCondition) => (value: string) => {
        switch (propertyName) {
            case "lineWeight":
            case "max":
            case "min":
                if (!isNaN(+value)) {
                    updateAlarmConditionProperty(+value, propertyName);
                }
                break;
            default:
                updateAlarmConditionProperty(value, propertyName);
                break;
        }
    }

    const onBoolPropertyChanged = (propertyName: keyof AlarmCondition) => (value: boolean) => {
        updateAlarmConditionProperty(value, propertyName);
    }

    const onColorChanged = (color: string) => {
        setState({
            ...state,
            color
        })
    }

    const onChangeFreqBands = (bands: VibrationFrequencyBand[]) => {
        setState({
            ...state,
            frequencyBands: bands
        })
    }

    const onBandsValidationEnd = (bandId: string, propertyName: string, isValid: boolean) => {
        const band = validState.get(bandId);
        if (!band) {
            validState.set(bandId, new Map<string, boolean>());
        }
        validState.set(bandId, validState.get(bandId)!.set(propertyName, isValid));
        setValidState(validState);
    }

    return (
        <ModalTransition>
            <Modal
                heading={isNewCondition ? t("Create alarm condition") : isReadonly ? t("Watch alarm condition") : t("Edit alarm condition")}
                width={'30%'}
                actions={[
                    { text: t("Save"), onClick: onSaveConditionHandler, appearance: 'primary', isDisabled: isReadonly },
                    { text: t("Cancel"), onClick: onClose },
                ]}
            >
                <div>
                    <TextEditField
                        htmlName="tfSeverityName"
                        onChangeTextField={onTextPropertyChanged("severityName")}
                        value={state.severityName}
                        labelText={t("Severity")}
                        labelColumnStyle={{ width: '100px' }}
                        isDisabled={isReadonly}
                    />
                    {!isVibration &&
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <TextEditField
                                htmlName="tfLowLim"
                                onChangeTextField={onTextPropertyChanged("min")}
                                value={state.min}
                                labelText={t("Low limit")}
                                isDisabled={!state.minEnabled || isReadonly || hasTriggeredSensors}
                                labelColumnStyle={{ width: '100px' }}
                            />
                            <BoolEditField
                                isChecked={state.minEnabled}
                                isDisabled={isReadonly || hasTriggeredSensors}
                                label={t("enabled")}
                                onChange={onBoolPropertyChanged("minEnabled")}
                                tooltipContent={hasTriggeredSensors && !isReadonly ? t("For change the condition limit you need to confirm all triggered sensors for this condition") : undefined}
                            />
                        </div>
                    }
                    {!isVibration &&
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <TextEditField
                                htmlName="tfHiLim"
                                onChangeTextField={onTextPropertyChanged("max")}
                                value={state.max}
                                labelText={t("High limit")}
                                isDisabled={!state.maxEnabled || isReadonly || hasTriggeredSensors}
                                labelColumnStyle={{ width: '100px' }}
                            />
                            <BoolEditField
                                isChecked={state.maxEnabled}
                                isDisabled={isReadonly || hasTriggeredSensors}
                                label={t("enabled")}
                                onChange={onBoolPropertyChanged("maxEnabled")}
                                tooltipContent={hasTriggeredSensors && !isReadonly ? t("For change the condition limit you need to confirm all triggered sensors for this condition") : undefined}
                            />
                        </div>
                    }
                    {isVibration &&
                        <AlarmConditionFrequencyBandsEditor
                            bands={state.frequencyBands}
                            isReadonly={isReadonly}
                            onChange={onChangeFreqBands}
                            onBandsValidationEnd={onBandsValidationEnd}
                        />
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <TextEditField
                            htmlName="tfLineWeight"
                            onChangeTextField={onTextPropertyChanged("lineWeight")}
                            value={state.lineWeight}
                            labelText={t("Line weight")}
                            type="number"
                            min={0}
                            max={10}
                            isDisabled={!state.lineEnabled || isReadonly}
                            labelColumnStyle={{ width: '100px' }}
                        />
                        <div style={{ width: '100%' }}>
                            <GeovisSelect
                                options={getLineTypeOptions()}
                                value={getSelectedLineOption()}
                                onChange={onSelectedLineChanged}
                                isDisabled={!state.lineEnabled || isReadonly}
                            />
                        </div>
                        <BoolEditField
                            isChecked={state.lineEnabled}
                            label={t("enabled")}
                            onChange={onBoolPropertyChanged("lineEnabled")}
                            isDisabled={isReadonly}
                        />
                    </div>

                    <BoolEditField
                        isChecked={state.fillEnabled}
                        label={t("Fill Alarm area")}
                        onChange={onBoolPropertyChanged("fillEnabled")}
                        isDisabled={isReadonly}
                    />

                    <EditColorField
                        color={state.color}
                        label={t("Condition color")}
                        onChange={onColorChanged}
                        labelColumnStyle={{ width: '100px' }}
                        height='14px'
                        width="200px"
                        isDisabled={isReadonly}
                    />
                </div>
            </Modal>
        </ModalTransition>
    )
}