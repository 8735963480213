//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { KindOfElementUsing } from '../../Common/KindOfElementUsing';
import { ProjectElementType } from '../../Common/ProjectElementType';
import { JsonDocNode } from '../../AtlaskitJsonDocument/JsonDocNode';
import { GeovisCommentFileInfo } from './GeovisCommentFileInfo';

export class GeovisCommentModel
{
	public Id: number;
	public ProjectId: number;
	public Name: string;
	public Title: string;
	public KindOfElementUsing: KindOfElementUsing;
	public CreatedFrom: number;
	public CreatedFromType: ProjectElementType;
	public TemplateCompanyId: string;
	public LastUpdateTime: string;
	public Document: JsonDocNode;
	public Attachments: GeovisCommentFileInfo[];
	public HashId: string;
	public Hidden: boolean;
}
