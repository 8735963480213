/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 15.08.2023
 * @description Geovis Image report data layer, it loads data for the GeovisImages
 */

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { useEffect } from "react";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../../helpers/GeovisHooks";
import { DataActionResponse } from "../../../../../../server/DataActionResponse";
import { IGeovisImageReportInfo } from "../../../../../../store/projectReport.types";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { IGeovisAction } from "../../../../../../store/types";
import { getGeovisImageInfo, getGeovisImageTimeSearchDistanceElementDataRequest } from "../tools";
import ServerRoutesGen from "../../../../../../server/Routes/ServerRoutesGen";
import { fetchServerElementsByPost } from "../../../../../../helpers/ProjectDataHelper";
import { TimeSearchDistanceDataRequest } from "../../../../../../server/AVTService/Computation/TimeSearchDistance/TimeSearchDistanceDataRequest";
import { projectReportGeovisImageDataLoaded, projectReportGeovisImageDataLoading } from "../../../../../../store/creators/projectReportCreators";
import { GeovisTimeSearchReportData } from "../../../../../../server/GEOvis3/Model/TimeSearchDistance/GeovisTimeSearchReportData";

interface IStateToProps {
    geovisImageInfo: IGeovisImageReportInfo | false;
}

interface IDispatchToProps {
    onLoading: () => void;
    onLoaded: (response: DataActionResponse<GeovisTimeSearchReportData>) => void;
}

interface IOwnProps {
    pageNum: number;
    elementId: number;
    reportId: number

    isPrinting: boolean;
    userId?: string;
    userToken?: string;
}

interface IComponentProps extends IStateToProps, IDispatchToProps, IOwnProps, IWithGeovisServerProps {

}

const Component = ({ Server, geovisImageInfo, userId, userToken, isPrinting, pageNum, onLoading, onLoaded, reportId }: IComponentProps) => {

    if (!geovisImageInfo) {
        return null;
    }

    const { Timestamp, GeovisImage: { Id, ProjectId } } = geovisImageInfo;

    useEffect(() => {

        onLoading();

        (async function loadData() {

            const url = isPrinting
                ? ServerRoutesGen.ReportPdfRenderData.GetTimeSearchDistanceReportData.patch(ProjectId, userToken, userId)
                : ServerRoutesGen.ReportRenderData.GetTimeSearchReportData.patch(ProjectId);

            const payload = getGeovisImageTimeSearchDistanceElementDataRequest(geovisImageInfo, reportId);
            const response = await fetchServerElementsByPost<GeovisTimeSearchReportData, TimeSearchDistanceDataRequest>(Server, url, payload);

            if (response.Success && geovisImageInfo) {
                response.Data.ChainsInfo.Data.forEach(chain => {
                    const storedChain = geovisImageInfo.GeovisImage.Chains.find(ch => ch.FullId === chain.Id);
                    if (storedChain !== undefined) {
                        chain.Coordinates = storedChain.RelativeLocation
                    }
                });

                response.Data.SensorsInfo.Data.forEach(sensor => {
                    const storedChain = geovisImageInfo.GeovisImage.Sensors.find(ch => ch.FullId === sensor.Id);
                    if (storedChain !== undefined) {
                        sensor.Coordinates = storedChain.RelativeLocation
                    }
                });
            }

            onLoaded(response);
        })();

    }, [Timestamp]);


    return (
        <div key={`geovis-image-${Id}-data-layer-of-page-${pageNum}`} style={{ display: 'none' }}>
            {/* geovis image report data layer */}
        </div>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { pageNum, elementId }: IOwnProps): IStateToProps => ({
    geovisImageInfo: getGeovisImageInfo(projectReport.geovisReportSettings, pageNum, elementId)
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>, { pageNum, elementId }: IOwnProps): IDispatchToProps => ({
    onLoading: () => dispatch(projectReportGeovisImageDataLoading(pageNum, elementId)),
    onLoaded: data => dispatch(projectReportGeovisImageDataLoaded(pageNum, elementId, data))
});

/**
 * Geovis image report data layer
 */
export const GeovisImageReportDataLayer = connect<IStateToProps, IDispatchToProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(withGeovisServer(Component));