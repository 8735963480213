import Tabs from '@atlaskit/tabs';
import { TabData } from '@atlaskit/tabs/types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IWithGeovisServerProps, withGeovisServer } from '../../../helpers/GeovisHooks';
import { t } from '../../../i18n';
import { IBusinessMessagesStorage } from '../../../store/businessData.types';
import { geovisBusinessMessageChangeTabName } from '../../../store/creators/businessDataCreators';
import { IGeovisStoreState } from '../../../store/store.types';
import { IGeovisAction } from '../../../store/types';
import MessagesHistoryList from './MessagesHistoryList';
import { MessagesHubTabItemComponent } from './MessagesHubTabItemComponent';
import MessageTemplatesList from './MessageTemplatesList';

export const MSG_TEMPLATES_TAB_NAME: string = "msg_templates";
const MSG_HISTORY_TAB_NAME: string = "msg_history";

interface IMessagesHubStateProps {
    store: IBusinessMessagesStorage;
}

interface IMessagesHubDispatchProps {
    onTabSelected: (storageName: string) => void;
}

interface IMessagesHubOwnProps {
    deleteMessageTemplateReference: (templateId: string) => Promise<boolean>;
}


interface IMessagesHubProps extends IMessagesHubStateProps, IMessagesHubDispatchProps, IMessagesHubOwnProps, IWithGeovisServerProps {

}

type MessagesHubTabData = TabData & {
    storageName: string
}

const createTemplatesTabContent = (props: IMessagesHubOwnProps): MessagesHubTabData => ({
    label: t("Message templates"),
    content: (<MessageTemplatesList
        deleteMessageTemplateReference={props.deleteMessageTemplateReference}
    />),
    storageName: MSG_TEMPLATES_TAB_NAME
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createHistoryTabContent = (store: IBusinessMessagesStorage): MessagesHubTabData => ({
    label: t("Messages history"),
    content: (<MessagesHistoryList />),
    storageName: MSG_HISTORY_TAB_NAME
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onTabSelectedFunc = (onTabSelected: (storageName: string) => void) => (tab: TabData, index: number) => {
    onTabSelected(tab.storageName);
}

const MessagesHub = ({
    store,
    deleteMessageTemplateReference,
    onTabSelected
}: IMessagesHubProps) => {

    const tabs = [
        createTemplatesTabContent({
            deleteMessageTemplateReference
        }),
        createHistoryTabContent(store)
    ]

    const getSelectedTab = (storageName: string, tables: MessagesHubTabData[]): MessagesHubTabData => {
        for (const tab of tables) {
            if (tab.storageName === storageName) {
                return tab;
            }
        }

        return tables[0];
    }

    return (
        <div style={{
            height: '100%',
            display: 'flex',
            marginTop: '15px'
        }}>
            <Tabs
                components={{ Item: MessagesHubTabItemComponent }}
                selected={getSelectedTab(store.storageType, tabs)}
                tabs={tabs}
                onSelect={onTabSelectedFunc(onTabSelected)} />
        </div>
    );
}

const mapStateToProps = ({ businessData }: IGeovisStoreState): IMessagesHubStateProps => ({
    store: businessData.messagesStore
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IMessagesHubDispatchProps => ({
    onTabSelected: storageName => dispatch(geovisBusinessMessageChangeTabName(storageName))
});

export default connect<IMessagesHubStateProps, IMessagesHubDispatchProps, IMessagesHubOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(MessagesHub))