/**
 * Map legends definition
 */

import L from 'leaflet';
import i18next from '../../i18n';
import AuthService from '../../services/AuthService';
import {
    circleIconPrimitive,
    legendSvgItemProps,
    projectStateFlagPrimitive,
    withSvg
} from './IconPrimitives';
import { createMapSectionViewLegend, createProjectsOverviewMapLegend } from './LegendJs';
import {
    defaultLeafletMapIconProps,
    ISVGIconProps,
    ProjectStateColorFinished,
    ProjectStateColorGray,
    ProjectStateColorGreen,
    ProjectStateColorLightGray,
    ProjectStateColorOrange,
    ProjectStateColorRed,
    ProjectStateColorViolet,
    SensorStateColorAlarm,
    SensorStateColorWarning,
} from './types';

const withSvgF = withSvg({ ...legendSvgItemProps(), className: "legend-item-svg" });

export const getProjectsOverviewMapLegend = (): L.Control => {

    const legendProjectStateItems = {
        "System is OK": ProjectStateColorGreen,
        "System is offline": ProjectStateColorRed,
        "Project is finished": ProjectStateColorFinished,
        // "System is on hold": ProjectStateColorGray
    };

    // add additional project states if user has CompanyAdmin role
    if (AuthService.hasUserTypeAsCompanyAdmin()) {
        legendProjectStateItems["System is in warning state"] = ProjectStateColorOrange;
    }

    // create project state circles
    const items: Array<{ icon: string, label: string }> = [];

    for (const property of Object.keys(legendProjectStateItems)) {
        const props: ISVGIconProps = {
            ...defaultLeafletMapIconProps,
            fillColors: [legendProjectStateItems[property]],
        };

        items.push({
            icon: withSvgF(circleIconPrimitive(props)),
            label: i18next.t(property)
        });
    }

    // add sensor flags
    const goodSensorStateFlagProps: ISVGIconProps = {
        ...defaultLeafletMapIconProps,
        fillColors: [ProjectStateColorGreen],
        iconSize: L.point(16, 24),
        iconAnchor: L.point(0, 1)
    };

    items.push({
        icon: withSvgF(projectStateFlagPrimitive(goodSensorStateFlagProps)),
        label: i18next.t("All sensor values are OK")
    });

    const wdSensorStateFlagProps: ISVGIconProps = {
        ...defaultLeafletMapIconProps,
        fillColors: [ProjectStateColorViolet],
        iconSize: L.point(16, 24),
        iconAnchor: L.point(0, 0)
    };

    items.push({
        icon: withSvgF(projectStateFlagPrimitive(wdSensorStateFlagProps)),
        label: i18next.t("Some sensors with WD raised")
    });

    const disabledSensorStateFlagProps: ISVGIconProps = {
        ...defaultLeafletMapIconProps,
        fillColors: [ProjectStateColorGray],
        iconSize: L.point(16, 24),
        iconAnchor: L.point(0, 0)
    };

    items.push({
        icon: withSvgF(projectStateFlagPrimitive(disabledSensorStateFlagProps)),
        label: i18next.t("Some sensors are disabled")
    });

    const badSensorStateFlagProps: ISVGIconProps = {
        ...defaultLeafletMapIconProps,
        fillColors: [SensorStateColorAlarm],
        iconSize: L.point(16, 24),
        iconAnchor: L.point(0, 0)
    };

    items.push({
        icon: withSvgF(projectStateFlagPrimitive(badSensorStateFlagProps)),
        label: i18next.t("(Any color) Some sensor values are in alarm state")
    });

    return createProjectsOverviewMapLegend(items);
}

export const createProjectOverviewSensorsLegend = (projectId: number): L.Control => {
    const legendItems = {
        "Sensor is ok": ProjectStateColorGreen,
        "Sensor disabled": ProjectStateColorLightGray,
        "Sensor not measured": ProjectStateColorViolet
    };

    if (AuthService.isAllowedShowSensorWarningState(projectId)) {
        legendItems["Sensor is in warning state"] = SensorStateColorWarning;
    }

    legendItems["(Any color) Sensor is in alarm state"] = SensorStateColorAlarm;

    const items: Array<{ icon: string, label: string }> = [];

    for (const property of Object.keys(legendItems)) {
        const props: ISVGIconProps = {
            ...defaultLeafletMapIconProps,
            fillColors: [legendItems[property]],
            iconAnchor: L.point(9, 9)
        };

        items.push({
            icon: withSvgF(circleIconPrimitive(props)),
            label: i18next.t(property)
        });
    }

    return createProjectsOverviewMapLegend(items);
}

interface IMapSectionViewLegendProps {
    showArrowItem: boolean;
    arrowLength: number;
    arrowDesc: string;

    showBarItem: boolean;
    barHeight: number;
    barDesc: string;

    barNegativeColor: string;
    barPositiveColor: string;
    arrowColor: string
}

export const getMapSectionViewLegend = ({
    showArrowItem,
    arrowLength,
    arrowDesc,

    showBarItem,
    barHeight,
    barDesc,

    arrowColor,
    barNegativeColor,
    barPositiveColor
}: IMapSectionViewLegendProps): L.Control => {
    const items: Array<{ icon: string, label: string }> = [];

    const className = "legend-item-svg";
    const svgWidth = (arrowLength === 0 ? 18 : arrowLength) + 2;

    if (showArrowItem) {
        const arrowY = 12;
        const headYOffset = 5;
        const headEndX = arrowLength - 7;

        const svg =
            `<line x1="0" y1="${arrowY}" x2="${arrowLength}" y2="${arrowY}" stroke-width="2" stroke="${arrowColor}" fill="none" stroke-linecap="round" stroke-linejoin="round" />` +
            `<polyline points="${headEndX},${arrowY - headYOffset} ${arrowLength},${arrowY} ${headEndX},${arrowY + headYOffset}" stroke-width="2" stroke="${arrowColor}" fill="none" stroke-linecap="round" stroke-linejoin="round" />`;


        items.push({
            icon: withSvg({ width: svgWidth, height: 24, className })(svg),
            label: arrowDesc
        });
    }

    if (showBarItem) {
        const startX = 3;
        const startY = 2;
        const barWidth = 15;

        //const svg = `<polygon points="${startX},${startY} ${startX + barWidth},${startY} ${startX + barWidth},${startY + barHeight} ${startX},${startY + barHeight}" stroke-width="1" stroke="black" fill="#91bfdb" stroke-linecap="round" stroke-linejoin="round" />`;

        const dividedSvg = `<path
        style="fill:${barNegativeColor};stroke:#000000;stroke-width:1;stroke-opacity:1;fill-opacity:1"
        d="M ${startX},${startY} V ${startY + barHeight} H ${startX + barWidth} Z"/>
     <path
        style="fill:${barPositiveColor};fill-opacity:1;stroke:#000000;stroke-width:1;stroke-opacity:1"
        d="M ${startX + barWidth},${startY + barHeight} V ${startY} H ${startX} Z"/>`

        items.push({
            icon: withSvg({ width: svgWidth, height: barHeight + 4, className })(dividedSvg),
            label: barDesc
        });
    }

    return items.length > 0 ? createMapSectionViewLegend(items, 'map-section-view-legend') : null;
}

export const getProjectViewMovementLegend = ({
    showArrowItem,
    arrowLength,
    arrowDesc,

    showBarItem,
    barHeight,
    barDesc,

    arrowColor,
    barNegativeColor,
    barPositiveColor
}: IMapSectionViewLegendProps): L.Control => {
    const items: Array<{ icon: string, label: string }> = [];

    const className = "legend-item-svg";
    const svgWidth = (arrowLength === 0 ? 18 : arrowLength) + 2;

    if (showArrowItem) {
        const arrowY = 12;
        const headYOffset = 5;
        const headEndX = arrowLength - 7;

        const svg =
            `<line x1="0" y1="${arrowY}" x2="${arrowLength}" y2="${arrowY}" stroke-width="2" stroke="${arrowColor}" fill="none" stroke-linecap="round" stroke-linejoin="round" />` +
            `<polyline points="${headEndX},${arrowY - headYOffset} ${arrowLength},${arrowY} ${headEndX},${arrowY + headYOffset}" stroke-width="2" stroke="${arrowColor}" fill="none" stroke-linecap="round" stroke-linejoin="round" />`;


        items.push({
            icon: withSvg({ width: svgWidth, height: 24, className })(svg),
            label: arrowDesc
        });
    }

    if (showBarItem) {
        const startX = 3;
        const startY = 2;
        const barWidth = 15;

        //const svg = `<polygon points="${startX},${startY} ${startX + barWidth},${startY} ${startX + barWidth},${startY + barHeight} ${startX},${startY + barHeight}" stroke-width="1" stroke="black" fill="#91bfdb" stroke-linecap="round" stroke-linejoin="round" />`;

        const dividedSvg = `<path
        style="fill:${barNegativeColor};stroke:#000000;stroke-width:1;stroke-opacity:1;fill-opacity:1"
        d="M ${startX},${startY} V ${startY + barHeight} H ${startX + barWidth} Z"/>
     <path
        style="fill:${barPositiveColor};fill-opacity:1;stroke:#000000;stroke-width:1;stroke-opacity:1"
        d="M ${startX + barWidth},${startY + barHeight} V ${startY} H ${startX} Z"/>`

        items.push({
            icon: withSvg({ width: svgWidth, height: barHeight + 4, className })(dividedSvg),
            label: barDesc
        });
    }

    return items.length > 0 ? createMapSectionViewLegend(items, 'project-view-movements-legend') : null;
}