/**
 * The Geovis Component implementation
 */
import React from 'react'
import AuthService from '../../services/AuthService';
import Logger from '../../services/Logger';
import { IRequestHelper } from '../../services/RequestHelper';
import IGeovisComponent from './IGeovisComponent';

export type TServerResult = true | string;

/**
 * Base geovis page component, non-functional
 */
export default abstract class GeovisComponent<TProps, TState = {}>
    extends React.Component<TProps, TState>
    implements IGeovisComponent {

    // /** @deprecated use IWithGeovisServerProps, withGeovisServer HOC and fetchProjectElements to get some data */
    protected readonly Server: IRequestHelper;

    private isGeovisComponentMounted: boolean;

    constructor(props: TProps) {
        super(props);

        this.isGeovisComponentMounted = false;
        this.Server = AuthService.getRequestHelper(this);
    }

    public componentDidMount = async () => {
        Logger.trace(`mounting...`, this.LoggerName());

        this.isGeovisComponentMounted = true;
        await this.geovisComponentDidMount();

        Logger.trace(`did mounted`, this.LoggerName());
    }

    public componentWillUnmount = async () => {
        Logger.trace(`unmounting...`, this.LoggerName());

        this.Server.cancelAllRequests();

        await this.geovisComponentWillUnmount();
        this.isGeovisComponentMounted = false;

        Logger.trace('unmounted', this.LoggerName());
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public UNSAFE_componentWillReceiveProps = async (nextProps: Readonly<TProps>, nextContext: any) => {
        await this.geovisComponentWillReceiveProps(nextProps);
    }

    public componentDidUpdate = async (prevProps: Readonly<TProps>) => {
        await this.geovisComponentDidUpdate(prevProps);
    }

    /** @deprecated we going to use functional components with Redux */
    public isComponentMounted = () => this.isGeovisComponentMounted;

    /** @deprecated we going to use functional components with Redux */
    protected abstract geovisComponentDidMount(): Promise<void>;

    /** @deprecated we going to use functional components with Redux */
    protected abstract geovisComponentWillUnmount(): Promise<void>;

    /** @deprecated we going to use functional components with Redux */
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-unused-vars
    protected geovisComponentWillReceiveProps = async (nextProps: Readonly<TProps>) => { /* empty body */ }

    /** @deprecated we going to use functional components with Redux */
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-unused-vars
    protected geovisComponentDidUpdate = async (prevProps: Readonly<TProps>) => {/* empty body */ }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    protected LoggerName = () => this.constructor.name;
}