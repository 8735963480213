/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.10.2022
 * @description User profile projects relations/assignments
 */

import Button from '@atlaskit/button';
import { HeadType, RowType } from '@atlaskit/dynamic-table/types';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { useGeovisDialogVisibilityHook, useGeovisDialogVisibilityHookWithState } from '../../helpers/Hooks.Toggles';
import { t } from '../../i18n';
import { GeovisUserProjectRelationModel } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserProjectRelationModel';
import { GeovisUserRoleEnum } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserRoleEnum';
import { GeovisUserToProjectRole, getGeovisUserToProjectRoleToDescription } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserToProjectRole';
import { GeovisUserProfileInfo } from '../../server/GEOvis3/Model/User/GeovisUserProfileInfo';
import { ProjectBusinessInfo } from '../../server/ProjectBusinessInfo';
import AuthService from '../../services/AuthService';
import { IBusinessProjectsStorage } from '../../store/businessData.types';
import { GeovisDynamicTable } from '../GeovisDynamicTable';
import { SelectUserToProjectRole } from '../select/SelectUserToProjectRole';
import { DowngradeUserToProjectRoleDialog } from './DowngradeUserToProjectRelationDialog';
import { SelectProjectsDialog } from './SelectProjectsDialog';
import { UserEditorMode } from './types';

interface IComponentProps {
    user: GeovisUserProfileInfo;

    editMode: UserEditorMode;
    projectsStorage: IBusinessProjectsStorage;

    onProjectRelationChanged: (projectId: number, role: GeovisUserToProjectRole) => void;
    onAddProjectRelations: (projectRelationsInfo: GeovisUserProjectRelationModel[]) => void;
    onDeleteProjectRelation: (projectId: number) => void;
}


const getTableHead = (): HeadType => ({
    cells: [{
        key: 'projectNumber',
        content: t("Project number"),
        isSortable: true
    }, {
        key: 'projectName',
        content: t("Project name"),
        isSortable: true
    }, {
        key: 'role',
        content: t("Role")
    }, {
        key: 'actions',
        content: t("Actions")
    }]
});


export const UserProfileEditorProjectRelationsTab = ({
    user,
    editMode,
    onAddProjectRelations,
    onDeleteProjectRelation,
    onProjectRelationChanged,
    projectsStorage
}: IComponentProps) => {

    const canEditProjectRelations = editMode === UserEditorMode.Edit || editMode === UserEditorMode.EditProjectRelation;

    const [showAddProjectDialog, onShowAddProjectDialog, onHideAddProjectDialog] = useGeovisDialogVisibilityHook();
    const [showDowngradeRelationDialog, onShowDowngradeRelationDialog, onHideDowngradeRelationDialog, downgradeRelationState] = useGeovisDialogVisibilityHookWithState<{
        projectId: number,
        sourceRole: GeovisUserToProjectRole,
        destRole: GeovisUserToProjectRole
    }>({
        projectId: 0,
        destRole: GeovisUserToProjectRole.Viewer,
        sourceRole: GeovisUserToProjectRole.Viewer
    });

    /**
     * Check is edit relations of this project is allowed
     * @param relation 
     * @returns 
     */
    const canEditCurrentRelation = (relation: GeovisUserProjectRelationModel): boolean => {

        if (!canEditProjectRelations) {
            return false;
        }

        const { isLoading, isError, projects } = projectsStorage;
        if (isLoading || isError) {
            return false;
        }

        const project = projects.get(relation.ProjectId);
        if (!project) {
            return false;
        }

        if (AuthService.hasUserTypeAsAdmin() || relation.Role === GeovisUserToProjectRole.Admin) {
            return true;
        }

        const isAdminOfProjectsCompany = project
            ? AuthService.hasUserTypeAsCompanyAdmin() && (project.CreatorCompaniesIds.includes(AuthService.currentUserCompanyId()) || AuthService.currentUserCompanyId() === project.OwnerCompanyId)
            : false;

        return isAdminOfProjectsCompany;
    }

    /**
     * Process change relation event
     * @param projectId
     * @param prevRole
     * @returns 
     */
    const onProjectRelationChangedHandlerFunc = (projectId: number, prevRole: GeovisUserToProjectRole) => (newRole: GeovisUserToProjectRole) => {

        if (prevRole === newRole) {
            return;
        }

        const downgradingSelfRole = (prevRole > newRole) && (user.Id === AuthService.currentUserId());

        if (!downgradingSelfRole) {
            onProjectRelationChanged(projectId, newRole);
            return;
        }

        onShowDowngradeRelationDialog({ projectId, sourceRole: prevRole, destRole: newRole });
    }

    /**
     * Delete relation to the project for user
     * @param projectId 
     * @returns 
     */
    const onRemoveProjectRelationFunc = (projectId: number) => () => onDeleteProjectRelation(projectId);

    /**
     * Get project relations table rows
     * @returns 
     */
    const getTableRows = (): RowType[] => user.ProjectRelationInfos.map<RowType>((relation, index) => {

        const isRelationEditable = canEditCurrentRelation(relation);
        const projectInfo = projectsStorage.projects.get(relation.ProjectId);

        const projectName = projectInfo ? projectInfo.Name : "Unknown project";
        const projectNumber = projectInfo ? projectInfo.Number : "";

        let showAdminInSelection = true;

        if (!projectInfo) {
            showAdminInSelection = false;
        }
        else {
            showAdminInSelection = (user.GeovisUserRole >= GeovisUserRoleEnum.Admin) || user.CompanyId === projectInfo.OwnerCompanyId || projectInfo.CreatorCompaniesIds.includes(user.CompanyId);
        }

        return {
            key: `row-${index}`,
            cells: [{
                key: projectNumber,
                content: <div>{projectNumber}</div>
            }, {
                key: projectName,
                content: <div>{projectName}</div>
            }, {
                key: relation.Role,
                content: (
                    <div style={{ minWidth: '160px' }}>
                        {isRelationEditable && (
                            <SelectUserToProjectRole
                                relation={relation}
                                onChange={onProjectRelationChangedHandlerFunc(relation.ProjectId, relation.Role)}
                                needEmailReceiver={true}
                                needViewer={true}
                                needAdmin={showAdminInSelection}
                            />
                        )}
                        {!isRelationEditable && (
                            <div style={{ margin: '10px' }}>
                                {getGeovisUserToProjectRoleToDescription(relation.Role)}
                            </div>
                        )}
                    </div>
                )
            }, {
                content: (
                    <div>
                        {isRelationEditable && (
                            <Button
                                spacing="none"
                                appearance="subtle-link"
                                title={t("Remove relation")}
                                iconBefore={<TrashIcon label={""} />}
                                onClick={onRemoveProjectRelationFunc(relation.ProjectId)}
                            />
                        )}
                    </div>)
            }]
        }
    });

    /**
     * Add new projects to relation and hide the dialog
     * @param projectInfoList 
     */
    const onSelectProjectsCompleteHandler = (projectInfoList: ProjectBusinessInfo[]) => {
        if (projectInfoList && projectInfoList.length > 0) {

            const projectRelationsInfo = projectInfoList.map<GeovisUserProjectRelationModel>(m => ({ ProjectId: m.Id, ProjectName: m.Name, ProjectNumber: m.Number, Role: GeovisUserToProjectRole.Viewer }));
            onAddProjectRelations(projectRelationsInfo);
        }

        onHideAddProjectDialog();
    }

    /**
     * Execute downgrade 
     */
    const onDowngradeProjectRelationHandler = () => {
        const { projectId, destRole } = downgradeRelationState;

        onProjectRelationChanged(projectId, destRole);
        onHideDowngradeRelationDialog();
    }

    const getExcludedProjectsIds = (): number[] => {
        const result = user.ProjectRelationInfos.map(m => m.ProjectId);

        if (AuthService.hasUserTypeAsAdmin())
            return result;

        projectsStorage.projects.forEach((project, projectId) => {
            if (result.includes(projectId))
                return;

            if (AuthService.currentUserRole() === GeovisUserRoleEnum.CompanyAdmin) {
                const companyId = AuthService.currentUserCompanyId();

                if (!companyId) {
                    result.push(projectId);
                    return;
                }

                if (project.OwnerCompanyId !== companyId && !project.CreatorCompaniesIds.includes(companyId)) {
                    result.push(projectId);
                    return;
                }

                // if (!project.IsPartOfGEOvis4)
                //     return;
            }
            else {
                if (!AuthService.isRealAdminOfProject(projectId)) {
                    result.push(projectId);
                    return;
                }
            }



        });

        return result;
    }

    return (
        <div style={{ width: '100%' }}>
            <div className='flexRowContainer'>
                <Button
                    onClick={onShowAddProjectDialog}>
                    {t("Add")}
                </Button>
            </div>
            <div className='flexRowMiddleContainer'>
                <GeovisDynamicTable
                    head={getTableHead()}
                    rows={getTableRows()}
                    defaultSortKey="projectNumber"
                    defaultSortOrder="ASC"
                />
            </div>
            {showAddProjectDialog && (
                <SelectProjectsDialog
                    excludeProjectIds={getExcludedProjectsIds()}
                    projectsStorage={projectsStorage}
                    onSave={onSelectProjectsCompleteHandler}
                    onCancel={onHideAddProjectDialog} />
            )}
            {showDowngradeRelationDialog && (
                <DowngradeUserToProjectRoleDialog
                    {...downgradeRelationState}
                    onOkClick={onDowngradeProjectRelationHandler}
                    onCancelClick={onHideDowngradeRelationDialog}
                />
            )}
        </div>
    )
}