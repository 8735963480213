/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @create 31.08.2020
 * @description Sensors data helper methods
 */

import { elementsToMap } from "../../helpers/StorageHelper";
import { SensorCategory } from "../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { Geovis4SensorsSymbolSettings } from "../../server/AVTService/TypeLibrary/Sensors/SymbolSettings/Geovis4SensorsSymbolSettings";
import { Geovis4SensorSymbolSettings } from "../../server/AVTService/TypeLibrary/Sensors/SymbolSettings/Geovis4SensorSymbolSettings";
import { ChainMeasurementInfo } from "../../server/ChainMeasurementInfo";
import { DataActionResponse } from "../../server/DataActionResponse";
import { IChainsLastDataStorage, ISensorsSymbolsStorage } from "../data.types";
import { errorSomethingStorageState, ISomethingStoreBase, loadedSomethingStorageState } from "../types";

/**
 * Process fetched data
 * @param fetchedData 
 * @param originalStorage 
 */
export const processFetchedData = <TData, TStorage extends ISomethingStoreBase>(
    fetchedData: DataActionResponse<TData> | undefined,
    originalStorage: TStorage,
    initialState: TStorage,
    fetchedDataToMap: (data: TData) => Omit<TStorage, keyof ISomethingStoreBase>): TStorage => {

    if (!fetchedData) {
        return originalStorage;
    }

    if (!fetchedData.Success) {
        return {
            ...originalStorage,
            ...initialState,
            ...errorSomethingStorageState(fetchedData.Messages.join('. '))
        }
    }

    return {
        ...initialState,
        ...loadedSomethingStorageState,
        ...fetchedDataToMap(fetchedData.Data)
    }
}

/**
 * Process fetched data to map, useful in case of sub-data sets
 * @param fetchedData 
 * @param originalMap 
 * @param initialState 
 */
export const processFetchedDataToMap = <TKey, TData extends { Id: TKey }>(
    fetchedData: DataActionResponse<TData[]> | undefined,
    originalMap: Map<TKey, TData>,
    initialState: Map<TKey, TData>
): Map<TKey, TData> => {

    if (!fetchedData) {
        return originalMap;
    }

    if (!fetchedData.Success) {
        return initialState;
    }

    return elementsToMap<TKey, TData>(fetchedData.Data);
}

/**
 * Convert sensors symbols settings to storage format
 * @param symbolSettings 
 */
export const convertSensorsSymbolsSettings = (symbolSettings: Geovis4SensorsSymbolSettings): Omit<ISensorsSymbolsStorage, keyof ISomethingStoreBase> => {

    const iconSettings = new Map<SensorCategory, Geovis4SensorSymbolSettings>();

    if (symbolSettings.SymbolSettings) {
        symbolSettings.SymbolSettings.forEach(option => {
            iconSettings.set(option.Category, option);
        });
    }

    return {
        ...loadedSomethingStorageState,
        groupNearSensors: symbolSettings.GroupNearSensors,
        radiusOfNearSensorsInPixels: symbolSettings.RadiusOfNearSensorsInPixels,
        iconSettings
    };
}

/**
 * Convert chains last measurements to storage format
 * @param records 
 */
export const convertChainsLastMeasurementsToStorage = (records: ChainMeasurementInfo[]): Omit<IChainsLastDataStorage, keyof ISomethingStoreBase> => {

    const chainsLastMeasurements = new Map<string, ChainMeasurementInfo>();
    records.forEach(meas => {
        chainsLastMeasurements.set(meas.FullId, meas);
    });

    return { chainsLastMeasurements };
}