import L from 'leaflet';

var mapResetView = L.Map.prototype._resetView;

L.Map.include({
    _resetView: function (center, zoom, noMoveStart) {
        //console.log(this.)
        if (!this._mapPane) {
            return;
        }
        mapResetView.call(this, center, zoom, noMoveStart);
    }
});

var getMapPanePos = L.Map.prototype._getMapPanePos;

L.Map.include({
    _getMapPanePos: function () {
        //console.log(this.)
        if (!this._mapPane) {
            return new L.Point(0, 0);
        }
        return getMapPanePos.call(this);
    }
});

var ranPanBy = L.Map.prototype._rawPanBy;

L.Map.include({
    _rawPanBy: function (offset) {
        if (!this._mapPane) {
            return;
        }
        ranPanBy.call(this, offset);
    }
})