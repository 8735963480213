import { Point } from "leaflet";
import { SensorMapTextOrientation } from "../../server/AVTService/TypeLibrary/Common/SensorMapTextOrientation";
import { SensorSymbol } from "../../server/AVTService/TypeLibrary/Sensors/SensorSymbol";

const getMapTextOrientationLeft = (text: string, iconWidth: number, fontSize: number, textOrientation: SensorMapTextOrientation, noScale: boolean, showBorder: boolean, sensorSymbol?: SensorSymbol, addition?: number) => {
    // remove <div> blocks
    let noDivText = text.replaceAll("<div>", "");
    // remove <div... (with styles) blocks
    while (noDivText.startsWith("<div")) {
        const closeDivIndex = noDivText.indexOf(">");
        noDivText = noDivText.substring(closeDivIndex + 1);
    }
    const splitedText = noDivText.split("</div>");
    const textLengthPixels = Math.max(...splitedText.map(s => getWidthOfText(s, fontSize)));
    const multiplier = noScale ? 1 : 1.5;
    let commonAddition = 0;
    if (showBorder) {
        //+2 for border and +2 for padding
        commonAddition = 4;
    }

    if (addition) {
        commonAddition += addition;
    }

    if (sensorSymbol !== undefined) {
        switch (sensorSymbol) {
            case SensorSymbol.Plus:
            case SensorSymbol.TriangleLeft:
            case SensorSymbol.TriangleDown:
            case SensorSymbol.Romb:
            case SensorSymbol.TriangleUp: commonAddition += 4; break;
            case SensorSymbol.Star: commonAddition += 9; break;
        }
    }

    switch (textOrientation) {
        case SensorMapTextOrientation.North:
        case SensorMapTextOrientation.South:
            // iconWidth * multiplier / 2 (to get center we need just half of width) 
            // -6.5 (icon anchor) - textLengthPixels /2 (move text to the left on half of it size)
            return iconWidth * 0.5 * multiplier - (textLengthPixels + commonAddition) / 2;

        case SensorMapTextOrientation.West:
        case SensorMapTextOrientation.NorthWest:
        case SensorMapTextOrientation.SouthWest:
            return - textLengthPixels - commonAddition - 4;

        case SensorMapTextOrientation.NorthEast:
        case SensorMapTextOrientation.SouthEast:
        case SensorMapTextOrientation.East:
            return iconWidth * multiplier + 2;

        default:
            return iconWidth * multiplier + 2;
    }
}

const getWidthOfText = (text: string, fontSize: number) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        return 0;
    }

    context.font = `${fontSize}px sans-serif`;

    return context.measureText(text).width;
}

const getMapTextOrientationTop = (iconHeight: number, textOrientation: SensorMapTextOrientation, linesUnder: number, symbolHeight: number, iconAnchor: Point, noScale: boolean, showBorder: boolean, sensorSymbol?: SensorSymbol, addition?: number) => {
    const singleFontPixelHeight = 1.5;
    const pixelSymbolHeight = symbolHeight * singleFontPixelHeight;
    let commonAddition = 0;
    if (showBorder) {
        //+2 for border and +2 for padding
        commonAddition = 4;
    }
    if (addition) {
        commonAddition += addition;
    }
    const countLines = linesUnder + 1;

    const multiplier = noScale ? 1 : 1.5;

    if (sensorSymbol !== undefined) {
        switch (sensorSymbol) {
            case SensorSymbol.Plus:
            case SensorSymbol.TriangleDown:
            case SensorSymbol.Romb:
            case SensorSymbol.TriangleLeft:
            case SensorSymbol.TriangleRight:
            case SensorSymbol.TriangleUp:
            case SensorSymbol.Star: commonAddition += 8; break;
        }
    }

    switch (textOrientation) {
        case SensorMapTextOrientation.North:
        case SensorMapTextOrientation.NorthEast:
        case SensorMapTextOrientation.NorthWest:
            return -countLines * pixelSymbolHeight - commonAddition - 1;

        case SensorMapTextOrientation.South:
        case SensorMapTextOrientation.SouthEast:
        case SensorMapTextOrientation.SouthWest:
            return iconHeight * multiplier + 1;

        default:
            return - 0.5 * (countLines * pixelSymbolHeight + commonAddition) + iconHeight * 0.5 * multiplier;
    }
}

interface IMapTextOrientationProps {
    iconSize: Point;
    fontSize: number;
    iconText: string;
    textOrientation: SensorMapTextOrientation | false;
    linesUnder: number;
    iconAnchor: Point;
    noScale: boolean;
    showBorder: boolean;
    sensorSymbol?: SensorSymbol;
    topAddition?: number;
    leftAddition?: number;
}

export const getMapTextOrientationCssProps = ({
    iconSize,
    fontSize,
    iconText,
    textOrientation,
    linesUnder,
    iconAnchor,
    noScale,
    showBorder,
    sensorSymbol,
    leftAddition,
    topAddition
}: IMapTextOrientationProps) => {
    if (textOrientation === false) {
        return 'margin-left: 0.5em;';
    }

    return `top: ${getMapTextOrientationTop(iconSize.y, textOrientation, linesUnder, fontSize, iconAnchor, noScale, showBorder, sensorSymbol, topAddition)}px;
            left: ${getMapTextOrientationLeft(iconText, iconSize.x, fontSize, textOrientation, noScale, showBorder, sensorSymbol, leftAddition)}px`;
}