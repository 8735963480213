//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum RelationChangeState {
	Added = 0,
	Changed = 1,
	Removed = 2
}

const RelationChangeStateToName = new Map<number, string>([
	[ 0, 'Added' ],
	[ 1, 'Changed' ],
	[ 2, 'Removed' ],
])

export function getRelationChangeStateToName(element: RelationChangeState) : string {
    return RelationChangeStateToName.get(element) || '';
}

const RelationChangeStateByName = new Map<string, number>([
	[ 'Added', 0 ],
	[ 'Changed', 1 ],
	[ 'Removed', 2 ],
]);

export function getRelationChangeStateByName(name: string): RelationChangeState {
    return RelationChangeStateByName.get(name)!;
}


export const RelationChangeStateList : Readonly<RelationChangeState[]> = [
	RelationChangeState.Added,
	RelationChangeState.Changed,
	RelationChangeState.Removed,
];



const RelationChangeStateToDescription = new Map<number, string>([
	[ 0, 'Added' ],
	[ 1, 'Changed' ],
	[ 2, 'Removed' ],
])

export function getRelationChangeStateToDescription(element: RelationChangeState) : string {
    return RelationChangeStateToDescription.get(element) || '';
}

