import { MaxMapZoomLevel, MaxNativeZoomLevel } from "../components/map/types";
import { getMapProvidersToDescription, MapProviders, MapProvidersList } from "../server/AVTService/TypeLibrary/Map/MapProviders";
import { GeoLocation } from "../server/GeoLocation";

export type CountryCode = "AUT" | "CHE" | "DEU" | "All";

export interface ICountryBox {
    latitudeMin: number;
    latitudeMax: number;
    longitudeMin: number;
    longitudeMax: number;
    countryCode: CountryCode;
}

export default class MapProviderHelper {

    public static mapProvidersByCountries = (): Map<CountryCode, MapProviders[]> => {
        return new Map([
            ["All", [MapProviders.CartoDBPositron, MapProviders.OSM, MapProviders.EsriWorldImagery]],
            ["AUT", [MapProviders.BasemapATGray, MapProviders.BasemapATOrtophoto]],
            ["DEU", [MapProviders.BasemapDE, MapProviders.BasemapDEGray]],
            ["CHE", [MapProviders.SwissTopoGray, MapProviders.SwissTopo, MapProviders.SwissTopoPhoto, MapProviders.SwissTopoOpenData]]
        ])
    }

    public static urlTemplateForMapProvider = (provider: MapProviders): string => {
        switch (provider) {
            case MapProviders.OSM: return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
            case MapProviders.EsriWorldTopo: return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}";
            case MapProviders.EsriWorldImagery: return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
            case MapProviders.CartoDBPositron: return "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png";
            case MapProviders.SwissTopo: return "https://wmts10.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg";
            case MapProviders.SwissTopoGray: return "https://wmts10.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-grau/default/current/3857/{z}/{x}/{y}.jpeg";
            case MapProviders.SwissTopoPhoto: return "https://wmts10.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg";
            case MapProviders.SwissTopoCadastral: return "https://wmts10.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg";
            // case MapProviders.SwissTopoOpenData: return "https://wfs.geodienste.ch/av_0/deu";
            // case MapProviders.SwissTopoOpenData: return "https://wfs.geodienste.ch/avc_0/deu";
            case MapProviders.SwissTopoOpenData: return "https://wfs.geodienste.ch/av_situationsplan_0/deu";
            // case MapProviders.SwissTopoOpenData: return "https://wfs.geodienste.ch/av_situationsplan_oereb_0/deu";
            case MapProviders.BasemapDEGray: return "https://sgx.geodatenzentrum.de/wmts_basemapde/tile/1.0.0/de_basemapde_web_raster_grau/default/GLOBAL_WEBMERCATOR/{z}/{y}/{x}.png";
            case MapProviders.BasemapDE: return "https://sgx.geodatenzentrum.de/wmts_basemapde/tile/1.0.0/de_basemapde_web_raster_farbe/default/GLOBAL_WEBMERCATOR/{z}/{y}/{x}.png";
            case MapProviders.BasemapATGray: return "https://maps.wien.gv.at/basemap/bmapgrau/normal/google3857/{z}/{y}/{x}.png";
            case MapProviders.BasemapATOrtophoto: return "https://maps.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg";
            // https://sgx.geodatenzentrum.de/wmts_basemapde/tile/1.0.0/de_basemapde_web_raster_farbe/default/DE_EPSG_3857_ADV/06/662/1082.png
            // case MapProviderHelper.SwissTopoOpenData: return "https://wmts10.geo.admin.ch/1.0.0/ch.kantone.cadastralwebmap-farbe";
            // https://wfs.geodienste.ch/av_0/deu
            default: return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
        }
    }

    public static attributionForMapProvider = (provider: MapProviders) => {
        switch (provider) {
            case MapProviders.OSM: return "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors";
            case MapProviders.EsriWorldTopo: return "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community";
            case MapProviders.EsriWorldImagery: return "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community";
            case MapProviders.CartoDBPositron: return "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>";
            case MapProviders.SwissTopo: return "&copy; <a href='https://swisstopo.ch'>Swisstopo</a>";
            case MapProviders.SwissTopoGray: return "&copy; <a href='https://swisstopo.ch'>Swisstopo</a>";
            case MapProviders.SwissTopoPhoto: return "&copy; <a href='https://swisstopo.ch'>Swisstopo</a>";
            case MapProviders.SwissTopoCadastral: return "SwissTopo Cadastral";
            case MapProviders.SwissTopoOpenData: return "&copy; <a href='https://geodienste.ch'>Geodienste</a>";
            case MapProviders.BasemapDEGray: return "&copy; <a href=' https://basemap.de'>basemap.de</a>";
            case MapProviders.BasemapDE: return "&copy; <a href=' https://basemap.de'>basemap.de</a>";
            case MapProviders.BasemapATGray: return "&copy; <a href=' https://basemap.at'>basemap.at</a>";
            case MapProviders.BasemapATOrtophoto: return "&copy; <a href=' https://basemap.at'>basemap.at</a>";
            default: return "Unknown";
        }
    }

    public static getProviderMaxNativeZoomValue = (provider: MapProviders): number => {
        switch (provider) {

            case MapProviders.EsriWorldTopo:
            case MapProviders.CartoDBPositron: return MaxMapZoomLevel;

            case MapProviders.BasemapDEGray:
            case MapProviders.BasemapDE:
            case MapProviders.BasemapATGray:
            case MapProviders.SwissTopoCadastral:
            case MapProviders.SwissTopo:
            case MapProviders.SwissTopoGray:
            case MapProviders.OSM: return 19;

            case MapProviders.SwissTopoPhoto: return 20;

            case MapProviders.SwissTopoOpenData: return 22;

            case MapProviders.EsriWorldImagery:
            case MapProviders.BasemapATOrtophoto: return MaxNativeZoomLevel;

            default: return MaxNativeZoomLevel;
        }
    }

    public static defaultUrlForMapProvider = (): MapProviders => {
        return MapProviders.CartoDBPositron;
    }

    public static getProvider = (provider?: MapProviders): MapProviders => {
        if (!provider) {
            return this.defaultUrlForMapProvider();
        }
        return provider;
    }

    public static IsWMSLayer = (provider: MapProviders): boolean => {
        switch (provider) {
            case MapProviders.SwissTopoOpenData:
            case MapProviders.SwissTopoCadastral:
                return true;
            default:
                return false;
        }
    }

    public static getBaseLayerForWMSLayer = (provider: MapProviders): MapProviders => {
        switch (provider) {
            case MapProviders.SwissTopoOpenData:
            case MapProviders.SwissTopoCadastral:
                return MapProviders.SwissTopoGray;
            default:
                return this.defaultUrlForMapProvider();
        }
    }

    public static getAllLicenseProviders = (includeNone: boolean): MapProviders[] => {
        const providersByCountry = this.mapProvidersByCountries();
        const result = providersByCountry.get("All");
        if (!result) {
            return [];
        }
        if (includeNone) {
            result.push(MapProviders.None);
        }

        return result;
    }

    public static getProvidersByLocation = (includeNone: boolean, location?: GeoLocation): MapProviders[] => {

        const providersByCountry = this.mapProvidersByCountries();
        const allCountryProviders = providersByCountry.get("All");
        if (!allCountryProviders) {
            return [];
        }
        const result: MapProviders[] = [];
        if (includeNone) {
            result.push(MapProviders.None);
        }
        result.push(...allCountryProviders);

        if (!location) {
            return result;
        }

        this.getCountryBoxes().forEach(box => {
            if (this.IsInCountryBox(location, box)) {
                const countryProviders = providersByCountry.get(box.countryCode);
                if (countryProviders !== undefined) {
                    result.push(...countryProviders);
                }
            }
        });

        return result;
    }

    public static IsInCountryBox = (location: GeoLocation, box: ICountryBox): boolean => {
        return (location.Latitude >= box.latitudeMin && location.Latitude <= box.latitudeMax) && (location.Longitude >= box.longitudeMin && location.Longitude <= box.longitudeMax);
    }

    public static getCountryBoxes = (): ICountryBox[] => {
        return [{
            countryCode: "AUT",
            latitudeMin: 46.3722761,
            latitudeMax: 49.0205305,
            longitudeMin: 9.5307487,
            longitudeMax: 17.160776
        }, {
            countryCode: "CHE",
            latitudeMin: 45.817995,
            latitudeMax: 47.8084648,
            longitudeMin: 5.9559113,
            longitudeMax: 10.4922941
        }, {
            countryCode: "DEU",
            latitudeMin: 47.2701114,
            latitudeMax: 55.099161,
            longitudeMin: 5.8663153,
            longitudeMax: 15.0419319
        }]
    }

    public static getMapProviderByLayerName = (layerName: string) => {
        let result: MapProviders = MapProviders.CartoDBPositron;

        MapProvidersList.forEach(p => {
            if (getMapProvidersToDescription(p) === layerName) {
                result = p;
            }
        });

        return result;
    }
}