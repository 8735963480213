//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IPopupContent } from './IPopupContent';
import { GeoPoint } from './GeoPoint';
import { SensorCategory } from './AVTService/TypeLibrary/Sensors/SensorCategory';
import { PhysicalUnit } from './AVTService/TypeLibrary/Sensors/PhysicalUnit';
import { AlarmInfo } from './AlarmInfo';
import { SensorChartInfo } from './SensorChartInfo';
import { SensorReportInfo } from './SensorReportInfo';
import { SensorAlarmplanInfo } from './GEOvis3/Model/Sensors/SensorAlarmplanInfo';
import { SensorManufacturer } from './AVTService/TypeLibrary/Sensors/SensorManufacturer';
import { SensorAttribyteEntry } from './AVTService/TypeLibrary/Common/SensorAttribyteEntry';
import { SensorSymbol } from './AVTService/TypeLibrary/Sensors/SensorSymbol';
import { SensorMapTextOrientation } from './AVTService/TypeLibrary/Common/SensorMapTextOrientation';

export abstract class SensorBase extends GeoPoint implements IPopupContent
{
	public Id: string;
	public Name: string;
	public DatabaseName: string;
	public DatabaseId: string;
	public WatchdogEnabled: boolean;
	public PhysicalType: SensorCategory;
	public Unit: PhysicalUnit;
	public UnitDecimals: number;
	public CausedAlarms: AlarmInfo[];
	public Charts: SensorChartInfo[];
	public Reports: SensorReportInfo[];
	public DefaultCharts: SensorChartInfo[];
	public Alarms: SensorAlarmplanInfo[];
	public IsPublic: boolean;
	public Manufacturer: SensorManufacturer;
	public TunnelMeter: number;
	public LocalAngle: number;
	public LocalRadius: number;
	public LocX: number;
	public LocY: number;
	public ActualAxisReference: SensorAttribyteEntry;
	public UseColorOnMap: boolean;
	public SensorLink: string;
	public SensorLinkName: string;
	public Color: string;
	public Symbol: SensorSymbol;
	public MapTextOrientation: SensorMapTextOrientation;
}
