/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 26.10.2020
 * @description Inventory data reducer
 */

import { ItemId } from '@atlaskit/tree';
import { Reducer } from 'redux';
import { IProgressUploadFileInfo } from '../../components/FileBrowser';
import { addOrUpdateElementsInArray, elementsToMap, updateElementsInArray } from '../../helpers/StorageHelper';
import { EMPTY_PARENT_IT } from '../../pages/business/inventory/content/InventoryTreeControl';
import { InventoryObjectType } from '../../server/AVTService/TypeLibrary/Inventory/InventoryObjectType';
import { InventoryCalendarViewObjectsData } from '../../server/GEOvis3/Model/Inventory/InventoryCalendarViewObjectsData';
import { InventoryMstInfo } from '../../server/GEOvis3/Model/Inventory/InventoryMstInfo';
import { InventoryProjectInfo } from '../../server/GEOvis3/Model/Inventory/InventoryProjectInfo';
import { InventoryUsagePlanInfo } from '../../server/GEOvis3/Model/Inventory/InventoryUsagePlanInfo';
import {
    GEOVIS_INVENTORY_ADD,
    GEOVIS_INVENTORY_CALENDAR_FILTERS,
    GEOVIS_INVENTORY_CALENDAR_HIDE_RESERVATION_REMOVE,
    GEOVIS_INVENTORY_CALENDAR_HIDE_UP_ACTION_DIALOG,
    GEOVIS_INVENTORY_CALENDAR_SHOW_OPEN_PERIOD,
    GEOVIS_INVENTORY_CALENDAR_SHOW_RESERVATION_REMOVE,
    GEOVIS_INVENTORY_CALENDAR_SHOW_UP_ACTION_DIALOG,
    GEOVIS_INVENTORY_CALENDAR_UP_SHOW_EDIT,
    GEOVIS_INVENTORY_CALENDAR_VIEW_DATA,
    GEOVIS_INVENTORY_CALENDAR_VIEW_LOADING,
    GEOVIS_INVENTORY_CHANGE_STATE_DIALOG_COMPLITE,
    GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_HIDE,
    GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_SHOW,
    GEOVIS_INVENTORY_CHANGED_VIEW,
    GEOVIS_INVENTORY_DATA_ERROR,
    GEOVIS_INVENTORY_DATA_LOADED,
    GEOVIS_INVENTORY_DATA_LOADING,
    GEOVIS_INVENTORY_DESCRIPTIONS_ERROR,
    GEOVIS_INVENTORY_DESCRIPTIONS_LOADED,
    GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_HIDE,
    GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADED,
    GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING,
    GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING_ERROR,
    GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_SHOW,
    GEOVIS_INVENTORY_EXPAND_DIALOG_COMPLITE,
    GEOVIS_INVENTORY_EXPAND_DIALOG_HIDE,
    GEOVIS_INVENTORY_EXPAND_DIALOG_SHOW,
    GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_HIDE,
    GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_SHOW,
    GEOVIS_INVENTORY_HIDE_DIALOG,
    GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_HIDE,
    GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_SHOW,
    GEOVIS_INVENTORY_NODE_COLLAPSED,
    GEOVIS_INVENTORY_NODE_EXPANDED,
    GEOVIS_INVENTORY_PARENTS_LOADED,
    GEOVIS_INVENTORY_PROJECT_ID_CHANGED,
    GEOVIS_INVENTORY_PROJECTS,
    GEOVIS_INVENTORY_PROJECTS_LOADING,
    GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADED,
    GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING,
    GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING_ERROR,
    GEOVIS_INVENTORY_REMOVE,
    GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_HIDE,
    GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_SHOW,
    GEOVIS_INVENTORY_RESET_STORAGE,
    GEOVIS_INVENTORY_RV_HIDE_EDIT_DIALOG,
    GEOVIS_INVENTORY_RV_HIDE_REMOVE_DIALOG,
    GEOVIS_INVENTORY_RV_HIDE_TO_PROJECT_DIALOG,
    GEOVIS_INVENTORY_RV_LOADED,
    GEOVIS_INVENTORY_RV_LOADING,
    GEOVIS_INVENTORY_RV_SHOW_EDIT_DIALOG,
    GEOVIS_INVENTORY_RV_SHOW_REMOVE_DIALOG,
    GEOVIS_INVENTORY_RV_SHOW_TO_PROJECT_DIALOG,
    GEOVIS_INVENTORY_SET_OBJECT_TYPE,
    GEOVIS_INVENTORY_SET_UPLOAD_STATE,
    GEOVIS_INVENTORY_SHOW_CREATE,
    GEOVIS_INVENTORY_SHOW_EDIT_DIALOG,
    GEOVIS_INVENTORY_SHOW_EDIT_DIALOG_BY_UP_ID,
    GEOVIS_INVENTORY_SHOW_REMOVE_DIALOG,
    GEOVIS_INVENTORY_UP_CLOSE_EDIT_DIALOG,
    GEOVIS_INVENTORY_UP_CLOSE_LIST_VIEW,
    GEOVIS_INVENTORY_UP_CLOSE_REMOVE_DIALOG,
    GEOVIS_INVENTORY_UP_HIDE_CONFIRM_CHANGE_STATE_DIALOG,
    GEOVIS_INVENTORY_UP_HIDE_EXTEND_TIME_RANGE_DIALOG,
    GEOVIS_INVENTORY_UP_REMOVE_RECORD,
    GEOVIS_INVENTORY_UP_SAVE_RECORDS,
    GEOVIS_INVENTORY_UP_SHOW_CONFIRM_CHANGE_STATE_DIALOG,
    GEOVIS_INVENTORY_UP_SHOW_EDIT_DIALOG,
    GEOVIS_INVENTORY_UP_SHOW_EXTEND_TIME_RANGE_DIALOG,
    GEOVIS_INVENTORY_UP_SHOW_LIST_VIEW,
    GEOVIS_INVENTORY_UP_SHOW_REMOVE_DIALOG,
    GEOVIS_INVENTORY_UP_UPDATE_RECORDS,
    GEOVIS_INVENTORY_UPDATE,
    GEOVIS_INVENTORY_UPDATE_RESERVATIONS,
    GEOVIS_INVENTORY_USAGE_PLAN_RECORDS,
    GEOVIS_INVENTORY_USAGE_PLAN_RECORDS_DATA
} from '../actions/inventoryActions';
import { LOGIN_SUCCESS } from '../actions/navigationActions';
import { processFetchedData } from '../helpers/DataHelper';
import {
    IGeovisInventoryAction,
    IGeovisInventoryDataState,
    IGeovisInventoryUploadFileState,
    IInventoryProjectsStorage,
    InventoryViewAsTables
} from '../inventory.types';
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from '../types';
import {
    dispatchDataAddAction,
    dispatchDataErrorAction,
    dispatchDataLoadedAction,
    dispatchDataLoadingAction,
    dispatchDescriptionsErrorAction,
    dispatchDescriptionsLoadedAction,
    dispatchInventoryRemoveAction,
    dispatchInventoryUpdateAction
} from './inventoryReducer.tools';
import {
    inventoryAfmInitialState,
    inventoryAtcInitialState,
    inventoryCalendarDataInitialState,
    inventoryDescriptionsInitialState,
    inventoryLoRaGatewayInitialState,
    inventoryMstInitialState,
    inventoryPropertyHistoryInitialState,
    inventoryReservationInitialState,
    inventorySensorboxesInitialState,
    inventoryTachymetersInitialState,
    inventoryUsagePlanInitialState
} from './inventoryReducer.types';

const inventoryUploadInitialState: IGeovisInventoryUploadFileState = {
    complete: [],
    error: [],
    inProgress: false,
    processing: [],
    progress: []
};

const inventoryProjectsInitialState: IInventoryProjectsStorage = {
    ...defaultSomethingStorageState,
    projects: new Map<number, InventoryProjectInfo>()
}

export const inventoryDataInitialState: IGeovisInventoryDataState = {
    atc: { ...inventoryAtcInitialState },
    afm: { ...inventoryAfmInitialState },
    mst: { ...inventoryMstInitialState },
    loraGateway: { ...inventoryLoRaGatewayInitialState },
    sensorboxes: { ...inventorySensorboxesInitialState },
    tachy: { ...inventoryTachymetersInitialState },
    uploadState: { ...inventoryUploadInitialState },
    descriptions: { ...inventoryDescriptionsInitialState },
    projectsStorage: { ...inventoryProjectsInitialState },
    showDeleteDialog: false,
    showEditDialog: false,
    objectType: InventoryObjectType.MST,
    createRecordMode: false,
    inventoryView: InventoryViewAsTables,
    objectId: "",
    usagePlan: { ...inventoryUsagePlanInitialState },
    showUsagePlanListView: false,
    reservations: { ...inventoryReservationInitialState },
    propertyHistory: { ...inventoryPropertyHistoryInitialState },
    calendarData: { ...inventoryCalendarDataInitialState },
    requestSource: "",
    displayedObjectData: {
        ...defaultSomethingStorageState,
        inventoryObjectInfo: new InventoryMstInfo(),
        objectId: '',
        showDisplayDialog: false
    },
    showExpandDialog: false,
    showChangeStateDialog: false,
    showMoveToStorageDialog: false,
    showExpandProjectDurationDialog: false,
    showRemoveFromProjectDialog: false,
    inventoryObjectIDs: []
};

const inventoryDataReducer: Reducer<IGeovisInventoryDataState> = (
    state: IGeovisInventoryDataState = inventoryDataInitialState,
    action: IGeovisInventoryAction
): IGeovisInventoryDataState => {

    switch (action.type) {
        case LOGIN_SUCCESS:
        case GEOVIS_INVENTORY_RESET_STORAGE:
            return inventoryDataInitialState;

        case GEOVIS_INVENTORY_DATA_LOADING:
            return dispatchDataLoadingAction(state, action);

        case GEOVIS_INVENTORY_DATA_LOADED:
            return dispatchDataLoadedAction(state, action);

        case GEOVIS_INVENTORY_DATA_ERROR:
            return dispatchDataErrorAction(state, action);

        case GEOVIS_INVENTORY_DESCRIPTIONS_LOADED:
            return dispatchDescriptionsLoadedAction(state, action);

        case GEOVIS_INVENTORY_DESCRIPTIONS_ERROR:
            return dispatchDescriptionsErrorAction(state, action);

        case GEOVIS_INVENTORY_PROJECTS_LOADING:
            return {
                ...state,
                projectsStorage: inventoryProjectsInitialState
            }

        case GEOVIS_INVENTORY_PROJECTS:
            return {
                ...state,
                projectsStorage: processFetchedData(action.inventoryProjects, state.projectsStorage, inventoryProjectsInitialState, st => ({ projects: elementsToMap(st) }))
            }

        case GEOVIS_INVENTORY_CHANGED_VIEW:
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                inventoryView: action.stringProperty
            }

        case GEOVIS_INVENTORY_SHOW_EDIT_DIALOG_BY_UP_ID: {
            if (!action.stringProperty) {
                return state;
            }

            const usagePlanRecord = state.calendarData.records.find(r => r.Record.Id === action.stringProperty);
            if (!usagePlanRecord) {
                return state;
            }

            return {
                ...state,
                calendarData: {
                    ...state.calendarData,
                    showActionDialog: false,
                    recordId: ''
                },
                showEditDialog: true,
                objectId: usagePlanRecord.Record.InventoryObjectId,
                objectType: usagePlanRecord.Record.DeviceType
            }
        }

        case GEOVIS_INVENTORY_SHOW_EDIT_DIALOG:
        case GEOVIS_INVENTORY_SHOW_REMOVE_DIALOG:
            if (!action.objectId || action.objectType === undefined) {
                return state;
            }

            return {
                ...state,
                usagePlan: {
                    ...state.usagePlan,
                    recordId: ''
                },
                objectId: action.objectId,
                objectType: action.objectType,
                showEditDialog: action.type === GEOVIS_INVENTORY_SHOW_EDIT_DIALOG,
                showDeleteDialog: action.type === GEOVIS_INVENTORY_SHOW_REMOVE_DIALOG
            }

        case GEOVIS_INVENTORY_UPDATE:
            return {
                ...dispatchInventoryUpdateAction(state, action),
                showEditDialog: false,
                objectId: ''
            }

        case GEOVIS_INVENTORY_REMOVE:
            return {
                ...dispatchInventoryRemoveAction(state, action),
                showDeleteDialog: false,
                objectId: ''
            };

        case GEOVIS_INVENTORY_SHOW_CREATE:
            if (action.objectType === undefined) {
                return state;
            }

            return {
                ...state,
                createRecordMode: true,
                showEditDialog: true,
                objectType: action.objectType
            };

        case GEOVIS_INVENTORY_ADD:
            return {
                ...dispatchDataAddAction(state, action),
                showEditDialog: false,
                createRecordMode: false,
                objectId: ''
            };

        case GEOVIS_INVENTORY_HIDE_DIALOG:
            return {
                ...state,
                showDeleteDialog: false,
                showEditDialog: false,
                createRecordMode: false,
                objectId: ''
            };

        case GEOVIS_INVENTORY_SET_UPLOAD_STATE: {
            if (!action.uploadState) {
                return state;
            }

            const {
                inProgress,
                complete,
                error,
                processing,
                progress
            } = action.uploadState;

            if (inProgress === false) {
                return {
                    ...state,
                    uploadState: {
                        inProgress: false,
                        complete: [...complete || []],
                        error: [...error || []],
                        processing: [...processing || []],
                        progress: [...progress || []],
                    }
                }
            }

            const uploadState: IGeovisInventoryUploadFileState = {
                ...state.uploadState,
                inProgress: inProgress === undefined ? state.uploadState.inProgress : inProgress
            };

            if (progress && progress.length > 0) {
                if (uploadState.progress.length === 0) {
                    uploadState.progress = progress;
                    return { ...state, uploadState };
                }

                // update progress information
                uploadState.progress = uploadState.progress.map<IProgressUploadFileInfo>(r => {
                    const changed = progress.find(ch => ch.fileId === r.fileId);
                    return changed || r;
                });
            }

            // move file to processing stage, remove from progress
            if (processing && processing.length > 0) {
                const processingIds = processing.map(f => f.fileId);
                // remove files from progress step
                uploadState.progress = uploadState.progress.filter(f => processingIds.indexOf(f.fileId) === -1);

                // add to processing collection
                uploadState.processing = [...uploadState.processing, ...processing];
            }

            // completed
            if (complete && complete.length > 0) {
                const completeIds = complete.map(f => f.fileId);

                // move files to completed from processing and progress
                uploadState.processing = uploadState.processing.filter(f => completeIds.indexOf(f.fileId) === -1);
                uploadState.progress = uploadState.progress.filter(f => completeIds.indexOf(f.fileId) === -1);

                // add to complete collection
                uploadState.complete = [...uploadState.complete, ...complete];
            }

            // error
            if (error && error.length > 0) {
                const errorIds = error.map(f => f.fileId);

                // remove file from all, except of errors
                uploadState.processing = uploadState.processing.filter(f => errorIds.indexOf(f.fileId) === -1);
                uploadState.progress = uploadState.progress.filter(f => errorIds.indexOf(f.fileId) === -1);
                uploadState.complete = uploadState.complete.filter(f => errorIds.indexOf(f.fileId) === -1);

                // add to errors
                uploadState.error = [...uploadState.error, ...error];
            }

            return { ...state, uploadState };
        }

        case GEOVIS_INVENTORY_SET_OBJECT_TYPE:
            if (action.objectType === undefined) {
                return state;
            }

            return {
                ...state,
                objectType: action.objectType
            }

        case GEOVIS_INVENTORY_NODE_EXPANDED: {
            if (action.stringProperty === undefined) {
                return state;
            }
            const expand = state.descriptions.expandInfo;
            expand.set(action.stringProperty, true);
            return {
                ...state,
                descriptions: {
                    ...state.descriptions,
                    displayNodeId: action.booleanProperty ? action.stringProperty : state.descriptions.displayNodeId,
                    expandInfo: expand
                }
            }
        }
        case GEOVIS_INVENTORY_NODE_COLLAPSED: {
            if (action.stringProperty === undefined) {
                return state;
            }
            const expand = state.descriptions.expandInfo;
            expand.set(action.stringProperty, false);
            return {
                ...state,
                descriptions: {
                    ...state.descriptions,
                    expandInfo: expand
                }
            }
        }
        case GEOVIS_INVENTORY_PARENTS_LOADED: {
            if (!action.parents) {
                return state;
            }
            return {
                ...state,
                descriptions: {
                    ...state.descriptions,
                    possibleParents: action.parents
                }
            }
        }

        case GEOVIS_INVENTORY_PROJECT_ID_CHANGED:
            return {
                ...state,
                descriptions: {
                    ...state.descriptions,
                    displayNodeId: EMPTY_PARENT_IT,
                    expandInfo: new Map<ItemId, boolean>(),
                    projectId: action.numberProperty
                }
            }

        case GEOVIS_INVENTORY_UP_SHOW_EDIT_DIALOG:
            if (action.objectId === undefined) {
                return state;
            }

            return {
                ...state,
                usagePlan: {
                    ...state.usagePlan,
                    showEditDialog: true,
                    createEntryMode: action.booleanProperty || false,
                    recordId: action.objectId
                },
                calendarData: {
                    ...state.calendarData,
                    showEditDialog: true,
                    createEntryMode: action.booleanProperty || false,
                    recordId: action.objectId
                }
            }

        case GEOVIS_INVENTORY_UP_CLOSE_EDIT_DIALOG:

            return {
                ...state,
                usagePlan: {
                    ...state.usagePlan,
                    showEditDialog: false,
                    createEntryMode: false,
                    recordId: ''
                },
                calendarData: {
                    ...state.calendarData,
                    showEditDialog: false,
                    createEntryMode: false,
                    recordId: ''
                },
                requestSource: ''
            }

        case GEOVIS_INVENTORY_UP_SAVE_RECORDS: {
            if (!action.usagePlanData || !action.inventoryObjectInfo) {
                return state;
            }

            const { usagePlanData } = action;

            if (!usagePlanData.Success) {
                return state;
            }

            // const isCalendar = state.requestSource === "CALENDAR";

            const itemIds = usagePlanData.Data.map(d => d.Record.InventoryObjectId);
            const recordsIds = usagePlanData.Data.map(d => d.Record.Id);

            const updUsagePlanData = addOrUpdateElementsInArray(state.usagePlan.records, usagePlanData.Data, r => r.Record.Id)
                .filter(e => (recordsIds.includes(e.Record.Id) && itemIds.includes(e.Record.InventoryObjectId) || !itemIds.includes(e.Record.InventoryObjectId)));
            const updCalendarData = addOrUpdateElementsInArray(state.calendarData.records, usagePlanData.Data, r => r.Record.Id)
                .filter(e => (recordsIds.includes(e.Record.Id) && itemIds.includes(e.Record.InventoryObjectId) || !itemIds.includes(e.Record.InventoryObjectId)));


            return dispatchInventoryUpdateAction({
                ...state,
                calendarData: {
                    ...state.calendarData,
                    records: updCalendarData,
                    showEditDialog: false,
                    recordId: '',
                    createEntryMode: false,
                    showReservationItemRemoveDialog: false
                },
                usagePlan: {
                    ...state.usagePlan,
                    records: updUsagePlanData,
                    showEditDialog: false,
                    recordId: '',
                    createEntryMode: false
                },
                requestSource: ''
            }, action);
        }

        case GEOVIS_INVENTORY_UP_SHOW_LIST_VIEW:
            if (!action.objectId || action.objectType === undefined || action.requestSource === undefined) {
                return state;
            }

            return {
                ...state,
                showUsagePlanListView: true,
                calendarData: {
                    ...state.calendarData,
                    showActionDialog: false
                },
                objectId: action.objectId,
                objectType: action.objectType,
                requestSource: action.requestSource
            }

        case GEOVIS_INVENTORY_UP_CLOSE_LIST_VIEW:
            return {
                ...state,
                showUsagePlanListView: false,
                requestSource: '',
                objectId: '',
                objectType: state.objectType,
                calendarData: {
                    ...state.calendarData,
                    showActionDialog: false
                }
            }

        case GEOVIS_INVENTORY_UP_SHOW_REMOVE_DIALOG:
            if (!action.objectId) {
                return state;
            }

            return {
                ...state,
                usagePlan: {
                    ...state.usagePlan,
                    showRemoveDialog: true,
                    recordId: action.objectId
                }
            }

        case GEOVIS_INVENTORY_UP_CLOSE_REMOVE_DIALOG:
            return {
                ...state,
                usagePlan: {
                    ...state.usagePlan,
                    showRemoveDialog: false,
                    recordId: ''
                }
            }

        case GEOVIS_INVENTORY_UP_REMOVE_RECORD: {
            if (!action.objectId || !action.usagePlanData) {
                return state;
            }

            const { objectId, usagePlanData } = action;

            const itemIds = usagePlanData.Data.map(d => d.Record.InventoryObjectId);
            const recordsIds = usagePlanData.Data.map(d => d.Record.Id);

            const updUsagePlanData = addOrUpdateElementsInArray(state.usagePlan.records.filter(r => r.Record.Id !== objectId), usagePlanData.Data, r => r.Record.Id)
                .filter(e => (recordsIds.includes(e.Record.Id) && itemIds.includes(e.Record.InventoryObjectId) || !itemIds.includes(e.Record.InventoryObjectId)));
            const updCalendarData = addOrUpdateElementsInArray(state.calendarData.records.filter(r => r.Record.Id !== objectId), usagePlanData.Data, r => r.Record.Id)
                .filter(e => (recordsIds.includes(e.Record.Id) && itemIds.includes(e.Record.InventoryObjectId) || !itemIds.includes(e.Record.InventoryObjectId)));


            return {
                ...state,
                usagePlan: {
                    ...state.usagePlan,
                    showRemoveDialog: false,
                    recordId: '',
                    records: updUsagePlanData
                },
                calendarData: {
                    ...state.calendarData,
                    recordId: '',
                    records: updCalendarData
                }
            }
        }

        case GEOVIS_INVENTORY_USAGE_PLAN_RECORDS_DATA: {
            if (!action.usagePlanData) {
                return state;
            }

            return {
                ...state,
                usagePlan: processFetchedData(action.usagePlanData, state.usagePlan, inventoryUsagePlanInitialState, st => ({
                    ...inventoryUsagePlanInitialState,
                    ...loadedSomethingStorageState,
                    records: st,
                    inventoryObjects: { ...new InventoryCalendarViewObjectsData() }
                }))
            }
        }

        case GEOVIS_INVENTORY_CALENDAR_SHOW_UP_ACTION_DIALOG:
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                calendarData: {
                    ...state.calendarData,
                    showActionDialog: true,
                    recordId: action.stringProperty
                }
            }

        case GEOVIS_INVENTORY_CALENDAR_HIDE_UP_ACTION_DIALOG:
            return {
                ...state,
                calendarData: {
                    ...state.calendarData,
                    recordId: '',
                    showActionDialog: false
                }
            }


        case GEOVIS_INVENTORY_CALENDAR_VIEW_LOADING:
            return {
                ...state,
                calendarData: {
                    ...inventoryCalendarDataInitialState,
                    filters: state.calendarData.filters
                }
            }

        case GEOVIS_INVENTORY_CALENDAR_VIEW_DATA: {
            if (!action.calendarViewData) {
                return state;
            }

            return {
                ...state,
                projectsStorage: processFetchedData(action.calendarViewData, state.projectsStorage, inventoryProjectsInitialState, st => ({
                    projects: elementsToMap(st.ProjectsInfo)
                })),
                calendarData: processFetchedData(action.calendarViewData, state.calendarData, inventoryCalendarDataInitialState, st => ({
                    ...inventoryCalendarDataInitialState,
                    ...loadedSomethingStorageState,
                    records: st.UsagePlansInfo,
                    filters: state.calendarData.filters,
                })),
                reservations: processFetchedData(action.calendarViewData, state.reservations, inventoryReservationInitialState, st => ({
                    ...inventoryReservationInitialState,
                    ...loadedSomethingStorageState,
                    reservations: st.Reservations
                })),
                afm: processFetchedData(action.calendarViewData, state.afm, inventoryAfmInitialState, ({ InventoryObjectsData: { AFMs } }) => ({
                    ...inventoryAfmInitialState,
                    ...loadedSomethingStorageState,
                    data: { Records: AFMs, Total: AFMs.length }
                })),
                atc: processFetchedData(action.calendarViewData, state.atc, inventoryAtcInitialState, ({ InventoryObjectsData: { Atcs } }) => ({
                    ...inventoryAtcInitialState,
                    ...loadedSomethingStorageState,
                    data: { Records: Atcs, Total: Atcs.length }
                })),
                mst: processFetchedData(action.calendarViewData, state.mst, inventoryMstInitialState, ({ InventoryObjectsData: { Msts } }) => ({
                    ...inventoryMstInitialState,
                    ...loadedSomethingStorageState,
                    data: { Records: Msts, Total: Msts.length }
                })),
                loraGateway: processFetchedData(action.calendarViewData, state.loraGateway, inventoryLoRaGatewayInitialState, ({ InventoryObjectsData: { LoRaGateways } }) => ({
                    ...inventoryLoRaGatewayInitialState,
                    ...loadedSomethingStorageState,
                    data: { Records: LoRaGateways, Total: LoRaGateways.length }
                })),
                tachy: processFetchedData(action.calendarViewData, state.tachy, inventoryTachymetersInitialState, ({ InventoryObjectsData: { Tachymeters } }) => ({
                    ...inventoryTachymetersInitialState,
                    ...loadedSomethingStorageState,
                    data: { Records: Tachymeters, Total: Tachymeters.length }
                })),
                sensorboxes: processFetchedData(action.calendarViewData, state.sensorboxes, inventorySensorboxesInitialState, ({ InventoryObjectsData: { SBs } }) => ({
                    ...inventorySensorboxesInitialState,
                    ...loadedSomethingStorageState,
                    data: { Records: SBs, Total: SBs.length }
                }))
            }
        }

        case GEOVIS_INVENTORY_USAGE_PLAN_RECORDS:
            return {
                ...state,
                usagePlan: inventoryUsagePlanInitialState
            }

        case GEOVIS_INVENTORY_UP_SHOW_CONFIRM_CHANGE_STATE_DIALOG:
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                usagePlan: {
                    ...state.usagePlan,
                    showConfirmStatusChangeDialog: true,
                    recordId: action.stringProperty
                },
                calendarData: {
                    ...state.calendarData,
                    showConfirmStatusChangeDialog: true,
                    recordId: action.stringProperty
                },
                requestSource: action.requestSource ?? ''
            }

        case GEOVIS_INVENTORY_UP_HIDE_CONFIRM_CHANGE_STATE_DIALOG:
            return {
                ...state,
                usagePlan: {
                    ...state.usagePlan,
                    showConfirmStatusChangeDialog: false,
                    recordId: ''
                },
                calendarData: {
                    ...state.calendarData,
                    showConfirmStatusChangeDialog: false,
                    recordId: ''
                },
                requestSource: ''
            }


        case GEOVIS_INVENTORY_UP_SHOW_EXTEND_TIME_RANGE_DIALOG:
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                usagePlan: {
                    ...state.usagePlan,
                    showExtendTimeRangeDialog: true,
                    recordId: action.stringProperty
                },
                calendarData: {
                    ...state.calendarData,
                    showExtendTimeRangeDialog: true,
                    recordId: action.stringProperty
                },
                requestSource: action.requestSource ?? ''
            }

        case GEOVIS_INVENTORY_UP_HIDE_EXTEND_TIME_RANGE_DIALOG:
            return {
                ...state,
                objectId: '',
                objectType: state.objectType,
                usagePlan: {
                    ...state.usagePlan,
                    showExtendTimeRangeDialog: false,
                    recordId: ''
                },
                calendarData: {
                    ...state.calendarData,
                    showExtendTimeRangeDialog: false,
                    recordId: ''
                },
                requestSource: ''
            }

        case GEOVIS_INVENTORY_UP_UPDATE_RECORDS: {
            if (!action.usagePlanData) {
                return state;
            }

            const { Success, Data } = action.usagePlanData;
            if (!Success) {
                return state
            }

            return {
                ...state,
                usagePlan: {
                    ...state.usagePlan,
                    showExtendTimeRangeDialog: false,
                    showConfirmStatusChangeDialog: false,
                    recordId: '',
                    records: updateElementsInArray(state.usagePlan.records, Data, m => m.Record.Id)
                },
                calendarData: {
                    ...state.calendarData,
                    showExtendTimeRangeDialog: false,
                    records: updateElementsInArray(state.calendarData.records, Data, m => m.Record.Id),
                    showConfirmStatusChangeDialog: false,
                    recordId: ''
                }
            }
        }

        case GEOVIS_INVENTORY_RV_SHOW_EDIT_DIALOG: {
            if (action.stringProperty === undefined) {
                return state;
            }
            return {
                ...state,
                reservations: {
                    ...state.reservations,
                    reservationId: action.stringProperty,
                    showEditDialog: true,
                    createMode: action.booleanProperty || false
                }
            }
        }
        case GEOVIS_INVENTORY_RV_HIDE_EDIT_DIALOG:
            return {
                ...state,
                reservations: {
                    ...state.reservations,
                    showEditDialog: false,
                    reservationId: '',
                    createMode: false
                }
            }

        case GEOVIS_INVENTORY_RV_LOADED: {
            if (!action.reservationData) {
                return state;
            }

            let upData: InventoryUsagePlanInfo[] = []

            if (action.reservationData.Success) {
                upData = action.reservationData.Data.ChangedUsagePlans;
            }

            const updUsagePlanData = addOrUpdateElementsInArray(state.usagePlan.records, upData, r => r.Record.Id);
            const updCalendarData = addOrUpdateElementsInArray(state.calendarData.records, upData, r => r.Record.Id);

            return {
                ...state,
                reservations: processFetchedData(action.reservationData, state.reservations, inventoryReservationInitialState, st => ({
                    ...inventoryReservationInitialState,
                    ...loadedSomethingStorageState,
                    reservations: st.Reservations
                })),
                usagePlan: {
                    ...state.usagePlan,
                    records: updUsagePlanData
                },
                calendarData: {
                    ...state.calendarData,
                    records: updCalendarData
                }
            }
        }

        case GEOVIS_INVENTORY_RV_LOADING: {
            return {
                ...state,
                reservations: {
                    ...inventoryReservationInitialState
                }
            }
        }

        case GEOVIS_INVENTORY_RV_SHOW_REMOVE_DIALOG: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                reservations: {
                    ...state.reservations,
                    showRemoveDialog: true,
                    reservationId: action.stringProperty
                }
            }
        }
        case GEOVIS_INVENTORY_RV_HIDE_REMOVE_DIALOG:
            return {
                ...state,
                reservations: {
                    ...state.reservations,
                    reservationId: '',
                    showRemoveDialog: false
                }
            }

        case GEOVIS_INVENTORY_RV_SHOW_TO_PROJECT_DIALOG:
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                reservations: {
                    ...state.reservations,
                    showReservationToProjectDialog: true,
                    reservationId: action.stringProperty
                }
            }

        case GEOVIS_INVENTORY_RV_HIDE_TO_PROJECT_DIALOG: {
            return {
                ...state,
                reservations: {
                    ...state.reservations,
                    reservationId: '',
                    showReservationToProjectDialog: false
                }
            }
        }

        case GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING: {
            return {
                ...state,
                propertyHistory: {
                    ...state.propertyHistory,
                    isError: false,
                    isLoaded: false,
                    isLoading: true
                }
            }
        }

        case GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADED: {
            if (!action.propertyHistoryRecords) {
                return state;
            }
            return {
                ...state,
                propertyHistory: {
                    ...state.propertyHistory,
                    isError: false,
                    isLoaded: true,
                    isLoading: false,
                    records: action.propertyHistoryRecords
                }
            }
        }

        case GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING_ERROR: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                propertyHistory: {
                    ...state.propertyHistory,
                    isError: true,
                    isLoaded: false,
                    isLoading: false,
                    errorDescription: action.stringProperty
                }
            }
        }

        case GEOVIS_INVENTORY_CALENDAR_FILTERS: {
            if (!action.calendarFilters) {
                return state;
            }

            return {
                ...state,
                calendarData: {
                    ...state.calendarData,
                    filters: action.calendarFilters,
                    isLoading: true
                }
            }
        }

        case GEOVIS_INVENTORY_CALENDAR_SHOW_OPEN_PERIOD:
            return {
                ...state,
                calendarData: {
                    ...state.calendarData,
                    showOpenPeriod: action.booleanProperty || false
                }
            }

        case GEOVIS_INVENTORY_CALENDAR_UP_SHOW_EDIT: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                calendarData: {
                    ...state.calendarData,
                    showActionDialog: false,
                    showEditDialog: true
                },
                requestSource: "CALENDAR"
            }
        }

        case GEOVIS_INVENTORY_CALENDAR_SHOW_RESERVATION_REMOVE: {
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                calendarData: {
                    ...state.calendarData,
                    recordId: action.stringProperty,
                    showReservationItemRemoveDialog: true,
                }
            }
        }

        case GEOVIS_INVENTORY_CALENDAR_HIDE_RESERVATION_REMOVE: {
            return {
                ...state,
                calendarData: {
                    ...state.calendarData,
                    recordId: "",
                    showReservationItemRemoveDialog: false
                }
            }
        }

        case GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_HIDE: {
            return {
                ...state,
                displayedObjectData: {
                    ...state.displayedObjectData,
                    showDisplayDialog: false,
                    objectId: '',
                    inventoryObjectInfo: new InventoryMstInfo()
                }
            }
        }
        case GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_SHOW: {
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                displayedObjectData: {
                    ...state.displayedObjectData,
                    showDisplayDialog: true,
                    objectId: action.stringProperty,
                }
            }
        }
        case GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADED: {
            if (!action.inventoryObjectInfo) { return state; }
            return {
                ...state,
                displayedObjectData: {
                    ...state.displayedObjectData,
                    ...loadedSomethingStorageState,
                    inventoryObjectInfo: action.inventoryObjectInfo
                }
            }
        }
        case GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING: {
            return {
                ...state,
                displayedObjectData: {
                    ...state.displayedObjectData,
                    ...defaultSomethingStorageState,
                    inventoryObjectInfo: new InventoryMstInfo()
                }
            }
        }
        case GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING_ERROR: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                displayedObjectData: {
                    ...errorSomethingStorageState(action.stringProperty),
                    inventoryObjectInfo: new InventoryMstInfo(),
                    objectId: '',
                    showDisplayDialog: false
                }
            }
        }

        case GEOVIS_INVENTORY_UPDATE_RESERVATIONS: {
            if (!action.stringProperty || !action.reservationId) {
                return state;
            }

            const updReservations = state.reservations.reservations;
            updReservations.forEach(r => {
                if (r.Id === action.reservationId) {
                    r.ReservedElements = r.ReservedElements.filter(re => re !== action.stringProperty);
                }
            });

            return {
                ...state,
                reservations: {
                    ...state.reservations,
                    reservations: updReservations
                }
            }
        }

        case GEOVIS_INVENTORY_EXPAND_DIALOG_SHOW: {
            if (action.objectType === undefined) {
                return state;
            }

            return {
                ...state,
                showExpandDialog: true,
                objectId: 'expand',
                objectType: action.objectType
            }
        }
        case GEOVIS_INVENTORY_EXPAND_DIALOG_HIDE: {
            return {
                ...state,
                showExpandDialog: false,
                objectId: '',
                objectType: state.objectType
            }
        }
        case GEOVIS_INVENTORY_EXPAND_DIALOG_COMPLITE:
        case GEOVIS_INVENTORY_CHANGE_STATE_DIALOG_COMPLITE: {

            return {
                ...state,
                showExpandDialog: false,
                showChangeStateDialog: false,
                showMoveToStorageDialog: false,
                objectId: '',
                showRemoveFromProjectDialog: false,
                showExpandProjectDurationDialog: false,
                inventoryObjectIDs: [],
                usagePlan: {
                    ...state.usagePlan,
                    recordId: '',
                }
            }
        }

        case GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_SHOW: {
            if (!action.objectIds) {
                return state;
            }
            return {
                ...state,
                showRemoveFromProjectDialog: true,
                inventoryObjectIDs: action.objectIds
            }
        }

        case GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_HIDE: {
            return {
                ...state,
                showRemoveFromProjectDialog: false,
                inventoryObjectIDs: []
            }
        }

        case GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_SHOW: {
            if (!action.objectIds) {
                return state;
            }
            return {
                ...state,
                showExpandProjectDurationDialog: true,
                inventoryObjectIDs: action.objectIds
            }
        }

        case GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_HIDE: {
            return {
                ...state,
                showExpandProjectDurationDialog: false,
                inventoryObjectIDs: []
            }
        }

        case GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_SHOW: {
            if (action.objectType === undefined) {
                return state;
            }

            return {
                ...state,
                showChangeStateDialog: true,
                objectId: 'changeState',
                objectType: action.objectType
            }
        }
        case GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_HIDE: {
            return {
                ...state,
                objectId: '',
                showChangeStateDialog: false,
            }
        }

        case GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_SHOW: {
            if (action.objectType === undefined) {
                return state;
            }

            return {
                ...state,
                showMoveToStorageDialog: true,
                objectType: action.objectType,
                objectId: 'move_to_storage'
            }
        }
        case GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_HIDE: {
            return {
                ...state,
                showMoveToStorageDialog: false,
            }
        }
    }

    return state;
}

export default inventoryDataReducer;