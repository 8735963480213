/**
 * Loading page skeleton
 * Draw loading spinner
 */

import Spinner from '@atlaskit/spinner';

interface ILoadingPageSkeletonProps {
    loadingText?: string;
    spinnerTestId?: string;
}

export const LoadingPageSkeleton = ({ loadingText, spinnerTestId }: ILoadingPageSkeletonProps) => {
    return (
        <div className="page" style={{ display: 'flex', width: '100%' }}>
            <div className="flexRowContainer">
                <div className="skeletonContainer" style={{ height: '45px' }}>
                    {/*  */}
                </div>
            </div>
            <div className="flexRowMiddleContainer">
                <div className="skeletonContainer">
                    <div style={{ display: "inline-block" }} >
                        <Spinner size="xlarge" testId={spinnerTestId} />
                    </div>
                    {loadingText && (
                        <span style={{ marginLeft: '20px' }}>{loadingText}</span>
                    )}
                </div>
            </div>

            <div className="flexRowBottomContainer">
                <div className="skeletonContainer">
                    {/*  */}
                </div>
            </div>
        </div>
    );
}