/**
 * Application reducers
 */

import { combineReducers } from 'redux';
import applicationReducer from './reducers/applicationReducer';
import archivingReducer from './reducers/archivingReducer';
import businessDataReducer from './reducers/businessDataReducer';
import companiesViewReducer from './reducers/companiesReducer';
import dashboardReducer from './reducers/dashboardReducer';
import dataMigrationReducer from './reducers/dataMigrationsReducer';
import projectDataReducer from './reducers/dataReducer';
import dtsConfigurationReducer from './reducers/dtsConfigurationReducer';
import elementsMigrationsReducer from './reducers/elementsMigrationsReducer';
import inventoryReducer from './reducers/inventoryReducer';
import logbookEntryImagesReducer from './reducers/logbookEntryImagesReducer';
import loginReducer from './reducers/loginReducer';
import logosManagerReducer from './reducers/logosManagerReducer';
import logsReducer from './reducers/logsDataReducer';
import messageTemplateViewStateReducer from './reducers/messageTemplateViewStateReducer';
import navigationReducer from './reducers/navigationReducer';
import projectCreateReducer from './reducers/projectCreateReducer';
import projectEditReducer from './reducers/projectEditReducer';
import projectLoRaReducer from './reducers/projectLoRaReducer';
import projectOverviewReducer from './reducers/projectOverviewReducer';
import projectReportReducer from './reducers/projectReportReducer';
import projectsOverviewReducer from './reducers/projectsOverviewReducer';
import reportRenderReducer from './reducers/reportRenderReducer';
import serviceWorkerReducer from './reducers/serviceWorkerReducer';
import settingsReducer from './reducers/settingsReducer';
import statisticsReducer from './reducers/statisticsReducer';
import { IGeovisStoreState } from './store.types';
import { IGeovisAction } from './types';
import documentationReducer from './reducers/documentationReducer';

const reducer = combineReducers<IGeovisStoreState, IGeovisAction>({
    navigation: navigationReducer,
    projectsOverview: projectsOverviewReducer,
    projectOverview: projectOverviewReducer,
    projectEdit: projectEditReducer,
    projectReport: projectReportReducer,
    login: loginReducer,
    data: projectDataReducer,
    logosManager: logosManagerReducer,
    companiesViewState: companiesViewReducer,
    businessData: businessDataReducer,
    messageTemplateViewState: messageTemplateViewStateReducer,
    inventoryDataState: inventoryReducer,
    statisticsState: statisticsReducer,
    serviceWorkerState: serviceWorkerReducer,
    applicationState: applicationReducer,
    logs: logsReducer,
    projectLoRa: projectLoRaReducer,
    archiving: archivingReducer,
    logbookEntryImagesState: logbookEntryImagesReducer,
    creationProjectState: projectCreateReducer,
    dataMigration: dataMigrationReducer,
    dtsConfigurationState: dtsConfigurationReducer,
    settingsState: settingsReducer,
    reportRenderState: reportRenderReducer,
    projectElementsMigrations: elementsMigrationsReducer,
    dashboardState: dashboardReducer,
    documentationState: documentationReducer
});

export default reducer;