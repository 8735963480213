/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 31.10.2019
 * @description Common types for local map objects
 */

import documentIconPng from 'src/resources/icons/document_24px.png';
import dxfIconPng from 'src/resources/icons/dxf_24.png';
import jpegIconPng from 'src/resources/icons/jpg_26px.png';
import pngIconPng from 'src/resources/icons/png_26px.png';
import { LocalMapObject } from '../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject';
import { LocalMapObjectType } from "../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectType";
import { ImageBackgroundFileType } from '../../server/ImageBackgroundFileType';
import { LocalMapObjectContent } from '../../server/LocalMapObjectContent';
import { LocalMapObjectContentType } from '../../server/LocalMapObjectContentType';

export enum LocalMapObjectViewMode{
    View = 0,
    Edit = 1,
    MapSection = 2
}

export const LocalMapObjectsWithInternalOverlay = [
    LocalMapObjectType.Text,
    LocalMapObjectType.Polyline,
    LocalMapObjectType.Circle,
    LocalMapObjectType.Polygon
];

export const isSupportObjectContentType = (objectType: LocalMapObjectType): boolean => {
    return objectType === LocalMapObjectType.Text ||
        objectType === LocalMapObjectType.Polyline ||
        objectType === LocalMapObjectType.Circle ||
        objectType === LocalMapObjectType.Polygon;
}

export const isSupportInternalOverlay = (object: LocalMapObject): boolean => {
    const { ObjectType } = object;
    const isSupportedType = LocalMapObjectsWithInternalOverlay.indexOf(ObjectType) > -1;
    if (!isSupportedType) {
        return false;
    }

    if (isSupportObjectContentType(ObjectType)) {
        const shapeObject = object as LocalMapObjectContent;

        if (shapeObject.ContentType === LocalMapObjectContentType.Background) {
            return true;
        }
    }

    return false;
}

export const isEnabledLinkInLocalMapObject = (object: LocalMapObjectContent): boolean => {
    const { ObjectType, ContentType } = object;

    if (ObjectType === LocalMapObjectType.Camera) {
        return true;
    }

    return isSupportObjectContentType(ObjectType) && ContentType === LocalMapObjectContentType.Link;
}

export const isValidLocalMapObjectLink = (element: LocalMapObjectContent): boolean => {
    const { ObjectType, ContentType, Link } = element;
    if (!isSupportObjectContentType(ObjectType) || ContentType !== LocalMapObjectContentType.Link) {
        return true;
    }

    if (!Link) {
        return false;
    }

    return Link.length > 0;
}

export const isEnabledDocumentInLocalMapObject = (object: LocalMapObjectContent): boolean => {
    const { ObjectType, ContentType } = object;
    return isSupportObjectContentType(ObjectType) && ContentType === LocalMapObjectContentType.Document;
}

export const isEnabledReportInLocalMapObject = (object: LocalMapObjectContent): boolean => {
    const { ObjectType, ContentType } = object;
    return isSupportObjectContentType(ObjectType) && ContentType === LocalMapObjectContentType.Report;
}
export const isEnabledViewInLocalMapObject = (object: LocalMapObjectContent): boolean => {
    const { ObjectType, ContentType } = object;
    return isSupportObjectContentType(ObjectType) && ContentType === LocalMapObjectContentType.View;
}

export const getLocalMapObjectFileIconImage = (fileType: ImageBackgroundFileType) => (
    <img src={getLocalMapObjectFileIcon(fileType)} />
)

export const getLocalMapObjectFileIcon = (fileType: ImageBackgroundFileType) => {

    switch (fileType) {
        case ImageBackgroundFileType.DXF:
            return dxfIconPng;

        case ImageBackgroundFileType.JPEG:
            return jpegIconPng;

        case ImageBackgroundFileType.PNG:
            return pngIconPng;
    }

    return documentIconPng;
}