import React, { RefObject, useEffect, useState } from 'react';
import { ColorResult, CompactPicker } from 'react-color';
import { GeovisTooltip } from './geovisTooltip/GeovisTooltip';
import { useOutsideClick } from './utils';

export interface IGeovisColorPickerColor {
    hex: string;
    rgb: {
        r: number;
        g: number;
        b: number;
        a: number;
    };
    hsl: {
        h: number;
        s: number;
        l: number;
        a: number;
    };
}


const colors: string[] = [
    "#737373", "#bfbfbf", "#ffffff", "#ff0000", "#ffb400", "#ffff00", "#d8ff00", "#00ff00", "#82fffc", "#0000ff", "#c6a1ff", "#ffa1c0",
    "#4d4d4d", "#999999", "#f2f2f2", "#f44e3b", "#fe9200", "#fcdc00", "#dbdf00", "#a4dd00", "#68ccca", "#73d8ff", "#aea1ff", "#fda1ff",
    "#333333", "#808080", "#cccccc", "#d33115", "#e27300", "#fcc400", "#b0bc00", "#68bc00", "#16a5a5", "#009ce0", "#7b64ff", "#fa28ff",
    "#000000", "#666666", "#b3b3b3", "#9f0500", "#c45100", "#fb9e00", "#808900", "#194d33", "#0c797d", "#0062b1", "#653294", "#ab149e"
]

interface IMainComponentProps {
    hexColor: string;
    showPicker: () => void;
    width?: string;
    height?: string;
}

const ColorPickerMainComponent = (props: IMainComponentProps) => {
    const { hexColor, height, width, showPicker } = props;

    const pickerWidth = width || '56px';
    const pickerHeight = height || '14px';

    return (
        <div style={{
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer'
        }}
            onClick={showPicker}>
            <div style={{
                width: pickerWidth,
                height: pickerHeight,
                borderRadius: '2px',
                background: hexColor
            }} />
        </div>
    )
}

interface IGeovisColorPickerProps {
    hexColor?: string;
    defaultHexColor?: string;
    tooltip?: string;
    onColorChange: (hexColor: string, isPickerClosed: boolean) => void;

    width?: string;
    height?: string;
    isDisabled?: boolean;
}

const GeovisColorPicker = (props: IGeovisColorPickerProps) => {
    const { tooltip, width, height, isDisabled } = props;

    const [displayPicker, setDisplayPicker] = useState(false);
    const [hexColor, setHexColor] = useState<string>(props.hexColor ?? props.defaultHexColor ?? "#ffffff");

    useEffect(() => {
        // call 'onColorChange' only after close picker
        if (!displayPicker) {
            props.onColorChange(hexColor, true);
        }
    }, [displayPicker]);

    useEffect(() => {
        if (props.hexColor) {
            setHexColor(props.hexColor)
        }
    }, [props.hexColor]);

    function closePicker() {
        setDisplayPicker(false)
    }

    const showPicker = () => {
        if (isDisabled) {
            return;
        }
        setDisplayPicker(true)
    }

    const onChangeComplete = (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
        const isTextInput = event.nativeEvent.type === "input";
        setHexColor(color.hex);
        props.onColorChange(color.hex, false);
        if (!isTextInput) {
            closePicker();
        }
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            closePicker()
        }
    }
    const handleClickOutside = () => {
        closePicker()
    };

    const ref = useOutsideClick(handleClickOutside) as RefObject<HTMLDivElement>;

    return (
        <React.Fragment>
            <GeovisTooltip content={tooltip || ''}>
                <ColorPickerMainComponent width={width} height={height} hexColor={hexColor} showPicker={showPicker} />
            </GeovisTooltip>

            {displayPicker && (
                <div ref={ref} style={{ position: 'absolute', zIndex: 2000 }} onKeyDown={onKeyDown}>
                    <CompactPicker color={hexColor} colors={colors} onChangeComplete={onChangeComplete} />
                </div>
            )}
        </React.Fragment>
    );
}

export default GeovisColorPicker;
