//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GvModule {
	Logger = 0,
	ApplicationService = 1,
	ProjectElements = 2,
	CommandDispatcher = 3,
	DB = 4,
	Map = 5,
	Controller = 6,
	Migration = 7,
	ArchiveProject = 8,
	ModelsConverter = 9,
	WDSystem = 10,
	WDSensor = 11,
	ArchiveFiles = 12,
	WDNotification = 13,
	EmailService = 14,
	Alarming = 15,
	Import = 16,
	Export = 17,
	Backup = 19,
	Computation = 20,
	Dolphin = 21,
	AgmsService = 22,
	Statistic = 23,
	Synchronization = 24,
	Inventory = 25,
	ArchiveImportFiles = 26,
	Account = 27,
	PDFConverter = 28,
	Report = 29,
	ArchiveData = 30,
	RestoreData = 31,
	MapTilesService = 32,
	Scheduler = 33,
	UserFeedback = 34,
	Logbook = 35,
	RestoreProject = 36,
	Cache = 37,
	LoRaConfig = 38,
	Deleting = 39,
	MQTT = 40,
	Maintenance = 41,
	ArchiveFtpSettings = 42,
	SmsService = 43,
	GlobalImport = 44,
	SwisscomLoRa = 45,
	LoRaPackets = 46,
	LoRaService = 47,
	Tiling = 48,
	All = 49
}

const GvModuleToName = new Map<number, string>([
	[ 0, 'Logger' ],
	[ 1, 'ApplicationService' ],
	[ 2, 'ProjectElements' ],
	[ 3, 'CommandDispatcher' ],
	[ 4, 'DB' ],
	[ 5, 'Map' ],
	[ 6, 'Controller' ],
	[ 7, 'Migration' ],
	[ 8, 'ArchiveProject' ],
	[ 9, 'ModelsConverter' ],
	[ 10, 'WDSystem' ],
	[ 11, 'WDSensor' ],
	[ 12, 'ArchiveFiles' ],
	[ 13, 'WDNotification' ],
	[ 14, 'EmailService' ],
	[ 15, 'Alarming' ],
	[ 16, 'Import' ],
	[ 17, 'Export' ],
	[ 19, 'Backup' ],
	[ 20, 'Computation' ],
	[ 21, 'Dolphin' ],
	[ 22, 'AgmsService' ],
	[ 23, 'Statistic' ],
	[ 24, 'Synchronization' ],
	[ 25, 'Inventory' ],
	[ 26, 'ArchiveImportFiles' ],
	[ 27, 'Account' ],
	[ 28, 'PDFConverter' ],
	[ 29, 'Report' ],
	[ 30, 'ArchiveData' ],
	[ 31, 'RestoreData' ],
	[ 32, 'MapTilesService' ],
	[ 33, 'Scheduler' ],
	[ 34, 'UserFeedback' ],
	[ 35, 'Logbook' ],
	[ 36, 'RestoreProject' ],
	[ 37, 'Cache' ],
	[ 38, 'LoRaConfig' ],
	[ 39, 'Deleting' ],
	[ 40, 'MQTT' ],
	[ 41, 'Maintenance' ],
	[ 42, 'ArchiveFtpSettings' ],
	[ 43, 'SmsService' ],
	[ 44, 'GlobalImport' ],
	[ 45, 'SwisscomLoRa' ],
	[ 46, 'LoRaPackets' ],
	[ 47, 'LoRaService' ],
	[ 48, 'Tiling' ],
	[ 49, 'All' ],
])

export function getGvModuleToName(element: GvModule) : string {
    return GvModuleToName.get(element) || '';
}

const GvModuleByName = new Map<string, number>([
	[ 'Logger', 0 ],
	[ 'ApplicationService', 1 ],
	[ 'ProjectElements', 2 ],
	[ 'CommandDispatcher', 3 ],
	[ 'DB', 4 ],
	[ 'Map', 5 ],
	[ 'Controller', 6 ],
	[ 'Migration', 7 ],
	[ 'ArchiveProject', 8 ],
	[ 'ModelsConverter', 9 ],
	[ 'WDSystem', 10 ],
	[ 'WDSensor', 11 ],
	[ 'ArchiveFiles', 12 ],
	[ 'WDNotification', 13 ],
	[ 'EmailService', 14 ],
	[ 'Alarming', 15 ],
	[ 'Import', 16 ],
	[ 'Export', 17 ],
	[ 'Backup', 19 ],
	[ 'Computation', 20 ],
	[ 'Dolphin', 21 ],
	[ 'AgmsService', 22 ],
	[ 'Statistic', 23 ],
	[ 'Synchronization', 24 ],
	[ 'Inventory', 25 ],
	[ 'ArchiveImportFiles', 26 ],
	[ 'Account', 27 ],
	[ 'PDFConverter', 28 ],
	[ 'Report', 29 ],
	[ 'ArchiveData', 30 ],
	[ 'RestoreData', 31 ],
	[ 'MapTilesService', 32 ],
	[ 'Scheduler', 33 ],
	[ 'UserFeedback', 34 ],
	[ 'Logbook', 35 ],
	[ 'RestoreProject', 36 ],
	[ 'Cache', 37 ],
	[ 'LoRaConfig', 38 ],
	[ 'Deleting', 39 ],
	[ 'MQTT', 40 ],
	[ 'Maintenance', 41 ],
	[ 'ArchiveFtpSettings', 42 ],
	[ 'SmsService', 43 ],
	[ 'GlobalImport', 44 ],
	[ 'SwisscomLoRa', 45 ],
	[ 'LoRaPackets', 46 ],
	[ 'LoRaService', 47 ],
	[ 'Tiling', 48 ],
	[ 'All', 49 ],
]);

export function getGvModuleByName(name: string): GvModule {
    return GvModuleByName.get(name)!;
}


export const GvModuleList : Readonly<GvModule[]> = [
	GvModule.Logger,
	GvModule.ApplicationService,
	GvModule.ProjectElements,
	GvModule.CommandDispatcher,
	GvModule.DB,
	GvModule.Map,
	GvModule.Controller,
	GvModule.Migration,
	GvModule.ArchiveProject,
	GvModule.ModelsConverter,
	GvModule.WDSystem,
	GvModule.WDSensor,
	GvModule.ArchiveFiles,
	GvModule.WDNotification,
	GvModule.EmailService,
	GvModule.Alarming,
	GvModule.Import,
	GvModule.Export,
	GvModule.Backup,
	GvModule.Computation,
	GvModule.Dolphin,
	GvModule.AgmsService,
	GvModule.Statistic,
	GvModule.Synchronization,
	GvModule.Inventory,
	GvModule.ArchiveImportFiles,
	GvModule.Account,
	GvModule.PDFConverter,
	GvModule.Report,
	GvModule.ArchiveData,
	GvModule.RestoreData,
	GvModule.MapTilesService,
	GvModule.Scheduler,
	GvModule.UserFeedback,
	GvModule.Logbook,
	GvModule.RestoreProject,
	GvModule.Cache,
	GvModule.LoRaConfig,
	GvModule.Deleting,
	GvModule.MQTT,
	GvModule.Maintenance,
	GvModule.ArchiveFtpSettings,
	GvModule.SmsService,
	GvModule.GlobalImport,
	GvModule.SwisscomLoRa,
	GvModule.LoRaPackets,
	GvModule.LoRaService,
	GvModule.Tiling,
	GvModule.All,
];



const GvModuleToDescription = new Map<number, string>([
	[ 0, 'Logger' ],
	[ 1, 'ApplicationService' ],
	[ 2, 'ProjectElements' ],
	[ 3, 'CommandDispatcher' ],
	[ 4, 'DB' ],
	[ 5, 'Map' ],
	[ 6, 'Controller' ],
	[ 7, 'Migration' ],
	[ 8, 'ArchiveProject' ],
	[ 9, 'ModelsConverter' ],
	[ 10, 'WDSystem' ],
	[ 11, 'WDSensor' ],
	[ 12, 'ArchiveFiles' ],
	[ 13, 'WDNotification' ],
	[ 14, 'EmailService' ],
	[ 15, 'Alarming' ],
	[ 16, 'Import' ],
	[ 17, 'Export' ],
	[ 19, 'Backup' ],
	[ 20, 'Computation' ],
	[ 21, 'Dolphin' ],
	[ 22, 'AgmsService' ],
	[ 23, 'Statistic' ],
	[ 24, 'Synchronization' ],
	[ 25, 'Inventory' ],
	[ 26, 'ArchiveImportFiles' ],
	[ 27, 'Account' ],
	[ 28, 'PDFConverter' ],
	[ 29, 'Report' ],
	[ 30, 'ArchiveData' ],
	[ 31, 'RestoreData' ],
	[ 32, 'MapTilesService' ],
	[ 33, 'Scheduler' ],
	[ 34, 'UserFeedback' ],
	[ 35, 'Logbook' ],
	[ 36, 'RestoreProject' ],
	[ 37, 'Cache' ],
	[ 38, 'LoRaConfig' ],
	[ 39, 'Deleting' ],
	[ 40, 'MQTT' ],
	[ 41, 'Maintenance' ],
	[ 42, 'ArchiveFtpSettings' ],
	[ 43, 'SmsService' ],
	[ 44, 'GlobalImport' ],
	[ 45, 'SwisscomLoRa' ],
	[ 46, 'LoRaPackets' ],
	[ 47, 'LoRaService' ],
	[ 48, 'Tiling' ],
	[ 49, 'All' ],
])

export function getGvModuleToDescription(element: GvModule) : string {
    return GvModuleToDescription.get(element) || '';
}

