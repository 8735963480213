import Icon from "@atlaskit/icon";
import Add from "@atlaskit/icon/glyph/add";
import AppAccess from "@atlaskit/icon/glyph/app-access";
import ArrowDown from "@atlaskit/icon/glyph/arrow-down";
import ArrowLeft from "@atlaskit/icon/glyph/arrow-left";
import ArrowRight from "@atlaskit/icon/glyph/arrow-right";
import ArrowUp from "@atlaskit/icon/glyph/arrow-up";
import Billing from "@atlaskit/icon/glyph/billing";
import CheckCircle from "@atlaskit/icon/glyph/check-circle";
import ChevronDown from "@atlaskit/icon/glyph/chevron-down";
import ChevronRight from "@atlaskit/icon/glyph/chevron-right";
import Edit from '@atlaskit/icon/glyph/edit';
import BulletList from "@atlaskit/icon/glyph/editor/bullet-list";
import Point from "@atlaskit/icon/glyph/emoji/custom";
import Error from "@atlaskit/icon/glyph/error";
import Lock from "@atlaskit/icon/glyph/lock";
import Image from "@atlaskit/icon/glyph/media-services/image";
import Line from "@atlaskit/icon/glyph/media-services/line";
import Rectangle from "@atlaskit/icon/glyph/media-services/rectangle";
import More from "@atlaskit/icon/glyph/more";
import People from "@atlaskit/icon/glyph/people";
import QuestionCircle from "@atlaskit/icon/glyph/question-circle";
import Search from "@atlaskit/icon/glyph/search";
import Settings from "@atlaskit/icon/glyph/settings";
import Unlock from "@atlaskit/icon/glyph/unlock";
import Watch from "@atlaskit/icon/glyph/watch";
import Constants from "../helpers/Constants";
import i18next from "../i18n";

export interface IIconProps {
    label?: string;
    size?: "small" | "medium" | "large" | "xlarge";
    primaryColor?: string;
    secondaryColor?: string;
}

export function TunnelCloudLogoLight() {
    return (
        <svg width="100px" height="100px" viewBox="0 0 24 24">
            <g>
                <path fill={Constants.colorForegroundM3White} d="M10.0268902,2 L1.84615385,21.9874745 L0.5,21.9874745 C0.223857625,21.9874745 3.38176876e-17,21.7636168 0,21.4874745 L0,3 C-6.76353751e-17,2.44771525 0.44771525,2 1,2 L10.0268902,2 Z M14.9238325,2 L23,2 C23.5522847,2 24,2.44771525 24,3 L24,21.4874745 C24,21.7636168 23.7761424,21.9874745 23.5,21.9874745 L23.1124633,21.9874745 L14.9238325,2 Z" />
                <polygon fill={Constants.colorAmbergInfra7DGreen} points="8 21.5 12.2422259 10.4230769 15.3846154 18.7199095" />
            </g>
        </svg>
    );
}

export const AmbergInfra7DIcon = (props: IIconProps) => {
    const svg = MakeIconSvg(
        <g>
            <path fill={props.primaryColor || Constants.colorForegroundM3White} d="M10.0268902,2 L1.84615385,21.9874745 L0.5,21.9874745 C0.223857625,21.9874745 3.38176876e-17,21.7636168 0,21.4874745 L0,3 C-6.76353751e-17,2.44771525 0.44771525,2 1,2 L10.0268902,2 Z M14.9238325,2 L23,2 C23.5522847,2 24,2.44771525 24,3 L24,21.4874745 C24,21.7636168 23.7761424,21.9874745 23.5,21.9874745 L23.1124633,21.9874745 L14.9238325,2 Z" />
            <polygon fill={Constants.colorAmbergInfra7DGreen} points="8 21.5 12.2422259 10.4230769 15.3846154 18.7199095" />
        </g>
    );

    return (
        <Icon glyph={svg} label={props.label || i18next.t("GEOvis 4.0")} size={props.size || "large"} />
    );
}

export function CreateIcon(props: IIconProps) {
    return (
        <Add label={props.label || i18next.t("Create")} size={props.size || "medium"} />
    );
}

export function EditIcon(props: IIconProps) {
    return (
        <Edit label={props.label || i18next.t("Edit")} size={props.size || "medium"} />
    )
}

export function SearchIcon(props: IIconProps) {
    return (
        <Search label={props.label || i18next.t("Search")} size={props.size || "medium"} />
    );
}

export function UpIcon(props: IIconProps) {
    return (
        <ArrowUp label={props.label || i18next.t("labelUp")} size={props.size || "medium"} />
    );
}

export function DownIcon(props: IIconProps) {
    return (
        <ArrowDown label={props.label || i18next.t("labelDown")} size={props.size || "medium"} />
    );
}

export function BackIcon(props: IIconProps) {
    return (
        <ArrowLeft label={props.label || i18next.t("labelBack")} size={props.size || "medium"} />
    );
}

export function ForwardIcon(props: IIconProps) {
    return (
        <ArrowRight label={props.label || i18next.t("labelForward")} size={props.size || "medium"} />
    );
}

export function MoreIcon(props: IIconProps) {
    return (
        <More label={props.label || i18next.t("labelMore")} size={props.size || "medium"} />
    );
}

export function HelpIcon(props: IIconProps) {
    return (
        <QuestionCircle
            label={props.label || i18next.t("labelHelp")}
            size={props.size || "medium"}
            primaryColor={props.primaryColor}
            secondaryColor={props.secondaryColor}
        />
    );
}

export function HomeIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M14,22 L14,17 C14,16.4477153 13.5522847,16 13,16 L11,16 C10.4477153,16 10,16.4477153 10,17 L10,22 L6,22 C4.8954305,22 4,21.1045695 4,20 L4,13 L2.38167245,13 C1.8293877,13 1.38167245,12.5522847 1.38167245,12 C1.38167245,11.7381231 1.48439983,11.4866956 1.66778077,11.2997439 L11.6264642,1.14714282 C11.7250731,1.05297435 11.8593481,1 11.9994299,1 C12.1395116,1 12.2737866,1.05297435 12.3723955,1.14714282 L22.3320213,11.2997089 C22.7187814,11.6939614 22.7127076,12.3270972 22.3184551,12.7138573 C22.1315002,12.8972593 21.8800579,13 21.618164,13 L20,13 L20,20 C20,21.1045695 19.1045695,22 18,22 L14,22 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelHome")} size={props.size || "medium"} />
    );
}

export function OverviewIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M4,1 L20,1 C21.1045695,1 22,1.8954305 22,3 L22,13 C22,14.1045695 21.1045695,15 20,15 L4,15 C2.8954305,15 2,14.1045695 2,13 L2,3 C2,1.8954305 2.8954305,1 4,1 Z M4,3 L4,13 L20,13 L20,3 L4,3 Z M14,17 L21,17 C21.5522847,17 22,17.4477153 22,18 C22,18.5522847 21.5522847,19 21,19 L14,19 C13.4477153,19 13,18.5522847 13,18 C13,17.4477153 13.4477153,17 14,17 Z M3,17 L10,17 C10.5522847,17 11,17.4477153 11,18 C11,18.5522847 10.5522847,19 10,19 L3,19 C2.44771525,19 2,18.5522847 2,18 C2,17.4477153 2.44771525,17 3,17 Z M14,21 L21,21 C21.5522847,21 22,21.4477153 22,22 C22,22.5522847 21.5522847,23 21,23 L14,23 C13.4477153,23 13,22.5522847 13,22 C13,21.4477153 13.4477153,21 14,21 Z M3,21 L10,21 C10.5522847,21 11,21.4477153 11,22 C11,22.5522847 10.5522847,23 10,23 L3,23 C2.44771525,23 2,22.5522847 2,22 C2,21.4477153 2.44771525,21 3,21 Z M9.6418978,8.35642335 L7.4110813,10.9059279 C7.04739925,11.3215645 6.41563663,11.3636821 6,11 C5.58436337,10.6363179 5.54224586,10.0045553 5.90592791,9.5889187 L8.74742331,6.34149539 C9.09188131,5.9478291 9.68152699,5.88614524 10.1,6.2 L13.3684404,8.65133027 L16.5877411,5.07432941 C16.9572001,4.66381939 17.58949,4.63054098 18,5 C18.41051,5.36945902 18.4437884,6.00174886 18.0743294,6.41225888 L14.2432941,10.6689647 C13.8969581,11.0537825 13.3141755,11.1106316 12.9,10.8 L9.6418978,8.35642335 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelOverview")} size={props.size || "medium"} />
    );
}

export function ProjectIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M3.99893811,3 L10.5258692,3 C10.7604985,3 10.9875551,3.08328918 11.1668141,3.23511228 L12.8934332,6.00520383 L20.0015253,6.00520383 C21.1102432,6.00520383 22,6.89669138 22,7.99639699 L22,19.0088068 C22,20.1069126 21.1086907,21 20.0092049,21 L3.99079583,21 C2.89821311,21 2.00000077,20.1067681 2.00000077,19.0049107 L2.00000077,4.99508929 C2.00000077,3.8926228 2.8949555,3 3.99893811,3 Z M4,4.96203702 L4,18.9942716 L20.0078202,18.9942716 L20.0078202,7.98795522 L11.6456449,7.98795522 L9.89207342,5.13102396 C9.76323099,5.02190111 9.60003411,4.96203702 9.43139424,4.96203702 L4,4.96203702 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelProject")} size={props.size || "medium"} />
    );
}

export function StructureIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M12,3 C12.597883,3 13.1821495,3.05829962 13.7474246,3.16952418 L12.6722052,5.03185878 C12.450978,5.01078114 12.226748,5 12,5 C11.773252,5 11.549022,5.01078114 11.3277948,5.03185878 L10.2525754,3.16952418 C10.8178505,3.05829962 11.402117,3 12,3 Z M9.30934193,5.53579593 C8.66049708,5.80618144 8.06107228,6.17141076 7.5283825,6.61416894 L6.10948229,5.19526872 C6.76458513,4.62768252 7.50248151,4.15311642 8.30262711,3.79211473 L9.30934193,5.53579593 Z M17.8905177,5.19526872 L16.4716175,6.61416894 C15.9389277,6.17141076 15.3395029,5.80618144 14.6906581,5.53579593 L15.6973729,3.79211473 C16.4975185,4.15311642 17.2354149,4.62768252 17.8905177,5.19526872 Z M6.1860417,8.10025526 C5.89582376,8.53207493 5.65244178,8.99800023 5.46317257,9.49075433 L3.52251075,8.97075556 C3.81981659,8.13881247 4.23611002,7.36343025 4.75089319,6.66510676 L6.1860417,8.10025526 Z M5.021833,11.4430501 C5.00736896,11.6267964 5,11.8125376 5,12 L5,13 L3,13 L3,12 C3,11.6339955 3.02184773,11.2730937 3.06431017,10.9185278 L5.021833,11.4430501 Z M3,15 L5,15 L5,17 L3,17 L3,15 Z M3,19 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 Z M20.4774893,8.97075556 L18.5368274,9.49075433 C18.3475582,8.99800023 18.1041762,8.53207493 17.8139583,8.10025526 L19.2491068,6.66510676 C19.76389,7.36343025 20.1801834,8.13881247 20.4774893,8.97075556 Z M21,12 L21,13 L19,13 L19,12 C19,11.8125376 18.992631,11.6267964 18.978167,11.4430501 L20.9356905,10.9185333 C20.9781523,11.2730937 21,11.6339955 21,12 Z M21,15 L21,17 L19,17 L19,15 L21,15 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelStructure")} size={props.size || "medium"} />
    );
}

export function InspectionIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <g>
            <path d="M18.7627656,7.9383531 C18.0296451,7.54689699 17.2284185,7.26626722 16.3814586,7.1188367 C15.2831392,4.69000178 12.8389302,3 10,3 C6.13400675,3 3,6.13400675 3,10 L3,17 L7.25203497,17 C7.4346584,17.7095418 7.71217795,18.3809871 8.07025756,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,10 C1,5.02943725 5.02943725,1 10,1 C14.2611862,1 17.8307047,3.96138105 18.7627656,7.9383531 Z" id="Combined-Shape" />
            <path d="M19.7240407,18.6995361 L23.2525569,21.8356741 C23.6417697,22.1816056 23.6768561,22.7775578 23.3309246,23.1667705 C23.3186456,23.1805858 23.305963,23.194037 23.2928932,23.2071068 L23.2524871,23.2475129 C22.8781047,23.6218953 22.2767932,23.639581 21.8810544,23.2878492 L18.2394609,20.0512083 C17.3050759,20.651694 16.1932136,21 15,21 C11.6862915,21 9,18.3137085 9,15 C9,11.6862915 11.6862915,9 15,9 C18.3137085,9 21,11.6862915 21,15 C21,16.3957852 20.5233915,17.6802526 19.7240407,18.6995361 Z M15,19 C17.209139,19 19,17.209139 19,15 C19,12.790861 17.209139,11 15,11 C12.790861,11 11,12.790861 11,15 C11,17.209139 12.790861,19 15,19 Z" id="Combined-Shape" />
        </g>
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelInspection")} size={props.size || "medium"} />
    );
}

export function InspectionTaskIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <g>
            <path d="M3.8285,16 L1,16 L1,13.1715 L10.9905,3.18125 L13.81875,6.0095 L3.8285,16 Z M7.58151705,18 L1,18 L1,17 L7.25203497,17 C7.34025589,17.3427623 7.45062196,17.6766343 7.58151705,18 Z M14.8788255,0.707138031 L16.2928932,2.12139322 C16.683388,2.51192256 16.6833741,3.14506344 16.292862,3.53557553 L14.8795,4.949 L12.05125,2.1205 L13.464612,0.707075531 C13.8551363,0.31655124 14.4883012,0.31655124 14.8788255,0.707075531 C14.8788359,0.707085948 14.8788464,0.707096365 14.8788568,0.707106783 Z" />
            <path d="M23.2525569,21.8356741 C23.6417697,22.1816056 23.6768561,22.7775578 23.3309246,23.1667705 C23.3186456,23.1805858 23.305963,23.194037 23.2928932,23.2071068 L23.2524871,23.2475129 C22.8781047,23.6218953 22.2767932,23.639581 21.8810544,23.2878492 L18.2394609,20.0512083 C17.3050759,20.651694 16.1932136,21 15,21 C11.6862915,21 9,18.3137085 9,15 C9,11.6862915 11.6862915,9 15,9 C18.3137085,9 21,11.6862915 21,15 C21,16.3957852 20.5233915,17.6802526 19.7240407,18.6995361 L23.2525569,21.8356741 Z M15,19 C17.209139,19 19,17.209139 19,15 C19,12.790861 17.209139,11 15,11 C12.790861,11 11,12.790861 11,15 C11,17.209139 12.790861,19 15,19 Z" />
        </g>
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelInspectionTask")} size={props.size || "medium"} />
    );
}

export function InspectionAnalysisIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <g>
            <path d="M7.25203497,17 C7.4346584,17.7095418 7.71217795,18.3809871 8.07025756,19 L4,19 C2.895,19 2,18.104 2,17 L2,3 C2,1.895 2.895,1 4,1 L11.275,1 C11.744,1 12.197,1.164 12.557,1.464 L17.631,5.7 C17.858,5.89 17.99,6.171 17.99,6.468 L17.99,6.989 L13.49,6.989 C12.661,6.989 11.99,6.318 11.99,5.489 L11.99,3 L4,3 L4,17 L7.25203497,17 Z M6,15 C5.44771525,15 5,14.5522847 5,14 C5,13.4477153 5.44771525,13 6,13 L7.25203497,13 C7.08750601,13.6392398 7,14.3094009 7,15 L6,15 Z M6,11 C5.44771525,11 5,10.5522847 5,10 C5,9.44771525 5.44771525,9 6,9 L9.70835121,9 C9.06036148,9.57192754 8.50573486,10.2471896 8.07025756,11 L6,11 Z M6,5 L9,5 C9.55228475,5 10,5.44771525 10,6 C10,6.55228475 9.55228475,7 9,7 L6,7 C5.44771525,7 5,6.55228475 5,6 C5,5.44771525 5.44771525,5 6,5 Z" />
            <path d="M23.2525569,21.8356741 C23.6417697,22.1816056 23.6768561,22.7775578 23.3309246,23.1667705 C23.3186456,23.1805858 23.305963,23.194037 23.2928932,23.2071068 L23.2524871,23.2475129 C22.8781047,23.6218953 22.2767932,23.639581 21.8810544,23.2878492 L18.2394609,20.0512083 C17.3050759,20.651694 16.1932136,21 15,21 C11.6862915,21 9,18.3137085 9,15 C9,11.6862915 11.6862915,9 15,9 C18.3137085,9 21,11.6862915 21,15 C21,16.3957852 20.5233915,17.6802526 19.7240407,18.6995361 L23.2525569,21.8356741 Z M15,19 C17.209139,19 19,17.209139 19,15 C19,12.790861 17.209139,11 15,11 C12.790861,11 11,12.790861 11,15 C11,17.209139 12.790861,19 15,19 Z" />
        </g>
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelInspectionAnalysis")} size={props.size || "medium"} />
    );
}

export function SettingsIcon(props: IIconProps) {
    return (
        <Settings label={props.label || i18next.t("labelSettings")} size={props.size || "medium"} />
    );
}

export function ChevronRightIcon(props: IIconProps) {
    return (
        <ChevronRight label={props.label || i18next.t("labelSettings")} size={props.size || "medium"} />
    );
}

export function AdministrationIcon(props: IIconProps) {
    return (
        <AppAccess label={props.label || i18next.t("labelAdministration")} size={props.size || "medium"} />
    );
}

export function AccountIcon(props: IIconProps) {
    return (
        <Billing label={props.label || i18next.t("labelAccount")} size={props.size || "medium"} />
    );
}

export function UsersIcon(props: IIconProps) {
    return (
        <People label={props.label || i18next.t("labelUsers")} size={props.size || "medium"} />
    );
}

export function ResourcesIcon(props: IIconProps) {
    return (
        <BulletList label={props.label || i18next.t("labelResources")} size={props.size || "medium"} />
    );
}

export function LicensesIcon(props: IIconProps) {
    return (
        <Lock label={props.label || i18next.t("labelLicenses")} size={props.size || "medium"} />
    );
}

// inspection analysis categories
export function GraphicalAnalysisIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M4,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,5 C2,3.8954305 2.8954305,3 4,3 Z M4,5 L4,19 L20,19 L20,5 L4,5 Z M19,6 L19,8 L17,8 L17,6 L19,6 Z M19,11 L19,13 L17,13 L17,11 L19,11 Z M14,6 L14,8 L12,8 L12,6 L14,6 Z M8,16 L9,16 L9,18 L5,18 L5,16 L6,16 L6,8 L5,8 L5,6 L9,6 L9,8 L8,8 L8,16 Z M19,18 L10,18 L10,9 L19,18 Z M12,16 L14,16 L12,13.9936648 L12,16 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelGraphicalAnalysis")} size={props.size || "medium"} />
    );
}

export function ChartAnalysisIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M22,20 C22,20.5522847 21.5522847,21 21,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,4 C2,3.44771525 2.44771525,3 3,3 C3.55228475,3 4,3.44771525 4,4 L4,19 L21,19 C21.5522847,19 22,19.4477153 22,20 Z M7,6 L8,6 C8.55228475,6 9,6.44771525 9,7 L9,17 L6,17 L6,7 C6,6.44771525 6.44771525,6 7,6 Z M11,12 L12,12 C12.5522847,12 13,12.4477153 13,13 L13,17 L10,17 L10,13 C10,12.4477153 10.4477153,12 11,12 Z M15,9 L16,9 C16.5522847,9 17,9.44771525 17,10 L17,17 L14,17 L14,10 C14,9.44771525 14.4477153,9 15,9 Z M19,14 L20,14 C20.5522847,14 21,14.4477153 21,15 L21,17 L18,17 L18,15 C18,14.4477153 18.4477153,14 19,14 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelChartAnalysis")} size={props.size || "medium"} />
    );
}

export function NumericalAnalysisIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M4,3 L20,3 C21.1045695,3 22,3.8954305 22,5 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,5 C2,3.8954305 2.8954305,3 4,3 Z M8,5 L4,5 L4,19 L8,19 L8,5 Z M10,5 L10,19 L20,19 L20,5 L10,5 Z M12,7 L18,7 C18.5522847,7 19,7.44771525 19,8 C19,8.55228475 18.5522847,9 18,9 L12,9 C11.4477153,9 11,8.55228475 11,8 C11,7.44771525 11.4477153,7 12,7 Z M6,7 C6.55228475,7 7,7.44771525 7,8 C7,8.55228475 6.55228475,9 6,9 C5.44771525,9 5,8.55228475 5,8 C5,7.44771525 5.44771525,7 6,7 Z M6,11 C6.55228475,11 7,11.4477153 7,12 C7,12.5522847 6.55228475,13 6,13 C5.44771525,13 5,12.5522847 5,12 C5,11.4477153 5.44771525,11 6,11 Z M6,15 C6.55228475,15 7,15.4477153 7,16 C7,16.5522847 6.55228475,17 6,17 C5.44771525,17 5,16.5522847 5,16 C5,15.4477153 5.44771525,15 6,15 Z M12,11 L18,11 C18.5522847,11 19,11.4477153 19,12 C19,12.5522847 18.5522847,13 18,13 L12,13 C11.4477153,13 11,12.5522847 11,12 C11,11.4477153 11.4477153,11 12,11 Z M12,15 L18,15 C18.5522847,15 19,15.4477153 19,16 C19,16.5522847 18.5522847,17 18,17 L12,17 C11.4477153,17 11,16.5522847 11,16 C11,15.4477153 11.4477153,15 12,15 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelNumericalAnalysis")} size={props.size || "medium"} />
    );
}

// inspection analysis types
export function SideBySideAnalysisIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M11,5.00227665 L11,20 L3.99079506,20 C2.89821235,20 2,19.1067681 2,18.0049107 L2,6.99508929 C2,5.8926228 2.89495473,5 3.99893734,5 L11,5.00227665 Z M13,5.00292703 L20.0015245,5.00520383 C21.1102425,5.00520383 21.9999992,5.89669138 21.9999992,6.99639699 L21.9999992,18.0088068 C21.9999992,19.1069126 21.1086899,20 20.0092042,20 L13,20 L13,5.00292703 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelSBSAnalysis")} size={props.size || "medium"} />
    );
}

export function OverlayAnalysisIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M9,5.00162628 L9,20 L3.99079506,20 C2.89821235,20 2,19.1067681 2,18.0049107 L2,6.99508929 C2,5.8926228 2.89495473,5 3.99893734,5 L9,5.00162628 Z M11,5.00227665 L20.0015245,5.00520383 C21.1102425,5.00520383 21.9999992,5.89669138 21.9999992,6.99639699 L21.9999992,18.0088068 C21.9999992,19.1069126 21.1086899,20 20.0092042,20 L11,20 L11,5.00227665 Z M16.4285714,12.5 L13,9.5 L13,15.5 L16.4285714,12.5 Z M3.57142857,12.5 L7,15.5 L7,9.5 L3.57142857,12.5 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labeOverlayAnalysis")} size={props.size || "medium"} />
    );
}

export function DiagramAnalysisIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M8.06254761,14.8214823 C7.57813481,14.3627005 6.9408058,14.0637538 6.2349858,14.0090675 L8.26291885,9.44621813 C8.7531094,9.79493548 9.35260701,10 10,10 C10.8013398,10 11.5292973,9.68581318 12.0674072,9.17390499 L14.1692657,12.1165069 C13.5024468,12.3082695 12.9308956,12.7250797 12.5421186,13.2794311 L10.203219,10.0049717 L8.06254761,14.8214823 Z M20.5232709,6.96233066 L17.2323075,12.9957635 C16.7836238,12.4963527 16.1697743,12.1483313 15.4767291,12.0376693 L18.7676925,6.00423649 C19.2163762,6.50364727 19.8302257,6.8516687 20.5232709,6.96233066 Z M10,8 C9.44771525,8 9,7.55228475 9,7 C9,6.44771525 9.44771525,6 10,6 C10.5522847,6 11,6.44771525 11,7 C11,7.55228475 10.5522847,8 10,8 Z M15,16 C14.4477153,16 14,15.5522847 14,15 C14,14.4477153 14.4477153,14 15,14 C15.5522847,14 16,14.4477153 16,15 C16,15.5522847 15.5522847,16 15,16 Z M21,5 C20.4477153,5 20,4.55228475 20,4 C20,3.44771525 20.4477153,3 21,3 C21.5522847,3 22,3.44771525 22,4 C22,4.55228475 21.5522847,5 21,5 Z M6,18 C5.44771525,18 5,17.5522847 5,17 C5,16.4477153 5.44771525,16 6,16 C6.55228475,16 7,16.4477153 7,17 C7,17.5522847 6.55228475,18 6,18 Z M22,20 C22,20.5522847 21.5522847,21 21,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,4 C2,3.44771525 2.44771525,3 3,3 C3.55228475,3 4,3.44771525 4,4 L4,19 L21,19 C21.5522847,19 22,19.4477153 22,20 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelDiagramAnalysis")} size={props.size || "medium"} />
    );
}

export function NumericExportAnalysisIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M12,15 L18,15 C18.5522847,15 19,15.4477153 19,16 C19,16.5522847 18.5522847,17 18,17 L12,17 C11.4477153,17 11,16.5522847 11,16 C11,15.4477153 11.4477153,15 12,15 Z M20.573712,3.01231299 L18.987712,3.01231299 C18.4354273,3.01231299 17.987712,2.56459774 17.987712,2.01231299 C17.987712,1.46002824 18.4354273,1.01231299 18.987712,1.01231299 L22.8436648,1.01231299 C22.9779334,0.991044639 23.1172531,0.997026697 23.2532193,1.03261584 C23.6028488,1.12413128 23.8758937,1.39717624 23.9674092,1.74680567 C24.0029983,1.88277191 24.0089804,2.02209159 23.987712,2.15636023 L23.987712,6.01231299 C23.987712,6.56459774 23.5399968,7.01231299 22.987712,7.01231299 C22.4354273,7.01231299 21.987712,6.56459774 21.987712,6.01231299 L21.987712,4.42631299 L15.694712,10.719313 C15.3023331,11.0982852 14.6786272,11.0928654 14.2928934,10.7071316 C13.9071596,10.3213978 13.9017398,9.69769188 14.280712,9.30531299 L20.573712,3.01231299 Z M10,5 L10,19 L20,19 L20,11 L22,9 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,5 C2,3.8954305 2.8954305,3 4,3 L16,3 L14,5 L10,5 Z M8,5 L4,5 L4,19 L8,19 L8,5 Z M6,7 C6.55228475,7 7,7.44771525 7,8 C7,8.55228475 6.55228475,9 6,9 C5.44771525,9 5,8.55228475 5,8 C5,7.44771525 5.44771525,7 6,7 Z M6,11 C6.55228475,11 7,11.4477153 7,12 C7,12.5522847 6.55228475,13 6,13 C5.44771525,13 5,12.5522847 5,12 C5,11.4477153 5.44771525,11 6,11 Z M6,15 C6.55228475,15 7,15.4477153 7,16 C7,16.5522847 6.55228475,17 6,17 C5.44771525,17 5,16.5522847 5,16 C5,15.4477153 5.44771525,15 6,15 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelNumericExportAnalysis")} size={props.size || "medium"} />
    );
}

export function CadExportAnalysisIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M16,3 L14,5 L4,5 L4,19 L20,19 L20,11 L22,9 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,5 C2,3.8954305 2.8954305,3 4,3 L16,3 Z M8,16 L9,16 L9,18 L5,18 L5,16 L6,16 L6,8 L5,8 L5,6 L9,6 L9,8 L8,8 L8,16 Z M20.573712,3.01231299 L18.987712,3.01231299 C18.4354273,3.01231299 17.987712,2.56459774 17.987712,2.01231299 C17.987712,1.46002824 18.4354273,1.01231299 18.987712,1.01231299 L22.8436648,1.01231299 C22.9779334,0.991044639 23.1172531,0.997026697 23.2532193,1.03261584 C23.6028488,1.12413128 23.8758937,1.39717624 23.9674092,1.74680567 C24.0029983,1.88277191 24.0089804,2.02209159 23.987712,2.15636023 L23.987712,6.01231299 C23.987712,6.56459774 23.5399968,7.01231299 22.987712,7.01231299 C22.4354273,7.01231299 21.987712,6.56459774 21.987712,6.01231299 L21.987712,4.42631299 L15.694712,10.719313 C15.3023331,11.0982852 14.6786272,11.0928654 14.2928934,10.7071316 C13.9071596,10.3213978 13.9017398,9.69769188 14.280712,9.30531299 L20.573712,3.01231299 Z M19,18 L10,18 L10,9 L19,18 Z M12,16 L14,16 L12,13.9936648 L12,16 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelCADexportAnalysis")} size={props.size || "medium"} />
    );
}

export function PlaceholderIcon(props: IIconProps) {
    return (
        <Image label={props.label || i18next.t("labelPlaceholder")} size={props.size || "medium"} />
    );
}

// inspection catalog
export function LayersIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M4.11371028,10.8002525 L12,15.5 L19.8862897,10.8002525 L20.458025,11.1409719 C20.9324531,11.4237021 21.087855,12.0375002 20.8051248,12.5119284 C20.7200598,12.6546694 20.600766,12.7739632 20.458025,12.8590281 L12.5119284,17.5944169 C12.1965228,17.7823793 11.8034772,17.7823793 11.4880716,17.5944169 L3.54197503,12.8590281 C3.06754689,12.5762979 2.91214502,11.9624998 3.19487524,11.4880716 C3.27994018,11.3453306 3.39923402,11.2260368 3.54197503,11.1409719 L4.11371028,10.8002525 Z M4.11371028,14.8002525 L12,19.5 L19.8862897,14.8002525 L20.458025,15.1409719 C20.9324531,15.4237021 21.087855,16.0375002 20.8051248,16.5119284 C20.7200598,16.6546694 20.600766,16.7739632 20.458025,16.8590281 L12.5119284,21.5944169 C12.1965228,21.7823793 11.8034772,21.7823793 11.4880716,21.5944169 L3.54197503,16.8590281 C3.06754689,16.5762979 2.91214502,15.9624998 3.19487524,15.4880716 C3.27994018,15.3453306 3.39923402,15.2260368 3.54197503,15.1409719 L4.11371028,14.8002525 Z M3.54197503,7.14097185 L11.4880716,2.40558313 C11.8034772,2.21762066 12.1965228,2.21762066 12.5119284,2.40558313 L20.458025,7.14097185 C20.9324531,7.42370208 21.087855,8.03750021 20.8051248,8.51192836 C20.7200598,8.65466937 20.600766,8.77396322 20.458025,8.85902815 L12.5119284,13.5944169 C12.1965228,13.7823793 11.8034772,13.7823793 11.4880716,13.5944169 L3.54197503,8.85902815 C3.06754689,8.57629792 2.91214502,7.96249979 3.19487524,7.48807164 C3.27994018,7.34533063 3.39923402,7.22603678 3.54197503,7.14097185 Z M12,11.5712825 L17.9926983,8 L12,4.42871749 L6.00730172,8 L12,11.5712825 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelLayers")} size={props.size || "medium"} />
    );
}

export function StartDrawingIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M6.58578644,16 L16,6.58578644 L16,3 C16,2.44771525 16.4477153,2 17,2 L21,2 C21.5522847,2 22,2.44771525 22,3 L22,7 C22,7.55228475 21.5522847,8 21,8 L17.4142136,8 L8,17.4142136 L8,21 C8,21.5522847 7.55228475,22 7,22 L3,22 C2.44771525,22 2,21.5522847 2,21 L2,17 C2,16.4477153 2.44771525,16 3,16 L6.58578644,16 Z M4,18 L4,20 L6,20 L6,18 L4,18 Z M18,4 L18,6 L20,6 L20,4 L18,4 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelStartDrawing")} size={props.size || "medium"} />
    );
}

export function StopDrawingIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M4,2 L4,4 L2,4 L2,3 C2,2.44771525 2.44771525,2 3,2 L4,2 Z M6,2 L8,2 L8,4 L6,4 L6,2 Z M10,2 L12,2 L12,4 L10,4 L10,2 Z M14,2 L16,2 L16,4 L14,4 L14,2 Z M18,2 L19,2 C19.5522847,2 20,2.44771525 20,3 L20,4 L18,4 L18,2 Z M20,6 L20,8 L18,8 L18,6 L20,6 Z M20,10 L20,12 L18,12 L18,10 L20,10 Z M12,20 L10,20 L10,18 L12,18 L12,20 Z M8,20 L6,20 L6,18 L8,18 L8,20 Z M4,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,18 L4,18 L4,20 Z M2,16 L2,14 L4,14 L4,16 L2,16 Z M2,12 L2,10 L4,10 L4,12 L2,12 Z M18,18 L18,14 L20,14 L20,18 L24,18 L24,20 L20,20 L20,24 L18,24 L18,20 L14,20 L14,18 L18,18 Z M2,8 L2,6 L4,6 L4,8 L2,8 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelStopDrawing")} size={props.size || "medium"} />
    );
}

export function ExpandedIcon(props: IIconProps) {
    return (
        <ChevronDown label={props.label || i18next.t("labelExpanded")} size={props.size || "medium"} />
    );
}

export function CollapsedIcon(props: IIconProps) {
    return (
        <ChevronRight label={props.label || i18next.t("labelCollapsed")} size={props.size || "medium"} />
    );
}

export function PointPhenomenonIcon(props: IIconProps) {
    return (
        <Point
            label={props.label || i18next.t("labelPoint")}
            size={props.size || "medium"}
            primaryColor={props.primaryColor}
            secondaryColor={props.secondaryColor}
        />
    );
}

export function LinePhenomenonIcon(props: IIconProps) {
    return (
        <Line
            label={props.label || i18next.t("labelLine")}
            size={props.size || "medium"}
            primaryColor={props.primaryColor}
            secondaryColor={props.secondaryColor}
        />
    );
}

export function AreaPhenomenonIcon(props: IIconProps) {
    return (
        <Rectangle
            label={props.label || i18next.t("labelArea")}
            size={props.size || "medium"}
            primaryColor={props.primaryColor}
            secondaryColor={props.secondaryColor}
        />
    );
}

export function LockedIcon(props: IIconProps) {
    return (
        <Lock
            label={props.label || i18next.t("labelLocked")}
            size={props.size || "medium"}
            primaryColor={Constants.colorForegroundM1Blue}
        />
    );
}

export function UnlockedIcon(props: IIconProps) {
    return (
        <Unlock
            label={props.label || i18next.t("labelUnlocked")}
            size={props.size || "medium"}
        />
    );
}

export function VisibleIcon(props: IIconProps) {
    return (
        <Watch
            label={props.label || i18next.t("labelVisible")}
            size={props.size || "medium"}
        />
    );
}

export function InvisibleIcon(props: IIconProps) {
    const svg = MakeIconSvg(
        <path d="M13.1905686,4.07481197 L12.0788192,6.00041844 C12.0529064,6.00013989 12.0269665,6 12.001,6 C7.46,6 4.001,9.999 4.001,12 C4.001,12.897409 4.92215952,14.4651391 6.43659364,15.7730398 L5.41756835,17.5380434 C3.33500661,15.8669879 2,13.6668427 2,12 C2,8.841 6.48,4 12.001,4 L12,4 C12.4028408,4 12.8001427,4.02578243 13.1905686,4.07481197 Z M18.7123467,6.51081177 C20.7313122,8.16151857 22,10.3189214 22,12 C22,15.086 17.423,20 12,20 C11.649091,20 11.3017313,19.9794323 10.9589305,19.9401227 L12.0793051,17.999577 C16.5767828,17.9509894 19.999,13.7299737 19.999,12 C19.999,10.9978881 19.1137348,9.5201478 17.689755,8.28199269 L18.7123467,6.51081177 Z M15.7508608,10.6091823 C15.9119515,11.0425302 16,11.5111491 16,12 C16,13.9775117 14.5591877,15.62395 12.6709191,15.9437978 L15.7508608,10.6091823 Z M8.29859715,13.5168816 C8.106164,13.0488987 8,12.5366398 8,12 C8,9.97604531 9.51012533,8.29889756 11.4630579,8.03587484 L8.29859715,13.5168816 Z M6.6294873,19.4389382 L15.6205127,3.8660254 C15.8966551,3.38773278 16.5082455,3.22385763 16.9865381,3.5 L16.9865381,3.5 C17.4648307,3.77614237 17.6287059,4.38773278 17.3525635,4.8660254 L8.36153811,20.4389382 C8.08539573,20.9172308 7.47380533,21.081106 6.9955127,20.8049636 L6.9955127,20.8049636 C6.51722008,20.5288212 6.35334492,19.9172308 6.6294873,19.4389382 Z" />
    );
    return (
        <Icon glyph={svg} label={props.label || i18next.t("labelInvisible")}
            size={props.size || "medium"}
            primaryColor={Constants.colorForegroundM1Blue}
        />
    );
}

// flags
export function FlagSuccessIcon(props: IIconProps) {
    return (
        <CheckCircle primaryColor={Constants.successColor} label={props.label || i18next.t("labelSuccess")} />
    );
}

export function FlagErrorIcon(props: IIconProps) {
    return (
        <Error secondaryColor={Constants.errorColor} label={props.label || i18next.t("labelError")} />
    );
}

// helpers
function MakeIconSvg(svgGElement: JSX.Element) {
    return () => {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24">
                <g fill="currentColor">
                    {svgGElement}
                </g>
            </svg>
        );
    }
}