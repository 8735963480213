/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.04.2022
 * @description XyChart options tab
 */

import TextArea from '@atlaskit/textarea';
import React, { SyntheticEvent } from 'react';
import { OptionType } from '@atlaskit/select';
import { t } from '../../../../i18n';
import { ChartSettingBool } from '../../../../server/AVTService/TypeLibrary/Model/ChartSettingBool';
import { GeovisChartAxisSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings';
import { XyChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel';
import { ChartBooleanSettingProps, ChartRadioBooleanSettingProps, ChartSettingTooltip, ChartTextSetting, CustomerChangeableChartBooleanSetting } from '../ChartTab_tools';
import { AxisProperty } from '../GeovisChartAxisSettingsControl';
import { onChartPropertyChangedFunc } from '../tools';
import { defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IGeovisChartPropertyChangedProps } from '../types';
import { XyChartOptionsSettings } from './XyChartOptionSettings';
import { GeovisSelect } from '../../../select/GeovisSelect';
import { ChartAlarmLineTypeList, getChartAlarmLineTypeToDescription } from '../../../../server/AVTService/TypeLibrary/Common/ChartAlarmLineType';

type IXyChartOptionsTabProps = IGeovisChartPropertyChangedProps<XyChartModel>

export const XyChartOptionsTab = ({ chart, onChartPropertyChanged, onChartPropertiesChanged }: IXyChartOptionsTabProps) => {
    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    const onChangeFunc = onChartPropertyChangedFunc(onChartPropertyChanged);

    const onCustomTooltipChanged = (event: SyntheticEvent<HTMLTextAreaElement>) => {
        onChangeFunc("CustomTooltip")(event.currentTarget.value);
    }

    const { LeftYAxisSettings } = chart

    const legendTextInfo = () => (
        <div>
            <div>{"<SensorName>"}</div>
            <div>{"<CustomerName>"}</div>
            <div>{"<SensorLastValue>"}</div>
            <div>{"<Km>"}</div>
            <div>{"<Param{1-5}>"}</div>
        </div>
    )

    const customTooltipInfo = () => (
        <div>
            <div>{"<Km>"}</div>
            <div>{"<Param{1-5}>"}</div>
            <div>{"<Reference>"}</div>
            <div>{"<AxisReference>"}</div>
            <div>{"<Offset>"}</div>
            <div>{"<AxisOffset>"}</div>
        </div>
    )

    const commonTooltipContent = () => (
        <div>
            <div>{t("Additional sensor's attributes data will not be displayed if this option is selected")}</div>
        </div>
    )

    const onChangeBooleanChartValue = (propertyName: keyof XyChartModel, invert?: boolean) => (value: boolean) => {
        onChartPropertyChanged(propertyName, invert ? !value : value);
    }

    const onAxisPropertyChangedFunc = (propertyName: AxisProperty) => (value: ChartSettingBool) => {
        switch (propertyName) {
            case "ShowPointSymbols": {
                const newAxisSettings: GeovisChartAxisSettings = { ...LeftYAxisSettings, ShowPointSymbols: value };
                onChartPropertyChanged("LeftYAxisSettings", newAxisSettings);
                break;
            }
        }
    }

    const getLineStyleOptions = (): OptionType[] => {
        return ChartAlarmLineTypeList.map(c => ({ label: getChartAlarmLineTypeToDescription(c), value: c }));
    }

    const getSelectedLienStyle = (isLeftAxis: boolean): OptionType => {
        if (isLeftAxis) {
            const option = getLineStyleOptions().find(o => o.value === chart.LeftYAxisSettings.LineStyle);
            if (option) {
                return option;
            }
        } else {
            const option = getLineStyleOptions().find(o => o.value === chart.RightYAxisSettings.LineStyle);
            if (option) {
                return option;
            }
        }

        return getLineStyleOptions()[0];
    }

    const onAxisLineWeightPropertyChangedFunc = (isLeftAxis: boolean) => (value: OptionType) => {
        const newAxisSettings: GeovisChartAxisSettings = isLeftAxis
            ? { ...chart.LeftYAxisSettings, LineWeight: +value.value }
            : { ...chart.RightYAxisSettings, LineWeight: +value.value };
        onChartPropertyChanged(isLeftAxis ? "LeftYAxisSettings" : "RightYAxisSettings", newAxisSettings);
    }

    const getLineWidthOptions = (): OptionType[] => {
        const result: OptionType[] = [];

        result.push({ label: '0.5', value: 0.5 });
        result.push({ label: '1', value: 1 });
        result.push({ label: '1.5', value: 1.5 });
        result.push({ label: '2', value: 2 });
        result.push({ label: '2.5', value: 2.5 });
        result.push({ label: '3', value: 3 });

        return result;
    }

    const getSelectedLineWidth = (isLeftAxis: boolean): OptionType => {
        const availableOptions = getLineWidthOptions();
        if (isLeftAxis) {
            const option = availableOptions.find(o => o.value === chart.LeftYAxisSettings.LineWeight);
            if (option) {
                return option;
            }
        } else {
            const option = availableOptions.find(o => o.value === chart.RightYAxisSettings.LineWeight);
            if (option) {
                return option;
            }
        }

        return availableOptions[0];
    }

    const onChangeLineStyle = (isLeftAxis: boolean) => (option: OptionType) => {
        const newAxisSettings: GeovisChartAxisSettings = isLeftAxis
            ? { ...chart.LeftYAxisSettings, LineStyle: +option.value }
            : { ...chart.RightYAxisSettings, LineStyle: +option.value };
        onChartPropertyChanged(isLeftAxis ? "LeftYAxisSettings" : "RightYAxisSettings", newAxisSettings);
    }

    const getBooleanSetting = (setting: ChartSettingBool): ChartSettingBool => {
        return setting || { value: false, customerChangeable: false };
    }

    return (
        <div className='geovisChartTab'>

            <CustomerChangeableChartBooleanSetting
                setting={getBooleanSetting(LeftYAxisSettings.ShowPointSymbols)}
                settingLabel={t("Show point symbols")}
                onChange={onAxisPropertyChangedFunc("ShowPointSymbols")}
                isDisabled={false} />

            <React.Fragment>
                <CustomerChangeableChartBooleanSetting
                    setting={chart.DrawLineBetweenPoints}
                    settingLabel={t("Draw line between points")}
                    onChange={onChangeFunc("DrawLineBetweenPoints")}
                    isDisabled={false}
                />

                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                    <ChartRadioBooleanSettingProps
                        label={t("Connect points in X axis order")}
                        isChecked={chart.SortByXAxis}
                        onChange={onChangeBooleanChartValue("SortByXAxis", false)}
                        isDisabled={!chart.DrawLineBetweenPoints.value} />

                    <ChartRadioBooleanSettingProps
                        label={t("Connect points in Y axis order")}
                        isChecked={!chart.SortByXAxis}
                        onChange={onChangeBooleanChartValue("SortByXAxis", true)}
                        isDisabled={!chart.DrawLineBetweenPoints.value} />
                </div>

                <div className="flexRowContainerLine" style={settingRowStyle}>
                    <div className="flexCellContainer" style={labelColumnStyle}>
                        <label htmlFor="line-width">{t("Line weight")}:</label>
                    </div>
                    <div className="flexCellContainer_g1">
                        <GeovisSelect
                            id="line-width"
                            spacing="compact"
                            options={getLineWidthOptions()}
                            value={getSelectedLineWidth(true)}
                            isDisabled={!chart.DrawLineBetweenPoints.value}
                            onChange={onAxisLineWeightPropertyChangedFunc(true)} />
                    </div>
                </div>

                <div className="flexRowContainerLine" style={settingRowStyle}>
                    <div className="flexCellContainer" style={labelColumnStyle}>
                        <label htmlFor="cmbLSL">{t("Line style")}:</label>
                    </div>
                    <div className="flexCellContainer_g1">
                        <GeovisSelect
                            id="cmbLSL"
                            options={getLineStyleOptions()}
                            value={getSelectedLienStyle(true)}
                            onChange={onChangeLineStyle(true)}
                            isDisabled={!chart.DrawLineBetweenPoints.value}
                            isCompact={true}
                            spacing='compact'
                        />
                    </div>
                </div>

                <CustomerChangeableChartBooleanSetting
                    setting={chart.ShowLegend}
                    settingLabel={t("Show legend")}
                    onChange={onChangeFunc("ShowLegend")}
                    isDisabled={false} />
            </React.Fragment>

            <ChartTextSetting
                labelColumnStyle={labelColumnStyle}
                labelText={t("Legend text")}
                value={chart.LegendText}
                htmlName="tbLegendText"
                tooltipContent={legendTextInfo()}
                onChangeTextField={onChangeFunc("LegendText")} />

            <ChartBooleanSettingProps
                label={t("Disable mouse zoom")}
                isChecked={!chart.IsChartZoomAllow}
                onChange={onChangeBooleanChartValue("IsChartZoomAllow", true)}
                isDisabled={false} />

            <ChartBooleanSettingProps
                label={t("Show start of measurements")}
                isChecked={chart.ShowStartMeasurements}
                onChange={onChangeFunc("ShowStartMeasurements")}
                isDisabled={false} />

            <CustomerChangeableChartBooleanSetting
                settingLabel={t("Show all data in single tooltip")}
                setting={chart.ShowCommonTooltip}
                onChange={onChangeFunc("ShowCommonTooltip")}
                isDisabled={false}
                // infoText={t("Additional sensor's attributes data will not be displayed if this option is selected")}
                tooltipContent={commonTooltipContent()}
            />

            <ChartBooleanSettingProps
                label={t("showCustomTooltipLabel")}
                isChecked={chart.ShowCustomTooltip}
                onChange={onChangeFunc("ShowCustomTooltip")}
                isDisabled={chart.ShowCommonTooltip.value} />

            <div className="flexRowContainer">
                <div className="flexCellContainer_g1">
                    <TextArea
                        name="tbCustomTooltip"
                        isCompact={true}
                        isDisabled={chart.ShowCommonTooltip.value}
                        defaultValue={chart.CustomTooltip}
                        maxHeight="250px"
                        onChange={onCustomTooltipChanged} />
                </div>
                <div className="flexCellContainer">
                    <ChartSettingTooltip content={customTooltipInfo()} />
                </div>
            </div>

            <XyChartOptionsSettings
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        </div>
    )
}