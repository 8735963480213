import Tabs from "@atlaskit/tabs";
import { TabData } from "@atlaskit/tabs/dist/types/types";
import { t } from "i18next";
import { useState } from "react";
import { GeovisAlarmModel } from "../../../../server/GEOvis3/Model/Alarms/GeovisAlarmModel";
import { IGeovisProjectLabelStorage } from "../../../../store/data.types";
import { AlarmEditDialogActionsSettingsTab } from "./AlarmEditDialogActionsSettingsTab";
import { AlarmEditDialogMainSettingsTab } from "./AlarmEditDialogMainSettingsTab";
import AlarmEditDialogSensorsSettingsTab from "./AlarmEditDialogSensorsSettings";
import { CompanyInfo } from "../../../../server/GEOvis3/Model/Company/CompanyInfo";
import { AlarmActivationBlockModel } from "../../../../server/GEOvis3/Model/Alarms/UsersPresets/AlarmActivationBlockModel";
import { LicensedFeatures } from "../../../../server/AVTService/TypeLibrary/Licensing/LicensedFeatures";

interface IComponentProps {
    alarm: GeovisAlarmModel;
    onAlarmChanged: (updAlarm: GeovisAlarmModel) => void;
    isReadonly: boolean;
    labelStorage: IGeovisProjectLabelStorage;
    onShowLabelsListView: () => void;
    companies: CompanyInfo[];
    isTemplate: boolean;
    onChangeAlarmActivationPossibility: (config: AlarmActivationBlockModel) => void;
    availableFeatures: LicensedFeatures[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAlarmEditDialogTabData extends TabData {

}

export const AlarmEditDialogTabControl = ({
    alarm,
    isReadonly,
    labelStorage,
    onAlarmChanged,
    onShowLabelsListView,
    companies,
    isTemplate,
    onChangeAlarmActivationPossibility,
    availableFeatures
}: IComponentProps) => {
    const [tabIndex, setTabIndex] = useState<number>(0);

    const getMainSettingsTab = (): IAlarmEditDialogTabData => {
        return ({
            label: t("Main settings"),
            content: (
                <AlarmEditDialogMainSettingsTab
                    alarm={alarm}
                    isReadonly={isReadonly}
                    labelStorage={labelStorage}
                    onAlarmChanged={onAlarmChanged}
                    onShowLabelsListView={onShowLabelsListView}
                    isTemplate={isTemplate}
                />
            )

        })
    }

    const getActionsTab = (): IAlarmEditDialogTabData => {
        return ({
            label: t("Alarm Actions & Confirmation"),
            content: (
                <AlarmEditDialogActionsSettingsTab
                    alarm={alarm}
                    isReadonly={isReadonly}
                    onAlarmChanged={onAlarmChanged}
                    companies={companies}
                    isTemplate={isTemplate}
                    onChangeAlarmActivationPossibility={onChangeAlarmActivationPossibility}
                />
            )

        })
    }

    const getSensorsTab = (): IAlarmEditDialogTabData => {
        return ({
            label: t("Sensors"),
            content: (
                <AlarmEditDialogSensorsSettingsTab
                    alarm={alarm}
                    isReadonly={isReadonly}
                    onAlarmChanged={onAlarmChanged}
                />
            )

        })
    }

    const getTabs = (): IAlarmEditDialogTabData[] => {
        const result: IAlarmEditDialogTabData[] = [
            getMainSettingsTab()
        ];

        if (availableFeatures.includes(LicensedFeatures.Alarm_actions)) {
            result.push(getActionsTab());
        }

        if (!isTemplate) {
            result.push(getSensorsTab());
        }

        return result;
    }

    const tabs = getTabs();

    const onSelectedTabChangedHandler = (tab: TabData, index: number) => setTabIndex(index);

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Tabs
                tabs={tabs}
                selected={tabs[tabIndex || 0]}
                onSelect={onSelectedTabChangedHandler} />
        </div>
    );
}