import moment from "moment";
import AuthService from "../../services/AuthService";
import { setLocalStorageItem } from "../utils";

export const isClientVersionUpToDate = async () => {
    const format = "YYYY/MM/DD HH:mm";
    const lastCheckTimeItemId = "version_last_checktime";
    const lastCheckTime = localStorage.getItem(lastCheckTimeItemId);

    // the following check is required to avoid a cyclical call of windows.location.reload in case
    // if something wrong with the number of version
    const isCheckRequired = (momentDate: moment.Moment) => {

        const lastCheckDateTime = moment(lastCheckTime, format);
        if (!lastCheckDateTime.isValid()) {
            return true;
        }

        const diff = Math.abs(momentDate.diff(lastCheckDateTime, "minutes"));
        return diff >= 5;
    }

    const momentDateNow = moment(new Date());
    const dateNowFormatted = momentDateNow.format(format);

    if (!lastCheckTime || isCheckRequired(momentDateNow)) {
        setLocalStorageItem(lastCheckTimeItemId, dateNowFormatted);
        return await AuthService.isClientVersionUpToDate();
    }

    return true;
}

export const isCacheVersionUpToDate = async (serverVersion: string): Promise<boolean> => {
    const cacheNames = await caches.keys();

    const geo4CacheNamePrefix = 'precache-geovis4.amberg.ch-';
    let isUpToDate = true;

    cacheNames.forEach(cacheName => {
        if (cacheName.startsWith(geo4CacheNamePrefix)) {
            const cacheVersion = cacheName.substr(geo4CacheNamePrefix.length, serverVersion.length).trim();

            if (cacheVersion !== serverVersion) {
                // if there is at least one cache with other version then we have to update the version
                isUpToDate = false;
            }
        }
    });

    return isUpToDate;
}