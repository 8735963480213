//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ProjectAutomaticState {
	Ok = 0,
	InterventionRequired = 1,
	FunctionalAlarm = 2
}

const ProjectAutomaticStateToName = new Map<number, string>([
	[ 0, 'Ok' ],
	[ 1, 'InterventionRequired' ],
	[ 2, 'FunctionalAlarm' ],
])

export function getProjectAutomaticStateToName(element: ProjectAutomaticState) : string {
    return ProjectAutomaticStateToName.get(element) || '';
}

const ProjectAutomaticStateByName = new Map<string, number>([
	[ 'Ok', 0 ],
	[ 'InterventionRequired', 1 ],
	[ 'FunctionalAlarm', 2 ],
]);

export function getProjectAutomaticStateByName(name: string): ProjectAutomaticState {
    return ProjectAutomaticStateByName.get(name)!;
}


export const ProjectAutomaticStateList : Readonly<ProjectAutomaticState[]> = [
	ProjectAutomaticState.Ok,
	ProjectAutomaticState.InterventionRequired,
	ProjectAutomaticState.FunctionalAlarm,
];



const ProjectAutomaticStateToDescription = new Map<number, string>([
	[ 0, 'Ok' ],
	[ 1, 'Intervention required' ],
	[ 2, 'Functional alarm' ],
])

export function getProjectAutomaticStateToDescription(element: ProjectAutomaticState) : string {
    return ProjectAutomaticStateToDescription.get(element) || '';
}

