import { formattedDateTime } from "../../../../../../../helpers/DateHelper";
import { invertArray } from "../../../../../../../pages/edit/alarms/alarmEdit/AlarmActionsEdit/tools";
import { HtmlReportChainLineInfo } from "../../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportChainLineInfo";

interface IInclinometerChartLegendProps {
    linesData: HtmlReportChainLineInfo[];
    legendText: string
}

export const InclinometerChartLegend = ({
    linesData,
    legendText
}: IInclinometerChartLegendProps) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', marginLeft: '10px', marginRight: '10px', justifyContent: 'center', marginBottom: '5px' }}>
            {
                invertArray(linesData).map<JSX.Element | null>((line, index) => (
                    <div key={`div-${index}`} style={{ margin: '3px' }}>
                        <div style={{
                            display: 'inline-block',
                            width: '15px',
                            height: '3px',
                            background: line.color,
                            margin: '2px',
                            verticalAlign: 'middle'
                        }}>
                            {/*  */}
                        </div>
                        <span style={{
                            fontFamily: 'sans-serif',
                            textAnchor: 'middle',
                            fill: "#333333",
                            color: '#333333',
                            fontSize: '12px',
                            fontWeight: 'bold'
                        }}>{legendText ? `${formattedDateTime(line.timeslot, "DD.MM.YYYY HH:mm")} ${legendText}` : formattedDateTime(line.timeslot, "DD.MM.YYYY HH:mm")}</span>
                    </div>
                ))
            }
        </div>
    )
}