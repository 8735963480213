/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.04.2020
 * @description LogosManager helping methods
 */

import { LogoFileInfo } from "../server/AVTService/TypeLibrary/Logo/LogoFileInfo";
import { ApplyLogosChangesRequest } from "../server/GEOvis3/Model/LogosManager/ApplyLogosChangesRequest";
import { CancelLogosChangesRequest } from "../server/GEOvis3/Model/LogosManager/CancelLogosChangesRequest";
import { CompanyInfoWithLogoFiles } from "../server/GEOvis3/Model/LogosManager/CompanyInfoWithLogoFiles";
import AuthService from "../services/AuthService";
import { ILogosFileStorage } from "../store/logosManager.types";
import { GeovisUploadFileState } from "../store/uploading.types";
import { deleteElementOfMap } from "./StorageHelper";

/**
 * Convert uploaded files to request parameter to apply changes to projects
 * @param files 
 */
export const createApplyUploadedChangedPayload = (files: GeovisUploadFileState[], { companies, projects, names, companyLogos, userLogos }: {
    companies: Map<string, string>,
    projects: Map<string, number[]>,
    names: Map<string, string>,
    companyLogos: Map<string, boolean>,
    userLogos: Map<string, boolean>
}): ApplyLogosChangesRequest => {

    const result: ApplyLogosChangesRequest = {
        LogoToCompanyAssignInfos: [],
        AddReferences: [],
        Change: []
    };


    for (const file of files) {

        // change properties
        {
            const change: LogoFileInfo = { ...new LogoFileInfo(), Id: file.id };
            let isChanged = false;

            if (names.has(file.id)) {
                const changedName = names.get(file.id);

                if (changedName && file.name !== changedName) {
                    isChanged = true;
                    change.FileName = changedName;
                }
            }

            if (isChanged) {
                result.Change.push(change);
            }
        }

        const isCompanyLogo = companyLogos.get(file.id) || false;
        if (isCompanyLogo && companies.has(file.id)) {
            const companyId = companies.get(file.id);
            if (companyId) {
                result.LogoToCompanyAssignInfos.push({
                    CompanyId: companyId,
                    LogoId: file.id
                });
            }
        }

        const isUserLogo = userLogos.get(file.id) || false;
        if (isUserLogo && projects.has(file.id)) {
            const projectsIds = projects.get(file.id) || [];
            for (const projectId of projectsIds) {
                result.AddReferences.push({
                    ProjectId: projectId,
                    LogoId: file.id,
                    IsCompanyLogo: false,
                    IsUserLogo: true,
                    CompanyName: ""
                });
            }
        }
    }

    return result;
}

/**
 * Cancel changes for uploaded logos request
 * It deletes uploaded logos for the dialog
 * @param files 
 */
export const createCancelUploadingChangesPayload = (files: GeovisUploadFileState[]): CancelLogosChangesRequest => {
    return {
        Delete: files.map<LogoFileInfo>(f => ({
            ...new LogoFileInfo(),
            Id: f.id,
            LinkId: f.linkId
        }))
    }
}

/**
 * Update upload logo file info progress
 * @param filterFn
 * @param files
 * @param properties file properties
 */
export const updateUploadLogoFileInfoProperties = (filterFn: (f: GeovisUploadFileState) => boolean, files: GeovisUploadFileState[], properties: Partial<GeovisUploadFileState>): GeovisUploadFileState[] => {
    return files.map<GeovisUploadFileState>(f => {

        if (filterFn(f)) {
            return { ...f, ...properties }
        }

        return f;
    })
}

/**
 * Delete logo file info from files storage
 * @param storage 
 * @param infoId 
 */
export const deleteLogoFile = (storage: ILogosFileStorage, infoId: string): ILogosFileStorage => ({
    isLoaded: storage.isLoaded,
    isLoading: storage.isLoading,
    isError: storage.isError,
    isInProgress: storage.isInProgress,
    filesInfo: deleteElementOfMap(storage.filesInfo, infoId),
    errorDescription: storage.errorDescription
});

/**
 * 
 * @param companyMap 
 * @param fileInfoId 
 * @returns 
 */
export const deleteCompanyLogoFile = (companyMap: Map<string, CompanyInfoWithLogoFiles>, fileInfoId: string): Map<string, CompanyInfoWithLogoFiles> => {
    companyMap.forEach(c => {
        const index = c.LogoFiles.findIndex(f => f.Id === fileInfoId);
        if (index > -1) {
            c.LogoFiles.splice(index, 1);
        }
    });
    return companyMap;
}

/**
 * Add the logo files of the current user's company
 * @param companyMap 
 * @param logoFileInfo 
 * @returns 
 */
export const addCurrentCompanyLogoFile = (companyMap: Map<string, CompanyInfoWithLogoFiles>, logoFileInfo: LogoFileInfo): Map<string, CompanyInfoWithLogoFiles> => {
    if (AuthService.hasUserTypeAsAdmin()) {
        const currentCompanyWithLogos = companyMap.get(AuthService.currentUserCompanyId());
        if (currentCompanyWithLogos) {
            currentCompanyWithLogos.LogoFiles.push(logoFileInfo);
        }
    }
    return companyMap;
}