//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LogReadPredefinedPeriod {
	Minutes30 = 0,
	Hour1 = 1,
	Hours3 = 2,
	Hours6 = 3,
	Hours12 = 4,
	Day1 = 5,
	Custom = 6
}

const LogReadPredefinedPeriodToName = new Map<number, string>([
	[ 0, 'Minutes30' ],
	[ 1, 'Hour1' ],
	[ 2, 'Hours3' ],
	[ 3, 'Hours6' ],
	[ 4, 'Hours12' ],
	[ 5, 'Day1' ],
	[ 6, 'Custom' ],
])

export function getLogReadPredefinedPeriodToName(element: LogReadPredefinedPeriod) : string {
    return LogReadPredefinedPeriodToName.get(element) || '';
}

const LogReadPredefinedPeriodByName = new Map<string, number>([
	[ 'Minutes30', 0 ],
	[ 'Hour1', 1 ],
	[ 'Hours3', 2 ],
	[ 'Hours6', 3 ],
	[ 'Hours12', 4 ],
	[ 'Day1', 5 ],
	[ 'Custom', 6 ],
]);

export function getLogReadPredefinedPeriodByName(name: string): LogReadPredefinedPeriod {
    return LogReadPredefinedPeriodByName.get(name)!;
}


export const LogReadPredefinedPeriodList : Readonly<LogReadPredefinedPeriod[]> = [
	LogReadPredefinedPeriod.Minutes30,
	LogReadPredefinedPeriod.Hour1,
	LogReadPredefinedPeriod.Hours3,
	LogReadPredefinedPeriod.Hours6,
	LogReadPredefinedPeriod.Hours12,
	LogReadPredefinedPeriod.Day1,
	LogReadPredefinedPeriod.Custom,
];



const LogReadPredefinedPeriodToDescription = new Map<number, string>([
	[ 0, 'Minutes30' ],
	[ 1, 'Hour1' ],
	[ 2, 'Hours3' ],
	[ 3, 'Hours6' ],
	[ 4, 'Hours12' ],
	[ 5, 'Day1' ],
	[ 6, 'Custom' ],
])

export function getLogReadPredefinedPeriodToDescription(element: LogReadPredefinedPeriod) : string {
    return LogReadPredefinedPeriodToDescription.get(element) || '';
}

