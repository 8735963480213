import '@atlaskit/css-reset';
import React from 'react';
import { API_ROOT } from './ApiConfig';
import './App.css';
import GeovisNavigationRouter from './GeovisNavigationRouter';
import { GeovisName } from './helpers/Constants';
import Logger from './services/Logger';
import { setLocalStorageItem } from './components/utils';

if (process.env.NODE_ENV !== 'production') {
    setLocalStorageItem('debug', 'GEOvis4:*');
    Logger.info("logging is enabled");
}

Logger.info(`backend: ${API_ROOT}`);
Logger.info(`window width is: ${window.innerWidth}`);

const App = () => {
    return (
        <React.Suspense fallback={<div>Loading {GeovisName} Application...</div>}>
            <GeovisNavigationRouter />
        </React.Suspense>
    );
}

export default App;
