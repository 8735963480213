import { CSSProperties } from "react";
import { GeovisHeaderModel } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisHeaderModel";
import { GeovisHeaderCustomLogoRender, GeovisHeaderProjectLogoRender } from "./GeovisHeaderLogoRender";
import { GeovisHeaderLogoSource } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisHeaderLogoSource";
import { getFormattedCurrentDate } from "../../../../../helpers/DateHelper";
import { t } from "../../../../../i18n";

interface IHeaderRenderProps {

    header: GeovisHeaderModel;

    timeSlotsFormat?: string;
    minDateTime: string;
    maxDateTime: string;
}

export const HeaderRenderSlim = ({ header, timeSlotsFormat, maxDateTime, minDateTime }: IHeaderRenderProps) => {

    const column2LabelStyle: CSSProperties = { width: '45px', display: 'inline-block' };
    const column3LabelStyle: CSSProperties = { width: '65px', display: 'inline-block' };

    if (!timeSlotsFormat) {
        timeSlotsFormat = "DD.MM.yyyy HH:mm:ss";
    }

    // const minDateFormatted = formattedDateTime(minDateTime, timeSlotsFormat);
    // const maxDateFormatted = formattedDateTime(maxDateTime, timeSlotsFormat);

    let logoColumnMinWidth = 30;
    let authorColumnMinWidth = 'auto';
    let descriptionFontSize = 14;
    if (header.Description1 || header.Description2) {
        logoColumnMinWidth = 25;
        authorColumnMinWidth = '20%';

        const d1Length = header.Description1 ? header.Description1.length : 0;
        const d2Length = header.Description2 ? header.Description2.length : 0;

        const maxDescriptionLength = Math.max(d1Length, d2Length);
        if (maxDescriptionLength > 40) {
            descriptionFontSize = 12;
        }
        else if (maxDescriptionLength > 30) {
            descriptionFontSize = 13;
        }
    }

    return (
        <div className="reportHeaderContentContainer">
            <div className="flexRowContainer reportHeaderTitleCell" >
                <div className="flexCellContainer" style={{ textAlign: 'center', width: '100%' }}>
                    <label>{header.Title}</label>
                </div>
            </div>

            <div className="flexRowContainer" style={{ borderBottom: '2px solid black' }}>
                {(header.Description1 || header.Description2) &&
                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, fontSize: `${descriptionFontSize}px` }}>
                        <div className="flexCellContainer">
                            {header.Description1}
                        </div>
                        <div className="flexCellContainer">
                            {header.Description2}
                        </div>
                    </div>
                }

                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, whiteSpace: 'nowrap' }}>
                    <div className="flexCellContainer">
                        <label style={column2LabelStyle}>{t("From")}:</label>
                        <label>{minDateTime}</label>
                    </div>
                    <div className="flexCellContainer">
                        <label style={column2LabelStyle}>{t("To")}:</label>
                        <label>{maxDateTime}</label>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minWidth: `${authorColumnMinWidth}` }}>
                    <div className="flexCellContainer">
                        <label style={column3LabelStyle}>{t("Author")}:</label>
                        <label>{header.Author}</label>
                    </div>
                    <div className="flexCellContainer">
                        <label style={column3LabelStyle}>{t("Created")}:</label>
                        <label>{getFormattedCurrentDate()}</label>
                    </div>
                </div>

                <div className="flexCellContainer" style={{ display: 'flex', justifyContent: 'center', minWidth: `${logoColumnMinWidth}%` }}>
                    {header.LogoSource === GeovisHeaderLogoSource.Project && (
                        <GeovisHeaderProjectLogoRender projectId={header.ProjectId} />
                    )}
                    {header.LogoSource === GeovisHeaderLogoSource.Custom && (
                        <GeovisHeaderCustomLogoRender projectId={header.ProjectId} logoId={header.CustomLogoId} />
                    )}
                </div>
            </div>
        </div>
    );
}