import React from "react";
import { FallbackProps, withErrorBoundary } from "react-error-boundary";
import { GeovisFallbackComponent } from "./GeovisFallbackComponent";

const FallbackComponent = (props: FallbackProps) => (
    <div>
        <GeovisFallbackComponent {...props} />
    </div>
);

export const withGeovisErrorBoundary = <P extends object>(Component: React.ComponentType<P>) => {
    return withErrorBoundary(Component, FallbackComponent);
}