import { Reducer } from "redux";
import {
    NEW_VERSION_NOTIFIED,
    SERVICE_WORKER_ON_UPDATE
} from "../actions/serviceWorkerActions";
import { IGeovisServiceWorkerAction, IGeovisServiceWorkerState } from "../types";

const serviceWorkerInitialState: IGeovisServiceWorkerState = {
    isNewVersionAvailable: false,
    toastId: "",
    serviceWorkerRegistration: undefined
}

const serviceWorkerReducer: Reducer<IGeovisServiceWorkerState> = (state: IGeovisServiceWorkerState = serviceWorkerInitialState, action: IGeovisServiceWorkerAction): IGeovisServiceWorkerState => {
    switch (action.type) {
        case SERVICE_WORKER_ON_UPDATE: {
            return { ...state, isNewVersionAvailable: true, serviceWorkerRegistration: action.serviceWorkerRegistration }
        }

        case NEW_VERSION_NOTIFIED: {
            return { ...state, isNewVersionAvailable: false, toastId: action.toastId ? action.toastId : "" }
        }
    }

    return state;
}

export default serviceWorkerReducer;