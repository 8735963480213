import { cloneDeep } from "lodash";
import { Reducer } from "redux";
import { addOrUpdateElementInArray } from "../../helpers/StorageHelper";
import { ReportElementType } from "../../server/AVTService/TypeLibrary/Common/ReportElementType";
import { GeovisHeaderModel } from "../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisHeaderModel";
import { PROJECT_GEOVIS_REPORT_CHART_DATA_LOADED, PROJECT_GEOVIS_REPORT_SERVICE_DATA_CHANGED } from "../actions/projectReportActions";
import {
    PROJECT_REPORT_HEADER_LOADED,
    PROJECT_REPORT_HEADER_LOADING
} from "../actions/reportRenderActions";
import { processFetchedData } from "../helpers/DataHelper";
import { IGeovisProjectReportAction } from "../projectReport.types";
import { IHeaderElementDataInfo, IReportHeaderState, IReportRenderAction, IReportRenderState } from "../reportRender.types";
import { defaultSomethingStorageState } from "../types";

const headerInitialState: IReportHeaderState = {
    ...defaultSomethingStorageState,
    reportHeaderInfo: new GeovisHeaderModel(),
    pageChartsInfo: new Map<number, IHeaderElementDataInfo[]>(),
    pageLogbooksInfo: new Map<number, IHeaderElementDataInfo[]>()
}

export const reportRenderInitialState: IReportRenderState = {
    headerState: {
        ...headerInitialState
    },
    footerState: {
        pageNum: 1,
        pageTotal: 1
    }
}

const reportRenderReducer: Reducer<IReportRenderState> = (
    state: IReportRenderState = reportRenderInitialState,
    action: IReportRenderAction
): IReportRenderState => {

    switch (action.type) {
        case PROJECT_REPORT_HEADER_LOADING: {
            return {
                ...state,
                headerState: {
                    ...headerInitialState
                }
            }
        }

        case PROJECT_REPORT_HEADER_LOADED: {
            if (!action.reportHeaderInfo) {
                return state;
            }

            return {
                ...state,
                headerState: processFetchedData(action.reportHeaderInfo, state.headerState, headerInitialState, st => ({
                    reportHeaderInfo: st,
                    pageChartsInfo: new Map<number, IHeaderElementDataInfo[]>(), // reset it
                    pageLogbooksInfo: new Map<number, IHeaderElementDataInfo[]>()
                }))
            }
        }

        case PROJECT_GEOVIS_REPORT_CHART_DATA_LOADED: {
            const reportAction = action as IGeovisProjectReportAction;

            const { geovisChartData, pageNum } = reportAction;
            if (!geovisChartData || !geovisChartData.Success || pageNum === undefined) {
                return state;
            }

            const { SlimChartConfig } = geovisChartData.Data;

            const chartsInfo = state.headerState.pageChartsInfo.get(pageNum);

            // if no page info, then create it
            if (!chartsInfo) {

                const pageChartsInfo = cloneDeep(state.headerState.pageChartsInfo);
                pageChartsInfo.set(pageNum, [{
                    elementId: SlimChartConfig.Id,
                    startTimeString: SlimChartConfig.StartDate,
                    endTimeString: SlimChartConfig.EndDate,
                    elementType: ReportElementType.GeovisChart
                }]);

                return {
                    ...state,
                    headerState: {
                        ...state.headerState,
                        pageChartsInfo
                    }
                }
            }

            if (SlimChartConfig) {
                state.headerState.pageChartsInfo.set(pageNum, addOrUpdateElementInArray(chartsInfo, e => e.elementId === SlimChartConfig.Id && e.elementType === ReportElementType.GeovisChart, {
                    elementId: SlimChartConfig.Id,
                    startTimeString: SlimChartConfig.StartDate,
                    endTimeString: SlimChartConfig.EndDate,
                    elementType: ReportElementType.GeovisChart
                }));
            }

            return {
                ...state,
                headerState: {
                    ...state.headerState
                }
            }
        }

        case PROJECT_GEOVIS_REPORT_SERVICE_DATA_CHANGED: {
            const { elementId, stringValue2, stringValue, pageNum } = action as IGeovisProjectReportAction;

            if (elementId === undefined || stringValue2 === undefined || stringValue === undefined || pageNum === undefined) {
                return state;
            }

            const logbooksInfo = state.headerState.pageLogbooksInfo.get(pageNum);
            if (!logbooksInfo) {
                const pageLogbooksInfo = cloneDeep(state.headerState.pageLogbooksInfo);
                pageLogbooksInfo.set(pageNum, [{
                    elementId: elementId,
                    startTimeString: stringValue,
                    endTimeString: stringValue2,
                    elementType: ReportElementType.Logbook
                }]);

                return {
                    ...state,
                    headerState: {
                        ...state.headerState,
                        pageLogbooksInfo
                    }
                }
            }

            state.headerState.pageLogbooksInfo.set(pageNum, addOrUpdateElementInArray(logbooksInfo, e => e.elementId === elementId && e.elementType === ReportElementType.Logbook, {
                elementId: elementId,
                startTimeString: stringValue,
                endTimeString: stringValue2,
                elementType: ReportElementType.Logbook
            }))

            return {
                ...state,
                headerState: {
                    ...state.headerState
                }
            }
        }
    }

    return state;
}

export default reportRenderReducer;