import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { IGeovisChartReportData } from "../../../../../../store/projectReport.types";
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../../../../../../store/types";
import { LoadingContainerSkeleton } from "../../../../../LoadingContainerSkeleton";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../../helpers/GeovisHooks";
import { t } from "../../../../../../i18n";
import { VibrationChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { VibrationChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/VibrationChartData";
import { getVibrationBackgroundChartRenderOptions } from "../options/vibrationBackgroundRenderOptions";
import { ErrorContainerSkeleton } from "../../../../../ErrorContainerSkeleton";
import { fetchServerElementsByPost } from "../../../../../../helpers/ProjectDataHelper";
import { GeovisChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartData";
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import ServerRoutesGen from "../../../../../../server/Routes/ServerRoutesGen";


interface IComponentProps extends IWithGeovisServerProps {
    chart: VibrationChartModel;
    onShowEventChart: (eventId: string, fullId: string, eventDate: string) => void;
    projectId: number;
}

interface IComponentState {
    chartData: IGeovisChartReportData<VibrationChartData>;
}

const Component = ({
    chart,
    Server,
    onShowEventChart,
    projectId
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        chartData: {
            ...defaultSomethingStorageState,
            dtsSectionInfos: [],
            Timestamp: 0,
            data: {
                ...new VibrationChartData()
            }
        }
    });

    useEffect(() => {
        (async function loadChartData() {
            const url = ServerRoutesGen.ReportRenderData.GetGeovisPreviewChartCustomData.patch(projectId);
            const response = await fetchServerElementsByPost<GeovisChartData, GeovisChartModel>(Server, url, chart);
            if (!response.Success) {
                setState({
                    ...state,
                    chartData: {
                        ...errorSomethingStorageState(...response.Messages),
                        dtsSectionInfos: [],
                        Timestamp: 0,
                        data: {
                            ...new VibrationChartData()
                        }
                    }
                });
                return;
            }
            setState({
                ...state,
                chartData: {
                    ...loadedSomethingStorageState,
                    data: response.Data as VibrationChartData,
                    dtsSectionInfos: [],
                    Timestamp: 0
                }
            })
        })()
    }, [1])

    if (!state.chartData.isLoaded) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <LoadingContainerSkeleton loadingText={t("Loading Vibration preview chart data")} />
            </div>
        )
    }

    if (state.chartData.isError) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <ErrorContainerSkeleton errorText={`${t("Failed to load Vibration chart data")}: ${state.chartData.errorDescription}`} />
            </div>
        )
    }

    const { data, Timestamp } = state.chartData

    const renderOptions = getVibrationBackgroundChartRenderOptions(0, cloneDeep(chart), data, onShowEventChart);

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);
    HighchartsBoost(Highcharts);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <HighchartsReact
                key={`highcharts-${chart.Id}-0-${Timestamp}`}
                highcharts={Highcharts}
                options={renderOptions}
                constructorType={'chart'}
                containerProps={{ style: { flexGrow: 1, height: `100%` } }}
            />
        </div>
    )

}

export const VibrationPreviewChartRender = withGeovisServer(Component);

