/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 24.09.2020
 * @description Message template view state actions
 */

export const GEOVIS_MESSAGE_TEMPLATE = 'GEOVIS_MESSAGE_TEMPLATE';
export const GEOVIS_MESSAGE_TEMPLATE_DATA = 'GEOVIS_MESSAGE_TEMPLATE_DATA';
export const GEOVIS_MESSAGE_TEMPLATE_DATA_ERROR = 'GEOVIS_MESSAGE_TEMPLATE_DATA_ERROR';

export const GEOVIS_MESSAGE_TEMPLATE_START_NOTIFICATION = 'GEOVIS_MESSAGE_TEMPLATE_START_NOTIFICATION';
export const GEOVIS_MESSAGE_TEMPLATE_END_NOTIFICATION = 'GEOVIS_MESSAGE_TEMPLATE_END_NOTIFICATION';

export const GEOVIS_MESSAGE_TEMPLATE_ADD_ATTACHMENT = 'GEOVIS_MESSAGE_TEMPLATE_ADD_ATTACHMENT';
export const GEOVIS_MESSAGE_TEMPLATE_REMOVE_DIRTY_ATTACHMENT = 'GEOVIS_MESSAGE_TEMPLATE_REMOVE_DIRTY_ATTACHMENT';
export const GEOVIS_MESSAGE_TEMPLATE_MARK_ATTACHMENT_TO_REMOVE = 'GEOVIS_MESSAGE_TEMPLATE_MARK_ATTACHMENT_TO_REMOVE';