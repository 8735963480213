import Button from "@atlaskit/button";
import DynamicTable from "@atlaskit/dynamic-table";
import { HeadType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle'
import DetailViewIcon from '@atlaskit/icon/glyph/detail-view'
import EditorRemoveEmojiIcon from '@atlaskit/icon/glyph/editor/remove-emoji'
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play'
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { formattedDateTime } from "../../../../helpers/DateHelper";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { fetchServerElements, sendServerPostRequest } from "../../../../helpers/ProjectDataHelper";
import { t } from "../../../../i18n";
import { getMigratebleElementsToName, MigratebleElements } from "../../../../server/AVTService/TypeLibrary/Common/ElementsMigrations/MigratebleElements";
import { ProjectElementMigrationState } from "../../../../server/AVTService/TypeLibrary/Common/ElementsMigrations/ProjectElementMigrationState";
import { DataActionResponse } from "../../../../server/DataActionResponse";
import { ProjectElementsMigrationsInfo } from "../../../../server/GEOvis3/Model/ElementsMigrations/ProjectElementsMigrationsInfo";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import AuthService from "../../../../services/AuthService";
import FlagService from "../../../../services/FlagService";
import { elementsMigrationsHideProjectDialog, elementsMigrationsUpdateProjectInfo } from "../../../../store/creators/elementsMigrationsCreator";
import { IElementsMigrationState } from "../../../../store/elementsMigrations.types";
import { IGeovisStoreState } from "../../../../store/store.types";
import { IGeovisAction } from "../../../../store/types";
import { ProjectElementMigrationDetailsDialog } from "./ProjectElementMigrationDetailsDialog";
import { ProjectElementMigrationStatusSelect } from "./ProjectElementMigrationStatusSelect";
import { implementedMigrationsListElements } from "./types";

interface IDialogStateProps {
    projectInfo: ProjectElementsMigrationsInfo | undefined;
}

interface IDialogDispatchProps {
    onClose: () => void;
    onUpdateProjectInfo: (projectInfo: ProjectElementsMigrationsInfo) => void;
}

interface IDialogProps extends IDialogStateProps, IDialogDispatchProps, IWithGeovisServerProps {

}

const DialogComponent = ({ Server, projectInfo, onUpdateProjectInfo, onClose }: IDialogProps) => {

    const [detailsState, setDetailsState] = useState<{ elementType: MigratebleElements } | false>(false);

    if (!projectInfo) {
        return null;
    }

    const getTableHead = (): HeadType => ({
        cells: [{
            key: 'name',
            content: t("Element type")
        }, {
            key: 'status',
            content: t("Status"),
            width: 10
        }, {
            key: 'date',
            content: t("Date"),
            width: 10
        }, {
            key: 'actions',
            width: 12
        }]
    });

    const onMigrateElements = (type: MigratebleElements) => async () => {
        const url = ServerRoutesGen.Migrations.MigrateElements.patch(projectInfo.ProjectId, type);

        const response = await fetchServerElements<ProjectElementsMigrationsInfo>(Server, url, {
            headers: { access_token_mt: AuthService.getMapTilesToken() }
        });
        if (!response.Success) {
            FlagService.addError("Failed to migrate element", response.Messages.join(', '));
            return;
        }
        onUpdateProjectInfo(response.Data);
    }

    const onRollbackElements = (type: MigratebleElements) => async () => {

        const url = ServerRoutesGen.Migrations.RollbackElementsMigrations.patch(projectInfo.ProjectId, type);
        const response = await fetchServerElements<ProjectElementsMigrationsInfo>(Server, url);

        if (!response.Success) {
            FlagService.addError("Failed to rollback element", response.Messages.join(', '));
            return;
        }

        onUpdateProjectInfo(response.Data);
    }

    const onRemoveOldElements = (type: MigratebleElements) => async () => {

        const url = ServerRoutesGen.Migrations.RemoveOldElements.patch(projectInfo.ProjectId, type);
        const response = await fetchServerElements<ProjectElementsMigrationsInfo>(Server, url);

        if (!response.Success) {
            FlagService.addError("Failed to rollback element", response.Messages.join(', '));
            return;
        }

        onUpdateProjectInfo(response.Data);
    }

    const onSetMigrationStateFunc = (type: MigratebleElements) => async (state: ProjectElementMigrationState) => {
        const url = ServerRoutesGen.Migrations.SetProjectElementsMigrationState.patch(projectInfo.ProjectId, type, state);
        const response = await sendServerPostRequest<DataActionResponse<ProjectElementsMigrationsInfo>>(Server, url);

        if (!response.Success) {
            FlagService.addErrors("Fail to set elements migration state", response.Messages);
            return;
        }

        onUpdateProjectInfo(response.Data);
    }

    const onShowDetailsDialog = (elementType: MigratebleElements) => () => setDetailsState({ elementType });

    const onHideDetailsDialog = () => setDetailsState(false);

    const getTableRows = (): RowType[] => implementedMigrationsListElements.map(me => {

        const element = projectInfo.Migrations.find(ch => ch.ElementType === me);

        return ({
            cells: [{
                key: 'name',
                content: (<span>{t(getMigratebleElementsToName(me))}</span>)
            }, {
                key: 'status',
                content: (
                    <React.Fragment>
                        <ProjectElementMigrationStatusSelect
                            state={element ? element.State : ProjectElementMigrationState.NotMigrated}
                            onChange={onSetMigrationStateFunc(me)}
                        />
                    </React.Fragment>
                )
            }, {
                key: 'date',
                content: element ? formattedDateTime(element.MigrationTime, "DD.MM.YYYY") : "--"
            }, {
                key: 'actions',
                content: (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {element && element.State !== ProjectElementMigrationState.NotMigrated && (
                            <React.Fragment>

                                {/* details button */}
                                <Button
                                    style={{ width: '100px' }}
                                    onClick={onShowDetailsDialog(element.ElementType)}
                                    iconBefore={<DetailViewIcon label='' />}>
                                    {t("Details")}
                                </Button>

                                {(element.State === ProjectElementMigrationState.Ok || element.State === ProjectElementMigrationState.Warning) && (
                                    <React.Fragment>
                                        {/* rollback button */}
                                        <Button
                                            style={{ marginLeft: '0.5em', width: '100px' }}
                                            onClick={onRollbackElements(element.ElementType)}
                                            iconBefore={<ArrowLeftCircleIcon label='' />}>
                                            {t("Rollback")}
                                        </Button>

                                        {/* remove old elements button */}
                                        <Button
                                            style={{ marginLeft: '0.5em', width: '100px' }}
                                            onClick={onRemoveOldElements(element.ElementType)}
                                            iconBefore={<EditorRemoveEmojiIcon label='' />}>
                                            {t("Remove old elements")}
                                        </Button>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}

                        <Button
                            style={{ marginLeft: '0.5em', width: '100px' }}
                            onClick={onMigrateElements(me)}
                            iconBefore={<VidPlayIcon label='' />}>
                            {t("Migrate")}
                        </Button>
                    </div>
                )
            }]
        })
    })

    return (
        <React.Fragment>

            <ModalTransition>
                <Modal
                    heading={t("Manage elements migrations for project ") + projectInfo.ProjectName}
                    actions={[{ text: t("Close"), onClick: onClose }]}
                    width='60%'>
                    <div>
                        <DynamicTable
                            head={getTableHead()}
                            rows={getTableRows()}
                        />
                    </div>
                </Modal>
            </ModalTransition>

            {detailsState !== false && (
                <ProjectElementMigrationDetailsDialog
                    elementType={detailsState.elementType}
                    projectId={projectInfo.ProjectId}
                    onClose={onHideDetailsDialog} />
            )}
        </React.Fragment>
    )
}

const getProjectMigrationsInfoToShow = ({ projectId, showProjectDetailsDialog, projectsMigrationsStorage: { isLoading, isError, projectsMigrationsData } }: IElementsMigrationState): ProjectElementsMigrationsInfo | undefined => {
    if (projectId === 0 || !showProjectDetailsDialog || isLoading || isError) {
        return undefined;
    }

    return projectsMigrationsData.find(d => d.ProjectId === projectId);
}

const mapStateToProps = ({ projectElementsMigrations }: IGeovisStoreState): IDialogStateProps => ({
    projectInfo: getProjectMigrationsInfoToShow(projectElementsMigrations)
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDialogDispatchProps => ({
    onUpdateProjectInfo: projectInfo => dispatch(elementsMigrationsUpdateProjectInfo(projectInfo)),
    onClose: () => dispatch(elementsMigrationsHideProjectDialog())
});

export default connect<IDialogStateProps, IDialogDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(DialogComponent))