import { Reducer } from "redux";
import {
    GEOVIS_SETTINGS_HIDE_PING_DIALOG,
    GEOVIS_SETTINGS_SENDING_PING,
    GEOVIS_SETTINGS_SENT_PING,
    GEOVIS_SETTINGS_SHOW_PING_DIALOG
} from "../actions/settingsActions";
import { IGeovisSettingsAction, IGeovisSettingsState } from "../types";

export const settingsStateInitialState: IGeovisSettingsState = {
    pingResult: "",
    pingSending: false,
    showPingDialog: false
}

const settingsReducer: Reducer<IGeovisSettingsState> = (state: IGeovisSettingsState = settingsStateInitialState, action: IGeovisSettingsAction): IGeovisSettingsState => {
    switch (action.type) {
        case GEOVIS_SETTINGS_SHOW_PING_DIALOG: {
            return {
                pingResult: "",
                pingSending: false,
                showPingDialog: true
            }
        }

        case GEOVIS_SETTINGS_HIDE_PING_DIALOG: {
            return settingsStateInitialState;
        }

        case GEOVIS_SETTINGS_SENDING_PING: {
            return {
                ...state,
                pingSending: true,
                pingResult: ''
            }
        }

        case GEOVIS_SETTINGS_SENT_PING: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                pingSending: false,
                pingResult: action.stringProperty
            }
        }
    }

    return state;
}

export default settingsReducer;