import { ReactText } from "react";
import {
    NEW_VERSION_NOTIFIED,
    SERVICE_WORKER_ON_UPDATE
} from "../actions/serviceWorkerActions";
import { IGeovisServiceWorkerAction } from "../types";

/**
 * Actions for communicating with the Service worker
 */
export const serviceWorkerOnUpdate = (serviceWorkerRegistration: ServiceWorkerRegistration): IGeovisServiceWorkerAction => {
    return { type: SERVICE_WORKER_ON_UPDATE, serviceWorkerRegistration };
}

export const newVersionNotified = (toastId: ReactText): IGeovisServiceWorkerAction => {
    return { type: NEW_VERSION_NOTIFIED, toastId }
}