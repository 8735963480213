import ErrorIcon from '@atlaskit/icon/glyph/error'

interface IErrorContainerSkeletonProps {
    errorText?: string;
}

export const ErrorContainerSkeleton = ({ errorText }: IErrorContainerSkeletonProps) => {
    return (
        <div className="flexRowMiddleContainer" style={{ width: "100%", height: '100%' }}>
            <div className="skeletonContainer">
                <ErrorIcon label='error' primaryColor='red' size='xlarge' />
                {errorText && (
                    <span style={{ marginLeft: '20px', fontSize: '20px', color: 'red' }}>{errorText}</span>
                )}
            </div>
        </div>
    );
}