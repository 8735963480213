//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum KindOfElementUsing {
	Undefined = 0,
	Element = 1,
	Template = 2,
	Default = 4,
	DefaultTemplate = 22
}

const KindOfElementUsingToName = new Map<number, string>([
	[ 0, 'Undefined' ],
	[ 1, 'Element' ],
	[ 2, 'Template' ],
	[ 4, 'Default' ],
	[ 22, 'DefaultTemplate' ],
])

export function getKindOfElementUsingToName(element: KindOfElementUsing) : string {
    return KindOfElementUsingToName.get(element) || '';
}

const KindOfElementUsingByName = new Map<string, number>([
	[ 'Undefined', 0 ],
	[ 'Element', 1 ],
	[ 'Template', 2 ],
	[ 'Default', 4 ],
	[ 'DefaultTemplate', 22 ],
]);

export function getKindOfElementUsingByName(name: string): KindOfElementUsing {
    return KindOfElementUsingByName.get(name)!;
}


export const KindOfElementUsingList : Readonly<KindOfElementUsing[]> = [
	KindOfElementUsing.Undefined,
	KindOfElementUsing.Element,
	KindOfElementUsing.Template,
	KindOfElementUsing.Default,
	KindOfElementUsing.DefaultTemplate,
];



const KindOfElementUsingToDescription = new Map<number, string>([
	[ 0, 'Undefined' ],
	[ 1, 'Element' ],
	[ 2, 'Template' ],
	[ 4, 'Default' ],
	[ 22, 'DefaultTemplate' ],
])

export function getKindOfElementUsingToDescription(element: KindOfElementUsing) : string {
    return KindOfElementUsingToDescription.get(element) || '';
}

