//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { LocalMapObjectType } from './LocalMapObjectType';

export abstract class LocalMapObject
{
	public Id: string;
	public ProjectId: number;
	public Name: string;
	public Description: string;
	public ObjectType: LocalMapObjectType;
	public IsPublic: boolean;
	public Created: string;
}
