//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ChartAlarmLineType } from '../Common/ChartAlarmLineType';
import { AlarmChartValueType } from '../Common/AlarmChartValueType';

export class AlarmInfoRecord
{
	public enabled: boolean;
	public alarmId: string;
	public swapXY: boolean;
	public label: string;
	public xvalueFrom: string;
	public xvalueTo: string;
	public yvalueFrom: string;
	public yvalueTo: string;
	public lineColor: string;
	public lineWeight: number;
	public lineType: ChartAlarmLineType;
	public alarmChart: string;
	public alarmChartValueType: AlarmChartValueType;
	public fillAlarmArea: boolean;
	public fillFrom: number;
	public fillTo: number;
	public fillFromY: number;
	public fillToY: number;
	public drawLine: boolean;
	public isGap: boolean;
}
