//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DtsSectionLoop {
	Loop1 = 0,
	Loop2 = 1,
	Loop3 = 2,
	Loop4 = 3
}

const DtsSectionLoopToName = new Map<number, string>([
	[ 0, 'Loop1' ],
	[ 1, 'Loop2' ],
	[ 2, 'Loop3' ],
	[ 3, 'Loop4' ],
])

export function getDtsSectionLoopToName(element: DtsSectionLoop) : string {
    return DtsSectionLoopToName.get(element) || '';
}

const DtsSectionLoopByName = new Map<string, number>([
	[ 'Loop1', 0 ],
	[ 'Loop2', 1 ],
	[ 'Loop3', 2 ],
	[ 'Loop4', 3 ],
]);

export function getDtsSectionLoopByName(name: string): DtsSectionLoop {
    return DtsSectionLoopByName.get(name)!;
}


export const DtsSectionLoopList : Readonly<DtsSectionLoop[]> = [
	DtsSectionLoop.Loop1,
	DtsSectionLoop.Loop2,
	DtsSectionLoop.Loop3,
	DtsSectionLoop.Loop4,
];



const DtsSectionLoopToDescription = new Map<number, string>([
	[ 0, 'Loop1' ],
	[ 1, 'Loop2' ],
	[ 2, 'Loop3' ],
	[ 3, 'Loop4' ],
])

export function getDtsSectionLoopToDescription(element: DtsSectionLoop) : string {
    return DtsSectionLoopToDescription.get(element) || '';
}

