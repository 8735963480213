/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 03.02.2020
 * @description Icons descriptions and other definitions
 */

import {
    projectStateFlagPrimitive,
    withSvgOfIconProps
} from "./IconPrimitives";
import { getCircleIconSvg } from "./IconsRenderers";
import { IProjectsOverviewIconMapIconProps } from "./types";

const FlagOffsetPx = 3;

/**
 * Projects overview page project state icon rendered
 * @param props 
 */
export const ProjectStateIconRenderer = (props: IProjectsOverviewIconMapIconProps) => {

    const { circle, className, iconSize } = props;

    // take first 5 flags, otherwise it is too much to show
    const flags = props.flags.filter((f, index) => index < 5);

    const circleSvg = getCircleIconSvg(circle, "prj", true);
    const flagsSvg = flags.map<string>((flagProps, index) => {
        const { iconAnchor: { x, y } } = flagProps;

        return projectStateFlagPrimitive({
            ...flagProps,
            iconAnchor: L.point(x + index * FlagOffsetPx, y + (flags.length - index - 1) * FlagOffsetPx)
        });
    }).reverse();

    const svg = flagsSvg.join('\n') + '\n' + circleSvg;
    return withSvgOfIconProps({
        ...circle,
        className,
        iconSize
    })(svg);
}