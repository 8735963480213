//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MigratebleElements {
	TimeValueCharts = 0,
	XYCharts = 1,
	InclinometerCharts = 2,
	VibrationCharts = 3,
	XYVibrationCharts = 4,
	GeovisTables = 5,
	Headers = 6,
	Profiles = 7,
	ProfileTemplates = 8,
	DefaultCharts = 9,
	Logbooks = 10,
	Comments = 11,
	Maps = 12,
	Reports = 100,
	CustomGroups = 120
}

const MigratebleElementsToName = new Map<number, string>([
	[ 0, 'TimeValueCharts' ],
	[ 1, 'XYCharts' ],
	[ 2, 'InclinometerCharts' ],
	[ 3, 'VibrationCharts' ],
	[ 4, 'XYVibrationCharts' ],
	[ 5, 'GeovisTables' ],
	[ 6, 'Headers' ],
	[ 7, 'Profiles' ],
	[ 8, 'ProfileTemplates' ],
	[ 9, 'DefaultCharts' ],
	[ 10, 'Logbooks' ],
	[ 11, 'Comments' ],
	[ 12, 'Maps' ],
	[ 100, 'Reports' ],
	[ 120, 'CustomGroups' ],
])

export function getMigratebleElementsToName(element: MigratebleElements) : string {
    return MigratebleElementsToName.get(element) || '';
}

const MigratebleElementsByName = new Map<string, number>([
	[ 'TimeValueCharts', 0 ],
	[ 'XYCharts', 1 ],
	[ 'InclinometerCharts', 2 ],
	[ 'VibrationCharts', 3 ],
	[ 'XYVibrationCharts', 4 ],
	[ 'GeovisTables', 5 ],
	[ 'Headers', 6 ],
	[ 'Profiles', 7 ],
	[ 'ProfileTemplates', 8 ],
	[ 'DefaultCharts', 9 ],
	[ 'Logbooks', 10 ],
	[ 'Comments', 11 ],
	[ 'Maps', 12 ],
	[ 'Reports', 100 ],
	[ 'CustomGroups', 120 ],
]);

export function getMigratebleElementsByName(name: string): MigratebleElements {
    return MigratebleElementsByName.get(name)!;
}


export const MigratebleElementsList : Readonly<MigratebleElements[]> = [
	MigratebleElements.TimeValueCharts,
	MigratebleElements.XYCharts,
	MigratebleElements.InclinometerCharts,
	MigratebleElements.VibrationCharts,
	MigratebleElements.XYVibrationCharts,
	MigratebleElements.GeovisTables,
	MigratebleElements.Headers,
	MigratebleElements.Profiles,
	MigratebleElements.ProfileTemplates,
	MigratebleElements.DefaultCharts,
	MigratebleElements.Logbooks,
	MigratebleElements.Comments,
	MigratebleElements.Maps,
	MigratebleElements.Reports,
	MigratebleElements.CustomGroups,
];



const MigratebleElementsToDescription = new Map<number, string>([
	[ 0, 'TimeValueCharts' ],
	[ 1, 'XYCharts' ],
	[ 2, 'InclinometerCharts' ],
	[ 3, 'VibrationCharts' ],
	[ 4, 'XYVibrationCharts' ],
	[ 5, 'GeovisTables' ],
	[ 6, 'Headers' ],
	[ 7, 'Profiles' ],
	[ 8, 'ProfileTemplates' ],
	[ 9, 'DefaultCharts' ],
	[ 10, 'Logbooks' ],
	[ 11, 'Comments' ],
	[ 12, 'Maps' ],
	[ 100, 'Reports' ],
	[ 120, 'CustomGroups' ],
])

export function getMigratebleElementsToDescription(element: MigratebleElements) : string {
    return MigratebleElementsToDescription.get(element) || '';
}

