//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class MessageTemplateInfo
{
	public Id: string;
	public Subject: string;
	public AuthorId: string;
	public SharedToUserIds: string[];
	public Created: string;
	public Updated: string;
	public TemplateHashId: string;
	public CompanyId: string;
}
