import React, { useEffect, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import { ImageBackgroundInfo } from "../../server/AVTService/TypeLibrary/ImageAsMap/ImageBackgroundInfo";
import { MapArea } from "../../server/AVTService/TypeLibrary/Map/MapArea";
import { ImageBackgroundFileType } from '../../server/ImageBackgroundFileType';
import { ImageBackgroundViewPort } from '../../server/ImageBackgroundViewPort';
import Logger from '../../services/Logger';
import { MapEventsComponent } from '../leaflet/MapEventsComponent';
import { MaxLocalMapObjectZoomLevel } from '../map/types';
import { getMapSelectionBounds } from '../projectOverview/mapSection/tools';
import { ImageMapDXFLayer } from './DXFLayer';
import { ImageMapDXFMapTilesLayer } from './DXFMapTilesLayer';
import { getDeltaCoefficient, getDxfImageBounds } from './tools';
import {
    IImageMapViewBounds,
    ImageMapContentType,
    isDxfBackgroundFileType,
    isDxfMapTilesBackgroundFileType,
    isEmptyViewPort,
    isRasterBackgroundFileType,
    viewPortMapCenter,
    viewPortToMapBounds
} from "./types";
import { GeovisImageLayer } from './GeovisImageLayer';
import { GeovisMapPaneCreator } from '../map/GeovisMap.PaneCreator';
import './../map/geovis.leaflet.domUtils.js';

const RASTER_BIG_IMAGE_MIN_ZOOM = 2;
const COMMON_MIN_ZOOM = 0;

interface IImageMapProps {
    imageInfo: ImageBackgroundInfo;
    elementId: string;
    projectId: number;
    contentType: ImageMapContentType;

    children?: React.ReactNode
    rootContainer?: HTMLDivElement;

    disableManipulation?: boolean

    onClick?: (point: L.LatLng) => void;
    onReadyToDrawLegend?: (leafletElementRef: L.Map) => void;
    onRendered?: () => void;
    onZoomingFinished?: (leafletElementRef: L.Map) => void;
}

const ImageMapComponent = ({
    contentType,
    elementId,
    imageInfo,
    projectId,
    onClick,
    onReadyToDrawLegend,
    rootContainer,
    children,
    disableManipulation,
    onZoomingFinished,
    onRendered
}: IImageMapProps) => {

    const [mapRef, setMapRef] = useState<L.Map | null>(null);

    useEffect(() => {
        if (mapRef !== null) {

            //First: zoom and fit our map
            setTimeout(zoomAll, 200);
            setTimeout(onZoomFinishedHandler, 200);

            //Second: draw legend. This should be done on this stage because we need in exact map instance to calculate correct scale
            if (onReadyToDrawLegend) {
                setTimeout(() => onReadyToDrawLegend(mapRef), 1000);
            }

            //Third: report that image done
            if (onRendered) {
                onRendered();
            }
        }
    }, [mapRef])

    const onZoomFinishedHandler = () => {
        if (onZoomingFinished && mapRef) {
            onZoomingFinished(mapRef)
        }
    }

    const zoomAll = () => {

        if (imageInfo.IsMapTiles) {

            if (mapRef) {
                const bounds = getDxfImageBounds(imageInfo.ViewPort);
                mapRef.invalidateSize();
                mapRef.fitBounds(bounds);

                return;
            }
            else {
                return;
            }
        }

        if (!isEmptyViewPort(imageInfo.ViewPort)) {

            const mapBounds = contentType === 'LMO' || contentType === 'SensorImage'
                ? lmoViewPortToMapBounds(imageInfo)
                : geovisImageViewPortToMapBounds(imageInfo)

            if (mapBounds.isValid() && mapRef) {
                mapRef.fitBounds(mapBounds);
                mapRef.invalidateSize();
            }
        }
    }

    const onMapClickHandler = (event: L.LeafletMouseEvent) => {
        Logger.trace(`onClick: lat: ${event.latlng.lat}, lng: ${event.latlng.lng}`);
        Logger.trace(`onClick: Container x: ${event.containerPoint.x}, Container Y: ${event.containerPoint.y}`);
        if (onClick) {
            onClick(event.latlng);
        }
    }

    const getMaxImageWidth = () => {
        return 1750;
    }

    const getMaxImageHeight = () => {
        return 800;
    }

    const isBigImage = (fileType: ImageBackgroundFileType, viewPort: ImageBackgroundViewPort): boolean => {
        if (isRasterBackgroundFileType(fileType)) {

            const imageWidth = viewPort.Right - viewPort.Left;
            const imageHeight = viewPort.Bottom - viewPort.Top;

            const maxImageWidth = getMaxImageWidth();
            const maxImageHeight = getMaxImageHeight();

            return imageWidth > maxImageWidth || imageHeight > maxImageHeight;
        }
        return false;
        // return true
    }

    const getImageWidth = (viewPort: ImageBackgroundViewPort) => {
        const width = viewPort.Right - viewPort.Left;
        return width;
    }

    const getImageHeight = (viewPort: ImageBackgroundViewPort) => {
        const height = viewPort.Bottom - viewPort.Top;
        return height;
    }

    const getZoom = (isBig: boolean, { ViewPort: vp, IsMapTiles }: ImageBackgroundInfo) => {

        if (IsMapTiles) {
            return 0;
        }

        return isBig
            ? getZoomBySize(getImageWidth(vp), getImageHeight(vp))
            : 1;
    }

    const getZoomBySize = (width: number, height: number) => {
        const maxImageWidth = getMaxImageWidth();
        const maxImageHeight = getMaxImageHeight();

        const zoom = Math.round(Math.max(width / maxImageWidth, height / maxImageHeight));

        return zoom > RASTER_BIG_IMAGE_MIN_ZOOM ? zoom : RASTER_BIG_IMAGE_MIN_ZOOM;
    }

    const geovisImageViewPortToMapBounds = ({ ViewPort: vp }: ImageBackgroundInfo): L.LatLngBounds => {
        let mArea: MapArea = { East: vp.Right, North: vp.Top, South: vp.Bottom, West: vp.Left, Height: Math.abs(vp.Top - vp.Bottom), Width: Math.abs(vp.Right - vp.Left), ZoomLevel: 0 }

        if (rootContainer) {
            const deltaCoefficient = getDeltaCoefficient(rootContainer, mArea, ImageBackgroundFileType.PNG, 'GeovisImage');
            mArea = {
                East: mArea.East * (deltaCoefficient ?? 1),
                South: mArea.South * (deltaCoefficient ?? 1),
                North: mArea.North * (deltaCoefficient ?? 1),
                West: mArea.West * (deltaCoefficient ?? 1),
                Height: mArea.Height * (deltaCoefficient ?? 1),
                Width: mArea.Width * (deltaCoefficient ?? 1),
                ZoomLevel: 0
            }
        }
        return getMapSelectionBounds(mArea)
    }

    const lmoViewPortToMapBounds = ({ FileType: ft, IsMapTiles, ViewPort: vp }: ImageBackgroundInfo): L.LatLngBounds => {

        if (IsMapTiles) {
            return L.latLngBounds([[-1, -1], [1, 1]]);
        }

        const width = getImageWidth(vp);
        const height = getImageHeight(vp);
        const zoom = getZoomBySize(width, height);

        if (isBigImage(ft, vp) && mapRef) {

            const southWest = mapRef.unproject([0, height], zoom);
            const northEast = mapRef.unproject([width, 0], zoom);

            const bounds = new L.LatLngBounds(southWest, northEast);
            return bounds;
        }

        return viewPortToMapBounds(vp);
    }

    const { FileType, ViewPort } = imageInfo;

    const bigImage = isBigImage(FileType, ViewPort);

    const isGeovisImage = contentType === 'GeovisImage';

    const viewPortToMapBoundsFunc = isGeovisImage
        ? geovisImageViewPortToMapBounds
        : lmoViewPortToMapBounds

    const center = bigImage ? L.latLng([0, 0]) : viewPortMapCenter(imageInfo);

    const getImageMapViewBounds = (): IImageMapViewBounds | undefined => {

        if (rootContainer) {
            return {
                height: rootContainer.clientHeight,
                width: rootContainer.clientWidth
            }
        }

        return undefined;
    }

    // animate={disableManipulation ? false : true}
    return (
        <MapContainer
            ref={setMapRef}
            className={"imageMap"}
            center={center}
            minZoom={COMMON_MIN_ZOOM}
            maxZoom={MaxLocalMapObjectZoomLevel}
            zoom={getZoom(bigImage, imageInfo)}
            zoomControl={disableManipulation ? false : true}
            attributionControl={false}
            zoomSnap={0.1}
            crs={L.CRS.Simple}
            dragging={disableManipulation ? false : true}
            scrollWheelZoom={disableManipulation ? false : true}
            touchZoom={disableManipulation ? false : true}
            doubleClickZoom={disableManipulation ? false : true}
            zoomAnimation={disableManipulation ? false : true}
            fadeAnimation={disableManipulation ? false : true}
            markerZoomAnimation={disableManipulation ? false : true}
        >
            {/* This component must be added on each map where Sensors, Chains or Movement information panned to render */}
            <GeovisMapPaneCreator />


            <MapEventsComponent onClick={onMapClickHandler} />
            {isRasterBackgroundFileType(FileType) && (
                <GeovisImageLayer
                    backgroundInfo={imageInfo}
                    objectId={elementId}
                    viewPortToMapBounds={viewPortToMapBoundsFunc}
                    contentType={contentType}
                    projectId={projectId}
                    viewMapBounds={getImageMapViewBounds()}
                />
            )}
            {isDxfBackgroundFileType(imageInfo) && (
                <ImageMapDXFLayer
                    backgroundInfo={imageInfo}
                    projectId={projectId}
                    objectId={elementId}
                    contentType={contentType}
                />
            )}
            {isDxfMapTilesBackgroundFileType(imageInfo) && (
                <ImageMapDXFMapTilesLayer
                    projectId={projectId}
                    objectId={elementId}
                    backgroundInfo={imageInfo}
                    contentType={contentType}
                />
            )}
            {children}
        </MapContainer>
    );
}

export const ImageMapConnected = ImageMapComponent;