//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class LogoFileInfo
{
	public Id: string;
	public FileName: string;
	public LinkId: string;
	public FileId: string;
	public ProductionFileId: string;
	public OccurrenceKey: string;
	public Created: string;
	public CreatorCompanyId: string;
}
