/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.01.2020 (has been rewritten)
 * @description The map layer to draw DXF layer text points (with text labels)
 */
import type { Feature, Point } from 'geojson';
import { LatLng, Layer } from 'leaflet';
import { GeoJSON } from 'react-leaflet';
import { ProjectDXFLayerDocument } from '../../server/GEOvis3/Model/Layers/ProjectDXFLayerDocument';

const geoJsonPointToLayer = (point: Feature<Point>, latlng: LatLng): Layer => {
    let pointName = "";
    if (point.properties && point.properties.Text) {
        pointName = point.properties.Text;
    }

    const divIcon = L.divIcon({
        html: `<span>${pointName}</span>`,
        className: "geoJsonMarker"
    });

    const marker = L.marker(latlng, {
        icon: divIcon
    });

    return marker;
}

const geoJsonFilterFunc = (feature: Feature): boolean => {
    if (feature.geometry.type !== "Point") {
        return false;
    }

    return true;
}

interface IGeovisGeoJSONTextPointsProps {
    layerContent: ProjectDXFLayerDocument;
}

const GeovisGeoJsonTextPoints = ({ layerContent }: IGeovisGeoJSONTextPointsProps) => (
    <GeoJSON
        key={`geojson_text_points_${layerContent.Id}`}
        data={layerContent.GeoJsonDocument}
        pointToLayer={geoJsonPointToLayer}
        filter={geoJsonFilterFunc}
    />
);

export default GeovisGeoJsonTextPoints;