import { addDays, addHours, addMonths, addWeeks, addYears } from "date-fns";
import moment from "moment";
import { TimePeriod } from "../../server/AVTService/TypeLibrary/Common/TimePeriod";

export const getStartDateTimeOfPeriod = (endDate: Date, period: TimePeriod): Date => {
    switch (period) {
        case TimePeriod.Hour1: return addHours(endDate, -1);
        case TimePeriod.Hour2: return addHours(endDate, -2);
        case TimePeriod.Hour6: return addHours(endDate, -6);
        case TimePeriod.Hour12: return addHours(endDate, -12);

        case TimePeriod.Day1: return addDays(endDate, -1);
        case TimePeriod.Day2: return addDays(endDate, -2);
        case TimePeriod.Day4: return addDays(endDate, -4);

        case TimePeriod.Week1: return addWeeks(endDate, -1);
        case TimePeriod.Week2: return addWeeks(endDate, -2);

        case TimePeriod.Month1: return addMonths(endDate, -1);
        case TimePeriod.Month2: return addMonths(endDate, -2);
        case TimePeriod.Month3: return addMonths(endDate, -3);
        case TimePeriod.Month6: return addMonths(endDate, -6);

        case TimePeriod.Year1: return addYears(endDate, -1);
        case TimePeriod.Year2: return addYears(endDate, -2);
        case TimePeriod.Year3: return addYears(endDate, -3);
        case TimePeriod.Year5: return addYears(endDate, -5);
        case TimePeriod.Year10: return addYears(endDate, -10);

        default: return addDays(endDate, -1);
    }
}

/**
 * Convert picker data to string, which readable by server
 * @param date 
 * @returns 
 */
export const pickerValueToString = (date: Date, saveTimeZone: boolean = false): string => {
    if (saveTimeZone) {
        const updDate = date ? moment(date).format() : "";
        return updDate;
    }
    else {
        return date ? date.toJSON() : "";
    }
}

/**
 * Check if selected dateTime is less/great than expected range
 * @param selectedDateTime 
 * @param isThisStartOfDate 
 * @param pairedPickerDate 
 * @returns 
 */
export const isDateTimeInRange = (selectedDateTime: Date, isThisStartOfDate: boolean | undefined, pairedPickerDate: Date | undefined): boolean => {

    if (!selectedDateTime || !pairedPickerDate) {
        return true;
    }

    if (isThisStartOfDate) {
        return selectedDateTime < pairedPickerDate;
    }

    return selectedDateTime > pairedPickerDate;
}

/**
 * 
 * @param dateTime 
 * @returns 
 */
export const getPureDateTime = (dateTime: Date | undefined): Date | undefined => {
    if (!dateTime) {
        return undefined;
    }

    const resultDateTime = new Date(dateTime);

    resultDateTime.setSeconds(0);
    resultDateTime.setMilliseconds(0);

    return resultDateTime;
}