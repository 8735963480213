/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 26.10.2020
 * @description Inventory main types for store
 */

import { ItemId } from '@atlaskit/tree';
import { IErrorUploadFileInfo, IProcessingUploadFileInfo, IProgressUploadFileInfo, IUploadFileInfo } from '../components/FileBrowser';
import { InventoryAfmFilterOptions } from '../server/AVTService/TypeLibrary/Inventory/InventoryAfmFilterOptions';
import { InventoryAtcFilterOptions } from '../server/AVTService/TypeLibrary/Inventory/InventoryAtcFilterOptions';
import { InventoryCalendarViewFilterOptions } from '../server/AVTService/TypeLibrary/Inventory/InventoryCalendarViewFilterOptions';
import { InventoryLoRaGatewayFilterOptions } from '../server/AVTService/TypeLibrary/Inventory/InventoryLoRaGatewayFilterOptions';
import { InventoryMstFilterOptions } from '../server/AVTService/TypeLibrary/Inventory/InventoryMstFilterOptions';
import { InventoryObject } from '../server/AVTService/TypeLibrary/Inventory/InventoryObject';
import { InventoryObjectDescription } from '../server/AVTService/TypeLibrary/Inventory/InventoryObjectDescription';
import { InventoryObjectType } from '../server/AVTService/TypeLibrary/Inventory/InventoryObjectType';
import { InventoryReservation } from '../server/AVTService/TypeLibrary/Inventory/InventoryReservation';
import { InventorySensorboxFilterOptions } from '../server/AVTService/TypeLibrary/Inventory/InventorySensorboxFilterOptions';
import { InventoryTachymeterFilterOptions } from '../server/AVTService/TypeLibrary/Inventory/InventoryTachymeterFilterOptions';
import { InventoryPropertyHistory } from '../server/AVTService/TypeLibrary/InventoryHistory/InventoryPropertyHistory';
import { DataActionResponse } from '../server/DataActionResponse';
import { InventoryAfmBoxInfo } from '../server/GEOvis3/Model/Inventory/InventoryAfmBoxInfo';
import { InventoryAtcSensorInfo } from '../server/GEOvis3/Model/Inventory/InventoryAtcSensorInfo';
import { InventoryCalendarViewData } from '../server/GEOvis3/Model/Inventory/InventoryCalendarViewData';
import { InventoryLoRaGatewayInfo } from '../server/GEOvis3/Model/Inventory/InventoryLoRaGatewayInfo';
import { InventoryMstInfo } from '../server/GEOvis3/Model/Inventory/InventoryMstInfo';
import { InventoryObjectInfo } from '../server/GEOvis3/Model/Inventory/InventoryObjectInfo';
import { InventoryProjectInfo } from '../server/GEOvis3/Model/Inventory/InventoryProjectInfo';
import { InventoryRecordsInfo } from '../server/GEOvis3/Model/Inventory/InventoryRecordsInfo';
import { InventoryReservationChangedResponse } from '../server/GEOvis3/Model/Inventory/InventoryReservationChangedResponse';
import { InventoryReservationDevicesInfo } from '../server/GEOvis3/Model/Inventory/InventoryReservationDevicesInfo';
import { InventorySensorboxInfo } from '../server/GEOvis3/Model/Inventory/InventorySensorboxInfo';
import { InventoryTachymeterInfo } from '../server/GEOvis3/Model/Inventory/InventoryTachymeterInfo';
import { InventoryUsagePlanExtendTimeRangeInfo } from '../server/GEOvis3/Model/Inventory/InventoryUsagePlanExtendTimeRangeInfo';
import { InventoryUsagePlanInfo } from '../server/GEOvis3/Model/Inventory/InventoryUsagePlanInfo';
import { IGeovisAction, ISomethingStoreBase } from './types';

export const InventoryViewAsTables = "InventoryViewAsTables";
export const InventoryViewAsTree = "InventoryViewAsTree";

export type InventoryRequestSource = "USAGEPLAN" | "CALENDAR" | "LIST";
export interface IGeovisInventoryAction extends IGeovisAction {
    uploadState?: Partial<IGeovisInventoryUploadFileState>;
    objectType?: InventoryObjectType;
    numberProperty?: number;
    stringProperty?: string;
    booleanProperty?: boolean;
    parents?: InventoryObjectDescription[];
    booleanProperty2?: boolean;

    inventoryObject?: InventoryObject;
    inventoryObjectInfo?: InventoryObjectInfo;
    objectId?: string; // usually this is an inventoryObjectId or UsagePlanRecordId, see property up

    usagePlanData?: DataActionResponse<InventoryUsagePlanInfo[]>;
    calendarViewData?: DataActionResponse<InventoryCalendarViewData>;
    reservationData?: DataActionResponse<InventoryReservationChangedResponse>;

    // reservation?: InventoryReservation;
    reservations?: InventoryReservation[];
    reservationId?: string;

    devices?: InventoryReservationDevicesInfo;
    propertyValue?: any;
    usagePlanInfo?: InventoryUsagePlanInfo;
    propertyHistoryRecords?: InventoryPropertyHistory[];
    calendarFilters?: InventoryCalendarViewFilterOptions;

    requestSource?: InventoryRequestSource;

    objectIds?: string[];
    extendData?: InventoryUsagePlanExtendTimeRangeInfo;

    inventoryProjects?: DataActionResponse<InventoryProjectInfo[]>;
}

export interface IGeovisInventoryDataAction<TData> extends IGeovisInventoryAction {
    data?: InventoryRecordsInfo<TData>;
}

export interface IGeovisInventoryDataFilterAction<TData, TFilter> extends IGeovisInventoryDataAction<TData> {
    filter?: Partial<TFilter>;
}

export interface IGeovisInventoryObjectStorage<TData, TFilter> extends ISomethingStoreBase {
    data: InventoryRecordsInfo<TData>;
    filter: Partial<TFilter>;
    projectId: number | undefined;
    recordId: string;
}

export interface IGeovisInventoryUsagePlanStorage extends ISomethingStoreBase {
    recordId: string;
    records: InventoryUsagePlanInfo[];

    showEditDialog: boolean;
    showRemoveDialog: boolean;
    createEntryMode: boolean;
    showConfirmStatusChangeDialog: boolean;
    showExtendTimeRangeDialog: boolean;
}

export interface IGeovisInventoryCalendarStorage extends ISomethingStoreBase {
    recordId: string;
    records: InventoryUsagePlanInfo[];

    showEditDialog: boolean;
    showRemoveDialog: boolean;
    showActionDialog: boolean;
    createEntryMode: boolean;
    showConfirmStatusChangeDialog: boolean;
    showExtendTimeRangeDialog: boolean;
    showReservationItemRemoveDialog: boolean;

    showOpenPeriod: boolean;
    filters: InventoryCalendarViewFilterOptions;
}

export interface IGeovisInventoryReservationStorage extends ISomethingStoreBase {
    reservationId: string;
    reservations: InventoryReservation[];

    showEditDialog: boolean;
    showRemoveDialog: boolean;
    showReservationToProjectDialog: boolean;

    reservationCrossIntervalsMessage: string;
    createMode: boolean;
}

export interface IGeovisInventoryPropertyHistoryStorage extends ISomethingStoreBase {
    records: InventoryPropertyHistory[];
    propertyName: string;
    historyId: string;
}

/**
 * ATC storage
 */
export type IGeovisInventoryAtcStorage = IGeovisInventoryObjectStorage<InventoryAtcSensorInfo, InventoryAtcFilterOptions>

/**
 * MST storage
 */
export type IGeovisInventoryMstStorage = IGeovisInventoryObjectStorage<InventoryMstInfo, InventoryMstFilterOptions>

/**
 * AFM storage
 */
export type IGeovisInventoryAfmStorage = IGeovisInventoryObjectStorage<InventoryAfmBoxInfo, InventoryAfmFilterOptions>

/**
 * Sensorboxes storage
 */
export type IGeovisInventorySensorboxStorage = IGeovisInventoryObjectStorage<InventorySensorboxInfo, InventorySensorboxFilterOptions>

/**
 * Tachymeters
 */
export type IGeovisInventoryTachymetersStorage = IGeovisInventoryObjectStorage<InventoryTachymeterInfo, InventoryTachymeterFilterOptions>

/**
 * Inventory lora storage
 */
export type IGeovisInventoryLoRaGatewayStorage = IGeovisInventoryObjectStorage<InventoryLoRaGatewayInfo, InventoryLoRaGatewayFilterOptions>

/**
 * Descriptions
 */
export interface IGeovisInventoryDescriptionsStorage extends IGeovisInventoryObjectStorage<InventoryObjectDescription, null> {
    expandInfo: Map<ItemId, boolean>;
    displayNodeId: string;
    possibleParents: InventoryObjectDescription[];
    projectId: number | undefined;
}

export interface IGeovisInventoryDispalyedObjectStorage extends ISomethingStoreBase {
    inventoryObjectInfo: InventoryObjectInfo;
    objectId: string;
    showDisplayDialog: boolean;
}

export interface IGeovisInventoryUploadFileState {
    inProgress: boolean;
    progress: IProgressUploadFileInfo[];
    error: IErrorUploadFileInfo[];
    processing: IProcessingUploadFileInfo[];
    complete: IUploadFileInfo[];
}

export interface IInventoryProjectsStorage extends ISomethingStoreBase {
    projects: Map<number, InventoryProjectInfo>;
}

export interface IGeovisInventoryDataState {
    mst: IGeovisInventoryMstStorage;
    sensorboxes: IGeovisInventorySensorboxStorage;
    afm: IGeovisInventoryAfmStorage;
    atc: IGeovisInventoryAtcStorage;
    tachy: IGeovisInventoryTachymetersStorage;
    loraGateway: IGeovisInventoryLoRaGatewayStorage;
    descriptions: IGeovisInventoryDescriptionsStorage;
    usagePlan: IGeovisInventoryUsagePlanStorage;
    reservations: IGeovisInventoryReservationStorage;
    propertyHistory: IGeovisInventoryPropertyHistoryStorage;
    calendarData: IGeovisInventoryCalendarStorage;
    displayedObjectData: IGeovisInventoryDispalyedObjectStorage;
    projectsStorage: IInventoryProjectsStorage;

    uploadState: IGeovisInventoryUploadFileState;
    objectType: InventoryObjectType;
    objectId: string;

    showEditDialog: boolean;
    showDeleteDialog: boolean;
    createRecordMode: boolean;

    /**
     * Show the list view with usage plan records of selected inventory object
     * objectId and objectType should be set to expected values
     * Then the ListViewDialog will load them, store in "usagePlan" storage and show them as table
     */
    showUsagePlanListView: boolean;
    inventoryView: string;
    requestSource: string;

    showExpandDialog: boolean;
    // expandData: InventoryUsagePlanExtendTimeRangeInfo;
    showChangeStateDialog: boolean;
    showMoveToStorageDialog: boolean;
    showRemoveFromProjectDialog: boolean;
    showExpandProjectDurationDialog: boolean;

    inventoryObjectIDs: string[];
}