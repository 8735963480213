import { connect } from "react-redux";
import { IGeovisLogbookReportData, IGeovisLogbookReportInfo } from "../../../../../../store/projectReport.types";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { LoadingPageSkeleton } from "../../../../../LoadingPageSkeleton";
import { getGeovisImageData, getGeovisLogbookInfo } from "../tools";
import GeovisLogbookReportRenderView from "./GeovisLogbookReportRenderView";
import { IReportElementRenderSimpleOwnProps } from "../../types";

interface IStateToProps {
    geovisLogbookInfo: IGeovisLogbookReportInfo | false;
    geovisLogbookData: IGeovisLogbookReportData | false;
}

interface IOwnProps extends IReportElementRenderSimpleOwnProps {
    reportPagesCount: number;
    elementId: number;
}

interface IComponentProps extends IStateToProps, IOwnProps {

}

const Component = ({
    geovisLogbookInfo,
    geovisLogbookData,
    ...restProps
}: IComponentProps) => {
    if (!geovisLogbookInfo || !geovisLogbookData) {
        return null;
    }

    const { isLoading, isError, errorDescription } = geovisLogbookData;

    if (isLoading) {
        return (
            <LoadingPageSkeleton loadingText="Rendering..." />
        )
    }

    if (isError) {
        return (
            <LoadingPageErrorSkeleton errorDescription={errorDescription} />
        )
    }

    return (
        <GeovisLogbookReportRenderView
            {...restProps}
            geovisLogbookInfo={geovisLogbookInfo}
            geovisLogbookData={geovisLogbookData} />
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { pageNum, elementId }: IOwnProps): IStateToProps => ({
    geovisLogbookInfo: getGeovisLogbookInfo(projectReport.geovisReportSettings.geovisPages.get(pageNum), elementId),
    geovisLogbookData: getGeovisImageData(projectReport.geovisReportSettings.geovisPagesData.get(pageNum), elementId)
});

export default connect<IStateToProps, never, IOwnProps>(mapStateToProps)(Component);