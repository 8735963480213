/**
 * Projects overview reducer
 */

import { Reducer } from 'redux';
import { ProjectOverviewModel } from '../../server/GEOvis3/Model/ProjectsOverview/ProjectOverviewModel';
import { ProjectInfo } from '../../server/ProjectInfo';
import {
    HIDE_PROJECT_NO_PERMISSIONS_DIALOG,
    LOGIN_SUCCESS,
    PROJECTS_OVERVIEW,
    PROJECTS_OVERVIEW_DATA,
    PROJECTS_OVERVIEW_SEARCH_QUERY,
    PROJECTS_OVERVIEW_UPDATE_PROJECT,
    SHOW_PROJECT_NO_PERMISSIONS_DIALOG,
    SWITCH_PROJECTS_OVERVIEW
} from '../actions/navigationActions';
import {
    defaultSomethingStorageState,
    errorSomethingStorageState,
    IGeovisProjectsOverviewAction,
    IGeovisProjectsOverviewState,
    loadedSomethingStorageState,
    ProjectsViewAsMap,
    ProjectsViewFilterOnlyMy
} from '../types';

const projectsOverviewInitialState: IGeovisProjectsOverviewState = {
    ...defaultSomethingStorageState,
    data: [],
    noPermissionProjectId: 0,
    filter: ProjectsViewFilterOnlyMy,
    isLoading: true,
    rowsPerPage: 10,
    showProjectName: false,
    view: ProjectsViewAsMap,
    searchQuery: "",
    showNoPermissionsDialog: false,
    reloadingProjects: true
};


const updatedProjectsInfoArray = (projects: ProjectOverviewModel[], index: number, projectToUpdate: ProjectInfo): ProjectOverviewModel[] => {
    return projects.slice(0, index)
        .concat({
            ...projects[index],
            Note: projectToUpdate.Note,
            InternalInformation: projectToUpdate.InternalInformation,
            AdminInformation: projectToUpdate.AdminInformation,
            // EnableFADolphinNotification: projectToUpdate.EnableFADolphinNotification,
            // EnableVADolphinNotification: projectToUpdate.EnableVADolphinNotification
            VANotificationsSettings: projectToUpdate.VANotificationsSettings
        })
        .concat(projects.slice(index + 1))

}

const updatedProjectsInfo = (projects: ProjectOverviewModel[], projectToUpdate?: ProjectInfo): ProjectOverviewModel[] => {
    if (!projectToUpdate) {
        return projects;
    }

    const index = projects.findIndex(item => item.Id === projectToUpdate.Id);
    if (index >= 0) {
        return updatedProjectsInfoArray(projects, index, projectToUpdate);
    }

    return projects;
}

const projectsOverviewReducer: Reducer<IGeovisProjectsOverviewState> = (state: IGeovisProjectsOverviewState = projectsOverviewInitialState, action: IGeovisProjectsOverviewAction): IGeovisProjectsOverviewState => {
    switch (action.type) {

        case LOGIN_SUCCESS:
            return { ...projectsOverviewInitialState };

        case PROJECTS_OVERVIEW: {
            return {
                ...state,
                reloadingProjects: true
            }
        }

        case PROJECTS_OVERVIEW_DATA: {
            if (!action.projects) {
                return state;
            }

            const { Success, Messages, Data } = action.projects;
            if (!Success) {
                return {
                    ...state,
                    ...projectsOverviewInitialState,
                    ...errorSomethingStorageState(...Messages)
                }
            }

            const changedState: IGeovisProjectsOverviewState = {
                ...state,
                ...loadedSomethingStorageState,
                reloadingProjects: false,
                data: Data
            }

            return changedState;
        }

        case PROJECTS_OVERVIEW_SEARCH_QUERY:
            return { ...state, searchQuery: action.searchQuery! };

        case SWITCH_PROJECTS_OVERVIEW:
            return { ...state, ...action };

        case PROJECTS_OVERVIEW_UPDATE_PROJECT:
            return { ...state, data: updatedProjectsInfo(state.data, action.projectToUpdate) };

        case SHOW_PROJECT_NO_PERMISSIONS_DIALOG:
            if (action.projectId) {
                return { ...state, showNoPermissionsDialog: true, noPermissionProjectId: action.projectId };
            }
            break;

        case HIDE_PROJECT_NO_PERMISSIONS_DIALOG:
            return { ...state, showNoPermissionsDialog: false, noPermissionProjectId: 0 };
    }

    return state;
}

export default projectsOverviewReducer;