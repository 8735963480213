/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 08.05.2019
 * @description Project overview page actions reducer
 */

import moment from 'moment';
import { Reducer } from 'redux';
import { ReportsName } from '../../components/projectOverview/embeddedData/ProjectOverviewEmbeddedDataHub';
import { AllName } from '../../components/projectOverview/information/ProjectOverviewInformationDataHub';
import { InventoryObjectType } from '../../server/AVTService/TypeLibrary/Inventory/InventoryObjectType';
import {
    PROJECT_DATA_LOGBOOK_RECORDS_DATA,
    PROJECT_DATA_OVERVIEW_CONFIGURATION,
    PROJECT_DATA_SET_MAP_VIEW_STATE
} from '../actions/dataActions';
import { PROJECTS_OVERVIEW } from "../actions/navigationActions";
import {
    PROJECT_OVERVIEW_EMBEDDED_DATA_TAB_CHANGED,
    PROJECT_OVERVIEW_HIDE_ALARM_EDIT_DIALOG,
    PROJECT_OVERVIEW_HIDE_ANY_OVERLAY,
    PROJECT_OVERVIEW_HIDE_DEFAULT_CHART_CHOOSER,
    PROJECT_OVERVIEW_HIDE_LOCAL_MAP_OBJECT_CONTENT,
    PROJECT_OVERVIEW_HIDE_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG,
    PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_DIALOG,
    PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_REMOVE_DIALOG,
    PROJECT_OVERVIEW_HIDE_SELECTED_SENSORS_REPORT_VIEW,
    PROJECT_OVERVIEW_HIDE_SENSOR_DEFAULT_CHART_VIEW,
    PROJECT_OVERVIEW_HIDE_SENSOR_IMAGE,
    PROJECT_OVERVIEW_HIDE_SENSOR_SETTINGS,
    PROJECT_OVERVIEW_INFORMATION_TAB_CHANGED,
    PROJECT_OVERVIEW_INIT_LOGBOOK_VIEW_STATE,
    PROJECT_OVERVIEW_INVENTORY_OBJECT_TYPE_CHANGED,
    PROJECT_OVERVIEW_INVENTORY_TAB_CHANGED,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_PAGE_NUMBER,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_ROWS_PER_PAGE,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_SORTING,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_TEXT_SEARCH_STRING,
    PROJECT_OVERVIEW_LOGBOOK_REMOVED,
    PROJECT_OVERVIEW_SET_LOGBOOK_VIEW_STATE,
    PROJECT_OVERVIEW_SHOW_ALARM_EDIT_DIALOG,
    PROJECT_OVERVIEW_SHOW_DEFAULT_CHART_CHOOSER,
    PROJECT_OVERVIEW_SHOW_LAYERS_DIALOG,
    PROJECT_OVERVIEW_SHOW_LOCAL_MAP_OBJECT_CONTENT,
    PROJECT_OVERVIEW_SHOW_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG,
    PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_DIALOG,
    PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_EDIT_DIALOG,
    PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_REMOVE_DIALOG,
    PROJECT_OVERVIEW_SHOW_SELECTED_SENSORS_REPORT_VIEW,
    PROJECT_OVERVIEW_SHOW_SENSOR_DEFAULT_CHART_VIEW,
    PROJECT_OVERVIEW_SHOW_SENSOR_IMAGE,
    PROJECT_OVERVIEW_SHOW_SENSOR_SETTINGS,
    PROJECT_OVERVIEW_STATISTIC_MST_TAB_SELECTED
} from '../actions/projectOverviewActions';
import { InventoryViewAsTables } from '../inventory.types';
import {
    IGeovisProjectOverviewAction,
    IGeovisProjectOverviewState,
    ILogbookRecordsTableState,
    ProjectOverviewOverlay
} from "../projectOverview.types";
import { TextAlarmAction } from '../../server/AVTService/TypeLibrary/Alarming/TextAlarmAction';
import { ProjectEditSensorsTab } from '../../pages/edit/sensors/types';
import { SensorInfo } from '../../server/GEOvis3/Model/SensorInfo';

const logbookRecordsTableInitialState: ILogbookRecordsTableState = {
    showPublicFlag: true,
    canDeleteOwnRecordsOnly: false
}

export const projectOverviewInitialState: IGeovisProjectOverviewState = {
    isPostInProgress: false,
    contextSensorId: "",
    showLocalMapObjectContent: false,
    localMapObjectId: '',
    rowsPerPage: 10,
    activeStatisticMstAlias: '',
    drawOverlay: ProjectOverviewOverlay.None,
    reportId: 0,
    logbookRecordsViewState: { PageSize: 100, PageNumber: 1 },
    logbookId: '',
    logbookRecordDialogMode: 'view',
    showLogbookRecordDialog: false,
    showDefaultChartChooseWindow: false,
    embeddedDataName: ReportsName,
    informationName: AllName,
    inventoryObjectType: InventoryObjectType.MST,
    inventoryViewName: InventoryViewAsTables,
    showLogbookRecordRemoveDialog: false,
    showAddLogbookEntryDialog: false,
    removeAll: false,
    alarmAction: new TextAlarmAction(),
    logbookRecordsTableState: logbookRecordsTableInitialState,
    defaultReportChartId: 0,
    defaultReportSensorFullId: '',
    showSelectedSensorsDefaultChartView: false,
    showSensorDefaultChartView: false,
    alarmId: '',
    showAlarmEditDialog: false,
    sensorFullId: '',
    showSensorEditDialog: false,
    sensorEditTabType: ProjectEditSensorsTab.Standard,
    sensor: new SensorInfo(),
    showSensorImageDialog: false
};

const projectOverviewReducer: Reducer<IGeovisProjectOverviewState> = (
    state: IGeovisProjectOverviewState = projectOverviewInitialState,
    action: IGeovisProjectOverviewAction): IGeovisProjectOverviewState => {

    switch (action.type) {

        // clear overview state when back to projects overview
        case PROJECTS_OVERVIEW:
            return { ...projectOverviewInitialState };

        case PROJECT_DATA_OVERVIEW_CONFIGURATION:
            return {
                ...state,
                ...action
            };

        case PROJECT_DATA_SET_MAP_VIEW_STATE:
            return {
                ...state,
                drawOverlay: ProjectOverviewOverlay.None
            }

        case PROJECT_OVERVIEW_HIDE_ANY_OVERLAY:
            return {
                ...state,
                drawOverlay: ProjectOverviewOverlay.None,
                reportId: 0
            };

        case PROJECT_OVERVIEW_SHOW_LAYERS_DIALOG:
            return {
                ...state,
                drawOverlay: ProjectOverviewOverlay.Layers
            };

        case PROJECT_OVERVIEW_SHOW_LOCAL_MAP_OBJECT_CONTENT:
            if (action.stringProperty) {
                return { ...state, showLocalMapObjectContent: true, localMapObjectId: action.stringProperty };
            }
            break;

        case PROJECT_OVERVIEW_SHOW_SENSOR_DEFAULT_CHART_VIEW:
            if (action.numberProperty && action.stringProperty) {
                return {
                    ...state,
                    showSensorDefaultChartView: true,
                    defaultReportChartId: action.numberProperty,
                    defaultReportSensorFullId: action.stringProperty,
                    showDefaultChartChooseWindow: false
                };
            }
            break;
        case PROJECT_OVERVIEW_HIDE_SENSOR_DEFAULT_CHART_VIEW:
            return { ...state, showSensorDefaultChartView: false, defaultReportChartId: 0, defaultReportSensorFullId: '' };

        case PROJECT_OVERVIEW_SHOW_SENSOR_SETTINGS:
            if (!action.stringProperty || !action.sensorEditType) {
                return state;
            }
            return ({
                ...state,
                showSensorEditDialog: true,
                sensorFullId: action.stringProperty,
                sensorEditTabType: action.sensorEditType
            });

        case PROJECT_OVERVIEW_HIDE_SENSOR_SETTINGS: {
            return ({
                ...state,
                showSensorEditDialog: false,
                sensorFullId: '',
                sensorEditTabType: ProjectEditSensorsTab.Standard
            });
        }

        case PROJECT_OVERVIEW_SHOW_SENSOR_IMAGE: {
            if (!action.sensor) {
                return state;
            }

            return ({
                ...state,
                sensor: action.sensor,
                showSensorImageDialog: true
            })
        }

        case PROJECT_OVERVIEW_HIDE_SENSOR_IMAGE: {
            return ({
                ...state,
                sensor: new SensorInfo(),
                showSensorImageDialog: false
            })
        }

        case PROJECT_OVERVIEW_SHOW_SELECTED_SENSORS_REPORT_VIEW:
            return { ...state, showSelectedSensorsDefaultChartView: true };

        case PROJECT_OVERVIEW_HIDE_SELECTED_SENSORS_REPORT_VIEW:
            return { ...state, showSelectedSensorsDefaultChartView: false };

        case PROJECT_OVERVIEW_SHOW_DEFAULT_CHART_CHOOSER:
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                showDefaultChartChooseWindow: true,
                defaultReportSensorFullId: action.stringProperty,
                showSensorDefaultChartView: false
            };

        case PROJECT_OVERVIEW_HIDE_DEFAULT_CHART_CHOOSER:
            return { ...state, showDefaultChartChooseWindow: false, defaultReportSensorFullId: '' };

        case PROJECT_OVERVIEW_SHOW_ALARM_EDIT_DIALOG:
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                showAlarmEditDialog: true,
                alarmId: action.stringProperty
            };

        case PROJECT_OVERVIEW_HIDE_ALARM_EDIT_DIALOG:
            return { ...state, showAlarmEditDialog: false, alarmId: '' };

        case PROJECT_OVERVIEW_HIDE_LOCAL_MAP_OBJECT_CONTENT:
            return { ...state, showLocalMapObjectContent: false, localMapObjectId: '' };

        //#region Logbook

        case PROJECT_DATA_LOGBOOK_RECORDS_DATA:
            return {
                ...state,
                logbookRecordsViewState: {
                    ...state.logbookRecordsViewState,
                    ...action.logbookRecordsViewState
                }
            };

        case PROJECT_OVERVIEW_INIT_LOGBOOK_VIEW_STATE: {

            const year = new Date().getFullYear();

            const startDate = new Date();
            startDate.setFullYear(year - 1);
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);

            const endDate = new Date();
            endDate.setHours(23);
            endDate.setMinutes(0);
            endDate.setSeconds(0);

            const recordsTableState = action.logbookRecordsTableState ? action.logbookRecordsTableState : logbookRecordsTableInitialState;

            if (action.numberProperty) {
                return {
                    ...state,
                    logbookRecordsViewState: {
                        ElementId: action.numberProperty,
                        PageNumber: 1,
                        PageSize: 100,
                        StartDate: moment(startDate).format(),
                        EndDate: moment(endDate).format()
                    },
                    showLogbookRecordDialog: false,
                    logbookId: '',
                    logbookRecordsTableState: { ...recordsTableState }
                };
            }
            return {
                ...state,
                logbookRecordsViewState: { StartDate: moment(startDate).format(), EndDate: moment(endDate).format(), PageNumber: 1, PageSize: 100 },
                showLogbookRecordDialog: false,
                logbookId: '',
                logbookRecordsTableState: { ...recordsTableState }
            };
        }

        case PROJECT_OVERVIEW_SET_LOGBOOK_VIEW_STATE:
            if (!action.logbookRecordsViewState) {
                return state;
            }
            return {
                ...state,
                logbookRecordsViewState: action.logbookRecordsViewState
            }

        case PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_DIALOG:
            if (!action.logbookAction || !action.stringProperty) {
                return state;
            }

            return {
                ...state,
                showLogbookRecordDialog: true,
                logbookRecordDialogMode: action.logbookAction,
                logbookId: action.stringProperty
            }

        case PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_DIALOG:
            return {
                ...state,
                showLogbookRecordDialog: false,
                logbookId: ''
            }

        case PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_REMOVE_DIALOG: {
            if (action.stringProperty === undefined || action.booleanProperty === undefined) {
                return state;
            }

            return {
                ...state,
                showLogbookRecordRemoveDialog: true,
                logbookId: action.stringProperty,
                removeAll: action.booleanProperty
            }
        }

        case PROJECT_OVERVIEW_LOGBOOK_REMOVED:
        case PROJECT_OVERVIEW_HIDE_LOGBOOK_RECORD_REMOVE_DIALOG: {
            return {
                ...state,
                showLogbookRecordRemoveDialog: false
            }
        }

        case PROJECT_OVERVIEW_LOGBOOK_CHANGED_TEXT_SEARCH_STRING: {
            if (action.stringProperty === undefined) {
                return state;
            }

            return {
                ...state,
                logbookRecordsViewState: {
                    ...state.logbookRecordsViewState,
                    SearchQuery: action.stringProperty,
                    PageNumber: 1
                }
            }
        }
        // showAddLogbookEntryDialog

        case PROJECT_OVERVIEW_SHOW_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG: {
            return {
                ...state,
                logbookId: '',
                showAddLogbookEntryDialog: true
            }
        }

        case PROJECT_OVERVIEW_HIDE_LOGBOOK_ELEMENT_ADD_ENTRY_DIALOG: {
            return {
                ...state,
                showAddLogbookEntryDialog: false,
                logbookId: ''
            }
        }
        case PROJECT_OVERVIEW_LOGBOOK_CHANGED_PAGE_NUMBER: {
            if (!action.numberProperty) {
                return state;
            }

            return {
                ...state,
                logbookRecordsViewState: {
                    ...state.logbookRecordsViewState,
                    PageNumber: action.numberProperty
                }
            }
        }

        case PROJECT_OVERVIEW_LOGBOOK_CHANGED_ROWS_PER_PAGE: {
            if (!action.numberProperty) {
                return state;
            }

            return {
                ...state,
                logbookRecordsViewState: {
                    ...state.logbookRecordsViewState,
                    PageSize: action.numberProperty,
                    PageNumber: 1
                }
            }
        }

        case PROJECT_OVERVIEW_LOGBOOK_CHANGED_SORTING: {
            if (action.stringProperty === undefined || action.booleanProperty === undefined) {
                return state;
            }

            return {
                ...state,
                logbookRecordsViewState: {
                    ...state.logbookRecordsViewState,
                    OrderBy: action.stringProperty,
                    Ascending: action.booleanProperty
                }
            }
        }

        case PROJECT_OVERVIEW_SHOW_LOGBOOK_RECORD_EDIT_DIALOG: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                showAddLogbookEntryDialog: true,
                logbookId: action.stringProperty
            }
        }

        //#endregion

        //#region statistic

        case PROJECT_OVERVIEW_STATISTIC_MST_TAB_SELECTED:
            if (action.stringProperty) {
                return {
                    ...state,
                    activeStatisticMstAlias: action.stringProperty
                }
            }
            break;

        //#endregion

        case PROJECT_OVERVIEW_EMBEDDED_DATA_TAB_CHANGED:
            if (action.stringProperty) {
                return {
                    ...state,
                    embeddedDataName: action.stringProperty
                }
            }
            break;

        case PROJECT_OVERVIEW_INFORMATION_TAB_CHANGED:
            if (action.stringProperty) {
                return {
                    ...state,
                    informationName: action.stringProperty
                }
            }
            break;

        case PROJECT_OVERVIEW_INVENTORY_TAB_CHANGED:
            if (action.stringProperty) {
                return {
                    ...state,
                    inventoryViewName: action.stringProperty
                }
            }
            break;

        case PROJECT_OVERVIEW_INVENTORY_OBJECT_TYPE_CHANGED:
            if (action.inventoryObjectType !== undefined) {
                return {
                    ...state,
                    inventoryObjectType: action.inventoryObjectType
                }
            }
            break;
    }

    return state;
}

export default projectOverviewReducer;