//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { LogbookRecordSource } from './LogbookRecordSource';

export class LogbookModel
{
	public id: string;
	public type: string;
	public projectId: number;
	public created: string;
	public reportDate: string;
	public description: string;
	public descriptionText: string;
	public comment: string;
	public customerViewable: boolean;
	public labelIds: string[];
	public showInChart: boolean;
	public AuthorId: string;
	public AuthorName: string;
	public Source: LogbookRecordSource;
	public imageIds: string[];
	public ReadedByUser: string[];
	public ObjectType: string;
}
