/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 09.12.2019
 * @description Project data reducer
 */

import { uuid } from '@atlaskit/adf-schema';
import { LatLng } from 'leaflet';
import { cloneDeep } from 'lodash';
import { Reducer } from 'redux';
import { DocumentsName, ReportsName } from '../../components/projectOverview/embeddedData/ProjectOverviewEmbeddedDataHub';
import { AAName, AllName, PAName } from '../../components/projectOverview/information/ProjectOverviewInformationDataHub';
import { SupportTemplateEditorMode } from '../../components/projectOverview/support/types';
import { RaisedAlarmsStorageName } from '../../helpers/AlarmsHelper';
import { ambergTechnologiesCoordinates } from '../../helpers/Constants';
import { getProjectViewId } from '../../helpers/FiltersHelper';
import {
    calculateMapElementsBounds,
    getProjectOverviewMapCenter,
    getProjectOverviewMapZoom
} from '../../helpers/MapHelper';
import {
    addProjectElementToStore,
    removeProjectElementFromStorage,
    updateProjectElementInStorage
} from '../../helpers/ProjectElementsHelper';
import {
    addOrUpdateElementInMap,
    deleteElementInMap,
    deleteElementOfMap,
    elementsToMap,
    elementToMapLowerCase,
    joinMapElements,
    mapToListOfElements,
    updateElementPropertiesInMap
} from '../../helpers/StorageHelper';
import { AttachedDocumentModel } from '../../server/AttachedDocumentModel';
import { GEOvisDXFLayerType } from '../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType';
import { LocalMapObject } from '../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject';
import { AlarmLogbookModel } from '../../server/AVTService/TypeLibrary/Logbook/AlarmLogbookModel';
import { LogbookModel } from '../../server/AVTService/TypeLibrary/Logbook/LogbookModel';
import { LogbookPagesInfo } from '../../server/AVTService/TypeLibrary/Logbook/Navigation/LogbookPagesInfo';
import { LogoFileInfo } from '../../server/AVTService/TypeLibrary/Logo/LogoFileInfo';
import { LabelModel } from '../../server/AVTService/TypeLibrary/Model/LabelModel';
import { ProjectSupportMessageTemplate } from '../../server/AVTService/TypeLibrary/ProjectSupport/ProjectSupportMessageTemplate';
import { SensorCategory } from '../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { Geovis4SensorSymbolSettings } from '../../server/AVTService/TypeLibrary/Sensors/SymbolSettings/Geovis4SensorSymbolSettings';
import { ChainInfo } from '../../server/ChainInfo';
import { ChainMeasurementInfo } from '../../server/ChainMeasurementInfo';
import { GeovisMstModel } from '../../server/GEOvis3/Model/Database/GeovisMstModel';
import { DtsSectionInfo } from '../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfo';
import { LocalMapObjectSensorsInfo } from '../../server/GEOvis3/Model/LocalMapObjects/LocalMapObjectSensorsInfo';
import { LogbookProjectElement } from '../../server/GEOvis3/Model/Logbook/LogbookProjectElement';
import { GeovisLoRaRootConfiguration } from '../../server/GEOvis3/Model/LoRa/GeovisLoRaRootConfiguration';
import { ProjectViewInfo } from '../../server/GEOvis3/Model/ProjectViews/ProjectViewInfo';
import { SensorInfo } from '../../server/GEOvis3/Model/SensorInfo';
import { GeovisProjectStatistic } from '../../server/GEOvis3/Model/Statistics/GeovisProjectStatistic';
import { GeovisUserCommonInfo } from '../../server/GEOvis3/Model/User/GeovisUserCommonInfo';
import { ProjectDXFLayerInfo } from "../../server/ProjectDXFLayerInfo";
import { ProjectInfo } from '../../server/ProjectInfo';
import { ProjectReportInfo } from '../../server/ProjectReportInfo';
import { SensorMeasurementInfo } from '../../server/SensorMeasurementInfo';
import {
    PROJECT_COMPANIES_INFO,
    PROJECT_COMPANIES_INFO_LOADED,
    PROJECT_DATA_ADD_REPORT_INFO,
    PROJECT_DATA_ALARM_CONFIRMED,
    PROJECT_DATA_ALARM_DO_SWITCHED_OFF,
    PROJECT_DATA_ALARMS,
    PROJECT_DATA_ALARMS_DATA,
    PROJECT_DATA_APPEND_SENSORS_MEAS,
    PROJECT_DATA_ATTACHED_DOCUMENT_ADD,
    PROJECT_DATA_ATTACHED_DOCUMENT_DELETED,
    PROJECT_DATA_ATTACHED_DOCUMENT_UPDATE,
    PROJECT_DATA_ATTACHED_DOCUMENTS,
    PROJECT_DATA_ATTACHED_DOCUMENTS_DATA,
    PROJECT_DATA_ATTACHED_DOCUMENTS_ERROR,
    PROJECT_DATA_ATTACHED_DOCUMENTS_LOADED,
    PROJECT_DATA_CHAINS_INFO,
    PROJECT_DATA_CHAINS_INFO_DATA,
    PROJECT_DATA_CHAINS_INFO_ERROR,
    PROJECT_DATA_DXF_LAYER_DELETE,
    PROJECT_DATA_DXF_LAYERS,
    PROJECT_DATA_DXF_LAYERS_DATA,
    PROJECT_DATA_INFORMATION_UPDATE,
    PROJECT_DATA_KEEP_ZOOM_CHANGED,
    PROJECT_DATA_LABEL_REMOVED,
    PROJECT_DATA_LABEL_UPDATED,
    PROJECT_DATA_LABELS,
    PROJECT_DATA_LABELS_DATA,
    PROJECT_DATA_LOCAL_MAP_OBJECTS,
    PROJECT_DATA_LOCAL_MAP_OBJECTS_DATA,
    PROJECT_DATA_LOGBOOK_AUTHORS_LOADED,
    PROJECT_DATA_LOGBOOK_AUTHORS_LOADING,
    PROJECT_DATA_LOGBOOK_AUTHORS_LOADING_ERROR,
    PROJECT_DATA_LOGBOOK_CHECK_ALL,
    PROJECT_DATA_LOGBOOK_ELEMENT_SAVE_ENTRY,
    PROJECT_DATA_LOGBOOK_ELEMENTS,
    PROJECT_DATA_LOGBOOK_ELEMENTS_DATA,
    PROJECT_DATA_LOGBOOK_ELEMENTS_ERROR,
    PROJECT_DATA_LOGBOOK_MARK_AS_READED,
    PROJECT_DATA_LOGBOOK_MARK_AS_READED_SELECTED,
    PROJECT_DATA_LOGBOOK_RECORD_SELECTION_CHANGED,
    PROJECT_DATA_LOGBOOK_RECORDS,
    PROJECT_DATA_LOGBOOK_RECORDS_DATA,
    PROJECT_DATA_LOGBOOK_RECORDS_ERROR,
    PROJECT_DATA_LOGOS_INFO,
    PROJECT_DATA_LOGOS_INFO_DATA,
    PROJECT_DATA_LOGOS_INFO_ERROR,
    PROJECT_DATA_OVERVIEW_CONFIGURATION,
    PROJECT_DATA_OVERVIEW_CONFIGURATION_DATA,
    PROJECT_DATA_PROPERTIES,
    PROJECT_DATA_PROPERTIES_DATA,
    PROJECT_DATA_PROPERTIES_LOADED,
    PROJECT_DATA_REPORTS,
    PROJECT_DATA_REPORTS_DATA,
    PROJECT_DATA_RESET_MAP_VIEWPORT_OF_VIEW,
    PROJECT_DATA_SENSORS_CHAINS_LOADED,
    PROJECT_DATA_SENSORS_CHAINS_LOADING,
    PROJECT_DATA_SENSORS_CHAINS_MEAS_LOADED,
    PROJECT_DATA_SENSORS_INFO,
    PROJECT_DATA_SENSORS_INFO_DATA,
    PROJECT_DATA_SENSORS_INFO_ERROR,
    PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADED,
    PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADING,
    PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADING_ERROR,
    PROJECT_DATA_SET_MAP_VIEW_STATE,
    PROJECT_DATA_SHOW_LEGEND,
    PROJECT_DATA_STATISTIC,
    PROJECT_DATA_STATISTIC_DATA,
    PROJECT_DATA_STATISTIC_ERROR,
    PROJECT_DATA_SUPPORT_CANCEL_EDIT,
    PROJECT_DATA_SUPPORT_CREATE_NEW,
    PROJECT_DATA_SUPPORT_DELETE_TEMPLATE,
    PROJECT_DATA_SUPPORT_HIDE_DELETE_DIALOG,
    PROJECT_DATA_SUPPORT_LOADED,
    PROJECT_DATA_SUPPORT_LOADING,
    PROJECT_DATA_SUPPORT_SAVE_MESSAGE_TEMPLATE,
    PROJECT_DATA_SUPPORT_SELECT_TEMPLATE_ID,
    PROJECT_DATA_SUPPORT_SHOW_DELETE_DIALOG,
    PROJECT_DATA_SUPPORT_START_EDIT_TEMPLATE,
    PROJECT_DATA_SYMBOL_SETTINGS,
    PROJECT_DATA_SYMBOL_SETTINGS_DATA,
    PROJECT_DATA_SYMBOL_SETTINGS_ERROR,
    PROJECT_DATA_SYMBOLS_SETTINGS_RESPONSE_LOADED,
    PROJECT_DATA_UPDATE_PROJECT_VIEWPORT,
    PROJECT_DATA_USERS,
    PROJECT_DATA_USERS_DATA,
    PROJECT_DATA_USERS_ERROR,
    PROJECT_DATA_USERS_UPDATE,
    PROJECT_DATA_VIEW_SELECTED,
    PROJECT_DATA_VIEW_TREE_FILTER_CHANGED,
    PROJECT_DATA_VIEW_TREE_FILTER_NODE_COLLAPSED,
    PROJECT_DATA_VIEW_TREE_FILTER_NODE_EXPANDED,
    PROJECT_DATA_VIEW_TREE_ITEM_COLLAPSE,
    PROJECT_DATA_VIEW_TREE_ITEM_EXPAND,
    PROJECT_DATA_VIEW_TREE_ITEM_SELECTION_CHANGE,
    PROJECT_DATA_VIEW_TREE_SEARCH,
    PROJECT_DATA_VIEW_UPDATE_VIEWPORT,
    PROJECT_DATA_VISUAL_SETTINGS,
    PROJECT_DATA_VISUAL_SETTINGS_DATA,
    PROJECT_DATA_VISUAL_SETTINGS_ERROR,
    PROJECT_DATABASE_ADDED,
    PROJECT_DATABASE_REMOVED,
    PROJECT_DATABASE_UPDATED,
    PROJECT_DATABASES_IN_PROGRESS,
    PROJECT_DATABASES_LOADED,
    PROJECT_DATABASES_LOADING_ERROR,
    PROJECT_DTS_SECTION_ADDED,
    PROJECT_DTS_SECTION_REMOVED,
    PROJECT_DTS_SECTION_UPDATED,
    PROJECT_DTS_SECTIONS_LOADED,
    PROJECT_DTS_SECTIONS_LOADING,
    PROJECT_DTS_SECTIONS_LOADING_ERROR,
    PROJECT_ELEMENT_ENTRIES_LOADED,
    PROJECT_ELEMENT_ENTRIES_LOADING,
    PROJECT_ELEMENT_ENTRY_ADDED,
    PROJECT_ELEMENT_ENTRY_REMOVED,
    PROJECT_ELEMENT_ENTRY_UPDATED,
    PROJECT_ELEMENTS_CUSTOM_GROUPS_UPDATED,
    PROJECT_LORA_ROOT_CONFIGURATION,
    PROJECT_LORA_ROOT_CONFIGURATION_DATA,
    PROJECT_PROFILE_VIEW_CHANGED,
    PROJECT_PROFILE_VIEWS,
    PROJECT_PROFILE_VIEWS_DATA,
    PROJECT_PROFILE_VIEWS_ERROR,
    PROJECT_SETTINGS_NOTIFICATIONS_CHANGED,
    PROJECT_VIEWS_LOADED,
    PROJECT_VIEWS_LOADING,
    PROJECT_VIEWS_LOADING_ERROR,
    PROJECT_DATA_REPORT_PUBLIC_CHANGED,
    PROJECT_DATA_OVERVIEW_CHANGED,
    PROJECT_DATA_GEORASTER_LOADING,
    PROJECT_DATA_GEORASTER_LOADED,
    PROJECT_DATA_GEORASTER_LOADING_ERROR,
    PROJECT_DATA_GEORASTER_ADDED,
    PROJECT_DATA_GEORASTER_REMOVED,
    PROJECT_DATA_GEORASTER_UPDATED,
    PROJECT_VIEW_DATA_GEORASTERS_LOADING,
    PROJECT_VIEW_DATA_GEORASTERS_LOADING_ERROR,
    PROJECT_VIEW_DATA_GEORASTERS_LOADED,
    PROJECT_VIEW_DATA_CURRENT_GEORASTER_CHANGED,
    PROJECT_DATA_SENSOR_INFO_UPDATED,
    PROJECT_DATA_SENSOR_INFO_DELETED
} from '../actions/dataActions';
import { PROJECTS_OVERVIEW } from '../actions/navigationActions';
import {
    LOGO_EDIT_UPLOAD_END,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_ADD,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_CHANGE_IS_PUBLIC,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_REMOVE,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_UPDATE,
    PROJECT_EDIT_REMOVE_PROJECT,
    PROJECT_EDIT_REPORT_ADD_OR_UPDATE,
    PROJECT_EDIT_REPORT_REMOVED,
    PROJECT_EDIT_VIEW_DELETE,
    PROJECT_EDIT_VIEW_SET_AS_DEFAULT
} from '../actions/projectEditActions';
import {
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_PAGE_NUMBER,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_ROWS_PER_PAGE,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_SORTING,
    PROJECT_OVERVIEW_LOGBOOK_CHANGED_TEXT_SEARCH_STRING,
    PROJECT_OVERVIEW_LOGBOOK_CONFIRMED,
    PROJECT_OVERVIEW_LOGBOOK_REMOVED,
    PROJECT_OVERVIEW_SET_LOGBOOK_VIEW_STATE
} from '../actions/projectOverviewActions';
import { GEOVIS_PROJECT_REPORT_PDF_INITIAL_STATE } from '../actions/projectReportActions.pdf';
import {
    IAttachedDocumentsStorage,
    IChainsInfoStorage,
    IChainsLastDataStorage,
    IDXFLayersStorage,
    IGeoRasterLayersStorage,
    IGeovisProjectDataAction,
    IGeovisProjectDataActionEx,
    IGeovisProjectDataState,
    IGeovisProjectDtsConfigurationStorage,
    ILocalMapObjectsStorage,
    ILogbookProjectElementsStorage,
    ILogbookSelectionStorage,
    IProjectAlarmsStorage,
    IProjectElementsStorage,
    IProjectInfoStorage,
    IProjectLoRaStorage,
    IProjectMapPosition,
    IProjectMapViewState,
    IProjectSupportStorage,
    IProjectViewFilter,
    IProjectViewGeoRasters,
    IProjectViewsStorage,
    IProjectVisualSettingsStorage,
    IReportsStorage,
    ISensorsInfoStorage,
    ISensorsLastDataStorage,
    ISensorsSymbolsStorage
} from '../data.types';
import { IGeovisProjectEditAction } from '../edit.types';
import {
    convertChainsLastMeasurementsToStorage,
    convertSensorsSymbolsSettings,
    processFetchedData,
    processFetchedDataToMap
} from '../helpers/DataHelper';
import {
    switchIsPublicForLocalMapObject,
    updateLocalMapObjects
} from '../helpers/LocalMapObjectHelper';
import { IGeovisProjectReportPdfAction } from '../projectReport.types.pdf';
import {
    defaultSomethingStorageState,
    errorSomethingStorageState,
    inProgressSomethingStorageState,
    loadedSomethingStorageState
} from '../types';
import { GeoRasterFileModel } from '../../server/AVTService/TypeLibrary/Model/GeoRasterFileModel';

const defaultLogbookPagesInfo: LogbookPagesInfo = {
    PageNumber: 0,
    PageSize: 0,
    TotalPages: 0,
    TotalRecords: 0
}

const projectInfoInitialState: IProjectInfoStorage = {
    ...defaultSomethingStorageState,
    project: { ...new ProjectInfo(), AvailableFeatures: [] }
};

const projectViewsInitialState: IProjectViewsStorage = {
    ...defaultSomethingStorageState,
    projectViewsInfo: new Map<string, ProjectViewInfo>(),
    viewId: '',
    expandInfo: new Map<string, boolean>(),
    embeddedDataName: ReportsName
};

const projectViewFilterInitialState: IProjectViewFilter = {
    searchElementQuery: '',
    expandInfo: new Map<string, boolean>(),
    sensorFilter: {
        angleFilter: { End: undefined, Start: undefined },
        radiusFilter: { End: undefined, Start: undefined },
        tunnelMeterFilter: { End: undefined, Start: undefined },
        manufacturerFilter: [],
        sensorTypeFilter: [],
        unitFilter: [],
        databasesFilter: []
    },
    redrawTree: false
}

const projectSensorsSymbolSettingsInitialState: ISensorsSymbolsStorage = {
    ...defaultSomethingStorageState,
    iconSettings: new Map<SensorCategory, Geovis4SensorSymbolSettings>(),
    groupNearSensors: false,
    radiusOfNearSensorsInPixels: 0
};

const attachedDocumentsStorageInitialState: IAttachedDocumentsStorage = {
    ...defaultSomethingStorageState,
    documents: new Map<string, AttachedDocumentModel>()
};

export const dxfLayersStorageInitialState: IDXFLayersStorage = {
    ...defaultSomethingStorageState,
    dxfLayers: new Map<string, ProjectDXFLayerInfo>()
};

export const geoRasterStorageInitialState: IGeoRasterLayersStorage = {
    ...defaultSomethingStorageState,
    rasters: new Map<string, GeoRasterFileModel>()
}

export const projectViewGeoRastersInitialState: IProjectViewGeoRasters = {
    ...defaultSomethingStorageState,
    rasters: [],
    currentRasterIdOfView: ''
}

export const localMapObjectsDataStorageInitialState: ILocalMapObjectsStorage = {
    ...defaultSomethingStorageState,
    localMapObjects: new Map<string, LocalMapObject>(),
    localMapObjectSensorsInfo: new Map<string, LocalMapObjectSensorsInfo>()
};

export const sensorsInfoStorageInitialState: ISensorsInfoStorage = {
    ...defaultSomethingStorageState,
    sensorsInfo: new Map<string, SensorInfo>()
};

export const sensorsLastDataStorageInitialState: ISensorsLastDataStorage = {
    ...defaultSomethingStorageState,
    sensorsLastMeasurements: new Map<string, SensorMeasurementInfo>()
};

export const chainsInfoStorageInitialState: IChainsInfoStorage = {
    ...defaultSomethingStorageState,
    chainsInfo: new Map<string, ChainInfo>()
}

export const chainsLastDataStorageInitialState: IChainsLastDataStorage = {
    ...defaultSomethingStorageState,
    chainsLastMeasurements: new Map<string, ChainMeasurementInfo>()
};

const reportsStorageInitialState: IReportsStorage = {
    ...defaultSomethingStorageState,
    reports: new Map<number, ProjectReportInfo>()
};

const logbookElementsStorageInitialStorage: ILogbookProjectElementsStorage = {
    ...defaultSomethingStorageState,
    logbookElements: new Map<number, LogbookProjectElement>()
};

const logbookSelectionStorageInitialStorage: ILogbookSelectionStorage = {
    ...defaultSomethingStorageState,
    selectedRecordsIds: [],
    projectId: 0,
    logbookAuthors: []
};

const projectMapViewStateInitialState: IProjectMapViewState = {
    position: {
        bounds: L.latLngBounds([]),
        center: ambergTechnologiesCoordinates(),
        zoom: 0
    },
    layersVisibility: {
        showSensorsLayer: true,
        showGeoJSONLayers: true,
        showInclinometerChains: true
    },
    sensorsLayerState: {
        showSensorNames: false,
        visibleSensorTypes: new Map<SensorCategory, boolean>(),
        fontSize: 10
    },
    showLegend: true
}

const projectSupportStorageInitialState: IProjectSupportStorage = {
    ...defaultSomethingStorageState,
    templates: new Map<string, ProjectSupportMessageTemplate>(),
    templateId: '',
    editorMode: SupportTemplateEditorMode.View,
    storageTimeMark: ''
}

const loraStorageInitialState: IProjectLoRaStorage = {
    ...defaultSomethingStorageState,
    storagesInfo: new Map<string, GeovisMstModel>()
}

const projectDtsConfigurationStorageInitialState: IGeovisProjectDtsConfigurationStorage = {
    ...defaultSomethingStorageState,
    isLoading: false,
    dtsSectionInfos: []
}

const visualSettingsStorageInitialState: IProjectVisualSettingsStorage = {
    ...defaultSomethingStorageState,
    visualSettings: {
        chartAxisLabelFontSize: '12px',
        chartLineWidth: '2px',
        negativeHeightBarColor: "#91bfdb",
        positiveHeightBarColor: "#fc8d59",
        movementVectorColor: "#FF0000"
    }
};

const projectElementsInitialStorage: IProjectElementsStorage = {
    ...defaultSomethingStorageState,
    elements: {
        Comments: [],
        CustomGroups: [],
        Footers: [],
        GeovisCharts: [],
        GeovisTables: [],
        GeovisHeaders: [],
        GeovisComments: [],
        Maps: [],
        MapSections: [],
        ProfileTemplates: [],
        Profiles: [],
        ReportElementLogbooks: [],
        GeovisChartCompanyTemplates: [],
        GeovisChartProjectTemplates: [],
        GeovisCommentsCompanyTemplates: [],
        GeovisCommentsProjectTemplates: [],
        GeovisReports: [],
        GeovisImages: [],
        GeovisImageProjectTemplates: [],
        GeovisImagesCompanyTemplates: [],
        GeovisTablesCompanyTemplates: [],
        GeovisTablesProjectTemplates: []
    }
}

export const alarmStorageInitialState = (storageName: string): IProjectAlarmsStorage => ({
    ...defaultSomethingStorageState,
    alarms: [],
    storageName
});

export const projectDataInitialState: IGeovisProjectDataState = {
    projectMapViewState: { ...projectMapViewStateInitialState },
    attachedDocumentsStorage: { ...attachedDocumentsStorageInitialState },
    dxfLayersStorage: { ...dxfLayersStorageInitialState },
    localMapObjectsDataStorage: { ...localMapObjectsDataStorageInitialState },
    projectInfo: { ...projectInfoInitialState },
    sensorsInfoStorage: { ...sensorsInfoStorageInitialState },
    sensorsLastDataStorage: { ...sensorsLastDataStorageInitialState },
    projectViewsStorage: { ...projectViewsInitialState },
    chainsInfoStorage: { ...chainsInfoStorageInitialState },
    chainsLastDataStorage: { ...chainsLastDataStorageInitialState },
    reportsStorage: { ...reportsStorageInitialState },
    logbookElementsStorage: { ...logbookElementsStorageInitialStorage },
    sensorsSymbolsStorage: { ...projectSensorsSymbolSettingsInitialState },
    visualSettingsStorage: { ...visualSettingsStorageInitialState },
    alarmsStorage: { ...alarmStorageInitialState(RaisedAlarmsStorageName) },
    projectSupportStorage: { ...projectSupportStorageInitialState },
    logbookRecordsStorage: {
        ...defaultSomethingStorageState,
        pagesInfo: { ...defaultLogbookPagesInfo },
        records: []
    },
    projectLogoStorage: {
        ...defaultSomethingStorageState,
        filesInfo: new Map<string, LogoFileInfo>()
    },
    projectStatisticStorage: {
        actualStatistic: new GeovisProjectStatistic(),
        isError: false,
        isLoaded: false,
        isLoading: true,
        isInProgress: false,
        errorDescription: ''
    },
    projectUsersStorage: {
        ...defaultSomethingStorageState,
        users: new Map<string, GeovisUserCommonInfo>()
    },
    projectLabelStorage: {
        ...defaultSomethingStorageState,
        labels: new Map<string, LabelModel>()
    },
    logbookSelectionStorage: { ...logbookSelectionStorageInitialStorage },
    projectViewFilter: projectViewFilterInitialState,
    loraStorage: loraStorageInitialState,
    projectCompaniesStorage: {
        ...defaultSomethingStorageState,
        creators: [],
        owner: undefined
    },
    projectProfileViewsStorage: {
        ...defaultSomethingStorageState,
        currentLayerId: '',
        layers: new Map<string, ProjectDXFLayerInfo>()
    },
    projectSideViewsStorage: {
        ...defaultSomethingStorageState,
        currentLayerId: '',
        layers: new Map<string, ProjectDXFLayerInfo>()
    },
    projectDatabasesStorage: {
        ...defaultSomethingStorageState,
        databases: []
    },
    projectDtsConfigurationStorage: { ...projectDtsConfigurationStorageInitialState },
    projectElementsStorage: projectElementsInitialStorage,
    geoRasterLayersStorage: geoRasterStorageInitialState,
    projectViewGeoRasters: projectViewGeoRastersInitialState
};

/**
 * Project data reducer
 * @param state 
 * @param action 
 */
const projectDataReducer: Reducer<IGeovisProjectDataState> = (
    state: IGeovisProjectDataState = projectDataInitialState,
    action: IGeovisProjectDataAction): IGeovisProjectDataState => {

    // main processing step
    let mainState = projectDataMainReducer(state, action);

    // second step
    mainState = projectViewTreeDataReducer(mainState, action);

    // update default project view
    mainState = projectViewDataReducer(mainState, action);

    // third step
    mainState = projectMapDataReducer(mainState, action);

    mainState = editLocalMapObjectsDataReducer(mainState, action as IGeovisProjectEditAction);

    // project support message reducer
    mainState = projectSupportMessageTemplatesReducer(mainState, action as IGeovisProjectDataActionEx<ProjectSupportMessageTemplate[]>);

    // lora reducer
    mainState = loraReducer(mainState, action);

    // DTS configuration reducer
    mainState = dtsConfigurationReducer(mainState, action);

    // project elements reducer
    mainState = projectElementsReducer(mainState, action);

    // result
    return mainState;
}

/**
 * Edit LMO data reducer
 * @param state 
 * @param action 
 */
const editLocalMapObjectsDataReducer = (state: IGeovisProjectDataState, action: IGeovisProjectEditAction): IGeovisProjectDataState => {

    if (!action) {
        return state;
    }

    switch (action.type) {
        case PROJECT_EDIT_LOCAL_MAP_OBJECT_CHANGE_IS_PUBLIC:
            if (!action.lmoId) {
                return state;
            }
            return {
                ...state,
                localMapObjectsDataStorage: switchIsPublicForLocalMapObject(state.localMapObjectsDataStorage, action.lmoId, action.isPublic || false)
            };

        case PROJECT_EDIT_LOCAL_MAP_OBJECT_ADD:
            if (!action.localMapObjects) {
                return state;
            }
            return {
                ...state,
                localMapObjectsDataStorage: {
                    ...state.localMapObjectsDataStorage,
                    localMapObjects: joinMapElements(state.localMapObjectsDataStorage.localMapObjects, action.localMapObjects),
                }
            }

        case PROJECT_EDIT_LOCAL_MAP_OBJECT_REMOVE:
            if (!action.lmoId) {
                return state;
            }
            return {
                ...state,
                localMapObjectsDataStorage: {
                    ...state.localMapObjectsDataStorage,
                    localMapObjects: deleteElementOfMap(state.localMapObjectsDataStorage.localMapObjects, action.lmoId)
                }
            }

        case PROJECT_EDIT_LOCAL_MAP_OBJECT_UPDATE:
            if (!action.localMapObjects) {
                return state;
            }
            return {
                ...state,
                localMapObjectsDataStorage: updateLocalMapObjects(state.localMapObjectsDataStorage, action.localMapObjects)
            };
    }

    return state;
}

const projectMapDataReducer = (state: IGeovisProjectDataState, action: IGeovisProjectDataAction): IGeovisProjectDataState => {

    switch (action.type) {
        case PROJECT_DATA_RESET_MAP_VIEWPORT_OF_VIEW:
        case PROJECT_DATA_OVERVIEW_CONFIGURATION_DATA: {
            let ctr;
            const lat = sessionStorage.getItem("center-lat");
            const lng = sessionStorage.getItem("center-lng");
            const storedZoom = sessionStorage.getItem("zoom");
            const storedViewId = sessionStorage.getItem("view");
            const bounds = calculateMapElementsBounds(state);
            if (lat && lng) {
                ctr = new LatLng(+lat, +lng);
            }
            else {
                ctr = getProjectOverviewMapCenter(state) || bounds.getCenter()
            }

            const position: IProjectMapPosition = {
                bounds,
                center: ctr,
                zoom: storedZoom ? +storedZoom : getProjectOverviewMapZoom(state)
            };
            return {
                ...state,
                projectMapViewState: { ...projectMapViewStateInitialState, position },
                projectViewsStorage: {
                    ...state.projectViewsStorage,
                    viewId: storedViewId ? storedViewId : state.projectViewsStorage.viewId
                }
            };
        }
        case PROJECT_DATA_VIEW_SELECTED: {

            const { projectInfo, projectViewsStorage } = state;

            const bounds = calculateMapElementsBounds(state);

            if (projectInfo.project.KeepZoomLevel) {

                const centerLatId = 'center-lat';
                const centerLngId = 'center-lng';
                const zoomId = 'zoom';

                const centerLat = sessionStorage.getItem(centerLatId);
                const centerLng = sessionStorage.getItem(centerLngId);
                const zoom = sessionStorage.getItem(zoomId);

                sessionStorage.clear();

                if (centerLat) {
                    sessionStorage.setItem(centerLatId, centerLat);
                }

                if (centerLng) {
                    sessionStorage.setItem(centerLngId, centerLng);
                }

                if (zoom) {
                    sessionStorage.setItem(zoomId, zoom);
                }

            } else {
                sessionStorage.clear();
            }

            sessionStorage.setItem("projectId", projectInfo.project.Id.toString());
            sessionStorage.setItem("view", projectViewsStorage.viewId);

            const position: IProjectMapPosition = {
                bounds,
                center: getProjectOverviewMapCenter(state) || bounds.getCenter(),
                zoom: getProjectOverviewMapZoom(state)
            };

            if (projectInfo.project.KeepZoomLevel) {
                return state;
            }

            if (position.zoom) {
                sessionStorage.setItem("zoom", position.zoom.toString());
            }

            if (position.center) {
                sessionStorage.setItem("center-lat", position.center.lat.toString());
                sessionStorage.setItem("center-lng", position.center.lng.toString());
            }

            return {
                ...state,
                projectMapViewState: { ...projectMapViewStateInitialState, position }
            };
        }

        case PROJECT_DATA_SENSORS_CHAINS_LOADED:
        case PROJECT_DATA_LOCAL_MAP_OBJECTS_DATA:
            return {
                ...state,
                projectMapViewState: {
                    ...state.projectMapViewState,
                    position: {
                        ...state.projectMapViewState.position,
                        bounds: calculateMapElementsBounds(state)
                    }
                }
            }

        case PROJECT_DATA_SET_MAP_VIEW_STATE:
            if (!action.mapViewState) {
                return state;
            }

            return {
                ...state,
                projectMapViewState: {
                    ...state.projectMapViewState,
                    ...action.mapViewState
                }
            }

        case PROJECT_DATA_SHOW_LEGEND:
            return {
                ...state,
                projectMapViewState: {
                    ...state.projectMapViewState,
                    showLegend: action.booleanProperty || false
                }
            }
    }

    return state;
}


/**
 * there is updates the project view tree data
 * @param state 
 * @param action 
 */
const projectViewTreeDataReducer = (state: IGeovisProjectDataState, action: IGeovisProjectDataAction): IGeovisProjectDataState => {

    switch (action.type) {

        case PROJECT_DATA_VIEW_TREE_ITEM_EXPAND:
        case PROJECT_DATA_VIEW_TREE_ITEM_COLLAPSE: {

            if (!action.itemId) {
                return state;
            }

            const { expandInfo } = state.projectViewsStorage;

            const isExpanded = action.type === PROJECT_DATA_VIEW_TREE_ITEM_EXPAND;
            expandInfo.set(action.itemId, isExpanded)

            return {
                ...state,
                projectViewsStorage: {
                    ...state.projectViewsStorage,
                    expandInfo
                }
            }
        }
    }

    return state;
}

/**
 * Edit project view data reducer
 * @param state 
 * @param action 
 */
const projectViewDataReducer = (state: IGeovisProjectDataState, action: IGeovisProjectDataAction): IGeovisProjectDataState => {
    switch (action.type) {
        case PROJECT_EDIT_VIEW_DELETE: {
            const { stringProperty: defaultViewId } = action as IGeovisProjectEditAction;
            if (!defaultViewId) {
                return state;
            }

            return {
                ...state,
                projectInfo: {
                    ...state.projectInfo,
                    project: {
                        ...state.projectInfo.project,
                        DefaultProjectViewId: defaultViewId
                    }
                }
            }
        }
    }

    return state;
}

/**
 * Main project data reducer processing
 * @param state 
 * @param action 
 */
const projectDataMainReducer = (state: IGeovisProjectDataState, action: IGeovisProjectDataAction): IGeovisProjectDataState => {

    switch (action.type) {

        //#region Data batch processing

        case PROJECT_DATA_OVERVIEW_CONFIGURATION:
            return {
                ...state,
                projectInfo: projectInfoInitialState,
                projectViewsStorage: projectViewsInitialState,
                sensorsSymbolsStorage: projectSensorsSymbolSettingsInitialState,
                sensorsInfoStorage: sensorsInfoStorageInitialState,
                sensorsLastDataStorage: sensorsLastDataStorageInitialState,
                chainsInfoStorage: chainsInfoStorageInitialState,
                chainsLastDataStorage: chainsLastDataStorageInitialState,
                attachedDocumentsStorage: attachedDocumentsStorageInitialState,
                dxfLayersStorage: dxfLayersStorageInitialState,
                localMapObjectsDataStorage: localMapObjectsDataStorageInitialState,
                logbookElementsStorage: logbookElementsStorageInitialStorage,
                reportsStorage: reportsStorageInitialState,
                projectViewFilter: projectViewFilterInitialState
            };

        case PROJECT_DATA_OVERVIEW_CONFIGURATION_DATA: {
            if (!action.mainConfiguration) {
                return state;
            }

            const { projectViewsInfo, symbolSettings } = action.mainConfiguration;
            const projectInfo = processFetchedData(action.mainConfiguration.projectInfo, state.projectInfo, projectInfoInitialState, project => ({ project }));

            return {
                ...state,
                projectInfo,
                projectViewsStorage: processFetchedData<ProjectViewInfo[], IProjectViewsStorage>(projectViewsInfo, state.projectViewsStorage, projectViewsInitialState, views => ({
                    projectViewsInfo: elementsToMap(views),
                    viewId: getProjectViewId(projectInfo, elementsToMap(views)),
                    expandInfo: new Map<string, boolean>(),
                    embeddedDataName: ReportsName,
                })),
                projectViewFilter: {
                    searchElementQuery: '',
                    expandInfo: new Map<string, boolean>(),
                    sensorFilter: {
                        angleFilter: { End: undefined, Start: undefined },
                        radiusFilter: { End: undefined, Start: undefined },
                        tunnelMeterFilter: { End: undefined, Start: undefined },
                        manufacturerFilter: [],
                        sensorTypeFilter: [],
                        unitFilter: [],
                        databasesFilter: []
                    },
                    redrawTree: false
                },
                sensorsSymbolsStorage: processFetchedData(symbolSettings, state.sensorsSymbolsStorage, projectSensorsSymbolSettingsInitialState, convertSensorsSymbolsSettings)
            }
        }

        //#endregion

        // reset view if we back to Projects Overview
        case PROJECTS_OVERVIEW:
            return projectDataInitialState;

        // #region Project info

        case PROJECT_DATA_PROPERTIES:
            return { ...state, projectInfo: { ...state.projectInfo, isLoading: true } };

        case PROJECT_DATA_PROPERTIES_DATA:
            if (!action.project) {
                return state;
            }
            return {
                ...state,
                projectInfo: {
                    ...loadedSomethingStorageState,
                    project: action.project
                }
            };

        case GEOVIS_PROJECT_REPORT_PDF_INITIAL_STATE: {
            const pdfAction = action as IGeovisProjectReportPdfAction;
            if (!pdfAction.pdfReportInitialState || !pdfAction.pdfReportInitialState.Success) {
                return state;
            }

            return {
                ...state,
                projectInfo: {
                    ...loadedSomethingStorageState,
                    project: pdfAction.pdfReportInitialState.Data.ProjectInfo
                }
            }
        }

        case PROJECT_DATA_PROPERTIES_LOADED:
            if (!action.projectInfoData) {
                return state;
            }

            return {
                ...state,
                projectInfo: processFetchedData<ProjectInfo, IProjectInfoStorage>(
                    action.projectInfoData,
                    state.projectInfo,
                    projectInfoInitialState,
                    p => ({ project: p })
                )
            };

        case PROJECT_DATA_UPDATE_PROJECT_VIEWPORT:
            if (state.projectInfo.isLoading || !action.viewport) {
                return state;
            }

            return {
                ...state,
                projectInfo: {
                    ...state.projectInfo,
                    project: {
                        ...state.projectInfo.project,
                        DefaultMapViewport: { ...action.viewport }
                    }
                }
            };

        case PROJECT_DATA_OVERVIEW_CHANGED:
            if (!action.projectOverview) {
                return state;
            }

            return {
                ...state,
                projectInfo: {
                    ...state.projectInfo,
                    project: {
                        ...state.projectInfo.project,
                        ProjectOverview: action.projectOverview
                    }
                }
            }

        // #endregion

        // #region attached documents

        case PROJECT_DATA_ATTACHED_DOCUMENTS:
            return { ...state, attachedDocumentsStorage: { ...state.attachedDocumentsStorage, isLoading: true }, projectViewsStorage: { ...state.projectViewsStorage, embeddedDataName: DocumentsName } };

        case PROJECT_DATA_ATTACHED_DOCUMENTS_DATA:
            if (action.documents) {
                return {
                    ...state,
                    attachedDocumentsStorage: {
                        ...loadedSomethingStorageState,
                        documents: elementsToMap(action.documents)
                    }
                };
            }
            break;

        case PROJECT_DATA_ATTACHED_DOCUMENTS_ERROR:
            return {
                ...state,
                attachedDocumentsStorage: {
                    ...attachedDocumentsStorageInitialState,
                    ...errorSomethingStorageState(action.errorDescription)
                }
            }

        case PROJECT_DATA_ATTACHED_DOCUMENTS_LOADED:
            if (!action.attachedDocumentsData) {
                return state;
            }

            return {
                ...state,
                attachedDocumentsStorage: processFetchedData<AttachedDocumentModel[], IAttachedDocumentsStorage>(
                    action.attachedDocumentsData,
                    state.attachedDocumentsStorage,
                    attachedDocumentsStorageInitialState,
                    a => ({ documents: elementsToMap(a) }))
            }

        case PROJECT_DATA_ATTACHED_DOCUMENT_ADD:
        case PROJECT_DATA_ATTACHED_DOCUMENT_UPDATE:
            if (action.documents) {
                return {
                    ...state,
                    attachedDocumentsStorage: {
                        ...loadedSomethingStorageState,
                        documents: joinMapElements(state.attachedDocumentsStorage.documents, action.documents)
                    }
                }
            }
            break;

        case PROJECT_DATA_ATTACHED_DOCUMENT_DELETED:
            if (action.objectId) {
                return {
                    ...state,
                    attachedDocumentsStorage: {
                        ...loadedSomethingStorageState,
                        documents: deleteElementOfMap(state.attachedDocumentsStorage.documents, action.objectId)
                    }
                }
            }
            break;

        // #endregion

        // #region DXF layers

        case PROJECT_DATA_DXF_LAYERS:
            return { ...state, dxfLayersStorage: { ...state.dxfLayersStorage, isLoading: true } }

        case PROJECT_DATA_DXF_LAYERS_DATA:
            if (action.dxfLayers) {
                return {
                    ...state,
                    dxfLayersStorage: {
                        ...loadedSomethingStorageState,
                        dxfLayers: elementsToMap(action.dxfLayers)
                    }
                }
            }
            break;

        case PROJECT_DATA_DXF_LAYER_DELETE:
            if (action.objectId) {
                return {
                    ...state,
                    dxfLayersStorage: {
                        ...state.dxfLayersStorage,
                        dxfLayers: deleteElementOfMap(state.dxfLayersStorage.dxfLayers, action.objectId)
                    }
                }
            }
            break;

        // #endregion

        // #region Local map objects

        case PROJECT_DATA_LOCAL_MAP_OBJECTS:
            return { ...state, localMapObjectsDataStorage: { ...localMapObjectsDataStorageInitialState } };

        case PROJECT_DATA_LOCAL_MAP_OBJECTS_DATA: {

            if (!action.localMapObjectsData) {
                return state;
            }

            const { lmoChildSensors, localMapObjects } = action.localMapObjectsData;

            return {
                ...state,
                localMapObjectsDataStorage: processFetchedData<LocalMapObject[], ILocalMapObjectsStorage>(
                    localMapObjects,
                    state.localMapObjectsDataStorage,
                    localMapObjectsDataStorageInitialState,
                    lmos => ({
                        localMapObjects: elementsToMap(lmos),
                        localMapObjectSensorsInfo: processFetchedDataToMap<string, LocalMapObjectSensorsInfo>(
                            lmoChildSensors,
                            state.localMapObjectsDataStorage.localMapObjectSensorsInfo,
                            localMapObjectsDataStorageInitialState.localMapObjectSensorsInfo)
                    }))
            }
        }

        // #endregion

        // #region sensors info

        case PROJECT_DATA_SENSORS_CHAINS_LOADING:
            return {
                ...state,
                sensorsInfoStorage: sensorsInfoStorageInitialState,
                chainsInfoStorage: chainsInfoStorageInitialState,
                chainsLastDataStorage: chainsLastDataStorageInitialState,
                sensorsLastDataStorage: sensorsLastDataStorageInitialState
            }

        case PROJECT_DATA_SENSORS_CHAINS_LOADED: {
            return {
                ...state,
                sensorsInfoStorage: processFetchedData(action.sensorsInfo, state.sensorsInfoStorage, sensorsInfoStorageInitialState, s => ({ sensorsInfo: elementsToMap(s) })),
                chainsInfoStorage: processFetchedData(action.chainsInfo, state.chainsInfoStorage, chainsInfoStorageInitialState, c => ({ chainsInfo: elementsToMap(c) }))
            };
        }

        case PROJECT_DATA_SENSORS_CHAINS_MEAS_LOADED:
            return {
                ...state,
                sensorsLastDataStorage: processFetchedData(action.sensorsLastMeasurements, state.sensorsLastDataStorage, sensorsLastDataStorageInitialState, d => ({ sensorsLastMeasurements: elementsToMap(d) })),
                chainsLastDataStorage: processFetchedData(action.chainsLastMeasurements, state.chainsLastDataStorage, chainsLastDataStorageInitialState, convertChainsLastMeasurementsToStorage)
            }

        case PROJECT_DATA_APPEND_SENSORS_MEAS:
            if (!action.sensorsLastMeasurements) {
                return state;
            }

            return {
                ...state,
                sensorsLastDataStorage: {
                    ...state.sensorsLastDataStorage,
                    sensorsLastMeasurements: addOrUpdateElementInMap(state.sensorsLastDataStorage.sensorsLastMeasurements, ...action.sensorsLastMeasurements.Data)
                }
            }

        case PROJECT_DATA_SENSORS_INFO:
            return { ...state, sensorsInfoStorage: sensorsInfoStorageInitialState }

        case PROJECT_DATA_SENSORS_INFO_DATA:
            return {
                ...state,
                sensorsInfoStorage: processFetchedData(action.sensorsInfo, state.sensorsInfoStorage, sensorsInfoStorageInitialState, s => ({ sensorsInfo: elementsToMap(s) }))
            }

        case PROJECT_DATA_SENSORS_INFO_ERROR:
            return {
                ...state,
                sensorsInfoStorage: {
                    ...sensorsInfoStorageInitialState,
                    ...errorSomethingStorageState(action.errorDescription)
                }
            };

        case PROJECT_DATA_SENSOR_INFO_UPDATED: {
            if (!action.sensorInfo) {
                return state;
            }

            return ({
                ...state,
                sensorsInfoStorage: {
                    ...state.sensorsInfoStorage,
                    sensorsInfo: addOrUpdateElementInMap(state.sensorsInfoStorage.sensorsInfo, action.sensorInfo)
                }
            })
        }

        case PROJECT_DATA_SENSOR_INFO_DELETED: {
            if (!action.stringProperty) {
                return state;
            }

            return ({
                ...state,
                sensorsInfoStorage: {
                    ...state.sensorsInfoStorage,
                    sensorsInfo: deleteElementInMap(state.sensorsInfoStorage.sensorsInfo, action.stringProperty)
                }
            })
        }

        case PROJECT_DATA_CHAINS_INFO:
            return { ...state, chainsInfoStorage: chainsInfoStorageInitialState }


        case PROJECT_DATA_CHAINS_INFO_DATA:
            return {
                ...state,
                chainsInfoStorage: processFetchedData(action.chainsInfo, state.chainsInfoStorage, chainsInfoStorageInitialState, c => ({ chainsInfo: elementsToMap(c) }))
            }

        case PROJECT_DATA_CHAINS_INFO_ERROR:
            return {
                ...state,
                chainsInfoStorage: {
                    ...chainsInfoStorageInitialState,
                    ...errorSomethingStorageState(action.errorDescription)
                }
            }

        case PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADING:
            return {
                ...state,
                sensorsLastDataStorage: sensorsLastDataStorageInitialState
            }

        case PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADED: {
            const { sensorsLastMeasurements, booleanProperty: append } = action;

            if (!sensorsLastMeasurements) {
                return state;
            }

            if (!state.sensorsLastDataStorage.isLoaded || !append) {
                return {
                    ...state,
                    sensorsLastDataStorage: processFetchedData(action.sensorsLastMeasurements, state.sensorsLastDataStorage, sensorsLastDataStorageInitialState, d => ({ sensorsLastMeasurements: elementsToMap(d) })),
                }
            }

            if (!sensorsLastMeasurements.Success) {
                return state;
            }

            return {
                ...state,
                sensorsLastDataStorage: {
                    ...state.sensorsLastDataStorage,
                    sensorsLastMeasurements: addOrUpdateElementInMap(state.sensorsLastDataStorage.sensorsLastMeasurements, ...sensorsLastMeasurements.Data)
                }
            }
        }

        case PROJECT_DATA_SENSORS_LAST_MEASUREMENTS_LOADING_ERROR:
            return {
                ...state,
                sensorsLastDataStorage: {
                    ...sensorsLastDataStorageInitialState,
                    ...errorSomethingStorageState(action.errorDescription)
                }
            };

        // #endregion

        // #region project visual settings

        case PROJECT_DATA_VISUAL_SETTINGS:
            return {
                ...state,
                visualSettingsStorage: {
                    ...projectDataInitialState.visualSettingsStorage,
                    isLoading: true
                }
            };

        case PROJECT_DATA_VISUAL_SETTINGS_DATA:
            if (!action.visualSettings) {
                return state;
            }

            return {
                ...state,
                visualSettingsStorage: {
                    ...loadedSomethingStorageState,
                    visualSettings: action.visualSettings
                }
            };

        case PROJECT_DATA_VISUAL_SETTINGS_ERROR:
            return {
                ...state,
                visualSettingsStorage: {
                    ...projectDataInitialState.visualSettingsStorage,
                    ...errorSomethingStorageState(action.errorDescription)
                }
            };

        // #endregion

        // #region sensor symbols

        case PROJECT_DATA_SYMBOL_SETTINGS:
            return {
                ...state,
                sensorsSymbolsStorage: {
                    ...projectDataInitialState.sensorsSymbolsStorage,
                    isLoading: true
                }
            };

        case PROJECT_DATA_SYMBOL_SETTINGS_DATA:
            if (action.symbolSettings) {

                const iconSettings = new Map<SensorCategory, Geovis4SensorSymbolSettings>();
                action.symbolSettings.SymbolSettings.forEach(option => {
                    iconSettings.set(option.Category, option);
                });

                return {
                    ...state,
                    sensorsSymbolsStorage: {
                        ...loadedSomethingStorageState,
                        groupNearSensors: action.symbolSettings.GroupNearSensors,
                        radiusOfNearSensorsInPixels: action.symbolSettings.RadiusOfNearSensorsInPixels,
                        iconSettings
                    }
                };
            }
            break;

        case PROJECT_DATA_SYMBOL_SETTINGS_ERROR:
            return {
                ...state,
                sensorsSymbolsStorage: {
                    ...projectDataInitialState.sensorsSymbolsStorage,
                    ...errorSomethingStorageState(action.errorDescription)
                }
            };


        case PROJECT_DATA_SYMBOLS_SETTINGS_RESPONSE_LOADED:
            if (!action.symbolSettingsResponse) {
                return state;
            }

            return {
                ...state,
                sensorsSymbolsStorage: processFetchedData(action.symbolSettingsResponse, state.sensorsSymbolsStorage, projectSensorsSymbolSettingsInitialState, convertSensorsSymbolsSettings)
            }

        // #endregion

        // #region views

        case PROJECT_EDIT_VIEW_SET_AS_DEFAULT: {
            if (!action.viewId) {
                return state;
            }
            return {
                ...state,
                projectInfo: {
                    ...loadedSomethingStorageState,
                    project: {
                        ...state.projectInfo.project,
                        DefaultProjectViewId: action.viewId
                    }
                }
            };
        }


        case PROJECT_DATA_RESET_MAP_VIEWPORT_OF_VIEW:
            if (!action.viewId) {
                return state;
            }

            return {
                ...state,
                projectViewsStorage: {
                    ...state.projectViewsStorage,
                    projectViewsInfo: updateElementPropertiesInMap(state.projectViewsStorage.projectViewsInfo, action.viewId, {
                        DefaultMapData: {
                            Latitude: 0,
                            Longitude: 0,
                            ZoomLevel: 0
                        }
                    })
                }
            };

        case PROJECT_DATA_VIEW_SELECTED:
            if (!action.viewId) {
                return state;
            }

            if (state.projectViewsStorage.viewId === action.viewId) {
                return state;
            }

            return {
                ...state,
                sensorsInfoStorage: sensorsInfoStorageInitialState,
                sensorsLastDataStorage: sensorsLastDataStorageInitialState,
                chainsInfoStorage: chainsInfoStorageInitialState,
                chainsLastDataStorage: chainsLastDataStorageInitialState,
                projectViewsStorage: {
                    ...state.projectViewsStorage,
                    viewId: action.viewId,
                    expandInfo: projectViewsInitialState.expandInfo
                },
                projectViewFilter: {
                    ...state.projectViewFilter,
                    expandInfo: projectViewFilterInitialState.expandInfo
                },
                projectProfileViewsStorage: {
                    ...defaultSomethingStorageState,
                    currentLayerId: '',
                    layers: new Map<string, ProjectDXFLayerInfo>()
                },
                projectSideViewsStorage: {
                    ...defaultSomethingStorageState,
                    currentLayerId: '',
                    layers: new Map<string, ProjectDXFLayerInfo>()
                },
                alarmsStorage: alarmStorageInitialState(RaisedAlarmsStorageName)
            }

        case PROJECT_DATA_VIEW_TREE_FILTER_CHANGED:
            if (!action.sensorFilter) {
                return state;
            }
            return {
                ...state,
                projectViewFilter: {
                    ...state.projectViewFilter,
                    sensorFilter: action.sensorFilter
                }
            }

        case PROJECT_DATA_VIEW_TREE_FILTER_NODE_EXPANDED: {
            if (!action.stringProperty) {
                return state;
            }
            const expand = state.projectViewFilter.expandInfo;
            expand.set(action.stringProperty, true);
            return {
                ...state,
                projectViewFilter: {
                    ...state.projectViewFilter,
                    expandInfo: expand,
                    redrawTree: !state.projectViewFilter.redrawTree
                }
            }
        }

        case PROJECT_DATA_VIEW_TREE_ITEM_SELECTION_CHANGE: {
            if (!action.stringValues || action.booleanProperty === undefined) {
                return state;
            }

            const newSensorsInfo = state.sensorsInfoStorage.sensorsInfo;

            newSensorsInfo.forEach((val, key) => {
                if (action.stringValues?.includes(key)) {
                    val.Selected = action.booleanProperty ?? false;
                }
            });

            return {
                ...state,
                sensorsInfoStorage: {
                    ...state.sensorsInfoStorage,
                    sensorsInfo: newSensorsInfo
                },
                projectViewFilter: {
                    ...state.projectViewFilter,
                    redrawTree: !state.projectViewFilter.redrawTree
                }
            }
        }

        case PROJECT_DATA_VIEW_TREE_FILTER_NODE_COLLAPSED: {
            if (!action.stringProperty) {
                return state;
            }
            const collapse = state.projectViewFilter.expandInfo;
            collapse.set(action.stringProperty, false);
            return {
                ...state,
                projectViewFilter: {
                    ...state.projectViewFilter,
                    expandInfo: collapse,
                    redrawTree: !state.projectViewFilter.redrawTree
                }
            }
        }

        case PROJECT_DATA_VIEW_TREE_SEARCH:
            if (action.searchQuery === undefined) {
                return state;
            }

            return {
                ...state,
                projectViewFilter: {
                    ...state.projectViewFilter,
                    searchElementQuery: action.searchQuery
                }
            };

        case PROJECT_DATA_VIEW_UPDATE_VIEWPORT: {
            if (action.viewport === undefined || action.viewId === undefined) {
                return state;
            }

            return {
                ...state,
                projectViewsStorage: {
                    ...state.projectViewsStorage,
                    projectViewsInfo: updateElementPropertiesInMap(state.projectViewsStorage.projectViewsInfo, action.viewId, { DefaultMapData: action.viewport })
                }
            };
        }

        case PROJECT_VIEWS_LOADING: {
            return {
                ...state,
                projectViewsStorage: projectViewsInitialState
            }
        }

        case PROJECT_VIEWS_LOADED: {
            if (!action.projectViewsResponse) {
                return state;
            }

            return {
                ...state,
                projectViewsStorage: processFetchedData<ProjectViewInfo[], IProjectViewsStorage>(action.projectViewsResponse, state.projectViewsStorage, projectViewsInitialState, views => ({
                    projectViewsInfo: elementsToMap(views),
                    viewId: getProjectViewId(state.projectInfo, elementsToMap(views)),
                    expandInfo: new Map<string, boolean>(),
                    embeddedDataName: ReportsName,
                }))
            }
        }

        case PROJECT_VIEWS_LOADING_ERROR: {
            return {
                ...state,
                projectViewsStorage: {
                    ...state.projectViewsStorage,
                    ...errorSomethingStorageState(action.errorDescription)
                }
            }
        }

        // #endregion

        // #region georaster

        case PROJECT_DATA_GEORASTER_LOADING: {
            return {
                ...state,
                geoRasterLayersStorage: geoRasterStorageInitialState
            }
        }

        case PROJECT_DATA_GEORASTER_LOADED: {
            if (!action.projectGeoRasterResponse) {
                return state;
            }

            return {
                ...state,
                geoRasterLayersStorage: processFetchedData<GeoRasterFileModel[], IGeoRasterLayersStorage>(action.projectGeoRasterResponse, state.geoRasterLayersStorage, geoRasterStorageInitialState, rasters => ({
                    rasters: elementsToMap(rasters)
                }))
            }
        }

        case PROJECT_DATA_GEORASTER_LOADING_ERROR: {
            return {
                ...state,
                geoRasterLayersStorage: {
                    ...state.geoRasterLayersStorage,
                    ...errorSomethingStorageState(action.errorDescription)
                }
            }
        }

        case PROJECT_DATA_GEORASTER_ADDED:
        case PROJECT_DATA_GEORASTER_UPDATED: {
            if (!action.raster) {
                return state;
            }

            return {
                ...state,
                geoRasterLayersStorage: {
                    ...state.geoRasterLayersStorage,
                    rasters: addOrUpdateElementInMap(state.geoRasterLayersStorage.rasters, action.raster)
                }
            }
        }

        case PROJECT_DATA_GEORASTER_REMOVED: {
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                geoRasterLayersStorage: {
                    ...state.geoRasterLayersStorage,
                    rasters: deleteElementInMap(state.geoRasterLayersStorage.rasters, action.stringProperty)
                }
            }
        }


        case PROJECT_VIEW_DATA_GEORASTERS_LOADING: {
            return {
                ...state,
                projectViewGeoRasters: projectViewGeoRastersInitialState
            }
        }

        case PROJECT_VIEW_DATA_GEORASTERS_LOADING_ERROR: {
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                projectViewGeoRasters: {
                    ...errorSomethingStorageState(action.stringProperty),
                    currentRasterIdOfView: '',
                    rasters: []
                }
            }
        }

        case PROJECT_VIEW_DATA_GEORASTERS_LOADED: {
            if (!action.slimRasters) {
                return state;
            }

            const currentRasterId = action.slimRasters.length > 0 ? action.slimRasters[0].Id : '';

            return {
                ...state,
                projectViewGeoRasters: {
                    ...loadedSomethingStorageState,
                    currentRasterIdOfView: currentRasterId,
                    rasters: action.slimRasters
                }
            }
        }

        case PROJECT_VIEW_DATA_CURRENT_GEORASTER_CHANGED: {
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                projectViewGeoRasters: {
                    ...state.projectViewGeoRasters,
                    currentRasterIdOfView: action.stringProperty
                }
            }
        }

        // #endregion


        // #region recent alarms

        case PROJECT_DATA_ALARMS:
            if (!action.alarmStorageName) {
                return state;
            }

            return {
                ...state,
                alarmsStorage: alarmStorageInitialState(action.alarmStorageName)
            };

        case PROJECT_DATA_ALARMS_DATA:

            if (!action.alarms || !action.alarmStorageName) {
                return state;
            }

            return {
                ...state,
                alarmsStorage: processFetchedData(action.alarms, state.alarmsStorage, alarmStorageInitialState(action.alarmStorageName), alarms => ({
                    alarms,
                    storageName: action.alarmStorageName!
                }))
            }

        case PROJECT_DATA_ALARM_CONFIRMED: {
            if (!action.stringValue || !action.alarmId) {
                return state;
            }

            const fullId = action.stringValue;
            const alarmId = action.alarmId;

            const { alarmsStorage, sensorsInfoStorage } = state;
            if (alarmsStorage.storageName !== RaisedAlarmsStorageName) {
                return state;
            }

            const alarm = alarmsStorage.alarms.find(a => a.AlarmId === action.alarmId && a.SensorFullId === fullId);
            if (!alarm) {
                return state;
            }

            alarm.ConfirmedAt = Date.now.toString();
            alarm.DigitalOutputsSwitchedOff = true;

            const sensorInfo = sensorsInfoStorage.sensorsInfo.get(fullId);
            if (sensorInfo) {
                sensorInfo.CausedAlarms = sensorInfo.CausedAlarms.filter(a => a.AlarmId !== alarmId);
                sensorsInfoStorage.sensorsInfo.set(fullId, sensorInfo);
            }

            alarmsStorage.alarms.forEach(a => {
                if (a.AlarmId === alarmId) {
                    a.DigitalOutputsSwitchedOff = true;
                }
            });

            return {
                ...state,
                sensorsInfoStorage: { ...sensorsInfoStorage },
                alarmsStorage: {
                    ...state.alarmsStorage,
                    alarms: alarmsStorage.alarms.filter(a => !(a.AlarmId === alarm.AlarmId && a.SensorFullId === fullId))
                }
            }
        }

        case PROJECT_DATA_ALARM_DO_SWITCHED_OFF: {
            if (!action.alarmId) {
                return state;
            }

            const alarmId = action.alarmId;

            const { alarmsStorage } = state;
            if (alarmsStorage.storageName !== RaisedAlarmsStorageName) {
                return state;
            }

            alarmsStorage.alarms.forEach(a => {
                if (a.AlarmId === alarmId) {
                    a.DigitalOutputsSwitchedOff = true;
                }
            });

            return {
                ...state,
                alarmsStorage: {
                    ...alarmsStorage,
                    alarms: alarmsStorage.alarms
                }
            }
        }

        // #endregion

        // #region reports

        case PROJECT_DATA_REPORTS:
            return { ...state, reportsStorage: { ...projectDataInitialState.reportsStorage }, projectViewsStorage: { ...state.projectViewsStorage, embeddedDataName: ReportsName } };

        case PROJECT_DATA_REPORTS_DATA: {
            if (!action.reportsInfo) {
                return state;
            }

            return {
                ...state,
                reportsStorage: processFetchedData(action.reportsInfo, state.reportsStorage, reportsStorageInitialState, st => ({ reports: elementsToMap(st) }))
            }
        }

        case PROJECT_DATA_ADD_REPORT_INFO: {
            if (!action.reportInfo) {
                return state;
            }

            return {
                ...state,
                reportsStorage: {
                    ...state.reportsStorage,
                    reports: addOrUpdateElementInMap(state.reportsStorage.reports, action.reportInfo)
                }
            }
        }

        case PROJECT_DATA_REPORT_PUBLIC_CHANGED: {
            if (!action.numberProperty) {
                return state;
            }

            const reportToUpdate = state.reportsStorage.reports.get(action.numberProperty);

            if (!reportToUpdate) {
                return state;
            }

            reportToUpdate.IsPublic = !reportToUpdate.IsPublic;

            return {
                ...state,
                reportsStorage: {
                    ...state.reportsStorage,
                    reports: addOrUpdateElementInMap(state.reportsStorage.reports, reportToUpdate)
                }
            }
        }

        // #endregion

        // #region logbooks

        case PROJECT_DATA_LOGBOOK_MARK_AS_READED: {
            const { elementId, stringValue: userId, logbookModel } = action;
            if (!userId || !logbookModel) {
                return state;
            }

            // first search the logbook records in the storage of records
            const { id, type } = logbookModel;
            const logbookRecord = state.logbookRecordsStorage.records.find(r => r.id === id);

            // record not found
            if (!logbookRecord) {
                return state;
            }

            // this record is already readed by this user
            if (logbookRecord.ReadedByUser.length > 0 && logbookRecord.ReadedByUser.indexOf(userId) > -1) {
                return state;
            }

            // second decrease the elements number if needed
            let logbookElementsStorage: ILogbookProjectElementsStorage = state.logbookElementsStorage;
            if (elementId) {

                const element = logbookElementsStorage.logbookElements.get(elementId);
                if (element) {

                    let unreadedRecordsNumber = element.UnreadedElements[type] || 0;
                    if (unreadedRecordsNumber > 0) {
                        unreadedRecordsNumber--;
                    }

                    element.UnreadedElements[type] = unreadedRecordsNumber;
                    logbookElementsStorage = {
                        ...logbookElementsStorage,
                        logbookElements: addOrUpdateElementInMap(state.logbookElementsStorage.logbookElements, element)
                    }
                }
            }

            return {
                ...state,
                logbookElementsStorage,
                logbookRecordsStorage: {
                    ...state.logbookRecordsStorage,
                    records: state.logbookRecordsStorage.records.map<LogbookModel>(r => r.id === logbookRecord.id ? { ...logbookRecord, ReadedByUser: [userId] } : r)
                }
            }
        }

        case PROJECT_DATA_LOGBOOK_MARK_AS_READED_SELECTED: {

            const { elementId, changingLogbooksResult } = action;
            if (!changingLogbooksResult) {
                return state;
            }

            const { Records, UnreadedInfo } = changingLogbooksResult;

            let logbookElementsStorage: ILogbookProjectElementsStorage = state.logbookElementsStorage;
            if (elementId) {
                const element = logbookElementsStorage.logbookElements.get(elementId);
                if (element) {
                    element.UnreadedElements = UnreadedInfo;

                    logbookElementsStorage = {
                        ...logbookElementsStorage,
                        logbookElements: addOrUpdateElementInMap(logbookElementsStorage.logbookElements, element)
                    };
                }
            }

            return {
                ...state,
                logbookElementsStorage,
                logbookSelectionStorage: { ...state.logbookSelectionStorage, selectedRecordsIds: [] },
                logbookRecordsStorage: {
                    ...state.logbookRecordsStorage,
                    records: state.logbookRecordsStorage.records.map<LogbookModel>(r => Records[r.id] || r)
                }
            }
        }

        case PROJECT_DATA_LOGBOOK_ELEMENTS:
            return { ...state, logbookElementsStorage: { ...projectDataInitialState.logbookElementsStorage } };

        case PROJECT_DATA_LOGBOOK_ELEMENTS_DATA:
            if (action.logbookElements) {
                return {
                    ...state,
                    logbookElementsStorage: {
                        ...loadedSomethingStorageState,
                        logbookElements: elementsToMap(action.logbookElements)
                    }
                }
            }
            break;

        case PROJECT_DATA_LOGBOOK_ELEMENTS_ERROR:
            if (!action.errorDescription) {
                return state;
            }

            return {
                ...state,
                logbookElementsStorage: {
                    ...errorSomethingStorageState(action.errorDescription),
                    logbookElements: new Map<number, LogbookProjectElement>()
                }
            }

        case PROJECT_DATA_LOGBOOK_RECORDS:
            return {
                ...state,
                logbookRecordsStorage: {
                    ...defaultSomethingStorageState,
                    pagesInfo: { ...defaultLogbookPagesInfo },
                    records: []
                },
                logbookSelectionStorage: {
                    ...state.logbookSelectionStorage,
                    selectedRecordsIds: state.logbookSelectionStorage.selectedRecordsIds
                }
            };

        case PROJECT_DATA_LOGBOOK_RECORDS_DATA: {
            if (!action.logbookRecordsData) {
                return state;
            }

            const { PagesInfo, Records, UnreadedRecords, ElementId } = action.logbookRecordsData;

            let logbookElementsStorage: ILogbookProjectElementsStorage = state.logbookElementsStorage;

            if (ElementId > 0) {
                const element = state.logbookElementsStorage.logbookElements.get(ElementId);
                if (element) {
                    logbookElementsStorage = {
                        ...logbookElementsStorage,
                        logbookElements: addOrUpdateElementInMap(state.logbookElementsStorage.logbookElements, { ...element, UnreadedElements: UnreadedRecords })
                    }
                }
            }

            return {
                ...state,
                logbookElementsStorage,
                logbookRecordsStorage: {
                    ...loadedSomethingStorageState,
                    pagesInfo: { ...PagesInfo },
                    records: [...Records]
                },
                logbookSelectionStorage: {
                    ...state.logbookSelectionStorage,
                    selectedRecordsIds: [] // state.logbookSelectionStorage.selectedRecordsIds
                }
            }
        }

        case PROJECT_DATA_LOGBOOK_RECORDS_ERROR:
            if (!action.errorDescription) {
                return state;
            }

            return {
                ...state,
                logbookRecordsStorage: {
                    ...errorSomethingStorageState(action.errorDescription),
                    records: [],
                    pagesInfo: { ...defaultLogbookPagesInfo },
                    // selectedRecordsIds: []
                },
                logbookSelectionStorage: {
                    ...state.logbookSelectionStorage,
                    selectedRecordsIds: []
                }
            }

        case PROJECT_DATA_LOGBOOK_ELEMENT_SAVE_ENTRY:
            if (!action.logbookModel) {
                return state;
            }

            return {
                ...state,
                logbookRecordsStorage: {
                    ...state.logbookRecordsStorage,
                    isLoaded: false
                }
            }

        case PROJECT_DATA_LOGBOOK_CHECK_ALL: {
            if (action.booleanProperty === undefined) {
                return state;
            }

            const selectedLogbookIds = action.booleanProperty ? state.logbookRecordsStorage.records.map(r => r.id) : [];

            return {
                ...state,
                logbookSelectionStorage: {
                    ...state.logbookSelectionStorage,
                    selectedRecordsIds: selectedLogbookIds
                }
            }
        }

        case PROJECT_DATA_LOGBOOK_RECORD_SELECTION_CHANGED: {
            if (action.booleanProperty === undefined || action.stringProperty === undefined) {
                return state;
            }

            const selectedRecordsIds: string[] = action.booleanProperty
                ? [...state.logbookSelectionStorage.selectedRecordsIds, action.stringProperty]
                : state.logbookSelectionStorage.selectedRecordsIds.filter(lId => lId !== action.stringProperty);

            return {
                ...state,
                logbookSelectionStorage: {
                    ...state.logbookSelectionStorage,
                    selectedRecordsIds
                }
            }
        }

        case PROJECT_DATA_LOGBOOK_AUTHORS_LOADED: {
            if (!action.projectUsers) {
                return state;
            }
            return {
                ...state,
                logbookSelectionStorage: {
                    ...state.logbookSelectionStorage,
                    logbookAuthors: action.projectUsers,
                    isLoaded: true,
                    isLoading: false,
                    isError: false,
                }
            }
        }

        case PROJECT_DATA_LOGBOOK_AUTHORS_LOADING:
            if (action.numberProperty === undefined) {
                return state;
            }
            return {
                ...state,
                logbookSelectionStorage: {
                    ...state.logbookSelectionStorage,
                    isLoaded: false,
                    isLoading: true,
                    isError: false,
                    projectId: action.numberProperty
                }
            }

        case PROJECT_DATA_LOGBOOK_AUTHORS_LOADING_ERROR: {
            if (action.stringProperty === undefined) {
                return state;
            }
            return {
                ...state,
                logbookSelectionStorage: {
                    ...state.logbookSelectionStorage,
                    isLoaded: false,
                    isLoading: false,
                    isError: true,
                    logbookAuthors: []
                }
            }
        }

        case PROJECT_OVERVIEW_SET_LOGBOOK_VIEW_STATE:
        case PROJECT_OVERVIEW_LOGBOOK_CHANGED_TEXT_SEARCH_STRING:
        case PROJECT_OVERVIEW_LOGBOOK_CHANGED_PAGE_NUMBER:
        case PROJECT_OVERVIEW_LOGBOOK_CHANGED_ROWS_PER_PAGE:
        case PROJECT_OVERVIEW_LOGBOOK_CHANGED_SORTING:
        case PROJECT_OVERVIEW_LOGBOOK_REMOVED: {
            return {
                ...state,
                logbookRecordsStorage: {
                    ...state.logbookRecordsStorage,
                    isLoaded: false
                }
            }
        }

        case PROJECT_OVERVIEW_LOGBOOK_CONFIRMED: {
            if (!action.stringProperty) {
                return state;
            }
            const records = cloneDeep(state.logbookRecordsStorage.records);
            records.forEach(r => {
                if (r.id === action.stringProperty && (r as AlarmLogbookModel)) {
                    (r as AlarmLogbookModel).waitingConfirm = false;
                }
            });
            return {
                ...state,
                logbookRecordsStorage: {
                    ...state.logbookRecordsStorage,
                    records
                }
            }
        }

        // #endregion

        //#region logo files info

        case PROJECT_DATA_LOGOS_INFO:
            return {
                ...state,
                projectLogoStorage: { ...projectDataInitialState.projectLogoStorage }
            };

        case PROJECT_DATA_LOGOS_INFO_ERROR:
            return {
                ...state,
                projectLogoStorage: {
                    ...projectDataInitialState.projectLogoStorage,
                    isError: true
                }
            }

        case PROJECT_DATA_LOGOS_INFO_DATA:
            if (!action.logoFilesInfo) {
                return state;
            }

            return {
                ...state,
                projectLogoStorage: {
                    ...loadedSomethingStorageState,
                    filesInfo: elementsToMap(action.logoFilesInfo)
                }
            };

        case LOGO_EDIT_UPLOAD_END: {
            const editAction = action as IGeovisProjectEditAction;
            if (!editAction.objectId || !editAction.logoFileInfo) {
                return state;
            }

            return {
                ...state,
                projectLogoStorage: {
                    ...loadedSomethingStorageState,
                    filesInfo: addOrUpdateElementInMap(state.projectLogoStorage.filesInfo, editAction.logoFileInfo)
                }
            };
        }

        //#endregion

        //#region Project statistic

        case PROJECT_DATA_STATISTIC:
            return {
                ...state,
                projectStatisticStorage: { ...projectDataInitialState.projectStatisticStorage }
            };

        case PROJECT_DATA_STATISTIC_ERROR:
            return {
                ...state,
                projectStatisticStorage: {
                    ...projectDataInitialState.projectStatisticStorage,
                    isError: true,
                    isLoaded: true,
                    isLoading: false
                }
            }

        case PROJECT_DATA_STATISTIC_DATA:
            if (!action.projectStatistic) {
                return state;
            }

            return {
                ...state,
                projectStatisticStorage: {
                    ...loadedSomethingStorageState,
                    actualStatistic: action.projectStatistic
                }
            };

        //#endregion

        //#region Project users

        case PROJECT_DATA_USERS:
            return {
                ...state,
                projectUsersStorage: { ...projectDataInitialState.projectUsersStorage }
            };

        case PROJECT_DATA_USERS_ERROR:
            return {
                ...state,
                projectUsersStorage: {
                    ...projectDataInitialState.projectUsersStorage,
                    isError: true,
                    isLoaded: true,
                    isLoading: false
                }
            }

        case PROJECT_DATA_USERS_DATA:
            if (!action.projectUsers) {
                return state;
            }

            return {
                ...state,
                projectUsersStorage: {
                    ...loadedSomethingStorageState,
                    users: elementsToMap(action.projectUsers)
                }
            };

        case PROJECT_DATA_USERS_UPDATE:
            if (!action.projectUsers) {
                return state;
            }

            return {
                ...state,
                projectUsersStorage: {
                    ...loadedSomethingStorageState,
                    users: addOrUpdateElementInMap(state.projectUsersStorage.users, ...action.projectUsers)
                }
            }

        //#endregion

        //#region Labels

        case PROJECT_DATA_LABELS:
            return {
                ...state,
                projectLabelStorage: {
                    ...defaultSomethingStorageState,
                    labels: new Map<string, LabelModel>()
                }
            };

        case PROJECT_DATA_LABELS_DATA:
            if (!action.labels) {
                return state;
            }

            return {
                ...state,
                projectLabelStorage: processFetchedData(action.labels, state.projectLabelStorage, projectDataInitialState.projectLabelStorage, st => ({ labels: elementToMapLowerCase(st) }))
            }

        case PROJECT_DATA_LABEL_UPDATED: {
            if (!action.label) {
                return state;
            }
            const labels = state.projectLabelStorage.labels;
            labels.set(action.label.id, action.label);

            return {
                ...state,
                projectLabelStorage: {
                    ...state.projectLabelStorage,
                    labels
                }
            }
        }

        case PROJECT_DATA_LABEL_REMOVED: {
            if (!action.stringProperty) {
                return state;
            }
            const labels = state.projectLabelStorage.labels;
            labels.delete(action.stringProperty);

            return {
                ...state,
                projectLabelStorage: {
                    ...state.projectLabelStorage,
                    labels
                }
            }
        }

        //#endregion
        case PROJECT_DATA_INFORMATION_UPDATE:
            if (action.stringValue === undefined || !action.tabName) {
                return state;
            }
            switch (action.tabName) {
                case AAName:
                    return {
                        ...state,
                        projectInfo: {
                            ...state.projectInfo,
                            project: {
                                ...state.projectInfo.project,
                                InternalInformation: action.stringValue!
                            }
                        }
                    }
                case PAName:
                    return {
                        ...state,
                        projectInfo: {
                            ...state.projectInfo,
                            project: {
                                ...state.projectInfo.project,
                                AdminInformation: action.stringValue!
                            }
                        }
                    }
                case AllName:
                    return {
                        ...state,
                        projectInfo: {
                            ...state.projectInfo,
                            project: {
                                ...state.projectInfo.project,
                                Note: action.stringValue!
                            }
                        }
                    }
                default:
                    return state;
            }
        case PROJECT_DATA_KEEP_ZOOM_CHANGED: {
            const newKz = !state.projectInfo.project.KeepZoomLevel;
            return {
                ...state,
                projectInfo: {
                    ...state.projectInfo,
                    project: {
                        ...state.projectInfo.project,
                        KeepZoomLevel: newKz
                    }
                }
            }
        }
    }

    return state;
};

const projectSupportMessageTemplatesReducer = (state: IGeovisProjectDataState, action: IGeovisProjectDataActionEx<ProjectSupportMessageTemplate[]>): IGeovisProjectDataState => {

    switch (action.type) {

        case PROJECT_DATA_SUPPORT_LOADING:
            return {
                ...state,
                projectSupportStorage: projectSupportStorageInitialState
            };

        case PROJECT_DATA_SUPPORT_LOADED: {
            if (!action.data) {
                return state;
            }

            const { SupportMessageTemplateId } = state.projectInfo.project;

            return {
                ...state,
                projectSupportStorage: processFetchedData(action.data, state.projectSupportStorage, projectSupportStorageInitialState, d => ({
                    templateId: SupportMessageTemplateId,
                    templates: elementsToMap(d),
                    editorMode: SupportTemplateEditorMode.View,
                    storageTimeMark: ''
                }))
            }
        }

        case PROJECT_DATA_SUPPORT_SELECT_TEMPLATE_ID:
            if (action.stringValue === undefined) {
                return state;
            }

            return {
                ...state,
                projectInfo: {
                    ...state.projectInfo,
                    project: {
                        ...state.projectInfo.project,
                        SupportMessageTemplateId: action.stringValue
                    }
                },
                projectSupportStorage: {
                    ...state.projectSupportStorage,
                    templateId: action.stringValue,
                    editorMode: SupportTemplateEditorMode.View
                }
            };

        case PROJECT_DATA_SUPPORT_SAVE_MESSAGE_TEMPLATE:
            if (!action.data) {
                return state;
            }

            return {
                ...state,
                projectSupportStorage: {
                    ...state.projectSupportStorage,
                    templates: addOrUpdateElementInMap(state.projectSupportStorage.templates, ...action.data.Data),
                    editorMode: SupportTemplateEditorMode.View,
                    templateId: action.data.Data[0].Id
                }
            }

        case PROJECT_DATA_SUPPORT_START_EDIT_TEMPLATE: {
            return {
                ...state,
                projectSupportStorage: {
                    ...state.projectSupportStorage,
                    editorMode: SupportTemplateEditorMode.Edit
                }
            }
        }

        case PROJECT_DATA_SUPPORT_CANCEL_EDIT: {
            const { SupportMessageTemplateId } = state.projectInfo.project;

            return {
                ...state,
                projectSupportStorage: {
                    ...state.projectSupportStorage,
                    templateId: SupportMessageTemplateId,
                    editorMode: SupportTemplateEditorMode.View,
                    storageTimeMark: uuid.generate()
                }
            }
        }

        case PROJECT_DATA_SUPPORT_CREATE_NEW:
            return {
                ...state,
                projectSupportStorage: {
                    ...state.projectSupportStorage,
                    editorMode: SupportTemplateEditorMode.Create,
                    templateId: 'new'
                }
            }

        case PROJECT_DATA_SUPPORT_SHOW_DELETE_DIALOG:
            return {
                ...state,
                projectSupportStorage: {
                    ...state.projectSupportStorage,
                    editorMode: SupportTemplateEditorMode.Delete
                }
            }


        case PROJECT_DATA_SUPPORT_HIDE_DELETE_DIALOG:
            return {
                ...state,
                projectSupportStorage: {
                    ...state.projectSupportStorage,
                    editorMode: SupportTemplateEditorMode.View
                }
            }

        case PROJECT_DATA_SUPPORT_DELETE_TEMPLATE: {
            if (!action.stringValue) {
                return state;
            }

            const templates = deleteElementOfMap(state.projectSupportStorage.templates, action.stringValue);
            let templateId = state.projectSupportStorage.templateId;
            if (action.stringValue === templateId) {
                if (templates.size > 0) {
                    templateId = mapToListOfElements(templates)[0].Id;
                }
                else {
                    templateId = '';
                }
            }

            return {
                ...state,
                projectSupportStorage: {
                    ...state.projectSupportStorage,
                    editorMode: SupportTemplateEditorMode.View,
                    templates,
                    templateId
                }
            }
        }
        case PROJECT_COMPANIES_INFO: {
            return {
                ...state,
                projectCompaniesStorage: {
                    ...state.projectCompaniesStorage,
                    isLoaded: false,
                    isLoading: true
                }
            }
        }
        case PROJECT_COMPANIES_INFO_LOADED: {
            if (!action.projectCompaniesInfo) {
                return state;
            }
            return {
                ...state,
                projectCompaniesStorage: {
                    ...state.projectCompaniesStorage,
                    isLoaded: true,
                    isLoading: false,
                    creators: action.projectCompaniesInfo.Creators,
                    owner: action.projectCompaniesInfo.Customer ?? undefined
                }
            }
        }

        case PROJECT_SETTINGS_NOTIFICATIONS_CHANGED: {
            if (!action.project) {
                return state;
            }
            return {
                ...state,
                projectInfo: {
                    ...state.projectInfo,
                    project: action.project
                }
            }
        }

        case PROJECT_PROFILE_VIEWS: {
            if (!action.typeOfDxfView) {
                return state;
            }
            return {
                ...state,
                projectProfileViewsStorage: action.typeOfDxfView === GEOvisDXFLayerType.ProfileView
                    ? {
                        ...defaultSomethingStorageState,
                        currentLayerId: '',
                        layers: new Map<string, ProjectDXFLayerInfo>()
                    }
                    : state.projectProfileViewsStorage,
                projectSideViewsStorage: action.typeOfDxfView === GEOvisDXFLayerType.SideView
                    ? {
                        ...defaultSomethingStorageState,
                        currentLayerId: '',
                        layers: new Map<string, ProjectDXFLayerInfo>()
                    }
                    : state.projectSideViewsStorage,
            }
        }

        case PROJECT_PROFILE_VIEWS_DATA: {
            if (!action.dxfViews || !action.typeOfDxfView) {
                return state;
            }
            try {
                const mapOfViews = new Map<string, ProjectDXFLayerInfo>();
                action.dxfViews.forEach(pw => {
                    mapOfViews.set(pw.Id, pw);
                });
                const firstElementId = action.dxfViews.length > 0 ? action.dxfViews[0].Id : "";

                return {
                    ...state,
                    projectProfileViewsStorage: action.typeOfDxfView === GEOvisDXFLayerType.ProfileView
                        ? {
                            ...loadedSomethingStorageState,
                            currentLayerId: firstElementId,
                            layers: mapOfViews
                        }
                        : state.projectProfileViewsStorage,
                    projectSideViewsStorage: action.typeOfDxfView === GEOvisDXFLayerType.SideView
                        ? {
                            ...loadedSomethingStorageState,
                            currentLayerId: firstElementId,
                            layers: mapOfViews
                        }
                        : state.projectSideViewsStorage,
                }
            } catch (error) {
                return state;
            }

        }

        case PROJECT_PROFILE_VIEWS_ERROR: {
            if (!action.stringProperty || !action.typeOfDxfView) {
                return state;
            }
            return {
                ...state,
                projectProfileViewsStorage: action.typeOfDxfView === GEOvisDXFLayerType.ProfileView
                    ? {
                        errorDescription: action.stringProperty,
                        isError: true,
                        isLoaded: false,
                        isLoading: false,
                        isInProgress: false,
                        currentLayerId: '',
                        layers: new Map<string, ProjectDXFLayerInfo>()
                    }
                    : state.projectProfileViewsStorage,
                projectSideViewsStorage: action.typeOfDxfView === GEOvisDXFLayerType.SideView
                    ? {
                        errorDescription: action.stringProperty,
                        isError: true,
                        isLoaded: false,
                        isLoading: false,
                        isInProgress: false,
                        currentLayerId: '',
                        layers: new Map<string, ProjectDXFLayerInfo>()
                    }
                    : state.projectSideViewsStorage,
            }
        }

        case PROJECT_PROFILE_VIEW_CHANGED: {
            if (!action.stringProperty || !action.typeOfDxfView) {
                return state;
            }
            return {
                ...state,
                projectProfileViewsStorage: action.typeOfDxfView === GEOvisDXFLayerType.ProfileView
                    ? {
                        ...state.projectProfileViewsStorage,
                        currentLayerId: action.stringProperty
                    }
                    : state.projectProfileViewsStorage,
                projectSideViewsStorage: action.typeOfDxfView === GEOvisDXFLayerType.SideView
                    ? {
                        ...state.projectSideViewsStorage,
                        currentLayerId: action.stringProperty
                    }
                    : state.projectSideViewsStorage,
            }
        }

        case PROJECT_DATABASES_IN_PROGRESS: {
            return {
                ...state,
                projectDatabasesStorage: {
                    ...inProgressSomethingStorageState,
                    databases: []
                }
            }
        }

        case PROJECT_DATABASES_LOADED: {
            if (!action.msts) {
                return state;
            }
            return {
                ...state,
                projectDatabasesStorage: {
                    ...loadedSomethingStorageState,
                    databases: action.msts,
                    projectId: action.numberProperty
                }
            }
        }

        case PROJECT_DATABASES_LOADING_ERROR: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                projectDatabasesStorage: {
                    ...errorSomethingStorageState(action.stringProperty),
                    databases: []
                }
            }
        }

        case PROJECT_DATABASE_ADDED: {
            if (!action.mst) {
                return state;
            }

            const updMsts = state.projectDatabasesStorage.databases;
            updMsts.push(action.mst);

            return {
                ...state,
                projectDatabasesStorage: {
                    ...state.projectDatabasesStorage,
                    databases: updMsts
                }
            }
        }

        case PROJECT_DATABASE_UPDATED: {
            if (action.mst === undefined) {
                return state;
            }

            const updMsts = state.projectDatabasesStorage.databases;
            const exMst = state.projectDatabasesStorage.databases.find(m => m.Id === action.mst?.Id);
            if (!exMst) {
                return state;
            }
            updMsts.splice(updMsts.indexOf(exMst), 1, action.mst);


            return {
                ...state,
                projectDatabasesStorage: {
                    ...state.projectDatabasesStorage,
                    databases: updMsts
                }
            }
        }

        case PROJECT_DATABASE_REMOVED: {
            if (!action.mstId) {
                return state;
            }

            const updMsts = state.projectDatabasesStorage.databases.filter(m => m.Id !== action.mstId);

            return {
                ...state,
                projectDatabasesStorage: {
                    ...state.projectDatabasesStorage,
                    databases: updMsts
                }
            }
        }

        case PROJECT_EDIT_REMOVE_PROJECT: {
            if (!(action as IGeovisProjectEditAction) || (action as IGeovisProjectEditAction).projectId === undefined) {
                return state;
            }
            return {
                ...state,
            }
        }

        case PROJECT_EDIT_REPORT_ADD_OR_UPDATE: {

            // const editAction = action as IGeovisProjectEditAction;
            const reportInfo = action.reportInfo;

            if (!reportInfo) {
                return state;
            }

            return {
                ...state,
                reportsStorage: {
                    ...state.reportsStorage,
                    reports: addOrUpdateElementInMap(state.reportsStorage.reports, reportInfo)
                }
            }
        }

        case PROJECT_EDIT_REPORT_REMOVED: {
            const editAction = action as IGeovisProjectEditAction;
            const reportId = editAction.numberProperty;

            if (reportId === undefined) {
                return state;
            }

            const reports = elementsToMap<number, ProjectReportInfo>(mapToListOfElements(state.reportsStorage.reports, r => r.Id !== reportId));

            return {
                ...state,
                reportsStorage: {
                    ...state.reportsStorage,
                    reports
                }
            }
        }
    }

    return state;
}

/**
 * Project LoRa data reducer
 * @param state 
 * @param action 
 */
const loraReducer = (state: IGeovisProjectDataState, action: IGeovisProjectDataAction): IGeovisProjectDataState => {

    switch (action.type) {

        case PROJECT_LORA_ROOT_CONFIGURATION:
            return {
                ...state,
                loraStorage: { ...loraStorageInitialState }
            }

        case PROJECT_LORA_ROOT_CONFIGURATION_DATA: {
            const dataAction = action as IGeovisProjectDataActionEx<GeovisLoRaRootConfiguration>;

            return {
                ...state,
                loraStorage: processFetchedData(dataAction.data, state.loraStorage, loraStorageInitialState, st => ({
                    configurations: elementsToMap(st.Gateways),
                    storagesInfo: elementsToMap(st.StoragesInfo)
                }))
            };
        }
    }
    return state;
}

//#region DTS Configuration Reducer

const updateDtsSectionInfo = (objectList: DtsSectionInfo[], targetObject: DtsSectionInfo) => {
    const targetIndex = objectList.findIndex(c => c.Id === targetObject.Id);
    if (targetIndex !== -1) {
        const updatedObjectList = [...objectList];
        updatedObjectList[targetIndex] = targetObject;
        return updatedObjectList;
    }

    return objectList;
}

const dtsConfigurationReducer = (state: IGeovisProjectDataState, action: IGeovisProjectDataAction): IGeovisProjectDataState => {
    switch (action.type) {
        case PROJECT_DTS_SECTIONS_LOADING:
            return {
                ...state,
                projectDtsConfigurationStorage: { ...projectDtsConfigurationStorageInitialState }
            }

        case PROJECT_DTS_SECTIONS_LOADED:
            if (!action.dtsSectionInfos) {
                return state;
            }
            return {
                ...state,
                projectDtsConfigurationStorage: {
                    ...loadedSomethingStorageState,
                    dtsSectionInfos: action.dtsSectionInfos
                }
            }


        case PROJECT_DTS_SECTIONS_LOADING_ERROR:
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                projectDtsConfigurationStorage: {
                    ...errorSomethingStorageState(action.stringProperty),
                    dtsSectionInfos: []
                }
            }


        case PROJECT_DTS_SECTION_ADDED:
            if (!action.dtsSectionInfo) {
                return state;
            }

            return {
                ...state,
                projectDtsConfigurationStorage: {
                    ...state.projectDtsConfigurationStorage,
                    dtsSectionInfos: [...state.projectDtsConfigurationStorage.dtsSectionInfos, action.dtsSectionInfo]
                }
            }

        case PROJECT_DTS_SECTION_UPDATED:
            if (!action.dtsSectionInfo) {
                return state;
            }

            return {
                ...state,
                projectDtsConfigurationStorage: {
                    ...state.projectDtsConfigurationStorage,
                    dtsSectionInfos: updateDtsSectionInfo(state.projectDtsConfigurationStorage.dtsSectionInfos, action.dtsSectionInfo)
                }
            }

        case PROJECT_DTS_SECTION_REMOVED:
            if (!action.dtsSectionId) {
                return state;
            }

            return {
                ...state,
                projectDtsConfigurationStorage: {
                    ...state.projectDtsConfigurationStorage,
                    dtsSectionInfos: state.projectDtsConfigurationStorage.dtsSectionInfos.filter(m => m.Id !== action.dtsSectionId)
                }
            };
    }

    return state;
}

//#endregion

// #region Project elements state

const projectElementsReducer = (state: IGeovisProjectDataState, action: IGeovisProjectDataAction): IGeovisProjectDataState => {
    switch (action.type) {
        case PROJECT_ELEMENT_ENTRIES_LOADING: {
            return {
                ...state,
                projectElementsStorage: {
                    ...projectElementsInitialStorage,
                    isLoading: true,
                    isLoaded: false
                }
            }
        }

        case PROJECT_ELEMENT_ENTRIES_LOADED: {
            if (!action.projectElementsData) {
                return state;
            }

            return {
                ...state,
                projectElementsStorage: processFetchedData(action.projectElementsData, state.projectElementsStorage, projectElementsInitialStorage, st => ({ elements: st }))
            }
        }

        case PROJECT_ELEMENT_ENTRY_ADDED:
            if (!action.elementEntry) {
                return state;
            }

            return {
                ...state,
                projectElementsStorage: {
                    ...state.projectElementsStorage,
                    elements: addProjectElementToStore(state.projectElementsStorage.elements, action.elementEntry, action.customElementsGroups || [])
                }
            }

        case PROJECT_ELEMENT_ENTRY_UPDATED:
            if (!action.elementEntry) {
                return state;
            }

            return {
                ...state,
                projectElementsStorage: {
                    ...state.projectElementsStorage,
                    elements: updateProjectElementInStorage(state.projectElementsStorage.elements, action.elementEntry, action.customElementsGroups || [])
                }
            }

        case PROJECT_ELEMENT_ENTRY_REMOVED:
            if (!action.elementId || !action.elementType) {
                return state;
            }

            return {
                ...state,
                projectElementsStorage: {
                    ...state.projectElementsStorage,
                    elements: removeProjectElementFromStorage(state.projectElementsStorage.elements, action.elementId, action.elementType, action.customElementsGroups || [])
                }
            };

        case PROJECT_ELEMENTS_CUSTOM_GROUPS_UPDATED:
            if (!action.customElementsGroups) {
                return state;
            }

            return {
                ...state,
                projectElementsStorage: {
                    ...state.projectElementsStorage,
                    elements: {
                        ...state.projectElementsStorage.elements,
                        CustomGroups: [...action.customElementsGroups]
                    }

                }
            }

    }

    return state;
}

// #endregion

export default projectDataReducer;