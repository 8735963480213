//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { KindOfElementUsing } from '../../Common/KindOfElementUsing';
import { TimePeriod } from '../../Common/TimePeriod';
import { LogbookType } from '../../Logbook/LogbookType';
import { DatesGrouping } from '../../Report/DatesGrouping';
import { ChartSettingBool } from '../ChartSettingBool';

export class GeovisLogbookModel
{
	public Id: number;
	public Name: string;
	public KindOfElementUsing: KindOfElementUsing;
	public ProjectId: number;
	public Title: string;
	public Period: TimePeriod;
	public IsPeriodCustomerChangeable: boolean;
	public UseLastMeasurementTime: boolean;
	public Hidden: boolean;
	public Types: LogbookType[];
	public Group: DatesGrouping;
	public LabelIds: string[];
	public ShowAuthor: boolean;
	public IsShowAuthorCustomerChangeable: boolean;
	public AllowViewerAddManualEntries: boolean;
	public ShowOnlyPublicEntries: boolean;
	public ShowInGeovis4: boolean;
	public CreatedFrom: number;
	public StartDate: string;
	public IsStartDateCustomerChangeable: boolean;
	public EndDate: string;
	public IsEndDateCustomerChangeable: boolean;
	public ShowComment: boolean;
	public IsShowCommentCustomerChangeable: boolean;
	public IsPublic: boolean;
	public LastUpdateTime: string;
	public TemplateCompanyId: string;
	public ShowOnlyActiveAlarms: boolean;
	public ShowAllMeasurementsSetting: ChartSettingBool;
	public ShowPicturesInReport: ChartSettingBool;
}
