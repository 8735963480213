//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { AlarmInfoRecord } from '../Model/AlarmInfoRecord';
import { FrequencyColoredBand } from '../Model/FrequencyColoredBand';
import { GeovisChartSlimConfig } from './GeovisChartSlimConfig';
import { ProjectVisualSettings } from '../Model/ProjectVisualSettings';

export class GeovisChartData
{
	public startMeasurementTime: string;
	public TimeSlotFrom: string;
	public TimeSlotTo: string;
	public LinkedAlarmLines: AlarmInfoRecord[];
	public VibrationAlarmsBands: FrequencyColoredBand[];
	public SlimChartConfig: GeovisChartSlimConfig;
	public visualSettings: ProjectVisualSettings;
}
