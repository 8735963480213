//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SensorMapTextOrientation {
	North = 0,
	South = 1,
	West = 2,
	East = 3,
	NorthWest = 4,
	NorthEast = 5,
	SouthWest = 6,
	SouthEast = 7
}

const SensorMapTextOrientationToName = new Map<number, string>([
	[ 0, 'North' ],
	[ 1, 'South' ],
	[ 2, 'West' ],
	[ 3, 'East' ],
	[ 4, 'NorthWest' ],
	[ 5, 'NorthEast' ],
	[ 6, 'SouthWest' ],
	[ 7, 'SouthEast' ],
])

export function getSensorMapTextOrientationToName(element: SensorMapTextOrientation) : string {
    return SensorMapTextOrientationToName.get(element) || '';
}

const SensorMapTextOrientationByName = new Map<string, number>([
	[ 'North', 0 ],
	[ 'South', 1 ],
	[ 'West', 2 ],
	[ 'East', 3 ],
	[ 'NorthWest', 4 ],
	[ 'NorthEast', 5 ],
	[ 'SouthWest', 6 ],
	[ 'SouthEast', 7 ],
]);

export function getSensorMapTextOrientationByName(name: string): SensorMapTextOrientation {
    return SensorMapTextOrientationByName.get(name)!;
}


export const SensorMapTextOrientationList : Readonly<SensorMapTextOrientation[]> = [
	SensorMapTextOrientation.North,
	SensorMapTextOrientation.South,
	SensorMapTextOrientation.West,
	SensorMapTextOrientation.East,
	SensorMapTextOrientation.NorthWest,
	SensorMapTextOrientation.NorthEast,
	SensorMapTextOrientation.SouthWest,
	SensorMapTextOrientation.SouthEast,
];



const SensorMapTextOrientationToDescription = new Map<number, string>([
	[ 0, 'North' ],
	[ 1, 'South' ],
	[ 2, 'West' ],
	[ 3, 'East' ],
	[ 4, 'NorthWest' ],
	[ 5, 'NorthEast' ],
	[ 6, 'SouthWest' ],
	[ 7, 'SouthEast' ],
])

export function getSensorMapTextOrientationToDescription(element: SensorMapTextOrientation) : string {
    return SensorMapTextOrientationToDescription.get(element) || '';
}

