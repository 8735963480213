//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GeovisUserToProjectRole {
	EmailReceiver = 0,
	Viewer = 1,
	Admin = 10
}

const GeovisUserToProjectRoleToName = new Map<number, string>([
	[ 0, 'EmailReceiver' ],
	[ 1, 'Viewer' ],
	[ 10, 'Admin' ],
])

export function getGeovisUserToProjectRoleToName(element: GeovisUserToProjectRole) : string {
    return GeovisUserToProjectRoleToName.get(element) || '';
}

const GeovisUserToProjectRoleByName = new Map<string, number>([
	[ 'EmailReceiver', 0 ],
	[ 'Viewer', 1 ],
	[ 'Admin', 10 ],
]);

export function getGeovisUserToProjectRoleByName(name: string): GeovisUserToProjectRole {
    return GeovisUserToProjectRoleByName.get(name)!;
}


export const GeovisUserToProjectRoleList : Readonly<GeovisUserToProjectRole[]> = [
	GeovisUserToProjectRole.EmailReceiver,
	GeovisUserToProjectRole.Viewer,
	GeovisUserToProjectRole.Admin,
];



const GeovisUserToProjectRoleToDescription = new Map<number, string>([
	[ 0, 'E-mail Receiver' ],
	[ 1, 'Project-Viewer' ],
	[ 10, 'Project-Admin' ],
])

export function getGeovisUserToProjectRoleToDescription(element: GeovisUserToProjectRole) : string {
    return GeovisUserToProjectRoleToDescription.get(element) || '';
}

