//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CompanySlimInfo } from './CompanySlimInfo';
import { GeovisDataPackage } from '../../../AVTService/TypeLibrary/Common/GeovisDataPackage';
import { GeovisLicenseType } from '../../../AVTService/TypeLibrary/Common/GeovisLicenseType';
import { LicensedFeatures } from '../../../AVTService/TypeLibrary/Licensing/LicensedFeatures';

export class CompanyInfo extends CompanySlimInfo
{
	public Address: string;
	public LogoId: string;
	public DataPackage?: GeovisDataPackage;
	public DolphinId: string;
	public DefaultSupportMessageTemplateId: string;
	public AlarmsSettingsAvailable: boolean;
	public CreatingVirtualSensorAvailable: boolean;
	public LicenseType: GeovisLicenseType;
	public AdditionalFeatures: LicensedFeatures[];
	public EnableNewInvoice: boolean;
	public MaxProjectsCountForBasicLicense: number;
	public AllCompanyFeatures: LicensedFeatures[];
}
