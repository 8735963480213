/**
 * @author Vyacheslav Skripin <vs@ieksr.ru>
 * @created 25.04.2022
 * @description Heatmap chart alarms tab
 */

import { XyChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel';
import { GeovisChartAlarmInfo } from '../../../../server/GEOvis3/Model/GeovisChart/GeovisChartAlarmInfo';
import { ChartAlarmLinesControl, IChartAlarmLinesControlActionProps } from '../ChartAlarmLinesControl';
import { ChartAlarmPlansControl, IChartAlarmPlansControlActionProps } from '../ChartAlarmPlansControl';
import { isChartTemplate } from '../tools';

interface IXyChartAlarmsTabProps extends IChartAlarmLinesControlActionProps, IChartAlarmPlansControlActionProps {
    chart: XyChartModel;

    alarmPlansInfo: GeovisChartAlarmInfo[];
}

export const XyChartAlarmsTab = ({
    chart,
    alarmPlansInfo,
    onAlarmLineDeleteClickHandler,
    onChangeAlarmLines,
    onNewAlarmLinkClickHandler,
    onChangeLinkedAlarms
}: IXyChartAlarmsTabProps) => {

    const { AlarmLines } = chart;

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainer">
                <ChartAlarmLinesControl
                    AlarmLines={AlarmLines}
                    chartType={chart.Type}
                    onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                    onChangeAlarmLines={onChangeAlarmLines}
                    onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
                    yUnit={chart.LeftYAxisSettings.Unit}
                    xUnit={chart.XAxisSettings.Unit}
                />
            </div>
            {!isChartTemplate(chart) &&
                <div className="flexRowContainer">
                    <ChartAlarmPlansControl
                        allAlarms={alarmPlansInfo || []}
                        onChangeLinkedAlarms={onChangeLinkedAlarms}
                        chart={chart}
                    />
                </div>
            }
        </div>
    )
}
