import Highcharts, { TooltipFormatterContextObject } from "highcharts";
import { formattedDateTime } from "../../../../../../helpers/DateHelper";
import { t } from "../../../../../../i18n";
import { getSensorMapTextOrientationByName, SensorMapTextOrientation } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorMapTextOrientation";
import { DtsChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/DtsChartData";
import { DtsCloseTSensorData } from "../../../../../../server/AVTService/TypeLibrary/Computation/DtsCloseTSensorData";
import { ColorizableElement } from "../../../../../../server/AVTService/TypeLibrary/DB/ColorizableElement";
import { DtsSection } from "../../../../../../server/AVTService/TypeLibrary/DtsConfiguration/DtsSection";
import { AlarmInfoRecord } from "../../../../../../server/AVTService/TypeLibrary/Model/AlarmInfoRecord";
import { ChartSettingBool } from "../../../../../../server/AVTService/TypeLibrary/Model/ChartSettingBool";
import { DtsChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel";
import { SensorHeatpulseOption } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SensorHeatpulseOption";
import { PhysicalUnit } from "../../../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { getSensorCategoryByName, SensorCategory } from "../../../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { DtsSectionInfo } from "../../../../../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfo";
import { createChartRenderedFunc } from "../../tools";
import {
    getColor,
    getDashStyleValue,
    getDtsChartSeriesLineWidth,
    getLimitValue,
    getMarkerSymbol,
    getSeriesSymbol,
    getSoftLimitValue,
    getUnitShortName,
    getXAxisUnitsAnnotation,
    getYAxisOptions,
    getYAxisUnitsAnnotation,
    isKnowTypeOfSensor,
    startEndOnTick
} from "./chartRenderOptions";
import { getBoostOptions } from "./tools";

const CLOSE_T_SENSORS_SERIES_NAME = "Close Temperature Sensors";

export interface IDtsRenderOptionsSettings {
    chart: DtsChartModel;
    dtsSection: DtsSection;
    startMeasurement: string;
    primaryData: number[][];
    primarySeriesName: string;
    secondaryData: number[][];
    secondarySeriesName: string;
    dtsCloseTSensorsData: DtsCloseTSensorData[];
    linkedAlarmLines: AlarmInfoRecord[];
    onChartRender: () => void;
}

export const getDtsChartRenderOptions = (pageNum: number, chart: DtsChartModel, chartData: DtsChartData, dtsSections: DtsSectionInfo[]): Highcharts.Options => {
    const getPointDescription = (point: any): string => {
        if (point.description !== undefined) {
            return point.description;
        }
        return "";
    }

    const isPointMarkerEnabled = (setting: ChartSettingBool): boolean => {
        return setting ? setting.value : true;
    }

    const getDefaultTooltipYValueAndDescription = (point: TooltipFormatterContextObject, showCustomTooltip: boolean): string => {
        let defaultTooltip = '';

        if (point.series.name.endsWith(CLOSE_T_SENSORS_SERIES_NAME)) {
            defaultTooltip = `${point.y} ${getUnitShortName(PhysicalUnit.Celsius)}`;
            const description = getPointDescription(point.point);
            if (description.length > 0) {
                defaultTooltip += `<br />${description}`;
            }
        }
        else {
            const symbol = (point.series as any).symbol;
            const { userOptions } = point.series;
            const seriesUnitShortName = userOptions.custom && userOptions.custom.unitShortName ? userOptions.custom.unitShortName : "";

            defaultTooltip = `${getSeriesSymbol({ symbol, color: point.color })} ${point.series.name}: ${point.y} ${seriesUnitShortName}`;
            if (showCustomTooltip && chart.ShowCustomTooltip && chart.CustomTooltip.length > 0) {
                defaultTooltip += `<br />${chart.CustomTooltip}`;
            }
        }

        return defaultTooltip;
    }

    /**
     * Gets primary series data
     * @returns 
     */
    const getPrimarySeries = (): Highcharts.SeriesOptionsType[] => {
        return dtsSections.map(dtsSection => {
            const primarySeriesColorElement = getDtsChartSeriesColorElement(chart.LeftYAxisSettings.TypeOfSensor, chart.LeftYAxisSettings.HeatpulseOption, dtsSection)
            const primaryLineColor = getColor(primarySeriesColorElement);
            const sectionData = chartData.DtsSectionDataMap[dtsSection.Id];

            return {
                type: 'line',
                yAxis: 0,
                data: sectionData ? sectionData.PrimaryData : [[]],
                name: sectionData ? sectionData.PrimarySeriesName : "",
                color: primarySeriesColorElement ? primarySeriesColorElement.Color : undefined,
                lineWidth: getDtsChartSeriesLineWidth(chartData.visualSettings, chart, primarySeriesColorElement),
                dashStyle: primarySeriesColorElement ? getDashStyleValue(primarySeriesColorElement.LineType) : undefined,
                marker: {
                    enabled: isPointMarkerEnabled(chart.LeftYAxisSettings.ShowPointSymbols),
                    symbol: getMarkerSymbol(primarySeriesColorElement),
                    lineColor: primaryLineColor,
                    lineWidth: 2
                },
                custom: {
                    unitShortName: getUnitShortName(chart.LeftYAxisSettings.Unit)
                }
            }
        })
    }

    /**
     * Gets secondary Y-Axis data series
     * @returns 
     */
    const getSecondarySeries = (): Highcharts.SeriesOptionsType[] => {
        return dtsSections.map(dtsSection => {
            const secondarySeriesColorElement = getDtsChartSeriesColorElement(chart.RightYAxisSettings.TypeOfSensor, chart.RightYAxisSettings.HeatpulseOption, dtsSection);
            const secondaryLineColor = getColor(secondarySeriesColorElement);
            const pointLineWidth = secondarySeriesColorElement ? secondarySeriesColorElement.LineWeight : 1;
            const sectionData = chartData.DtsSectionDataMap[dtsSection.Id];

            return {
                type: 'line',
                yAxis: 1,
                data: sectionData ? sectionData.SecondaryData : [[]],
                name: sectionData ? sectionData.SecondarySeriesName : "",
                color: secondarySeriesColorElement ? secondarySeriesColorElement.Color : undefined,
                lineWidth: chart.DrawLineBetweenPoints.value ? getDtsChartSeriesLineWidth(chartData.visualSettings, chart, secondarySeriesColorElement) : 0,
                dashStyle: secondarySeriesColorElement ? getDashStyleValue(secondarySeriesColorElement.LineType) : undefined,
                marker: {
                    enabled: isPointMarkerEnabled(chart.RightYAxisSettings.ShowPointSymbols),
                    symbol: getMarkerSymbol(secondarySeriesColorElement),
                    lineColor: secondaryLineColor,
                    lineWidth: pointLineWidth * 2,
                    customElements
                },
                custom: {
                    unitShortName: getUnitShortName(chart.RightYAxisSettings.Unit)
                },
                states: {
                    hover: {
                        lineWidthPlus: chart.DrawLineBetweenPoints.value ? 1 : 0
                    }
                }
            }
        })
    }

    const getNormalizedMapTextOrientation = (orientation: any): SensorMapTextOrientation => {
        if (typeof (orientation) === "string") {
            return getSensorMapTextOrientationByName(orientation);
        }
        return orientation;
    }

    const getLabelHorizontalAlignment = (textOrientation: SensorMapTextOrientation) => {
        switch (textOrientation) {
            case SensorMapTextOrientation.North:
            case SensorMapTextOrientation.South:
                return "center";

            case SensorMapTextOrientation.East:
            case SensorMapTextOrientation.NorthEast:
            case SensorMapTextOrientation.SouthEast:
                return "left";

            default:
                return "right";
        }
    }

    const getLabelVerticalAlignment = (textOrientation: SensorMapTextOrientation) => {
        switch (textOrientation) {
            case SensorMapTextOrientation.North:
            case SensorMapTextOrientation.NorthEast:
            case SensorMapTextOrientation.NorthWest:
                return "bottom";

            case SensorMapTextOrientation.South:
            case SensorMapTextOrientation.SouthEast:
            case SensorMapTextOrientation.SouthWest:
                return "top";

            default:
                return "middle";
        }
    }

    /**
     * Gets closest temperature sensors data series
     * @param yAxis 
     * @returns 
     */
    const getTSensorsDataSeries = (yAxis: number): Highcharts.SeriesOptionsType[] => {

        const result: Highcharts.SeriesOptionsType[] = [];

        /**
         * Get temperature sensors data
         * @param tSensorsData 
         * @returns 
         */
        const getData = (tSensorsData: DtsCloseTSensorData[]): Highcharts.PointOptionsObject[] => {
            return tSensorsData.map(d => {
                const mapTextOrientation = getNormalizedMapTextOrientation(d.MapTextOrientation);
                return ({
                    x: d.X,
                    y: d.Y,
                    description: `${d.SensorId}<br />${t("Distance to DTS")}: ${d.DistanceToDts.toFixed(4)} m`,
                    dataLabels: {
                        enabled: chart.ShowTemperaturePointLabels && chart.ShowTemperaturePointLabels.value,
                        align: getLabelHorizontalAlignment(mapTextOrientation),
                        verticalAlign: getLabelVerticalAlignment(mapTextOrientation),
                        formatter() {
                            return `${d.SensorId}`
                        }
                    },
                })
            });
        }

        for (const dtsSection of dtsSections) {

            const closeTSensorsColorElement = dtsSection.CloseTSensorsColorElement
            const pointLineWidth = closeTSensorsColorElement ? closeTSensorsColorElement.LineWeight : 1;
            const pointColor = closeTSensorsColorElement ? closeTSensorsColorElement.Color : undefined;
            const sectionData = chartData.DtsSectionDataMap[dtsSection.Id];

            const tSensorsData = sectionData ? sectionData.TSensorsData : [];

            if (tSensorsData && tSensorsData.length > 0) {
                result.push({
                    type: 'line',
                    yAxis,
                    data: getData(tSensorsData),
                    name: `${dtsSection.Name}_${CLOSE_T_SENSORS_SERIES_NAME}`,
                    color: pointColor,
                    lineWidth: 0,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                    marker: {
                        lineColor: pointColor,
                        symbol: getMarkerSymbol(closeTSensorsColorElement),
                        lineWidth: pointLineWidth * 2
                    }
                });
            }
        }

        return result;
    }

    const { SlimChartConfig } = chartData;

    const axesUnits = [getYAxisUnitsAnnotation(chart.LeftYAxisSettings, 0)];
    if (chart.RightYAxisSettings.TypeOfSensor !== undefined) {
        axesUnits.push(getYAxisUnitsAnnotation(chart.RightYAxisSettings, 1))
    }
    axesUnits.push(getXAxisUnitsAnnotation(chart.XAxisSettings))

    const series = getPrimarySeries();
    const noData = series.length === 0;

    const options: Highcharts.Options = {
        boost: getBoostOptions(chart.Type, SlimChartConfig),
        chart: {
            zooming: {
                type: chart.IsChartZoomAllow ? "xy" : undefined
            },
            reflow: true,
            alignTicks: true,
            showAxes: true,
            events: {
                render: createChartRenderedFunc(pageNum, chart.Id)
            }
        },
        annotations: axesUnits,
        title: {
            floating: false,
            text: "<div style='height:10px'></div>",
            useHTML: true
        },
        legend: {
            enabled: chart.ShowLegend.value,
            itemStyle: {
                fontSize: "14px"
            }
        },
        lang: {
            noData: `<div style='z-index:20'>${t('No data is available in the chart')}</div>`
        },
        noData: {
            useHTML: true
        },
        tooltip: {
            style: {
                padding: '5px',
            },
            shared: chart.ShowCommonTooltip.value,
            useHTML: true,
            formatter() {
                if (chart.ShowCommonTooltip.value && this.points !== undefined && this.points.length > 0) {
                    let commonTooltip = `${this.x}`;
                    this.points.forEach(point => {
                        const defaultTooltip = getDefaultTooltipYValueAndDescription(point, false);
                        commonTooltip += `<br />${defaultTooltip}`;
                    })
                    return commonTooltip;
                }
                else {
                    const defaultTooltip = `${this.x}<br />${getDefaultTooltipYValueAndDescription(this, true)}`;
                    return defaultTooltip;
                }

            }
        },
        xAxis: {
            title: {
                text: `${chart.XAxisSettings.axisXLabel}`,
                style: {
                    fontSize: chartData.visualSettings.chartAxisLabelFontSize
                },
            },
            reversed: chart.XAxisSettings.ReverseAxis.value,
            type: 'linear',
            lineWidth: 2,
            lineColor: "black",
            crosshair: true,
            minRange: 0.25,
            minorTickInterval: 'auto',
            min: getLimitValue(chart.XAxisSettings.minScaleLimit),
            max: getLimitValue(chart.XAxisSettings.maxScaleLimit),
            softMax: getSoftLimitValue(chart.XAxisSettings.maxScaleLimit),
            softMin: getSoftLimitValue(chart.XAxisSettings.minScaleLimit),
            startOnTick: startEndOnTick(chart.XAxisSettings.minScaleLimit),
            endOnTick: startEndOnTick(chart.XAxisSettings.maxScaleLimit),
            labels: {
                style: {
                    fontSize: chartData.visualSettings.chartAxisLabelFontSize
                }
            }
        },
        // yAxis: getYAxisOptions(chart, chartData.visualSettings, chartData.LinkedAlarmLines),
        yAxis: getYAxisOptions({
            chart,
            drawAlarmLines: true,
            linkedAlarmLines: chartData.LinkedAlarmLines,
            needSecondAxis: false,
            visualSettings: chartData.visualSettings,
            hideUnitsInLabel: false,
            primaryAxisValueType: undefined,
            secondaryAxisValueType: undefined,
            noData
        }),
        series,
        exporting: {
            enabled: false,
        },
        plotOptions: {
            series: {
                animation: false,
                events: {
                    legendItemClick() {
                        return false;
                    }
                }
            }
        },
        credits: {
            enabled: false
        }
    };

    if (chart.ShowStartMeasurements) {
        const startMeasurementText = chartData.startMeasurementTime ? formattedDateTime(chartData.startMeasurementTime, "DD.MM.YYYY") : 'no data';

        options.subtitle = {
            align: 'center',
            text: `${t("Start of measurements")}: ${startMeasurementText}`
        };
    }

    const hasSecondaryYAxis = isKnowTypeOfSensor(chart.RightYAxisSettings.TypeOfSensor);
    if (hasSecondaryYAxis) {
        if (options.series !== undefined) {
            options.series.push(...getSecondarySeries());
        }
    }

    if (options.series !== undefined) {

        const { LeftYAxisSettings, RightYAxisSettings } = chart;

        if (LeftYAxisSettings.TypeOfSensor === SensorCategory.Temperature && LeftYAxisSettings.HeatpulseOption === SensorHeatpulseOption.Default) {
            options.series.push(...getTSensorsDataSeries(0));
        }

        if (hasSecondaryYAxis && RightYAxisSettings.TypeOfSensor === SensorCategory.Temperature && RightYAxisSettings.HeatpulseOption === SensorHeatpulseOption.Default) {
            options.series.push(...getTSensorsDataSeries(1));
        }
    }

    return options;
}

const getDtsChartSeriesColorElement = (sensorType: SensorCategory, heatpulseOption: SensorHeatpulseOption, dtsSection?: DtsSection): ColorizableElement | undefined => {
    if (!dtsSection) {
        return undefined;
    }

    let sensorTypeValue = sensorType;
    if (typeof (sensorType) === 'string') {
        sensorTypeValue = getSensorCategoryByName(sensorType);
    }

    switch (sensorTypeValue) {
        case SensorCategory.Temperature:
            switch (heatpulseOption) {
                case SensorHeatpulseOption.Heatpulse_T0:
                    return dtsSection.HeatpulseT0;

                case SensorHeatpulseOption.Heatpulse_dT:
                    return dtsSection.HeatpulseDT;

                case SensorHeatpulseOption.Heatpulse_Temperature:
                    return dtsSection.HeatpulseTemperature;

                default:
                    return dtsSection.TemperatureColorElement;
            }

        case SensorCategory.Attenuation:
            switch (heatpulseOption) {
                case SensorHeatpulseOption.Heatpulse_Attenuation:
                    return dtsSection.HeatpulseAttenuation;

                default:
                    return dtsSection.AttenuationColorElement;
            }

        case SensorCategory.Stoke:
            switch (heatpulseOption) {
                case SensorHeatpulseOption.Heatpulse_Stoke_Antistoke:
                    return dtsSection.HeatpulseStockAntistoke;

                default:
                    return dtsSection.StokeColorElement;
            }

        case SensorCategory.NormalizedTemperature:
            return dtsSection.HeatpulseDTNormalized;

        case SensorCategory.ThermalConductivity:
            return dtsSection.HeatpulseThermalConductivity;

        default:
            return undefined;
    }
}