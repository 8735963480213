/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 24.09.2020
 * @description Message template view state data reducer
 */

import { Reducer } from 'redux';
import { updateElementsOfArrayIf } from '../../helpers/StorageHelper';
import { MessageTemplateModel } from "../../server/AVTService/TypeLibrary/MessageTemplates/MessageTemplateModel";
import { GEOVIS_BUSINESS_MESSAGE_TEMPLATE_HIDE_NOTIFICATION_DIALOG, GEOVIS_BUSINESS_MESSAGE_TEMPLATE_SHOW_NOTIFICATION_DIALOG } from '../actions/businessDataActions';
import {
    GEOVIS_MESSAGE_TEMPLATE,
    GEOVIS_MESSAGE_TEMPLATE_ADD_ATTACHMENT,
    GEOVIS_MESSAGE_TEMPLATE_DATA,
    GEOVIS_MESSAGE_TEMPLATE_DATA_ERROR,
    GEOVIS_MESSAGE_TEMPLATE_END_NOTIFICATION,
    GEOVIS_MESSAGE_TEMPLATE_MARK_ATTACHMENT_TO_REMOVE,
    GEOVIS_MESSAGE_TEMPLATE_REMOVE_DIRTY_ATTACHMENT,
    GEOVIS_MESSAGE_TEMPLATE_START_NOTIFICATION
} from '../actions/messageTemplateViewStateActions';
import { IGeovisBusinessDataAction } from '../businessData.types';
import {
    IGeovisMessageTemplatesViewAction,
    IGeovisMessageTemplatesViewState
} from "../messageTemplates.types";
import {
    defaultSomethingStorageState,
    errorSomethingStorageState,
    loadedSomethingStorageState
} from '../types';

export const messageTemplateViewStateInitialState: IGeovisMessageTemplatesViewState = {
    errorDescription: '',
    isError: false,
    isLoaded: false,
    isLoading: true,
    isInProgress: false,
    template: new MessageTemplateModel(),
    templateId: '',
    isNotificationInProgress: false
}

const messageTemplateViewStateReducer: Reducer<IGeovisMessageTemplatesViewState> = (
    state: IGeovisMessageTemplatesViewState = messageTemplateViewStateInitialState,
    action: IGeovisMessageTemplatesViewAction
): IGeovisMessageTemplatesViewState => {

    switch (action.type) {

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATE_SHOW_NOTIFICATION_DIALOG: {

            const businessAction = action as IGeovisBusinessDataAction;
            if (businessAction.stringProperty) {
                const templateId = businessAction.stringProperty;

                return {
                    ...state,
                    templateId,
                    isLoaded: false,
                    isLoading: true,
                    template: {
                        ...new MessageTemplateModel(),
                        Id: templateId
                    }
                };
            }
            break;
        }

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATE_HIDE_NOTIFICATION_DIALOG: {
            return {
                ...state,
                templateId: '',
                isLoaded: false,
                isLoading: true,
                template: { ...new MessageTemplateModel() }
            }
        }
    }

    switch (action.type) {
        case GEOVIS_MESSAGE_TEMPLATE:
            if (!action.templateId) {
                return state;
            }

            return {
                ...messageTemplateViewStateInitialState,
                ...defaultSomethingStorageState,
                templateId: action.templateId
            };

        case GEOVIS_MESSAGE_TEMPLATE_DATA:
            if (!action.template) {
                return state;
            }

            return {
                ...messageTemplateViewStateInitialState,
                ...loadedSomethingStorageState,
                template: action.template,
                templateId: action.template.Id
            };

        case GEOVIS_MESSAGE_TEMPLATE_DATA_ERROR:
            if (!action.errorDescription) {
                return state;
            }

            return {
                ...messageTemplateViewStateInitialState,
                ...errorSomethingStorageState(action.errorDescription)
            };

        case GEOVIS_MESSAGE_TEMPLATE_START_NOTIFICATION:
            return {
                ...state,
                isNotificationInProgress: true
            };


        case GEOVIS_MESSAGE_TEMPLATE_END_NOTIFICATION:
            return {
                ...state,
                isNotificationInProgress: false
            }

        case GEOVIS_MESSAGE_TEMPLATE_ADD_ATTACHMENT: {
            if (!action.templateId || !action.attachment) {
                return state;
            }

            if (action.templateId !== state.templateId) {
                return state;
            }

            return {
                ...state,
                template: {
                    ...state.template,
                    Attachments: [...state.template.Attachments, action.attachment]
                }
            };
        }

        case GEOVIS_MESSAGE_TEMPLATE_REMOVE_DIRTY_ATTACHMENT:
            if (!action.attachmentId) {
                return state;
            }

            return {
                ...state,
                template: {
                    ...state.template,
                    Attachments: state.template.Attachments.filter(a => !(a.FileId === action.attachmentId && a.IsDirty))
                }
            };

        case GEOVIS_MESSAGE_TEMPLATE_MARK_ATTACHMENT_TO_REMOVE:
            if (!action.attachmentId) {
                return state;
            }

            return {
                ...state,
                template: {
                    ...state.template,
                    Attachments: updateElementsOfArrayIf(state.template.Attachments, a => a.FileId === action.attachmentId, { IsDeleted: true })
                }
            };
    }

    return state;
};


export default messageTemplateViewStateReducer;