import i18next from 'i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { IS_DEVELOPMENT } from './ApiConfig';

export interface ILanguageProps {
    language: string;
    code: string;
}

export const AllowedLanguages: ILanguageProps[] = [
    { language: "English", code: "en" },
    { language: "German", code: "de" },
    { language: "French", code: "fr" },
    { language: "Italian", code: "it" }
];

// const getAllowedLanguageCodes = () => AllowedLanguages.map(l => l.code);

i18next.use(HttpBackend)
    .use(initReactI18next)
    .init<HttpBackendOptions>({
        debug: IS_DEVELOPMENT,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            format(value: any, format: any, lng: any) {
                if (format === 'uppercase') { return value ? value.toUpperCase() : value }
                if (format === 'lowercase') { return value ? value.toLowerCase() : value }
                return value;
            }
        },
        lng: localStorage.getItem("id_language") || "en",
        /*react: {            
            wait: true
        },*/
        returnEmptyString: false,
        returnNull: false,
        load: 'all',
        // preload: getAllowedLanguageCodes()
    });

export default i18next;

export const t = (key: string): string => {
    return i18next.t(key);
}