//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ProjectType {
	None = 0,
	Manual = 1,
	Automatic = 2
}

const ProjectTypeToName = new Map<number, string>([
	[ 0, 'None' ],
	[ 1, 'Manual' ],
	[ 2, 'Automatic' ],
])

export function getProjectTypeToName(element: ProjectType) : string {
    return ProjectTypeToName.get(element) || '';
}

const ProjectTypeByName = new Map<string, number>([
	[ 'None', 0 ],
	[ 'Manual', 1 ],
	[ 'Automatic', 2 ],
]);

export function getProjectTypeByName(name: string): ProjectType {
    return ProjectTypeByName.get(name)!;
}


export const ProjectTypeList : Readonly<ProjectType[]> = [
	ProjectType.None,
	ProjectType.Manual,
	ProjectType.Automatic,
];



const ProjectTypeToDescription = new Map<number, string>([
	[ 0, '' ],
	[ 1, 'Manual' ],
	[ 2, 'Automatic' ],
])

export function getProjectTypeToDescription(element: ProjectType) : string {
    return ProjectTypeToDescription.get(element) || '';
}

