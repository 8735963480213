/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.07.2023
 * @description The host component to render geovis report elements on the page
 */

import { Fragment } from "react";
import { GeovisReportElementInfo } from "../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo";
import { IReportElementRenderHostOwnProp } from "./types";
import { GeovisReportPageRenderProjectElement } from "./GeovisReportPageRender.ProjectElement";


interface IComponentProps extends Omit<IReportElementRenderHostOwnProp, 'elementInfo' | 'isDefault' | 'isVibrationEventChart' | 'eventInfo' | 'elementIndexOnPage' | 'lastInGroup' | 'isInGroup'> {
    elements: GeovisReportElementInfo[];
    rootContainerWidth: number;
}

const Component = ({ rootContainerWidth, elements, ...restProps }: IComponentProps) => (
    <Fragment>
        {elements.map((element, elementIndex, collection) => {

            const isLast = collection.length === elementIndex - 1;

            return (
                <GeovisReportPageRenderProjectElement
                    key={`geovis-report-elements-${restProps.pageNum}-children-${element.Id}-${element.ElementType}-${elementIndex}`}
                    elementInfo={element}
                    elementIndexOnPage={elementIndex}
                    lastInGroup={isLast}
                    isInGroup={false}
                    allContainerWidth={rootContainerWidth}
                    rootContainerWidth={rootContainerWidth}
                    {...restProps}
                />
            )
        })}
    </Fragment>
)

export default Component;