//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AutoRefreshInterval {
	NoRefresh = 0,
	Interval10s = 1,
	Interval20s = 2,
	Interval30s = 3,
	Interval1min = 4,
	Interval2min = 5,
	Interval5min = 6
}

const AutoRefreshIntervalToName = new Map<number, string>([
	[ 0, 'NoRefresh' ],
	[ 1, 'Interval10s' ],
	[ 2, 'Interval20s' ],
	[ 3, 'Interval30s' ],
	[ 4, 'Interval1min' ],
	[ 5, 'Interval2min' ],
	[ 6, 'Interval5min' ],
])

export function getAutoRefreshIntervalToName(element: AutoRefreshInterval) : string {
    return AutoRefreshIntervalToName.get(element) || '';
}

const AutoRefreshIntervalByName = new Map<string, number>([
	[ 'NoRefresh', 0 ],
	[ 'Interval10s', 1 ],
	[ 'Interval20s', 2 ],
	[ 'Interval30s', 3 ],
	[ 'Interval1min', 4 ],
	[ 'Interval2min', 5 ],
	[ 'Interval5min', 6 ],
]);

export function getAutoRefreshIntervalByName(name: string): AutoRefreshInterval {
    return AutoRefreshIntervalByName.get(name)!;
}


export const AutoRefreshIntervalList : Readonly<AutoRefreshInterval[]> = [
	AutoRefreshInterval.NoRefresh,
	AutoRefreshInterval.Interval10s,
	AutoRefreshInterval.Interval20s,
	AutoRefreshInterval.Interval30s,
	AutoRefreshInterval.Interval1min,
	AutoRefreshInterval.Interval2min,
	AutoRefreshInterval.Interval5min,
];



const AutoRefreshIntervalToDescription = new Map<number, string>([
	[ 0, 'No refresh' ],
	[ 1, '10s' ],
	[ 2, '20s' ],
	[ 3, '30s' ],
	[ 4, '1min' ],
	[ 5, '2min' ],
	[ 6, '5min' ],
])

export function getAutoRefreshIntervalToDescription(element: AutoRefreshInterval) : string {
    return AutoRefreshIntervalToDescription.get(element) || '';
}

