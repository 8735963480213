/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 27.01.2023
 * @description The button to show a dialog to register the user feedback
 */

import FeedbackIcon from '@atlaskit/icon/glyph/feedback'
import Button from '@atlaskit/button';
import { Fragment } from 'react';
import { useGeovisDialogVisibilityHook } from '../../../helpers/Hooks.Toggles';
import { UserFeedbackModalDialog } from './dialog/UserFeedbackModalDialog';
import { t } from '../../../i18n';

interface IComponentProps {
    buttonText: string;
    feedbackTitle: string;
}

export const UserFeedbackButton = ({
    buttonText,
    feedbackTitle
}: IComponentProps) => {

    const [isShowDialog, onShowDialog, onHideDialog] = useGeovisDialogVisibilityHook(false);

    return (
        <Fragment>
            <Button
                title={t("Submit user feedback")}
                iconBefore={<FeedbackIcon label={""} />}
                onClick={onShowDialog}>
                {buttonText}
            </Button>

            {isShowDialog && (
                <UserFeedbackModalDialog
                    feedbackTitle={feedbackTitle}
                    onCancel={onHideDialog}
                />
            )}
        </Fragment>
    )
}