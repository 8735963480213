import Checkbox from "@atlaskit/checkbox";
import DynamicTable from "@atlaskit/dynamic-table";
import { HeadType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import { Radio } from '@atlaskit/radio';
import Textfield from "@atlaskit/textfield";
import { SyntheticEvent } from "react";
import { t } from "../../../../i18n";
import { DtsChartSettings } from "../../../../server/AVTService/TypeLibrary/DtsConfiguration/DtsChartSettings";
import { TimeValueChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel";
import { DtsSectionInfoSlim } from "../../../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfoSlim";

interface IComponentProps {
    isTemplate: boolean;
    chartDtsInfo: DtsChartSettings;
    DtsSections: DtsSectionInfoSlim[]
    onChartPropertyChanged: <TValue extends unknown>(propertyName: keyof TimeValueChartModel, value: TValue) => void
}

export const TimeValueChartDtsSectionsControl = ({ chartDtsInfo, DtsSections, onChartPropertyChanged, isTemplate }: IComponentProps) => {

    const getTableHead = (): HeadType => {
        return ({
            cells: [{
                key: 'use_col',
                content: t("Use"),
                width: 5
            }, {
                key: 'name_col',
                content: t("Name")
            }]
        })
    }

    const getTableRows = (): RowType[] => {
        return DtsSections.map(section => ({
            cells: [{
                key: 'use_col',
                content: (
                    <Checkbox
                        onChange={onSectionSelectionChanged(section.Id)}
                        isChecked={getSectionSelected(section.Id)}
                    />
                )
            }, {
                key: 'name_col',
                content: (
                    <span>{section.Name}</span>
                )
            }]
        }))
    }

    const onSectionSelectionChanged = (sectionId: string) => (event: SyntheticEvent<HTMLInputElement>) => {
        const newDtsSettings: DtsChartSettings = { ... new DtsChartSettings(), ...chartDtsInfo };
        if (event.currentTarget.checked && !newDtsSettings.DtsSectionIds.includes(sectionId)) {
            newDtsSettings.DtsSectionIds.push(sectionId);
        }
        else if (!event.currentTarget.checked && newDtsSettings.DtsSectionIds.includes(sectionId)) {
            newDtsSettings.DtsSectionIds = newDtsSettings.DtsSectionIds.filter(sId => sId !== sectionId);
        }

        onChartPropertyChanged('DtsSettings', newDtsSettings);
    }

    const getSectionSelected = (sectionId: string): boolean => {
        return chartDtsInfo.DtsSectionIds.includes(sectionId);
    }

    const onBooleanValueChanged = (useCloses: boolean) => (event: SyntheticEvent<HTMLInputElement>) => {
        const useClosestTunnelmeter = useCloses ? event.currentTarget.checked : !event.currentTarget.checked;
        const newDtsSettings: DtsChartSettings = { ... new DtsChartSettings(), ...chartDtsInfo, UseClosestTunnelmeter: useClosestTunnelmeter };
        onChartPropertyChanged('DtsSettings', newDtsSettings);
    }

    const onTextPropertyChangeHandler = (keyName: keyof (DtsChartSettings)) => (event: SyntheticEvent<HTMLInputElement>) => {
        const newDtsSettings: DtsChartSettings = { ... new DtsChartSettings(), ...chartDtsInfo };
        switch (keyName) {
            case "ClosestTunnelmeter":
                newDtsSettings.ClosestTunnelmeter = +event.currentTarget.value;
                break;
            case "FromTunnelmeter":
                newDtsSettings.FromTunnelmeter = +event.currentTarget.value;
                break;
            case "ToTunnelmeter":
                newDtsSettings.ToTunnelmeter = +event.currentTarget.value;
                break;
        }
        onChartPropertyChanged('DtsSettings', newDtsSettings);
    }

    return (
        <div className="flexRowContainer" style={{ marginTop: '10px', flexDirection: 'column' }}>
            <div className="flexRowContainer">{t("Specify the tunnel meter where DTS Sensors should be shown")}:</div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center' }}>
                <Radio
                    isChecked={chartDtsInfo.UseClosestTunnelmeter}
                    onChange={onBooleanValueChanged(true)}
                />
                <span style={{ width: '70px' }}>{t("Closets to")}: </span>
                <div style={{ width: '100px' }}>
                    <Textfield
                        isCompact={true}
                        type='number'
                        width='100px'
                        onChange={onTextPropertyChangeHandler("ClosestTunnelmeter")}
                        defaultValue={chartDtsInfo.ClosestTunnelmeter}
                    />
                </div>

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', marginTop: '5px', alignItems: 'center' }}>
                <Radio
                    isChecked={!chartDtsInfo.UseClosestTunnelmeter}
                    onChange={onBooleanValueChanged(false)}
                />
                <span style={{ width: '50px' }}>{t("From")}: </span>
                <div style={{ width: '100px' }}>
                    <Textfield
                        isCompact={true}
                        type='number'
                        width='100px'
                        onChange={onTextPropertyChangeHandler("FromTunnelmeter")}
                        defaultValue={chartDtsInfo.FromTunnelmeter}
                    />
                </div>

                <span style={{ marginLeft: '5px', width: '30px' }}>{t(" To")}: </span>
                <div style={{ width: '100px' }}>
                    <Textfield
                        isCompact={true}
                        type='number'
                        width='100px'
                        onChange={onTextPropertyChangeHandler("ToTunnelmeter")}
                        defaultValue={chartDtsInfo.ToTunnelmeter}
                    />
                </div>

            </div>
            {!isTemplate && <div className="flexRowContainer" style={{ marginTop: '10px' }}>{t("DTS Sections")}</div>}
            {!isTemplate &&
                <DynamicTable
                    head={getTableHead()}
                    rows={getTableRows()}
                />
            }
        </div>
    )
}