/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 07.02.2023
 * @description The user feedback modal dialog
 */


import Modal, { ModalFooter, ModalTransition } from "@atlaskit/modal-dialog"
import Button, { LoadingButton } from "@atlaskit/button";
import { t } from "../../../../i18n";
import { isAllowSubmitUserFeedback } from "./tools";
import UserFeedbackDataLayer from "./UserFeedbackDataLayer";
import UserFeedbackDialogView from "./UserFeedbackDialogView";
import FeedbackAttachmentsUploadControl from "./FeedbackAttachmentsUploadControl";

interface IComponentProps {
    feedbackTitle: string;
    onCancel: () => void;
}

export const UserFeedbackModalDialog = ({
    feedbackTitle,
    onCancel
}: IComponentProps) => (
    <UserFeedbackDataLayer initialFeedbackTitle={feedbackTitle} onCancel={onCancel}>
        {({ onSubmit, onCancel: onCancelInline, isInProgress, onAddAttachment, ...viewProps }) => (
            <ModalTransition>
                <Modal
                    heading={t("Submit user feedback")}
                    components={{
                        Footer: () =>
                            <ModalFooter>
                                <div style={{ display: 'inline-flex', width: '100%' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', marginLeft: '5px' }}>
                                        <FeedbackAttachmentsUploadControl onAddAttachment={onAddAttachment} alreadyUploadedAttachments={viewProps.model.AttachmentIds.length} />
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginRight: '5px' }}>
                                        <LoadingButton onClick={onSubmit} appearance='primary' style={{ marginRight: '5px' }} isDisabled={!isAllowSubmitUserFeedback(viewProps.model)} isLoading={isInProgress}>
                                            {t("Submit")}
                                        </LoadingButton>
                                        <Button onClick={onCancelInline} appearance='default'>
                                            {t("Close")}
                                        </Button>
                                    </div>

                                </div>
                            </ModalFooter>
                    }}>

                    <UserFeedbackDialogView {...viewProps} />
                </Modal>
            </ModalTransition>
        )}
    </UserFeedbackDataLayer>
)