//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisAlarmTemplateModel } from '../../../AVTService/TypeLibrary/Model/GeovisAlarmTemplateModel';
import { GeovisAlarmSensor } from './GeovisAlarmSensor';

export class GeovisAlarmModel extends GeovisAlarmTemplateModel
{
	public LabelsIds: string[];
	public MaxTriggerCount: number;
	public IsActive: boolean;
	public IsTriggered: boolean;
	public Sensors: GeovisAlarmSensor[];
	public ProjectId: number;
	public CanBeActivated: boolean;
}
