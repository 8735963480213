import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { SyntheticEvent, useRef, useState } from "react";
import { debounce } from "lodash";
import Textfield from "@atlaskit/textfield";
import { HeadType, RowType, SortOrderType } from '@atlaskit/dynamic-table/dist/types/types';
import { t } from 'i18next';
import { IGeovisProjectUsersStorage } from "../../../../../store/data.types";
import { GeovisUserCommonInfo } from "../../../../../server/GEOvis3/Model/User/GeovisUserCommonInfo";
import { GeovisTableWithSelectableRows } from '../../../../../components/DynamicTableWithSelectableRows';
import { isMatchToSearchString } from '../../../../../helpers/FiltersHelper';
import { CompanyInfo } from '../../../../../server/GEOvis3/Model/Company/CompanyInfo';
import { mapToListOfElements } from '../../../../../helpers/StorageHelper';

interface IComponentProps {
    projectUsersStorage: IGeovisProjectUsersStorage;
    addedUsersIds: string[];
    companies: CompanyInfo[];

    onClose: () => void;
    onAdd: (usersIds: string[]) => void;
}

interface IComponentState {
    addedUsersIds: string[];
    selectedUsers: GeovisUserCommonInfo[];
    filter: string;
    sortOrder: SortOrderType;
    sortKey: string;
}

export const AlarmActionAddUserDialog = ({
    addedUsersIds,
    onAdd,
    onClose,
    projectUsersStorage,
    companies
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        addedUsersIds,
        selectedUsers: mapToListOfElements(projectUsersStorage.users).filter(u => addedUsersIds.includes(u.Id)),
        filter: "",
        sortKey: 'name',
        sortOrder: "ASC"
    })

    const searchField = useRef<HTMLInputElement>(null);

    const onClearFilter = () => {
        if (searchField.current !== null) {
            searchField.current.value = "";
        }

        setState({
            ...state,
            filter: ""
        })
    }

    const onFilterChanged = (event: SyntheticEvent<HTMLInputElement>) => {
        onFilterChangedDebounced(event.currentTarget.value);
    }

    const onFilterChangedDebounced = debounce(value => {
        setState({
            ...state,
            filter: value
        })
    }, 400);

    const getUserName = (user: GeovisUserCommonInfo): string => {
        return `${user.Name}, ${user.Forename}`;
    }

    const getUserCompanyName = (userCompanyId: string): string => {
        const company = companies.find(c => c.Id === userCompanyId);

        if (!company) {
            return t("Unknown company");
        }

        return company.Name;
    }

    const userMatchFilter = (user: GeovisUserCommonInfo): boolean => {
        if (state.filter === "") {
            return true;
        }

        const company = companies.find(c => c.Id === user.CompanyId);

        if (!company) {
            return false;
        }

        return isMatchToSearchString(getUserName(user), state.filter) || isMatchToSearchString(user.Email, state.filter) || isMatchToSearchString(company.Name, state.filter);
    }

    const getAllItems = (): GeovisUserCommonInfo[] => {
        return mapToListOfElements(projectUsersStorage.users)
            .filter(u => userMatchFilter(u))
            .sort((u1, u2) => sortingFunc(u1, u2, state.sortKey, state.sortOrder));
    }

    const getIdFunc = (user: GeovisUserCommonInfo): string => {
        return user.Id;
    }

    const itemToRowFunc = (user: GeovisUserCommonInfo): RowType => {
        return ({
            key: `row-${user.Id}`,
            cells: [{
                key: 'name',
                content: (
                    <span>{getUserName(user)}</span>
                )
            }, {
                key: 'email',
                content: (
                    <span>{user.Email}</span>
                )
            }, {
                key: 'phone',
                content: (
                    <span>{user.PhoneNumber}</span>
                )
            }, {
                key: 'company',
                content: (
                    <span>{getUserCompanyName(user.CompanyId)}</span>
                )
            }]
        })
    }

    const onSelectionChanged = (selection: GeovisUserCommonInfo[]) => {
        setState({
            ...state,
            selectedUsers: selection
        })
    }

    const onSelectDeselectAll = (allSelected: boolean) => {

        const allAvailableItems = getAllItems();

        if (allSelected) {
            allAvailableItems.forEach(item => {
                const selectedUser = state.selectedUsers.find(u => u.Id === item.Id);
                if (!selectedUser) {
                    state.selectedUsers.push(item);
                }
            })
            setState({
                ...state,
                selectedUsers: state.selectedUsers
            });
        }
        else {
            setState({
                ...state,
                selectedUsers: state.selectedUsers.filter(u => !allAvailableItems.includes(u))
            });
        }
    }

    const getHead = (): HeadType => {
        return ({
            cells: [{
                key: 'name',
                isSortable: true,
                width: 24,
                content: t("Name")
            }, {
                key: 'email',
                isSortable: true,
                width: 24,
                content: t("Email")
            }, {
                key: 'phone',
                isSortable: true,
                width: 24,
                content: t("Phone")
            }, {
                key: 'company',
                isSortable: true,
                width: 24,
                content: t("Company")
            }]
        })
    }

    const sortingFunc = (itemA: GeovisUserCommonInfo, itemB: GeovisUserCommonInfo, sortKey: string, sortOrder: string): number => {

        let valueToCompareA = "";
        let valueToCompareB = "";

        switch (sortKey) {
            case "name": {
                valueToCompareA = getUserName(itemA);
                valueToCompareB = getUserName(itemB);
                break;
            }
            case "email": {
                valueToCompareA = itemA.Email;
                valueToCompareB = itemB.Email;
                break;
            }
            case "phone": {
                valueToCompareA = itemA.PhoneNumber;
                valueToCompareB = itemB.PhoneNumber;
                break;
            }
            case "company": {
                valueToCompareA = getUserCompanyName(itemA.CompanyId);
                valueToCompareB = getUserCompanyName(itemB.CompanyId);
                break;
            }
        }

        return valueToCompareA === valueToCompareB
            ? 0
            : valueToCompareA > valueToCompareB
                ? sortOrder === 'ASC' ? 1 : -1
                : sortOrder === 'ASC' ? -1 : 1;
    }

    const onAfterSort = (sortKey: string, sortOrder: SortOrderType) => {
        setState({
            ...state,
            sortKey,
            sortOrder
        })
    }

    const onAddHandler = () => {
        onAdd(state.selectedUsers.map(u => u.Id));
    }

    return (
        <ModalTransition>
            <Modal
                autoFocus={false}
                heading={t("Select users")}
                actions={[{
                    text: t("Add users"), onClick: onAddHandler, appearance: 'primary'
                }, {
                    text: t("Close"), onClick: onClose, appearance: 'default'
                }]}
                width={'80%'}
                height={"700px"}
            >
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', gap: '5px' }}>
                    <div style={{ width: '40%' }}>
                        <Textfield
                            defaultValue={state.filter}
                            onChange={onFilterChanged}
                            isCompact={true}
                            elemAfterInput={
                                <div style={{ marginRight: '5px', cursor: 'default' }} onClick={onClearFilter}>
                                    <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                                </div>
                            }
                        />
                    </div>
                    <GeovisTableWithSelectableRows
                        allItems={getAllItems()}
                        getIdFunc={getIdFunc}
                        isLoading={false}
                        itemToRowFunc={itemToRowFunc}
                        needSelectAll={true}
                        onSelectionChanged={onSelectionChanged}
                        selectedItems={state.selectedUsers}
                        onSelectDeselectAll={onSelectDeselectAll}
                        head={getHead()}
                        defaultSortKey={state.sortKey}
                        defaultSortOrder={state.sortOrder}
                        sortingFunc={sortingFunc}
                        onAfterSort={onAfterSort}
                    />
                </div>
            </Modal>
        </ModalTransition>
    )
}