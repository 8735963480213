//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AlarmChartValueType {
	Chart1 = 0,
	Chart2 = 1,
	Chart3 = 2,
	AllCharts = 50
}

const AlarmChartValueTypeToName = new Map<number, string>([
	[ 0, 'Chart1' ],
	[ 1, 'Chart2' ],
	[ 2, 'Chart3' ],
	[ 50, 'AllCharts' ],
])

export function getAlarmChartValueTypeToName(element: AlarmChartValueType) : string {
    return AlarmChartValueTypeToName.get(element) || '';
}

const AlarmChartValueTypeByName = new Map<string, number>([
	[ 'Chart1', 0 ],
	[ 'Chart2', 1 ],
	[ 'Chart3', 2 ],
	[ 'AllCharts', 50 ],
]);

export function getAlarmChartValueTypeByName(name: string): AlarmChartValueType {
    return AlarmChartValueTypeByName.get(name)!;
}


export const AlarmChartValueTypeList : Readonly<AlarmChartValueType[]> = [
	AlarmChartValueType.Chart1,
	AlarmChartValueType.Chart2,
	AlarmChartValueType.Chart3,
	AlarmChartValueType.AllCharts,
];



const AlarmChartValueTypeToDescription = new Map<number, string>([
	[ 0, 'Chart 1' ],
	[ 1, 'Chart 2' ],
	[ 2, 'Chart 3' ],
	[ 50, 'All Charts' ],
])

export function getAlarmChartValueTypeToDescription(element: AlarmChartValueType) : string {
    return AlarmChartValueTypeToDescription.get(element) || '';
}

