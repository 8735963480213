import { t } from "../../../../i18n";
import { InclinometerChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { ChartBooleanSettingProps } from "../ChartTab_tools";
import { IGeovisChartPropertyChangedProps } from "../types";

interface IInclinometerChartOptionSettingsProps extends IGeovisChartPropertyChangedProps<InclinometerChartModel> {
    chart: InclinometerChartModel;
}

export const InclinometerChartOptionSettings = ({
    chart,
    onChartPropertyChanged
}: IInclinometerChartOptionSettingsProps) => {
    const onChartPropertyChangedFunc = (propertyName: keyof InclinometerChartModel) => (value: any) => {
        onChartPropertyChanged(propertyName, value);
    }

    return (
        <div>
            <ChartBooleanSettingProps
                label={t("Show date of reference measurements")}
                isChecked={chart.ShowReferenceDate}
                onChange={onChartPropertyChangedFunc("ShowReferenceDate")}
                isDisabled={false} />
        </div>
    )
}