import L from 'leaflet';

var originalInitTile = L.GridLayer.prototype._initTile

L.GridLayer.include({
    _initTile: function (tile) {
        originalInitTile.call(this, tile);

        var tileSize = this.getTileSize();

        tile.style.width = tileSize.x + 0.5 + 'px';
        tile.style.height = tileSize.y + 0.5 + 'px';
    }
});