/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 28.10.2022
 * @description Geovis4Table render types for HotTable
 */

import Handsontable from "handsontable";

export const HandsonTableLicenseKey = "non-commercial-and-evaluation";

export const TABLE_HEADER_HEIGHT = 30;
export const DEFAULT_ROW_HEIGHT = 30;

export enum HotTableRenderMode {
    CONSTRUCTOR,
    PREVIEW,
    REPORT,
    PDF
}

/**
 * Common settings for Geovis4Table handsonTable component
 */
export const geovis4TableHandsonTableCommonSettings: Handsontable.GridSettings = {
    licenseKey: HandsonTableLicenseKey,
    readOnly: true
};