/**
 * Global actions in the application
 */

// login actions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_IN_PROGRESS = "LOGIN_IN_PROGRESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_OUT = "LOGIN_OUT";
export const LOGIN_MESSAGE_LANGUAGE = "LOGIN_MESSAGE_LANGUAGE";

export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_IN_PROGRESS = "RESET_PASSWORD_IN_PROGRESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

// projects overview navigation
export const PROJECTS_OVERVIEW = "PROJECTS_OVERVIEW";
export const PROJECTS_OVERVIEW_DATA = "PROJECTS_OVERVIEW_DATA";
export const PROJECTS_OVERVIEW_UPDATE_PROJECT = "PROJECTS_OVERVIEW_UPDATE_PROJECT";
export const SHOW_PROJECT_NO_PERMISSIONS_DIALOG = "SHOW_PROJECT_NO_PERMISSIONS_DIALOG";
export const HIDE_PROJECT_NO_PERMISSIONS_DIALOG = "HIDE_PROJECT_NO_PERMISSIONS_DIALOG";

// other service actions, TODO: need to check them later
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const SERVICES_ADD_FLAG = "SERVICES_ADD_FLAG";
export const SERVICES_CLEAR_FLAGS = "SERVICES_CLEAR_FLAGS";

export const SWITCH_PROJECTS_OVERVIEW = "SWITCH_PROJECTS_OVERVIEW";

export const PROJECTS_OVERVIEW_SEARCH_QUERY = "PROJECTS_OVERVIEW_SEARCH_QUERY";

export const CHANGE_MAP_BASE_LAYER = "CHANGE_MAP_BASE_LAYER";

export const NAVIGATION_UPDATE_USER_VIEW_MODE = "NAVIGATION_UPDATE_USER_VIEW_MODE";
export const REFRESH_NAVIGATION_STATE = "REFRESH_NAVIGATION_STATE";

export const NAVIGATION_FEEDBACK_STATISTIC_DATA = 'NAVIGATION_FEEDBACK_STATISTIC_DATA';