/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 26.10.2020
 * @description Inventory page actions
 */


export const GEOVIS_INVENTORY_RESET_STORAGE = 'GEOVIS_INVENTORY_RESET_STORAGE';
export const GEOVIS_INVENTORY_DATA_LOADING = "GEOVIS_INVENTORY_DATA_LOADING";
export const GEOVIS_INVENTORY_DATA_LOADED = "GEOVIS_INVENTORY_DATA_LOADED";
export const GEOVIS_INVENTORY_DATA_ERROR = "GEOVIS_INVENTORY_DATA_ERROR";

export const GEOVIS_INVENTORY_SHOW_EDIT_DIALOG = 'GEOVIS_INVENTORY_SHOW_EDIT_DIALOG';
export const GEOVIS_INVENTORY_SHOW_EDIT_DIALOG_BY_UP_ID = 'GEOVIS_INVENTORY_SHOW_EDIT_DIALOG_BY_UP_ID';
export const GEOVIS_INVENTORY_SHOW_REMOVE_DIALOG = 'GEOVIS_INVENTORY_SHOW_REMOVE_DIALOG';
export const GEOVIS_INVENTORY_UPDATE = 'GEOVIS_INVENTORY_UPDATE'
export const GEOVIS_INVENTORY_REMOVE = 'GEOVIS_INVENTORY_REMOVE';
export const GEOVIS_INVENTORY_SHOW_CREATE = 'GEOVIS_INVENTORY_SHOW_CREATE';
export const GEOVIS_INVENTORY_ADD = 'GEOVIS_INVENTORY_ADD';

export const GEOVIS_INVENTORY_SET_UPLOAD_STATE = 'GEOVIS_INVENTORY_SET_UPLOAD_STATE';
export const GEOVIS_INVENTORY_SET_OBJECT_TYPE = 'GEOVIS_INVENTORY_SET_OBJECT_TYPE';
export const GEOVIS_INVENTORY_HIDE_DIALOG = 'GEOVIS_INVENTORY_HIDE_DIALOG';

export const GEOVIS_INVENTORY_DESCRIPTIONS_LOADED = "GEOVIS_INVENTORY_DESCRIPTIONS_LOADED";
export const GEOVIS_INVENTORY_DESCRIPTIONS_ERROR = "GEOVIS_INVENTORY_DESCRIPTIONS_ERROR";

export const GEOVIS_INVENTORY_NODE_EXPANDED = "GEOVIS_INVENTORY_NODE_EXPANDED";
export const GEOVIS_INVENTORY_NODE_COLLAPSED = "GEOVIS_INVENTORY_NODE_COLLAPSED";

export const GEOVIS_INVENTORY_CHANGED_VIEW = "GEOVIS_INVENTORY_CHANGED_VIEW";

export const GEOVIS_INVENTORY_PARENTS_LOADED = "GEOVIS_INVENTORY_PARENTS_LOADED";
export const GEOVIS_INVENTORY_PROJECT_ID_CHANGED = "GEOVIS_INVENTORY_PROJECT_ID_CHANGED";

export const GEOVIS_INVENTORY_USAGE_PLAN_RECORDS = "GEOVIS_INVENTORY_USAGE_PLAN_RECORDS";
export const GEOVIS_INVENTORY_USAGE_PLAN_RECORDS_DATA = "GEOVIS_INVENTORY_USAGE_PLAN_RECORDS_DATA";

export const GEOVIS_INVENTORY_CALENDAR_VIEW_LOADING = 'GEOVIS_INVENTORY_CALENDAR_VIEW_LOADING';
export const GEOVIS_INVENTORY_CALENDAR_VIEW_DATA = 'GEOVIS_INVENTORY_CALENDAR_VIEW_DATA';
export const GEOVIS_INVENTORY_CALENDAR_FILTERS = 'GEOVIS_INVENTORY_CALENDAR_FILTERS';

export const GEOVIS_INVENTORY_CALENDAR_SHOW_UP_ACTION_DIALOG = 'GEOVIS_INVENTORY_CALENDAR_SHOW_UP_ACTION_DIALOG';
export const GEOVIS_INVENTORY_CALENDAR_HIDE_UP_ACTION_DIALOG = 'GEOVIS_INVENTORY_CALENDAR_HIDE_UP_ACTION_DIALOG';

export const GEOVIS_INVENTORY_CALENDAR_SHOW_OPEN_PERIOD = 'GEOVIS_INVENTORY_CALENDAR_SHOW_OPEN_PERIOD';

// UP -> Usage Plan
// actions to show a list of Usage Plan records
/** Show the list view of inventory usage plans */
export const GEOVIS_INVENTORY_UP_SHOW_LIST_VIEW = "GEOVIS_INVENTORY_UP_SHOW_LIST_VIEW";
export const GEOVIS_INVENTORY_UP_CLOSE_LIST_VIEW = "GEOVIS_INVENTORY_UP_CLOSE_LIST_VIEW";

export const GEOVIS_INVENTORY_UP_SHOW_CONFIRM_CHANGE_STATE_DIALOG = 'GEOVIS_INVENTORY_UP_SHOW_CONFIRM_CHANGE_STATE_DIALOG';
export const GEOVIS_INVENTORY_UP_HIDE_CONFIRM_CHANGE_STATE_DIALOG = 'GEOVIS_INVENTORY_UP_HIDE_CONFIRM_CHANGE_STATE_DIALOG';

export const GEOVIS_INVENTORY_UP_SHOW_EXTEND_TIME_RANGE_DIALOG = 'GEOVIS_INVENTORY_UP_SHOW_EXTEND_TIME_RANGE_DIALOG';
export const GEOVIS_INVENTORY_UP_HIDE_EXTEND_TIME_RANGE_DIALOG = 'GEOVIS_INVENTORY_UP_HIDE_EXTEND_TIME_RANGE_DIALOG';

export const GEOVIS_INVENTORY_UP_UPDATE_RECORDS = 'GEOVIS_INVENTORY_UP_UPDATE_RECORDS';

// actions to show an edit one usage plan record
export const GEOVIS_INVENTORY_UP_SHOW_EDIT_DIALOG = "GEOVIS_INVENTORY_UP_SHOW_EDIT_DIALOG";
export const GEOVIS_INVENTORY_UP_CLOSE_EDIT_DIALOG = "GEOVIS_INVENTORY_UP_CLOSE_EDIT_DIALOG";
export const GEOVIS_INVENTORY_UP_SAVE_RECORDS = 'GEOVIS_INVENTORY_UP_SAVE_RECORDS'; // Create or Update the UsagePlanRecord records after dialog

// actions to show the remove Usage Plan record dialog
export const GEOVIS_INVENTORY_UP_SHOW_REMOVE_DIALOG = "GEOVIS_INVENTORY_UP_SHOW_REMOVE_DIALOG";
export const GEOVIS_INVENTORY_UP_CLOSE_REMOVE_DIALOG = "GEOVIS_INVENTORY_UP_CLOSE_REMOVE_DIALOG";
export const GEOVIS_INVENTORY_UP_REMOVE_RECORD = "GEOVIS_INVENTORY_UP_REMOVE_RECORD";

export const GEOVIS_INVENTORY_RV_SHOW_EDIT_DIALOG = "GEOVIS_INVENTORY_RV_SHOW_EDIT_DIALOG";
export const GEOVIS_INVENTORY_RV_HIDE_EDIT_DIALOG = "GEOVIS_INVENTORY_RV_HIDE_EDIT_DIALOG";

export const GEOVIS_INVENTORY_RV_SHOW_REMOVE_DIALOG = "GEOVIS_INVENTORY_RV_SHOW_REMOVE_DIALOG";
export const GEOVIS_INVENTORY_RV_HIDE_REMOVE_DIALOG = "GEOVIS_INVENTORY_RV_HIDE_REMOVE_DIALOG";

export const GEOVIS_INVENTORY_RV_SHOW_TO_PROJECT_DIALOG = "GEOVIS_INVENTORY_RV_SHOW_TO_PROJECT_DIALOG";
export const GEOVIS_INVENTORY_RV_HIDE_TO_PROJECT_DIALOG = "GEOVIS_INVENTORY_RV_HIDE_TO_PROJECT_DIALOG";

export const GEOVIS_INVENTORY_RV_LOADING = "GEOVIS_INVENTORY_RV_LOADING";
export const GEOVIS_INVENTORY_RV_LOADED = "GEOVIS_INVENTORY_RV_LOADED";

export const GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING = "GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING";
export const GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADED = "GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADED";
export const GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING_ERROR = "GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING_ERROR";

export const GEOVIS_INVENTORY_CALENDAR_UP_SHOW_EDIT = "GEOVIS_INVENTORY_CALENDAR_UP_SHOW_EDIT";
export const GEOVIS_INVENTORY_CALENDAR_SHOW_RESERVATION_REMOVE = "GEOVIS_INVENTORY_CALENDAR_SHOW_RESERVATION_REMOVE";
export const GEOVIS_INVENTORY_CALENDAR_HIDE_RESERVATION_REMOVE = "GEOVIS_INVENTORY_CALENDAR_HIDE_RESERVATION_REMOVE";

export const GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_SHOW = "GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_SHOW";
export const GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_HIDE = "GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_HIDE";
export const GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING = "GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING";
export const GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING_ERROR = "GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING_ERROR";
export const GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADED = "GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADED";

export const GEOVIS_INVENTORY_UPDATE_RESERVATIONS = 'GEOVIS_INVENTORY_UPDATE_RESERVATIONS';

// actions to show expand dialog
export const GEOVIS_INVENTORY_EXPAND_DIALOG_SHOW = "GEOVIS_INVENTORY_EXPAND_DIALOG_SHOW";
export const GEOVIS_INVENTORY_EXPAND_DIALOG_HIDE = "GEOVIS_INVENTORY_EXPAND_DIALOG_HIDE";
export const GEOVIS_INVENTORY_EXPAND_DIALOG_COMPLITE = "GEOVIS_INVENTORY_EXPAND_DIALOG_COMPLITE";


// actions to show change state items dialog
export const GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_SHOW = "GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_SHOW";
export const GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_HIDE = "GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_HIDE";
export const GEOVIS_INVENTORY_CHANGE_STATE_DIALOG_COMPLITE = "GEOVIS_INVENTORY_CHANGE_STATE_DIALOG_COMPLITE";

export const GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_SHOW = "GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_SHOW";
export const GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_HIDE = "GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_HIDE";

export const GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_SHOW = "GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_SHOW";
export const GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_HIDE = "GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_HIDE";

// actions to show move to storage dialog
export const GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_SHOW = "GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_SHOW";
export const GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_HIDE = "GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_HIDE";

export const GEOVIS_INVENTORY_PROJECTS_LOADING = 'GEOVIS_INVENTORY_PROJECTS_LOADING';
export const GEOVIS_INVENTORY_PROJECTS = 'GEOVIS_INVENTORY_PROJECTS';