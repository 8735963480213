/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 15.08.2023
 * @description Geovis image render map
 * It renders only one map with sensors and legend
 */

import { useState } from "react";
import { IChainsInfoStorage, IChainsLastDataStorage, ISensorsInfoStorage, ISensorsLastDataStorage, ISensorsSymbolsStorage } from "../../../../../../store/data.types";
import { IGeovisImageReportInfo } from "../../../../../../store/projectReport.types";
import { IReportElementRenderHostOwnProp } from "../../types";
import ElementReportTitle, { TitleComponentHeightPx } from "../../ElementReportTitle";
import { ImageMapView } from "../../../../../imageMap/ImageMapView";
import { LoadingPageSkeleton } from "../../../../../LoadingPageSkeleton";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { SensorCategory } from "../../../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { ISomethingStoreBase } from "../../../../../../store/types";
import { calculateWidthHeightScale } from "../../../../../imageMap/tools";
import { GeovisImageMapLegend } from "../../../../geovisImages/GeovisImage.MapLegend";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";

// const LoggerSource = "GeovisImagesRender";
const IMAGE_CONTAINER_ID = "image_container";

interface IComponentProps extends Omit<IReportElementRenderHostOwnProp, 'eventInfo' | 'isDefault' | 'isVibrationEventChart' | 'showVibrationEventChartOnPage' | 'settingsId' | 'isPrinting' | 'projectId'> {
    geovisImageInfo: IGeovisImageReportInfo;

    sensorsInfoStorage: ISensorsInfoStorage;
    sensorsLastDataStorage: ISensorsLastDataStorage;


    chainsInfoStorage: IChainsInfoStorage;
    chainsLastDataStorage: IChainsLastDataStorage;

    sensorsSymbolsStorage: ISensorsSymbolsStorage;
    visualSettings: ProjectVisualSettings;
}



const Component = ({
    elementIndexOnPage,
    elementInfo,
    geovisImageInfo,
    pageNum,
    chainsInfoStorage,
    sensorsInfoStorage,
    sensorsLastDataStorage,
    chainsLastDataStorage,
    sensorsSymbolsStorage,
    isInGroup,
    lastInGroup,
    userId,
    userToken,
    reportId,
    visualSettings
}: IComponentProps) => {

    const [mapDivRef, setMapDivRef] = useState<HTMLDivElement | null>(null);
    const [isMapReadyState, setIsMapReadyState] = useState<boolean>(false);

    const { GeovisImage } = geovisImageInfo;
    const { Title, Id, ProjectId, ImageInfo } = GeovisImage;

    const { Height, HideTitle } = elementInfo;
    const showTitle = !HideTitle;

    const heightMarginPx = 10; // 5px from top and 5px from bottom
    const initialHeight = Height - heightMarginPx - (showTitle ? TitleComponentHeightPx : 0);

    const isPdfPrintingMode = userToken !== undefined && userId !== undefined;

    const onZoomingFinished = () => {
        const imageContainer = document.getElementById(`${IMAGE_CONTAINER_ID}_${Id}`);

        if (imageContainer) {
            imageContainer.addEventListener('click', (e) => {
                e.preventDefault();
            })
        }
    }

    if (checkIsLoading(chainsInfoStorage, sensorsInfoStorage, sensorsLastDataStorage, chainsLastDataStorage)) {
        return (
            <LoadingPageSkeleton loadingText='Rendering...' />
        )
    }

    const [isError, errorDescription] = checkIsError(chainsInfoStorage, sensorsInfoStorage, sensorsLastDataStorage, chainsLastDataStorage);
    if (isError) {
        return (<LoadingPageErrorSkeleton errorText={errorDescription} />)
    }

    const whenReadyHandler = () => {
        setIsMapReadyState(true);
    }

    const onRenderedHandler = () => {
        // eslint-disable-next-line no-console
        console.info(`GeovisImage was rendered: pageNum=${pageNum}&elementId=${Id}`);
    }

    const elementInfoUntyped: any = { ...elementInfo };
    const widthModifier: number = isInGroup && elementInfoUntyped.width !== undefined && !isNaN(+elementInfoUntyped.width) ? +elementInfoUntyped.width / 100 : 1;
    const containerData = calculateWidthHeightScale(ImageInfo.ViewPort, initialHeight, widthModifier);

    return (
        <div style={{ height: `${Height}px`, width: '100%', borderRight: elementInfo.DrawBorder && !lastInGroup ? '2px solid black' : '' }} >
            <div style={{ display: 'flex', flexDirection: 'column', height: `100%` }}>
                {showTitle && (
                    <ElementReportTitle
                        elementTitle={Title}
                        elementIndexOnPage={elementIndexOnPage}
                        isInGroup={isInGroup}
                        lastInGroup={lastInGroup || elementInfo.DrawBorder}
                        projectId={ProjectId}
                        chartId={0}
                        isPrinting={isPdfPrintingMode}
                        reportId={reportId}
                    />
                )}
                <div style={{ display: 'flex', justifyContent: 'space-around', height: '100%' }}>
                    <div id={`${IMAGE_CONTAINER_ID}_${Id}`} ref={setMapDivRef} style={{ width: `${containerData.width}px`, height: `${containerData.height}px`, alignSelf: 'center' }}>
                        {mapDivRef && (
                            // render the map view when root div has rendered
                            <ImageMapView
                                key={`image-view-${containerData.width}-${containerData.height}`}
                                contentType="GeovisImage"
                                elementId={elementInfo.Id.toString()}
                                projectId={ProjectId}
                                sensorsSymbolsSettings={sensorsSymbolsStorage}
                                chainsInfoStorage={chainsInfoStorage}
                                chainsLastDataStorage={chainsLastDataStorage}
                                sensorsInfoStorage={sensorsInfoStorage}
                                sensorsLastDataStorage={sensorsLastDataStorage}
                                setCoordinatesMode={false}
                                imageInfo={GeovisImage.ImageInfo}
                                // что-то этот трюк я никак не пойму. для чего 2 поля под сенсоры?
                                sensors={GeovisImage.Sensors.filter(s => GeovisImage.SensorsIds.includes(s.FullId))}
                                chains={GeovisImage.Chains}
                                onZoomingFinished={onZoomingFinished}
                                rootContainer={mapDivRef}
                                userHash={userToken}
                                userId={userId}
                                fontSize={GeovisImage.FontSize}
                                disableManipulation={true}
                                pictureXCoordinate={GeovisImage.PictureXCoordinate}
                                pictureYCoordinate={GeovisImage.PictureYCoordinate}
                                showBorderAroundIconText={GeovisImage.UseBordersAroundText}
                                sensorLayerState={{
                                    showSensorNames: GeovisImage.ShowSensorNamesSetting.value,
                                    fontSize: GeovisImage.FontSize,
                                    useGeovis4SensorTypeSymbol: GeovisImage.UseGeovis4SensorTypeSymbolSetting.value,
                                    useSensorColorForText: GeovisImage.UseSensorColorForSensorName,
                                    useSensorColor: GeovisImage.UseSensorColorSetting.value,
                                    visibleSensorTypes: new Map<SensorCategory, boolean>()
                                }}
                                movementSettings={GeovisImage.MovementSettings}
                                isPdfPrintingMode={isPdfPrintingMode}
                                onReadyToDrawLegend={whenReadyHandler}
                                onRendered={onRenderedHandler}>

                                {isMapReadyState &&
                                    <GeovisImageMapLegend
                                        key={`image-legend-${containerData.width}-${containerData.height}`}
                                        geovisImageInfo={geovisImageInfo}
                                        rootContainer={mapDivRef}
                                        visualSettings={visualSettings}
                                    />
                                }

                            </ImageMapView>
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}

const checkIsLoading = (...storages: ISomethingStoreBase[]): boolean => {
    for (const storage of storages) {
        if (storage.isLoading) {
            return true;
        }
    }

    return false;
}

const checkIsError = (...storages: ISomethingStoreBase[]): [boolean, string] => {
    for (const storage of storages) {
        if (storage.isError) {
            return [true, storage.errorDescription];
        }
    }

    return [false, ''];
}


export default Component;