import { OptionType } from "@atlaskit/select";
import { t } from "../../../../i18n";
import { ChainFixedPointList, getChainFixedPointToDescription } from "../../../../server/AVTService/TypeLibrary/Common/ChainFixedPoint";
import { ChartSettingBool } from "../../../../server/AVTService/TypeLibrary/Model/ChartSettingBool";
import { InclinometerChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { InclinometerVisibleRangeSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerVisibleRangeSettings";
import { getPhysicalUnitShortName, PhysicalUnit } from "../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { SensorCategory } from "../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { GeovisSelect } from "../../../select/GeovisSelect";
import AxisScaleLimitControl from "../AxisScaleLimitControl";
import { ChartAxisSensorsControl } from "../ChartAxisSensorsControl";
import { ChartBooleanSettingProps, ChartTextSetting, CustomerChangeableChartBooleanSetting } from "../ChartTab_tools";
import { isChartTemplate, onChangeTextPropertyDebounced } from "../tools";
import { defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IGeovisChartPropertyChangedProps } from "../types";

type IInclinometerChartVisualSettingsTab = IGeovisChartPropertyChangedProps<InclinometerChartModel>

export const InclinometerChartVisualSettingsTab = ({ chart, onChartPropertyChanged }: IInclinometerChartVisualSettingsTab) => {
    const {
        VisibleRangeSettings,
        IsHorizontal,
        ForcedFixedPoints,
        ShowAxisLabels,
        FirstAxisLabel,
        SecondAxisLabel,
        LeftYAxisSettings
    } = chart;
    const {
        ChainsIds,
        MaxXLimit,
        MaxYLimit,
        MinXLimit,
        MinYLimit,
        ShowFirstXAxis,
        ShowSecondYAxis,
        TurnSignX,
        TurnSignY,
        ZOffset
    } = VisibleRangeSettings

    const onChartPropertyChangedFunc = (propertyName: keyof InclinometerChartModel) => (value: any) => {
        onChartPropertyChanged(propertyName, value);
    }

    const onVisibleRangeSettingsChangedFunc = (propertyName: keyof InclinometerVisibleRangeSettings) => (value: any) => {
        VisibleRangeSettings[propertyName] = (value as never);
        onChartPropertyChanged("VisibleRangeSettings", { ...new InclinometerVisibleRangeSettings(), ...VisibleRangeSettings });
    }

    const getBooleanSetting = (setting: ChartSettingBool): ChartSettingBool => {
        return setting || { value: false, customerChangeable: false };
    }

    const getFixedOptions = (): OptionType[] => {
        return ChainFixedPointList.map(el => ({ value: el, label: getChainFixedPointToDescription(el) }));
    }

    const getSelectedFixedOption = (): OptionType => {
        return { value: ForcedFixedPoints, label: getChainFixedPointToDescription(ForcedFixedPoints) }
    }

    const onSelectablePropertyChangedFunc = (propertyName: keyof InclinometerChartModel) => (selected: OptionType) => {
        onChartPropertyChanged(propertyName, +selected.value);
    }

    const onChangeStringProperty = (propertyName: keyof InclinometerChartModel) => (v1: string) => {
        onChangeTextPropertyDebounced(v1, (v2) => {
            onChartPropertyChanged(propertyName, v2);
        });
    }

    const onChangeNumericProperty = (propertyName: keyof InclinometerVisibleRangeSettings) => (v1: string) => {
        if (!isNaN(+v1)) {
            onChangeTextPropertyDebounced(v1, (v2) => {
                VisibleRangeSettings[propertyName] = (v2 as never);
                onChartPropertyChanged("VisibleRangeSettings", { ...new InclinometerVisibleRangeSettings(), ...VisibleRangeSettings });
            });
        }
    }

    const onChangeUnits = (selected: OptionType) => {
        onChartPropertyChanged("LeftYAxisSettings", { ...LeftYAxisSettings, Unit: +selected.value })
    }

    const getPossibleUnits = (): OptionType[] => {
        return [
            { value: PhysicalUnit.Meter, label: getPhysicalUnitShortName(PhysicalUnit.Meter) },
            { value: PhysicalUnit.Decimeter, label: getPhysicalUnitShortName(PhysicalUnit.Decimeter) },
            { value: PhysicalUnit.Centimeter, label: getPhysicalUnitShortName(PhysicalUnit.Centimeter) },
            { value: PhysicalUnit.Millimeter, label: getPhysicalUnitShortName(PhysicalUnit.Millimeter) },
        ]
    }

    const getSelectedUnit = (): OptionType | undefined => {
        return getPossibleUnits().find(u => +u.value === LeftYAxisSettings.Unit);
    }

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainer" style={defaultChartRowSettingsStyle}>
                <div className="flexCellContainer" style={defaultChartLabelColumnStyle}>
                    <label htmlFor="cmbUnits">{t("Unit")}:</label>
                </div>
                <div className="flexCellContainer_g1">
                    <GeovisSelect
                        id="cmbUnits"
                        value={getSelectedUnit()}
                        options={getPossibleUnits()}
                        onChange={onChangeUnits}
                        spacing='compact'
                    />
                </div>
            </div>
            {/* Min X limit */}
            <AxisScaleLimitControl
                scaleLimit={MinXLimit}
                tbLabel={`${t("Min X-Value")} [${getPhysicalUnitShortName(LeftYAxisSettings.Unit)}]`}
                onChange={onVisibleRangeSettingsChangedFunc("MinXLimit")} />
            {/* Max X limit */}
            <AxisScaleLimitControl
                scaleLimit={MaxXLimit}
                tbLabel={`${t("Max X-Value")} [${getPhysicalUnitShortName(LeftYAxisSettings.Unit)}]`}
                onChange={onVisibleRangeSettingsChangedFunc("MaxXLimit")} />
            {/* Min Y limit */}
            <AxisScaleLimitControl
                scaleLimit={MinYLimit}
                tbLabel={t("Min Y-Value [m]")}
                onChange={onVisibleRangeSettingsChangedFunc("MinYLimit")} />
            {/* Max Y limit */}
            <AxisScaleLimitControl
                scaleLimit={MaxYLimit}
                tbLabel={t("Max Y-Value [m]")}
                onChange={onVisibleRangeSettingsChangedFunc("MaxYLimit")} />
            {/* Show first X axis */}
            <ChartBooleanSettingProps
                label={t("Show first (X) axis of inclinometer")}
                isChecked={ShowFirstXAxis}
                onChange={onVisibleRangeSettingsChangedFunc("ShowFirstXAxis")}
                isDisabled={false}
            />
            {/* Show second Y axis */}
            <ChartBooleanSettingProps
                label={t("Show second (Y) axis of inclinometer")}
                isChecked={ShowSecondYAxis}
                onChange={onVisibleRangeSettingsChangedFunc("ShowSecondYAxis")}
                isDisabled={false}
            />
            {/* Show axis labels */}
            <CustomerChangeableChartBooleanSetting
                setting={getBooleanSetting(ShowAxisLabels)}
                settingLabel={t("Show axis labels")}
                onChange={onChartPropertyChangedFunc("ShowAxisLabels")}
                isDisabled={false}
            />
            {/* First axis label */}
            <ChartTextSetting
                labelColumnStyle={defaultChartLabelColumnStyle}
                settingsStyle={defaultChartRowSettingsStyle}
                labelText={t("Axis 1 label")}
                value={FirstAxisLabel}
                htmlName="tbAxis1Label"
                isDisabled={!ShowFirstXAxis || !ShowAxisLabels.value}
                onChangeTextField={onChangeStringProperty("FirstAxisLabel")}
            />
            {/* Second axis label */}
            <ChartTextSetting
                labelColumnStyle={defaultChartLabelColumnStyle}
                settingsStyle={defaultChartRowSettingsStyle}
                labelText={t("Axis 2 label")}
                value={SecondAxisLabel}
                htmlName="tbAxis2Label"
                isDisabled={!ShowSecondYAxis || !ShowAxisLabels.value}
                onChangeTextField={onChangeStringProperty("SecondAxisLabel")}
            />
            {/* Turn X */}
            <ChartBooleanSettingProps
                label={t("Turn the sign of X")}
                isChecked={TurnSignX}
                onChange={onVisibleRangeSettingsChangedFunc("TurnSignX")}
                isDisabled={false}
            />
            {/* Turn Y */}
            <ChartBooleanSettingProps
                label={t("Turn the sign of Y")}
                isChecked={TurnSignY}
                onChange={onVisibleRangeSettingsChangedFunc("TurnSignY")}
                isDisabled={false}
            />
            {/* Horizontal */}
            <ChartBooleanSettingProps
                label={t("Horizontal inclinometer")}
                isChecked={IsHorizontal}
                onChange={onChartPropertyChangedFunc("IsHorizontal")}
                isDisabled={false}
            />
            {/* Z offset */}
            <ChartTextSetting
                labelColumnStyle={defaultChartLabelColumnStyle}
                settingsStyle={defaultChartRowSettingsStyle}
                labelText={t("Y-axis offset [m]")}
                value={ZOffset}
                htmlName="tbZoffsetLabel"
                onChangeTextField={onChangeNumericProperty("ZOffset")}
            />

            {/* Fixed point */}
            <div className="flexRowContainer" style={defaultChartRowSettingsStyle}>
                <div className="flexCellContainer" style={defaultChartLabelColumnStyle}>
                    <label htmlFor="cmbFixed">{t("Force fixed point to")}:</label>
                </div>
                <div className="flexCellContainer_g1">
                    <GeovisSelect
                        id="cmbFixed"
                        options={getFixedOptions()}
                        value={getSelectedFixedOption()}
                        onChange={onSelectablePropertyChangedFunc("ForcedFixedPoints")}
                        isCompact={true}
                        spacing='compact'
                        isDisabled={false}
                    />
                </div>
            </div>
            {/* Chains */}
            {!isChartTemplate(chart) &&
                <ChartAxisSensorsControl
                    isChain={true}
                    chartType={chart.Type}
                    dtsSectionIds={[]}
                    projectId={chart.ProjectId}
                    ySensorType={SensorCategory.Inclinometer}
                    sensorIds={[]}
                    customerChangeable={false}
                    onChange={onVisibleRangeSettingsChangedFunc('ChainsIds')}
                    sensorChanges={[]}
                    showPairsDialog={false}
                    hideCustomerChangeable={true}
                    selectedChainsIds={ChainsIds} // in this dialog we do not need those ids
                    isSingle={false}
                />
            }
        </div>
    )
}