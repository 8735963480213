import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { useState } from "react"
import { t } from "../../i18n"
import GeovisReactDateTimePicker from './GeovisReactDateTimePicker';
import { GeovisTimePicker } from '../timepicker/GeovisTimePicker';

interface IComponentProps {
    onSelect: (dateTimeValue: string) => void;
    onCancel: () => void;
    saveTimeZone?: boolean;
    timeOnly?: boolean;
    headerText?: string;
}

interface IComponentState {
    dateTimeValue: string;
}

const Component = ({
    onSelect,
    onCancel,
    saveTimeZone,
    headerText,
    timeOnly
}: IComponentProps) => {
    const getFormattedToday = () => new Date(new Date().setHours(0, 0, 0, 0)).toJSON();

    const [state, setState] = useState<IComponentState>({
        dateTimeValue: getFormattedToday()
    });

    const onChange = (value: string) => {
        setState({
            dateTimeValue: value || ""
        })
    }

    const onSelectHandler = () => {
        onSelect(state.dateTimeValue);
    }

    return (
        <ModalTransition>
            <Modal
                actions={[
                    { text: t("Select"), appearance: 'primary', onClick: onSelectHandler },
                    { text: t("Cancel"), onClick: onCancel }
                ]}
                heading={headerText ?? t("Select an exact timeslot")}
                autoFocus={false}
                width="small">
                <div>
                    <div className="flexRowContainer">
                        <div className="flexCellContainer_g1">
                            {!timeOnly &&
                                <GeovisReactDateTimePicker
                                    locale="en-GB"
                                    key="dtEndDateTime"
                                    value={state.dateTimeValue}
                                    openOnInitialFocus={true}
                                    onChange={onChange}
                                    saveTimeZone={saveTimeZone}
                                />
                            }
                            {timeOnly &&
                                <GeovisTimePicker
                                    value={state.dateTimeValue}
                                    spacing="compact"
                                    onChange={onChange}
                                />
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </ModalTransition>
    )
}

export default Component;