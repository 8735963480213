import Tabs from '@atlaskit/tabs';
import { TabData } from "@atlaskit/tabs/dist/types/types";
import { useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { getProjectView } from '../../../helpers/FiltersHelper';
import { IWithGeovisServerProps, withGeovisServer } from "../../../helpers/GeovisHooks";
import { navigateToProjectReport } from '../../../helpers/NavigationHelper';
import { fetchServerElements } from "../../../helpers/ProjectDataHelper";
import { t } from "../../../i18n";
import { AttachedDocumentModel } from "../../../server/AttachedDocumentModel";
import { DataActionResponse } from "../../../server/DataActionResponse";
import { ProjectViewInfo } from "../../../server/GEOvis3/Model/ProjectViews/ProjectViewInfo";
import { ProjectReportInfo } from "../../../server/ProjectReportInfo";
import ServerRoutesGen from "../../../server/Routes/ServerRoutesGen";
import Logger from "../../../services/Logger";
import { projectDataAttachedDocumentsData, projectDataReportsData } from '../../../store/creators/dataCreators';
import { projectOverviewEmbeddedDataTabChanged } from '../../../store/creators/projectOverviewCreators';
import { IAttachedDocumentsStorage, IProjectViewsStorage } from "../../../store/data.types";
import { IGeovisStoreState } from '../../../store/store.types';
import { IGeovisAction } from '../../../store/types';
import { IWithProjectViewerModeProperty } from "../../abstract/IWithProjectViewerModeProperty";
import { DocumentsList, IDocumentsListProps } from "../DocumentsList";
import { IReportsListProps, ReportsList } from "../ReportsList";
import { ProjectOverviewEmbeddedDataHubTabItemComponent } from "./ProjectOverviewEmbeddedDataHubControl";


export const DocumentsName = "Documents";
export const ReportsName = "Reports";

interface IProjectOverviewEmbeddedDataHubStateProps extends IWithProjectViewerModeProperty {
    documentsStorage: IAttachedDocumentsStorage;
    view: ProjectViewInfo;
    searchQuery: string;
    dataName: string;
    projectViewsStorage: IProjectViewsStorage;
}
interface IProjectOverviewEmbeddedDataHubDispatchProps {
    onTabSelected: (dataName: string) => void;

    onReportStorageData: (reports: DataActionResponse<ProjectReportInfo[]>) => void;
    onDocumentStorageData: (documents: DataActionResponse<AttachedDocumentModel[]>) => void;
}
interface IProjectOverviewEmbeddedDataHubProps extends IProjectOverviewEmbeddedDataHubStateProps, IProjectOverviewEmbeddedDataHubDispatchProps, IWithGeovisServerProps {

}

type EmbeddedDataTabData = TabData & {
    dataName: string
}
const createReportsTabContent = (props: IReportsListProps): EmbeddedDataTabData => ({
    label: t("Reports"),
    content: (
        <div style={{ width: '100%' }}>
            <ReportsList
                projectId={props.projectId}
                searchQuery={props.searchQuery}
                title={""}
                onClick={props.onClick} />
        </div>
    ),
    dataName: ReportsName
});

const createDocumentsTabContent = (props: IDocumentsListProps): EmbeddedDataTabData => ({
    label: t("Documents"),
    content: (
        <div style={{ width: '100%' }}>
            <DocumentsList
                documentsStorage={props.documentsStorage}
                projectId={props.projectId}
                Server={props.Server}
                title={""}
                searchQuery={props.searchQuery}
                projectViewsStorage={props.projectViewsStorage} />
        </div>
    ),
    dataName: DocumentsName
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onTabSelectedFunc = (onTabSelected: (dataName: string) => void) => (tab: TabData, index: number) => {
    onTabSelected(tab.dataName);
}

const ProjectOverViewEmbeddedDataHub = ({
    documentsStorage,
    projectViewsStorage,
    view,
    Server,
    searchQuery,
    dataName,
    onTabSelected,
    onReportStorageData,
    onDocumentStorageData
}: IProjectOverviewEmbeddedDataHubProps) => {
    const { ProjectId, Id: viewId } = view;

    useEffect(() => {
        (async function loadEmbeddedData() {

            if (ProjectId <= 0) {
                return;
            }

            let result: DataActionResponse<ProjectReportInfo[]> | DataActionResponse<AttachedDocumentModel[]> | false = false;
            switch (dataName) {
                default:
                case ReportsName:
                    result = await fetchServerElements<ProjectReportInfo[]>(Server, ServerRoutesGen.ProjectReports.GetReportsOfView.patch(ProjectId, viewId));
                    if (result) {
                        onReportStorageData(result);
                    }
                    break;
                case DocumentsName:
                    result = await fetchServerElements<AttachedDocumentModel[]>(Server, ServerRoutesGen.AttachedDocuments.Get.patch(ProjectId));
                    if (result) {
                        onDocumentStorageData(result);
                    }
                    break;
            }
        })();
    }, [dataName, viewId]);

    const history = useHistory();

    const onClickHandler = (report: ProjectReportInfo) => {
        navigateToProjectReport(history, report.ProjectId, report.Id);
    }

    const tabs = [
        createReportsTabContent({
            projectId: ProjectId,
            searchQuery,
            title: "",
            onClick: onClickHandler
        }),
        createDocumentsTabContent({
            Server,
            documentsStorage,
            projectId: ProjectId,
            title: "",
            searchQuery,
            projectViewsStorage
        })

    ];
    Logger.render('Rendering', ProjectOverViewEmbeddedDataHub.name);

    return (
        <div style={{
            height: '100%',
            display: 'flex'
        }}>
            <Tabs
                components={{ Item: ProjectOverviewEmbeddedDataHubTabItemComponent }}
                selected={getSelectedTab(dataName, tabs)}
                tabs={tabs}
                onSelect={onTabSelectedFunc(onTabSelected)} />
        </div>
    );
}
const getSelectedTab = (dataName: string, tabs: EmbeddedDataTabData[]): EmbeddedDataTabData => {
    for (const tab of tabs) {
        if (tab.dataName === dataName) {
            return tab;
        }
    }

    return tabs[0];
}

const mapStateToProps = ({ data, navigation, projectOverview }: IGeovisStoreState): IProjectOverviewEmbeddedDataHubStateProps => ({
    searchQuery: data.projectViewFilter.searchElementQuery,
    documentsStorage: data.attachedDocumentsStorage,
    view: getProjectView(data.projectInfo, data.projectViewsStorage),
    viewerModeStamp: navigation.viewerModeStamp,
    dataName: projectOverview.embeddedDataName,
    projectViewsStorage: data.projectViewsStorage
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IProjectOverviewEmbeddedDataHubDispatchProps => ({
    onTabSelected: dataName => dispatch(projectOverviewEmbeddedDataTabChanged(dataName)),
    onDocumentStorageData: (documents) => dispatch(projectDataAttachedDocumentsData(documents.Data)),
    onReportStorageData: (reports) => dispatch(projectDataReportsData(reports))
});

export default connect<IProjectOverviewEmbeddedDataHubStateProps, IProjectOverviewEmbeddedDataHubDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(ProjectOverViewEmbeddedDataHub));