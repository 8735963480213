/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 31.01.2020
 * @description The SVGIcon implementation with custom renderer
 */

L.DivIcon.SVGIcon.SVGIconWithCustomRenderer = L.DivIcon.SVGIcon.extend({
    iconRenderer: null,
    initialize: function(options, renderer) {
        if(!renderer || typeof renderer !== "function"){
            throw Error('The custom renderer has incorrect type. It should be a function');
        }

        this.iconRenderer = renderer;
        L.DivIcon.SVGIcon.prototype.initialize.call(this, options);
    },
    _createSVG() {
        return this.iconRenderer(this.options);
    }
});