//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { SensorCategory } from '../Sensors/SensorCategory';
import { AlarmCondition } from '../Alarming/AlarmCondition';
import { PhysicalUnit } from '../Sensors/PhysicalUnit';
import { SensorValueAttribute } from '../Common/SensorValueAttribute';
import { GeovisAlarmActionModel } from '../Alarming/GeovisAlarmActionModel';

export class GeovisAlarmTemplateModel
{
	public Id: string;
	public CompanyId: string;
	public Name: string;
	public SensorType: SensorCategory;
	public Conditions: AlarmCondition[];
	public Comment: string;
	public TriggeredLimit: number;
	public Unit: PhysicalUnit;
	public SensorFunction: SensorValueAttribute;
	public UseRelative: boolean;
	public AutoConfirm: boolean;
	public Retrigger: boolean;
	public ListInLogbook: boolean;
	public IsPublic: boolean;
	public Offset: number;
	public Actions: GeovisAlarmActionModel[];
	public DescriptionRaised: string;
	public DescriptionConfirmed: string;
	public RelevantFrequency: SensorValueAttribute;
}
