//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartData } from './GeovisChartData';
import { XySeriaData } from './XySeriaData';
import { XLabelModel } from './XLabelModel';
import { HtmlReportSensorDescriptionViewModel } from '../Report/Model/HtmlReportSensorDescriptionViewModel';

export class XyChartData extends GeovisChartData
{
	public StartDate: string;
	public EndDate: string;
	public Series: XySeriaData[];
	public ReferenceTimes: string[];
	public XLabels: XLabelModel[];
	public Descriptions: HtmlReportSensorDescriptionViewModel[];
	public WarningSensors: string[];
	public MaxX: number;
	public MaxY: number;
	public MinX: number;
	public MinY: number;
}
