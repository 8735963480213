/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @description New implementation of GeoJSON VT render
 */
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { createGeoJSONWithGeoJSONVT } from '../../helpers/GeoJsonVtHelper';

interface IGeovisGeoJSONVTLayerProps {
    document: any;
    bounds?: L.LatLngBoundsExpression;
}

const GeovisGeoJSONVTLayer = ({
    document,
    bounds
}: IGeovisGeoJSONVTLayerProps) => {
    const map = useMap();
    useEffect(() => {
        const layer = createGeoJSONWithGeoJSONVT(document, { bounds });
        layer.addTo(map);
    },[]);
    return null;
}

export default GeovisGeoJSONVTLayer;