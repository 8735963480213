//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GvModule } from '../../GvModule';
import { GvLogEntryType } from './GvLogEntryType';
import { GvLogApp } from '../../GvLogApp';
import { GvSeverity } from '../../GvSeverity';

export abstract class GvLogEntryBase
{
	public Id: string;
	public ParentId: string;
	public ProjectId: number;
	public Module: GvModule;
	public Time: string;
	public Message: string;
	public RecordType: GvLogEntryType;
	public App: GvLogApp;
	public UserId: string;
	public Severity: GvSeverity;
}
