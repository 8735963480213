//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum Country {
	Unknown = 0,
	AF = 1,
	AX = 2,
	AL = 3,
	DZ = 4,
	AS = 5,
	AD = 6,
	AO = 7,
	AI = 8,
	AQ = 9,
	AG = 10,
	AR = 11,
	AM = 12,
	AW = 13,
	AU = 14,
	AT = 15,
	AZ = 16,
	BS = 17,
	BH = 18,
	BD = 19,
	BB = 20,
	BY = 21,
	BE = 22,
	BZ = 23,
	BJ = 24,
	BM = 25,
	BT = 26,
	BO = 27,
	BQ = 28,
	BA = 29,
	BW = 30,
	BR = 31,
	IO = 32,
	BN = 33,
	BG = 34,
	BF = 35,
	BI = 36,
	CV = 37,
	KH = 38,
	CM = 39,
	CA = 40,
	KY = 41,
	CF = 42,
	TD = 43,
	CL = 44,
	CN = 45,
	CX = 46,
	CC = 47,
	CO = 48,
	KM = 49,
	CG = 50,
	CD = 51,
	CK = 52,
	CR = 53,
	HR = 54,
	CU = 55,
	CW = 56,
	CY = 57,
	CZ = 58,
	DK = 59,
	DJ = 60,
	DM = 61,
	DO = 62,
	EC = 63,
	EG = 64,
	SV = 65,
	GQ = 66,
	ER = 67,
	EE = 68,
	ET = 69,
	FK = 70,
	FO = 71,
	FJ = 72,
	FI = 73,
	FR = 74,
	GF = 75,
	PF = 76,
	TF = 77,
	GA = 78,
	GM = 79,
	GE = 80,
	DE = 81,
	GH = 82,
	GI = 83,
	GR = 84,
	GL = 85,
	GD = 86,
	GP = 87,
	GU = 88,
	GT = 89,
	GG = 90,
	GN = 91,
	GW = 92,
	GY = 93,
	HT = 94,
	VA = 95,
	HN = 96,
	HK = 97,
	HU = 98,
	IS = 99,
	IN = 100,
	ID = 101,
	IR = 102,
	IQ = 103,
	IE = 104,
	IM = 105,
	IL = 106,
	IT = 107,
	JM = 108,
	JP = 109,
	JE = 110,
	JO = 111,
	KZ = 112,
	KE = 113,
	KI = 114,
	KP = 115,
	KR = 116,
	KW = 117,
	KG = 118,
	LA = 119,
	LV = 120,
	LB = 121,
	LS = 122,
	LR = 123,
	LY = 124,
	LI = 125,
	LT = 126,
	LU = 127,
	MO = 128,
	MK = 129,
	MG = 130,
	MW = 131,
	MY = 132,
	MV = 133,
	ML = 134,
	MT = 135,
	MH = 136,
	MQ = 137,
	MR = 138,
	MU = 139,
	YT = 140,
	MX = 141,
	FM = 142,
	MD = 143,
	MC = 144,
	MN = 145,
	ME = 146,
	MS = 147,
	MA = 148,
	MZ = 149,
	MM = 150,
	NA = 151,
	NR = 152,
	NP = 153,
	NL = 154,
	NC = 155,
	NZ = 156,
	NI = 157,
	NE = 158,
	NG = 159,
	NU = 160,
	NF = 161,
	MP = 162,
	NO = 163,
	OM = 164,
	PK = 165,
	PW = 166,
	PS = 167,
	PA = 168,
	PG = 169,
	PY = 170,
	PE = 171,
	PH = 172,
	PN = 173,
	PL = 174,
	PT = 175,
	PR = 176,
	QA = 177,
	RE = 178,
	RO = 179,
	RU = 180,
	RW = 181,
	BL = 182,
	SH = 183,
	KN = 184,
	LC = 185,
	MF = 186,
	PM = 187,
	VC = 188,
	WS = 189,
	SM = 190,
	ST = 191,
	SA = 192,
	SN = 193,
	RS = 194,
	SC = 195,
	SL = 196,
	SG = 197,
	SX = 198,
	SK = 199,
	SI = 200,
	SB = 201,
	SO = 202,
	ZA = 203,
	GS = 204,
	SS = 205,
	ES = 206,
	LK = 207,
	SD = 208,
	SR = 209,
	SJ = 210,
	SZ = 211,
	SE = 212,
	CH = 213,
	SY = 214,
	TW = 215,
	TJ = 216,
	TZ = 217,
	TH = 218,
	TL = 219,
	TG = 220,
	TK = 221,
	TO = 222,
	TT = 223,
	TN = 224,
	TR = 225,
	TM = 226,
	TC = 227,
	TV = 228,
	UG = 229,
	UA = 230,
	AE = 231,
	GB = 232,
	US = 233,
	UY = 234,
	UZ = 235,
	VU = 236,
	VE = 237,
	VN = 238,
	VG = 239,
	VI = 240,
	WF = 241,
	EH = 242,
	YE = 243,
	ZM = 244,
	ZW = 245
}

const CountryToName = new Map<number, string>([
	[ 0, 'Unknown' ],
	[ 1, 'AF' ],
	[ 2, 'AX' ],
	[ 3, 'AL' ],
	[ 4, 'DZ' ],
	[ 5, 'AS' ],
	[ 6, 'AD' ],
	[ 7, 'AO' ],
	[ 8, 'AI' ],
	[ 9, 'AQ' ],
	[ 10, 'AG' ],
	[ 11, 'AR' ],
	[ 12, 'AM' ],
	[ 13, 'AW' ],
	[ 14, 'AU' ],
	[ 15, 'AT' ],
	[ 16, 'AZ' ],
	[ 17, 'BS' ],
	[ 18, 'BH' ],
	[ 19, 'BD' ],
	[ 20, 'BB' ],
	[ 21, 'BY' ],
	[ 22, 'BE' ],
	[ 23, 'BZ' ],
	[ 24, 'BJ' ],
	[ 25, 'BM' ],
	[ 26, 'BT' ],
	[ 27, 'BO' ],
	[ 28, 'BQ' ],
	[ 29, 'BA' ],
	[ 30, 'BW' ],
	[ 31, 'BR' ],
	[ 32, 'IO' ],
	[ 33, 'BN' ],
	[ 34, 'BG' ],
	[ 35, 'BF' ],
	[ 36, 'BI' ],
	[ 37, 'CV' ],
	[ 38, 'KH' ],
	[ 39, 'CM' ],
	[ 40, 'CA' ],
	[ 41, 'KY' ],
	[ 42, 'CF' ],
	[ 43, 'TD' ],
	[ 44, 'CL' ],
	[ 45, 'CN' ],
	[ 46, 'CX' ],
	[ 47, 'CC' ],
	[ 48, 'CO' ],
	[ 49, 'KM' ],
	[ 50, 'CG' ],
	[ 51, 'CD' ],
	[ 52, 'CK' ],
	[ 53, 'CR' ],
	[ 54, 'HR' ],
	[ 55, 'CU' ],
	[ 56, 'CW' ],
	[ 57, 'CY' ],
	[ 58, 'CZ' ],
	[ 59, 'DK' ],
	[ 60, 'DJ' ],
	[ 61, 'DM' ],
	[ 62, 'DO' ],
	[ 63, 'EC' ],
	[ 64, 'EG' ],
	[ 65, 'SV' ],
	[ 66, 'GQ' ],
	[ 67, 'ER' ],
	[ 68, 'EE' ],
	[ 69, 'ET' ],
	[ 70, 'FK' ],
	[ 71, 'FO' ],
	[ 72, 'FJ' ],
	[ 73, 'FI' ],
	[ 74, 'FR' ],
	[ 75, 'GF' ],
	[ 76, 'PF' ],
	[ 77, 'TF' ],
	[ 78, 'GA' ],
	[ 79, 'GM' ],
	[ 80, 'GE' ],
	[ 81, 'DE' ],
	[ 82, 'GH' ],
	[ 83, 'GI' ],
	[ 84, 'GR' ],
	[ 85, 'GL' ],
	[ 86, 'GD' ],
	[ 87, 'GP' ],
	[ 88, 'GU' ],
	[ 89, 'GT' ],
	[ 90, 'GG' ],
	[ 91, 'GN' ],
	[ 92, 'GW' ],
	[ 93, 'GY' ],
	[ 94, 'HT' ],
	[ 95, 'VA' ],
	[ 96, 'HN' ],
	[ 97, 'HK' ],
	[ 98, 'HU' ],
	[ 99, 'IS' ],
	[ 100, 'IN' ],
	[ 101, 'ID' ],
	[ 102, 'IR' ],
	[ 103, 'IQ' ],
	[ 104, 'IE' ],
	[ 105, 'IM' ],
	[ 106, 'IL' ],
	[ 107, 'IT' ],
	[ 108, 'JM' ],
	[ 109, 'JP' ],
	[ 110, 'JE' ],
	[ 111, 'JO' ],
	[ 112, 'KZ' ],
	[ 113, 'KE' ],
	[ 114, 'KI' ],
	[ 115, 'KP' ],
	[ 116, 'KR' ],
	[ 117, 'KW' ],
	[ 118, 'KG' ],
	[ 119, 'LA' ],
	[ 120, 'LV' ],
	[ 121, 'LB' ],
	[ 122, 'LS' ],
	[ 123, 'LR' ],
	[ 124, 'LY' ],
	[ 125, 'LI' ],
	[ 126, 'LT' ],
	[ 127, 'LU' ],
	[ 128, 'MO' ],
	[ 129, 'MK' ],
	[ 130, 'MG' ],
	[ 131, 'MW' ],
	[ 132, 'MY' ],
	[ 133, 'MV' ],
	[ 134, 'ML' ],
	[ 135, 'MT' ],
	[ 136, 'MH' ],
	[ 137, 'MQ' ],
	[ 138, 'MR' ],
	[ 139, 'MU' ],
	[ 140, 'YT' ],
	[ 141, 'MX' ],
	[ 142, 'FM' ],
	[ 143, 'MD' ],
	[ 144, 'MC' ],
	[ 145, 'MN' ],
	[ 146, 'ME' ],
	[ 147, 'MS' ],
	[ 148, 'MA' ],
	[ 149, 'MZ' ],
	[ 150, 'MM' ],
	[ 151, 'NA' ],
	[ 152, 'NR' ],
	[ 153, 'NP' ],
	[ 154, 'NL' ],
	[ 155, 'NC' ],
	[ 156, 'NZ' ],
	[ 157, 'NI' ],
	[ 158, 'NE' ],
	[ 159, 'NG' ],
	[ 160, 'NU' ],
	[ 161, 'NF' ],
	[ 162, 'MP' ],
	[ 163, 'NO' ],
	[ 164, 'OM' ],
	[ 165, 'PK' ],
	[ 166, 'PW' ],
	[ 167, 'PS' ],
	[ 168, 'PA' ],
	[ 169, 'PG' ],
	[ 170, 'PY' ],
	[ 171, 'PE' ],
	[ 172, 'PH' ],
	[ 173, 'PN' ],
	[ 174, 'PL' ],
	[ 175, 'PT' ],
	[ 176, 'PR' ],
	[ 177, 'QA' ],
	[ 178, 'RE' ],
	[ 179, 'RO' ],
	[ 180, 'RU' ],
	[ 181, 'RW' ],
	[ 182, 'BL' ],
	[ 183, 'SH' ],
	[ 184, 'KN' ],
	[ 185, 'LC' ],
	[ 186, 'MF' ],
	[ 187, 'PM' ],
	[ 188, 'VC' ],
	[ 189, 'WS' ],
	[ 190, 'SM' ],
	[ 191, 'ST' ],
	[ 192, 'SA' ],
	[ 193, 'SN' ],
	[ 194, 'RS' ],
	[ 195, 'SC' ],
	[ 196, 'SL' ],
	[ 197, 'SG' ],
	[ 198, 'SX' ],
	[ 199, 'SK' ],
	[ 200, 'SI' ],
	[ 201, 'SB' ],
	[ 202, 'SO' ],
	[ 203, 'ZA' ],
	[ 204, 'GS' ],
	[ 205, 'SS' ],
	[ 206, 'ES' ],
	[ 207, 'LK' ],
	[ 208, 'SD' ],
	[ 209, 'SR' ],
	[ 210, 'SJ' ],
	[ 211, 'SZ' ],
	[ 212, 'SE' ],
	[ 213, 'CH' ],
	[ 214, 'SY' ],
	[ 215, 'TW' ],
	[ 216, 'TJ' ],
	[ 217, 'TZ' ],
	[ 218, 'TH' ],
	[ 219, 'TL' ],
	[ 220, 'TG' ],
	[ 221, 'TK' ],
	[ 222, 'TO' ],
	[ 223, 'TT' ],
	[ 224, 'TN' ],
	[ 225, 'TR' ],
	[ 226, 'TM' ],
	[ 227, 'TC' ],
	[ 228, 'TV' ],
	[ 229, 'UG' ],
	[ 230, 'UA' ],
	[ 231, 'AE' ],
	[ 232, 'GB' ],
	[ 233, 'US' ],
	[ 234, 'UY' ],
	[ 235, 'UZ' ],
	[ 236, 'VU' ],
	[ 237, 'VE' ],
	[ 238, 'VN' ],
	[ 239, 'VG' ],
	[ 240, 'VI' ],
	[ 241, 'WF' ],
	[ 242, 'EH' ],
	[ 243, 'YE' ],
	[ 244, 'ZM' ],
	[ 245, 'ZW' ],
])

export function getCountryToName(element: Country) : string {
    return CountryToName.get(element) || '';
}

const CountryByName = new Map<string, number>([
	[ 'Unknown', 0 ],
	[ 'AF', 1 ],
	[ 'AX', 2 ],
	[ 'AL', 3 ],
	[ 'DZ', 4 ],
	[ 'AS', 5 ],
	[ 'AD', 6 ],
	[ 'AO', 7 ],
	[ 'AI', 8 ],
	[ 'AQ', 9 ],
	[ 'AG', 10 ],
	[ 'AR', 11 ],
	[ 'AM', 12 ],
	[ 'AW', 13 ],
	[ 'AU', 14 ],
	[ 'AT', 15 ],
	[ 'AZ', 16 ],
	[ 'BS', 17 ],
	[ 'BH', 18 ],
	[ 'BD', 19 ],
	[ 'BB', 20 ],
	[ 'BY', 21 ],
	[ 'BE', 22 ],
	[ 'BZ', 23 ],
	[ 'BJ', 24 ],
	[ 'BM', 25 ],
	[ 'BT', 26 ],
	[ 'BO', 27 ],
	[ 'BQ', 28 ],
	[ 'BA', 29 ],
	[ 'BW', 30 ],
	[ 'BR', 31 ],
	[ 'IO', 32 ],
	[ 'BN', 33 ],
	[ 'BG', 34 ],
	[ 'BF', 35 ],
	[ 'BI', 36 ],
	[ 'CV', 37 ],
	[ 'KH', 38 ],
	[ 'CM', 39 ],
	[ 'CA', 40 ],
	[ 'KY', 41 ],
	[ 'CF', 42 ],
	[ 'TD', 43 ],
	[ 'CL', 44 ],
	[ 'CN', 45 ],
	[ 'CX', 46 ],
	[ 'CC', 47 ],
	[ 'CO', 48 ],
	[ 'KM', 49 ],
	[ 'CG', 50 ],
	[ 'CD', 51 ],
	[ 'CK', 52 ],
	[ 'CR', 53 ],
	[ 'HR', 54 ],
	[ 'CU', 55 ],
	[ 'CW', 56 ],
	[ 'CY', 57 ],
	[ 'CZ', 58 ],
	[ 'DK', 59 ],
	[ 'DJ', 60 ],
	[ 'DM', 61 ],
	[ 'DO', 62 ],
	[ 'EC', 63 ],
	[ 'EG', 64 ],
	[ 'SV', 65 ],
	[ 'GQ', 66 ],
	[ 'ER', 67 ],
	[ 'EE', 68 ],
	[ 'ET', 69 ],
	[ 'FK', 70 ],
	[ 'FO', 71 ],
	[ 'FJ', 72 ],
	[ 'FI', 73 ],
	[ 'FR', 74 ],
	[ 'GF', 75 ],
	[ 'PF', 76 ],
	[ 'TF', 77 ],
	[ 'GA', 78 ],
	[ 'GM', 79 ],
	[ 'GE', 80 ],
	[ 'DE', 81 ],
	[ 'GH', 82 ],
	[ 'GI', 83 ],
	[ 'GR', 84 ],
	[ 'GL', 85 ],
	[ 'GD', 86 ],
	[ 'GP', 87 ],
	[ 'GU', 88 ],
	[ 'GT', 89 ],
	[ 'GG', 90 ],
	[ 'GN', 91 ],
	[ 'GW', 92 ],
	[ 'GY', 93 ],
	[ 'HT', 94 ],
	[ 'VA', 95 ],
	[ 'HN', 96 ],
	[ 'HK', 97 ],
	[ 'HU', 98 ],
	[ 'IS', 99 ],
	[ 'IN', 100 ],
	[ 'ID', 101 ],
	[ 'IR', 102 ],
	[ 'IQ', 103 ],
	[ 'IE', 104 ],
	[ 'IM', 105 ],
	[ 'IL', 106 ],
	[ 'IT', 107 ],
	[ 'JM', 108 ],
	[ 'JP', 109 ],
	[ 'JE', 110 ],
	[ 'JO', 111 ],
	[ 'KZ', 112 ],
	[ 'KE', 113 ],
	[ 'KI', 114 ],
	[ 'KP', 115 ],
	[ 'KR', 116 ],
	[ 'KW', 117 ],
	[ 'KG', 118 ],
	[ 'LA', 119 ],
	[ 'LV', 120 ],
	[ 'LB', 121 ],
	[ 'LS', 122 ],
	[ 'LR', 123 ],
	[ 'LY', 124 ],
	[ 'LI', 125 ],
	[ 'LT', 126 ],
	[ 'LU', 127 ],
	[ 'MO', 128 ],
	[ 'MK', 129 ],
	[ 'MG', 130 ],
	[ 'MW', 131 ],
	[ 'MY', 132 ],
	[ 'MV', 133 ],
	[ 'ML', 134 ],
	[ 'MT', 135 ],
	[ 'MH', 136 ],
	[ 'MQ', 137 ],
	[ 'MR', 138 ],
	[ 'MU', 139 ],
	[ 'YT', 140 ],
	[ 'MX', 141 ],
	[ 'FM', 142 ],
	[ 'MD', 143 ],
	[ 'MC', 144 ],
	[ 'MN', 145 ],
	[ 'ME', 146 ],
	[ 'MS', 147 ],
	[ 'MA', 148 ],
	[ 'MZ', 149 ],
	[ 'MM', 150 ],
	[ 'NA', 151 ],
	[ 'NR', 152 ],
	[ 'NP', 153 ],
	[ 'NL', 154 ],
	[ 'NC', 155 ],
	[ 'NZ', 156 ],
	[ 'NI', 157 ],
	[ 'NE', 158 ],
	[ 'NG', 159 ],
	[ 'NU', 160 ],
	[ 'NF', 161 ],
	[ 'MP', 162 ],
	[ 'NO', 163 ],
	[ 'OM', 164 ],
	[ 'PK', 165 ],
	[ 'PW', 166 ],
	[ 'PS', 167 ],
	[ 'PA', 168 ],
	[ 'PG', 169 ],
	[ 'PY', 170 ],
	[ 'PE', 171 ],
	[ 'PH', 172 ],
	[ 'PN', 173 ],
	[ 'PL', 174 ],
	[ 'PT', 175 ],
	[ 'PR', 176 ],
	[ 'QA', 177 ],
	[ 'RE', 178 ],
	[ 'RO', 179 ],
	[ 'RU', 180 ],
	[ 'RW', 181 ],
	[ 'BL', 182 ],
	[ 'SH', 183 ],
	[ 'KN', 184 ],
	[ 'LC', 185 ],
	[ 'MF', 186 ],
	[ 'PM', 187 ],
	[ 'VC', 188 ],
	[ 'WS', 189 ],
	[ 'SM', 190 ],
	[ 'ST', 191 ],
	[ 'SA', 192 ],
	[ 'SN', 193 ],
	[ 'RS', 194 ],
	[ 'SC', 195 ],
	[ 'SL', 196 ],
	[ 'SG', 197 ],
	[ 'SX', 198 ],
	[ 'SK', 199 ],
	[ 'SI', 200 ],
	[ 'SB', 201 ],
	[ 'SO', 202 ],
	[ 'ZA', 203 ],
	[ 'GS', 204 ],
	[ 'SS', 205 ],
	[ 'ES', 206 ],
	[ 'LK', 207 ],
	[ 'SD', 208 ],
	[ 'SR', 209 ],
	[ 'SJ', 210 ],
	[ 'SZ', 211 ],
	[ 'SE', 212 ],
	[ 'CH', 213 ],
	[ 'SY', 214 ],
	[ 'TW', 215 ],
	[ 'TJ', 216 ],
	[ 'TZ', 217 ],
	[ 'TH', 218 ],
	[ 'TL', 219 ],
	[ 'TG', 220 ],
	[ 'TK', 221 ],
	[ 'TO', 222 ],
	[ 'TT', 223 ],
	[ 'TN', 224 ],
	[ 'TR', 225 ],
	[ 'TM', 226 ],
	[ 'TC', 227 ],
	[ 'TV', 228 ],
	[ 'UG', 229 ],
	[ 'UA', 230 ],
	[ 'AE', 231 ],
	[ 'GB', 232 ],
	[ 'US', 233 ],
	[ 'UY', 234 ],
	[ 'UZ', 235 ],
	[ 'VU', 236 ],
	[ 'VE', 237 ],
	[ 'VN', 238 ],
	[ 'VG', 239 ],
	[ 'VI', 240 ],
	[ 'WF', 241 ],
	[ 'EH', 242 ],
	[ 'YE', 243 ],
	[ 'ZM', 244 ],
	[ 'ZW', 245 ],
]);

export function getCountryByName(name: string): Country {
    return CountryByName.get(name)!;
}


export const CountryList : Readonly<Country[]> = [
	Country.Unknown,
	Country.AF,
	Country.AX,
	Country.AL,
	Country.DZ,
	Country.AS,
	Country.AD,
	Country.AO,
	Country.AI,
	Country.AQ,
	Country.AG,
	Country.AR,
	Country.AM,
	Country.AW,
	Country.AU,
	Country.AT,
	Country.AZ,
	Country.BS,
	Country.BH,
	Country.BD,
	Country.BB,
	Country.BY,
	Country.BE,
	Country.BZ,
	Country.BJ,
	Country.BM,
	Country.BT,
	Country.BO,
	Country.BQ,
	Country.BA,
	Country.BW,
	Country.BR,
	Country.IO,
	Country.BN,
	Country.BG,
	Country.BF,
	Country.BI,
	Country.CV,
	Country.KH,
	Country.CM,
	Country.CA,
	Country.KY,
	Country.CF,
	Country.TD,
	Country.CL,
	Country.CN,
	Country.CX,
	Country.CC,
	Country.CO,
	Country.KM,
	Country.CG,
	Country.CD,
	Country.CK,
	Country.CR,
	Country.HR,
	Country.CU,
	Country.CW,
	Country.CY,
	Country.CZ,
	Country.DK,
	Country.DJ,
	Country.DM,
	Country.DO,
	Country.EC,
	Country.EG,
	Country.SV,
	Country.GQ,
	Country.ER,
	Country.EE,
	Country.ET,
	Country.FK,
	Country.FO,
	Country.FJ,
	Country.FI,
	Country.FR,
	Country.GF,
	Country.PF,
	Country.TF,
	Country.GA,
	Country.GM,
	Country.GE,
	Country.DE,
	Country.GH,
	Country.GI,
	Country.GR,
	Country.GL,
	Country.GD,
	Country.GP,
	Country.GU,
	Country.GT,
	Country.GG,
	Country.GN,
	Country.GW,
	Country.GY,
	Country.HT,
	Country.VA,
	Country.HN,
	Country.HK,
	Country.HU,
	Country.IS,
	Country.IN,
	Country.ID,
	Country.IR,
	Country.IQ,
	Country.IE,
	Country.IM,
	Country.IL,
	Country.IT,
	Country.JM,
	Country.JP,
	Country.JE,
	Country.JO,
	Country.KZ,
	Country.KE,
	Country.KI,
	Country.KP,
	Country.KR,
	Country.KW,
	Country.KG,
	Country.LA,
	Country.LV,
	Country.LB,
	Country.LS,
	Country.LR,
	Country.LY,
	Country.LI,
	Country.LT,
	Country.LU,
	Country.MO,
	Country.MK,
	Country.MG,
	Country.MW,
	Country.MY,
	Country.MV,
	Country.ML,
	Country.MT,
	Country.MH,
	Country.MQ,
	Country.MR,
	Country.MU,
	Country.YT,
	Country.MX,
	Country.FM,
	Country.MD,
	Country.MC,
	Country.MN,
	Country.ME,
	Country.MS,
	Country.MA,
	Country.MZ,
	Country.MM,
	Country.NA,
	Country.NR,
	Country.NP,
	Country.NL,
	Country.NC,
	Country.NZ,
	Country.NI,
	Country.NE,
	Country.NG,
	Country.NU,
	Country.NF,
	Country.MP,
	Country.NO,
	Country.OM,
	Country.PK,
	Country.PW,
	Country.PS,
	Country.PA,
	Country.PG,
	Country.PY,
	Country.PE,
	Country.PH,
	Country.PN,
	Country.PL,
	Country.PT,
	Country.PR,
	Country.QA,
	Country.RE,
	Country.RO,
	Country.RU,
	Country.RW,
	Country.BL,
	Country.SH,
	Country.KN,
	Country.LC,
	Country.MF,
	Country.PM,
	Country.VC,
	Country.WS,
	Country.SM,
	Country.ST,
	Country.SA,
	Country.SN,
	Country.RS,
	Country.SC,
	Country.SL,
	Country.SG,
	Country.SX,
	Country.SK,
	Country.SI,
	Country.SB,
	Country.SO,
	Country.ZA,
	Country.GS,
	Country.SS,
	Country.ES,
	Country.LK,
	Country.SD,
	Country.SR,
	Country.SJ,
	Country.SZ,
	Country.SE,
	Country.CH,
	Country.SY,
	Country.TW,
	Country.TJ,
	Country.TZ,
	Country.TH,
	Country.TL,
	Country.TG,
	Country.TK,
	Country.TO,
	Country.TT,
	Country.TN,
	Country.TR,
	Country.TM,
	Country.TC,
	Country.TV,
	Country.UG,
	Country.UA,
	Country.AE,
	Country.GB,
	Country.US,
	Country.UY,
	Country.UZ,
	Country.VU,
	Country.VE,
	Country.VN,
	Country.VG,
	Country.VI,
	Country.WF,
	Country.EH,
	Country.YE,
	Country.ZM,
	Country.ZW,
];



const CountryToDescription = new Map<number, string>([
	[ 0, 'Unknown' ],
	[ 1, 'Afghanistan' ],
	[ 2, 'Åland Islands' ],
	[ 3, 'Albania' ],
	[ 4, 'Algeria' ],
	[ 5, 'American Samoa' ],
	[ 6, 'Andorra' ],
	[ 7, 'Angola' ],
	[ 8, 'Anguilla' ],
	[ 9, 'Antarctica' ],
	[ 10, 'Antigua and Barbuda' ],
	[ 11, 'Argentina' ],
	[ 12, 'Armenia' ],
	[ 13, 'Aruba' ],
	[ 14, 'Australia' ],
	[ 15, 'Austria' ],
	[ 16, 'Azerbaijan' ],
	[ 17, 'Bahamas' ],
	[ 18, 'Bahrain' ],
	[ 19, 'Bangladesh' ],
	[ 20, 'Barbados' ],
	[ 21, 'Belarus' ],
	[ 22, 'Belgium' ],
	[ 23, 'Belize' ],
	[ 24, 'Benin' ],
	[ 25, 'Bermuda' ],
	[ 26, 'Bhutan' ],
	[ 27, 'Bolivia' ],
	[ 28, 'Bonaire Sint Eustatius and Saba' ],
	[ 29, 'Bosnia and Herzegovina' ],
	[ 30, 'Botswana' ],
	[ 31, 'Brazil' ],
	[ 32, 'British Indian Ocean Territory' ],
	[ 33, 'Brunei Darussalam' ],
	[ 34, 'Bulgaria' ],
	[ 35, 'Burkina Faso' ],
	[ 36, 'Burundi' ],
	[ 37, 'Cabo Verde' ],
	[ 38, 'Cambodia' ],
	[ 39, 'Cameroon' ],
	[ 40, 'Canada' ],
	[ 41, 'Cayman Islands' ],
	[ 42, 'Central African Republic' ],
	[ 43, 'Chad' ],
	[ 44, 'Chile' ],
	[ 45, 'China' ],
	[ 46, 'Christmas Island' ],
	[ 47, 'Cocos (Keeling) Islands' ],
	[ 48, 'Colombia' ],
	[ 49, 'Comoros' ],
	[ 50, 'Congo' ],
	[ 51, 'Congo' ],
	[ 52, 'Cook Islands' ],
	[ 53, 'Costa Rica' ],
	[ 54, 'Croatia' ],
	[ 55, 'Cuba' ],
	[ 56, 'Curaçao' ],
	[ 57, 'Cyprus' ],
	[ 58, 'Czech Republic' ],
	[ 59, 'Denmark' ],
	[ 60, 'Djibouti' ],
	[ 61, 'Dominica' ],
	[ 62, 'Dominican Republic' ],
	[ 63, 'Ecuador' ],
	[ 64, 'Egypt' ],
	[ 65, 'El Salvador' ],
	[ 66, 'Equatorial Guinea' ],
	[ 67, 'Eritrea' ],
	[ 68, 'Estonia' ],
	[ 69, 'Ethiopia' ],
	[ 70, 'Falkland Islands (Malvinas)' ],
	[ 71, 'Faroe Islands' ],
	[ 72, 'Fiji' ],
	[ 73, 'Finland' ],
	[ 74, 'France' ],
	[ 75, 'French Guiana' ],
	[ 76, 'French Polynesia' ],
	[ 77, 'French Southern Territories' ],
	[ 78, 'Gabon' ],
	[ 79, 'Gambia' ],
	[ 80, 'Georgia' ],
	[ 81, 'Germany' ],
	[ 82, 'Ghana' ],
	[ 83, 'Gibraltar' ],
	[ 84, 'Greece' ],
	[ 85, 'Greenland' ],
	[ 86, 'Grenada' ],
	[ 87, 'Guadeloupe' ],
	[ 88, 'Guam' ],
	[ 89, 'Guatemala' ],
	[ 90, 'Guernsey' ],
	[ 91, 'Guinea' ],
	[ 92, 'Guinea-Bissau' ],
	[ 93, 'Guyana' ],
	[ 94, 'Haiti' ],
	[ 95, 'Holy See' ],
	[ 96, 'Honduras' ],
	[ 97, 'Hong Kong' ],
	[ 98, 'Hungary' ],
	[ 99, 'Iceland' ],
	[ 100, 'India' ],
	[ 101, 'Indonesia' ],
	[ 102, 'Iran' ],
	[ 103, 'Iraq' ],
	[ 104, 'Ireland' ],
	[ 105, 'Isle of Man' ],
	[ 106, 'Israel' ],
	[ 107, 'Italy' ],
	[ 108, 'Jamaica' ],
	[ 109, 'Japan' ],
	[ 110, 'Jersey' ],
	[ 111, 'Jordan' ],
	[ 112, 'Kazakhstan' ],
	[ 113, 'Kenya' ],
	[ 114, 'Kiribati' ],
	[ 115, 'Korea' ],
	[ 116, 'Republic of Korea' ],
	[ 117, 'Kuwait' ],
	[ 118, 'Kyrgyzstan' ],
	[ 119, 'Lao' ],
	[ 120, 'Latvia' ],
	[ 121, 'Lebanon' ],
	[ 122, 'Lesotho' ],
	[ 123, 'Liberia' ],
	[ 124, 'Libya' ],
	[ 125, 'Liechtenstein' ],
	[ 126, 'Lithuania' ],
	[ 127, 'Luxembourg' ],
	[ 128, 'Macao' ],
	[ 129, 'Macedonia' ],
	[ 130, 'Madagascar' ],
	[ 131, 'Malawi' ],
	[ 132, 'Malaysia' ],
	[ 133, 'Maldives' ],
	[ 134, 'Mali' ],
	[ 135, 'Malta' ],
	[ 136, 'Marshall Islands' ],
	[ 137, 'Martinique' ],
	[ 138, 'Mauritania' ],
	[ 139, 'Mauritius' ],
	[ 140, 'Mayotte' ],
	[ 141, 'Mexico' ],
	[ 142, 'Micronesia' ],
	[ 143, 'Moldova' ],
	[ 144, 'Monaco' ],
	[ 145, 'Mongolia' ],
	[ 146, 'Montenegro' ],
	[ 147, 'Montserrat' ],
	[ 148, 'Morocco' ],
	[ 149, 'Mozambique' ],
	[ 150, 'Myanmar' ],
	[ 151, 'Namibia' ],
	[ 152, 'Nauru' ],
	[ 153, 'Nepal' ],
	[ 154, 'Netherlands' ],
	[ 155, 'New Caledonia' ],
	[ 156, 'New Zealand' ],
	[ 157, 'Nicaragua' ],
	[ 158, 'Niger' ],
	[ 159, 'Nigeria' ],
	[ 160, 'Niue' ],
	[ 161, 'Norfolk Island' ],
	[ 162, 'Northern Mariana Islands' ],
	[ 163, 'Norway' ],
	[ 164, 'Oman' ],
	[ 165, 'Pakistan' ],
	[ 166, 'Palau' ],
	[ 167, 'Palestine' ],
	[ 168, 'Panama' ],
	[ 169, 'Papua New Guinea' ],
	[ 170, 'Paraguay' ],
	[ 171, 'Peru' ],
	[ 172, 'Philippines' ],
	[ 173, 'Pitcairn' ],
	[ 174, 'Poland' ],
	[ 175, 'Portugal' ],
	[ 176, 'Puerto Rico' ],
	[ 177, 'Qatar' ],
	[ 178, 'Réunion' ],
	[ 179, 'Romania' ],
	[ 180, 'Russian Federation' ],
	[ 181, 'Rwanda' ],
	[ 182, 'Saint Barthélemy' ],
	[ 183, 'Saint Helena, Ascension and Tristan da Cunha' ],
	[ 184, 'Saint Kitts and Nevis' ],
	[ 185, 'Saint Lucia' ],
	[ 186, 'Saint Martin (French part)' ],
	[ 187, 'Saint Pierre and Miquelon' ],
	[ 188, 'Saint Vincent and the Grenadines' ],
	[ 189, 'Samoa' ],
	[ 190, 'San Marino' ],
	[ 191, 'Sao Tome and Principe' ],
	[ 192, 'Saudi Arabia' ],
	[ 193, 'Senegal' ],
	[ 194, 'Serbia' ],
	[ 195, 'Seychelles' ],
	[ 196, 'Sierra Leone' ],
	[ 197, 'Singapore' ],
	[ 198, 'Sint Maarten (Dutch part)' ],
	[ 199, 'Slovakia' ],
	[ 200, 'Slovenia' ],
	[ 201, 'Solomon Islands' ],
	[ 202, 'Somalia' ],
	[ 203, 'South Africa' ],
	[ 204, 'South Georgia and the South Sandwich Islands' ],
	[ 205, 'South Sudan' ],
	[ 206, 'Spain' ],
	[ 207, 'Sri Lanka' ],
	[ 208, 'Sudan' ],
	[ 209, 'Suriname' ],
	[ 210, 'Svalbard and Jan Mayen' ],
	[ 211, 'Swaziland' ],
	[ 212, 'Sweden' ],
	[ 213, 'Switzerland' ],
	[ 214, 'Syrian Arab Republic' ],
	[ 215, 'Taiwan' ],
	[ 216, 'Tajikistan' ],
	[ 217, 'Tanzania' ],
	[ 218, 'Thailand' ],
	[ 219, 'Timor-Leste' ],
	[ 220, 'Togo' ],
	[ 221, 'Tokelau' ],
	[ 222, 'Tonga' ],
	[ 223, 'Trinidad and Tobago' ],
	[ 224, 'Tunisia' ],
	[ 225, 'Turkey' ],
	[ 226, 'Turkmenistan' ],
	[ 227, 'Turks and Caicos Islands' ],
	[ 228, 'Tuvalu' ],
	[ 229, 'Uganda' ],
	[ 230, 'Ukraine' ],
	[ 231, 'United Arab Emirates' ],
	[ 232, 'United Kingdom' ],
	[ 233, 'USA' ],
	[ 234, 'Uruguay' ],
	[ 235, 'Uzbekistan' ],
	[ 236, 'Vanuatu' ],
	[ 237, 'Venezuela' ],
	[ 238, 'Viet Nam' ],
	[ 239, 'Virgin Islands (British)' ],
	[ 240, 'Virgin Islands (U.S.)' ],
	[ 241, 'Wallis and Futuna' ],
	[ 242, 'Western Sahara' ],
	[ 243, 'Yemen' ],
	[ 244, 'Zambia' ],
	[ 245, 'Zimbabwe' ],
])

export function getCountryToDescription(element: Country) : string {
    return CountryToDescription.get(element) || '';
}


const CountryCodeByCountry = new Map<number, string>([
	[ 0, '0' ],
	[ 1, '93' ],
	[ 2, '358' ],
	[ 3, '355' ],
	[ 4, '213' ],
	[ 5, '1 684' ],
	[ 6, '376' ],
	[ 7, '244' ],
	[ 8, '1 264' ],
	[ 9, '672' ],
	[ 10, '1 268' ],
	[ 11, '54' ],
	[ 12, '374' ],
	[ 13, '297' ],
	[ 14, '61' ],
	[ 15, '43' ],
	[ 16, '994' ],
	[ 17, '1 242' ],
	[ 18, '973' ],
	[ 19, '880' ],
	[ 20, '1 246' ],
	[ 21, '375' ],
	[ 22, '32' ],
	[ 23, '501' ],
	[ 24, '229' ],
	[ 25, '1 441' ],
	[ 26, '975' ],
	[ 27, '591' ],
	[ 28, '599' ],
	[ 29, '387' ],
	[ 30, '267' ],
	[ 31, '55' ],
	[ 32, '246' ],
	[ 33, '673' ],
	[ 34, '359' ],
	[ 35, '226' ],
	[ 36, '257' ],
	[ 37, '238' ],
	[ 38, '855' ],
	[ 39, '237' ],
	[ 40, '1' ],
	[ 41, '1 345' ],
	[ 42, '236' ],
	[ 43, '235' ],
	[ 44, '56' ],
	[ 45, '86' ],
	[ 46, '61' ],
	[ 47, '61' ],
	[ 48, '57' ],
	[ 49, '269' ],
	[ 50, '242' ],
	[ 51, '243' ],
	[ 52, '682' ],
	[ 53, '506' ],
	[ 54, '385' ],
	[ 55, '53' ],
	[ 56, '599' ],
	[ 57, '357' ],
	[ 58, '420' ],
	[ 59, '45' ],
	[ 60, '253' ],
	[ 61, '1 767' ],
	[ 62, '1 809})' ],
	[ 63, '593' ],
	[ 64, '20' ],
	[ 65, '503' ],
	[ 66, '240' ],
	[ 67, '291' ],
	[ 68, '372' ],
	[ 69, '251' ],
	[ 70, '500' ],
	[ 71, '298' ],
	[ 72, '679' ],
	[ 73, '358' ],
	[ 74, '33' ],
	[ 75, '594' ],
	[ 76, '689' ],
	[ 77, '262' ],
	[ 78, '241' ],
	[ 79, '220' ],
	[ 80, '995' ],
	[ 81, '49' ],
	[ 82, '233' ],
	[ 83, '350' ],
	[ 84, '30' ],
	[ 85, '299' ],
	[ 86, '1 473' ],
	[ 87, '590' ],
	[ 88, '1 671' ],
	[ 89, '502' ],
	[ 90, '44 1481' ],
	[ 91, '224' ],
	[ 92, '245' ],
	[ 93, '592' ],
	[ 94, '509' ],
	[ 95, '379' ],
	[ 96, '504' ],
	[ 97, '852' ],
	[ 98, '36' ],
	[ 99, '354' ],
	[ 100, '91' ],
	[ 101, '62' ],
	[ 102, '98' ],
	[ 103, '964' ],
	[ 104, '353' ],
	[ 105, '44 1624' ],
	[ 106, '972' ],
	[ 107, '39' ],
	[ 108, '1 876' ],
	[ 109, '81' ],
	[ 110, '44 1534' ],
	[ 111, '962' ],
	[ 112, '7' ],
	[ 113, '254' ],
	[ 114, '686' ],
	[ 115, '850' ],
	[ 116, '82' ],
	[ 117, '965' ],
	[ 118, '996' ],
	[ 119, '856' ],
	[ 120, '371' ],
	[ 121, '961' ],
	[ 122, '266' ],
	[ 123, '231' ],
	[ 124, '218' ],
	[ 125, '423' ],
	[ 126, '370' ],
	[ 127, '352' ],
	[ 128, '853' ],
	[ 129, '389' ],
	[ 130, '261' ],
	[ 131, '265' ],
	[ 132, '60' ],
	[ 133, '960' ],
	[ 134, '223' ],
	[ 135, '356' ],
	[ 136, '692' ],
	[ 137, '596' ],
	[ 138, '222' ],
	[ 139, '230' ],
	[ 140, '262' ],
	[ 141, '52' ],
	[ 142, '691' ],
	[ 143, '373' ],
	[ 144, '377' ],
	[ 145, '976' ],
	[ 146, '382' ],
	[ 147, '1 664' ],
	[ 148, '212' ],
	[ 149, '258' ],
	[ 150, '95' ],
	[ 151, '264' ],
	[ 152, '674' ],
	[ 153, '977' ],
	[ 154, '31' ],
	[ 155, '687' ],
	[ 156, '64' ],
	[ 157, '505' ],
	[ 158, '227' ],
	[ 159, '234' ],
	[ 160, '683' ],
	[ 161, '672' ],
	[ 162, '1 670' ],
	[ 163, '47' ],
	[ 164, '968' ],
	[ 165, '92' ],
	[ 166, '680' ],
	[ 167, '970' ],
	[ 168, '507' ],
	[ 169, '675' ],
	[ 170, '595' ],
	[ 171, '51' ],
	[ 172, '63' ],
	[ 173, '64' ],
	[ 174, '48' ],
	[ 175, '351' ],
	[ 176, '1 787' ],
	[ 177, '974' ],
	[ 178, '262' ],
	[ 179, '40' ],
	[ 180, '7' ],
	[ 181, '250' ],
	[ 182, '590' ],
	[ 183, '290' ],
	[ 184, '1 869' ],
	[ 185, '1 758' ],
	[ 186, '590' ],
	[ 187, '508' ],
	[ 188, '1 784' ],
	[ 189, '685' ],
	[ 190, '378' ],
	[ 191, '239' ],
	[ 192, '966' ],
	[ 193, '221' ],
	[ 194, '381' ],
	[ 195, '248' ],
	[ 196, '232' ],
	[ 197, '65' ],
	[ 198, '1 721' ],
	[ 199, '421' ],
	[ 200, '386' ],
	[ 201, '677' ],
	[ 202, '252' ],
	[ 203, '27' ],
	[ 204, '500' ],
	[ 205, '211' ],
	[ 206, '34' ],
	[ 207, '94' ],
	[ 208, '249' ],
	[ 209, '597' ],
	[ 210, '47' ],
	[ 211, '268' ],
	[ 212, '46' ],
	[ 213, '41' ],
	[ 214, '963' ],
	[ 215, '886' ],
	[ 216, '992' ],
	[ 217, '255' ],
	[ 218, '66' ],
	[ 219, '670' ],
	[ 220, '228' ],
	[ 221, '690' ],
	[ 222, '676' ],
	[ 223, '1 868' ],
	[ 224, '216' ],
	[ 225, '90' ],
	[ 226, '993' ],
	[ 227, '1 649' ],
	[ 228, '688' ],
	[ 229, '256' ],
	[ 230, '380' ],
	[ 231, '971' ],
	[ 232, '44' ],
	[ 233, '1' ],
	[ 234, '598' ],
	[ 235, '998' ],
	[ 236, '678' ],
	[ 237, '58' ],
	[ 238, '84' ],
	[ 239, '1 284' ],
	[ 240, '1 340' ],
	[ 241, '681' ],
	[ 242, '212' ],
	[ 243, '967' ],
	[ 244, '260' ],
	[ 245, '263' ],
]);

export function getCountryCodeByCountry(country:number): string{
    return CountryCodeByCountry.get(country)!;
}

