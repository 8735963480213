import { useEffect, useState } from "react";
import { isMatchToSearchString } from "../../../helpers/FiltersHelper";
import { IWithGeovisServerProps, withGeovisServer } from '../../../helpers/GeovisHooks';
import { failedResponse, fetchServerElements, fetchServerElementsByPost, sendServerPostRequestData, successRequest } from "../../../helpers/ProjectDataHelper";
import { elementsToMap, mapToListOfElements, mapToListOfElementsOfIds } from "../../../helpers/StorageHelper";
import { t } from '../../../i18n';
import { ChartType } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { XYSensorPair } from "../../../server/AVTService/TypeLibrary/Common/XYSensorPair";
import { SensorCategory } from "../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { ChainInfo } from "../../../server/ChainInfo";
import { DataActionResponse } from "../../../server/DataActionResponse";
import { GeovisMstShortInfoModel } from "../../../server/GEOvis3/Model/Database/GeovisMstShortInfoModel";
import { DtsChartSensorsInfo } from "../../../server/GEOvis3/Model/GeovisChart/DtsChartSensorsInfo";
import { SensorChangesModel } from "../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel";
import { SensorInfoMini } from "../../../server/GEOvis3/Model/Sensors/SensorInfoMini";
import { SensorsInfoRequest } from "../../../server/GEOvis3/Model/Sensors/SensorsInfoRequest";
import ServerRoutesGen from "../../../server/Routes/ServerRoutesGen";
import FlagService from "../../../services/FlagService";
import { processFetchedData } from "../../../store/helpers/DataHelper";
import { defaultSomethingStorageState, ISomethingStoreBase } from "../../../store/types";
import { SelectChainsDialog } from "./SelectChainsDialog";
import { SelectSensorsDialog } from "./SelectSensorsDialog";
import { SelectSensorsPairsDialog } from "./SelectSensorsPairsDialog";
import { SelectSingleSensorDialog } from "./SelectSingleSensorDialog";
import { isTheSameSensorType } from "./tools";

export type SensorDialogType = 'base' | 'single' | 'pairs' | 'chains';

interface IComponentState extends ISomethingStoreBase {
    sensorsInfo: Map<string, SensorInfoMini>;
    databases: GeovisMstShortInfoModel[];
    chainsInfo: Map<string, ChainInfo>;

}

interface IFilterState {
    showPublic: boolean | undefined;
    searchQuery: string;
    useFilterForSelectedItems: boolean;
    databaseIds: string[];
    selectedSensorIds?: string[];
    selectedSensorId?: string;
    selectedChainsIds?: string[];
}

const chartSensorsSelectDialogInitialState: IComponentState = {
    ...defaultSomethingStorageState,
    databases: [],
    sensorsInfo: new Map<string, SensorInfoMini>(),
    chainsInfo: new Map<string, ChainInfo>(),
};

interface IComponentProps extends IWithGeovisServerProps {
    chartType: ChartType;
    projectId: number;
    dtsSectionIds: string[];
    sensorChanges: SensorChangesModel[];

    yAxisSensorType?: SensorCategory;
    xAxisSensorType?: SensorCategory;

    selectedSensorIds?: string[];
    selectedSensorId?: string;
    selectedChainsIds?: string[];

    selectedSensorsPairs?: XYSensorPair[];
    dialogType: SensorDialogType;

    onSelectedAxisSensors?: (items: SensorInfoMini[], sensorChanges: SensorChangesModel[]) => void;
    onSelectSingleSensor?: (sensorId: string) => void;
    onSelectSensorsPairs?: (pairs: XYSensorPair[], sensorChanges: SensorChangesModel[]) => void;
    onSelectedChains?: (chains: ChainInfo[]) => void;

    onCanceled: () => void;
}

export const ChartSensorsSelectDialog = withGeovisServer((props: IComponentProps) => {
    const {
        yAxisSensorType,
        selectedSensorIds,
        Server,
        chartType,
        dtsSectionIds,
        projectId,
        onSelectedAxisSensors,
        onCanceled,
        dialogType,
        onSelectSensorsPairs,
        onSelectSingleSensor,
        selectedSensorId,
        xAxisSensorType,
        selectedSensorsPairs,
        onSelectedChains,
        selectedChainsIds,
        sensorChanges
    } = props;

    const [state, setState] = useState<IComponentState>(chartSensorsSelectDialogInitialState);
    const [filter, setFilter] = useState<IFilterState>({ databaseIds: [], showPublic: undefined, selectedChainsIds, selectedSensorId, selectedSensorIds, searchQuery: '', useFilterForSelectedItems: false });

    /**
     * Load DTS chart sensors info
     * @returns 
     */
    const loadDtsChartSensorInfos = async (): Promise<DataActionResponse<SensorInfoMini[]>> => {

        const route = ServerRoutesGen.GeovisChart.GetDtsChartSensorsInfoBySectionIds.patch(projectId);
        const response = await fetchServerElementsByPost<DtsChartSensorsInfo, string[]>(Server, route, dtsSectionIds);

        if (!response.Success) {
            return failedResponse(response);
        }

        return successRequest([
            ...response.Data.TemperatureSensors,
            ...response.Data.AttenuationSensors,
            ...response.Data.StokeSensors,
            ...response.Data.NormalizedTemperatureSensors,
            ...response.Data.ThermalConductivitySensors]);
    }

    /**
     * Load chains info (this method loads all chains of project, filter by database ids doesn't work)
     * @returns 
     */
    const loadAvailableChainInfos = async (): Promise<DataActionResponse<ChainInfo[]>> => {
        const url = ServerRoutesGen.Project.GetAllChainsInfo.patch(projectId);
        const response = await fetchServerElements<ChainInfo[]>(Server, url);

        if (!response.Success) {
            return failedResponse(response);
        }
        return response;
    }

    /**
     * Load available chains
     * @returns 
     */
    const loadAvailableSensorInfos = async (): Promise<DataActionResponse<SensorInfoMini[]>> => {

        if (chartType === ChartType.DtsChart) {
            return await loadDtsChartSensorInfos();
        }

        const request: SensorsInfoRequest = {
            DatabaseIds: filter.databaseIds,
            FullIds: [],
            SensorTypes: [],
            IsPublic: filter.showPublic,
            ChainsIds: []
        }

        // When sensor type "Temperature" is chosen then we also will see 1) the 'Inclinometer' sensors (AGMS-3267) and 2) the 'Hydrostatic level' sensors
        let isTemperatureSensorTypeChosen = false;

        if (yAxisSensorType !== undefined) {
            if (yAxisSensorType === SensorCategory.MST_SB) {
                request.SensorTypes!.push(SensorCategory.MST, SensorCategory.SB);
            }
            else {
                request.SensorTypes!.push(yAxisSensorType);
            }
            isTemperatureSensorTypeChosen = isTheSameSensorType(yAxisSensorType, SensorCategory.Temperature);
        }

        if (xAxisSensorType !== undefined) {
            if (xAxisSensorType === SensorCategory.MST_SB) {
                request.SensorTypes!.push(SensorCategory.MST, SensorCategory.SB);
            }
            else {
                request.SensorTypes!.push(xAxisSensorType);
            }

            if (!isTemperatureSensorTypeChosen) {
                isTemperatureSensorTypeChosen = isTheSameSensorType(xAxisSensorType, SensorCategory.Temperature);
            }
        }

        if (isTemperatureSensorTypeChosen) {
            request.SensorTypes!.push(SensorCategory.Inclinometer, SensorCategory.HydrostaticLevel);
        }

        if ((yAxisSensorType === SensorCategory.InclinometerChainPosition) && filter.selectedChainsIds && filter.selectedChainsIds.length > 0) {
            request.ChainsIds = filter.selectedChainsIds;
        }

        const url = ServerRoutesGen.Project.GetSensorsInfoByRequestMini.patch(projectId);
        const response = await sendServerPostRequestData<SensorsInfoRequest, DataActionResponse<SensorInfoMini[]>>(Server, url, request);

        if (!response.Success) {
            return failedResponse(response);
        }
        return response;
    }

    /**
     * Check if data can be loaded
     * @returns 
     */
    const canDataBeLoaded = (): boolean => {
        if (!onSelectSensorsPairs && dialogType === "pairs") {
            return false;
        }

        if (!yAxisSensorType && (dialogType === "base" || dialogType === "pairs")) {
            return false;
        }

        if (!xAxisSensorType && dialogType === "pairs") {
            return false;
        }

        return true;
    }

    // did mount effect
    // load list of database ids
    useEffect(() => {

        // avoid unnecessary loading of data
        if (!canDataBeLoaded()) {
            return;
        }

        (async function loadProjectDatabases() {

            const url = ServerRoutesGen.ProjectDatabase.GetDatabasesShortInfo.patch(projectId);
            const response = await fetchServerElements<GeovisMstShortInfoModel[]>(Server, url);

            setState(processFetchedData(response, state, chartSensorsSelectDialogInitialState, st => ({
                databases: st,
                sensorsInfo: new Map<string, SensorInfoMini>(),
                chainsInfo: new Map<string, ChainInfo>(),
            })));

            // trigger reload elements
            setFilter({ ...filter, databaseIds: [] });
        })();

    }, [1]);


    // load/reload list of sensors
    useEffect(() => {

        // avoid unnecessary loading of data
        if (!canDataBeLoaded()) {
            return;
        }

        // list of database ids should be loaded first
        if (!state.databases || state.databases.length === 0) {
            return;
        }

        (async function loadItemsInfo() {

            // set to loading state
            setState({ ...state, ...defaultSomethingStorageState });

            if (dialogType === 'chains') {

                const chainsResponse = await loadAvailableChainInfos();
                setState(processFetchedData(chainsResponse, state, chartSensorsSelectDialogInitialState, st => ({
                    databases: state.databases,
                    sensorsInfo: new Map<string, SensorInfoMini>(),
                    chainsInfo: elementsToMap(st)
                })));

                if (!chainsResponse.Success) {
                    FlagService.addErrors("Failed to load chains info", chainsResponse.Messages);
                    return;
                }

            }
            else {

                const sensorsResponse = await loadAvailableSensorInfos();
                setState(processFetchedData(sensorsResponse, state, chartSensorsSelectDialogInitialState, st => ({
                    databases: state.databases,
                    sensorsInfo: elementsToMap(st),
                    chainsInfo: new Map<string, ChainInfo>()
                })));


                if (!sensorsResponse.Success) {
                    FlagService.addErrors("Failed to load sensors info", sensorsResponse.Messages);
                    return;
                }
            }
        })();

    }, [state.databases]);

    const sensorTypeFilter = (targetSensorType: SensorCategory, axisSensorType: any, isTemperatureSensorType: boolean, isInclinometerChainPosition: boolean = false): boolean => {
        if (isTheSameSensorType(SensorCategory.MST_SB, axisSensorType)) {
            return isTheSameSensorType(SensorCategory.MST, targetSensorType) || isTheSameSensorType(SensorCategory.SB, targetSensorType);
        }

        if (isTheSameSensorType(targetSensorType, axisSensorType)) {
            return true;
        }

        if (isTemperatureSensorType && (isTheSameSensorType(targetSensorType, SensorCategory.Inclinometer) || isTheSameSensorType(targetSensorType, SensorCategory.HydrostaticLevel))) {
            return true;
        }

        return isInclinometerChainPosition;
    }

    const onShowPublicChanged = (showPublic: boolean | undefined, selectedIds: string[]) => {
        setFilter({ ...filter, showPublic, selectedSensorIds: selectedIds });
    }

    const onSelectedDatabaseIdsChanged = (databaseIds: string[], selectedIds: string[]) => {
        setFilter({ ...filter, databaseIds, selectedSensorIds: selectedIds });
    }

    const onSearchTextChanged = (searchText: string, selectedIds: string[]) => {
        setFilter({ ...filter, searchQuery: searchText, selectedSensorIds: selectedIds });
    }

    const onFilterSelectedChanged = (useFilterForSelectedItems: boolean, selectedIds: string[]) => {
        setFilter({ ...filter, useFilterForSelectedItems, selectedSensorIds: selectedIds });
    }

    switch (dialogType) {
        case "base": {
            if (!onSelectedAxisSensors) {
                FlagService.addError("Select sensors: Error of definition", 'onSelectSensorsPairs not defined')
                return null;
            }

            if (yAxisSensorType === undefined || yAxisSensorType === null) {
                FlagService.addError(t("Define sensor types"), t('Sensor types for Y-axis should be defined'));
                return null;
            }

            const isTemperatureSensorType = isTheSameSensorType(yAxisSensorType, SensorCategory.Temperature);
            const isInclinometerChainPosition = yAxisSensorType === SensorCategory.InclinometerChainPosition;

            const allSensorsFilterFunc = (sensor: SensorInfoMini): boolean => {
                const isNameMatch: boolean = filter.searchQuery !== '' && (isMatchToSearchString(sensor.Name, filter.searchQuery) || isMatchToSearchString(sensor.DatabaseName, filter.searchQuery)) || filter.searchQuery === '';
                const isPublicMatch: boolean = filter.showPublic && sensor.IsPublic || filter.showPublic === undefined || filter.showPublic === false && !sensor.IsPublic;
                const isDatabaseMatch: boolean = filter.databaseIds.length === 0 || filter.databaseIds.length > 0 && filter.databaseIds.includes(sensor.DatabaseId);

                return isNameMatch && isPublicMatch && isDatabaseMatch;
            }

            const selectedItemsFilterFunc = (sensor: SensorInfoMini): boolean => {
                if (!filter.useFilterForSelectedItems) {
                    return true;
                }

                return allSensorsFilterFunc(sensor);
            }

            const allItems = mapToListOfElements(state.sensorsInfo, (i => sensorTypeFilter(i.PhysicalType, yAxisSensorType, isTemperatureSensorType, isInclinometerChainPosition)));

            const selectedItems = mapToListOfElementsOfIds(state.sensorsInfo, filter.selectedSensorIds);

            return (
                <SelectSensorsDialog
                    heading={t("Select sensors")}
                    isLoading={state.isLoading}
                    allItems={allItems}
                    selectedItems={selectedItems}
                    onSelected={onSelectedAxisSensors}
                    onCanceled={onCanceled}
                    projectMsts={state.databases}
                    showPublic={filter.showPublic}
                    onShowPublicChanged={onShowPublicChanged}
                    selectedDatabaseIds={filter.databaseIds}
                    onDatabaseIdsChanged={onSelectedDatabaseIdsChanged}
                    onFilterSelectedChanged={onFilterSelectedChanged}
                    onSearchTextChanged={onSearchTextChanged}
                    searchQuery={filter.searchQuery}
                    useFilterForSelected={filter.useFilterForSelectedItems}
                    selectedItemsFilterFunc={selectedItemsFilterFunc}
                    allItemsFilterFunc={allSensorsFilterFunc}
                    sensorChanges={sensorChanges}
                />
            )
        }

        case "pairs": {
            if (!onSelectSensorsPairs) {
                FlagService.addError("Select pairs: Error of definition", 'onSelectSensorsPairs not defined')
                return null;
            }

            if (!yAxisSensorType || !xAxisSensorType) {
                FlagService.addError(t("Define sensor types"), t('Sensor types for both axes should be defined to select pairs'))
                return null;
            }

            const yAxisSensors = mapToListOfElements(state.sensorsInfo).filter(i => isTheSameSensorType(i.PhysicalType, yAxisSensorType));
            const xAxisSensors = mapToListOfElements(state.sensorsInfo).filter(i => isTheSameSensorType(i.PhysicalType, xAxisSensorType));

            return (
                <SelectSensorsPairsDialog
                    allXSensors={xAxisSensors}
                    allYSensors={yAxisSensors}
                    heading={t("Select sensors pairs")}
                    onCanceled={onCanceled}
                    onSelected={onSelectSensorsPairs}
                    projectMsts={state.databases}
                    selectedPairs={selectedSensorsPairs ?? []}
                    isLoading={state.isLoading}
                />
            )
        }

        case 'single': {
            if (!onSelectSingleSensor) {
                FlagService.addError("Select single sensor: Error of definition", 'onSelectSensorsPairs not defined')
                return null;
            }

            const allSingleItems = mapToListOfElements(state.sensorsInfo);

            return (
                <SelectSingleSensorDialog
                    selectedSensorId={filter.selectedSensorId ?? ""}
                    heading={t("Select sensor")}
                    allItems={allSingleItems}
                    onCanceled={onCanceled}
                    isLoading={state.isLoading}
                    projectMsts={state.databases}
                    onSelected={onSelectSingleSensor}
                />
            )
        }

        case 'chains': {
            if (!onSelectedChains) {
                FlagService.addError("Select single sensor: Error of definition", 'onSelectSensorsPairs not defined')
                return null;
            }

            const allChains = mapToListOfElements(state.chainsInfo)

            const onSelectedChainsHandler = (chainIds: string[]) => {
                const selectedChains = mapToListOfElements(state.chainsInfo).filter(ch => chainIds.includes(ch.Id));
                onSelectedChains(selectedChains);
            }
            return (
                <SelectChainsDialog
                    allItems={allChains}
                    heading={t("Select chains")}
                    onCanceled={onCanceled}
                    isLoading={state.isLoading}
                    projectMsts={state.databases}
                    selectedChainsIds={filter.selectedChainsIds ?? []}
                    onSelected={onSelectedChainsHandler}
                />
            )
        }
    }
})