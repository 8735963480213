/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 04.02.2020
 * @description Near geopoints icon renderer
 */

import { circleIconPrimitive, withSvgOfIconProps } from "./IconPrimitives";
import {
    defaultLeafletMapIconProps,
    ISVGIconProps
} from "./types";

/**
 * Near geopoints icon renderer
 * @param props 
 */
export const MultipleSensorsIconRenderer = (props: ISVGIconProps): string => {

    const {
        iconSize,
        fillColors
    } = props;

    let svg = '';
    const countPoints = fillColors.length;

    if (countPoints === 1) {
        const outerCircleProps: ISVGIconProps = {
            ...defaultLeafletMapIconProps,
            iconSize,
            iconAnchor: props.iconAnchor,
            fillColors: [...fillColors]
        };

        svg = circleIconPrimitive(outerCircleProps);
    }
    else {
        const centerX = iconSize.x / 2;
        const centerY = iconSize.y / 2;

        const radius = centerX - props.weight;

        const {
            color,
            weight
        } = props;

        for (let index = 0; index < countPoints; index++) {
            const fillColor = fillColors[index];

            const startAngle = 2 * Math.PI * index / countPoints;
            const endAngle = 2 * Math.PI * (index + 1) / countPoints;

            const startX = radius * Math.cos(startAngle) + centerX;
            const startY = radius * Math.sin(startAngle) + centerY;

            const endX = radius * Math.cos(endAngle) + centerX;
            const endY = radius * Math.sin(endAngle) + centerY;

            svg += `<path d="
                            M ${centerX},${centerY} 
                            L ${startX},${startY}
                            A ${radius},${radius} 0 0 1 ${endX} ${endY} Z" 
                        stroke="${color}" stroke-width="${weight}" fill="${fillColor}"/>`;
        }
    }

    // draw inner circle
    const circleOptions: ISVGIconProps = {
        ...defaultLeafletMapIconProps,
        iconSize: L.point(iconSize.x * 0.55, iconSize.y * 0.55),
        iconAnchor: L.point(iconSize.x / 2 - props.weight, iconSize.y / 2 - props.weight),
        fillColors: ['white']
    };
    svg += circleIconPrimitive(circleOptions);

    // draw text
    {
        const textX = 0.5 * iconSize.x;
        const textY = 0.61 * iconSize.y;
        const textText = +props.iconText > 9 ? ":D" : props.iconText;

        svg += `<text x="${textX}" y="${textY}" font-size="${props.fontSize}px" text-anchor="middle ">${textText}</text>`;
    }

    return withSvgOfIconProps({
        ...props,
        iconText: false
    })(svg);
}