/**
 * @description Project edit action creators
 */

import { SortOrderType } from "@atlaskit/dynamic-table/dist/types/types";
import { GEOvisDXFLayerTypeList } from "../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { ProjectElementType } from "../../server/AVTService/TypeLibrary/Common/ProjectElementType";
import { GvSensorDataInfo } from "../../server/AVTService/TypeLibrary/DB/ArgusDataStorage/SensorsData/GvSensorDataInfo";
import { SensorsDataFilter } from "../../server/AVTService/TypeLibrary/DB/ArgusDataStorage/SensorsDataFilter";
import { GeovisUserToProjectRole } from "../../server/AVTService/TypeLibrary/Identity/GeovisUserToProjectRole";
import { LocalMapObject } from "../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject";
import { LocalMapObjectType } from "../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectType";
import { GeovisReportAvailableElementsModel } from "../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportAvailableElementsModel";
import { GeovisReportElementInfoSlim } from "../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfoSlim";
import { ProjectViewConfig } from "../../server/AVTService/TypeLibrary/ProjectViews/ProjectViewConfig";
import { DataActionResponse } from "../../server/DataActionResponse";
import { ProjectViewParentInfo } from "../../server/GEOvis3/Model/ProjectViews/ProjectViewParentInfo";
import { GvSensorDataResponse } from "../../server/GEOvis3/Model/SensorsData/GvSensorDataResponse";
import { ProjectInfo } from "../../server/ProjectInfo";
import { ProjectReportInfo } from "../../server/ProjectReportInfo";
import {
    PROJECT_EDIT_ACCESS_CONTROL_HIDE_EXPORT_USERS_DIALOG,
    PROJECT_EDIT_ACCESS_CONTROL_HIDE_USERS_DIALOG,
    PROJECT_EDIT_ACCESS_CONTROL_HIDE_USER_DIALOG,
    PROJECT_EDIT_ACCESS_CONTROL_SHOW_EXPORT_USERS_DIALOG,
    PROJECT_EDIT_ACCESS_CONTROL_SHOW_USERS_DIALOG,
    PROJECT_EDIT_ACCESS_CONTROL_SHOW_USER_DIALOG,
    PROJECT_EDIT_ATTACHED_DOCUMENTS_SWITCH_ROWS_PER_PAGE,
    PROJECT_EDIT_ATTACHED_DOCUMENT_CLEANUP_UPLOADING_PROGRESS,
    PROJECT_EDIT_ATTACHED_DOCUMENT_DELETE_DIALOG,
    PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_DIALOG,
    PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_SENSORS_SELECT_DIALOG,
    PROJECT_EDIT_ATTACHED_DOCUMENT_SHOW_SENSORS_SELECT_DIALOG,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_END,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_ERROR,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_END,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_START,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_PROCESSING,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_START,
    PROJECT_EDIT_DXF_DELETE_DIALOG,
    PROJECT_EDIT_DXF_DELETING_FILE_MODEL,
    PROJECT_EDIT_DXF_HIDE_DIALOGS,
    PROJECT_EDIT_DXF_REBUILD_MAP_TILES_DIALOG,
    PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MAX_ZOOM,
    PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MIN_ZOOM,
    PROJECT_EDIT_DXF_REBUILD_MAP_TILES_SEGMENT_LENGTH,
    PROJECT_EDIT_DXF_UPLOAD_DIALOG,
    PROJECT_EDIT_DXF_UPLOAD_DIALOG_TYPE,
    PROJECT_EDIT_DXF_UPLOAD_FILES_STATE,
    PROJECT_EDIT_EDIT_DATABASE_HIDE,
    PROJECT_EDIT_EDIT_DATABASE_SHOW,
    PROJECT_EDIT_GEORASTER_CHANGE_UPLOAD_STATE,
    PROJECT_EDIT_GEORASTER_HIDE_DIALOGS,
    PROJECT_EDIT_GEORASTER_SHOW_REMOVE_DIALOG,
    PROJECT_EDIT_LABELS_DELETE_HIDE,
    PROJECT_EDIT_LABELS_DELETE_SHOW,
    PROJECT_EDIT_LABELS_EDIT_HIDE,
    PROJECT_EDIT_LABELS_EDIT_SHOW,
    PROJECT_EDIT_LABELS_HIDE,
    PROJECT_EDIT_LABELS_SHOW, PROJECT_EDIT_LOCAL_MAP_OBJECTS_CHOOSE_COORDINATES_FOR,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_CLEANUP_EDIT_STATE,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_CREATE_NEW_ELEMENT,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_EDIT,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_SEARCH_QUERY,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_SET_VIEW,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_SHOW_DELETE_DIALOG, PROJECT_EDIT_LOCAL_MAP_OBJECT_ADD,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_CHANGE_IS_PUBLIC,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_REMOVE,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_START_EDITING_OBJECTS,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_STOP_EDITING_OBJECTS,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_UPDATE, PROJECT_EDIT_OVERVIEW_CHANGED, PROJECT_EDIT_PROPERTIES_INIT_STATE,
    PROJECT_EDIT_PROPERTIES_SAVING,
    PROJECT_EDIT_REMOVE_DATABASE_HIDE,
    PROJECT_EDIT_REMOVE_DATABASE_SHOW,
    PROJECT_EDIT_REMOVE_PROJECT,
    PROJECT_EDIT_REMOVE_PROJECT_HIDE,
    PROJECT_EDIT_REMOVE_PROJECT_SHOW,
    PROJECT_EDIT_REPORT_ADD_OR_UPDATE,
    PROJECT_EDIT_REPORT_ADD_TO_GROUPS_DIALOG_SHOW,
    PROJECT_EDIT_REPORT_ELEMENTS_LOADED,
    PROJECT_EDIT_REPORT_ELEMENTS_LOADING,
    PROJECT_EDIT_REPORT_HIDE_ANY_DIALOG,
    PROJECT_EDIT_REPORT_REMOVED,
    PROJECT_EDIT_REPORT_SHOW_COPY_DIALOG,
    PROJECT_EDIT_REPORT_SHOW_REMOVE_DIALOG,
    PROJECT_EDIT_REPORT_UPDATE_ELEMENT_ENTRY,
    PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION,
    PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION_OF_ALL,
    PROJECT_EDIT_SENSORS_DATA_CHANGE_SORT,
    PROJECT_EDIT_SENSORS_DATA_DATABASES_SELECTED,
    PROJECT_EDIT_SENSORS_DATA_EDIT_RECORD,
    PROJECT_EDIT_SENSORS_DATA_FILTER_CHANGED,
    PROJECT_EDIT_SENSORS_DATA_HIDE_EDIT_DIALOG,
    PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG,
    PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG_AND_RELOAD,
    PROJECT_EDIT_SENSORS_DATA_LOADED,
    PROJECT_EDIT_SENSORS_DATA_LOADING,
    PROJECT_EDIT_SENSORS_DATA_RELOAD,
    PROJECT_EDIT_SENSORS_DATA_REMOVE_RECORD,
    PROJECT_EDIT_SENSORS_DATA_SHOW_REMOVE_ATTRIBUTES_DIALOG,
    PROJECT_EDIT_SENSORS_DATA_UPDATE_RECORD,
    PROJECT_EDIT_SENSORS_LIST_FILTER_CHANGED,
    PROJECT_EDIT_SENSORS_LIST_FILTER_LOADED,
    PROJECT_EDIT_SENSORS_LIST_FILTER_LOADING,
    PROJECT_EDIT_SENSORS_LIST_LOADED,
    PROJECT_EDIT_SENSORS_LIST_LOADING,
    PROJECT_EDIT_VIEWS_CONFIG,
    PROJECT_EDIT_VIEWS_CONFIG_DATA,
    PROJECT_EDIT_VIEWS_CONFIG_ERROR,
    PROJECT_EDIT_VIEWS_HIDE_DIALOGS,
    PROJECT_EDIT_VIEW_ADD_OR_UPDATE,
    PROJECT_EDIT_VIEW_CHANGE_PUBLIC,
    PROJECT_EDIT_VIEW_CHANGE_USERS,
    PROJECT_EDIT_VIEW_DELETE,
    PROJECT_EDIT_VIEW_DELETE_DIALOG,
    PROJECT_EDIT_VIEW_SET_AS_DEFAULT,
    PROJECT_EDIT_VIEW_SET_IS_DEFAULT_EXPANDED,
    PROJECT_EDIT_VIEW_UPDATE_PARENT_INFO,
    PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_HIDE,
    PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_SHOW,
    PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_HIDE,
    PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_SHOW,
    PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_HIDE,
    PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_SHOW,
    PROJECT_ELEMENT_COPY_DIALOG_SHOW,
    PROJECT_ELEMENT_HIDE_ELEMENT_DIALOG,
    PROJECT_ELEMENT_REMOVE_DIALOG_SHOW,
    PROJECT_EDIT_SENSORS_MANUAL_IMPORT_RELOAD
} from "../actions/projectEditActions";
import { IGeovisProjectEditAction, IGvSensorDataInfoModel } from "../edit.types";
import { GeovisUploadFileState } from "../uploading.types";
import { ProjectSensorsEditResponseModel } from "../../server/GEOvis3/Model/SensorsEdit/ProjectSensorsEditResponseModel";
import { GeovisSensorListFilterModel } from "../../server/AVTService/TypeLibrary/Model/GeovisSensorListFilterModel";
import { ProjectSensorsInitialInfoModel } from "../../server/GEOvis3/Model/SensorsEdit/ProjectSensorsInitialInfoModel";
import { GeovisSensorsDataFilter } from "../../server/AVTService/TypeLibrary/DB/ArgusDataStorage/GeovisSensorsDataFilter";

/**
 * Switch UI to "save in progress" mode (spinner on a button "Save")
 * @param project 
 */
export const projectEditPropertiesSaving = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_PROPERTIES_SAVING
});

/**
 * Init project edit fields after loading the page
 * @param project 
 */
export const projectEditPropertiesInitState = (project: ProjectInfo): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_PROPERTIES_INIT_STATE, project
})

export function projectEditDxfDeleteLayerDialog(layerId: string): IGeovisProjectEditAction {
    return { type: PROJECT_EDIT_DXF_DELETE_DIALOG, layerId };
}

export const projectEditDxfDeletingFileModel = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_DXF_DELETING_FILE_MODEL
})

export const projectEditDxfLayersRebuildMapTilesMaxZoom = (zoom: number): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MAX_ZOOM, zoom
});
export const projectEditDxfLayersRebuildMapTilesMinZoom = (zoom: number): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MIN_ZOOM, zoom
});

export const projectEditDxfLayersRebuildMapTilesSegmentLength = (useSegmentLength: boolean): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_DXF_REBUILD_MAP_TILES_SEGMENT_LENGTH, useSegmentLength
});

export function projectEditDxfUploadFileState(files: Array<Partial<GeovisUploadFileState>>): IGeovisProjectEditAction {
    return { type: PROJECT_EDIT_DXF_UPLOAD_FILES_STATE, uploadingFiles: files };
}

export function projectEditDxfUploadLayersDialog(selectedCoordinateSystem?: string): IGeovisProjectEditAction {
    return { type: PROJECT_EDIT_DXF_UPLOAD_DIALOG, selectedCoordinateSystem };
}

export function projectEditDxfUploadLayersDialogType(type: number): IGeovisProjectEditAction {
    return { type: PROJECT_EDIT_DXF_UPLOAD_DIALOG_TYPE, selectedDxfType: GEOvisDXFLayerTypeList[type] };
}

export const projectEditHideDxfLayerDialogs = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_DXF_HIDE_DIALOGS
});

export function projectEditDxfLayerRebuildMapTilesDialog(layerId: string, isProfileView: boolean): IGeovisProjectEditAction {
    return { type: PROJECT_EDIT_DXF_REBUILD_MAP_TILES_DIALOG, layerId, booleanProperty: isProfileView };
}

//#region  Local map objects

/**
 * Shows edit local map object dialog
 * @param lmoId the local map object id
 */
export const projectEditLocalMapObjectEdit = (lmoId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECTS_EDIT, lmoId
})

/**
 * Set view for the edit local map objects page
 * @param viewValue the view value
 */
export const projectEditLocalMapObjectSetView = (viewValue: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECTS_SET_VIEW,
    localMapObjectsViewValue: viewValue
});

/**
 * Start editing local map objects action
 */
export const projectEditLocalMapObjectStartEditObjects = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECT_START_EDITING_OBJECTS
});

/**
 * Stop editing local map objects action
 */
export const projectEditLocalMapObjectStopEditObjects = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECT_STOP_EDITING_OBJECTS
});

/**
 * Update local map object in the object storage
 * @param localMapObjects Local map objects to update
 */
export const projectEditLocalMapObjectsUpdate = (localMapObjects: LocalMapObject[]): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECT_UPDATE, localMapObjects
});

/**
 * Switch IsPublic property for local map object without reloading documents
 * @param lmoId The object id
 * @param isPublic IsPublic property value
 */
export const projectEditLocalMapObjectChangeIsPublic = (lmoId: string, isPublic: boolean): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECT_CHANGE_IS_PUBLIC, lmoId, isPublic
});

/**
 * Remove local map object in storage
 * @param lmoId
 */
export const projectEditLocalMapObjectRemove = (lmoId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECT_REMOVE, lmoId
});

/**
 * Save local map object to storage
 * @param localMapObject 
 */
export const projectEditLocalMapObjectAdd = (localMapObject: LocalMapObject): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECT_ADD, localMapObjects: [localMapObject]
});

//#endregion

/**
 * Show the delete attached document dialog
 * @param documentId 
 */
export const projectEditAttachedDocumentDeleteDialog = (documentId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_DELETE_DIALOG, documentId
});

/**
 * Hide all dialogs
 */
export const projectEditAttachedDocumentsHideDialogs = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_DIALOG
});

/**
 * Show select sensors dialog for a project attached document
 */
export const projectEditAttachedDocumentShowSelectSensorsDialog = (documentId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_SHOW_SENSORS_SELECT_DIALOG, documentId
});

/**
 * Hide select sensors dialog for a project attached document
 */
export const projectEditAttachedDocumentHideSelectSensorsDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_SENSORS_SELECT_DIALOG
});

/**
 * Show files on uploading progress overlay
 * @param uploadingFiles List of files
 */
export const projectEditAttachedDocumentUploadStart = (uploadingFiles: Array<Partial<GeovisUploadFileState>>): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_START, uploadingFiles
});

/**
 * Hide file from uploading overlay
 * @param uploadFile File, which was uploaded
 */
export const projectEditAttachedDocumentUploadEnd = (uploadFile: Partial<GeovisUploadFileState>): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_END, uploadingFiles: [uploadFile]
})

/**
 * Show file in error state on a uploading overlay
 * @param uploadFile 
 */
export const projectEditAttachedDocumentUploadError = (uploadFile: Partial<GeovisUploadFileState>): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_ERROR, uploadingFiles: [uploadFile]
});

/**
 * Update uploading progress of file on an uploading overlay
 * @param uploadFile 
 */
export const projectEditAttachedDocumentUploadProcessing = (uploadFile: Partial<GeovisUploadFileState>): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_PROCESSING, uploadingFiles: [uploadFile]
})

/**
 * Clean all uploading progress information (went to close progress dialog)
 */
export const projectEditAttachedDocumentCloseUploadingProgressDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_CLEANUP_UPLOADING_PROGRESS
})

export const projectEditAttachedDocumentUploadNewVersionStart = (documentId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_START, documentId
})

export const projectEditAttachedDocumentUploadNewVersionEnd = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_END
})

export const projectEditAttachedDocumentsSwitchRowsPerPage = (rowsPerPage: number): IGeovisProjectEditAction => {
    return { type: PROJECT_EDIT_ATTACHED_DOCUMENTS_SWITCH_ROWS_PER_PAGE, rowsPerPage };
}

/**
 * Cleanup project edit local map object edit state
 */
export const projectEditLocalMapObjectsCleanupEditState = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECTS_CLEANUP_EDIT_STATE
})

/**
 * Show delete local map object dialog
 * @param lmoId
 */
export const projectEditLocalMapObjectsShowDeleteDialog = (lmoId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECTS_SHOW_DELETE_DIALOG, lmoId
});

/**
 * Change local map objects search query
 * @param searchQuery the query text
 */
export const projectEditLocalMapObjectsSearchQuery = (searchQuery: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECTS_SEARCH_QUERY, searchQuery
})

/**
 * Switch to mode to choose coordinates for new local map object
 * @param localMapObjectType Local map object type
 */
export const projectEditLocalMapObjectsChooseCoordinatesFor = (localMapObjectType: LocalMapObjectType): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECTS_CHOOSE_COORDINATES_FOR, localMapObjectType
})

/**
 * Show dialog to edit properties of new local map object
 */
export const projectEditLocalMapObjectCreateNewElement = (localMapObjectProps: LocalMapObject): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LOCAL_MAP_OBJECTS_CREATE_NEW_ELEMENT, localMapObjectProps
});

/**
 * Show dialog to create new project view
 */
// export const projectEditViewCreate = (): IGeovisProjectEditAction => ({
//     type: PROJECT_EDIT_VIEW_CREATE
// });

/**
 * Show delete dialog for project view
 * @param viewId
 */
export const projectEditViewDeleteDialog = (viewId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEW_DELETE_DIALOG, viewId
});

/**
 * Hide all dialogs in project views edit page
 */
export const projectEditViewsHideDialogs = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEWS_HIDE_DIALOGS
});

// /**
//  * Show edit project view dialog
//  * @param viewId
//  */
// export const projectEditView = (viewId: string): IGeovisProjectEditAction => ({
//     type: PROJECT_EDIT_VIEW, viewId
// });

/**
 * Show select users dialog in project access control
 * @param targetRole 
 */
export const projectEditAccessControlShowUsersDialog = (targetRole: GeovisUserToProjectRole): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ACCESS_CONTROL_SHOW_USERS_DIALOG, targetRole
});

/**
 * Hide select users dialog in project access control
 */
export const projectEditAccessControlHideUsersDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ACCESS_CONTROL_HIDE_USERS_DIALOG
});

/**
 * Show user edit dialog in project access control
 * @param userId 
 */
export const projectEditAccessControlShowUserEditDialog = (userId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ACCESS_CONTROL_SHOW_USER_DIALOG, userId
});

/**
 * Hide used edit dialog in project access control
 */
export const projectEditAccessControlHideUserDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ACCESS_CONTROL_HIDE_USER_DIALOG
});

/**
 * Show the dialog 'Export users of the project'
 * @returns 
 */
export const projectEditAccessControlShowExportUsersDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ACCESS_CONTROL_SHOW_EXPORT_USERS_DIALOG
});

/**
 * Hide the dialog 'Export users of the project'
 * @returns 
 */
export const projectEditAccessControlHideExportUsersDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_ACCESS_CONTROL_HIDE_EXPORT_USERS_DIALOG
});

//#region Project views

/**
 * Navigate to project edit views page
 */
export const projectEditViewsConfig = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEWS_CONFIG
});

/**
 * Store project views to local config storage
 * @param projectViews 
 */
export const projectEditViewsConfigData = (projectViews: ProjectViewConfig[]): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEWS_CONFIG_DATA, projectViews
});

/**
 * Set project views configuration storage in to error state
 * @param errorDescription 
 */
export const projectEditViewsConfigError = (errorDescription: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEWS_CONFIG_ERROR, errorDescription
})

/**
 * Delete project view in local storage (without reloading of all views)
 * @param viewId
 * @param defaultViewId
 */
export const projectEditViewDelete = (viewId: string, defaultViewId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEW_DELETE, viewId, stringProperty: defaultViewId
});

/**
 * Add project view to storage
 * @param view 
 */
export const projectEditViewAddOrUpdate = (view: ProjectViewConfig): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEW_ADD_OR_UPDATE, projectViews: [view]
});

/**
 * Change IsPublic of the project view
 * @param objectId - view object id
 * @param isPublic 
 */
export const projectEditChangeIsPublicForView = (viewId: string, isPublic: boolean): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEW_CHANGE_PUBLIC, isPublic, viewId
});

/**
 * Set project view as default (in project model)
 * @param viewId 
 */
export const projectEditViewSetAsDefault = (viewId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEW_SET_AS_DEFAULT, viewId
});

/**
 * Set view is default expanded in project overview page
 * @param viewId 
 * @param isExpanded 
 * @returns 
 */
export const projectEditViewSetIsDefaultExpanded = (viewId: string, isExpanded: boolean): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEW_SET_IS_DEFAULT_EXPANDED, viewId, booleanProperty: isExpanded
});

/**
 * Update project view parent info in the storage
 * @param viewsOrderInfo
 * @returns 
 */
export const projectEditViewUpdateParentInfo = (viewsOrderInfo: ProjectViewParentInfo[]): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEW_UPDATE_PARENT_INFO, viewsOrderInfo
});

export const projectEditViewChangeUsers = (viewId: string, userIds: string[]): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_VIEW_CHANGE_USERS, viewId, userIds
});

export const projectEditOverviewChanged = (nameOfOverview: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_OVERVIEW_CHANGED, stringProperty: nameOfOverview
});

//#endregion

//#region Labels


export const projectLabelsShow = (): IGeovisProjectEditAction => ({ type: PROJECT_EDIT_LABELS_SHOW });

export const projectLabelsHide = (): IGeovisProjectEditAction => ({ type: PROJECT_EDIT_LABELS_HIDE });

export const projectLabelsEditShow = (labelId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LABELS_EDIT_SHOW, labelId
});

export const projectLabelsEditHide = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LABELS_EDIT_HIDE
});

export const projectLabelsDeleteShow = (labelId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LABELS_DELETE_SHOW, labelId
});

export const projectLabelsDeleteHide = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_LABELS_DELETE_HIDE
});

//#endregion

//#region Databases

export const projectEditEditDatabaseShow = (databaseId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_EDIT_DATABASE_SHOW, databaseId
});

export const projectEditEditDatabaseHide = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_EDIT_DATABASE_HIDE
});

export const projectEditRemoveDatabaseShow = (databaseId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REMOVE_DATABASE_SHOW, databaseId
});

export const projectEditRemoveDatabaseHide = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REMOVE_DATABASE_HIDE
});

//#endregion

//#region Project remove

export const projectEditShowRemoveDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REMOVE_PROJECT_SHOW
});

export const projectEditHideRemoveDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REMOVE_PROJECT_HIDE
});

export const projectEditRemoveProject = (projectId: number): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REMOVE_PROJECT, projectId
});

//#endregion

//#region Project sensors data creators

/**
 * Set sensors data filter to the storage (to trigger data reload)
 * @param sensorsDataFilter 
 * @returns 
 */
export const projectEditSensorsDataFilterChanged = (sensorsDataFilter: Partial<SensorsDataFilter>): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_FILTER_CHANGED, sensorsDataFilter
});

/**
 * Set sensor storage to loading state
 */
export const projectEditSensorsDataLoading = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_LOADING
});

/**
 * Set loaded sensors data to project sensors data storage
 * @param sensorsData - loaded sensors data
 * @returns 
 */
export const projectEditSensorsDataLoaded = (sensorsData: DataActionResponse<GvSensorDataResponse>, firstDataLoad: boolean, filter?: GeovisSensorsDataFilter): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_LOADED, sensorsData, booleanProperty: firstDataLoad, sensorDataFilter: filter
});

/**
 * Show the edit data record dialog
 * Using exact the record object is needed to avoid long search in huge array og records
 * @param recordUid
 * @returns 
 */
export const projectEditSensorsDataEditRecord = (recordUid: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_EDIT_RECORD, stringProperty: recordUid
});

/**
 * Hide edit sensor data record dialog
 * @returns 
 */
export const projectEditSensorsDataHideEditDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_HIDE_EDIT_DIALOG
});

export const projectEditSensorsDataChangeSelectionOfAll = (isAllSelected: boolean): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION_OF_ALL, booleanProperty: isAllSelected
});

export const projectEditSensorsDataChangeSort = (columnKey: string, sortOrder: SortOrderType): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_CHANGE_SORT, stringProperty: columnKey, sortOrder
});

export const projectEditSensorsDataChangeSelection = (selected: IGvSensorDataInfoModel[]): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION, selectedRecords: selected
})

/**
 * Updates the sensor data record in storage
 * @param recordUid 
 * @param sensorDataRecord 
 * @returns 
 */
export const projectEditSensorsDataUpdateRecord = (recordUid: string, sensorDataRecord: GvSensorDataInfo): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_UPDATE_RECORD, stringProperty: recordUid, sensorDataRecord
});

/**
 * Remove the sensor data record in the storage
 * @param recordUid
 * @returns 
 */
export const projectEditSensorsDataRemoveRecord = (recordUid: string,): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_REMOVE_RECORD, stringProperty: recordUid
});

/**
 * Reload sensor data
 * @returns 
 */
export const projectEditSensorsDataReload = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_RELOAD
})

/**
 * Store list of selected database ids in to storage, needs for depended data filters
 * @param databaseIds 
 * @returns 
 */
export const projectEditSensorsDataDatabasesSelected = (databaseIds: string[]): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_DATABASES_SELECTED, databaseIds
})

export const projectEditSensorsDataShowRemoveAttributesDialog = (selectedSensorsData: GvSensorDataInfo[]): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_SHOW_REMOVE_ATTRIBUTES_DIALOG, selectedSensorsData
})

export const projectEditSensorsDataHideRemoveAttributesDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG,
})

export const projectEditSensorsDataHideRemoveAttributesDialogAndReload = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG_AND_RELOAD
})

//#endregion

//#region Reports

/**
 * Update report info in storage
 * @param reportInfo 
 * @returns 
 */
export const projectEditReportAddOrUpdate = (reportInfo: ProjectReportInfo): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REPORT_ADD_OR_UPDATE, reportInfo
});

/**
 * Show report report dialog
 * @param reportId 
 * @returns 
 */
export const projectEditReportShowRemoveDialog = (reportId: number): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REPORT_SHOW_REMOVE_DIALOG, numberProperty: reportId
});

/**
 * Hide any delete/copy dialogs
 * @returns 
 */
export const projectEditReportHideAnyDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REPORT_HIDE_ANY_DIALOG
})

/**
 * Show copy report dialog
 * @param reportId 
 * @returns 
 */
export const projectEditReportShowCopyDialog = (reportId: number): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REPORT_SHOW_COPY_DIALOG, numberProperty: reportId
})

/**
 * Remove project info in storage
 * @param reportId 
 * @returns 
 */
export const projectEditReportRemoved = (reportId: number): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REPORT_REMOVED, numberProperty: reportId
});

/**
 * Set report elements in loading state
 * @returns 
 */
export const projectEditReportElementsLoading = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REPORT_ELEMENTS_LOADING
})

/**
 * Set report available elements in to storage
 * @param reportElements 
 * @returns 
 */
export const projectEditReportElementsLoaded = (reportElements: DataActionResponse<GeovisReportAvailableElementsModel>): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REPORT_ELEMENTS_LOADED, reportElements
})

/**
 * Update project element entry in project edit storage
 * @param reportElement 
 * @returns 
 */
export const projectEditReportElementUpdate = (reportElement: GeovisReportElementInfoSlim): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REPORT_UPDATE_ELEMENT_ENTRY, reportElement
});

/**
 * Show a dialog to add a report to groups
 * @param reportId 
 * @returns 
 */
export const projectEditReportShowAddToGroupsDialog = (reportId: number): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_REPORT_ADD_TO_GROUPS_DIALOG_SHOW, numberProperty: reportId
});

//#endregion

// #region Project elements

/**
 * Show copy project element dialog
 * @param elementId 
 * @param elementType 
 * @param groupName
 * @returns 
 */
export const projectEditElementShowCopyDialog = (elementId: number, elementType: ProjectElementType, groupName: string = ''): IGeovisProjectEditAction => ({
    type: PROJECT_ELEMENT_COPY_DIALOG_SHOW, elementId, elementType, groupName
});

/**
 * Hide COPY OR REMOVE project element dialogs, EDIT dialog are managing by URL
 * @returns 
 */
export const projectEditElementHideElementDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_ELEMENT_HIDE_ELEMENT_DIALOG
});

/**
 * Show dialog to remove the project element
 * @param elementId 
 * @param elementType 
 * @param groupName
 * @returns 
 */
export const projectEditElementShowRemoveDialog = (elementId: number, elementType: ProjectElementType, groupName: string = '', removeFromGroupOnly: boolean = true): IGeovisProjectEditAction => ({
    type: PROJECT_ELEMENT_REMOVE_DIALOG_SHOW, elementId, elementType, groupName, booleanProperty: removeFromGroupOnly
});

/**
 * Show a dialog to remove a custom elements group 
 * @param groupName 
 * @returns 
 */
export const projectEditElementsShowRemoveGroupDialog = (groupName: string): IGeovisProjectEditAction => ({
    type: PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_SHOW, groupName
});

/**
 * Hide the dialog to remove a custom elements group 
 * @returns 
 */
export const projectEditElementsHideRemoveGroupDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_HIDE
});

/**
 * Show a dialog to edit the custom group's items
 * @param groupName 
 * @returns 
 */
export const projectEditElementsShowEditGroupDialog = (groupName: string): IGeovisProjectEditAction => ({
    type: PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_SHOW, groupName
});

/**
 * Hide the dialog to edit the custom group's items
 * @returns 
 */
export const projectEditElementsHideEditGroupDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_HIDE
});

/**
 * Show a dialog to add a project element to groups
 * @param elementId 
 * @param elementType 
 * @returns 
 */
export const projectEditElementShowAddToGroupsDialog = (elementId: number, elementType: ProjectElementType, groupName: string): IGeovisProjectEditAction => ({
    type: PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_SHOW, elementId, elementType, groupName
});

/**
 * Hide the dialog to add a project element to groups
 * @returns 
 */
export const projectEditElementHideAddToGroupsDialog = (): IGeovisProjectEditAction => ({
    type: PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_HIDE
});

// #endregion

export const projectEditGeorasterHideAllDialogs = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_GEORASTER_HIDE_DIALOGS
});

export const projectEditGeorasterShowDeleteDialog = (rasterId: string): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_GEORASTER_SHOW_REMOVE_DIALOG, stringProperty: rasterId
});

export const projectEditGeorasterChangeUploadState = (progress: number): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_GEORASTER_CHANGE_UPLOAD_STATE, numberProperty: progress
});

export const projectEditSensorsLoading = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_LIST_LOADING
});

export const projectEditSensorsLoaded = (sensorsListData: DataActionResponse<ProjectSensorsEditResponseModel>): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_LIST_LOADED, sensorsListData
});

export const projectEditSensorsFilterChanged = (filter: GeovisSensorListFilterModel): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_LIST_FILTER_CHANGED, filter
});

export const projectEditSensorsFilterDataLoading = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_LIST_FILTER_LOADING
});

export const projectEditSensorsFilterDataLoaded = (filterData: DataActionResponse<ProjectSensorsInitialInfoModel>): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_LIST_FILTER_LOADED, filterData
})

/**
 * Trigger reload manual import tasks list
 * @param taskId 
 * @returns 
 */
export const projectEditSensorsManualImportReload = (): IGeovisProjectEditAction => ({
    type: PROJECT_EDIT_SENSORS_MANUAL_IMPORT_RELOAD
});