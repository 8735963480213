//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ChartMeasureTimeCalcType {
	Auto = 0,
	Reduce = 1,
	DailyAt = 2,
	AtExactTimeslots = 3
}

const ChartMeasureTimeCalcTypeToName = new Map<number, string>([
	[ 0, 'Auto' ],
	[ 1, 'Reduce' ],
	[ 2, 'DailyAt' ],
	[ 3, 'AtExactTimeslots' ],
])

export function getChartMeasureTimeCalcTypeToName(element: ChartMeasureTimeCalcType) : string {
    return ChartMeasureTimeCalcTypeToName.get(element) || '';
}

const ChartMeasureTimeCalcTypeByName = new Map<string, number>([
	[ 'Auto', 0 ],
	[ 'Reduce', 1 ],
	[ 'DailyAt', 2 ],
	[ 'AtExactTimeslots', 3 ],
]);

export function getChartMeasureTimeCalcTypeByName(name: string): ChartMeasureTimeCalcType {
    return ChartMeasureTimeCalcTypeByName.get(name)!;
}


export const ChartMeasureTimeCalcTypeList : Readonly<ChartMeasureTimeCalcType[]> = [
	ChartMeasureTimeCalcType.Auto,
	ChartMeasureTimeCalcType.Reduce,
	ChartMeasureTimeCalcType.DailyAt,
	ChartMeasureTimeCalcType.AtExactTimeslots,
];



const ChartMeasureTimeCalcTypeToDescription = new Map<number, string>([
	[ 0, 'Use all measurements for the period' ],
	[ 1, 'Reduce nr. of measurements' ],
	[ 2, 'Show measurements daily' ],
	[ 3, 'Show measurements at' ],
])

export function getChartMeasureTimeCalcTypeToDescription(element: ChartMeasureTimeCalcType) : string {
    return ChartMeasureTimeCalcTypeToDescription.get(element) || '';
}

