/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 01.11.2019
 * @description Methods to helps uploading file to the Server
 */

import { GEOvisDXFLayerType } from "../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";

/**
 * Get attached files collection name for upload
 * @param projectId project identifier
 */
export const getAttachedFilesUploadCollection = (projectId: number): string =>
    `pId=${projectId}&collection=attachedDocumentsCollection`;

export const getAttachedFilesUpdateVersionUploadCollection = (projectId: number, documentId: string): string =>
    `pId=${projectId}&collection=attachedDocumentsCollection&docId=${documentId}&updateVersion=true`;

/**
 * Get the collection name for uploading for LMO background as map tiles
 * @param projectId 
 */
export const getLocalMapObjectBackgroundFilesAsMapTilesUploadCollection = (projectId: number): string => {
    return `pId=${projectId}&collection=localMapObjectBackgroundsAsMapTilesCollection`;
}

export const getGeovisImagesFilesUploadCollection = (projectId: number): string => {
    return `pId=${projectId}&collection=geovisImagesCollection`;
}

export const getSensorsImagesFilesUploadCollection = (projectId: number): string => {
    return `pId=${projectId}&collection=sensorImagesCollection`;
}

/**
 * Get collection name for uploading DXF layer as map tiles
 * @param coordinateSystem 
 * @param projectId 
 */
export const getUploadDxfFileCollectionString = (coordinateSystem: string, projectId: string | undefined, type: GEOvisDXFLayerType): string => {
    return `pId=${projectId}&collection=dxfMapTilesCollection&coordinateSystem=${coordinateSystem}&layerType=${type}&showInOverview=${false}`;
}

export const getUploadGeorasterCollectionString = (projectId: number): string => {
    return `pId=${projectId}&collection=geoRasterFilesCollection`;
}

/**
 * Axis files upload collection name
 * @param projectId 
 * @returns 
 */
export const getUploadAxisFileCollectionString = (projectId: number): string => {
    return `pId=${projectId}&collection=axisFilesCollection`;
}

/**
 * Upload logo files collection
 */
export const getUploadLogoFilesCollectionString = (): string => {
    return 'collection=globalLogosCollection';
}

/**
 * Replace logo files collection
 */
export const getReplaceLogoFilesCollectionString = (logoInfoId: string): string => {
    return `collection=globalReplaceLogosCollection&logoInfoId=${logoInfoId}`;
}

/**
 * Upload project logo file collection
 * @param projectId 
 */
export const getUploadProjectLogoFileCollectionString = (projectId: number): string => {
    return `pId=${projectId}&collection=projectsLogosCollection`;
}

export const getUploadImportUsersCollectionString = (): string => {
    return 'collection=importUsersCollection';
}

/**
 * The upload inventory sensors list collection string
 */
export const getUploadInventorySensorsAtcCollectionString = (): string => {
    return 'collection=inventorySensorsActCollection';
}

/**
 * The upload release notes collection
 * This is an extensive path to upload the release note file through Server and the return to the client
 */
export const getUploadReleaseNotesCollectionString = (): string => {
    return 'collection=releaseNotesCollection'
}

export const getFeedbackAttachmentsCollectionString = (): string => {
    return 'collection=feedbackAttachmentsCollection';
}

/**
 * Manual import files collection string
 * @param projectId 
 * @returns 
 */
export const getManualImportUploadFileCollectionString = (projectId:number) :string => {
    return `collection=manualImportCollection&pId=${projectId}`;
}