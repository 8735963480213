import { Reducer } from "redux";
import { IDocumentationAction, IDocumentationState } from "../documentation.types";
import {
    DOCUMENTATION_ENTRY_DELETE,
    DOCUMENTATION_HIDE_DELETE_DIALOG,
    DOCUMENTATION_DATA_LOADED,
    DOCUMENTATION_DATA_LOADING_ERROR,
    DOCUMENTATION_SHOW_DELETE_DIALOG,
    DOCUMENTATION_UPLOAD_ERROR,
    DOCUMENTATION_UPLOAD_FINISHED,
    DOCUMENTATION_UPLOAD_PROGRESS,
    DOCUMENTATION_UPLOAD_STARTED,
    DOCUMENTATION_ADD_DOCUMENT,
    DOCUMENTATION_UPDATE_DOCUMENT
} from "../actions/documentationActions";
import { GeovisUploadFileState } from "../uploading.types";
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../types";
import { addOrUpdateElementInMap, deleteElementOfMap, elementsToMap } from "../../helpers/StorageHelper";
import { ManualDocumentModel } from "../../server/AVTService/TypeLibrary/Model/ManualDocumentModel";

export const documentationInitialState: IDocumentationState = {
    uploadFilesInfo: [],
    showDeleteDialog: false,
    documentId: "",
    documentsStorage: {
        ...defaultSomethingStorageState,
        documents: new Map<string, ManualDocumentModel>()
    }
}

const documentationReducer: Reducer<IDocumentationState> = (
    state: IDocumentationState = documentationInitialState,
    action: IDocumentationAction): IDocumentationState => {

    switch (action.type) {
        case DOCUMENTATION_DATA_LOADED:
            if (!action.documents) {
                return state;
            }

            return {
                ...state,
                documentsStorage: {
                    ...loadedSomethingStorageState,
                    documents: elementsToMap(action.documents)
                }
            }

        case DOCUMENTATION_DATA_LOADING_ERROR:
            return {
                ...state,
                documentsStorage: {
                    ...documentationInitialState.documentsStorage,
                    ...errorSomethingStorageState(action.errorDescription)
                }
            }

        case DOCUMENTATION_ADD_DOCUMENT:
        case DOCUMENTATION_UPDATE_DOCUMENT:
            if (!action.document) {
                return state;
            }

            return {
                ...state,
                documentsStorage: {
                    ...state.documentsStorage,
                    documents: addOrUpdateElementInMap(state.documentsStorage.documents, action.document)
                }
            }

        case DOCUMENTATION_SHOW_DELETE_DIALOG:
            if (!action.documentId) {
                return state;
            }

            return {
                ...state,
                showDeleteDialog: true,
                documentId: action.documentId
            }

        case DOCUMENTATION_HIDE_DELETE_DIALOG:
            return {
                ...state,
                showDeleteDialog: false,
                documentId: ""
            }

        case DOCUMENTATION_ENTRY_DELETE:
            if (!action.documentId) {
                return state;
            }

            return {
                ...state,
                documentsStorage: {
                    ...state.documentsStorage,
                    documents: deleteElementOfMap(state.documentsStorage.documents, action.documentId)
                }
            }

        case DOCUMENTATION_UPLOAD_STARTED:
            if (!action.uploadFilesInfo) {
                return state;
            }
            return {
                ...state,
                uploadFilesInfo: action.uploadFilesInfo.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f }))
            };

        case DOCUMENTATION_UPLOAD_PROGRESS:
            if (!action.uploadFileInfo) {
                return state;
            }
            return {
                ...state,
                uploadFilesInfo: state.uploadFilesInfo.map<GeovisUploadFileState>(f => {
                    if (f.linkId === action.uploadFileInfo?.id) {
                        return ({ ...new GeovisUploadFileState(), ...action.uploadFileInfo })
                    }
                    return f;
                })
            };

        case DOCUMENTATION_UPLOAD_FINISHED:
        case DOCUMENTATION_UPLOAD_ERROR:
            if (!action.uploadFileId) {
                return state;
            }
            return {
                ...state,
                uploadFilesInfo: state.uploadFilesInfo.filter(f => f.id !== action.uploadFileId)
            };

        /*case DOCUMENTATION_UPLOAD_ERROR:
            if (!action.uploadFileId) {
                return state;
            }
            return {
                ...state,
                uploadFilesInfo: state.uploadFilesInfo.filter(f => f.id !== action.uploadFileId)
            };*/
    }

    return state;
}

export default documentationReducer;