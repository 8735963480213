import Button from '@atlaskit/button';
import { HeadType, RowType } from '@atlaskit/dynamic-table/dist/types/types';
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left'
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right'
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Select, { OptionType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import Tooltip from '@atlaskit/tooltip';
import { cloneDeep, debounce } from "lodash";
import { SyntheticEvent, useLayoutEffect, useRef, useState } from "react";
import { isMatchToSearchString } from '../../../helpers/FiltersHelper';
import { t } from '../../../i18n';
import { ChainInfo } from "../../../server/ChainInfo";
import { GeovisMstShortInfoModel } from '../../../server/GEOvis3/Model/Database/GeovisMstShortInfoModel';
import { GeovisTableWithSelectableRows } from '../../DynamicTableWithSelectableRows';
import { IGvOptionType } from '../../select/GeovisSelect_tools';

const lineHeight = 48; // 3
const defaultRowsPerPage = 15;


interface IDialogProps {
    selectedChainsIds: string[];
    allItems: ChainInfo[];
    heading: string;
    isLoading?: boolean;
    projectMsts: GeovisMstShortInfoModel[];

    onSelected: (chainIds: string[]) => void;
    onCanceled: () => void;
}

interface ISelectSingleChainsDialogState {
    /**
     * Chains which was marked, but not moved to selected
     */
    chosenNotSelectedChains: ChainInfo[];
    /**
     * Chains which was marked, but not moved to unselected
     */
    chosenSelectedChains: ChainInfo[];
    /**
     * Ids of selected chains
     */
    selectedChainIds: string[];
    /**
     * Name filter
     */
    allItemsQuery: string;
    /**
     * Database filter
     */
    selectedMstAliases: string[];
    /**
     * Public filter
     */
    publicFilter: number | undefined;
}

export const SelectChainsDialog = ({
    allItems,
    heading,
    onCanceled,
    onSelected,
    projectMsts,
    selectedChainsIds,
    isLoading
}: IDialogProps) => {

    const initState: ISelectSingleChainsDialogState = {
        allItemsQuery: '',
        selectedMstAliases: [],
        selectedChainIds: selectedChainsIds,
        chosenNotSelectedChains: [],
        chosenSelectedChains: [],
        publicFilter: undefined
    }

    const [state, setState] = useState<ISelectSingleChainsDialogState>(initState);
    const mainDivRef = useRef<HTMLDivElement | null>(null);

    const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage);

    useLayoutEffect(() => {
        if (mainDivRef && mainDivRef.current) {

            const height = mainDivRef.current.clientHeight;
            const linesPerPage = Math.trunc(height / lineHeight) - 1;
            setRowsPerPage(linesPerPage);
        }
    }, [selectedChainsIds]);

    const clearSearchQueryRef = useRef<HTMLInputElement>();

    const onSearchDebounce = debounce((query: string) => setState({
        ...state,
        allItemsQuery: query
    }), 500);

    const onSearchWrapped = (event: SyntheticEvent<HTMLInputElement>) => onSearchDebounce(event.currentTarget.value);

    const onClearSearchField = () => {
        if (clearSearchQueryRef !== undefined && clearSearchQueryRef.current) {
            clearSearchQueryRef.current.value = "";
        }
        setState({
            ...state,
            allItemsQuery: ''
        });
    }

    const getMstsOptions = (): Array<IGvOptionType<string>> => {
        return projectMsts.map(mst => ({ label: mst.Name, value: mst.Alias }));
    }

    const getPublicOptions = (): OptionType[] => {
        return [{
            label: t("Public only"), value: 1
        }, {
            label: t("Non public only"), value: 2
        }]
    }

    const getSelectedPublicOption = (): OptionType | undefined => {
        return getPublicOptions().find(o => o.value === state.publicFilter);
    }

    const onPublicChanged = (value: OptionType) => {
        if (value) {
            setState({ ...state, publicFilter: +value.value });
            return;
        }
        setState({ ...state, publicFilter: undefined });
    }

    const getSelectedMsts = (): Array<IGvOptionType<string>> => {
        const result: Array<IGvOptionType<string>> = [];

        state.selectedMstAliases.forEach(mstAlias => {
            const mst = projectMsts.find(m => m.Alias === mstAlias);
            if (mst) {
                result.push({ label: mst.Name, value: mst.Alias });
            }
        })

        return result;
    }

    const onMstSelectionChanged = (selection: OptionType[]) => {
        if (!selection || selection === null || selection.length === 0) {
            setState({
                ...state,
                selectedMstAliases: [],
            });
            return;
        }

        setState({
            ...state,
            selectedMstAliases: selection.map(op => op.value.toString())
        });
    }

    const filterFunc = (element: ChainInfo): boolean => {
        if (state.allItemsQuery && !isMatchToSearchString(element.Name, state.allItemsQuery) && !isMatchToSearchString(element.DatabaseName, state.allItemsQuery)) {
            return false;
        }
        if (state.selectedMstAliases.length > 0 && !state.selectedMstAliases.includes(element.DatabaseId)) {
            return false;
        }

        if (state.publicFilter) {
            if (state.publicFilter === 1 && !element.IsPublic || state.publicFilter === 2 && element.IsPublic) {
                return false;
            }
        }

        return true;
    }

    //#region Not selected chains

    const getNotSelectedChains = (): ChainInfo[] => {
        return allItems.filter(item => !state.selectedChainIds.includes(item.Id)).filter(item => filterFunc(item));
    }

    const notSelectedChainToRow = (item: ChainInfo): RowType => {
        return ({
            key: `row-${item.Id}`,
            cells: [{
                key: 'name',
                content: (<span>{item.Name}</span>)
            }
                // , {
                //     key: 'actions',
                //     content: (
                //         <div>
                //             <Button
                //                 spacing='compact'
                //                 onClick={onAddItem(item)}
                //                 iconBefore={<EditorAddIcon label='addIcon' size='small' />}
                //                 appearance='subtle'
                //             />
                //         </div>
                //     )
                // }
            ]
        })
    }

    const onChangedSelectionOfNotSelectedChains = (chains: ChainInfo[]) => {
        setState({
            ...state,
            chosenNotSelectedChains: chains
        })
    }

    const onSelectDeselectAllNotSelectedChains = (allSelected: boolean) => {
        setState({
            ...state,
            chosenNotSelectedChains: allSelected ? getNotSelectedChains() : []
        })
    }

    const onSelect = () => {
        const selectedIds = cloneDeep(state.selectedChainIds);
        selectedIds.push(...state.chosenNotSelectedChains.map(s => s.Id));
        setState({
            ...state,
            selectedChainIds: selectedIds,
            chosenNotSelectedChains: [],
            chosenSelectedChains: []
        })
    }

    //#endregion

    //#region Not selected chains

    const getSelectedChains = (): ChainInfo[] => {
        return allItems.filter(item => state.selectedChainIds.includes(item.Id)).filter(item => filterFunc(item));
    }

    const selectedChainToRow = (item: ChainInfo): RowType => {
        return ({
            key: `row-${item.Id}`,
            cells: [{
                key: "nameCol",
                content: (
                    <span>{`${item.Name}`}</span>
                )
            }]
        })
    }

    const onChangedSelectionOfSelectedChains = (chains: ChainInfo[]) => {
        setState({
            ...state,
            chosenSelectedChains: chains
        })
    }

    const onSelectDeselectAllSelectedChains = (allSelected: boolean) => {
        setState({
            ...state,
            chosenSelectedChains: allSelected ? getSelectedChains() : []
        })
    }

    const getChainsTableHead = (): HeadType => ({
        cells: [{
            key: 'chains',
            content: t("Chains")
        }]
    })

    const onDeselect = () => {
        const selectedIds = cloneDeep(state.selectedChainIds).filter(id => !state.chosenSelectedChains.map(s => s.Id).includes(id));
        setState({
            ...state,
            selectedChainIds: selectedIds,
            chosenNotSelectedChains: [],
            chosenSelectedChains: []
        })
    }

    //#endregion

    //#region all chains

    const getIdFunc = (item: ChainInfo): string => {
        return item.Id;
    }

    //#endregion

    const onDone = () => {
        onSelected(state.selectedChainIds);
    }

    return (
        <ModalTransition>
            <Modal
                heading={heading}
                actions={[
                    { text: t("Done"), onClick: onDone, isDisabled: isLoading },
                    { text: t("Cancel"), onClick: onCanceled }
                ]}
                width="x-large"
                height="100%">
                <div ref={mainDivRef} style={{ height: '100%', display: 'flex', flexDirection: 'column', gap: "5px" }}>
                    {/* header */}
                    <div className="flexRowContainerLine" style={{ gap: "5px" }}>
                        <div className="flexCellContainer_g1">
                            <Textfield
                                ref={clearSearchQueryRef}
                                placeholder={t("Search")}
                                defaultValue={state.allItemsQuery}
                                onChange={onSearchWrapped}
                                isCompact={true}
                                elemAfterInput={
                                    <div style={{ marginRight: '5px', cursor: 'default' }} onClick={onClearSearchField}>
                                        <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                                    </div>
                                }
                            />
                        </div>
                        <div className="flexCellContainer_g1">
                            <Select
                                placeholder={t("Database")}
                                options={getMstsOptions()}
                                searchable={true}
                                isClearable={true}
                                spacing='compact'
                                isMulti={true}
                                value={getSelectedMsts()}
                                onChange={onMstSelectionChanged} />

                        </div>
                        <div className="flexCellContainer_g1">
                            <Select
                                placeholder={t("Public")}
                                options={getPublicOptions()}
                                searchable={true}
                                isClearable={true}
                                spacing='compact'
                                value={getSelectedPublicOption()}
                                onChange={onPublicChanged} />

                        </div>
                    </div>
                    {/* panels below */}
                    <div className='flexRowMiddleContainer' style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* left panel */}
                        <div style={{ width: '100%', height: '100%', display: "flex", flexDirection: 'column', gap: "5px" }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                <Tooltip content={t("Add selected")} >
                                    <Button
                                        style={{ marginRight: '10px' }}
                                        // isDisabled={state.availableItemsSelection.length === 0}
                                        onClick={onSelect}
                                        iconBefore={<ChevronRightIcon label='moveSelected' />}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <span style={{ fontSize: 'large' }}>{t("Chains to select")}</span>
                            </div>

                            <GeovisTableWithSelectableRows
                                allItems={getNotSelectedChains()}
                                getIdFunc={getIdFunc}
                                isLoading={isLoading ?? false}
                                itemToRowFunc={notSelectedChainToRow}
                                needSelectAll={true}
                                head={getChainsTableHead()}
                                onSelectionChanged={onChangedSelectionOfNotSelectedChains}
                                selectedItems={state.chosenNotSelectedChains}
                                rowsPerPage={rowsPerPage}
                                onSelectDeselectAll={onSelectDeselectAllNotSelectedChains}
                            />


                        </div>
                        <div className="flexCellContainer separator">
                            {/** separator */}
                        </div>
                        {/* right panel */}
                        <div style={{ width: '100%', height: '100%', display: "flex", flexDirection: 'column', gap: "5px" }}>
                            <div className='flexRowContainer'>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Tooltip content={t("Remove selected")}>
                                        <Button
                                            onClick={onDeselect}
                                            iconBefore={<ChevronLeftIcon label='removeSelected' />}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className='flexRowContainer'>
                                <div>
                                    <span style={{ fontSize: 'large' }}>{t("Selected chains")}</span>
                                </div>
                            </div>
                            <div className='flexRowMiddleContainer'>
                                <GeovisTableWithSelectableRows
                                    allItems={getSelectedChains()}
                                    getIdFunc={getIdFunc}
                                    isLoading={isLoading ?? false}
                                    itemToRowFunc={selectedChainToRow}
                                    needSelectAll={true}
                                    onSelectionChanged={onChangedSelectionOfSelectedChains}
                                    selectedItems={state.chosenSelectedChains}
                                    head={getChainsTableHead()}
                                    rowsPerPage={rowsPerPage}
                                    onSelectDeselectAll={onSelectDeselectAllSelectedChains}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </ModalTransition>
    )
}