//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { BaseModel } from '../BaseModel';
import { ChartType } from '../../Common/ChartType';
import { KindOfElementUsing } from '../../Common/KindOfElementUsing';
import { TimePeriod } from '../../Common/TimePeriod';
import { GeovisChartDateTimeSettings } from './GeovisChartDateTimeSettings';
import { ChartSettingBool } from '../ChartSettingBool';
import { BucketReportValueKind } from '../../DB/ArgusDataStorage/Bucket/Types/BucketReportValueKind';
import { GeovisChartAxisSettings } from './GeovisChartAxisSettings';
import { DtsChartSettings } from '../../DtsConfiguration/DtsChartSettings';
import { AlarmInfoRecord } from '../AlarmInfoRecord';
import { ChartBackground } from '../ChartBackground';
import { ChartAlarmPlanLinkModel } from './ChartAlarmPlanLinkModel';

export abstract class GeovisChartModel extends BaseModel
{
	public Id: number;
	public ProjectId: number;
	public Type: ChartType;
	public KindOfElementUsing: KindOfElementUsing;
	public Name: string;
	public Title: string;
	public Period: TimePeriod;
	public IsPeriodCustomerChangeable: boolean;
	public StartDateSetting: GeovisChartDateTimeSettings;
	public EndDateSetting: GeovisChartDateTimeSettings;
	public ShowAllMeasurementsSetting: ChartSettingBool;
	public UseLastMeasurementTime: boolean;
	public UseLastMeasurementTimeCustomerChangeable: boolean;
	public BucketReportValueKind: BucketReportValueKind;
	public BucketReportValueKindCustomerChangeable: boolean;
	public LeftYAxisSettings: GeovisChartAxisSettings;
	public RightYAxisSettings: GeovisChartAxisSettings;
	public DtsSettings: DtsChartSettings;
	public ShowLegend: ChartSettingBool;
	public ShowCustomTooltip: boolean;
	public CustomTooltip: string;
	public LegendText: string;
	public IsChartZoomAllow: boolean;
	public ShowStartMeasurements: boolean;
	public DrawLineBetweenPoints: ChartSettingBool;
	public DrawLineBetweenPointsSecondary: ChartSettingBool;
	public ShowCommonTooltip: ChartSettingBool;
	public AlarmLines: AlarmInfoRecord[];
	public BackgroundAreas: ChartBackground[];
	public AlarmPlans: ChartAlarmPlanLinkModel[];
	public InvertXAxisInclinometerValue: boolean;
	public InvertYAxisInclinometerValue: boolean;
	public TemplateCompanyId: string;
	public Logbooks: string[];
	public IncludeInvalidMarkedData: ChartSettingBool;
	public CreatedFrom: number;
	public LastUpdateTime: string;
	public Hidden: boolean;
	public UseProjectColorScheme: boolean;
	public ColorSchemeId: string;
}
