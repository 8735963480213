/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 26.10.2020
 * @description Inventory action creators
 */

import { successRequest } from '../../helpers/ProjectDataHelper';
import { InventoryCalendarViewFilterOptions } from '../../server/AVTService/TypeLibrary/Inventory/InventoryCalendarViewFilterOptions';
import { InventoryObjectDescription } from '../../server/AVTService/TypeLibrary/Inventory/InventoryObjectDescription';
import { InventoryObjectType } from '../../server/AVTService/TypeLibrary/Inventory/InventoryObjectType';
import { InventoryPropertyHistory } from '../../server/AVTService/TypeLibrary/InventoryHistory/InventoryPropertyHistory';
import { DataActionResponse } from '../../server/DataActionResponse';
import { InventoryCalendarViewData } from '../../server/GEOvis3/Model/Inventory/InventoryCalendarViewData';
import { InventoryObjectInfo } from '../../server/GEOvis3/Model/Inventory/InventoryObjectInfo';
import { InventoryProjectInfo } from '../../server/GEOvis3/Model/Inventory/InventoryProjectInfo';
import { InventoryRecordsInfo } from '../../server/GEOvis3/Model/Inventory/InventoryRecordsInfo';
import { InventoryReservationChangedResponse } from '../../server/GEOvis3/Model/Inventory/InventoryReservationChangedResponse';
import { InventoryUsagePlanInfo } from '../../server/GEOvis3/Model/Inventory/InventoryUsagePlanInfo';
import {
    GEOVIS_INVENTORY_ADD,
    GEOVIS_INVENTORY_CALENDAR_FILTERS,
    GEOVIS_INVENTORY_CALENDAR_HIDE_RESERVATION_REMOVE,
    GEOVIS_INVENTORY_CALENDAR_HIDE_UP_ACTION_DIALOG,
    GEOVIS_INVENTORY_CALENDAR_SHOW_OPEN_PERIOD,
    GEOVIS_INVENTORY_CALENDAR_SHOW_RESERVATION_REMOVE,
    GEOVIS_INVENTORY_CALENDAR_SHOW_UP_ACTION_DIALOG,
    GEOVIS_INVENTORY_CALENDAR_UP_SHOW_EDIT,
    GEOVIS_INVENTORY_CALENDAR_VIEW_DATA,
    GEOVIS_INVENTORY_CALENDAR_VIEW_LOADING,
    GEOVIS_INVENTORY_CHANGE_STATE_DIALOG_COMPLITE,
    GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_HIDE,
    GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_SHOW,
    GEOVIS_INVENTORY_CHANGED_VIEW,
    GEOVIS_INVENTORY_DATA_ERROR,
    GEOVIS_INVENTORY_DATA_LOADED,
    GEOVIS_INVENTORY_DATA_LOADING,
    GEOVIS_INVENTORY_DESCRIPTIONS_ERROR,
    GEOVIS_INVENTORY_DESCRIPTIONS_LOADED,
    GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_HIDE,
    GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADED,
    GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING,
    GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING_ERROR,
    GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_SHOW,
    GEOVIS_INVENTORY_EXPAND_DIALOG_COMPLITE,
    GEOVIS_INVENTORY_EXPAND_DIALOG_HIDE,
    GEOVIS_INVENTORY_EXPAND_DIALOG_SHOW,
    GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_HIDE,
    GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_SHOW,
    GEOVIS_INVENTORY_HIDE_DIALOG,
    GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_HIDE,
    GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_SHOW,
    GEOVIS_INVENTORY_NODE_COLLAPSED,
    GEOVIS_INVENTORY_NODE_EXPANDED,
    GEOVIS_INVENTORY_PARENTS_LOADED,
    GEOVIS_INVENTORY_PROJECT_ID_CHANGED,
    GEOVIS_INVENTORY_PROJECTS,
    GEOVIS_INVENTORY_PROJECTS_LOADING,
    GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADED,
    GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING,
    GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING_ERROR,
    GEOVIS_INVENTORY_REMOVE,
    GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_HIDE,
    GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_SHOW,
    GEOVIS_INVENTORY_RESET_STORAGE,
    GEOVIS_INVENTORY_RV_HIDE_EDIT_DIALOG,
    GEOVIS_INVENTORY_RV_HIDE_REMOVE_DIALOG,
    GEOVIS_INVENTORY_RV_HIDE_TO_PROJECT_DIALOG,
    GEOVIS_INVENTORY_RV_LOADED,
    GEOVIS_INVENTORY_RV_LOADING,
    GEOVIS_INVENTORY_RV_SHOW_EDIT_DIALOG,
    GEOVIS_INVENTORY_RV_SHOW_REMOVE_DIALOG,
    GEOVIS_INVENTORY_RV_SHOW_TO_PROJECT_DIALOG,
    GEOVIS_INVENTORY_SET_OBJECT_TYPE,
    GEOVIS_INVENTORY_SET_UPLOAD_STATE,
    GEOVIS_INVENTORY_SHOW_CREATE,
    GEOVIS_INVENTORY_SHOW_EDIT_DIALOG,
    GEOVIS_INVENTORY_SHOW_EDIT_DIALOG_BY_UP_ID,
    GEOVIS_INVENTORY_SHOW_REMOVE_DIALOG,
    GEOVIS_INVENTORY_UP_CLOSE_EDIT_DIALOG,
    GEOVIS_INVENTORY_UP_CLOSE_LIST_VIEW,
    GEOVIS_INVENTORY_UP_CLOSE_REMOVE_DIALOG,
    GEOVIS_INVENTORY_UP_HIDE_CONFIRM_CHANGE_STATE_DIALOG,
    GEOVIS_INVENTORY_UP_HIDE_EXTEND_TIME_RANGE_DIALOG,
    GEOVIS_INVENTORY_UP_REMOVE_RECORD,
    GEOVIS_INVENTORY_UP_SAVE_RECORDS,
    GEOVIS_INVENTORY_UP_SHOW_CONFIRM_CHANGE_STATE_DIALOG,
    GEOVIS_INVENTORY_UP_SHOW_EDIT_DIALOG,
    GEOVIS_INVENTORY_UP_SHOW_EXTEND_TIME_RANGE_DIALOG,
    GEOVIS_INVENTORY_UP_SHOW_LIST_VIEW,
    GEOVIS_INVENTORY_UP_SHOW_REMOVE_DIALOG,
    GEOVIS_INVENTORY_UP_UPDATE_RECORDS,
    GEOVIS_INVENTORY_UPDATE,
    GEOVIS_INVENTORY_UPDATE_RESERVATIONS,
    GEOVIS_INVENTORY_USAGE_PLAN_RECORDS,
    GEOVIS_INVENTORY_USAGE_PLAN_RECORDS_DATA
} from '../actions/inventoryActions';
import {
    IGeovisInventoryAction,
    IGeovisInventoryDataAction,
    IGeovisInventoryDataFilterAction,
    IGeovisInventoryUploadFileState,
    InventoryRequestSource
} from '../inventory.types';


/**
 * Set inventory projects storage to loading
 * @returns 
 */
export const geovisInventoryProjectsLoading = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_PROJECTS_LOADING
})

/**
 * Set inventory projects info in to storage
 * @param response 
 * @returns 
 */
export const geovisInventoryProjects = (response: DataActionResponse<InventoryProjectInfo[]>): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_PROJECTS, inventoryProjects: response
})

/**
 * Reset inventory storage to initial state
 * @returns 
 */
export const geovisInventoryResetStorage = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_RESET_STORAGE
})

/**
 * 
 * @param objectType 
 */
export const geovisInventoryDataLoading = (objectType: InventoryObjectType): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_DATA_LOADING, objectType
});

/**
 * Set data to storage
 * @param objectType 
 * @param data 
 * @param filter 
 */
export const geovisInventoryDataLoaded = <TData, TFilter>(
    objectType: InventoryObjectType,
    data: InventoryRecordsInfo<TData>,
    filter: Partial<TFilter>,
    projectId: number | undefined): IGeovisInventoryDataFilterAction<TData, TFilter> => ({
        type: GEOVIS_INVENTORY_DATA_LOADED, objectType, data, filter, numberProperty: projectId
    });

/**
 * Show edit dialog
 * @param objectType 
 * @param objectId
 */
export const geovisInventoryShowEditDialog = (objectType: InventoryObjectType, objectId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_SHOW_EDIT_DIALOG, objectType, objectId
});

/**
 * Show remove dialog
 * @param objectType 
 * @param objectId
 */
export const geovisInventoryShowRemoveDialog = (objectType: InventoryObjectType, objectId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_SHOW_REMOVE_DIALOG, objectType, objectId
});

/**
 * Set state in to error state
 * @param objectType 
 * @param errorDescription 
 */
export const geovisInventoryDataError = (objectType: InventoryObjectType, errorDescription: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_DATA_ERROR, objectType, errorDescription
});

/**
 * Update inventory record in storage
 * @param inventoryObjectInfo
 */
export const geovisInventoryUpdate = (inventoryObjectInfo: InventoryObjectInfo): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UPDATE, inventoryObjectInfo
});

/**
 * Remove inventory record in storage
 * @param objectType
 * @param objectId
 */
export const geovisInventoryRemove = (objectType: InventoryObjectType, objectId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_REMOVE, objectType, objectId
});

/**
 * Show create new record dialog
 */
export const geovisInventoryShowCreateDialog = (objectType: InventoryObjectType): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_SHOW_CREATE, objectType
});

/**
 * Add ATC sensor record dialog to the storage
 * @param inventoryObjectInfo 
 */
export const geovisInventoryAdd = (inventoryObjectInfo: InventoryObjectInfo): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_ADD, inventoryObjectInfo
});

/**
 * Hide all dialogs
 */
export const geovisInventoryHideDialog = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_HIDE_DIALOG
});


/**
 * Set upload files state
 * @param uploadState 
 */
export const geovisInventorySetUploadState = (uploadState: Partial<IGeovisInventoryUploadFileState>): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_SET_UPLOAD_STATE, uploadState
});

/**
 * Set selected tab index
 * @param index 
 */
export const geovisInventorySetSelectedObjectType = (objectType: InventoryObjectType): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_SET_OBJECT_TYPE, objectType
});

/**
 * Set data to storage
 * @param data 
 */
export const geovisInventoryDescriptionsLoaded = (recs: InventoryObjectDescription[], parentId: string): IGeovisInventoryDataAction<InventoryObjectDescription> => ({
    type: GEOVIS_INVENTORY_DESCRIPTIONS_LOADED, data: { Records: recs, Total: recs.length }, stringProperty: parentId
});

/**
 * Set state in to error state
 * @param errorDescription 
 */
export const geovisInventoryDescriptionsError = (errorDescription: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_DESCRIPTIONS_ERROR, errorDescription
});

export const geovisInventoryNodeExpanded = (nodeId: string, needLoading: boolean): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_NODE_EXPANDED, stringProperty: nodeId, booleanProperty: needLoading
});

export const geovisInventoryNodeCollapsed = (nodeId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_NODE_COLLAPSED, stringProperty: nodeId
});

export const geovisInventoryChangedView = (view: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CHANGED_VIEW, stringProperty: view
});

export const geovisInventoryParentsLoaded = (records: InventoryObjectDescription[]): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_PARENTS_LOADED, parents: records
});

export const geovisInventoryProjectIdChanged = (projectId: number | undefined): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_PROJECT_ID_CHANGED, numberProperty: projectId
});

/**
 * Show usage plan list view of inventory object
 * @param objectType 
 * @param objectId 
 * @returns 
 */
export const geovisInventoryUPShowListView = (objectType: InventoryObjectType, objectId: string, requestSource: InventoryRequestSource): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_SHOW_LIST_VIEW, objectType, objectId, requestSource
});

/**
 * Close current usage plan list view of the inventory object
 * @returns 
 */
export const geovisInventoryUPCloseListView = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_CLOSE_LIST_VIEW
});

/**
 * Show the edit dialog of inventory object
 * Make search it by UsagePlan record id (id -> UPRecord -> (objId and objType) -> inventory object)
 * @param recordId 
 * @returns 
 */
export const geovisInventoryShowEditDialogByUsagePlanId = (recordId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_SHOW_EDIT_DIALOG_BY_UP_ID, stringProperty: recordId
})

/**
 * Show usage plan edit/create dialog
 * @param recordId - usage plan record id
 * @param createMode 
 * @returns 
 */
export const geovisInventoryUPShowEditDialog = (recordId: string, createMode: boolean): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_SHOW_EDIT_DIALOG, objectId: recordId, booleanProperty: createMode
});

export const geovisInventoryUPCloseEditDialog = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_CLOSE_EDIT_DIALOG
});

/**
 * Add or update UsagePlan records
 * @param usagePlanData 
 * @param inventoryObjectInfo the inventoryObject to update in storage
 * @returns 
 */
export const geovisInventoryUPSaveRecords = (usagePlanData: DataActionResponse<InventoryUsagePlanInfo[]>, inventoryObjectInfo: InventoryObjectInfo): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_SAVE_RECORDS, usagePlanData, inventoryObjectInfo
});

export const geovisInventoryRemoveReservationItem = (itemId: string, reservationId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UPDATE_RESERVATIONS, stringProperty: itemId, reservationId
});

/**
 * Show the remove usage plan record dialog
 * @param recordId 
 * @returns 
 */
export const geovisInventoryUPShowRemoveDialog = (recordId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_SHOW_REMOVE_DIALOG, objectId: recordId
});

/**
 * Close the remove usage plan record dialog
 * @returns 
 */
export const geovisInventoryUPCloseRemoveDialog = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_CLOSE_REMOVE_DIALOG
});

/**
 * Show Usage Plan confirm change state dialog
 * @param recordId 
 * @returns 
 */
export const geovisInventoryUPShowConfirmStatusDialog = (recordId: string, requestSource: InventoryRequestSource): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_SHOW_CONFIRM_CHANGE_STATE_DIALOG, stringProperty: recordId, requestSource
});

/**
 * Hide Usage Plan confirm change state dialog
 * @returns 
 */
export const geovisInventoryUPCloseConfirmStatusDialog = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_HIDE_CONFIRM_CHANGE_STATE_DIALOG
})

/**
 * Show Usage Plan extend time range dialog
 * @param recordId 
 * @returns 
 */
export const geovisInventoryUPShowExtendTimeRangeDialog = (recordId: string, requestSource: InventoryRequestSource): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_SHOW_EXTEND_TIME_RANGE_DIALOG, stringProperty: recordId, requestSource
});

/**
 * Hide Usage Plan extend time range dialog
 * @returns 
 */
export const geovisInventoryUPCloseExtendTimeRangeDialog = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_HIDE_EXTEND_TIME_RANGE_DIALOG
})

/**
 * Update exist records in the Usage Plan store
 * @param record
 * @returns 
 */
export const geovisInventoryUPUpdateRecords = (records: InventoryUsagePlanInfo[]): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_UPDATE_RECORDS, usagePlanData: successRequest(records)
});


/**
 * Remove usage plan record and update exist usage plan records
 * @param recordId 
 * @param usagePlanData 
 * @returns 
 */
export const geovisInventoryUPRemoveRecord = (recordId: string, usagePlanData: DataActionResponse<InventoryUsagePlanInfo[]>): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_UP_REMOVE_RECORD, objectId: recordId, usagePlanData
});

export const geovisInventoryUPDataLoading = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_USAGE_PLAN_RECORDS
});

export const geovisInventoryUPDataLoaded = (usagePlanData: DataActionResponse<InventoryUsagePlanInfo[]>): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_USAGE_PLAN_RECORDS_DATA, usagePlanData
});

export const geovisInventoryCalendarViewLoading = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CALENDAR_VIEW_LOADING
})

export const geovisInventoryCalendarViewData = (calendarViewData: DataActionResponse<InventoryCalendarViewData>): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CALENDAR_VIEW_DATA, calendarViewData
});

export const geovisInventoryCalendarShowUPActionDialog = (recordId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CALENDAR_SHOW_UP_ACTION_DIALOG, stringProperty: recordId
});

export const geovisInventoryCalendarHideUPActionDialog = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CALENDAR_HIDE_UP_ACTION_DIALOG
});

/**
 * Show edit dialog of reservation record
 * @param reservationId 
 * @param createMode 
 * @returns 
 */
export const geovisInventoryRVShowEditDialog = (reservationId: string, createMode: boolean): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_RV_SHOW_EDIT_DIALOG, stringProperty: reservationId, booleanProperty: createMode
});

/**
 * Close the edit reservation record dialog
 * @returns 
 */
export const geovisInventoryRVHideEditDialog = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_RV_HIDE_EDIT_DIALOG
});

/**
 * Show remove reservation dialog
 * @param reservationId 
 * @returns 
 */
export const geovisInventoryRVShowRemoveDialog = (reservationId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_RV_SHOW_REMOVE_DIALOG, stringProperty: reservationId
});

/**
 * Hide remove reservation dialog
 * @returns 
 */
export const geovisInventoryRVHideRemoveDialog = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_RV_HIDE_REMOVE_DIALOG
});

/**
 * Show converting reservation to project dialog
 * @param reservationId 
 * @returns 
 */
export const geovisInventoryRVShowToProjectDialog = (reservationId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_RV_SHOW_TO_PROJECT_DIALOG, stringProperty: reservationId
});

/**
 * Hide reservation to project dialog
 * @returns 
 */
export const geovisInventoryRVHideToProjectDialog = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_RV_HIDE_TO_PROJECT_DIALOG
});

/**
 * Set reservation storage to loading state
 * @returns 
 */
export const geovisInventoryRVLoading = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_RV_LOADING
});

/**
 * Set reservation data to storage
 * @param reservationData 
 * @returns 
 */
export const geovisInventoryRVLoaded = (reservationData: DataActionResponse<InventoryReservationChangedResponse>): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_RV_LOADED, reservationData
});

export const geovisInventoryPropertyHistoryDataLoading = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING
});

export const geovisInventoryPropertyHistoryDataLoaded = (records: InventoryPropertyHistory[]): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADED, propertyHistoryRecords: records
});

export const geovisInventoryPropertyHistoryDataLoadingError = (error: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_PROPERTY_HISTORY_DATA_LOADING_ERROR, stringProperty: error
});

/**
 * Set inventory calendar filters data
 * @param filters 
 * @returns 
 */
export const geovisInventoryCalendarFilters = (filters: InventoryCalendarViewFilterOptions): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CALENDAR_FILTERS, calendarFilters: filters
});

/**
 * Switch visibility of open periods on inventory gantt chart
 * @param value 
 * @returns 
 */
export const geovisInventoryCalendarShowOpenPeriod = (value: boolean): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CALENDAR_SHOW_OPEN_PERIOD, booleanProperty: value
})

export const geovisInventoryCalendarUPShowEdit = (recordId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CALENDAR_UP_SHOW_EDIT, stringProperty: recordId
})

export const geovisInventoryCalendarShowReservationRemove = (recordId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CALENDAR_SHOW_RESERVATION_REMOVE, stringProperty: recordId
})

export const geovisInventoryCalendarHideReservationRemove = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CALENDAR_HIDE_RESERVATION_REMOVE
})

export const geovisInventoryDisplayObjectShow = (objectId: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_SHOW, stringProperty: objectId
})

export const geovisInventoryDisplayObjectHide = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_HIDE
})

export const geovisInventoryDisplayObjectLoading = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING
})

export const geovisInventoryDisplayObjectLoadingError = (error: string): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADING_ERROR, stringProperty: error
})

export const geovisInventoryDisplayObjectLoaded = (data: InventoryObjectInfo): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_DISPLAY_OBJECT_DIALOG_LOADED, inventoryObjectInfo: data
})

/**
 * expand dialog
 */
export const geovisInventoryExpandDialogShow = (objectType: InventoryObjectType): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_EXPAND_DIALOG_SHOW, objectType
});

export const geovisInventoryExpandDialogHide = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_EXPAND_DIALOG_HIDE
});

export const geovisInventoryExpandDialogComplite = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_EXPAND_DIALOG_COMPLITE
});

/**
 * change state dialog
 */
export const geovisInventoryChangeStateDialogShow = (objectType: InventoryObjectType): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_SHOW, objectType
});

export const geovisInventoryChangeStateDialogHide = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CHANGE_STATE_ITEMS_DIALOG_HIDE
});

export const geovisInventoryChangeStateDialogComplite = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_CHANGE_STATE_DIALOG_COMPLITE
});

/**
 * remove from project dialog
 */
export const geovisInventoryRemoveItemFromProjectDialogShow = (objectIds: string[]): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_SHOW, objectIds
});

export const geovisInventoryRemoveItemFromProjectDialogHide = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_REMOVE_FROM_PROJECT_DIALOG_HIDE
});

/**
 * expand duration dialog
 */
export const geovisInventoryExpandProjectItemsDurationDialogShow = (objectIds: string[]): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_SHOW, objectIds
});

export const geovisInventoryExpandProjectItemsDurationDialogHide = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_EXPAND_ITEMS_DIRATION_DIALOG_HIDE
});

/**
 * move to storage dialog
 */
export const geovisInventoryMoveToStorageDialogShow = (objectType: InventoryObjectType): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_SHOW, objectType
});

export const geovisInventoryMoveToStorageDialogHide = (): IGeovisInventoryAction => ({
    type: GEOVIS_INVENTORY_MOVE_TO_STORAGE_DIALOG_HIDE
});