//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ReportModelBase } from '../ReportModelBase';
import { GeovisReportElementInfoBase } from './GeovisReportElementInfoBase';
import { GeovisReportPage } from './GeovisReportPage';
import { AutoRefreshInterval } from './AutoRefreshInterval';

export class GeovisReportModel extends ReportModelBase
{
	public Id: number;
	public startDate: string;
	public endDate: string;
	public MaxPdfCreationTime: number;
	public Header: GeovisReportElementInfoBase;
	public Footer: GeovisReportElementInfoBase;
	public Pages: GeovisReportPage[];
	public TemplateCompanyId: string;
	public AutoRefreshInterval: AutoRefreshInterval;
	public Hidden: boolean;
}
