/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 30.06.2020
 * @description Project overview report actions
 */

export const PROJECT_REPORT_LOADING = 'PROJECT_REPORT_LOADING';
export const PROJECT_REPORT_LOADED = 'PROJECT_REPORT_LOADED';
export const PROJECT_REPORT_GEOVIS_LOADED = 'PROJECT_REPORT_GEOVIS_LOADED';
export const PROJECT_DEFAULT_REPORT_GEOVIS_LOADED = 'PROJECT_DEFAULT_REPORT_GEOVIS_LOADED';

export const PROJECT_REPORT_UPDATE_GEOVIS_ELEMENTS_SENSORS_SELECTION_INFO = 'PROJECT_REPORT_UPDATE_GEOVIS_ELEMENTS_SENSORS_SELECTION_INFO';
export const PROJECT_REPORT_UPDATE_LEGACY_CHART_SENSORS_SELECTION_INFO = 'PROJECT_REPORT_UPDATE_LEGACY_CHART_SENSORS_SELECTION_INFO';

/**
 * Show/Hide the sensors dialog
 */
export const PROJECT_REPORT_SENSOR_DIALOG = 'PROJECT_REPORT_SENSOR_DIALOG';
export const PROJECT_REPORT_CUSTOMER_SETTINGS_DIRTY = 'PROJECT_REPORT_CUSTOMER_SETTINGS_DIRTY';
export const PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTY = 'PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTY';
export const PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTIES = 'PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTIES';
export const PROJECT_REPORT_CLEAN_INVALID_PROPERTIES = 'PROJECT_REPORT_CLEAN_INVALID_PROPERTIES';
export const PROJECT_REPORT_APPLY_CUSTOM_SETTINGS = 'PROJECT_REPORT_APPLY_CUSTOM_SETTINGS';

export const PROJECT_REPORT_PNG_LOADED = 'PROJECT_REPORT_PNG_LOADED';
export const PROJECT_REPORT_PNG_START_LOADING = 'PROJECT_REPORT_PNG_START_LOADING';

export const PROJECT_REPORT_PDF_LOADED = 'PROJECT_REPORT_PDF_LOADED';
export const PROJECT_REPORT_PDF_START_LOADING = 'PROJECT_REPORT_PDF_START_LOADING';

export const PROJECT_REPORT_APPLY_CUSTOMER_PROPERTIES = 'PROJECT_REPORT_APPLY_CUSTOMER_PROPERTIES';
export const PROJECT_REPORT_CLEAR_CUSTOMER_PROPERTIES = 'PROJECT_REPORT_CLEAR_CUSTOMER_PROPERTIES';

export const PROJECT_REPORT_CHART_UPDATE_TIMES = 'PROJECT_REPORT_CHART_UPDATE_TIMES';

export const PROJECT_REPORT_DEFAULT_SET_VALIDATION_RESULT = "PROJECT_REPORT_DEFAULT_SET_VALIDATION_RESULT";

export const PROJECT_GEOVIS_REPORT_DEFAULT_SET_REPORT_SETTINGS = "PROJECT_GEOVIS_REPORT_DEFAULT_SET_REPORT_SETTINGS";
export const PROJECT_GEOVIS_REPORT_SET_REPORT_SETTINGS = "PROJECT_GEOVIS_REPORT_SET_REPORT_SETTINGS";
export const PROJECT_GEOVIS_REPORT_RESET_REPORT_SETTINGS = "PROJECT_GEOVIS_REPORT_RESET_REPORT_SETTINGS";

// project report actions
export const PROJECT_GEOVIS_REPORT_SET_CHART_DIRTY_SETTINGS = "PROJECT_GEOVIS_REPORT_SET_CHART_DIRTY_SETTINGS";
export const PROJECT_GEOVIS_REPORT_CHART_DATA_LOADING = 'PROJECT_GEOVIS_REPORT_CHART_DATA_LOADING';
export const PROJECT_GEOVIS_REPORT_CHART_DATA_LOADED = "PROJECT_GEOVIS_REPORT_CHART_DATA_LOADED";
export const PROJECT_GEOVIS_REPORT_CHART_REDRAW = 'PROJECT_GEOVIS_REPORT_CHART_REDRAW';

export const PROJECT_GEOVIS_REPORT_RELOAD_DATA_WITHOUT_BLINK = "PROJECT_GEOVIS_REPORT_RELOAD_DATA_WITHOUT_BLINK";

export const PROJECT_GEOVIS_REPORT_SERVICE_DATA_CHANGED = 'PROJECT_GEOVIS_REPORT_SERVICE_DATA_CHANGED';

export const PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_SHOW = "PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_SHOW";
export const PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_HIDE = "PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_HIDE";

export const PROJECT_GEOVIS_REPORT_TABLE_LOADING = 'PROJECT_GEOVIS_REPORT_TABLE_LOADING';
export const PROJECT_GEOVIS_REPORT_TABLE_LOADED = 'PROJECT_GEOVIS_REPORT_TABLE_LOADED';

export const PROJECT_REPORT_MAP_SECTION_DATA_LOADING = 'PROJECT_REPORT_MAP_SECTION_DATA_LOADING';
export const PROJECT_REPORT_MAP_SECTION_DATA_LOADED = 'PROJECT_REPORT_MAP_SECTION_DATA_LOADED';

export const PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADING = 'PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADING';
export const PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADED = 'PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADED';

export const PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADING = 'PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADING';
export const PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADED = 'PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADED';