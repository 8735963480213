import { LatLngBounds } from "leaflet";
import { MapArea } from "../../server/AVTService/TypeLibrary/Map/MapArea";
import { ImageBackgroundFileType } from "../../server/ImageBackgroundFileType";
import { ImageBackgroundViewPort } from "../../server/ImageBackgroundViewPort";
import { IMapSectionScale } from "../projectOverview/mapSection/render/MapSectionScale.tools";
import { ImageMapContentType } from "./types";
import { IGeovisImageReportInfo } from "../../store/projectReport.types";
import { ISensorsInfoStorage } from "../../store/data.types";
import { ICloseToBorderSensorsIds } from "../projectOverview/sensors/types";
import { getMapAreaOfGeovisImageViewPort } from "../projectOverview/reportOverlay/reportRender/geovisImage/tools";
import { printPageWidthInPx } from "../projectOverview/reportOverlay/reportRender/tools";
import { REPORT_PAGE_CONTAINER_ID } from "../projectOverview/reportOverlay/reportRender/GeovisReportPageRender";

/**
 * Delta coefficient is value used for conversion sensor's relative coordinates to coordinates of image with exact size
 * @param rootContainer Container where image is drawn
 * @param mapArea Size of base image
 * @param fileType Type of base image
 * @param contentType Internal content type (LMO or Geovis Image)
 * @returns Multiplier for every sensor's coordinate or undefined if it's not needed
 */
export const getDeltaCoefficient = (rootContainer: HTMLDivElement | null, mapArea: MapArea | undefined, fileType: ImageBackgroundFileType, contentType?: ImageMapContentType): number | undefined => {
    if (fileType === ImageBackgroundFileType.DXF) {
        return undefined;
    }

    if (contentType && contentType === "LMO") {
        return undefined;
    }

    if (!mapArea) {
        return undefined;
    }

    if (rootContainer && rootContainer && mapArea) {
        let deltaCoefficient = rootContainer.clientWidth / mapArea.Width;

        if (mapArea.Width < mapArea.Height) {
            deltaCoefficient = rootContainer.clientHeight / mapArea.Height;
        }

        return deltaCoefficient;
    }

    return undefined;
}

export const getDxfImageScale = (leafletElement: L.Map, scalingRate: number, maxPixelSize: number, coefficient: number): IMapSectionScale => {
    const centerLatLng = leafletElement.getCenter();
    const pointC = leafletElement.latLngToContainerPoint(centerLatLng);
    const pointY = L.point(pointC.x, pointC.y + maxPixelSize);

    const cLatLng = leafletElement.containerPointToLatLng(pointC);
    const yLatLng = leafletElement.containerPointToLatLng(pointY);

    const distanceInMeters = leafletElement.distance(cLatLng, yLatLng) / (scalingRate) / coefficient;
    const unit = "mm";

    const convertedDistance = distanceInMeters;
    const meters = getRoundNum(convertedDistance);

    const ratio = meters / convertedDistance;
    const pixels = Math.round(maxPixelSize * ratio);

    return {
        pixels: Math.min(pixels, maxPixelSize),
        label: getScaleLabel(convertedDistance, meters, unit)
    }
}

const getRoundNum = (num: number) => {
    const pow10 = Math.pow(10, (Math.floor(num) + '').length - 1);
    let d = num / pow10;

    d = d >= 10 ? 10 :
        d >= 5 ? 5 :
            d >= 3 ? 3 :
                d >= 2 ? 2 : 1;

    return pow10 * d;
}

const getScaleLabel = (distance: number, scale: number, unit: string): string => {
    if (distance < 1) {
        let dimension = 1;
        if (distance < 0.1) {
            dimension = 0.1;
        }
        if (distance < 0.01) {
            dimension = 0.01;
        }
        return `< ${dimension} ${unit}`;
    }
    return `${scale} ${unit}`;
}

const getPointsDifference = (point1: number, point2: number): number => {
    if (point1 > 0 && point2 < 0 || point1 < 0 && point2 > 0) {
        //points have different sign
        return Math.abs(point1) + Math.abs(point2);
    }
    return Math.abs(point1 - point2);
}

export const getDxfImageBounds = (viewPort: ImageBackgroundViewPort): LatLngBounds => {

    const viewWidth = getPointsDifference(viewPort.Left, viewPort.Right);
    const viewHeight = getPointsDifference(viewPort.Bottom, viewPort.Top);

    const widthToHeight = viewWidth / viewHeight;
    const widthDeltaCoeff = widthToHeight >= 1 ? 1 : widthToHeight;
    const heightDeltaCoeff = widthToHeight >= 1 ? viewHeight / viewWidth : 1;

    const LATITUDE_CENTER_VALUE = -128;
    const LONGITUDE_CENTER_VALUE = 128;
    const ACTUAL_PICTURE_SIZE = 213.4;

    const southWest = L.latLng(LATITUDE_CENTER_VALUE - (ACTUAL_PICTURE_SIZE * (isNaN(heightDeltaCoeff) ? 1 : heightDeltaCoeff) / 2), LONGITUDE_CENTER_VALUE - (ACTUAL_PICTURE_SIZE * (isNaN(widthDeltaCoeff) ? 1 : widthDeltaCoeff) / 2));
    const northEast = L.latLng(LATITUDE_CENTER_VALUE + (ACTUAL_PICTURE_SIZE * (isNaN(heightDeltaCoeff) ? 1 : heightDeltaCoeff) / 2), LONGITUDE_CENTER_VALUE + (ACTUAL_PICTURE_SIZE * (isNaN(widthDeltaCoeff) ? 1 : widthDeltaCoeff) / 2));

    return L.latLngBounds(southWest, northEast);
}

export const checkIfSensorIsCloseToBorder = (geovisImageInfo: IGeovisImageReportInfo, sensorsInfoStorage: ISensorsInfoStorage): ICloseToBorderSensorsIds => {

    const result: ICloseToBorderSensorsIds = {
        bottom: [],
        left: [],
        right: [],
        top: []
    };

    if (sensorsInfoStorage.isLoading || sensorsInfoStorage.isError) {
        return result;
    }

    const APPEND_VALUE = 25;
    const { ViewPort } = geovisImageInfo.GeovisImage.ImageInfo;
    const width = Math.abs(ViewPort.Left - ViewPort.Right);
    const height = Math.abs(ViewPort.Top - ViewPort.Bottom);

    sensorsInfoStorage.sensorsInfo.forEach(s => {

        if (s.Coordinates.Longitude < APPEND_VALUE) {
            result.left.push(s.Id);
        }
        if (Math.abs(width - s.Coordinates.Longitude) < APPEND_VALUE) {
            result.right.push(s.Id);
        }
        if (s.Coordinates.Latitude < APPEND_VALUE) {
            result.bottom.push(s.Id);
        }
        if (Math.abs(height - s.Coordinates.Latitude) < APPEND_VALUE) {
            result.top.push(s.Id);
        }
    });

    return result;
}

export const getRootContainerWidth = (widthCoefficient: number) => {
    const widthMarginPx = 10; // 5px from left and 5px from right
    // for normal report we search for page header to calculate available container width
    const refHeader = document.getElementById(REPORT_PAGE_CONTAINER_ID );
    if (refHeader) {
        return refHeader.clientWidth * widthCoefficient - widthMarginPx;
    }
    // for printing we use predefined value
    return printPageWidthInPx * widthCoefficient - widthMarginPx;
}

export const getScale = (viewWidth: number, viewHeight: number, rectangleWidth: number, rectangleHeight: number): number => {
    return Math.min(viewWidth / rectangleWidth, viewHeight / rectangleHeight);
}

interface IContainerState {
    width: number;
    height: number;
}

export const calculateWidthHeightScale = (viewPort: ImageBackgroundViewPort, initialHeight: number, widthCoefficient: number = 1): IContainerState => {
    let currentViewWidthPx = getRootContainerWidth(widthCoefficient);
    const currentViewSize = getMapAreaOfGeovisImageViewPort(viewPort);

    //we should always use initial height, because if image height less than container height we should fill container, if bigger - fit image
    let mapAreaHeight = initialHeight;

    const scale = getScale(currentViewWidthPx, mapAreaHeight, currentViewSize.Width, currentViewSize.Height);

    const scaledWidth = Math.ceil(currentViewSize.Width * scale);
    const scaledHeight = Math.ceil(currentViewSize.Height * scale);

    if (scaledWidth < currentViewWidthPx || scaledHeight < initialHeight) {
        const fitScale = Math.min(currentViewWidthPx / scaledWidth, initialHeight / scaledHeight);
        currentViewWidthPx = Math.ceil(scaledWidth * fitScale);
        mapAreaHeight = Math.ceil(scaledHeight * fitScale);
    }

    return {
        height: mapAreaHeight,
        width: currentViewWidthPx
    }
}