import Button from "@atlaskit/button";
import Checkbox from '@atlaskit/checkbox';
import DynamicTable from '@atlaskit/dynamic-table';
import { HeadType, RowType } from '@atlaskit/dynamic-table/types';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle'
import Tooltip from '@atlaskit/tooltip';
import { CSSProperties, Fragment, SyntheticEvent } from "react";
import WarningIcon from '@atlaskit/icon/glyph/warning'
import { t } from "../../../i18n";
import { ChartType } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { ChartAlarmPlanLinkModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/ChartAlarmPlanLinkModel";
import { GeovisChartAlarmInfo } from "../../../server/GEOvis3/Model/GeovisChart/GeovisChartAlarmInfo";
import { GeovisSelect } from "../../select/GeovisSelect";
import { IGvOptionType } from "../../select/GeovisSelect_tools";
import Constants from "../../../helpers/Constants";
import { SensorValueAttribute } from "../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { GeovisChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { getChartSensorValueForAlarm } from "./tools";
import { InclinometerChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { XyChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import { XYVibrationChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XYVibrationChartModel";

export interface IChartAlarmPlansControlActionProps {
    onChangeLinkedAlarms: (updAlarms: ChartAlarmPlanLinkModel[]) => void;
}

interface IComponentProps extends IChartAlarmPlansControlActionProps {
    // chartType: ChartType;
    // allowedSensorValues: ReadonlyArray<SensorValueAttribute>;

    allAlarms: GeovisChartAlarmInfo[];
    // linkedAlarms: ChartAlarmPlanLinkModel[];
    chart: GeovisChartModel;
}

const defaultTableHead: HeadType = ({
    cells: [{
        key: 'enabled',
        content: t("Enabled")
    }, {
        key: 'plan',
        content: t("Alarm Plan")
    }, {
        key: 'actions'
    }]
});

const xyTableHead: HeadType = ({
    cells: [{
        key: 'enabled',
        content: t("Enabled")
    }, {
        key: 'plan',
        content: t("Alarm Plan")
    }, {
        key: 'swap',
        content: t("Axis type")
    }, {
        key: 'actions'
    }]
});

const axisTypesOptions: ReadonlyArray<IGvOptionType<number>> = [{
    value: 0,
    label: t("Horizontal Line")
}, {
    value: 1,
    label: t("Vertical Line")
}];

const getAxisTypesSelectedOption = (line: ChartAlarmPlanLinkModel): IGvOptionType<number> => {
    if (line.SwapXY) {
        // return ({ value: 1, label: t("Swap X/Y") })
        return axisTypesOptions[1];
    }


    // else {
    //     return ({ value: 0, label: t("Default axis") })
    // }
    return axisTypesOptions[0];
}


export const ChartAlarmPlansControl = ({ chart, allAlarms, onChangeLinkedAlarms }: IComponentProps) => {

    const inclinoChart: InclinometerChartModel | undefined = chart.Type === ChartType.Inclinometer ? chart as InclinometerChartModel : undefined;

    let allowedAlarms = allAlarms.filter(a => getChartSensorValueForAlarm(chart.Type, chart.LeftYAxisSettings, inclinoChart ? inclinoChart.VisibleRangeSettings : undefined, true).includes(a.SensorFunction));
    if (chart.Type === ChartType.XYDiagram) {
        const xyChart = chart as XyChartModel;
        const xAllowedAlarms = allAlarms.filter(a => getChartSensorValueForAlarm(chart.Type, xyChart.XAxisSettings).includes(a.SensorFunction));
        xAllowedAlarms.forEach(alarm => {
            const existing = allowedAlarms.find(a => a.Id === alarm.Id);
            if (!existing) {
                allowedAlarms.push(alarm);
            }
        })
    }

    if (chart.Type === ChartType.XYVibrationEventDiagram) {
        const xyVibrationCHart = chart as XYVibrationChartModel;
        allowedAlarms = allowedAlarms.filter(a => getChartSensorValueForAlarm(chart.Type, xyVibrationCHart.XAxisSettings, undefined, false).includes(a.RelevantFrequencyFunction))
    }

    allowedAlarms = allowedAlarms.filter(a => a.AlarmType === chart.LeftYAxisSettings.TypeOfSensor || a.AlarmType === chart.RightYAxisSettings.TypeOfSensor);

    const onAddNewLink = () => {
        chart.AlarmPlans.push({ AlarmEnabled: true, AlarmId: "", SwapXY: false });
        onChangeLinkedAlarms(chart.AlarmPlans);
    }

    /**
     * Get alarm select options
     * @returns 
     */
    const getAlarmPlansOptions = (): ReadonlyArray<IGvOptionType<string>> => {
        return allowedAlarms.map(ap => ({ label: ap.Name, value: ap.Id }));
    }

    /**
     * Get selected alarm select option
     * @param alarmId 
     * @returns 
     */
    const getSelectedAlarmPlanOption = (alarmId: string): IGvOptionType<string> | undefined => {
        const allowed = getAlarmPlansOptions().find(op => op.value === alarmId);
        if (allowed) {
            return allowed;
        }

        const notAllowed = allAlarms.find(a => a.Id === alarmId);
        if (notAllowed) {
            return { label: notAllowed.Name, value: notAllowed.Id };
        }

        return undefined;
    }

    const onChangeLinkedAlarmId = (index: number) => (data: IGvOptionType<string>) => {
        chart.AlarmPlans[index] = { ...chart.AlarmPlans[index], AlarmId: data.value };
        onChangeLinkedAlarms(chart.AlarmPlans);
    }

    const onEnableAlarmChangedFunc = (index: number) => (event: SyntheticEvent<HTMLInputElement>) => {
        chart.AlarmPlans[index] = { ...chart.AlarmPlans[index], AlarmEnabled: event.currentTarget.checked };
        onChangeLinkedAlarms(chart.AlarmPlans);
    }

    const onRemovePlanFunc = (index: number) => () => {
        chart.AlarmPlans.splice(index, 1);
        onChangeLinkedAlarms(chart.AlarmPlans);
    }

    const onSelectedAxisTypeChanged = (index: number) => (value: IGvOptionType<number>) => {
        chart.AlarmPlans[index] = { ...chart.AlarmPlans[index], SwapXY: value.value > 0 };
        onChangeLinkedAlarms(chart.AlarmPlans);
    }

    const getTableRows = (): RowType[] => {
        switch (chart.Type) {
            case ChartType.XYDiagram: return getXYTableRows();
            default: return getDefaultTableRows();
        }
    }

    const getTableHead = (): HeadType => {
        switch (chart.Type) {
            case ChartType.XYDiagram: return xyTableHead;
            default: return defaultTableHead;
        }
    }

    /**
     * Get linked alarm sensor function
     * If alarm not found, then return Undefined unit
     * @param alarmId 
     * @returns 
     */
    const getLinkedAlarmSensorFunction = (alarmId: string): SensorValueAttribute | -1 => {

        const alarm = allowedAlarms.find(a => a.Id === alarmId);
        if (!alarm) {
            return -1;
        }

        return alarm.SensorFunction;
    }

    const getLinkedAlarmRelevantFrequency = (alarmId: string): SensorValueAttribute | -1 => {

        const alarm = allowedAlarms.find(a => a.Id === alarmId);
        if (!alarm) {
            return -1;
        }

        return alarm.RelevantFrequencyFunction;
    }


    const getLinkedAlarmSwapValue = (alarmId: string): boolean | undefined => {
        const alarm = chart.AlarmPlans.find(a => a.AlarmId === alarmId);
        if (!alarm) {
            return undefined;
        }
        return alarm.SwapXY;
    }

    const getLinkedAlarmSensorRowStyle = (alarmId: string): CSSProperties => {

        const props: CSSProperties = {};
        const alarmFunction = getLinkedAlarmSensorFunction(alarmId);
        const allowedSensorValues = getChartSensorValueForAlarm(chart.Type, chart.LeftYAxisSettings, inclinoChart ? inclinoChart.VisibleRangeSettings : undefined, true);

        if (chart.Type === ChartType.XYDiagram) {
            const xyChart = chart as XyChartModel;
            allowedSensorValues.push(...getChartSensorValueForAlarm(chart.Type, xyChart.XAxisSettings))
        }

        if (alarmId && (alarmFunction === -1 || !allowedSensorValues.includes(alarmFunction))) {
            props.backgroundColor = "#f77b6d";
        }

        return props;
    }

    const AlarmUnitMismatchWarning = ({ alarmId }: { alarmId: string }) => {
        const alarmFunction = getLinkedAlarmSensorFunction(alarmId);
        const relevantFreq = getLinkedAlarmRelevantFrequency(alarmId);
        const allowedSensorValues = getChartSensorValueForAlarm(chart.Type, chart.LeftYAxisSettings, inclinoChart ? inclinoChart.VisibleRangeSettings : undefined, true);

        if (chart.Type === ChartType.XYDiagram) {
            const xyChart = chart as XyChartModel;
            allowedSensorValues.push(...getChartSensorValueForAlarm(chart.Type, xyChart.XAxisSettings))
        }

        let isAlarmFailed = alarmFunction === -1 || !allowedSensorValues.includes(alarmFunction);

        if (chart.Type === ChartType.XYVibrationEventDiagram) {
            const xyVibrationChart = chart as XYVibrationChartModel;
            const allowedRelevantFrequency = getChartSensorValueForAlarm(chart.Type, xyVibrationChart.XAxisSettings, undefined, false);
            isAlarmFailed = alarmFunction === -1 || relevantFreq === -1 || !allowedSensorValues.includes(alarmFunction) || !allowedRelevantFrequency.includes(relevantFreq);
        }

        return (
            <Fragment>
                {alarmId && isAlarmFailed && (
                    <Tooltip content={t("Alarmplan value doesn't match with selected Chart Value, Alarmlines, -areas and -labels won't be drawn")}>
                        <WarningIcon label="" primaryColor={Constants.errorColor} />
                    </Tooltip>
                )}
            </Fragment>
        )
    }

    const getXyLinkedAlarmSensorRowStyle = (alarmId: string): CSSProperties => {

        const props: CSSProperties = {};
        const alarmSwap = getLinkedAlarmSwapValue(alarmId);

        if (alarmSwap === undefined) {
            return props;
        }

        const alarmFunction = getLinkedAlarmSensorFunction(alarmId);

        if (alarmFunction === -1) {
            props.backgroundColor = "#f77b6d";
            return props;
        }
        const xyChart = chart as XyChartModel;

        const yAllowedSensorValues = getChartSensorValueForAlarm(chart.Type, chart.LeftYAxisSettings);
        const xAllowedSensorValues = getChartSensorValueForAlarm(chart.Type, xyChart.XAxisSettings);
        const allowedSensorValues = [...yAllowedSensorValues, ...xAllowedSensorValues];

        const lineMismatch = alarmSwap
            ? !xAllowedSensorValues.includes(alarmFunction)
            : !yAllowedSensorValues.includes(alarmFunction);

        const typeMismatch = !allowedSensorValues.includes(alarmFunction);

        if (lineMismatch || typeMismatch) {
            props.backgroundColor = "#f77b6d";
        }

        return props;
    }

    const XyChartAlarmUnitMismatchWarning = ({ alarmId }: { alarmId: string }) => {

        if (!alarmId) {
            return null;
        }

        const alarmSwap = getLinkedAlarmSwapValue(alarmId);

        if (alarmSwap === undefined) {
            return null;
        }

        const alarmFunction = getLinkedAlarmSensorFunction(alarmId);

        if (alarmFunction === -1) {
            return (
                <Fragment>
                    <Tooltip content={t("Alarmplan value doesn't match with selected Chart Value. Alarmlines, -areas and -labels won't be drawn")}>
                        <WarningIcon label="" primaryColor={Constants.errorColor} />
                    </Tooltip>
                </Fragment>
            )
        }
        const xyChart = chart as XyChartModel;

        const yAllowedSensorValues = getChartSensorValueForAlarm(chart.Type, chart.LeftYAxisSettings);
        const xAllowedSensorValues = getChartSensorValueForAlarm(chart.Type, xyChart.XAxisSettings);
        const allowedSensorValues = [...yAllowedSensorValues, ...xAllowedSensorValues];

        const lineMismatch = alarmSwap
            ? !xAllowedSensorValues.includes(alarmFunction)
            : !yAllowedSensorValues.includes(alarmFunction);

        const typeMismatch = !allowedSensorValues.includes(alarmFunction);

        return (
            <Fragment>
                {alarmId && (typeMismatch) && (
                    <Tooltip content={t("Alarmplan value doesn't match with selected Chart Value. Alarmlines, -areas and -labels won't be drawn")}>
                        <WarningIcon label="" primaryColor={Constants.errorColor} />
                    </Tooltip>
                )}
                {alarmId && !typeMismatch && lineMismatch && (
                    <Tooltip content={t("Wrong Axis type. Alarmlines, -areas and -labels won't be drawn")}>
                        <WarningIcon label="" primaryColor={Constants.errorColor} />
                    </Tooltip>
                )}
            </Fragment>
        )
    }

    const getDefaultTableRows = (): RowType[] => chart.AlarmPlans.map((linkedAlarm, index) => ({
        key: `area-row-${index}`,
        style: getLinkedAlarmSensorRowStyle(linkedAlarm.AlarmId),
        cells: [{
            key: 'enabled',
            content: (
                <div className="flexRowContainerLine">
                    <Checkbox
                        isChecked={linkedAlarm.AlarmEnabled}
                        onChange={onEnableAlarmChangedFunc(index)}
                    />
                    <AlarmUnitMismatchWarning alarmId={linkedAlarm.AlarmId} />
                </div>
            )
        }, {
            key: 'days',
            content: (
                <GeovisSelect
                    options={getAlarmPlansOptions()}
                    value={getSelectedAlarmPlanOption(linkedAlarm.AlarmId)}
                    onChange={onChangeLinkedAlarmId(index)}
                />
            )
        }, {
            key: 'actions',
            content: (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        iconBefore={<CrossCircleIcon label="remove" />}
                        spacing='compact'
                        appearance="subtle-link"
                        title={t("Delete link to alarm plan")}
                        onClick={onRemovePlanFunc(index)}
                    />
                </div>
            )
        }]
    }))

    const getXYTableRows = (): RowType[] => chart.AlarmPlans.map((linkedAlarm, index) => ({
        key: `area-row-${index}`,
        style: getXyLinkedAlarmSensorRowStyle(linkedAlarm.AlarmId),
        cells: [{
            key: 'enabled',
            content: (
                <div className="flexRowContainerLine">
                    <Checkbox
                        isChecked={linkedAlarm.AlarmEnabled}
                        onChange={onEnableAlarmChangedFunc(index)}
                    />
                    <XyChartAlarmUnitMismatchWarning alarmId={linkedAlarm.AlarmId} />
                </div>
            )
        }, {
            key: 'days',
            content: (
                <GeovisSelect
                    options={getAlarmPlansOptions()}
                    value={getSelectedAlarmPlanOption(linkedAlarm.AlarmId)}
                    onChange={onChangeLinkedAlarmId(index)}
                />
            )
        }, {
            key: 'axisType',
            content: (
                <GeovisSelect
                    options={axisTypesOptions}
                    value={getAxisTypesSelectedOption(linkedAlarm)}
                    onChange={onSelectedAxisTypeChanged(index)}
                />
            )
        }, {
            key: 'actions',
            content: (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        iconBefore={<CrossCircleIcon label="remove" />}
                        spacing='compact'
                        appearance="subtle-link"
                        title={t("Delete link to alarm plan")}
                        onClick={onRemovePlanFunc(index)}
                    />
                </div>

            )
        }]
    }))

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '15px' }}>
            <div style={{ alignSelf: 'flex-start' }}>
                <h2>{t("Alarmplans")}</h2>
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
                <Tooltip
                    content={allowedAlarms.length === 0 ? t("No alarmplans are available") : t("Add new link to alarmplan")}>
                    <Button
                        appearance="primary"
                        spacing="compact"
                        onClick={onAddNewLink}
                        isDisabled={allowedAlarms.length === 0}>
                        {t("Add link to alarmplan")}
                    </Button>
                </Tooltip>

            </div>
            <div>
                <DynamicTable
                    head={getTableHead()}
                    rows={getTableRows()}
                />
            </div>
        </div>
    )

}