/**
 * Vyacheslav Skripin<vs@ieskr.ru>
 * Created: 23.09.2019
 * Switch user view depends of user to project relation role
 */

import Dropdown, {
    DropdownItemGroupRadio,
    DropdownItemRadio
} from '@atlaskit/dropdown-menu';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import i18next from '../../i18n';
import { GeovisUserToProjectRole } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserToProjectRole';
import AuthService from '../../services/AuthService';
import { navigationUpdateUserViewMode } from '../../store/creators/navigationCreators';
import { IProjectInfoStorage } from '../../store/data.types';
import { IGeovisStoreState } from '../../store/store.types';
import { IGeovisAction } from '../../store/types';

interface ISwitchUserViewMenuStateProps {
    projectInfo: IProjectInfoStorage;
}

interface ISwitchUserViewMenuDispatchProps {
    onUpdateUserProjectViewMode: () => void;
}

interface ISwitchUserViewMenuProps extends ISwitchUserViewMenuStateProps, ISwitchUserViewMenuDispatchProps {

}

/**
 * The stateless component to switch user viewable mode
 * AGMS-1138
 */
const SwitchUserViewMenu = ({ onUpdateUserProjectViewMode, projectInfo }: ISwitchUserViewMenuProps) => {

    if (projectInfo.isLoading) {
        return null;
    }

    const { project: { Id } } = projectInfo;

    const userToProjectItems = [
        { role: GeovisUserToProjectRole.Viewer, text: i18next.t("Viewer") },
        { role: GeovisUserToProjectRole.Admin, text: i18next.t("Admin") }
    ];

    const isUserToProjectRoleSelected = (role: GeovisUserToProjectRole): boolean => {

        if (role === GeovisUserToProjectRole.Admin && AuthService.isActualAdminOfProject(Id)) {
            return true;
        }

        if (role === GeovisUserToProjectRole.Viewer && AuthService.isActualViewerOfProject(Id)) {
            return true;
        }

        return false;
    }

    const setUserToProjectRoleFunc = (role: GeovisUserToProjectRole) => {
        return () => {
            AuthService.setActualProjectRelationRole(Id, role);
            onUpdateUserProjectViewMode();
        }
    }

    return (
        <Dropdown
            trigger={<WatchIcon label="" />}
            position="right bottom"
            appearance="tall">
            <DropdownItemGroupRadio id='select_user_to_project_view_group' title={i18next.t("Switch user view")}>
                {userToProjectItems.map(item => (
                    <DropdownItemRadio
                        key={`switch_user_to_project_role_${item.role}`}
                        id={`switch_user_to_project_role_${item.role}`}
                        isSelected={isUserToProjectRoleSelected(item.role)}
                        onClick={setUserToProjectRoleFunc(item.role)}>
                        {item.text}
                    </DropdownItemRadio>
                ))}
            </DropdownItemGroupRadio>
        </Dropdown>)
}

const mapStateToProps = ({ data }: IGeovisStoreState): ISwitchUserViewMenuStateProps => ({
    projectInfo: data.projectInfo
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): ISwitchUserViewMenuDispatchProps => ({
    onUpdateUserProjectViewMode: () => dispatch(navigationUpdateUserViewMode())
});

export default connect<ISwitchUserViewMenuStateProps, ISwitchUserViewMenuDispatchProps>(mapStateToProps, mapDispatchToProps)(SwitchUserViewMenu);
