//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ProjectLocalCoordinatesType {
	Polar = 0,
	Cartesian_FE = 1,
	Cartesian_GAST = 2
}

const ProjectLocalCoordinatesTypeToName = new Map<number, string>([
	[ 0, 'Polar' ],
	[ 1, 'Cartesian_FE' ],
	[ 2, 'Cartesian_GAST' ],
])

export function getProjectLocalCoordinatesTypeToName(element: ProjectLocalCoordinatesType) : string {
    return ProjectLocalCoordinatesTypeToName.get(element) || '';
}

const ProjectLocalCoordinatesTypeByName = new Map<string, number>([
	[ 'Polar', 0 ],
	[ 'Cartesian_FE', 1 ],
	[ 'Cartesian_GAST', 2 ],
]);

export function getProjectLocalCoordinatesTypeByName(name: string): ProjectLocalCoordinatesType {
    return ProjectLocalCoordinatesTypeByName.get(name)!;
}


export const ProjectLocalCoordinatesTypeList : Readonly<ProjectLocalCoordinatesType[]> = [
	ProjectLocalCoordinatesType.Polar,
	ProjectLocalCoordinatesType.Cartesian_FE,
	ProjectLocalCoordinatesType.Cartesian_GAST,
];



const ProjectLocalCoordinatesTypeToDescription = new Map<number, string>([
	[ 0, 'Polar' ],
	[ 1, 'FE Cartesian' ],
	[ 2, 'GAST Cartesian' ],
])

export function getProjectLocalCoordinatesTypeToDescription(element: ProjectLocalCoordinatesType) : string {
    return ProjectLocalCoordinatesTypeToDescription.get(element) || '';
}

