//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ReportElementType {
	None = 0,
	PageBreak = 1,
	Comment = 2,
	Map = 3,
	Chart = 4,
	Header = 5,
	Footer = 6,
	ProjectComment = 8,
	Profile = 9,
	Logbook = 10,
	GeovisTable = 11,
	GeovisChart = 12,
	MapSection = 13,
	ElementsGroup = 14,
	GeovisComment = 15,
	GeovisImage = 16
}

const ReportElementTypeToName = new Map<number, string>([
	[ 0, 'None' ],
	[ 1, 'PageBreak' ],
	[ 2, 'Comment' ],
	[ 3, 'Map' ],
	[ 4, 'Chart' ],
	[ 5, 'Header' ],
	[ 6, 'Footer' ],
	[ 8, 'ProjectComment' ],
	[ 9, 'Profile' ],
	[ 10, 'Logbook' ],
	[ 11, 'GeovisTable' ],
	[ 12, 'GeovisChart' ],
	[ 13, 'MapSection' ],
	[ 14, 'ElementsGroup' ],
	[ 15, 'GeovisComment' ],
	[ 16, 'GeovisImage' ],
])

export function getReportElementTypeToName(element: ReportElementType) : string {
    return ReportElementTypeToName.get(element) || '';
}

const ReportElementTypeByName = new Map<string, number>([
	[ 'None', 0 ],
	[ 'PageBreak', 1 ],
	[ 'Comment', 2 ],
	[ 'Map', 3 ],
	[ 'Chart', 4 ],
	[ 'Header', 5 ],
	[ 'Footer', 6 ],
	[ 'ProjectComment', 8 ],
	[ 'Profile', 9 ],
	[ 'Logbook', 10 ],
	[ 'GeovisTable', 11 ],
	[ 'GeovisChart', 12 ],
	[ 'MapSection', 13 ],
	[ 'ElementsGroup', 14 ],
	[ 'GeovisComment', 15 ],
	[ 'GeovisImage', 16 ],
]);

export function getReportElementTypeByName(name: string): ReportElementType {
    return ReportElementTypeByName.get(name)!;
}


export const ReportElementTypeList : Readonly<ReportElementType[]> = [
	ReportElementType.None,
	ReportElementType.PageBreak,
	ReportElementType.Comment,
	ReportElementType.Map,
	ReportElementType.Chart,
	ReportElementType.Header,
	ReportElementType.Footer,
	ReportElementType.ProjectComment,
	ReportElementType.Profile,
	ReportElementType.Logbook,
	ReportElementType.GeovisTable,
	ReportElementType.GeovisChart,
	ReportElementType.MapSection,
	ReportElementType.ElementsGroup,
	ReportElementType.GeovisComment,
	ReportElementType.GeovisImage,
];



const ReportElementTypeToDescription = new Map<number, string>([
	[ 0, 'None' ],
	[ 1, 'Page break' ],
	[ 2, 'Comment' ],
	[ 3, 'Map' ],
	[ 4, 'Chart (legacy)' ],
	[ 5, 'Header' ],
	[ 6, 'Footer' ],
	[ 8, 'Project comment' ],
	[ 9, 'Profile' ],
	[ 10, 'Logbook' ],
	[ 11, 'Geovis table' ],
	[ 12, 'Geovis chart' ],
	[ 13, 'Map section' ],
	[ 14, 'Group' ],
	[ 15, 'Geovis comment' ],
	[ 16, 'Geovis image' ],
])

export function getReportElementTypeToDescription(element: ReportElementType) : string {
    return ReportElementTypeToDescription.get(element) || '';
}

