/**
 * @author Alexei Soloviev <sa@ieskr.ru>
 * @created 25.05.2022
 * @description Geovis user to project role select
 * @refactored by Vyacheslav Skripin <vs@ieskr.ru> at 14.10.2022
 */

import { GeovisUserProjectRelationModel } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserProjectRelationModel';
import {
    GeovisUserToProjectRole,
    GeovisUserToProjectRoleList,
    getGeovisUserToProjectRoleToDescription
} from '../../server/AVTService/TypeLibrary/Identity/GeovisUserToProjectRole';
import { GeovisSelect } from './GeovisSelect';
import { getIGvOptionType, IGvOptionType } from './GeovisSelect_tools';

interface ISelectUserToProjectRoleProps {
    relation: GeovisUserProjectRelationModel;
    needEmailReceiver: boolean;
    needAdmin: boolean;
    needViewer: boolean;

    onChange: (relation: GeovisUserToProjectRole) => void;
}

export const SelectUserToProjectRole = ({ relation, onChange, needEmailReceiver, needAdmin, needViewer }: ISelectUserToProjectRoleProps) => {

    const options = GeovisUserToProjectRoleList
        .filter(r => (needEmailReceiver ? true : r !== GeovisUserToProjectRole.EmailReceiver) && (needAdmin ? true : r !== GeovisUserToProjectRole.Admin) && (needViewer ? true : r !== GeovisUserToProjectRole.Viewer))
        .map<IGvOptionType<GeovisUserToProjectRole>>(r => getIGvOptionType(r, getGeovisUserToProjectRoleToDescription));


    const onChangeHandler = (option: IGvOptionType<GeovisUserToProjectRole>) => onChange(option.value);

    return (
        <GeovisSelect
            name="selectUserToProjectRole"
            value={getIGvOptionType(relation.Role, getGeovisUserToProjectRoleToDescription)}
            options={options}
            onChange={onChangeHandler}
        />
    )
}