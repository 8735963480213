//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { LocalMapObjectLink } from '../../../LocalMapObjectLink';

export class LocalMapObjectCamera extends LocalMapObjectLink
{
	public Opacity: number;
}
