import { OptionType } from "@atlaskit/select";
import { debounce } from "lodash";
import { ChartType, getChartTypeByName, getChartTypeToName } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { KindOfElementUsing } from "../../../server/AVTService/TypeLibrary/Common/KindOfElementUsing";
import { getSensorValueAttributeByName, SensorValueAttribute } from "../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { TimePeriod } from "../../../server/AVTService/TypeLibrary/Common/TimePeriod";
import { GeovisChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { SensorHeatpulseOption } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SensorHeatpulseOption";
import { getPhysicalUnitShortName, PhysicalUnit, PhysicalUnitSupported } from "../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { getSensorCategoryByName, getSensorCategoryName, getSensorCategorySupportedUnitsValue, SensorCategory } from "../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { GeovisMstShortInfoModel } from "../../../server/GEOvis3/Model/Database/GeovisMstShortInfoModel";
import { DtsChartSensorsInfo } from "../../../server/GEOvis3/Model/GeovisChart/DtsChartSensorsInfo";
import { SensorInfo } from "../../../server/GEOvis3/Model/SensorInfo";
import { IGvOptionType } from "../../select/GeovisSelect_tools";
import { dtsSectionAxisTypes, TextValueType } from "./types";
import { IGeovisReportPageConfig } from "../../../store/projectReport.types";
import { GeovisChartAxisSettings } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { InclinometerVisibleRangeSettings } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerVisibleRangeSettings";

/**
 * Return true is chart is template
 * @param chart 
 * @returns 
 */
export const isChartTemplate = (chart: GeovisChartModel): boolean => {
    return chart.KindOfElementUsing === KindOfElementUsing.Template || chart.KindOfElementUsing === KindOfElementUsing.DefaultTemplate;
}

/**
 * Create the function to trigger onChartPropertyChanged function when property changed
 * @param action 
 * @returns 
 */
export const onChartPropertyChangedFunc = <TChart extends GeovisChartModel>(action: (propertyName: keyof TChart, value: any) => void) => (propertyName: keyof TChart) => (value: any) => action(propertyName, value);

/**
 * Common func to create "changePropertyFunction" for project element
 * @param action 
 * @returns 
 */
export const onProjectElementPropertyChangedFunc = <TElement extends any>(action: (propertyName: keyof TElement, value: any) => void) => (propertyName: keyof TElement) => (value: any) => action(propertyName, value);

export const isDtsChart = (type: any): boolean => {
    if (typeof (type) === "string") {
        return type === getChartTypeToName(ChartType.DtsChart);
    }

    return type === ChartType.DtsChart;
}

export const compareNotChartTypes = (type: ChartType | string, ...etalon: ChartType[]): boolean => !compareChartTypes(type, ...etalon);

export const compareChartTypes = (type: ChartType | string, ...etalon: ChartType[]): boolean => {
    if (typeof type === "string") {
        return etalon.includes(getChartTypeByName(type.toString()));
    }

    return etalon.includes(type);
}

export const compareValueAttribute = (attr: SensorValueAttribute, ...anyOf: SensorValueAttribute[]) => anyOf.indexOf(attr) > -1;

export const compareValueAttributes = (attribute: string, etalon: SensorValueAttribute): boolean => {
    return getSensorValueAttributeByName(attribute) === etalon;
}

export const getChartTypeNormalized = (type: any): ChartType => {
    if (typeof (type) === "string") {
        return getChartTypeByName(type);
    }

    return type;
}

export const compareSensorCategory = (type: any, etalon: SensorCategory): boolean => {
    if (typeof (type) === "string") {
        return type === getSensorCategoryName(etalon);
    }

    return type === etalon;
}

export const getValidSensorCategory = (category: any): SensorCategory => {
    if (typeof (category) === "string") {
        return getSensorCategoryByName(category);
    }

    return category;
}

export const onChangeTextPropertyDebounced = debounce((value: TextValueType, onChange: (changedValue: TextValueType) => void) => onChange(value), 500);


export const isTheSameSensorType = (sensorType1: SensorCategory, sensorType2: any) => {
    if (typeof sensorType1 === typeof sensorType2) {
        return sensorType1 === sensorType2;
    }

    let value2 = sensorType2 as SensorCategory;
    if (typeof sensorType2 === 'string') {
        value2 = getSensorCategoryByName(sensorType2);
    }
    return sensorType1 === value2;
}

const checkSensorHeatpulseIds = (sensorId: string, heatpulseOption: SensorHeatpulseOption, sensorType: SensorCategory): boolean => {
    switch (heatpulseOption) {
        case SensorHeatpulseOption.Heatpulse_Temperature: return sensorId.toUpperCase().includes("_HP_T_");
        case SensorHeatpulseOption.Heatpulse_Stoke_Antistoke: return sensorId.toUpperCase().includes("_HP_STOKE_");
        case SensorHeatpulseOption.Heatpulse_Attenuation: return sensorId.toUpperCase().includes("_HP_ATTENUATION_");
        case SensorHeatpulseOption.Heatpulse_dTNorm: return sensorId.toUpperCase().includes("_HP_DTNORM");
        case SensorHeatpulseOption.Heatpulse_T0: return sensorId.toUpperCase().includes("_HP_T0_");
        case SensorHeatpulseOption.Heatpulse_dT: return sensorId.toUpperCase().includes("_HP_DT_");
        case SensorHeatpulseOption.Heatpulse_tc: return sensorId.toUpperCase().includes("_HP_TC_");
        default: {
            switch (sensorType) {
                case SensorCategory.Stoke: return sensorId.toUpperCase().includes("_STOKE_") && !sensorId.toUpperCase().includes("_HP_STOKE_");
                case SensorCategory.Attenuation: return sensorId.toUpperCase().includes("_ATTENUATION_") && !sensorId.toUpperCase().includes("_HP_ATTENUATION_");
                default:
                    return sensorId.toUpperCase().includes("_T_") && !sensorId.toUpperCase().includes("_HP_T_");
            }
        }
    }
}

const getHeatpulseSensorInfosByOptions = (allSensors: SensorInfo[], heatpulseOption: SensorHeatpulseOption, sensorType: SensorCategory): SensorInfo[] => {
    if (allSensors.length === 0) {
        return allSensors;
    }

    return allSensors.filter(s => checkSensorHeatpulseIds(s.Id, heatpulseOption, sensorType));
}

export const getDtsChartSensorIdsOfType = (dtsChartSensorsInfo: DtsChartSensorsInfo, sensorType: SensorCategory, heatpulseOption: SensorHeatpulseOption): string[] => {
    // const heatpulseOptionPattern = getSensorHeatpulseOptionPattern(heatpulseOption);
    if (isTheSameSensorType(SensorCategory.Temperature, sensorType)) {
        // return getHeatpulseSensorInfos(dtsChartSensorsInfo.TemperatureSensors, heatpulseOptionPattern).map(s => s.Id);
        return getHeatpulseSensorInfosByOptions(dtsChartSensorsInfo.TemperatureSensors, heatpulseOption, sensorType).map(s => s.Id);
    }
    else if (isTheSameSensorType(SensorCategory.Attenuation, sensorType)) {
        // return getHeatpulseSensorInfos(dtsChartSensorsInfo.AttenuationSensors, heatpulseOptionPattern).map(s => s.Id);
        return getHeatpulseSensorInfosByOptions(dtsChartSensorsInfo.AttenuationSensors, heatpulseOption, sensorType).map(s => s.Id);
    }
    else if (isTheSameSensorType(SensorCategory.Stoke, sensorType)) {
        // return getHeatpulseSensorInfos(dtsChartSensorsInfo.StokeSensors, heatpulseOptionPattern).map(s => s.Id);
        return getHeatpulseSensorInfosByOptions(dtsChartSensorsInfo.StokeSensors, heatpulseOption, sensorType).map(s => s.Id);
    }
    else if (isTheSameSensorType(SensorCategory.NormalizedTemperature, sensorType)) {
        // return getHeatpulseSensorInfos(dtsChartSensorsInfo.NormalizedTemperatureSensors, heatpulseOptionPattern).map(s => s.Id);
        return getHeatpulseSensorInfosByOptions(dtsChartSensorsInfo.NormalizedTemperatureSensors, heatpulseOption, sensorType).map(s => s.Id);
    }
    else if (isTheSameSensorType(SensorCategory.ThermalConductivity, sensorType)) {
        // return getHeatpulseSensorInfos(dtsChartSensorsInfo.ThermalConductivitySensors, heatpulseOptionPattern).map(s => s.Id);
        return getHeatpulseSensorInfosByOptions(dtsChartSensorsInfo.ThermalConductivitySensors, heatpulseOption, sensorType).map(s => s.Id);
    }

    return [];
}

export const getNormalizedSensorType = (sensorType: SensorCategory): SensorCategory => {
    let sensorTypeValue = sensorType;
    if (typeof (sensorType) === 'string') {
        sensorTypeValue = getSensorCategoryByName(sensorType);
    }

    return sensorTypeValue;
}

export const isTemperatureSensorType = (sensorType: SensorCategory): boolean => {
    return getNormalizedSensorType(sensorType) === SensorCategory.Temperature;
}

export const isTemperatureSensorsType = (pages: IGeovisReportPageConfig[]): boolean => {

    // geovisChartsArray.map(ch => ch.LeftYAxisSettings.TypeOfSensor)
    // sensorsTypes: SensorCategory[]

    const sensorTypes = new Map<SensorCategory, number>();

    for (const page of pages) {

        page.geovisCharts.forEach(({ Chart }) => {

            const left = Chart.LeftYAxisSettings.TypeOfSensor;
            sensorTypes.set(left, (sensorTypes.get(left) || 0) + 1);
        });

    }

    if (sensorTypes.size > 1) {
        return false;
    }

    return sensorTypes.has(SensorCategory.Temperature);
    // return sensorsTypes.filter(st => getNormalizedSensorType(st) === SensorCategory.Temperature).length === sensorsTypes.length;
}

export const getPredefinedColors = (): string[] => {
    return [
        "#90ee90", // Light green
        "#ffd700", // Gold
        "#008b8b", // Dark cyan
        "#808000", // Olive
        "#f08080", // Light coral
        "#7b68ee", // Medium state blue
        "#008000", // Green
        "#ffa500", // Orange
        "#0000ff", // Blue
        "#808080", // Gray
        "#4682b4", // Steel blue
        "#ff00ff", // Magenta        
        "#006400", // Dark green
        "#ffff00", // Yellow
        "#00008b", // Dark blue
        "#000000", // Black        
        "#8b0000", // Dark red
        "#800080", // Purple
    ]
}

export const getPhysicalUnitsOptionsOfCategory = (sensorType: SensorCategory): Array<IGvOptionType<PhysicalUnit>> => {

    if (compareSensorCategory(sensorType, SensorCategory.Unknown)) {
        return PhysicalUnitSupported.map((u) => ({ label: getPhysicalUnitShortName(u), value: u }));
    }
    else {
        return getSensorCategorySupportedUnitsValue(getValidSensorCategory(sensorType)).map((u) => ({ label: getPhysicalUnitShortName(u), value: u }));
    }
}

export const getMstsOptions = (msts: GeovisMstShortInfoModel[]): OptionType[] => {
    const options = msts.map(mst => ({ label: mst.Name, value: mst.Alias }));
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
}

export const getTimes = (): string[] => {
    const result: string[] = [];
    for (let index = 0; index < 24; index++) {
        result.push(index.toLocaleString("en-en", { minimumIntegerDigits: 2 }) + ":00");
        result.push(index.toLocaleString("en-en", { minimumIntegerDigits: 2 }) + ":30");
    }
    return result;
}

export const getTimeOfPeriodInHours = (periodValue: TimePeriod): number => {
    switch (periodValue) {
        case TimePeriod.Hour1: return 1;
        case TimePeriod.Hour2: return 2;
        case TimePeriod.Hour6: return 6;
        case TimePeriod.Hour12: return 12;
        case TimePeriod.Day1: return 24;
        case TimePeriod.Day2: return 48;
        case TimePeriod.Day4: return 96;
        case TimePeriod.Week1: return 168;
        case TimePeriod.Week2: return 336;
        case TimePeriod.Month1: return 720;
        case TimePeriod.Month2: return 1440;
        case TimePeriod.Month3: return 2160;
        case TimePeriod.Month6: return 4320;
        case TimePeriod.Year1: return 8760;
        case TimePeriod.Year2: return 17520;
        case TimePeriod.Year3: return 26280;
        case TimePeriod.Year5: return 43800;
        case TimePeriod.Year10: {
            // in period of 10 years we have at least 2 leap years
            return 10 * 365 * 24 + 2 * 24;
        }
    }

    return 0;
}

export const isHeatableSensorType = (sensorType: SensorCategory) => {
    return sensorType === SensorCategory.Temperature ||
        sensorType === SensorCategory.Stoke ||
        sensorType === SensorCategory.Attenuation ||
        sensorType === SensorCategory.NormalizedTemperature ||
        sensorType === SensorCategory.ThermalConductivity;
}

export const getSensorHeatpulseOptions = (sensorType: SensorCategory): SensorHeatpulseOption[] => {
    switch (sensorType) {
        case SensorCategory.Temperature:
            return [SensorHeatpulseOption.Default, SensorHeatpulseOption.Heatpulse_Temperature, SensorHeatpulseOption.Heatpulse_T0, SensorHeatpulseOption.Heatpulse_dT];

        case SensorCategory.Stoke:
            return [SensorHeatpulseOption.Default, SensorHeatpulseOption.Heatpulse_Stoke_Antistoke];

        case SensorCategory.Attenuation:
            return [SensorHeatpulseOption.Default, SensorHeatpulseOption.Heatpulse_Attenuation];

        case SensorCategory.NormalizedTemperature:
            return [SensorHeatpulseOption.Heatpulse_dTNorm];

        case SensorCategory.ThermalConductivity:
            return [SensorHeatpulseOption.Heatpulse_tc];

        default:
            return [];
    }
}

/**
 * Check if time value axis for DTS section
 * @param type 
 * @returns 
 */
export const isDtsSensorTypeOfSeries = (type: SensorCategory) => {
    return dtsSectionAxisTypes.includes(type);
}

export const getSensorCategory = (sensorCategory: any) => {
    let value = sensorCategory as SensorCategory;
    if (typeof (sensorCategory) === 'string') {
        value = getSensorCategoryByName(sensorCategory);
    }
    return value;
}

export const isVisibleSensorType = (sensorType: SensorCategory) => {
    const sensorCategory = getSensorCategory(sensorType);
    return sensorCategory !== SensorCategory.MST && sensorCategory !== SensorCategory.SB;
}
// TODO: move it to server side and apply validation of chart config on server side

const absoluteTimeValueChartSensorValueToAlarmFunctionsMap = new Map<SensorValueAttribute, SensorValueAttribute[]>([
    [SensorValueAttribute.Value1, [SensorValueAttribute.Value1]],
    [SensorValueAttribute.Value2, [SensorValueAttribute.Value2]],
    [SensorValueAttribute.Value3, [SensorValueAttribute.Value3]],
    [SensorValueAttribute.AxisValue1, [SensorValueAttribute.AxisValue1]],
    [SensorValueAttribute.AxisValue2, [SensorValueAttribute.AxisValue2]],
    [SensorValueAttribute.AxisValue3, [SensorValueAttribute.AxisValue3]],
    [SensorValueAttribute.ValuesXYZ, [SensorValueAttribute.Value1, SensorValueAttribute.Value2, SensorValueAttribute.Value3]],
    [SensorValueAttribute.Axis, [SensorValueAttribute.AxisValue1, SensorValueAttribute.AxisValue2, SensorValueAttribute.AxisValue3]],
    [SensorValueAttribute.Deviation, [SensorValueAttribute.Deviation]],
    [SensorValueAttribute.DeviationXY, [SensorValueAttribute.DeviationXY]]
]);

const relativeTimeValueChartSensorValueToAlarmFunctionsMap = new Map<SensorValueAttribute, SensorValueAttribute[]>([
    [SensorValueAttribute.Value1, [SensorValueAttribute.Relative1]],
    [SensorValueAttribute.Value2, [SensorValueAttribute.Relative2]],
    [SensorValueAttribute.Value3, [SensorValueAttribute.Relative3]],
    [SensorValueAttribute.AxisValue1, [SensorValueAttribute.AxisRelative1]],
    [SensorValueAttribute.AxisValue2, [SensorValueAttribute.AxisRelative2]],
    [SensorValueAttribute.AxisValue3, [SensorValueAttribute.AxisRelative3]],
    [SensorValueAttribute.ValuesXYZ, [SensorValueAttribute.Relative1, SensorValueAttribute.Relative2, SensorValueAttribute.Relative3]],
    [SensorValueAttribute.Axis, [SensorValueAttribute.AxisRelative1, SensorValueAttribute.AxisRelative2, SensorValueAttribute.AxisRelative3]],
    [SensorValueAttribute.Deviation, [SensorValueAttribute.Deviation]],
    [SensorValueAttribute.DeviationXY, [SensorValueAttribute.DeviationXY]]
]);

const vibrationChartSensorValueToAlarmFunctionMap = new Map<SensorValueAttribute, SensorValueAttribute[]>([
    [SensorValueAttribute.PeakX, [SensorValueAttribute.PeakX]],
    [SensorValueAttribute.PeakY, [SensorValueAttribute.PeakY]],
    [SensorValueAttribute.PeakZ, [SensorValueAttribute.PeakZ]],
    [SensorValueAttribute.PeakVector, [SensorValueAttribute.PeakVector]],
    [SensorValueAttribute.VSum, [SensorValueAttribute.VSum]],
]);

const vibrationChartFreqValueToAlarmFreqFunctionMap = new Map<SensorValueAttribute, SensorValueAttribute[]>([
    [SensorValueAttribute.DomFreqX, [SensorValueAttribute.DomFreqX]],
    [SensorValueAttribute.DomFreqY, [SensorValueAttribute.DomFreqY]],
    [SensorValueAttribute.DomFreqZ, [SensorValueAttribute.DomFreqZ]],
    [SensorValueAttribute.DomFreqVector, [SensorValueAttribute.DomFreqVector]],
    [SensorValueAttribute.FreqMaxVcomponent, [SensorValueAttribute.FreqMaxVcomponent]],
]);

const createXyChartAbsoluteSensorValueToAlarmFunctionMap = (): Map<SensorValueAttribute, SensorValueAttribute[]> => {

    const result = new Map<SensorValueAttribute, SensorValueAttribute[]>();
    const modifications = new Map<SensorValueAttribute, SensorValueAttribute[]>([
        [SensorValueAttribute.Value1, [SensorValueAttribute.Reference1]],
        [SensorValueAttribute.Value2, [SensorValueAttribute.Reference2]],
        [SensorValueAttribute.Value3, [SensorValueAttribute.Reference3]],

        [SensorValueAttribute.AxisValue1, [SensorValueAttribute.AxisReference1]],
        [SensorValueAttribute.AxisValue2, [SensorValueAttribute.AxisReference2]],
        [SensorValueAttribute.AxisValue3, [SensorValueAttribute.AxisReference3]],
    ])


    absoluteTimeValueChartSensorValueToAlarmFunctionsMap.forEach((values, key) => {

        const mods = modifications.get(key);
        if (!mods) {
            result.set(key, values);
            return;
        }

        result.set(key, [...values, ...mods]);
    });


    return result;
}

// const createXyChartAbsoluteAndRelativeSensorValueToAlarmFunctionMap = (): Map<SensorValueAttribute, ReadonlyArray<SensorValueAttribute>> => {

//     const result = new Map<SensorValueAttribute, ReadonlyArray<SensorValueAttribute>>();
//     const modifications = new Map<SensorValueAttribute, ReadonlyArray<SensorValueAttribute>>([
//         [SensorValueAttribute.Value1, [SensorValueAttribute.Reference1, SensorValueAttribute.Relative1]],
//         [SensorValueAttribute.Value2, [SensorValueAttribute.Reference2, SensorValueAttribute.Relative2]],
//         [SensorValueAttribute.Value3, [SensorValueAttribute.Reference3, SensorValueAttribute.Relative3]],

//         [SensorValueAttribute.AxisValue1, [SensorValueAttribute.AxisReference1, SensorValueAttribute.AxisRelative1]],
//         [SensorValueAttribute.AxisValue2, [SensorValueAttribute.AxisReference2, SensorValueAttribute.AxisRelative2]],
//         [SensorValueAttribute.AxisValue3, [SensorValueAttribute.AxisReference3, SensorValueAttribute.AxisRelative3]],
//     ])


//     absoluteTimeValueChartSensorValueToAlarmFunctionsMap.forEach((values, key) => {

//         const mods = modifications.get(key);
//         if (!mods) {
//             result.set(key, values);
//             return;
//         }

//         result.set(key, [...values, ...mods]);
//     });


//     return result;
// }

const absoluteXyChartSensorValueToAlarmFunctionMap = createXyChartAbsoluteSensorValueToAlarmFunctionMap();
// const absoluteAndRelativeXyChartSensorValueToAlarmFunctionMap = createXyChartAbsoluteAndRelativeSensorValueToAlarmFunctionMap();


/**
 * Get chart sensor values for alarms
 * @param chart 
 * @returns 
 */
export const getChartSensorValueForAlarm = (chartType: ChartType, axis: GeovisChartAxisSettings, inclinometerVSSettings?: InclinometerVisibleRangeSettings, isLeftAxis?: boolean): SensorValueAttribute[] => {

    // chartType: ChartType, axisSettings: GeovisChartAxisSettings

    const sensorValue: SensorValueAttribute = getSensorValueAttributeByName(axis.sensorValue);
    const isRelative = axis.ShowAsRelative.value;

    let source: Map<SensorValueAttribute, SensorValueAttribute[]> | false = false;

    switch (chartType) {

        case ChartType.TimeValue: {

            source = isRelative
                ? relativeTimeValueChartSensorValueToAlarmFunctionsMap
                : absoluteTimeValueChartSensorValueToAlarmFunctionsMap;

            break;
        }

        case ChartType.XYDiagram: {

            source = isRelative
                ? relativeTimeValueChartSensorValueToAlarmFunctionsMap
                : absoluteXyChartSensorValueToAlarmFunctionMap;

            break;
        }

        case ChartType.Inclinometer: {
            const inclinoResult = [];
            if (inclinometerVSSettings && inclinometerVSSettings.ShowFirstXAxis) {
                inclinoResult.push(SensorValueAttribute.InclinometerChainPosition1);
            }
            if (inclinometerVSSettings && inclinometerVSSettings.ShowSecondYAxis) {
                inclinoResult.push(SensorValueAttribute.InclinometerChainPosition2);
            }

            return inclinoResult;
        }

        case ChartType.XYVibrationEventDiagram: {
            source = isLeftAxis ? vibrationChartSensorValueToAlarmFunctionMap : vibrationChartFreqValueToAlarmFreqFunctionMap;
        }
    }

    if (!source) {
        return [];
    }

    const dest = source.get(sensorValue);
    if (dest) {
        return dest;
    }

    return [];
}