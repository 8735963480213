/**
 * vs@ieskr.ru, year 2019
 * The project logo component definition
 * Created: 03.09.2019
 */
import Button from '@atlaskit/button';
import DropdownMenu, {
    DropdownItem,
    DropdownItemGroup
} from '@atlaskit/dropdown-menu';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import Lozenge from '@atlaskit/lozenge';
import { UploadEndEventPayload, UploadsStartEventPayload } from '@atlaskit/media-picker/dist/cjs/types';
import { Radio } from '@atlaskit/radio';
import React, { SyntheticEvent, useRef } from 'react';
import { IWithGeovisServerProps, withGeovisServer } from '../helpers/GeovisHooks';
import { getReplaceLogoFilesCollectionString } from '../helpers/UploadHelper';
import { t } from '../i18n';
import { LogoFileInfo } from '../server/AVTService/TypeLibrary/Logo/LogoFileInfo';
import { ProjectInfoSlimWithLogoFiles } from '../server/GEOvis3/Model/Project/ProjectInfoSlimWithLogoFiles';
import { ProjectInfo } from '../server/ProjectInfo';
import ServerRoutesGen from '../server/Routes/ServerRoutesGen';
import AuthService from '../services/AuthService';
import FileBrowser, { IErrorUploadFileInfo, IProgressUploadFileInfo } from './FileBrowser';
import { LogoFileExtensions } from './logosManager/types';

/**
 * GEOvis logo base component
 * @param logoUrl 
 */
export const GEOvisLogoComponent = ({ logoUrl, logoClassName }: { logoUrl: string, logoClassName?: string }) => {
    const url = logoUrl.length > 0 ? AuthService.getRequestHelper(AuthService).getEndpointServerUrl(logoUrl) : "";

    return (
        <div
            className={logoClassName ?? "projectLogo"}
            style={{ backgroundImage: `url(${url})` }}>
            {/* noting inside, because the logo must be putted as background image there */}
            {/* see doc for: style={{ backgroundImage: 'url()' }} */}
        </div>
    );
}

const isLogoIdValid = (id: string) => (id && id.length > 0);

export const GEOvisAdminLogoRender = ({ logoId, fileId, fullImage, logoClassName }: { logoId: string, fileId?: string, fullImage: boolean, logoClassName?: string }) => {
    if (isLogoIdValid(logoId)) {
        return (
            <GEOvisLogoComponent
                logoUrl={`${fullImage
                    ? ServerRoutesGen.LogosManager.LogoFullImage.patch(logoId).path
                    : ServerRoutesGen.LogosManager.LogoImage.patch(logoId).path}?fileId=${fileId}`}
                logoClassName={logoClassName}
            />
        )
    }

    return (<div>{/** */}</div>);
}

const isShowContextMenu = (props: IGEOvisLogoViewComponentProps): boolean => {
    if (props.onDelete) {
        return true;
    }

    return false;
}

const isProjectCompanyLogo = ({ project, logoInfo, isCompanyLogo }: IGEOvisLogoViewComponentProps): boolean => {
    if (!logoInfo) {
        return false;
    }

    return project !== undefined && (project.CompanyLogoId === logoInfo.Id || (isCompanyLogo !== undefined && isCompanyLogo === true));
}

const isUserLogo = ({ logoInfo, project }: IGEOvisLogoViewComponentProps): boolean => {
    if (!logoInfo) {
        return false;
    }

    return project !== undefined && project.UserLogoId === logoInfo.Id;
}

export const CompanyLogoLozenge = () => (
    <Lozenge appearance="inprogress">{t("Company logo")}</Lozenge>
)

export const UserLogoLozenge = () => (
    <Lozenge appearance="moved">{t("User logo")}</Lozenge>
)

interface IGEOvisLogoViewComponentProps extends IWithGeovisServerProps {
    logoInfo: LogoFileInfo | undefined;
    project?: ProjectInfoSlimWithLogoFiles;
    label?: string | React.ReactNode;
    /**
     * The component or any ReactChild, which render the logo
     */
    imageRender: React.ReactChild;
    isDirty?: boolean;
    isSelected?: boolean;
    isSelectedRadio?: boolean;
    labelRadio?: string | React.ReactNode;
    isCompanyLogo?: boolean;
    onDelete?: (logoId: string) => void;
    labelTextDelete?: string;
    onSelectButtonClick?: (logoId: string) => void;
    onClick?: (logoId: string) => void;
    onChangeRadioButtonClick?: (event: SyntheticEvent<HTMLInputElement>) => void;

    onReplaceLogoUploadStart?: (payload: UploadsStartEventPayload) => void;
    onReplaceLogoUploadEnd?: (payload: UploadEndEventPayload) => void;
    onReplaceLogoProgress?: (statusUpdateEvent: IProgressUploadFileInfo) => void;
    // onReplaceLogoProcessing?: (uploadProcessing: IProcessingUploadFileInfo) => void;
    onReplaceLogoError?: (errorEvent: IErrorUploadFileInfo) => void;
}


/**
 * GEOvis Logo viewer main component for logo
 * It helps to manage 
 * @param props
 */
export const GEOvisLogoViewComponent = withGeovisServer((props: IGEOvisLogoViewComponentProps) => {

    const {
        logoInfo,
        isCompanyLogo,
        isSelected,
        isSelectedRadio,
        Server,
        labelTextDelete,
        onDelete,
        onSelectButtonClick,
        onChangeRadioButtonClick,
        onClick,
        onReplaceLogoUploadEnd,
        onReplaceLogoError,
        onReplaceLogoProgress,
        onReplaceLogoUploadStart
    } = props;

    const uploadFileBrowser = useRef<FileBrowser>(null);

    const onReplaceFn = () => uploadFileBrowser.current?.triggerBrowseFn();

    const onDeleteFn = () => onDelete && logoInfo && onDelete(logoInfo.Id);
    const onSelectButtonClickFn = () => onSelectButtonClick && onSelectButtonClick(logoInfo ? logoInfo.Id : '');
    const onClickFn = () => onClick && onClick(logoInfo ? logoInfo.Id : '');
    const onChangeRadioButtonClickFn = (event: SyntheticEvent<HTMLInputElement>) => onChangeRadioButtonClick && onChangeRadioButtonClick(event);

    return (
        <div className="flexCellContainer">
            {props.label && (
                <div className="niceProjectLogoFieldLabel">
                    {props.label}
                    {props.isDirty && (<b style={{ color: 'black' }}>&nbsp;*</b>)}
                </div>
            )}
            <div
                className="niceProjectLogoContainer"
                onClick={onClickFn}
                style={{
                    backgroundColor: isSelected ? "#63a9ff" : ""
                }}>

                <div className="flexRowContainer">
                    {isProjectCompanyLogo(props) && (
                        <CompanyLogoLozenge />
                    )}
                    {isUserLogo(props) && (
                        <UserLogoLozenge />
                    )}
                </div>
                {props.imageRender}
                <div className="flexRowContainer">
                    <div
                        className="niceProjectLogoContainerLabel"
                        style={{
                            flexGrow: 1,
                            alignSelf: "center"
                        }}>
                        <span>
                            {logoInfo && logoInfo.FileName}
                        </span>
                    </div>

                    {onReplaceLogoUploadEnd && (
                        <FileBrowser
                            ref={uploadFileBrowser}
                            baseUrl={Server.getApiBaseUrl()}
                            buttonText={""}
                            collection={getReplaceLogoFilesCollectionString(logoInfo ? logoInfo.Id : '')}
                            fileExtensions={LogoFileExtensions}
                            multiple={false}
                            isButton={false}
                            onError={onReplaceLogoError}
                            onUploadEnd={onReplaceLogoUploadEnd}
                            onUploadStatusUpdate={onReplaceLogoProgress}
                            onUploadsStart={onReplaceLogoUploadStart}
                        />
                    )}

                    {isShowContextMenu(props) && (
                        <div>
                            <DropdownMenu
                                triggerType="default"
                                trigger={<MoreVerticalIcon label="" size="small" />}
                                triggerButtonProps={{
                                    appearance: "subtle-link"
                                }}>
                                <DropdownItemGroup key="main_menu">
                                    {onDelete &&
                                        <DropdownItem
                                            key='main_delete'
                                            onClick={onDeleteFn}>
                                            {labelTextDelete ?? t("Delete")}
                                        </DropdownItem>
                                    }

                                    {onReplaceLogoUploadEnd !== undefined && (
                                        <DropdownItem
                                            key='main_replace'
                                            onClick={onReplaceFn}>
                                            {t("Replace")}
                                        </DropdownItem>
                                    )}
                                </DropdownItemGroup>
                            </DropdownMenu>
                        </div>
                    )}
                </div>
            </div>
            {onChangeRadioButtonClick && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Radio
                        name="geovis-logo"
                        value={isCompanyLogo ? "company" : "user"}
                        label={props.labelRadio}
                        isChecked={isSelectedRadio}
                        // onChange={onChangeRadioButtonClickFn}
                        onMouseDown={onChangeRadioButtonClickFn}
                    />
                </div>
            )}
            {onSelectButtonClick && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={onSelectButtonClickFn}>
                        {t("Select")}
                    </Button>
                </div>
            )}
        </div>
    )
})

interface IGEOvisProjectLogoProps {
    logoId: string;
    project?: ProjectInfo;
    logoClassName?: string;
}

/**
 * Project logo
 */
const GEOvisProjectLogo = ({ project, logoId, logoClassName }: IGEOvisProjectLogoProps) => {

    if (!project) {
        return (<div>
            {/*  */}
        </div>)
    }

    if (isLogoIdValid(logoId) && (project.CompanyLogoId === logoId || project.UserLogoId === logoId)) {
        return (<GEOvisLogoComponent logoUrl={ServerRoutesGen.Project.ProjectCustomLogo.patch(project.Id, logoId).path} logoClassName={logoClassName} />);
    }

    const logoUrl = isLogoIdValid(logoId)
        ? ServerRoutesGen.LogosManager.LogoImage.patch(logoId).path
        : "";

    return (<GEOvisLogoComponent logoUrl={logoUrl} logoClassName={logoClassName} />);
}

export default GEOvisProjectLogo;