//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { StatisticRecordState } from './StatisticRecordState';
import { ProjectDolphinStatistic } from './ProjectDolphinStatistic';

export class ProjectStatistic
{
	public Id: string;
	public ProjectId: number;
	public StatisticTime: string;
	public IsEmpty: boolean;
	public State: StatisticRecordState;
	public ForDeletingProject: boolean;
	public RemovedProjectInfo: any;
	public IsMonth: boolean;
	public CountProjectElements: { [key:string]: number };
	public VolumeProjectElementsMb: { [key:string]: number };
	public CountStorageElements: { [key:string]: number };
	public VolumeStorageElementsMb: { [key:string]: number };
	public DolphinInfo: ProjectDolphinStatistic;
}
