import Highcharts, { DashStyleValue, XAxisPlotLinesOptions, YAxisOptions, YAxisPlotBandsOptions } from "highcharts";
import { cloneDeep } from "lodash";
import { AlarmChartValueType } from "../../../../../../server/AVTService/TypeLibrary/Common/AlarmChartValueType";
import { ChartAlarmLineType, ChartAlarmLineTypeList, getChartAlarmLineTypeByName, getChartAlarmLineTypeToName } from "../../../../../../server/AVTService/TypeLibrary/Common/ChartAlarmLineType";
import { ChartType } from "../../../../../../server/AVTService/TypeLibrary/Common/ChartType";
import { SensorValueAttribute } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { ColorizableElement } from "../../../../../../server/AVTService/TypeLibrary/DB/ColorizableElement";
import { AlarmInfoRecord } from "../../../../../../server/AVTService/TypeLibrary/Model/AlarmInfoRecord";
import { AxisScaleLimit } from "../../../../../../server/AVTService/TypeLibrary/Model/AxisScaleLimit";
import { GeovisChartAxisSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { XyChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { HtmlReportSensorDescriptionViewModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportSensorDescriptionViewModel";
import { getPhysicalUnitByName, getPhysicalUnitShortName, PhysicalUnit } from "../../../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { getSensorCategoryByName, SensorCategory } from "../../../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { getSensorSymbolByName, getSensorSymbolToName, SensorSymbol } from "../../../../../../server/AVTService/TypeLibrary/Sensors/SensorSymbol";
import { compareChartTypes } from "../../../../charts/tools";
import { convertValueAttributeToAlarmChartValueType } from "./tools";

const Color = require('color');

type ColorType = string | Highcharts.ColorString | Highcharts.GradientColorObject | Highcharts.PatternObject;

interface IGetSeriesSymbolProps {
    symbol?: 'circle' | 'diamond' | 'square' | 'triangle' | 'triangle-down' | "Romb" | "Star";
    color?: ColorType;
}

export const LOGBOOK_AXIS_NAME = "logbook_axis"

// here the type of series is 'any' because of error "Property 'symbol' does not exist on type 'Series'"
export const getSeriesSymbol = (series: IGetSeriesSymbolProps): string => {
    if (series.symbol !== undefined && series.color !== undefined && typeof series.symbol === 'string') {
        let htmlSymbol = "";
        switch (series.symbol) {
            case 'circle':
                htmlSymbol = '&#9679';
                break;
            case 'diamond':
                htmlSymbol = '&#9670';
                break;
            case 'square':
                htmlSymbol = '&#9632';
                break;
            case 'triangle':
                htmlSymbol = '&#9650';
                break;
            case 'triangle-down':
                htmlSymbol = '&#9660';
                break;
            case "Romb":
                htmlSymbol = '&#9670';
                break;
            case "Star":
                htmlSymbol = '&#9733';
                break;
        }
        if (htmlSymbol.length > 0) {
            return `<span style="color: ${series.color}; font-size: 1.5em;">${htmlSymbol}</span>`;
        }
    }
    return "";
}

export const getStyledPointName = (name: string, color?: ColorType) => {
    if (!color) {
        return name;
    }

    return `<span style="color: ${color}" >${name}</span>`;
}

export const getUnitShortName = (axisUnit: PhysicalUnit) => {
    let unit = axisUnit;
    if (typeof unit === "string") {
        unit = getPhysicalUnitByName(unit);
    }
    return getPhysicalUnitShortName(unit);
}

export const getDashStyleValue = (lineType: ChartAlarmLineType | string) => {
    let lineTypeValue = lineType;
    if (typeof lineTypeValue === 'string') {
        lineTypeValue = getChartAlarmLineTypeByName(lineTypeValue)
    }
    const dashStyle = getChartAlarmLineTypeToName(lineTypeValue) as DashStyleValue;
    return dashStyle;
}

export const getFirstDashStyleValueExcept = (lineType: ChartAlarmLineType | string) => {
    let lineTypeValue = lineType;
    if (typeof lineTypeValue === 'string') {
        lineTypeValue = getChartAlarmLineTypeByName(lineTypeValue)
    }
    const neededLineType = ChartAlarmLineTypeList.filter(l => l !== lineTypeValue)[0];
    const dashStyle = getChartAlarmLineTypeToName(neededLineType) as DashStyleValue;
    return dashStyle;
}


/**
 * Gets dts chart series line width
 * @param chart 
 * @param colorizableElement 
 * @returns 
 */
export const getDtsChartSeriesLineWidth = (visualSettings: ProjectVisualSettings, chart: GeovisChartModel, colorizableElement: ColorizableElement | undefined): number | undefined => {
    // // if (!chart.DrawLineBetweenPoints.value) {
    // //     return 0;
    // // }

    // // if (AuthService.isNagraDistribution()) {
    // //     return 1.5;
    // // }

    if (colorizableElement && colorizableElement.LineWeight > 0) {
        return colorizableElement.LineWeight;
    }

    return parseFloat(visualSettings.chartLineWidth);
}

/**
 * Gets geovis live sensor series line width
 * @param chart 
 * @param description 
 * @returns 
 */
export const getGeovisChartSeriesLineWidth = (visualSettings: ProjectVisualSettings, description: HtmlReportSensorDescriptionViewModel | undefined): number => {

    if (description && description.lineWeight > 0) {
        return description.lineWeight;
    }

    return parseFloat(visualSettings.chartLineWidth);
}

export const isKnowTypeOfSensor = (typeOfSensor: SensorCategory) => {
    let value = typeOfSensor;
    if (typeof (typeOfSensor) === 'string') {
        value = getSensorCategoryByName(typeOfSensor);
    }
    return value !== SensorCategory.Unknown;

}

interface IOneYAxisOptionsSettings {
    chartType: ChartType
    axisSettings: GeovisChartAxisSettings;
    visualSettings: ProjectVisualSettings;
    plotLines: XAxisPlotLinesOptions[] | undefined;
    plotBands: YAxisPlotBandsOptions[];
    opposite: boolean;
    gridLineColor: string;
    gridLineDashStyle?: DashStyleValue;
    valueType?: SensorValueAttribute;
    hideUnitsInLabel?: boolean;
    minValue?: number;
    maxValue?: number;
    forceMinValue?: boolean;
    forceMaxValue?: boolean;
    noData: boolean;
}

const getOneYAxisOptions = ({
    axisSettings,
    gridLineColor,
    opposite,
    plotLines,
    visualSettings,
    gridLineDashStyle,
    valueType,
    maxValue,
    minValue,
    forceMaxValue,
    forceMinValue,
    noData,
    plotBands
}: IOneYAxisOptionsSettings): YAxisOptions => {

    let limitDelta: number = 0;

    if (minValue !== undefined && maxValue !== undefined) {
        const minValueNorm = axisSettings.minScaleLimit.exact ? axisSettings.minScaleLimit.value : minValue;
        const maxValueNorm = axisSettings.maxScaleLimit.exact ? axisSettings.maxScaleLimit.value : maxValue;
        limitDelta = Math.abs(maxValueNorm - minValueNorm);
    }

    return {
        title: {
            text: getAxisLabel(axisSettings, valueType),
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            }
        },
        lineWidth: 2,
        lineColor: "black",
        crosshair: true,
        labels: {
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            },
            useHTML: true,
            formatter() {
                return (this.value as number).toFixed(axisSettings.numberOfDigits.value);
                // if (this.isLast && !hideUnitsInLabel) {
                //     return `<div style="margin-top: ${unitMargin}px;">${axisSettings.DestConverter.enable ? getUnitShortName(getPhysicalUnitByName(axisSettings.DestConverter.unit)) : getUnitShortName(axisSettings.Unit)}</div><div style="display: flex;justify-content: flex-end;">${thisValue}</div>`;
                // } else {
                //     return thisValue;
                // }
            }
        },
        tickPositioner() {
            let positions = this.tickPositions;

            if (!positions) {
                return [];
            }

            if (this.min && axisSettings.minScaleLimit.exact) {
                for (let index = 0; index < positions.length; index++) {
                    if (this.min && positions[index] < this.min) {
                        positions.splice(index, 1);
                        index--;
                    }
                }
            }
            if (this.max && axisSettings.maxScaleLimit.exact) {
                for (let index = 0; index < positions.length; index++) {
                    if (this.max && positions[index] > this.max) {
                        positions.splice(index, 1);
                        index--;
                    }
                }
            }

            const removeLess = (dataArray: Highcharts.AxisTickPositionsArray, value: number): Highcharts.AxisTickPositionsArray => {
                const result: Highcharts.AxisTickPositionsArray = [];
                dataArray.forEach(data => {
                    if (data >= value) {
                        result.push(data);
                    }
                })
                return result;
            }

            const removeBigger = (dataArray: Highcharts.AxisTickPositionsArray, value: number): Highcharts.AxisTickPositionsArray => {
                const result: Highcharts.AxisTickPositionsArray = [];
                dataArray.forEach(data => {
                    if (data <= value) {
                        result.push(data);
                    }
                })
                return result;
            }

            // when min or max axis settings EXACT set we should display first/last axis label. To prevent rounding up because of using startOnTick and endOnTick we manually add min or max or both
            // labels. 

            if (this.min && !checkPositionInclude(positions, this.min, axisSettings.numberOfDigits.value) && axisSettings.minScaleLimit.exact && this.min >= axisSettings.minScaleLimit.value && !axisSettings.minScaleLimit.auto) {
                positions.splice(0, 0, this.min);
                positions = removeLess(positions, this.min);
            }

            if (this.max && !checkPositionInclude(positions, this.max, axisSettings.numberOfDigits.value) && axisSettings.maxScaleLimit.exact && this.max <= axisSettings.maxScaleLimit.value && !axisSettings.maxScaleLimit.auto) {
                positions.push(this.max);
                positions = removeBigger(positions, this.max);
            }

            if (this.min && this.max && positions.length === 0) {
                positions.push(this.min);
                positions.push(this.max);
            }

            return positions;
        },
        plotLines,
        gridLineColor,
        gridLineDashStyle,
        opposite,
        // min: getLimitValue(axisSettings.minScaleLimit, axisSettings.DestConverter.enable ? axisSettings.DestConverter.multiplicatorValue : undefined, minValue),
        // max: getLimitValue(axisSettings.maxScaleLimit, axisSettings.DestConverter.enable ? axisSettings.DestConverter.multiplicatorValue : undefined, maxValue),
        softMax: getSoftLimitValue(axisSettings.maxScaleLimit, axisSettings.DestConverter.enable ? axisSettings.DestConverter.multiplicatorValue : undefined),
        softMin: getSoftLimitValue(axisSettings.minScaleLimit, axisSettings.DestConverter.enable ? axisSettings.DestConverter.multiplicatorValue : undefined),
        min: getAxisLimitValue(axisSettings.minScaleLimit, false, noData, axisSettings.DestConverter.enable ? axisSettings.DestConverter.multiplicatorValue : undefined, minValue, limitDelta, forceMinValue),
        max: getAxisLimitValue(axisSettings.maxScaleLimit, true, noData, axisSettings.DestConverter.enable ? axisSettings.DestConverter.multiplicatorValue : undefined, maxValue, limitDelta, forceMaxValue),
        startOnTick: undefined,
        endOnTick: undefined,
        gridLineWidth: 1,
        gridZIndex: 2,
        minRange: 0.01,
        plotBands
    }
}

export const checkPositionInclude = (positions: Highcharts.AxisTickPositionsArray, position: number, numberOfDigits: number): boolean => {
    const positionNumbers: string[] = [];
    positions.forEach(pos => {
        positionNumbers.push(pos.toFixed(numberOfDigits));
    });

    return positionNumbers.includes(position.toFixed(numberOfDigits));
}
/**
 * 
 * @param alarmChartValueType attribute of alarm
 * @param isRelative should be shown as relative
 * @param source attribute of chart
 * @returns 
 */
const isAttributeMatch = (alarmChartValueType: AlarmChartValueType, source: SensorValueAttribute | undefined): boolean => {
    if (source === undefined) {
        return false;
    }

    const sourceAlarmType = convertValueAttributeToAlarmChartValueType(source);

    return alarmChartValueType === sourceAlarmType ||
        alarmChartValueType === AlarmChartValueType.AllCharts ||
        sourceAlarmType === AlarmChartValueType.AllCharts;
}

interface IYAxesOptionsSettings {
    chart: GeovisChartModel;
    visualSettings: ProjectVisualSettings;
    linkedAlarmLines: AlarmInfoRecord[];
    primaryAxisValueType?: SensorValueAttribute;
    secondaryAxisValueType?: SensorValueAttribute;
    needSecondAxis: boolean;
    hideUnitsInLabel?: boolean;
    drawAlarmLines: boolean;
    minValue?: number;
    maxValue?: number;
    maxYSecondary?: number;
    minYSecondary?: number;
    forceMinValue?: boolean;
    forceMaxValue?: boolean;
    noData: boolean;
}

export const getYAxisOptions = ({
    chart,
    linkedAlarmLines,
    needSecondAxis,
    visualSettings,
    drawAlarmLines,
    hideUnitsInLabel,
    primaryAxisValueType,
    secondaryAxisValueType,
    maxValue,
    minValue,
    forceMaxValue,
    forceMinValue,
    noData,
    maxYSecondary,
    minYSecondary
}: IYAxesOptionsSettings): YAxisOptions | YAxisOptions[] => {
    const leftAxisPlotLines: XAxisPlotLinesOptions[] = [];
    const leftAxisPlodBands: YAxisPlotBandsOptions[] = [];
    const hasSecondaryYAxis = isKnowTypeOfSensor(chart.RightYAxisSettings.TypeOfSensor);

    const allAlarmLines: AlarmInfoRecord[] = compareChartTypes(chart.Type, ChartType.XYDiagram) ? [] : cloneDeep(chart.AlarmLines);
    allAlarmLines.push(...linkedAlarmLines.filter(al => !al.swapXY));

    const chartDestUnitCoeff = chart.LeftYAxisSettings.DestConverter.enable ? chart.LeftYAxisSettings.DestConverter.multiplicatorValue : 1;

    if (allAlarmLines.length > 0 && drawAlarmLines) {

        allAlarmLines.filter(a => a.enabled).forEach(alarmLine => {

            const plotLine: XAxisPlotLinesOptions = {
                value: +alarmLine.yvalueFrom * (!alarmLine.alarmId ? chartDestUnitCoeff : 1),
                label: alarmLine.drawLine || alarmLine.fillAlarmArea ? {
                    text: alarmLine.label,
                    style: {
                        color: alarmLine.lineColor
                    }
                } : undefined,
                color: alarmLine.drawLine ? alarmLine.lineColor : "#ffffff00",
                width: alarmLine.lineWeight * 2,
                dashStyle: getDashStyleValue(alarmLine.lineType),
                zIndex: 4
            };

            if (chart.Type === ChartType.DtsChart
                || chart.Type === ChartType.TimeValue && isAttributeMatch(alarmLine.alarmChartValueType, primaryAxisValueType)
                || chart.Type === ChartType.XYDiagram && isAttributeMatch(alarmLine.alarmChartValueType, primaryAxisValueType)
                || chart.Type === ChartType.VibrationDiagramm
                || chart.Type === ChartType.XYVibrationEventDiagram) {

                leftAxisPlotLines.push(plotLine);
                const color = Color(alarmLine.lineColor).alpha(0.4);

                if (alarmLine.fillAlarmArea) {
                    const plotArea: YAxisPlotBandsOptions = {
                        from: alarmLine.fillFrom,
                        to: alarmLine.fillTo,
                        color: color.rgb().string(),
                        zIndex: 3,
                    }
                    leftAxisPlodBands.push(plotArea);
                }
            }
        })
    }

    // const primaryAxis: YAxisOptions = getOneYAxisOptions(chart.LeftYAxisSettings, visualSettings, leftAxisPlotLines, false, '#e6e6e6', undefined, primaryAxisValueType, hideUnitsInLabel);
    const primaryAxis: YAxisOptions = getOneYAxisOptions({
        chartType: chart.Type,
        axisSettings: chart.LeftYAxisSettings,
        gridLineColor: '#e6e6e6',
        opposite: false,
        plotLines: leftAxisPlotLines,
        visualSettings,
        gridLineDashStyle: undefined,
        hideUnitsInLabel,
        valueType: primaryAxisValueType,
        minValue,
        maxValue,
        forceMaxValue,
        forceMinValue,
        noData,
        plotBands: leftAxisPlodBands
    });
    if (hasSecondaryYAxis && needSecondAxis) {
        return [
            primaryAxis,
            // getOneYAxisOptions(chart.RightYAxisSettings, visualSettings, undefined, true, '#e6e6e6', 'Dash', secondaryAxisValueType, hideUnitsInLabel),
            getOneYAxisOptions({
                chartType: chart.Type,
                axisSettings: chart.RightYAxisSettings,
                gridLineColor: '#e6e6e6',
                opposite: true,
                plotLines: undefined,
                visualSettings,
                gridLineDashStyle: 'Dash',
                hideUnitsInLabel,
                valueType: secondaryAxisValueType,
                noData: false,
                plotBands: [],
                minValue: minYSecondary,
                maxValue: maxYSecondary
            }),
            getAnnotationYAxis()
        ]; // #a6a6a6 transparent
    }
    return [
        primaryAxis,
        getAnnotationYAxis()
    ];
}

const getAnnotationYAxis = (): YAxisOptions => {
    return {
        visible: false,
        min: 0,
        max: 100,
        zoomEnabled: false,
        id: LOGBOOK_AXIS_NAME
    };
}

export const getMarkerSymbol = (colorizableElement: ColorizableElement | undefined): string | undefined => {
    if (!colorizableElement) {
        return undefined;
    }

    let sensorSymbol = colorizableElement.Symbol;
    if (typeof sensorSymbol === "string") {
        sensorSymbol = getSensorSymbolByName(sensorSymbol);
    }

    switch (sensorSymbol) {
        case SensorSymbol.Circle:
            return "circle";

        case SensorSymbol.Square:
            return "square";

        case SensorSymbol.TriangleUp:
            return "triangle";

        case SensorSymbol.TriangleDown:
            return "triangle-down";

        default:
            return getSensorSymbolToName(sensorSymbol);
    }
}

export const getColor = (colorizableElement: ColorizableElement | undefined): string | undefined => {
    return colorizableElement ? colorizableElement.Color : undefined;
}

export const getAxisLabel = (axisSettings: GeovisChartAxisSettings, valueType?: SensorValueAttribute): string | undefined => {
    if (!axisSettings.showAxisLabels.value) {
        return undefined;
    }

    const unit = axisSettings.showAxisLabelsUnit ? `[${axisSettings.DestConverter.enable ? getUnitShortName(getPhysicalUnitByName(axisSettings.DestConverter.unit)) : getUnitShortName(axisSettings.Unit)}]` : "";

    let axisLabel = axisSettings.axisXLabel;

    if (valueType !== undefined && (valueType === SensorValueAttribute.AxisValue1 || valueType === SensorValueAttribute.Value1)) {
        axisLabel = axisSettings.axisXLabel;
    }
    if (valueType && (valueType === SensorValueAttribute.AxisValue2 || valueType === SensorValueAttribute.Value2)) {
        axisLabel = axisSettings.axisYLabel;
    }
    if (valueType && (valueType === SensorValueAttribute.AxisValue3 || valueType === SensorValueAttribute.Value3)) {
        axisLabel = axisSettings.axisZLabel;
    }

    return `${axisLabel} ${unit}`;
}

export const getLimitValue = (scaleLimit: AxisScaleLimit, modifier?: number, limitValue?: number): number | undefined | null => {
    if (scaleLimit.auto !== true && scaleLimit.exact === true) {
        if (limitValue) {
            return modifier ? limitValue * modifier : limitValue;
        }
        return modifier ? scaleLimit.value * modifier : scaleLimit.value;
    }
    return null;
}

export const getAxisLimitValue = (scaleLimit: AxisScaleLimit, isMax: boolean, noData: boolean, modifier?: number, limitValue?: number, limitDelta: number = 0, forceLimit: boolean = false): number | undefined | null => {
    // if no data in chart we should display Y axis from min set axis limit to max
    if (noData) {
        return modifier ? scaleLimit.value * modifier : scaleLimit.value;
    }
    // when scale is auto then we should display axis until max limit value
    if ((scaleLimit.auto || forceLimit) && limitValue !== undefined) {
        const limit = isMax ? limitValue + limitDelta * 0.1 : limitValue - limitDelta * 0.1;
        return modifier ? limit * modifier : limit;
    }
    if (!scaleLimit.auto && scaleLimit.exact) {
        return modifier ? scaleLimit.value * modifier : scaleLimit.value;
    }

    if (!scaleLimit.auto && !scaleLimit.exact) {
        if (limitValue === undefined || limitDelta === undefined) {
            return null;
        }
        const limit = isMax ? Math.max(scaleLimit.value, limitValue + limitDelta * 0.1) : Math.min(scaleLimit.value, limitValue - limitDelta * 0.1);
        return modifier ? limit * modifier : limit;
    }
    return undefined;
}

export const getExtremumValue = (values: number[], index: number): number | undefined => {
    if (values.length < index + 1) {
        return undefined;
    }

    return isNaN(values[index]) ? undefined : values[index]
}

export const getSoftLimitValue = (scaleLimit: AxisScaleLimit, modifier?: number): number | undefined => {
    if (scaleLimit.auto === true || scaleLimit.exact === undefined) {
        return undefined;
    }
    return modifier ? scaleLimit.value * modifier : scaleLimit.value;
}

export const startEndOnTick = (scaleLimit: AxisScaleLimit): boolean | undefined => {
    if (scaleLimit.auto === true) {
        return undefined;
    }
    return !scaleLimit.exact;
}

export const selectValue = (values: number[], valueType: SensorValueAttribute): number => {

    if (valueType === SensorValueAttribute.Value2 || valueType === SensorValueAttribute.AxisValue2) {
        return values.length > 1 ? values[1] : NaN;
    }
    else if (valueType === SensorValueAttribute.Value3 || valueType === SensorValueAttribute.AxisValue3) {
        return values.length > 2 ? values[2] : NaN;
    }
    else if (values.length > 0) {
        return values[0];
    }
    return NaN;
}

export const getSeriesUnitShortName = (chart: GeovisChartModel, axisIndex: number): string => {
    if (axisIndex === 3) {
        return getUnitShortName(chart.LeftYAxisSettings.DestConverter.enable ? getPhysicalUnitByName(chart.LeftYAxisSettings.DestConverter.unit) : chart.LeftYAxisSettings.Unit);
    }

    if (axisIndex === 2 && compareChartTypes(chart.Type, ChartType.XYDiagram)) {
        const xyChart = chart as XyChartModel;
        return getUnitShortName(xyChart.XAxisSettings.DestConverter.enable ? getPhysicalUnitByName(xyChart.XAxisSettings.DestConverter.unit) : xyChart.XAxisSettings.Unit);
    }

    if (axisIndex === 1) {
        return getUnitShortName(chart.RightYAxisSettings.DestConverter.enable ? getPhysicalUnitByName(chart.RightYAxisSettings.DestConverter.unit) : chart.RightYAxisSettings.Unit);
    }
    return "";
}



export const getCustomTooltipInfo = (sensorId: string, descriptions: HtmlReportSensorDescriptionViewModel[]): string => {
    const sensorDescription = descriptions.find(d => d.id === sensorId);
    if (!sensorDescription) {
        return "";
    }

    let customTooltipText = "<div>";
    const tooltipParts = sensorDescription.customTooltip ? sensorDescription.customTooltip.split("\n") : [];
    tooltipParts.forEach(part => {
        customTooltipText += `<div>${part}</div>`
    })
    customTooltipText += '</div>'
    return customTooltipText;
}

export const getYAxisUnitsAnnotation = (axisSettings: GeovisChartAxisSettings, axisIndex: number): Highcharts.AnnotationsOptions => {

    const annotationCallbackFunc = (axisNumber: number) => (annotation: any): Highcharts.AnnotationMockPointOptionsObject => {

        // const xAxisDelta = Math.abs(annotation.chart.xAxis[0].max - annotation.chart.xAxis[0].min);
        const xAxisValue = axisNumber === 0 ? 0 : annotation.chart.plotWidth + annotation.chart.plotLeft - annotation.chart.axisOffset[3];
        const yAxisDelta = Math.abs(annotation.chart.yAxis[axisNumber].max - annotation.chart.yAxis[axisNumber].min);

        return {
            x: xAxisValue,
            y: annotation.chart.yAxis[axisNumber].max ? annotation.chart.yAxis[axisNumber].max - 0.01 * (yAxisDelta) : 0,
            xAxis: null,
            yAxis: axisNumber
        }
    }

    const unitText = axisSettings.DestConverter.enable ? getUnitShortName(getPhysicalUnitByName(axisSettings.DestConverter.unit)) : getUnitShortName(axisSettings.Unit);

    const result: Highcharts.AnnotationsOptions = {
        draggable: '',
        labels: [{
            useHTML: false,
            align: axisIndex === 0 ? 'right' : 'left',
            text: `[${unitText}]`,
            point: annotationCallbackFunc(axisIndex),
            crop: false,
            overflow: 'allow'
        }],
        labelOptions: {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            borderWidth: 0,
        },

    }

    return result;
}

export const getXAxisUnitsAnnotation = (axisSettings: GeovisChartAxisSettings): Highcharts.AnnotationsOptions => {

    const annotationCallbackFunc = (annotation: any): Highcharts.AnnotationMockPointOptionsObject => {

        const plotHeightDelta = Math.abs(annotation.chart.chartHeight - annotation.chart.plotHeight)
        const yAxisValue = annotation.chart.chartHeight - (plotHeightDelta - 20);
        const xAxisDelta = Math.abs(annotation.chart.xAxis[0].max - annotation.chart.xAxis[0].min);

        return {
            y: yAxisValue,
            x: annotation.chart.xAxis[0].max ? annotation.chart.xAxis[0].max - 0.02 * (xAxisDelta) : 0,
            xAxis: 0,
            yAxis: null
        }
    }

    const unitText = axisSettings.DestConverter.enable ? getUnitShortName(getPhysicalUnitByName(axisSettings.DestConverter.unit)) : getUnitShortName(axisSettings.Unit);

    const result: Highcharts.AnnotationsOptions = {
        draggable: '',
        labels: [{
            useHTML: false,
            align: 'left',
            // verticalAlign: 'bottom',
            text: `[${unitText}]`,
            point: annotationCallbackFunc,
            crop: false,
            overflow: 'allow'
        }],
        labelOptions: {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            borderWidth: 0,
        },

    }

    return result;
}

export const getAlarmLinesAnnotations = (alarms: AlarmInfoRecord[]): Highcharts.AnnotationsOptions[] => {
    const result: Highcharts.AnnotationsOptions[] = [];

    alarms.filter(a => a.enabled && !a.isGap).forEach(alarm => {
        const hasLabel = alarm.label !== '';
        const alarmAnnotation: Highcharts.AnnotationsOptions = {
            draggable: '',
            labelOptions: {
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderWidth: 0,
            },
            labels: hasLabel ? [{
                useHTML: false,
                // verticalAlign: 'top',
                align: 'right',
                text: alarm.label,
                style: {
                    color: alarm.lineColor
                },
                y: 1,
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: +alarm.xvalueTo,
                    y: +alarm.yvalueTo
                }
            }] : undefined,
            shapes: [{
                type: 'path',
                stroke: alarm.lineColor,
                strokeWidth: alarm.lineWeight * 1.5,
                dashStyle: getDashStyleValue(alarm.lineType),
                points: [{
                    xAxis: 0,
                    yAxis: 0,
                    x: +alarm.xvalueFrom,
                    y: +alarm.yvalueFrom
                }, {
                    xAxis: 0,
                    yAxis: 0,
                    x: +alarm.xvalueTo,
                    y: +alarm.yvalueTo
                }]
            }]
        }

        result.push(alarmAnnotation);
    })

    return result;
}


