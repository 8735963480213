//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { AlarmActionUIType } from '../../../AVTService/TypeLibrary/Alarming/AlarmActionUIType';

export class AlarmActionTestPayload
{
	public Message: string;
	public Subject: string;
	public Type: AlarmActionUIType;
	public EmailReceiversIds: string[];
	public SmsReceiversIds: string[];
	public PresetsId: string;
	public ForConfirm: boolean;
	public Alias: string;
	public SensorboxIds: string;
	public ConditionIndex: number;
}
