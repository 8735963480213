/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.11.2020
 * @description Project data methods
 */

import { Dispatch } from "redux";
import AxiosCancelError from "../errors/AxiosCancelError";
import { t } from '../i18n';
import { ActionResponse } from "../server/ActionResponse";
import { AuthorizationCode } from "../server/AuthorizationCode";
import { LocalMapObject } from "../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject";
import { ProjectVisualSettings } from "../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { Geovis4SensorsSymbolSettings } from "../server/AVTService/TypeLibrary/Sensors/SymbolSettings/Geovis4SensorsSymbolSettings";
import { ChainInfo } from "../server/ChainInfo";
import { DataActionResponse } from "../server/DataActionResponse";
import { ProjectDXFLayerDocument } from "../server/GEOvis3/Model/Layers/ProjectDXFLayerDocument";
import { LocalMapObjectSensorsInfo } from "../server/GEOvis3/Model/LocalMapObjects/LocalMapObjectSensorsInfo";
import { ProjectViewInfo } from "../server/GEOvis3/Model/ProjectViews/ProjectViewInfo";
import { SensorInfo } from "../server/GEOvis3/Model/SensorInfo";
import { SensorsInfoRequest } from "../server/GEOvis3/Model/Sensors/SensorsInfoRequest";
import { ProjectInfo } from "../server/ProjectInfo";
import { ProjectSensorAlarmInfo } from "../server/ProjectSensorAlarmInfo";
import ServerRoutesGen from "../server/Routes/ServerRoutesGen";
import FlagService from "../services/FlagService";
import Logger from "../services/Logger";
import { IHttpRequestOptions, IRequestHelper } from "../services/RequestHelper";
import {
    projectDataProperties,
    projectDataPropertiesData
} from "../store/creators/dataCreators";
import { IProjectInfoStorage } from "../store/data.types";
import {
    defaultSomethingStorageState,
    errorSomethingStorageState,
    IGeovisAction,
    ISomethingStoreBase,
    loadedSomethingStorageState
} from "../store/types";
import {
    IProjectLocalMapObjectsData,
    IProjectOverviewMainData,
    IProjectSensorsChainsInfoData
} from "./ProjectDataHelper.types";
import Route from "./Route";

const cancelledRequestSlim = <TResponse extends ActionResponse>(error: AxiosCancelError): TResponse => ({
    ...({} as TResponse),
    AuthorizationCode: AuthorizationCode.Cancelled,
    JsonPath: '',
    Messages: [error.message],
    Success: false
});

const cancelledRequest = <TData extends any>(error: AxiosCancelError): DataActionResponse<TData> => ({
    AuthorizationCode: AuthorizationCode.Cancelled,
    Data: {} as TData,
    JsonPath: '',
    Messages: [error.message],
    Success: false,
    FailedFields: []
});

const unexpectedErrorSlim = <TResponse extends ActionResponse>(error: any): TResponse => ({
    ...({} as TResponse),
    AuthorizationCode: AuthorizationCode.UnexpectedError,
    JsonPath: '',
    Messages: [error instanceof Error ? error.message : `${error}`],
    Success: false,
    FailedFields: []
})

const unexpectedError = <TData extends any>(error: any): DataActionResponse<TData> => ({
    AuthorizationCode: AuthorizationCode.UnexpectedError,
    Data: {} as TData,
    JsonPath: '',
    Messages: [error instanceof Error ? error.message : `${error}`],
    Success: false,
    FailedFields: []
});

export const failedDataActionResult = <TData extends any>(...errorDescription: string[]): DataActionResponse<TData> => ({
    AuthorizationCode: AuthorizationCode.UnexpectedError,
    Data: {} as TData,
    JsonPath: '',
    Messages: errorDescription,
    Success: false,
    FailedFields: []
});

export const successRequest = <TData extends any>(data: TData): DataActionResponse<TData> => ({
    AuthorizationCode: AuthorizationCode.OK,
    Data: data,
    JsonPath: '',
    Messages: [],
    Success: true,
    FailedFields: []
});

export const failedResponse = <TData extends any>(response: ActionResponse): DataActionResponse<TData> => ({
    ...response,
    Data: {} as TData
});

/**
 * Make empty storage with 
 * @param initialState 
 */
export const getEmptyStorage = <TStorage extends ISomethingStoreBase>(initialState: Omit<TStorage, keyof ISomethingStoreBase>): TStorage => ({
    ...({} as TStorage),
    ...defaultSomethingStorageState,
    ...initialState
});

/**
 * Make empty loaded storage
 * @param initialState 
 */
export const getLoadedStorage = <TStorage extends ISomethingStoreBase>(initialState: Omit<TStorage, keyof ISomethingStoreBase>): TStorage => ({
    ...({} as TStorage),
    ...initialState,
    ...loadedSomethingStorageState
});

/**
 * Create failed storage with error descriptions
 * @param initialState 
 * @param errorDescriptions 
 * @returns 
 */
export const getFailedStorage = <TStorage extends ISomethingStoreBase>(initialState: Omit<TStorage, keyof ISomethingStoreBase>, ...errorDescriptions: string[]): TStorage => ({
    ...({} as TStorage),
    ...initialState,
    ...errorSomethingStorageState(...errorDescriptions)
})

/**
 * Load project info
 * @param server 
 * @param dispatch 
 * @param projectId 
 */
export const loadProjectInfoData = async (server: IRequestHelper, dispatch: Dispatch<IGeovisAction>, projectId: string) => {

    Logger.data("Loading project properties...");
    dispatch(projectDataProperties());

    const result = await fetchServerElements<ProjectInfo>(server, ServerRoutesGen.Project.Get.patch(projectId));

    if (result.Success) {
        dispatch(projectDataPropertiesData(result.Data));
        return;
    }

    FlagService.addErrors(t("Can't load project properties"), result.Messages);
}

//#region Fetching data

export const fetchProjectSensorsSymbolSettings = async (server: IRequestHelper, projectId: string | number): Promise<DataActionResponse<Geovis4SensorsSymbolSettings>> => {
    try {
        const url = ServerRoutesGen.Project.GetSensorsSymbolSettings.patch(projectId).path;
        return await server.get<DataActionResponse<Geovis4SensorsSymbolSettings>>(url);
    } catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequest(error);
        }

        return unexpectedError(error);
    }
}

export const fetchProjectVisualSettings = async (server: IRequestHelper, projectId: string): Promise<DataActionResponse<ProjectVisualSettings>> => {
    try {
        const url = ServerRoutesGen.Project.GetProjectVisualSettings.patch(projectId).path;
        return await server.get<DataActionResponse<ProjectVisualSettings>>(url);
    } catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequest(error);
        }

        return unexpectedError(error);
    }
}

/**
 * Fetch project info and project views configuration from the server
 * @param server 
 * @param projectId 
 */
export const fetchProjectOverviewConfiguration = async (server: IRequestHelper, projectId: string | number): Promise<IProjectOverviewMainData> => ({
    projectInfo: await fetchServerElements<ProjectInfo>(server, ServerRoutesGen.Project.Get.patch(projectId)),
    projectViewsInfo: await fetchServerElements<ProjectViewInfo[]>(server, ServerRoutesGen.ProjectViews.GetViewsInfo.patch(projectId)),
    symbolSettings: await fetchProjectSensorsSymbolSettings(server, projectId)
});

/**
 * Load project sensors and chains configurations info
 * @param server 
 * @param projectId 
 * @param viewId 
 */
export const fetchSensorsChainsInfoData = async (server: IRequestHelper, projectId: number, viewId: string): Promise<IProjectSensorsChainsInfoData> => ({
    chains: await fetchServerElements<ChainInfo[]>(server, ServerRoutesGen.Project.GetChainsInfoOfView.patch(projectId, viewId)),
    sensors: await fetchServerElements<SensorInfo[]>(server, ServerRoutesGen.Project.GetSensorsInfoOfView.patch(projectId, viewId))
})

/**
 * Load project view local map objects
 * @param server 
 * @param projectId 
 * @param viewId 
 */
export const fetchProjectViewLocalMapObjects = async (server: IRequestHelper, projectId: number, viewId: string, userToken?: string, userId?: string): Promise<IProjectLocalMapObjectsData> => {
    if (userToken && userId) {
        return ({
            localMapObjects: await fetchServerElements<LocalMapObject[]>(server, ServerRoutesGen.ReportPdfRenderData.GetLocalMapObjectsOfView.patch(projectId, userToken, userId, viewId)),
            lmoChildSensors: await fetchServerElements<LocalMapObjectSensorsInfo[]>(server, ServerRoutesGen.ReportPdfRenderData.GetProjectViewLmoSensorsInfo.patch(projectId, userToken, userId, viewId))
        });
    }

    return ({
        localMapObjects: await fetchServerElements<LocalMapObject[]>(server, ServerRoutesGen.LocalMapObjects.GetLocalMapObjectsOfView.patch(projectId, viewId)),
        lmoChildSensors: await fetchServerElements<LocalMapObjectSensorsInfo[]>(server, ServerRoutesGen.LocalMapObject.GetProjectViewLmoSensorsInfo.patch(projectId, viewId))
    });
};

/**
 * Fetch server elements by route
 * @param server 
 * @param route 
 */
export const fetchServerElements = async <TData>(server: IRequestHelper, route: Route, options?: IHttpRequestOptions): Promise<DataActionResponse<TData>> => {
    try {
        return await server.get<DataActionResponse<TData>>(route.path, options);
    }
    catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequest<TData>(error);
        }
        return unexpectedError<TData>(error);
    }
}

/**
 * Fetch server elements by route. Controlled cancel
 * @param server 
 * @param route 
 */
export const fetchServerElementsCancellable = async <TData>(server: IRequestHelper, route: Route, requestId: string): Promise<DataActionResponse<TData>> => {
    try {
        return await server.getCancellable<DataActionResponse<TData>>(route.path, requestId);
    }
    catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequest<TData>(error);
        }
        return unexpectedError<TData>(error);
    }
}

/**
 * Fetch server elements by post request
 * For example: if you want to get some data by filter
 * @param server 
 * @param route 
 * @param payload 
 */
export const fetchServerElementsByPost = async<TData, TPostData>(
    server: IRequestHelper,
    route: Route,
    payload?: TPostData
): Promise<DataActionResponse<TData>> => {
    try {
        return await server.post<DataActionResponse<TData>>(route.path, payload || {});
    }
    catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequest<TData>(error);
        }
        return unexpectedError<TData>(error);
    }
}

/**
 * Fetch server elements by post request
 * Can be cancelled
 * @param server 
 * @param route 
 * @param payload 
 */
export const fetchServerElementsByPostCancellable = async<TData, TPostData>(
    server: IRequestHelper,
    route: Route,
    requestId: string,
    payload?: TPostData
): Promise<DataActionResponse<TData>> => {
    try {
        return await server.postCancellable<DataActionResponse<TData>>(route.path, requestId, payload || {});
    }
    catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequest<TData>(error);
        }
        return unexpectedError<TData>(error);
    }
}

export const sendServerPostRequest = async <TResponse extends ActionResponse>(
    server: IRequestHelper,
    route: Route
): Promise<TResponse> => {
    try {
        return await server.post<TResponse>(route.path, {});
    }
    catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequestSlim<TResponse>(error);
        }
        return unexpectedErrorSlim<TResponse>(error);
    }
}

export const sendServerPostRequestCustomHeaders = async <TResponse extends ActionResponse>(
    server: IRequestHelper,
    route: Route,
    options?: IHttpRequestOptions
): Promise<TResponse> => {
    try {
        return await server.post<TResponse>(route.path, {}, options);
    }
    catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequestSlim<TResponse>(error);
        }
        return unexpectedErrorSlim<TResponse>(error);
    }
}

export const sendServerGetRequest = async <TResponse extends ActionResponse>(
    server: IRequestHelper,
    route: Route
): Promise<TResponse> => {
    try {
        return await server.get<TResponse>(route.path);
    }
    catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequestSlim<TResponse>(error);
        }
        return unexpectedErrorSlim<TResponse>(error);
    }
}

export const sendServerPostRequestData = async <TRequest extends {}, TResponse extends ActionResponse>(
    server: IRequestHelper,
    route: Route,
    payload: TRequest,
    options?: IHttpRequestOptions
): Promise<TResponse> => {
    try {
        return await server.post<TResponse>(route.path, payload, options);
    }
    catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequestSlim<TResponse>(error);
        }
        return unexpectedErrorSlim<TResponse>(error);
    }
}

/**
 * Send delete request to server
 * @param server 
 * @param route 
 * @returns 
 */
export const sendServerDeleteRequest = async <TResponse extends ActionResponse>(
    server: IRequestHelper,
    route: Route
): Promise<TResponse> => {
    try {
        return await server.delete<TResponse>(route.path);
    }
    catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequestSlim<TResponse>(error);
        }
        return unexpectedErrorSlim<TResponse>(error);
    }
}

/**
 * Fetch sensors info by request
 * @param server 
 * @param route 
 * @param request 
 */
export const fetchSensorsInfoOfIds = async <TSensorInfo>(server: IRequestHelper, route: Route, request: SensorsInfoRequest): Promise<DataActionResponse<TSensorInfo[]>> => {
    try {
        return await server.post<DataActionResponse<TSensorInfo[]>>(route.path, request);
    }
    catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequest<TSensorInfo[]>(error);
        }

        return unexpectedError<TSensorInfo[]>(error);
    }
}

//#endregion

//#endregion Fetch data

/**
 * Load recent alarms of the project
 * @param server the request helper
 * @param projectId project identifier
 * @param reloadAnyway if true, then reload the storage data anyway
 */
export const fetchProjectRaisedAlarmsAsync = async (server: IRequestHelper, projectId: number, viewId: string): Promise<DataActionResponse<ProjectSensorAlarmInfo[]>> => {
    return fetchProjectAlarms(server, ServerRoutesGen.Alarms.RaisedAlarmsOfView.patch(projectId, viewId));
}

/**
 * Load project active alarms
 * @param server
 * @param projectId project identifier
 * @param reloadAnyway if true, then reload the storage data anyway
 */
export const fetchProjectAlarmHistoryAsync = async (server: IRequestHelper, projectId: number, viewId: string): Promise<DataActionResponse<ProjectSensorAlarmInfo[]>> => {
    return await fetchProjectAlarms(server, ServerRoutesGen.Alarms.AlarmHistoryOfView.patch(projectId, viewId));
}

/**
 * Load project internal alarms (it works only for "project admin" or uer type "company admin" and higher)
 * @param server
 * @param projectId project identifier
 * @param reloadAnyway if true, then reload the storage data anyway
 */
export const fetchProjectInternalAlarmsAsync = async (server: IRequestHelper, projectId: number, viewId: string) => {

    // check permissions to load data
    // TODO: check permission on Server side
    // if (!AuthService.isRealAdminOfProject(+projectId) && !AuthService.hasUserTypeAsCompanyAdmin()) {
    //     return;
    // }

    return await fetchProjectAlarms(server, ServerRoutesGen.Alarms.InternalAlarmsOfView.patch(projectId, viewId));
}

/**
 * Load project alarms
 * @param server 
 * @param url the url to get data from server
 */
const fetchProjectAlarms = async (server: IRequestHelper, url: Route): Promise<DataActionResponse<ProjectSensorAlarmInfo[]>> => {
    try {

        return await server.get<DataActionResponse<ProjectSensorAlarmInfo[]>>(url.path);

    } catch (error) {
        if (error instanceof AxiosCancelError) {
            return cancelledRequest<ProjectSensorAlarmInfo[]>(error);
        }

        return unexpectedError<ProjectSensorAlarmInfo[]>(error);
    }
}

//#endregion

export const fetchDxfLayersGeoJSONContent = async (server: IRequestHelper, projectId: number, layerId: string): Promise<DataActionResponse<ProjectDXFLayerDocument>> => {
    const route = ServerRoutesGen.DxfLayers.GeoJSONContent.patch(projectId, layerId);

    return fetchServerElements<ProjectDXFLayerDocument>(server, route);
}

/**
 * Checks that loading of project info is required
 * @param param0 
 * @param projectId 
 */
export const isLoadProjectInfoRequired = ({ isLoaded, isError, project, }: IProjectInfoStorage, projectId: number | string | undefined): boolean => {
    if (isError) {
        return false;
    }

    if (!projectId || !isLoaded) {
        return true;
    }

    return project.Id !== +projectId;
}