/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.04.2022
 * @description Timevalue Y-axis settings tab
 */

import { t } from '../../../../i18n';
import { ChartType } from '../../../../server/AVTService/TypeLibrary/Common/ChartType';
import { DefaultChartSensorFilterOption } from '../../../../server/AVTService/TypeLibrary/Common/DefaultChartSensorFilterOption';
import { DefaultTimeValueChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DefaultTimeValueChartModel';
import { GeovisChartAxisSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings';
import { TimeValueChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel';
import { SensorCategoryOrdered } from '../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { SensorChangesModel } from '../../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel';
import { ChartAxisSensorsControl } from '../ChartAxisSensorsControl';
import { GeovisChartAxisSettingsControl } from '../GeovisChartAxisSettingsControl';
import { isChartTemplate } from '../tools';
import { IGeovisChartPropertyChangedProps, IGeovisChartSensorsPropertyProps } from '../types';

interface IDefaultTimeValueChartYAxisTab extends IGeovisChartPropertyChangedProps<DefaultTimeValueChartModel>, IGeovisChartSensorsPropertyProps {
    chart: DefaultTimeValueChartModel;
}

/**
 * TimeValue chart Y-axis settings
 * @param props ITimeValueChartYAxisTab
 * @returns 
 */
export const DefaultChartYAxisTab = ({ chart, onChartPropertyChanged, sensorsChanges }: IDefaultTimeValueChartYAxisTab) => {

    const { LeftYAxisSettings, RightYAxisSettings } = chart;
    const isTemplate = isChartTemplate(chart);

    const onAxisPropertyChangedHandler = (axisPropertyName: keyof TimeValueChartModel, axisSettings: GeovisChartAxisSettings) => (propertyName: keyof GeovisChartAxisSettings, value: any) => {

        const changedAxisSettings: Partial<GeovisChartAxisSettings> = { ...axisSettings };
        changedAxisSettings[propertyName] = value;

        onChartPropertyChanged(axisPropertyName, changedAxisSettings);
    }

    const onAxisPropertiesChangedHandlerFunc = (axisPropertyName: keyof TimeValueChartModel, axisSettings: GeovisChartAxisSettings) => (properties: Partial<GeovisChartAxisSettings>) => {
        onChartPropertyChanged<GeovisChartAxisSettings>(axisPropertyName, { ...axisSettings, ...properties });
    }

    const onAxisSensorsChangedHandler = (axisPropertyName: keyof TimeValueChartModel, axisSettings: GeovisChartAxisSettings) => (sensorIds: string[], customerChangeable: boolean, sensorChanges: SensorChangesModel[]) => {

        const newAxisSettings: GeovisChartAxisSettings = {
            ...axisSettings,
            SensorIds: [...sensorIds],
            IsSensorsCustomerChangeable: customerChangeable
        }

        // onChangeSensorsChanges(sensorChanges);
        onChartPropertyChanged(axisPropertyName, newAxisSettings, sensorChanges);
    }

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={{ width: '49%' }}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t("Left Y-Axis")}</div>
                    <GeovisChartAxisSettingsControl
                        chartType={ChartType.TimeValue}
                        onAxisPropertiesChanged={onAxisPropertiesChangedHandlerFunc("LeftYAxisSettings", LeftYAxisSettings)}
                        onAxisPropertyChanged={onAxisPropertyChangedHandler("LeftYAxisSettings", LeftYAxisSettings)}
                        axisSettings={LeftYAxisSettings}
                        sensorTypesToSelect={SensorCategoryOrdered}
                        isXAxis={false}
                        hideTypeOfSensor={true}
                        isRightAxis={false}
                        isDisabled={false}
                    />

                    {!isTemplate && chart.FilterType === DefaultChartSensorFilterOption.UseManuallySelectedSensors &&
                        <ChartAxisSensorsControl
                            isChain={false}
                            chartType={chart.Type}
                            dtsSectionIds={[]}
                            projectId={chart.ProjectId}
                            ySensorType={LeftYAxisSettings.TypeOfSensor}
                            // xSensorType={compareChartTypes(Chart.Type, [ChartType.XYDiagram]) ? (Chart as XyChartModel).XAxisSettings.TypeOfSensor : undefined} // undefined for DtsChartModel
                            sensorIds={LeftYAxisSettings.SensorIds}
                            customerChangeable={LeftYAxisSettings.IsSensorsCustomerChangeable}
                            onChange={onAxisSensorsChangedHandler("LeftYAxisSettings", chart.LeftYAxisSettings)}
                            sensorChanges={sensorsChanges}
                            showPairsDialog={false} // pairs dialog for XyChart
                            selectedChainsIds={[]} // in this dialog we do not need those ids
                            isSingle={false}
                        // onChangeXyChartSensorsPairs={undefined}
                        // sensorsPairs={(Chart as XyChartModel) ? (Chart as XyChartModel).SensorPairs : undefined} // only for XyChart
                        />
                    }
                </div>

                <div className="flexCellContainer_g1 separator">
                    {/** */}
                </div>

                <div className="flexCellContainer" style={{ width: '49%' }}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t("Right Y-Axis")}</div>
                    <GeovisChartAxisSettingsControl
                        chartType={ChartType.TimeValue}
                        onAxisPropertiesChanged={onAxisPropertiesChangedHandlerFunc("RightYAxisSettings", RightYAxisSettings)}
                        onAxisPropertyChanged={onAxisPropertyChangedHandler("RightYAxisSettings", RightYAxisSettings)}
                        axisSettings={RightYAxisSettings}
                        sensorTypesToSelect={SensorCategoryOrdered}
                        isXAxis={false}
                        isRightAxis={true}
                        isDisabled={false}
                    />

                    {!isTemplate &&
                        <ChartAxisSensorsControl
                            isChain={false}
                            chartType={chart.Type}
                            dtsSectionIds={[]}
                            projectId={chart.ProjectId}
                            ySensorType={RightYAxisSettings.TypeOfSensor}
                            // xSensorType={compareChartTypes(Chart.Type, [ChartType.XYDiagram]) ? (Chart as XyChartModel).XAxisSettings.TypeOfSensor : undefined} // undefined for DtsChartModel
                            sensorIds={RightYAxisSettings.SensorIds}
                            customerChangeable={RightYAxisSettings.IsSensorsCustomerChangeable}
                            onChange={onAxisSensorsChangedHandler("RightYAxisSettings", RightYAxisSettings)}
                            sensorChanges={sensorsChanges}
                            showPairsDialog={false} // pairs dialog for XyChart
                            selectedChainsIds={[]} // in this dialog we do not need those ids
                            isSingle={false}
                        />
                    }

                </div>
            </div>
        </div>
    )
}