/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 10.03.2020
 * @description Alarms helpers
 */

import { IProjectAlarmsStorage } from "../store/data.types";

export const RaisedAlarmsStorageName = "RaisedAlarms";

export const AlarmHistoryStorageName = "AlarmHistory";

export const InternalAlarmsStorageName = "InternalAlarms";

export const transparentAlarmStorageWrapper = (alarmStorage: IProjectAlarmsStorage): IProjectAlarmsStorage => {
    return alarmStorage;
}

export const activeAlarmStorageWrapper = (alarmStorage: IProjectAlarmsStorage): IProjectAlarmsStorage => {
    // this style is to save performance, instead of spreading operator
    return {
        alarms: alarmStorage.alarms.filter(a => a.IsPublic),
        isError: alarmStorage.isError,
        isLoaded: alarmStorage.isLoaded,
        isLoading: alarmStorage.isLoading,
        isInProgress: alarmStorage.isInProgress,
        storageName: alarmStorage.storageName,
        errorDescription: alarmStorage.errorDescription
    };
}