/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 23.12.2020
 * @description Global statistics and project history reducer
 */

import { Reducer } from 'redux';
import { elementsToMap, elementsToMapOfCustomKey } from '../../helpers/StorageHelper';
import { ProjectCreationDateKey, ProjectNumberKey } from '../../pages/business/statistics/projectsHistory/ProjectsHistoryTableView';
import { ProjectHistoryModel } from '../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectHistoryModel';
import { CompanyInvoiceRecord } from '../../server/AVTService/TypeLibrary/Statistics/CompanyInvoiceRecord';
import { CompanyInfo } from '../../server/GEOvis3/Model/Company/CompanyInfo';
import {
    STATISTCS_COMPANY_INVOICE_CHANGED_COMPANY,
    STATISTCS_COMPANY_INVOICE_HIDE_PROJECTS,
    STATISTCS_COMPANY_INVOICE_LOADED,
    STATISTCS_COMPANY_INVOICE_LOADING,
    STATISTCS_COMPANY_INVOICE_LOADING_ERROR,
    STATISTCS_COMPANY_INVOICE_SHOW_PROJECTS,
    STATISTICS_COMPANY_INVOICE_HIDE_EXPORT_DIALOG,
    STATISTICS_COMPANY_INVOICE_SHOW_EXPORT_DIALOG,
    STATISTICS_PROJECT_HISTORY_CHANGE_SORT,
    STATISTICS_PROJECT_HISTORY_COMPANY_ID,
    STATISTICS_PROJECT_HISTORY_GEOVIS_4_PACKAGE,
    STATISTICS_PROJECT_HISTORY_PROJECT_STATES,
    STATISTICS_PROJECT_HISTORY_SEARCH,
    STATISTICS_PROJECTS_HISTORY,
    STATISTICS_PROJECTS_HISTORY_COMPANIES_DATA,
    STATISTICS_PROJECTS_HISTORY_DATA
} from '../actions/statisticsActions';
import { processFetchedData } from '../helpers/DataHelper';
import {
    IGeovisCompanyInvoiceState,
    IGeovisProjectsHistoryState,
    IGeovisProjectsHistoryViewState,
    IGeovisStatisticsAction,
    IGeovisStatisticsState
} from '../statistics.types';
import { defaultSomethingStorageState } from '../types';
import { ExportDocumentKind } from '../../server/AVTService/Export/ExportDocumentKind';

const projectsHistoryViewInitialState: IGeovisProjectsHistoryViewState = {
    sortKey: ProjectCreationDateKey,
    sortOrder: 'DESC'
}

const projectsHistoryInitialState: IGeovisProjectsHistoryState = {
    ...defaultSomethingStorageState,
    filters: {
        isGeovis4PackageSelected: false,
        searchQuery: '',
        selectedCompanyId: '',
        selectedProjectStates: [],
    },
    projects: new Map<string, ProjectHistoryModel>(),
    companies: new Map<string, CompanyInfo>(),
    viewState: projectsHistoryViewInitialState
}

const companyInvoiceInitialState: IGeovisCompanyInvoiceState = {
    ...defaultSomethingStorageState,
    records: [],
    selectedCompanyId: "",
    showProjectsWindow: false,
    currentRecord: new CompanyInvoiceRecord(),
    showSelectMonthDialog: false,
    selectedCompanyName: "",
    exportDocumentKind: ExportDocumentKind.EXCEL
}

export const statisticsInitialState: IGeovisStatisticsState = {
    projectsHistory: projectsHistoryInitialState,
    companyInvoice: companyInvoiceInitialState
};

const statisticsReducer: Reducer<IGeovisStatisticsState> = (
    state: IGeovisStatisticsState = statisticsInitialState,
    action: IGeovisStatisticsAction
): IGeovisStatisticsState => {

    const mainState = projectHistoryReducer(state, action);


    return mainState;
}

const projectHistoryReducer = (state: IGeovisStatisticsState, action: IGeovisStatisticsAction): IGeovisStatisticsState => {

    switch (action.type) {
        case STATISTICS_PROJECTS_HISTORY:
            return {
                ...state,
                projectsHistory: {
                    ...projectsHistoryInitialState,
                    filters: state.projectsHistory.filters,
                    companies: state.projectsHistory.companies
                }
            };

        case STATISTICS_PROJECTS_HISTORY_DATA: {
            const { filters, viewState } = state.projectsHistory;

            return {
                ...state,
                projectsHistory: processFetchedData(action.projectsHistoryData, state.projectsHistory, projectsHistoryInitialState, data => ({
                    projects: elementsToMapOfCustomKey(data, p => p.ProjectGUID),
                    filters,
                    viewState,
                    companies: state.projectsHistory.companies
                }))
            }
        }

        case STATISTICS_PROJECT_HISTORY_SEARCH:
            if (action.searchQuery === undefined) {
                return state;
            }

            return {
                ...state,
                projectsHistory: {
                    ...state.projectsHistory,
                    filters: {
                        ...state.projectsHistory.filters,
                        searchQuery: action.searchQuery
                    }
                }
            }

        case STATISTICS_PROJECT_HISTORY_COMPANY_ID:
            if (action.companyId === undefined) {
                return state;
            }

            return {
                ...state,
                projectsHistory: {
                    ...state.projectsHistory,
                    filters: {
                        ...state.projectsHistory.filters,
                        selectedCompanyId: action.companyId
                    }
                }
            }

        case STATISTICS_PROJECT_HISTORY_PROJECT_STATES:
            if (!action.projectHistoryStates) {
                return state;
            }

            return {
                ...state,
                projectsHistory: {
                    ...state.projectsHistory,
                    filters: {
                        ...state.projectsHistory.filters,
                        selectedProjectStates: action.projectHistoryStates
                    }
                }
            }

        case STATISTICS_PROJECT_HISTORY_GEOVIS_4_PACKAGE:
            return {
                ...state,
                projectsHistory: {
                    ...state.projectsHistory,
                    filters: {
                        ...state.projectsHistory.filters,
                        isGeovis4PackageSelected: action.geovis4Package || false
                    }
                }
            }

        case STATISTICS_PROJECTS_HISTORY_COMPANIES_DATA: {
            if (!action.companiesData) {
                return state;
            }

            if (!action.companiesData.Success) {
                return state;
            }

            return {
                ...state,
                projectsHistory: {
                    ...state.projectsHistory,
                    companies: elementsToMap(action.companiesData.Data)
                }
            }
        }

        case STATISTICS_PROJECT_HISTORY_CHANGE_SORT:
            return {
                ...state,
                projectsHistory: {
                    ...state.projectsHistory,
                    viewState: {
                        ...state.projectsHistory.viewState,
                        sortKey: action.sortKey || ProjectNumberKey,
                        sortOrder: action.sortOrder || "ASC"
                    }
                }
            }
        case STATISTCS_COMPANY_INVOICE_CHANGED_COMPANY: {
            if (!action.companyId || !action.stringProperty) {
                return state;
            }
            return {
                ...state,
                companyInvoice: {
                    ...state.companyInvoice,
                    selectedCompanyId: action.companyId,
                    selectedCompanyName: action.stringProperty
                }
            }
        }
        case STATISTCS_COMPANY_INVOICE_LOADED: {
            if (!action.invoice) {
                return state;
            }
            return {
                ...state,
                companyInvoice: {
                    ...state.companyInvoice,
                    errorDescription: "",
                    isLoading: false,
                    isError: false,
                    isLoaded: true,
                    records: action.invoice
                }
            }
        }
        case STATISTCS_COMPANY_INVOICE_LOADING: {
            return {
                ...state,
                companyInvoice: {
                    ...state.companyInvoice,
                    errorDescription: "",
                    isLoading: true,
                    isError: false,
                    isLoaded: false,
                    records: []
                }
            }
        }
        case STATISTCS_COMPANY_INVOICE_LOADING_ERROR: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                companyInvoice: {
                    ...state.companyInvoice,
                    errorDescription: action.stringProperty,
                    isLoading: false,
                    isError: true,
                    isLoaded: false,
                    records: []
                }
            }
        }

        case STATISTCS_COMPANY_INVOICE_SHOW_PROJECTS: {
            if (!action.currentInvoiceRecord) {
                return state;
            }
            return {
                ...state,
                companyInvoice: {
                    ...state.companyInvoice,
                    showProjectsWindow: true,
                    currentRecord: action.currentInvoiceRecord
                }
            }
        }

        case STATISTCS_COMPANY_INVOICE_HIDE_PROJECTS: {
            return {
                ...state,
                companyInvoice: {
                    ...state.companyInvoice,
                    showProjectsWindow: false,
                    currentRecord: new CompanyInvoiceRecord()
                }
            }
        }

        case STATISTICS_COMPANY_INVOICE_SHOW_EXPORT_DIALOG: {
            if (action.exportDocumentKind === undefined) {
                return state;
            }

            return {
                ...state,
                companyInvoice: {
                    ...state.companyInvoice,
                    showSelectMonthDialog: true,
                    exportDocumentKind: action.exportDocumentKind
                }
            }
        }

        case STATISTICS_COMPANY_INVOICE_HIDE_EXPORT_DIALOG: {
            return {
                ...state,
                companyInvoice: {
                    ...state.companyInvoice,
                    showSelectMonthDialog: false
                }
            }
        }
    }

    return state;
}

export default statisticsReducer;