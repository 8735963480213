//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ProjectElementType {
	Group = 0,
	ReportElementLogbook = 1,
	Map = 2,
	Chart = 3,
	ProfileTemplate = 4,
	Profile = 5,
	Header = 6,
	Footer = 7,
	Comment = 10,
	Report = 11,
	CustomReport = 12,
	Unknown = 13,
	GeovisTable = 14,
	ImportTask = 15,
	AlarmPlan = 16,
	DefaultChart = 17,
	DefaultHeader = 18,
	MapSection = 19,
	ProjectChartTemplate = 20,
	CompanyChartTemplate = 21,
	ProjectCommentTemplate = 22,
	CompanyCommentTemplate = 23,
	GeovisImage = 24,
	ProjectGeovisImageTemplate = 25,
	CompanyGeovisImageTemplate = 26,
	GeovisChart = 27,
	GeovisChartTemplate = 28,
	GeovisLogbook = 29,
	Geovis4Table = 30,
	GeovisReport = 31,
	ProjectGeovis4TableTemplate = 32,
	CompanyGeovis4TableTemplate = 33
}

const ProjectElementTypeToName = new Map<number, string>([
	[ 0, 'Group' ],
	[ 1, 'ReportElementLogbook' ],
	[ 2, 'Map' ],
	[ 3, 'Chart' ],
	[ 4, 'ProfileTemplate' ],
	[ 5, 'Profile' ],
	[ 6, 'Header' ],
	[ 7, 'Footer' ],
	[ 10, 'Comment' ],
	[ 11, 'Report' ],
	[ 12, 'CustomReport' ],
	[ 13, 'Unknown' ],
	[ 14, 'GeovisTable' ],
	[ 15, 'ImportTask' ],
	[ 16, 'AlarmPlan' ],
	[ 17, 'DefaultChart' ],
	[ 18, 'DefaultHeader' ],
	[ 19, 'MapSection' ],
	[ 20, 'ProjectChartTemplate' ],
	[ 21, 'CompanyChartTemplate' ],
	[ 22, 'ProjectCommentTemplate' ],
	[ 23, 'CompanyCommentTemplate' ],
	[ 24, 'GeovisImage' ],
	[ 25, 'ProjectGeovisImageTemplate' ],
	[ 26, 'CompanyGeovisImageTemplate' ],
	[ 27, 'GeovisChart' ],
	[ 28, 'GeovisChartTemplate' ],
	[ 29, 'GeovisLogbook' ],
	[ 30, 'Geovis4Table' ],
	[ 31, 'GeovisReport' ],
	[ 32, 'ProjectGeovis4TableTemplate' ],
	[ 33, 'CompanyGeovis4TableTemplate' ],
])

export function getProjectElementTypeToName(element: ProjectElementType) : string {
    return ProjectElementTypeToName.get(element) || '';
}

const ProjectElementTypeByName = new Map<string, number>([
	[ 'Group', 0 ],
	[ 'ReportElementLogbook', 1 ],
	[ 'Map', 2 ],
	[ 'Chart', 3 ],
	[ 'ProfileTemplate', 4 ],
	[ 'Profile', 5 ],
	[ 'Header', 6 ],
	[ 'Footer', 7 ],
	[ 'Comment', 10 ],
	[ 'Report', 11 ],
	[ 'CustomReport', 12 ],
	[ 'Unknown', 13 ],
	[ 'GeovisTable', 14 ],
	[ 'ImportTask', 15 ],
	[ 'AlarmPlan', 16 ],
	[ 'DefaultChart', 17 ],
	[ 'DefaultHeader', 18 ],
	[ 'MapSection', 19 ],
	[ 'ProjectChartTemplate', 20 ],
	[ 'CompanyChartTemplate', 21 ],
	[ 'ProjectCommentTemplate', 22 ],
	[ 'CompanyCommentTemplate', 23 ],
	[ 'GeovisImage', 24 ],
	[ 'ProjectGeovisImageTemplate', 25 ],
	[ 'CompanyGeovisImageTemplate', 26 ],
	[ 'GeovisChart', 27 ],
	[ 'GeovisChartTemplate', 28 ],
	[ 'GeovisLogbook', 29 ],
	[ 'Geovis4Table', 30 ],
	[ 'GeovisReport', 31 ],
	[ 'ProjectGeovis4TableTemplate', 32 ],
	[ 'CompanyGeovis4TableTemplate', 33 ],
]);

export function getProjectElementTypeByName(name: string): ProjectElementType {
    return ProjectElementTypeByName.get(name)!;
}


export const ProjectElementTypeList : Readonly<ProjectElementType[]> = [
	ProjectElementType.Group,
	ProjectElementType.ReportElementLogbook,
	ProjectElementType.Map,
	ProjectElementType.Chart,
	ProjectElementType.ProfileTemplate,
	ProjectElementType.Profile,
	ProjectElementType.Header,
	ProjectElementType.Footer,
	ProjectElementType.Comment,
	ProjectElementType.Report,
	ProjectElementType.CustomReport,
	ProjectElementType.Unknown,
	ProjectElementType.GeovisTable,
	ProjectElementType.ImportTask,
	ProjectElementType.AlarmPlan,
	ProjectElementType.DefaultChart,
	ProjectElementType.DefaultHeader,
	ProjectElementType.MapSection,
	ProjectElementType.ProjectChartTemplate,
	ProjectElementType.CompanyChartTemplate,
	ProjectElementType.ProjectCommentTemplate,
	ProjectElementType.CompanyCommentTemplate,
	ProjectElementType.GeovisImage,
	ProjectElementType.ProjectGeovisImageTemplate,
	ProjectElementType.CompanyGeovisImageTemplate,
	ProjectElementType.GeovisChart,
	ProjectElementType.GeovisChartTemplate,
	ProjectElementType.GeovisLogbook,
	ProjectElementType.Geovis4Table,
	ProjectElementType.GeovisReport,
	ProjectElementType.ProjectGeovis4TableTemplate,
	ProjectElementType.CompanyGeovis4TableTemplate,
];



const ProjectElementTypeToDescription = new Map<number, string>([
	[ 0, 'Group' ],
	[ 1, 'ReportElementLogbook' ],
	[ 2, 'Map' ],
	[ 3, 'Chart' ],
	[ 4, 'ProfileTemplate' ],
	[ 5, 'Profile' ],
	[ 6, 'Header' ],
	[ 7, 'Footer' ],
	[ 10, 'Comment' ],
	[ 11, 'Report' ],
	[ 12, 'CustomReport' ],
	[ 13, 'Unknown' ],
	[ 14, 'GeovisTable' ],
	[ 15, 'ImportTask' ],
	[ 16, 'AlarmPlan' ],
	[ 17, 'DefaultChart' ],
	[ 18, 'DefaultHeader' ],
	[ 19, 'MapSection' ],
	[ 20, 'ProjectChartTemplate' ],
	[ 21, 'CompanyChartTemplate' ],
	[ 22, 'ProjectCommentTemplate' ],
	[ 23, 'CompanyCommentTemplate' ],
	[ 24, 'GeovisImage' ],
	[ 25, 'ProjectGeovisImageTemplate' ],
	[ 26, 'CompanyGeovisImageTemplate' ],
	[ 27, 'GeovisChart' ],
	[ 28, 'GeovisChartTemplate' ],
	[ 29, 'GeovisLogbook' ],
	[ 30, 'Geovis4Table' ],
	[ 31, 'GeovisReport' ],
	[ 32, 'ProjectGeovis4TableTemplate' ],
	[ 33, 'CompanyGeovis4TableTemplate' ],
])

export function getProjectElementTypeToDescription(element: ProjectElementType) : string {
    return ProjectElementTypeToDescription.get(element) || '';
}


export const statisticProjectElementsAllowedProjectElementType:ProjectElementType[] = [
	ProjectElementType.AlarmPlan,
	ProjectElementType.Chart,
	ProjectElementType.Map,
	ProjectElementType.CustomReport,
	ProjectElementType.GeovisTable,
	ProjectElementType.ImportTask,
	ProjectElementType.Profile,
	ProjectElementType.Report,
	ProjectElementType.ReportElementLogbook,
	ProjectElementType.GeovisChart,
	ProjectElementType.GeovisChartTemplate,
	ProjectElementType.GeovisLogbook,
	ProjectElementType.Geovis4Table,
	ProjectElementType.Comment,
	ProjectElementType.ProjectCommentTemplate,
	ProjectElementType.GeovisImage,
	ProjectElementType.ProjectGeovisImageTemplate,
	ProjectElementType.GeovisReport,
	ProjectElementType.MapSection,
];

