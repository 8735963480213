//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { InventoryObjectInfo } from './InventoryObjectInfo';

export class InventoryMstInfo extends InventoryObjectInfo
{
	public SerialNumber: number;
	public SimId: string;
	public FirmwareVersion: string;
	public Name: string;
	public ServiceVersion: string;
	public AccessUrl: string;
	public DeviceName: string;
	public ASIF: boolean;
	public RS485Male: number;
	public RS485Female: number;
	public AmphenolRS485Female: number;
	public ASIFPlug: number;
	public HardwareVersion: string;
	public CameraEthernet: number;
}
