//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SensorColorIntensityCategory {
	Category1 = 1,
	Category2 = 2,
	Category3 = 3,
	Category4 = 4,
	Category5 = 5,
	Category6 = 6
}

const SensorColorIntensityCategoryToName = new Map<number, string>([
	[ 1, 'Category1' ],
	[ 2, 'Category2' ],
	[ 3, 'Category3' ],
	[ 4, 'Category4' ],
	[ 5, 'Category5' ],
	[ 6, 'Category6' ],
])

export function getSensorColorIntensityCategoryToName(element: SensorColorIntensityCategory) : string {
    return SensorColorIntensityCategoryToName.get(element) || '';
}

const SensorColorIntensityCategoryByName = new Map<string, number>([
	[ 'Category1', 1 ],
	[ 'Category2', 2 ],
	[ 'Category3', 3 ],
	[ 'Category4', 4 ],
	[ 'Category5', 5 ],
	[ 'Category6', 6 ],
]);

export function getSensorColorIntensityCategoryByName(name: string): SensorColorIntensityCategory {
    return SensorColorIntensityCategoryByName.get(name)!;
}


export const SensorColorIntensityCategoryList : Readonly<SensorColorIntensityCategory[]> = [
	SensorColorIntensityCategory.Category1,
	SensorColorIntensityCategory.Category2,
	SensorColorIntensityCategory.Category3,
	SensorColorIntensityCategory.Category4,
	SensorColorIntensityCategory.Category5,
	SensorColorIntensityCategory.Category6,
];



const SensorColorIntensityCategoryToDescription = new Map<number, string>([
	[ 1, 'Category1' ],
	[ 2, 'Category2' ],
	[ 3, 'Category3' ],
	[ 4, 'Category4' ],
	[ 5, 'Category5' ],
	[ 6, 'Category6' ],
])

export function getSensorColorIntensityCategoryToDescription(element: SensorColorIntensityCategory) : string {
    return SensorColorIntensityCategoryToDescription.get(element) || '';
}

