import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { AlarmActionUsersPreset } from "../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUsersPreset";
import { t } from "../../../../i18n";

interface IComponentProps {
    preset: AlarmActionUsersPreset;
    onRemove: (presetId: string) => Promise<void>;
    onClose: () => void;
}

export const RemovePresetDialog = ({
    onClose,
    onRemove,
    preset
}: IComponentProps) => {
    const onRemoveHandler = async () => {
        await onRemove(preset.Id);
    }

    return (
        <ModalTransition>
            <Modal
                heading={`${t("Confirm removing preset")} ${preset.Name}`}
                actions={[
                    { text: t("Remove"), onClick: onRemoveHandler, appearance: 'danger' },
                    { text: t("Close"), onClick: onClose, appearance: 'default' },
                ]}
            >
                <div>
                    {`${t("Do you confirm removing users preset")} ${preset.Name}?`}
                </div>
            </Modal>
        </ModalTransition>
    )
}