import Button from '@atlaskit/button';
import { ModalFooter } from '@atlaskit/modal-dialog';
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { IWithGeovisServerProps, withGeovisServer } from '../../../helpers/GeovisHooks';
import { navigateToCreateChartDialog, navigateToCreateDefaultChartDialog } from '../../../helpers/NavigationHelper';
import { fetchServerElements, sendServerPostRequestData } from '../../../helpers/ProjectDataHelper';
import Route from '../../../helpers/Route';
import Routes from '../../../helpers/Routes';
import { t } from '../../../i18n';
import { ActionResponse } from '../../../server/ActionResponse';
import { AuthorizationCode } from '../../../server/AuthorizationCode';
import { ChartType, getChartTypeToDescription } from '../../../server/AVTService/TypeLibrary/Common/ChartType';
import { KindOfElementUsing } from '../../../server/AVTService/TypeLibrary/Common/KindOfElementUsing';
import { ProjectElementType } from '../../../server/AVTService/TypeLibrary/Common/ProjectElementType';
import { AlarmInfoRecord } from '../../../server/AVTService/TypeLibrary/Model/AlarmInfoRecord';
import { DtsChartModel } from '../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel';
import { GeovisChartAxisSettings } from '../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings';
import { GeovisChartModel } from '../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel';
import { DataActionResponse } from '../../../server/DataActionResponse';
import { GeovisMstModel } from '../../../server/GEOvis3/Model/Database/GeovisMstModel';
import { DtsChartSensorsInfo } from '../../../server/GEOvis3/Model/GeovisChart/DtsChartSensorsInfo';
import { GeovisChartInfo } from '../../../server/GEOvis3/Model/GeovisChart/GeovisChartInfo';
import { SensorChangesModel } from '../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel';
import { GeovisProjectElementEntryModel } from '../../../server/GEOvis3/Model/ProjectElements/GeovisProjectElementEntryModel';
import ServerRoutesGen from '../../../server/Routes/ServerRoutesGen';
import AuthService from '../../../services/AuthService';
import FlagService from '../../../services/FlagService';
import { processFetchedData } from '../../../store/helpers/DataHelper';
import { LoadingPageSkeleton } from '../../LoadingPageSkeleton';
import { ElementsTab } from '../../navigation/ContentNavigation';
import { ChartTabControl } from './ChartTabControl';
import { SelectTemplateDialog } from './SelectTemplateDialog';
import { getChartTypeNormalized, getDtsChartSensorIdsOfType, isChartTemplate } from './tools';
import { chartInfoStorageInitialState, dtsChartSensorsInfoStorageInitialState, IChartInfoStorage, IDtsChartSensorsInfoStorage } from './types';
import { ProjectElementEditHeader } from '../ProjectElementEditHeader';
import { GeovisModalDialog } from '../../editDialogs/GeovisModalDialog';

interface IComponentProps extends IWithGeovisServerProps {
    projectId: number;
    chartId: number;
    chartKind: KindOfElementUsing;

    /**
     * This parameter is required only when create new chart
     * If you need to edit the chart, then use only: projectId and chartId properties
     */
    // eslint-disable-next-line camelcase
    createMode_chartType?: ChartType;

    isTemplate?: boolean;
    baseElementId?: number;
    isForCompany?: boolean;

    onClose: () => void;
    onUpdateChartEntry: (chart: GeovisProjectElementEntryModel) => void;
}


const ChartEditDialog = ({
    projectId,
    createMode_chartType,
    chartId,
    Server,
    onClose,
    onUpdateChartEntry,
    chartKind,
    isTemplate,
    baseElementId,
    isForCompany
}: IComponentProps) => {

    const [chartInfoStorage, setChartInfoStorage] = useState<IChartInfoStorage>(chartInfoStorageInitialState);
    const [dtsChartSensorsInfoStorage, setDtsChartSensorsInfoStorage] = useState<IDtsChartSensorsInfoStorage>(dtsChartSensorsInfoStorageInitialState);

    const [templatesDialogState, setTemplatesDialogState] = useState<boolean>(false);

    const isEditMode = chartId > 0;

    const history = useHistory();

    useEffect(() => {

        setChartInfoStorage(chartInfoStorageInitialState);

        (async function loadData() {

            let url: Route;
            if (!isTemplate) {
                url = isEditMode
                    ? ServerRoutesGen.GeovisChart.GetChartInfo.patch(projectId, chartId)
                    : baseElementId
                        ? ServerRoutesGen.GeovisChart.GetChartInitialModelOfTemplate.patch(projectId, baseElementId)
                        : ServerRoutesGen.GeovisChart.GetChartInitialModel.patch(projectId, createMode_chartType, chartKind);
            }
            else {
                url = isEditMode
                    ? ServerRoutesGen.GeovisTemplates.GetChartTemplateInfo.patch(projectId, chartId)
                    : ServerRoutesGen.GeovisTemplates.GetChartTemplateInitialModel.patch(projectId, createMode_chartType, chartKind);
            }


            const response = await fetchServerElements<GeovisChartInfo>(Server, url);

            if (!response.Success) {
                if (response.AuthorizationCode === AuthorizationCode.ElementNotFound) {
                    FlagService.addError(t("Chart not found"), t("Chart not found"));
                    onClose();
                }
            }

            let state = processFetchedData(response, chartInfoStorage, chartInfoStorageInitialState, st => ({
                chartInfo: { ...st, Chart: { ...st.Chart, KindOfElementUsing: chartKind } },
                sensorsChanges: [],
                aliases: [],
                templatesCount: 0
            }))

            if (isTemplate && !isEditMode && isForCompany) {
                state.chartInfo.Chart.TemplateCompanyId = AuthService.currentUserCompanyId();
            }

            if (!isTemplate) {
                const aliasesUrl = ServerRoutesGen.GeovisChart.GetAliases.patch(projectId);

                const aliasesResponse = await fetchServerElements<GeovisMstModel[]>(Server, aliasesUrl);

                state = processFetchedData(aliasesResponse, chartInfoStorage, chartInfoStorageInitialState, aliases => ({
                    chartInfo: state.chartInfo,
                    sensorsChanges: [],
                    aliases,
                    templatesCount: 0
                }))
            }

            const templatesCountResponse = await fetchServerElements<number>(
                Server,
                ServerRoutesGen.ProjectElement.GetTemplatesCount.patch(projectId,
                    // eslint-disable-next-line camelcase
                    chartKind === KindOfElementUsing.Default ? ProjectElementType.DefaultChart : ProjectElementType.Chart, createMode_chartType ?? 0));

            if (templatesCountResponse.Success) {
                state.templatesCount = templatesCountResponse.Data;
            }
            setChartInfoStorage(state);
        })();

        // eslint-disable-next-line camelcase
    }, [chartId, projectId, createMode_chartType, baseElementId]);

    /**
     * Save chart handler
     * @returns 
     */
    const onSaveHandler = async () => {
        const { chartInfo } = chartInfoStorage;

        if (!validateAlarmInfoRecords(chartInfo.Chart.AlarmLines)) {
            FlagService.addError("Chart config is not valid", "Alarm lines have bad configuration");
            return;
        }

        const url = isTemplate
            ? ServerRoutesGen.GeovisTemplates.SaveGeovisChartTemplate.patch(projectId)
            : ServerRoutesGen.GeovisChart.SaveGeovisChart.patch(projectId);
        const response = await sendServerPostRequestData<GeovisChartModel, DataActionResponse<GeovisProjectElementEntryModel>>(Server, url, chartInfo.Chart);

        if (!response.Success) {
            FlagService.addErrors("Failed to save the chart", response.Messages);
            return;
        }

        const saveChangesSuccess = await onSaveChangedSensorsHandler();

        if (saveChangesSuccess) {
            onUpdateChartEntry(response.Data);
        }
    }

    const onConvertToTemplateClick = (forCompany: boolean) => async () => {
        const { chartInfo } = chartInfoStorage;

        const url = ServerRoutesGen.GeovisTemplates.GeovisChartTemplateFromGeovisChart.patch(projectId);

        const payload: GeovisChartModel = { ...chartInfo.Chart, Id: 0, TemplateCompanyId: forCompany ? AuthService.currentUserCompanyId() : "" };

        const response = await sendServerPostRequestData<GeovisChartModel, DataActionResponse<GeovisProjectElementEntryModel>>(Server, url, payload);

        if (!response.Success) {
            FlagService.addErrors("Failed to convert chart to template", response.Messages);
            return;
        }

        onUpdateChartEntry(response.Data);
    }

    const onConvertToChartClick = async () => {
        const { chartInfo } = chartInfoStorage;

        // Save current state of template first
        const url = ServerRoutesGen.GeovisTemplates.SaveGeovisChartTemplate.patch(projectId);

        const response = await sendServerPostRequestData<GeovisChartModel, DataActionResponse<GeovisProjectElementEntryModel>>(Server, url, chartInfo.Chart);

        if (!response.Success) {
            FlagService.addErrors("Failed to convert template to chart", response.Messages);
            return;
        }

        if (chartInfo.Chart.KindOfElementUsing === KindOfElementUsing.DefaultTemplate) {
            navigateToCreateDefaultChartDialog(history, projectId, chartInfo.Chart.Type, response.Data.Id);
            return;
        }

        navigateToCreateChartDialog(history, projectId, chartInfo.Chart.Type, response.Data.Id)
    }

    const showTemplatesDialog = () => {
        setTemplatesDialogState(true);
    }

    const hideTemplatesDialog = () => {
        setTemplatesDialogState(false);
    }

    const implementTemplate = (templateId: number) => {
        const { chartInfo } = chartInfoStorage;
        setTemplatesDialogState(false);

        if (chartInfo.Chart.KindOfElementUsing === KindOfElementUsing.Default) {
            navigateToCreateDefaultChartDialog(history, projectId, chartInfo.Chart.Type, templateId);
            return;
        }

        navigateToCreateChartDialog(history, projectId, chartInfo.Chart.Type, templateId);
    }

    const validateAlarmInfoRecords = (alarmInfos: AlarmInfoRecord[]): boolean => {
        let isGood: boolean = true;

        alarmInfos.forEach(alarmInfo => {
            if ((alarmInfo.xvalueFrom && Object.is(+(alarmInfo.xvalueFrom.toString().replace(",", ".")), NaN))
                || (alarmInfo.yvalueFrom && Object.is(+(alarmInfo.yvalueFrom.toString().replace(",", ".")), NaN))
                || (alarmInfo.xvalueTo && Object.is(+(alarmInfo.xvalueTo.toString().replace(",", ".")), NaN))
                || (alarmInfo.yvalueTo && Object.is(+(alarmInfo.yvalueTo.toString().replace(",", ".")), NaN))) {
                isGood = false;
            }
        });

        return isGood;
    }

    /**
     * Save changes of sensors
     * @returns Empty string in case of successful saving. Otherwise - error message
     */
    const onSaveChangedSensorsHandler = async (): Promise<boolean> => {

        const { sensorsChanges } = chartInfoStorage;

        if (sensorsChanges.length === 0) {
            return true; // nothing to save, all fine
        }

        const url = ServerRoutesGen.GeovisChart.SaveUpdatedSensors.patch(projectId);
        const response = await sendServerPostRequestData<SensorChangesModel[], ActionResponse>(Server, url, sensorsChanges);

        if (!response.Success) {
            FlagService.addErrors(t("Save sensors changes error"), response.Messages);
            return false;
        }

        return true;
    }

    const getChartTypeToName = (chartType: ChartType, isDefault: boolean): string => {
        return isDefault
            ? "Popup chart"
            : getChartTypeToDescription(chartType);
    }

    const getChartDialogHeaderText = (typeOfChart: ChartType, editMode: boolean, isDefault: boolean): string => {
        const prefix = editMode ? "Edit " : "Create ";
        const postfix = isTemplate ? " template" : "";
        let chartTitle = "";
        if (chartInfoStorage.isLoaded) {
            chartTitle = chartInfoStorage.chartInfo.Chart.Title ? chartInfoStorage.chartInfo.Chart.Title : chartInfoStorage.chartInfo.Chart.Name;
        }

        const normChartType = getChartTypeNormalized(typeOfChart);

        if (!editMode) {
            return t(`${prefix} ${getChartTypeToName(normChartType, isDefault)}${postfix}`);
        }

        return chartTitle ? t(`${prefix} ${getChartTypeToName(normChartType, isDefault)}${postfix} '%1'`).replace('%1', chartTitle) : t(`${prefix} ${getChartTypeToName(normChartType, isDefault)}${postfix}`);
    }

    const getChartDialogHeader = (typeOfChart: ChartType | undefined, editMode: boolean, isDefault: boolean): React.ReactNode => {

        const chartToWorkWith = chartInfoStorage.isLoaded ? chartInfoStorage.chartInfo.Chart : undefined;

        const chartType = typeOfChart ?? chartToWorkWith?.Type

        if (chartType === undefined) {
            return "New chart";
        }

        if (!editMode) {
            return getChartDialogHeaderText(chartType, false, isDefault);
        }

        return (
            <ProjectElementEditHeader
                headerText={getChartDialogHeaderText(chartType, true, isDefault)}
                onCopyToClipboard={copyToClipboard}
                tooltipContent={t("Copy the link to this chart")}
            />
        )
    }

    const copyToClipboard = () => {
        const { chartInfo } = chartInfoStorage;
        let chartElementType = ProjectElementType.Chart;
        let tabHash: ElementsTab = 'All';

        if (isChartTemplate(chartInfo.Chart)) {
            chartElementType = chartInfo.Chart.ProjectId > 0 ? ProjectElementType.ProjectChartTemplate : ProjectElementType.CompanyChartTemplate;
            tabHash = chartInfo.Chart.ProjectId > 0 ? "ProjectTemplates" : "CompanyTemplates";
        }
        else if (chartInfo.Chart.KindOfElementUsing === KindOfElementUsing.Default) {
            chartElementType = ProjectElementType.DefaultChart;
            tabHash = 'Default';
        }

        const url = Routes.projectEditElementEditDialog.patch(projectId, tabHash, chartElementType, chartId);
        const copyText = `${window.location.origin}${url.path}`;
        navigator.clipboard.writeText(copyText);
    }

    const onChartModelChanged = (chart: GeovisChartModel, changes?: SensorChangesModel[]) => {
        setChartInfoStorage({ ...chartInfoStorage, chartInfo: { ...chartInfoStorage.chartInfo, Chart: chart }, sensorsChanges: changes ?? chartInfoStorage.sensorsChanges });
    }

    const onSensorsChangesUpdated = (changes: SensorChangesModel[]) => {
        setChartInfoStorage({ ...chartInfoStorage, sensorsChanges: changes });
    }

    const onDtsChartSectionLoading = () => {
        setDtsChartSensorsInfoStorage(dtsChartSensorsInfoStorageInitialState);
    }

    const updateDtsChartSensorIds = (dtsChartSensorsInfo: DtsChartSensorsInfo, axisSettings: GeovisChartAxisSettings): GeovisChartAxisSettings => {
        return {
            ...axisSettings,
            SensorIds: [...getDtsChartSensorIdsOfType(dtsChartSensorsInfo, axisSettings.TypeOfSensor, axisSettings.HeatpulseOption)]
        }
    }

    const onDtsChartSectionLoaded = (response: DataActionResponse<DtsChartSensorsInfo>) => {
        setDtsChartSensorsInfoStorage(processFetchedData(response, dtsChartSensorsInfoStorage, dtsChartSensorsInfoStorageInitialState, st => ({ dtsChartSensorInfos: st })));

        // add sensors of selected DTS chart to Left and Right axes of chart
        if (response.Success) {

            const existChart = chartInfoStorage.chartInfo.Chart as DtsChartModel;

            const chart: DtsChartModel = {
                ...existChart,
                LeftYAxisSettings: updateDtsChartSensorIds(response.Data, existChart.LeftYAxisSettings),
                RightYAxisSettings: updateDtsChartSensorIds(response.Data, existChart.RightYAxisSettings)
            }

            onChartModelChanged(chart);
        }
    }

    const onCloseHandler = () => {
        onClose();
    }

    const getDialogSaveButtonText = (): string => {
        let text = isEditMode ? "Save Chart" : "Create Chart";
        if (isTemplate) {
            text += " Template";
        }
        return t(text);
    }

    const getCustomFooter = () => {
        return (
            <ModalFooter>
                <div style={{ display: 'inline-flex', width: '100%' }}>
                    {isEditMode && !isTemplate &&
                        <div style={{ display: 'flex' }}>
                            <div>
                                <Button onClick={onConvertToTemplateClick(false)} appearance='default' style={{ marginRight: '5px' }}>
                                    {t("Save as project template")}
                                </Button>
                            </div>
                            <div>
                                <Button onClick={onConvertToTemplateClick(true)} appearance='default' style={{ marginRight: '5px' }}>
                                    {t("Save as company template")}
                                </Button>
                            </div>
                        </div>
                    }

                    {isEditMode && isTemplate &&
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                            <Button onClick={onConvertToChartClick} appearance='primary' style={{ marginRight: '5px' }}>
                                {t("Create chart")}
                            </Button>
                        </div>
                    }
                    {!isTemplate && !isEditMode &&
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                            <Button onClick={showTemplatesDialog} isDisabled={chartInfoStorage.templatesCount === 0}>
                                {t("Load settings from template")}
                            </Button>
                        </div>
                    }
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                        <Button onClick={onSaveHandler} appearance='primary' style={{ marginRight: '5px' }}>
                            {getDialogSaveButtonText()}
                        </Button>
                        <Button onClick={onCloseHandler} appearance='default' style={{ width: '105px' }}>
                            {t("Cancel")}
                        </Button>
                    </div>
                </div>

            </ModalFooter>
        );
    };

    const isDefaultChart = chartKind === KindOfElementUsing.Default || chartKind === KindOfElementUsing.DefaultTemplate;

    return (
        <GeovisModalDialog
            heading={getChartDialogHeader(createMode_chartType, isEditMode, isDefaultChart)}
            height="100%"
            dialogSize='slim'
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
            components={{
                Footer: getCustomFooter
            }}>
            <Fragment>
                {chartInfoStorage.isLoading && (
                    <LoadingPageSkeleton />
                )}
                {chartInfoStorage.isLoaded && (
                    <ChartTabControl
                        chartInfoStorage={chartInfoStorage}
                        dtsChartSensorsInfoStorage={dtsChartSensorsInfoStorage}
                        onChartModelChanged={onChartModelChanged}
                        onDtsChartSectionLoading={onDtsChartSectionLoading}
                        onDtsChartSectionLoaded={onDtsChartSectionLoaded}
                        onSensorsChangesUpdated={onSensorsChangesUpdated}
                        projectId={projectId}
                    />
                )}
                {templatesDialogState &&
                    <SelectTemplateDialog
                        Server={Server}
                        chartType={chartInfoStorage.chartInfo.Chart.Type}
                        onClose={hideTemplatesDialog}
                        onSelectTemplate={implementTemplate}
                        projectId={projectId}
                        projectElementType={isDefaultChart ? ProjectElementType.DefaultChart : ProjectElementType.GeovisChart}
                    />
                }
            </Fragment>
        </GeovisModalDialog>
    )
}

export default withGeovisServer(ChartEditDialog);
