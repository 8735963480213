/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 09.04.2020
 * @description GEOvis 4 service settings menu
 */

import Badge from '@atlaskit/badge';
import Dropdown, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import H from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Constants from '../helpers/Constants';
import Route from '../helpers/Route';
import Routes from '../helpers/Routes';
import { t } from '../i18n';
import AuthService from '../services/AuthService';
import { IGeovisStoreState } from '../store/store.types';
import { IFeedbackStatisticStorage } from '../store/types';
import { SettingsIcon } from './Icons';
import { GeovisCompanyType } from '../server/AVTService/TypeLibrary/Common/GeovisCompanyType';

const navigateToRouteFunc = (route: Route, history: H.History) => () => {
    history.push(route.path);
}


interface IStateToProps {
    feedbackNavigationStorage: IFeedbackStatisticStorage;
}

const SettingsMenu = ({ feedbackNavigationStorage }: IStateToProps) => {

    if (!AuthService.hasUserTypeAsCompanyAdmin()) {
        return (null);
    }

    const history = useHistory();

    const menuItems: Array<{ title: string, route: Route }> = [];
    //const servicesMenuItems: Array<{ title: string, route: Route }> = [];

    if (AuthService.hasUserTypeAsCompanyAdmin()) {
        menuItems.push({ title: "Logos Manager", route: Routes.logosManager });
        menuItems.push({ title: "Users and Companies", route: Routes.users });
    }

    if (AuthService.hasUserTypeAsAdmin()) {
        menuItems.push({ title: "Statistics", route: Routes.globalStatistics });
        menuItems.push({ title: "Feedbacks", route: Routes.applicationFeedbacks });
    }

    if ((AuthService.getCurrentUserCompanyType() === GeovisCompanyType.Owner && AuthService.hasUserTypeAsCompanyAdmin()) || AuthService.hasUserTypeAsAdmin()) {
        menuItems.push({ title: "Inventory", route: Routes.inventoryCalendarView });
    }

    if (AuthService.hasUserTypeAsAdmin()) {
        menuItems.push({ title: 'Logs', route: Routes.logsEntryPoints });
        //servicesMenuItems.push({ title: 'Global Imports', route: Routes.swissMeteoGlobalImport });
        menuItems.push({ title: 'Settings', route: Routes.settingsRoot })
    }

    if (AuthService.hasUserTypeAsCompanyAdmin()) {
        menuItems.push({ title: 'Archive and restore projects', route: Routes.archivingProjects })
    }

    const isFeedbacksRoute = (route: Route): boolean => {
        return route.path === Routes.feedbacks.path
            || route.path === Routes.usersFeedbacks.path
            || route.path === Routes.usersFeedbacksEntry.path
            || route.path === Routes.applicationFeedbacks.path
            || route.path === Routes.applicationFeedbacksEntry.path;
    }

    const getElementAfter = (route: Route): React.ReactNode | undefined => {

        if (isFeedbacksRoute(route)) {

            const { isLoading, isError, data } = feedbackNavigationStorage;
            if (isLoading) {
                return (<RefreshIcon label={''} size='small' />);
            }

            if (isError) {
                return (<ErrorIcon label={''} size='small' primaryColor={Constants.errorColor} />)
            }

            const { ApplicationRecords, UserRecords } = data;

            return (
                <React.Fragment>
                    {ApplicationRecords > 0 && (
                        <Badge appearance='important'>{ApplicationRecords}</Badge>
                    )}
                    {UserRecords > 0 && (
                        <Badge appearance='primary'>{UserRecords}</Badge>
                    )}
                </React.Fragment>
            )
        }

        return undefined;

    }

    // const ServiceDropdown = () => {
    //     return (
    //         <Dropdown
    //             position='right bottom'
    //             trigger={
    //                 <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', color: '#172B4D', marginTop: '5px' }}>
    //                     <span style={{ marginLeft: '12px' }}>{t('Services')}</span>
    //                     <ChevronRightIcon />
    //                 </div>
    //             }
    //             shouldFitContainer={true}
    //         >
    //             <DropdownItemGroup>
    //                 {servicesMenuItems.map(item =>
    //                     <DropdownItem
    //                         key={item.title}
    //                         onClick={navigateToRouteFunc(item.route, history)}
    //                         elemAfter={getElementAfter(item.route)}>
    //                         {t(item.title)}
    //                     </DropdownItem>
    //                 )}
    //             </DropdownItemGroup>
    //         </Dropdown>
    //     );
    // };

    // settings' menu is allowed for the role admin
    return (
        <Dropdown trigger={<SettingsIcon />} position="right bottom">
            <DropdownItemGroup title={t("Menu")}>
                {menuItems.map(item =>
                    <DropdownItem
                        key={item.title}
                        onClick={navigateToRouteFunc(item.route, history)}
                        elemAfter={getElementAfter(item.route)}>
                        {t(item.title)}
                    </DropdownItem>
                )}

                {/* <ServiceDropdown /> */}


            </DropdownItemGroup>

        </Dropdown>
    );
}

const mapStateToProps = ({ navigation }: IGeovisStoreState): IStateToProps => ({
    feedbackNavigationStorage: navigation.feedbackStatisticStorage
});

export default connect<IStateToProps>(mapStateToProps)(SettingsMenu);