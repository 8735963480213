/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.04.2022
 * @description Dts charts options tab
 */

import { t } from '../../../../i18n';
import { DtsChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel';
import { ChartOptionsTab } from '../ChartOptionsTab';
import { CustomerChangeableChartBooleanSetting } from '../ChartTab_tools';
import { IGeovisChartPropertyChangedProps } from '../types';

type IDtsChartOptionsTab = IGeovisChartPropertyChangedProps<DtsChartModel>

export const DtsChartOptionsTab = ({ chart, onChartPropertyChanged, onChartPropertiesChanged }: IDtsChartOptionsTab) => {

    const onChartPropertyChangedFunc = (propertyName: keyof DtsChartModel) => (value: any) => {
        onChartPropertyChanged(propertyName, value);
    }

    return (
        <div className="geovisChartTab">
            <ChartOptionsTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />

            <CustomerChangeableChartBooleanSetting
                setting={chart.ShowCloseTemperatureSensorsAsPoints}
                settingLabel={t("Show close temperature sensor's as points")}
                onChange={onChartPropertyChangedFunc("ShowCloseTemperatureSensorsAsPoints")}
                isDisabled={false}
            />

            <CustomerChangeableChartBooleanSetting
                setting={chart.ShowTemperaturePointLabels}
                settingLabel={t("Temperature point labels")}
                onChange={onChartPropertyChangedFunc("ShowTemperaturePointLabels")}
                isDisabled={false}
            />
        </div>
    )

}