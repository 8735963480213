//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartData } from './GeovisChartData';
import { HtmlReportChainLineInfo } from '../Report/Model/HtmlReportChainLineInfo';
import { HtmlReportSensorDescriptionViewModel } from '../Report/Model/HtmlReportSensorDescriptionViewModel';
import { ChainModel } from '../Model/ChainModel';

export class InclinometerChartData extends GeovisChartData
{
	public LineInfo: HtmlReportChainLineInfo[];
	public Descriptions: HtmlReportSensorDescriptionViewModel[];
	public ReferencesTime: string;
	public ChainConfig: ChainModel;
	public MinDepth: number;
	public MaxDepth: number;
	public MinDeltaFirst: number;
	public MinDeltaSecond: number;
	public MaxDeltaFirst: number;
	public MaxDeltaSecond: number;
}
