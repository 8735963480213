/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 16.01.2020
 * @description Common types for helpers
 */

import { AlarmInfo } from "../server/AlarmInfo";
import { ProjectReportInfo } from "../server/ProjectReportInfo";

export interface ITreeElementObj {
    Name: string;
    CausedAlarms: AlarmInfo[];
}

export const getReportName = (report: ProjectReportInfo): string => {
    return report.Title || report.Name;
}