//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisFeedbackType } from './GeovisFeedbackType';
import { FeedbackState } from '../../Common/FeedbackState';

export abstract class GeovisFeedbackModel
{
	public Id: string;
	public FeedbackType: GeovisFeedbackType;
	public Name: string;
	public UserId: string;
	public ProjectId: number;
	public State: FeedbackState;
	public TicketNumber: string;
	public Comment: string;
	public Created: string;
	public Updated: string;
}
