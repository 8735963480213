import debug from "debug";
import { IS_PRODUCTION } from "../ApiConfig";

const BASE = "GEOvis4";
const COLORS = {
    error: "red",
    warn: "orange",
    info: "blue",
    trace: "gray",
    nav: 'green',
    data: 'Fuchsia',
    render: '#3498DB'
};

class Logger {
    public trace = (object: any, source?: string) => {
        return this.log("trace", object, source);
    }

    public info = (message: string, source?: string) => {
        return this.log("info", message, source);
    }

    public warning = (message: string, source?: string) => {
        return this.log("warn", message, source);
    }

    public error = (message: string, source?: string) => {
        return this.log("error", message, source);
    }

    public nav = (message: string) => {
        if (IS_PRODUCTION) {
            return;
        }
        this.log("nav", message);
    }

    public data = (message: string, source?: string) => {
        if (IS_PRODUCTION) {
            return;
        }
        this.log("data", message, source);
    }

    public render = (message: string, source?: string) => {
        if (IS_PRODUCTION) {
            return;
        }

        this.log("render", message, source);
    }

    public logComponentDidMount = (component: string) => {
        this.info("componentDidMount", component)
    }

    // public logRender = (component: string) => {
    //     this.info("render", component)
    // }

    //
    private log = (level: string, message: any, source?: string) => {
        const createDebug: any = debug(`${BASE}:${level}`);
        createDebug.color = COLORS[level];

        if (source) {
            createDebug(source, message);
        } else {
            createDebug(message);
        }
    };
}

export default new Logger();