// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { Item } from '@atlaskit/navigation-next';
import H from 'history';
import i18next from '../../i18n';
import { BackIcon } from '../Icons';

interface IBackButtonItemProps {
    history: H.History,
    prevRouteIndex: number
}

export const BackButtonItem = ({ history, prevRouteIndex }: IBackButtonItemProps) => {

    const before = () => <BackIcon label={i18next.t("Back")} />
    const onClick = () => history.go(prevRouteIndex - history.length)

    return (
        <Item
            key="back-button-key"
            id="back-button-id"
            before={before}
            text={i18next.t("Back")}
            onClick={onClick}
        />
    );
}