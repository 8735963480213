/**
 * @author Vyacheslav Skripin <vs@ieks.ru>
 * @created 04.03.2021
 * @description The interceptor to the function "fetch" and add a header "access_token" to pass the Authorization
 */

import AuthService from "../services/AuthService";

const originalFetch = window.fetch;

window.fetch = (input: RequestInfo, init?: RequestInit): Promise<Response> => originalFetch(input, {
    ...init,
    headers: {
        ...(init && init.headers),
        access_token: "Bearer ".concat(AuthService.getToken() || "")
    }
});