//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum HeatmapSensorsSource {
	Sensors = 0,
	DTS_Loops = 1,
	DTS_Sections = 2
}

const HeatmapSensorsSourceToName = new Map<number, string>([
	[ 0, 'Sensors' ],
	[ 1, 'DTS_Loops' ],
	[ 2, 'DTS_Sections' ],
])

export function getHeatmapSensorsSourceToName(element: HeatmapSensorsSource) : string {
    return HeatmapSensorsSourceToName.get(element) || '';
}

const HeatmapSensorsSourceByName = new Map<string, number>([
	[ 'Sensors', 0 ],
	[ 'DTS_Loops', 1 ],
	[ 'DTS_Sections', 2 ],
]);

export function getHeatmapSensorsSourceByName(name: string): HeatmapSensorsSource {
    return HeatmapSensorsSourceByName.get(name)!;
}


export const HeatmapSensorsSourceList : Readonly<HeatmapSensorsSource[]> = [
	HeatmapSensorsSource.Sensors,
	HeatmapSensorsSource.DTS_Loops,
	HeatmapSensorsSource.DTS_Sections,
];



const HeatmapSensorsSourceToDescription = new Map<number, string>([
	[ 0, 'Sensors' ],
	[ 1, 'DTS_Loops' ],
	[ 2, 'DTS_Sections' ],
])

export function getHeatmapSensorsSourceToDescription(element: HeatmapSensorsSource) : string {
    return HeatmapSensorsSourceToDescription.get(element) || '';
}

