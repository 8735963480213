import {
    Editor,
    EditorContext,
} from '@atlaskit/editor-core';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { dateTimeToReactMoment } from '../../../helpers/DateHelper';
import { getUserFullName } from '../../../helpers/UsersHelper';
import { t } from '../../../i18n';
import { EmailMessageHistoryEntry } from '../../../server/AVTService/TypeLibrary/Email/EmailMessageHistoryEntry';
import { GeovisUserCommonInfo } from '../../../server/GEOvis3/Model/User/GeovisUserCommonInfo';
import { IBusinessUsersStorage } from '../../../store/businessData.types';
import { geovisBusinessMessageHistoryHideItem } from '../../../store/creators/businessDataCreators';
import { IGeovisStoreState } from '../../../store/store.types';
import { IGeovisAction } from '../../../store/types';

interface IMessageDisplayDialogStateProps {
    historyEntry: EmailMessageHistoryEntry;
    showDialog: boolean;
    usersStore: IBusinessUsersStorage;
}

interface IMessageDisplayDialogDispatchProps {
    onClose: () => void;
}

interface IMessageDisplayDialogProps extends IMessageDisplayDialogStateProps, IMessageDisplayDialogDispatchProps {

}

interface IReceiversControlProps {
    receivers: string[];
    userStore: IBusinessUsersStorage;
}

const ReceiversControl = ({ receivers, userStore }: IReceiversControlProps) => {

    const users: GeovisUserCommonInfo[] = [];
    receivers.forEach(r => {
        const user = userStore.users.get(r);
        if (user) {
            users.push(user);
        }
    });

    return (
        <div>
            {users.map<JSX.Element | null>(u => {
                return (
                    <div key={u.Id} style={{ display: 'flex' }}>
                        <div>
                            {getUserFullName(u)}
                        </div>
                        <div>
                            {"<" + u.Email + ">"}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const MessageDisplayDialog = ({
    historyEntry,
    showDialog,
    usersStore,
    onClose
}: IMessageDisplayDialogProps) => {

    if (!historyEntry || !showDialog) {
        return null;
    }

    return (
        <ModalTransition>
            <Modal
                width='80%'
                heading={`Subject: ${historyEntry.Subject}`}
                actions={[
                    { text: t("Close"), onClick: onClose }
                ]}>
                <div>
                    <div className="flexRowContainer">
                        <div>
                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>{"Send date: "}</span>
                        </div>
                        <div>
                            {dateTimeToReactMoment(historyEntry.SendTime)}
                        </div>
                    </div>
                    <div className="flexRowContainer" style={{ marginBottom: '15px' }}>
                        <div>
                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>{"Receivers: "}</span>
                        </div>
                        <div>
                            <ReceiversControl receivers={historyEntry.Recievers} userStore={usersStore} />
                        </div>
                    </div>
                    <div className="flexRowContainer">
                        <span style={{ fontWeight: 'bold', marginRight: '5px', marginBottom: '5px' }}>{"Content: "}</span>
                    </div>
                    <div >
                        <EditorContext>
                            <div>
                                <Editor
                                    css={{ maxHeight: '500px' }}
                                    disabled={true}
                                    appearance='chromeless'
                                    primaryToolbarComponents={undefined}
                                    media={{
                                        allowMediaSingle: true,
                                        waitForMediaUpload: true,
                                        allowLazyLoading: false
                                    }}
                                    placeholder="Empty message body"
                                    shouldFocus={false}
                                    defaultValue={historyEntry.Content}
                                />
                            </div>
                        </EditorContext>
                    </div>

                </div>
            </Modal>
        </ModalTransition>
    )
}

const mapStateToProps = ({ businessData }: IGeovisStoreState): IMessageDisplayDialogStateProps => ({
    historyEntry: businessData.messagesStore.historyEntry,
    showDialog: businessData.messagesStore.showHistoryEntryDialog,
    usersStore: businessData.usersStore
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IMessageDisplayDialogDispatchProps => ({
    onClose: () => dispatch(geovisBusinessMessageHistoryHideItem())
});

export default connect<IMessageDisplayDialogStateProps, IMessageDisplayDialogDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(MessageDisplayDialog)