//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Country } from '../../../AVTService/TypeLibrary/Sms/Country';

export class GeovisUserCommonInfo
{
	public Id: string;
	public Name: string;
	public Forename: string;
	public Email: string;
	public CompanyId: string;
	public PhoneNumber: string;
	public CountryCode: Country;
}
