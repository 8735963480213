/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.07.2023
 * @description Geovis report page single project element render
 */

import { Fragment } from "react";
import { IReportElementRenderHostOwnProp } from "./types";
import GeovisReportPageRenderProjectElementsGroup from "./GeovisReportPageRender.ProjectElementsGroup";
import { ReportElementType } from "../../../../server/AVTService/TypeLibrary/Common/ReportElementType";
import GeovisMapSectionRender from "./mapSection/view/GeovisMapSectionReportRender";
import { CommentRender } from "./CommentRender";
import { ChartElementRender } from "./ChartElementRender";
import GeovisImageReportRender from "./geovisImage/view/GeovisImageReportRender";
import { GeovisReportElementInfo } from "../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo";


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IComponentProps extends Omit<IReportElementRenderHostOwnProp, 'isDefault' | 'isVibrationEventChart' | 'eventInfo'> {
    rootContainerWidth: number;
    groupedElements?: GeovisReportElementInfo[];
    allContainerWidth: number;
}

export const GeovisReportPageRenderProjectElement = ({ allContainerWidth, groupedElements, rootContainerWidth, elementInfo, ...restProps }: IComponentProps) => {

    const { elementIndexOnPage, } = restProps;

    return (
        <Fragment>
            {elementInfo.ElementType === ReportElementType.ElementsGroup && (
                <GeovisReportPageRenderProjectElementsGroup
                    key={`group-at-pos-${elementIndexOnPage}`}
                    elementInfo={elementInfo}
                    rootContainerWidth={rootContainerWidth}
                    {...restProps}
                />
            )}

            {/*
            // IMPORTANT: this call is used for single MapSection, which not in the group with any other element or MapSection
            // in this case property elementsInGroup has been removed and not used anymore there
            // for this feature look for component GeovisMapSectionGroupRender, there is implemented that feature
            */}

            {elementInfo.ElementType === ReportElementType.MapSection && (
                <GeovisMapSectionRender
                    key={`map-section-render-${elementInfo.Id}-${elementIndexOnPage}`}
                    elementInfo={elementInfo}
                    rootContainerWidth={rootContainerWidth}
                    groupedElements={groupedElements}
                    allContainerWidth={allContainerWidth}
                    {...restProps}
                // following properties must be initialized in the group render
                // rootContainerRef={ }
                // rootContainerSize={ }
                // windowWidth={ }
                />
            )}

            {elementInfo.ElementType === ReportElementType.GeovisImage && (
                <GeovisImageReportRender
                    key={`geovis-image-render-${elementInfo.Id}-${elementIndexOnPage}`}
                    elementInfo={elementInfo}
                    {...restProps}
                />
            )}

            {elementInfo.ElementType === ReportElementType.GeovisChart && (
                <ChartElementRender
                    // key={`geovis-chart-${pageNum}-${element.Id}-${elementIndex}-${rootContainerSize.width}-${rootContainerSize.height}-${timestamp}`}
                    // {...restOwnProps}
                    key={`geovis-chart-render-${elementInfo.Id}-${elementIndexOnPage}`}
                    elementInfo={elementInfo}
                    rootContainerWidth={rootContainerWidth}
                    {...restProps}
                />
            )}

            {(elementInfo.ElementType === ReportElementType.Comment || elementInfo.ElementType === ReportElementType.GeovisComment) && (
                <CommentRender
                    key={`geovis-comment-${elementInfo.Id}-${elementIndexOnPage}`}
                    elementInfo={elementInfo}
                    {...restProps}
                />
            )}
        </Fragment>
    )
}