//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum InventoryObjectType {
	MST = 0,
	SB = 1,
	Tachymeter = 2,
	Atc = 3,
	Afm = 4,
	LoRaGateway = 5
}

const InventoryObjectTypeToName = new Map<number, string>([
	[ 0, 'MST' ],
	[ 1, 'SB' ],
	[ 2, 'Tachymeter' ],
	[ 3, 'Atc' ],
	[ 4, 'Afm' ],
	[ 5, 'LoRaGateway' ],
])

export function getInventoryObjectTypeToName(element: InventoryObjectType) : string {
    return InventoryObjectTypeToName.get(element) || '';
}

const InventoryObjectTypeByName = new Map<string, number>([
	[ 'MST', 0 ],
	[ 'SB', 1 ],
	[ 'Tachymeter', 2 ],
	[ 'Atc', 3 ],
	[ 'Afm', 4 ],
	[ 'LoRaGateway', 5 ],
]);

export function getInventoryObjectTypeByName(name: string): InventoryObjectType {
    return InventoryObjectTypeByName.get(name)!;
}


export const InventoryObjectTypeList : Readonly<InventoryObjectType[]> = [
	InventoryObjectType.MST,
	InventoryObjectType.SB,
	InventoryObjectType.Tachymeter,
	InventoryObjectType.Atc,
	InventoryObjectType.Afm,
	InventoryObjectType.LoRaGateway,
];



const InventoryObjectTypeToDescription = new Map<number, string>([
	[ 0, 'MST' ],
	[ 1, 'SB' ],
	[ 2, 'Tachymeter' ],
	[ 3, 'Atc' ],
	[ 4, 'Afm' ],
	[ 5, 'LoRaGateway' ],
])

export function getInventoryObjectTypeToDescription(element: InventoryObjectType) : string {
    return InventoryObjectTypeToDescription.get(element) || '';
}

