/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 23.12.2020
 * @description Global statistics actions
 */

export const STATISTICS_PROJECTS_HISTORY = 'STATISTICS_PROJECTS_HISTORY';
export const STATISTICS_PROJECTS_HISTORY_DATA = 'STATISTICS_PROJECTS_HISTORY_DATA';

export const STATISTICS_PROJECTS_HISTORY_COMPANIES_DATA = 'STATISTICS_PROJECTS_HISTORY_COMPANIES_DATA';

export const STATISTICS_PROJECT_HISTORY_SEARCH = 'STATISTICS_PROJECT_HISTORY_SEARCH';
export const STATISTICS_PROJECT_HISTORY_COMPANY_ID = 'STATISTICS_PROJECT_HISTORY_COMPANY_ID';
export const STATISTICS_PROJECT_HISTORY_PROJECT_STATES = 'STATISTICS_PROJECT_HISTORY_PROJECT_STATES';
export const STATISTICS_PROJECT_HISTORY_GEOVIS_4_PACKAGE = 'STATISTICS_PROJECT_HISTORY_GEOVIS_4_PACKAGE';

export const STATISTICS_PROJECT_HISTORY_CHANGE_SORT = "STATISTICS_PROJECT_HISTORY_CHANGE_SORT";

export const STATISTCS_COMPANY_INVOICE_LOADING = "STATISTCS_COMPANY_INVOICE_LOADING";
export const STATISTCS_COMPANY_INVOICE_LOADED = "STATISTCS_COMPANY_INVOICE_LOADED";
export const STATISTCS_COMPANY_INVOICE_LOADING_ERROR = "STATISTCS_COMPANY_INVOICE_LOADING_ERROR";
export const STATISTCS_COMPANY_INVOICE_CHANGED_COMPANY = "STATISTCS_COMPANY_INVOICE_CHANGED_COMPANY";
export const STATISTCS_COMPANY_INVOICE_SHOW_PROJECTS = "STATISTCS_COMPANY_INVOICE_SHOW_PROJECTS";
export const STATISTCS_COMPANY_INVOICE_HIDE_PROJECTS = "STATISTCS_COMPANY_INVOICE_HIDE_PROJECTS";

export const STATISTICS_COMPANY_INVOICE_SHOW_EXPORT_DIALOG = "STATISTICS_COMPANY_INVOICE_SHOW_EXPORT_DIALOG";
export const STATISTICS_COMPANY_INVOICE_HIDE_EXPORT_DIALOG = "STATISTICS_COMPANY_INVOICE_HIDE_EXPORT_DIALOG";


