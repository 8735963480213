/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 30.06.2020
 * @description Project overview report action creators
 */

import {
    IInvalidPropertyInfo,
    IReportChartCallbackInfo,
    IReportSensorsSelectionInfoLegacy
} from "../../components/projectOverview/reportOverlay/types";
import { Geovis4TableData } from "../../server/AVTService/TypeLibrary/Computation/Geovis4TableData";
import { GeovisChartData } from "../../server/AVTService/TypeLibrary/Computation/GeovisChartData";
import { CustomerReportSettingsModel } from "../../server/AVTService/TypeLibrary/Model/CustomerReportSettingsModel";
import { GeovisChartModel } from "../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { ChartSensorsDetails } from "../../server/AVTService/TypeLibrary/Report/GEOvis4/Model/ChartSensorsDetails";
import { ReportSettings } from "../../server/AVTService/TypeLibrary/Report/ReportSettings";
import { ChartInvalidFieldDescription } from "../../server/AVTService/TypeLibrary/Validation/ChartInvalidFieldDescription";
import { DataActionResponse } from "../../server/DataActionResponse";
import { DtsSectionInfo } from "../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfo";
import { GeovisLogbookDataModel } from "../../server/GEOvis3/Model/GeovisLogbooks/GeovisLogbookDataModel";
import { GeovisDefaultReportSettings } from "../../server/GEOvis3/Model/Reports/GeovisDefaultReportSettings";
import { GeovisReportSettings } from "../../server/GEOvis3/Model/Reports/GeovisReportSettings";
import { GeovisTimeSearchReportData } from "../../server/GEOvis3/Model/TimeSearchDistance/GeovisTimeSearchReportData";
import {
    PROJECT_DEFAULT_REPORT_GEOVIS_LOADED,
    PROJECT_GEOVIS_REPORT_CHART_DATA_LOADED,
    PROJECT_GEOVIS_REPORT_CHART_DATA_LOADING,
    PROJECT_GEOVIS_REPORT_CHART_REDRAW,
    PROJECT_GEOVIS_REPORT_DEFAULT_SET_REPORT_SETTINGS,
    PROJECT_GEOVIS_REPORT_RESET_REPORT_SETTINGS,
    PROJECT_GEOVIS_REPORT_SERVICE_DATA_CHANGED,
    PROJECT_GEOVIS_REPORT_SET_CHART_DIRTY_SETTINGS,
    PROJECT_GEOVIS_REPORT_SET_REPORT_SETTINGS,
    PROJECT_GEOVIS_REPORT_TABLE_LOADED,
    PROJECT_GEOVIS_REPORT_TABLE_LOADING,
    PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_SHOW,
    PROJECT_REPORT_APPLY_CUSTOM_SETTINGS,
    PROJECT_REPORT_APPLY_CUSTOMER_PROPERTIES,
    PROJECT_REPORT_CHART_UPDATE_TIMES,
    PROJECT_REPORT_CLEAN_INVALID_PROPERTIES,
    PROJECT_REPORT_CLEAR_CUSTOMER_PROPERTIES,
    PROJECT_REPORT_CUSTOMER_SETTINGS_DIRTY,
    PROJECT_REPORT_DEFAULT_SET_VALIDATION_RESULT,
    PROJECT_REPORT_GEOVIS_LOADED,
    PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTIES,
    PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTY,
    PROJECT_REPORT_LOADED,
    PROJECT_REPORT_LOADING,
    PROJECT_REPORT_PDF_LOADED,
    PROJECT_REPORT_PDF_START_LOADING,
    PROJECT_REPORT_PNG_LOADED,
    PROJECT_REPORT_PNG_START_LOADING,
    PROJECT_REPORT_SENSOR_DIALOG,
    PROJECT_GEOVIS_REPORT_RELOAD_DATA_WITHOUT_BLINK,
    PROJECT_REPORT_MAP_SECTION_DATA_LOADING,
    PROJECT_REPORT_MAP_SECTION_DATA_LOADED,
    PROJECT_REPORT_UPDATE_LEGACY_CHART_SENSORS_SELECTION_INFO,
    PROJECT_REPORT_UPDATE_GEOVIS_ELEMENTS_SENSORS_SELECTION_INFO,
    PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADING,
    PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADED,
    PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_HIDE,
    PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADING,
    PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADED
} from "../actions/projectReportActions";
import {
    IGeovisProjectReportAction,
    IGeovisProjectReportChangedElementIds,
    IGeovisProjectReportChangedElements,
    IGeovisReportPageConfig,
    IGeovisReportSettings,
    IReportChartSensorsSelectionInfo
} from "../projectReport.types";

/**
 * Project report loading state
 */
export const projectReportLoading = (): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_LOADING
});

/**
 * Project report data
 * @param report
 * @param reportSettings 
 */
export const projectReportData = (reportSettings: DataActionResponse<ReportSettings>): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_LOADED, reportSettings
});

/**
 * Set only report customer settings
 * @param customerReportSettings 
 */
export const projectReportCustomerSettingsDirty = (customerReportSettings: CustomerReportSettingsModel): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_CUSTOMER_SETTINGS_DIRTY, customerReportSettings
});

/**
 * Geovis report configuration loaded
 * @param geovisReportSettings: GeovisReportSettings
 * @param reportElementsChanges - changes in elements, used to determine dirty elements
 * @returns 
 */
export const projectReportGeovisLoaded = (geovisReportSettings: DataActionResponse<GeovisReportSettings>, reportElementsChanges: IGeovisProjectReportChangedElements): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_GEOVIS_LOADED, geovisReportSettings, reportElementsChanges
})

/**
 * Geovis report configuration loaded
 * @param geovisReportSettings: GeovisReportSettings
 * @returns 
 */
export const projectDefaultReportGeovisLoaded = (reportSettings: DataActionResponse<GeovisDefaultReportSettings>): IGeovisProjectReportAction => ({
    type: PROJECT_DEFAULT_REPORT_GEOVIS_LOADED, geovisDefaultReportSettings: reportSettings
})

/**
 * Show/hide sensors list
 * @param showSensorsList 
 * @param sensorsPropertyId 
 */
export const projectReportSensorsDialog = (showSensorsList: boolean, sensorsPropertyId: string = ''): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_SENSOR_DIALOG, showSensorsList, sensorsPropertyId
});

/**
 * Update legacy chart sensors selection info
 * @param legacySensorsSelectionInfo 
 * @param changedChartIds 
 * @returns 
 */
export const projectReportUpdateLegacyChartSensorsSelection = (legacySensorsSelectionInfo: IReportSensorsSelectionInfoLegacy, changedChartIds: number[]): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_UPDATE_LEGACY_CHART_SENSORS_SELECTION_INFO, legacySensorsSelectionInfo, legacyChartChangedIds: changedChartIds
})

/**
 * Update report sensors selection info
 * @param geovisSensorsSelectionInfo
 * @param changedIdsByPage
 */
export const projectReportUpdateGeovisElementsSensorsSelectionInfo = (geovisSensorsSelectionInfo: IReportChartSensorsSelectionInfo, changedIdsByPage: IGeovisProjectReportChangedElementIds): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_UPDATE_GEOVIS_ELEMENTS_SENSORS_SELECTION_INFO, geovisSensorsSelectionInfo, reportElementsChanges: changedIdsByPage
});

/**
 * Highlight invalid property by property path
 * @param propertyId
 * @param message 
 */
export const projectReportHighlightInvalidProperty = (propertyId: string, message: string): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTY, propertyId, errorMessage: message
});

/**
 * Highlight invalid chart properties after validation
 * @param invalidPropertiesInfo
 */
export const projectReportHighlighInvalidProperties = (invalidPropertiesInfo: ChartInvalidFieldDescription[]): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_HIGHLIGH_INVALID_PROPERTIES, invalidPropertiesInfo
});

/**
 * Clear invalid properties state
 */
export const projectReportClearInvalidProperties = (): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_CLEAN_INVALID_PROPERTIES
})

/**
 * Store changed report settings in the storage
 * @param settings 
 * @returns 
 */
export const projectReportApplyCustomSettings = (settings: CustomerReportSettingsModel): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_APPLY_CUSTOM_SETTINGS, customerReportSettings: settings
})

/**
 * Applies custom report properties
 */
export const projectReportApplyCustomProperties = (): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_APPLY_CUSTOMER_PROPERTIES
});

/**
 * Clear custom report properties
 */
export const projectReportClearCustomProperties = (): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_CLEAR_CUSTOMER_PROPERTIES
});

export const projectReportPngLoaded = (): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_PNG_LOADED
});

export const projectReportPngStartLoading = (): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_PNG_START_LOADING
});

export const projectReportPdfLoaded = (): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_PDF_LOADED
});

export const projectReportPdfStartLoading = (): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_PDF_START_LOADING
});

export const projectReportChartUpdateTimes = (updTimesInfo: IReportChartCallbackInfo): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_CHART_UPDATE_TIMES, updTimesInfo
})

export const setValidationResult = (validationResult: IInvalidPropertyInfo[]): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_DEFAULT_SET_VALIDATION_RESULT, invalidProperties: validationResult
})

export const setDefaultGeovisReportSettings = (settings: IGeovisReportSettings, sensorDetails: { [key: number]: ChartSensorsDetails }): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_DEFAULT_SET_REPORT_SETTINGS, defaultReportSettings: settings, sensorDetails
});

export const setGeovisReportSettings = (changedGeovisChartPages: Map<number, IGeovisReportPageConfig>): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_SET_REPORT_SETTINGS, changedGeovisChartPages
});

export const resetGeovisReportSettings = (): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_RESET_REPORT_SETTINGS,
});

export const setDirtyGeovisChartSettings = (pageNum: number, chartModel: GeovisChartModel): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_SET_CHART_DIRTY_SETTINGS, geovisChart: chartModel, pageNum
});

/**
 * Set geovis chart report data storage in loading state
 * @param chartId 
 * @returns 
 */
export const projectReportGeovisChartDataLoading = (pageNum: number, chartId: number, isVibrationData: boolean): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_CHART_DATA_LOADING, numberValue: chartId, pageNum, booleanValue: isVibrationData
});

/**
 * Update geovis chart after data loading in store
 * - update header info
 * - update dates from/to and period in left panel, but no trigger redraw the chart
 * @param chartId
 * @param geovisChartData
 * @param dtsSectionData
 * @returns 
 */
export const projectReportGeovisChartDataLoaded = (pageNum: number, chartId: number, geovisChartData: DataActionResponse<GeovisChartData>, dtsSectionData: DataActionResponse<DtsSectionInfo[]> | undefined, isVibrationData: boolean): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_CHART_DATA_LOADED, numberValue: chartId, geovisChartData, dtsSectionData, pageNum, booleanValue: isVibrationData
});

/**
 * Call redraw the chart, update chart data reference only
 * @param pageNum
 * @param chartId 
 * @returns 
 */
export const projectReportGeovisChartRedraw = (pageNum: number, chartId: number): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_CHART_REDRAW, numberValue: chartId, pageNum
});

/**
 * Call reload chart data, but don't set it to isLoading=true (avoid blink)
 * Used to reload data in background
 * @returns 
 */
export const projectReportGeovisReloadWithoutBlink = (): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_RELOAD_DATA_WITHOUT_BLINK
})

/**
 * Set geovis4table to loading state in report
 * @param pageNum 
 * @param tableId 
 * @returns 
 */
export const projectReportGeovis4TableLoading = (pageNum: number, tableId: number): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_TABLE_LOADING, pageNum, numberValue: tableId
});

/**
 * Set loaded data of Geovis4Table to storage
 * @param pageNum 
 * @param tableId 
 * @param geovis4TableData 
 * @returns 
 */
export const projectReportGeovis4TableLoaded = (pageNum: number, tableId: number, geovis4TableData: DataActionResponse<Geovis4TableData>): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_TABLE_LOADED, pageNum, numberValue: tableId, geovis4TableData
})


export const projectReportServiceDataChanged = (elementId: number, pageNum: number, pagesCount: number, startDate: string, endDate: string): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_SERVICE_DATA_CHANGED, pageNum, numberValue2: pagesCount, stringValue: startDate, stringValue2: endDate, elementId
})

export const projectGeovisReportVibrationEventShow = (pageNum: number, elementId: number, eventId: string, fullId: string, eventDate: string): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_SHOW, elementId, stringValue: eventId, pageNum, stringValue2: fullId, eventDate
});

export const projectGeovisReportVibrationEventHide = (pageNum: number, elementId: number): IGeovisProjectReportAction => ({
    type: PROJECT_GEOVIS_REPORT_VIBRATION_EVENT_HIDE, elementId, pageNum
});
/**
 * Set map section data storage to loading state
 * @param pageNum 
 * @param sectionId 
 * @returns 
 */
export const projectReportMapSectionDataLoading = (pageNum: number, sectionId: number): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_MAP_SECTION_DATA_LOADING, pageNum, numberValue: sectionId
})

/**
 * Set map section data to storage
 * @param pageNum 
 * @param sectionId 
 * @param timeSearchData
 * @returns 
 */
export const projectReportMapSectionDataLoaded = (pageNum: number, sectionId: number, timeSearchData: DataActionResponse<GeovisTimeSearchReportData>): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_MAP_SECTION_DATA_LOADED, pageNum, numberValue: sectionId, timeSearchData
});

/**
 * Set geovis image data storage to loading state
 * @param pageNum 
 * @param elementId 
 * @returns 
 */
export const projectReportGeovisImageDataLoading = (pageNum: number, elementId: number): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADING, pageNum, elementId
});


/**
 * Set geovis image data storage to loaded state
 * @param pageNum 
 * @param elementId 
 * @param timeSearchData
 * @returns 
 */
export const projectReportGeovisImageDataLoaded = (pageNum: number, elementId: number, timeSearchData: DataActionResponse<GeovisTimeSearchReportData>): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_GEOVIS_IMAGE_DATA_LOADED, pageNum, elementId, timeSearchData
});

/**
 * 
 * @param pageNum 
 * @param elementId 
 * @returns 
 */
export const projectReportGeovisLogbookDataLoading = (pageNum: number, elementId: number): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADING, pageNum, elementId
});

/**
 * 
 * @param pageNum 
 * @param elementId 
 * @param geovisLogbookData 
 * @returns 
 */
export const projectReportGeovisLogbookDataLoaded = (pageNum: number, elementId: number, geovisLogbookData: DataActionResponse<GeovisLogbookDataModel>): IGeovisProjectReportAction => ({
    type: PROJECT_REPORT_GEOVIS_LOGBOOK_DATA_LOADED, pageNum, elementId, geovisLogbookData
});