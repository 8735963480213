/**
 * @author Ivan Kozlov<ik@ieskr.ru>
 * @created 30.05.2022
 * @description Control for table with selectable rows. For same selection behavior in all components
 */

import Checkbox from "@atlaskit/checkbox";
import { HeadType, RowType, SortOrderType } from "@atlaskit/dynamic-table/dist/types/types";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { PaginationControl } from "./PaginationControl";
import { DynamicTableWithFixedHeader } from "./DynamicTableWithFixedHeader";

interface IComponentProps<TItem> {
    head?: HeadType;
    allItems: TItem[];
    selectedItems: TItem[];
    isLoading: boolean;
    needSelectAll: boolean;

    pageNumber?: number;
    onChangePageCallback?: (pageNumber: number) => void;

    //defaultPageNumber?: number;
    rowsPerPage?: number;
    replaceStyling?: boolean;
    styleToReplace?: string;
    defaultSortKey?: string;
    defaultSortOrder?: SortOrderType;
    showPagination?: boolean;
    disableSelection?: boolean;
    itemToRowFunc: (item: TItem) => RowType;
    sortingFunc?: (itemA: TItem, itemB: TItem, sortKey: string, sortOrder: string) => number;
    onSelectionChanged: (selectedItems: TItem[]) => void;
    onSelectDeselectAll?: (allSelected: boolean) => void;
    getIdFunc: (item: TItem) => string;
    onAfterSort?: (sortKey: string, sortOrder: string) => void;
    itemToRowColorFunc?: (item: TItem) => string;
    showCountRowsSelect?: boolean;
    rowsPerPageOptions?: number[]
}

interface IComponentState {
    lastSelectedIndex: number;
    lastClickedRowWithShift: number;
    sortKey: string;
    sortOrder: SortOrderType;
    allSelected: boolean;
    rowsPerPage: number | undefined;
    pageNumber: number;
}

export const GeovisTableWithSelectableRows = <TItem extends any>({
    head,
    onSelectionChanged,
    //defaultPageNumber,
    pageNumber,
    rowsPerPage,
    onSelectDeselectAll,
    needSelectAll,
    isLoading,
    allItems,
    itemToRowFunc,
    selectedItems,
    sortingFunc,
    getIdFunc,
    replaceStyling,
    onChangePageCallback,
    defaultSortKey,
    defaultSortOrder,
    showPagination,
    styleToReplace,
    onAfterSort,
    disableSelection,
    itemToRowColorFunc,
    showCountRowsSelect,
    rowsPerPageOptions
}: IComponentProps<TItem>) => {

    const getAllSelected = (): boolean => {
        if (allItems.length === 0) {
            return false;
        }
        let allSelected = true;
        allItems.forEach(item => {
            if (!selectedItems.includes(item)) {
                allSelected = false;
            }
        })

        return allSelected;
    }

    const [state, setState] = useState<IComponentState>({
        lastSelectedIndex: 0,
        sortKey: defaultSortKey ?? "",
        sortOrder: defaultSortOrder ?? "ASC",
        allSelected: getAllSelected(),
        lastClickedRowWithShift: -1,
        rowsPerPage,
        pageNumber: pageNumber || 1
    });

    useEffect(() => {
        setState({
            lastSelectedIndex: 0,
            sortKey: state.sortKey,
            sortOrder: state.sortOrder,
            allSelected: getAllSelected(),
            lastClickedRowWithShift: -1,
            rowsPerPage,
            pageNumber: pageNumber || 1
        });
    }, [])

    // Handle external changes of page number
    useEffect(() => {
        setState(st => ({
            ...st,
            pageNumber: pageNumber || st.pageNumber
        }))
    }, [pageNumber])

    const getTableHead = (exHead?: HeadType): HeadType | undefined => {
        if (!exHead) {
            if (needSelectAll) {
                const headData: HeadType = {
                    cells: [{
                        key: 'select',
                        width: 3,
                        content: (
                            <Checkbox
                                onChange={onChangeAllHandler}
                                isChecked={getAllSelected()}
                                isDisabled={disableSelection}
                            />
                        )
                    }]
                }
                return headData;
            }
            else {
                return undefined;
            }
        }

        if (!exHead.cells.find(c => c.key === 'select') && needSelectAll) {
            exHead.cells.splice(0, 0, {
                key: 'select',
                width: 3,
                content: (
                    <Checkbox
                        isChecked={getAllSelected()}
                        onChange={onChangeAllHandler}
                        isDisabled={disableSelection}
                    />
                )
            });
        }

        return exHead;
    }

    const checkItemIndex = (index: number): boolean => {
        if (state.rowsPerPage) {
            const minIndex = (state.pageNumber - 1) * state.rowsPerPage;
            const maxIndex = state.pageNumber * state.rowsPerPage;
            return index < maxIndex && index >= minIndex;
        }

        return true;
    }

    const getTableRows = (): RowType[] => {
        const result: RowType[] = [];
        const sortedItems = sortingFunc ? allItems.sort((a, b) => sortingFunc(a, b, state.sortKey, state.sortOrder)) : allItems;
        sortedItems.forEach((item, itemIndex) => {
            if (checkItemIndex(itemIndex)) {
                const itemId = getIdFunc(item);
                const row: any = itemToRowFunc(item);
                if (itemToRowColorFunc) {
                    const color = itemToRowColorFunc(item);
                    if (color !== "") {
                        row.style = { background: itemToRowColorFunc(item) };
                    }
                }
                row.onClick = onRowClickHandler(itemId);
                row.cells.splice(0, 0, {
                    key: 'select',
                    content: (
                        <Checkbox
                            isChecked={isValueChecked(itemId)}
                            isDisabled={disableSelection}
                        />
                    )
                });
                result.push(row);
            }
        });

        return result;
    }

    const getRowIndexById = (rowId: string): number => {
        const sortedItems = sortingFunc ? allItems.sort((a, b) => sortingFunc(a, b, state.sortKey, state.sortOrder)) : allItems;
        return sortedItems.findIndex(item => rowId === getIdFunc(item));
    }

    const onRowClickHandler = (clickedRowId: string) => (event: React.MouseEvent) => {
        if (event.isDefaultPrevented() || disableSelection) {
            return;
        }

        const { lastClickedRowWithShift } = state;

        const clickedRowIndex = getRowIndexById(clickedRowId);
        const sortedItems = sortingFunc ? allItems.sort((a, b) => sortingFunc(a, b, state.sortKey, state.sortOrder)) : allItems;
        const clickedItem = allItems.find(i => getIdFunc(i) === clickedRowId);

        if (clickedRowIndex < 0 || !clickedItem) {
            return;
        }

        let updSelection: TItem[] = [];
        updSelection.push(...selectedItems);

        const { shiftKey } = event;

        if (shiftKey) {
            // we want to select everything from last selected index to current selected index
            sortedItems.forEach((item, itemIndex) => {
                if (between(state.lastSelectedIndex, clickedRowIndex, itemIndex)) {
                    if (updSelection.findIndex(i => getIdFunc(i) === getIdFunc(item)) < 0) {
                        updSelection.push(item);
                    }
                }
                if (clickedRowIndex >= state.lastSelectedIndex && lastClickedRowWithShift >= 0 && clickedRowIndex < lastClickedRowWithShift && between(clickedRowIndex + 1, lastClickedRowWithShift, itemIndex)) {
                    updSelection = updSelection.filter(e => getIdFunc(e) !== getIdFunc(item));
                }
                if (clickedRowIndex <= state.lastSelectedIndex && lastClickedRowWithShift >= 0 && clickedRowIndex > lastClickedRowWithShift && between(clickedRowIndex - 1, lastClickedRowWithShift, itemIndex)) {
                    updSelection = updSelection.filter(e => getIdFunc(e) !== getIdFunc(item));
                }
            })
        }
        else {
            if (updSelection.findIndex(i => getIdFunc(i) === clickedRowId) >= 0) {
                const selectedRowIndex = updSelection.findIndex(i => getIdFunc(i) === clickedRowId);
                updSelection.splice(selectedRowIndex, 1);
            }
            else {
                updSelection.push(clickedItem);
            }

        }

        onSelectionChanged(updSelection);
        setState({ ...state, lastSelectedIndex: shiftKey ? state.lastSelectedIndex : clickedRowIndex, lastClickedRowWithShift: shiftKey ? clickedRowIndex : -1 })
        event.preventDefault();
    }

    const onChangeAllHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        if (onSelectDeselectAll) {
            onSelectDeselectAll(event.currentTarget.checked);
        }
    }

    const isValueChecked = (rowId: string): boolean => {
        return selectedItems.findIndex(i => getIdFunc(i) === rowId) >= 0;
    }

    const between = (valueA: number, valueB: number, toCheck: number): boolean => {
        const max = Math.max(valueA, valueB);
        const min = Math.min(valueA, valueB);

        return toCheck >= min && toCheck <= max;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onSortHandler = (data: any, event: any) => {
        if (onAfterSort) {
            onAfterSort(data.key, data.sortOrder)
        }
        // drop last selected index in case of sorting
        setState({ ...state, lastSelectedIndex: 0, lastClickedRowWithShift: -1, sortKey: data.key, sortOrder: data.sortOrder })
    }

    const onSetPageHandler = (page: number) => {
        setState({ ...state, pageNumber: page })
        if (onChangePageCallback) {
            onChangePageCallback(page);
        }
    }

    const onSetRowsPerPage = (value: number) => {
        setState({ ...state, lastSelectedIndex: 0, lastClickedRowWithShift: -1, rowsPerPage: value })
    }

    const rowsPerPageValues = rowsPerPageOptions ?? [10, 25, 50, 100];

    return (
        <div className={replaceStyling ? styleToReplace ?? "" : "geovisDynamicTableSelectable"} style={{ userSelect: 'none', msUserSelect: 'none' }}>
            <div className="flexRowMiddleContainer">
                <DynamicTableWithFixedHeader
                    rows={getTableRows()}
                    head={getTableHead(head)}
                    isLoading={isLoading}
                    sortKey={state.sortKey}
                    onSort={onSortHandler}
                    sortOrder={state.sortOrder}
                    loadingSpinnerSize="large"
                />
            </div>
            {showPagination &&
                <div className="flexRowContainerLine">
                    <PaginationControl
                        itemsCount={allItems.length}
                        pageNumber={state.pageNumber}
                        rowsPerPage={state.rowsPerPage ?? 100}
                        onSetPageNumber={onSetPageHandler}
                        onSetRowsPerPage={onSetRowsPerPage}
                        rowsPerPageOptions={rowsPerPageValues}
                        showCountRowsSelect={showCountRowsSelect}
                    />
                </div>
            }
        </div>
    )
}