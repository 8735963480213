/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 27.07.2023
 * @description Geovis map section map layers
 */

import { Fragment } from "react"
import { connect } from "react-redux";
import { useMap } from "react-leaflet";
import { GeoLocation } from "../../../../server/GeoLocation";
import { IChainsInfoStorage, IChainsLastDataStorage, ISensorsInfoStorage, ISensorsLastDataStorage, ISensorsSymbolsStorage } from "../../../../store/data.types";
import { IGeovisStoreState } from "../../../../store/store.types";
import { IGeovisMapSectionReportInfo } from "../../../../store/projectReport.types";
import { GEOvisDXFLayerType } from "../../../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import MapLayersControl from "../../../map/MapLayersControl";
import { MaxMapZoomLevel, MinMapZoomLevel } from "../../../map/types";
import { GeovisMapSectionGeoJSONLayer } from "./GeovisMapSectionGeoJSONLayer";
import { GeovisMapSectionLocalMapObjectsLayer } from "./GeovisMapSection.LocalMapObjectsLayer";
import { IReportElementRenderSimpleOwnProps } from "../../reportOverlay/reportRender/types";
import { DXFViewerOfLayerInfo } from "../../dxfLayers/DXFViewLayer";
import { SensorsOverviewLayer } from "../../sensors/SensorsOverviewLayer";
import { SensorCategory } from "../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { SensorValueAttribute } from "../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { TimeSearchDistanceDataRequest } from "../../../../server/AVTService/Computation/TimeSearchDistance/TimeSearchDistanceDataRequest";
import MapProviderHelper from "../../../../helpers/MapProviderHelper";
import { GeovisMapPaneCreator } from "../../../map/GeovisMap.PaneCreator";
import { MapSectionGeoRasterLayer } from "./MapSectionGeoRasterLayer";

interface IStateToProps {
    sensorsSymbolsStorage: ISensorsSymbolsStorage;
}

interface IOwnProps extends Omit<IReportElementRenderSimpleOwnProps, 'isPrinting' | 'pageNum' | 'reportId'> {
    mapSectionInfo: IGeovisMapSectionReportInfo;
    projectLocation: GeoLocation;

    sensorsInfoStorage: ISensorsInfoStorage;
    sensorsLastDataStorage: ISensorsLastDataStorage;
    chainsInfoStorage: IChainsInfoStorage;
    chainsLastDataStorage: IChainsLastDataStorage
    isMapReady: boolean

    showSensorsLayer: boolean;
    tooltipEnabled?: boolean;
    popupEnabled?: boolean;
    spiderfyEnabled?: boolean;

    timeSearchDistanceElement: TimeSearchDistanceDataRequest;
}

interface IComponentProps extends IStateToProps, IOwnProps {

}

const GeovisMapSectionMapLayers = ({
    mapSectionInfo,
    projectLocation,
    sensorsSymbolsStorage,
    chainsInfoStorage,
    sensorsInfoStorage,
    sensorsLastDataStorage,
    userId,
    userToken,
    showSensorsLayer,
    popupEnabled,
    spiderfyEnabled,
    tooltipEnabled,
    chainsLastDataStorage,
    timeSearchDistanceElement,
    isMapReady
}: IComponentProps) => {

    const mapRef = useMap();

    const { MapSection, Timestamp, DxfMapTileLayerBounds, InvertXAxis } = mapSectionInfo;
    const { ProjectId } = MapSection;

    const isMapLayer = MapSection.LayerType === GEOvisDXFLayerType.Map;

    return (
        <Fragment>

            {/* This component must be added on each map where Sensors, Chains or Movement information panned to render */}
            <GeovisMapPaneCreator />

            {/* if map layer */}
            {/* MapSection.LayerType === GEOvisDXFLayerType.Map */}
            {isMapLayer && (
                <div>
                    <MapLayersControl
                        mapProvider={MapSection.BaseMapProvider}
                        streetsMapMinZoom={MinMapZoomLevel}
                        streetsMapMaxZoom={MaxMapZoomLevel}
                        streetsMapMaxNativeZoom={MapProviderHelper.getProviderMaxNativeZoomValue(MapSection.BaseMapProvider)}
                        projectLocation={projectLocation}
                    />

                    <GeovisMapSectionGeoJSONLayer
                        projectId={MapSection.ProjectId}
                        layerId={MapSection.BackgroundDxfLayer}
                        userId={userId}
                        userToken={userToken}
                    />

                    <GeovisMapSectionLocalMapObjectsLayer
                        mapSection={MapSection}
                        userId={userId}
                        userToken={userToken}
                    />

                    <MapSectionGeoRasterLayer layerId={MapSection.GeoRaster} userId={userId} userToken={userToken} projectId={MapSection.ProjectId} />

                </div>
            )}

            {/* if profile view layer*/}
            {MapSection.LayerType === GEOvisDXFLayerType.ProfileView && (
                <DXFViewerOfLayerInfo
                    projectId={ProjectId}
                    layerId={MapSection.ProfileViewDxfLayer}
                />
            )}

            {/* if side view layer */}
            {MapSection.LayerType === GEOvisDXFLayerType.SideView && (
                <DXFViewerOfLayerInfo
                    projectId={ProjectId}
                    layerId={MapSection.SideViewDxfLayer}
                />
            )}

            {showSensorsLayer && (
                <SensorsOverviewLayer
                    projectId={ProjectId}
                    viewType={MapSection.LayerType}
                    sensorsInfoStorage={sensorsInfoStorage}
                    chainsInfoStorage={chainsInfoStorage}
                    customSensorsLastDataStorage={sensorsLastDataStorage}
                    customChainsLastDataStorage={chainsLastDataStorage}
                    showLastMeasurementTimeInTooltip={true}
                    layersVisibility={{
                        showGeoJSONLayers: isMapLayer,
                        showInclinometerChains: isMapLayer,
                        showSensorsLayer: true
                    }}
                    sensorsLayerState={{
                        showTooltipContentEvenFaAlarm: true,
                        showSensorNames: MapSection.ShowSensorNamesSetting.value,
                        fontSize: MapSection.FontSize,
                        useSensorColor: MapSection.UseSensorColorSetting.value,
                        useGeovis4SensorTypeSymbol: MapSection.UseGeovis4SensorTypeSymbolSetting.value,
                        visibleSensorTypes: new Map<SensorCategory, boolean>(),
                        useSensorColorForText: MapSection.UseSensorColorForSensorName
                    }}
                    sensorsSymbolsStorage={sensorsSymbolsStorage}
                    invertXAxis={InvertXAxis}
                    offsetsBounds={DxfMapTileLayerBounds}
                    sensorFilter={{ angleFilter: {}, manufacturerFilter: [], radiusFilter: {}, sensorTypeFilter: [], tunnelMeterFilter: {}, unitFilter: [], databasesFilter: [] }}
                    tooltipEnabled={tooltipEnabled}
                    popupEnabled={popupEnabled}
                    spiderfyEnabled={spiderfyEnabled}
                    forceSingleMarkerMode={true}
                    useMapTextOrientation={true}
                    createCustomizedClusterIcon={true}
                    useTooltipSmartPosition={true}
                    leafletElement={mapRef || undefined}
                    isMapReady={isMapReady}
                    // this property responsible for rendering bars and movement records
                    movementOptions={{
                        layerType: MapSection.LayerType,
                        movementSettings: MapSection.MovementSettings,
                        fontSize: MapSection.FontSize,
                        pictureXCoordinate: SensorValueAttribute.Empty,
                        pictureYCoordinate: SensorValueAttribute.Empty,
                        imageSensors: []
                    }}
                    timeSearchDistanceElement={timeSearchDistanceElement}
                    reportElementTimestamp={Timestamp}
                    userId={userId}
                    userToken={userToken}
                    showIconTextBorder={MapSection.UseBordersAroundText}
                />
            )}
        </Fragment>
    )
}


const mapStateToProps = ({ data: { sensorsSymbolsStorage } }: IGeovisStoreState): IStateToProps => ({
    sensorsSymbolsStorage
});

const GeovisMapSectionMapLayersConnected = connect<IStateToProps, never, IOwnProps>(mapStateToProps)(GeovisMapSectionMapLayers);

export default GeovisMapSectionMapLayersConnected;