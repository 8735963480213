/**
 * @author Vyacheslav Skripin <vs@iesk.ru>
 * @created 25.01.2022
 * @description Common types for sensors/chains data pages
 */

//import IRouteParams from "../../../helpers/IRouteParams";

// export interface ISensorDataRouteParams extends IRouteParams {
//     fullId: string;
// }

export const SensorsDataReferenceColors: Readonly<string[]> = [
    '#D4F8D4',
    '#DCF9EC',
    '#E5F3CA',
    '#D8F2F8',
    '#BDCCBB'
];