/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.12.2-10
 * @description Methods to help to sort elements
 */

import { AlarmInfo } from "../server/AlarmInfo";
import {
    getHighestAlarm,
    isElementWithAlarms
} from "./SensorHelper";
import { ITreeElementObj } from "./types";

/**
 * Comparer of two sensors to sort by method "sort:
 * First sort by sensor alarms, sensor with highest alarm level will be first
 * Then compare by sensor name (smart comparison)
 * @param elementA 
 * @param elementB 
 */
export const comparerFnOfElementsInTheProjectViewTree = <TElement extends ITreeElementObj>(elementA: TElement, elementB: TElement): number => {

    // comparer result info
    // a negative value if first argument is less than second argument, zero if they're equal and a positive value otherwise (if they must stay). 

    // first is compare by alarms
    // if one of sensor has alarm, but other doesn't, then it should state higher
    if (isElementWithAlarms(elementA) && !isElementWithAlarms(elementB)) {
        return -1; // the should stay
    }

    if (!isElementWithAlarms(elementA) && isElementWithAlarms(elementB)) {
        return 1; // sensorB must be before of sensorA
    }

    if (isElementWithAlarms(elementA) && isElementWithAlarms(elementB)) {
        return compareFnOfElementInfoAlarmsDown(elementA, elementB);
    }

    // sensors without alarms
    // second compare by names
    return compareFnOfNames(elementA.Name, elementB.Name);
}

/**
 * Compare sensors by alarm severity (first sensor has lowest severity, last has highest severity)
 * @param elementA 
 * @param elementB 
 */
export const compareFnOfElementInfoAlarms = <TElement extends ITreeElementObj>(elementA: TElement, elementB: TElement): number => {

    if (!isElementWithAlarms(elementA) && !isElementWithAlarms(elementB)) {
        return 0; // nothing to change, because sensors without alarms
    }

    const aAlarm = getHighestAlarm(elementA.CausedAlarms);
    const bAlarm = getHighestAlarm(elementB.CausedAlarms);

    if (!aAlarm && !bAlarm) {
        return 0;
    }

    if (aAlarm && !bAlarm) {
        return -1
    }

    if (!aAlarm && bAlarm) {
        return 1;
    }

    const aSeverity = (aAlarm as AlarmInfo).SeverityIndex;
    const bSeverity = (bAlarm as AlarmInfo).SeverityIndex;

    return bSeverity - aSeverity;
}

/**
 * Compare two sensors by alarms in case that first will be sensor with highest severity of the alarm
 * @param elementA 
 * @param elementB 
 */
export const compareFnOfElementInfoAlarmsDown = <TElement extends ITreeElementObj>(elementA: TElement, elementB: TElement): number => {
    const result = compareFnOfElementInfoAlarms(elementA, elementB);

    if (result > 0) {
        return -1;
    }

    if (result < 0) {
        return 1;
    }

    return compareFnOfNames(elementA.Name, elementB.Name);
}

/**
 * Comparer for names
 * @param nameA 
 * @param nameB 
 */
export const compareFnOfNames = (nameA: string, nameB: string): number => {

    // comparer result info
    // a negative value if first argument is less than second argument, zero if they're equal and a positive value otherwise (if they must stay). 

    if (!nameA && nameB) {
        return -1;
    }

    if (nameA && !nameB) {
        return 1;
    }

    const piecesOfA = getStringPieces(nameA);
    const piecesOfB = getStringPieces(nameB);

    const maxPieces = piecesOfA.length > piecesOfB.length ? piecesOfA.length : piecesOfB.length;

    for (let index = 0; index < maxPieces; index++) {

        if (index >= piecesOfA.length && index < piecesOfB.length) {
            return -1;
        }

        if (index < piecesOfA.length && index >= piecesOfB.length) {
            return 1;
        }

        const pieceA = piecesOfA[index];
        const pieceB = piecesOfB[index];

        // skip the same pieces
        if (pieceA === pieceB) {
            continue;
        }

        const numberA = +pieceA;
        const numberB = +pieceB;

        if (isNaN(numberA) && !isNaN(numberB)) {
            return 1; // pieceA is letter, pieceB is number
        }

        if (!isNaN(numberA) && isNaN(numberB)) {
            return -1; // pieceA is number, pieceB is letter
        }

        if (isNaN(numberA) && isNaN(numberB)) {
            return pieceA.localeCompare(pieceB);
        }

        return numberA - numberB;
    }

    return 0;
}

const getStringPieces = (line: string): string[] => {
    const result: string[] = [];

    let isNumber: boolean | undefined;
    let buffer = "";

    for (const letter of line) {

        const letterIsNumber = !isNaN(Number(letter));

        // first check
        if (isNumber === undefined) {
            isNumber = letterIsNumber;
            buffer = buffer + letter;
            continue;
        }

        if (letterIsNumber !== isNumber) {
            result.push(buffer);

            isNumber = letterIsNumber;
            buffer = letter;
            continue;
        }

        buffer = buffer + letter;
    }

    if (buffer !== "") {
        result.push(buffer);
    }

    return result;
}