import Button, { LoadingButton } from "@atlaskit/button";
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle'
import MediaServicesSpreadsheetIcon from '@atlaskit/icon/glyph/media-services/spreadsheet';
import PdfIcon from '@atlaskit/icon/glyph/pdf'
import Tooltip from '@atlaskit/tooltip';
import { useState } from "react";
import { formattedDateTime } from "../../../../helpers/DateHelper";
import { fetchServerElementsByPost, sendServerPostRequestData } from "../../../../helpers/ProjectDataHelper";
import { t } from "../../../../i18n";
import { VibrationEventInfoRequestModel } from "../../../../server/GEOvis3/Model/GeovisChart/VibrationEventInfoRequestModel";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import FlagService from "../../../../services/FlagService";
import { IGeovisVibrationEventSlimInfo } from "../../../../store/projectReport.types";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { PdfReportRequest } from "../../../../server/GEOvis3/Model/Reports/PdfReportRequest";
import { GeovisReportPageSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisReportPageSettings";
import { VibrationChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { DataActionResponse } from "../../../../server/DataActionResponse";
import { ActionResponse } from "../../../../server/ActionResponse";
import { ChartType } from "../../../../server/AVTService/TypeLibrary/Common/ChartType";
import { KindOfElementUsing } from "../../../../server/AVTService/TypeLibrary/Common/KindOfElementUsing";

export const TitleComponentHeightPx = 32;
const TitleComponentBorderPx = 2;

export const getTitleComponentHeight = (indexOnPage: number): number => {
    let result = TitleComponentHeightPx + TitleComponentBorderPx; // + bottom border height
    if (indexOnPage !== 0) {
        result += TitleComponentBorderPx; // + top border height
    }

    return result;
}

interface IComponentProps extends IWithGeovisServerProps {
    elementTitle: string;
    elementIndexOnPage: number;
    projectId: number;
    chartId: number;
    reportId: number;

    isInGroup?: boolean;
    lastInGroup?: boolean;
    vibrationEventInfo?: IGeovisVibrationEventSlimInfo
    onHideVibrationEvent?: () => void;
    isVibrationEventChart?: boolean;
    isPrinting: boolean;
}

interface IComponentState {
    loadingEventExport: boolean;
    loadingPrintingToPdf: boolean;
}

const Component = ({
    elementTitle,
    elementIndexOnPage,
    isInGroup,
    lastInGroup,
    vibrationEventInfo,
    onHideVibrationEvent,
    isVibrationEventChart,
    Server,
    projectId,
    chartId,
    isPrinting,
    reportId
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({ loadingEventExport: false, loadingPrintingToPdf: false })

    if (isVibrationEventChart && vibrationEventInfo) {
        const onCloseEventChart = () => {
            if (onHideVibrationEvent) {
                onHideVibrationEvent();
            }
        }

        const onExportEventInCSV = async () => {
            try {
                setState({ ...state, loadingEventExport: true });

                const eventInfoRequest: VibrationEventInfoRequestModel = { ChartId: chartId, EventId: vibrationEventInfo.eventId, FullId: vibrationEventInfo.fullId };

                const fileNameUrl = ServerRoutesGen.ProjectReport.GetExportGeovisVibrationEventDataFileName.patch(projectId);
                const fileNameResponse = await fetchServerElementsByPost<string, VibrationEventInfoRequestModel>(Server, fileNameUrl, eventInfoRequest);

                if (!fileNameResponse.Success) {
                    FlagService.addError("Failed to export event data", fileNameResponse.Messages.join(', '));
                    setState({ ...state, loadingEventExport: false });
                    return;
                }

                const url = ServerRoutesGen.ProjectReport.ExportGeovisVibrationEventDataToCSV.patch(projectId).path;

                await Server.downloadFilePost(url, eventInfoRequest, `${fileNameResponse.Data}.csv`)
            }
            catch (error) {
                FlagService.addError("Failed to export event data", error);
            }
            setState({ ...state, loadingEventExport: false });
        }

        const onPrintEventToPdfClick = async () => {
            try {
                setState({
                    ...state,
                    loadingPrintingToPdf: true
                });

                const pdfReportSettings: PdfReportRequest = {
                    Pages: [{
                        ...new GeovisReportPageSettings(),
                        PageNum: 0,
                        GeovisCharts: [{
                            ...new VibrationChartModel(),
                            Id: chartId,
                            ProjectId: projectId,
                            Type: ChartType.VibrationDiagramm,
                            KindOfElementUsing: KindOfElementUsing.Element,
                        }]
                    }],
                    ProjectId: projectId,
                    ReportId: reportId,
                    IsDefaultChart: false,
                    EventPrintInfo: [{
                        EventChartId: chartId,
                        EventDate: vibrationEventInfo.eventDate,
                        EventId: vibrationEventInfo.eventId,
                        FullId: vibrationEventInfo.fullId,
                        PageNumber: 0
                    }],
                    IsEventOnlyReport: true
                };

                let settingsId = "";
                const url = ServerRoutesGen.ProjectReport.StoreGeovisReportCustomSettings.patch(projectId);
                const response = await sendServerPostRequestData<PdfReportRequest, DataActionResponse<string>>(Server, url, pdfReportSettings);

                if (!response.Success) {
                    FlagService.addErrors(t("Error to store changed report settings"), response.Messages);
                    return;
                }

                settingsId = response.Data;

                const pdfUrl = ServerRoutesGen.ProjectReport.GetGeovisReportPdf.patch(projectId, reportId, settingsId);

                const reportArrayBuffer = await Server.get<any>(pdfUrl.path, { asBlob: true });

                // a small size of the response may say about a bad / failed ActionResponse
                const isResponseOfSmallSize = reportArrayBuffer.byteLength !== undefined && reportArrayBuffer.byteLength < 500;
                if (isResponseOfSmallSize) {
                    try {
                        const blob = new Blob([reportArrayBuffer], { type: 'text/plain; charset=utf-8' });
                        const responseJson = await blob.text();
                        const reportResponse = JSON.parse(responseJson) as ActionResponse;
                        const reportFailed = reportResponse && reportResponse.Success !== undefined && reportResponse.Success === false;
                        if (reportFailed) {
                            FlagService.addErrors(t("An error to get a pdf report"), reportResponse.Messages);
                            return;
                        }
                    }
                    catch (parseError) {
                        // nothing to do: Parse error here means just that the reportArrayBuffer is not a json object (not an instance of ActionResponse)
                    }
                }

                Server.downloadFileData(reportArrayBuffer, `${t("Event at")} ${formattedDateTime(vibrationEventInfo.eventDate, "DD.MM.YYYY HH:mm:ss")}` + '.pdf');

                setState({
                    ...state,
                    loadingPrintingToPdf: false
                });

            }
            catch (error) {
                FlagService.addError("Failed to export event data", error);
                setState({
                    ...state,
                    loadingPrintingToPdf: false
                });
            }
        }

        return (
            <div style={{
                height: `${TitleComponentHeightPx}px`,
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'center',
                borderBottom: `${TitleComponentBorderPx}px solid black`,
                borderTop: '0px',
                borderRight: '0px',
                display: 'flex'
            }}>
                <div style={{ flexGrow: 2, width: '100%', marginLeft: '60px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                    <span>{`${t("Event at")} ${formattedDateTime(vibrationEventInfo.eventDate, "DD.MM.YYYY HH:mm:ss")}`}</span>
                </div>
                {!isPrinting &&
                    <div style={{ flexShrink: 2, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center' }}>
                        <Tooltip content={t("Print event to PDF")}>
                            <LoadingButton
                                onClick={onPrintEventToPdfClick}
                                appearance='default'
                                isLoading={state.loadingPrintingToPdf}
                                iconBefore={<PdfIcon label="printPdf" primaryColor="gray" />}
                                style={{ marginRight: '3px', height: `${TitleComponentHeightPx - 2}px` }}
                            />
                        </Tooltip>
                        <Tooltip content={t("Export event to CSV")}>
                            <LoadingButton
                                onClick={onExportEventInCSV}
                                appearance='default'
                                isLoading={state.loadingEventExport}
                                iconBefore={<MediaServicesSpreadsheetIcon label="export" primaryColor="gray" />}
                                style={{ marginRight: '3px', height: `${TitleComponentHeightPx - 2}px` }}
                            />
                        </Tooltip>

                        <Tooltip content={t("Close event")}>
                            <Button
                                onClick={onCloseEventChart}
                                appearance='default'
                                iconBefore={<CrossCircleIcon label="close" primaryColor="gray" />}
                                style={{ height: `${TitleComponentHeightPx - 2}px` }}
                            />
                        </Tooltip>
                    </div>
                }
            </div>
        )
    }

    if (!elementTitle) {
        if (!isInGroup) {
            return null;
        }
        elementTitle = ""
    }

    return (
        <div style={{
            height: `${TitleComponentHeightPx}px`,
            flexShrink: 0,
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
            WebkitFlexShrink: 0,
            borderBottom: `${TitleComponentBorderPx}px solid black`,
            borderTop: elementIndexOnPage !== 0 ? `${TitleComponentBorderPx}px solid black` : '0px',
            borderRight: lastInGroup || !isInGroup ? '0px' : `${TitleComponentBorderPx}px solid black`
        }}>{elementTitle}</div>
    )
}

export default withGeovisServer(Component);