/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14 10.2022
 * @description This file has hooks for common storages in GEOvis 4 client
 */

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CompanyInfo } from "../server/GEOvis3/Model/Company/CompanyInfo";
import { ProjectBusinessInfo } from "../server/ProjectBusinessInfo";
import ServerRoutesGen from "../server/Routes/ServerRoutesGen";
import { IRequestHelper } from "../services/RequestHelper";
import { IBusinessCompaniesStorage, IBusinessProjectsStorage } from "../store/businessData.types";
import { processFetchedData } from "../store/helpers/DataHelper";
import { businessCompaniesStoreInitialState, businessProjectsStoreInitialState } from "../store/reducers/businessDataReducer";
import { ISomethingStoreBase, defaultSomethingStorageState } from "../store/types";
import { fetchServerElements } from "./ProjectDataHelper";
import { elementsToMap } from "./StorageHelper";
import { GeovisMstShortInfoModel } from "../server/GEOvis3/Model/Database/GeovisMstShortInfoModel";

type ISetHookStorageDispatch<TStorage extends ISomethingStoreBase> = Dispatch<SetStateAction<TStorage>>

/**
 * Create and return storage to load related companies
 * @param Server 
 * @param dependencies dependency variables for useEffect and reload the storage
 * @returns 
 */
export const useGeovisRelatedCompanies = (Server: IRequestHelper, ...dependencies: any[]): [IBusinessCompaniesStorage, ISetHookStorageDispatch<IBusinessCompaniesStorage>] => {

    const [storage, setStorage] = useState<IBusinessCompaniesStorage>(businessCompaniesStoreInitialState);

    useEffect(() => {
        (async function loadCompanies() {

            const url = ServerRoutesGen.Account.RelatedCompanies;
            const response = await fetchServerElements<CompanyInfo[]>(Server, url);

            setStorage(processFetchedData(response, storage, businessCompaniesStoreInitialState, st => ({
                companies: elementsToMap(st)
            })));

        })();
    }, [...dependencies]);


    return [storage, setStorage];
};

/**
 * Create and return a projects business storage with leaders as hook
 * Run loading in background
 * @param Server 
 * @param dependencies 
 * @returns 
 */
export const useGeovisRelatedProjects = (Server: IRequestHelper, ...dependencies: any[]): [IBusinessProjectsStorage, ISetHookStorageDispatch<IBusinessProjectsStorage>] => {

    const [storage, setStorage] = useState<IBusinessProjectsStorage>(businessProjectsStoreInitialState);

    useEffect(() => {
        (async function loadProjects() {

            const url = ServerRoutesGen.Account.BusinessProjects.patch(true); // with leaders (why?, but is was implemented that in legacy code)
            const response = await fetchServerElements<ProjectBusinessInfo[]>(Server, url);

            setStorage(processFetchedData(response, storage, businessProjectsStoreInitialState, st => ({
                failedProjectIds: [],
                projects: elementsToMap(st),
                showFailedProjectsDialog: false
            })));
        })();

    }, [...dependencies]);


    return [storage, setStorage];
}

interface IGeovisDatabasesState extends ISomethingStoreBase {
    databases: GeovisMstShortInfoModel[];
}

/**
 * Create and return a project databases storage as state with possibility to edit that
 * @param Server 
 * @param projectId
 * @param onSuccess - callback, which calls when list of databases loaded success
 */
export const useGeovisProjectDatabases = (Server: IRequestHelper, projectId: number, onSuccess: (databases: GeovisMstShortInfoModel[]) => void): [IGeovisDatabasesState, ISetHookStorageDispatch<IGeovisDatabasesState>] => {

    const [state, setState] = useState<IGeovisDatabasesState>({
        ...defaultSomethingStorageState,
        databases: []
    });

    useEffect(() => {

        (async function loadDatabases() {

            const url = ServerRoutesGen.ProjectDatabase.GetDatabasesShortInfo.patch(projectId);
            const response = await fetchServerElements<GeovisMstShortInfoModel[]>(Server, url);

            setState(processFetchedData(response, state, {
                ...defaultSomethingStorageState,
                databases: []
            }, st => ({ databases: st })));

            if (response.Success) {
                onSuccess(response.Data)
            }
        })();

    }, [projectId]);

    return [state, setState];
}