/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 15.07.2020
 * @description Business data action creators, to manage users and companies data
 */

import { MessageTemplateNotifyDialogMode } from '../../pages/business/messages/types';
import { EmailHistoryRequest } from '../../server/AVTService/TypeLibrary/Email/EmailHistoryRequest';
import { EmailMessageHistoryEntry } from '../../server/AVTService/TypeLibrary/Email/EmailMessageHistoryEntry';
import { MessageTemplateInfo } from '../../server/AVTService/TypeLibrary/MessageTemplates/MessageTemplateInfo';
import { CompanyInfo } from '../../server/GEOvis3/Model/Company/CompanyInfo';
import { GeovisUserTableInfo } from '../../server/GEOvis3/Model/User/GeovisUserTableInfo';
import { ProjectBusinessInfo } from '../../server/ProjectBusinessInfo';
import {
    GEOVIS_BUSINESS_COMPANIES,
    GEOVIS_BUSINESS_COMPANIES_DATA,
    GEOVIS_BUSINESS_COMPANIES_DATA_ERROR,
    GEOVIS_BUSINESS_HIDE_FAILED_PROJECTS_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_CHANGE_TAB_NAME,
    GEOVIS_BUSINESS_MESSAGE_HISTORY,
    GEOVIS_BUSINESS_MESSAGE_HISTORY_ERROR,
    GEOVIS_BUSINESS_MESSAGE_HISTORY_FILTER_CHANGED,
    GEOVIS_BUSINESS_MESSAGE_HISTORY_HIDE_ITEM,
    GEOVIS_BUSINESS_MESSAGE_HISTORY_LOADED,
    GEOVIS_BUSINESS_MESSAGE_HISTORY_SHOW_ITEM,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATE_HIDE_NOTIFICATION_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_SHARE_BETWEEN,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_UNSHARE_FOR,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATE_SHOW_NOTIFICATION_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ADD_OR_UPDATE,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DATA,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DELETE,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ERROR,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_HIDE_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_DELETE_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_SHARE_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_UNSHARE_DIALOG,
    GEOVIS_BUSINESS_PROJECTS,
    GEOVIS_BUSINESS_PROJECTS_DATA,
    GEOVIS_BUSINESS_PROJECTS_DATA_ERROR,
    GEOVIS_BUSINESS_REFRESH_STATE,
    GEOVIS_BUSINESS_REMOVE_USER,
    GEOVIS_BUSINESS_UPDATE_PROJECTS_PROPERTY,
    GEOVIS_BUSINESS_USERS,
    GEOVIS_BUSINESS_USERS_CREATE_OR_UPDATE,
    GEOVIS_BUSINESS_USERS_DATA,
    GEOVIS_BUSINESS_USERS_DATA_ERROR,
    GEOVIS_BUSINESS_USERS_SET_DIRTY_STATE,
} from '../actions/businessDataActions';
import { IGeovisBusinessDataAction } from '../businessData.types';

/**
 * Refresh components, which depends of "businessState"
 */
export const geovisBusinessRefreshState = (): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_REFRESH_STATE
});

//#region Users
/**
 * Set users storage to state "isLoading"
 */
export const geovisBusinessUsers = (): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_USERS
});

/**
 * Set loaded users to storage and set it as "isLoaded"
 * @param users list of users
 */
export const geovisBusinessUsersData = (users: GeovisUserTableInfo[]): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_USERS_DATA, users
});

/**
 * Set users store in error state
 * @param errorDescription 
 */
export const geovisBusinessUsersDataError = (errorDescription: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_USERS_DATA_ERROR, errorDescription
});

/**
 * Create or update exists users info in the business storage
 * @param user
 */
export const geovisBusinessUsersCreateOrUpdate = (...users: GeovisUserTableInfo[]): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_USERS_CREATE_OR_UPDATE, users
});

/**
 * Remove user in the storage
 * @param userId 
 */
export const geovisBusinessRemoveUser = (userId: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_REMOVE_USER, userId
})

export const geovisBusinessUsersSetDirtyState = () => ({
    type: GEOVIS_BUSINESS_USERS_SET_DIRTY_STATE
})

//#endregion

//#region Companies

/**
 * Set store to loading companies
 */
export const geovisBusinessCompanies = (): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_COMPANIES
})

/**
 * Set store companies data
 * @param companies 
 */
export const geovisBusinessCompaniesData = (companies: CompanyInfo[]): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_COMPANIES_DATA, companies
})

/**
 * Set store to loading error state
 * @param errorDescription 
 */
export const geovisBusinessCompaniesDataError = (errorDescription: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_COMPANIES_DATA_ERROR, errorDescription
})

//#endregion

//#region Projects

/**
 * Set business projects to loading state
 */
export const geovisBusinessProjects = (): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_PROJECTS
});

/**
 * Set business projects to store
 * @param projects 
 */
export const geovisBusinessProjectsData = (projects: ProjectBusinessInfo[]): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_PROJECTS_DATA, projects
});

/**
 * Set business projects store to error state
 * @param errorDescription 
 */
export const geovisBusinessProjectsDataError = (errorDescription: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_PROJECTS_DATA_ERROR, errorDescription
});

/**
 * Update one property of many projects in the business storage
 * @param projectIds 
 * @param propertyName 
 * @param value 
 */
export const geovisBusinessUpdateProjectsProperty = (projectIds: number[], propertyName: keyof ProjectBusinessInfo, value: any, failedProjectIds: number[] = []): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_UPDATE_PROJECTS_PROPERTY, projectIds, projectPropertyName: propertyName, value, failedProjectIds
})

/**
 * Hide failed projects dialog
 */
export const geovisBusinessHideFailedProjectsDialog = (): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_HIDE_FAILED_PROJECTS_DIALOG
})

//#endregion

//#region  Message templates

/**
 * Set message template storage to loading state (and clean up the storage)
 */
export const geovisBusinessMessageTemplatesInfo = (): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO
});

/**
 * Set message templates storage to error state
 * @param errorDescription 
 */
export const geovisBusinessMessageTemplatesInfoDataError = (errorDescription: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ERROR, errorDescription
});

/**
 * Set message templates info records to storage
 * @param templatesInfo 
 */
export const geovisBusinessMessageTemplatesInfoData = (templatesInfo: MessageTemplateInfo[]): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DATA, templatesInfo
});

/**
 * Add or update message templates info in the storage
 * @param templateInfo 
 */
export const geovisBusinessMessageTemplatesInfoAddOrUpdate = (templateInfo: MessageTemplateInfo): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ADD_OR_UPDATE, templatesInfo: [templateInfo]
});

/**
 * Delete message templates info
 * @param templateId 
 */
export const geovisBusinessMessageTemplatesInfoDelete = (templateId: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DELETE, stringProperties: [templateId]
});

/**
 * Show delete message template dialog
 * @param templateId 
 */
export const geovisBusinessMessageTemplatesInfoShowDeleteDialog = (templateId: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_DELETE_DIALOG, stringProperty: templateId
});

/**
 * Hide message template dialogs (all)
 */
export const geovisBusinessMessageTemplatesInfoHideDialog = (): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_HIDE_DIALOG
});

/**
 * Show unshare message template dialog
 * @param templateId
 */
export const geovisBusinessMessageTemplatesInfoShowUnshareDialog = (templateId: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_UNSHARE_DIALOG, stringProperty: templateId
});

/**
 * Show unshare dialog message 
 * @param templateId 
 */
export const geovisBusinessMessageTemplatesInfoShowShareDialog = (templateId: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_SHARE_DIALOG, stringProperty: templateId
});

/**
 * Add user ids to list of shared users of the template
 * @param templateId 
 * @param userIds 
 */
export const geovisBusinessMessageTemplatesInfoShareBetweenUsers = (templateId: string, userIds: string[]): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_SHARE_BETWEEN, stringProperties: userIds, stringProperty: templateId
});

/**
 * Remove users from shared users of the template
 * @param templateId 
 * @param userIds 
 */
export const geovisBusinessMessageTemplatesInfoUnshareForUsers = (templateId: string, userIds: string[]): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_UNSHARE_FOR, stringProperties: userIds, stringProperty: templateId
});

/**
 * Show message template notification dialog
 * @param templateId 
 * @param dialogMode 
 */
export const geovisBusinessMessageTemplateShowNotificationDialog = (templateId: string, dialogMode: MessageTemplateNotifyDialogMode): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATE_SHOW_NOTIFICATION_DIALOG, stringProperty: templateId, notificationDialogMode: dialogMode
});

/**
 * Hide message template notification dialog
 */
export const geovisBusinessMessageTemplateHideNotificationDialog = (): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_TEMPLATE_HIDE_NOTIFICATION_DIALOG
});
/**
 * Change tab name
 * @param tabName 
 * @returns 
 */
export const geovisBusinessMessageChangeTabName = (tabName: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_CHANGE_TAB_NAME, stringProperty: tabName
});


export const geovisBusinessMessageHistory = (): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_HISTORY
});

export const geovisBusinessMessageHistoryLoaded = (data: EmailMessageHistoryEntry[], countPages: number): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_HISTORY_LOADED, messageHistoryData: data, numberProperty: countPages
});

export const geovisBusinessMessageHistoryError = (error: string): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_HISTORY_ERROR, stringProperty: error
});

export const geovisBusinessMessageHistoryShowItem = (entry: EmailMessageHistoryEntry): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_HISTORY_SHOW_ITEM, messageHistoryEntry: entry
});

export const geovisBusinessMessageHistoryHideItem = (): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_HISTORY_HIDE_ITEM
});

export const geovisBusinessMessageHistoryFilterChanged = (filter: EmailHistoryRequest): IGeovisBusinessDataAction => ({
    type: GEOVIS_BUSINESS_MESSAGE_HISTORY_FILTER_CHANGED, historyFilter: filter
});
//#endregion