//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { LocalMapObjectContent } from './LocalMapObjectContent';
import { GeoLocation } from './GeoLocation';
import { LocalMapObjectTextDirection } from './AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectTextDirection';

export class LocalMapObjectText extends LocalMapObjectContent
{
	public Color: string;
	public TextOnTheMap: string;
	public FontSize: number;
	public RotationAngle: number;
	public Location: GeoLocation;
	public TextDirection: LocalMapObjectTextDirection;
}
