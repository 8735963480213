/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 22.09.2020
 * @description The table tool methods to create lines
 */

import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { InsertedImageProperties } from '@atlaskit/editor-common/provider-factory';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play';
import Tooltip from '@atlaskit/tooltip';
import React from 'react';
import { Link } from 'react-router-dom';
import { IDynamicTableRow } from '../../../components/types';
import Routes from '../../../helpers/Routes';
import { mapToListOfElements } from '../../../helpers/StorageHelper';
import { t } from '../../../i18n';
import { MessageTemplateAttachmentType } from '../../../server/AVTService/TypeLibrary/MessageTemplates/MessageTemplateAttachmentType';
import { DataActionResponse } from '../../../server/DataActionResponse';
import ServerRoutesGen from '../../../server/Routes/ServerRoutesGen';
import AuthService from '../../../services/AuthService';
import { IBusinessMessagesStorage, IBusinessUsersStorage } from '../../../store/businessData.types';
import { MessageTemplateAuthor } from './MessageTemplateAuthor';
import { IProcessDataTransferParam, MessageTemplateNotifyDialogMode } from './types';

/**
 * Create the messages templates table head
 */
export const createMessageTemplatesTableHead = (): IDynamicTableRow => ({
    key: 'message_templates_table_head',
    cells: [{
        key: 'notify',
        content: t("Notify"),
        width: '70px'
    }, {
        key: 'subject',
        content: t("Subject")
    }, {
        key: 'author',
        content: t("Author")
    }, {
        key: 'actions',
        content: '',
        width: '50px'
    }]
});

export enum TemplateActionEnum {
    Delete = 'delete',
    Share = 'share',
    RemoveLinkOf = 'removeLinkOf',
    RemoveLink = 'removeLink',
    NotifyAllUsers = 'notify_all_users',
    NotifyAllCompanyAdmins = 'notify_all_company_admins',
    NotifyAllUsersOfProject = 'notify_all_users_of_projects',
    NotifyAllAdminsOfProject = 'notify_all_admins_of_project',
    NotifyAllUsersOfCompany = 'notify_all_users_of_company',
    NotifyAllCompanyAdminsOfCompany = 'notify_all_company_admins_of_company',
    NotifyCustomSelectedUsers = 'notify_custom_selected_users',
    NotifyProjectAdminsAndViewers = 'notify_project_admins_and_viewers'
}

/**
 * convert the template action to message template notification dialog mode
 * @param action 
 */
export const templateActionToNotifyDialogMode = (action: TemplateActionEnum): MessageTemplateNotifyDialogMode => {
    switch (action) {
        case TemplateActionEnum.NotifyAllUsers: return MessageTemplateNotifyDialogMode.AllUsers;
        case TemplateActionEnum.NotifyAllCompanyAdmins: return MessageTemplateNotifyDialogMode.AllCompanyAdmins;
        case TemplateActionEnum.NotifyAllUsersOfProject: return MessageTemplateNotifyDialogMode.AllUsersOfProject;
        case TemplateActionEnum.NotifyAllAdminsOfProject: return MessageTemplateNotifyDialogMode.AllAdminsOfProject;
        case TemplateActionEnum.NotifyAllUsersOfCompany: return MessageTemplateNotifyDialogMode.AllUsersOfCompany;
        case TemplateActionEnum.NotifyProjectAdminsAndViewers: return MessageTemplateNotifyDialogMode.AllProjectAdminsAndViewers;
        case TemplateActionEnum.NotifyAllCompanyAdminsOfCompany: return MessageTemplateNotifyDialogMode.AllCompanyAdminsOfCompany;
        case TemplateActionEnum.NotifyCustomSelectedUsers: return MessageTemplateNotifyDialogMode.CustomSelection;

        default:
            throw new Error(`Message template action ${action} not supported`);
    }
}

export type TemplateAction = (action: TemplateActionEnum, templateId: string) => void;

const onTemplateActionHandler = (templateAction: TemplateActionEnum, templateId: string, action: TemplateAction) => () => action(templateAction, templateId);

/**
 * create the messages templates table rows
 * @param storage IBusinessMessagesStorage
 */
export const createMessageTemplatesTableRows = ({ templatesStore: { templates } }: IBusinessMessagesStorage, usersStore: IBusinessUsersStorage, action: TemplateAction): IDynamicTableRow[] => mapToListOfElements(templates).map<IDynamicTableRow>(template => {
    return {
        key: `template-row-${template.Id}`,
        cells: [{
            key: 'notify',
            content: (
                <DropdownMenu
                    triggerType="button"

                    triggerButtonProps={{
                        style: { cursor: "pointer" },
                        appearance: "subtle",
                        iconBefore: <VidPlayIcon label="notify" />
                    }}>
                    <DropdownItemGroup >
                        <DropdownItem
                            isCompact={true}
                            shouldAllowMultiline={true}
                            onClick={onTemplateActionHandler(TemplateActionEnum.NotifyCustomSelectedUsers, template.Id, action)}>
                            {t("Custom selection of users")}
                        </DropdownItem>
                        {AuthService.hasUserTypeAsAdmin() &&
                            <DropdownItem
                                shouldAllowMultiline={true}
                                isCompact={true}
                                onClick={onTemplateActionHandler(TemplateActionEnum.NotifyAllUsers, template.Id, action)}>
                                {t("All users")}
                            </DropdownItem>
                        }
                        {AuthService.hasUserTypeAsAdmin() &&
                            <DropdownItem
                                shouldAllowMultiline={true}
                                isCompact={true}
                                onClick={onTemplateActionHandler(TemplateActionEnum.NotifyAllCompanyAdmins, template.Id, action)}>
                                {t("All company admins")}
                            </DropdownItem>
                        }
                        <DropdownItem
                            shouldAllowMultiline={true}
                            isCompact={true}
                            onClick={onTemplateActionHandler(TemplateActionEnum.NotifyAllUsersOfProject, template.Id, action)}>
                            {t("All users of a certain project")}
                        </DropdownItem>
                        <DropdownItem
                            shouldAllowMultiline={true}
                            isCompact={true}
                            onClick={onTemplateActionHandler(TemplateActionEnum.NotifyAllAdminsOfProject, template.Id, action)}>
                            {t("All project admins of a certain project")}
                        </DropdownItem>
                        <DropdownItem
                            isCompact={true}
                            shouldAllowMultiline={true}
                            onClick={onTemplateActionHandler(TemplateActionEnum.NotifyProjectAdminsAndViewers, template.Id, action)}>
                            {t("All project admins and project viewers of a certain project")}
                        </DropdownItem>
                        <DropdownItem
                            shouldAllowMultiline={true}
                            isCompact={true}
                            onClick={onTemplateActionHandler(TemplateActionEnum.NotifyAllUsersOfCompany, template.Id, action)}>
                            {t("All users of a specific company")}
                        </DropdownItem>
                        {AuthService.hasUserTypeAsAdmin() &&
                            <DropdownItem shouldAllowMultiline={true} isCompact={true} onClick={onTemplateActionHandler(TemplateActionEnum.NotifyAllCompanyAdminsOfCompany, template.Id, action)}>
                                {t("All company admins of a specific company")}
                            </DropdownItem>
                        }
                    </DropdownItemGroup>
                </DropdownMenu>
            )
        }, {
            key: 'subject',
            content: (
                <React.Fragment>
                    <Link to={Routes.messageTemplate.patch(template.Id).path}>{template.Subject}</Link>
                </React.Fragment>
            )
        }, {
            key: 'author',
            content: (
                <MessageTemplateAuthor
                    storage={usersStore}
                    userId={template.AuthorId}
                />
            )
        }, {
            key: 'actions',
            content: (
                <React.Fragment>
                    <Tooltip content={t("Delete")}>
                        <Button
                            appearance="subtle-link"
                            iconBefore={<TrashIcon label="delete" />}
                            onClick={onTemplateActionHandler(TemplateActionEnum.Delete, template.Id, action)} />
                    </Tooltip>
                </React.Fragment>
            )
        }]
    }
});

export const processDataTransferFunc = ({ server, templateInfo, onAddAttachment, onUploadFailed }: IProcessDataTransferParam) =>
    async (dt: DataTransfer, insertImageFn: (props: InsertedImageProperties) => void) => {
        //
        if (dt.files.length === 0 && dt.items.length === 0) {
            return;
        }

        const MaxSize = 20 * 1024 * 1024; // bytes = 20 mb

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < dt.items.length; index++) {

            const item = dt.items[index];

            if (item.kind === "file") {
                const file = item.getAsFile();
                if (!file) {
                    continue;
                }

                if (file.size > MaxSize) {
                    onUploadFailed(templateInfo.Id, file.name, 'You cannot upload files more that 20 Mb')
                    continue;
                }

                let fileId = '';

                // 1 step: upload file to reserved stream.
                try {
                    const url = ServerRoutesGen.MessageTemplates.UploadFile.patch(templateInfo.TemplateHashId).path;
                    const response = await server.post<DataActionResponse<string>>(url, file);

                    if (!response.Success) {
                        onUploadFailed(templateInfo.Id, file.name, 'Cannot upload file');
                        continue;
                    }

                    fileId = response.Data;
                }
                catch (error) {
                    onUploadFailed(templateInfo.Id, file.name, 'An error has occurred during uploading file stream');
                    return;
                }

                if (file.type === "image/png") {
                    onAddAttachment(templateInfo.Id, {
                        AttachmentType: MessageTemplateAttachmentType.Image,
                        FileId: fileId,
                        IsDirty: true,
                        Name: file.name,
                        IsDeleted: false
                    });

                    const src = server.getEndpointServerUrl(ServerRoutesGen.MessageTemplatesStream.Image.patch(fileId).path);

                    insertImageFn({
                        src,
                        alt: 'Image',
                        title: 'Image'
                    });

                    return;
                }

                // attach file
                onAddAttachment(templateInfo.Id, {
                    AttachmentType: MessageTemplateAttachmentType.File,
                    FileId: fileId,
                    IsDirty: true,
                    Name: file.name,
                    IsDeleted: false
                });
            }
        }
    }
