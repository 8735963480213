import { SyntheticEvent, useState } from "react";
import { debounce } from "lodash";
import Textfield from "@atlaskit/textfield";
import Tooltip from "@atlaskit/tooltip";
import { t } from "i18next";
import { Country, getCountryCodeByCountry } from "../../server/AVTService/TypeLibrary/Sms/Country";
import { drawFlag } from "./tools";
import { GeovisSelectCountryDialog } from "./GeovisSelectCountryDialog";
import { verifyPhoneNumber } from "../../pages/edit/alarms/alarmEdit/AlarmActionsEdit/tools";

interface IComponentProps {
    number: string;
    countryCode: Country;
    onNumberChanged: (unpNumber: string) => void;
    onCountryCodeChanged: (updCountryCode: Country) => void;
    isDisabled: boolean;
}

export const GeovisPhoneNumber = ({
    countryCode,
    number,
    onCountryCodeChanged,
    onNumberChanged,
    isDisabled
}: IComponentProps) => {

    const [showDialog, setShowDialog] = useState<boolean>(false);

    const onNumberChangedHandlerDebounced = debounce((value: string) => onNumberChanged(value), 300);

    const onNumberChangedHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onNumberChangedHandlerDebounced(event.currentTarget.value);
    }

    const getCountryCode = (): string => {
        return `+${getCountryCodeByCountry(countryCode)}`;
    }

    const onShowCountryDialog = () => {
        if (!isDisabled) {
            setShowDialog(true);
        }
    }

    const onHideCountryDialog = () => {
        setShowDialog(false);
    }

    const onSelectCountry = (country: Country) => {
        onCountryCodeChanged(country);
        setShowDialog(false);
    }

    const checkNumberIsInvalid = (): boolean => {
        return !verifyPhoneNumber(number, countryCode, false);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', gap: "5px", justifyContent: 'center', alignItems: 'center' }}>

            <Tooltip content={t("Click to select country code")}>
                <div onClick={onShowCountryDialog} style={{ display: 'flex', background: isDisabled ? '#fafbfc' : '#ffffff', flexDirection: 'row', flexWrap: 'nowrap', gap: "5px", cursor: 'pointer', boxShadow: isDisabled ? 'none' : '0 0 5px grey', borderRadius: '5px', padding: '4px', justifyContent: 'center', alignItems: 'center' }}>
                    {/* Flag */}
                    <div style={{ borderRadius: '5px' }}>
                        {drawFlag(countryCode)}
                    </div>
                    {/* Country code */}
                    <span style={{ fontWeight: 'bold' }}>{getCountryCode()}</span>
                </div>
            </Tooltip>

            <Textfield
                defaultValue={number}
                isCompact={true}
                onChange={onNumberChangedHandler}
                isDisabled={isDisabled}
                isInvalid={checkNumberIsInvalid()}
            />
            <GeovisSelectCountryDialog
                currentCountry={countryCode}
                onClose={onHideCountryDialog}
                onSelect={onSelectCountry}
                showDialog={showDialog}
            />
        </div>
    )
}