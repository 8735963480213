/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 09.06.2023
 * @description Map section local map objects render
 * It renders objects in Read-Only mode for Report
 */

import { Fragment } from "react";
import { LocalMapObject } from "../../../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject"
import { MapSectionLocalMapObjectsRenderHost } from "./MapSectionLocalMapObjectsRenderHost";


interface IComponentProps {
    localMapObjects: LocalMapObject[];
}

export const MapSectionLocalMapObjectsMapRender = ({ localMapObjects }: IComponentProps) => (
    <Fragment>
        {localMapObjects.map(lmo => (
            <MapSectionLocalMapObjectsRenderHost
                key={lmo.Id}
                lmo={lmo}
            />
        ))}
    </Fragment>
)