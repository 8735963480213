/**
 * @author Ivan Kozlov <ik@ieskr.ru>
 * @created 23.05.2022
 * @description Render for report of selected sensors
 */

import React from "react";
import { connect } from "react-redux";
import { GeovisReportElementInfo } from "../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo";
import { IGeovisReportSettings } from "../../../../store/projectReport.types";
import { IGeovisStoreState } from "../../../../store/store.types";
import { ChartElementRender } from "./ChartElementRender";
import GeovisChartRenderDataLayer from "./charts/GeovisChartRenderDataLayer";
import FooterRender from "./FooterRender";
import HeaderRender from "./header/HeaderRender";
import HeaderRenderDataLayer from "./header/HeaderRender.DataLayer";

interface IComponentStateProps {
    reportSettings: IGeovisReportSettings;
}

interface IComponentOwnProps {
    userId?: string;
    userToken?: string;
    isPrinting: boolean;
}

interface IComponentProps extends IComponentStateProps, IComponentOwnProps {

}

const getElementRenderDataLayer = (element: GeovisReportElementInfo, pageNum: number, isPrinting: boolean, userId?: string, userToken?: string) => {
    return (
        <GeovisChartRenderDataLayer
            key={`element-render-data-layer-${element.ElementType}-${element.Id}`}
            reportId={0}
            userId={userId}
            userToken={userToken}
            isPrinting={isPrinting}
            pageNum={pageNum}
            isVibrationEventChart={false}
            chartId={element.Id}
            isDefault={true}
            eventInfo={undefined}
        />
    )
}

const DefaultReportsRender = ({ reportSettings, userId, userToken, isPrinting }: IComponentProps) => (
    <React.Fragment>
        {reportSettings.detailedReportInfo.Pages.map((page, index, array) => (
            <div key={`geovis-charts-report-page-${index}`}
                className="reportContentContainer" >
                <div className="reportPageContainer" >
                    <div>
                        <HeaderRender isDefaultReport={true} />
                        <HeaderRenderDataLayer
                            reportId={0}
                            userId={userId}
                            userToken={userToken}
                        />
                    </div>
                    <div style={{ height: '100%' }}>
                        {page.Elements.map((element, elementIndex) => (
                            <React.Fragment key={`div-${elementIndex}`}>
                                {getElementRenderDataLayer(element, 0, isPrinting, userId, userToken)}
                                <ChartElementRender
                                    rootContainerWidth={0}
                                    pageNum={0}
                                    elementIndexOnPage={elementIndex}
                                    isPrinting={isPrinting}
                                    reportId={0}
                                    isDefault={true}
                                    userId={userId}
                                    userToken={userToken}
                                    isInGroup={false}
                                    elementInfo={element}
                                    projectId={0}
                                    key={`geovis-chart-${element.Id}-${elementIndex}`}
                                />
                            </React.Fragment>

                        ))}
                    </div>

                    <div>
                        <FooterRender
                            countPages={array.length}
                            pageNumber={index + 1}
                        />
                    </div>
                </div>
            </div>
        ))}
    </React.Fragment>
)

const mapStateToProps = ({ projectReport }: IGeovisStoreState): IComponentStateProps => ({
    reportSettings: projectReport.geovisReportSettings
})

export default connect<IComponentStateProps, never, IComponentOwnProps>(mapStateToProps)(DefaultReportsRender)