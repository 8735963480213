/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 02.06.2022
 * @description Components to show loading information
 */

import Spinner from '@atlaskit/spinner';

interface ILoadingComponentProps {
    text: string
    spinnerTestId?: string;
}

export const ChartLoadingSkeleton = ({ text: loadingText, spinnerTestId }: ILoadingComponentProps) => (
    <div className="flexRowMiddleContainer">
        <div className="skeletonContainer">
            <div style={{ display: "inline-block" }} >
                <Spinner size="xlarge" testId={spinnerTestId} />
            </div>
            {loadingText && (
                <span style={{ marginLeft: '20px' }}>{loadingText}</span>
            )}
        </div>
    </div>
)