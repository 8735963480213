/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 17.10.2022
 * @description User edit password tab content
 */

import { LoadingButton } from '@atlaskit/button';
import { ErrorMessage } from '@atlaskit/form';
import { useState } from 'react';
import { onStringPropertyChangedHandler } from '../../helpers/PropertiesHelper';
import { t } from '../../i18n';
import { GeovisUserProfileChangePasswordModel } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserProfileChangePasswordModel';
import { GeovisUserProfilePasswordModel } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserProfilePasswordModel';
import { GeovisPasswordField } from './GeovisPasswordField';


interface IComponentProps {
    isOwnProfile: boolean;
    inProgress: boolean;

    onSetUserPassword: (passwordInfo: GeovisUserProfileChangePasswordModel) => void;
}

export const UserProfileEditorPasswordsTab = ({ onSetUserPassword, isOwnProfile, inProgress }: IComponentProps) => {

    const [state, setState] = useState<GeovisUserProfileChangePasswordModel>({
        ConfirmPassword: '',
        CurrentPassword: '',
        Password: ''
    });

    const onPasswordPropertyChangedMiddleHandler = (propertyName: keyof GeovisUserProfileChangePasswordModel, value: string) => {
        setState({
            ...state,
            [propertyName]: value
        });
    }

    let errorMessage = '';

    if (state.Password || state.ConfirmPassword) {

        if (state.Password !== state.ConfirmPassword) {
            errorMessage = t("Passwords mismatch");
        }
    }

    /**
     * Execute action to set/change the user password
     */
    const onSetUserPasswordClick = () => {
        if (!errorMessage || errorMessage.length === 0) {
            onSetUserPassword(state);
        }
    }

    return (
        <div className='flexCellContainer_g1'>
            {isOwnProfile && (
                <GeovisPasswordField
                    name="current-password-field"
                    label={t("Current password")}
                    value={state.CurrentPassword}
                    onFieldChanged={onStringPropertyChangedHandler<GeovisUserProfileChangePasswordModel>("CurrentPassword", onPasswordPropertyChangedMiddleHandler)}
                />
            )}

            <GeovisPasswordField
                name="new-password-field"
                label={t("New password")}
                value={state.Password}
                onFieldChanged={onStringPropertyChangedHandler<GeovisUserProfilePasswordModel>("Password", onPasswordPropertyChangedMiddleHandler)}
            />

            <GeovisPasswordField
                name="new-password-confirm-field"
                label={t("Confirm new password")}
                value={state.ConfirmPassword}
                onFieldChanged={onStringPropertyChangedHandler<GeovisUserProfilePasswordModel>("ConfirmPassword", onPasswordPropertyChangedMiddleHandler)}
            />

            {errorMessage && (
                <ErrorMessage>{errorMessage}</ErrorMessage>
            )}

            <div className='flexRowContainer' style={{ marginTop: '2em' }}>
                <LoadingButton
                    appearance='primary'
                    isLoading={inProgress}
                    isDisabled={!(!errorMessage) || errorMessage.length > 0}
                    onClick={onSetUserPasswordClick}>
                    {t("Set password")}
                </LoadingButton>
            </div>

        </div>
    )
}