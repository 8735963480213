/**
 * There are functions to draw a project icon on the projects overview page
 */

import { MapSensorObject } from '../../helpers/MapHelper';
import { getAlarmedSensorsColors } from '../../helpers/SensorHelper';
import { ProjectAutomaticState } from '../../server/AVTService/TypeLibrary/Common/ProjectAutomaticState';
import { ProjectManualState } from '../../server/AVTService/TypeLibrary/Common/ProjectManualState';
import { SensorMapTextOrientation } from '../../server/AVTService/TypeLibrary/Common/SensorMapTextOrientation';
import { GeovisUserToProjectRole } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserToProjectRole';
import { SensorCategory } from "../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { SensorSymbol } from '../../server/AVTService/TypeLibrary/Sensors/SensorSymbol';
import { ChainInfo } from '../../server/ChainInfo';
import { ProjectOverviewModel } from '../../server/GEOvis3/Model/ProjectsOverview/ProjectOverviewModel';
import { SensorInfo } from '../../server/GEOvis3/Model/SensorInfo';
import { SensorBase } from '../../server/SensorBase';
import AuthService from '../../services/AuthService';
import {
    ICreateSensorStateIconOptions
} from "../types";
import {
    createMultipleSensorsIcon,
    createSingleSensorIcon,
    GeovisIcon,
    projectStateMarkerIconExt
} from "./IconActions";
import {
    createMultipleSensorsIconProps,
    createSensorIconProps,
    createSensorSymbolIconProps,
    createTextIconProps,
    defaultProjectOverviewAlarmFlagsIconProps as defaultProjectOverviewAlarmFlagsIconProps,
    defaultProjectsOverviewSvgIconProps,
    getDefaultSensorIconProps,
    getDefaultSensorSymbolIconProps,
    IProjectsOverviewIconMapIconProps,
    ISVGIconProps,
    mergeSensorsIconProps,
    ProjectStateColorFinished,
    ProjectStateColorGray,
    ProjectStateColorGreen,
    ProjectStateColorOrange,
    ProjectStateColorRed,
    ProjectStateColorViolet,
    SensorStateColorWarning
} from "./types";

export const getProjectAlarmsAsSVGIcons = (overview: ProjectOverviewModel): ISVGIconProps[] => {

    const { BusinessState, CountValueAlarmedSensors, CountFaAlarms, CountWdAlarms } = overview;
    if (BusinessState === ProjectManualState.Finished || BusinessState === ProjectManualState.OnHold) {
        return [];
    }

    if (CountValueAlarmedSensors === 0 && CountFaAlarms === 0 && CountWdAlarms === 0) {
        return [{
            ...defaultProjectOverviewAlarmFlagsIconProps,
            fillColors: [ProjectStateColorGreen]
        }];
    }

    return getProjectAlarmsAsSVGIconsCommon(overview);
}

const getProjectAlarmsAsSVGIconsCommon = ({ CountWdAlarms, CountSensorsInWarningState, ValueAlarmColors }: ProjectOverviewModel): ISVGIconProps[] => {

    const colors: string[] = [...ValueAlarmColors];

    if (CountWdAlarms > 0) {
        colors.push(ProjectStateColorViolet);
    }

    if (CountSensorsInWarningState > 0) {
        colors.push(SensorStateColorWarning);
    }

    const svgIconProps = colors.map<ISVGIconProps>((color, index) => ({
        ...defaultProjectOverviewAlarmFlagsIconProps,
        fillColors: [color],
        weight: index === 0 ? 1 : 0.5
    }));

    // if no alarms, then everything okay and draw the green flag
    if (svgIconProps.length === 0) {
        const greenFlagProps: ISVGIconProps = {
            ...defaultProjectOverviewAlarmFlagsIconProps,
            fillColors: [ProjectStateColorGreen]
        };

        svgIconProps.push(greenFlagProps);
    }

    return svgIconProps;
}

export const getProjectStateColor = (overview: ProjectOverviewModel): string[] => {
    let resultColors: string[] = [];
    if (overview.States.includes(ProjectAutomaticState.Ok)) {
        resultColors.push(ProjectStateColorGreen);
    }
    if (overview.States.includes(ProjectAutomaticState.FunctionalAlarm)) {
        resultColors.push(ProjectStateColorOrange);
    }
    if (overview.States.includes(ProjectAutomaticState.InterventionRequired)) {
        resultColors.push(ProjectStateColorRed);
    }
    if (overview.BusinessState === ProjectManualState.OnHold) {
        resultColors = [];
        resultColors.push(ProjectStateColorGray);
    }
    if (overview.BusinessState === ProjectManualState.Finished) {
        resultColors = [];
        resultColors.push(ProjectStateColorFinished);
    }
    return resultColors;
}

export const getProjectMainStateColor = (projectInfo: ProjectOverviewModel, allowWarnings: boolean): string => {
    switch (projectInfo.BusinessState) {
        case ProjectManualState.OnHold:
            return ProjectStateColorGray;
        case ProjectManualState.Finished:
            return ProjectStateColorFinished;
        case ProjectManualState.Running:
            if (projectInfo.States.includes(ProjectAutomaticState.InterventionRequired)) {
                return ProjectStateColorRed;
            }
            if (projectInfo.States.includes(ProjectAutomaticState.FunctionalAlarm) && allowWarnings) {
                return ProjectStateColorOrange;
            }
    }
    return "";
}

export const getProjectStateIcon = (projectInfo: ProjectOverviewModel, withProjectName?: boolean) => {
    return AuthService.getProjectViewerMode(projectInfo.Id) === GeovisUserToProjectRole.Admin
        ? getProjectStateIconForAdmin(projectInfo, withProjectName)
        : getProjectStateIconForViewer(projectInfo, withProjectName);
}

const getProjectStateIconForViewer = (projectInfo: ProjectOverviewModel, withProjectName?: boolean) => {
    const props: IProjectsOverviewIconMapIconProps = {
        ...defaultProjectsOverviewSvgIconProps,
        circle: {
            ...defaultProjectsOverviewSvgIconProps.circle,
            iconText: withProjectName ? projectInfo.Name : false,
            fillColors: getProjectStateColor(projectInfo)
        },
        flags: getProjectAlarmsAsSVGIcons(projectInfo)
    };

    return projectStateMarkerIconExt(props);
}

const getProjectStateIconForAdmin = (projectInfo: ProjectOverviewModel, withProjectName?: boolean) => {
    const props: IProjectsOverviewIconMapIconProps = {
        ...defaultProjectsOverviewSvgIconProps,
        circle: {
            ...defaultProjectsOverviewSvgIconProps.circle,
            iconText: withProjectName ? projectInfo.Name : false,
            fillColors: getProjectStateColor(projectInfo)
        },
        flags: getProjectAlarmsAsSVGIcons(projectInfo)
    };

    return projectStateMarkerIconExt(props);
}

const isMultipleTypes = <TPoint extends MapSensorObject>(points: TPoint[]): boolean => {
    if (points.length < 2) {
        return false;
    }

    let firstType: SensorCategory | undefined;

    for (const point of points) {
        if (firstType === undefined) {
            firstType = point.PhysicalType;
            continue;
        }

        if (firstType !== point.PhysicalType) {
            return true;
        }
    }

    return false;
}

/**
 * Create sensors group icon
 * @param group 
 * @param options 
 */
export const createClusterIconOfSensors = <TPoint extends MapSensorObject>(points: TPoint[], options?: ICreateSensorStateIconOptions): GeovisIcon => {
    const forceSingleMarkerMode = options ? options.forceSingleMarkerMode === true : false;
    if (!forceSingleMarkerMode && isMultipleTypes(points)) {
        return createIconOfMultipleSensorTypes(points, options);
    }

    return createIconOfSingleSensorType(points, options);
}

const createSensorSymbolIconExtProps = <T extends MapSensorObject>(sensor: T, options?: ICreateSensorStateIconOptions): ISVGIconProps => {
    const colors = options && options.useSensorColor
        ? [sensor.Color]
        : getAlarmedSensorsColors([sensor]);

    return ({
        ...createSensorSymbolIconProps(colors.length > 0 ? colors : [sensor.Color], sensor.Symbol),
        ...options,
        iconText: options && options.showName ? sensor.Name : "",
        textOrientation: options && options.mandatoryOrientation ? options.mandatoryOrientation : options && options.useMapTextOrientation ? sensor.MapTextOrientation : false,
        fontColor: options && options.useSensorColorForText ? sensor.Color : 'black'
    })
}

export const getSensorIconProps = (sensor: SensorBase, options?: ICreateSensorStateIconOptions) => {
    return options && options.useGeovis4SensorTypeSymbol === false
        ? createSensorSymbolIconExtProps(sensor, options)
        : createSensorIconProps(sensor, options);
}

const getTextIconProps = (id: string, orientation: SensorMapTextOrientation, color: string, text: string, xSize: number, ySize: number, xAnchor: number, yAnchor: number, options?: ICreateSensorStateIconOptions) => {
    const props = createTextIconProps(id, orientation, color, text, xSize, ySize, xAnchor, yAnchor, options);
    return props;
}

/**
 * Create single sensor marker icon
 * @param sensor 
 * @param options 
 */
export const createSingleSensorMarkerIcon = (sensor: SensorInfo, options?: ICreateSensorStateIconOptions): GeovisIcon => {
    const physicalType = getDestinationSensorCategory(sensor, options);

    if (sensor.PhysicalType !== physicalType) {
        sensor = { ...sensor, PhysicalType: physicalType };
    }

    const iconProps = getSensorIconProps(sensor, options);
    return createSingleSensorIcon(iconProps);

}

export const createOnlyTextIcon = (id: string, orientation: SensorMapTextOrientation, color: string, text: string, xSize: number, ySize: number, xAnchor: number, yAnchor: number, options?: ICreateSensorStateIconOptions): GeovisIcon => {
    const iconProps = getTextIconProps(id, orientation, color, text, xSize, ySize, xAnchor, yAnchor, options);
    return createSingleSensorIcon(iconProps);
}

export const createSingleChainMarkerIcon = (chain: ChainInfo, options?: ICreateSensorStateIconOptions): GeovisIcon => {
    const physicalType = getDestinationSensorCategory(chain, options);
    if (chain.PhysicalType !== physicalType) {
        chain = { ...chain, PhysicalType: physicalType };
    }
    const iconProps = getSensorIconProps(chain, options);

    return createSingleSensorIcon(iconProps);
}

/**
 * Create the sensor icon with one type, like stacked
 * @param group 
 * @param options 
 */
const createIconOfSingleSensorType = (points: MapSensorObject[], options?: ICreateSensorStateIconOptions): GeovisIcon => {
    if (points.length < 1) {
        throw Error('createIconOfSingleSensorType: collection of points cannot be empty');
    }

    if (points.length < 2) {
        return createSensorStateIcon(points[0], options);
    }

    const firstPoint = points[0];
    const destinationIconPhysicalType = getDestinationSensorCategory(firstPoint, options);

    const useSensorSymbolProps = options && options.useGeovis4SensorTypeSymbol === false;
    const defaultProps = useSensorSymbolProps ? getDefaultSensorSymbolIconProps(SensorSymbol.Circle) : getDefaultSensorIconProps(destinationIconPhysicalType);

    const iconProps = mergeSensorsIconProps(defaultProps, points, options);
    return createSingleSensorIcon(iconProps);
}

/**
 * Create the sensor icon with multiple sensor types, like "near sensors" in previous implementation
 * @param group 
 * @param options 
 */
const createIconOfMultipleSensorTypes = <TPoint extends MapSensorObject>(points: TPoint[], options?: ICreateSensorStateIconOptions): GeovisIcon => {
    if (points.length < 1) {
        throw Error('createIconOfMultipleSensorTypes: collection of points cannot be empty');
    }

    if (points.length < 2) {
        return createSensorStateIcon(points[0], options);
    }

    const iconProps = createMultipleSensorsIconProps(points, options);

    return createMultipleSensorsIcon(iconProps);
}

/**
 * Create sensor state icon
 * @param sensor 
 * @param options 
 */
export const createSensorStateIcon = (sensor: MapSensorObject, options?: ICreateSensorStateIconOptions): GeovisIcon => {
    const destinationIconPhysicalType = getDestinationSensorCategory(sensor, options);

    if (destinationIconPhysicalType !== sensor.PhysicalType) {
        // check is avoid additional creating of sensor object
        const props = createSensorIconProps({ ...sensor, PhysicalType: destinationIconPhysicalType }, options);
        return createSingleSensorIcon(props);
    }

    const iconProps = options && options.useGeovis4SensorTypeSymbol === false
        ? createSensorSymbolIconExtProps(sensor, options)
        : createSensorIconProps(sensor, options);

    return createSingleSensorIcon(iconProps);
}

const createSensorSymbolIcon = (color: string, symbol: SensorSymbol): GeovisIcon => {
    return createSingleSensorIcon(createSensorSymbolIconProps([color], symbol));
}

/**
 * Create default green sensor icon
 * @param sensorType 
 */
export const createGreenSensorIconOfTypeSvg = (sensorType: SensorCategory): string => {

    const sensor = new SensorInfo();
    sensor.PhysicalType = sensorType;
    sensor.WatchdogEnabled = true;

    const icon = createSensorStateIcon(sensor, { showName: false, noScale: true });

    return icon._createSVG();
}
/**
 * Create icon for sensor symbol
 * @param sensor 
 * @returns 
 */
export const createSensorSymbolSvg = (sensor: SensorInfo, overrideColor?: string): string => {

    const icon = createSensorSymbolIcon(overrideColor ?? sensor.Color, sensor.Symbol);

    return icon._createSVG();
}

/**
 * Create sensor icon and return its SVG
 * @param sensor sensor definition
 * @param options create icon options
 */
export const createSensorIconOfSvg = (sensor: SensorBase, options?: ICreateSensorStateIconOptions) => {
    return createSensorStateIcon(sensor, options)._createSVG();
}

/**
 * Create green sensor icon
 * @param sensorType 
 */
export const createGreenSensorIcon = (sensorType: SensorCategory) => {
    const sensor = new SensorInfo();
    sensor.PhysicalType = sensorType;
    sensor.WatchdogEnabled = true;

    return createSensorStateIcon(sensor, { showName: false, noScale: false });
}

/**
 * Create green sensor info (with defined name, id)
 * @param category 
 * @param id 
 * @param name 
 * @param deviceType is empty, then equal to category
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getGreenSensorInfo = (category: SensorCategory, id?: string, name?: string, deviceType?: SensorCategory) => {
    const sensor = new SensorInfo();
    sensor.PhysicalType = category;
    sensor.Id = id || "";
    sensor.Name = name || "";
    sensor.WatchdogEnabled = true;

    return sensor;
}

const getDestinationSensorCategory = <TPoint extends MapSensorObject>(sensor: TPoint, options?: ICreateSensorStateIconOptions): SensorCategory => {
    const category = sensor.PhysicalType;

    if (!options || !options.replaceIconMap) {
        return category;
    }

    const replacement = options.replaceIconMap.get(category);
    if (!replacement) {
        return category;
    }

    return replacement.ReplaceIconAsType || category;
}