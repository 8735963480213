import AddIcon from '@atlaskit/icon/glyph/add'
import { HeadType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import { Checkbox } from "@atlaskit/checkbox";
import TrashIcon from '@atlaskit/icon/glyph/trash';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import { SyntheticEvent, useState } from "react";
import Button from "@atlaskit/button";
import Tooltip from '@atlaskit/tooltip';
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play';
import { GeovisAlarmModel } from "../../../../server/GEOvis3/Model/Alarms/GeovisAlarmModel";
import { t } from "../../../../i18n";
import { GeovisDynamicTable } from "../../../../components/GeovisDynamicTable";
import { AlarmActionEditDialog } from './AlarmActionsEdit/AlarmActionEditDialog';
import { CompanyInfo } from '../../../../server/GEOvis3/Model/Company/CompanyInfo';
import { GeovisAlarmActionModel } from '../../../../server/AVTService/TypeLibrary/Alarming/GeovisAlarmActionModel';
import { AlarmActionUIType, getAlarmActionUITypeToDescription } from '../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUIType';
import AlarmActionTestDialog from './AlarmActionTestDialog';
import { AlarmActivationBlockModel } from '../../../../server/GEOvis3/Model/Alarms/UsersPresets/AlarmActivationBlockModel';

interface IComponentProps {
    alarm: GeovisAlarmModel;
    onAlarmChanged: (updAlarm: GeovisAlarmModel) => void;
    isReadonly: boolean;
    companies: CompanyInfo[];
    isTemplate: boolean
    onChangeAlarmActivationPossibility: (config: AlarmActivationBlockModel) => void;
}

interface IComponentState {
    currentAction: GeovisAlarmActionModel;
    currentActionIndex: number;
    showEditDialog: boolean;
    isCreateMode: boolean;
    showTestDialog: boolean;
}

export const AlarmEditDialogActionsSettingsTab = ({
    alarm,
    isReadonly,
    onAlarmChanged,
    companies,
    isTemplate,
    onChangeAlarmActivationPossibility
}: IComponentProps) => {

    const getInitAction = (forConfirm: boolean): GeovisAlarmActionModel => ({
        ActionType: AlarmActionUIType.Message,
        IsActive: true,
        ForConfirm: forConfirm,
        Message: "",
        Subject: "",
        ActivityPeriods: [],
        SmsNotificationsStrategy: [],
        EmailNotificationsStrategy: [],
        Alias: "",
        AllowProjectViewerToSwitchOff: false,
        CanBeSwitchedOffSeparately: false,
        SensorboxIds: "",
        ConditionsIds: alarm.Conditions.map(c => c.Id),
        SensorboxNames: '',
        ReceiversPresetId: ''
    })

    const [state, setState] = useState<IComponentState>({
        currentAction: getInitAction(false),
        currentActionIndex: -1,
        isCreateMode: false,
        showEditDialog: false,
        showTestDialog: false
    })

    const onActionChanged = (action: GeovisAlarmActionModel, actionIndex: number) => {
        const updActions = [...alarm.Actions];
        updActions.splice(actionIndex, 1, action);
        onAlarmChanged({ ...alarm, Actions: updActions });
    }

    const onActiveChanged = (action: GeovisAlarmActionModel, actionIndex: number) => (event: SyntheticEvent<HTMLInputElement>) => {
        action.IsActive = event.currentTarget.checked;
        onActionChanged(action, actionIndex);
    }

    const getMessageFromAction = (action: GeovisAlarmActionModel): JSX.Element => {

        let messageText = "";

        switch (action.ActionType) {
            case AlarmActionUIType.Dolphin:
            case AlarmActionUIType.Message: {
                messageText = action.Message ?? "";
                break;
            }
            case AlarmActionUIType.AgmsDigitalOutput: {
                messageText = action.SensorboxNames ?? "";
                break;
            }
            default:
                messageText = "";
                break;
        }

        if (messageText.length <= 80) {
            return (
                <span>{messageText}</span>
            )
        }

        const trimmedText = `${messageText.substring(0, 80)}...`

        return (
            <Tooltip
                content={messageText}
            >
                <span>{trimmedText}</span>
            </Tooltip>
        )
    }

    const getRows = (forConfirm: boolean): RowType[] => {

        const result: RowType[] = [];

        const actionTSState = (action: GeovisAlarmActionModel): string => {

            if (action.ActivityPeriods.length > 0 && action.ActivityPeriods.some(p => p.IsActive)) {
                return t("Restricted");
            }

            return t("Always")
        }

        alarm.Actions.forEach((action, index) => {
            if (action.ForConfirm === forConfirm) {
                result.push({
                    key: `action-${index}`,
                    cells: [{
                        key: 'active',
                        content: (
                            <Checkbox
                                isChecked={action.IsActive}
                                onChange={onActiveChanged(action, index)}
                                isDisabled={isReadonly}
                            />
                        )
                    }, {
                        key: 'type',
                        content: (
                            <span>{getAlarmActionUITypeToDescription(action.ActionType)}</span>
                        )
                    }, {
                        key: 'timeSchedule',
                        content: (
                            <span>{actionTSState(action)}</span>
                        )
                    }, {
                        key: 'message',
                        content: getMessageFromAction(action)
                    }, {
                        key: 'actions',
                        content: (
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
                                <div>
                                    {!isReadonly &&
                                        <Tooltip content={t(`Edit alarm ${action.ForConfirm ? "confirmation" : "action"}`)}>
                                            <Button
                                                iconBefore={<EditFilledIcon label='edit' />}
                                                spacing='compact'
                                                appearance="subtle-link"
                                                onClick={onShowActionEditDialog(action.ForConfirm, action, index)}
                                            />
                                        </Tooltip>
                                    }
                                    {isReadonly &&
                                        <Tooltip content={t(`Watch alarm ${action.ForConfirm ? "confirmation" : "action"}`)}>
                                            <Button
                                                iconBefore={<WatchIcon label='edit' />}
                                                spacing='compact'
                                                appearance="subtle-link"
                                                onClick={onShowActionEditDialog(action.ForConfirm, action, index)}
                                            />
                                        </Tooltip>
                                    }
                                </div>
                                <div>
                                    {!isReadonly && alarm.Conditions.length > 0 &&
                                        <Tooltip content={t(`Test alarm ${action.ForConfirm ? "confirmation" : "action"}`)}>
                                            <Button
                                                iconBefore={<VidPlayIcon label='test' />}
                                                spacing='compact'
                                                appearance="subtle-link"
                                                isDisabled={isReadonly || alarm.Conditions.length === 0}
                                                onClick={onShowTestDialog(action)}
                                            />
                                        </Tooltip>
                                    }
                                    {(isReadonly || alarm.Conditions.length === 0) &&
                                        <Tooltip content={isReadonly ? t(`${action.ForConfirm ? "Confirmation" : "Action"} can not be tested when alarm is active`) : t("No conditions configured")}>
                                            <Button
                                                iconBefore={<VidPlayIcon label='test' primaryColor="#a5adba" />}
                                                spacing='compact'
                                                appearance="subtle-link"
                                            />
                                        </Tooltip>
                                    }
                                </div>
                                <div>
                                    {!isReadonly &&
                                        <Tooltip content={action.ForConfirm ? t("Remove alarm confirmation") : t("Remove alarm action")}>
                                            <Button
                                                iconBefore={<TrashIcon label='remove' />}
                                                spacing='compact'
                                                appearance="subtle-link"
                                                isDisabled={isReadonly}
                                                onClick={onRemoveAlarmAction(index)}
                                            />
                                        </Tooltip>
                                    }
                                    {isReadonly &&
                                        <Tooltip content={t(`Alarm ${action.ForConfirm ? "confirmation" : "action"} can not be removed when alarm is active`)}>
                                            <Button
                                                iconBefore={<TrashIcon label='remove' primaryColor="#a5adba" />}
                                                spacing='compact'
                                                appearance="subtle-link"
                                            />
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                        )
                    }]
                });
            }
        });
        return result;
    }

    const getHead = (): HeadType => {
        return ({
            cells: [{
                key: 'active',
                content: t("Active"),
                width: 10
            }, {
                key: 'type',
                content: t("Type"),
                width: 15
            }, {
                key: 'timeSchedule',
                content: t("Activity Periods"),
                width: 15
            }, {
                key: 'message',
                content: t("Message"),
                width: 35
            }, {
                key: 'actions',
                width: 15
            }]
        })
    }

    const onCloseActionEditDialog = () => {
        setState({
            ...state,
            isCreateMode: false,
            showEditDialog: false,
            currentAction: getInitAction(false),
            currentActionIndex: -1
        })
    }

    const onShowActionEditDialog = (forConfirm: boolean, action?: GeovisAlarmActionModel, actionIndex?: number) => () => {

        setState({
            ...state,
            currentAction: action ? { ...action } : getInitAction(forConfirm),
            currentActionIndex: actionIndex ?? -1,
            isCreateMode: !action,
            showEditDialog: true
        })
    }

    const onSaveAlarmAction = (action: GeovisAlarmActionModel) => {
        const updActions = [...alarm.Actions];
        if (state.currentActionIndex < 0) {
            updActions.push(action);
        }
        else {
            updActions.splice(state.currentActionIndex, 1, action);
        }
        onAlarmChanged({ ...alarm, Actions: updActions });
        onCloseActionEditDialog();
    }

    const onRemoveAlarmAction = (actionIndex: number) => () => {
        const updActions = [...alarm.Actions];
        updActions.splice(actionIndex, 1);
        onAlarmChanged({ ...alarm, Actions: updActions });
    }

    const onShowTestDialog = (alarmAction: GeovisAlarmActionModel) => () => {
        setState({
            ...state,
            showTestDialog: true,
            currentAction: alarmAction
        });
    }

    const onHideTestDialog = () => {
        setState({
            ...state,
            showTestDialog: false
        });
    }

    const onChangeAlarmActivationPossibilityHandler = (config: AlarmActivationBlockModel) => {
        if (config.CanNotBeActivated.includes(alarm.Id)) {
            onAlarmChanged({ ...alarm, CanBeActivated: false });
        }
        else if (config.CanBeActivated.includes(alarm.Id)) {
            onAlarmChanged({ ...alarm, CanBeActivated: true });
        }
        onChangeAlarmActivationPossibility(config);
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: "100%" }}>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ fontWeight: 'bold', flexGrow: 3 }}>{t("Alarm actions")}</span>
                    {!isReadonly &&
                        <Button
                            appearance={isReadonly ? 'default' : 'primary'}
                            iconBefore={<AddIcon label={'add'} />}
                            onClick={onShowActionEditDialog(false)}
                        >
                            {t("Add Alarm Action")}
                        </Button>
                    }
                </div>
                <GeovisDynamicTable
                    rows={getRows(false)}
                    head={getHead()}
                />
            </div>


            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ fontWeight: 'bold', flexGrow: 3 }}>{t("Alarm confirmations")}</span>
                    {!isReadonly &&
                        <Button
                            appearance={isReadonly ? 'default' : 'primary'}
                            iconBefore={<AddIcon label={'add'} />}
                            onClick={onShowActionEditDialog(true)}
                        >
                            {t("Add Alarm Confirmation")}
                        </Button>
                    }
                </div>
                <GeovisDynamicTable
                    rows={getRows(true)}
                    head={getHead()}
                />
            </div>

            {state.showEditDialog && (
                <AlarmActionEditDialog
                    action={state.currentAction}
                    forConfirm={state.currentAction.ForConfirm}
                    isCreateMode={state.isCreateMode}
                    isReadonly={isReadonly}
                    onClose={onCloseActionEditDialog}
                    onSave={onSaveAlarmAction}
                    companies={companies}
                    conditions={alarm.Conditions}
                    isTemplate={isTemplate}
                    onChangeAlarmActivationPossibility={onChangeAlarmActivationPossibilityHandler}
                />
            )}

            {state.showTestDialog && (
                <AlarmActionTestDialog
                    action={state.currentAction}
                    alarm={alarm}
                    onClose={onHideTestDialog}
                />
            )}
        </div>
    )
}