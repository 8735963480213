//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { LocalMapObject } from './AVTService/TypeLibrary/LocalMapObjects/LocalMapObject';
import { LocalMapObjectContentType } from './LocalMapObjectContentType';
import { ImageSensorModel } from './ImageSensorModel';
import { ImageBackgroundInfo } from './AVTService/TypeLibrary/ImageAsMap/ImageBackgroundInfo';

export abstract class LocalMapObjectContent extends LocalMapObject
{
	public ContentType: LocalMapObjectContentType;
	public Sensors: ImageSensorModel[];
	public BackgroundInfo: ImageBackgroundInfo;
	public Link: string;
	public DocumentId: string;
	public ReportId: number;
	public ViewId: string;
}
