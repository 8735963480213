/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 07.03.2024
 * @description Geovis time picker, based on atlaskit time picker
 */

import { TimePicker, TimePickerProps } from "@atlaskit/datetime-picker";


/**
 * Generate array of times for the time picker
 * @returns 
 */
const getTimes = (): string[] => {
    const result: string[] = [];
    for (let index = 0; index < 24; index++) {
        result.push(`${index < 10 ? "0" : ""}${index.toString()}:00`);
        result.push(`${index < 10 ? "0" : ""}${index.toString()}:30`);
    }
    return result;
}


export const GeovisTimePicker = (props: TimePickerProps) => {


    return (
        <TimePicker
            timeFormat="HH:mm"
            times={getTimes()}
            {...props}
        />
    )
}