//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ProjectStatistic } from '../../../AVTService/TypeLibrary/Statistics/ProjectStatistic';
import { MstStatistic } from '../../../AVTService/TypeLibrary/Statistics/MstStatistic';

export class GeovisProjectStatistic extends ProjectStatistic
{
	public MstStats: MstStatistic[];
}
