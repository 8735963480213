/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 28.10.2019
 * @description Local map objects helping functions
 */

import L, { latLng, LatLngBounds } from 'leaflet';
import CircleIcon from 'src/resources/icons/circle-24.png';
import DocumentIcon from 'src/resources/icons/document_24px.png';
import LinkIcon from 'src/resources/icons/link-26.png';
import PolylineIcon from 'src/resources/icons/polyline_24px.png';
import T from 'src/resources/icons/T.png';
import PolygonIcon from 'src/resources/icons/TestPolygon.png';
import { LocalMapObject } from '../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject';
import { LocalMapObjectCamera } from '../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectCamera';
import { LocalMapObjectTextDirection } from '../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectTextDirection';
import { LocalMapObjectType } from '../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectType';
import { ImageBackgroundFileType } from '../server/ImageBackgroundFileType';
import { LocalMapObjectCircle } from '../server/LocalMapObjectCircle';
import { LocalMapObjectContentType } from '../server/LocalMapObjectContentType';
import { LocalMapObjectDocument } from '../server/LocalMapObjectDocument';
import { LocalMapObjectLink } from '../server/LocalMapObjectLink';
import { LocalMapObjectPolygon } from '../server/LocalMapObjectPolygon';
import { LocalMapObjectPolyline } from '../server/LocalMapObjectPolyline';
import { LocalMapObjectText } from '../server/LocalMapObjectText';
import { ILocalMapObjectsStorage } from '../store/data.types';
import { latLngToLocation } from './MapHelper';
import { mapToListOfElements, mapToListOfElementsOfIds } from './StorageHelper';

/**
 * Get local map objects for project overview
 * Include project view filter
 * @param local map objects storage
 * @param objectIds LMO ids to draw
 */
export const getLocalMapObjectForProjectOverview = ({ isLoading, localMapObjects }: ILocalMapObjectsStorage, objectIds: string[] | undefined, filterFunc: (lmo: LocalMapObject) => boolean): LocalMapObject[] => {
    if (isLoading) {
        return [];
    }

    return mapToListOfElementsOfIds(localMapObjects, objectIds, filterFunc);
}

/**
 * Get local map object from storage or FALSE
 * @param param0 
 * @param lmoId 
 */
export const getLocalMapObject = ({ isLoading, isError, localMapObjects }: ILocalMapObjectsStorage, lmoId: string): LocalMapObject | false => {
    if (isLoading || isError) {
        return false;
    }

    const lmo = localMapObjects.get(lmoId);

    return lmo || false;
}

/**
 * Filter LMO by its names
 * @param query 
 * @param param1 
 */
export const filterLocalMapObjects = (query: string, { isLoading, isError, localMapObjects }: ILocalMapObjectsStorage): LocalMapObject[] => {

    if (isLoading || isError) {
        return [];
    }
    return mapToListOfElements(localMapObjects, filterLocalMapObjectByName(query.toLowerCase()));
}

/**
 * 
 * @param query 
 */
export const filterLocalMapObjectByName = (query: string) => {
    if (!query || query.length === 0) {
        return () => true;
    }

    return (obj: LocalMapObject): boolean => {
        try {
            return obj.Name.toLowerCase().search(query) > -1;
        }
        catch {
            return false;
        }
    }
}

/**
 * Get local map object bounds on the map
 * @param obj 
 */
export const getLocalMapObjectBounds = (obj: LocalMapObject): LatLngBounds => {

    switch (obj.ObjectType) {
        case LocalMapObjectType.Link: {
            const location = (obj as LocalMapObjectLink).Location;
            return L.latLngBounds([latLng(location.Latitude, location.Longitude)]);
        }

        case LocalMapObjectType.Text: {
            const location = (obj as LocalMapObjectText).Location;
            return L.latLngBounds([latLng(location.Latitude, location.Longitude)]);
        }

        case LocalMapObjectType.Camera: {
            const location = (obj as LocalMapObjectCamera).Location;
            return L.latLngBounds([latLng(location.Latitude, location.Longitude)]);
        }

        case LocalMapObjectType.Document: {
            const location = (obj as LocalMapObjectDocument).Location;
            return L.latLngBounds([latLng(location.Latitude, location.Longitude)]);
        }

        case LocalMapObjectType.Polyline: {
            const polyline = (obj as LocalMapObjectPolyline);
            return getPolylineBounds(polyline);
        }

        case LocalMapObjectType.Circle: {
            const circle = (obj as LocalMapObjectCircle);
            return L.latLng(circle.Center.Latitude, circle.Center.Longitude).toBounds(circle.Radius);
        }

        case LocalMapObjectType.Polygon: {
            const polygon = (obj as LocalMapObjectPolygon);
            return getPolygonBounds(polygon);
        }
    }

    throw Error(`getLocalMapObjectBounds: not supported type ${obj.ObjectType}`);
}

const getPolylineBounds = (polyline: LocalMapObjectPolyline): LatLngBounds => {

    let left: number | false = false;
    let right: number | false = false;
    let top: number | false = false;
    let bottom: number | false = false;

    for (const point of polyline.LocationPoints) {

        const { Latitude, Longitude } = point;

        if (left === false || left > Latitude) {
            left = Latitude;
        }

        if (right === false || right < Latitude) {
            right = Latitude;
        }

        if (top === false || top < Longitude) {
            top = Longitude;
        }

        if (bottom === false || bottom > Longitude) {
            bottom = Longitude;
        }
    }

    return L.latLngBounds(L.latLng(+left, +top), L.latLng(+right, +bottom));
}

const getPolygonBounds = (polygon: LocalMapObjectPolygon): LatLngBounds => {

    let left: number | false = false;
    let right: number | false = false;
    let top: number | false = false;
    let bottom: number | false = false;

    for (const point of polygon.LocationPoints) {

        const { Latitude, Longitude } = point;

        if (left === false || left > Latitude) {
            left = Latitude;
        }

        if (right === false || right < Latitude) {
            right = Latitude;
        }

        if (top === false || top < Longitude) {
            top = Longitude;
        }

        if (bottom === false || bottom > Longitude) {
            bottom = Longitude;
        }
    }

    return L.latLngBounds(L.latLng(+left, +top), L.latLng(+right, +bottom));
}

export const getLocalMapObjectIcon = (objType: LocalMapObjectType): JSX.Element => {

    switch (objType) {
        case LocalMapObjectType.Text: return (
            <img className="localMapObjectListIcon" src={T} />
        );
        case LocalMapObjectType.Link: return (
            <img className="localMapObjectListIcon" src={LinkIcon} />
        );
        case LocalMapObjectType.Document: return (
            <img className="localMapObjectListIcon" src={DocumentIcon} />
        )
        case LocalMapObjectType.Polyline: return (
            <img className="localMapObjectListIcon" src={PolylineIcon} />
        )
        case LocalMapObjectType.Circle: return (
            <img className="localMapObjectListIcon" src={CircleIcon} />
        )
        case LocalMapObjectType.Polygon: return (
            <img className="localMapObjectListIcon" src={PolygonIcon} />
        )
    }

    return (<div />);
}

/**
 * Default camera properties
 * @param projectId 
 * @param objectType 
 */
export const getDefaultCameraElementProps = (projectId: number, objectType: LocalMapObjectType): LocalMapObjectCamera => ({
    ...new LocalMapObjectCamera(),
    IsPublic: true,
    Description: "",
    ProjectId: projectId,
    Color: "#666666",
    ObjectType: objectType,
    Location: { Height: 0, Latitude: 0, Longitude: 0 },
    Link: '',
    Opacity: 1
})

/**
 * Default text element properties
 * @param projectId 
 * @param objectType 
 */
export const getDefaultTextElementProps = (projectId: number, objectType: LocalMapObjectType): LocalMapObjectText => ({
    FontSize: 14,
    RotationAngle: 0,
    IsPublic: true,
    Description: "",
    Color: "#000",
    ObjectType: objectType,
    Location: { Latitude: 0, Longitude: 0, Height: 0 },
    Name: getDefaultElementName(objectType),
    TextOnTheMap: "",
    Id: '',
    ProjectId: projectId,
    Created: '',
    ContentType: LocalMapObjectContentType.Empty,
    Sensors: [],
    BackgroundInfo: {
        FileId: '',
        GeoJSONFileId: '',
        FileName: '',
        FileType: ImageBackgroundFileType.Blank,
        IsFileDirty: false,
        IsGeoJSONFileDirty: false,
        IsMapTilesDirty: false,
        ViewPort: { Right: 0, Left: 0, Top: 0, Bottom: 0 },
        IsMapTiles: false,
        MapTilesId: ''
    },
    Link: '',
    DocumentId: '',
    ReportId: 0,
    ViewId: '',
    TextDirection: LocalMapObjectTextDirection.ToRight
});

/**
 * Default element name
 * @param objectType 
 */
export const getDefaultElementName = (objectType: LocalMapObjectType): string => {

    switch (objectType) {
        case LocalMapObjectType.Text: return "Text on the map";
        case LocalMapObjectType.Link: return "Link on the map";
        case LocalMapObjectType.Document: return "Document on the map";
        case LocalMapObjectType.Polyline: return "Line";
        case LocalMapObjectType.Circle: return "Circle";
    }

    return "";
}

/**
 * Default document properties
 * @param projectId 
 * @param elementType 
 */
export const getDefaultDocumentElementProps = (projectId: number, elementType: LocalMapObjectType): LocalMapObjectDocument => ({
    Description: "",
    IsPublic: true,
    DocumentId: '',
    DocumentName: '',
    Id: '',
    Location: { Latitude: 0, Longitude: 0, Height: 0 },
    Name: getDefaultElementName(elementType),
    ObjectType: elementType,
    ProjectId: projectId,
    Created: ''
});

/**
 * Set location to single point LMO
 * @param object 
 * @param l - location in latLng
 */
export const setSinglePointLocation = (object: LocalMapObject, l: L.LatLng): LocalMapObject => {
    const location = latLngToLocation(l);

    switch (object.ObjectType) {
        case LocalMapObjectType.Text: {
            const text: LocalMapObjectText = {
                ...new LocalMapObjectText(),
                ...object,
                Location: location
            };
            return text;
        }

        case LocalMapObjectType.Document: {
            const doc: LocalMapObjectDocument = {
                ...new LocalMapObjectDocument(),
                ...object,
                Location: location
            };
            return doc;
        }

        case LocalMapObjectType.Link: {
            const link: LocalMapObjectLink = {
                ...new LocalMapObjectLink(),
                ...object,
                Location: location
            }

            return link;
        }

        case LocalMapObjectType.Camera: {
            const camera: LocalMapObjectCamera = {
                ...new LocalMapObjectCamera(),
                ...object,
                Location: location
            };

            return camera;
        }
    }

    throw Error(`setSinglePointLocation doesn't support local map object type ${object.ObjectType}`);
}