import { CancelTokenSource } from "axios";

const cancelTokensToURLs = new Map<string, CancelTokenSource>();

/**
 * Set new url-token pair
 * @param url ID of request
 * @param cToken Cancellation token
 */
export const setTokenToId = (url: string, cToken: CancelTokenSource) => {
    cancelTokensToURLs.set(url, cToken);
}

/**
 * Remove token from map
 * @param url ID of request
 */
export const removeTokenById = (url: string) => {
    cancelTokensToURLs.delete(url);
}

/**
 * Cancel token and than remove it from map
 * @param url ID of request
 */
export const cancelTokenById = (url: string) => {
    const token = cancelTokensToURLs.get(url);
    if (token) {
        token.cancel();
        cancelTokensToURLs.delete(url);
    }
}

/**
 * Check if URL is already in map
 * @param url ID of request
 * @returns True if URL is in map, otherwise - False
 */
export const checkTokenById = (url: string): boolean => {
    return cancelTokensToURLs.has(url);
}