/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 26.08.2022
 * @description The select to change elements migration state without executing additional migrations
 */

import { GeovisSelectT } from '../../../../components/select/GeovisSelect';
import { getIGvOptionTypeList, IGvOptionType } from '../../../../components/select/GeovisSelect_tools';
import {
    getProjectElementMigrationStateToName,
    ProjectElementMigrationState,
    ProjectElementMigrationStateList
} from '../../../../server/AVTService/TypeLibrary/Common/ElementsMigrations/ProjectElementMigrationState';

interface IComponentProps {
    state: ProjectElementMigrationState;
    onChange: (state: ProjectElementMigrationState) => void;
}

export const ProjectElementMigrationStatusSelect = ({ onChange, state }: IComponentProps) => {

    const onChangeHandler = (option: IGvOptionType<ProjectElementMigrationState>) => onChange(option.value);
    const options = getIGvOptionTypeList(ProjectElementMigrationStateList, getProjectElementMigrationStateToName);
    const selected = options.find(o => o.value === state);

    return (
        <GeovisSelectT<ProjectElementMigrationState>
            options={options}
            value={selected}
            spacing='compact'
            isSearchable={false}
            onChange={onChangeHandler}
        />
    )
}