/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 31.07.2020
 * @description Geovis Leaflet map marker
 */

import { Marker, MarkerProps, useMap } from 'react-leaflet';
import { ChainInfo } from '../../../server/ChainInfo';
import { SensorInfo } from '../../../server/GEOvis3/Model/SensorInfo';

export interface IGeovisMarkerAdditionalProps {
    sensor?: SensorInfo;
    chain?: ChainInfo;
}

export interface IGeovisMarkerProps {
    makerPaneName: string;
    markerProps: MarkerProps;
    additionalProps: IGeovisMarkerAdditionalProps;
    children: JSX.Element[];
    setRef: (marker: L.Marker | null) => void;
}

export const GeovisMarker = (props: IGeovisMarkerProps) => {
    const leafletMap = useMap();
    return (
        <Marker
            ref={props.setRef}
            {...props.markerProps}
            {...{
                additionalProps: props.additionalProps,
                leafletMap
            }}
            pane={props.makerPaneName}>
            {props.children}
        </Marker>
    )
}
