import Textfield from "@atlaskit/textfield";
import { debounce } from "lodash";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear'
import Tooltip from "@atlaskit/tooltip";
import WarningIcon from '@atlaskit/icon/glyph/warning'
import { t } from "../../i18n";

interface IComponentProps {
    value: string;
    type: "string" | "number";
    onChanged: (updValue: string) => void;
    onCheckValidate?: (value: string) => IValidationResult;
    isDisabled?: boolean;
    isReadonly?: boolean;
    isClearable?: boolean;
    passValueAnyway?: boolean;
    debounceTimer?: number;
    updateTimeStamp?: number;
}

interface IValidationResult {
    isValid: boolean;
    error: string;
}

interface IComponentState extends IValidationResult {
    oldValue: string;
}
export const GeovisValidatableTextfield = ({
    onChanged,
    type,
    value,
    isDisabled,
    isReadonly,
    onCheckValidate,
    isClearable,
    passValueAnyway,
    debounceTimer,
    updateTimeStamp
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        oldValue: value,
        isValid: true,
        error: ""
    });

    useEffect(() => {
        if (onCheckValidate) {
            const validateResult = onCheckValidate(value);
            setState({
                error: validateResult.error,
                isValid: validateResult.isValid,
                oldValue: value
            });
        }
    }, [updateTimeStamp])

    const textFieldRef = useRef<HTMLInputElement>(null);

    const onChangeDebounced = debounce((val: string) => {
        if (onCheckValidate) {
            const validateResult = onCheckValidate(val);
            if (validateResult.isValid || passValueAnyway) {
                onChanged(val);
                setState({
                    oldValue: val,
                    ...validateResult
                });
            }
            else {
                setState({
                    ...state,
                    ...validateResult
                });
            }

        }
        else {
            onChanged(val);
            setState({
                oldValue: val,
                isValid: true,
                error: ''
            });
        }

    }, debounceTimer ?? 300)

    const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onChangeDebounced(event.currentTarget.value);
    }
    const onClearField = () => {

        if (textFieldRef.current !== null) {
            textFieldRef.current.value = state.oldValue;
            onChanged(state.oldValue);
            setState({
                ...state,
                isValid: true
            })
        }
    }

    return (
        <Textfield
            ref={textFieldRef}
            type={type}
            isDisabled={isDisabled}
            isReadOnly={isReadonly}
            isCompact={true}
            defaultValue={value}
            onChange={onChangeHandler}
            autoFocus={false}
            //onBlur={onBlurHandler}
            isInvalid={!state.isValid}
            elemAfterInput={
                <div style={{ display: 'flex', alignItems: "center" }}>
                    {isClearable &&
                        <Tooltip content={t("Clear")} >
                            <div style={{ marginRight: '5px', cursor: 'default' }} onClick={onClearField}>
                                <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                            </div>
                        </Tooltip>
                    }
                    {!state.isValid &&
                        <Tooltip content={state.error} >
                            <div style={{ marginRight: '5px', cursor: 'default' }}>
                                <WarningIcon label="warning" size="small" primaryColor="orange" />
                            </div>
                        </Tooltip>
                    }
                </div>
            }
        />
    )
}