//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GeovisUserRoleEnum {
	Zero = 0,
	User = 10,
	CompanyAdmin = 30,
	Admin = 50,
	Developer = 100
}

const GeovisUserRoleEnumToName = new Map<number, string>([
	[ 0, 'Zero' ],
	[ 10, 'User' ],
	[ 30, 'CompanyAdmin' ],
	[ 50, 'Admin' ],
	[ 100, 'Developer' ],
])

export function getGeovisUserRoleEnumToName(element: GeovisUserRoleEnum) : string {
    return GeovisUserRoleEnumToName.get(element) || '';
}

const GeovisUserRoleEnumByName = new Map<string, number>([
	[ 'Zero', 0 ],
	[ 'User', 10 ],
	[ 'CompanyAdmin', 30 ],
	[ 'Admin', 50 ],
	[ 'Developer', 100 ],
]);

export function getGeovisUserRoleEnumByName(name: string): GeovisUserRoleEnum {
    return GeovisUserRoleEnumByName.get(name)!;
}


export const GeovisUserRoleEnumList : Readonly<GeovisUserRoleEnum[]> = [
	GeovisUserRoleEnum.Zero,
	GeovisUserRoleEnum.User,
	GeovisUserRoleEnum.CompanyAdmin,
	GeovisUserRoleEnum.Admin,
	GeovisUserRoleEnum.Developer,
];



const GeovisUserRoleEnumToDescription = new Map<number, string>([
	[ 0, 'Zero' ],
	[ 10, 'User' ],
	[ 30, 'CompanyAdmin' ],
	[ 50, 'Admin' ],
	[ 100, 'Developer' ],
])

export function getGeovisUserRoleEnumToDescription(element: GeovisUserRoleEnum) : string {
    return GeovisUserRoleEnumToDescription.get(element) || '';
}

