import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useRouteMatch } from "react-router-dom";
import { Dispatch } from 'redux';
import { LoadingPageErrorSkeleton } from "../../components/LoadingPageErrorSkeleton";
import { LoadingPageSkeleton } from "../../components/LoadingPageSkeleton";
import { ChartElementRender } from "../../components/projectOverview/reportOverlay/reportRender/ChartElementRender";
import GeovisChartRenderDataLayer from "../../components/projectOverview/reportOverlay/reportRender/charts/GeovisChartRenderDataLayer";
import { printPageWidthInPx } from "../../components/projectOverview/reportOverlay/reportRender/tools";
import { IWithGeovisServerProps, withGeovisServer } from "../../helpers/GeovisHooks";
import { IReportChartRenderRouteParams } from "../../helpers/IRouteParams";
import { fetchServerElements } from "../../helpers/ProjectDataHelper";
import Routes from "../../helpers/Routes";
import { ReportElementType } from "../../server/AVTService/TypeLibrary/Common/ReportElementType";
import { GeovisReportElementInfo } from "../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo";
import { GeovisReportModel } from "../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportModel";
import { DataActionResponse } from "../../server/DataActionResponse";
import { PdfReportInitialState } from "../../server/GEOvis3/Model/Reports/PdfReportInitialState";
import ServerRoutesGen from "../../server/Routes/ServerRoutesGen";
import AuthService from "../../services/AuthService";
import { geovisProjectReportPdfInitialState } from "../../store/creators/projectReportCreators.pdf";
import { IGeovisStoreState } from "../../store/store.types";
import {
    defaultSomethingStorageState,
    errorSomethingStorageState,
    IGeovisAction,
    ISomethingStoreBase,
    loadedSomethingStorageState
} from "../../store/types";

interface IPageStateProps {
    detailedReportInfo: GeovisReportModel
}

interface IPageDispatchProps {
    onReportInitialStateLoaded: (pdfReportInitialState: DataActionResponse<PdfReportInitialState>) => void;
}

interface IPageProps extends IPageStateProps, IPageDispatchProps, IWithGeovisServerProps {

}
/**
 * Component to draw reports for PDF export
 * @param param0 
 * @returns 
 */
const ReportChartRenderPage = ({ onReportInitialStateLoaded, Server, detailedReportInfo }: IPageProps) => {

    const routeParams = useRouteMatch<IReportChartRenderRouteParams>(Routes.renderGeovisChartToPng);

    if (!routeParams) {
        return (
            <div>
                Wrong URL
            </div>
        )
    }

    const [localState, setLocalState] = useState<ISomethingStoreBase>(defaultSomethingStorageState);

    const {
        params: {
            projectId,
            reportId,
            settingsId,
            pageNum,
            chartId,
            height,
            width,
            userId,
            userToken
        }
    } = routeParams;

    if (!reportId || !(+reportId > 0)) {
        return (
            <div>
                Wrong URL ( no report ID was found)
            </div>
        )
    }

    if (!chartId || !(+chartId > 0)) {
        return (
            <div>
                Wrong URL ( no chart ID was found)
            </div>
        )
    }

    if (pageNum === undefined) {
        return (
            <div>
                Wrong URL (report page number not found)
            </div>
        )
    }

    useEffect(() => {
        (async function loadReportInitialData() {

            const url = ServerRoutesGen.ReportPdfRenderData.GetPdfReportInitialState.patch(projectId, reportId, userId, userToken, settingsId);

            const response = await fetchServerElements<PdfReportInitialState>(Server, url);

            if (!response.Success) {
                setLocalState(errorSomethingStorageState(...response.Messages));

                // this message for Puppeteer
                // eslint-disable-next-line no-console
                console.error('REPORT ERROR: ' + response.Messages.join('; '));
                return;
            }

            await AuthService.lazyLoadServerInfoByUrl(ServerRoutesGen.ReportPdfRenderData.GetServerInfo.patch(userToken, userId));

            onReportInitialStateLoaded(response);
            setLocalState(loadedSomethingStorageState);
        })();
    }, [])


    if (localState.isLoading) {
        return (
            <LoadingPageSkeleton loadingText="Loading report info" />
        )
    }

    if (localState.isError) {
        return (
            <LoadingPageErrorSkeleton errorText="Load report error" errorDescription={localState.errorDescription} />
        )
    }

    const getElementRenderDataLayer = (element: GeovisReportElementInfo) => (
        <GeovisChartRenderDataLayer
            key={`element-render-data-layer-${element.ElementType}-${element.Id}`}
            reportId={+reportId}
            userId={userId}
            userToken={userToken}
            isPrinting={true}
            pageNum={+pageNum}
            eventInfo={undefined}
            isVibrationEventChart={false}
            chartId={element.Id}
        />
    )

    /*
        const getDataLayersForElements = (page: GeovisReportPage, pageNum: number) => {
            return (
                <React.Fragment>
                    {page.Elements.map((element, index) => {
    
                        if (element.ElementType === ReportElementType.ElementsGroup) {
                            return (
                                <div key={`page-element-${index}`} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%' }}>
                                    {element.Children.map(child => {
                                        const childElement: GeovisReportElementInfo = { ...element, ...child };
                                        return getElementRenderDataLayer(childElement, pageNum)
                                    })}
                                </div>
    
                            )
                        }
    
                        return getElementRenderDataLayer(element, pageNum);
                    })}
                </React.Fragment>
            )
        }*/

    const getExpectedChart = (elements: GeovisReportElementInfo[]): GeovisReportElementInfo | false => {

        for (const element of elements) {

            let elementInfo: GeovisReportElementInfo | false = false;

            if (element.ElementType === ReportElementType.ElementsGroup) {
                const elementInfoBase = element.Children.find(e => e.ElementType === ReportElementType.GeovisChart && e.Id === +chartId);

                if (elementInfoBase) {
                    elementInfo = { ...element, ...elementInfoBase, Children: [] };
                }
            }
            else if (element.ElementType === ReportElementType.GeovisChart && element.Id === +chartId) {
                elementInfo = element;
            }

            if (elementInfo) {
                return elementInfo;
            }
        }

        return false;
    }

    const containerHeight = height ? `${height}px` : "auto";
    const containerWidth = width ? `${width}px` : `${printPageWidthInPx}px`;

    const page = detailedReportInfo.Pages[+pageNum];

    if (!page) {
        return (
            <div>
                Wrong config (page {pageNum} not found)
            </div>
        )
    }

    const chart = getExpectedChart(page.Elements);
    if (!chart) {
        return (
            <div>
                Wrong config (chart {chartId} on page {pageNum} not found)
            </div>
        )
    }

    return (
        <div style={{ width: containerWidth, height: containerHeight }}> {/* 1185 = 210 / printPageCoefficient, A4 = 210 x 297 */}
            <React.Fragment key={`page-${pageNum}-${chartId}`}>
                <ChartElementRender
                    key={`geovis-chart-${chart.Id}`}
                    pageNum={+pageNum}
                    elementInfo={chart}
                    elementIndexOnPage={0}
                    reportId={+reportId}
                    userId={userId}
                    userToken={userToken}
                    isPrinting={true}
                    lastInGroup={false}
                    useClientRenderer={true}
                    projectId={+projectId!}
                    settingsId={settingsId}
                    isInGroup={false}
                    rootContainerWidth={0}
                />
                {/* {getDataLayersForElements(page, pageNum)} */}
                {getElementRenderDataLayer(chart)}
            </React.Fragment>
        </div>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState): IPageStateProps => ({
    detailedReportInfo: projectReport.geovisReportSettings.detailedReportInfo
})

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IPageDispatchProps => ({
    onReportInitialStateLoaded: initialState => dispatch(geovisProjectReportPdfInitialState(initialState))
});

const ComponentConnected = connect<IPageStateProps, IPageDispatchProps>(mapStateToProps, mapDispatchToProps)(withGeovisServer(ReportChartRenderPage))

export default ComponentConnected;