//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum InventoryStatus {
	Project = 0,
	Storage = 1,
	Reservation = 2,
	Service = 3,
	Bought = 4,
	Unusable = 5,
	Defect = 6
}

const InventoryStatusToName = new Map<number, string>([
	[ 0, 'Project' ],
	[ 1, 'Storage' ],
	[ 2, 'Reservation' ],
	[ 3, 'Service' ],
	[ 4, 'Bought' ],
	[ 5, 'Unusable' ],
	[ 6, 'Defect' ],
])

export function getInventoryStatusToName(element: InventoryStatus) : string {
    return InventoryStatusToName.get(element) || '';
}

const InventoryStatusByName = new Map<string, number>([
	[ 'Project', 0 ],
	[ 'Storage', 1 ],
	[ 'Reservation', 2 ],
	[ 'Service', 3 ],
	[ 'Bought', 4 ],
	[ 'Unusable', 5 ],
	[ 'Defect', 6 ],
]);

export function getInventoryStatusByName(name: string): InventoryStatus {
    return InventoryStatusByName.get(name)!;
}


export const InventoryStatusList : Readonly<InventoryStatus[]> = [
	InventoryStatus.Project,
	InventoryStatus.Storage,
	InventoryStatus.Reservation,
	InventoryStatus.Service,
	InventoryStatus.Bought,
	InventoryStatus.Unusable,
	InventoryStatus.Defect,
];



const InventoryStatusToDescription = new Map<number, string>([
	[ 0, 'Project' ],
	[ 1, 'Storage' ],
	[ 2, 'Reservation' ],
	[ 3, 'Service' ],
	[ 4, 'Bought' ],
	[ 5, 'Unusable' ],
	[ 6, 'Defect' ],
])

export function getInventoryStatusToDescription(element: InventoryStatus) : string {
    return InventoryStatusToDescription.get(element) || '';
}

