/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 20.05.2022
 * @description DTS chart render
 */

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { t } from "../../../../../../i18n";
import { DtsChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/DtsChartData";
import { DtsChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel";
import Logger from "../../../../../../services/Logger";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { IReportElementRenderOwnProps } from "../../types";
import { getDtsChartRenderOptions } from "../options/dtsChartRenderOptions";
import { getGeovisChartConfigFromStorage } from "../options/tools";
import { getGeovisChartData } from "../tools";
import { IChartRenderStateToProps } from "../types";
import { ChartLoadingSkeleton } from "./ChartLoadingComponent";

type IStateToProps = IChartRenderStateToProps<DtsChartData>

interface IOwnProps extends IReportElementRenderOwnProps {
    chartId: number;
}


interface IComponentProps extends IStateToProps, IOwnProps {

}

const DtsChartRender = ({ chartData, chartId, isPrinting, pageNum }: IComponentProps) => {

    if (!chartData) {
        return (<ChartLoadingSkeleton text={t("Initializing DTS chart data")} />)
    }

    const { data, errorDescription, isError, isLoaded, dtsSectionInfos } = chartData;

    if (isError) {
        return (<LoadingPageErrorSkeleton errorText={t("Error load DTS chart data")} errorDescription={errorDescription} />);
    }

    if (!isLoaded) {
        return (<ChartLoadingSkeleton text={t("Loading DTS chart data...")} />)
    }


    Logger.render('DtsChartRender');

    // getting changed chart config directly from storage, do not subscribe on UPDATE event
    const chart = getGeovisChartConfigFromStorage<DtsChartModel>(pageNum, chartId);
    if (!chart) {
        return null;
    }

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);

    // disable boost if chart is in Printing mode
    if (!isPrinting) {
        HighchartsBoost(Highcharts);
    }

    const options = getDtsChartRenderOptions(pageNum, cloneDeep(chart), data, dtsSectionInfos);

    return (
        <div className="report-chart-render" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}>
            <HighchartsReact
                key={`highcharts_dtschart-${chart.Id}-${chartData.Timestamp}`}
                highcharts={Highcharts}
                options={options}
                constructorType={'chart'}
                containerProps={{ style: { flexGrow: 1, height: `100%` } }}
            />
        </div>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { pageNum, chartId }: IOwnProps): IStateToProps => ({
    chartData: getGeovisChartData<DtsChartData>(projectReport.geovisReportSettings, pageNum, chartId)
});

export default connect<IStateToProps, never, IOwnProps>(mapStateToProps)(DtsChartRender);