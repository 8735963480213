//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum Geovis4TableTextDirectionMode {
	ToRight = 0,
	ToTop = 1,
	ToBottom = 2
}

const Geovis4TableTextDirectionModeToName = new Map<number, string>([
	[ 0, 'ToRight' ],
	[ 1, 'ToTop' ],
	[ 2, 'ToBottom' ],
])

export function getGeovis4TableTextDirectionModeToName(element: Geovis4TableTextDirectionMode) : string {
    return Geovis4TableTextDirectionModeToName.get(element) || '';
}

const Geovis4TableTextDirectionModeByName = new Map<string, number>([
	[ 'ToRight', 0 ],
	[ 'ToTop', 1 ],
	[ 'ToBottom', 2 ],
]);

export function getGeovis4TableTextDirectionModeByName(name: string): Geovis4TableTextDirectionMode {
    return Geovis4TableTextDirectionModeByName.get(name)!;
}


export const Geovis4TableTextDirectionModeList : Readonly<Geovis4TableTextDirectionMode[]> = [
	Geovis4TableTextDirectionMode.ToRight,
	Geovis4TableTextDirectionMode.ToTop,
	Geovis4TableTextDirectionMode.ToBottom,
];



const Geovis4TableTextDirectionModeToDescription = new Map<number, string>([
	[ 0, 'To right' ],
	[ 1, 'To top' ],
	[ 2, 'To bottom' ],
])

export function getGeovis4TableTextDirectionModeToDescription(element: Geovis4TableTextDirectionMode) : string {
    return Geovis4TableTextDirectionModeToDescription.get(element) || '';
}

