/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 04.02.2020
 * @description The icons renderers
 */

import { getSensorSymbolByName, getSensorSymbolToName, SensorSymbol } from '../../server/AVTService/TypeLibrary/Sensors/SensorSymbol';
import {
    AirPressurePrimitivePoints,
    circleIconPrimitive,
    CounterPrimitivePoints,
    CurrentLoopPrimitivePoints,
    DistancePrimitivePoints,
    FlowPrimitivePoints,
    getShapeTransform,
    GPSPrimitivePoints,
    InclinometerChainPositionPrimitiveInnerPoints,
    InclinometerChainPositionPrimitiveOuterPoints,
    InclinometerPrimitivePoint,
    LoadCellPrimitivePoints,
    PfVPrimitivePoints,
    PhFPrimitivePoints,
    PlusPoints,
    RainmeterPrimitivePoints,
    rectanglePrimitive,
    RombPoints,
    SolarRadiationPrimitivePoints,
    StarPoints,
    svgSensorIconStyle,
    TriangleDownPoints,
    TriangleLeftPoints,
    TriangleRightPoints,
    TriangleUpPoints,
    UnknownPrimitivePoints,
    VirtualSensorPrimitivePoints,
    VoltagePrimitivePoints,
    VPrimitivePoints,
    WindSeedPrimitivePoints,
    withSvgOfIconProps,
    wrapPolygonSvg
} from './IconPrimitives';
import {
    getFillColor,
    ISVGIconProps
} from "./types";

const getColorizedCircleAreas = (props: ISVGIconProps, projectIconMode: boolean = false) => {

    const { iconSize, fillColors, weight } = props;

    let centerX = (iconSize.x - 2 * weight) / 2;
    let centerY = (iconSize.y - 2 * weight) / 2;

    if (projectIconMode) {
        centerX = props.iconAnchor.x;
        centerY = props.iconAnchor.y;
    }

    let paths = '';

    for (let index = 0; index < fillColors.length; index++) {
        const color = fillColors[index];

        const startAngle = 2 * Math.PI * (index + 1) / fillColors.length;
        const endAngle = 2 * Math.PI * (index) / fillColors.length;

        const startX = ((iconSize.x - 2 * weight) / 2) * Math.sin(startAngle) + centerX;
        const startY = ((iconSize.y - 2 * weight) / 2) * Math.cos(startAngle) + centerY;

        const endX = ((iconSize.x - 2 * weight) / 2) * Math.sin(endAngle) + centerX;
        const endY = ((iconSize.y - 2 * weight) / 2) * Math.cos(endAngle) + centerY;

        paths += `<path d="
                    M ${centerX},${centerY} 
                    L ${startX},${startY}
                    A ${(iconSize.x - 2 * weight) / 2},${(iconSize.y - 2 * weight) / 2} 0 0 1 ${endX} ${endY} Z" 
                    fill="${color}"
                    transform="translate(${weight}, ${weight})" />`;
    }

    return paths;
}

const getColorizedRects = (colors: string[], startX: number, startY: number, width: number, height: number, transform: string, horizontal: boolean) => {
    let cx = startX;
    let cy = startY;
    let rects = '';

    for (const color of colors) {
        rects += `<rect x="${cx}" y="${cy}" 
                    width="${width}" height="${height}" 
                    fill="${color}"
                    ${transform} />`;

        if (horizontal) {
            cy += height;
        }
        else {
            cx += width;
        }
    }

    return rects;
}

const getColorizedRectAreas = (props: ISVGIconProps, horizontal: boolean) => {

    const { iconSize, fillColors, weight } = props;

    const width = (iconSize.x - 2 * weight) / (horizontal ? 1 : fillColors.length);
    const height = (iconSize.y - 2 * weight) / (horizontal ? fillColors.length : 1);
    const transform = getShapeTransform(props);

    return getColorizedRects(fillColors, 0, 0, width, height, transform, horizontal);
}

const getColorizedIconSvg = (maskedIcon: string, transparentIcon: string, colorizedAreas: string, key: string) => {
    const maskId = `iconMask-${key}`;
    const svg =
        `<defs><mask id="${maskId}">${maskedIcon}</clipPath></defs>` +
        `<g mask="url(#${maskId})">${colorizedAreas}${transparentIcon}</g>`;

    return svg;
}

const getIconPropsForMask = (props: ISVGIconProps): ISVGIconProps => {
    return props.fillColors.length < 2
        ? props
        : { ...props, fillColors: ["white"], color: "white", };
}

const getTransparentIconProps = (props: ISVGIconProps): ISVGIconProps => {
    return { ...props, fillColors: ["transparent"] };
}

const getIconKey = (props: ISVGIconProps, typeKey: string): string => (
    typeKey + (props.key === undefined ? '' : `-${props.key}`)
)

export const getCircleIconSvg = (props: ISVGIconProps, key: string, projectIconMode: boolean = false): string => {
    if (props.fillColors.length < 2) {
        return circleIconPrimitive(props);
    }
    else {
        const maskedIcon = circleIconPrimitive(getIconPropsForMask(props));
        const transparentIcon = circleIconPrimitive(getTransparentIconProps(props));
        return getColorizedIconSvg(maskedIcon, transparentIcon, getColorizedCircleAreas(props, projectIconMode), getIconKey(props, key));
    }
}

const getPolygonIconSvg = (points: string, props: ISVGIconProps, key: string, horizontal: boolean = false): string => {
    if (props.fillColors.length < 2) {
        return wrapPolygonSvg(points, props);
    }
    else {
        const maskedPolygon = wrapPolygonSvg(points, getIconPropsForMask(props));
        const transparentPolygon = wrapPolygonSvg(points, getTransparentIconProps(props));
        return getColorizedIconSvg(maskedPolygon, transparentPolygon, getColorizedRectAreas(props, horizontal), getIconKey(props, key));
    }
}

const getRectangleIconSvg = (props: ISVGIconProps, key: string, horizontal: boolean = false): string => {
    if (props.fillColors.length < 2) {
        return rectanglePrimitive(props);
    }
    else {
        const maskedRect = rectanglePrimitive(getIconPropsForMask(props));
        const transparentRect = rectanglePrimitive(getTransparentIconProps(props));
        return getColorizedIconSvg(maskedRect, transparentRect, getColorizedRectAreas(props, horizontal), getIconKey(props, key));
    }
}

/**
 * The unknown sensor type icon renderer 
 * @param props 
 */
export const UnknownIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(UnknownPrimitivePoints, props, "unknown", true);
    return withSvgOfIconProps(props)(svg);
}

/**
 * The theodolite sensor type icon renderer
 * @param props 
 */
const theodoliteIconOuterSvg = (style: string, transform: string): string => {
    return `<polygon points="15.24,1.82 16.28,3.55 17.18,6.96 17.27,12.97 17.24,20.97 6.3,20.97 6.27,12.97 6.37,6.96 7.27,3.55 8.3,1.82" style="${style}" ${transform}/>`;
}

const theodoliteIconInnerSvg = (style: string, transform: string): string => {
    return `<polygon points="9.19,6.96 9.19,3.55 9.52,2.41 14.02,2.41 14.36,3.55 14.36,6.96 14.02,6.96 14.02,16.8 9.52,16.8 9.52,6.96 " style="fill: white; fill-opacity:1.0; stroke: black; stroke-width: 0.5;" ${transform}/>` +
        `<polygon points="9.69,7.13 13.86,7.13 13.86,15.46 9.69,15.46" style="${style}" ${transform}/>` +
        `<polygon points="15.25,20.97 15.25,22.81 8.29,22.81 8.29,20.97" style="${style}" ${transform}/>` +
        `<circle cx="11.77" cy="11.3" r="1.67" style="fill: white; fill-opacity:1.0; stroke: black; stroke-width: 0.5;" ${transform}/>`;
}

export const TheodoliteIconRenderer = (props: ISVGIconProps): string => {

    const primitivesStyle = svgSensorIconStyle(props);
    const transform = getShapeTransform(props);

    const innerSvg = theodoliteIconInnerSvg(primitivesStyle, transform);
    if (props.fillColors.length < 2) {
        const outerSvg = theodoliteIconOuterSvg(primitivesStyle, transform);
        return withSvgOfIconProps(props)(outerSvg + innerSvg);
    }
    else {
        const maskedStyle = svgSensorIconStyle(getIconPropsForMask(props));
        const maskedIcon = theodoliteIconOuterSvg(maskedStyle, transform);

        const transparentStyle = svgSensorIconStyle(getTransparentIconProps(props));
        const transparentIcon = theodoliteIconOuterSvg(transparentStyle, transform);

        const svg = getColorizedIconSvg(maskedIcon, transparentIcon, getColorizedRectAreas(props, true), getIconKey(props, "theodolite"));
        return withSvgOfIconProps(props)(svg + innerSvg);
    }
}

/**
 * The extensometer sensor type icon renderer
 * @param props 
 */
const extensometerIconMainSvg = (props: ISVGIconProps, transform: string): string => {
    return `<path 
        d="M5.48 5.49 L 8.88 2.09 L 6.44 2.09 L 5.36 1 L 11 1.01 L 11 6.65 L 9.91 5.56 L 9.91 3.12 L 3.11 9.92 L 5.55 9.92 L 6.63 11.01 L 0.99 11 L 1 5.36 L 2.08 6.45 L 2.08 8.89 z" 
        stroke="${props.color}" stroke-width="${props.weight}" fill="${props.fillColors[0]}" ${transform} />`;
}

export const ExtensometerIconRenderer = (props: ISVGIconProps) => {
    const fillColor = getFillColor(props.fillColors);
    const transform = getShapeTransform(props);

    const eSvg =
        `<path d="M2.99 4.20 L 1 4.20 L 1 1 L 2.99 1" stroke="${fillColor}" stroke-width="0.5" fill="transparent" ${transform}/>` +
        `<path d="M1 2.6 h 1.2" stroke="${fillColor}" stroke-width="0.5" fill="transparent" ${transform}/>`;

    if (props.fillColors.length < 2) {
        const mainSvg = extensometerIconMainSvg({ ...props, fillColors: [fillColor] }, transform);
        return withSvgOfIconProps(props)(mainSvg + eSvg);
    }
    else {
        const maskedIcon = extensometerIconMainSvg(getIconPropsForMask(props), transform);
        const transparentIcon = extensometerIconMainSvg(getTransparentIconProps(props), transform);
        const svg = getColorizedIconSvg(maskedIcon, transparentIcon, getColorizedRectAreas(props, false), getIconKey(props, "extensometer"));

        return withSvgOfIconProps(props)(svg + eSvg);
    }
}

/**
 * The inclinometer sensor type icon renderer
 * @param props 
 */
export const InclinometerIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(InclinometerPrimitivePoint, props, "inklinometer");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The temperature sensor type icon renderer
 * @param props 
 */
export const TemperatureIconRenderer = (props: ISVGIconProps) => {

    const { weight, color, fillColors } = props;
    const fillColor = getFillColor(fillColors);

    const transform = getShapeTransform(props);
    const iconSvg =
        `<path d="M5.06 4.46 L 6.94 4.46 L 6.94 7.74 A 1.57 1.57 0 1 1 5.06 7.74 z" stroke="${color}" stroke-width="${weight}" fill="${fillColor}" ${transform}/>` +
        `<path d="M5.06 4.46 L 5.06 1.32 A 0.94 0.94 0 0 1 6.94 1.32 L 6.94 4.46 z" stroke="${color}" stroke-width="${weight}" fill="white" ${transform}/>` +
        `<path d="M6.94 1.99 h -1" stroke="${fillColor}" stroke-width="${weight}" ${transform}/>` +
        `<path d="M6.94 3.58 h -1" stroke="${fillColor}" stroke-width="${weight}" ${transform}/>` +
        `<path d="M6.94 5.18 h -1" stroke="white" stroke-width="${weight}" ${transform}/>` +
        `<path d="M6.94 6.77 h -1" stroke="white" stroke-width="${weight}" ${transform}/>`;

    if (fillColors.length < 2) {
        return withSvgOfIconProps(props)(iconSvg);
    }
    else {
        const startX = 3.0;
        const startY = 0.0;
        const width = 6.0;
        const height = 11.5 / fillColors.length;
        const areas = getColorizedRects(fillColors, startX, startY, width, height, transform, true);
        const ellipse = `<ellipse cx="6.0" cy="5.6" rx="3.0" ry="5.6" fill="white" fill-opacity="0.8" ${transform} />`;
        const colorizedBackground = getColorizedIconSvg(ellipse, "", areas, getIconKey(props, "temperature"));
        return withSvgOfIconProps(props)(colorizedBackground + iconSvg);
    }
}

/**
 * The piezometer sensor type icon renderer
 * @param props 
 */
export const PiezometerIconRenderer = (props: ISVGIconProps) => {
    const { weight, color, fillOpacity, fillColors } = props;
    const fillColor = getFillColor(fillColors);
    const transform = getShapeTransform(props);
    const colorizedAreas = fillColors.length < 2 ? '' : getColorizedRectAreas(props, false);
    const svg =
        `<polygon points="0,0 0,12 12,12 12,0" style="fill: ${fillColor}; fill-opacity: ${fillOpacity}; stroke: ${color}; stroke-width: ${weight};" ${transform} />` +
        colorizedAreas +
        `<path d="M0.87 2.95 A 3.28 3.28 0 0 1 6 2.1 A 3.28 3.28 0 0 0 11.1 1.23" stroke="black" stroke-width="0.5" fill="transparent" ${transform} />` +
        `<path d="M0.87 5.57 A 3.28 3.28 0 0 1 6 4.72 A 3.28 3.28 0 0 0 11.1 3.85" stroke="black" stroke-width="0.5" fill="transparent" ${transform} />` +
        `<path d="M0.87 8.14 A 3.28 3.28 0 0 1 6 7.29 A 3.28 3.28 0 0 0 11.1 6.42" stroke="black" stroke-width="0.5" fill="transparent" ${transform} />` +
        `<path d="M0.87 10.7 A 3.28 3.28 0 0 1 6 9.85 A 3.28 3.28 0 0 0 11.1 8.98" stroke="black" stroke-width="0.5" fill="transparent" ${transform} />`;

    return withSvgOfIconProps(props)(svg);
}

/**
 * Air pressure sensor type icon renderer
 * @param props 
 */
export const AirPressureIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(AirPressurePrimitivePoints, props, "airpressure");
    return withSvgOfIconProps(props)(svg);
}

/**
 * Wind speed sensor type icon renderer
 * @param props 
 */
export const WindSpeedIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(WindSeedPrimitivePoints, props, "windspeed");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The solar radiation sensor type icon renderer
 * @param props 
 */
export const SolarRadiationIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(SolarRadiationPrimitivePoints, props, "solar-radiation");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The V sensor type icon renderer
 * @param props 
 */
export const VIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(VPrimitivePoints, props, "v");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The PfH sensor type icon renderer
 * @param props 
 */
export const PfHIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(PhFPrimitivePoints, props, "pfh");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The Voltage sensor type icon renderer
 * @param props 
 */
export const VoltageIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(VoltagePrimitivePoints, props, "voltage");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The Counter sensor type icon renderer
 * @param props 
 */
export const CounterIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(CounterPrimitivePoints, props, "counter");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The current loop sensor type icon renderer
 * @param props 
 */
export const CurrentLoopIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(CurrentLoopPrimitivePoints, props, "current");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The load cell sensor type icon renderer
 * @param props 
 */
export const LoadCellIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(LoadCellPrimitivePoints, props, "loadcell");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The virtual sensor sensor type icon renderer
 * @param props 
 */
export const VirtualSensorIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(VirtualSensorPrimitivePoints, props, "virtual");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The distance sensor type icon renderer
 * @param props 
 */
export const DistanceIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(DistancePrimitivePoints, props, "distance");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The flow sensor type icon renderer
 * @param props 
 */
export const FlowIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(FlowPrimitivePoints, props, "flow", true);
    return withSvgOfIconProps(props)(svg);
}

/**
 * The GH sensor type icon renderer
 * @param props 
 */
export const GHIconRenderer = (props: ISVGIconProps) => {
    const svg = getRectangleIconSvg(props, "gh");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The GPS sensor type icon renderer
 * @param props 
 */
export const GPSIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(GPSPrimitivePoints, props, "gps");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The hydrostatic level sensor type icon renderer
 * @param props 
 */
export const HydrostaticLevelIconRenderer = (props: ISVGIconProps) => {

    const { weight, fillColors } = props;
    const defaultFillColor = getFillColor(fillColors);
    const transform = getShapeTransform(props);

    const fillColor1 = fillColors.length < 2 ? defaultFillColor : fillColors[0];
    const fillColor2 = fillColors.length < 2 ? defaultFillColor : fillColors[1];

    const svg =
        `<path d="M0.27 6.04 L 0.27 4.02 h 1.54 L 1.81 6.04 z" stroke="${fillColor1}" stroke-width="${weight}" fill="${fillColor1}" ${transform}/>` +
        `<path d="M0.27 4.02 L 0.27 0.49 L 1.81 0.49 L 1.81 4.02 z" stroke="${fillColor1}" stroke-width="${weight}" fill="white" ${transform}/>` +
        `<path d="M1.81 4.64 h -0.8" stroke="${fillColor1}" stroke-width="${fillColor1}" ${transform}/>` +
        `<path d="M1.81 3.24 h -0.8" stroke="${fillColor1}" stroke-width="${fillColor1}" ${transform}/>` +
        `<path d="M1.81 1.85 h -0.8" stroke="${fillColor1}" stroke-width="${fillColor1}" ${transform}/>` +

        `<path d="M9.92 7.45 L 9.92 4.02 h 1.54 L 11.46 7.45 z" stroke="${fillColor2}" stroke-width="${weight}" fill="${fillColor2}" ${transform}/>` +
        `<path d="M9.92 1.90 L 11.46 1.90 L 11.46 4.02 L 9.92 4.02 z" stroke="${fillColor2}" stroke-width="${weight}" fill="white" ${transform}/>` +
        `<path d="M11.46 6.05 h -0.8" stroke="${fillColor2}" stroke-width="${fillColor2}" ${transform}/>` +
        `<path d="M11.46 4.66 h -0.8" stroke="${fillColor2}" stroke-width="${fillColor2}" ${transform}/>` +
        `<path d="M11.46 3.26 h -0.8" stroke="${fillColor2}" stroke-width="${fillColor2}" ${transform}/>` +

        // `<path d="M1.04 6.04 L 1.04 9.39 A 1.1 1.1 0 0 0 2.12 10.73 L 2.89 10.73 A 1.1 1.1 0 0 0 4.12 9.65 L 4.11 8.16 A 1.2 1.2 0 0 1 5.37 7.08 L 6.94 7.06 A 1.2 1.2 0 0 1 8.16 8.30 L 8.16 10.51 A 0.6 0.6 0 0 0 8.84 11.11 L 9.99 11.11 A 0.6 0.6 0 0 0 10.67 10.51 L 10.69 7.45" stroke="${fillColor1}" stroke-width="${weight}" fill="transparent" ${transform} />`;

        `<path d="M1.04 6.04 L 1.04 9.39 A 1.1 1.1 0 0 0 2.12 10.73 L 2.89 10.73 A 1.1 1.1 0 0 0 4.12 9.65 L 4.11 8.16 A 1.2 1.2 0 0 1 5.37 7.08 L 6.94 7.06" stroke="${fillColor1}" stroke-width="${weight}" fill="transparent" ${transform} />` +
        `<path d="M6.0 7.06 L 6.94 7.06 A 1.2 1.2 0 0 1 8.16 8.30 L 8.16 10.51 A 0.6 0.6 0 0 0 8.84 11.11 L 9.99 11.11 A 0.6 0.6 0 0 0 10.67 10.51 L 10.69 7.45" stroke="${fillColor2}" stroke-width="${weight}" fill="transparent" ${transform} />`;

    return withSvgOfIconProps(props)(svg);
}

/**
 * The Noise sensor type icon renderer
 * @param props 
 */
export const NoiseIconRenderer = (props: ISVGIconProps) => {
    const {
        fillOpacity,
        fillColors,
        color,
        weight
    } = props;

    const fillColor = getFillColor(fillColors);
    const transform = getShapeTransform(props);

    const speakerSvg = `<polygon points="0.97,4.64 3.96,4.64 6.02,1.76 6.02,10.24 3.96,7.36 0.97,7.36 0.97,4.64" style="fill: ${fillColor}; fill-opacity:${fillOpacity}; stroke: ${color}; stroke-width: ${weight};" ${transform}/>`;
    const wave1 = `<path d="M7.09 4.22 A 2.09 2.09 0 0 1 7.09 7.78" stroke="${color}" stroke-width="${weight}" fill="transparent" ${transform}/>`;
    const wave2 = `<path d="M7.88 2.95 A 3.58 3.58 0 0 1 7.88 9.05" stroke="${color}" stroke-width="${weight}" fill="transparent" ${transform}/>`;
    const wave3 = `<path d="M8.64 1.71 A 5.06 5.06 0 0 1 8.67 10.34" stroke="${color}" stroke-width="${weight}" fill="transparent" ${transform}/>`;

    const svg = speakerSvg + wave1 + wave2 + wave3;

    if (fillColors.length < 2) {
        return withSvgOfIconProps(props)(svg);
    }
    else {
        const startX = 0.0;
        const startY = 0.0;
        const width = 12.0;
        const height = 11.5 / fillColors.length;
        const areas = getColorizedRects(fillColors, startX, startY, width, height, transform, true);
        const background = `<ellipse cx="5.9" cy="5.9" rx="5.5" ry="5.5" fill="white" fill-opacity="0.8" ${transform} />`;
        const colorizedBackground = getColorizedIconSvg(background, "", areas, getIconKey(props, "noise"));

        return withSvgOfIconProps(props)(colorizedBackground + svg);
    }
}

/**
 * The inclinometer chain position sensor type icon renderer
 * @param props 
 */
export const InclinometerChainPositionIconRender = (props: ISVGIconProps) => {
    const transform = getShapeTransform(props);

    const innerRectProps = { ...props, fillColors: ["white"] };

    const outerRect = `<path d="${InclinometerChainPositionPrimitiveOuterPoints}" style="${svgSensorIconStyle(props)}" ${transform} />`;
    const innerRect = `<path d="${InclinometerChainPositionPrimitiveInnerPoints}" style="${svgSensorIconStyle(innerRectProps)}" ${transform} />`;
    const colorizedAreas = props.fillColors.length < 2 ? '' : getColorizedRectAreas(props, false);

    const svg = `<g>${outerRect}${colorizedAreas}${innerRect}</g>`
    return withSvgOfIconProps(props)(svg);
}

/**
 * The PfV sensor type icon renderer
 * @param props 
 */
export const PfVIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(PfVPrimitivePoints, props, "pfv");
    return withSvgOfIconProps(props)(svg);
}

/**
 * The prism sensor type icon renderer
 * @param props 
 */
export const PrismIconRenderer = (props: ISVGIconProps) => {
    const svg = getCircleIconSvg(props, "prism");
    return withSvgOfIconProps(props)(svg);
}

/**
 * THe Rainmeter sensor type icon renderer
 * @param props 
 */
export const RainmeterIconRenderer = (props: ISVGIconProps) => {
    const svg = getPolygonIconSvg(RainmeterPrimitivePoints, props, "rainmeter", true);
    return withSvgOfIconProps(props)(svg);
}

/**
 * The settlement sensor type icon renderer
 * @param props 
 */
export const SettlementIconRenderer = (props: ISVGIconProps) => {
    const { iconSize } = props;

    const smallSize = iconSize.x / 2;
    const bigCircleSvg = circleIconPrimitive(props);
    const smallCircleProps: ISVGIconProps = {
        ...props,
        iconSize: L.point(smallSize, smallSize),
        fillColors: ['white']
    }
    const smallCircleSvg = circleIconPrimitive(smallCircleProps);
    const colorizeAreas = props.fillColors.length < 2 ? '' : '\n' + getColorizedCircleAreas(props);
    const svg = bigCircleSvg + colorizeAreas + '\n' + smallCircleSvg;

    return withSvgOfIconProps(props)(svg);
}

/**
 * The vibration sensor type icon renderer
 * @param props 
 */
export const VibrationIconRenderer = (props: ISVGIconProps) => {

    const { fillColors, weight } = props;
    const defautFillColor = getFillColor(props.fillColors);
    const transform = getShapeTransform(props);

    const circleColor = fillColors.length < 3 ? defautFillColor : fillColors[2];
    const color1 = fillColors.length < 2 ? defautFillColor : fillColors[0];
    const color2 = fillColors.length < 2 ? defautFillColor : fillColors[1];

    const svg =
        `<circle cx="6" cy="6" r="1" style="fill: ${circleColor}; fill-opacity:1.0; stroke: ${circleColor}; stroke-width: ${weight};" ${transform}/>` +
        `<path d="M7.09 4.22 A 2.09 2.09 0 0 1 7.09 7.78" stroke="${color1}" stroke-width="${weight}" fill="transparent" ${transform}/>` +
        `<path d="M7.88 2.95 A 3.58 3.58 0 0 1 7.88 9.05" stroke="${color1}" stroke-width="${weight}" fill="transparent" ${transform}/>` +
        `<path d="M8.64 1.71 A 5.06 5.06 0 0 1 8.67 10.34" stroke="${color1}" stroke-width="${weight}" fill="transparent" ${transform}/>` +
        `<path d="M4.91 4.22 A 2.09 2.09 0 0 0 4.91 7.78" stroke="${color2}"  stroke-width="${weight}" fill="transparent" ${transform}/>` +
        `<path d="M4.12 2.95 A 3.58 3.58 0 0 0 4.12 9.05" stroke="${color2}"  stroke-width="${weight}" fill="transparent" ${transform}/>` +
        `<path d="M3.36 1.71 A 5.06 5.06 0 0 0 3.33 10.34" stroke="${color2}" stroke-width="${weight}" fill="transparent" ${transform}/>` +
        `<circle cx="6" cy="6" r="6" style="fill:none; stroke:#000000; stroke-width:${weight};" ${transform}`;

    return withSvgOfIconProps(props)(svg);
}

/**
 * The volume sensor type icon renderer
 * @param props 
 */
export const VolumeIconRenderer = (props: ISVGIconProps) => {

    const { fillColors, weight } = props;
    const transform = getShapeTransform(props);

    const color1 = fillColors.length < 3 ? fillColors[0] : fillColors[2];
    const color2 = fillColors[0];
    const color3 = fillColors.length < 2 ? fillColors[0] : fillColors[1];

    const style1 = svgSensorIconStyle({ ...props, fillColors: [color1], fillOpacity: .4 });
    const style2 = svgSensorIconStyle({ ...props, fillColors: [color2], fillOpacity: .8 });
    const style3 = svgSensorIconStyle({ ...props, fillColors: [color3], fillOpacity: 1.0 });

    const polygon1 = fillColors.length === 2
        ? `<polygon points="0,3 6,0 6,6" style="${style2}" transform="translate(${weight})" ${transform} />`
        : `<polygon points="0,3 6,0 12,3 6,6" style="${style1}" transform="translate(${weight})" ${transform} />`;

    const polygon1P2 = fillColors.length === 2
        ? `<polygon points="6,0 12,3 6,6" style="${style3}" transform="translate(${weight})" ${transform} />`
        : '';

    const svg =
        `<polygon points="0,3 6,0 12,3 12,9 6,12 0,9" style="fill: rgb(255, 255, 255);" transform="translate(${weight})" ${transform} />` +
        polygon1 + polygon1P2 +
        `<polygon points="0,3 6,6 6,12 0,9" style="${style2}" transform="translate(${weight})" ${transform} />` +
        `<polygon points="12,3 6,6 6,12 12,9" style="${style3}" transform="translate(${weight})" ${transform} />`;

    return withSvgOfIconProps(props)(svg);
}



/**
 * The pH sensor type icon renderer
 * @param props 
 */
export const PHIconRenderer = (props: ISVGIconProps) => {
    const { fillColors } = props;
    const defautFillColor = getFillColor(fillColors);
    const lineStyle = `"fill:none;stroke:${defautFillColor};stroke-width:2.5px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"`;
    const svg = `<path d="M 1.6536488,23.0093 1.6063988,7.1343003 c 1.659807,-0.02875 3.403427,-0.448633 4.890067,0.330729 2.015534,1.750179 0.878866,6.1001997 -0.08268,6.8212887 -1.348725,1.225546 -3.11433,0.807686 -4.760138,0.785482"` +
        ` style=${lineStyle}/>` +
        `<path d="M 10.46049,0.47247016 10.413243,16.34747" style=${lineStyle}/>` +
        `<path d="M 15.969494,0.47247016 V 16.34747" style=${lineStyle}/>` +
        `<path d="M 15.969494,8.4099703 H 10.677828" style=${lineStyle}/>`;

    return withSvgOfIconProps(props)(svg);
}

/**
 * The oxygen sensor type icon renderer
 * @param props 
 */
export const OxygenIconRenderer = (props: ISVGIconProps) => {
    const { fillColors } = props;
    const defautFillColor = getFillColor(fillColors);
    // const lineStyle = `"fill:${defautFillColor};fill-opacity:1;fill-rule:evenodd;stroke:#000000;stroke-width:0.171164;stroke-linejoin:round"`;
    const transform = getShapeTransform(props);
    const svg =
        `<polygon points="0,0 0,12 12,12 12,0" style="fill: transparent; fill-opacity:1.0; stroke: black; stroke-width: 0;" ${transform} />` +

        `<path d="M4.36 10.81 L 7.63 4.60" stroke= "${defautFillColor}" stroke-width="0.7" ${transform} />` +
        `<path d="M4.83 5.15 A 0.9 0.9 0 0 0 3.34 5.16 A 1.96 1.96 0 0 0 3.32 7.04 A 0.91 0.91 0 0 0 4.8 7.05 A 2.05 2.05 0 0 0 4.83 5.15" stroke= "${defautFillColor}" stroke-width="0.7" fill="none" ${transform} />` +
        `<path d="M8.72 8.3 A 0.9 0.9 0 0 0 7.23 8.31 A 1.96 1.96 0 0 0 7.21 10.19 A 0.91 0.91 0 0 0 8.69 10.20 A 2.05 2.05 0 0 0 8.72 8.3" stroke= "${defautFillColor}" stroke-width="0.7" fill="none" ${transform} />` +

        `<path d="M4.16 1.72 A 1.11 1.11 0 0 0 2.49 1.06 A 1.49 1.49 0 0 0 2.48 3.56 A 1.56 1.56 0 0 0 4.19 3.30 L 4.18 2.48 L 3.13 2.48" stroke= "${defautFillColor}" stroke-width="0.7" fill="none" ${transform} />` +

        `<path d="M4.84 3.80 L 5.99 0.82 L 7.21 3.80" stroke= "${defautFillColor}" stroke-width="0.6" fill="none" stroke-linejoin="round" ${transform} />` +
        `<path d="M5.25 2.73 L 6.77 2.73" stroke= "${defautFillColor}" stroke-width="0.6" fill="none" ${transform}/>` +

        `<path d="M9.72 1.68 A 0.78 0.78 0 0 0 9.65 1.4 A 1.03 1.03 0 0 0 8.04 1.23 A 0.53 0.53 0 0 0 8.21 2.07 L 9.33 2.37 A 0.64 0.64 0 0 1 9.46 3.55 A 1.35 1.35 0 0 1 8.01 3.42 A 0.69 0.69 0 0 1 7.81 2.84" stroke= "${defautFillColor}" stroke-width="0.7" fill="none" ${transform} />`;

    return withSvgOfIconProps(props)(svg);
}

export const SensorSymbolIconRenderer = (props: ISVGIconProps) => {

    const typeOfSymbolStr = props.key ?? getSensorSymbolToName(SensorSymbol.Circle);
    const typeOfSymbol = getSensorSymbolByName(typeOfSymbolStr);

    let svg: string = "";

    switch (typeOfSymbol) {
        case SensorSymbol.Plus:
            svg = getPolygonIconSvg(PlusPoints, props, "starSymbol");
            break;
        case SensorSymbol.TriangleDown:
            svg = getPolygonIconSvg(TriangleDownPoints, props, "starSymbol");
            break;
        case SensorSymbol.TriangleLeft:
            svg = getPolygonIconSvg(TriangleLeftPoints, props, "starSymbol");
            break;
        case SensorSymbol.TriangleRight:
            svg = getPolygonIconSvg(TriangleRightPoints, props, "starSymbol");
            break;
        case SensorSymbol.TriangleUp:
            svg = getPolygonIconSvg(TriangleUpPoints, props, "starSymbol");
            break;
        case SensorSymbol.Romb:
            svg = getPolygonIconSvg(RombPoints, props, "starSymbol");
            break;
        case SensorSymbol.Star:
            svg = getPolygonIconSvg(StarPoints, props, "starSymbol");
            break;
        case SensorSymbol.Square:
            props.iconScale = 1.2;
            svg = getRectangleIconSvg(props, "squareSymbol");
            break;
        default:
            props.iconScale = 1.2;
            svg = getCircleIconSvg(props, "circleSymbol");
            break;
    }

    return withSvgOfIconProps(props)(svg);
}

export const MstIconRenderer = (props: ISVGIconProps) => {
    const { fillColors, weight } = props;
    const defaultFillColor = getFillColor(fillColors);

    const fillColor1 = fillColors.length < 2 ? defaultFillColor : fillColors[0];
    const fillColor2 = fillColors.length < 2 ? defaultFillColor : fillColors[1];

    const transform = getShapeTransform(props);

    const svg =
        `<polyline points="9,1  1,1  1,17 9,17" stroke="#000" stroke-weight="${weight}" fill="${fillColor1}" ${transform} />` +
        `<polyline points="9,1 17,1 17,17 9,17" stroke="#000" stroke-weight="${weight}" fill="${fillColor2}" ${transform} />` +

        `<path d="M3.5 9.2 L 3.5 3 L 5 8 L 6.5 3 L 6.5 9.2" stroke-width="0.5" fill="none" stroke="#000" ${transform} />` +       // letter 'M'
        `<path d="M11 4 A1.5 1.5 0 1 0 9.5 6 A1.5 1.5 0 1 1 8 8" stroke-width="0.5" fill="none" stroke="#000" ${transform} />` +   // letter 'S'
        `<path d="M12.5 3 L 16.5 3" stroke-width="0.5" fill="none" stroke="#000" ${transform} />` +
        `<path d="M14.5 3 L 14.5 9.2" stroke-width="0.5" fill="none" stroke="#000" ${transform} />` +

        `<line x1="3" y1="10" x2="17" y2="10" stroke="#000" stroke-weight="${weight}" fill="none" ${transform} />` +

        `<circle cx="5"  cy="13" r="1" stroke="#000" fill="#fff" ${transform} />` +
        `<circle cx="10" cy="13" r="1" stroke="#000" fill="#fff" ${transform} />` +
        `<circle cx="15" cy="13" r="1" stroke="#000" fill="#fff" ${transform} />` +

        `<line x1="5"  y1="17" x2="5"  y2="18.9" stroke="${fillColor1}" fill="${fillColor1}" ${transform} />` +
        `<line x1="10" y1="17" x2="10" y2="18.9" stroke="${fillColor2}" fill="${fillColor2}" ${transform} />` +
        `<line x1="15" y1="17" x2="15" y2="18.9" stroke="${fillColor2}" fill="${fillColor2}" ${transform} />`;

    return withSvgOfIconProps(props)(svg);
}

export const SbIconRenderer = (props: ISVGIconProps) => {
    const { fillColors, weight } = props;
    const defaultFillColor = getFillColor(fillColors);

    const fillColor1 = fillColors.length < 2 ? defaultFillColor : fillColors[0];
    const fillColor2 = fillColors.length < 2 ? defaultFillColor : fillColors[1];

    const transform = getShapeTransform(props);

    const svg =
        `<polyline points="7,1  1,1  1,14 7,14" stroke="#000" stroke-weight="${weight}" fill="${fillColor1}" ${transform} />` +
        `<polyline points="7,1 13,1 13,14 7,14" stroke="#000" stroke-weight="${weight}" fill="${fillColor2}" ${transform} />` +

        `<path d="M6 3.2 A1.5 1.5 0 1 0 4.5 5.2 A1.5 1.5 0 1 1 3 7.2" stroke-width="0.5" fill="none" stroke="#000" ${transform} />` + // letter 'S'

        `<path d="M10.05 2.3 L 9 2.3 L 9 8.3 L 10.05 8.3" stroke-width="0.5" fill="none" stroke="#000" ${transform} />` +
        `<path d="M10.05 5.3 L 9 5.3" stroke-width="0.5" fill="none" stroke="#000" ${transform} />` +
        `<path d="M10 2.3 A1.5 1.5 0 0 1 10 5.3" stroke-width="0.5" fill="none" stroke="#000" ${transform} />` +
        `<path d="M10 5.3 A1.5 1.5 0 0 1 10 8.3" stroke-width="0.5" fill="none" stroke="#000" ${transform} />` +

        `<line x1="2" y1="9.5" x2="12" y2="9.5" stroke="#000" fill="none" ${transform} />` +

        `<circle cx="7" cy="11.9" r="1" stroke="#000" fill="#fff" ${transform} />` +
        `<line x1="4" y1="14" x2="4" y2="15.9" stroke="${fillColor1}" fill="${fillColor1}" ${transform} />`;

    return withSvgOfIconProps(props)(svg);
}
