/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 22.05.2020
 * @description Local map object icons low level implementation
 */

import '../../libs/svg.icon.customRenderer';
import { LocalMapObjectCameraIconRenderer } from './LocalMapObjectIconsRenderers';


L.divIcon.svgIcon.localMapObjectCameraIcon = function (options) {
    return new L.DivIcon.SVGIcon.SVGIconWithCustomRenderer(options, LocalMapObjectCameraIconRenderer);
}