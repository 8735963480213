//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SensorValueAttribute {
	Value1 = 0,
	Value2 = 1,
	Value3 = 2,
	ValuesXYZ = 3,
	ValuesVector = 4,
	AxisValue1 = 10,
	AxisValue2 = 11,
	AxisValue3 = 12,
	Axis = 13,
	Deviation = 14,
	DeviationX = 15,
	DeviationY = 16,
	DeviationZ = 17,
	DeviationXY = 18,
	Param1 = 20,
	Param2 = 21,
	Param3 = 22,
	Param4 = 23,
	Param5 = 24,
	Reference1 = 30,
	Reference2 = 31,
	Reference3 = 32,
	References = 33,
	AxisReference1 = 40,
	AxisReference2 = 41,
	AxisReference3 = 42,
	AxisReferences = 43,
	AxisDeviation = 44,
	AxisDeviationX = 45,
	AxisDeviationY = 46,
	AxisDeviationZ = 47,
	AxisDeviationXY = 48,
	FrequencyX = 50,
	FrequencyY = 51,
	FrequencyZ = 52,
	FrequencyVector = 53,
	PeakX = 55,
	PeakY = 56,
	PeakZ = 57,
	PeakVector = 58,
	PeakXYZ = 59,
	DomFreqX = 60,
	DomFreqY = 61,
	DomFreqZ = 62,
	DomFreqVector = 63,
	OccurrenceX = 64,
	OccurrenceY = 65,
	OccurrenceZ = 66,
	OccurrenceVector = 67,
	OccurrenceFrequency = 68,
	DomFreqXYZ = 69,
	FrequencyXYZ = 70,
	VSum = 71,
	ValuesXYZAndVector = 72,
	OccurrenceXYZ = 73,
	EventAV = 74,
	VibrationVector = 75,
	Km = 76,
	FreqMaxVcomponent = 77,
	InclinometerChainPosition1 = 80,
	InclinometerChainPosition2 = 81,
	InclinometerChainPosition3 = 82,
	AxisDeviationValue1 = 90,
	AxisDeviationValue2 = 91,
	AxisDeviationValue3 = 92,
	Name = 100,
	LocalCoordAngle = 101,
	LocalCoordRadius = 102,
	LocalCoordTunnelmeter = 103,
	LocationNorth = 110,
	LocationEast = 111,
	LocationHeight = 112,
	Relative1 = 121,
	Relative2 = 122,
	Relative3 = 123,
	AxisRelative1 = 124,
	AxisRelative2 = 125,
	AxisRelative3 = 126,
	Empty = 1000
}

const SensorValueAttributeToName = new Map<number, string>([
	[ 0, 'Value1' ],
	[ 1, 'Value2' ],
	[ 2, 'Value3' ],
	[ 3, 'ValuesXYZ' ],
	[ 4, 'ValuesVector' ],
	[ 10, 'AxisValue1' ],
	[ 11, 'AxisValue2' ],
	[ 12, 'AxisValue3' ],
	[ 13, 'Axis' ],
	[ 14, 'Deviation' ],
	[ 15, 'DeviationX' ],
	[ 16, 'DeviationY' ],
	[ 17, 'DeviationZ' ],
	[ 18, 'DeviationXY' ],
	[ 20, 'Param1' ],
	[ 21, 'Param2' ],
	[ 22, 'Param3' ],
	[ 23, 'Param4' ],
	[ 24, 'Param5' ],
	[ 30, 'Reference1' ],
	[ 31, 'Reference2' ],
	[ 32, 'Reference3' ],
	[ 33, 'References' ],
	[ 40, 'AxisReference1' ],
	[ 41, 'AxisReference2' ],
	[ 42, 'AxisReference3' ],
	[ 43, 'AxisReferences' ],
	[ 44, 'AxisDeviation' ],
	[ 45, 'AxisDeviationX' ],
	[ 46, 'AxisDeviationY' ],
	[ 47, 'AxisDeviationZ' ],
	[ 48, 'AxisDeviationXY' ],
	[ 50, 'FrequencyX' ],
	[ 51, 'FrequencyY' ],
	[ 52, 'FrequencyZ' ],
	[ 53, 'FrequencyVector' ],
	[ 55, 'PeakX' ],
	[ 56, 'PeakY' ],
	[ 57, 'PeakZ' ],
	[ 58, 'PeakVector' ],
	[ 59, 'PeakXYZ' ],
	[ 60, 'DomFreqX' ],
	[ 61, 'DomFreqY' ],
	[ 62, 'DomFreqZ' ],
	[ 63, 'DomFreqVector' ],
	[ 64, 'OccurrenceX' ],
	[ 65, 'OccurrenceY' ],
	[ 66, 'OccurrenceZ' ],
	[ 67, 'OccurrenceVector' ],
	[ 68, 'OccurrenceFrequency' ],
	[ 69, 'DomFreqXYZ' ],
	[ 70, 'FrequencyXYZ' ],
	[ 71, 'VSum' ],
	[ 72, 'ValuesXYZAndVector' ],
	[ 73, 'OccurrenceXYZ' ],
	[ 74, 'EventAV' ],
	[ 75, 'VibrationVector' ],
	[ 76, 'Km' ],
	[ 77, 'FreqMaxVcomponent' ],
	[ 80, 'InclinometerChainPosition1' ],
	[ 81, 'InclinometerChainPosition2' ],
	[ 82, 'InclinometerChainPosition3' ],
	[ 90, 'AxisDeviationValue1' ],
	[ 91, 'AxisDeviationValue2' ],
	[ 92, 'AxisDeviationValue3' ],
	[ 100, 'Name' ],
	[ 101, 'LocalCoordAngle' ],
	[ 102, 'LocalCoordRadius' ],
	[ 103, 'LocalCoordTunnelmeter' ],
	[ 110, 'LocationNorth' ],
	[ 111, 'LocationEast' ],
	[ 112, 'LocationHeight' ],
	[ 121, 'Relative1' ],
	[ 122, 'Relative2' ],
	[ 123, 'Relative3' ],
	[ 124, 'AxisRelative1' ],
	[ 125, 'AxisRelative2' ],
	[ 126, 'AxisRelative3' ],
	[ 1000, 'Empty' ],
])

export function getSensorValueAttributeToName(element: SensorValueAttribute) : string {
    return SensorValueAttributeToName.get(element) || '';
}

const SensorValueAttributeByName = new Map<string, number>([
	[ 'Value1', 0 ],
	[ 'Value2', 1 ],
	[ 'Value3', 2 ],
	[ 'ValuesXYZ', 3 ],
	[ 'ValuesVector', 4 ],
	[ 'AxisValue1', 10 ],
	[ 'AxisValue2', 11 ],
	[ 'AxisValue3', 12 ],
	[ 'Axis', 13 ],
	[ 'Deviation', 14 ],
	[ 'DeviationX', 15 ],
	[ 'DeviationY', 16 ],
	[ 'DeviationZ', 17 ],
	[ 'DeviationXY', 18 ],
	[ 'Param1', 20 ],
	[ 'Param2', 21 ],
	[ 'Param3', 22 ],
	[ 'Param4', 23 ],
	[ 'Param5', 24 ],
	[ 'Reference1', 30 ],
	[ 'Reference2', 31 ],
	[ 'Reference3', 32 ],
	[ 'References', 33 ],
	[ 'AxisReference1', 40 ],
	[ 'AxisReference2', 41 ],
	[ 'AxisReference3', 42 ],
	[ 'AxisReferences', 43 ],
	[ 'AxisDeviation', 44 ],
	[ 'AxisDeviationX', 45 ],
	[ 'AxisDeviationY', 46 ],
	[ 'AxisDeviationZ', 47 ],
	[ 'AxisDeviationXY', 48 ],
	[ 'FrequencyX', 50 ],
	[ 'FrequencyY', 51 ],
	[ 'FrequencyZ', 52 ],
	[ 'FrequencyVector', 53 ],
	[ 'PeakX', 55 ],
	[ 'PeakY', 56 ],
	[ 'PeakZ', 57 ],
	[ 'PeakVector', 58 ],
	[ 'PeakXYZ', 59 ],
	[ 'DomFreqX', 60 ],
	[ 'DomFreqY', 61 ],
	[ 'DomFreqZ', 62 ],
	[ 'DomFreqVector', 63 ],
	[ 'OccurrenceX', 64 ],
	[ 'OccurrenceY', 65 ],
	[ 'OccurrenceZ', 66 ],
	[ 'OccurrenceVector', 67 ],
	[ 'OccurrenceFrequency', 68 ],
	[ 'DomFreqXYZ', 69 ],
	[ 'FrequencyXYZ', 70 ],
	[ 'VSum', 71 ],
	[ 'ValuesXYZAndVector', 72 ],
	[ 'OccurrenceXYZ', 73 ],
	[ 'EventAV', 74 ],
	[ 'VibrationVector', 75 ],
	[ 'Km', 76 ],
	[ 'FreqMaxVcomponent', 77 ],
	[ 'InclinometerChainPosition1', 80 ],
	[ 'InclinometerChainPosition2', 81 ],
	[ 'InclinometerChainPosition3', 82 ],
	[ 'AxisDeviationValue1', 90 ],
	[ 'AxisDeviationValue2', 91 ],
	[ 'AxisDeviationValue3', 92 ],
	[ 'Name', 100 ],
	[ 'LocalCoordAngle', 101 ],
	[ 'LocalCoordRadius', 102 ],
	[ 'LocalCoordTunnelmeter', 103 ],
	[ 'LocationNorth', 110 ],
	[ 'LocationEast', 111 ],
	[ 'LocationHeight', 112 ],
	[ 'Relative1', 121 ],
	[ 'Relative2', 122 ],
	[ 'Relative3', 123 ],
	[ 'AxisRelative1', 124 ],
	[ 'AxisRelative2', 125 ],
	[ 'AxisRelative3', 126 ],
	[ 'Empty', 1000 ],
]);

export function getSensorValueAttributeByName(name: string): SensorValueAttribute {
    return SensorValueAttributeByName.get(name)!;
}


export const SensorValueAttributeList : Readonly<SensorValueAttribute[]> = [
	SensorValueAttribute.Value1,
	SensorValueAttribute.Value2,
	SensorValueAttribute.Value3,
	SensorValueAttribute.ValuesXYZ,
	SensorValueAttribute.ValuesVector,
	SensorValueAttribute.AxisValue1,
	SensorValueAttribute.AxisValue2,
	SensorValueAttribute.AxisValue3,
	SensorValueAttribute.Axis,
	SensorValueAttribute.Deviation,
	SensorValueAttribute.DeviationX,
	SensorValueAttribute.DeviationY,
	SensorValueAttribute.DeviationZ,
	SensorValueAttribute.DeviationXY,
	SensorValueAttribute.Param1,
	SensorValueAttribute.Param2,
	SensorValueAttribute.Param3,
	SensorValueAttribute.Param4,
	SensorValueAttribute.Param5,
	SensorValueAttribute.Reference1,
	SensorValueAttribute.Reference2,
	SensorValueAttribute.Reference3,
	SensorValueAttribute.References,
	SensorValueAttribute.AxisReference1,
	SensorValueAttribute.AxisReference2,
	SensorValueAttribute.AxisReference3,
	SensorValueAttribute.AxisReferences,
	SensorValueAttribute.AxisDeviation,
	SensorValueAttribute.AxisDeviationX,
	SensorValueAttribute.AxisDeviationY,
	SensorValueAttribute.AxisDeviationZ,
	SensorValueAttribute.AxisDeviationXY,
	SensorValueAttribute.FrequencyX,
	SensorValueAttribute.FrequencyY,
	SensorValueAttribute.FrequencyZ,
	SensorValueAttribute.FrequencyVector,
	SensorValueAttribute.PeakX,
	SensorValueAttribute.PeakY,
	SensorValueAttribute.PeakZ,
	SensorValueAttribute.PeakVector,
	SensorValueAttribute.PeakXYZ,
	SensorValueAttribute.DomFreqX,
	SensorValueAttribute.DomFreqY,
	SensorValueAttribute.DomFreqZ,
	SensorValueAttribute.DomFreqVector,
	SensorValueAttribute.OccurrenceX,
	SensorValueAttribute.OccurrenceY,
	SensorValueAttribute.OccurrenceZ,
	SensorValueAttribute.OccurrenceVector,
	SensorValueAttribute.OccurrenceFrequency,
	SensorValueAttribute.DomFreqXYZ,
	SensorValueAttribute.FrequencyXYZ,
	SensorValueAttribute.VSum,
	SensorValueAttribute.ValuesXYZAndVector,
	SensorValueAttribute.OccurrenceXYZ,
	SensorValueAttribute.EventAV,
	SensorValueAttribute.VibrationVector,
	SensorValueAttribute.Km,
	SensorValueAttribute.FreqMaxVcomponent,
	SensorValueAttribute.InclinometerChainPosition1,
	SensorValueAttribute.InclinometerChainPosition2,
	SensorValueAttribute.InclinometerChainPosition3,
	SensorValueAttribute.AxisDeviationValue1,
	SensorValueAttribute.AxisDeviationValue2,
	SensorValueAttribute.AxisDeviationValue3,
	SensorValueAttribute.Name,
	SensorValueAttribute.LocalCoordAngle,
	SensorValueAttribute.LocalCoordRadius,
	SensorValueAttribute.LocalCoordTunnelmeter,
	SensorValueAttribute.LocationNorth,
	SensorValueAttribute.LocationEast,
	SensorValueAttribute.LocationHeight,
	SensorValueAttribute.Relative1,
	SensorValueAttribute.Relative2,
	SensorValueAttribute.Relative3,
	SensorValueAttribute.AxisRelative1,
	SensorValueAttribute.AxisRelative2,
	SensorValueAttribute.AxisRelative3,
	SensorValueAttribute.Empty,
];



const SensorValueAttributeToDescription = new Map<number, string>([
	[ 0, 'Value 1' ],
	[ 1, 'Value 2' ],
	[ 2, 'Value 3' ],
	[ 3, 'Values XYZ' ],
	[ 4, 'Values Vector' ],
	[ 10, 'Axis Value 1' ],
	[ 11, 'Axis Value 2' ],
	[ 12, 'Axis Value 3' ],
	[ 13, 'Axis' ],
	[ 14, 'Deviation 3D' ],
	[ 15, 'Deviation X' ],
	[ 16, 'Deviation Y' ],
	[ 17, 'Deviation Z' ],
	[ 18, 'Deviation XY' ],
	[ 20, 'Param 1' ],
	[ 21, 'Param 2' ],
	[ 22, 'Param 3' ],
	[ 23, 'Param 4' ],
	[ 24, 'Param 5' ],
	[ 30, 'Reference 1' ],
	[ 31, 'Reference 2' ],
	[ 32, 'Reference 3' ],
	[ 33, 'References' ],
	[ 40, 'Axis Reference 1' ],
	[ 41, 'Axis Reference 2' ],
	[ 42, 'Axis Reference 3' ],
	[ 43, 'Axis References' ],
	[ 44, 'Axis Deviation' ],
	[ 45, 'Axis Deviation X' ],
	[ 46, 'Axis Deviation Y' ],
	[ 47, 'Axis Deviation Z' ],
	[ 48, 'Axis Deviation XY' ],
	[ 50, 'Frequency X' ],
	[ 51, 'Frequency Y' ],
	[ 52, 'Frequency Z' ],
	[ 53, 'Frequency Vector' ],
	[ 55, 'Peak X' ],
	[ 56, 'Peak Y' ],
	[ 57, 'Peak Z' ],
	[ 58, 'Peak Vector' ],
	[ 59, 'Peak XYZ' ],
	[ 60, 'DomFreq X' ],
	[ 61, 'DomFreq Y' ],
	[ 62, 'DomFreq Z' ],
	[ 63, 'DomFreq Vector' ],
	[ 64, 'Occurrence X' ],
	[ 65, 'Occurrence Y' ],
	[ 66, 'Occurrence Z' ],
	[ 67, 'Occurrence Vector' ],
	[ 68, 'Occurrence Frequency' ],
	[ 69, 'DomFreq XYZ' ],
	[ 70, 'Frequency XYZ' ],
	[ 71, 'VSum' ],
	[ 72, 'Values XYZ + Vector' ],
	[ 73, 'Occurrence XYZ' ],
	[ 74, 'Event AV field' ],
	[ 75, 'Vibration Vector (Vsum)' ],
	[ 76, 'Km' ],
	[ 77, 'Freq Max Velocity Component' ],
	[ 80, 'Inclinometer chain position 1' ],
	[ 81, 'Inclinometer chain position 2' ],
	[ 82, 'Inclinometer chain position 3' ],
	[ 90, 'Axis Deviation Value 1' ],
	[ 91, 'Axis Deviation Value 2' ],
	[ 92, 'Axis Deviation Value 3' ],
	[ 100, 'Name' ],
	[ 101, 'Loc. Coord. - Angle' ],
	[ 102, 'Loc. Coord. - Radius' ],
	[ 103, 'Loc. Coord. - Tunnelmeter' ],
	[ 110, 'Location North' ],
	[ 111, 'Location East' ],
	[ 112, 'Location Height' ],
	[ 121, 'Relative X' ],
	[ 122, 'Relative Y' ],
	[ 123, 'Relative Z' ],
	[ 124, 'Relative Axis Value 1' ],
	[ 125, 'Relative Axis Value 2' ],
	[ 126, 'Relative Axis Value 3' ],
	[ 1000, 'Empty' ],
])

export function getSensorValueAttributeToDescription(element: SensorValueAttribute) : string {
    return SensorValueAttributeToDescription.get(element) || '';
}

