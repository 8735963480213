/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.04.2022
 * @description XyChart X-axis settings tab
 */

import { GeovisChartAxisSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings';
import { XyChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel';
import { SensorCategoryOrdered } from '../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { GeovisChartAxisSettingsControl } from '../GeovisChartAxisSettingsControl';
import { IGeovisChartPropertyChangedProps } from '../types';

interface IXyChartXAxisTabProps extends IGeovisChartPropertyChangedProps<XyChartModel> {
    chart: XyChartModel;
}

export const XyChartXAxisTab = ({ chart, onChartPropertyChanged }: IXyChartXAxisTabProps) => {

    const { XAxisSettings } = chart;

    const onAxisPropertyChangedHandler = (propertyName: keyof GeovisChartAxisSettings, value: any) => {

        const changedAxisSettings: Partial<GeovisChartAxisSettings> = { ...XAxisSettings };
        changedAxisSettings[propertyName] = value;

        onChartPropertyChanged("XAxisSettings", changedAxisSettings);
    }

    const onAxisPropertiesChangedHandler = (properties: Partial<GeovisChartAxisSettings>) => {
        onChartPropertyChanged<GeovisChartAxisSettings>("XAxisSettings", { ...XAxisSettings, ...properties });
    }

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={{ width: '100%' }}>
                    <GeovisChartAxisSettingsControl
                        onXyChartPropertyChanged={onChartPropertyChanged}
                        xyChart={chart}
                        axisSettings={XAxisSettings}
                        chartType={chart.Type}
                        isXAxis={true}
                        hideTypeOfSensor={!chart.UseXYAxisDataBasedOnDifferentSensors}
                        onAxisPropertiesChanged={onAxisPropertiesChangedHandler}
                        onAxisPropertyChanged={onAxisPropertyChangedHandler}
                        sensorTypesToSelect={SensorCategoryOrdered}
                        isRightAxis={false}
                        isDisabled={false}
                    />
                </div>
            </div>
        </div>
    )
}