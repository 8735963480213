
import DynamicTable from '@atlaskit/dynamic-table';
import { StatefulProps, StatelessProps } from '@atlaskit/dynamic-table/types';

export const DynamicTableWithFixedHeader = (props: StatefulProps | StatelessProps) => {
    return (
        // <Scrollbars>
        //     <div className="stickyHeadCell">
        //         <div>
        //             <DynamicTable {...props} />
        //         </div>
        //     </div>
        // </Scrollbars>
        <div className="stickyHeadCell">
            <div>
                <DynamicTable {...props} />
            </div>
        </div>
    );
}