import { Fragment, useEffect, useState } from "react";
import { GEOvisDXFLayerType } from "../../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { SensorsMovementSettings } from "../../../server/AVTService/TypeLibrary/Map/SensorsMovementSettings";
import MovementVectorControl from "./SensorMovementVectorControl";
import { IMovementVectorInfo } from "./types";

interface IComponentProps {
    layerType: GEOvisDXFLayerType;
    movementSettings: SensorsMovementSettings;
    leafletElement: L.Map;
    movementVectors: IMovementVectorInfo[];
}

interface IComponentState {
    movementVectors: IMovementVectorInfo[];
    zoom: number;
}

const VectorMovementComponent = ({ layerType, movementSettings, leafletElement, movementVectors }: IComponentProps) => {

    const [zoom, setZoom] = useState<number>(leafletElement.getZoom());

    const [state, setState] = useState<IComponentState>({
        movementVectors,
        zoom: leafletElement.getZoom()
    });

    const getArrowEndPosition = (vectorEndPosition: L.Point, vectorAngle: number, isFirst: boolean) => {
        const arrowLength = 10;
        const multiplier = isFirst ? 1 : -1;
        const angleInRadian = (vectorAngle + 135 * multiplier) * Math.PI / 180;

        const endPoint = layerType === GEOvisDXFLayerType.Map
            ? L.point(vectorEndPosition.x + arrowLength * Math.sin(angleInRadian), vectorEndPosition.y - arrowLength * Math.cos(angleInRadian))
            : L.point(vectorEndPosition.x + arrowLength * Math.cos(angleInRadian), vectorEndPosition.y - arrowLength * Math.sin(angleInRadian));

        return leafletElement.layerPointToLatLng(endPoint);
    }

    useEffect(() => {
        leafletElement.on("zoomend", () => {
            setZoom(leafletElement.getZoom());
        })

    }, []);

    useEffect(() => {
        if (zoom !== state.zoom) {
            setState({
                ...state,
                movementVectors: state.movementVectors.map(vector => {
                    const endPositionLayerPoint = leafletElement.latLngToLayerPoint(vector.endPosition);

                    return {
                        ...vector,
                        arrowEndPosition1: getArrowEndPosition(endPositionLayerPoint, vector.angle, true),
                        arrowEndPosition2: getArrowEndPosition(endPositionLayerPoint, vector.angle, false),
                    }
                }),
                zoom: leafletElement.getZoom()
            })
        }

    }, [zoom]);

    return (
        <Fragment>
            {state.movementVectors.map(vectorInfo => (
                <MovementVectorControl
                    key={`sensor_movement_vector_${vectorInfo.sensorFullId}`}
                    vectorMovementSettings={movementSettings.VectorMovementSettings}
                    vectorInfo={vectorInfo}

                />
            ))}
        </Fragment>
    )
}

export default VectorMovementComponent;