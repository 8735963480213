//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GeovisUserRESTAPIEnum {
	READ_ONLY = 0,
	READ_WRITE = 10
}

const GeovisUserRESTAPIEnumToName = new Map<number, string>([
	[ 0, 'READ_ONLY' ],
	[ 10, 'READ_WRITE' ],
])

export function getGeovisUserRESTAPIEnumToName(element: GeovisUserRESTAPIEnum) : string {
    return GeovisUserRESTAPIEnumToName.get(element) || '';
}

const GeovisUserRESTAPIEnumByName = new Map<string, number>([
	[ 'READ_ONLY', 0 ],
	[ 'READ_WRITE', 10 ],
]);

export function getGeovisUserRESTAPIEnumByName(name: string): GeovisUserRESTAPIEnum {
    return GeovisUserRESTAPIEnumByName.get(name)!;
}


export const GeovisUserRESTAPIEnumList : Readonly<GeovisUserRESTAPIEnum[]> = [
	GeovisUserRESTAPIEnum.READ_ONLY,
	GeovisUserRESTAPIEnum.READ_WRITE,
];



const GeovisUserRESTAPIEnumToDescription = new Map<number, string>([
	[ 0, 'READ_ONLY' ],
	[ 10, 'READ_WRITE' ],
])

export function getGeovisUserRESTAPIEnumToDescription(element: GeovisUserRESTAPIEnum) : string {
    return GeovisUserRESTAPIEnumToDescription.get(element) || '';
}

