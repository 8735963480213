import { HeadType, RowType } from '@atlaskit/dynamic-table/types';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Select, { OptionType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { debounce } from "lodash";
import { SyntheticEvent, useRef, useState } from "react";
import CircleSensorSymbol from 'src/resources/icons/SensorsSymbols/circle-transparent.png'
import PlusSensorSymbol from 'src/resources/icons/SensorsSymbols/plus-transparent.png'
import RombSensorSymbol from 'src/resources/icons/SensorsSymbols/romb-transparent.png'
import SquareSensorSymbol from 'src/resources/icons/SensorsSymbols/square-transparent.png'
import StarSensorSymbol from 'src/resources/icons/SensorsSymbols/star-transparent.png'
import TriangleDownSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-down-transparent.png'
import TriangleLeftSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-left-transparent.png'
import TriangleRightSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-right-transparent.png'
import TriangleUpSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-up-transparent.png'
import { isMatchToSearchString } from '../../../helpers/FiltersHelper';
import { t } from '../../../i18n';
import { SensorSymbol } from '../../../server/AVTService/TypeLibrary/Sensors/SensorSymbol';
import { GeovisMstShortInfoModel } from '../../../server/GEOvis3/Model/Database/GeovisMstShortInfoModel';
import { SensorInfoMini } from '../../../server/GEOvis3/Model/Sensors/SensorInfoMini';
import { GeovisTableWithSelectableRows } from '../../DynamicTableWithSelectableRows';
import { IGvOptionType } from '../../select/GeovisSelect_tools';
import { getMstsOptions } from './tools';

interface IDialogProps {
    selectedSensorId: string;
    allItems: SensorInfoMini[];
    heading: string;
    isLoading?: boolean;
    projectMsts: GeovisMstShortInfoModel[];

    onSelected: (sensorId: string) => void;
    onCanceled: () => void;
}

interface ISelectSingleSensorsDialogState {
    selectedSensorId: string;
    allItemsQuery: string;
    selectedMstAliases: string[];
}

export const SelectSingleSensorDialog = ({
    allItems,
    heading,
    onCanceled,
    onSelected,
    projectMsts,
    selectedSensorId,
    isLoading
}: IDialogProps) => {

    const initState: ISelectSingleSensorsDialogState = {
        allItemsQuery: '',
        selectedSensorId,
        selectedMstAliases: []
    }

    const [state, setState] = useState<ISelectSingleSensorsDialogState>(initState);

    const clearSearchQueryRef = useRef<HTMLInputElement>();

    const onSearchDebounce = debounce((query: string) => setState({
        ...state,
        allItemsQuery: query
    }), 500);

    const onSearchWrapped = (event: SyntheticEvent<HTMLInputElement>) => onSearchDebounce(event.currentTarget.value);

    const onClearSearchField = () => {
        if (clearSearchQueryRef !== undefined && clearSearchQueryRef.current) {
            clearSearchQueryRef.current.value = "";
        }
        setState({
            ...state,
            allItemsQuery: ''
        });
    }

    const getSelectedMsts = (): OptionType[] => {
        const result: OptionType[] = [];

        state.selectedMstAliases.forEach(mstAlias => {
            const mst = projectMsts.find(m => m.Alias === mstAlias);
            if (mst) {
                result.push({ label: mst.Name, value: mst.Alias });
            }
        })

        return result;
    }

    const onMstSelectionChanged = (selection: Array<IGvOptionType<string>>) => {
        if (!selection) {
            setState({ ...state, selectedMstAliases: [] });
            return;
        }

        setState({ ...state, selectedMstAliases: selection.map(op => op.value) });
    }

    const sensorTypeIcon = (element: SensorInfoMini) => {

        switch (element.Symbol) {
            case SensorSymbol.Star:
                return (
                    <img src={StarSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.Plus:
                return (
                    <img src={PlusSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.Romb:
                return (
                    <img src={RombSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.Square:
                return (
                    <img src={SquareSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleDown:
                return (
                    <img src={TriangleDownSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleLeft:
                return (
                    <img src={TriangleLeftSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleRight:
                return (
                    <img src={TriangleRightSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleUp:
                return (
                    <img src={TriangleUpSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
            default:
                return (
                    <img src={CircleSensorSymbol} style={{ backgroundColor: element.Color, width: "24px", height: "24px" }} />
                )
        }

    }

    const filterFunc = (element: SensorInfoMini): boolean => {
        if (state.allItemsQuery && !isMatchToSearchString(element.Name, state.allItemsQuery) && !isMatchToSearchString(element.DatabaseName, state.allItemsQuery)) {
            return false;
        }
        if (state.selectedMstAliases.length > 0 && !state.selectedMstAliases.includes(element.DatabaseId)) {
            return false;
        }
        return true;
    }

    const getTableHead = (): HeadType => {
        return ({
            cells: [{
                key: 'selectedCol',
                content: ""
            }, {
                key: 'nameCol',
                content: t("Sensor name")
            }]
        })
    }

    const getIdFunc = (sensor: SensorInfoMini): string => {
        return sensor.Id;
    }

    const itemToRowFunc = (sensor: SensorInfoMini): RowType => {
        return ({
            key: `row-${sensor.Id}`,
            cells: [{
                key: 'nameCol',
                content: (
                    <span style={{ userSelect: 'none', msUserSelect: 'none' }}>{`${sensor.DatabaseName}.${sensor.Name}`}</span>
                )
            }, {
                key: "colorCol",
                content: (
                    <div style={{ textAlign: 'center' }}>
                        {sensorTypeIcon(sensor)}
                    </div>
                )
            }]
        })
    }

    const onSelectionChanged = (selection: SensorInfoMini[]) => {
        if (selection.length === 0) {
            setState({
                ...state,
                selectedSensorId: ""
            });
        }
        else {
            setState({
                ...state,
                selectedSensorId: selection[selection.length - 1].Id
            });
        }
    }

    const getSelectedItems = (): SensorInfoMini[] => {
        return allItems.filter(si => filterFunc(si)).filter(s => s.Id === state.selectedSensorId);
    }

    const getAllItems = (): SensorInfoMini[] => {
        return allItems.filter(si => filterFunc(si));
    }

    const onDone = () => {
        onSelected(state.selectedSensorId);
        onCanceled();
    }

    return (
        <ModalTransition>
            <Modal
                heading={heading}
                actions={[
                    { text: t("Done"), onClick: onDone, isDisabled: isLoading },
                    { text: t("Cancel"), onClick: onCanceled }
                ]}
                height={"100%"}
                width="x-large">
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div className="flexRowContainer">
                        <div className="flexCellContainer" style={{ width: '60%' }}>
                            <Textfield
                                ref={clearSearchQueryRef}
                                placeholder={t("Search")}
                                defaultValue={state.allItemsQuery}
                                onChange={onSearchWrapped}
                                isCompact={true}
                                elemAfterInput={
                                    <div style={{ marginRight: '5px', cursor: 'default' }} onClick={onClearSearchField}>
                                        <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                                    </div>
                                }
                            />
                        </div>
                        <div className="flexCellContainer" style={{ width: '50%' }}>
                            <Select
                                placeholder={t("Database")}
                                options={getMstsOptions(projectMsts)}
                                searchable={true}
                                isClearable={true}
                                spacing='compact'
                                isMulti={true}
                                value={getSelectedMsts()}
                                onChange={onMstSelectionChanged}
                                isLoading={isLoading} />

                        </div>
                    </div>
                    <div style={{ height: '100%' }}>
                        <GeovisTableWithSelectableRows
                            allItems={getAllItems()}
                            getIdFunc={getIdFunc}
                            isLoading={isLoading ?? false}
                            itemToRowFunc={itemToRowFunc}
                            head={getTableHead()}
                            rowsPerPage={30}
                            needSelectAll={false}
                            onSelectionChanged={onSelectionChanged}
                            showPagination={true}
                            selectedItems={getSelectedItems()}
                            showCountRowsSelect={false}
                        />
                    </div>
                </div>
            </Modal>
        </ModalTransition>
    );
}