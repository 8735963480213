/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 18.03.2020
 * @description DXF map tiles layer for local map object
 */

import { TileLayer, TileLayerProps } from 'react-leaflet';
import { MAP_TILES_API_ROOT } from '../../ApiConfig';
import { LeafletMapTilesRoutes } from '../../helpers/MapTilesServiceRoutes';
import { ImageBackgroundInfo } from '../../server/AVTService/TypeLibrary/ImageAsMap/ImageBackgroundInfo';
import AuthService from '../../services/AuthService';
import { ImageMapContentType } from './types';

interface IDXFMapTilesLayerProps {
    projectId: number;
    objectId: string;
    backgroundInfo: ImageBackgroundInfo;
    contentType: ImageMapContentType
}

export const ImageMapDXFMapTilesLayer = ({ projectId, backgroundInfo, contentType }: IDXFMapTilesLayerProps) => {

    if (!backgroundInfo.IsMapTiles) {
        return (null);
    }

    const url = contentType === 'LMO'
        ? LeafletMapTilesRoutes.DxfLmoMapTilesRoute.patch(projectId, backgroundInfo.MapTilesId).path
        : LeafletMapTilesRoutes.DxfGeovisImageMapTilesRoute.patch(projectId, backgroundInfo.MapTilesId).path;

    const tilesUrl = AuthService.getFullUrl(MAP_TILES_API_ROOT, url);
    const tileLayerProps: TileLayerProps = {
        url: tilesUrl,
        noWrap: true,
        zIndex: 100,
    };

    return (
        <TileLayer {...tileLayerProps} />
    );
}