//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DistanceUnit {
	Meter = 0,
	Kilometer = 1
}

const DistanceUnitToName = new Map<number, string>([
	[ 0, 'Meter' ],
	[ 1, 'Kilometer' ],
])

export function getDistanceUnitToName(element: DistanceUnit) : string {
    return DistanceUnitToName.get(element) || '';
}

const DistanceUnitByName = new Map<string, number>([
	[ 'Meter', 0 ],
	[ 'Kilometer', 1 ],
]);

export function getDistanceUnitByName(name: string): DistanceUnit {
    return DistanceUnitByName.get(name)!;
}


export const DistanceUnitList : Readonly<DistanceUnit[]> = [
	DistanceUnit.Meter,
	DistanceUnit.Kilometer,
];



const DistanceUnitToDescription = new Map<number, string>([
	[ 0, 'm' ],
	[ 1, 'km' ],
])

export function getDistanceUnitToDescription(element: DistanceUnit) : string {
    return DistanceUnitToDescription.get(element) || '';
}

