import Pagination from '@atlaskit/pagination';
import { OptionType } from '@atlaskit/select';
import { SyntheticEvent, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getRowsPerPageOptions } from '../../../components/DynamicTableStatelessWithVirtualPagination';
import { RowsPerPageSelect } from '../../../components/RowsPerPageSelect';
import { EmailHistoryRequest } from "../../../server/AVTService/TypeLibrary/Email/EmailHistoryRequest";
import { geovisBusinessMessageHistoryFilterChanged } from "../../../store/creators/businessDataCreators";
import { IGeovisStoreState } from "../../../store/store.types";
import { IGeovisAction } from "../../../store/types";

const rowPerPageOptions: number[] = [50, 100, 200, 500];

interface IMessagesHistoryPaginationControlStateProps {
    filter: EmailHistoryRequest
    countPages: number;
}

interface IMessagesHistoryPaginationControlDispatchProps {
    onFilterChanged: (filter: EmailHistoryRequest) => void;
}

interface IMessagesHistoryPaginationControlProps extends IMessagesHistoryPaginationControlStateProps, IMessagesHistoryPaginationControlDispatchProps {

}

const MessagesHistoryPaginationControl = ({
    filter,
    countPages,
    onFilterChanged
}: IMessagesHistoryPaginationControlProps) => {

    useEffect(() => {
        // 
    }, [filter, countPages])

    const onRowsPerPageChangedHandler = (value: OptionType) => {
        onFilterChanged({ ...filter, RowsPerPage: +value.value });
    }

    const onPageChangedHandler = (event: SyntheticEvent<any>, page: number) => {
        onFilterChanged({ ...filter, PageNumber: page });
    }

    const getPages = (): number[] => {
        const result: number[] = [];

        for (let index = 1; index <= countPages; index++) {
            result.push(index)
        }

        return result;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
            <div style={{ flexGrow: 1 }}>
                <RowsPerPageSelect
                    options={getRowsPerPageOptions(rowPerPageOptions)}
                    selectedValue={filter.RowsPerPage ?? 50}
                    onChange={onRowsPerPageChangedHandler}
                />
            </div>
            {countPages > 1 &&
                <div>
                    <Pagination
                        selectedIndex={filter.PageNumber ?? 1}
                        pages={getPages()}
                        onChange={onPageChangedHandler}
                    />
                </div>
            }
            <div style={{ flexGrow: 1 }}>
                {/*  */}
            </div>
        </div>
    )
}

const mapStateToProps = ({ businessData }: IGeovisStoreState): IMessagesHistoryPaginationControlStateProps => ({
    filter: businessData.messagesStore.historyStore.filter,
    countPages: businessData.messagesStore.historyStore.countPages
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IMessagesHistoryPaginationControlDispatchProps => ({
    onFilterChanged: (filter) => dispatch(geovisBusinessMessageHistoryFilterChanged(filter))
});

export default connect<IMessagesHistoryPaginationControlStateProps, IMessagesHistoryPaginationControlDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(MessagesHistoryPaginationControl)