//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FeedbackState {
	Open = 0,
	InProgress = 1,
	Fixed = 2,
	Closed = 3
}

const FeedbackStateToName = new Map<number, string>([
	[ 0, 'Open' ],
	[ 1, 'InProgress' ],
	[ 2, 'Fixed' ],
	[ 3, 'Closed' ],
])

export function getFeedbackStateToName(element: FeedbackState) : string {
    return FeedbackStateToName.get(element) || '';
}

const FeedbackStateByName = new Map<string, number>([
	[ 'Open', 0 ],
	[ 'InProgress', 1 ],
	[ 'Fixed', 2 ],
	[ 'Closed', 3 ],
]);

export function getFeedbackStateByName(name: string): FeedbackState {
    return FeedbackStateByName.get(name)!;
}


export const FeedbackStateList : Readonly<FeedbackState[]> = [
	FeedbackState.Open,
	FeedbackState.InProgress,
	FeedbackState.Fixed,
	FeedbackState.Closed,
];



const FeedbackStateToDescription = new Map<number, string>([
	[ 0, 'Open' ],
	[ 1, 'InProgress' ],
	[ 2, 'Fixed' ],
	[ 3, 'Closed' ],
])

export function getFeedbackStateToDescription(element: FeedbackState) : string {
    return FeedbackStateToDescription.get(element) || '';
}

