import DynamicTable from "@atlaskit/dynamic-table";
import { HeadType, RowType } from "@atlaskit/dynamic-table/types";
import { SyntheticEvent, useRef, useState } from "react";
import { Checkbox } from "@atlaskit/checkbox";
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { t } from "i18next";
import Textfield from "@atlaskit/textfield";
import { debounce } from "lodash";
import { drawFlag } from "./tools";
import { Country, CountryList, getCountryToDescription } from "../../server/AVTService/TypeLibrary/Sms/Country";
import { isMatchToSearchString } from "../../helpers/FiltersHelper";

interface IComponentProps {
    showDialog: boolean;
    onClose: () => void;
    onSelect: (country: Country) => void;
    currentCountry: Country;
}

interface IComponentState {
    selectedCountry: Country;
    filterString: string;
    sortOrder: string
}

export const GeovisSelectCountryDialog = ({
    currentCountry,
    onClose,
    onSelect,
    showDialog
}: IComponentProps) => {
    if (!showDialog) {
        return null;
    }

    const [state, setState] = useState<IComponentState>({
        selectedCountry: currentCountry,
        filterString: '',
        sortOrder: 'ASC'
    })

    const nameFilterRef = useRef<HTMLInputElement>(null);

    const setCountry = (country: Country) => () => {
        setState({
            ...state,
            selectedCountry: country
        })
    }

    const onRowChecked = (country: Country) => () => {
        setState({
            ...state,
            selectedCountry: country
        })
    }

    const sortCountries = (a: Country, b: Country): number => {
        const nameA = getCountryToDescription(a);
        const nameB = getCountryToDescription(b);

        if (nameA === nameB) {
            return 0;
        }

        return nameA > nameB ? state.sortOrder === 'ASC' ? 1 : -1 : state.sortOrder === 'ASC' ? -1 : 1;
    }

    const getRows = (): RowType[] => {
        return CountryList
            .filter(c => c !== Country.Unknown && isMatchToSearchString(getCountryToDescription(c), state.filterString))
            .sort((a, b) => sortCountries(a, b))
            .map(c => ({
                key: `row-${c}`,
                onClick: setCountry(c),
                cells: [{
                    key: 'selected',
                    content: (
                        <Checkbox
                            isChecked={c === state.selectedCountry}
                            onChange={onRowChecked(c)}
                        />
                    )
                }, {
                    key: 'flag',
                    content: (
                        drawFlag(c)
                    )
                }, {
                    key: 'countryName',
                    content: (<span>{getCountryToDescription(c)}</span>)
                }]
            }))
    }

    const getHead = (): HeadType => {
        return ({
            cells: [{
                key: 'selected',
                width: 2
            }, {
                key: 'flag',
                width: 4,
                content: t("Flag")
            }, {
                key: 'countryName',
                width: 6,
                isSortable: true,
                content: t("Country")
            }]
        })
    }

    const onSelectHandler = () => {
        onSelect(state.selectedCountry);
    }

    const onNameFilterChanged = (event: SyntheticEvent<HTMLInputElement>) => {
        onNameFilterChangedDebounced(event.currentTarget.value);
    }

    const onNameFilterChangedDebounced = debounce((filter) => setState({ ...state, filterString: filter }), 400);

    const onNameFilterClear = () => {
        if (nameFilterRef !== null && nameFilterRef.current !== null) {
            nameFilterRef.current.value = "";
        }
        setState({ ...state, filterString: '' });
    }

    return (
        <ModalTransition>
            <Modal
                heading={t("Select country")}
                width={'400px'}
                height={'400px'}
                actions={[
                    { text: t("Select"), onClick: onSelectHandler, appearance: 'primary' },
                    { text: t("Close"), onClick: onClose, appearance: 'default' },
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '5px' }}>
                        <Textfield
                            defaultValue={state.filterString}
                            isCompact={true}
                            ref={nameFilterRef}
                            onChange={onNameFilterChanged}
                            elemAfterInput={
                                <div style={{ marginRight: '5px', cursor: 'default' }} onClick={onNameFilterClear}>
                                    <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                                </div>
                            }
                        />
                    </div>
                    <DynamicTable
                        rows={getRows()}
                        head={getHead()}
                        sortKey="countryName"
                        sortOrder={state.sortOrder}
                    />
                </div>
            </Modal>
        </ModalTransition>
    )
}