/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 22.05.2020
 * @description Local map objects icon renderers
 */

import { LocalMapObjectCamera } from '../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectCamera';
import {
    defaultLeafletMapIconProps,
    ISVGIconProps
} from '../map/types';

const defaultLocalMapObjectCameraIconProps: ISVGIconProps = {
    ...defaultLeafletMapIconProps,
    className: 'geovis-lmo-camera-icon-svg',
    fillColors: ["#666666"]
}

export const getLocalMapObjectCameraIconProps = (element: LocalMapObjectCamera): ISVGIconProps => ({
    ...defaultLocalMapObjectCameraIconProps,
    iconSize: L.point(12, 12),
    iconScale: 2,
    iconAnchor: L.point(6, 6),
    fillColors: [element.Color],
    fillOpacity: element.Opacity,
    weight: 0.5,
});

export const LocalMapObjectCameraIconRenderer = (props: ISVGIconProps): string => {

    const {
        className,
        iconSize,
        color,
        weight,
        fillColors,
        fillOpacity,
        iconScale
    } = props;

    const fillColor = fillColors[0];

    const svg =
        `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="${className}" style="width:${iconSize.x}px; height:${iconSize.y}px;" transform="scale(${iconScale})">
            <path d="M 4.4 1.42 L 3.18 2.65 L 0.71 2.65 A 0.45 0.45 1 0 0 0.26 3.1 L 0.26 8.48 A 0.45 0.45 1 0 0 0.71 8.93 L 11.29 8.93 A 0.45 0.45 1 0 0 11.74 8.48 L 11.74 3.10 A 0.45 0.45 1 0 0 11.29 2.65 L 8.82 2.65 L 7.64 1.42 z" stroke= "${color}" stroke-width="${weight}" fill="${fillColor}" fill-opacity="${fillOpacity}"/>
            <polygon points="4.75,1.83 4.75,2.9 7.31,2.9 7.31,1.83" style="fill: white; fill-opacity:${fillOpacity}; stroke: ${color}; stroke-width: ${weight};"/>
            <circle cx="6" cy="5.79" r="2.1" style="fill: white; fill-opacity:${fillOpacity}; stroke: ${color}; stroke-width: ${weight};"/>
            <path d="M 6 4.37 A 1.42 1.42 1 0 1 4.58 5.78"/>   
        </svg>`

    return svg;
}