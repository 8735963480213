/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 06.12.2019
 * @description Local map object help methods
 */

import { LocalMapObject } from "../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject";
import { ILocalMapObjectsStorage } from "../data.types";

/**
 * Change IsPublic property of the document
 * @param state Documents storage state
 * @param objectId local map object id
 * @param isPublic IsPublic destination property value
 */
export const switchIsPublicForLocalMapObject = <T extends ILocalMapObjectsStorage>(state: T, objectId: string, isPublic: boolean): T => {

    const objects = new Map<string, LocalMapObject>();

    state.localMapObjects.forEach((obj) => {
        if (obj.Id === objectId) {
            objects.set(obj.Id, { ...obj, IsPublic: isPublic });
        } else {
            objects.set(obj.Id, obj);
        }
    })

    return { ...state, localMapObjects: objects };
}

/**
 * Replace local map objects in storage
 * @param state local map object state
 * @param objects objects to replace
 */
export const updateLocalMapObjects = <T extends ILocalMapObjectsStorage>(state: T, objects: LocalMapObject[]): T => {

    // copy current list of LMO to result
    const result = new Map<string, LocalMapObject>(state.localMapObjects);

    // update expected
    objects.forEach(obj => {
        if (result.has(obj.Id)) {
            result.set(obj.Id, obj);
        }
    })

    return {
        ...state,
        localMapObjects: result
    };
}
