//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GeovisErrorCode {
	EmptyField = 0,
	Duplicate = 1,
	WrongValue = 2
}

const GeovisErrorCodeToName = new Map<number, string>([
	[ 0, 'EmptyField' ],
	[ 1, 'Duplicate' ],
	[ 2, 'WrongValue' ],
])

export function getGeovisErrorCodeToName(element: GeovisErrorCode) : string {
    return GeovisErrorCodeToName.get(element) || '';
}

const GeovisErrorCodeByName = new Map<string, number>([
	[ 'EmptyField', 0 ],
	[ 'Duplicate', 1 ],
	[ 'WrongValue', 2 ],
]);

export function getGeovisErrorCodeByName(name: string): GeovisErrorCode {
    return GeovisErrorCodeByName.get(name)!;
}


export const GeovisErrorCodeList : Readonly<GeovisErrorCode[]> = [
	GeovisErrorCode.EmptyField,
	GeovisErrorCode.Duplicate,
	GeovisErrorCode.WrongValue,
];



const GeovisErrorCodeToDescription = new Map<number, string>([
	[ 0, 'EmptyField' ],
	[ 1, 'Duplicate' ],
	[ 2, 'WrongValue' ],
])

export function getGeovisErrorCodeToDescription(element: GeovisErrorCode) : string {
    return GeovisErrorCodeToDescription.get(element) || '';
}

