/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 26.01.2022
 * @description The tool methods to help to draw sensors data
 */

import { SortOrderType } from "@atlaskit/dynamic-table/dist/types/types";
import moment from "moment";
import { GvSensorDoubleDataInfo } from "../../../../server/AVTService/DB/ArgusDataStorages/Bucket/SensorsData/GvSensorDoubleDataInfo";
import { GvSensorStringDataInfo } from "../../../../server/AVTService/DB/ArgusDataStorages/Bucket/SensorsData/GvSensorStringDataInfo";
import { GvMeasurementDataKind } from "../../../../server/AVTService/TypeLibrary/DB/ArgusDataStorage/SensorsData/GvMeasurementDataKind";
import { GvSensorDataInfo } from "../../../../server/AVTService/TypeLibrary/DB/ArgusDataStorage/SensorsData/GvSensorDataInfo";
import { IGvSensorDataInfoModel } from "../../../../store/edit.types";
import { SensorsDataReferenceColors } from "../types";
import { SensorHashKey } from "./types";
import { MongoDataState } from "../../../../server/AVTService/TypeLibrary/DB/MongoDataState";
import { GeovisSensorsDataFilter } from "../../../../server/AVTService/TypeLibrary/DB/ArgusDataStorage/GeovisSensorsDataFilter";
import { ExportDataType } from "../../../../server/AVTService/TypeLibrary/Export/ExportDataType";

/**
 * Draw sensor values to string
 * @param record 
 * @returns 
 */
export const drawSensorValues = (record: GvSensorDataInfo, decimals?: number): string => {

    switch (record.MeasurementKind) {
        case GvMeasurementDataKind.Axis:
        case GvMeasurementDataKind.Data:
            return drawDoubleSensorValues(record as GvSensorDoubleDataInfo, decimals);

        case GvMeasurementDataKind.String: {
            const stringRecord = record as GvSensorStringDataInfo;

            return stringRecord.StringValue || '';
        }
    }
}

/**
 * Draw sensor double values to string
 * @param record 
 */
export const drawDoubleSensorValues = (record: GvSensorDoubleDataInfo, decimals?: number): string => {
    if (!record.Values || !record.Values.length) {
        return '';
    }

    if (typeof record.Values === "string") {
        return record.Values;
    }

    if (record.Values.length === 1) {
        return isNaN(record.Values[0]) ? 'NaN' : record.Values[0].toFixed(decimals ?? 4);
    }

    return record.Values.map(v => isNaN(v) ? 'NaN' : v.toFixed(decimals ?? 4)).join('; ');
}

export const getNextColorInPalette = (colorKey: string, usedTimesMap: Map<string, string>): string => {

    if (!colorKey) {
        return '';
    }

    const exist = usedTimesMap.get(colorKey);
    if (exist) {
        return exist;
    }

    const colorIndex = usedTimesMap.size % SensorsDataReferenceColors.length;
    const color = SensorsDataReferenceColors[colorIndex];
    usedTimesMap.set(colorKey, color);

    return color;
}

export const isOffsetTimeRecord = (r: GvSensorDoubleDataInfo): boolean => r.IsOffsetTime;

export const isReferenceTimeRecord = (r: GvSensorDoubleDataInfo): boolean => r.IsReferenceTime;

export const isNullMeasurementTimeRecord = (r: GvSensorDoubleDataInfo): boolean => r.IsZeroMeasurementTime;

export const sortSensorData = (data: IGvSensorDataInfoModel[], columnKey: string, sortOrder: SortOrderType): IGvSensorDataInfoModel[] => {

    const datetimeToNumber = (datetime: string): number => datetime ? moment(datetime).unix() : 0;

    if (columnKey === 'timeslot' && sortOrder === 'ASC') {
        return data.sort((a, b) => datetimeToNumber(a.record.TimeSlot) - datetimeToNumber(b.record.TimeSlot));
    } else if (columnKey === 'timeslot' && sortOrder === 'DESC') {
        return data.sort((a, b) => datetimeToNumber(b.record.TimeSlot) - datetimeToNumber(a.record.TimeSlot));
    } else if (columnKey === 'exact-time' && sortOrder === 'ASC') {
        return data.sort((a, b) => datetimeToNumber(a.record.ExactTime) - datetimeToNumber(b.record.ExactTime));
    } else if (columnKey === 'exact-time' && sortOrder === 'DESC') {
        return data.sort((a, b) => datetimeToNumber(b.record.ExactTime) - datetimeToNumber(a.record.ExactTime));
    } else {
        throw Error(`Change sensor data sort: unsupported column [${columnKey}] or sort order [${sortOrder}]`)
    }
}

export const parseSensorsDataHash = (hashString: string): GeovisSensorsDataFilter => {

    const defaultFilter: GeovisSensorsDataFilter = {
        AxisRecords: false,
        DataType: ExportDataType.Raw,
        FullIds: [],
        RawRecords: false,
        States: [],
        EndTime: undefined,
        EndValue: undefined,
        StartTime: undefined,
        StartValue: undefined
    }

    const hashArray = hashString.replace("#", "").split(";");

    if (hashArray.length === 0) {
        return defaultFilter;
    }

    hashArray.forEach(item => {
        const parsedItems = item.split("=");
        if (parsedItems.length === 2) {
            switch (parsedItems[0] as SensorHashKey) {
                case "DB":
                    break;
                case "End":
                    defaultFilter.EndTime = parsedItems[1];
                    break;
                case "FullIds": {
                    const fullIds = parsedItems[1].split(",");
                    defaultFilter.FullIds = fullIds;
                    break;
                }
                case "Start":
                    defaultFilter.StartTime = parsedItems[1];
                    break;
                case "States": {
                    const states = parsedItems[1].split(",");
                    defaultFilter.States = states.map(s => +s as MongoDataState);
                    break;
                }
                case "Type":
                    defaultFilter.DataType = +parsedItems[1];
                    break;
                case "VF":
                    defaultFilter.StartValue = +parsedItems[1];
                    break;
                case "VT":
                    defaultFilter.EndValue = +parsedItems[1];
                    break;
                case "UseAxis":
                    defaultFilter.AxisRecords = +parsedItems[1] > 0;
                    break;
                case "UseRaw":
                    defaultFilter.RawRecords = +parsedItems[1] > 0;
                    break;
            }
        }
    });


    return defaultFilter;
}

export const createHashForSensorsData = (filter: GeovisSensorsDataFilter): string => {
    let hashResult = "";
    if (filter.FullIds.length > 0) {
        hashResult = `#FullIds=${filter.FullIds.join(",")};`
    }
    else {
        return hashResult;
    }

    if (filter.AxisRecords) {
        hashResult += "UseAxis=1;"
    }

    if (filter.RawRecords) {
        hashResult += "UseRaw=1;"
    }

    if (filter.EndTime) {
        hashResult += `End=${filter.EndTime};`
    }

    if (filter.StartTime) {
        hashResult += `Start=${filter.StartTime};`
    }

    if (filter.StartValue !== undefined) {
        hashResult += `VF=${filter.StartValue};`
    }

    if (filter.EndValue !== undefined) {
        hashResult += `VT=${filter.EndValue};`
    }

    if (filter.States.length > 0) {
        hashResult += `States=${filter.States.join(",")};`
    }

    hashResult += `Type=${filter.DataType};`;

    return hashResult;
}