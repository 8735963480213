// interface ISensorsListFilter {
//     databasesIds: string[];
//     sensorName: string;
//     shouldMatchCase: boolean;
//     units: PhysicalUnit[];
//     categories: SensorCategory[];
//     isPublic: number;
// }

import { IProjectRouteParams } from "../../../helpers/IRouteParams";

export enum Formulas {
    abs = 0,
    sqrt = 1,
    ln = 2,
    sin = 3,
    cos = 4,
    tan = 5,
    cotan = 6,
    arcsin = 7,
    arccos = 8,
    arctan = 9,
    length = 10,
    deviation = 11,
    deviationX = 13,
    deviationY = 14,
    deviationZ = 15,
    deviationXY = 16,
    deviationAxis = 17,
    deviationAxisX = 18,
    prev = 19,
    prevtime = 20,
    translate = 21,
    interpolate = 22,
    meas = 23,
    measDate = 24,
    length2d = 25
}

export const FormulasList: Readonly<Formulas[]> = [
    Formulas.abs,
    Formulas.sqrt,
    Formulas.ln,
    Formulas.sin,
    Formulas.cos,
    Formulas.tan,
    Formulas.cotan,
    Formulas.arcsin,
    Formulas.arccos,
    Formulas.arctan,
    Formulas.length,
    Formulas.length2d,
    Formulas.deviation,
    Formulas.deviationX,
    Formulas.deviationY,
    Formulas.deviationZ,
    Formulas.deviationXY,
    Formulas.deviationAxis,
    Formulas.deviationAxisX,
    Formulas.prev,
    Formulas.prevtime,
    Formulas.translate,
    Formulas.interpolate,
    Formulas.meas,
    Formulas.measDate
];

const FormulasToName = new Map<number, string>([
    [0, 'Abs'],
    [1, 'Sqrt'],
    [2, 'Ln'],
    [3, 'Sin'],
    [4, 'Cos'],
    [5, 'Tan'],
    [6, 'CoTan'],
    [7, 'ArcSin'],
    [8, 'ArcCos'],
    [9, 'ArcTan'],
    [10, 'Length'],
    [11, 'Deviation'],
    [13, 'Deviation X'],
    [14, 'Deviation Y'],
    [15, 'Deviation Z'],
    [16, 'Deviation XY'],
    [17, 'Deviation Axis'],
    [18, 'Deviation Axis(x)'],
    [19, 'Prev'],
    [20, 'PrevTime'],
    [21, 'Translate'],
    [22, 'Interpolate'],
    [23, 'Meas'],
    [24, 'Meas (Date)'],
    [25, 'Length2d']
])

export function getFormulasToName(element: Formulas): string {
    return FormulasToName.get(element) || '';
}

const FormulasToDescription = new Map<number, string>([
    [0, 'Absolute value'],
    [1, 'Square root'],
    [2, 'Natural logarithm'],
    [3, 'Sinus'],
    [4, 'Cosine'],
    [5, 'Tangent'],
    [6, 'Cotangent'],
    [7, 'Arcsine'],
    [8, 'Arccosine'],
    [9, 'Arctangent'],
    [10, 'Vector length functions, returns the absolute value of a vector: (length([id]) = sqrt(id[0]^2 + id[1]^2 + id[2]^2))'],
    [11, 'Deviation from reference value: (deviation(id) = abs(id-id.reference)). For 3D sensors returns deviation as a absolute value: deviation(id) = sqrt((id[0]-id.reference[0])^2 + (id[1]-id.reference[1])^2 + (id[2]-id.reference[2])^2)'],
    [13, 'Deviation in particular direction (only works if sensors has three values)'],
    [14, 'Deviation in particular direction (only works if sensors has three values)'],
    [15, 'Deviation in particular direction (only works if sensors has three values)'],
    [16, 'Deviation in particular direction (only works if sensors has three values)'],
    [17, 'Returns deviation as a absolute Axis value: deviationAXIS(id) = sqrt(sum(id.axis[n]-id.axisreference[n])^2), where n = [0, size vector - 1]'],
    [18, "deviationAXIS(id)[x] = abs(id.axis[x]-id.axisreference[x]), where x from 0 to 'size vector - 1'"],
    [19, 'Returns previous value. The prev(id, 5) is equal to time[-5] value of sensor ‘id’ (index ‘0’ is a time-reference for current value of sensor i.e. the same as ‘id’); this expression can be used for calculating of sensor deviation during time e.g. d = length(id – prev(id,1))'],
    [20, "Returns data of previous sensor's timeslot"],
    [21, 'Translate sensor data for id[0] and id[1]: = X - A*T^3 + B*T^2 + C*T + D, (T - value for the transformation ; A, B, C and D are coefficients for sensor).'],
    [22, "Interpolate the sensor's data"],
    [23, 'Meas'],
    [24, 'Meas (Date)'],
    [25, 'Vector length functions for first two components of vector: (length2d([id]) = sqrt(id[0]^2 + id[1]^2))'],
])

export function getFormulasToDescription(element: Formulas): string {
    return FormulasToDescription.get(element) || '';
}

const FormulasToLexem = new Map<number, string>([
    [0, 'abs'],
    [1, 'sqrt'],
    [2, 'ln'],
    [3, 'sin'],
    [4, 'cos'],
    [5, 'tan'],
    [6, 'cotan'],
    [7, 'arcsin'],
    [8, 'arccos'],
    [9, 'arctan'],
    [10, 'length'],
    [11, 'deviation'],
    [13, 'deviationX'],
    [14, 'deviationY'],
    [15, 'deviationZ'],
    [16, 'deviationXY'],
    [17, 'deviationAXIS'],
    [18, 'deviationAXIS'],
    [19, 'prev'],
    [20, 'prevtime'],
    [21, 'translate'],
    [22, 'interpolate'],
    [23, 'Meas'],
    [24, 'Meas'],
    [25, 'length2d']
])

export function getFormulasToLexem(element: Formulas): string {
    return FormulasToLexem.get(element) || '';
}

export enum Filters {
    Min = 0,
    Max = 1,
    Mean = 2,
    Median = 3,
    TrimmedMean = 4
}

const FiltersToName = new Map<number, string>([
    [0, 'Min'],
    [1, 'Max'],
    [2, 'Mean'],
    [3, 'Median'],
    [4, 'Trimmed Mean'],
])

export function getFiltersToName(element: Filters): string {
    return FiltersToName.get(element) || '';
}

const FiltersToDescription = new Map<number, string>([
    [0, 'Minimum value'],
    [1, 'Maximum value'],
    [2, 'Mean value'],
    [3, 'Median value'],
    [4, "Special filters: mean value, but ignores the highest and the lowest value. Don't work with arrays and x has to be 3 or bigger"],
])

export function getFiltersToDescription(element: Filters): string {
    return FiltersToDescription.get(element) || '';
}

const FiltersToLexem = new Map<number, string>([
    [0, 'min'],
    [1, 'max'],
    [2, 'mean'],
    [3, 'median'],
    [4, 'trimmedmean'],
])

export function getFiltersToLexem(element: Filters): string {
    return FiltersToLexem.get(element) || '';
}
export const FiltersList: Readonly<Filters[]> = [
    Filters.Min,
    Filters.Max,
    Filters.Mean,
    Filters.Median,
    Filters.TrimmedMean
];

export type FormulaParams =
    "devAxis_X"
    | "prev_x"
    | "prevTime_T"
    | "prevTime_D"
    | "translate_T"
    | "interpolate_T"
    | "interpolate_SD"
    | "interpolate_O"
    | "interpolate_N"
    | "interpolate_TO"
    | "meas_x"
    | "meas_d"
    | "meas_c";

export type FilterParams = "min" | "max" | "mean" | "median" | "trimmedMean";


export enum InterpolateModes {
    Normal = 0,
    Fast = 1,
    NormalMed = 2,
    NormalMedBack = 3,
}

const InterpolateModesToName = new Map<number, string>([
    [0, 'Normal Mode (average)'],
    [1, 'Fast Mode'],
    [2, 'Normal Mode (median)'],
    [3, 'Normal Mode (median/only backwards)']
])

export function getInterpolateModesToName(element: InterpolateModes): string {
    return InterpolateModesToName.get(element) || '';
}

export const InterpolateModesList: Readonly<InterpolateModes[]> = [
    InterpolateModes.Normal,
    InterpolateModes.Fast,
    InterpolateModes.NormalMed,
    InterpolateModes.NormalMedBack
];

export enum SensorProperties {
    None = 0,
    Index = 1,
    Raw = 2,
    Value = 3,
    Offset = 4,
    Reference = 5,
    Relative = 6,
    Axis = 7,
    AxisOffset = 8,
    AxisReference = 9,
    AxisRelative = 10,
    Param1 = 11,
    Param2 = 12,
    Param3 = 13,
    Param4 = 14,
    Param5 = 15,
    Km = 16,
    Time = 17
}


const SensorPropertiesToName = new Map<number, string>([
    [0, 'None'],
    [1, 'Index'],
    [2, 'Raw'],
    [3, 'Value'],
    [4, 'Offset'],
    [5, 'Reference'],
    [6, 'Relative'],
    [7, 'Axis'],
    [8, 'AxisOffset'],
    [9, 'AxisReference'],
    [10, 'AxisRelative'],
    [11, 'Param 1'],
    [12, 'Param 2'],
    [13, 'Param 3'],
    [14, 'Param 4'],
    [15, 'Param 5'],
    [16, 'Km'],
    [17, 'Time'],
])

export function getSensorPropertiesToName(element: SensorProperties): string {
    return SensorPropertiesToName.get(element) || '';
}

export const SensorPropertiesList: Readonly<SensorProperties[]> = [
    SensorProperties.None,
    SensorProperties.Index,
    SensorProperties.Raw,
    SensorProperties.Value,
    SensorProperties.Offset,
    SensorProperties.Reference,
    SensorProperties.Relative,
    SensorProperties.Axis,
    SensorProperties.AxisOffset,
    SensorProperties.AxisReference,
    SensorProperties.AxisRelative,
    SensorProperties.Param1,
    SensorProperties.Param2,
    SensorProperties.Param3,
    SensorProperties.Param4,
    SensorProperties.Param5,
    SensorProperties.Km,
    SensorProperties.Time
];

const SensorPropertiesToLexem = new Map<number, string>([
    [0, ''],
    [1, ''],
    [2, '.raw'],
    [3, '.value'],
    [4, '.offset'],
    [5, '.reference'],
    [6, '.relative'],
    [7, '.axis'],
    [8, '.axisoffset'],
    [9, '.axisreference'],
    [10, '.axisrelative'],
    [11, '.param1'],
    [12, '.param2'],
    [13, '.param3'],
    [14, '.param4'],
    [15, '.param5'],
    [16, '.km'],
    [17, '.time'],
])

export function getSensorPropertiesToLexem(element: SensorProperties): string {
    return SensorPropertiesToLexem.get(element) || '';
}

/**
 * Sensor types enum
 */
export enum ProjectEditSensorsTab {
    /**
     * Default sensors
     */
    Standard = "standard",

    /**
     * Chains
     */
    Chains = "chains",

    /**
     * Vibration sensors
     */
    Vibration = "vibration"
}

export interface ISensorsDialogCommonProps {
    projectId: number;

    /**
     * Confirm dialog action
     * @returns 
     */
    onOk: () => void;

    onCancel: () => void;
}

/**
 * Edit sensor route param
 */
export interface IProjectSensorsRouteParams extends IProjectRouteParams {
    type: ProjectEditSensorsTab | undefined;
}


/**
 * 
 */
export interface IEditProjectSensorsRouteParams extends IProjectSensorsRouteParams {
    fullId: string | undefined;
}