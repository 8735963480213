export const fixHref = (original: string): string => {

    if (original === null) {
        return "";
    }

    let href = original;

    if (href.startsWith("http://")) {
        href = href.substring("http://".length);
    } else if (href.startsWith("https://")) {
        href = href.substring("https://".length);
    }

    href = "http://" + href;

    return href;
}