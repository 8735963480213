/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 29.10.2019
 * @description Local map object polyline render
 */

import { Polyline, PolylineProps } from 'react-leaflet';
import uuid from 'uuid';
import { locationToMapCoordinates } from '../../../helpers/MapHelper';
import { fixHref } from '../../../helpers/UrlHelper';
import { LocalMapObjectContentType } from '../../../server/LocalMapObjectContentType';
import { LocalMapObjectPolyline } from '../../../server/LocalMapObjectPolyline';
import { ILocalMapShapeOptions } from '../ILocalMapShapeOptions';
import { LocalMapObjectViewMode } from '../types';
import {
    LocalMapObjectPopup,
    LocalMapObjectTooltip
} from './LocalMapObjectMapElements';
import { getLocalMapObjectShapeClassName } from './tools';

interface ILocalMapObjectPolylineProps {
    element: LocalMapObjectPolyline;
    viewMode: LocalMapObjectViewMode;

    onEdit: (id: string) => void;
    onRemove: (id: string) => void;
    onClick: (id: string) => void;
    onOpenFile: (documentId: string) => void;
    onOpenView: (viewId: string) => void;
    onOpenReport: (reportId: number) => void;
}

export const LocalMapObjectPolylineShape = ({
    element,
    viewMode,
    onEdit,
    onRemove,
    onClick,
    onOpenFile: onDownloadFile,
    onOpenReport,
    onOpenView
}: ILocalMapObjectPolylineProps) => {

    const shapeOptions: ILocalMapShapeOptions = {
        localMapObjectId: element.Id,
        localMapObjectType: element.ObjectType
    };

    const polylineProps: PolylineProps = {
        positions: element.LocationPoints.map(l => locationToMapCoordinates(l)),
        stroke: true,
        weight: element.Weight,
        color: element.Color,
        opacity: element.Opacity,
        className: getLocalMapObjectShapeClassName(viewMode)
    };

    const onClickHandler = (event: L.LeafletMouseEvent) => {

        if (viewMode === LocalMapObjectViewMode.View || event.originalEvent.ctrlKey) {
            if (element.ContentType === LocalMapObjectContentType.Link) {

                const href = fixHref(element.Link);
                window.open(href, "_blank");

                event.originalEvent.preventDefault();
                return;
            }
            else if (element.ContentType === LocalMapObjectContentType.Document) {
                onDownloadFile(element.DocumentId);
            }
            else if (element.ContentType === LocalMapObjectContentType.Report) {
                onOpenReport(element.ReportId);
            }
            else if (element.ContentType === LocalMapObjectContentType.View) {
                onOpenView(element.ViewId);
            }
            else {
                onClick(element.Id);
            }
        }
    }

    /*
    the problem that Polyline doesn't changing according to edit handlers is known, but reasons of that is in the dark.
    the issue is described in https://stackoverflow.com/questions/52236250/why-do-multiple-react-renders-break-leaflet-draw-editing-and-how-do-i-fix-it
    in this case the key was introduced as UUID element, instead of Math.random().

    second problem not found yet
    */
    const key = uuid.v4();

    const getChildren = (): JSX.Element[] => {
        const result: JSX.Element[] = [];
        if (viewMode !== LocalMapObjectViewMode.MapSection) {
            result.push(<LocalMapObjectPopup key={`lmo-${element.Id}-popup-key`} element={element} onEdit={onEdit} onRemove={onRemove} editMode={viewMode === LocalMapObjectViewMode.Edit} />)
        }
        result.push(<LocalMapObjectTooltip key={`lmo-${element.Id}-tooltip-key`} element={element} />);

        return result;
    }

    return (
        <Polyline
            {...polylineProps}
            {...shapeOptions}
            key={key}
            eventHandlers={viewMode === LocalMapObjectViewMode.MapSection ? undefined : { 
                click: onClickHandler 
            }}>
            {getChildren()}
        </Polyline>
    )
}