/**
 * Definition and implementation simple icon primitives as SVG elements
 */

import { Point } from 'leaflet';
import { SensorMapTextOrientation } from '../../server/AVTService/TypeLibrary/Common/SensorMapTextOrientation';
import { getMapTextOrientationCssProps } from './textOrientation.tools';
import {
    defaultPrismIconProps,
    defaultSensorSymbolIconProps,
    ISVGIconProps
} from './types';
import { SensorSymbol } from '../../server/AVTService/TypeLibrary/Sensors/SensorSymbol';

/**
 * Get transformation for Shape
 * @param props 
 */
export const getShapeTransform = (props: ISVGIconProps): string => {
    const actions: string[] = [];

    // add movement, because the left/top border is not visible
    if (props.stackedIconMode) {
        let translateX = 0;
        let translateY = 0;

        const { transformTo } = props;
        if (transformTo) {
            translateX += transformTo.x;
            translateY += transformTo.y;
        }

        // add weight offset, but not for "prism";
        if (props.className !== defaultPrismIconProps.className) {
            translateX += props.weight;
            translateY += props.weight;
        }

        if (translateX > 0 || translateY > 0) {
            actions.push(`translate(${translateX}, ${translateY})`);
        }
    }
    else {
        if (props.className === defaultSensorSymbolIconProps.className) {
            const { transformTo } = props;
            if (transformTo && (Math.abs(transformTo.x) > 0 || Math.abs(transformTo.y) > 0)) {
                actions.push(`translate(${transformTo.x}, ${transformTo.y})`);
            }
        }
        else {
            // disable translation of no weight or it is circle
            // circle has translate position by cx,cy -> iconAnchor
            if (props.weight !== 0 && props.className !== defaultPrismIconProps.className) {
                actions.push(`translate(${props.weight}, ${props.weight})`);
            }
        }
    }

    if (actions.length > 0) {
        return ` transform="${actions.join(', ')}" `;
    }

    return '';
}

/**
 * The Circle primitive as SVG
 * @param props Circle properties
 */
export const circleIconPrimitive = (props: ISVGIconProps): string => {

    const size = props.iconSize.x;
    const cx = props.iconAnchor.x;
    const cy = props.iconAnchor.y;
    const radius = size / 2 - props.weight;

    const transform = getShapeTransform(props);

    const circle = '<circle class="' + props.className +
        '" cx="' + cx +
        '" cy="' + cy +
        '" r="' + radius +
        '" fill="' + props.fillColors[0] +
        '" fill-opacity="' + props.fillOpacity +
        '" stroke="' + props.color +
        '" stroke-opacity="' + props.opacity +
        '" stroke-width="' + props.weight + '"' +
        transform + '/>'

    return circle
}

export const dotIconPrimitive = (props: ISVGIconProps): string => {
    const cx = props.iconAnchor.x;
    const cy = props.iconAnchor.y;
    const radius = 0.1;

    const transform = getShapeTransform(props);

    const circle = '<circle class="' + props.className +
        '" cx="' + cx +
        '" cy="' + cy +
        '" r="' + radius +
        '" fill="' + props.fillColors[0] +
        '" fill-opacity="' + props.fillOpacity +
        '" stroke="' + props.color +
        '" stroke-opacity="' + props.opacity +
        '" stroke-width="' + props.weight + '"' +
        transform + '/>'

    return circle
}

/**
 * The Project state flag as SVG
 * iconAnchor - moves icon on values, which are in iconAnchor property
 * IMPORTANT: doesn't support noSvgWrap and noScale (getShapeTransform)
 * @param props Icon properties
 */
export const projectStateFlagPrimitive = (props: ISVGIconProps): string => {

    const width = props.iconSize.x;
    const height = props.iconSize.y;

    const moveX = props.iconAnchor.x;
    const moveY = props.iconAnchor.y;

    const x1 = width;
    const y1 = height;

    const x2 = x1;
    const y2 = y1 / 2;

    const x3 = 0;
    const y3 = y2 / 2;

    const x4 = x1;
    const y4 = 0;

    const d = `M${x1},${y1} L${x2},${y2} L${x3},${y3} L${x4},${y4} Z`;

    const path = '<path class="' + props.className +
        '" d="' + d +
        '" stroke-width="' + props.weight +
        '" stroke="' + props.color +
        '" stroke-opacity="' + props.opacity +
        '" fill="' + props.fillColors[0] +
        '" fill-opacity="' + props.fillOpacity +
        '" transform="translate(' + moveX + ',' + moveY + ')"/>'

    return path;
}

export const rectanglePrimitive = (props: ISVGIconProps): string => {
    const transform = getShapeTransform(props);
    const { iconSize, weight } = props
    return `<rect width="${iconSize.x - 2 * weight}" height="${iconSize.y - 2 * weight}" style="${svgSensorIconStyle(props)}" ${transform} />`;
}

export const AirPressurePrimitivePoints = '0,5 6,0 12,5 9,12 3,12';
export const InclinometerPrimitivePoint = '0,4 4,4 4,0 8,0 8,4 12,4 12,8 8,8 8,12 4,12 4,8 0,8';
export const LoadCellPrimitivePoints = '4,0 8,0 12,6 8,12 4,12 0,6';
export const WindSeedPrimitivePoints = '1,4 12,4 12,0 18,6 12,12 12,8 1,8';
export const VirtualSensorPrimitivePoints = '0,6 6,12 6,8 17,8 17,4 6,4 6,0';
export const CounterPrimitivePoints = '1,0 12,0 18,4 12,8 1,8';
export const VPrimitivePoints = '0,6 6,0 12,6 6,12';
export const SolarRadiationPrimitivePoints = '0,0 8,0 8,10 17,10 17,17 0,17';
export const VoltagePrimitivePoints = '8,0 6.5,5 0,5 5,10 4,16 8,12 12,16 11,10 16,5 9.5 5';
export const CurrentLoopPrimitivePoints = '8,0 12,2 10,2 10,6 14,6 14,4 16,8 14,12 14,10 2,10 2,12 0,8 2,4 2,6 6,6 6,2 4,2';
export const UnknownPrimitivePoints = '4,1 8,1 4,5 8,5 0,14 3,7 0,7';
export const PhFPrimitivePoints = '0,0 12,6 0,12';
export const DistancePrimitivePoints = '0,6 6,0 6,4 18,4 18,0 24,6 18,12 18,8 6,8 6,12';
export const FlowPrimitivePoints = '3.5,12 8.5,12 8.5,5 12,5 6,0 0,5 3.5,5';
export const GPSPrimitivePoints = '0,3 3,0 6,3 9,0 12,3 9,6 12,9 9,12 6,9 3,12 0,9 3,6';
export const InclinometerChainPositionPrimitiveOuterPoints = 'M 0,0 L 12,0 L 12,12 L 0,12 z';
export const InclinometerChainPositionPrimitiveInnerPoints = 'M 3,3 L 9,3 L 9,9 L 3,9 z';
export const PfVPrimitivePoints = '6,0 12,12 0,12';
export const RainmeterPrimitivePoints = '3.5,0 8.5,0 8.5,7 12,7 6,12 0,7 3.5,7';

export const TriangleRightPoints = '0,0 12,6 0,12';
export const TriangleLeftPoints = '0,6 12,12 12,0';
export const TriangleDownPoints = '0,0 6,12 12,0';
export const TriangleUpPoints = '0,12 6,0 12,12';
export const RombPoints = '0,6 6,0 12,6 6,12';
// export const StarPoints = '8,0 6.5,5 0,5 5,10 4,16 8,12 12,16 11,10 16,5 9.5 5';
export const StarPoints = '8,2 6,7.5 0,6 5,10 3,16 8,12 13,16 11,10 16,6 10,7.5';
export const PlusPoints = '0,4 4,4 4,0 8,0 8,4 12,4 12,8 8,8 8,12 4,12 4,8 0,8';

export interface ISVGProps {
    width: number;
    height: number;
    className?: string;
    iconScale?: number;
    iconTextElement?: string;
    stackedIconMode?: boolean;
}

export interface ISVGIconTextProps {
    iconText: string;
    fontSize: number;
    fontWeight: string;
    iconSize: Point;
    textOrientation: SensorMapTextOrientation | false;
    fontColor: string;
    linesUnder?: number;
    backgroundColor: string;
    showBorder: boolean;
    iconAnchor: Point;
    noScale: boolean;
    sensorSymbol?: SensorSymbol;
    topAddition?: number;
    leftAddition?: number;
}

export const withSvg = (props: ISVGProps) => {
    const { className, width, height, iconTextElement } = props;
    const style = "width:" + width + "px; height:" + height + "px; z-index:200;";

    const transform = props.iconScale !== undefined
        ? `transform="scale(${props.iconScale})"`
        : '';

    const textElement = iconTextElement !== undefined ? iconTextElement : '';

    // if no wrap of SVG tag, then return only 
    if (props.stackedIconMode) {
        return (primitiveSvg: string) => primitiveSvg;
    }

    return (primitiveSvg: string) => {
        return '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="' + className + '" style="' + style + '" ' + transform + '>'
            + primitiveSvg
            + '</svg>'
            + textElement
    }
};

export const svgIconTextDiv = ({
    iconText,
    fontSize,
    fontWeight,
    iconSize,
    textOrientation,
    fontColor,
    linesUnder,
    backgroundColor,
    showBorder,
    iconAnchor,
    noScale,
    leftAddition,
    topAddition
}: ISVGIconTextProps) => {
    const lineUnderMultiplier = linesUnder ?? 0;
    const fontStyle = `font-size:${fontSize}px;
                        font-weight:${fontWeight};
                        position: absolute;
                        color:${fontColor};
                        background: ${backgroundColor};
                        border-radius: 4px;
                        border: ${showBorder ? "solid 1px" : "none"};
                        padding: ${showBorder ? "1px" : "0px"};
                        display: inline-block;
                        white-space: nowrap;
                        ` + getMapTextOrientationCssProps({ iconSize, iconText, fontSize, textOrientation, linesUnder: lineUnderMultiplier, iconAnchor, noScale, showBorder, leftAddition, topAddition });

    return "<div style=\"" + fontStyle + "\">" + iconText + "</div>";
}

export const svgIconTextOnlyDiv = ({
    iconText,
    fontSize,
    fontWeight,
    iconSize,
    textOrientation,
    fontColor,
    linesUnder,
    showBorder,
    iconAnchor,
    noScale,
    sensorSymbol,
    leftAddition,
    topAddition
}: ISVGIconTextProps) => {
    // const lineUnderMultiplier = linesUnder ?? 0;
    const fontStyle = `font-size:${fontSize}px;
                        font-weight:${fontWeight};
                        color:${fontColor};
                        background: #ffffffd5;
                        position: absolute;
                        border-radius: 4px;
                        border: ${showBorder ? "solid 1px" : "none"};
                        padding: ${showBorder ? "1px" : "0px"};
                        display: inline-block;
                        white-space: nowrap;
                        ` + getMapTextOrientationCssProps({ iconSize, iconText, fontSize, textOrientation, linesUnder: linesUnder ?? 0, iconAnchor, noScale, showBorder, sensorSymbol, leftAddition, topAddition });

    return "<div style=\"" + fontStyle + "\">" + iconText + "</div>";
}

export const withSvgOfIconProps = (iconProps: ISVGIconProps) => {
    const {
        className,
        iconScale,
        iconSize,
        iconText,
        textOrientation,
        fontSize,
        fontWeight,
        stackedIconMode,
        fontColor,
        countLinesUnder,
        backgroundColor,
        showBorder,
        iconAnchor,
        noScale,
        leftAddition,
        topAddition
    } = iconProps;

    const iconTextElement = iconText
        ? svgIconTextDiv({
            iconText,
            fontSize,
            fontWeight,
            iconSize,
            textOrientation,
            fontColor,
            linesUnder: countLinesUnder ?? 0,
            backgroundColor: backgroundColor ?? 'lightyellow',
            showBorder: showBorder ?? false,
            iconAnchor,
            noScale,
            topAddition,
            leftAddition
        })
        : '';

    const svgProps: ISVGProps = {
        className,
        iconScale,
        width: iconSize.x,
        height: iconSize.y,
        iconTextElement,
        stackedIconMode
    };

    return withSvg(svgProps);
}

export const svgSensorIconStyle = (props: ISVGIconProps) => {

    const {
        fillColors,
        fillOpacity,
        color,
        weight
    } = props;

    return `fill: ${fillColors[0]}; fill-opacity: ${fillOpacity}; stroke: ${color}; stroke-width: ${weight}`;
}

export const legendSvgItemProps = (): ISVGProps => {
    return {
        height: 18,
        width: 18
    };
}

export const wrapPolygonSvg = (points: string, props: ISVGIconProps): string => {
    const transform = getShapeTransform(props);
    const svg = `<polygon points="${points}" style="${svgSensorIconStyle(props)}" ${transform} />`;
    return svg;
}

/**
 * Extend icon size
 * @param original 
 * @param newOneProps - new icon properties
 */
export const extendIconSize = (original: L.Point, newOne: L.Point): L.Point => {

    if (original.x > newOne.x && original.y > newOne.y) {
        return original;
    }

    return L.point(Math.max(original.x, newOne.x), Math.max(original.y, newOne.y));
}

/**
 * Calculate icon size in pixels within scaling
 * @param props 
 */
export const getIconSizeWithScale = (props: ISVGIconProps): L.Point => {
    const { noScale, iconScale, iconSize } = props;
    if (noScale || iconScale === 1) {
        return iconSize;
    }

    const x = iconScale * iconSize.x;
    const y = iconScale * iconSize.y;

    return L.point(x, y);
}

/**
 * Extend the icon size with scaling
 * @param origin 
 * @param oneIconProps 
 */
export const extendIconSizeWithScale = (origin: L.Point, oneIconProps: ISVGIconProps): L.Point => {
    const newOne = getIconSizeWithScale(oneIconProps);
    return extendIconSize(origin, newOne);
}