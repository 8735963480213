/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 09.04.2020
 * @description Common types for 
 */

export enum LogosViewMode {
    OwnLogos,
    GroupByCompanies,
    GroupByProject
}

export enum ProjectLogoKind {
    Company,
    User
}

export const LogoFileExtensions = ['.png', '.jpg', '.gif'];