//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum InventoryAtcSensorOnlineStatus {
	Offline = 0,
	Online = 1
}

const InventoryAtcSensorOnlineStatusToName = new Map<number, string>([
	[ 0, 'Offline' ],
	[ 1, 'Online' ],
])

export function getInventoryAtcSensorOnlineStatusToName(element: InventoryAtcSensorOnlineStatus) : string {
    return InventoryAtcSensorOnlineStatusToName.get(element) || '';
}

const InventoryAtcSensorOnlineStatusByName = new Map<string, number>([
	[ 'Offline', 0 ],
	[ 'Online', 1 ],
]);

export function getInventoryAtcSensorOnlineStatusByName(name: string): InventoryAtcSensorOnlineStatus {
    return InventoryAtcSensorOnlineStatusByName.get(name)!;
}


export const InventoryAtcSensorOnlineStatusList : Readonly<InventoryAtcSensorOnlineStatus[]> = [
	InventoryAtcSensorOnlineStatus.Offline,
	InventoryAtcSensorOnlineStatus.Online,
];



const InventoryAtcSensorOnlineStatusToDescription = new Map<number, string>([
	[ 0, 'Offline' ],
	[ 1, 'Online' ],
])

export function getInventoryAtcSensorOnlineStatusToDescription(element: InventoryAtcSensorOnlineStatus) : string {
    return InventoryAtcSensorOnlineStatusToDescription.get(element) || '';
}

