/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 06.05.2022
 * @description Tool methods for chart rendering
 */

import { formattedDateTime } from "../../../../../../helpers/DateHelper";
import { AlarmChartValueType } from "../../../../../../server/AVTService/TypeLibrary/Common/AlarmChartValueType";
import { ChartType } from "../../../../../../server/AVTService/TypeLibrary/Common/ChartType";
import { SensorAttribyteEntry } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorAttribyteEntry";
import { SensorValueAttribute } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { GeovisChartSlimConfig } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartSlimConfig";
import { HeatmapSensorInfo } from "../../../../../../server/AVTService/TypeLibrary/Computation/HeatmapSensorInfo";
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { MapSectionModel } from "../../../../../../server/AVTService/TypeLibrary/Model/MapSectionModel";
import store from "../../../../../../store/configureStore";
import { IGeovisChartReportInfo } from "../../../../../../store/projectReport.types";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getBoostOptions = (chartType: ChartType, slimConfig: GeovisChartSlimConfig): Highcharts.BoostOptions => ({
    // enabled: AuthService.isAmbergDistribution() && getIsBoostEnabledDependsOfTimeInterval(slimConfig) && compareNotChartTypes(chartType, ChartType.VibrationDiagramm), // enabled only for Amberg distribution
    // allowForce: true,
    // useGPUTranslations: compareChartTypes(chartType, ChartType.XYDiagram)

    // This feature is disabled because it's useless in our case
    enabled: false
});

// const getIsBoostEnabledDependsOfTimeInterval = (slimConfig: GeovisChartSlimConfig): boolean => {

//     const startTime = moment(slimConfig.StartDate);
//     const endTime = moment(slimConfig.EndDate);

//     const diff = endTime.diff(startTime, 'hours');

//     return diff > 36;
// }

/**
 * Get  geovis chart info from storage
 * @param pageNum 
 * @param chartId 
 * @returns 
 */
export const getGeovisChartInfoFromStorage = <TChart extends GeovisChartModel>(pageNum: number, chartId: number): IGeovisChartReportInfo<TChart> | false => {
    const state = store.getState();
    const page = state.projectReport.geovisReportSettings.geovisPages.get(pageNum);
    if (!page) {
        return false;
    }

    const chartInfo = page.geovisCharts.get(chartId) as IGeovisChartReportInfo<TChart>;
    if (!chartInfo) {
        return false;
    }

    return chartInfo;
}

/**
 * Gets geovis chart config from storage
 * Or return 'false' if chart config not found
 * @param pageNum
 * @param chartId 
 * @returns 
 */
export const getGeovisChartConfigFromStorage = <TChart extends GeovisChartModel>(pageNum: number, chartId: number): TChart | false => {
    const chartInfo = getGeovisChartInfoFromStorage<TChart>(pageNum, chartId);
    if (!chartInfo) {
        return false;
    }

    return chartInfo.Chart;
}

/**
 * Gets the map section's config from the storage
 * @param pageNum 
 * @param mapSectionId 
 * @returns 
 */
export const getMapSectionFromStorage = (pageNum: number, mapSectionId: number): MapSectionModel | false => {
    const state = store.getState();
    const page = state.projectReport.geovisReportSettings.geovisPages.get(pageNum);
    if (!page) {
        return false;
    }

    const mapSectionInfo = page.mapSections.get(mapSectionId);
    if (!mapSectionInfo) {
        return false;
    }

    return mapSectionInfo.MapSection;
}

const sensorAttributeToString = (attr: SensorAttribyteEntry): string => {
    if (!attr) {
        return '';
    }

    return `${formattedDateTime(attr.date)}: ${attr.values.join(';')}`;
}

const additionalSensorAttributes: ReadonlyArray<{ key: string, func: (info: HeatmapSensorInfo) => string }> = [
    { key: '\r', func: () => '<br/>' },
    { key: '\n', func: () => '<br/>' },
    { key: "<Km>", func: i => i.Km.toFixed(2) },
    { key: "<Param1>", func: i => i.Params[0] || '' },
    { key: "<Param2>", func: i => i.Params[1] || '' },
    { key: "<Param3>", func: i => i.Params[2] || '' },
    { key: "<Param4>", func: i => i.Params[3] || '' },
    { key: "<Param5>", func: i => i.Params[4] || '' },
    { key: "<Reference>", func: i => sensorAttributeToString(i.Reference) },
    { key: "<AxisReference>", func: i => sensorAttributeToString(i.AxisReference) },
    { key: "<Offset>", func: i => sensorAttributeToString(i.Offset) },
    { key: "<AxisOffset>", func: i => sensorAttributeToString(i.AxisOffset) }
]

export const renderChartSensorAdditionalAttributes = (templateLine: string, sensorInfo: HeatmapSensorInfo): string => {

    if (!templateLine) {
        return '';
    }
    let result = templateLine;

    for (const attrInfo of additionalSensorAttributes) {
        while (result.indexOf(attrInfo.key) > -1) {
            result = result.replace(attrInfo.key, attrInfo.func(sensorInfo));
        }
    }

    return '<br/>' + result;
}

export const convertValueAttributeToAlarmChartValueType = (attribute: SensorValueAttribute): AlarmChartValueType => {
    switch (attribute) {
        case SensorValueAttribute.Value1:
        case SensorValueAttribute.AxisValue1:
        case SensorValueAttribute.DeviationX:
        case SensorValueAttribute.FrequencyX:
        case SensorValueAttribute.DomFreqX:
        case SensorValueAttribute.AxisDeviationValue1:
        case SensorValueAttribute.AxisDeviationX:
        case SensorValueAttribute.AxisReference1:
        case SensorValueAttribute.AxisRelative1:
        case SensorValueAttribute.InclinometerChainPosition1:
        case SensorValueAttribute.OccurrenceX:
        case SensorValueAttribute.PeakX:
        case SensorValueAttribute.Reference1:
        case SensorValueAttribute.Relative1:
            return AlarmChartValueType.Chart1;
        case SensorValueAttribute.Value2:
        case SensorValueAttribute.AxisValue2:
        case SensorValueAttribute.DeviationY:
        case SensorValueAttribute.FrequencyY:
        case SensorValueAttribute.DomFreqY:
        case SensorValueAttribute.AxisDeviationValue2:
        case SensorValueAttribute.AxisDeviationY:
        case SensorValueAttribute.AxisReference2:
        case SensorValueAttribute.AxisRelative2:
        case SensorValueAttribute.InclinometerChainPosition2:
        case SensorValueAttribute.OccurrenceY:
        case SensorValueAttribute.PeakY:
        case SensorValueAttribute.Reference2:
        case SensorValueAttribute.Relative2:
            return AlarmChartValueType.Chart2;
        case SensorValueAttribute.Value3:
        case SensorValueAttribute.AxisValue3:
        case SensorValueAttribute.DeviationZ:
        case SensorValueAttribute.FrequencyZ:
        case SensorValueAttribute.DomFreqZ:
        case SensorValueAttribute.AxisDeviationValue3:
        case SensorValueAttribute.AxisDeviationZ:
        case SensorValueAttribute.AxisReference3:
        case SensorValueAttribute.AxisRelative3:
        case SensorValueAttribute.InclinometerChainPosition3:
        case SensorValueAttribute.OccurrenceZ:
        case SensorValueAttribute.PeakZ:
        case SensorValueAttribute.Reference3:
        case SensorValueAttribute.Relative3:
            return AlarmChartValueType.Chart3;
        default:
            return AlarmChartValueType.AllCharts;
    }
}

export const getMaxByAbsoluteValue = (...compareValues: number[]): number => {
    if (compareValues.every(v => v <= 0) || compareValues.every(v => v >= 0)) {
        const maxAbsoluteValue = Math.max(...compareValues.map(v => Math.abs(v)));
        const allMaxValues = compareValues.filter(v => maxAbsoluteValue === Math.abs(v));
        return Math.max(...allMaxValues);
    }

    return Math.max(...compareValues);
}

export const getMinByAbsoluteValue = (...compareValues: number[]): number => {
    if (compareValues.every(v => v <= 0) || compareValues.every(v => v >= 0)) {
        const minAbsoluteValue = Math.min(...compareValues.map(v => Math.abs(v)));
        const allMinValues = compareValues.filter(v => minAbsoluteValue === Math.abs(v));
        return Math.min(...allMinValues);
    }

    return Math.min(...compareValues);
}