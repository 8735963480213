import Button from "@atlaskit/button";
import Tabs from '@atlaskit/tabs';
import TextArea from "@atlaskit/textarea";
import React, { Dispatch, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { IWithGeovisServerProps, withGeovisServer } from "../../../helpers/GeovisHooks";
import { fetchServerElements } from "../../../helpers/ProjectDataHelper";
import { t } from "../../../i18n";
import { ActionResponse } from "../../../server/ActionResponse";
import { DataActionResponse } from "../../../server/DataActionResponse";
import { ProjectInfo } from "../../../server/ProjectInfo";
import ServerRoutesGen from "../../../server/Routes/ServerRoutesGen";
import AuthService from "../../../services/AuthService";
import FlagService from "../../../services/FlagService";
import Logger from "../../../services/Logger";
import { projectDataInformationUpdate, projectDataPropertiesData } from "../../../store/creators/dataCreators";
import { projectOverviewInformationTabChanged } from "../../../store/creators/projectOverviewCreators";
import { IProjectInfoStorage } from "../../../store/data.types";
import { IGeovisStoreState } from "../../../store/store.types";
import { IGeovisAction, IGeovisNavigationState } from "../../../store/types";
import { IWithProjectViewerModeProperty } from "../../abstract/IWithProjectViewerModeProperty";
import { IGeovisTData, ProjectOverviewInformationHubTabItemComponent } from "./ProjectOverviewInformationDataHubControl";

export const AllName = "All";
export const PAName = "ProjectAdmin";
export const AAName = "AmbergAdmin";

interface IInformationPanelProps {
    title: string;
    groupName: string;
    note: string;
    taRef: React.RefObject<HTMLTextAreaElement>;
    needSaveButton: boolean;
    onSave: () => void;
    onSelect: (tabName: string) => void;
}

interface IProjectOverviewInformationHubStateProps extends IWithProjectViewerModeProperty {
    navigation: IGeovisNavigationState;
    projectInfo: IProjectInfoStorage;
    dataName: string;
}
interface IProjectOverviewInformationHubDispatchProps {
    onTabSelected: (dataName: string) => void;
    onSave: (dataForSave: string, dataName: string) => void;
}
interface IProjectOverviewInformationHubProps extends IProjectOverviewInformationHubStateProps, IProjectOverviewInformationHubDispatchProps, IWithGeovisServerProps {

}


const createInfoTabContent = (props: IInformationPanelProps): IGeovisTData => ({
    label: t(props.title),
    content: (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <TextArea
                defaultValue={props.note}
                ref={props.taRef}
                maxHeight={'100%'}
                key={"area" + props.groupName}
                isReadOnly={!props.needSaveButton}
            />
            {props.needSaveButton &&
                <Button appearance="primary" onClick={props.onSave} style={{ marginTop: '10px', width: '100px', alignSelf: 'flex-end' }}>{t("Save")}</Button>
            }
        </div>
    ),
    storageName: props.groupName,
    hasData: (props.note !== null && props.note.length > 0),
    onSelect: props.onSelect
});



const ProjectOverviewInformationHub = ({
    projectInfo,
    dataName,
    Server,
    onTabSelected,
    onSave
}: IProjectOverviewInformationHubProps) => {
    const { project } = projectInfo;
    const inputEl = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
        (async function loadInformation() {
            let result: DataActionResponse<ProjectInfo> | false = false;
            result = await fetchServerElements<ProjectInfo>(Server, ServerRoutesGen.Project.Get.patch(project.Id));
            if (result && result.Success) {
                projectDataPropertiesData(result.Data);
            }
        })();
    }, [dataName]);

    const onSaveHandle = (tabName: string) => async () => {
        if (inputEl && inputEl.current) {
            const information = inputEl.current.value;
            const getUrl = () => {
                switch (tabName) {
                    case AllName:
                        return ServerRoutesGen.Project.SaveNote.patch(project.Id).path;

                    case PAName:
                        return ServerRoutesGen.Project.SaveProjectAdminInformation.patch(project.Id).path;

                    case AAName:
                        return ServerRoutesGen.Project.SaveInternalInformation.patch(project.Id).path;

                    default:
                        throw Error(`Save project information: unsupported project information property name ${tabName}`)
                }
            }
            try {
                const url = getUrl();
                const result = await Server.post<ActionResponse>(url, { information });
                if (!result.Success) {
                    FlagService.addErrors("Changing the project's information", result.Messages);
                }
                else {
                    onSave(information, tabName);
                }
            }
            catch (error) {
                FlagService.addError("Failed to change the project's information", error);
            }
        }
    }

    const tabs = [
        // Create all info tab
        createInfoTabContent({
            title: t("All"),
            groupName: AllName,
            note: project.Note,
            taRef: inputEl,
            onSave: onSaveHandle(AllName),
            onSelect: onTabSelected,
            needSaveButton: AuthService.isActualAdminOfProject(project.Id)
        })
    ];

    // Create Project Admin info tab
    if (AuthService.isActualAdminOfProject(project.Id)) {
        tabs.push(createInfoTabContent({
            title: t("Project admins"),
            groupName: PAName,
            note: project.AdminInformation,
            taRef: inputEl,
            onSave: onSaveHandle(PAName),
            onSelect: onTabSelected,
            needSaveButton: true
        }));
    }

    // Create Amberg Admin info tab
    if (AuthService.hasUserTypeAsAdmin()) {
        tabs.push(createInfoTabContent({
            title: t("Amberg"),
            groupName: AAName,
            note: project.InternalInformation,
            taRef: inputEl,
            onSave: onSaveHandle(AAName),
            onSelect: onTabSelected,
            needSaveButton: true
        }));
    }
    Logger.render('Rendering', ProjectOverviewInformationHub.name);

    return (
        <div style={{ height: '80%' }}>
            <div style={{ marginBottom: '15px', marginTop: '5px' }}>
                <span style={{ color: '#42526E', fontSize: '1.2em', fontWeight: 500, marginLeft: '9px' }}>{t("Information")}</span>
            </div>
            <hr style={{ color: '#ebecf0', opacity: '30%', marginRight: '9px', marginLeft: '9px' }} />
            <div style={{
                height: '100%',
                display: 'flex'
            }}>
                {(AuthService.isActualAdminOfProject(project.Id) || AuthService.hasUserTypeAsAdmin()) &&
                    <Tabs
                        components={{ Item: ProjectOverviewInformationHubTabItemComponent }}
                        selected={getSelectedTab(dataName, tabs)}
                        tabs={tabs}
                    />
                }
                {!AuthService.isActualAdminOfProject(project.Id) && !AuthService.hasUserTypeAsAdmin() &&

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginRight: '9px', marginLeft: '9px' }}>
                        <TextArea
                            defaultValue={projectInfo.project.Note}
                            maxHeight={'100%'}
                            key={"area" + dataName}
                            isReadOnly={true}
                        />
                    </div>
                }
            </div>
        </div>

    );
}
const getSelectedTab = (dataName: string, tabs: IGeovisTData[]): IGeovisTData => {
    for (const tab of tabs) {
        if (tab.storageName === dataName) {
            return tab;
        }
    }

    return tabs[0];
}

const mapStateToProps = ({ data, navigation, projectOverview }: IGeovisStoreState): IProjectOverviewInformationHubStateProps => ({
    navigation,
    projectInfo: data.projectInfo,
    viewerModeStamp: navigation.viewerModeStamp,
    dataName: projectOverview.informationName
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IProjectOverviewInformationHubDispatchProps => ({
    onTabSelected: dataName => dispatch(projectOverviewInformationTabChanged(dataName)),
    onSave: (dataForSave, dataName) => dispatch(projectDataInformationUpdate(dataForSave, dataName))
});

export default connect<IProjectOverviewInformationHubStateProps, IProjectOverviewInformationHubDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(ProjectOverviewInformationHub));