//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MessageTemplateAttachmentType {
	File = 0,
	Image = 1
}

const MessageTemplateAttachmentTypeToName = new Map<number, string>([
	[ 0, 'File' ],
	[ 1, 'Image' ],
])

export function getMessageTemplateAttachmentTypeToName(element: MessageTemplateAttachmentType) : string {
    return MessageTemplateAttachmentTypeToName.get(element) || '';
}

const MessageTemplateAttachmentTypeByName = new Map<string, number>([
	[ 'File', 0 ],
	[ 'Image', 1 ],
]);

export function getMessageTemplateAttachmentTypeByName(name: string): MessageTemplateAttachmentType {
    return MessageTemplateAttachmentTypeByName.get(name)!;
}


export const MessageTemplateAttachmentTypeList : Readonly<MessageTemplateAttachmentType[]> = [
	MessageTemplateAttachmentType.File,
	MessageTemplateAttachmentType.Image,
];



const MessageTemplateAttachmentTypeToDescription = new Map<number, string>([
	[ 0, 'File' ],
	[ 1, 'Image' ],
])

export function getMessageTemplateAttachmentTypeToDescription(element: MessageTemplateAttachmentType) : string {
    return MessageTemplateAttachmentTypeToDescription.get(element) || '';
}

