/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 23.12.2019
 * @description Project view tree element icons
 */

import {
    CircleIconSvg,
    DocumentIconSvg,
    PolylineIconSvg,
    TextIconSvg
} from '../../../components/localMapObjects/LocalMapObjectSvgIcons';
import { LocalMapObject } from '../../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject';
import { LocalMapObjectType } from '../../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectType';
import { ChainInfo } from '../../../server/ChainInfo';
import { SensorInfo } from '../../../server/GEOvis3/Model/SensorInfo';
import { LocalMapObjectCircle } from '../../../server/LocalMapObjectCircle';
import { LocalMapObjectPolyline } from '../../../server/LocalMapObjectPolyline';
import { LocalMapObjectText } from '../../../server/LocalMapObjectText';
import { createSensorStateIcon } from '../../map/IconFactory';


interface ILocalMapObjectIconProps {
    className: string;
    localMapObject: LocalMapObject;
}

/**
 * Local map object icon
 * @param props
 */
export const LocalMapObjectIcon = ({
    className,
    localMapObject
}: ILocalMapObjectIconProps) => {

    const { ObjectType } = localMapObject;

    switch (ObjectType) {
        case LocalMapObjectType.Text:
        case LocalMapObjectType.Link: {
            const text = localMapObject as LocalMapObjectText;
            return (<span className={className} dangerouslySetInnerHTML={{ __html: TextIconSvg({ color: text.Color }) }} />);
        }

        case LocalMapObjectType.Polyline: {
            const polyline = localMapObject as LocalMapObjectPolyline
            return (<span className={className} dangerouslySetInnerHTML={{ __html: PolylineIconSvg({ color: polyline.Color }) }} />)
        }

        case LocalMapObjectType.Circle: {
            const circle = localMapObject as LocalMapObjectCircle;

            return (<span className={className} dangerouslySetInnerHTML={{ __html: CircleIconSvg({ color: circle.Color }) }} />)
        }

        case LocalMapObjectType.Document:
            return (<span className={className} dangerouslySetInnerHTML={{ __html: DocumentIconSvg() }} />);

    }

    return (<span>&bull;</span>)
}

interface IInclinometerChainObjectIconProps {
    className: string;
    chain: ChainInfo;
}

/**
 * Inclinometer chain icon
 * @param props
 */
export const InclinometerChainObjectIcon = ({
    chain,
    className
}: IInclinometerChainObjectIconProps) => {

    const sensorInfo = new SensorInfo();
    sensorInfo.Name = chain.Name;
    sensorInfo.CausedAlarms = chain.CausedAlarms || [];
    sensorInfo.PhysicalType = chain.PhysicalType;
    sensorInfo.WatchdogEnabled = chain.WatchdogEnabled;

    const sensorIcon = createSensorStateIcon(sensorInfo, { noScale: true });
    const svg = sensorIcon._createSVG();

    return (
        <span className={className} dangerouslySetInnerHTML={{ __html: svg }}>
            {/*  */}
        </span>
    )
}