//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { AlarmAction } from './AlarmAction';

export class TextAlarmAction extends AlarmAction
{
	public message: string;
}
