import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { HeadType, RowType, SortOrderType } from "@atlaskit/dynamic-table/dist/types/types";
import Textfield from "@atlaskit/textfield";
import { debounce } from "lodash";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { AlarmActionUsersPreset } from "../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUsersPreset";
import { ISomethingStorageBaseEx, defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../../../../store/types";
import { t } from "../../../../i18n";
import IRouteParams from "../../../../helpers/IRouteParams";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import { fetchServerElements } from "../../../../helpers/ProjectDataHelper";
import { GeovisTableWithSelectableRows } from "../../../../components/DynamicTableWithSelectableRows";
import { isMatchToSearchString } from "../../../../helpers/FiltersHelper";
import { GeovisModalDialog } from "../../../../components/editDialogs/GeovisModalDialog";

interface IComponentProps extends IWithGeovisServerProps {
    linkedPresetId: string;
    onLink: (presetId: string) => void;
    onClose: () => void;
    countOfConditions: number;
}

interface IComponentState extends ISomethingStorageBaseEx<AlarmActionUsersPreset[]> {
    linkedPresetId: string;
    filterString: string;
    sortOrder: SortOrderType;
    sortKey: string;
}

const Component = ({
    Server,
    linkedPresetId,
    onClose,
    onLink,
    countOfConditions
}: IComponentProps) => {

    const filterRef = useRef<HTMLInputElement>(null);

    const [state, setState] = useState<IComponentState>({
        ...defaultSomethingStorageState,
        data: [],
        linkedPresetId,
        filterString: '',
        sortKey: 'name',
        sortOrder: 'ASC'
    })

    const { projectId } = useParams<IRouteParams>();

    useEffect(() => {
        (async function loadPresets() {
            const url = ServerRoutesGen.Alarms.GetUsersPresetsForProject.patch(projectId);
            const response = await fetchServerElements<AlarmActionUsersPreset[]>(Server, url);
            if (!response.Success) {
                setState({
                    ...state,
                    ...errorSomethingStorageState(response.Messages.join("; ")),
                    data: []
                });
                return;
            }
            setState({
                ...state,
                ...loadedSomethingStorageState,
                data: response.Data
            });
        })();
    }, [1])

    const onLinkClickHandler = () => {
        onLink(state.linkedPresetId);
    }

    const sortPreset = (item1: AlarmActionUsersPreset, item2: AlarmActionUsersPreset): number => {
        switch (state.sortKey) {
            case 'name': {
                if (item1.Name === item2.Name) {
                    return 0;
                }

                return item1.Name > item2.Name
                    ? state.sortOrder === "ASC" ? 1 : -1
                    : state.sortOrder === "ASC" ? -1 : 1
            }
            case 'count': {
                if (item1.Users.length === item2.Users.length) {
                    return 0;
                }

                return item1.Users.length > item2.Users.length
                    ? state.sortOrder === "ASC" ? 1 : -1
                    : state.sortOrder === "ASC" ? -1 : 1
            }
        }

        return 0;
    }

    const getAllPresets = (): AlarmActionUsersPreset[] => {
        if (state.isLoading) {
            return [];
        }

        return state.data.sort((a, b) => sortPreset(a, b)).filter(a => a.ConditionsCount === countOfConditions && isMatchToSearchString(a.Name, state.filterString));
    }

    const getIdFunc = (preset: AlarmActionUsersPreset): string => preset.Id;

    const itemToRowFunc = (preset: AlarmActionUsersPreset): RowType => {
        return ({
            key: preset.Id,
            cells: [{
                key: 'name',
                content: (
                    <span>{preset.Name}</span>
                )
            }, {
                key: 'count',
                content: (
                    <span>{preset.Users.length}</span>
                )
            }]
        })
    }

    const onSelectionChanged = (selectedItems: AlarmActionUsersPreset[]) => {
        if (selectedItems.length === 0) {
            setState({
                ...state,
                linkedPresetId: ''
            });
            return;
        }

        setState({
            ...state,
            linkedPresetId: selectedItems[selectedItems.length - 1].Id
        })
    }

    const getSelectedItems = (): AlarmActionUsersPreset[] => {
        return state.data.filter(d => d.Id === state.linkedPresetId);
    }

    const getHead = (): HeadType => {
        return ({
            cells: [{
                content: t("Selected")
            }, {
                content: t("Preset name"),
                key: 'name',
                isSortable: true
            }, {
                content: t("Count of users"),
                key: 'count',
                isSortable: true
            }]
        })
    }

    const onAfterSort = (sortKey: string, sortOrder: SortOrderType) => {
        setState({
            ...state,
            sortKey,
            sortOrder
        })
    }

    const onClearSearchField = () => {
        if (filterRef.current !== null) {
            filterRef.current.value = "";
        }
        setState({
            ...state,
            filterString: ""
        })
    }

    const onChangeFilterDebounced = debounce((value) => {
        setState({
            ...state,
            filterString: value
        });
    }, 350);

    const onChangeFilterString = (event: SyntheticEvent<HTMLInputElement>) => {
        onChangeFilterDebounced(event.currentTarget.value);
    }

    return (
        <GeovisModalDialog
            heading={t("Link users preset to action")}
            actions={[
                { text: t("Link"), appearance: 'primary', onClick: onLinkClickHandler },
                { text: t("Close"), appearance: 'default', onClick: onClose }
            ]}
        >
            <div className='flexColumnContainer' style={{ height: '100%' }}>
                <div style={{ flexShrink: 2 }}>
                    <Textfield
                        defaultValue={state.filterString}
                        isCompact={true}
                        placeholder={t("Filter presets")}
                        onChange={onChangeFilterString}
                        ref={filterRef}
                        elemAfterInput={
                            <div style={{ marginRight: '5px', cursor: 'default' }} onClick={onClearSearchField}>
                                <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                            </div>
                        }
                    />
                </div>
                <div style={{ height: '100%' }}>
                    <GeovisTableWithSelectableRows
                        isLoading={state.isLoading}
                        allItems={getAllPresets()}
                        getIdFunc={getIdFunc}
                        itemToRowFunc={itemToRowFunc}
                        needSelectAll={false}
                        onSelectionChanged={onSelectionChanged}
                        selectedItems={getSelectedItems()}
                        head={getHead()}
                        onAfterSort={onAfterSort}
                        defaultSortKey={state.sortKey}
                        defaultSortOrder={state.sortOrder}
                    />
                </div>
            </div>
        </GeovisModalDialog>
    )
}

export default withGeovisServer(Component);