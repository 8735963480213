/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 24.12.2019
 * @description Geovis dispatcher actions
 */

import { CustomerReportSettingsModel } from "../server/AVTService/TypeLibrary/Model/CustomerReportSettingsModel";
import { SensorCategory } from "../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { ProjectReportInfo } from "../server/ProjectReportInfo";
import { IGeovisEventDispatcherAction } from "./GeovisEventDispatcher";

//#region Actions

export const ACTION_SHOW_POPUP_OF_SENSOR = 'ACTION_SHOW_POPUP_OF_SENSOR';
export const ACTION_SHOW_POPUP_OF_CHAIN = 'ACTION_SHOW_POPUP_OF_CHAIN';

export const ACTION_SHOW_REGION_OF_SENSORS = 'ACTION_SHOW_REGION_OF_SENSORS';
export const ACTION_SHOW_REGION_OF_CHAINS = 'ACTION_SHOW_REGION_OF_CHAINS';
export const ACTION_SHOW_REGION_OF_LMO = 'ACTION_SHOW_REGION_OF_LMO';

export const ACTION_APPLY_REPORT_SETTINGS = 'ACTION_APPLY_REPORT_SETTINGS';
export const ACTION_RESET_REPORT_CHANGES = 'ACTION_RESET_REPORT_CHANGES';

export const ACTION_REPORT_CLICKED = 'ACTION_REPORT_CLICKED';

export const ACTION_REPORT_SAVE_AS_PNG = 'ACTION_REPORT_SAVE_AS_PNG';
export const ACTION_REPORT_SAVE_AS_PDF = 'ACTION_REPORT_SAVE_AS_PDF';

export const ACTION_SENSOR_SELECTION_CHANGE = 'ACTION_SENSOR_SELECTION_CHANGE';

//#endregion

//#region Creators

export const actionShowPopupOfSensor = (sensorId: string): IGeovisEventDispatcherAction<string> => ({
    actionId: ACTION_SHOW_POPUP_OF_SENSOR, data: sensorId
});

export const actionShowPopupOfChain = (chainId: string): IGeovisEventDispatcherAction<string> => ({
    actionId: ACTION_SHOW_POPUP_OF_CHAIN, data: chainId
});

export const actionShowRegionOfSensors = (category: SensorCategory): IGeovisEventDispatcherAction<SensorCategory> => ({
    actionId: ACTION_SHOW_REGION_OF_SENSORS, data: category
});

export const actionShowRegionOfChains = (): IGeovisEventDispatcherAction<any> => ({
    actionId: ACTION_SHOW_REGION_OF_CHAINS, data: undefined
});

export const actionShowRegionOfLmo = (objectId: string): IGeovisEventDispatcherAction<string> => ({
    actionId: ACTION_SHOW_REGION_OF_LMO, data: objectId
});

export const actionApplyReportSettings = (settings: CustomerReportSettingsModel): IGeovisEventDispatcherAction<any> => ({
    actionId: ACTION_APPLY_REPORT_SETTINGS, data: settings
})

export const actionResetReportChanges = (): IGeovisEventDispatcherAction<any> => ({
    actionId: ACTION_RESET_REPORT_CHANGES, data: undefined
});

export const actionReportClicked = (reportInfo: ProjectReportInfo): IGeovisEventDispatcherAction<ProjectReportInfo> => ({
    actionId: ACTION_REPORT_CLICKED, data: reportInfo
});

export const actionReportSaveAsPDF = (): IGeovisEventDispatcherAction<any> => ({
    actionId: ACTION_REPORT_SAVE_AS_PDF, data: undefined
});

export const actionReportSaveAsPNG = (): IGeovisEventDispatcherAction<any> => ({
    actionId: ACTION_REPORT_SAVE_AS_PNG, data: undefined
});

//#endregion