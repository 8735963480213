//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ExportDocumentKind {
	PDF = 0,
	EXCEL = 1
}

const ExportDocumentKindToName = new Map<number, string>([
	[ 0, 'PDF' ],
	[ 1, 'EXCEL' ],
])

export function getExportDocumentKindToName(element: ExportDocumentKind) : string {
    return ExportDocumentKindToName.get(element) || '';
}

const ExportDocumentKindByName = new Map<string, number>([
	[ 'PDF', 0 ],
	[ 'EXCEL', 1 ],
]);

export function getExportDocumentKindByName(name: string): ExportDocumentKind {
    return ExportDocumentKindByName.get(name)!;
}


export const ExportDocumentKindList : Readonly<ExportDocumentKind[]> = [
	ExportDocumentKind.PDF,
	ExportDocumentKind.EXCEL,
];



const ExportDocumentKindToDescription = new Map<number, string>([
	[ 0, 'PDF' ],
	[ 1, 'EXCEL' ],
])

export function getExportDocumentKindToDescription(element: ExportDocumentKind) : string {
    return ExportDocumentKindToDescription.get(element) || '';
}

