//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SensorSymbol {
	Circle = 0,
	Square = 1,
	TriangleUp = 2,
	TriangleLeft = 3,
	TriangleRight = 4,
	TriangleDown = 5,
	Romb = 6,
	Star = 7,
	Plus = 8
}

const SensorSymbolToName = new Map<number, string>([
	[ 0, 'Circle' ],
	[ 1, 'Square' ],
	[ 2, 'TriangleUp' ],
	[ 3, 'TriangleLeft' ],
	[ 4, 'TriangleRight' ],
	[ 5, 'TriangleDown' ],
	[ 6, 'Romb' ],
	[ 7, 'Star' ],
	[ 8, 'Plus' ],
])

export function getSensorSymbolToName(element: SensorSymbol) : string {
    return SensorSymbolToName.get(element) || '';
}

const SensorSymbolByName = new Map<string, number>([
	[ 'Circle', 0 ],
	[ 'Square', 1 ],
	[ 'TriangleUp', 2 ],
	[ 'TriangleLeft', 3 ],
	[ 'TriangleRight', 4 ],
	[ 'TriangleDown', 5 ],
	[ 'Romb', 6 ],
	[ 'Star', 7 ],
	[ 'Plus', 8 ],
]);

export function getSensorSymbolByName(name: string): SensorSymbol {
    return SensorSymbolByName.get(name)!;
}


export const SensorSymbolList : Readonly<SensorSymbol[]> = [
	SensorSymbol.Circle,
	SensorSymbol.Square,
	SensorSymbol.TriangleUp,
	SensorSymbol.TriangleLeft,
	SensorSymbol.TriangleRight,
	SensorSymbol.TriangleDown,
	SensorSymbol.Romb,
	SensorSymbol.Star,
	SensorSymbol.Plus,
];



const SensorSymbolToDescription = new Map<number, string>([
	[ 0, 'Circle' ],
	[ 1, 'Square' ],
	[ 2, 'TriangleUp' ],
	[ 3, 'TriangleLeft' ],
	[ 4, 'TriangleRight' ],
	[ 5, 'TriangleDown' ],
	[ 6, 'Romb' ],
	[ 7, 'Star' ],
	[ 8, 'Plus' ],
])

export function getSensorSymbolToDescription(element: SensorSymbol) : string {
    return SensorSymbolToDescription.get(element) || '';
}

