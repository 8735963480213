/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.10.2021
 * @description The data migrations state reducer
 */

import { Reducer } from 'redux';
import {
    addOrUpdateElementInMapWithRebuild,
    elementsToMapOfCustomKey
} from '../../helpers/StorageHelper';
import { ArgusDataMigration } from '../../server/AVTService/TypeLibrary/DB/MstModelTypes/ArgusDataMigration';
import { DataMigrationMstOverviewModel } from '../../server/GEOvis3/Model/Migrations/DataMigrationMstOverviewModel';
import { MigrationGeneralInfo } from '../../server/GEOvis3/Model/Migrations/MigrationGeneralInfo';
import { ProjectMigrationsInfo } from '../../server/GEOvis3/Model/Migrations/ProjectMigrationsInfo';
import {
    DATA_MIGRATION_FILTER_CHANGED,
    DATA_MIGRATION_GENERAL_INFO,
    DATA_MIGRATION_GENERAL_INFO_LOADED,
    DATA_MIGRATION_MSTS_OVERVIEW_LOADED,
    DATA_MIGRATION_PROJECTS_INFO,
    DATA_MIGRATION_PROJECTS_INFO_LOADED,
    DATA_MIGRATION_UPDATE_ARGUS_MIGRATION_INFO,
    DATA_MIGRATION_UPDATE_MST_MIGRATIONS_INFO,
    DATA_MIGRATION_UPDATE_MST_MIGRATIONS_SETTINGS
} from '../actions/dataMigrationsActions';
import {
    IDataMigrationAction,
    IDataMigrationState
} from '../dataMigrations.types';
import { processFetchedData } from '../helpers/DataHelper';
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from '../types';

/**
 * Data migration initial state
 */
export const dataMigrationInitialState: IDataMigrationState = {
    generalInfoStorage: {
        ...defaultSomethingStorageState,
        data: { ... new MigrationGeneralInfo() },
    },
    projectsStorage: {
        ...defaultSomethingStorageState,
        data: []
    },
    filter: {
        SearchFilter: ''
    },
    mstsStatisticStorage: {
        ...defaultSomethingStorageState,
        migrationsMap: new Map<ArgusDataMigration, Map<number, DataMigrationMstOverviewModel>>()
    }
}

/**
 * Data migration reducer
 * @param state 
 * @param action 
 * @returns 
 */
const reducer: Reducer<IDataMigrationState> = (
    state: IDataMigrationState = dataMigrationInitialState,
    action: IDataMigrationAction
): IDataMigrationState => {

    switch (action.type) {

        case DATA_MIGRATION_GENERAL_INFO:
            return { ...state, generalInfoStorage: { ...dataMigrationInitialState.generalInfoStorage } };

        case DATA_MIGRATION_GENERAL_INFO_LOADED:
            if (!action.generalInfoResponse) {
                return state;
            }

            return {
                ...state,
                generalInfoStorage: processFetchedData(action.generalInfoResponse, state.generalInfoStorage, dataMigrationInitialState.generalInfoStorage, st => ({
                    data: st
                }))
            }

        case DATA_MIGRATION_PROJECTS_INFO:
            return { ...state, projectsStorage: { ...dataMigrationInitialState.projectsStorage } };

        case DATA_MIGRATION_PROJECTS_INFO_LOADED:
            if (!action.projectsResponse) {
                return state;
            }

            return {
                ...state,
                projectsStorage: processFetchedData(action.projectsResponse, state.projectsStorage, dataMigrationInitialState.projectsStorage, st => ({
                    data: st
                }))
            }

        case DATA_MIGRATION_FILTER_CHANGED: {
            const { filter } = action;

            if (!filter) {
                return state;
            }

            return {
                ...state,
                filter
            }
        }

        case DATA_MIGRATION_UPDATE_ARGUS_MIGRATION_INFO: {
            const { projectId, mstId, migration, migrationInfo } = action;

            if (!projectId || !mstId || migrationInfo === undefined || migration === undefined) {
                return state;
            }

            return {
                ...state,
                projectsStorage: {
                    ...state.projectsStorage,
                    data: state.projectsStorage.data.map<ProjectMigrationsInfo>(p => {

                        if (p.Id !== projectId) {
                            return p;
                        }

                        const mstInfo = p.MstsInfo[mstId];
                        if (!mstInfo) {
                            return p;
                        }

                        mstInfo.MigrationsInfo[migration] = migrationInfo;

                        p.MstsInfo[mstId] = { ...mstInfo };
                        return p;
                    })
                }
            }
        }

        case DATA_MIGRATION_MSTS_OVERVIEW_LOADED: {
            const { migration, mstsOverviewData } = action;
            if (!mstsOverviewData || migration === undefined) {
                return state;
            }

            if (!mstsOverviewData.Success) {
                return {
                    ...state,
                    mstsStatisticStorage: {
                        ...dataMigrationInitialState.mstsStatisticStorage,
                        ...errorSomethingStorageState(...mstsOverviewData.Messages)
                    }
                }
            }

            const mstToMigrationMap: Map<number, DataMigrationMstOverviewModel> = elementsToMapOfCustomKey(mstsOverviewData.Data, r => r.MstId);

            return {
                ...state,
                mstsStatisticStorage: {
                    ...loadedSomethingStorageState,
                    migrationsMap: addOrUpdateElementInMapWithRebuild(state.mstsStatisticStorage.migrationsMap, migration, mstToMigrationMap)
                }
            }
        }

        case DATA_MIGRATION_UPDATE_MST_MIGRATIONS_INFO: {
            const { projectId, mstMigrationsInfo } = action;

            if (!projectId || !mstMigrationsInfo) {
                return state;
            }

            return {
                ...state,
                projectsStorage: {
                    ...state.projectsStorage,
                    data: state.projectsStorage.data.map<ProjectMigrationsInfo>(p => {
                        if (p.Id !== projectId) {
                            return p;
                        }

                        p.MstsInfo[mstMigrationsInfo.Id] = mstMigrationsInfo;

                        return { ...p };
                    })
                }
            }
        }

        case DATA_MIGRATION_UPDATE_MST_MIGRATIONS_SETTINGS: {
            const { projectId, mstId, mstSettings } = action;

            if (!projectId || !mstId || !mstSettings) {
                return state;
            }

            return {
                ...state,
                projectsStorage: {
                    ...state.projectsStorage,
                    data: state.projectsStorage.data.map<ProjectMigrationsInfo>(p => {

                        if (p.Id !== projectId) {
                            return p;
                        }

                        if (!p.MstsInfo[mstId]) {
                            return p;
                        }

                        p.MstsInfo[mstId] = { ...p.MstsInfo[mstId], ...mstSettings };
                        return { ...p };
                    })
                }
            }
        }
    }

    return state;
}

export default reducer;