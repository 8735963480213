//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartData } from './GeovisChartData';
import { HtmlReportVibrationEventLineViewModel } from '../Report/Model/HtmlReportVibrationEventLineViewModel';
import { HtmlReportVibrationEventHeaderViewModel } from '../Report/Model/HtmlReportVibrationEventHeaderViewModel';

export class VibrationEventChartData extends GeovisChartData
{
	public AccelerationLine: HtmlReportVibrationEventLineViewModel;
	public FrequencyLine: HtmlReportVibrationEventLineViewModel;
	public EventHeader: HtmlReportVibrationEventHeaderViewModel;
	public OccurrenceData: { [key:number]: number[] };
	public OccurrenceVector: { [key:number]: number[] };
	public Color: string;
}
