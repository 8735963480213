//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum NotificationsTypes {
	Information = 0,
	ValueAlarm = 1,
	Watchdog = 2,
	SystemWD = 21,
	ImportWD = 22,
	SensorWD = 23,
	ChainWD = 24,
	PrismNotMeasured = 111,
	PrismNotAccurate = 112,
	PrismDeviationOverLimit = 113,
	TachymeterIsBusy = 121,
	TachymeterInitializationError = 122,
	TachymeterMeasurementSessionError = 123,
	TachymeterInitializedSuccesfully = 124,
	TachymeterShutDownError = 125,
	FixpointRemoved = 131,
	TooLessFixpoints = 132,
	NoDataForCremer = 133,
	InclinometerChainNotMeasured = 211,
	DimetrixNoData = 311,
	BoxBatteryProblems = 411,
	BoxOffline = 412,
	RemotePowerAsymmetry = 413,
	AllSensorsNotMeasured = 414,
	RebootModemIsOffline = 415,
	CorruptedDataFromBox = 416,
	Mst23MainSwitch = 417,
	DatabaseProblems = 421,
	LoRaBatteryProblem = 501,
	DataImportSuccess = 611,
	DataImportError = 612,
	ExportTaskError = 621,
	BackupSuccesful = 711,
	BackupFailed = 712,
	MonthStatisticSuccessful = 721,
	MonthStatisticFailed = 722,
	WeekStatisticSuccessful = 723,
	WeekStatisticFailed = 724,
	InvoiceSuccessful = 731,
	InvoiceFailed = 732,
	ProjectArchiveFinished = 741,
	ProjectArchiveFailed = 742,
	ProjectRestoringFinished = 743,
	ProjectRestoringFailed = 744,
	ApplicationFeedbackRegistered = 751,
	UserFeedbackRegistered = 752,
	ApplicationServiceConnectionFailure = 761,
	ApplicationServiceConnectionRecover = 762,
	MaintenanceServiceConnectionFailure = 763,
	MaintenanceServiceConnectionRecover = 764,
	MaintenanceServiceReport = 765,
	SyncPostProcessingQueueOverloaded = 766,
	OtherFunctionalAlarms = 1000
}

const NotificationsTypesToName = new Map<number, string>([
	[ 0, 'Information' ],
	[ 1, 'ValueAlarm' ],
	[ 2, 'Watchdog' ],
	[ 21, 'SystemWD' ],
	[ 22, 'ImportWD' ],
	[ 23, 'SensorWD' ],
	[ 24, 'ChainWD' ],
	[ 111, 'PrismNotMeasured' ],
	[ 112, 'PrismNotAccurate' ],
	[ 113, 'PrismDeviationOverLimit' ],
	[ 121, 'TachymeterIsBusy' ],
	[ 122, 'TachymeterInitializationError' ],
	[ 123, 'TachymeterMeasurementSessionError' ],
	[ 124, 'TachymeterInitializedSuccesfully' ],
	[ 125, 'TachymeterShutDownError' ],
	[ 131, 'FixpointRemoved' ],
	[ 132, 'TooLessFixpoints' ],
	[ 133, 'NoDataForCremer' ],
	[ 211, 'InclinometerChainNotMeasured' ],
	[ 311, 'DimetrixNoData' ],
	[ 411, 'BoxBatteryProblems' ],
	[ 412, 'BoxOffline' ],
	[ 413, 'RemotePowerAsymmetry' ],
	[ 414, 'AllSensorsNotMeasured' ],
	[ 415, 'RebootModemIsOffline' ],
	[ 416, 'CorruptedDataFromBox' ],
	[ 417, 'Mst23MainSwitch' ],
	[ 421, 'DatabaseProblems' ],
	[ 501, 'LoRaBatteryProblem' ],
	[ 611, 'DataImportSuccess' ],
	[ 612, 'DataImportError' ],
	[ 621, 'ExportTaskError' ],
	[ 711, 'BackupSuccesful' ],
	[ 712, 'BackupFailed' ],
	[ 721, 'MonthStatisticSuccessful' ],
	[ 722, 'MonthStatisticFailed' ],
	[ 723, 'WeekStatisticSuccessful' ],
	[ 724, 'WeekStatisticFailed' ],
	[ 731, 'InvoiceSuccessful' ],
	[ 732, 'InvoiceFailed' ],
	[ 741, 'ProjectArchiveFinished' ],
	[ 742, 'ProjectArchiveFailed' ],
	[ 743, 'ProjectRestoringFinished' ],
	[ 744, 'ProjectRestoringFailed' ],
	[ 751, 'ApplicationFeedbackRegistered' ],
	[ 752, 'UserFeedbackRegistered' ],
	[ 761, 'ApplicationServiceConnectionFailure' ],
	[ 762, 'ApplicationServiceConnectionRecover' ],
	[ 763, 'MaintenanceServiceConnectionFailure' ],
	[ 764, 'MaintenanceServiceConnectionRecover' ],
	[ 765, 'MaintenanceServiceReport' ],
	[ 766, 'SyncPostProcessingQueueOverloaded' ],
	[ 1000, 'OtherFunctionalAlarms' ],
])

export function getNotificationsTypesToName(element: NotificationsTypes) : string {
    return NotificationsTypesToName.get(element) || '';
}

const NotificationsTypesByName = new Map<string, number>([
	[ 'Information', 0 ],
	[ 'ValueAlarm', 1 ],
	[ 'Watchdog', 2 ],
	[ 'SystemWD', 21 ],
	[ 'ImportWD', 22 ],
	[ 'SensorWD', 23 ],
	[ 'ChainWD', 24 ],
	[ 'PrismNotMeasured', 111 ],
	[ 'PrismNotAccurate', 112 ],
	[ 'PrismDeviationOverLimit', 113 ],
	[ 'TachymeterIsBusy', 121 ],
	[ 'TachymeterInitializationError', 122 ],
	[ 'TachymeterMeasurementSessionError', 123 ],
	[ 'TachymeterInitializedSuccesfully', 124 ],
	[ 'TachymeterShutDownError', 125 ],
	[ 'FixpointRemoved', 131 ],
	[ 'TooLessFixpoints', 132 ],
	[ 'NoDataForCremer', 133 ],
	[ 'InclinometerChainNotMeasured', 211 ],
	[ 'DimetrixNoData', 311 ],
	[ 'BoxBatteryProblems', 411 ],
	[ 'BoxOffline', 412 ],
	[ 'RemotePowerAsymmetry', 413 ],
	[ 'AllSensorsNotMeasured', 414 ],
	[ 'RebootModemIsOffline', 415 ],
	[ 'CorruptedDataFromBox', 416 ],
	[ 'Mst23MainSwitch', 417 ],
	[ 'DatabaseProblems', 421 ],
	[ 'LoRaBatteryProblem', 501 ],
	[ 'DataImportSuccess', 611 ],
	[ 'DataImportError', 612 ],
	[ 'ExportTaskError', 621 ],
	[ 'BackupSuccesful', 711 ],
	[ 'BackupFailed', 712 ],
	[ 'MonthStatisticSuccessful', 721 ],
	[ 'MonthStatisticFailed', 722 ],
	[ 'WeekStatisticSuccessful', 723 ],
	[ 'WeekStatisticFailed', 724 ],
	[ 'InvoiceSuccessful', 731 ],
	[ 'InvoiceFailed', 732 ],
	[ 'ProjectArchiveFinished', 741 ],
	[ 'ProjectArchiveFailed', 742 ],
	[ 'ProjectRestoringFinished', 743 ],
	[ 'ProjectRestoringFailed', 744 ],
	[ 'ApplicationFeedbackRegistered', 751 ],
	[ 'UserFeedbackRegistered', 752 ],
	[ 'ApplicationServiceConnectionFailure', 761 ],
	[ 'ApplicationServiceConnectionRecover', 762 ],
	[ 'MaintenanceServiceConnectionFailure', 763 ],
	[ 'MaintenanceServiceConnectionRecover', 764 ],
	[ 'MaintenanceServiceReport', 765 ],
	[ 'SyncPostProcessingQueueOverloaded', 766 ],
	[ 'OtherFunctionalAlarms', 1000 ],
]);

export function getNotificationsTypesByName(name: string): NotificationsTypes {
    return NotificationsTypesByName.get(name)!;
}


export const NotificationsTypesList : Readonly<NotificationsTypes[]> = [
	NotificationsTypes.Information,
	NotificationsTypes.ValueAlarm,
	NotificationsTypes.Watchdog,
	NotificationsTypes.SystemWD,
	NotificationsTypes.ImportWD,
	NotificationsTypes.SensorWD,
	NotificationsTypes.ChainWD,
	NotificationsTypes.PrismNotMeasured,
	NotificationsTypes.PrismNotAccurate,
	NotificationsTypes.PrismDeviationOverLimit,
	NotificationsTypes.TachymeterIsBusy,
	NotificationsTypes.TachymeterInitializationError,
	NotificationsTypes.TachymeterMeasurementSessionError,
	NotificationsTypes.TachymeterInitializedSuccesfully,
	NotificationsTypes.TachymeterShutDownError,
	NotificationsTypes.FixpointRemoved,
	NotificationsTypes.TooLessFixpoints,
	NotificationsTypes.NoDataForCremer,
	NotificationsTypes.InclinometerChainNotMeasured,
	NotificationsTypes.DimetrixNoData,
	NotificationsTypes.BoxBatteryProblems,
	NotificationsTypes.BoxOffline,
	NotificationsTypes.RemotePowerAsymmetry,
	NotificationsTypes.AllSensorsNotMeasured,
	NotificationsTypes.RebootModemIsOffline,
	NotificationsTypes.CorruptedDataFromBox,
	NotificationsTypes.Mst23MainSwitch,
	NotificationsTypes.DatabaseProblems,
	NotificationsTypes.LoRaBatteryProblem,
	NotificationsTypes.DataImportSuccess,
	NotificationsTypes.DataImportError,
	NotificationsTypes.ExportTaskError,
	NotificationsTypes.BackupSuccesful,
	NotificationsTypes.BackupFailed,
	NotificationsTypes.MonthStatisticSuccessful,
	NotificationsTypes.MonthStatisticFailed,
	NotificationsTypes.WeekStatisticSuccessful,
	NotificationsTypes.WeekStatisticFailed,
	NotificationsTypes.InvoiceSuccessful,
	NotificationsTypes.InvoiceFailed,
	NotificationsTypes.ProjectArchiveFinished,
	NotificationsTypes.ProjectArchiveFailed,
	NotificationsTypes.ProjectRestoringFinished,
	NotificationsTypes.ProjectRestoringFailed,
	NotificationsTypes.ApplicationFeedbackRegistered,
	NotificationsTypes.UserFeedbackRegistered,
	NotificationsTypes.ApplicationServiceConnectionFailure,
	NotificationsTypes.ApplicationServiceConnectionRecover,
	NotificationsTypes.MaintenanceServiceConnectionFailure,
	NotificationsTypes.MaintenanceServiceConnectionRecover,
	NotificationsTypes.MaintenanceServiceReport,
	NotificationsTypes.SyncPostProcessingQueueOverloaded,
	NotificationsTypes.OtherFunctionalAlarms,
];



const NotificationsTypesToDescription = new Map<number, string>([
	[ 0, 'Information' ],
	[ 1, 'Value alarm' ],
	[ 2, 'Watchdog' ],
	[ 21, 'System Watchdog' ],
	[ 22, 'Import Watchdog' ],
	[ 23, 'Sensor Watchdog' ],
	[ 24, 'Chain Watchdog' ],
	[ 111, 'Prism not measured' ],
	[ 112, 'Prism not accurate' ],
	[ 113, 'Prism jump movement' ],
	[ 121, 'Tachymeter is busy' ],
	[ 122, 'Tachymeter initialization error' ],
	[ 123, 'Tachymeter measurement session error' ],
	[ 124, 'Tachymeter initialized successfully' ],
	[ 125, 'Tachymeter shutdown error' ],
	[ 131, 'Fixpoint removed' ],
	[ 132, 'Too less fixpoints' ],
	[ 133, 'No data for Cremer' ],
	[ 211, 'Inclinometer chain not measured' ],
	[ 311, 'Dimetrix no data' ],
	[ 411, 'Box battery problems' ],
	[ 412, 'Box offline' ],
	[ 413, 'Remote power asymmetry' ],
	[ 414, 'All sensors not measured' ],
	[ 415, 'Reboot, because modem offline' ],
	[ 416, 'Corrupted data from box' ],
	[ 417, 'MST23 Main Switch' ],
	[ 421, 'Database problems' ],
	[ 501, 'LoRa Battery problem' ],
	[ 611, 'Data Import Success' ],
	[ 612, 'Data Import Error' ],
	[ 621, 'Export Task Error' ],
	[ 711, 'Backup successfully finished' ],
	[ 712, 'Backup failed' ],
	[ 721, 'Creation of statistics finished' ],
	[ 722, 'Creation of statistics failed' ],
	[ 723, 'Creation of weekly statistics finished' ],
	[ 724, 'Creation of weekly statistics failed' ],
	[ 731, 'Creation of invoices finished' ],
	[ 732, 'Creation of invoices failed' ],
	[ 741, 'Project archive finished' ],
	[ 742, 'Project archive failed' ],
	[ 743, 'Project restored' ],
	[ 744, 'Project restoring failed' ],
	[ 751, 'GEOvis4 Client Application Feedback' ],
	[ 752, 'GEOvis4 Client User Feedback' ],
	[ 761, 'GEOvis Application Service Failure' ],
	[ 762, 'GEOvis Application Service Recover' ],
	[ 763, 'GEOvis Maintenance Server Failure' ],
	[ 764, 'GEOvis Maintenance Server Recover' ],
	[ 765, 'GEOvis Maintenance Service Report' ],
	[ 766, 'GEOvis SyncPostProcessing Queue overloaded' ],
	[ 1000, 'Other functional alarms' ],
])

export function getNotificationsTypesToDescription(element: NotificationsTypes) : string {
    return NotificationsTypesToDescription.get(element) || '';
}

