import { GEOVIS_LIVE_API_ROOT } from "../ApiConfig";
import { AlarmInfo } from "../server/AlarmInfo";
import { AlarmType } from "../server/AVTService/TypeLibrary/Alarming/AlarmType";
import { ProjectSensorAlarmInfo } from "../server/ProjectSensorAlarmInfo";
import { SensorChartInfo } from "../server/SensorChartInfo";

export function geovisLiveEditAlarmUrl(projectId: number, alarm: AlarmInfo): string {
    switch (alarm.AlarmType) {
        case AlarmType.AGMSAlarm:
            return getGeovisLiveUrl(`Logbook?projectId=${projectId}&recordId=${alarm.AlarmId}`);
        case AlarmType.SensorAlarm:
            return getGeovisLiveUrl(`Alarm/Edit/${alarm.AlarmId}`);
    }
    return "";
}

export function geovisLiveEditTriggeredAlarmUrl(alarm: ProjectSensorAlarmInfo): string {
    return getGeovisLiveUrl(`Alarm/Edit/${alarm.AlarmId}`);
}

export function geovisLiveEditChartUrl(chart: SensorChartInfo): string {
    return getGeovisLiveUrl(`Chart/Edit/${chart.ChartId}`);
}

export function geovisLiveProjectUrl(projectId: number): string {
    return getGeovisLiveUrl(`ProjectElements/Elements/${projectId}?viewId=custom`);
}

export function geovisLiveSensorsUrl(projectId: number): string {
    return getGeovisLiveUrl(`ProjectElements/Sensors/${projectId}?type=SENSORS#alias=&sensor=&matchCase=false&units=&types=&public=All&page=1`);
}

export function geovisLiveChainsUrl(projectId: number): string {
    return getGeovisLiveUrl(`ProjectElements/Sensors/${projectId}?type=CHAINS#alias=&sensor=&matchCase=false&units=&types=&public=All&page=1`);
}

export function geovisLiveVibrationsUrl(projectId: number): string {
    return getGeovisLiveUrl(`ProjectElements/Sensors/${projectId}?type=VIBRATION SENSORS#alias=&sensor=&matchCase=false&units=&types=&public=All&page=1`);
}

export function geovisLiveReportsUrl(projectId: number): string {
    return getGeovisLiveUrl(`ProjectElements/Reports/${projectId}`);
}

export function getGeovisLiveUrl(subUrl: string): string {
    return `${GEOVIS_LIVE_API_ROOT}/${subUrl}`;
}