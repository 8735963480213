import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { IGeovisChartReportData } from "../../../../../../store/projectReport.types";
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../../../../../../store/types";
import { LoadingContainerSkeleton } from "../../../../../LoadingContainerSkeleton";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../../helpers/GeovisHooks";
import { t } from "../../../../../../i18n";
import { InclinometerChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { InclinometerChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/InclinometerChartData";
import { getInclinometerChartRenderOptions } from "../options/inclinometerChartRenderOptions";
import { fetchServerElementsByPost } from "../../../../../../helpers/ProjectDataHelper";
import { GeovisChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartData";
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import ServerRoutesGen from "../../../../../../server/Routes/ServerRoutesGen";
import { ErrorContainerSkeleton } from "../../../../../ErrorContainerSkeleton";
import { InclinometerChartAdditionalInfo } from "../renders/extraComponents/InclinometerChartAdditionalInfo";
import { InclinometerChartLegend } from "../renders/extraComponents/InclinometerChartLegend";


interface IComponentProps extends IWithGeovisServerProps {
    chart: InclinometerChartModel;
    projectId: number;
}

interface IComponentState {
    chartData: IGeovisChartReportData<InclinometerChartData>;
}

const Component = ({
    chart,
    Server,
    projectId
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        chartData: {
            ...defaultSomethingStorageState,
            dtsSectionInfos: [],
            Timestamp: 0,
            data: {
                ...new InclinometerChartData()
            }
        }
    });

    useEffect(() => {
        (async function loadChartData() {
            const url = ServerRoutesGen.ReportRenderData.GetGeovisPreviewChartCustomData.patch(projectId);
            const response = await fetchServerElementsByPost<GeovisChartData, GeovisChartModel>(Server, url, chart);
            if (!response.Success) {
                setState({
                    ...state,
                    chartData: {
                        ...errorSomethingStorageState(...response.Messages),
                        dtsSectionInfos: [],
                        Timestamp: 0,
                        data: {
                            ...new InclinometerChartData()
                        }
                    }
                });
                return;
            }
            setState({
                ...state,
                chartData: {
                    ...loadedSomethingStorageState,
                    data: response.Data as InclinometerChartData,
                    dtsSectionInfos: [],
                    Timestamp: 0
                }
            })
        })()
    }, [1])

    if (!state.chartData.isLoaded) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <LoadingContainerSkeleton loadingText={t("Loading Inclinometer preview chart data")} />
            </div>
        )
    }

    if (state.chartData.isError) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <ErrorContainerSkeleton errorText={`${t("Failed to load Inclinometer chart data")}: ${state.chartData.errorDescription}`} />
            </div>
        )
    }

    const { data, Timestamp } = state.chartData

    const renderOptions = getInclinometerChartRenderOptions(0, cloneDeep(chart), data);

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);
    HighchartsBoost(Highcharts);

    return (
        <div className="report-chart-render" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <InclinometerChartAdditionalInfo
                showDateOfReference={chart.ShowReferenceDate}
                showStartMeasurements={chart.ShowStartMeasurements}
                startMeasurements={data.startMeasurementTime}
                referenceDate={data.ReferencesTime}
            />
            <div style={{ display: 'flex', flexDirection: chart.IsHorizontal ? 'column' : 'row', flexWrap: 'nowrap', flexGrow: 1 }}>
                {renderOptions.map<JSX.Element | null>((option, index) => (
                    <HighchartsReact
                        key={`highcharts-${chart.Id}-${index}-${Timestamp}`}
                        highcharts={Highcharts}
                        options={option}
                        constructorType={'chart'}
                        containerProps={{ style: { width: '100%', height: '100%' } }}
                    />
                ))}
            </div>
            {chart.ShowLegend.value &&
                <InclinometerChartLegend
                    legendText={chart.LegendText}
                    linesData={data.LineInfo}
                />
            }
        </div>
    )

}

export const InclinometerPreviewChartRender = withGeovisServer(Component);

