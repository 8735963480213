import L, { DomUtil, FeatureGroup } from 'leaflet';

L.Tooltip.include({
    _updatePosition() {
        const thisRef: L.Tooltip = this;

        const pos = thisRef._map.latLngToLayerPoint(thisRef._latlng);
        if (thisRef.options.useSmartPosition) {
            thisRef.setSmartPosition(pos);
        }
        else {
            thisRef._setPosition(pos);
        }
    },

    setSmartPosition(pos: L.Point) {
        const thisRef: L.Tooltip = this;

        const container = this._container;
        const tooltipWidth = container.offsetWidth;
        const tooltipHeight = container.offsetHeight;

        const tooltipPoint = thisRef._map.layerPointToContainerPoint(pos);

        const offset = L.point(this.options.offset);

        const mapSize = thisRef._map.getSize();

        let subX = tooltipWidth / 2;
        let subY = tooltipHeight;
        let myClassName = "";
        let mustBeOnTop = true;

        if (tooltipPoint.x - subX < 0) {
            subX = subX / 2;
            myClassName = "mapSectionViewLeft25Tooltip";

            if (tooltipPoint.x - subX < 0) {
                subX = tooltipWidth / 100 * 2;
                myClassName = "mapSectionViewLeft2Tooltip";
            }
        }
        else if (tooltipPoint.x + subX > mapSize.x) {
            subX += (subX / 2);
            myClassName = "mapSectionViewLeft75Tooltip";

            if (tooltipPoint.x + subX > mapSize.x) {
                subX = tooltipWidth / 100 * 98;
                myClassName = "mapSectionViewLeft98Tooltip";
            }
        }
        if (tooltipPoint.y - subY < 0) {
            mustBeOnTop = false;
            subY = -12;
        }

        const newPos = pos.subtract(L.point(subX, subY, true)).add(offset);

        DomUtil.removeClass(container, 'leaflet-tooltip-right');
        DomUtil.removeClass(container, 'leaflet-tooltip-left');
        DomUtil.removeClass(container, 'leaflet-tooltip-top');
        DomUtil.removeClass(container, 'leaflet-tooltip-bottom');

        if (myClassName.length > 0) {
            DomUtil.addClass(container, myClassName);
        }
        if (mustBeOnTop) {
            DomUtil.addClass(container, 'leaflet-tooltip-top');
        }
        else {
            DomUtil.addClass(container, 'leaflet-tooltip-bottom');
        }

        DomUtil.setPosition(container, newPos);
    }
});

L.Layer.include({
    // we have to override the library function to fix the problem mentioned in see AGMS-3780:
    // "clicking on an LMO should not show the tooltip in the middle of the object"
    openTooltip: function (latlng: any) {
        if (this._tooltip) {
            if (!(this instanceof FeatureGroup)) {
                this._tooltip._source = this;
            }

            // Here it is our improvement
            const correctLatLng = this.getCorrectLatLng(latlng);

            if (this._tooltip._prepareOpen(correctLatLng)) {
                // open the tooltip on the map
                this._tooltip.openOn(this._map);

                if (this.getElement) {
                    this._setAriaDescribedByOnLayer(this);
                } else if (this.eachLayer) {
                    this.eachLayer(this._setAriaDescribedByOnLayer, this);
                }
            }
        }
        return this;
    },

    getCorrectLatLng(latlng: any) {
        if (!latlng) {
            if (this._tooltip.options.sticky && this._tooltip._latlng) {
                return this._tooltip._latlng;
            }
        }
        return latlng;
    }

})