//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class DtsChartSettings
{
	public UseClosestTunnelmeter: boolean;
	public ClosestTunnelmeter: number;
	public FromTunnelmeter: number;
	public ToTunnelmeter: number;
	public DtsSectionIds: string[];
}
