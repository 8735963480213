/**
 * @author Vyacheslav Skripin <vs@isekr.ru>
 * @created 28.12.2020
 * @description History of the property of the project history model
 */

import Button from '@atlaskit/button';
import Checkbox from '@atlaskit/checkbox';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { HeadType, RowType } from '@atlaskit/dynamic-table/types';
import AddIcon from '@atlaskit/icon/glyph/add';
import CheckIcon from '@atlaskit/icon/glyph/check';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import { OptionType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import moment from 'moment';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import GeovisReactDateTimePicker from '../../../../components/dateTimePickers/GeovisReactDateTimePicker';
import { GeovisSelect, GeovisSelectMulti } from '../../../../components/select/GeovisSelect';
import { getProjectTypeSelectOptions, getServiceModelOptions, projectTypeToSelectOption, serviceModelToSelectOption } from '../../../../components/select/tools';
import { dateTimeToReactMoment } from '../../../../helpers/DateHelper';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../helpers/GeovisHooks';
import { fetchServerElements, fetchServerElementsByPost, successRequest } from '../../../../helpers/ProjectDataHelper';
import { elementsToMap, mapToListOfElements } from '../../../../helpers/StorageHelper';
import { t } from '../../../../i18n';
import { getProjectTypeToName, ProjectType } from '../../../../server/AVTService/TypeLibrary/Common/ProjectType';
import { getServiceModelToName, ServiceModel } from '../../../../server/AVTService/TypeLibrary/Common/ServiceModel';
import { ProjectBoolPropertyHistory } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectBoolPropertyHistory';
import { ProjectDateTimeNullablePropertyHistory } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectDateTimeNullablePropertyHistory';
import { ProjectDateTimePropertyHistory } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectDateTimePropertyHistory';
import { ProjectHistoryModel } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectHistoryModel';
import { getProjectHistoryStateToName, ProjectHistoryState, ProjectHistoryStateList } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectHistoryState';
import { ProjectListStringPropertyHistory } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectListStringPropertyHistory';
import { ProjectPropertyHistory } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectPropertyHistory';
import { ProjectStatePropertyHistory } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectStatePropertyHistory';
import { ProjectStringPropertyHistory } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectStringPropertyHistory';
import { ProjectTypePropertyHistory } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ProjectTypePropertyHistory';
import { ServiceModelPropertyHistory } from '../../../../server/AVTService/TypeLibrary/ProjectsHistory/ServiceModelPropertyHistory';
import { DataActionResponse } from '../../../../server/DataActionResponse';
import { CompanyInfo } from '../../../../server/GEOvis3/Model/Company/CompanyInfo';
import ServerRoutesGen from '../../../../server/Routes/ServerRoutesGen';
import AuthService from '../../../../services/AuthService';
import FlagService from '../../../../services/FlagService';
import {
    defaultSomethingStorageState,
    errorSomethingStorageState,
    ISomethingStoreBase,
    loadedSomethingStorageState
} from '../../../../store/types';
import { ProjectPropertyHistoryUserName } from './ProjectPropertyHistoryUserName';

const Locale = "en-GB";

interface IProjectHistoryPropertyState extends ISomethingStoreBase {
    records: Map<string, ProjectPropertyHistory>;
    companies: Map<string, CompanyInfo>;

    editRecordId: string;
}

interface IPropertyHistoryProps extends IWithGeovisServerProps {
    historyId: string;
    propertyName: keyof ProjectHistoryModel;
}

export const ProjectHistoryPropertyHistory = withGeovisServer(({ Server, historyId, propertyName }: IPropertyHistoryProps) => {

    const [storage, setStorage] = useState<IProjectHistoryPropertyState>({
        ...defaultSomethingStorageState,
        records: new Map<string, ProjectPropertyHistory>(),
        companies: new Map<string, CompanyInfo>(),
        editRecordId: ''
    });


    useEffect(() => {

        (async function loadPropertyHistory() {
            const recordsData = await fetchServerElements<ProjectPropertyHistory[]>(Server, ServerRoutesGen.ProjectsHistory.GetHistoryOfProperty.patch(historyId, propertyName));

            if (!recordsData.Success) {
                setStorage({
                    ...errorSomethingStorageState(recordsData.Messages.join(', ')),
                    records: new Map<string, ProjectPropertyHistory>(),
                    companies: new Map<string, CompanyInfo>(),
                    editRecordId: ''
                });
                return;
            }

            let companiesData: DataActionResponse<CompanyInfo[]> = successRequest<CompanyInfo[]>([]);

            // load companies for Company property
            if (propertyName === "CreatorsCompaniesIds" || propertyName === "CompanyOwnerId") {
                companiesData = await fetchServerElementsByPost<CompanyInfo[], any>(Server, ServerRoutesGen.ProjectsHistory.GetCompanies, {});
            }

            setStorage({
                ...loadedSomethingStorageState,
                records: elementsToMap(recordsData.Data),
                companies: companiesData.Success ? elementsToMap(companiesData.Data) : new Map<string, CompanyInfo>(),
                editRecordId: ''
            });
        })();

    }, [propertyName])


    const onCreate = () => {

        const record = getDefaultPropertyHistory(historyId, propertyName);

        storage.records.set('', record);
        setStorage({ ...storage, editRecordId: '' });
    }

    const onEdit = (editRecordId: string) => setStorage({ ...storage, editRecordId });

    const onCancel = () => {
        storage.records.delete('');
        setStorage({ ...storage, editRecordId: '' });
    }

    const onSave = async (recordId: string, dateTime: string, value: any) => {

        const record = storage.records.get(recordId);
        if (!record) {
            return;
        }

        record.SinceDate = dateTime;

        if (propertyName === "IsGeovis4Package") {

            const boolRecord = record as ProjectBoolPropertyHistory;
            boolRecord.Value = value;

        } else if (propertyName === "ProjectCreationDate") {

            const dateRecord = record as ProjectDateTimePropertyHistory;
            dateRecord.Value = value;

        } else if (propertyName === "ProjectEndDate") {

            const dateRecord = record as ProjectDateTimeNullablePropertyHistory;
            dateRecord.Value = value;

        } else {

            const stringRecord = record as ProjectStringPropertyHistory;
            stringRecord.Value = value;
        }

        try {

            const url = ServerRoutesGen.ProjectsHistory.SaveProjectPropertyRecord.path;
            const response = await Server.post<DataActionResponse<ProjectPropertyHistory>>(url, record);

            if (response.Success) {

                const { Data } = response;

                if (recordId === '') {
                    storage.records.delete(recordId);
                }

                storage.records.set(Data.Id, Data);

            } else {
                FlagService.addErrors(t("Cannot save property record"), response.Messages);
            }

        }
        catch (error) {
            FlagService.addError(t("An error to save the property record"), error);
        }

        setStorage({ ...storage, editRecordId: '' });
    }


    const rows = getHistoryTableRows(storage, propertyName, onEdit, onSave, onCancel);

    return (
        <div style={{ padding: '1em' }}>
            <h4>
                {t("History of property")}: "{propertyName}"
            </h4>
            <div>
                <Button
                    iconBefore={<AddIcon label={""} size="small" />}
                    onClick={onCreate}
                    spacing="compact">
                    {t("Add")}
                </Button>
            </div>
            <div>
                <DynamicTableStateless
                    sortKey='date'
                    sortOrder="ASC"
                    isLoading={storage.isLoading}
                    head={getHistoryTableHead()}
                    rows={rows}
                />
            </div>
        </div>
    )
});

const getHistoryTableHead = (): HeadType => ({
    cells: [{
        key: 'date',
        content: t("Date")
    }, {
        key: 'value',
        content: t("Value")
    }, {
        key: 'changedBy',
        content: t("Changed by")
    }, {
        key: 'actions',
        content: ''
    }]
});

const getHistoryTableRows = (
    storage: IProjectHistoryPropertyState,
    propertyName: keyof ProjectHistoryModel,
    onEdit: (recordId: string) => void,
    onSave: (recordId: string, dateTime: string, value: any) => void,
    onCancel: () => void
): RowType[] => {

    const { isLoading, errorDescription, records, isError, editRecordId, companies } = storage;

    const editableRecord = records.get(editRecordId);

    const [datePickerValue, setDatePickerValue] = useState<string>('');

    const getInitialValue = (record: any): any => {
        if (!record) {
            switch (propertyName) {
                case 'ProjectState':
                    return ProjectHistoryState.Active;
                case 'CreatorsCompaniesIds':
                    return [];
                case 'CompanyOwnerId':
                    return "";
                case 'IsGeovis4Package':
                    return false;
                case 'ProjectCreationDate':
                    return moment().format();
                case 'ProjectEndDate':
                    return moment().format();
                default:
                    return null;
            }
        }
        if (record.Value !== undefined) {
            return record.Value;
        }
        else {
            return null;
        }
    }

    const [value, setValue] = useState<any>(getInitialValue(editableRecord));

    useEffect(() => {
        if (editableRecord) {
            setDatePickerValue(editableRecord.SinceDate);
            if ((editableRecord && value !== getInitialValue(editableRecord))) {
                setValue(getInitialValue(editableRecord));
            }
        }
    }, [editableRecord]);

    if (isLoading) {
        return [];
    }

    if (isError) {
        return [{
            cells: [{
                colSpan: 4,
                content: errorDescription
            }]
        }]
    }


    return mapToListOfElements(records).map<RowType>(record => {

        const isEditMode = record.Id === editRecordId;

        const onSaveHandler = () => onSave(record.Id, datePickerValue, value);

        const onEditHandler = () => onEdit(record.Id);

        return {
            key: `row-${record.Id}`,
            cells: [{
                key: record.SinceDate,
                content: !isEditMode
                    ? dateTimeToReactMoment(record.SinceDate)
                    : (<PropertyHistoryDateTimePicker record={record} onChange={setDatePickerValue} />)
            }, {
                content: !isEditMode
                    ? (<PropertyHistoryValueViewer
                        record={record}
                        propertyName={propertyName}
                        companies={companies}
                    />)
                    : (<PropertyHistoryValueEditor
                        record={record}
                        onChange={setValue}
                        propertyName={propertyName}
                        companies={companies}
                    />)
            }, {
                content: (<ProjectPropertyHistoryUserName UserId={record.UserId} />)
            }, {
                content: (
                    <React.Fragment>
                        {!isEditMode && (
                            <Button
                                appearance="subtle"
                                spacing="compact"
                                iconBefore={<EditFilledIcon label={""} size="small" />}
                                onClick={onEditHandler}
                            />
                        )}
                        {isEditMode && (
                            <React.Fragment>
                                {/* save */}
                                <Button
                                    appearance="subtle"
                                    spacing="compact"
                                    iconBefore={<CheckIcon label={""} size="small" />}
                                    onClick={onSaveHandler}
                                />

                                {/* cancel */}
                                <Button
                                    appearance="subtle"
                                    spacing="compact"
                                    iconBefore={<EditorCloseIcon label={""} size="small" />}
                                    onClick={onCancel}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )
            }]
        }
    })
}


const PropertyHistoryDateTimePicker = ({ record, onChange }: {
    record: ProjectPropertyHistory,
    onChange: (value: string) => void
}) => (
    <div style={{ width: '220px' }}>
        <GeovisReactDateTimePicker
            value={record.SinceDate}
            locale={Locale}
            onChange={onChange}
        />
    </div>
)

const PropertyHistoryValueViewer = ({ record, propertyName, companies }: {
    record: ProjectPropertyHistory,
    propertyName: keyof ProjectHistoryModel,
    companies: Map<string, CompanyInfo>
}) => {

    if (propertyName === "IsGeovis4Package") {
        const boolRecord = record as ProjectBoolPropertyHistory;
        return (
            <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center' }}>
                <Checkbox label="" isChecked={boolRecord.Value} />
            </div>
        )
    }

    if (propertyName === "CompanyOwnerId") {
        const stringRecord = record as ProjectStringPropertyHistory;
        return (
            <ProjectHistoryPropertyCompanyTableCell
                companyId={stringRecord.Value}
                companies={companies} />
        )
    }

    if (propertyName === "CreatorsCompaniesIds") {
        const listRecord = record as ProjectListStringPropertyHistory;
        return (
            <ProjectHistoryPropertyCreatorsCompaniesTableCell
                creators={listRecord.Value}
                companies={companies} />
        )
    }

    if (propertyName === "ProjectEndDate") {
        const dateRecord = record as ProjectDateTimeNullablePropertyHistory;
        return dateTimeToReactMoment(dateRecord.Value);
    }

    if (propertyName === "ProjectCreationDate") {
        const dateRecord = record as ProjectDateTimePropertyHistory;
        return dateTimeToReactMoment(dateRecord.Value);
    }

    if (propertyName === "ProjectState") {
        const stateRecord = record as ProjectStatePropertyHistory;
        return (
            <span>{getProjectHistoryStateToName(stateRecord.Value)}</span>
        )
    }

    if (propertyName === "ProjectType") {
        const projectTypeRecord = record as ProjectTypePropertyHistory;
        return (
            <span>{getProjectTypeToName(projectTypeRecord.Value)}</span>
        )
    }

    if (propertyName === "ServiceModel") {
        const serviceModelRecord = record as ServiceModelPropertyHistory;
        return (
            <span>{getServiceModelToName(serviceModelRecord.Value)}</span>
        )
    }

    return (<span>&nbsp;</span>);
}


const PropertyHistoryValueEditor = ({ record, onChange, propertyName, companies }: {
    record: ProjectPropertyHistory,
    propertyName: keyof ProjectHistoryModel,
    companies: Map<string, CompanyInfo>,
    onChange: (value: any) => void
}) => {

    if (propertyName === "CompanyOwnerId") {
        const stringRecord = record as ProjectStringPropertyHistory;
        return (
            <ProjectHistoryPropertyCompanySelectTableCell
                companyId={stringRecord.Value}
                companies={companies}
                onSelect={onChange}
            />
        )
    }

    if (propertyName === "CreatorsCompaniesIds") {
        const listRecord = record as ProjectListStringPropertyHistory;
        return (
            <ProjectHistoryPropertyCreatorCompaniesSelectTableCell
                creators={listRecord.Value}
                companies={companies}
                onSelect={onChange}
            />
        )
    }

    if (propertyName === "IsGeovis4Package") {
        const boolRecord = record as ProjectBoolPropertyHistory;
        return (
            <ProjectHistoryPropertyProjectInGeovis4CheckboxTableCell
                onSelect={onChange}
                value={boolRecord.Value}
            />
        )
    }

    if (propertyName === "ProjectState") {
        const stateRecord = record as ProjectStatePropertyHistory;
        return (
            <ProjectHistoryPropertySelectTableCell
                defaultOption={{ label: getProjectHistoryStateToName(stateRecord.Value), value: stateRecord.Value }}
                getOptions={getProjectHistoryStateSelectOptions}
                onChange={onChange}
            />
        )
    }

    if (propertyName === "ProjectType") {
        const projectTypeRecord = record as ProjectTypePropertyHistory;
        return (
            <ProjectHistoryPropertySelectTableCell
                defaultOption={projectTypeToSelectOption(projectTypeRecord.Value)}
                getOptions={getProjectTypeSelectOptions}
                onChange={onChange}

            />
        )
    }

    if (propertyName === "ServiceModel") {
        const serviceModelRecord = record as ServiceModelPropertyHistory;
        return (
            <ProjectHistoryPropertySelectTableCell
                defaultOption={serviceModelToSelectOption(serviceModelRecord.Value)}
                getOptions={getServiceModelOptions}
                onChange={onChange}

            />
        )
    }

    if (propertyName === "ProjectCreationDate" || propertyName === "ProjectEndDate") {
        const dateRecord = record as ProjectDateTimePropertyHistory;
        return (
            <div style={{ minWidth: '220px' }}>
                <GeovisReactDateTimePicker
                    value={dateRecord.Value}
                    locale={Locale}
                    onChange={onChange}
                />
            </div>
        )
    }

    const defaultRecord = record as ProjectStringPropertyHistory;

    return (
        <Textfield
            isCompact={true}
            defaultValue={defaultRecord.Value}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
        />
    )
}

interface IProjectHistoryPropertyCompanyTableCell {
    companies: Map<string, CompanyInfo>,
    companyId: string;
}

const ProjectHistoryPropertyCompanyTableCell = ({ companies, companyId }: IProjectHistoryPropertyCompanyTableCell) => {

    const company = companies.get(companyId);

    return (
        <React.Fragment>
            {company && company.Name}
            {!company && t("Company not found")}
        </React.Fragment>
    )
};

interface IProjectHistoryPropertyCreatorsCompaniesTableCell {
    companies: Map<string, CompanyInfo>,
    creators: string[];
}

const ProjectHistoryPropertyCreatorsCompaniesTableCell = ({ companies, creators }: IProjectHistoryPropertyCreatorsCompaniesTableCell) => {

    const creatorCompanies: CompanyInfo[] = [];
    creators.forEach(id => {
        const company = companies.get(id);
        if (company) {
            creatorCompanies.push(company);
        }
    });

    const creatorsPhrase = creatorCompanies.length > 0 ? creatorCompanies.map(c => c.Name).join('; ') : t("Companies not found")

    return (
        <React.Fragment>
            {creatorsPhrase}
        </React.Fragment>
    )
};

interface IProjectHistoryPropertyCompanySelectTableCellProps {
    companies: Map<string, CompanyInfo>,
    companyId: string;

    onSelect: (companyId: string) => void;
}

const ProjectHistoryPropertyCompanySelectTableCell = ({ onSelect, companies, companyId }: IProjectHistoryPropertyCompanySelectTableCellProps) => {

    const onSelectHandler = (option: OptionType) => onSelect(option.value.toString());

    return (
        <div style={{ minWidth: '200px' }}>
            <GeovisSelect
                placeholder={t("Select a company")}
                isSearchable={true}
                spacing="compact"
                defaultValue={getSelectedCompanyAsOptionType(companies, companyId)}
                options={getCompaniesAsOptionTypes(companies)}
                onChange={onSelectHandler}
            />
        </div>
    )
}

const getSelectedCompanyAsOptionType = (companies: Map<string, CompanyInfo>, companyId: string): OptionType => {
    const company = companies.get(companyId);

    if (company) {
        return ({
            label: company.Name,
            value: company.Id
        });
    }

    return ({ value: companyId, label: companyId });
}

const getCompaniesAsOptionTypes = (companies: Map<string, CompanyInfo>): OptionType[] =>
    mapToListOfElements(companies).map<OptionType>(c => ({ label: c.Name, value: c.Id }));


interface IProjectHistoryPropertyCreatorCompaniesSelectTableCellProps {
    companies: Map<string, CompanyInfo>,
    creators: string[];

    onSelect: (companiesIds: string[]) => void;
}

const ProjectHistoryPropertyCreatorCompaniesSelectTableCell = ({ onSelect, companies, creators }: IProjectHistoryPropertyCreatorCompaniesSelectTableCellProps) => {

    const onSelectHandler = (options: OptionType[]) => onSelect(options ? options.map(c => c.value.toString()) : []);

    return (
        <div style={{ minWidth: '200px' }}>
            <GeovisSelectMulti
                placeholder={t("Select companies")}
                isSearchable={true}
                spacing="compact"
                defaultValue={getSelectedCompaniesAsOptionType(companies, creators)}
                options={getCompaniesAsOptionTypes(companies)}
                onChange={onSelectHandler}
            />
        </div>
    )
}

const getSelectedCompaniesAsOptionType = (companies: Map<string, CompanyInfo>, creators: string[]): ReadonlyArray<OptionType> => {
    const selectedCompanies: OptionType[] = [];
    creators.forEach(id => {
        const company = companies.get(id);
        if (company) {
            selectedCompanies.push({
                label: company.Name,
                value: company.Id
            });
        }
    })
    return selectedCompanies;
}

const ProjectHistoryPropertyProjectInGeovis4CheckboxTableCell = ({ value, onSelect }: {
    value: boolean,
    onSelect: (value: boolean) => void
}) => {

    const onChangeHandler = (e: SyntheticEvent<HTMLInputElement>) => onSelect(e.currentTarget.checked);

    return (
        <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center' }}>
            <Checkbox defaultChecked={value} onChange={onChangeHandler} />
        </div>
    )
}

const getProjectHistoryStateSelectOptions = (): OptionType[] =>
    ProjectHistoryStateList.map<OptionType>(state => ({ value: state, label: getProjectHistoryStateToName(state) }));


const ProjectHistoryPropertySelectTableCell = ({ defaultOption, getOptions, onChange }: {
    defaultOption: OptionType,
    getOptions: () => OptionType[],
    onChange: (value: string) => void;
}) => (
    <div style={{ minWidth: '100px' }}>
        <GeovisSelect
            spacing="compact"
            defaultValue={defaultOption}
            options={getOptions()}
            onChange={(o: OptionType) => onChange(o.value.toString())}
        />
    </div>
)

const getDefaultPropertyHistory = (historyId: string, propertyName: keyof ProjectHistoryModel): ProjectPropertyHistory => {

    const sinceDate = new Date().toUTCString();
    const userId = AuthService.currentUserId();

    if (propertyName === "IsGeovis4Package") {
        const state: ProjectBoolPropertyHistory = {
            Id: '',
            HistoryId: historyId,
            PropertyName: propertyName,
            SinceDate: sinceDate,
            UserId: userId,
            Value: false
        };

        return state;
    }

    if (propertyName === "ProjectState") {
        const state: ProjectStatePropertyHistory = {
            Id: '',
            HistoryId: historyId,
            PropertyName: propertyName,
            SinceDate: sinceDate,
            UserId: userId,
            Value: ProjectHistoryState.Active
        }

        return state;
    }

    if (propertyName === "ProjectCreationDate") {
        const state: ProjectDateTimePropertyHistory = {
            Id: '',
            HistoryId: historyId,
            PropertyName: propertyName,
            SinceDate: sinceDate,
            UserId: userId,
            Value: sinceDate
        }

        return state;
    }

    if (propertyName === "ProjectEndDate") {
        const state: ProjectDateTimeNullablePropertyHistory = {
            Id: '',
            HistoryId: historyId,
            PropertyName: propertyName,
            SinceDate: sinceDate,
            UserId: userId,
            Value: ''
        };

        return state;
    }

    if (propertyName === "ProjectType") {
        const history: ProjectTypePropertyHistory = {
            Id: '',
            HistoryId: historyId,
            PropertyName: propertyName,
            SinceDate: sinceDate,
            UserId: userId,
            Value: ProjectType.None
        }

        return history;
    }

    if (propertyName === "ServiceModel") {
        const history: ServiceModelPropertyHistory = {
            Id: '',
            HistoryId: historyId,
            PropertyName: propertyName,
            SinceDate: sinceDate,
            UserId: userId,
            Value: ServiceModel.None
        }

        return history;
    }

    const record: ProjectStringPropertyHistory = {
        Id: '',
        HistoryId: historyId,
        PropertyName: propertyName,
        SinceDate: sinceDate,
        UserId: userId,
        Value: ''
    };

    return record;
}