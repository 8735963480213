//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { SensorMapTextOrientation } from '../Common/SensorMapTextOrientation';
import { SensorSymbol } from '../Sensors/SensorSymbol';

export class ColorizableElement
{
	public Color: string;
	public LineType: string;
	public LineWeight: number;
	public mapTextOrientation: SensorMapTextOrientation;
	public Symbol: SensorSymbol;
}
