import Modal, { ActionProps, ModalTransition } from "@atlaskit/modal-dialog";
import { OptionType } from "@atlaskit/select";
import { useParams } from "react-router-dom";
import { useEffect, useState, CSSProperties } from "react";
import TextArea from "@atlaskit/textarea";
import { connect } from "react-redux";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { t } from "../../../../i18n";
import { GeovisAlarmModel } from "../../../../server/GEOvis3/Model/Alarms/GeovisAlarmModel";
import { GeovisSelect } from "../../../../components/select/GeovisSelect";
import { AlarmActionTestPreparePayload } from "../../../../server/GEOvis3/Model/Alarms/AlarmActionTestPreparePayload";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import IRouteParams from "../../../../helpers/IRouteParams";
import { fetchServerElementsByPost, sendServerPostRequestData } from "../../../../helpers/ProjectDataHelper";
import { AlarmActionTestPrepareResponse } from "../../../../server/GEOvis3/Model/Alarms/AlarmActionTestPrepareResponse";
import FlagService from "../../../../services/FlagService";
import { AlarmActionTestPayload } from "../../../../server/GEOvis3/Model/Alarms/AlarmActionTestPayload";
import AuthService from "../../../../services/AuthService";
import { AlarmActionTestConfirmAGMSDODialog } from "./AlarmActionTestConfirmAGMSDODialog";
import { GeovisAlarmActionModel } from "../../../../server/AVTService/TypeLibrary/Alarming/GeovisAlarmActionModel";
import { AlarmActionUIType } from "../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUIType";
import { EditFieldPopup } from "../../../../components/editDialogs/EditDialogTools";
import { IGeovisProjectUsersStorage } from "../../../../store/data.types";
import { IGeovisStoreState } from "../../../../store/store.types";
import { verifyUsersPhone } from "./AlarmActionsEdit/tools";

interface IComponentStateProps {
    projectUsersStorage: IGeovisProjectUsersStorage;
}

interface IComponentOwnProps {
    action: GeovisAlarmActionModel;
    alarm: GeovisAlarmModel;
    onClose: () => void;
}

interface IComponentProps extends IWithGeovisServerProps, IComponentStateProps, IComponentOwnProps {

}

interface IComponentState {
    selectedConditionId: string;
    selectedConditionIndex: number;
    message: string;
    subject: string;
    showDOConfirmation: boolean;
    payload: AlarmActionTestPayload;
}

const AlarmActionTestDialog = ({
    Server,
    action,
    alarm,
    onClose,
    projectUsersStorage
}: IComponentProps) => {

    const { projectId } = useParams<IRouteParams>();

    const [state, setState] = useState<IComponentState>({
        selectedConditionId: alarm.Conditions[0].Id,
        selectedConditionIndex: 0,
        message: '',
        showDOConfirmation: false,
        subject: '',
        payload: new AlarmActionTestPayload()
    })

    useEffect(() => {
        (async function loadMessage() {
            const selectedCondition = alarm.Conditions.find(c => c.Id === state.selectedConditionId);
            if (!selectedCondition) {
                return;
            }
            const index = alarm.Conditions.indexOf(selectedCondition);

            const payload: AlarmActionTestPreparePayload = {
                Action: action,
                AlarmUnit: alarm.Unit,
                Condition: selectedCondition,
                SendToAll: false,
                AlarmName: alarm.Name
            }

            const url = ServerRoutesGen.Alarms.TestAlarmActionPrepare.patch(projectId);

            const response = await fetchServerElementsByPost<AlarmActionTestPrepareResponse, AlarmActionTestPreparePayload>(Server, url, payload);

            if (!response.Success) {
                FlagService.addError("Failed to create message for testing action", response.Messages.join('; '));
                return;
            }

            if (action.ActionType !== AlarmActionUIType.AgmsDigitalOutput) {
                setState({
                    ...state,
                    message: response.Data.Message,
                    subject: response.Data.Subject,
                    selectedConditionIndex: index
                })
            }

        })()
    }, [state.selectedConditionId])

    const getSeveritiesOptions = (): OptionType[] => {
        return alarm.Conditions.map(c => ({
            label: c.severityName,
            value: c.Id
        }))
    }

    const getSelectedSeverity = (): OptionType | undefined => {
        return getSeveritiesOptions().find(o => o.value === state.selectedConditionId);
    }

    const onSelectedSeverityChanged = (selected: OptionType) => {
        setState({
            ...state,
            selectedConditionId: selected.value.toString()
        })
    }


    const onTestClickHandler = async () => {
        const payload: AlarmActionTestPayload = {
            Alias: '',
            ForConfirm: action.ForConfirm,
            Message: state.message,
            SmsReceiversIds: [],
            SensorboxIds: '',
            Subject: state.subject,
            Type: AlarmActionUIType.Message,
            EmailReceiversIds: [],
            ConditionIndex: state.selectedConditionIndex,
            PresetsId: ""
        }

        switch (action.ActionType) {
            case AlarmActionUIType.AgmsDigitalOutput:
                payload.Alias = action.Alias;
                payload.SensorboxIds = action.SensorboxIds;
                payload.Type = AlarmActionUIType.AgmsDigitalOutput;

                setState({
                    ...state,
                    payload,
                    showDOConfirmation: true
                })
                return;
            case AlarmActionUIType.Dolphin:
                payload.Type = AlarmActionUIType.Dolphin;
                break;
            case AlarmActionUIType.Message:
                payload.EmailReceiversIds.push(AuthService.currentUserId());

                if (verifyUsersPhone(AuthService.currentUserId(), projectUsersStorage)) {
                    payload.SmsReceiversIds.push(AuthService.currentUserId());
                }

                payload.Type = AlarmActionUIType.Message
                break;
        }

        const url = ServerRoutesGen.Alarms.TestAlarmActionExecute.patch(projectId);
        const response = await sendServerPostRequestData(Server, url, payload);

        if (!response.Success) {
            FlagService.addError("Failed to test action", response.Messages.join("; "));
        }
        else {
            onClose();
        }
    }

    const onSendToAllClickHandler = async () => {

        const selectedCondition = alarm.Conditions.find(c => c.Id === state.selectedConditionId);
        if (!selectedCondition) {
            return;
        }
        const emailReceiveStrategy = action.EmailNotificationsStrategy.filter(s => s.ConditionsIds.includes(selectedCondition.Id));
        const smsReceiveStrategy = action.SmsNotificationsStrategy.filter(s => s.ConditionsIds.includes(selectedCondition.Id));

        const payload: AlarmActionTestPayload = {
            Alias: '',
            ForConfirm: action.ForConfirm,
            Message: state.message,
            EmailReceiversIds: emailReceiveStrategy.length > 0 ? emailReceiveStrategy.map(s => s.UserId) : [],
            SensorboxIds: '',
            Subject: state.subject,
            Type: AlarmActionUIType.Message,
            SmsReceiversIds: smsReceiveStrategy.length > 0 ? smsReceiveStrategy.map(s => s.UserId) : [],
            ConditionIndex: state.selectedConditionIndex,
            PresetsId: action.ReceiversPresetId
        }

        const url = ServerRoutesGen.Alarms.TestAlarmActionExecute.patch(projectId);
        const response = await sendServerPostRequestData(Server, url, payload);

        if (!response.Success) {
            FlagService.addError("Failed to test action", response.Messages.join("; "));
        }
        else {
            onClose();
        }
    }

    const getActions = (): ActionProps[] => {
        const result: ActionProps[] = [];
        if (action.ActionType === AlarmActionUIType.Message) {
            result.push({
                text: t("Send Email and SMS to all receivers"), appearance: 'primary', onClick: onSendToAllClickHandler
            })
        }
        result.push({ text: action.ActionType === AlarmActionUIType.Message ? t(`Send Email${verifyUsersPhone(AuthService.currentUserId(), projectUsersStorage) ? " and SMS" : ""} to me only`) : getHeaderText(), appearance: 'primary', onClick: onTestClickHandler });
        result.push({ text: t("Close"), appearance: 'default', onClick: onClose });

        return result;
    }

    const onCloseConfirmDialog = () => {
        setState({
            ...state,
            payload: new AlarmActionTestPayload(),
            showDOConfirmation: false
        });
    }

    const onCloseConfirmDialogAfterSuccess = () => {
        setState({
            ...state,
            payload: new AlarmActionTestPayload(),
            showDOConfirmation: false
        });

        onClose();
    }

    const getInfoContent = () => (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ fontWeight: 'bold' }}>{"Send to all receivers: "}</span>
                <span>{"Will send Test Email and SMS according to the selected alarm severity."}</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <span style={{ fontWeight: 'bold' }}>{"Send to me only: "}</span>
                <span>{"Will send Test Email and SMS to current logged in user."}</span>
            </div>
        </div>
    )

    const actionContainerStyle: CSSProperties = { display: 'grid', gridTemplateColumns: '100px 2fr auto', marginTop: '10px', width: '100%', marginLeft: '5px' };

    const getHeaderText = () => {
        switch (action.ActionType) {
            case AlarmActionUIType.AgmsDigitalOutput:
                return t(`Test AGMS Digital Output alarm ${action.ForConfirm ? "confirmation" : "action"}`);
            case AlarmActionUIType.Dolphin:
                return t(`Test F24 alarm ${action.ForConfirm ? "confirmation" : "action"}`);
            case AlarmActionUIType.Message:
                return t(`Test Email/SMS alarm ${action.ForConfirm ? "confirmation" : "action"}`);
        }
    }

    return (
        <ModalTransition>
            <Modal
                heading={getHeaderText()}
                width={'40%'}
                actions={getActions()}
            >
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                    <div style={actionContainerStyle}>
                        <div style={{ width: '100px' }}>
                            <span style={{ fontWeight: 'bold' }}>{t("Alarm severity")}:</span>
                        </div>
                        <div>
                            <GeovisSelect
                                options={getSeveritiesOptions()}
                                value={getSelectedSeverity()}
                                onChange={onSelectedSeverityChanged}
                            />
                        </div>
                        {action.ActionType === AlarmActionUIType.Message &&
                            <div>
                                <EditFieldPopup content={getInfoContent()} popupPlacement={"bottom"} />
                            </div>
                        }
                    </div>

                    {action.ActionType === AlarmActionUIType.Message &&
                        <div style={actionContainerStyle}>
                            <div style={{ width: '100px' }}>
                                <span style={{ fontWeight: 'bold' }}>{t("Subject")}:</span>
                            </div>
                            <div>
                                <span>{state.subject}</span>
                            </div>
                        </div>
                    }
                    {(action.ActionType === AlarmActionUIType.Message || action.ActionType === AlarmActionUIType.Dolphin) &&
                        <div style={{ ...actionContainerStyle, height: '100%' }}>
                            <div style={{ width: '100px' }}>
                                <span style={{ fontWeight: 'bold' }}>{t("Message")}:</span>
                            </div>
                            <div style={{ height: '200px', width: '100%' }}>
                                <TextArea
                                    value={state.message}
                                    isReadOnly={true}
                                    minimumRows={9}
                                />
                            </div>
                        </div>
                    }

                    <AlarmActionTestConfirmAGMSDODialog
                        onClose={onCloseConfirmDialog}
                        onConfirm={onCloseConfirmDialogAfterSuccess}
                        payload={state.payload}
                        projectId={projectId ? +projectId : 0}
                        showDialog={state.showDOConfirmation}
                        forConfirm={action.ForConfirm}
                    />
                </div>
            </Modal>
        </ModalTransition>
    )
}

const mapStateToProps = (state: IGeovisStoreState): IComponentStateProps => ({
    projectUsersStorage: state.data.projectUsersStorage
})

export default connect<IComponentStateProps, never, IComponentOwnProps>(
    mapStateToProps
)(withGeovisServer(AlarmActionTestDialog));