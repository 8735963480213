/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 20.04.2020
 * @description Uploading files common types
 */

export class GeovisUploadFileState {
    public id: string;
    public name: string;
    public linkId: string;
    public progress: number;
    public isCompleted: boolean;
    public isProcessing: boolean;
    public isError: boolean;
    public isCanceled: boolean;
    public description: string;

    constructor() {
        this.id = '';
        this.name = '';
        this.linkId = '';
        this.progress = 0;
        this.isCompleted = false;
        this.isProcessing = false;
        this.isError = false;
        this.isCanceled = false;
        this.description = '';
    }
}