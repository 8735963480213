import { MigratebleElements } from "../../../../server/AVTService/TypeLibrary/Common/ElementsMigrations/MigratebleElements";

export type ChangeAllProjectsType = "migrate" | "rollback" | "removeOld"

export const implementedMigrationsListElements: ReadonlyArray<MigratebleElements> = [
    MigratebleElements.TimeValueCharts,
    MigratebleElements.XYCharts,
    MigratebleElements.InclinometerCharts,
    MigratebleElements.Reports,
    MigratebleElements.DefaultCharts,
    MigratebleElements.Headers,
    MigratebleElements.Comments,
    MigratebleElements.Logbooks,
    MigratebleElements.VibrationCharts,
    MigratebleElements.XYVibrationCharts,
    MigratebleElements.Maps,
    MigratebleElements.GeovisTables,
    MigratebleElements.CustomGroups,
    MigratebleElements.Profiles
];