import { useEffect, useState } from "react";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { IGeoRasterLayersStorage } from "../../../../store/data.types";
import { geoRasterStorageInitialState } from "../../../../store/reducers/dataReducer";
import Logger from "../../../../services/Logger";
import { fetchServerElements } from "../../../../helpers/ProjectDataHelper";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import { processFetchedData } from "../../../../store/helpers/DataHelper";
import { elementsToMap } from "../../../../helpers/StorageHelper";
import { GeoRasterFileModel } from "../../../../server/AVTService/TypeLibrary/Model/GeoRasterFileModel";
import { GeoRasterLayers } from "../../../map/GeoRasterLayers";

const LoggerSource = "MapSectionGeoRasterLayer";

interface IComponentProps extends IWithGeovisServerProps {
    projectId: number;
    layerId: string;
    userId?: string;
    userToken?: string;
}

/**
 * Geovis Map Section GeoJSON data layer with loading data from Server
 * @param param0 
 * @returns 
 */
export const MapSectionGeoRasterLayer = withGeovisServer(({ Server, projectId, layerId, userId, userToken }: IComponentProps) => {

    const [geoRasterStorage, setGeoRasterStorage] = useState<IGeoRasterLayersStorage>({ ...geoRasterStorageInitialState });

    useEffect(() => {
        if (!layerId) {
            return;
        }

        (async function loadDxfLayersConfig() {
            const isPdfPrintingMode = userToken && userId;

            const route = isPdfPrintingMode
                ? ServerRoutesGen.ReportPdfRenderData.GetRasterLayerInfo.patch(projectId, userToken, userId, layerId)
                : ServerRoutesGen.DxfLayers.GetRasterLayerInfo.patch(projectId, layerId);

            const result = await fetchServerElements<GeoRasterFileModel>(Server, route);

            setGeoRasterStorage(processFetchedData(result, geoRasterStorage, geoRasterStorageInitialState, l => ({ rasters: elementsToMap([l]) })));
        })();

    }, [projectId, layerId])

    if (!layerId) {
        return null;
    }

    Logger.render(`layerId=${layerId}`, LoggerSource);

    return (
        <GeoRasterLayers
            geoRasterStorage={geoRasterStorage}
            rasterToDisplay={layerId}
        />
    )
});