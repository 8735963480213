import { LatLngBounds, Layer } from "leaflet";
import { GEOvisDXFLayerType } from "../../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { MapArea } from "../../../server/AVTService/TypeLibrary/Map/MapArea";
import { GeoJSONMapTilesLayerBounds } from "../../../server/AVTService/TypeLibrary/Model/GeoJSONMapTilesLayerBounds";
import { MapSectionModel } from "../../../server/AVTService/TypeLibrary/Model/MapSectionModel";
import { ProjectDXFLayerInfo } from "../../../server/ProjectDXFLayerInfo";
import { IDXFLayersStorage, IGeovisProjectLayersStorage } from "../../../store/data.types";
import { loadedSomethingStorageState } from "../../../store/types";
import {
    DXF_AVAILABLE_PIXELS_SIZE,
    PROFILE_VIEW_LATITUDE_OFFSET,
    PROFILE_VIEW_LONGITUDE_OFFSET,
    SIDE_VIEW_LATITUDE_OFFSET,
    SIDE_VIEW_NAGRA_INVERT_AXIS_LONGITUDE_OFFSET,
    SIDE_VIEW_NAGRA_LONGITUDE_OFFSET
} from "../sensors/tools";
import { IGeovisMapSectionReportInfo, IGeovisReportPageConfig } from "../../../store/projectReport.types";
import { TimeSearchDistanceDataRequest } from "../../../server/AVTService/Computation/TimeSearchDistance/TimeSearchDistanceDataRequest";

/**
 * Remove first leaflet layer and return that array
 * @param layers 
 * @returns 
 */
export const removeFirstLeafletLayer = (layers: Layer[]): Layer[] => {
    if (layers.length > 1) {
        layers.shift();
    }
    return layers;
}

export const isGeoLocationWithinArea = (coordinates: L.LatLng, mapArea: MapArea): boolean => {
    const { lat, lng } = coordinates;
    return (lat > mapArea.South && lat < mapArea.North) && (lng > mapArea.West && lng < mapArea.East);
}

const getDxfLayersMap = (layerInfo: ProjectDXFLayerInfo): Map<string, ProjectDXFLayerInfo> => {
    const map = new Map<string, ProjectDXFLayerInfo>();
    map.set(layerInfo.Id, layerInfo);
    return map;
}

export const getProjectLayersStorage = (layerInfo: ProjectDXFLayerInfo): IGeovisProjectLayersStorage => ({
    ...loadedSomethingStorageState,
    currentLayerId: layerInfo.Id,
    layers: getDxfLayersMap(layerInfo)
})

const substractPoint = (leafletElement: L.Map, projectedPoint: any): L.Point => {
    return projectedPoint._subtract(leafletElement.getPixelOrigin());
}

const latLngToLayerPoint = (leafletElement: L.Map, latLng: L.LatLng, zoom: number): L.Point => {
    const projectedPoint = leafletElement.project(latLng, zoom);
    projectedPoint.x = Math.round(projectedPoint.x);
    projectedPoint.y = Math.round(projectedPoint.y);
    return substractPoint(leafletElement, projectedPoint);
}

export interface IMapSectionAreaSize {
    width: number;
    height: number;
}

export const getMapAreaSize = (leafletElement: L.Map, mapArea: MapArea, isNativeMap: boolean): IMapSectionAreaSize => {
    const northEast = L.latLng(mapArea.North, mapArea.East);
    const southWest = L.latLng(mapArea.South, mapArea.West);

    const northEastPoint = isNativeMap ? leafletElement.latLngToLayerPoint(northEast) : latLngToLayerPoint(leafletElement, northEast, mapArea.ZoomLevel);
    const southWestPoint = isNativeMap ? leafletElement.latLngToLayerPoint(southWest) : latLngToLayerPoint(leafletElement, southWest, mapArea.ZoomLevel);

    return {
        width: Math.abs(northEastPoint.x - southWestPoint.x),
        height: Math.abs(northEastPoint.y - southWestPoint.y)
    }
}
export const getMapSelectionBounds = (mapArea: MapArea): LatLngBounds => {
    const southWest = L.latLng(mapArea.South, mapArea.West);
    const northEast = L.latLng(mapArea.North, mapArea.East);

    return L.latLngBounds(southWest, northEast);
}

export const isSelectionValid = (bounds: LatLngBounds) => {
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    const width = Math.abs(northEast.lng - southWest.lng);
    const height = Math.abs(northEast.lat - southWest.lat);
    return width !== 0 && height !== 0;
}

export interface IGetMapSectionLocalCoordinatesState {
    isProfileView: boolean;
    isSideView: boolean;
    isMapLayer: boolean;
    invertXAxis: boolean;
    offsetsBounds?: GeoJSONMapTilesLayerBounds;
}

export interface IMapSectionSouthWestPoint {
    south: number,
    west: number
}

export interface IMapSectionNorthEastPoint {
    north: number,
    east: number
}

/**
 * Get selection local coordinates
 * Useful to draw a blue rectangle
 * Useful to filter sensors, which are in the selected area
 * @param selectionBounds 
 * @param state 
 * @returns 
 */
export const getMapSectionLocalCoordinates = (selectionBounds: L.LatLngBounds, state: IGetMapSectionLocalCoordinatesState): {
    southWest: IMapSectionSouthWestPoint,
    northEast: IMapSectionNorthEastPoint
} => {

    const { offsetsBounds } = state;

    const South = selectionBounds.getSouth();
    const West = selectionBounds.getWest();
    const North = selectionBounds.getNorth();
    const East = selectionBounds.getEast();

    let xMeterPerPixel = 1;
    let yMeterPerPixel = 1;
    let offsetDeltaInPixel = 0;

    if (offsetsBounds) {
        const xLengthReal = offsetsBounds.MaxX - offsetsBounds.MinX;
        const yLengthReal = offsetsBounds.MaxY - offsetsBounds.MinY;
        const maxDimension = Math.max(xLengthReal, yLengthReal);

        const pixelsPerMeter = DXF_AVAILABLE_PIXELS_SIZE / maxDimension;

        xMeterPerPixel = yMeterPerPixel = maxDimension / DXF_AVAILABLE_PIXELS_SIZE;
        offsetDeltaInPixel = pixelsPerMeter * (offsetsBounds.MaxY - (yLengthReal / 2));
    }

    return {
        southWest: {
            south: layerYCoordInPixelToDxfCoordInMeter(South, yMeterPerPixel, offsetDeltaInPixel, state),
            west: layerXCoordInPixelToDxfCoordInMeter(West, xMeterPerPixel, state)
        },
        northEast: {
            north: layerYCoordInPixelToDxfCoordInMeter(North, yMeterPerPixel, offsetDeltaInPixel, state),
            east: layerXCoordInPixelToDxfCoordInMeter(East, xMeterPerPixel, state)
        }
    }
}

const layerYCoordInPixelToDxfCoordInMeter = (coordInPixel: number, meterPerPixel: number, offsetDeltaInPixel: number, { isMapLayer, isSideView }: IGetMapSectionLocalCoordinatesState): number => {
    if (isSideView) {
        return (coordInPixel + SIDE_VIEW_LATITUDE_OFFSET + offsetDeltaInPixel) * meterPerPixel;
    }

    const offset = isMapLayer ? 0 : PROFILE_VIEW_LONGITUDE_OFFSET + offsetDeltaInPixel;
    return (coordInPixel + offset) * meterPerPixel;
}

const layerXCoordInPixelToDxfCoordInMeter = (coordInPixel: number, meterPerPixel: number, { isSideView, invertXAxis, offsetsBounds, isMapLayer }: IGetMapSectionLocalCoordinatesState): number => {
    if (isSideView) {
        if (!offsetsBounds) {
            return coordInPixel * meterPerPixel;
        }

        const viewCoordInPixel = invertXAxis
            ? SIDE_VIEW_NAGRA_INVERT_AXIS_LONGITUDE_OFFSET - coordInPixel
            : SIDE_VIEW_NAGRA_LONGITUDE_OFFSET + coordInPixel;

        const coordInMeter = viewCoordInPixel * meterPerPixel;
        const viewCoordInMeter = invertXAxis
            ? -coordInMeter + offsetsBounds.MaxX
            : coordInMeter + offsetsBounds.MinX;

        return viewCoordInMeter;
    }

    const offset = isMapLayer ? 0 : PROFILE_VIEW_LATITUDE_OFFSET;
    return (coordInPixel - offset) * meterPerPixel;
}

/**
 * Get request for report elements data
 * MapSection, Sensors and Chains
 * @param mapSectionReportInfo - section report info to get data 
 * @returns 
 */
export const getMapSectionTimeSearchDistanceElementDataRequest = (pageConfig: IGeovisReportPageConfig, sectionInfo: IGeovisMapSectionReportInfo, reportId?:number): TimeSearchDistanceDataRequest => {

    const { MapSection } = sectionInfo;
    const { UseLastMeasurementTimeSetting, TimeslotSetting, TimeSearchDistanceSetting, ChainIds } = MapSection;

    return {
        UseLastMeasurementTime: UseLastMeasurementTimeSetting ? UseLastMeasurementTimeSetting.value : true,
        Timeslot: TimeslotSetting ? TimeslotSetting.Value : "",
        TimeSearchDistance: TimeSearchDistanceSetting ? TimeSearchDistanceSetting.value : 0,
        SensorFullIds: getMapSectionSensorIds(pageConfig, MapSection),
        ChainFullId: [...ChainIds],
        UseCurrentTime:false,
        ReportId: reportId ?? 0
    };
}

const getMapSectionSensorIds = (pageConfig: IGeovisReportPageConfig, mapSection: MapSectionModel): string[] => {

    if (mapSection.UseManualSelectedSensors) {
        return [...mapSection.SensorIds];
    }

    // collect selected sensors
    const sensorIdsObj: { [k: string]: boolean } = {};

    pageConfig.selectionInfo.geovisCharts.forEach(info => {

        info.unitASensors.forEach((isSelected, sId) => {
            if (isSelected) {
                sensorIdsObj[sId] = true;
            }
        });
    });

    return Object.keys(sensorIdsObj);
}

// /** @deprecated This code has moved to the Server, it will be removed after end of refactoring */
export const getMapSectionDxfLayerId = (mapSection: MapSectionModel): string => {
    switch (mapSection.LayerType) {
        case GEOvisDXFLayerType.Map:
            return mapSection.BackgroundDxfLayer;

        case GEOvisDXFLayerType.ProfileView:
            return mapSection.ProfileViewDxfLayer

        case GEOvisDXFLayerType.SideView:
            return mapSection.SideViewDxfLayer;

        default:
            return "";
    }
}


/**
 * Get map section dxf layer info
 * @param mapSection 
 * @param param1 
 * @returns 
 */
export const getMapSectionDxfLayerInfo = (mapSection: MapSectionModel, { isLoading, isError, dxfLayers }: IDXFLayersStorage): ProjectDXFLayerInfo | false => {

    if (isLoading || isError) {
        return false;
    }

    const dxfLayerId = getMapSectionDxfLayerId(mapSection);

    if (!dxfLayerId) {
        return false;
    }

    return dxfLayers.get(dxfLayerId) || false;
}

// export const calculateMapSectionContainerSize = (rootWidth: number, rootHeight: number, mapArea: MapArea): [number, number] => {

    
// }