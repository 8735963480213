import Highcharts from "highcharts";
import { t } from "i18next";
import { cloneDeep } from "lodash";
import { formattedDateTime } from "../../../../../../helpers/DateHelper";
import { ChainFixedPoint } from "../../../../../../server/AVTService/TypeLibrary/Common/ChainFixedPoint";
import { GeovisChartSlimConfig } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartSlimConfig";
import { InclinometerChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/InclinometerChartData";
import { ColorizableElement } from "../../../../../../server/AVTService/TypeLibrary/DB/ColorizableElement";
import { AlarmInfoRecord } from "../../../../../../server/AVTService/TypeLibrary/Model/AlarmInfoRecord";
import { ChainModel } from "../../../../../../server/AVTService/TypeLibrary/Model/ChainModel";
import { InclinometerChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { HtmlReportChainLineInfo } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportChainLineInfo";
import { HtmlReportSensorDescriptionViewModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportSensorDescriptionViewModel";
import { SensorSymbol } from "../../../../../../server/AVTService/TypeLibrary/Sensors/SensorSymbol";
import { createChartRenderedFunc } from "../../tools";
import { getAxisLimitValue, getDashStyleValue, getGeovisChartSeriesLineWidth, getMarkerSymbol, getSoftLimitValue } from "./chartRenderOptions";
import { getBoostOptions } from "./tools";
import { AlarmChartValueType } from "../../../../../../server/AVTService/TypeLibrary/Common/AlarmChartValueType";
import { getDigitsAfterDecimalPoint, getPhysicalUnitShortName } from "../../../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";

const Color = require('color');

export interface IInclinometerChartRenderSettings {
    onChartRender: () => void;
    chart: InclinometerChartModel;
    startMeasurements: string;
    referenceDate: string;
    descriptions: HtmlReportSensorDescriptionViewModel[];
    linkedAlarmLines: AlarmInfoRecord[];
    linesData: HtmlReportChainLineInfo[];
    chartDataSlimConfig: GeovisChartSlimConfig;
    chainConfig: ChainModel;
    visualSettings: ProjectVisualSettings;
    minDepth: number;
    maxDepth: number;
    minDeltaFirst: number;
    minDeltaSecond: number;
    maxDeltaFirst: number;
    maxDeltaSecond: number;
}

interface IInclinometerChartPoint {
    x: number;
    y: number;
    sensorId: string;
    time: string;
    isFixed: boolean;
    isSensorPosition: boolean;
    isManual: boolean;
}

interface IInclinometerLine {
    color: string;
    isLast: boolean;
    points: IInclinometerChartPoint[];
    time: string;
    isStartFixed: boolean;
    version: number;
    isManual: boolean;
}

interface IInclinometerFixedPoint {
    x: number;
    y: number;
}

export const getInclinometerChartRenderOptions = (pageNum: number, chart: InclinometerChartModel, chartData: InclinometerChartData): Highcharts.Options[] => {

    const settings: IInclinometerChartRenderSettings = {
        chart,
        descriptions: chartData.Descriptions,
        linesData: chartData.LineInfo,
        linkedAlarmLines: chartData.LinkedAlarmLines,
        referenceDate: chartData.ReferencesTime,
        startMeasurements: chartData.startMeasurementTime,
        onChartRender: createChartRenderedFunc(pageNum, chart.Id),
        chartDataSlimConfig: chartData.SlimChartConfig,
        chainConfig: chartData.ChainConfig,
        visualSettings: {
            ...chartData.visualSettings,
            chartLineWidth: `${chart.LeftYAxisSettings.LineWeight}px`
        },
        maxDeltaFirst: chartData.MaxDeltaFirst,
        maxDeltaSecond: chartData.MaxDeltaSecond,
        maxDepth: chartData.MaxDepth,
        minDeltaFirst: chartData.MinDeltaFirst,
        minDeltaSecond: chartData.MinDeltaSecond,
        minDepth: chartData.MinDepth
    }

    const result: Highcharts.Options[] = [];

    if (chart.VisibleRangeSettings.ShowFirstXAxis) {
        result.push(getSingleChartOptions({ ...settings, chartIndex: 0 }));
    }

    if (chart.VisibleRangeSettings.ShowSecondYAxis) {
        result.push(getSingleChartOptions({ ...settings, chartIndex: 1 }));
    }

    return result;
}

interface IInclinometerSingleValueTypeSettings extends IInclinometerChartRenderSettings {
    chartIndex: number
}

const getSingleChartOptions = (settings: IInclinometerSingleValueTypeSettings): Highcharts.Options => {
    const {
        chart,
        chartIndex,
        descriptions,
        linesData,
        linkedAlarmLines,
        onChartRender,
        chartDataSlimConfig,
        chainConfig,
        visualSettings,
        // maxDeltaFirst,
        // maxDeltaSecond,
        // minDeltaFirst,
        // minDeltaSecond,
    } = settings;

    const lastInList = !chart.VisibleRangeSettings.ShowFirstXAxis
        || !chart.VisibleRangeSettings.ShowSecondYAxis
        || (chart.VisibleRangeSettings.ShowSecondYAxis && chartIndex === 1);

    const getSeries = (): Highcharts.SeriesOptionsType[] => {

        const result: Highcharts.SeriesOptionsType[] = [];
        const processedLines = processLinesData(chart, linesData, chartIndex, chainConfig);

        processedLines.forEach(line => {
            const correctLine = chart.IsHorizontal ? rotateLine(line) : line;
            const singleSeriesOptions: Highcharts.SeriesOptionsType = {
                type: 'line',
                lineWidth: chart.DrawLineBetweenPoints.value ? getGeovisChartSeriesLineWidth(visualSettings, undefined) : 0,
                name: chart.LegendText ? chart.LegendText : formattedDateTime(correctLine.time, "DD.MM.YYYY HH:mm"),
                data: getSeriesData(correctLine, chart, descriptions),
                yAxis: 0,
                color: correctLine.color,
                dashStyle: getDashStyleValue(chart.LeftYAxisSettings.LineStyle),
                states: {
                    hover: {
                        lineWidthPlus: chart.DrawLineBetweenPoints.value ? 1 : 0
                    }
                }
            }
            result.push(singleSeriesOptions);
        })

        return result;
    }

    const getAlarmsAnnotations = (): Highcharts.AnnotationsOptions[] => {
        const result: Highcharts.AnnotationsOptions[] = [];

        const allAlarmLines: AlarmInfoRecord[] = chart.AlarmLines;

        // allAlarmLines.push(...linkedAlarmLines);

        allAlarmLines.forEach(alarmLine => {
            // do not draw line with equal x values and equal y values at the same time and also do not draw disabled alarm lines
            if (!(+alarmLine.xvalueFrom === +alarmLine.xvalueTo && +alarmLine.yvalueFrom === +alarmLine.yvalueTo) && alarmLine.enabled) {

                const isVertical = (alarmLine.xvalueFrom === alarmLine.xvalueTo) && !chart.IsHorizontal || chart.IsHorizontal && (alarmLine.yvalueFrom === alarmLine.yvalueTo);

                const annotationLine: Highcharts.AnnotationsOptions = {
                    draggable: '',
                    shapes: [{
                        type: "path",
                        stroke: alarmLine.lineColor,
                        strokeWidth: alarmLine.lineWeight,
                        dashStyle: getDashStyleValue(alarmLine.lineType),
                        points: [{
                            x: chart.IsHorizontal ? +alarmLine.yvalueFrom : +alarmLine.xvalueFrom,
                            y: chart.IsHorizontal ? +alarmLine.xvalueFrom : +alarmLine.yvalueFrom,
                            xAxis: 0,
                            yAxis: 0
                        }, {
                            x: chart.IsHorizontal ? +alarmLine.yvalueTo : +alarmLine.xvalueTo,
                            y: chart.IsHorizontal ? +alarmLine.xvalueTo : +alarmLine.yvalueTo,
                            xAxis: 0,
                            yAxis: 0
                        }]
                    }],
                    labels: [{
                        useHTML: true,
                        text: isVertical ? `<div style="transform: rotate(270deg); transform-origin:right">${alarmLine.label}</div>` : `<div>${alarmLine.label}</div>`,
                        point: annotationCallbackFunc(alarmLine, chart.IsHorizontal),
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        style: {
                            color: alarmLine.lineColor,
                            // fontSize: visualSettings.chartAxisLabelFontSize
                        },
                        borderColor: "rgba(255, 255, 255, 0)",
                        x: isVertical ? -8 : 0,
                        y: isVertical ? -16 : 24,
                        align: 'right'
                    }]
                }
                result.push(annotationLine);
            }
        })

        return result;
    }

    const annotationCallbackFunc = (alarmLine: AlarmInfoRecord, isHorizontal: boolean) => (annotation: any): Highcharts.AnnotationMockPointOptionsObject => {
        const normXTo = isHorizontal ? +alarmLine.yvalueTo : +alarmLine.xvalueTo;
        const normXFrom = isHorizontal ? +alarmLine.yvalueFrom : +alarmLine.xvalueFrom;
        const normYTo = isHorizontal ? +alarmLine.xvalueTo : +alarmLine.yvalueTo;
        const normYFrom = isHorizontal ? +alarmLine.xvalueFrom : +alarmLine.yvalueFrom;

        const selectedX = normXFrom > normXTo ? normXFrom : normXTo;
        const selectedY = normYFrom > normYTo ? normYFrom : normYTo;

        const xAxisDelta = Math.abs(annotation.chart.xAxis[0].max - annotation.chart.xAxis[0].min);
        const yAxisDelta = Math.abs(annotation.chart.yAxis[0].max - annotation.chart.yAxis[0].min);

        return {
            x: isHorizontal && selectedX >= annotation.chart.xAxis[0].max ? annotation.chart.xAxis[0].max - 0.02 * (xAxisDelta) : selectedX,
            y: !isHorizontal && selectedY >= annotation.chart.yAxis[0].max ? annotation.chart.yAxis[0].max - 0.02 * (yAxisDelta) : selectedY,
            xAxis: 0,
            yAxis: 0
        }
    }

    const getVerticalDeltaAxisPlotLines = (): Highcharts.XAxisPlotLinesOptions[] => linkedAlarmLines
        .filter(line => line.enabled && (line.alarmChartValueType === AlarmChartValueType.AllCharts || line.alarmChartValueType === settings.chartIndex))
        .map<Highcharts.XAxisPlotLinesOptions>(line => ({
            label: line.drawLine || line.fillAlarmArea ? {
                text: line.label,
                style: { color: line.lineColor }
            } : undefined,
            color: line.drawLine ? line.lineColor : "#ffffff00",
            value: (+line.yvalueFrom),
            dashStyle: getDashStyleValue(line.lineType),
            width: line.lineWeight,
            zIndex: 100
        }));

    const getHorizontalDeltaAxisPlotLines = (): Highcharts.YAxisPlotLinesOptions[] => linkedAlarmLines
        .filter(line => line.enabled && (line.alarmChartValueType === AlarmChartValueType.AllCharts || line.alarmChartValueType === settings.chartIndex))
        .map<Highcharts.YAxisPlotLinesOptions>(line => ({
            label: line.drawLine || line.fillAlarmArea ? {
                text: line.label,
                style: {
                    color: line.lineColor
                }
            } : undefined,
            color: line.drawLine ? line.lineColor : "#ffffff00",
            value: (+line.yvalueFrom),
            dashStyle: getDashStyleValue(line.lineType),
            width: line.lineWeight,
            zIndex: 100
        }));

    const getDeltaAxisPlotBands = (): Highcharts.YAxisPlotBandsOptions[] => linkedAlarmLines
        .filter(line => line.enabled && line.fillAlarmArea && (line.alarmChartValueType === AlarmChartValueType.AllCharts || line.alarmChartValueType === settings.chartIndex))
        .map<Highcharts.YAxisPlotBandsOptions>(line => {
            const color = Color(line.lineColor).alpha(0.4);
            const plotArea: Highcharts.YAxisPlotBandsOptions = {
                from: line.fillFrom,
                to: line.fillTo,
                color: color.rgb().string(),
                zIndex: 3,
            }

            return plotArea;
        });

    const series = getSeries();
    const noData = series.length === 0;

    const deltaAxis: any = {
        title: {
            text: `[${getPhysicalUnitShortName(chart.LeftYAxisSettings.Unit)}]`,
            align: "high",
            offset: 0,
            x: chart.IsHorizontal ? 35 : 0,
            y: chart.IsHorizontal ? 10 : -15,
            rotation: 0,
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            }
        },
        type: 'linear',
        crosshair: {
            snap: true,
        },
        plotLines: chart.IsHorizontal ? getHorizontalDeltaAxisPlotLines() : getVerticalDeltaAxisPlotLines(),
        plotBands: getDeltaAxisPlotBands(),
        lineWidth: 2,
        lineColor: "black",
        minRange: 0.0001,
        minorTickInterval: 'auto',
        min: getAxisLimitValue(chart.VisibleRangeSettings.MinXLimit, false, noData, undefined),
        max: getAxisLimitValue(chart.VisibleRangeSettings.MaxXLimit, true, noData, undefined),
        softMax: getSoftLimitValue(chart.VisibleRangeSettings.MaxXLimit, undefined),
        softMin: getSoftLimitValue(chart.VisibleRangeSettings.MinXLimit, undefined),
        minTickInterval: 0.001,
        startOnTick: false,
        endOnTick: false,
        maxPadding: 0,
        labels: {
            format: '{value:.2f}',
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            }
        }
    }

    const depthAxis: any = {
        title: {
            text: undefined
        },
        type: 'linear',
        lineWidth: 2,
        lineColor: "black",
        crosshair: {
            snap: true
        },
        min: getAxisLimitValue(chart.VisibleRangeSettings.MinYLimit, false, noData),
        max: getAxisLimitValue(chart.VisibleRangeSettings.MaxYLimit, true, noData),
        softMax: getSoftLimitValue(chart.VisibleRangeSettings.MaxYLimit),
        softMin: getSoftLimitValue(chart.VisibleRangeSettings.MinYLimit),
        startOnTick: false,
        endOnTick: false,
        gridLineWidth: 1,
        minRange: 0.0001,
        minTickInterval: 0.001,
        labels: {
            format: '{value:.2f} m',
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            }
        }
    }

    const options: Highcharts.Options = {
        boost: getBoostOptions(chart.Type, chartDataSlimConfig),
        annotations: getAlarmsAnnotations(),
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        chart: {
            animation: false,
            zooming: {
                type: undefined
            },
            reflow: true,
            alignTicks: true,
            showAxes: true,
            events: {
                render: lastInList ? onChartRender : undefined,
            }
        },
        legend: {
            enabled: false
        },
        title: {
            floating: false,
            text: chart.ShowAxisLabels.value ? `<div style='max-height:40px'>${chartIndex === 0 ? chart.FirstAxisLabel : chart.SecondAxisLabel}</div>` : "<div style='height:10px'></div>",
            useHTML: true
        },
        lang: {
            noData: `<div style='z-index:20'>${descriptions.length > 0 ? t('No data is available in the chart') : t('No chains selected for drawing the chart')}</div>`
        },
        noData: {
            useHTML: true
        },
        xAxis: chart.IsHorizontal ? depthAxis : deltaAxis,
        yAxis: chart.IsHorizontal ? deltaAxis : depthAxis,
        series,
        tooltip: {
            snap: 1,
            useHTML: true,
            formatter() {
                if (this.x !== undefined && this.y !== undefined) {
                    const customPointSettings = this.point.options.custom
                    if (!customPointSettings) {
                        return undefined;
                    }
                    if (customPointSettings.isJunctionPoint) {
                        return (
                            `<div style='display:flex; flex-direction:column'>
                                <div style='color:${customPointSettings.lineColor}'>${chainConfig.name}.SEG_${customPointSettings.junctionPointsNumber}</div>
                                <div>${formattedDateTime(customPointSettings.time, "DD.MM.YYYY HH:mm")}</div>
                                <div>${customPointSettings.xDisplayValue} ${chart.IsHorizontal ? "m" : `${getPhysicalUnitShortName(chart.LeftYAxisSettings.Unit)}`}</div>
                                <div>${customPointSettings.yDisplayValue} ${chart.IsHorizontal ? `${getPhysicalUnitShortName(chart.LeftYAxisSettings.Unit)}` : "m"}</div>
                            </div>`
                        );
                    }
                    if (customPointSettings.isSensorPosition) {
                        return (
                            `<div style='display:flex; flex-direction:column'>
                                <div style='color:${customPointSettings.lineColor}'>${customPointSettings.sensorId}</div>
                                <div>${formattedDateTime(customPointSettings.time, "DD.MM.YYYY HH:mm")}</div>
                                <div>${customPointSettings.xDisplayValue} ${chart.IsHorizontal ? "m" : `${getPhysicalUnitShortName(chart.LeftYAxisSettings.Unit)}`}</div>
                                <div>${customPointSettings.yDisplayValue} ${chart.IsHorizontal ? `${getPhysicalUnitShortName(chart.LeftYAxisSettings.Unit)}` : "m"}</div>
                            </div>`
                        );
                    }
                    if (customPointSettings.isFixed) {
                        return (
                            `<div style='display:flex; flex-direction:column'>                                
                                <div>${formattedDateTime(customPointSettings.time, "DD.MM.YYYY HH:mm")}</div>
                                <div>${customPointSettings.xDisplayValue} ${chart.IsHorizontal ? "m" : `${getPhysicalUnitShortName(chart.LeftYAxisSettings.Unit)}`}</div>
                                <div>${customPointSettings.yDisplayValue} ${chart.IsHorizontal ? `${getPhysicalUnitShortName(chart.LeftYAxisSettings.Unit)}` : "m"}</div>
                            </div>`
                        );
                    }
                    return (`<div>X = ${this.x}; Y = ${this.y}</div>`)
                }

                return undefined;
            }
        },
        plotOptions: {
            line: {
                findNearestPointBy: 'xy'
            }
        },
    }

    return options;
}

const getSeriesData = (line: IInclinometerLine, chartModel: InclinometerChartModel, descriptions: HtmlReportSensorDescriptionViewModel[]): Highcharts.PointOptionsObject[] => {
    const result: Highcharts.PointOptionsObject[] = [];
    let junctionPointsCounter: number = 0;

    line.points.forEach(point => {

        const isJunctionPoint = !point.isSensorPosition && !point.isFixed;

        if (isJunctionPoint) {
            junctionPointsCounter += 1;
        }

        if (!isNaN(point.x) && !isNaN(point.y)) {
            const pointItem: Highcharts.PointOptionsObject = {
                x: point.x,
                y: point.y,
                marker: getPointMarkerOptions(point, line, chartModel, descriptions),
                custom: {
                    isJunctionPoint,
                    isSensorPosition: point.isSensorPosition,
                    sensorId: point.sensorId.includes("_calc") ? point.sensorId.substring(0, point.sensorId.length - "_calc".length) : point.sensorId,
                    junctionPointsNumber: isJunctionPoint ? junctionPointsCounter : 0,
                    time: line.time,
                    isFixed: point.isFixed,
                    lineColor: line.color,
                    xDisplayValue: point.x.toFixed(chartModel.IsHorizontal ? 2 : getDigitsAfterDecimalPoint(chartModel.LeftYAxisSettings.Unit)),
                    yDisplayValue: point.y.toFixed(chartModel.IsHorizontal ? getDigitsAfterDecimalPoint(chartModel.LeftYAxisSettings.Unit) : 2)
                }
            }
            result.push(pointItem);
        }
    });

    return result;
}

const getPointMarkerOptions = (point: IInclinometerChartPoint, line: IInclinometerLine, chartModel: InclinometerChartModel, descriptions: HtmlReportSensorDescriptionViewModel[]): Highcharts.PointMarkerOptionsObject => {
    const pointSensorDescription = getDescriptionForPoint(point, descriptions);
    const needMarker = isMarkerEnableForPoint(point, chartModel);

    // completely hide marker if it's not needed or description was not found for this point
    if (!needMarker) {
        return {
            enabled: false,
            states: {
                hover: {
                    enabled: false
                }
            }
        }
    }

    if (!pointSensorDescription) {
        return {
            enabled: true,
            symbol: getMarkerSymbol({
                ... new ColorizableElement(),
                Symbol: SensorSymbol.TriangleUp
            }),
            color: line.color,
            fillColor: line.color
        }
    }

    return {
        enabled: true,
        symbol: getMarkerSymbol({
            ... new ColorizableElement(),
            Symbol: !point.isFixed && !point.isSensorPosition ? SensorSymbol.TriangleUp : pointSensorDescription.symbol
        }),
        color: !point.isFixed && !point.isSensorPosition ? line.color : pointSensorDescription.color,
        fillColor: !point.isFixed && !point.isSensorPosition ? line.color : pointSensorDescription.color,
    }
}

const isMarkerEnableForPoint = (point: IInclinometerChartPoint, chartModel: InclinometerChartModel): boolean => {
    if (!point.isFixed && !point.isSensorPosition) {
        return chartModel.VisibleRangeSettings.ShowJunctionPoints.value; // if point is not fixed and not sensor position than this is junction point
    }

    if (point.isSensorPosition && point.sensorId) {
        return chartModel.VisibleRangeSettings.ShowSensors.value; // if point is fixed or is sensor position than we will show marker in case of sensor's markers needed
    }

    return point.isFixed;
}

const getDescriptionForPoint = (point: IInclinometerChartPoint, descriptions: HtmlReportSensorDescriptionViewModel[]): HtmlReportSensorDescriptionViewModel | undefined => {
    if (!point.sensorId) {
        return undefined;
    }
    let result: HtmlReportSensorDescriptionViewModel | undefined;

    result = descriptions.find(d => getSensorIdFromFullId(d.id) === point.sensorId);

    if (!result) {
        const cutId = point.sensorId.substring(0, point.sensorId.length - "_calc".length);
        result = descriptions.find(d => getSensorIdFromFullId(d.id) === cutId);
    }

    return result;
}

const getSensorIdFromFullId = (fullId: string): string => {
    const parsedFullId = fullId.split('.');
    if (parsedFullId.length > 1) {
        return fullId.substring(parsedFullId[0].length + 1);
    }
    return fullId;
}

const processLinesData = (chartModel: InclinometerChartModel, lines: HtmlReportChainLineInfo[], chartIndex: number, chainConfig: ChainModel): IInclinometerLine[] => {
    const processedLines: IInclinometerLine[] = [];

    lines.forEach((line, lineIndex, lineCollection) => {
        let processedLine: IInclinometerLine = {
            color: line.color,
            isLast: lineIndex === lineCollection.length - 1,
            isManual: line.isManual,
            isStartFixed: line.isStartFixed,
            points: [],
            time: line.timeslot,
            version: line.version
        }

        let lineTopValue: number | false = false;
        let lineBottomValue: number | false = false;

        line.positions.forEach((position) => {
            const processedPoint: IInclinometerChartPoint = {
                x: position.positions[chartIndex],
                y: position.positions[2],
                sensorId: position.sensorId,
                isFixed: position.isFixedPoint,
                isManual: line.isManual,
                isSensorPosition: position.isSensorPosition,
                time: line.timeslot
            }

            if (lineTopValue === false || lineTopValue < position.positions[2]) {
                lineTopValue = position.positions[2];
            }

            if (lineBottomValue === false || lineBottomValue > position.positions[2]) {
                lineBottomValue = position.positions[2];
            }

            processedLine.points.push(processedPoint);
        })

        const isStartFixed = line.isStartFixed === undefined
            ? chainConfig.isStartFixed
            : line.isStartFixed;

        if (line.version < 2) {

            let fixedPoint: IInclinometerFixedPoint | undefined;

            if (line.hasFixedPoint) {
                fixedPoint = {
                    x: line.fixedPoint[chartIndex],
                    y: line.fixedPoint[2]
                }
            }
            else {
                const destZ = isStartFixed || chartModel.IsHorizontal
                    ? lineTopValue
                        ? lineTopValue + chainConfig.segmentLength / 2
                        : chainConfig.segmentLength / 2
                    : lineBottomValue
                        ? lineBottomValue - chainConfig.segmentLength / 2
                        : - chainConfig.segmentLength / 2;
                fixedPoint = {
                    x: 0,
                    y: destZ
                };
            }

            if (chainConfig.isFixedBothSides) {
                if (chartModel.IsHorizontal) {
                    processedLine.points.splice(0, 0, {
                        x: fixedPoint.x,
                        y: fixedPoint.y,
                        sensorId: "",
                        time: line.timeslot,
                        isFixed: false,
                        isManual: false,
                        isSensorPosition: false
                    });
                }
                else {
                    processedLine.points.splice(0, 0, {
                        x: 0,
                        y: 0,
                        sensorId: "",
                        time: line.timeslot,
                        isFixed: false,
                        isManual: false,
                        isSensorPosition: false
                    });
                }
            }
            else if (isStartFixed) {
                const firstId = processedLine.points[0].sensorId;

                processedLine.points.splice(0, 0, {
                    x: fixedPoint.x,
                    y: fixedPoint.y,
                    sensorId: firstId,
                    time: line.timeslot,
                    isFixed: false,
                    isManual: false,
                    isSensorPosition: false
                });

                if (chartModel.ForcedFixedPoints === ChainFixedPoint.End) {
                    processedLine = moveLineByX(processedLine, -processedLine.points[processedLine.points.length - 1].x)
                }
            }
            else {

                processedLine.points.push({
                    x: fixedPoint.x,
                    y: fixedPoint.y,
                    sensorId: "",
                    time: line.timeslot,
                    isFixed: true,
                    isManual: false,
                    isSensorPosition: false
                });

                if (chartModel.ForcedFixedPoints === ChainFixedPoint.Start) {
                    processedLine = moveLineByX(processedLine, -processedLine.points[0].x)
                }
            }
        }

        // if there is no sensor position between to junction points add it manually
        let lastJunctionPointIndex: number = -1;

        for (let index = 0; index < processedLine.points.length; index++) {
            if (index <= lastJunctionPointIndex) {
                continue;
            }
            const isJunctionPoint = !processedLine.points[index].isSensorPosition && !processedLine.points[index].isFixed;

            if (isJunctionPoint) {
                if (index - lastJunctionPointIndex === 1 && index < processedLine.points.length - 1) {
                    processedLine.points.splice(index + 1, 0, {
                        x: (processedLine.points[index + 1].x + processedLine.points[index].x) / 2,
                        y: (processedLine.points[index + 1].y + processedLine.points[index].y) / 2,
                        isFixed: false,
                        isManual: false,
                        isSensorPosition: true,
                        time: line.timeslot,
                        sensorId: chainConfig.isFixedBothSides || isStartFixed ? processedLine.points[index + 1].sensorId : processedLine.points[index].sensorId
                    });
                    index++;
                }
                lastJunctionPointIndex = index;
            }
        }
        processedLines.push(processedLine);
    });

    return processedLines;
}

const moveLineByX = (line: IInclinometerLine, value: number): IInclinometerLine => {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < line.points.length; index++) {
        line.points[index].x += value;
    }
    return line;
}

const rotateLine = (line: IInclinometerLine): IInclinometerLine => {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < line.points.length; index++) {
        const change = cloneDeep(line.points[index].x);
        line.points[index].x = cloneDeep(line.points[index].y);
        line.points[index].y = change;
    }
    return line;
}