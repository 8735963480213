import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add'
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove'
import HipchatChevronDoubleDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-double-down';
import HipchatChevronDoubleUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-double-up';
import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from "react";
import { OptionType } from "@atlaskit/select";
import { debounce } from "lodash";
import { HeadType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import Button from "@atlaskit/button";
import Textfield from '@atlaskit/textfield';
import Tooltip from '@atlaskit/tooltip';
import Checkbox from '@atlaskit/checkbox';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import { SensorCategory, getSensorCategoryName } from "../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { ViewEditDataItem } from "../../server/GEOvis3/Model/ProjectViews/ViewEditDataItem";
import { isMatchToSearchString } from "../../helpers/FiltersHelper";
import { t } from "../../i18n";
import { GeovisTableWithSelectableRows } from '../DynamicTableWithSelectableRows';
import { GeovisSelect, GeovisSelectMulti } from '../select/GeovisSelect';
import { InclinometerChainsStringType, InclinometerChainsType } from '../../helpers/ProjectViewsHelper';
import { GeovisModalDialog } from '../editDialogs/GeovisModalDialog';

interface IComponentProps<TData> {
    originData: TData[];
    selectedDataIds: string[];
    header: ReactNode;

    convertFunc: (dataItem: TData) => ViewEditDataItem;
    onSave: (selectedItems: TData[]) => void;
    onClose: () => void;

    useCategoriesFilter?: boolean;
    useDatabasesFilter?: boolean;
    customTableHead?: HeadType;
    useChainsFilter?: boolean;

    loadDataFunc?: () => Promise<TData[]>;

}

interface IComponentFilterState {
    nameFilter: string;
    databaseFilter: string[];
    chainsFilter: string[];
    publicFilter?: boolean;
    sensorTypeFilter: SensorCategory[];
    applyFilterToSelectedItems: boolean;

    possiblePageNumber: number;
    selectedPageNumber: number;
}

interface IComponentState<TData> {
    allItems: TData[];
    markedPossibleElements: ViewEditDataItem[];
    markedSelectedElements: ViewEditDataItem[];
    selectedItemsIds: string[],
    isLoaded: boolean;
}

export const GeovisSelectItemsDialog = <TData extends any>({
    originData,
    selectedDataIds,
    loadDataFunc,
    header,
    useCategoriesFilter,
    customTableHead,
    useDatabasesFilter,
    onClose,
    onSave,
    convertFunc,
    useChainsFilter
}: IComponentProps<TData>) => {

    const [filterState, setFilterState] = useState<IComponentFilterState>({
        applyFilterToSelectedItems: false,
        databaseFilter: [],
        nameFilter: "",
        sensorTypeFilter: [],
        publicFilter: undefined,
        chainsFilter: [],
        possiblePageNumber: 1,
        selectedPageNumber: 1
    });

    const [state, setState] = useState<IComponentState<TData>>({
        markedPossibleElements: [],
        markedSelectedElements: [],
        selectedItemsIds: selectedDataIds,
        isLoaded: loadDataFunc !== undefined ? false : true,
        allItems: originData
    });

    const nameFilterRef = useRef<HTMLInputElement>(null);

    // Maybe this loading is not needed, or have to be implemented with other approach
    useEffect(() => {
        if (!loadDataFunc) {
            return;
        }

        (async function loadData() {
            const loadedItems = await loadDataFunc();
            setState({
                ...state,
                allItems: loadedItems,
                isLoaded: true
            })
        })();
    }, [1]);

    //#region Filter setup

    const getPublicFilterOptions = (): OptionType[] => ([{
        value: -1, label: t("Not public")
    }, {
        value: 1, label: t("Public")
    }]);

    const getSelectedPublicFilterOption = (): OptionType | undefined => {
        const publicFilterNumericValue = filterState.publicFilter === undefined ? 0 : filterState.publicFilter ? 1 : -1;
        return getPublicFilterOptions().find(o => o.value === publicFilterNumericValue);
    }

    const onSelectedPublicFilterOptionChanged = (data: OptionType | undefined) => {
        setFilterState({
            ...filterState,
            publicFilter: !data ? undefined : +data.value === 1 ? true : false,
            possiblePageNumber: 1,
            selectedPageNumber: filterState.applyFilterToSelectedItems ? 1 : filterState.selectedPageNumber
        });
    }

    const getChainsFilterOptions = (): OptionType[] => {
        if (!state.isLoaded) {
            return [];
        }
        const result: OptionType[] = [];
        state.allItems.map(i => convertFunc(i)).forEach(d => {
            if (d.ChainId && result.findIndex(e => e.value === d.ChainId) < 0) {
                result.push({ value: d.ChainId, label: d.ChainName });
            }
        });
        return result;
    }

    const onChainsFilterChange = (newFilter: OptionType[] | null | undefined) => {
        setFilterState({
            ...filterState,
            chainsFilter: newFilter ? newFilter.map(o => o.value.toString()) : [],
            possiblePageNumber: 1,
            selectedPageNumber: filterState.applyFilterToSelectedItems ? 1 : filterState.selectedPageNumber
        });
    }

    const getSelectedChainsForFilter = (): OptionType[] => {
        const possibleOptions: OptionType[] = state.allItems.map(i => convertFunc(i)).map(item => ({ value: item.ChainId, label: item.ChainName }));
        const result: OptionType[] = [];
        filterState.chainsFilter.forEach(o => {
            const chainOption = possibleOptions.find(option => option.value.toString() === o);
            if (chainOption) {
                result.push(chainOption);
            }
        });
        return result;
    }

    const getDatabaseFilterOptions = (): OptionType[] => {
        if (!state.isLoaded) {
            return [];
        }
        const result: OptionType[] = [];
        state.allItems.map(i => convertFunc(i)).forEach(d => {
            if (d.DatabaseId && result.findIndex(e => e.value === d.DatabaseId) < 0) {
                result.push({ value: d.DatabaseId, label: d.DatabaseName });
            }
        });
        return result;
    }

    const onDatabaseFilterChange = (newFilter: OptionType[] | null | undefined) => {
        setFilterState({
            ...filterState,
            databaseFilter: newFilter ? newFilter.map(o => o.value.toString()) : [],
            possiblePageNumber: 1,
            selectedPageNumber: filterState.applyFilterToSelectedItems ? 1 : filterState.selectedPageNumber
        });
    }

    const getSelectedDatabasesForFilter = (): OptionType[] => {
        const possibleOptions: OptionType[] = state.allItems.map(i => convertFunc(i)).map(item => ({ value: item.DatabaseId, label: item.DatabaseName }));
        const result: OptionType[] = [];
        filterState.databaseFilter.forEach(o => {
            const dbOption = possibleOptions.find(option => option.value.toString() === o);
            if (dbOption) {
                result.push(dbOption);
            }
        });
        return result;
    }

    const onNameFilterChanged = (event: SyntheticEvent<HTMLInputElement>) => {
        onNameFilterChangedDebounced(event.currentTarget.value);
    }

    const onNameFilterChangedDebounced = debounce((filter) => setFilterState({
        ...filterState,
        nameFilter: filter,
        possiblePageNumber: 1,
        selectedPageNumber: filterState.applyFilterToSelectedItems ? 1 : filterState.selectedPageNumber
    }), 400);

    const onNameFilterClear = () => {
        if (nameFilterRef !== null && nameFilterRef.current !== null) {
            nameFilterRef.current.value = "";
        }
        setFilterState({ ...filterState, nameFilter: '' });
    }

    const getSensorChainCategoryName = (category: number) =>
        category === InclinometerChainsType
            ? InclinometerChainsStringType
            : getSensorCategoryName(category);

    const getSensorTypesFilterOptions = (): OptionType[] => {
        if (!state.isLoaded) {
            return [];
        }
        const categories = state.allItems
            .map(i => convertFunc(i))
            .map(item => (item.SensorCategory));
        const uniqCategories = [...new Set(categories)];
        const result: OptionType[] = uniqCategories.map(item => ({ value: item, label: getSensorChainCategoryName(item) }));
        return result;
    }

    const onSensorTypesFilterChange = (newFilter: OptionType[] | null | undefined) => {
        setFilterState({
            ...filterState,
            sensorTypeFilter: newFilter ? newFilter.map(o => +o.value) : [],
            possiblePageNumber: 1,
            selectedPageNumber: filterState.applyFilterToSelectedItems ? 1 : filterState.selectedPageNumber
        });
    }

    const getSelectedSensorTypesForFilter = (): OptionType[] => {
        const possibleOptions: OptionType[] = state.allItems
            .map(i => convertFunc(i))
            .map(item => ({ value: item.SensorCategory, label: getSensorChainCategoryName(item.SensorCategory) }));
        const result: OptionType[] = [];
        filterState.sensorTypeFilter.forEach(o => {
            const dbOption = possibleOptions.find(option => +option.value === o);
            if (dbOption) {
                result.push(dbOption);
            }
        });
        return result;
    }

    const onApplyFilterToSelectedChanged = () => {
        setFilterState({
            ...filterState,
            applyFilterToSelectedItems: !filterState.applyFilterToSelectedItems,
            selectedPageNumber: 1
        });
    }

    //#endregion

    //#region Common options setup

    const getIdFunc = (item: ViewEditDataItem): string => item.Id;

    const getHead = (): HeadType => ({
        cells: [{
            key: 'name',
            content: "",
            width: 10
        }, {
            key: 'actions',
            content: "",
            width: 1
        }]
    })

    const sortItemsFunc = (a: ViewEditDataItem, b: ViewEditDataItem): number => {
        if (a.Name === b.Name) {
            return 0
        }

        return a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1;
    }

    //#endregion


    //#region Possible options setup

    const getAllPossibleItems = (): ViewEditDataItem[] => {
        if (!state.isLoaded) {
            return [];
        }

        const possibleItems = state.allItems
            .map(i => convertFunc(i))
            .filter(d => filterState.publicFilter === undefined || d.IsPublic === filterState.publicFilter) // public filter 
            .filter(d => !state.selectedItemsIds.map(id => id.toString()).includes(d.Id)) //filter all selected items
            .filter(d => !useDatabasesFilter || !d.DatabaseId || filterState.databaseFilter.includes(d.DatabaseId) || filterState.databaseFilter.length === 0) // filter database ids if needed
            .filter(d => !useChainsFilter || !d.ChainId || filterState.chainsFilter.includes(d.ChainId) || filterState.chainsFilter.length === 0) // filter chains ids if needed
            .filter(d => !useCategoriesFilter || filterState.sensorTypeFilter.length === 0 || filterState.sensorTypeFilter.includes(d.SensorCategory)) // filter sensor categories if needed
            .filter(d => !filterState.nameFilter || isMatchToSearchString(d.Name, filterState.nameFilter)); // filter by name

        return possibleItems.sort((a, b) => sortItemsFunc(a, b));
    }

    const onSelectPossibleItem = (itemId: string) => (event: React.MouseEvent<HTMLElement>) => {
        if (event.isDefaultPrevented()) {
            return;
        }
        state.selectedItemsIds.push(itemId);
        setState({
            ...state,
            selectedItemsIds: state.selectedItemsIds,
            markedSelectedElements: []
        })
        event.preventDefault();
    }

    const possibleItemToRowFunc = (item: ViewEditDataItem): RowType => {
        return {
            key: `row-possible-${getIdFunc(item)}`,
            cells: [{
                key: 'name',
                content: (
                    <span>{item.Name}</span>
                )
            }, {
                key: 'actions',
                content: (
                    <Button onClick={onSelectPossibleItem(item.Id)} appearance='subtle' iconBefore={<EditorAddIcon label='add' size='small' />} />
                )
            },]
        }
    }

    const onSelectionOfPossibleItemsChanged = (items: ViewEditDataItem[]) => {
        setState({
            ...state,
            markedPossibleElements: items
        })
    }

    const onSelectDeselectAllPossibleItems = (allSelected: boolean) => {
        setState({
            ...state,
            markedPossibleElements: allSelected ? getAllPossibleItems() : []
        })
    }

    const onAddAllPossibleClick = () => {
        const allData = [...state.selectedItemsIds];
        allData.push(...getAllPossibleItems().map(i => getIdFunc(i)));
        setState({
            ...state,
            markedPossibleElements: [],
            selectedItemsIds: allData
        });
    }

    const onAddMarkedPossibleClick = () => {
        const allData = [...state.selectedItemsIds];
        allData.push(...state.markedPossibleElements.map(i => getIdFunc(i)));
        setState({
            ...state,
            markedPossibleElements: [],
            selectedItemsIds: allData
        });
    }

    const onClearPossibleSelection = () => {
        setState({
            ...state,
            markedPossibleElements: []
        })
    }

    //#endregion

    //#region Selected options setup

    const getAllSelectedItems = (): ViewEditDataItem[] => {
        if (!state.isLoaded) {
            return [];
        }

        const selectedItems = state.allItems
            .map(i => convertFunc(i))
            .filter(d => state.selectedItemsIds.map(id => id.toString()).includes(d.Id)) //filter all selected items
            .filter(d => !filterState.applyFilterToSelectedItems || filterState.publicFilter === undefined || filterState.publicFilter === d.IsPublic) // filter public if needed
            .filter(d => !filterState.applyFilterToSelectedItems || !useDatabasesFilter || !d.DatabaseId || filterState.databaseFilter.includes(d.DatabaseId) || filterState.databaseFilter.length === 0) // filter database ids if needed
            .filter(d => !filterState.applyFilterToSelectedItems || !useCategoriesFilter || filterState.sensorTypeFilter.length === 0 || filterState.sensorTypeFilter.includes(d.SensorCategory)) // filter sensor categories if needed
            .filter(d => !filterState.applyFilterToSelectedItems || !filterState.nameFilter || isMatchToSearchString(d.Name, filterState.nameFilter)); // filter by name

        return selectedItems.sort((a, b) => sortItemsFunc(a, b));
    }

    const onDeselectSelectedItem = (itemId: string) => (event: React.MouseEvent<HTMLElement>) => {

        if (event.isDefaultPrevented()) {
            return;
        }

        const updData = state.selectedItemsIds.filter(i => i !== itemId);
        setState({
            ...state,
            selectedItemsIds: updData,
            markedSelectedElements: []
        })

        event.preventDefault();
    }

    const selectedItemToRowFunc = (item: ViewEditDataItem): RowType => {
        return {
            key: `row-selected-${getIdFunc(item)}`,
            cells: [{
                key: 'name',
                content: (
                    <span>{item.Name}</span>
                )
            }, {
                key: 'actions',
                content: (
                    <Button onClick={onDeselectSelectedItem(item.Id)} appearance='subtle' iconBefore={<EditorRemoveIcon label='add' size='small' />} />
                )
            },]
        }
    }

    const onSelectionOfSelectedItemsChanged = (items: ViewEditDataItem[]) => {
        setState({
            ...state,
            markedSelectedElements: items
        })
    }

    const onSelectDeselectAllSelectedItems = (allSelected: boolean) => {
        setState({
            ...state,
            markedSelectedElements: allSelected ? getAllSelectedItems() : []
        })
    }

    const onRemoveAllSelectedClick = () => {
        const allDataToRemove = [...getAllSelectedItems().map(s => getIdFunc(s))];
        setState({
            ...state,
            markedSelectedElements: [],
            selectedItemsIds: state.selectedItemsIds.filter(s => !allDataToRemove.includes(s))
        });
    }

    const onRemoveMarkedSelectedClick = () => {
        const allDataToRemove = [...state.markedSelectedElements.map(i => getIdFunc(i))];
        setState({
            ...state,
            markedSelectedElements: [],
            selectedItemsIds: state.selectedItemsIds.filter(s => !allDataToRemove.includes(s))
        });
    }

    const onClearSelectedSelection = () => {
        setState({
            ...state,
            markedSelectedElements: []
        })
    }

    const onChangePageCallback = (isPossible: boolean) => (pageNumber: number) => {
        setFilterState({
            ...filterState,
            possiblePageNumber: isPossible ? pageNumber : filterState.possiblePageNumber,
            selectedPageNumber: isPossible ? filterState.selectedPageNumber : pageNumber
        })
    }

    //#endregion

    const onSaveHandler = () => {
        const result: TData[] = [];
        state.selectedItemsIds.forEach(id => {
            const item = state.allItems.find(i => convertFunc(i).Id === id);
            if (item) {
                result.push(item);
            }
        });
        onSave(result);
    }

    return (
        <GeovisModalDialog
            heading={header}
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
            actions={[
                { text: t('Save'), appearance: 'primary', onClick: onSaveHandler },
                { text: t("Close"), appearance: 'default', onClick: onClose }
            ]}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className='flexRowContainer' style={{ marginBottom: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <div style={{ marginRight: '5px', flexGrow: 2 }}>
                        <Textfield
                            placeholder={t("Name filter")}
                            defaultValue={filterState.nameFilter}
                            onChange={onNameFilterChanged}
                            ref={nameFilterRef}
                            isCompact={true}
                            elemAfterInput={
                                <div style={{ marginRight: '5px', cursor: 'default' }} onClick={onNameFilterClear}>
                                    <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                                </div>
                            }
                        />
                    </div>
                    {useDatabasesFilter &&
                        <div style={{ marginLeft: '5px', marginRight: '5px', flexGrow: 3, alignItems: 'center' }}>
                            <GeovisSelectMulti
                                options={getDatabaseFilterOptions()}
                                value={getSelectedDatabasesForFilter()}
                                onChange={onDatabaseFilterChange}
                                placeholder={t("Databases filter")}
                                isLoading={!state.isLoaded}
                                spacing='compact'
                            />
                        </div>
                    }
                    {useCategoriesFilter &&
                        <div style={{ marginLeft: '5px', marginRight: '5px', flexGrow: 3 }}>
                            <GeovisSelectMulti
                                options={getSensorTypesFilterOptions()}
                                value={getSelectedSensorTypesForFilter()}
                                onChange={onSensorTypesFilterChange}
                                placeholder={t("Sensors categories filter")}
                                isLoading={!state.isLoaded}
                                spacing='compact'
                            />
                        </div>
                    }
                    {useChainsFilter &&
                        <div style={{ marginLeft: '5px', marginRight: '5px', flexGrow: 3, alignItems: 'center' }}>
                            <GeovisSelectMulti
                                options={getChainsFilterOptions()}
                                value={getSelectedChainsForFilter()}
                                onChange={onChainsFilterChange}
                                placeholder={t("Chains filter")}
                                isLoading={!state.isLoaded}
                                spacing='compact'
                            />
                        </div>
                    }
                    <div style={{ marginRight: '5px', flexGrow: 3 }}>
                        <GeovisSelect
                            isClearable={true}
                            options={getPublicFilterOptions()}
                            value={getSelectedPublicFilterOption()}
                            onChange={onSelectedPublicFilterOptionChanged}
                            placeholder={t("Public filter")}
                            isLoading={!state.isLoaded}
                            spacing='compact'
                        />
                    </div>
                    <div style={{ marginLeft: '5px', marginRight: '5px', alignSelf: 'center' }}>
                        <Checkbox label={t("Use filter for selected")} isChecked={filterState.applyFilterToSelectedItems} onChange={onApplyFilterToSelectedChanged} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 3 }}>
                    <div className='flexRowMiddleContainer' style={{ flexGrow: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flexGrow: 3 }}>
                                {/*  */}
                            </div>
                            <div style={{ marginLeft: '5px' }}>
                                <Tooltip content={t("Add selected")} >
                                    <Button
                                        style={{ marginRight: '10px' }}
                                        // isDisabled={state.availableItemsSelection.length === 0}
                                        onClick={onAddMarkedPossibleClick}
                                        iconBefore={<ChevronRightIcon label='moveSelected' />}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip content={t("Add all")}>
                                    <Button
                                        style={{ marginRight: '10px', transform: 'rotate(90deg)' }}
                                        onClick={onAddAllPossibleClick}
                                        iconBefore={<HipchatChevronDoubleUpIcon label='moveAll' />}
                                    />
                                </Tooltip>
                            </div>
                            <div style={{ marginRight: '5px' }}>
                                <Tooltip content={t("Clear selection")}>
                                    <Button
                                        // isDisabled={state.availableItemsSelection.length === 0}
                                        onClick={onClearPossibleSelection}
                                        iconBefore={<CrossIcon label='clearSelected' />}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{ flexGrow: 3 }}>
                            <GeovisTableWithSelectableRows
                                allItems={getAllPossibleItems()}
                                getIdFunc={getIdFunc}
                                isLoading={!state.isLoaded}
                                itemToRowFunc={possibleItemToRowFunc}
                                needSelectAll={true}
                                onSelectionChanged={onSelectionOfPossibleItemsChanged}
                                selectedItems={state.markedPossibleElements}
                                rowsPerPage={20}
                                onSelectDeselectAll={onSelectDeselectAllPossibleItems}
                                //defaultPageNumber={1}
                                head={customTableHead ?? getHead()}
                                pageNumber={filterState.possiblePageNumber}
                                onChangePageCallback={onChangePageCallback(true)}
                                showPagination={true}
                                showCountRowsSelect={false}
                            />
                        </div>

                    </div>
                    <div className='separator'>
                        {/*  */}
                    </div>
                    <div className='flexRowMiddleContainer' style={{ flexGrow: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginLeft: '5px' }}>
                                <Tooltip content={t("Remove selected")}>
                                    <Button
                                        style={{ marginRight: '10px' }}
                                        // isDisabled={state.selectedItemsSelection.length === 0}
                                        onClick={onRemoveMarkedSelectedClick}
                                        iconBefore={<ChevronLeftIcon label='removeSelected' />}
                                    />
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip content={t("Remove all")}>
                                    <Button
                                        style={{ marginRight: '10px', transform: 'rotate(90deg)' }}
                                        onClick={onRemoveAllSelectedClick}
                                        iconBefore={<HipchatChevronDoubleDownIcon label='removeAll' />}
                                    />
                                </Tooltip>
                            </div>
                            <div style={{ marginRight: '5px' }}>
                                <Tooltip content={t("Clear selection")}>
                                    <Button
                                        onClick={onClearSelectedSelection}
                                        iconBefore={<CrossIcon label='clearSelected' />}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{ flexGrow: 3 }}>
                            <GeovisTableWithSelectableRows
                                allItems={getAllSelectedItems()}
                                getIdFunc={getIdFunc}
                                isLoading={!state.isLoaded}
                                itemToRowFunc={selectedItemToRowFunc}
                                needSelectAll={true}
                                onSelectionChanged={onSelectionOfSelectedItemsChanged}
                                selectedItems={state.markedSelectedElements}
                                rowsPerPage={20}
                                onSelectDeselectAll={onSelectDeselectAllSelectedItems}
                                //defaultPageNumber={1}
                                head={customTableHead ?? getHead()}
                                pageNumber={filterState.selectedPageNumber}
                                onChangePageCallback={onChangePageCallback(false)}
                                showPagination={true}
                                showCountRowsSelect={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </GeovisModalDialog>
    )
}
