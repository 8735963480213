/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 09.06.2023
 * @description Map section local map objects render host
 * It providers required minimum
 */

import { LocalMapObject } from "../../../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject"
import { LocalMapObjectShapesRenderHost } from "../../../localMapObjects/LocalMapObjectShapesRenderHost";
import { LocalMapObjectViewMode } from "../../../localMapObjects/types";

interface IComponentProps {
    lmo: LocalMapObject;
}

export const MapSectionLocalMapObjectsRenderHost = ({ lmo }: IComponentProps) => {

    const dummyMethod = () => {
        // nothing there
    }

    return (
        <LocalMapObjectShapesRenderHost
            lmo={lmo}
            isEditMode={false}
            onClickLocalMapObjectHandler={dummyMethod}
            onEditLocalMapObjectHandler={dummyMethod}
            onOpenAttachedDocumentById={dummyMethod}
            onOpenLocalMapDocument={dummyMethod}
            onRemoveLocalMapObjectHandler={dummyMethod}
            openReportById={dummyMethod}
            openViewById={dummyMethod}
            viewMode={LocalMapObjectViewMode.MapSection}
        />
    )
}