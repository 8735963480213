import 'leaflet.markercluster';

L.MarkerClusterGroup.include({
	zoomToShowLayerGeovisEdition(layer, callback) {

		if (typeof callback !== 'function') {
			callback = function () { /** */ };
		}

		var showMarker = function () {
			if ((layer._icon || (layer.__parent && layer.__parent._icon)) && !this._inZoomAnimation) {

				this._map.off('moveend', showMarker, this);
				this.off('animationend', showMarker, this);

				if (layer._icon) {
					callback();
				} else if (layer.__parent._icon) {
					this.once('spiderfied', callback, this);
					layer.__parent.spiderfy();
					callback();
				}
			}
			else if (layer.__parent && typeof layer.__parent.spiderfy === "function") {
				layer.__parent.spiderfy();
				callback();
			}
		};

		if (layer._icon && this._map.getBounds().contains(layer.getLatLng())) {
			//Layer is visible ond on screen, immediate return
			callback();
		} else if (layer.__parent._zoom < Math.round(this._map._zoom)) {
			//Layer should be visible at this zoom level. It must not be on screen so just pan over to it
			this._map.once('moveend', showMarker, this);
			this._map.panTo(layer.getLatLng());
		} else {
			this._map.once('moveend', showMarker, this);
			this.once('animationend', showMarker, this);
			layer.__parent.zoomToBounds();
		}
	}
});