/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 18.07.2023
 * @description Geovis report map section render
 * This is intermediate layer to connect to the report storage and get data about map section report view
 */

import { connect } from "react-redux";
import { GeovisReportElementInfo } from "../../../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo";
import { IGeovisMapSectionReportData, IGeovisMapSectionReportInfo, IGeovisReportPageConfig, IGeovisReportSettings } from "../../../../../../store/projectReport.types";
import { IReportElementRenderHostOwnProp } from "../../types";
import GeovisMapSectionRenderView from "./GeovisMapSectionRenderView";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { getMapSectionTimeSearchDistanceElementDataRequest } from "../../../../mapSection/tools";

interface IStateToProps {

    mapSectionInfo: IGeovisMapSectionReportInfo | false;
    mapSectionData: IGeovisMapSectionReportData | false;
    groupedMapSectionData: IGeovisMapSectionReportInfo | false;

    pageConfig: IGeovisReportPageConfig | false;
}

interface IOwnProps extends Omit<IReportElementRenderHostOwnProp, 'eventInfo' | 'isDefault' | 'isVibrationEventChart' | 'showVibrationEventChartOnPage' | 'settingsId'> {
    pageNum: number;
    elementInfo: GeovisReportElementInfo;
    rootContainerWidth: number;
    allContainerWidth: number;
    groupedElements?: GeovisReportElementInfo[]
}

interface IComponentProps extends IStateToProps, IOwnProps {

}


const Component = ({
    pageConfig,
    elementIndexOnPage,
    elementInfo,
    mapSectionInfo,
    mapSectionData,
    pageNum,
    projectId,
    isInGroup,
    lastInGroup,
    userId,
    userToken,
    rootContainerWidth,
    groupedMapSectionData,
    allContainerWidth,
    isPrinting,
    reportId
}: IComponentProps) => {

    if (!mapSectionData || !mapSectionInfo || !pageConfig) {
        return null;
    }

    // const { Height, HideTitle } = elementInfo;
    // const { MapSection } = mapSectionInfo;

    const { GlobalTimeslot, chainsInfoStorage, sensorsInfoStorage, sensorsLastDataStorage, chainsLastDataStorage } = mapSectionData;

    // const elementTitle = processTitleTimeslotTemplate(MapSection.Title, GlobalTimeslot ? GlobalTimeslot : MapSection.TimeslotSetting ? MapSection.TimeslotSetting.Value : "");

    const timeSearchDistanceDataRequest = getMapSectionTimeSearchDistanceElementDataRequest(pageConfig, mapSectionInfo);

    return (
        <GeovisMapSectionRenderView
            chainsInfoStorage={chainsInfoStorage}
            elementIndexOnPage={elementIndexOnPage}
            elementInfo={elementInfo}
            globalTimeslot={GlobalTimeslot}
            mapSectionInfo={mapSectionInfo}
            pageNum={pageNum}
            projectId={projectId}
            sensorsInfoStorage={sensorsInfoStorage}
            sensorsLastDataStorage={sensorsLastDataStorage}
            isInGroup={isInGroup}
            lastInGroup={lastInGroup}
            userId={userId}
            userToken={userToken}
            chainsLastDataStorage={chainsLastDataStorage}
            timeSearchDistanceDataRequest={timeSearchDistanceDataRequest}
            rootContainerWidth={rootContainerWidth}
            groupedMapSectionData={groupedMapSectionData}
            allContainersWidth={allContainerWidth}
            isPreview={false}
            isPrinting={isPrinting}
            reportId={reportId}
        />
    )
}

const getMapSectionInfo = (geovisReportSettings: IGeovisReportSettings, pageNum: number, sectionId: number): IGeovisMapSectionReportInfo | false => {
    const page = geovisReportSettings.geovisPages.get(pageNum);
    if (!page) {
        return false;
    }

    return page.mapSections.get(sectionId) || false;
}

const getGroupedMapSectionInfo = (geovisReportSettings: IGeovisReportSettings, pageNum: number, sectionId: number, elementsOfGroup?: GeovisReportElementInfo[]): IGeovisMapSectionReportInfo | false => {
    if (!elementsOfGroup) {
        return false;
    }
    const page = geovisReportSettings.geovisPages.get(pageNum);
    if (!page) {
        return false;
    }
    const secondMs = elementsOfGroup.find(s => s.Id !== sectionId);

    if (!secondMs) {
        return false;
    }

    return page.mapSections.get(secondMs.Id) || false;
}

const getMapSectionData = (geovisReportSettings: IGeovisReportSettings, pageNum: number, sectionId: number): IGeovisMapSectionReportData | false => {

    const page = geovisReportSettings.geovisPagesData.get(pageNum);
    if (!page) {
        return false;
    }

    return page.geovisMapSectionsData.get(sectionId) || false;
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { pageNum, elementInfo: { Id }, groupedElements }: IOwnProps): IStateToProps => ({
    pageConfig: projectReport.geovisReportSettings.geovisPages.get(pageNum) || false,
    mapSectionInfo: getMapSectionInfo(projectReport.geovisReportSettings, pageNum, Id),
    mapSectionData: getMapSectionData(projectReport.geovisReportSettings, pageNum, Id),
    groupedMapSectionData: getGroupedMapSectionInfo(projectReport.geovisReportSettings, pageNum, Id, groupedElements)
})

const ComponentConnected = connect<IStateToProps, never, IOwnProps>(mapStateToProps)(Component);

export default ComponentConnected;