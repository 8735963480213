export const DASHBOARD_SELECTED_COMPANIES_CHANGED = "DASHBOARD_SELECTED_COMPANIES_CHANGED";
export const DASHBOARD_SELECTED_PROJECTS_CHANGED = "DASHBOARD_SELECTED_PROJECTS_CHANGED";
export const DASHBOARD_TIMESTAMP_CHANGED = "DASHBOARD_TIMESTAMP_CHANGED";
export const DASHBOARD_GLOBAL_TIMESTAMP_CHANGED = "DASHBOARD_GLOBAL_TIMESTAMP_CHANGED";
export const DASHBOARD_GLOBAL_LOADED_CHANGED = "DASHBOARD_GLOBAL_LOADED_CHANGED";
export const DASHBOARD_SHOW_PROJECT_WITHOUT_VA_POPUP = "DASHBOARD_SHOW_PROJECT_WITHOUT_VA_POPUP";
export const DASHBOARD_HIDE_PROJECT_WITHOUT_VA_POPUP = "DASHBOARD_HIDE_PROJECT_WITHOUT_VA_POPUP";
export const DASHBOARD_CHANGE_TABLE = "DASHBOARD_CHANGE_TABLE";
export const DASHBOARD_ADD_ERROR = "DASHBOARD_ADD_ERROR";
export const DASHBOARD_CHANGE_EXPIRED_COUNT = "DASHBOARD_CHANGE_EXPIRED_COUNT";
export const DASHBOARD_CHANGE_TOO_MANY_WD_COUNT = "DASHBOARD_CHANGE_TOO_MANY_WD_COUNT";
export const DASHBOARD_SHOW_TASKS_ERRORS_POPUP = "DASHBOARD_SHOW_TASKS_ERRORS_POPUP";
export const DASHBOARD_HIDE_TASKS_ERRORS_POPUP = "DASHBOARD_HIDE_TASKS_ERRORS_POPUP";
export const DASHBOARD_SHOW_NOTIFICATIONS_ERRORS_POPUP = "DASHBOARD_SHOW_NOTIFICATIONS_ERRORS_POPUP";
export const DASHBOARD_HIDE_NOTIFICATIONS_ERRORS_POPUP = "DASHBOARD_HIDE_NOTIFICATIONS_ERRORS_POPUP";
export const DASHBOARD_HIDE_FA411_POPUP = "DASHBOARD_HIDE_FA411_POPUP";
export const DASHBOARD_SHOW_FA411_POPUP = "DASHBOARD_SHOW_FA411_POPUP";
export const DASHBOARD_PROJECTS_LOADING = "DASHBOARD_PROJECTS_LOADING";
export const DASHBOARD_PROJECTS_LOADED = "DASHBOARD_PROJECTS_LOADED";
export const DASHBOARD_COMPANIES_LOADING = "DASHBOARD_COMPANIES_LOADING";
export const DASHBOARD_COMPANIES_LOADED = "DASHBOARD_COMPANIES_LOADED";
export const DASHBOARD_PROJECT_UPDATED = "DASHBOARD_PROJECT_UPDATED";