/**
 * @author Alexei Soloviev <sa@ieskr.ru>
 * @description Geovis map section layer with data
 * @updated by Vyacheslav Skripin <vs@ieskr.ru> 27.07.2023
 */
import { useEffect, useState } from 'react';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../helpers/GeovisHooks';
import { fetchServerElements } from '../../../../helpers/ProjectDataHelper';
import { elementsToMap } from '../../../../helpers/StorageHelper';
import { ProjectDXFLayerInfo } from '../../../../server/ProjectDXFLayerInfo';
import ServerRoutesGen from '../../../../server/Routes/ServerRoutesGen';
import Logger from '../../../../services/Logger';
import { IDXFLayersStorage } from '../../../../store/data.types';
import GeovisGeoJSONLayers from '../../../map/GeoJSONLayers';
import { dxfLayersStorageInitialState } from '../../../../store/reducers/dataReducer';
import { processFetchedData } from '../../../../store/helpers/DataHelper';

const LoggerSource = "MapSectionGeoJSONLayerData";

interface IComponentProps extends IWithGeovisServerProps {
    projectId: number;
    layerId: string;
    userId?: string;
    userToken?: string;
}

/**
 * Geovis Map Section GeoJSON data layer with loading data from Server
 * @param param0 
 * @returns 
 */
export const GeovisMapSectionGeoJSONLayer = withGeovisServer(({ Server, projectId, layerId, userId, userToken }: IComponentProps) => {

    const [dxfLayersStorage, setDxfLayersStorage] = useState<IDXFLayersStorage>({ ...dxfLayersStorageInitialState });

    useEffect(() => {
        if (!layerId) {
            return;
        }

        (async function loadDxfLayersConfig() {
            const isPdfPrintingMode = userToken && userId;

            const route = isPdfPrintingMode
                ? ServerRoutesGen.ReportPdfRenderData.GetDXFLayerInfo.patch(projectId, userToken, userId, layerId)
                : ServerRoutesGen.DxfLayers.GetDXFLayerInfo.patch(projectId, layerId);

            const result = await fetchServerElements<ProjectDXFLayerInfo>(Server, route);

            setDxfLayersStorage(processFetchedData(result, dxfLayersStorage, dxfLayersStorageInitialState, l => ({ dxfLayers: elementsToMap([l]) })));
        })();

    }, [projectId, layerId])

    if (!layerId) {
        return null;
    }

    Logger.render(`layerId=${layerId}`, LoggerSource);

    return (
        <GeovisGeoJSONLayers
            projectId={projectId}
            dxfLayersStorage={dxfLayersStorage}
        />
    )
});