import Button from '@atlaskit/button';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear'
import Textfield from "@atlaskit/textfield";
import { debounce } from "lodash";
import { SyntheticEvent, useRef } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { t } from "../../../../i18n";
import { elementsMigrationsFilterChanged, elementsMigrationsShowMigrateAll } from '../../../../store/creators/elementsMigrationsCreator';
import { IGeovisStoreState } from "../../../../store/store.types";
import { IGeovisAction } from "../../../../store/types";
import { ChangeAllProjectsType } from './types';

interface IElementsStateProps {
    projectNameFilter: string;
}

interface IElementDispatchProps {
    onFilterChanged: (filterString: string) => void;
    onChangeAll: (type: ChangeAllProjectsType) => void;
}

interface IElementProps extends IElementsStateProps, IElementDispatchProps {

}

const ProjectsElementsMigrationsFilter = ({
    onFilterChanged,
    projectNameFilter,
    onChangeAll
}: IElementProps) => {

    const filterRef = useRef<HTMLInputElement>(null);

    const onFilterChangedDebounce = debounce((newPhrase) => onFilterChanged(newPhrase), 400);

    const onFilterChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onFilterChangedDebounce(event.currentTarget.value);
    }

    const onClearFilter = () => {
        if (filterRef && filterRef.current) {
            filterRef.current.value = "";
        }
        onFilterChanged("");
    }

    const onMigrateAll = () => {
        onChangeAll("migrate");
    }

    const onRollbackAll = () => {
        onChangeAll("rollback");
    }

    const onRemoveOldAll = () => {
        onChangeAll("removeOld");
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ width: '60%' }}>
                <Textfield
                    defaultValue={projectNameFilter}
                    placeholder={t("Filter by project names")}
                    onChange={onFilterChangeHandler}
                    ref={filterRef}
                    isCompact={true}
                    elemAfterInput={
                        <Button
                            iconBefore={<SelectClearIcon label='clear' size='small' primaryColor='gray' />}
                            onClick={onClearFilter}
                            appearance='subtle-link'
                        />
                    }
                />
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                <div style={{ margin: '5px' }}>
                    <Button
                        onClick={onMigrateAll}
                        appearance={'primary'}
                    >
                        {t("Migrate all projects")}
                    </Button>
                </div>
                <div style={{ margin: '5px' }}>
                    <Button
                        onClick={onRollbackAll}
                    >
                        {t("Rollback all projects")}
                    </Button>
                </div>
                <div style={{ margin: '5px' }}>
                    <Button
                        onClick={onRemoveOldAll}
                        appearance={'danger'}
                    >
                        {t("Remove old for all projects")}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ projectElementsMigrations }: IGeovisStoreState): IElementsStateProps => ({
    projectNameFilter: projectElementsMigrations.projectNameFilter
})

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IElementDispatchProps => ({
    onFilterChanged: (filterString) => dispatch(elementsMigrationsFilterChanged(filterString)),
    onChangeAll: (type) => dispatch(elementsMigrationsShowMigrateAll(type))
})

export default connect<IElementsStateProps, IElementDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsElementsMigrationsFilter)