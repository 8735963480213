import { t } from "../../../../i18n";
import { ChartType } from "../../../../server/AVTService/TypeLibrary/Common/ChartType";
import { GeovisChartAxisSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { VibrationChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { SensorCategory } from "../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { ChartBooleanSettingProps, CustomerChangeableChartBooleanSetting } from "../ChartTab_tools";
import { GeovisChartAxisSettingsControl } from "../GeovisChartAxisSettingsControl";
import { onChartPropertyChangedFunc } from "../tools";
import { IGeovisChartPropertyChangedProps, IGeovisChartSensorsPropertyProps } from "../types";

interface IVibrationChartYAxisTab extends IGeovisChartPropertyChangedProps<VibrationChartModel>, IGeovisChartSensorsPropertyProps {
    chart: VibrationChartModel;
    isEventAxes: boolean;
}

/**
 * TimeValue chart Y-axis settings
 * @param props ITimeValueChartYAxisTab
 * @returns 
 */
export const VibrationChartYAxisTab = ({ chart, onChartPropertyChanged, isEventAxes }: IVibrationChartYAxisTab) => {

    const { LeftYAxisSettings, RightYAxisSettings, LeftEventAxisSettings, RightEventAxisSettings, ShowFrequencyScaling, ShowDominantFreq } = chart;

    const onAxisPropertyChangedHandler = (axisPropertyName: keyof VibrationChartModel, axisSettings: GeovisChartAxisSettings) => (propertyName: keyof GeovisChartAxisSettings, value: any) => {

        const changedAxisSettings: Partial<GeovisChartAxisSettings> = { ...axisSettings };
        changedAxisSettings[propertyName] = value;

        onChartPropertyChanged(axisPropertyName, changedAxisSettings);
    }

    const onAxisPropertiesChangedHandlerFunc = (axisPropertyName: keyof VibrationChartModel, axisSettings: GeovisChartAxisSettings) => (properties: Partial<GeovisChartAxisSettings>) => {
        const changedAxisSettings: GeovisChartAxisSettings = { ...axisSettings, ...properties };

        onChartPropertyChanged<GeovisChartAxisSettings>(axisPropertyName, changedAxisSettings);
    }

    const onChangeFunc = onChartPropertyChangedFunc(onChartPropertyChanged);

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={{ width: '49%' }}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t("Velocity scaling")}</div>
                    <GeovisChartAxisSettingsControl
                        chartType={ChartType.VibrationDiagramm}
                        onAxisPropertiesChanged={isEventAxes
                            ? onAxisPropertiesChangedHandlerFunc("LeftEventAxisSettings", LeftEventAxisSettings)
                            : onAxisPropertiesChangedHandlerFunc("LeftYAxisSettings", LeftYAxisSettings)}
                        onAxisPropertyChanged={isEventAxes
                            ? onAxisPropertyChangedHandler("LeftEventAxisSettings", LeftEventAxisSettings)
                            : onAxisPropertyChangedHandler("LeftYAxisSettings", LeftYAxisSettings)
                        }
                        axisSettings={isEventAxes ? LeftEventAxisSettings : LeftYAxisSettings}
                        sensorTypesToSelect={[SensorCategory.VibrationSensor]}
                        isXAxis={false}
                        isRightAxis={false}
                        hideTypeOfSensor={true}
                        isEventChart={isEventAxes}
                        isDisabled={false}
                    />
                </div>

                <div className="flexCellContainer_g1 separator">
                    {/** */}
                </div>

                <div className="flexCellContainer" style={{ width: '49%' }}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t("Frequency scaling")}</div>
                    <GeovisChartAxisSettingsControl
                        chartType={ChartType.VibrationDiagramm}
                        onAxisPropertiesChanged={isEventAxes
                            ? onAxisPropertiesChangedHandlerFunc("RightEventAxisSettings", RightEventAxisSettings)
                            : onAxisPropertiesChangedHandlerFunc("RightYAxisSettings", RightYAxisSettings)}
                        onAxisPropertyChanged={isEventAxes
                            ? onAxisPropertyChangedHandler("RightEventAxisSettings", RightEventAxisSettings)
                            : onAxisPropertyChangedHandler("RightYAxisSettings", RightYAxisSettings)
                        }
                        axisSettings={isEventAxes ? RightEventAxisSettings : RightYAxisSettings}
                        sensorTypesToSelect={[SensorCategory.Frequency]}
                        isXAxis={false}
                        isRightAxis={true}
                        hideTypeOfSensor={true}
                        isEventChart={isEventAxes}
                        isDisabled={(!ShowFrequencyScaling && !isEventAxes) || (!ShowDominantFreq && isEventAxes)}
                    />
                </div>
            </div>
            <div style={{ marginTop: '15px' }}>
                <fieldset style={{ border: '1px #ccc solid' }}>
                    {!isEventAxes && <legend style={{ fontSize: '16px' }}>{t("Main chart options")}</legend>}
                    {!isEventAxes &&
                        <div className="flexRowContainer" style={{ flexDirection: 'column' }}>
                            <div className="flexRowContainer">
                                <ChartBooleanSettingProps
                                    label={t("Show frequency scaling")}
                                    isChecked={chart.ShowFrequencyScaling}
                                    onChange={onChangeFunc("ShowFrequencyScaling")}
                                    isDisabled={false} />
                            </div>
                            <div className="flexRowContainer">
                                <CustomerChangeableChartBooleanSetting
                                    settingLabel={t("Show background data")}
                                    setting={chart.ShowBackgroundData}
                                    onChange={onChangeFunc("ShowBackgroundData")}
                                    isDisabled={false}
                                />
                            </div>
                            <div className="flexRowContainer">
                                <CustomerChangeableChartBooleanSetting
                                    settingLabel={t("Show event graph")}
                                    setting={chart.ShowEventGraph}
                                    onChange={onChangeFunc("ShowEventGraph")}
                                    isDisabled={false}
                                />
                            </div>
                            <div className="flexRowContainer">
                                <ChartBooleanSettingProps
                                    label={t("Show start of measurements")}
                                    isChecked={chart.ShowStartMeasurements}
                                    onChange={onChangeFunc("ShowStartMeasurements")}
                                    isDisabled={false} />
                            </div>
                        </div>
                    }
                    {isEventAxes && <legend style={{ fontSize: '16px' }}>{t("Event chart options")}</legend>}
                    {isEventAxes &&
                        <div className="flexRowContainer" style={{ flexDirection: 'column' }}>
                            <div className="flexRowContainer">
                                <ChartBooleanSettingProps
                                    label={t("Show occurrence charts (only available for Walesch vibration devices)")}
                                    isChecked={chart.ShowOccurrence}
                                    onChange={onChangeFunc("ShowOccurrence")}
                                    isDisabled={false} />
                            </div>

                            <div className="flexRowContainer">
                                <ChartBooleanSettingProps
                                    label={t("Show the dominant frequency in the eventcharts")}
                                    isChecked={chart.ShowDominantFreq}
                                    onChange={onChangeFunc("ShowDominantFreq")}
                                    isDisabled={false} />
                            </div>

                        </div>
                    }
                </fieldset>
            </div>
        </div>
    )
}