/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 21.03.2022
 * @description Check connection to map tiles service directly from GEOvis4 Client
 */

import { useState } from 'react';
import { MAP_TILES_API_ROOT } from '../ApiConfig';
import Logger from '../services/Logger';



export const CheckMapTilesConnectionComponent = () => {

    const [showFrame, setShowFrame] = useState<boolean>(true);

    const onLoadHandler = () => {
        setShowFrame(false);
        Logger.trace('MapTiles frame has been hidden');
    }

    return (
        <div style={{ display: 'none' }}>
            {/*  */}
            {showFrame && (
                <iframe
                    height='100%'
                    width='100%'
                    sandbox="allow-scripts allow-same-origin allow-forms allow-downloads"
                    src={MAP_TILES_API_ROOT}
                    onLoad={onLoadHandler}
                />
            )}
        </div>
    )
}