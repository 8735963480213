import { getPhysicalUnitShortName, PhysicalUnit } from "../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";

export interface IMapSectionScale {
    pixels: number;
    label: string
}

const getRoundNum = (num: number) => {
    const pow10 = Math.pow(10, (Math.floor(num) + '').length - 1);
    let d = num / pow10;

    d = d >= 10 ? 10 :
        d >= 5 ? 5 :
            d >= 3 ? 3 :
                d >= 2 ? 2 : 1;

    return pow10 * d;
}

const getScaleLabel = (distance: number, scale: number, unit: string): string => {
    if (distance < 1) {
        let dimension = 1;
        if (distance < 0.1) {
            dimension = 0.1;
        }
        if (distance < 0.01) {
            dimension = 0.01;
        }
        return `< ${dimension} ${unit}`;
    }
    return `${scale} ${unit}`;
}

const getMapSectionScale = (leafletElement: L.Map, latLng1: L.LatLng, latLng2: L.LatLng, scalingRate: number, maxPixelSize: number): IMapSectionScale => {
    const distanceInMeters = leafletElement.distance(latLng1, latLng2) / (scalingRate * 1000);

    const coefficient = distanceInMeters >= 1 ? 1 : 1000;
    const unit = distanceInMeters >= 1 ? "m" : "mm";
    // if (distanceInMeters < 0.01) {
    //     coefficient = 1000;
    //     unit = "mm";
    // }
    // else if (distanceInMeters < 1) {
    //     coefficient = 100;
    //     unit = "cm";
    // }

    const convertedDistance = distanceInMeters * coefficient;
    const meters = getRoundNum(convertedDistance);

    const ratio = meters / convertedDistance;
    const pixels = Math.round(maxPixelSize * ratio);

    return {
        pixels: Math.min(pixels, maxPixelSize),
        label: getScaleLabel(convertedDistance, meters, unit)
    }
}

export const getMapSectionXScale = (leafletElement: L.Map, scalingRate: number): IMapSectionScale => {
    const maxWidthInPixel = 50;

    const centerLatLng = leafletElement.getCenter();
    const pointC = leafletElement.latLngToContainerPoint(centerLatLng);
    const pointX = L.point(pointC.x + maxWidthInPixel, pointC.y);

    return getMapSectionScale(leafletElement, leafletElement.containerPointToLatLng(pointC), leafletElement.containerPointToLatLng(pointX), scalingRate, maxWidthInPixel);
}

export const getMapSectionXScaleProfileAndSideViews = (leafletElement: L.Map, scalingRate: number, leafletUnitsPerMeter: number): IMapSectionScale => {
    const maxWidthInPixel = 70;

    const centerLatLng = leafletElement.getCenter();
    const pointC = leafletElement.latLngToContainerPoint(centerLatLng);
    const pointY = L.point(pointC.x, pointC.y + maxWidthInPixel);

    const distanceInLeafletUnits = leafletElement.distance(leafletElement.containerPointToLatLng(pointC), leafletElement.containerPointToLatLng(pointY));

    const maximumWidthInMetersOfMap = distanceInLeafletUnits / leafletUnitsPerMeter;
    const maximumVectorLengthInMM = maximumWidthInMetersOfMap / scalingRate;

    let coefficient = 1;
    let unit = getPhysicalUnitShortName(PhysicalUnit.Millimeter);

    if (maximumVectorLengthInMM > 10) {
        coefficient = 0.1;
        unit = getPhysicalUnitShortName(PhysicalUnit.Centimeter);
    }
    else if (maximumVectorLengthInMM > 1000) {
        coefficient = 0.001;
        unit = getPhysicalUnitShortName(PhysicalUnit.Meter);
    }

    const length = maximumVectorLengthInMM * coefficient;
    const meters = getRoundNum(length);

    const ratio = meters / length;
    const pixels = Math.round(maxWidthInPixel * ratio);

    return ({
        label: getScaleLabel(length, meters, unit),
        pixels: Math.min(pixels, maxWidthInPixel)
    })
}

export const getMapSectionYScale = (leafletElement: L.Map, scalingRate: number): IMapSectionScale => {
    const maxHeightInPixel = 40;

    const centerLatLng = leafletElement.getCenter();
    const pointC = leafletElement.latLngToContainerPoint(centerLatLng);
    const pointY = L.point(pointC.x, pointC.y + maxHeightInPixel);

    return getMapSectionScale(leafletElement, leafletElement.containerPointToLatLng(pointC), leafletElement.containerPointToLatLng(pointY), scalingRate, maxHeightInPixel);
}