export const LOGBOOK_ENTRY_IMAGES_INIT = "LOGBOOK_ENTRY_IMAGES_INIT";
export const LOGBOOK_ENTRY_IMAGES_LOADED = "LOGBOOK_ENTRY_IMAGES_LOADED";

export const LOGBOOK_ENTRY_IMAGE_UPLOAD_STARTED = "LOGBOOK_ENTRY_IMAGE_UPLOAD_STARTED";
export const LOGBOOK_ENTRY_IMAGE_UPLOAD_PROGRESS = "LOGBOOK_ENTRY_IMAGE_UPLOAD_PROGRESS";
export const LOGBOOK_ENTRY_IMAGE_UPLOAD_FINISHED = "LOGBOOK_ENTRY_IMAGE_UPLOAD_FINISHED";
export const LOGBOOK_ENTRY_IMAGE_UPLOAD_ERROR = "LOGBOOK_ENTRY_IMAGE_UPLOAD_ERROR";

export const LOGBOOK_ENTRY_IMAGES_SHOW_GALLERY = "LOGBOOK_ENTRY_IMAGES_SHOW_GALLERY";
export const LOGBOOK_ENTRY_IMAGES_CLOSE_GALLERY = "LOGBOOK_ENTRY_IMAGES_CLOSE_GALLERY";

export const LOGBOOK_ENTRY_IMAGE_DELETE = "LOGBOOK_ENTRY_IMAGE_DELETE";