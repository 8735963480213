import { Reducer } from 'redux';
import { v4 } from 'uuid';
import { ProjectLogoKind } from '../../components/logosManager/types';
import { MaxProfileViewRenderZoomLevel } from '../../components/map/types';
import { sortSensorData } from '../../components/projectOverview/data/sensors/tools';
import {
    addOrUpdateElementInMap,
    deleteElementOfMap,
    elementsToMap,
    mapToListOfElements,
    updateElementPropertiesInMap,
    updateElementsPropertiesInMap
} from '../../helpers/StorageHelper';
import { GEOvisDXFLayerType } from '../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType';
import { ProjectElementType } from '../../server/AVTService/TypeLibrary/Common/ProjectElementType';
import { getReportElementTypeToName, ReportElementType } from '../../server/AVTService/TypeLibrary/Common/ReportElementType';
import { GeovisReportElementInfoSlim } from '../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfoSlim';
import { ProjectViewConfig } from '../../server/AVTService/TypeLibrary/ProjectViews/ProjectViewConfig';
import { LocalMapObjectText } from '../../server/LocalMapObjectText';
import {
    PROJECT_DATA_PROPERTIES_DATA,
    PROJECT_DATA_PROPERTIES_ERROR,
    PROJECT_DATA_REPORTS,
    PROJECT_DATA_REPORTS_DATA,
    PROJECT_DATA_USERS_UPDATE,
    PROJECT_ELEMENT_ENTRY_ADDED,
    PROJECT_ELEMENT_ENTRY_REMOVED,
    PROJECT_ELEMENT_ENTRY_UPDATED
} from '../actions/dataActions';
import { PROJECTS_OVERVIEW } from '../actions/navigationActions';
import {
    LOGO_EDIT_HIDE_LOGO_PICKER,
    LOGO_EDIT_SET_LOGO_TO_PROJECT,
    LOGO_EDIT_SET_SELECTED_LOGO,
    LOGO_EDIT_SHOW_LOGO_PICKER,
    LOGO_EDIT_UPLOAD_END,
    LOGO_EDIT_UPLOAD_ERROR,
    LOGO_EDIT_UPLOAD_PROCESSING,
    LOGO_EDIT_UPLOAD_STARTED,
    PROJECT_EDIT_ACCESS_CONTROL_HIDE_EXPORT_USERS_DIALOG,
    PROJECT_EDIT_ACCESS_CONTROL_HIDE_USERS_DIALOG,
    PROJECT_EDIT_ACCESS_CONTROL_HIDE_USER_DIALOG,
    PROJECT_EDIT_ACCESS_CONTROL_SHOW_EXPORT_USERS_DIALOG,
    PROJECT_EDIT_ACCESS_CONTROL_SHOW_USERS_DIALOG,
    PROJECT_EDIT_ACCESS_CONTROL_SHOW_USER_DIALOG,
    PROJECT_EDIT_ATTACHED_DOCUMENTS_SWITCH_ROWS_PER_PAGE,
    PROJECT_EDIT_ATTACHED_DOCUMENT_CLEANUP_UPLOADING_PROGRESS,
    PROJECT_EDIT_ATTACHED_DOCUMENT_DELETE_DIALOG,
    PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_DIALOG,
    PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_SENSORS_SELECT_DIALOG,
    PROJECT_EDIT_ATTACHED_DOCUMENT_SHOW_SENSORS_SELECT_DIALOG,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_END,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_ERROR,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_END,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_START,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_PROCESSING,
    PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_START,
    PROJECT_EDIT_DXF_DELETE_DIALOG,
    PROJECT_EDIT_DXF_DELETING_FILE_MODEL,
    PROJECT_EDIT_DXF_HIDE_DIALOGS,
    PROJECT_EDIT_DXF_REBUILD_MAP_TILES_DIALOG,
    PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MAX_ZOOM,
    PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MIN_ZOOM,
    PROJECT_EDIT_DXF_REBUILD_MAP_TILES_SEGMENT_LENGTH,
    PROJECT_EDIT_DXF_UPLOAD_DIALOG,
    PROJECT_EDIT_DXF_UPLOAD_DIALOG_TYPE,
    PROJECT_EDIT_DXF_UPLOAD_FILES_STATE,
    PROJECT_EDIT_EDIT_DATABASE_HIDE,
    PROJECT_EDIT_EDIT_DATABASE_SHOW,
    PROJECT_EDIT_GEORASTER_CHANGE_UPLOAD_STATE,
    PROJECT_EDIT_GEORASTER_HIDE_DIALOGS,
    PROJECT_EDIT_GEORASTER_SHOW_REMOVE_DIALOG,
    PROJECT_EDIT_LABELS_DELETE_HIDE,
    PROJECT_EDIT_LABELS_DELETE_SHOW,
    PROJECT_EDIT_LABELS_EDIT_HIDE,
    PROJECT_EDIT_LABELS_EDIT_SHOW,
    PROJECT_EDIT_LABELS_HIDE,
    PROJECT_EDIT_LABELS_SHOW, PROJECT_EDIT_LOCAL_MAP_OBJECTS_CHOOSE_COORDINATES_FOR,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_CLEANUP_EDIT_STATE,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_CREATE_NEW_ELEMENT,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_EDIT,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_SEARCH_QUERY,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_SET_VIEW,
    PROJECT_EDIT_LOCAL_MAP_OBJECTS_SHOW_DELETE_DIALOG,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_ADD,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_START_EDITING_OBJECTS,
    PROJECT_EDIT_LOCAL_MAP_OBJECT_STOP_EDITING_OBJECTS,
    PROJECT_EDIT_OVERVIEW_CHANGED,
    PROJECT_EDIT_PROPERTIES_INIT_STATE,
    PROJECT_EDIT_PROPERTIES_SAVING,
    PROJECT_EDIT_REMOVE_DATABASE_HIDE,
    PROJECT_EDIT_REMOVE_DATABASE_SHOW,
    PROJECT_EDIT_REMOVE_PROJECT,
    PROJECT_EDIT_REMOVE_PROJECT_HIDE,
    PROJECT_EDIT_REMOVE_PROJECT_SHOW,
    PROJECT_EDIT_REPORT_ADD_OR_UPDATE,
    PROJECT_EDIT_REPORT_ADD_TO_GROUPS_DIALOG_SHOW,
    PROJECT_EDIT_REPORT_ELEMENTS_LOADED,
    PROJECT_EDIT_REPORT_ELEMENTS_LOADING,
    PROJECT_EDIT_REPORT_HIDE_ANY_DIALOG,
    PROJECT_EDIT_REPORT_REMOVED,
    PROJECT_EDIT_REPORT_SHOW_COPY_DIALOG,
    PROJECT_EDIT_REPORT_SHOW_REMOVE_DIALOG,
    PROJECT_EDIT_REPORT_UPDATE_ELEMENT_ENTRY,
    PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION,
    PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION_OF_ALL,
    PROJECT_EDIT_SENSORS_DATA_CHANGE_SORT,
    PROJECT_EDIT_SENSORS_DATA_DATABASES_SELECTED,
    PROJECT_EDIT_SENSORS_DATA_EDIT_RECORD,
    PROJECT_EDIT_SENSORS_DATA_FILTER_CHANGED,
    PROJECT_EDIT_SENSORS_DATA_HIDE_EDIT_DIALOG,
    PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG,
    PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG_AND_RELOAD,
    PROJECT_EDIT_SENSORS_DATA_LOADED,
    PROJECT_EDIT_SENSORS_DATA_LOADING,
    PROJECT_EDIT_SENSORS_DATA_RELOAD,
    PROJECT_EDIT_SENSORS_DATA_REMOVE_RECORD,
    PROJECT_EDIT_SENSORS_DATA_SHOW_REMOVE_ATTRIBUTES_DIALOG,
    PROJECT_EDIT_SENSORS_DATA_UPDATE_RECORD,
    PROJECT_EDIT_SENSORS_LIST_FILTER_LOADED,
    PROJECT_EDIT_SENSORS_LIST_FILTER_LOADING,
    PROJECT_EDIT_SENSORS_LIST_FILTER_CHANGED,
    PROJECT_EDIT_SENSORS_LIST_LOADED,
    PROJECT_EDIT_SENSORS_LIST_LOADING,
    PROJECT_EDIT_VIEWS_CONFIG,
    PROJECT_EDIT_VIEWS_CONFIG_DATA,
    PROJECT_EDIT_VIEWS_CONFIG_ERROR,
    PROJECT_EDIT_VIEWS_HIDE_DIALOGS, PROJECT_EDIT_VIEW_ADD_OR_UPDATE,
    PROJECT_EDIT_VIEW_CHANGE_PUBLIC,
    PROJECT_EDIT_VIEW_CHANGE_USERS,
    PROJECT_EDIT_VIEW_DELETE,
    PROJECT_EDIT_VIEW_DELETE_DIALOG,
    PROJECT_EDIT_VIEW_SET_IS_DEFAULT_EXPANDED,
    PROJECT_EDIT_VIEW_UPDATE_PARENT_INFO, PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_HIDE,
    PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_SHOW,
    PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_HIDE,
    PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_SHOW, PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_HIDE,
    PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_SHOW,
    PROJECT_ELEMENT_COPY_DIALOG_SHOW,
    PROJECT_ELEMENT_HIDE_ELEMENT_DIALOG, PROJECT_ELEMENT_REMOVE_DIALOG_SHOW,
    PROJECT_EDIT_SENSORS_MANUAL_IMPORT_RELOAD
} from '../actions/projectEditActions';
import { IGeovisProjectDataAction } from '../data.types';
import {
    IGeovisProjectEditAction,
    IGeovisProjectEditState,
    IGeovisReportElementsStorage,
    IGvSensorDataInfoModel,
    IProjectEditSensorsManualImportTasksState,
    IProjectEditSensorsDataRecordsState,
    IProjectEditSensorsDataStorage,
    IProjectEditSensorsFilterStorage,
    IProjectElementsState,
    IProjectPropertiesEditState,
    IProjectViewsEditState
} from "../edit.types";
import {
    updateAttachedFilesUploadingState
} from '../helpers/AttachedDocumentsHelper';
import { processFetchedData } from '../helpers/DataHelper';
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from '../types';
import { GeovisUploadFileState } from '../uploading.types';
import { GeovisSensorsDataFilter } from '../../server/AVTService/TypeLibrary/DB/ArgusDataStorage/GeovisSensorsDataFilter';
import { ExportDataType } from '../../server/AVTService/TypeLibrary/Export/ExportDataType';
import { GeovisSensorListFilterModel } from '../../server/AVTService/TypeLibrary/Model/GeovisSensorListFilterModel';

const projectViewsEditInitialState: IProjectViewsEditState = {
    // createView: false,
    deleteView: false,
    // editView: false,
    viewId: '',
    viewsConfigStorage: {
        ...defaultSomethingStorageState,
        projectViews: new Map<string, ProjectViewConfig>()
    }
}

const projectElementsInitialState: IProjectElementsState = {
    showCopyDialog: false,
    showDeleteDialog: false,
    showDeleteGroupDialog: false,
    showEditGroupDialog: false,
    showAddToGroupsDialog: false,
    elementId: 0,
    elementType: ProjectElementType.Unknown,
    groupName: '',
    removeFromGroupOnly: false
}

const projectEditReportsElementsStorageInitialState: IGeovisReportElementsStorage = {
    ...defaultSomethingStorageState,
    charts: new Map<number, GeovisReportElementInfoSlim>(),
    headers: new Map<number, GeovisReportElementInfoSlim>(),
    logbooks: new Map<number, GeovisReportElementInfoSlim>(),
    comments: new Map<number, GeovisReportElementInfoSlim>(),
    mapSections: new Map<number, GeovisReportElementInfoSlim>(),
    tables: new Map<number, GeovisReportElementInfoSlim>(),
    geovisImages: new Map<number, GeovisReportElementInfoSlim>()
}

const projectEditSensorsStorageInitialState: IProjectEditSensorsDataStorage = {
    ...defaultSomethingStorageState,
    sensorsData: {
        AllSensorsCount: 0,
        PageSensors: []
    }
};

const projectEditSensorsManualImportTasksInitialState: IProjectEditSensorsManualImportTasksState = {
    ...defaultSomethingStorageState,
    watchTasksTimestamp: Date.now()
}

const projectEditSensorsFilterStorageInitialState: IProjectEditSensorsFilterStorage = {
    ...defaultSomethingStorageState,
    databases: [],
    physicalUnits: [],
    sensorTypes: []
}

export const sensorFilterInitState: GeovisSensorListFilterModel = {
    Ascending: true,
    Categories: [],
    CountLinesOnPage: 50,
    DatabasesIds: [],
    Name: '',
    PageNumber: 1,
    SortKey: 'Id',
    States: [],
    Units: [],
    IsPublic: undefined,
    VirtualOnly: undefined
}

export const projectEditInitialState: IGeovisProjectEditState = {
    type: "",
    showRemoveProjectDialog: false,
    projectEditState: {
        isPostInProgress: false,
        companyLogoId: '',
        isCompanyLogoDirty: false,
        userLogoId: '',
        isUserLogoDirty: false,
        logoKind: ProjectLogoKind.Company,
        selectedLogoId: '',
        showSelectLogoPicker: false,
        uploadingFiles: []
    },
    localMapObjectsEditState: {
        localMapObjectProps: new LocalMapObjectText(),
        chooseCoordinatesFor: undefined,
        isNewLocalMapObject: false,
        searchQuery: '',
        showDeleteDialog: false,
        showEditDialog: false,
        localMapObjectsViewValue: 'map',
        lmoId: '',
        dxfLayerId: '',
        isEditMode: false
    },
    attachedDocumentsEditState: {
        uploadingFiles: [],
        documentId: '',
        showDeleteDialog: false,
        showSelectSensorsDialog: false,
        isUploadingNewVersion: false,
        rowsPerPage: 25
    },
    projectViewEditState: { ...projectViewsEditInitialState },
    dxfLayersEditState: {
        layerId: '',
        selectedCoordinateSystem: undefined,
        showDeleteDialog: false,
        showUploadDialog: false,
        showRebuildMapTilesDialog: false,
        uploadFilesState: [],
        deletingFileModel: false,
        rebuildMapTilesMaxZoom: 23,
        rebuildMapTilesMinZoom: 0,
        useSegmentLength: false,
        selectedDxfType: GEOvisDXFLayerType.Map,
        //showInOverview: true
    },
    accessControlEditState: {
        showSelectUsersDialog: false,
        targetRole: null,
        showEditUserDialog: false,
        showExportUsersDialog: false,
        userId: ''
    },
    projectEditLabelState: {
        labelId: '',
        showDeleteLabelDialog: false,
        showEditLabelDialog: false,
        showLabelsDialog: false
    },
    projectEditDatabasesState: {
        databaseId: '',
        showEditDialog: false,
        showRemoveDialog: false
    },
    sensorsDataState: {
        filter: {
            FullIds: [],
            States: [],
            AxisRecords: true,
            DataType: ExportDataType.Raw,
            RawRecords: true
        },
        databaseIds: [],
        recordsState: {
            ...defaultSomethingStorageState,
            data: {
                SensorDecimals: {},
                Records: [],
                ReadonlyMsts: []
            },
            sortKey: 'exact-time',
            sortOrder: 'DESC'
        },
        lastCheckedIndex: -1,
        editDataRecordUid: '',
        selectedSensorsData: [],
        showRemoveAttributesDialog: false
    },
    projectReportsState: {
        showCopyDialog: false,
        showRemoveDialog: false,
        showAddToGroupsDialog: false,
        reportId: 0,
        elementsStorage: projectEditReportsElementsStorageInitialState
    },
    projectElementsState: projectElementsInitialState,
    rasterEditState: {
        rasterId: "",
        showDeleteDialog: false,
        showAddDialog: false,
        progress: -1
    },
    sensorsListEditState: {
        sensorId: '',
        filterStorage: projectEditSensorsFilterStorageInitialState,
        sensorsStorage: projectEditSensorsStorageInitialState,
        manualTasksState: projectEditSensorsManualImportTasksInitialState,
        showEditDialog: false,
        filter: sensorFilterInitState
    }
};

const projectEditReducer: Reducer<IGeovisProjectEditState> = (
    state: IGeovisProjectEditState = projectEditInitialState,
    action: IGeovisProjectEditAction): IGeovisProjectEditState => {

    switch (action.type) {

        case PROJECTS_OVERVIEW:
            return { ...projectEditInitialState };

        // #region Project properties

        case PROJECT_EDIT_PROPERTIES_SAVING:
            return {
                ...state,
                projectEditState: {
                    ...state.projectEditState,
                    isPostInProgress: true
                }
            };

        case PROJECT_DATA_PROPERTIES_DATA: {

            const resultState: IGeovisProjectEditState = {
                ...state,
                projectEditState: {
                    ...state.projectEditState,
                    isPostInProgress: false
                }
            };

            const { project } = action as IGeovisProjectDataAction;
            if (!project) {
                return resultState;
            }

            resultState.projectEditState.companyLogoId = project.CompanyLogoId || '';
            resultState.projectEditState.userLogoId = project.UserLogoId || '';

            return resultState;
        }

        case PROJECT_DATA_PROPERTIES_ERROR: {
            return {
                ...state,
                projectEditState: {
                    ...state.projectEditState,
                    isPostInProgress: false
                }
            };
        }

        case PROJECT_EDIT_PROPERTIES_INIT_STATE: {
            if (!action.project) {
                return state;
            }

            const { project } = action;

            return {
                ...state,
                projectEditState: {
                    ...state.projectEditState,
                    isCompanyLogoDirty: false,
                    companyLogoId: project.CompanyLogoId,
                    isUserLogoDirty: false,
                    userLogoId: project.UserLogoId
                }
            };
        }

        case LOGO_EDIT_SHOW_LOGO_PICKER:
            if (action.logoKind === undefined) {
                return state;
            }

            return {
                ...state,
                projectEditState: {
                    ...state.projectEditState,
                    selectedLogoId: action.objectId || '',
                    logoKind: action.logoKind,
                    showSelectLogoPicker: true
                }
            }

        case LOGO_EDIT_SET_SELECTED_LOGO:
            if (!action.objectId) {
                return state;
            }

            return {
                ...state,
                projectEditState: {
                    ...state.projectEditState,
                    selectedLogoId: action.objectId
                }
            };

        case LOGO_EDIT_SET_LOGO_TO_PROJECT: {
            if (action.objectId === undefined || action.logoKind === undefined) {
                return state;
            }

            const projectEditState: IProjectPropertiesEditState = {
                ...state.projectEditState,
                showSelectLogoPicker: false,
                selectedLogoId: ''
            }

            switch (action.logoKind) {
                case ProjectLogoKind.Company:
                    projectEditState.companyLogoId = action.objectId;
                    projectEditState.isCompanyLogoDirty = true;
                    break;

                case ProjectLogoKind.User:
                    projectEditState.userLogoId = action.objectId;
                    projectEditState.isUserLogoDirty = true;
                    break;
            }

            return { ...state, projectEditState };
        }

        case LOGO_EDIT_HIDE_LOGO_PICKER:
            return {
                ...state,
                projectEditState: {
                    ...state.projectEditState,
                    selectedLogoId: '',
                    logoKind: ProjectLogoKind.Company,
                    showSelectLogoPicker: false
                }
            };

        case LOGO_EDIT_UPLOAD_STARTED:
        case LOGO_EDIT_UPLOAD_PROCESSING:
            if (!action.uploadingFiles) {
                return state;
            }

            return {
                ...state,
                projectEditState: {
                    ...state.projectEditState,
                    uploadingFiles: action.uploadingFiles.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f }))
                }
            };

        case LOGO_EDIT_UPLOAD_ERROR:
        case LOGO_EDIT_UPLOAD_END:
            if (!action.objectId || !action.logoFileInfo) {
                return state;
            }

            return {
                ...state,
                projectEditState: {
                    ...state.projectEditState,
                    uploadingFiles: state.projectEditState.uploadingFiles.filter(f => f.id !== action.objectId)
                }
            }

        // #endregion

        // #region DXF layers

        case PROJECT_EDIT_DXF_UPLOAD_FILES_STATE:
            if (action.uploadingFiles) {
                return {
                    ...state,
                    dxfLayersEditState: {
                        ...state.dxfLayersEditState,
                        //showInOverview: true,
                        selectedCoordinateSystem: undefined,
                        selectedDxfType: GEOvisDXFLayerType.Map,
                        uploadFilesState: action.uploadingFiles.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f }))
                    }
                }
            }
            break;

        case PROJECT_EDIT_DXF_UPLOAD_DIALOG:


            return {
                ...state,
                dxfLayersEditState: {
                    ...state.dxfLayersEditState,
                    showUploadDialog: true,
                    selectedCoordinateSystem: action.selectedCoordinateSystem === undefined ? state.dxfLayersEditState.selectedCoordinateSystem : action.selectedCoordinateSystem,
                    //showInOverview: action.booleanProperty === undefined ? state.dxfLayersEditState.showInOverview : action.booleanProperty,
                }
            }

        case PROJECT_EDIT_DXF_UPLOAD_DIALOG_TYPE:
            if (action.selectedDxfType === undefined) {
                return state;
            }
            return {
                ...state,
                dxfLayersEditState: {
                    ...state.dxfLayersEditState,
                    showUploadDialog: true,
                    selectedDxfType: action.selectedDxfType
                }
            }

        case PROJECT_EDIT_DXF_DELETE_DIALOG:
            if (!action.layerId) {
                return state;
            }

            return {
                ...state,
                dxfLayersEditState: {
                    ...state.dxfLayersEditState,
                    showDeleteDialog: true,
                    layerId: action.layerId,
                    deletingFileModel: false
                }
            };

        case PROJECT_EDIT_DXF_HIDE_DIALOGS:
            return {
                ...state,
                dxfLayersEditState: {
                    ...state.dxfLayersEditState,
                    showDeleteDialog: false,
                    showUploadDialog: false,
                    showRebuildMapTilesDialog: false,
                    layerId: '',
                    deletingFileModel: false,
                    //showInOverview: true,
                    selectedCoordinateSystem: undefined,
                    selectedDxfType: GEOvisDXFLayerType.Map
                }
            };

        case PROJECT_EDIT_DXF_REBUILD_MAP_TILES_DIALOG:
            if (!action.layerId) {
                return state;
            }

            return {
                ...state,
                dxfLayersEditState: {
                    ...state.dxfLayersEditState,
                    showRebuildMapTilesDialog: true,
                    layerId: action.layerId,
                    rebuildMapTilesMaxZoom: action.booleanProperty ? MaxProfileViewRenderZoomLevel : 23
                }
            }

        case PROJECT_EDIT_DXF_DELETING_FILE_MODEL:
            return {
                ...state,
                dxfLayersEditState: {
                    ...state.dxfLayersEditState,
                    deletingFileModel: true
                }
            }

        case PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MAX_ZOOM:
            if (action.zoom !== undefined) {
                return {
                    ...state,
                    dxfLayersEditState: {
                        ...state.dxfLayersEditState,
                        rebuildMapTilesMaxZoom: action.zoom
                    }
                }
            }
            break;

        case PROJECT_EDIT_DXF_REBUILD_MAP_TILES_MIN_ZOOM:
            if (action.zoom !== undefined) {
                return {
                    ...state,
                    dxfLayersEditState: {
                        ...state.dxfLayersEditState,
                        rebuildMapTilesMinZoom: action.zoom
                    }
                }
            }
            break;

        case PROJECT_EDIT_DXF_REBUILD_MAP_TILES_SEGMENT_LENGTH:
            return {
                ...state,
                dxfLayersEditState: {
                    ...state.dxfLayersEditState,
                    useSegmentLength: action.useSegmentLength || false
                }
            };


        // #endregion

        // #region Local map objects

        case PROJECT_EDIT_LOCAL_MAP_OBJECT_START_EDITING_OBJECTS:
            return {
                ...state,
                localMapObjectsEditState: {
                    ...state.localMapObjectsEditState,
                    isEditMode: true
                }
            };

        case PROJECT_EDIT_LOCAL_MAP_OBJECT_STOP_EDITING_OBJECTS:
            return {
                ...state,
                localMapObjectsEditState: {
                    ...state.localMapObjectsEditState,
                    isEditMode: false
                }
            };

        case PROJECT_EDIT_LOCAL_MAP_OBJECTS_SET_VIEW:
            return {
                ...state,
                localMapObjectsEditState: {
                    ...state.localMapObjectsEditState,
                    localMapObjectsViewValue: action.localMapObjectsViewValue!
                }
            };

        case PROJECT_EDIT_LOCAL_MAP_OBJECTS_CLEANUP_EDIT_STATE:
        case PROJECT_EDIT_LOCAL_MAP_OBJECT_ADD:
            return {
                ...state,
                localMapObjectsEditState: {
                    ...projectEditInitialState.localMapObjectsEditState,
                    localMapObjectsViewValue: state.localMapObjectsEditState.localMapObjectsViewValue,
                    searchQuery: state.localMapObjectsEditState.searchQuery,
                    dxfLayerId: state.localMapObjectsEditState.dxfLayerId
                }
            };

        case PROJECT_EDIT_LOCAL_MAP_OBJECTS_SHOW_DELETE_DIALOG:
            if (!action.lmoId) {
                return state;
            }

            return {
                ...state,
                localMapObjectsEditState: {
                    ...state.localMapObjectsEditState,
                    showDeleteDialog: true,
                    lmoId: action.lmoId
                }
            };

        case PROJECT_EDIT_LOCAL_MAP_OBJECTS_SEARCH_QUERY:
            return {
                ...state,
                localMapObjectsEditState: {
                    ...state.localMapObjectsEditState,
                    searchQuery: action.searchQuery || ''
                }
            };

        case PROJECT_EDIT_LOCAL_MAP_OBJECTS_CHOOSE_COORDINATES_FOR:
            return {
                ...state,
                localMapObjectsEditState: {
                    ...state.localMapObjectsEditState,
                    localMapObjectsViewValue: "map",
                    chooseCoordinatesFor: action.localMapObjectType
                }
            };

        case PROJECT_EDIT_LOCAL_MAP_OBJECTS_CREATE_NEW_ELEMENT:
            if (action.localMapObjectProps) {
                return {
                    ...state,
                    localMapObjectsEditState: {
                        ...state.localMapObjectsEditState,
                        isNewLocalMapObject: true,
                        showEditDialog: true,
                        localMapObjectProps: { ...action.localMapObjectProps },
                        searchQuery: state.localMapObjectsEditState.searchQuery,
                        chooseCoordinatesFor: undefined,
                        showDeleteDialog: false
                    }
                };
            }
            break;

        case PROJECT_EDIT_LOCAL_MAP_OBJECTS_EDIT:
            if (!action.lmoId) {
                return state;
            }

            return {
                ...state,
                localMapObjectsEditState: {
                    ...state.localMapObjectsEditState,
                    chooseCoordinatesFor: undefined,
                    isNewLocalMapObject: false,
                    searchQuery: state.localMapObjectsEditState.searchQuery,
                    showDeleteDialog: false,
                    showEditDialog: true,
                    lmoId: action.lmoId
                }
            }

        // #endregion

        // #region Attached documents

        case PROJECT_EDIT_ATTACHED_DOCUMENT_DELETE_DIALOG:
            if (!action.documentId) {
                return state;
            }

            return {
                ...state,
                attachedDocumentsEditState: {
                    ...state.attachedDocumentsEditState,
                    showDeleteDialog: true,
                    documentId: action.documentId
                }
            };

        case PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_DIALOG:
            return {
                ...state,
                attachedDocumentsEditState: {
                    ...state.attachedDocumentsEditState,
                    showDeleteDialog: false
                }
            };

        case PROJECT_EDIT_ATTACHED_DOCUMENT_SHOW_SENSORS_SELECT_DIALOG:
            if (!action.documentId) {
                return state;
            }
            return {
                ...state,
                attachedDocumentsEditState: {
                    ...state.attachedDocumentsEditState,
                    showSelectSensorsDialog: true,
                    documentId: action.documentId
                }
            }

        case PROJECT_EDIT_ATTACHED_DOCUMENT_HIDE_SENSORS_SELECT_DIALOG:
            return {
                ...state,
                attachedDocumentsEditState: {
                    ...state.attachedDocumentsEditState,
                    showSelectSensorsDialog: false
                }
            };


        case PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_START:
        case PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_END:
        case PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_ERROR:
        case PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_PROCESSING:
            return { ...state, attachedDocumentsEditState: updateAttachedFilesUploadingState(state.attachedDocumentsEditState, action) };

        case PROJECT_EDIT_ATTACHED_DOCUMENT_CLEANUP_UPLOADING_PROGRESS:
            return { ...state, attachedDocumentsEditState: { ...state.attachedDocumentsEditState, uploadingFiles: [] } };


        case PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_START:
            if (!action.documentId) {
                return state;
            }

            return {
                ...state,
                attachedDocumentsEditState: {
                    ...state.attachedDocumentsEditState,
                    isUploadingNewVersion: true,
                    documentId: action.documentId
                }
            };

        case PROJECT_EDIT_ATTACHED_DOCUMENT_UPLOAD_NEW_VERSION_END:
            return {
                ...state,
                attachedDocumentsEditState: {
                    ...state.attachedDocumentsEditState,
                    isUploadingNewVersion: false
                }
            };

        case PROJECT_EDIT_ATTACHED_DOCUMENTS_SWITCH_ROWS_PER_PAGE:
            return {
                ...state,
                attachedDocumentsEditState: {
                    ...state.attachedDocumentsEditState,
                    rowsPerPage: action.rowsPerPage || projectEditInitialState.attachedDocumentsEditState.rowsPerPage
                }
            }

        // #endregion

        // #region Project views

        case PROJECT_EDIT_VIEW_DELETE_DIALOG:
            if (!action.viewId) {
                return state;
            }
            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    deleteView: true,
                    viewId: action.viewId
                }
            };

        case PROJECT_EDIT_VIEWS_HIDE_DIALOGS:
            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    viewId: '',
                    deleteView: false,
                }
            };

        case PROJECT_EDIT_VIEWS_CONFIG:
            return {
                ...state,
                projectViewEditState: { ...projectViewsEditInitialState }
            };


        case PROJECT_EDIT_VIEWS_CONFIG_DATA:
            if (!action.projectViews) {
                return state;
            }

            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    viewsConfigStorage: {
                        ...loadedSomethingStorageState,
                        projectViews: elementsToMap(action.projectViews)
                    }
                }
            }

        case PROJECT_EDIT_VIEWS_CONFIG_ERROR:
            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    viewsConfigStorage: {
                        ...projectViewsEditInitialState.viewsConfigStorage,
                        ...errorSomethingStorageState(action.errorDescription)
                    }
                }
            }

        case PROJECT_EDIT_VIEW_DELETE: {
            if (!action.viewId) {
                return state;
            }

            const map = deleteElementOfMap(state.projectViewEditState.viewsConfigStorage.projectViews, action.viewId);
            const childrenViewIds = mapToListOfElements(map, v => v.ParentViewId === action.viewId).map(v => v.Id);

            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    viewsConfigStorage: {
                        ...state.projectViewEditState.viewsConfigStorage,
                        projectViews: updateElementsPropertiesInMap(map, childrenViewIds, { ParentViewId: '' })
                    }
                }
            };
        }

        case PROJECT_EDIT_VIEW_ADD_OR_UPDATE:
            if (!action.projectViews) {
                return state;
            }

            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    viewsConfigStorage: {
                        ...state.projectViewEditState.viewsConfigStorage,
                        projectViews: addOrUpdateElementInMap(state.projectViewEditState.viewsConfigStorage.projectViews, ...action.projectViews)
                    }
                }
            }

        case PROJECT_EDIT_VIEW_CHANGE_PUBLIC:
            if (!action.viewId) {
                return state;
            }

            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    viewsConfigStorage: {
                        ...state.projectViewEditState.viewsConfigStorage,
                        projectViews: updateElementPropertiesInMap(state.projectViewEditState.viewsConfigStorage.projectViews, action.viewId, { IsPublic: action.isPublic || false })
                    }
                }
            };

        case PROJECT_EDIT_VIEW_CHANGE_USERS: {
            if (!action.viewId || !action.userIds) {
                return state;
            }
            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    viewsConfigStorage: {
                        ...state.projectViewEditState.viewsConfigStorage,
                        projectViews: updateElementPropertiesInMap(state.projectViewEditState.viewsConfigStorage.projectViews, action.viewId, { UserIds: action.userIds })
                    }
                }
            }
        }

        case PROJECT_EDIT_OVERVIEW_CHANGED: {
            if (action.stringProperty === undefined) {
                return state;
            }
            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    viewsConfigStorage: {
                        ...state.projectViewEditState.viewsConfigStorage,
                        projectViews: updateElementPropertiesInMap(state.projectViewEditState.viewsConfigStorage.projectViews, "Overview", { Name: action.stringProperty })
                    }
                }
            }
        }

        case PROJECT_EDIT_VIEW_SET_IS_DEFAULT_EXPANDED: {
            const { viewId, booleanProperty } = action;
            if (!viewId) {
                return state;
            }

            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    viewsConfigStorage: {
                        ...state.projectViewEditState.viewsConfigStorage,
                        projectViews: updateElementPropertiesInMap(state.projectViewEditState.viewsConfigStorage.projectViews, viewId, { IsDefaultExpanded: booleanProperty || false })
                    }
                }
            }
        }

        case PROJECT_EDIT_VIEW_UPDATE_PARENT_INFO: {
            const { viewsOrderInfo } = action;

            if (!viewsOrderInfo) {
                return state;
            }

            const map = state.projectViewEditState.viewsConfigStorage.projectViews;

            for (const { ViewId, ParentId, Order } of viewsOrderInfo) {
                const view = map.get(ViewId);
                if (!view) {
                    continue;
                }

                map.set(ViewId, { ...view, ParentViewId: ParentId, Order });
            }

            return {
                ...state,
                projectViewEditState: {
                    ...state.projectViewEditState,
                    viewsConfigStorage: {
                        ...state.projectViewEditState.viewsConfigStorage,
                        projectViews: map
                    }
                }
            }
        }

        // #endregion

        //#region Access control

        case PROJECT_EDIT_ACCESS_CONTROL_SHOW_USERS_DIALOG:
            if (action.targetRole !== undefined) {
                return {
                    ...state,
                    accessControlEditState: {
                        ...state.accessControlEditState,
                        showSelectUsersDialog: true,
                        targetRole: action.targetRole
                    }
                }
            }
            break;

        case PROJECT_EDIT_ACCESS_CONTROL_HIDE_USERS_DIALOG:
            return {
                ...state,
                accessControlEditState: {
                    ...state.accessControlEditState,
                    showSelectUsersDialog: false,
                    targetRole: null
                }
            }

        case PROJECT_EDIT_ACCESS_CONTROL_SHOW_USER_DIALOG:
            if (!action.userId) {
                return state;
            }

            return {
                ...state,
                accessControlEditState: {
                    ...state.accessControlEditState,
                    showEditUserDialog: true,
                    userId: action.userId
                }
            };

        case PROJECT_EDIT_ACCESS_CONTROL_HIDE_USER_DIALOG:
            return {
                ...state,
                accessControlEditState: {
                    ...state.accessControlEditState,
                    showEditUserDialog: false,
                    userId: ''
                }
            };

        case PROJECT_EDIT_ACCESS_CONTROL_SHOW_EXPORT_USERS_DIALOG:
            return {
                ...state,
                accessControlEditState: {
                    ...state.accessControlEditState,
                    showExportUsersDialog: true
                }
            }

        case PROJECT_EDIT_ACCESS_CONTROL_HIDE_EXPORT_USERS_DIALOG:
            return {
                ...state,
                accessControlEditState: {
                    ...state.accessControlEditState,
                    showExportUsersDialog: false
                }
            }

        case PROJECT_DATA_USERS_UPDATE:
            return {
                ...state,
                accessControlEditState: {
                    ...state.accessControlEditState,
                    showEditUserDialog: false,
                    userId: ''
                }
            }

        //#endregion

        case PROJECT_EDIT_LABELS_SHOW: {
            return {
                ...state,
                projectEditLabelState: {
                    ...state.projectEditLabelState,
                    showLabelsDialog: true
                }
            }
        }

        case PROJECT_EDIT_LABELS_HIDE: {
            return {
                ...state,
                projectEditLabelState: {
                    ...state.projectEditLabelState,
                    showLabelsDialog: false
                }
            }
        }

        case PROJECT_EDIT_LABELS_EDIT_SHOW: {
            if (action.labelId === undefined) {
                return state;
            }

            return {
                ...state,
                projectEditLabelState: {
                    ...state.projectEditLabelState,
                    showEditLabelDialog: true,
                    labelId: action.labelId
                }
            }
        }

        case PROJECT_EDIT_LABELS_EDIT_HIDE: {
            return {
                ...state,
                projectEditLabelState: {
                    ...state.projectEditLabelState,
                    showEditLabelDialog: false,
                    labelId: ""
                }
            }
        }

        case PROJECT_EDIT_LABELS_DELETE_SHOW: {
            if (!action.labelId) {
                return state;
            }

            return {
                ...state,
                projectEditLabelState: {
                    ...state.projectEditLabelState,
                    showDeleteLabelDialog: true,
                    labelId: action.labelId
                }
            }
        }

        case PROJECT_EDIT_LABELS_DELETE_HIDE: {
            return {
                ...state,
                projectEditLabelState: {
                    ...state.projectEditLabelState,
                    showDeleteLabelDialog: false,
                    labelId: ""
                }
            }
        }

        case PROJECT_EDIT_EDIT_DATABASE_SHOW: {
            if (!action.databaseId) {
                return state;
            }
            return {
                ...state,
                projectEditDatabasesState: {
                    ...state.projectEditDatabasesState,
                    showEditDialog: true,
                    databaseId: action.databaseId
                }
            }
        }

        case PROJECT_EDIT_EDIT_DATABASE_HIDE: {
            return {
                ...state,
                projectEditDatabasesState: {
                    ...state.projectEditDatabasesState,
                    showEditDialog: false,
                    databaseId: ''
                }
            }
        }

        case PROJECT_EDIT_REMOVE_DATABASE_SHOW: {
            if (!action.databaseId) {
                return state;
            }
            return {
                ...state,
                projectEditDatabasesState: {
                    ...state.projectEditDatabasesState,
                    showRemoveDialog: true,
                    databaseId: action.databaseId
                }
            }
        }

        case PROJECT_EDIT_REMOVE_DATABASE_HIDE: {
            return {
                ...state,
                projectEditDatabasesState: {
                    ...state.projectEditDatabasesState,
                    showRemoveDialog: false,
                    databaseId: ''
                }
            }
        }

        case PROJECT_EDIT_REMOVE_PROJECT_SHOW: {
            return {
                ...state,
                showRemoveProjectDialog: true
            }
        }

        case PROJECT_EDIT_REMOVE_PROJECT_HIDE: {
            return {
                ...state,
                showRemoveProjectDialog: false
            }
        }

        case PROJECT_EDIT_REMOVE_PROJECT: {
            if (action.projectId === undefined) {
                return state;
            }
            return {
                ...state,
                showRemoveProjectDialog: false
            }
        }

        //#region 

        case PROJECT_EDIT_SENSORS_DATA_FILTER_CHANGED: {
            if (!action.sensorsDataFilter) {
                return state;
            }

            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    filter: { ...state.sensorsDataState.filter, ...action.sensorsDataFilter }
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_LOADING:
            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    recordsState: projectEditInitialState.sensorsDataState.recordsState
                }
            }

        case PROJECT_EDIT_SENSORS_DATA_LOADED: {
            if (!action.sensorsData) {
                return state;
            }

            let filter = state.sensorsDataState.filter;
            // if first data load 
            if (action.booleanProperty) {
                if (action.sensorDataFilter) {
                    filter = action.sensorDataFilter;
                }
                const getFilterStateTime = (original: GeovisSensorsDataFilter): string | undefined => {
                    if (!action.sensorsData || !action.sensorsData.Success || action.sensorsData.Data.Records.length === 0) {
                        return original.StartTime;
                    }

                    return action.sensorsData.Data.Records[action.sensorsData.Data.Records.length - 1].TimeSlot;
                }

                const getFilterEndTime = (original: GeovisSensorsDataFilter): string | undefined => {
                    if (!action.sensorsData || !action.sensorsData.Success || action.sensorsData.Data.Records.length === 0) {
                        return original.EndTime;
                    }

                    return action.sensorsData.Data.Records[0].TimeSlot;
                }

                filter.StartTime = getFilterStateTime(filter);
                filter.EndTime = getFilterEndTime(filter);
            }


            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    filter,
                    recordsState: processFetchedData(action.sensorsData, state.sensorsDataState.recordsState, projectEditInitialState.sensorsDataState.recordsState, st => {
                        const records = st.Records.map<IGvSensorDataInfoModel>(record => ({ isChecked: false, record, uid: v4() }));

                        return {
                            sortKey: state.sensorsDataState.recordsState.sortKey,
                            sortOrder: state.sensorsDataState.recordsState.sortOrder,
                            data: {
                                SensorDecimals: st.SensorDecimals,
                                Records: sortSensorData(records, state.sensorsDataState.recordsState.sortKey, state.sensorsDataState.recordsState.sortOrder),
                                ReadonlyMsts: st.ReadonlyMsts
                            }
                        };
                    })
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_EDIT_RECORD: {
            const { stringProperty } = action;
            if (!stringProperty) {
                return state;
            }

            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    editDataRecordUid: stringProperty
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_HIDE_EDIT_DIALOG:
            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    editDataRecordUid: ''
                }
            }


        case PROJECT_EDIT_SENSORS_DATA_UPDATE_RECORD: {
            const { stringProperty: recordUid, sensorDataRecord } = action;
            if (!recordUid || !sensorDataRecord) {
                return state;
            }

            const changedDataRecords = state.sensorsDataState.recordsState.data.Records.map(r => {
                const { isChecked, uid } = r;
                if (uid === recordUid) {
                    return { isChecked, uid, record: sensorDataRecord };
                }

                return r;
            });

            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    editDataRecordUid: '',
                    recordsState: {
                        ...state.sensorsDataState.recordsState,
                        data: {
                            ...state.sensorsDataState.recordsState.data,
                            Records: changedDataRecords
                        }
                    }
                }
            }

        }

        case PROJECT_EDIT_SENSORS_DATA_REMOVE_RECORD: {
            const { stringProperty: recordUid } = action;

            if (!recordUid) {
                return state;
            }

            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    editDataRecordUid: '',
                    recordsState: {
                        ...state.sensorsDataState.recordsState,
                        data: {
                            ...state.sensorsDataState.recordsState.data,
                            Records: state.sensorsDataState.recordsState.data.Records.filter(r => r.uid !== recordUid)
                        }
                    }
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_RELOAD: {
            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    filter: { ...state.sensorsDataState.filter } // just make the same, bu different filter to trigger reloading data in the data layer
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_SHOW_REMOVE_ATTRIBUTES_DIALOG: {
            if (action.selectedSensorsData === undefined) {
                return state;
            }

            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    selectedSensorsData: action.selectedSensorsData,
                    showRemoveAttributesDialog: true
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG: {
            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    selectedSensorsData: [],
                    showRemoveAttributesDialog: false
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_HIDE_REMOVE_ATTRIBUTES_DIALOG_AND_RELOAD: {
            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    selectedSensorsData: [],
                    showRemoveAttributesDialog: false,
                    filter: { ...state.sensorsDataState.filter } // just make the same, bu different filter to trigger reloading data in the data layer
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_DATABASES_SELECTED: {
            if (!action.databaseIds) {
                return state;
            }

            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    databaseIds: action.databaseIds
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION: {
            if (!action.selectedRecords) {
                return state;
            }
            const changedRecordsState: IProjectEditSensorsDataRecordsState = {
                ...state.sensorsDataState.recordsState
            }
            changedRecordsState.data.Records.forEach(item => {
                if (action.selectedRecords && action.selectedRecords?.findIndex(i => i.uid === item.uid) >= 0) {
                    item.isChecked = true;
                }
                else {
                    item.isChecked = false;
                }
            })

            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    recordsState: changedRecordsState
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_CHANGE_SELECTION_OF_ALL: {
            if (action.booleanProperty === undefined) {
                return state;
            }
            const changedRecordsState: IProjectEditSensorsDataRecordsState = {
                ...state.sensorsDataState.recordsState
            }
            changedRecordsState.data.Records.forEach(item => {
                item.isChecked = action.booleanProperty ?? false;
            })

            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    recordsState: changedRecordsState,
                    lastCheckedIndex: 0
                }
            }
        }

        case PROJECT_EDIT_SENSORS_DATA_CHANGE_SORT: {
            const { stringProperty: columnKey, sortOrder } = action;

            if (columnKey === undefined || sortOrder === undefined) {
                return state;
            }

            const changedRecordsState: IProjectEditSensorsDataRecordsState = {
                ...state.sensorsDataState.recordsState,
                sortKey: columnKey,
                sortOrder,
                data: {
                    ...state.sensorsDataState.recordsState.data,
                    Records: sortSensorData(state.sensorsDataState.recordsState.data.Records, columnKey, sortOrder)
                }
            }

            changedRecordsState.data.Records.forEach(v => v.isChecked = false);

            return {
                ...state,
                sensorsDataState: {
                    ...state.sensorsDataState,
                    recordsState: changedRecordsState,
                    lastCheckedIndex: -1
                }
            }
        }

        //#endregion

        case PROJECT_DATA_REPORTS_DATA: {
            return {
                ...state,
                projectReportsState: {
                    ...state.projectReportsState,
                    elementsStorage: projectEditReportsElementsStorageInitialState
                }
            }
        }

        case PROJECT_EDIT_REPORT_ADD_OR_UPDATE: {
            return {
                ...state,
                projectReportsState: {
                    ...state.projectReportsState,
                    showCopyDialog: false,
                    showRemoveDialog: false
                }
            }
        }

        case PROJECT_EDIT_REPORT_REMOVED:
        case PROJECT_EDIT_REPORT_HIDE_ANY_DIALOG: {

            return {
                ...state,
                projectReportsState: {
                    ...state.projectReportsState,
                    reportId: 0,
                    showRemoveDialog: false,
                    showCopyDialog: false,
                    showAddToGroupsDialog: false
                }
            }
        }

        case PROJECT_EDIT_REPORT_SHOW_REMOVE_DIALOG: {
            if (action.numberProperty === undefined) {
                return state;
            }
            return {
                ...state,
                projectReportsState: {
                    ...state.projectReportsState,
                    reportId: action.numberProperty,
                    showRemoveDialog: true
                }
            }
        }

        case PROJECT_EDIT_REPORT_ADD_TO_GROUPS_DIALOG_SHOW:
            if (action.numberProperty === undefined) {
                return state;
            }
            return {
                ...state,
                projectReportsState: {
                    ...state.projectReportsState,
                    reportId: action.numberProperty,
                    showAddToGroupsDialog: true
                }
            }

        // reset elements storage when after navigation to the reports edit page
        case PROJECT_DATA_REPORTS: {
            return {
                ...state,
                projectReportsState: {
                    ...state.projectReportsState,
                    elementsStorage: projectEditReportsElementsStorageInitialState
                }
            };
        }

        case PROJECT_EDIT_REPORT_ELEMENTS_LOADING: {
            return {
                ...state,
                projectReportsState: {
                    ...state.projectReportsState,
                    elementsStorage: projectEditReportsElementsStorageInitialState
                }
            }
        }

        case PROJECT_EDIT_REPORT_ELEMENTS_LOADED: {
            if (action.reportElements === undefined) {
                return state;
            }

            return {
                ...state,
                projectReportsState: {
                    ...state.projectReportsState,
                    elementsStorage: processFetchedData(action.reportElements, state.projectReportsState.elementsStorage, projectEditReportsElementsStorageInitialState, st => ({
                        charts: elementsToMap<number, GeovisReportElementInfoSlim>(st.Charts),
                        logbooks: elementsToMap<number, GeovisReportElementInfoSlim>(st.Logbooks),
                        comments: elementsToMap<number, GeovisReportElementInfoSlim>(st.Comments),
                        headers: elementsToMap<number, GeovisReportElementInfoSlim>(st.Headers),
                        mapSections: elementsToMap<number, GeovisReportElementInfoSlim>(st.MapSections),
                        tables: elementsToMap<number, GeovisReportElementInfoSlim>(st.Tables),
                        geovisImages: elementsToMap<number, GeovisReportElementInfoSlim>(st.GeovisImages)
                    }))
                }
            }
        }

        case PROJECT_EDIT_REPORT_UPDATE_ELEMENT_ENTRY: {
            if (!action.reportElement) {
                return state;
            }

            const { reportElement } = action;
            const elementsStorage: IGeovisReportElementsStorage = { ...state.projectReportsState.elementsStorage };

            switch (reportElement.ElementType) {
                case ReportElementType.GeovisChart:
                case ReportElementType.Chart:
                    elementsStorage.charts = addOrUpdateElementInMap(elementsStorage.charts, reportElement);
                    break

                case ReportElementType.Logbook:
                    elementsStorage.logbooks = addOrUpdateElementInMap(elementsStorage.logbooks, reportElement);
                    break;

                case ReportElementType.MapSection:
                    elementsStorage.mapSections = addOrUpdateElementInMap(elementsStorage.mapSections, reportElement);
                    break;

                case ReportElementType.Header:
                    elementsStorage.headers = addOrUpdateElementInMap(elementsStorage.headers, reportElement);
                    break;

                case ReportElementType.GeovisComment:
                case ReportElementType.Comment:
                    elementsStorage.comments = addOrUpdateElementInMap(elementsStorage.comments, reportElement);
                    break;

                case ReportElementType.GeovisImage:
                    elementsStorage.geovisImages = addOrUpdateElementInMap(elementsStorage.geovisImages, reportElement);
                    break;

                case ReportElementType.GeovisTable:
                    elementsStorage.tables = addOrUpdateElementInMap(elementsStorage.tables, reportElement);
                    break;

                default:
                    throw Error(`${action.type}: not supported report element type ${getReportElementTypeToName(reportElement.ElementType)}`);
            }

            return {
                ...state,
                projectReportsState: {
                    ...state.projectReportsState,
                    elementsStorage
                }
            }
        }

        case PROJECT_EDIT_REPORT_SHOW_COPY_DIALOG: {
            if (action.numberProperty === undefined) {
                return state;
            }
            return {
                ...state,
                projectReportsState: {
                    ...state.projectReportsState,
                    reportId: action.numberProperty,
                    showCopyDialog: true
                }
            }
        }

        case PROJECT_EDIT_SENSORS_LIST_LOADING: {
            return {
                ...state,
                sensorsListEditState: {
                    ...state.sensorsListEditState,
                    sensorsStorage: projectEditSensorsStorageInitialState
                }
            }
        }

        case PROJECT_EDIT_SENSORS_LIST_LOADED: {
            if (!action.sensorsListData) {
                return state;
            }

            return {
                ...state,
                sensorsListEditState: {
                    ...state.sensorsListEditState,
                    sensorsStorage: processFetchedData(action.sensorsListData, state.sensorsListEditState.sensorsStorage, projectEditSensorsStorageInitialState, st => ({
                        sensorsData: st
                    }))
                }
            }
        }

        case PROJECT_EDIT_SENSORS_LIST_FILTER_CHANGED: {
            if (!action.filter) {
                return state;
            }

            return {
                ...state,
                sensorsListEditState: {
                    ...state.sensorsListEditState,
                    filter: action.filter
                }
            }
        }

        case PROJECT_EDIT_SENSORS_LIST_FILTER_LOADING: {
            return {
                ...state,
                sensorsListEditState: {
                    ...state.sensorsListEditState,
                    filterStorage: projectEditSensorsFilterStorageInitialState
                }
            }
        }

        case PROJECT_EDIT_SENSORS_LIST_FILTER_LOADED: {
            if (!action.filterData) {
                return state;
            }

            const filterStorage = processFetchedData(action.filterData, state.sensorsListEditState.filterStorage, projectEditSensorsFilterStorageInitialState, st => ({
                databases: st.AvailableDatabases,
                physicalUnits: st.AvailableUnits,
                sensorTypes: st.AvailableCategories
            }));

            return {
                ...state,
                sensorsListEditState: {
                    ...state.sensorsListEditState,
                    filterStorage
                }
            }
        }

        case PROJECT_EDIT_SENSORS_MANUAL_IMPORT_RELOAD: {

            return {
                ...state,
                sensorsListEditState: {
                    ...state.sensorsListEditState,
                    manualTasksState: {
                        ...state.sensorsListEditState.manualTasksState,
                        watchTasksTimestamp: Date.now()
                    }
                }
            }
        }
    }

    const mainState = projectEditElementsReducer(state, action);

    return mainState;
}

/**
 * Project elements edit reducer
 * @param state 
 * @param action 
 */
const projectEditElementsReducer = (state: IGeovisProjectEditState, action: IGeovisProjectEditAction): IGeovisProjectEditState => {

    switch (action.type) {

        case PROJECT_ELEMENT_COPY_DIALOG_SHOW:
        case PROJECT_ELEMENT_REMOVE_DIALOG_SHOW:
            if (!action.elementId || action.elementType === undefined) {
                return state;
            }

            return {
                ...state,
                projectElementsState: {
                    ...state.projectElementsState,
                    showCopyDialog: action.type === PROJECT_ELEMENT_COPY_DIALOG_SHOW,
                    showDeleteDialog: action.type === PROJECT_ELEMENT_REMOVE_DIALOG_SHOW,
                    elementId: action.elementId,
                    elementType: action.elementType,
                    groupName: action.groupName === undefined ? '' : action.groupName,
                    removeFromGroupOnly: action.booleanProperty === undefined ? false : action.booleanProperty
                }
            }

        case PROJECT_ELEMENT_ENTRY_ADDED: {
            return {
                ...state,
                projectElementsState: {
                    ...state.projectElementsState,
                    showCopyDialog: false,
                    elementId: 0,
                    elementType: ProjectElementType.Unknown,
                    groupName: ''
                }
            }
        }

        case PROJECT_ELEMENT_ENTRY_REMOVED:
        case PROJECT_ELEMENT_ENTRY_UPDATED:
        case PROJECT_ELEMENT_HIDE_ELEMENT_DIALOG:
            return {
                ...state,
                projectElementsState: {
                    ...state.projectElementsState,
                    showCopyDialog: false,
                    showDeleteDialog: false,
                    elementId: 0,
                    elementType: ProjectElementType.Unknown
                }
            }

        case PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_SHOW:
            if (!action.groupName) {
                return state;
            }

            return {
                ...state,
                projectElementsState: {
                    ...state.projectElementsState,
                    showDeleteGroupDialog: true,
                    groupName: action.groupName
                }
            }

        case PROJECT_ELEMENTS_REMOVE_CUSTOM_GROUP_DIALOG_HIDE:
            return {
                ...state,
                projectElementsState: {
                    ...state.projectElementsState,
                    showDeleteGroupDialog: false
                }
            }

        case PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_SHOW:
            if (action.groupName === undefined) {
                return state;
            }

            return {
                ...state,
                projectElementsState: {
                    ...state.projectElementsState,
                    showEditGroupDialog: true,
                    groupName: action.groupName
                }
            }

        case PROJECT_ELEMENTS_EDIT_GROUP_DIALOG_HIDE:
            return {
                ...state,
                projectElementsState: {
                    ...state.projectElementsState,
                    showEditGroupDialog: false
                }
            }

        case PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_SHOW:
            if (!action.elementId || action.elementType === undefined || action.groupName === undefined) {
                return state;
            }

            return {
                ...state,
                projectElementsState: {
                    ...state.projectElementsState,
                    elementId: action.elementId,
                    elementType: action.elementType,
                    groupName: action.groupName,
                    showAddToGroupsDialog: true,
                }
            }

        case PROJECT_ELEMENT_ADD_TO_GROUPS_DIALOG_HIDE:
            return {
                ...state,
                projectElementsState: {
                    ...state.projectElementsState,
                    elementId: 0,
                    elementType: ProjectElementType.Unknown,
                    groupName: '',
                    showAddToGroupsDialog: false,
                }
            }

        case PROJECT_EDIT_GEORASTER_HIDE_DIALOGS:
            return {
                ...state,
                rasterEditState: {
                    rasterId: '',
                    showDeleteDialog: false,
                    showAddDialog: false,
                    progress: -1
                }
            }

        case PROJECT_EDIT_GEORASTER_SHOW_REMOVE_DIALOG: {
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                rasterEditState: {
                    rasterId: action.stringProperty,
                    showDeleteDialog: true,
                    showAddDialog: false,
                    progress: -1
                }
            }
        }

        case PROJECT_EDIT_GEORASTER_CHANGE_UPLOAD_STATE: {
            if (action.numberProperty === undefined) {
                return state;
            }

            return {
                ...state,
                rasterEditState: {
                    ...state.rasterEditState,
                    showAddDialog: true,
                    progress: action.numberProperty
                }
            }
        }
    }

    return state;

}

export default projectEditReducer;