/**
 * Navigation helper 
 * Author: Vyacheslav Skripin
 * Created: 16.09.2019
 */

import H from 'history';
import { RouteComponentProps } from 'react-router';
import { matchPath } from 'react-router-dom';
import { ChartType } from '../server/AVTService/TypeLibrary/Common/ChartType';
import { KindOfElementUsing } from '../server/AVTService/TypeLibrary/Common/KindOfElementUsing';
import { ProjectElementType } from '../server/AVTService/TypeLibrary/Common/ProjectElementType';
import { InventoryObjectType } from '../server/AVTService/TypeLibrary/Inventory/InventoryObjectType';
import IRouteParams from './IRouteParams';
import Route from './Route';
import Routes from './Routes';
import { ElementsTab } from '../components/navigation/ContentNavigation';

/**
 * Navigate to the root page
 * @param history The browser history
 */
export const navigateToProjectsOverview = (history: H.History) => {
    history.push(Routes.home.path);
}

/**
 * Navigate to project report
 * @param history 
 * @param projectId 
 * @param reportId 
 */
export const navigateToProjectReport = (history: H.History, projectId: number, reportId: number) => {
    history.push(Routes.projectReport.patch(projectId, reportId).path);
}

/**
 * Open project report in new tab
 * @param history 
 * @param projectId 
 * @param reportId 
 */
export const navigateToProjectReportInNewTab = (projectId: number, reportId: number, viewId?: string) => {
    if (viewId) {
        window.open(Routes.projectReportWithViewId.patch(projectId, reportId, viewId).path, '_blank', 'noopener,noreferrer');
    }
    else {
        window.open(Routes.projectReport.patch(projectId, reportId).path, '_blank', 'noopener,noreferrer');
    }

}

/**
 * Navigate to project overview and select view
 * @param history 
 * @param projectId 
 * @param viewId 
 */
export const navigateToProjectOverviewAndSelectView = (history: H.History, projectId: number, viewId: string) => {
    history.push(Routes.projectView.patch(projectId, viewId).path);
}

/**
 * Navigate to project overview
 * @param history 
 * @param projectId 
 */
export const navigateToProjectOverview = (history: H.History, projectId: number) => {
    history.push(Routes.project.patch(projectId).path);
}

export const navigateToProjectOverviewFn = (history: H.History) => (projectId: number) => {
    navigateToProjectOverview(history, projectId);
}

export const navigateToProjectNotificationsSettingsFn = (history: H.History) => (projectId: number) => {
    history.push(Routes.projectEditNotifications.patch(projectId).path);
}

/**
 * Navigate to project support page
 * @param history 
 * @param projectId 
 */
export const navigateToProjectSupportPageFn = (history: H.History, projectId: number) => () => {
    history.push(Routes.projectSupport.patch(projectId).path);
}

/**
 * Get function to navigate to project element edit
 * @param history 
 * @returns 
 */
export const navigateToProjectElementEditFn = (projectId: number, history: H.History, tabName: ElementsTab) => (elementId: number, elementType: ProjectElementType) => {
    const url = Routes.projectEditElementEditDialog.patch(projectId, tabName, elementType, elementId).path;
    history.push(url);
}

/**
 * 
 * @param projectId 
 * @param history 
 * @returns 
 */
export const navigateToProjectGroupElementEditFn = (projectId: number, history: H.History) => (elementId: number, elementType: ProjectElementType, groupName: string) => {
    const tab: ElementsTab = 'Custom';
    const url = Routes.projectEditGroupElementEditDialog.patch(projectId, tab, elementType, elementId, groupName).path;
    history.push(url);
}

/**
 * Navigate to create new chart dialog (by url)
 * @param history 
 * @param projectId 
 * @param chartType 
 */
export const navigateToCreateChartDialog = (history: H.History, projectId: number, chartType: ChartType, templateId: number) => {
    const tab: ElementsTab = 'All';
    const url = Routes.projectEditElementCreateChartDialog.patch(projectId, tab, ProjectElementType.Chart, chartType, templateId);
    history.push(url.path);
}

/**
 * Navigate to create new chart dialog (by url)
 * @param history 
 * @param projectId 
 * @param chartType 
 */
export const navigateToCreateDefaultChartDialog = (history: H.History, projectId: number, chartType: ChartType, templateId: number) => {
    const tab: ElementsTab = 'Default';
    const url = Routes.projectEditElementCreateChartDialog.patch(projectId, tab, ProjectElementType.DefaultChart, chartType, templateId);
    history.push(url.path);
}

/**
 * Navigate to create new chart template dialog
 * @param history 
 * @param projectId 
 */
export const navigateToCreateChartTemplateDialog = (history: H.History, projectId: number, chartType: ChartType, isForCompany: boolean) => {
    const tab: ElementsTab = isForCompany ? 'CompanyTemplates' : 'ProjectTemplates';
    const url = Routes.projectEditElementCreateChartTemplateDialog.patch(projectId, tab, isForCompany ? ProjectElementType.CompanyChartTemplate : ProjectElementType.ProjectChartTemplate, chartType, KindOfElementUsing.Template);
    history.push(url.path);
}

/**
 * Navigate to create new popup chart template dialog
 * @param history 
 * @param projectId 
 */
export const navigateToCreatePopupChartTemplateDialog = (history: H.History, projectId: number, chartType: ChartType, isForCompany: boolean) => {
    const tab: ElementsTab = isForCompany ? 'CompanyTemplates' : 'ProjectTemplates';
    const url = Routes.projectEditElementCreateChartTemplateDialog.patch(projectId, tab, isForCompany ? ProjectElementType.CompanyChartTemplate : ProjectElementType.ProjectChartTemplate, chartType, KindOfElementUsing.DefaultTemplate);
    history.push(url.path);
}

/**
 * Navigate to create new project element (by url)
 * @param history 
 * @param projectId 
 * @param elementType 
 */
export const navigateToCreateProjectElementDialog = (history: H.History, projectId: number, elementType: ProjectElementType, templateId: number, tab: ElementsTab) => {
    const url = Routes.projectEditElementCreateDialog.patch(projectId, tab, elementType, templateId);
    history.push(url.path);
}

/**
 * Navigate to create new project element template (by url)
 * @param history 
 * @param projectId 
 * @param elementType 
 */
export const navigateToCreateProjectElementTemplateDialog = (history: H.History, projectId: number, elementType: ProjectElementType, tab: ElementsTab) => {
    const url = Routes.projectEditElementCreateTemplateDialog.patch(projectId, tab, elementType);
    history.push(url.path);
}

/**
 * Navigate to project inventory page
 * @param history 
 * @param projectId 
 */
export const navigateToProjectInventoryPageFn = (history: H.History, projectId: number, tabType: InventoryObjectType) => () => {
    // history.push(Routes.projectInventory.patch(projectId).path);
    switch (tabType) {
        case InventoryObjectType.MST:
            history.push(Routes.projectInventoryMst.patch(projectId).path);
            break;
        case InventoryObjectType.Afm:
            history.push(Routes.projectInventoryAfm.patch(projectId).path);
            break;
        case InventoryObjectType.Atc:
            history.push(Routes.projectInventoryAtc.patch(projectId).path);
            break;
        case InventoryObjectType.Tachymeter:
            history.push(Routes.projectInventoryTachy.patch(projectId).path);
            break;
        case InventoryObjectType.SB:
            history.push(Routes.projectInventorySb.patch(projectId).path);
            break;
        case InventoryObjectType.LoRaGateway:
            history.push(Routes.projectInventoryLora.patch(projectId).path);
            break;
    }
}

export const navigatesToInventoryPage = (history: H.History, tabType: InventoryObjectType) => {
    switch (tabType) {
        case InventoryObjectType.MST:
            history.push(Routes.inventoryMst.patch().path);
            break;
        case InventoryObjectType.Afm:
            history.push(Routes.inventoryAfm.patch().path);
            break;
        case InventoryObjectType.Atc:
            history.push(Routes.inventoryAtc.patch().path);
            break;
        case InventoryObjectType.Tachymeter:
            history.push(Routes.inventoryTachy.patch().path);
            break;
        case InventoryObjectType.SB:
            history.push(Routes.inventorySb.patch().path);
            break;
        case InventoryObjectType.LoRaGateway:
            history.push(Routes.inventoryLora.patch().path);
            break;
    }
}

/**
 * Parse route path from component props and return IRouteParams
 * @param props Component props
 * @param route Expected route
 */
/** @deprecated use "useRouteMatch" of "react-router-dom" */
export function geovisMatchPath<TRouteParams extends IRouteParams, P extends RouteComponentProps<TRouteParams>>(props: P, route: Route): TRouteParams | false {
    const matchResult = matchPath<TRouteParams>(props.location.pathname, route);
    return matchResult ? matchResult.params : false;
}