import { Redirect, Route } from 'react-router-dom';
import AuthService from '../../services/AuthService';

export default function GeovisRoute({
    component: Component,
    ...rest
}: any) {
    return (
        <Route
            exact={true}
            {...rest}
            render={() => {
                return AuthService.isAuthenticatedUser()
                    ? <Component />
                    : <Redirect to="/login" />
            }}
        />
    )
}