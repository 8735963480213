//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { JsonDocNode } from '../AtlaskitJsonDocument/JsonDocNode';

export class EmailMessageHistoryEntry
{
	public Id: string;
	public SendTime: string;
	public SenderId: string;
	public SenderCompanyId: string;
	public Recievers: string[];
	public Subject: string;
	public AttachmentsIds: string[];
	public Content: JsonDocNode;
	public ContentHtml: string;
}
