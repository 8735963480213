/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 28.07.2023
 * @description Geovis map section map legend (with scaling)
 */

import { useMap } from "react-leaflet";
import { useEffect } from "react";
import { GEOvisDXFLayerType } from "../../../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { IGeovisMapSectionReportInfo } from "../../../../store/projectReport.types";
import { getMapSectionXScale, getMapSectionXScaleProfileAndSideViews, getMapSectionYScale } from "./MapSectionScale.tools";
import { getProfileCoordsProps } from "../../sensors/tools";
import { getMapSectionViewLegend } from "../../../map/Legend";
import { ProjectVisualSettings } from "../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";


interface IComponentProps {
    mapSectionInfo: IGeovisMapSectionReportInfo;
    visualSettings: ProjectVisualSettings;
}

export const GeovisMapSectionMapLegend = ({ mapSectionInfo, visualSettings }: IComponentProps) => {

    const map = useMap();

    const { MapSection, DxfLayerType } = mapSectionInfo;
    const { MovementSettings, LayerType } = MapSection;

    /**
     * The function to draw a legend on the map
     * @param leafletElement
     * @returns 
     */
    const drawLegend = (leafletElement: L.Map) => {
        if (!MovementSettings.ShowScaleLegend) {
            return;
        }

        if (LayerType === GEOvisDXFLayerType.Map) {
            L.control.scale({ position: "bottomleft", imperial: false }).addTo(leafletElement);
        }

        const vectorScalingRate = MovementSettings.VectorMovementSettings.ScalingRate;
        let scaleX = getMapSectionXScale(leafletElement, vectorScalingRate);
        const scaleY = getMapSectionYScale(leafletElement, MovementSettings.UseVectorSettingsForBars ? vectorScalingRate : MovementSettings.HeightBarsMovementSettings.ScalingRate);

        const { DxfMapTileLayerBounds } = mapSectionInfo;

        if (DxfMapTileLayerBounds && DxfMapTileLayerBounds.IsValid && DxfLayerType !== GEOvisDXFLayerType.Map) {
            const coordProps = getProfileCoordsProps(DxfMapTileLayerBounds);
            scaleX = getMapSectionXScaleProfileAndSideViews(leafletElement, vectorScalingRate, coordProps.pixelsPerMeter);
        }

        const legend = getMapSectionViewLegend({
            showArrowItem: MovementSettings.ShowMovementVector,
            arrowLength: scaleX.pixels,
            arrowDesc: scaleX.label,

            showBarItem: MovementSettings.ShowMovementHeightBars,
            barHeight: scaleY.pixels,
            barDesc: scaleY.label,

            arrowColor: visualSettings.movementVectorColor,
            barNegativeColor: visualSettings.negativeHeightBarColor,
            barPositiveColor: visualSettings.positiveHeightBarColor
        });

        if (legend) {
            leafletElement.addControl(legend);
        }
    }

    useEffect(() => {
        drawLegend(map);
    }, []);

    return (null);
}