//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { AxisScaleLimit } from '../AxisScaleLimit';
import { ChartSettingBool } from '../ChartSettingBool';

export class InclinometerVisibleRangeSettings
{
	public ShowFirstXAxis: boolean;
	public ShowSecondYAxis: boolean;
	public MinXLimit: AxisScaleLimit;
	public MaxXLimit: AxisScaleLimit;
	public MinYLimit: AxisScaleLimit;
	public MaxYLimit: AxisScaleLimit;
	public ShowSensors: ChartSettingBool;
	public ShowJunctionPoints: ChartSettingBool;
	public TurnSignX: boolean;
	public TurnSignY: boolean;
	public ChainsIds: string[];
	public ZOffset: number;
}
