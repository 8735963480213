/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 23.12.2019
 * @description Local map objects icons as SVG
 */

import {
    ISVGProps,
    withSvg
} from '../map/IconPrimitives';

const PolylinePrimitive = '<polyline points="2,14 6,10 12,10 8,5 14,2" fill="none" stroke="%color" stroke-width="1.5" />';
const PolylineCirclePrimitives = [
    '<circle cx="2" cy="14" r="1.5" fill="%color" stroke="black" stroke-width="0.5" />',
    '<circle cx="6" cy="10" r="1.5" fill="%color" stroke="black" stroke-width="0.5" />',
    '<circle cx="12" cy="10" r="1.5" fill="%color" stroke="black" stroke-width="0.5" />',
    '<circle cx="8" cy="5" r="1.5" fill="%color" stroke="black" stroke-width="0.5" />',
    '<circle cx="14" cy="2" r="1.5" fill="%color" stroke="black" stroke-width="0.5" />'
];
const TextPrimitive = '<path d="M1,1 15,1 15,5 13,5 13,3 9,3 9,13 10,13 10,15 6,15 6,13 7,13 7,3 3,3 3,5 1,5 Z" fill="%color" stroke="black" stroke-width="0.5" />';

const CirclePrimitive =
    `<circle cx="6.5" cy="6.5" r="6" fill="%color" style="stroke-width: 0.5px; stroke: %color;"/>` +
    `<circle cx="6.5" cy="6.5" r="2.7" fill="white" style="stroke-width: 0.5px; stroke: %color;"/>`;

const DocumentPrimitive = '<path d="M2,0 2,16 14,16 14,5 9,0 Z M3.5,1.5 8,1.5 8,6 12.5,6 12.5,14.5 3.5,14.5 Z M5,7 5,8 9,8 9,7 Z M5,9 5,10 11,10 11,9 Z M5,11 5,12 9,12 9,11 Z"/>';

interface ILMOIconProps {
    color: string;
}

/**
 * Polyline local map object icon SVG
 */
export const PolylineIconSvg = ({ color }: ILMOIconProps): string => {

    const polylineProps: ISVGProps = {
        height: 16,
        width: 16,
        className: 'geovis-lmo-polyline-svg'
    };

    const primitive = PolylinePrimitive
        .replace("%color", color);

    const circlePrimitives = PolylineCirclePrimitives
        .map(cp => cp.replace("%color", color));

    const body = primitive + "\n" + circlePrimitives.join("\n");

    return withSvg(polylineProps)(body);
}

/**
 * Text local map object icon SVG
 */
export const TextIconSvg = ({ color }: ILMOIconProps): string => {

    const textProps: ISVGProps = {
        height: 16,
        width: 16,
        className: 'text-lmo-text-svg'
    };

    const primitive = TextPrimitive
        .replace("%color", color);

    return withSvg(textProps)(primitive);
}

export const CircleIconSvg = ({ color }: ILMOIconProps): string => {

    const circleProps: ISVGProps = {
        height: 16,
        width: 16,
        className: 'circle-lmo-text-svg'
    };

    const primitive = CirclePrimitive
        .replace("%color", color);

    return withSvg(circleProps)(primitive);
}

export const DocumentIconSvg = () : string => {
    const documentProps: ISVGProps = {
        height: 16,
        width: 16,
        className: 'geovis-lmo-document-svg'
    };

    return withSvg(documentProps)(DocumentPrimitive);
}