// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { Item, MenuSection, SkeletonContainerView } from "@atlaskit/navigation-next";
import i18next from "i18next";
import { Link, useRouteMatch } from "react-router-dom";
import BillingIcon from '@atlaskit/icon/glyph/billing';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';
import { connect } from "react-redux";
import { IProjectInfoStorage } from "../../store/data.types";
import IRouteParams from "../../helpers/IRouteParams";
import Routes from "../../helpers/Routes";
import { BackIcon } from '../Icons';
import AuthService from "../../services/AuthService";
import { IGeovisStoreState } from "../../store/store.types";

interface IProjectStatisticNavigationProps {
    projectInfo: IProjectInfoStorage;
}

const ProjectStatisticNavigation = ({ projectInfo: { isLoading, project } }: IProjectStatisticNavigationProps) => {
    if (isLoading) {
        return (<SkeletonContainerView type="content" />);
    }

    if (!project) {
        throw new Error("Cannot show Project Statistic Navigation. Project is not set");
    }

    const isAdmin = AuthService.hasUserTypeAsAdmin();

    const isProjectStatistic = useRouteMatch<IRouteParams>(Routes.projectStatistic);

    const isDetailedStatistic = useRouteMatch<IRouteParams>(Routes.projectDetailedStatistic);
    const isQuickStatistic = useRouteMatch<IRouteParams>(Routes.projectQuickStatistic);

    return (
        <MenuSection>{({ className }: any) => (
            <div className={className} style={{ marginTop: '15px' }}>
                <Link
                    to={Routes.project.patch(project.Id).path}
                    style={{ textDecoration: 'none' }}>
                    <Item
                        before={() => <BackIcon label={i18next.t("Back to Project")} />}
                        text={i18next.t("Back to Project")}
                    />
                </Link>

                <div style={{ marginTop: '10px' }}>
                    <Link
                        to={Routes.projectQuickStatistic.patch(project.Id).path}
                        style={{ textDecoration: 'none' }}>
                        <Item
                            isSelected={isQuickStatistic || isProjectStatistic}
                            before={() => <BillingIcon label="" />}
                            text={i18next.t("Invoice statistic")} />
                    </Link>

                    {isAdmin &&
                        <Link
                            to={Routes.projectDetailedStatistic.patch(project.Id).path}
                            style={{ textDecoration: 'none' }}>
                            <Item
                                isSelected={isDetailedStatistic}
                                before={() => <GraphLineIcon label={i18next.t("Detailed statistic")} />}
                                text={i18next.t("Detailed statistic")}
                            />
                        </Link>
                    }
                </div>

            </div>
        )}
        </MenuSection>
    )
}

const mapStateToProps = ({ data }: IGeovisStoreState): IProjectStatisticNavigationProps => ({
    projectInfo: data.projectInfo
});

export default connect<IProjectStatisticNavigationProps>(mapStateToProps)(ProjectStatisticNavigation);