import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IGeovisBusinessDataState } from '../../../store/businessData.types';
import {
    geovisBusinessMessageTemplateShowNotificationDialog,
    geovisBusinessMessageTemplatesInfoDelete,
    geovisBusinessMessageTemplatesInfoShowDeleteDialog
    , geovisBusinessMessageTemplatesInfoShowShareDialog
    , geovisBusinessMessageTemplatesInfoShowUnshareDialog
} from '../../../store/creators/businessDataCreators';
import { IGeovisStoreState } from '../../../store/store.types';
import { IGeovisAction } from '../../../store/types';
import {
    createMessageTemplatesTableHead,
    createMessageTemplatesTableRows,
    TemplateActionEnum,
    templateActionToNotifyDialogMode
} from './tools';
import { MessageTemplateNotifyDialogMode } from './types';

interface IMessageTemplatesListStateProps {
    businessData: IGeovisBusinessDataState;
}

interface IMessageTemplatesListDispatchProps {
    onShowDeleteDialog: (templateId: string) => void;
    onShowUnshareDialog: (templateId: string) => void;
    onShowShareDialog: (templateId: string) => void;
    onShowRemoveLink: (templateId: string) => void;
    onShowNotificationDialog: (templateId: string, mode: MessageTemplateNotifyDialogMode) => void;
}

interface IMessageTemplatesListOwnProps {
    deleteMessageTemplateReference: (templateId: string) => Promise<boolean>;
}

interface IMessageTemplatesListProps extends IMessageTemplatesListStateProps, IMessageTemplatesListDispatchProps, IMessageTemplatesListOwnProps {

}

const MessageTemplatesList = ({
    businessData,
    onShowDeleteDialog,
    onShowRemoveLink,
    onShowShareDialog,
    onShowUnshareDialog,
    onShowNotificationDialog,
    deleteMessageTemplateReference
}: IMessageTemplatesListProps) => {

    const onTemplateAction = async (action: TemplateActionEnum, templateId: string) => {
        if (!templateId) {
            return;
        }

        switch (action) {

            case TemplateActionEnum.Delete: {
                // this.props.dispatch(geovisBusinessMessageTemplatesInfoShowDeleteDialog(templateId));
                onShowDeleteDialog(templateId);
                break;
            }

            case TemplateActionEnum.RemoveLinkOf: {
                // this.props.dispatch(geovisBusinessMessageTemplatesInfoShowUnshareDialog(templateId));
                onShowUnshareDialog(templateId);
                break;
            }

            case TemplateActionEnum.Share: {
                // this.props.dispatch(geovisBusinessMessageTemplatesInfoShowShareDialog(templateId));
                onShowShareDialog(templateId);
                break;
            }

            case TemplateActionEnum.RemoveLink: {
                if (await deleteMessageTemplateReference(templateId)) {
                    // this.props.dispatch(geovisBusinessMessageTemplatesInfoDelete(templateId));
                    onShowRemoveLink(templateId);
                }
                break;
            }

            case TemplateActionEnum.NotifyCustomSelectedUsers:
            case TemplateActionEnum.NotifyAllUsers:
            case TemplateActionEnum.NotifyAllCompanyAdmins:
            case TemplateActionEnum.NotifyAllUsersOfProject:
            case TemplateActionEnum.NotifyAllAdminsOfProject:
            case TemplateActionEnum.NotifyAllUsersOfCompany:
            case TemplateActionEnum.NotifyProjectAdminsAndViewers:
            case TemplateActionEnum.NotifyAllCompanyAdminsOfCompany: {
                const dialogMode = templateActionToNotifyDialogMode(action);
                // this.props.dispatch(geovisBusinessMessageTemplateShowNotificationDialog(templateId, dialogMode));
                onShowNotificationDialog(templateId, dialogMode);
                break;
            }
        }
    }

    const { messagesStore, usersStore } = businessData;

    return (
        <div style={{ width: '100%' }}>
            <DynamicTableStateless
                // caption={t("Message templates")}
                head={createMessageTemplatesTableHead()}
                rows={createMessageTemplatesTableRows(messagesStore, usersStore, onTemplateAction)}
            />
        </div>

    )
}

const mapStateToProps = ({ businessData }: IGeovisStoreState): IMessageTemplatesListStateProps => ({
    businessData
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IMessageTemplatesListDispatchProps => ({
    onShowDeleteDialog: templateId => dispatch(geovisBusinessMessageTemplatesInfoShowDeleteDialog(templateId)),
    onShowNotificationDialog: (templateId, dialogMode) => dispatch(geovisBusinessMessageTemplateShowNotificationDialog(templateId, dialogMode)),
    onShowRemoveLink: templateId => dispatch(geovisBusinessMessageTemplatesInfoDelete(templateId)),
    onShowShareDialog: templateId => dispatch(geovisBusinessMessageTemplatesInfoShowShareDialog(templateId)),
    onShowUnshareDialog: templateId => dispatch(geovisBusinessMessageTemplatesInfoShowUnshareDialog(templateId)),
});

export default connect<IMessageTemplatesListStateProps, IMessageTemplatesListDispatchProps, IMessageTemplatesListOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(MessageTemplatesList)