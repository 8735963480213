//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { MessageTemplateInfo } from './MessageTemplateInfo';
import { JsonDocNode } from '../AtlaskitJsonDocument/JsonDocNode';
import { MessageTemplateFileInfo } from './MessageTemplateFileInfo';

export class MessageTemplateModel extends MessageTemplateInfo
{
	public Document: JsonDocNode;
	public Attachments: MessageTemplateFileInfo[];
}
