import { Reducer } from 'redux';
import { ProjectElementsMigrationsInfo } from '../../server/GEOvis3/Model/ElementsMigrations/ProjectElementsMigrationsInfo';
import {
    ELEMENTS_MIGRATIONS_FILTER_CHANGED,
    ELEMENTS_MIGRATIONS_HIDE_MIGRATE_ALL_DIALOG,
    ELEMENTS_MIGRATIONS_HIDE_PROJECT_DIALOG,
    ELEMENTS_MIGRATIONS_PROJECTS_LOADED,
    ELEMENTS_MIGRATIONS_PROJECTS_LOADING,
    ELEMENTS_MIGRATIONS_SHOW_MIGRATE_ALL_DIALOG,
    ELEMENTS_MIGRATIONS_SHOW_PROJECT_DIALOG,
    ELEMENTS_MIGRATIONS_UPDATE_PROJECT_INFO
} from '../actions/elementsMigrationsActions';
import { IElementsMigrationsAction, IElementsMigrationsProjectsStorage, IElementsMigrationState } from "../elementsMigrations.types";
import { processFetchedData } from '../helpers/DataHelper';
import { defaultSomethingStorageState } from '../types';

const projectsMigrationsStorageInitialState: IElementsMigrationsProjectsStorage = {
    ...defaultSomethingStorageState,
    projectsMigrationsData: []
}

const projectMigrationsStorageInitState: IElementsMigrationsProjectsStorage = {
    errorDescription: '',
    isError: false,
    isLoaded: false,
    isLoading: false,
    isInProgress: false,
    projectsMigrationsData: []
}

export const elementsMigrationsInitState: IElementsMigrationState = {
    projectId: 0,
    projectsMigrationsStorage: projectMigrationsStorageInitState,
    projectNameFilter: '',
    showMigrateAllDialog: false,
    showProjectDetailsDialog: false,
    migrationType: 'migrate'
}

const reducer: Reducer<IElementsMigrationState> = (state: IElementsMigrationState = elementsMigrationsInitState, action: IElementsMigrationsAction): IElementsMigrationState => {

    switch (action.type) {
        case ELEMENTS_MIGRATIONS_PROJECTS_LOADING: {
            return {
                ...state,
                projectsMigrationsStorage: {
                    ...defaultSomethingStorageState,
                    projectsMigrationsData: []
                }
            }
        }

        case ELEMENTS_MIGRATIONS_PROJECTS_LOADED: {
            if (!action.migrationsInfo) {
                return state;
            }

            return {
                ...state,
                projectsMigrationsStorage: processFetchedData(action.migrationsInfo, state.projectsMigrationsStorage, projectsMigrationsStorageInitialState, st => ({ projectsMigrationsData: st }))
            }
        }

        case ELEMENTS_MIGRATIONS_SHOW_PROJECT_DIALOG: {
            if (!action.projectId) {
                return state;
            }
            return {
                ...state,
                showProjectDetailsDialog: true,
                projectId: action.projectId
            }
        }

        case ELEMENTS_MIGRATIONS_HIDE_PROJECT_DIALOG: {
            return {
                ...state,
                showProjectDetailsDialog: false,
                projectId: 0
            }
        }

        case ELEMENTS_MIGRATIONS_UPDATE_PROJECT_INFO: {
            if (!action.projectMigrationsInfo) {
                return state;
            }

            const projectInfo = action.projectMigrationsInfo;

            // const updProjectData = [...state.projectsMigrationsStorage.projectsMigrationsData];

            // updProjectData.forEach(data => {
            //     if (data.ProjectId === action.projectId) {
            //         data.Changes = action.changes ?? [];
            //     }
            // });

            // return {
            //     ...state,
            //     projectsMigrationsStorage: {
            //         ...state.projectsMigrationsStorage,
            //         projectsMigrationsData: updProjectData
            //     }
            // }
            return {
                ...state,
                projectsMigrationsStorage: {
                    ...state.projectsMigrationsStorage,
                    projectsMigrationsData: state.projectsMigrationsStorage.projectsMigrationsData.map<ProjectElementsMigrationsInfo>(p => {
                        if (p.ProjectId !== projectInfo.ProjectId) {
                            return p;
                        }

                        return projectInfo;
                    })
                }
            }
        }

        case ELEMENTS_MIGRATIONS_FILTER_CHANGED: {
            if (action.stringProperty === undefined) {
                return state;
            }

            return {
                ...state,
                projectNameFilter: action.stringProperty
            }
        }

        case ELEMENTS_MIGRATIONS_SHOW_MIGRATE_ALL_DIALOG: {
            if (!action.migrationType) {
                return state;
            }
            return {
                ...state,
                showMigrateAllDialog: true,
                migrationType: action.migrationType
            }
        }

        case ELEMENTS_MIGRATIONS_HIDE_MIGRATE_ALL_DIALOG: {
            return {
                ...state,
                showMigrateAllDialog: false
            }
        }
    }

    return state;
}

export default reducer;