/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 17.05.2020
 * @description Business data reducer to manage users and companies data
 */

import { Reducer } from "redux";
import {
    addOrUpdateElementInMap,
    deleteElementOfMap,
    deleteElementsOfMap,
    distinctArray,
    elementsToMap,
    updateElementPropertiesInMap,
    updatePropertyOfMapElements
} from "../../helpers/StorageHelper";
import { MSG_TEMPLATES_TAB_NAME } from "../../pages/business/messages/MessagesHub";
import { MessageTemplateNotifyDialogMode } from "../../pages/business/messages/types";
import { EmailHistoryRequest } from "../../server/AVTService/TypeLibrary/Email/EmailHistoryRequest";
import { EmailMessageHistoryEntry } from "../../server/AVTService/TypeLibrary/Email/EmailMessageHistoryEntry";
import { MessageTemplateInfo } from "../../server/AVTService/TypeLibrary/MessageTemplates/MessageTemplateInfo";
import { CompanyInfo } from "../../server/GEOvis3/Model/Company/CompanyInfo";
import { GeovisUserTableInfo } from "../../server/GEOvis3/Model/User/GeovisUserTableInfo";
import { ProjectBusinessInfo } from "../../server/ProjectBusinessInfo";
import AuthService from "../../services/AuthService";
import {
    GEOVIS_BUSINESS_COMPANIES,
    GEOVIS_BUSINESS_COMPANIES_DATA,
    GEOVIS_BUSINESS_HIDE_FAILED_PROJECTS_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_CHANGE_TAB_NAME,
    GEOVIS_BUSINESS_MESSAGE_HISTORY,
    GEOVIS_BUSINESS_MESSAGE_HISTORY_ERROR,
    GEOVIS_BUSINESS_MESSAGE_HISTORY_FILTER_CHANGED,
    GEOVIS_BUSINESS_MESSAGE_HISTORY_HIDE_ITEM,
    GEOVIS_BUSINESS_MESSAGE_HISTORY_LOADED,
    GEOVIS_BUSINESS_MESSAGE_HISTORY_SHOW_ITEM,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATE_HIDE_NOTIFICATION_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_SHARE_BETWEEN,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_UNSHARE_FOR,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATE_SHOW_NOTIFICATION_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ADD_OR_UPDATE,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DATA,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DELETE,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ERROR,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_HIDE_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_DELETE_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_SHARE_DIALOG,
    GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_UNSHARE_DIALOG,
    GEOVIS_BUSINESS_PROJECTS,
    GEOVIS_BUSINESS_PROJECTS_DATA,
    GEOVIS_BUSINESS_PROJECTS_DATA_ERROR,
    GEOVIS_BUSINESS_REFRESH_STATE,
    GEOVIS_BUSINESS_REMOVE_USER,
    GEOVIS_BUSINESS_UPDATE_PROJECTS_PROPERTY,
    GEOVIS_BUSINESS_USERS,
    GEOVIS_BUSINESS_USERS_CREATE_OR_UPDATE,
    GEOVIS_BUSINESS_USERS_DATA,
    GEOVIS_BUSINESS_USERS_DATA_ERROR,
    GEOVIS_BUSINESS_USERS_SET_DIRTY_STATE
} from "../actions/businessDataActions";
import { GEOVIS_INVENTORY_CALENDAR_VIEW_DATA, GEOVIS_INVENTORY_CALENDAR_VIEW_LOADING } from "../actions/inventoryActions";
import { LOGIN_SUCCESS } from "../actions/navigationActions";
import {
    IBusinessCompaniesStorage,
    IBusinessMessagesStorage,
    IBusinessProjectsStorage,
    IBusinessUsersStorage,
    IGeovisBusinessDataAction,
    IGeovisBusinessDataState,
    IMessageHistoryStore,
    IMessageTemplatesStore
} from "../businessData.types";
import { processFetchedData } from "../helpers/DataHelper";
import { IGeovisInventoryAction } from "../inventory.types";
import {
    defaultSomethingStorageState,
    errorSomethingStorageState,
    loadedSomethingStorageState
} from "../types";

export const businessCompaniesStoreInitialState: IBusinessCompaniesStorage = {
    ...defaultSomethingStorageState,
    companies: new Map<string, CompanyInfo>()
}

const businessUsersStoreInitialState: IBusinessUsersStorage = {
    ...defaultSomethingStorageState,
    users: new Map<string, GeovisUserTableInfo>()
}

export const businessProjectsStoreInitialState: IBusinessProjectsStorage = {
    ...defaultSomethingStorageState,
    failedProjectIds: [],
    showFailedProjectsDialog: false,
    projects: new Map<number, ProjectBusinessInfo>()
}

const msgTemplatesStoreInitState: IMessageTemplatesStore = {
    ...defaultSomethingStorageState,
    templates: new Map<string, MessageTemplateInfo>()
}

const msgHistoryStoreInitState: IMessageHistoryStore = {
    ...defaultSomethingStorageState,
    history: [],
    filter: { ...new EmailHistoryRequest(), PageNumber: 1, RowsPerPage: 100, IsAscending: false, CompanyId: AuthService.currentUserCompanyId() },
    countPages: 1
}

const messageStoreInitialState: IBusinessMessagesStorage = {
    ...defaultSomethingStorageState,
    historyStore: msgHistoryStoreInitState,
    templatesStore: msgTemplatesStoreInitState,
    showDeleteDialog: false,
    showShareDialog: false,
    showUnshareDialog: false,
    templateId: '',
    notificationDialogMode: MessageTemplateNotifyDialogMode.AllUsers,
    showNotificationDialog: false,
    storageType: MSG_TEMPLATES_TAB_NAME,
    showHistoryEntryDialog: false,
    historyEntry: new EmailMessageHistoryEntry()
}

export const businessDataInitialState: IGeovisBusinessDataState = {
    companiesStore: { ...businessCompaniesStoreInitialState },
    usersStore: { ...businessUsersStoreInitialState },
    projectsStore: { ...businessProjectsStoreInitialState },
    messagesStore: { ...messageStoreInitialState }
};

const businessDataReducer: Reducer<IGeovisBusinessDataState> = (
    state: IGeovisBusinessDataState = businessDataInitialState,
    action: IGeovisBusinessDataAction
): IGeovisBusinessDataState => {

    switch (action.type) {

        case LOGIN_SUCCESS:
            return businessDataInitialState;

        case GEOVIS_BUSINESS_REFRESH_STATE:
            return { ...state };

        //#region Users

        case GEOVIS_BUSINESS_USERS:
            return {
                ...state,
                usersStore: { ...businessUsersStoreInitialState }
            };

        case GEOVIS_BUSINESS_USERS_DATA: {

            if (!action.users) {
                return state;
            }

            const usersMap = elementsToMap<string, GeovisUserTableInfo>(action.users);

            return {
                ...state,
                usersStore: {
                    ...loadedSomethingStorageState,
                    users: usersMap,
                }
            }
        }

        case GEOVIS_BUSINESS_USERS_DATA_ERROR: {
            if (!action.errorDescription) {
                return state;
            }

            return {
                ...state,
                usersStore: {
                    ...businessUsersStoreInitialState,
                    isError: true,
                    errorDescription: action.errorDescription,
                    isLoaded: true,
                    isLoading: false
                }
            }
        }

        case GEOVIS_BUSINESS_USERS_CREATE_OR_UPDATE: {
            if (!action.users) {
                return state;
            }

            return {
                ...state,
                usersStore: {
                    ...state.usersStore,
                    users: addOrUpdateElementInMap(state.usersStore.users, ...action.users)
                }
            };
        }

        case GEOVIS_BUSINESS_REMOVE_USER:
            if (!action.userId) {
                return state;
            }

            return {
                ...state,
                usersStore: {
                    ...state.usersStore,
                    users: deleteElementOfMap(state.usersStore.users, action.userId)
                }
            };


        case GEOVIS_BUSINESS_USERS_SET_DIRTY_STATE:
            return {
                ...state,
                usersStore: {
                    ...state.usersStore,
                    isLoaded: false
                }
            }

        //#endregion

        //#region Companies

        case GEOVIS_BUSINESS_COMPANIES:
            return {
                ...state,
                companiesStore: { ...businessCompaniesStoreInitialState }
            };

        case GEOVIS_BUSINESS_COMPANIES_DATA: {
            if (!action.companies) {
                return state;
            }

            return {
                ...state,
                companiesStore: {
                    ...loadedSomethingStorageState,
                    companies: elementsToMap(action.companies)
                }
            }
        }

        //#endregion

        //#region Projects

        case GEOVIS_BUSINESS_PROJECTS:
            return {
                ...state,
                projectsStore: { ...businessProjectsStoreInitialState }
            };

        case GEOVIS_BUSINESS_PROJECTS_DATA:
            if (!action.projects) {
                return state;
            }

            return {
                ...state,
                projectsStore: {
                    ...loadedSomethingStorageState,
                    failedProjectIds: [],
                    showFailedProjectsDialog: false,
                    projects: elementsToMap(action.projects)
                }
            };

        case GEOVIS_INVENTORY_CALENDAR_VIEW_LOADING: {
            return {
                ...state,
                projectsStore: businessProjectsStoreInitialState
            }
        }

        case GEOVIS_INVENTORY_CALENDAR_VIEW_DATA: {
            const inventoryAction = action as IGeovisInventoryAction;
            if (!inventoryAction.calendarViewData) {
                return state;
            }

            return {
                ...state,
                companiesStore: processFetchedData(inventoryAction.calendarViewData, state.companiesStore, businessCompaniesStoreInitialState, st => ({
                    companies: elementsToMap(st.CompanyInfos)
                })),
            }
        }

        case GEOVIS_BUSINESS_PROJECTS_DATA_ERROR:
            if (!action.errorDescription) {
                return state;
            }

            return {
                ...state,
                projectsStore: {
                    ...businessProjectsStoreInitialState,
                    ...errorSomethingStorageState(action.errorDescription),
                }
            }

        case GEOVIS_BUSINESS_UPDATE_PROJECTS_PROPERTY: {
            if (!action.projectIds || !action.projectPropertyName) {
                return state;
            }

            const failedProjectIds = [...action.failedProjectIds || []];
            const showFailedProjectsDialog = action.failedProjectIds ? action.failedProjectIds.length > 0 : false;

            return {
                ...state,
                projectsStore: {
                    ...state.projectsStore,
                    projects: updatePropertyOfMapElements(state.projectsStore.projects, action.projectIds, action.projectPropertyName, action.value),
                    showFailedProjectsDialog,
                    failedProjectIds
                }
            }
        }

        case GEOVIS_BUSINESS_HIDE_FAILED_PROJECTS_DIALOG:
            return {
                ...state,
                projectsStore: {
                    ...state.projectsStore,
                    failedProjectIds: [],
                    showFailedProjectsDialog: false
                }
            };

        //#endregion

        //#region Message templates

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO:
            return {
                ...state,
                messagesStore: {
                    ...messageStoreInitialState,
                    templatesStore: {
                        ...defaultSomethingStorageState,
                        templates: new Map<string, MessageTemplateInfo>()
                    }
                }
            };

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DATA:
            if (!action.templatesInfo) {
                return state;
            }

            return {
                ...state,
                messagesStore: {
                    ...messageStoreInitialState,
                    templatesStore: {
                        ...loadedSomethingStorageState,
                        templates: elementsToMap(action.templatesInfo)
                    }
                }
            };

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ERROR:
            if (!action.errorDescription) {
                return state;
            }

            return {
                ...state,
                messagesStore: {
                    ...messageStoreInitialState,
                    templatesStore: {
                        ...errorSomethingStorageState(action.errorDescription),
                        templates: new Map<string, MessageTemplateInfo>()
                    }
                }
            };

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ADD_OR_UPDATE:
            if (!action.templatesInfo || action.templatesInfo.length === 0) {
                return state;
            }

            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    templatesStore: {
                        ...state.messagesStore.templatesStore,
                        templates: addOrUpdateElementInMap(state.messagesStore.templatesStore.templates, ...action.templatesInfo)
                    }
                }
            };

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DELETE:
            if (!action.stringProperties || action.stringProperties.length === 0) {
                return state;
            }

            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    templatesStore: {
                        ...state.messagesStore.templatesStore,
                        templates: deleteElementsOfMap(state.messagesStore.templatesStore.templates, ...action.stringProperties)
                    }

                }
            }

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_DELETE_DIALOG:
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    showDeleteDialog: true,
                    templateId: action.stringProperty
                }
            };

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_SHARE_DIALOG:
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    showShareDialog: true,
                    templateId: action.stringProperty
                }
            };

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_UNSHARE_DIALOG:
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    showUnshareDialog: true,
                    templateId: action.stringProperty
                }
            };

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_HIDE_DIALOG:
            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    showDeleteDialog: false,
                    showShareDialog: false,
                    showUnshareDialog: false,
                    templateId: ''
                }
            };

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_SHARE_BETWEEN: {
            if (!action.stringProperty || !action.stringProperties || action.stringProperties.length === 0) {
                return state;
            }

            const template = state.messagesStore.templatesStore.templates.get(action.stringProperty);

            if (!template) {
                return state;
            }

            const shareTo = distinctArray(...template.SharedToUserIds, ...action.stringProperties);

            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    templatesStore: {
                        ...state.messagesStore.templatesStore,
                        templates: updateElementPropertiesInMap(state.messagesStore.templatesStore.templates, action.stringProperty, { SharedToUserIds: shareTo }),
                    },
                    showShareDialog: false,
                    templateId: ''
                }
            }
        }

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_UNSHARE_FOR: {
            if (!action.stringProperty || !action.stringProperties || action.stringProperties.length === 0) {
                return state;
            }

            const template = state.messagesStore.templatesStore.templates.get(action.stringProperty);

            if (!template) {
                return state;
            }

            // exclude users, which are not in stringProperties of this action
            const shareTo = template.SharedToUserIds.filter(id => action.stringProperties?.indexOf(id) === -1);

            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    templatesStore: {
                        ...state.messagesStore.templatesStore,
                        templates: updateElementPropertiesInMap(state.messagesStore.templatesStore.templates, action.stringProperty, { SharedToUserIds: shareTo }),
                    },
                    showUnshareDialog: false,
                    templateId: ''
                }
            };
        }

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATE_SHOW_NOTIFICATION_DIALOG: {
            if (action.stringProperty === undefined || action.notificationDialogMode === undefined) {
                return state;
            }
            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    templateId: action.stringProperty,
                    notificationDialogMode: action.notificationDialogMode,
                    showNotificationDialog: true
                }
            }
        }

        case GEOVIS_BUSINESS_MESSAGE_TEMPLATE_HIDE_NOTIFICATION_DIALOG:
            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    templateId: '',
                    showNotificationDialog: false
                }
            };

        case GEOVIS_BUSINESS_MESSAGE_CHANGE_TAB_NAME:
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    storageType: action.stringProperty
                }
            }

        case GEOVIS_BUSINESS_MESSAGE_HISTORY: {
            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    historyStore: {
                        ...state.messagesStore.historyStore,
                        ...defaultSomethingStorageState,
                        history: []
                    }
                }
            }
        }
        case GEOVIS_BUSINESS_MESSAGE_HISTORY_ERROR: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    historyStore: {
                        ...state.messagesStore.historyStore,
                        ...errorSomethingStorageState(action.stringProperty),
                        history: [],
                    }
                }
            }
        }
        case GEOVIS_BUSINESS_MESSAGE_HISTORY_HIDE_ITEM: {
            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    showHistoryEntryDialog: false,
                    historyEntry: new EmailMessageHistoryEntry()
                }
            }
        }
        case GEOVIS_BUSINESS_MESSAGE_HISTORY_LOADED: {
            if (!action.messageHistoryData || !action.numberProperty) {
                return state;
            }
            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    historyStore: {
                        ...state.messagesStore.historyStore,
                        ...loadedSomethingStorageState,
                        history: action.messageHistoryData,
                        countPages: action.numberProperty
                    }
                }
            }
        }
        case GEOVIS_BUSINESS_MESSAGE_HISTORY_SHOW_ITEM: {
            if (!action.messageHistoryEntry) {
                return state;
            }
            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    showHistoryEntryDialog: true,
                    historyEntry: action.messageHistoryEntry
                }
            }
        }
        case GEOVIS_BUSINESS_MESSAGE_HISTORY_FILTER_CHANGED: {
            if (!action.historyFilter) {
                return state;
            }

            return {
                ...state,
                messagesStore: {
                    ...state.messagesStore,
                    historyStore: {
                        ...state.messagesStore.historyStore,
                        filter: action.historyFilter
                    }
                }
            }
        }

        //#endregion
    }

    return state;
}

export default businessDataReducer;