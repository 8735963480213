//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TimePeriod {
	Hour1 = 0,
	Hour2 = 1,
	Hour6 = 2,
	Hour12 = 3,
	Day1 = 4,
	Day2 = 5,
	Day4 = 6,
	Week1 = 7,
	Week2 = 8,
	Month1 = 9,
	Month2 = 10,
	Month3 = 11,
	Month6 = 12,
	Year1 = 100,
	Year2 = 200,
	Year3 = 300,
	Year5 = 500,
	Year10 = 600,
	Custom = 1000
}

const TimePeriodToName = new Map<number, string>([
	[ 1000, 'Custom' ],
	[ 0, 'Hour1' ],
	[ 1, 'Hour2' ],
	[ 2, 'Hour6' ],
	[ 3, 'Hour12' ],
	[ 4, 'Day1' ],
	[ 5, 'Day2' ],
	[ 6, 'Day4' ],
	[ 7, 'Week1' ],
	[ 8, 'Week2' ],
	[ 9, 'Month1' ],
	[ 10, 'Month2' ],
	[ 11, 'Month3' ],
	[ 12, 'Month6' ],
	[ 100, 'Year1' ],
	[ 200, 'Year2' ],
	[ 300, 'Year3' ],
	[ 500, 'Year5' ],
	[ 600, 'Year10' ],
])

export function getTimePeriodToName(element: TimePeriod) : string {
    return TimePeriodToName.get(element) || '';
}

const TimePeriodByName = new Map<string, number>([
	[ 'Custom', 1000 ],
	[ 'Hour1', 0 ],
	[ 'Hour2', 1 ],
	[ 'Hour6', 2 ],
	[ 'Hour12', 3 ],
	[ 'Day1', 4 ],
	[ 'Day2', 5 ],
	[ 'Day4', 6 ],
	[ 'Week1', 7 ],
	[ 'Week2', 8 ],
	[ 'Month1', 9 ],
	[ 'Month2', 10 ],
	[ 'Month3', 11 ],
	[ 'Month6', 12 ],
	[ 'Year1', 100 ],
	[ 'Year2', 200 ],
	[ 'Year3', 300 ],
	[ 'Year5', 500 ],
	[ 'Year10', 600 ],
]);

export function getTimePeriodByName(name: string): TimePeriod {
    return TimePeriodByName.get(name)!;
}


export const TimePeriodList : Readonly<TimePeriod[]> = [
	TimePeriod.Custom,
	TimePeriod.Hour1,
	TimePeriod.Hour2,
	TimePeriod.Hour6,
	TimePeriod.Hour12,
	TimePeriod.Day1,
	TimePeriod.Day2,
	TimePeriod.Day4,
	TimePeriod.Week1,
	TimePeriod.Week2,
	TimePeriod.Month1,
	TimePeriod.Month2,
	TimePeriod.Month3,
	TimePeriod.Month6,
	TimePeriod.Year1,
	TimePeriod.Year2,
	TimePeriod.Year3,
	TimePeriod.Year5,
	TimePeriod.Year10,
];



const TimePeriodToDescription = new Map<number, string>([
	[ 1000, 'Custom' ],
	[ 0, '1 hour' ],
	[ 1, '2 hours' ],
	[ 2, '6 hours' ],
	[ 3, '12 hours' ],
	[ 4, '1 day' ],
	[ 5, '2 days' ],
	[ 6, '4 days' ],
	[ 7, '1 week' ],
	[ 8, '2 weeks' ],
	[ 9, '1 month' ],
	[ 10, '2 months' ],
	[ 11, '3 months' ],
	[ 12, '6 months' ],
	[ 100, '1 year' ],
	[ 200, '2 years' ],
	[ 300, '3 years' ],
	[ 500, '5 years' ],
	[ 600, '10 years' ],
])

export function getTimePeriodToDescription(element: TimePeriod) : string {
    return TimePeriodToDescription.get(element) || '';
}

