import { SortOrderType } from "@atlaskit/dynamic-table/dist/types/types";
import { GeovisCompanyType } from "../server/AVTService/TypeLibrary/Common/GeovisCompanyType";
import { LogoFileInfo } from "../server/AVTService/TypeLibrary/Logo/LogoFileInfo";
import { ProjectSupportMessageTemplate } from "../server/AVTService/TypeLibrary/ProjectSupport/ProjectSupportMessageTemplate";
import { CompanyRelatedData } from "../server/CompanyRelatedData";
import { CompanyInfo } from "../server/GEOvis3/Model/Company/CompanyInfo";
import { CompanyOwnData } from "../server/GEOvis3/Model/Company/CompanyOwnData";
import { IGeovisLogoEditAction, ILogoEditState } from "./edit.types";
import { ILogosFileStorage } from "./logosManager.types";
import { ISomethingStoreBase } from "./types";

/**
 * Action
 */
export interface IGeovisCompaniesAction extends IGeovisLogoEditAction {
    company?: CompanyInfo;
    companies?: CompanyInfo[];
    rowsPerPage?: number;
    companyId?: string;
    searchQuery?: string;
    companyRelatedData?: CompanyRelatedData[];
    companyOwnData?: CompanyOwnData;
    logoFilesInfo?: LogoFileInfo[];
    supportMessageTemplates?: ProjectSupportMessageTemplate[];
    page?: number;
    companyType?: GeovisCompanyType;
    sortKey?: string;
    sortOrder?: SortOrderType;
}

/**
 * State
 */
export interface ICompanyStorage extends ISomethingStoreBase {
    company: CompanyInfo;
    removingInProgress: boolean;
}

export interface ICompaniesStorage extends ISomethingStoreBase {
    companies: CompanyInfo[];
}

export interface ICompanyRelatedDataStorage extends ISomethingStoreBase {
    companyRelatedData: CompanyRelatedData[];
}

export interface ICompanyOwnDataStorage extends ISomethingStoreBase {
    companyOwnData: CompanyOwnData;
}

export interface ICompanySupportMessageTemplatesStorage extends ISomethingStoreBase {
    supportMessageTemplates: ProjectSupportMessageTemplate[];
}

export interface IGeovisCompaniesDataState {
    companyStorage: ICompanyStorage;
    relatedCompaniesStorage: ICompaniesStorage;
    rowsPerPage: number;
    addCompanyStorage: ICompaniesStorage;
    showEditDialog: boolean;
    showDeleteDialog: boolean;
    searchQuery: string;
    companyRelatedDataStorage: ICompanyRelatedDataStorage;
    companyOwnDataStorage: ICompanyOwnDataStorage;
    logoEditState: ILogoEditState;
    logosFileStorage: ILogosFileStorage;
    companySupportMessageTemplatesStorage: ICompanySupportMessageTemplatesStorage;

    rowsPerPages: Map<GeovisCompanyType, number>;
    currentPages: Map<GeovisCompanyType, number>;
    sortKeys: Map<GeovisCompanyType, string>;
    sortOrders: Map<GeovisCompanyType, SortOrderType>;
}

export const companyInitialState: CompanyInfo = {
    ...new CompanyInfo,
    CompanyType: GeovisCompanyType.Viewer,
    DolphinId: ""
}