/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 15.07.2020
 * @description Business data actions, like for users and companies data
 */

export const GEOVIS_BUSINESS_REFRESH_STATE = "GEOVIS_BUSINESS_REFRESH_STATE";

export const GEOVIS_BUSINESS_USERS = "GEOVIS_BUSINESS_USERS";
export const GEOVIS_BUSINESS_USERS_DATA = "GEOVIS_BUSINESS_USERS_DATA";
export const GEOVIS_BUSINESS_USERS_DATA_ERROR = 'GEOVIS_BUSINESS_USERS_DATA_ERROR';
export const GEOVIS_BUSINESS_USERS_CREATE_OR_UPDATE = "GEOVIS_BUSINESS_USERS_CREATE_OR_UPDATE";
export const GEOVIS_BUSINESS_REMOVE_USER = "GEOVIS_BUSINESS_REMOVE_USER";
export const GEOVIS_BUSINESS_USERS_SET_DIRTY_STATE = "GEOVIS_BUSINESS_USERS_SET_DIRTY_STATE";

export const GEOVIS_BUSINESS_COMPANIES = "GEOVIS_BUSINESS_COMPANIES";
export const GEOVIS_BUSINESS_COMPANIES_DATA = "GEOVIS_BUSINESS_COMPANIES_DATA";
export const GEOVIS_BUSINESS_COMPANIES_DATA_ERROR = 'GEOVIS_BUSINESS_COMPANIES_DATA_ERROR';


export const GEOVIS_BUSINESS_PROJECTS = 'GEOVIS_BUSINESS_PROJECTS';
export const GEOVIS_BUSINESS_PROJECTS_DATA = 'GEOVIS_BUSINESS_PROJECTS_DATA';
export const GEOVIS_BUSINESS_PROJECTS_DATA_ERROR = 'GEOVIS_BUSINESS_PROJECTS_DATA_ERROR';
export const GEOVIS_BUSINESS_UPDATE_PROJECTS_PROPERTY = 'GEOVIS_BUSINESS_UPDATE_PROJECTS_PROPERTY';
export const GEOVIS_BUSINESS_HIDE_FAILED_PROJECTS_DIALOG = 'GEOVIS_BUSINESS_HIDE_FAILED_PROJECTS_DIALOG';

export const GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DATA = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DATA';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ERROR = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ERROR';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ADD_OR_UPDATE = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_ADD_OR_UPDATE';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DELETE = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_DELETE';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_DELETE_DIALOG = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_DELETE_DIALOG';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_SHARE_DIALOG = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_SHARE_DIALOG';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_UNSHARE_DIALOG = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_SHOW_UNSHARE_DIALOG';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_HIDE_DIALOG = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATES_INFO_HIDE_DIALOG';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_SHARE_BETWEEN = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_SHARE_BETWEEN';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_UNSHARE_FOR = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATE_INFO_UNSHARE_FOR';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATE_SHOW_NOTIFICATION_DIALOG = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATE_SHOW_NOTIFICATION_DIALOG';
export const GEOVIS_BUSINESS_MESSAGE_TEMPLATE_HIDE_NOTIFICATION_DIALOG = 'GEOVIS_BUSINESS_MESSAGE_TEMPLATE_HIDE_NOTIFICATION_DIALOG';

export const GEOVIS_BUSINESS_MESSAGE_CHANGE_TAB_NAME = 'GEOVIS_BUSINESS_MESSAGE_CHANGE_TAB_NAME';

export const GEOVIS_BUSINESS_MESSAGE_HISTORY = 'GEOVIS_BUSINESS_MESSAGE_HISTORY';
export const GEOVIS_BUSINESS_MESSAGE_HISTORY_LOADED = 'GEOVIS_BUSINESS_MESSAGE_HISTORY_LOADED';
export const GEOVIS_BUSINESS_MESSAGE_HISTORY_ERROR = 'GEOVIS_BUSINESS_MESSAGE_HISTORY_ERROR';
export const GEOVIS_BUSINESS_MESSAGE_HISTORY_SHOW_ITEM = 'GEOVIS_BUSINESS_MESSAGE_HISTORY_SHOW_ITEM';
export const GEOVIS_BUSINESS_MESSAGE_HISTORY_HIDE_ITEM = 'GEOVIS_BUSINESS_MESSAGE_HISTORY_HIDE_ITEM';
export const GEOVIS_BUSINESS_MESSAGE_HISTORY_FILTER_CHANGED = 'GEOVIS_BUSINESS_MESSAGE_HISTORY_FILTER_CHANGED';
