//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AlarmActionType {
	Dolphin = 0,
	Email = 1,
	Text = 3,
	AgmsDigitalOutput = 4,
	Sms = 5
}

const AlarmActionTypeToName = new Map<number, string>([
	[ 0, 'Dolphin' ],
	[ 1, 'Email' ],
	[ 3, 'Text' ],
	[ 4, 'AgmsDigitalOutput' ],
	[ 5, 'Sms' ],
])

export function getAlarmActionTypeToName(element: AlarmActionType) : string {
    return AlarmActionTypeToName.get(element) || '';
}

const AlarmActionTypeByName = new Map<string, number>([
	[ 'Dolphin', 0 ],
	[ 'Email', 1 ],
	[ 'Text', 3 ],
	[ 'AgmsDigitalOutput', 4 ],
	[ 'Sms', 5 ],
]);

export function getAlarmActionTypeByName(name: string): AlarmActionType {
    return AlarmActionTypeByName.get(name)!;
}


export const AlarmActionTypeList : Readonly<AlarmActionType[]> = [
	AlarmActionType.Dolphin,
	AlarmActionType.Email,
	AlarmActionType.Text,
	AlarmActionType.AgmsDigitalOutput,
	AlarmActionType.Sms,
];



const AlarmActionTypeToDescription = new Map<number, string>([
	[ 0, 'F24' ],
	[ 1, 'Email' ],
	[ 3, 'Text' ],
	[ 4, 'Digital output' ],
	[ 5, 'Sms' ],
])

export function getAlarmActionTypeToDescription(element: AlarmActionType) : string {
    return AlarmActionTypeToDescription.get(element) || '';
}

