import { Polyline } from "react-leaflet";
import { MapElementMovementSettings } from "../../../server/AVTService/TypeLibrary/Map/MapElementMovementSettings";
import { MovementVectorsPaneName } from '../../map/GeovisMap.PaneCreator';
import { IMovementVectorInfo } from './types';

interface IComponentProps {
    vectorInfo: IMovementVectorInfo;
    vectorMovementSettings: MapElementMovementSettings;
}

const Component = ({ vectorInfo, vectorMovementSettings }: IComponentProps) => {

    const keyPrefix = `sensor_movement_vector_${vectorInfo.sensorFullId}`;
    return (
        <div key={`${keyPrefix}-div`} id={`${keyPrefix}-div`}>
            <Polyline
                key={`${keyPrefix}-main-line`}
                positions={[vectorInfo.startPosition, vectorInfo.endPosition]}
                color={vectorInfo.color}
                weight={vectorMovementSettings.Weight}
                className="movement-vector-line"
                pane={MovementVectorsPaneName}
            />
            <Polyline
                key={`${keyPrefix}-arrow-end1`}
                positions={[vectorInfo.endPosition, vectorInfo.arrowEndPosition1]}
                color={vectorInfo.color}
                weight={vectorMovementSettings.Weight}
                className="movement-vector-line"
                pane={MovementVectorsPaneName}
            />
            <Polyline
                key={`${keyPrefix}-arrow-end2`}
                positions={[vectorInfo.endPosition, vectorInfo.arrowEndPosition2]}
                color={vectorInfo.color}
                weight={vectorMovementSettings.Weight}
                className="movement-vector-line"
                pane={MovementVectorsPaneName}
            />
        </div>
    )
}

export default Component;