//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeoLocation } from './GeoLocation';
import { DistanceUnit } from './AVTService/TypeLibrary/Common/DistanceUnit';

export abstract class GeoPoint
{
	public Coordinates: GeoLocation;
	public Km: number;
	public DistanceUnit: DistanceUnit;
}
