import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import TextArea from "@atlaskit/textarea";
import { useRef } from "react";
import { IWithGeovisServerProps, withGeovisServer } from "../../helpers/GeovisHooks";
import { t } from "../../i18n";
import { GeovisApplicationFeedbackModel } from "../../server/AVTService/TypeLibrary/Model/ApplicationFeedback/GeovisApplicationFeedbackModel";
import { GeovisFeedbackType } from "../../server/AVTService/TypeLibrary/Model/ApplicationFeedback/GeovisFeedbackType";
import { FeedbackState } from "../../server/AVTService/TypeLibrary/Common/FeedbackState";
import ServerRoutesGen from "../../server/Routes/ServerRoutesGen";
import { sendServerPostRequestData } from "../../helpers/ProjectDataHelper";
import { DataActionResponse } from "../../server/DataActionResponse";
import FlagService from "../../services/FlagService";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IComponentProps extends IWithGeovisServerProps {
    error?: Error;
    componentStack?: string;
    projectId?: number;
    onClose: (feedbackId: string) => void;
}

const GeovisErrorReportDialog = ({
    Server,
    componentStack,
    error,
    projectId,
    onClose
}: IComponentProps) => {

    const taRef = useRef<HTMLTextAreaElement>(null);

    const onSendFeedbackClick = async () => {
        let commentToSend = "";
        if (taRef.current !== null) {
            commentToSend = taRef.current.value;
        }

        const feedback: GeovisApplicationFeedbackModel = {
            ...new GeovisApplicationFeedbackModel(),
            FeedbackType: GeovisFeedbackType.Application,
            State: FeedbackState.Open,
            ComponentStack: componentStack ? componentStack : "",
            Comment: commentToSend
        };

        if (error) {
            feedback.Name = error.name;
            feedback.ErrorMessage = error.message;
            feedback.ErrorStack = error.stack ? error.stack : "";
        }

        if (projectId) {
            feedback.ProjectId = projectId;
        }

        const url = ServerRoutesGen.GeovisFeedback.RegisterRecord;
        const response = await sendServerPostRequestData<GeovisApplicationFeedbackModel, DataActionResponse<string>>(Server, url, feedback);

        if (!response.Success) {
            FlagService.addErrors("Failed to send feedback", response.Messages);
        }
        else {
            onClose(response.Data);
        }
    }

    return (
        <ModalTransition>
            <Modal
                heading={t("Unexpected error report")}
                actions={[{ text: t("Send"), appearance: 'primary', onClick: onSendFeedbackClick }]}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <span>{t("Please add some information about how to reproduce this issue")}</span>
                    <div>
                        <TextArea ref={taRef} />
                    </div>
                </div>
            </Modal>
        </ModalTransition>
    )
}

export default withGeovisServer(GeovisErrorReportDialog);