//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AlarmType {
	AGMSAlarm = 0,
	WDSystemAlarm = 1,
	WDImportAlarm = 2,
	WDSensorAlarm = 3,
	SensorAlarm = 4
}

const AlarmTypeToName = new Map<number, string>([
	[ 0, 'AGMSAlarm' ],
	[ 1, 'WDSystemAlarm' ],
	[ 2, 'WDImportAlarm' ],
	[ 3, 'WDSensorAlarm' ],
	[ 4, 'SensorAlarm' ],
])

export function getAlarmTypeToName(element: AlarmType) : string {
    return AlarmTypeToName.get(element) || '';
}

const AlarmTypeByName = new Map<string, number>([
	[ 'AGMSAlarm', 0 ],
	[ 'WDSystemAlarm', 1 ],
	[ 'WDImportAlarm', 2 ],
	[ 'WDSensorAlarm', 3 ],
	[ 'SensorAlarm', 4 ],
]);

export function getAlarmTypeByName(name: string): AlarmType {
    return AlarmTypeByName.get(name)!;
}


export const AlarmTypeList : Readonly<AlarmType[]> = [
	AlarmType.AGMSAlarm,
	AlarmType.WDSystemAlarm,
	AlarmType.WDImportAlarm,
	AlarmType.WDSensorAlarm,
	AlarmType.SensorAlarm,
];



const AlarmTypeToDescription = new Map<number, string>([
	[ 0, 'AGMSAlarm' ],
	[ 1, 'WDSystemAlarm' ],
	[ 2, 'WDImportAlarm' ],
	[ 3, 'WDSensorAlarm' ],
	[ 4, 'SensorAlarm' ],
])

export function getAlarmTypeToDescription(element: AlarmType) : string {
    return AlarmTypeToDescription.get(element) || '';
}

