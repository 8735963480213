//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class ChartBackground
{
	public enabled: boolean;
	public color: string;
	public from: string;
	public to: string;
	public weekDays: number[];
}
