/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 08.05.2020
 * @description Loading page error skeleton
 */

import Constants from '../helpers/Constants';
import { t } from '../i18n';

interface ILoadingPageErrorSkeleton {
    errorText?: string;
    errorDescription?: string;
}

export const LoadingPageErrorSkeleton = ({ errorText, errorDescription }: ILoadingPageErrorSkeleton) => (
    <div style={{
        display: 'flex',
        flexFlow: 'column',
        fontWeight: 500,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 32,
        color: Constants.errorColor,
        width: '100%',
        height: '100%'
    }}>
        <div className='flexRowContainer' style={{ alignSelf: 'center' }}>
            {errorText && t(errorText)}
            {!errorText && t("Error loading page data")}
        </div>
        {errorDescription && (
            <div className='flexRowContainer' style={{ alignSelf: 'center' }}>
                <span>{errorDescription}</span>
            </div>
        )}
    </div>
)