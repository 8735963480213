/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.08.2022
 * @description Tool methods for chart renders
 */

import { formattedDateTime } from "../../../../../helpers/DateHelper";
import { ChartType } from "../../../../../server/AVTService/TypeLibrary/Common/ChartType";
import { GeovisChartData } from "../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartData";
import { GeovisChartModel } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { IGeovisChartReportData, IGeovisChartReportInfo, IGeovisReportSettings } from "../../../../../store/projectReport.types";

/**
 * 
 * @param reportSettings 
 * @param pageNum 
 * @param chartId 
 * @returns 
 */
export const getGeovisChartInfo = (reportSettings: IGeovisReportSettings, pageNum: number, chartId: number): IGeovisChartReportInfo<GeovisChartModel> | undefined => {

    const page = reportSettings.geovisPages.get(pageNum);
    if (!page) {
        return undefined;
    }

    return page.geovisCharts.get(chartId);
}

export const getChartDataIsLoading = (reportSettings: IGeovisReportSettings, pageNum: number, chartId: number, isVibrationEvent: boolean): boolean | undefined => {
    const pageData = reportSettings.geovisPagesData.get(pageNum);
    if (!pageData) {
        return undefined;
    }
    const chartData = isVibrationEvent ? pageData.geovisEventChartsData.get(chartId) : pageData.geovisChartsData.get(chartId);
    if (!chartData) {
        return undefined;
    }

    return chartData.isLoading;
}

/**
 * Get chart type
 * @param pageNum
 * @param chartId 
 * @param reportSettings
 * @returns 
 */
export const getChartType = (pageNum: number, chartId: number, reportSettings: IGeovisReportSettings): ChartType | undefined => {
    const chartInfo = getGeovisChartInfo(reportSettings, pageNum, chartId);
    if (!chartInfo) {
        return undefined;
    }

    return chartInfo.Chart.Type;
}

/**
 * Get geovis chart data
 * @param reportSettings 
 * @param pageNum 
 * @param chartId 
 * @returns 
 */
export const getGeovisChartData = <TData extends GeovisChartData>(reportSettings: IGeovisReportSettings, pageNum: number, chartId: number): IGeovisChartReportData<TData> | undefined => {
    const page = reportSettings.geovisPagesData.get(pageNum);
    if (!page) {
        return undefined;
    }

    return page.geovisChartsData.get(chartId) as IGeovisChartReportData<TData>;
}

const TimeslotTemplate = "{timeslot}";

export const processTitleTimeslotTemplate = (title: string, timeslotValue: string) => {
    const formattedTimeslot = formattedDateTime(timeslotValue);
    return title.replace(TimeslotTemplate, formattedTimeslot);
}

/**
 * Update the chart title in the report if needed
 * @param chart 
 * @returns 
 */
export const getReportChartTitle = (chart: GeovisChartModel, globalTimeslot: string | undefined) => {
    const { Title, Type } = chart;
    return Type === ChartType.HeatMap && globalTimeslot ? processTitleTimeslotTemplate(Title, globalTimeslot) : Title;
}