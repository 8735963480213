//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ArchivingTargetType {
	LocalFolder = 1,
	FTP = 2,
	DirectDownload = 3
}

const ArchivingTargetTypeToName = new Map<number, string>([
	[ 1, 'LocalFolder' ],
	[ 2, 'FTP' ],
	[ 3, 'DirectDownload' ],
])

export function getArchivingTargetTypeToName(element: ArchivingTargetType) : string {
    return ArchivingTargetTypeToName.get(element) || '';
}

const ArchivingTargetTypeByName = new Map<string, number>([
	[ 'LocalFolder', 1 ],
	[ 'FTP', 2 ],
	[ 'DirectDownload', 3 ],
]);

export function getArchivingTargetTypeByName(name: string): ArchivingTargetType {
    return ArchivingTargetTypeByName.get(name)!;
}


export const ArchivingTargetTypeList : Readonly<ArchivingTargetType[]> = [
	ArchivingTargetType.LocalFolder,
	ArchivingTargetType.FTP,
	ArchivingTargetType.DirectDownload,
];



const ArchivingTargetTypeToDescription = new Map<number, string>([
	[ 1, 'Local folder' ],
	[ 2, 'FTP' ],
	[ 3, 'Direct download' ],
])

export function getArchivingTargetTypeToDescription(element: ArchivingTargetType) : string {
    return ArchivingTargetTypeToDescription.get(element) || '';
}

