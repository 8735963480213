//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { PresetUser } from './PresetUser';

export class AlarmActionUsersPreset
{
	public Id: string;
	public ProjectId: number;
	public Name: string;
	public ConditionsCount: number;
	public Users: PresetUser[];
}
