/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 05.05.2022
 * @description The trick to introduce 'contour' series option
 */

export function setHeatmapContourSeriesOptions(heatmapOptions) {

    heatmapOptions.series = heatmapOptions.series.map(s=> ({...s, type: 'contour'}));

    return heatmapOptions;
}