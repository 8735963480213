import { formattedDateTime } from "../../../../../../../helpers/DateHelper";
import { t } from "../../../../../../../i18n";
import { XYVibrationChartModel } from "../../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XYVibrationChartModel";

interface IComponentProps {
    chart: XYVibrationChartModel;
    IntensivityToDates: { [key: number]: string };
    timeslotTo: string;
    timeslotFrom: string
}

export const XYVibrationChartTimeline = ({
    chart,
    IntensivityToDates,
    timeslotFrom,
    timeslotTo
}: IComponentProps) => {

    if (!chart.ShowTimeslotsInLegend) {
        return null;
    }

    return (
        <div className="flexRowContainer" style={{ marginLeft: '20px', marginTop: '10px' }}>
            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
                <span style={{ fontSize: 'large' }}>{t("The intensity shows the age of the data")}:</span>
                <div style={{ height: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <div style={{ height: '100%', color: 'gray', opacity: 0.25, background: 'gray', width: '270px' }}>
                        {/* Category 6 */}
                    </div>
                    <div style={{ height: '100%', color: 'gray', opacity: 0.4, background: 'gray', width: '180px' }}>
                        {/* Category 5 */}
                    </div>
                    <div style={{ height: '100%', color: 'gray', opacity: 0.5, background: 'gray', width: '180px' }}>
                        {/* Category 4 */}
                    </div>
                    <div style={{ height: '100%', color: 'gray', opacity: 0.7, background: 'gray', width: '135px' }}>
                        {/* Category 3 */}
                    </div>
                    <div style={{ height: '100%', color: 'gray', opacity: 0.85, background: 'gray', width: '90px' }}>
                        {/* Category 2 */}
                    </div>
                    <div style={{ height: '100%', color: 'gray', opacity: 1, background: 'gray', width: '60px' }}>
                        {/* Category 1 */}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '170px' }}>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(timeslotFrom, "DD.MM.YYYY")}</span>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(timeslotFrom, "HH:mm")}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '115px' }}>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(IntensivityToDates[1], "DD.MM.YYYY")}</span>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(IntensivityToDates[1], "HH:mm")}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '115px' }}>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(IntensivityToDates[2], "DD.MM.YYYY")}</span>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(IntensivityToDates[2], "HH:mm")}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '70px' }}>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(IntensivityToDates[3], "DD.MM.YYYY")}</span>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(IntensivityToDates[3], "HH:mm")}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '20px' }}>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(IntensivityToDates[4], "DD.MM.YYYY")}</span>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(IntensivityToDates[4], "HH:mm")}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(IntensivityToDates[5], "DD.MM.YYYY")}</span>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(IntensivityToDates[5], "HH:mm")}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(timeslotTo, "DD.MM.YYYY")}</span>
                        <span style={{ fontSize: 'small' }}>{formattedDateTime(timeslotTo, "HH:mm")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}