//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { LocalMapObject } from './AVTService/TypeLibrary/LocalMapObjects/LocalMapObject';
import { GeoLocation } from './GeoLocation';

export class LocalMapObjectDocument extends LocalMapObject
{
	public DocumentId: string;
	public DocumentName: string;
	public Location: GeoLocation;
}
