/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 20.05.2021
 * @description Project LoRa ui reducer
 */

import { mutateTree } from '@atlaskit/tree';
import { Reducer } from 'redux'
import { GeovisLoRaRootConfiguration } from '../../server/GEOvis3/Model/LoRa/GeovisLoRaRootConfiguration';
import {
    PROJECT_LORA_ROOT_CONFIGURATION,
    PROJECT_LORA_ROOT_CONFIGURATION_DATA
} from '../actions/dataActions';
import {
    PROJECT_LORA_ADD_ALG_INFO,
    PROJECT_LORA_ADD_ALN_INFO,
    PROJECT_LORA_COLLAPSE_TREE_ITEM,
    PROJECT_LORA_EXPAND_TREE_ITEM,
    PROJECT_LORA_EXPAND_TREE_ITEM_AFTER_NAVIGATION,
    PROJECT_LORA_HIDE_DIALOG,
    PROJECT_LORA_HIDE_EDIT_ALN_NAME_DIALOG,
    PROJECT_LORA_INIT_SWISSCOM_ALG_DIALOG,
    PROJECT_LORA_REMOVE_ALG_INFO,
    PROJECT_LORA_REMOVE_ALN_INFO,
    PROJECT_LORA_SENSOR_CONFIGS_LOADED,
    PROJECT_LORA_SENSOR_CONFIGS_LOADING,
    PROJECT_LORA_SENSOR_CONFIGS_LOADING_ERROR,
    PROJECT_LORA_SENSOR_CONFIG_CHANGED,
    PROJECT_LORA_SHOW_ADD_ALG_DIALOG,
    PROJECT_LORA_SHOW_ADD_ALN_DIALOG,
    PROJECT_LORA_SHOW_EDIT_ALN_NAME_DIALOG,
    PROJECT_LORA_SHOW_REMOVE_ALG_DIALOG,
    PROJECT_LORA_SHOW_REMOVE_ALN_DIALOG,
    PROJECT_LORA_UPDATE_ALG_INFO,
    PROJECT_LORA_UPDATE_ALN_INFO,
    PROJECT_LORA_UPDATE_TREE
} from '../actions/projectLoRaActions';
import { IGeovisProjectDataActionEx } from '../data.types';
import {
    createAlnTreeItem,
    createGatewayTreeItem,
    createLoRaTreeData,
    expandLoRaTreeItems,
    getAlgTreeId,
    getRootTreeId,
    removeAlnTreeItem,
    removeTreeItem,
    updateAlnTreeItem,
    updateGatewayTreeItem
} from '../helpers/LoRaHelper';
import { IGeovisProjectLoRaState, IProjectLoRaAction } from '../projectLoRa.types';
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from '../types';
import { GeovisLoRaSensorConfig } from '../../server/Geovis/LoRa/Model/GeovisLoRaSensorConfig';
import { elementsToMapOfCustomKey } from '../../helpers/StorageHelper';
import { ALNDeviceConfig } from '../../server/AVTService/TypeLibrary/LoRa/Config/v3/SensorConfig/ALNDeviceConfig';

export const projectLoRaInitialState: IGeovisProjectLoRaState = {
    loraObjectsTreeData: { items: {}, rootId: '' },
    showCreateAlgDialog: false,
    showCreateAlnDialog: false,
    showRemoveAlgDialog: false,
    showRemoveAlnDialog: false,
    showEditAlnNameDialog: false,
    showInitSwisscomAlgDialog: false,
    algId: '',
    alnId: '',
    sensorConfigStorage: {
        ...defaultSomethingStorageState,
        sensorConfigMap: new Map<string, GeovisLoRaSensorConfig>()
    }
};

const updateSensorConfigInMap = (sensorConfigMap: Map<string, GeovisLoRaSensorConfig>, newConfig: ALNDeviceConfig) => {
    const key = newConfig.Name;
    const targetElement = sensorConfigMap.get(key);
    if(!targetElement){
        return sensorConfigMap;
    }

    targetElement.LoRaConfig = newConfig;
    sensorConfigMap.set(newConfig.Name, targetElement);

    return sensorConfigMap;
}

const projectLoRaReducer: Reducer<IGeovisProjectLoRaState> = (
    state: IGeovisProjectLoRaState = projectLoRaInitialState,
    action: IProjectLoRaAction
): IGeovisProjectLoRaState => {

    switch (action.type) {
        case PROJECT_LORA_ROOT_CONFIGURATION:
            return projectLoRaInitialState;

        case PROJECT_LORA_ROOT_CONFIGURATION_DATA: {
            const dataAction = action as IGeovisProjectDataActionEx<GeovisLoRaRootConfiguration>;
            if (!dataAction.data || !dataAction.data.Data) {
                return state;
            }

            return {
                ...state,
                loraObjectsTreeData: createLoRaTreeData(dataAction.data.Data.Gateways)
            }
        }

        case PROJECT_LORA_UPDATE_TREE:
            if (!action.treeData) {
                return state;
            }

            return { ...state, loraObjectsTreeData: { ...action.treeData } }


        case PROJECT_LORA_COLLAPSE_TREE_ITEM:
        case PROJECT_LORA_EXPAND_TREE_ITEM: {

            if (!action.itemId) {
                return state;
            }

            const loraObjectsTreeData = mutateTree(state.loraObjectsTreeData, action.itemId, { isExpanded: action.type === PROJECT_LORA_EXPAND_TREE_ITEM });

            return { ...state, loraObjectsTreeData }
        }

        case PROJECT_LORA_EXPAND_TREE_ITEM_AFTER_NAVIGATION:
            if (!action.loraRouteParams) {
                return state;
            }

            return {
                ...state,
                loraObjectsTreeData: expandLoRaTreeItems(state.loraObjectsTreeData, action.loraRouteParams)
            }

        case PROJECT_LORA_SHOW_ADD_ALG_DIALOG:
            return { ...state, showCreateAlgDialog: true };

        case PROJECT_LORA_INIT_SWISSCOM_ALG_DIALOG:
            return { ...state, showInitSwisscomAlgDialog: true }

        case PROJECT_LORA_SHOW_ADD_ALN_DIALOG:
            if (!action.algId) {
                return state;
            }

            return { ...state, showCreateAlnDialog: true, algId: action.algId };

        case PROJECT_LORA_HIDE_DIALOG:
            return {
                ...state,
                algId: '',
                alnId: '',
                showCreateAlgDialog: false,
                showCreateAlnDialog: false,
                showRemoveAlgDialog: false,
                showRemoveAlnDialog: false,
                showInitSwisscomAlgDialog: false
            };

        case PROJECT_LORA_ADD_ALG_INFO: {
            if (!action.algConfig) {
                return state;
            }

            return {
                ...state,
                showCreateAlgDialog: false,
                showInitSwisscomAlgDialog: false,
                loraObjectsTreeData: createGatewayTreeItem(state.loraObjectsTreeData, getRootTreeId(), action.algConfig)
            }
        }

        case PROJECT_LORA_SHOW_REMOVE_ALG_DIALOG:
            if (!action.algId) {
                return state;
            }

            return {
                ...state,
                showRemoveAlgDialog: true,
                algId: action.algId
            };

        case PROJECT_LORA_UPDATE_ALG_INFO:
            if (!action.algConfigPartial || !action.algId) {
                return state;
            }

            return {
                ...state,
                loraObjectsTreeData: updateGatewayTreeItem(state.loraObjectsTreeData, action.algId, action.algConfigPartial)
            }

        case PROJECT_LORA_REMOVE_ALG_INFO:
            if (!action.algId) {
                return state;
            }

            return {
                ...state,
                algId: '',
                showRemoveAlgDialog: false,
                loraObjectsTreeData: removeTreeItem(state.loraObjectsTreeData, getAlgTreeId(action.algId))
            };

        case PROJECT_LORA_ADD_ALN_INFO:
            if (!action.alnConfig) {
                return state;
            }

            return {
                ...state,
                showCreateAlnDialog: false,
                algId: '',
                loraObjectsTreeData: createAlnTreeItem(state.loraObjectsTreeData, action.alnConfig)
            }

        case PROJECT_LORA_UPDATE_ALN_INFO:
            if (!action.alnInfoPartial || !action.algId || !action.alnId) {
                return state;
            }

            return {
                ...state,
                loraObjectsTreeData: updateAlnTreeItem(state.loraObjectsTreeData, action.algId, action.alnId, action.alnInfoPartial)
            }

        case PROJECT_LORA_SHOW_REMOVE_ALN_DIALOG:
            if (!action.algId || !action.alnId) {
                return state;
            }

            return {
                ...state,
                showRemoveAlnDialog: true,
                algId: action.algId,
                alnId: action.alnId
            }

        case PROJECT_LORA_REMOVE_ALN_INFO:
            if (!action.algId || !action.alnId) {
                return state;
            }

            return {
                ...state,
                showRemoveAlnDialog: false,
                alnId: '',
                loraObjectsTreeData: removeAlnTreeItem(state.loraObjectsTreeData, action.algId, action.alnId)
            }

        case PROJECT_LORA_SHOW_EDIT_ALN_NAME_DIALOG:
            return {
                ...state,
                showEditAlnNameDialog: true
            }

        case PROJECT_LORA_HIDE_EDIT_ALN_NAME_DIALOG:
            return {
                ...state,
                showEditAlnNameDialog: false
            }


        case PROJECT_LORA_SENSOR_CONFIGS_LOADING:
            return {
                ...state,
                sensorConfigStorage: {
                    ...defaultSomethingStorageState,
                    sensorConfigMap: new Map<string, GeovisLoRaSensorConfig>()
                }
            }

        case PROJECT_LORA_SENSOR_CONFIGS_LOADED:
            if (!action.sensorConfigs) {
                return state;
            }

            return {
                ...state,
                sensorConfigStorage: {
                    ...loadedSomethingStorageState,
                    sensorConfigMap: elementsToMapOfCustomKey(action.sensorConfigs, e => e.LoRaConfig.Name)
                }
            }

        case PROJECT_LORA_SENSOR_CONFIGS_LOADING_ERROR:
            return {
                ...state,
                sensorConfigStorage: {
                    ...errorSomethingStorageState(action.errorDescription || ''),
                    sensorConfigMap: new Map<string, GeovisLoRaSensorConfig>()
                }
            }

        case PROJECT_LORA_SENSOR_CONFIG_CHANGED:
            if(!action.lrnSensorConfig){
                return state;
            }

            return {
                ...state,
                sensorConfigStorage: {
                    ...state.sensorConfigStorage,
                    sensorConfigMap: updateSensorConfigInMap(state.sensorConfigStorage.sensorConfigMap, action.lrnSensorConfig)
                }
            }
    }

    return state;
}

export default projectLoRaReducer;
