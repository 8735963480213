/**
 * @author Vyacheslav Skripin <vs@ieksr.ru>
 * @created 25.04.2022
 * @description Heatmap chart alarms tab
 */

import { TimeValueChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel';
import { GeovisChartAlarmInfo } from '../../../../server/GEOvis3/Model/GeovisChart/GeovisChartAlarmInfo';
import { ChartAlarmAreasControl, IChartAlarmAreasControlActionProps } from '../ChartAlarmAreasControl';
import { ChartAlarmLinesControl, IChartAlarmLinesControlActionProps } from '../ChartAlarmLinesControl';
import { ChartAlarmPlansControl, IChartAlarmPlansControlActionProps } from '../ChartAlarmPlansControl';
import { isChartTemplate } from '../tools';


interface ITimeValueAlarmsTabProps extends IChartAlarmLinesControlActionProps, IChartAlarmAreasControlActionProps, IChartAlarmPlansControlActionProps {
    chart: TimeValueChartModel;
    alarmPlansInfo: GeovisChartAlarmInfo[];
}

export const TimeValueChartAlarmsTab = ({
    chart,
    alarmPlansInfo,
    onAlarmAreasChanged,
    onAlarmLineDeleteClickHandler,
    onChangeAlarmLines,
    onChangeLinkedAlarms,
    onNewAlarmLinkClickHandler
}: ITimeValueAlarmsTabProps) => {

    const { AlarmLines, BackgroundAreas } = chart;

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainer">
                <ChartAlarmLinesControl
                    AlarmLines={AlarmLines}
                    chartType={chart.Type}
                    onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                    onChangeAlarmLines={onChangeAlarmLines}
                    onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
                    yUnit={chart.LeftYAxisSettings.Unit}
                    xUnit={undefined}
                />
            </div>
            <div className="flexRowContainer">
                <ChartAlarmAreasControl
                    areas={BackgroundAreas || []}
                    onAlarmAreasChanged={onAlarmAreasChanged}
                />
            </div>

            {!isChartTemplate(chart) &&
                <div className="flexRowContainer">
                    <ChartAlarmPlansControl
                        allAlarms={alarmPlansInfo || []}
                        onChangeLinkedAlarms={onChangeLinkedAlarms}
                        chart={chart}
                    />
                </div>
            }
        </div>
    )
}
