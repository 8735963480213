import { LayersControlEvent, LeafletEvent, LeafletKeyboardEvent, LeafletMouseEvent } from "leaflet";
import { useMapEvents } from "react-leaflet";
import { IViewport } from "./types";

interface IComponentProps {
  onBaseLayerChanged?: (event: LayersControlEvent) => void;
  onClick?: (event: LeafletMouseEvent) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  onMoveEnd?: (event: LeafletEvent) => void;
  onViewportChanged?: (viewport: IViewport) => void;
}

export const MapEventsComponent = ({
  onBaseLayerChanged,
  onClick,
  onKeyPress,
  onMoveEnd,
  onViewportChanged
}: IComponentProps) => {

  const map = useMapEvents({
    baselayerchange(event: LayersControlEvent) {
      if (onBaseLayerChanged) {
        onBaseLayerChanged(event);
      }
    },
    click(event: LeafletMouseEvent) {
      if (onClick) {
        onClick(event);
      }
    },
    keypress(event: LeafletKeyboardEvent) {
      if (onKeyPress) {
        onKeyPress(event.originalEvent);
      }

    },
    moveend(event: LeafletEvent) {
      if (onViewportChanged) {
        const center = map.getCenter();

        const viewport: IViewport = {
          center: center ? [center.lat, center.lng] : undefined,
          zoom: map.getZoom()
        };

        onViewportChanged(viewport);
      }
      if (onMoveEnd) {
        onMoveEnd(event);
      }
    }
  });

  return (<></>);
}