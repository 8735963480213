//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GvLogEntryBase } from './Abstract/GvLogEntryBase';

export class GvLogEntryPoint extends GvLogEntryBase
{
	public TaskId: string;
	public AlarmId: string;
}
