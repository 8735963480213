/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 26.05.2022
 * @description Special action creators for PDF report rendering
 */

import { DataActionResponse } from "../../server/DataActionResponse";
import { PdfReportInitialState } from "../../server/GEOvis3/Model/Reports/PdfReportInitialState";
import { GEOVIS_PROJECT_REPORT_PDF_INITIAL_STATE } from "../actions/projectReportActions.pdf";
import { IGeovisProjectReportPdfAction } from "../projectReport.types.pdf";

/**
 * Init project report storages
 * @returns 
 */
export const geovisProjectReportPdfInitialState = (pdfReportInitialState: DataActionResponse<PdfReportInitialState>): IGeovisProjectReportPdfAction => ({
    type: GEOVIS_PROJECT_REPORT_PDF_INITIAL_STATE, pdfReportInitialState
});