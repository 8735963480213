/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 22.05.2020
 * @description Local map object camera shape
 */

import { Marker } from 'react-leaflet';
import { locationToMapCoordinates } from '../../../helpers/MapHelper';
import { fixHref } from '../../../helpers/UrlHelper';
import { LocalMapObjectCamera } from '../../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectCamera';
import '../LocalMapObjectIconsJs';
import { getLocalMapObjectCameraIconProps } from '../LocalMapObjectIconsRenderers';
import { LocalMapObjectViewMode } from '../types';
import { LocalMapObjectPopup, LocalMapObjectTooltip } from './LocalMapObjectMapElements';

interface ILocalMapObjectCameraShapeProps {
    element: LocalMapObjectCamera;
    viewMode: LocalMapObjectViewMode;

    onEdit: (id: string) => void;
    onRemove: (id: string) => void;
}

export const LocalMapObjectCameraShape = ({
    element,
    onEdit,
    onRemove,
    viewMode
}: ILocalMapObjectCameraShapeProps) => {

    const onClickHandler = (event: L.LeafletMouseEvent) => {
        if (viewMode === LocalMapObjectViewMode.Edit && !event.originalEvent.ctrlKey) {
            return;
        }

        // if (element.Link === "") {
        //     FlagService.addError("Can not open link", "Link is empty");
        // }
        if (element.Link !== "") {
            const href = fixHref(element.Link);
            window.open(href, "_blank");
        }

        event.originalEvent.preventDefault();
    }

    const getChildren = (): JSX.Element[] => {
        const result: JSX.Element[] = [];
        if (viewMode !== LocalMapObjectViewMode.MapSection) {
            result.push(
                <LocalMapObjectPopup
                    key={`local_map_camera_${element.Id}_marker_popup`}
                    element={element}
                    onEdit={onEdit}
                    onRemove={onRemove}
                    editMode={viewMode === LocalMapObjectViewMode.Edit} />
            );
        }

        result.push(
            <LocalMapObjectTooltip
                key={`local_map_camera_${element.Id}_marker_tooltip`}
                element={element} />
        );

        return result;
    }

    return (
        <Marker
            key={`local_map_camera_${element.Id}_marker`}
            position={locationToMapCoordinates(element.Location)}
            icon={L.divIcon.svgIcon.localMapObjectCameraIcon(getLocalMapObjectCameraIconProps(element))}
            eventHandlers={viewMode === LocalMapObjectViewMode.MapSection ? undefined : {
                click: onClickHandler
            }}>
            {getChildren()}
        </Marker>
    );
}