import Highcharts, { XAxisPlotBandsOptions, YAxisPlotLinesOptions } from "highcharts";
import { formattedDateTime } from "../../../../../../helpers/DateHelper";
import { t } from "../../../../../../i18n";
import { getSensorColorIntensityCategoryByName, SensorColorIntensityCategory } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorColorIntensityCategory";
import { getSensorValueAttributeByName } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { GeovisChartSlimConfig } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartSlimConfig";
import { XYVibrationChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/XYVibrationChartData";
import { ColorizableElement } from "../../../../../../server/AVTService/TypeLibrary/DB/ColorizableElement";
import { AlarmInfoRecord } from "../../../../../../server/AVTService/TypeLibrary/Model/AlarmInfoRecord";
import { XYVibrationChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XYVibrationChartModel";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { HtmlReportSensorDescriptionViewModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportSensorDescriptionViewModel";
import { HtmlReportXyChartLineInfo } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportXyChartLineInfo";
import { XYVibrationEventDataModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/XYVibrationEventDataModel";
import { getPhysicalUnitShortName } from "../../../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { createChartRenderedFunc } from "../../tools";
import {
    getAlarmLinesAnnotations,
    getAxisLabel,
    getAxisLimitValue,
    getDashStyleValue,
    getMarkerSymbol,
    getSoftLimitValue,
    getXAxisUnitsAnnotation,
    getYAxisOptions,
    getYAxisUnitsAnnotation
} from "./chartRenderOptions";
import { getBoostOptions } from "./tools";
import { FrequencyColoredBand } from "../../../../../../server/AVTService/TypeLibrary/Model/FrequencyColoredBand";

const Color = require('color');

// const CATEGORY_WORD = "CATEGORY";

interface IXYVibrationRenderOptionsSettings {
    chart: XYVibrationChartModel;
    leftYAxisSensorIds: string[],
    chartDataSlimConfig: GeovisChartSlimConfig;
    startMeasurement: string;
    onChartRender: () => void;
    SensorsIds: { [key: number]: string };
    Lines: HtmlReportXyChartLineInfo[];
    ColorIntensivityPercentageMap: { [key: number]: number };
    ColorIntensivityBrightnessCoefficientMap: { [key: number]: number };
    descriptions: HtmlReportSensorDescriptionViewModel[];
    timeslotTo: string;
    timeslotFrom: string;
    linkedAlarmLines: AlarmInfoRecord[];
    visualSettings: ProjectVisualSettings;
    WarningSensors: string[];
    VibrationAlarmsBands: FrequencyColoredBand[];
    onShowEventData: (eventId: string, sensorId: string, eventDate: string) => void;
}

interface IXAxisSettings {
    chart: XYVibrationChartModel;
    linkedAlarmLines: AlarmInfoRecord[];
    SensorsIds: { [key: number]: string };
    visualSettings: ProjectVisualSettings;
    noData: boolean;
}

interface IYAxisSettings {
    chart: XYVibrationChartModel;
    visualSettings: ProjectVisualSettings;
    noData: boolean;
}

interface ISeriesSettings {
    chart: XYVibrationChartModel;
    Lines: HtmlReportXyChartLineInfo[];
    descriptions: HtmlReportSensorDescriptionViewModel[];
    SensorsIds: { [key: number]: string };
    ColorIntensivityPercentageMap: { [key: number]: number };
    ColorIntensivityBrightnessCoefficientMap: { [key: number]: number };
    onShowEventData: (eventId: string, sensorId: string, eventDate: string) => void;
}

export const getXYVibrationChartRenderOptions = (pageNum: number, chart: XYVibrationChartModel, chartData: XYVibrationChartData, onShowEventData: (eventId: string, fullId: string, eventDate: string) => void): Highcharts.Options => {
    const {
        ColorIntensivityBrightnessCoefficientMap,
        ColorIntensivityPercentageMap,
        Descriptions,
        Lines,
        LinkedAlarmLines,
        TimeSlotFrom,
        TimeSlotTo,
        startMeasurementTime,
        WarningSensors,
        visualSettings,
        SensorsIds,
        VibrationAlarmsBands
    } = chartData;

    const renderSettings: IXYVibrationRenderOptionsSettings = {
        chart,
        chartDataSlimConfig: chartData.SlimChartConfig,
        ColorIntensivityBrightnessCoefficientMap,
        ColorIntensivityPercentageMap,
        descriptions: Descriptions,
        leftYAxisSensorIds: chart.LeftYAxisSettings.SensorIds,
        linkedAlarmLines: LinkedAlarmLines,
        onChartRender: createChartRenderedFunc(pageNum, chart.Id),
        onShowEventData,
        SensorsIds,
        startMeasurement: startMeasurementTime,
        timeslotFrom: TimeSlotFrom,
        timeslotTo: TimeSlotTo,
        visualSettings,
        Lines,
        WarningSensors,
        VibrationAlarmsBands
    }

    return getSingleOptions(renderSettings);
}

const getSingleOptions = ({
    ColorIntensivityBrightnessCoefficientMap,
    ColorIntensivityPercentageMap,
    SensorsIds,
    chart,
    chartDataSlimConfig,
    descriptions,
    leftYAxisSensorIds,
    linkedAlarmLines,
    onChartRender,
    onShowEventData,
    startMeasurement,
    visualSettings,
    Lines,
    VibrationAlarmsBands
}: IXYVibrationRenderOptionsSettings): Highcharts.Options => {

    const series = getXyVibrationChartSeries({
        chart,
        ColorIntensivityBrightnessCoefficientMap,
        ColorIntensivityPercentageMap,
        descriptions,
        Lines,
        SensorsIds,
        onShowEventData
    });

    const noData = series.length === 0;

    const yUnitAnnotations = getYAxisUnitsAnnotation(chart.LeftYAxisSettings, 0);
    const xUnitAnnotation = getXAxisUnitsAnnotation(chart.XAxisSettings);

    const alarmAnnotations = getAlarmLinesAnnotations([...chart.AlarmLines, ...linkedAlarmLines]);
    const alarmBandsAnnotations = getAlarmColoredAnnotations(VibrationAlarmsBands);

    const annotations = [yUnitAnnotations, xUnitAnnotation, ...alarmAnnotations, ...alarmBandsAnnotations];

    const options: Highcharts.Options = {
        boost: getBoostOptions(chart.Type, chartDataSlimConfig),
        chart: {
            zooming: {
                type: undefined
            },
            reflow: true,
            alignTicks: true,
            showAxes: true,
            events: {
                render: onChartRender,
            },
        },
        annotations,
        title: {
            floating: false,
            text: "<div style='height:10px'></div>",
            useHTML: true
        },
        lang: {
            noData: `<div style='z-index:20'>${leftYAxisSensorIds.length > 0 ? t('No data is available in the chart') : t('No sensor selected for drawing the chart')}</div>`
        },
        noData: {
            useHTML: true
        },
        legend: {
            // legend should be done manually
            enabled: false,
        },
        xAxis: getXyVibrationChartXAxisOptions({
            chart,
            linkedAlarmLines,
            SensorsIds,
            visualSettings,
            noData
        }),
        yAxis: getXyVibrationChartYAxisOptions({
            chart,
            visualSettings,
            noData
        }),
        series,
        tooltip: {
            useHTML: true,
            shared: false,
            split: false,
            snap: 3,
            formatter() {
                if (this.point.options.custom) {
                    const date: string = this.point.options.custom.pointDate
                    const seriaName = this.series.name;
                    return `<div>${seriaName} <br/>
                            ${formattedDateTime(date)}<br/>
                            ${getFixedValue(chart.XAxisSettings.numberOfDigits.value, this.x)} [${getPhysicalUnitShortName(chart.XAxisSettings.Unit)}]<br/>
                            ${getFixedValue(chart.LeftYAxisSettings.numberOfDigits.value, this.y)} [${getPhysicalUnitShortName(chart.LeftYAxisSettings.Unit)}] </div>`
                }
                return "";
            }
        },
        exporting: {
            enabled: false,
        },
        plotOptions: {
            line: {
                findNearestPointBy: 'xy'
            },
            series: {
                animation: false,
            }
        },
        credits: {
            enabled: false
        }
    }

    if (chart.ShowStartMeasurements) {
        const startMeasurementText = startMeasurement ? formattedDateTime(startMeasurement, "DD.MM.YYYY") : 'no data';

        options.subtitle = {
            align: 'center',
            text: `${t("Start of measurements")}: ${startMeasurementText}`
        };
    }

    return options;
}




/**
 * Get plot lines options for linked alarm lines
 * @param alarmInfos
 * @returns Vertical plot lines options
 */
const getXPlotLines = (alarmInfos: AlarmInfoRecord[]): YAxisPlotLinesOptions[] => {
    const result: YAxisPlotLinesOptions[] = [];

    alarmInfos.forEach(ai => {
        result.push({
            value: +ai.yvalueFrom,
            color: ai.lineColor,
            dashStyle: getDashStyleValue(ai.lineType),
            width: ai.lineWeight,
            label: {
                text: ai.label,
                style: {
                    color: ai.lineColor,
                    border: 'black'
                },
                rotation: 0
            }
        })
    });

    return result;
}

const getAlarmsGapsBands = (alarmLines: AlarmInfoRecord[]): XAxisPlotBandsOptions[] => {
    const result: XAxisPlotBandsOptions[] = [];

    alarmLines.forEach(line => {
        if (line.isGap) {
            result.push({
                from: +line.xvalueFrom,
                to: +line.xvalueTo,
                color: "grey",
                zIndex: 3,
            })
        }
    })

    return result;
}

const getAlarmColoredAnnotations = (vibrationAlarmsBands: FrequencyColoredBand[]) => {
    const result: Highcharts.AnnotationsOptions[] = [];
    vibrationAlarmsBands.forEach(band => {
        const color = Color(band.Color).alpha(0.4);
        const annotation: Highcharts.AnnotationsOptions = {
            draggable: '',
            zIndex: 2,
            shapes: [{
                type: 'path',
                // width: band.Right - band.Left,
                // height: band.Top - band.Bottom,
                fill: color.rgb().string(),
                points: [{
                    x: band.Left,
                    y: band.Top,
                    xAxis: 0,
                    yAxis: 0
                }, {
                    x: band.Right,
                    y: band.Top,
                    xAxis: 0,
                    yAxis: 0
                }, {
                    x: band.Right,
                    y: band.Bottom,
                    xAxis: 0,
                    yAxis: 0
                }, {
                    x: band.Left,
                    y: band.Bottom,
                    xAxis: 0,
                    yAxis: 0
                }],
                //stroke: 'red',
                // point: {
                //     x: band.Left,
                //     y: band.Top,
                //     xAxis: 0,
                //     yAxis: 0
                // },
                strokeWidth: 0,
                xAxis: 0,
                yAxis: 0,

            }]
        };
        result.push(annotation);
    });

    return result
}

/**
 * Get options for X axis of chart
 * @param param0 
 * @returns 
 */
const getXyVibrationChartXAxisOptions = ({ chart, linkedAlarmLines, visualSettings, noData }: IXAxisSettings): Highcharts.XAxisOptions => {

    const verticalAlarmLines = linkedAlarmLines.filter(al => al.swapXY);
    const plotLines = getXPlotLines(verticalAlarmLines);
    const plotBands = getAlarmsGapsBands(linkedAlarmLines);

    const axisSettings = chart.XAxisSettings;

    return {
        title: {
            text: getAxisLabel(chart.XAxisSettings, getSensorValueAttributeByName(chart.XAxisSettings.sensorValue)),
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            }
        },
        type: 'linear',
        crosshair: true,
        lineWidth: 2,
        lineColor: "black",
        minRange: 0.0001,
        minorTickInterval: 'auto',
        // tickAmount: 12,
        // min: getLimitValue(chart.XAxisSettings.minScaleLimit),
        // max: getLimitValue(chart.XAxisSettings.maxScaleLimit),
        min: getAxisLimitValue(axisSettings.minScaleLimit, false, noData, axisSettings.DestConverter.enable ? axisSettings.DestConverter.multiplicatorValue : undefined, undefined, undefined, false),
        max: getAxisLimitValue(axisSettings.maxScaleLimit, true, noData, axisSettings.DestConverter.enable ? axisSettings.DestConverter.multiplicatorValue : undefined, undefined, undefined, false),
        softMax: getSoftLimitValue(chart.XAxisSettings.maxScaleLimit),
        softMin: getSoftLimitValue(chart.XAxisSettings.minScaleLimit),
        // startOnTick: startEndOnTick(chart.XAxisSettings.minScaleLimit),
        // endOnTick: startEndOnTick(chart.XAxisSettings.maxScaleLimit),
        startOnTick: false,
        endOnTick: false,
        plotLines,
        plotBands,
        labels: {
            style: {
                fontSize: visualSettings.chartAxisLabelFontSize
            },
            useHTML: chart.XAxisSettings.ShowXAxisSensorName.value,
            autoRotation: undefined,
            rotation: chart.XAxisSettings.UseVerticalAxisLabels ? -45 : 0,
            formatter(ctx) {
                const ctxValue = ctx.pos.toFixed(chart.XAxisSettings.numberOfDigits.value);
                return ctxValue;
            }
        }
    }
}

const getXyVibrationChartYAxisOptions = ({
    chart,
    visualSettings,
    noData
}: IYAxisSettings): Highcharts.YAxisOptions => {
    // const options: Highcharts.YAxisOptions = getYAxisOptions(chart, visualSettings, [], undefined, undefined, false, true)[0];
    const options: Highcharts.YAxisOptions = getYAxisOptions({
        chart,
        drawAlarmLines: false,
        linkedAlarmLines: [],
        needSecondAxis: false,
        visualSettings,
        hideUnitsInLabel: true,
        primaryAxisValueType: undefined,
        secondaryAxisValueType: undefined,
        noData
    })[0];
    options.tickAmount = 12;
    options.minRange = 0.001;
    return options;
}

const getXyVibrationChartSeries = ({ Lines, chart, descriptions, onShowEventData }: ISeriesSettings): Highcharts.SeriesOptionsType[] => {
    const result: Highcharts.SeriesOptionsType[] = [];

    for (const line of Lines) {

        const points = line.points;
        if (points.length === 0) {
            continue;
        }

        const basePoint = points[0];
        const sensorId = (basePoint as XYVibrationEventDataModel).fullId;

        if (sensorId) {

            const sensor = descriptions.find(d => d.id === sensorId);
            if (sensor) {

                const pointOpacity = chart.ShowTimeslotsInLegend
                    ? getOpacity(line.colorIntensityCategory.toString())
                    : 1;

                const seria: Highcharts.SeriesOptionsType = {
                    type: 'scatter',
                    data: [pointToObject(chart, basePoint as XYVibrationEventDataModel, onShowEventData)],
                    color: sensor.color,
                    name: sensor.name,
                    turboThreshold: 0,
                    opacity: pointOpacity,
                    marker: {
                        enabled: true,
                        color: sensor.color,
                        symbol: getMarkerSymbol({ ... new ColorizableElement(), Symbol: sensor.symbol }),
                        radius: 8
                    },
                    boostThreshold: 0

                }

                result.push(seria);
            }
        }
    }

    return result;
}

const pointToObject = (chart: XYVibrationChartModel, basePoint: XYVibrationEventDataModel, onShowEventData: (eventId: string, fullId: string, eventDate: string) => void): Highcharts.PointOptionsObject => {
    return {
        x: basePoint.x,
        y: basePoint.value[0],
        custom: {
            pointDate: basePoint.eventDate
        },
        events: chart.ShowEventGraph.value ? {
            click: onEventClickHandler(basePoint.recordId, basePoint.fullId, basePoint.eventDate, onShowEventData)
        } : undefined
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onEventClickHandler = (recordId: string, sensorFullId: string, eventDate: string, onShowEventData: (eventId: string, sensorId: string, eventDate: string) => void) => (eventData: any) => {
    // eslint-disable-next-line no-console
    console.log(`Event ${recordId} was clicked for ${sensorFullId}`);
    onShowEventData(recordId, sensorFullId, eventDate);
}

const getFixedValue = (numberOfDigits: number, source: string | number | undefined | null): string => {
    if (!source || source === null) {
        return "";
    }
    if (typeof source === 'string') {
        return source;
    }
    return source.toFixed(numberOfDigits);
}

const getOpacity = (category: string): number => {
    const parsedCategory = getSensorColorIntensityCategoryByName(category);
    switch (parsedCategory) {
        case SensorColorIntensityCategory.Category1: return 1;
        case SensorColorIntensityCategory.Category2: return 0.85;
        case SensorColorIntensityCategory.Category3: return 0.7;
        case SensorColorIntensityCategory.Category4: return 0.5;
        case SensorColorIntensityCategory.Category5: return 0.4;
        case SensorColorIntensityCategory.Category6: return 0.25;
    }
}