//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GvMeasurementDataKind {
	Data = 0,
	Axis = 1,
	String = 2
}

const GvMeasurementDataKindToName = new Map<number, string>([
	[ 0, 'Data' ],
	[ 1, 'Axis' ],
	[ 2, 'String' ],
])

export function getGvMeasurementDataKindToName(element: GvMeasurementDataKind) : string {
    return GvMeasurementDataKindToName.get(element) || '';
}

const GvMeasurementDataKindByName = new Map<string, number>([
	[ 'Data', 0 ],
	[ 'Axis', 1 ],
	[ 'String', 2 ],
]);

export function getGvMeasurementDataKindByName(name: string): GvMeasurementDataKind {
    return GvMeasurementDataKindByName.get(name)!;
}


export const GvMeasurementDataKindList : Readonly<GvMeasurementDataKind[]> = [
	GvMeasurementDataKind.Data,
	GvMeasurementDataKind.Axis,
	GvMeasurementDataKind.String,
];



const GvMeasurementDataKindToDescription = new Map<number, string>([
	[ 0, 'Data' ],
	[ 1, 'Axis' ],
	[ 2, 'String' ],
])

export function getGvMeasurementDataKindToDescription(element: GvMeasurementDataKind) : string {
    return GvMeasurementDataKindToDescription.get(element) || '';
}

