import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import Tree, {
    ItemId,
    RenderItemParams,
    TreeData,
    TreeItem,
} from '@atlaskit/tree';
import { useEffect } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { AuthorizationCode } from '../../../../server/AuthorizationCode';
import { InventoryObjectDescription } from "../../../../server/AVTService/TypeLibrary/Inventory/InventoryObjectDescription";
import { DataActionResponse } from "../../../../server/DataActionResponse";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import {
    geovisInventoryDescriptionsLoaded,
    geovisInventoryNodeCollapsed,
    geovisInventoryNodeExpanded,
    geovisInventoryProjectIdChanged
} from '../../../../store/creators/inventoryCreators';
import {
    IGeovisInventoryDescriptionsStorage,
    IInventoryProjectsStorage
} from "../../../../store/inventory.types";
import { IGeovisStoreState } from '../../../../store/store.types';
import { IGeovisAction } from '../../../../store/types';

export const EMPTY_PARENT_IT = "-";

interface IInventoryTreeControlStateProps {
    descriptions: IGeovisInventoryDescriptionsStorage;
    projectStorage: IInventoryProjectsStorage;
    inventoryViewName: string;
    projectInventoryViewName: string;
    storedProjectId: number | undefined;
}

interface IInventoryTreeControlDispatchProps {
    onProjectChanged: (projectId: number | undefined) => void;
    onInventoryData: (recs: InventoryObjectDescription[], parentId: string) => void;
    onNodeExpanded: (nodeId: string, needLoadData: boolean) => void;
    onNodeCollapsed: (nodeId: string) => void;
}

interface IInventoryTreeControlOwnProps {
    projectId?: number;
}

interface IInventoryTreeControlProps extends IInventoryTreeControlStateProps, IInventoryTreeControlDispatchProps, IInventoryTreeControlOwnProps, IWithGeovisServerProps {

}

const InventoryTreeControl = ({
    descriptions,
    projectStorage,
    Server,
    projectInventoryViewName,
    inventoryViewName,
    storedProjectId,
    onInventoryData,
    onNodeExpanded,
    onNodeCollapsed,
    onProjectChanged,
    projectId
}: IInventoryTreeControlProps) => {

    const unexpectedError = (error: Error): DataActionResponse<InventoryObjectDescription[]> => ({
        AuthorizationCode: AuthorizationCode.UnexpectedError,
        Data: {} as InventoryObjectDescription[],
        JsonPath: '',
        Messages: [error.message],
        Success: false,
        FailedFields: []
    });

    useEffect(() => {
        // if ((projectId && projectId > 0 && projectInventoryViewName === InventoryViewAsTree) || ((!projectId || projectId < 0) && inventoryViewName === InventoryViewAsTree)) {

        // }
        if (projectId !== storedProjectId) {
            storedProjectId = projectId;
            onProjectChanged(projectId);
        }

        (async function loadDateAsync() {
            let result: DataActionResponse<InventoryObjectDescription[]> | false = false;
            let sendingId: number = 0;
            if (projectId && projectId > 0) {
                sendingId = projectId;
            }
            const url = ServerRoutesGen.Inventory.GetChildren.patch(descriptions.displayNodeId, sendingId).path;
            try {
                result = await Server.get<DataActionResponse<InventoryObjectDescription[]>>(url);
            }
            catch (error) {
                result = unexpectedError(error instanceof Error ? error : new Error(`${error}`));
            }
            if (result.Success) {
                onInventoryData(result.Data, descriptions.displayNodeId);
            }
        })();
    }, [descriptions.displayNodeId, projectId, projectInventoryViewName, inventoryViewName]);

    const { expandInfo } = descriptions;

    const getProjectName = (prjId: number): string => {
        const project = projectStorage.projects.get(prjId);
        if (project) {
            return project.Name;
        }
        return "Not in project";
    }

    const createTreeData = (): TreeData => {
        const rootId = "root";

        const result: TreeData = {
            "rootId": rootId,
            items: {}
        };

        result.items[rootId] = {
            id: rootId,
            isExpanded: true,
            children: []
        };

        const rootTreeItem = result.items[rootId];
        const noParentItems: InventoryObjectDescription[] = [];

        descriptions.data.Records.forEach(r => {

            let isExpanded = expandInfo.get(r.Id);
            if (isExpanded === undefined && projectId !== undefined && projectId > 0) {
                isExpanded = true;
            }
            // add item to tree data
            result.items[r.Id] = {
                id: r.Id,
                isExpanded,
                children: [],
                hasChildren: r.HasChildren,
                data: r
            }
            // add relations
            if (r.ParentId === EMPTY_PARENT_IT) {
                if (storedProjectId && storedProjectId > 0) {
                    if (!rootTreeItem.children.includes(r.Id)) {
                        rootTreeItem.children.push(r.Id);
                    }
                }
                else {
                    if (!result.items[r.ProjectId]) {
                        result.items[r.ProjectId] = {
                            id: r.ProjectId,
                            isExpanded: expandInfo.get(r.ProjectId),
                            children: [],
                            hasChildren: true,
                            data: { Name: getProjectName(r.ProjectId), IsOnline: undefined }
                        }
                    }
                    if (!rootTreeItem.children.includes(r.ProjectId)) {
                        rootTreeItem.children.push(r.ProjectId);
                    }
                    result.items[r.ProjectId].children.push(r.Id);
                }
            }
            else if (r.ParentId !== EMPTY_PARENT_IT && result.items[r.ParentId] && !result.items[r.ParentId].children.includes(r.Id)) {
                result.items[r.ParentId].children.push(r.Id);
            }
            else if (!result.items[r.ParentId]) {
                noParentItems.push(r);
            }
        });
        noParentItems.forEach(i => {
            if (result.items[i.ParentId] && !result.items[i.ParentId].children.includes(i.Id)) {
                result.items[i.ParentId].children.push(i.Id);
            }
        });

        return result;
    }

    const renderTreeItem = ({ item, onExpand, onCollapse, provided }: RenderItemParams) => {
        const itemData = item.data as InventoryObjectDescription;

        const onClickHandler = (clicked: TreeItem) => () => {
            if (clicked.isExpanded) {
                onCollapse(clicked.id);
            }
            else {
                onExpand(clicked.id);
            }
        }

        const fontColor = itemData.IsOnline === undefined ? 'black' : itemData.IsOnline ? 'green' : 'red';

        return (
            <div ref={provided.innerRef}
                className="treeItem"
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                {getIcon(item, onExpand, onCollapse)}

                <Button appearance='subtle' width='100%' onClick={onClickHandler(item)}>
                    <span style={{ color: fontColor }}>{itemData.Name}</span>
                </Button>

            </div>
        )
    }

    const getIcon = (item: TreeItem, onExpand: (itemId: ItemId) => void, onCollapse: (itemId: ItemId) => void) => {

        const onCollapseFunc = (itemId: ItemId) => () => onCollapse(itemId);
        const onExpandFunc = (itemId: ItemId) => () => onExpand(itemId);

        if (item.hasChildren) {

            if (item.isExpanded) {
                return (
                    <span className="treeItemIcon" onClick={onCollapseFunc(item.id)}>
                        <ChevronDownIcon label="" size="medium" />
                    </span>
                );
            }

            return (
                <span className="treeItemIcon" onClick={onExpandFunc(item.id)}>
                    <ChevronRightIcon label="" size="medium" />
                </span>
            );
        }
        return (<span>&nbsp;</span>);
    };

    const treeData = createTreeData();

    const onExpandHandler = (nodeId: string) => {
        const needLoading = !(treeData.items[nodeId].children && treeData.items[nodeId].children.length > 0);
        onNodeExpanded(nodeId, needLoading);
    }
    return (
        <Tree
            tree={treeData}
            renderItem={renderTreeItem}
            onExpand={onExpandHandler}
            onCollapse={onNodeCollapsed}
            isDragEnabled={false}
        />

    )

}
const mapStateToProps = ({ inventoryDataState, projectOverview }: IGeovisStoreState): IInventoryTreeControlStateProps => ({
    descriptions: inventoryDataState.descriptions,
    projectStorage: inventoryDataState.projectsStorage,
    inventoryViewName: inventoryDataState.inventoryView,
    projectInventoryViewName: projectOverview.inventoryViewName,
    storedProjectId: inventoryDataState.descriptions.projectId
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IInventoryTreeControlDispatchProps => ({
    onInventoryData: (recs, parentId) => dispatch(geovisInventoryDescriptionsLoaded(recs, parentId)),
    onNodeExpanded: (nodeId, needDataLoading) => dispatch(geovisInventoryNodeExpanded(nodeId, needDataLoading)),
    onNodeCollapsed: nodeId => dispatch(geovisInventoryNodeCollapsed(nodeId)),
    onProjectChanged: projectId => dispatch(geovisInventoryProjectIdChanged(projectId))
});
export default connect<IInventoryTreeControlStateProps, IInventoryTreeControlDispatchProps>(mapStateToProps, mapDispatchToProps)(withGeovisServer(InventoryTreeControl))