import { formattedDateTime } from "../../../../../../../helpers/DateHelper";
import { t } from "../../../../../../../i18n";

interface IComponentProps {
    showStartMeasurements: boolean;
    showDateOfReference: boolean;
    startMeasurements: string;
    referenceDate: string;
}

export const InclinometerChartAdditionalInfo = ({
    referenceDate,
    showDateOfReference,
    showStartMeasurements,
    startMeasurements
}: IComponentProps) => {
    if (!showDateOfReference && !showStartMeasurements) {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5px' }}>
            {showStartMeasurements &&
                <span style={{
                    color: '#666666',
                    fill: '#666666',
                    fontFamily: 'sans-serif',
                    fontSize: 12
                }}>{t("Start of measurements") + ": " + (startMeasurements ? formattedDateTime(startMeasurements, "DD.MM.YYYY") : 'no data')}</span>
            }
            {showDateOfReference &&
                <span style={{
                    color: '#666666',
                    fill: '#666666',
                    fontFamily: 'sans-serif',
                    fontSize: 12
                }}>{t("Date of reference") + ": " + (referenceDate ? formattedDateTime(referenceDate, "DD.MM.YYYY") : 'no data')}</span>
            }
        </div>
    )
}