/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 21.04.2022
 * @description The heatmap main settings component
 */

import { RadioGroup } from '@atlaskit/radio';
import Tooltip from '@atlaskit/tooltip';
import { OptionsPropType } from '@atlaskit/radio/dist/types/types';
import React from 'react';
import { OptionType } from '@atlaskit/select';
import Button from '@atlaskit/button';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { t } from '../../../../i18n';
import { ChartSettingHeatmapRenderingMode } from '../../../../server/AVTService/TypeLibrary/Model/ChartSettingHeatmapRenderingMode';
import { GeovisChartDateTimeSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartDateTimeSettings';
import { HeatmapChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel';
import { getHeatmapRenderingModeToName, HeatmapRenderingModeList } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapRenderingMode';
import GeovisReactDateTimePicker from '../../../dateTimePickers/GeovisReactDateTimePicker';
import { getIGvOptionTypeList } from '../../../select/GeovisSelect_tools';
import { ChartSelectSettings } from '../ChartTab_tools';
import {
    defaultChartLabelColumnStyle,
    defaultChartRowSettingsStyle,
    IGeovisChartPropertyChangedProps,
} from '../types';
import { GeovisSelect } from '../../../select/GeovisSelect';
import { getTimePeriodToDescription, TimePeriod, TimePeriodList } from '../../../../server/AVTService/TypeLibrary/Common/TimePeriod';
import { ChartSettingTimePeriod } from '../../../../server/AVTService/TypeLibrary/Model/ChartSettingTimePeriod';
import { ElementSettingAllowCheckbox } from '../../ElementSettingAllowCheckbox';

interface IHeatmapMainSettingsProps extends IGeovisChartPropertyChangedProps<HeatmapChartModel> {
    chart: HeatmapChartModel;
}

export const ChartHeatmapMainSettings = ({ chart, onChartPropertyChanged }: IHeatmapMainSettingsProps) => {

    const { Timeslot, TimeSearchDistance, RenderingMode, UseLastMeasurementTime, UseLastMeasurementTimeCustomerChangeable } = chart;

    const onChartPropertyChangedFunc = (propertyName: keyof HeatmapChartModel) => (value: any) => {
        switch (propertyName) {
            case "Timeslot": {
                const formattedDateTime = value || "";
                onChartPropertyChanged<GeovisChartDateTimeSettings>(propertyName, { ...Timeslot, Value: formattedDateTime });
                break;
            }
            case "RenderingMode": {
                onChartPropertyChanged<ChartSettingHeatmapRenderingMode>(propertyName, { ...RenderingMode, value: +value });
                break;
            }
        }
    }

    const onCustomerChangeablePropertyChangedFunc = (propertyName: keyof HeatmapChartModel) => (value: boolean) => {
        switch (propertyName) {
            case "Timeslot": {
                onChartPropertyChanged<GeovisChartDateTimeSettings>(propertyName, { ...Timeslot, customerChangeable: value });
                break;
            }
            case "RenderingMode": {
                onChartPropertyChanged<ChartSettingHeatmapRenderingMode>(propertyName, { ...RenderingMode, customerChangeable: value });
                break;
            }
            case "TimeSearchDistance": {
                onChartPropertyChanged<ChartSettingTimePeriod>(propertyName, { ...TimeSearchDistance, customerChangeable: value });
                break;
            }

            case "UseLastMeasurementTimeCustomerChangeable": {
                onChartPropertyChanged(propertyName, value);
                break;
            }
        }
    }

    const getMeasurementTypeOptions = (): OptionsPropType => {
        return [{
            label: t("Use data of last measurement timeslot"),
            value: "1"
        }, {
            label: t("Use nearest data to following datetime") + ":",
            value: "-1"
        }]
    }
    const getMeasurementTypeValue = () => {
        return UseLastMeasurementTime ? "1" : "-1";
    }
    const onMeasurementTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChartPropertyChanged("UseLastMeasurementTime", +e.currentTarget.value > 0);
    }

    const getTimeSearchDistanceOption = (timeDistance: TimePeriod): OptionType => {
        return { value: timeDistance, label: (timeDistance === TimePeriod.Custom ? t("Exact") : getTimePeriodToDescription(timeDistance)) };
    }

    const getTimeSearchDistanceOptions = (): OptionType[] => {
        return TimePeriodList.map(val => (getTimeSearchDistanceOption(val)))
    }

    const onTimeSearchDistancePropertyChangedFunc = (selected: OptionType) => {
        onChartPropertyChanged<ChartSettingTimePeriod>("TimeSearchDistance", { ...TimeSearchDistance, value: +selected.value});
    }

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    return (
        <div>
            <div className="flexRowContainer">
                <div className="flexCellContainer" style={labelColumnStyle}>
                    {/* */}
                </div>
                <div className="flexCellContainer_g1">
                    <RadioGroup
                        options={getMeasurementTypeOptions()}
                        value={getMeasurementTypeValue()}
                        onChange={onMeasurementTypeChange}
                    />
                </div>
                <ElementSettingAllowCheckbox
                    isChecked={UseLastMeasurementTimeCustomerChangeable}
                    onChange={onCustomerChangeablePropertyChangedFunc("UseLastMeasurementTimeCustomerChangeable")}
                    isDisabled={false}
                />
            </div>

            {/* Date time around*/}
            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer" style={labelColumnStyle}>
                    <label htmlFor="dtStartDateTime">{t("Date / Time")}:</label>
                </div>
                <div className="flexCellContainer_g1">
                    <GeovisReactDateTimePicker
                        locale="en-GB"
                        key="dtStartDateTime"
                        value={Timeslot.Value}
                        disabled={UseLastMeasurementTime}
                        onChange={onChartPropertyChangedFunc("Timeslot")} />
                </div>
                <ElementSettingAllowCheckbox
                    isChecked={Timeslot.customerChangeable}
                    onChange={onCustomerChangeablePropertyChangedFunc("Timeslot")}
                    isDisabled={UseLastMeasurementTime}
                />
            </div>

            <div className="flexRowContainer" style={settingRowStyle}>
                <div className="flexCellContainer" style={labelColumnStyle}>
                    <label htmlFor="cmbTimeSearchDistance">{t("Time-Searchdistance")}:</label>
                </div>
                <div className="flexCellContainer_g1">
                    <GeovisSelect
                        id="cmbPeriod"
                        options={getTimeSearchDistanceOptions()}
                        value={getTimeSearchDistanceOption(TimeSearchDistance.value)}
                        onChange={onTimeSearchDistancePropertyChangedFunc}
                        isCompact={true}
                        spacing='compact'
                    />
                </div>
                <div className="flexCellContainer">
                    <Tooltip
                        content={t("labelTimeSearchDistanceTooltip")}>
                        <Button
                            appearance='subtle-link'
                            iconBefore={<InfoIcon label="Information" size='small' primaryColor="#42526E" />}
                        />
                    </Tooltip>
                </div>
                <ElementSettingAllowCheckbox
                    isChecked={TimeSearchDistance.customerChangeable}
                    onChange={onCustomerChangeablePropertyChangedFunc("TimeSearchDistance")}
                />
            </div>

            {/* render mode */}
            <ChartSelectSettings
                customerChangeable={RenderingMode.customerChangeable}
                label={t("Rendering mode")}
                onCustomerChangeableChanged={onCustomerChangeablePropertyChangedFunc("RenderingMode")}
                onValueChanged={onChartPropertyChangedFunc("RenderingMode")}
                options={getIGvOptionTypeList(HeatmapRenderingModeList, getHeatmapRenderingModeToName)}
                value={RenderingMode.value}
            />
        </div>
    )
}