import Button from '@atlaskit/button';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import HipchatAudioOnlyIcon from '@atlaskit/icon/glyph/hipchat/audio-only';
import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IDynamicTableRow } from '../../../components/types';
import { dateTimeToReactMoment } from '../../../helpers/DateHelper';
import { IWithGeovisServerProps, withGeovisServer } from "../../../helpers/GeovisHooks";
import { t } from '../../../i18n';
import { EmailHistoryRequest } from "../../../server/AVTService/TypeLibrary/Email/EmailHistoryRequest";
import { EmailMessageHistoryEntry } from "../../../server/AVTService/TypeLibrary/Email/EmailMessageHistoryEntry";
import { DataActionResponse } from "../../../server/DataActionResponse";
import { EmailHistoryDataModel } from "../../../server/GEOvis3/Model/Email/EmailHistoryDataModel";
import ServerRoutesGen from "../../../server/Routes/ServerRoutesGen";
import FlagService from "../../../services/FlagService";
import { IBusinessCompaniesStorage, IBusinessUsersStorage, IMessageHistoryStore } from "../../../store/businessData.types";
import { geovisBusinessMessageHistory, geovisBusinessMessageHistoryError, geovisBusinessMessageHistoryFilterChanged, geovisBusinessMessageHistoryLoaded, geovisBusinessMessageHistoryShowItem } from "../../../store/creators/businessDataCreators";
import { IGeovisStoreState } from "../../../store/store.types";
import { IGeovisAction } from "../../../store/types";
import MessageDisplayDialog from './MessageDisplayDialog';
import MessagesHistoryFilterControl from './MessagesHistoryFilterControl';
import MessagesHistoryPaginationControl from './MessagesHistoryPaginationControl';

interface IMessagesHistoryListStateProps {
    messagesStore: IMessageHistoryStore;
    usersStore: IBusinessUsersStorage;
    companiesStore: IBusinessCompaniesStorage;
}

interface IMessagesHistoryListDispatchProps {
    onMessagesLoading: () => void;
    onMessagesLoaded: (data: EmailHistoryDataModel) => void;
    onMessageLoadingError: (error: string) => void;
    onShowHistoryDialog: (entry: EmailMessageHistoryEntry) => void;
    onFilterChanged: (filter: EmailHistoryRequest) => void;
}

interface IMessagesHistoryListProps extends IMessagesHistoryListStateProps, IMessagesHistoryListDispatchProps, IWithGeovisServerProps {

}

const MessagesHistoryList = ({
    messagesStore,
    Server,
    onMessagesLoaded,
    onMessagesLoading,
    onMessageLoadingError,
    onShowHistoryDialog
}: IMessagesHistoryListProps) => {

    const loadMessages = async () => {
        try {
            onMessagesLoading();

            const url = ServerRoutesGen.MessageTemplates.GetHistory.patch().path;
            const response = await Server.post<DataActionResponse<EmailHistoryDataModel>>(url, messagesStore.filter);

            if (!response.Success) {
                FlagService.addError("Collecting history failed", response.Messages.join(', '));
                onMessageLoadingError(response.Messages.join(', '));
            }

            onMessagesLoaded(response.Data);

        } catch (error) {
            FlagService.addError("Loading history failed", error);
            onMessageLoadingError(error instanceof Error ? error.message : `${error}`);
        }
    }

    useEffect(() => {
        (async function loadInitData() {
            await loadMessages();
        })();
    }, [messagesStore.filter])

    const onShowMessage = (entry: EmailMessageHistoryEntry) => () => {
        onShowHistoryDialog(entry);
    }

    const createMessageHistoryTableHead = (): IDynamicTableRow => ({
        key: 'message_templates_table_head',
        cells: [{
            key: 'date',
            content: t("Send date"),
            isSortable: true
        },
        // {
        //     key: 'sender',
        //     content: t("Sender")
        // }, {
        //     key: 'company',
        //     content: t("Company")
        // }, 
        {
            key: 'subject',
            content: t("Subject")
        }, {
            key: 'c_receivers',
            content: t("Count receivers")
        }, {
            key: 'actions',
            content: '',
            width: '50px'
        }]
    });

    const createMessageHistoryTableRows = (): IDynamicTableRow[] => {
        return messagesStore.history.map<IDynamicTableRow>(entry => {

            // const user = usersStore.users.get(entry.SenderId);
            // const userName = user ? user.FullName : "Unknown user";

            // const company = companiesStore.companies.get(entry.SenderCompanyId);
            // const companyName = company ? company.Name : "Unknown company";

            return {
                key: `history-row-${entry.Id}`,
                cells: [
                    {
                        key: 'date',
                        content: (<span>{dateTimeToReactMoment(entry.SendTime)}</span>)
                    },
                    // {
                    //     key: 'sender',
                    //     content: (<span>{userName}</span>),
                    // }, {
                    //     key: 'company',
                    //     content: (<span>{companyName}</span>),
                    // }, 
                    {
                        key: 'subject',
                        content: (<span>{entry.Subject}</span>),
                    }, {
                        key: 'c_receivers',
                        content: (<span>{entry.Recievers.length}</span>),
                    }, {
                        key: 'actions',
                        content: (
                            <Button
                                appearance='subtle'
                                iconBefore={<HipchatAudioOnlyIcon label="watch" />}
                                onClick={onShowMessage(entry)}
                            />
                        ),
                        width: '50px'
                    }
                ]
            }
        })
    }

    return (
        <div style={{ width: '100%', marginTop: '10px' }}>
            <MessagesHistoryFilterControl />
            <div style={{ width: '100%' }}>
                <DynamicTableStateless
                    // caption={t("Message templates")}
                    head={createMessageHistoryTableHead()}
                    rows={createMessageHistoryTableRows()}
                />
            </div>
            <MessagesHistoryPaginationControl />
            <MessageDisplayDialog />
        </div>
    )
}

const mapStateToProps = ({ businessData }: IGeovisStoreState): IMessagesHistoryListStateProps => ({
    messagesStore: businessData.messagesStore.historyStore,
    usersStore: businessData.usersStore,
    companiesStore: businessData.companiesStore
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IMessagesHistoryListDispatchProps => ({
    onMessagesLoaded: data => dispatch(geovisBusinessMessageHistoryLoaded(data.History, data.EntriesCount)),
    onMessagesLoading: () => dispatch(geovisBusinessMessageHistory()),
    onMessageLoadingError: (error) => dispatch(geovisBusinessMessageHistoryError(error)),
    onShowHistoryDialog: (entry) => dispatch(geovisBusinessMessageHistoryShowItem(entry)),
    onFilterChanged: (filter) => dispatch(geovisBusinessMessageHistoryFilterChanged(filter))
});

export default connect<IMessagesHistoryListStateProps, IMessagesHistoryListDispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(MessagesHistoryList))