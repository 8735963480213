//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GeovisFeedbackType {
	Application = 0,
	User = 1
}

const GeovisFeedbackTypeToName = new Map<number, string>([
	[ 0, 'Application' ],
	[ 1, 'User' ],
])

export function getGeovisFeedbackTypeToName(element: GeovisFeedbackType) : string {
    return GeovisFeedbackTypeToName.get(element) || '';
}

const GeovisFeedbackTypeByName = new Map<string, number>([
	[ 'Application', 0 ],
	[ 'User', 1 ],
]);

export function getGeovisFeedbackTypeByName(name: string): GeovisFeedbackType {
    return GeovisFeedbackTypeByName.get(name)!;
}


export const GeovisFeedbackTypeList : Readonly<GeovisFeedbackType[]> = [
	GeovisFeedbackType.Application,
	GeovisFeedbackType.User,
];



const GeovisFeedbackTypeToDescription = new Map<number, string>([
	[ 0, 'Application' ],
	[ 1, 'User' ],
])

export function getGeovisFeedbackTypeToDescription(element: GeovisFeedbackType) : string {
    return GeovisFeedbackTypeToDescription.get(element) || '';
}

