import { GeovisHeaderModel } from "../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisHeaderModel";
import { DataActionResponse } from "../../server/DataActionResponse";
import {
    PROJECT_REPORT_HEADER_LOADED,
    PROJECT_REPORT_HEADER_LOADING
} from "../actions/reportRenderActions";
import { IReportRenderAction } from "../reportRender.types";

export const projectReportHeaderLoading = (): IReportRenderAction => ({
    type: PROJECT_REPORT_HEADER_LOADING
});

export const projectReportHeaderLoaded = (reportHeaderInfo: DataActionResponse<GeovisHeaderModel>): IReportRenderAction => ({
    type: PROJECT_REPORT_HEADER_LOADED, reportHeaderInfo
});