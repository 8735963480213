/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 27.07.2023
 * @description Geovis map section map component
 */

import { MapContainer, MapContainerProps } from "react-leaflet";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Logger from "../../../../services/Logger";
import { IGeovisMapSectionReportInfo } from "../../../../store/projectReport.types";
import { MapEventsComponent } from "../../../leaflet/MapEventsComponent"
import { IViewport } from "../../../leaflet/types";
import { getMapCenter } from "../../../../helpers/MapHelper";
import { getMapSelectionBounds } from "../tools";
import { GeoLocation } from "../../../../server/GeoLocation";
import { IGeovisStoreState } from "../../../../store/store.types";
import { GEOvisDXFLayerType } from "../../../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { MaxMapZoomLevel, MaxProfileViewZoomLevel } from "../../../map/types";
import GeovisMapSectionMapLayers from "./GeovisMapSection.MapLayers";
import { IChainsInfoStorage, IChainsLastDataStorage, ISensorsInfoStorage, ISensorsLastDataStorage } from "../../../../store/data.types";
import { IReportElementRenderSimpleOwnProps } from "../../reportOverlay/reportRender/types";
import { GeovisMapSectionMapLegend } from "./GeovisMapSectionMapLegend";
import { GeovisMapPaneCreator } from "../../../map/GeovisMap.PaneCreator";
import { TimeSearchDistanceDataRequest } from "../../../../server/AVTService/Computation/TimeSearchDistance/TimeSearchDistanceDataRequest";
import { ProjectVisualSettings } from "../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";

// const LoggerSource = 'GeovisMapSectionMap';

interface IStateToProps {
    projectLocation: GeoLocation;
    visualSettings: ProjectVisualSettings;
}

interface IOwnProps extends Omit<IReportElementRenderSimpleOwnProps, 'isPrinting' | 'reportId'> {
    mapSectionInfo: IGeovisMapSectionReportInfo;

    sensorsInfoStorage: ISensorsInfoStorage;
    sensorsLastDataStorage: ISensorsLastDataStorage;
    chainsInfoStorage: IChainsInfoStorage;
    chainsLastDataStorage: IChainsLastDataStorage;

    timeSearchDistanceElementRequest: TimeSearchDistanceDataRequest;
}

interface IComponentProps extends IStateToProps, IOwnProps {
}

const GeovisMapSectionMap = ({
    mapSectionInfo,
    projectLocation,
    chainsInfoStorage,
    sensorsInfoStorage,
    sensorsLastDataStorage,
    pageNum,
    userId,
    userToken,
    chainsLastDataStorage,
    timeSearchDistanceElementRequest,
    visualSettings
}: IComponentProps) => {

    const [mapRef, setMapRef] = useState<L.Map | null>(null);
    const [readyForLegend, setReadyForLegend] = useState<boolean>(false);

    useEffect(() => {
        if (mapRef !== null) {

            //First: zoom and fit our map
            setTimeout(zoomAll, 200)
            //Second: report that ms is done
            if (onRendered) {
                //important thing! onRender should be fired after zoomAll
                setTimeout(onRendered, 400)
            }
        }
    }, [mapRef])

    const { MapSection } = mapSectionInfo;

    const isMapLayer = MapSection.LayerType === GEOvisDXFLayerType.Map;
    const maxZoom = isMapLayer ? MaxMapZoomLevel : MaxProfileViewZoomLevel;

    const selectionBounds = getMapSelectionBounds(MapSection.MapArea);
    const mapCenter = selectionBounds.isValid()
        ? selectionBounds.getCenter()
        : getMapCenter(projectLocation);

    const zoomAll = () => {

        if (mapRef !== null) {
            mapRef.fitBounds(getMapSelectionBounds(MapSection.MapArea));
            mapRef.invalidateSize();
        }
    }

    const onRendered = () => {
        setReadyForLegend(true);
        // eslint-disable-next-line no-console
        console.info(`MapSection was rendered: pageNum=${pageNum}&elementId=${MapSection.Id}`);
    }

    const mapContainerProps: MapContainerProps = {
        className: "mapSectionMap",
        center: mapCenter,
        zoomSnap: 0.1,
        zoom: MapSection.MapArea.ZoomLevel,
        maxZoom,
        //whenReady: onRendered,
        zoomControl: false,
        dragging: false,
        zoomAnimation: false,
        fadeAnimation: false,
        markerZoomAnimation: false,
        keyboard: false,
        scrollWheelZoom: false,
        tap: false,
        touchZoom: false,
        attributionControl: false,
        doubleClickZoom: false,
        style: { height: '100%', width: '100%' }
    }

    if (!isMapLayer) {
        mapContainerProps.crs = L.CRS.Simple;
    }

    const onViewportChanged = (viewport: IViewport) => {
        Logger.trace(`onViewportChanged:zoom=${viewport.zoom}, center=[${viewport.center?.[0]},${viewport.center?.[1]}]`)
    }


    return (
        <div className="mapSectionView" style={{
            width: '100%',
            // height: '100%'
        }}>
            <MapContainer {...mapContainerProps} ref={setMapRef}>

                {/* This component must be added on each map where Sensors, Chains or Movement information panned to render */}
                <GeovisMapPaneCreator />

                {/* тут только события, непонятно для чего этот компонент тут */}
                <MapEventsComponent
                    onViewportChanged={onViewportChanged}
                />

                <GeovisMapSectionMapLayers
                    projectLocation={projectLocation}
                    chainsInfoStorage={chainsInfoStorage}
                    sensorsInfoStorage={sensorsInfoStorage}
                    sensorsLastDataStorage={sensorsLastDataStorage}
                    mapSectionInfo={mapSectionInfo}
                    showSensorsLayer={true}
                    popupEnabled={false}
                    spiderfyEnabled={false}
                    isMapReady={readyForLegend}
                    tooltipEnabled={true}
                    userId={userId}
                    userToken={userToken}
                    chainsLastDataStorage={chainsLastDataStorage}
                    timeSearchDistanceElement={timeSearchDistanceElementRequest}
                />

                {readyForLegend && mapRef !== null &&
                    <GeovisMapSectionMapLegend
                        mapSectionInfo={mapSectionInfo}
                        visualSettings={visualSettings}
                    />
                }
            </MapContainer>
        </div>
    )
}

const mapStateToProps = ({ data: { projectInfo } }: IGeovisStoreState): IStateToProps => ({
    projectLocation: projectInfo.project.Location,
    visualSettings: projectInfo.project.VisualSettings
})

const Connected = connect<IStateToProps, never, IOwnProps>(mapStateToProps)(GeovisMapSectionMap);


export default Connected;