/** 
 * @author Vyacheslav Skripin <Vs@ieskr.ru>
 * @created 30.05.2022
 * @description This component draws 
 */

import { useLayoutEffect, useRef, useState } from 'react';
import { t } from '../../../../i18n';
import { AxisScaleLimit } from '../../../../server/AVTService/TypeLibrary/Model/AxisScaleLimit';

interface IComponentProps {
    enabled: boolean;

    minAxisValue: AxisScaleLimit;
    maxAxisValue: AxisScaleLimit;

    startAt: number;
    interval: number;
}

interface IComponentState {
    clientWidth: number;
}

interface ITickProps {
    index: number;
    value: number;
    x: number;
    isVisible: boolean;
}

export const XyChartLabelsHelp = ({
    enabled,
    interval,
    maxAxisValue,
    minAxisValue,
    startAt
}: IComponentProps) => {

    const mainDivRef = useRef<HTMLDivElement | null>(null);

    const [state, setState] = useState<IComponentState>({ clientWidth: 100 });

    useLayoutEffect(() => {

        if (!enabled) {
            return;
        }
        //

        if (mainDivRef && mainDivRef.current) {
            setState({
                clientWidth: mainDivRef.current.clientWidth
            });
        }

    }, [enabled]);

    if (!enabled) {
        return null;
    }

    const countTicks = 10;

    const startValue = getStartValue(minAxisValue, maxAxisValue);
    const endValue = getEndValue(minAxisValue, maxAxisValue);

    const minTickPosition = 15;
    const maxTickPosition = state.clientWidth - 15;

    const ticksVisibility = getTicksVisibility(+startAt, +interval, countTicks);

    const step = (endValue - startValue) / 10;
    const ticks: ITickProps[] = [];

    for (let index = 0; index <= countTicks; index++) {
        const tickValue = startValue + index * step;

        const xValue = minTickPosition + (maxTickPosition - minTickPosition) * index / countTicks;

        let isVisible = true;

        if (interval <= 0 && startAt < 0) {
            isVisible = true;
        } else {
            isVisible = ticksVisibility[index] || false;
        }

        ticks.push({
            index,
            value: +tickValue.toFixed(2),
            x: xValue,
            isVisible
        });
    }

    return (
        <div
            ref={mainDivRef}
            className='flexRowContainer'>
            {/*  */}
            <svg
                width={state.clientWidth}
                height="60"
                style={{
                    border: '1px solid',
                    borderColor: 'gray',
                    borderRadius: '5px'
                }}>
                {/* line of axis imitation */}
                <line x1={10} y1={5} x2={state.clientWidth - 10} y2={5} stroke="gray" />

                {/* ticks of values */}
                {ticks.map(({ index, x, isVisible }) => (
                    <line key={index} x1={x} x2={x} y1={5} y2={15} stroke={isVisible ? 'black' : 'lightgray'} />
                ))}

                {/* labels of axis ticks */}
                {ticks.map(({ index, value, x, isVisible }) => (
                    <text key={index} x={x - 4} y={30} fill={isVisible ? 'black' : 'lightgray'}>{value}</text>
                ))}

                {/* help */}
                <rect x={20} y={45} height={10} width={30} fill="black" />
                <text x={60} y={55}>-{t("visible ticks")}</text>

                <rect x={200} y={45} height={10} width={30} fill="lightgray" />
                <text x={240} y={55}>-{t("invisible ticks")}</text>
            </svg>
        </div>
    )
}

const getStartValue = (minAxisValue: AxisScaleLimit, maxAxisValue: AxisScaleLimit): number => {
    if (minAxisValue.auto && maxAxisValue.auto) {
        return 0;
    }

    if (minAxisValue.auto && !maxAxisValue.auto) {
        return maxAxisValue.value - 10;
    }

    return minAxisValue.value;
}

const getEndValue = (minAxisValue: AxisScaleLimit, maxAxisValue: AxisScaleLimit): number => {
    if (minAxisValue.auto && maxAxisValue.auto) {
        return 10;
    }

    if (!minAxisValue.auto && maxAxisValue.auto) {
        return minAxisValue.value + 10;
    }

    return maxAxisValue.value;
}

const getTicksVisibility = (startAt: number, interval: number, countTicks: number): boolean[] => {

    const result: boolean[] = [];
    let localInterval = interval;

    for (let index = 0; index <= countTicks; index++) {
        if (index < startAt) {
            result.push(false);
            continue;
        }

        if (index === startAt) {
            result.push(true);
            continue;
        }

        if (localInterval <= 0) {
            result.push(true);
            localInterval = interval;
            continue;
        }

        result.push(false);
        localInterval--;
    }

    return result;
}