import Button from "@atlaskit/button";
import Checkbox from '@atlaskit/checkbox';
import DynamicTable from '@atlaskit/dynamic-table';
import { HeadType, RowType } from '@atlaskit/dynamic-table/types';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import Tooltip from '@atlaskit/tooltip';
import { SyntheticEvent } from "react";
import { t } from "../../../i18n";
import { ChartBackground } from "../../../server/AVTService/TypeLibrary/Model/ChartBackground";
import GeovisColorPicker from "../../GeovisColorPicker";
import { GeovisTimePicker } from "../../timepicker/GeovisTimePicker";

export interface IChartAlarmAreasControlActionProps {
    onAlarmAreasChanged: (updAreas: ChartBackground[]) => void;
}

interface IComponentProps extends IChartAlarmAreasControlActionProps {
    areas: ChartBackground[];

}

export const ChartAlarmAreasControl = ({ areas, onAlarmAreasChanged }: IComponentProps) => {

    const allDays = [1, 2, 3, 4, 5, 6, 0];

    const defaultBackgroundArea: ChartBackground = {
        ...new ChartBackground(),
        enabled: true,
        color: "#000000",
        from: "00:00",
        to: "00:00",
        weekDays: []
    }

    const onAddArea = () => {
        areas.push(defaultBackgroundArea);
        onAlarmAreasChanged(areas);
    }

    const onRemoveArea = (index: number) => () => {
        areas.splice(index, 1);
        onAlarmAreasChanged(areas);
    }

    const getTableHead = (): HeadType => {
        return ({
            cells: [{
                key: 'enabled',
                content: t("Enabled")
            }, {
                key: 'from',
                content: t("From time")
            }, {
                key: 'to',
                content: t("To time")
            }, {
                key: 'color',
                content: t("Color")
            }, {
                key: 'days',
                content: t("Week days")
            }, {
                key: 'actions'
            }]
        })
    }

    const onColorChangedHandler = (index: number) => (hexColor: string, isPickerClosed: boolean) => {
        if (isPickerClosed) {
            // onColorChanged(color.hex);
            areas[index].color = hexColor;
            onAlarmAreasChanged(areas);
        }
    }

    const onDaySelectionChanged = (index: number) => (dayNumber: number, include: boolean) => {
        if (include && !areas[index].weekDays.includes(dayNumber)) {
            areas[index].weekDays.push(dayNumber);
        }
        else {
            areas[index].weekDays = areas[index].weekDays.filter(wd => wd !== dayNumber);
        }
        onAlarmAreasChanged(areas);
    }

    const onTimeChanged = (index: number, key: keyof ChartBackground) => (value: string) => {
        if (key === 'from') {
            areas[index].from = value;
        }
        if (key === 'to') {
            areas[index].to = value;
        }
        onAlarmAreasChanged(areas);
    }

    const onEnableChanged = (index: number) => () => {
        areas[index].enabled = !areas[index].enabled;
        onAlarmAreasChanged(areas);
    }


    const getDayDescription = (dayNumber: number): string => {

        switch (dayNumber) {
            case 0: return t("SU");
            case 1: return t("MO");
            case 2: return t("TU");
            case 3: return t("WE");
            case 4: return t("TH");
            case 5: return t("FR");
            case 6: return t("SA");
            default: return "";
        }
    }

    const drawDayCheckbox = (dayNumber: number, days: number[], onChange: (dayNumber: number, include: boolean) => void) => {

        const dayDescription = getDayDescription(dayNumber);

        const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
            onChange(dayNumber, event.currentTarget.checked);
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', margin: '3px' }}>
                <span>{dayDescription}</span>
                <Checkbox
                    isChecked={days.includes(dayNumber)}
                    onChange={onChangeHandler}
                />
            </div>
        )
    }

    const getTableRows = (): RowType[] => {
        return areas.map((area, index) => ({
            key: `area-row-${index}`,
            cells: [{
                key: 'enabled',
                content: (
                    <Checkbox
                        isChecked={area.enabled}
                        onChange={onEnableChanged(index)}
                    />
                )
            }, {
                key: 'from',
                content: (
                    <div style={{ width: '100px' }}>
                        <GeovisTimePicker
                            onChange={onTimeChanged(index, 'from')}
                            value={area.from}
                            timeIsEditable={true}
                        />
                    </div>
                )
            }, {
                key: 'to',
                content: (
                    <div style={{ width: '100px' }}>
                        <GeovisTimePicker
                            onChange={onTimeChanged(index, 'to')}
                            value={area.to}
                            timeIsEditable={true}
                        />
                    </div>
                )
            }, {
                key: 'color',
                content: (
                    <div>
                        <GeovisColorPicker
                            hexColor={area.color}
                            onColorChange={onColorChangedHandler(index)}
                        />
                    </div>
                )
            }, {
                key: 'days',
                content: (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {allDays.map(day => {
                            return drawDayCheckbox(day, area.weekDays, onDaySelectionChanged(index))
                        })}
                    </div>
                )
            }, {
                key: 'actions',
                content: (
                    <Button
                        iconBefore={<CrossCircleIcon label="remove" />}
                        title={t("Delete alarm area")}
                        spacing='compact'
                        appearance="subtle-link"
                        onClick={onRemoveArea(index)}
                    />
                )
            }]
        }))
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '15px' }}>
            <div style={{ alignSelf: 'flex-start' }}>
                <h2>{t("Alarm areas")}</h2>
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
                <Tooltip
                    content={t("Add new Alarm area")}>
                    <Button
                        appearance="primary"
                        spacing="compact"
                        onClick={onAddArea}
                    >
                        {t("Add alarm area")}
                    </Button>
                </Tooltip>
            </div>
            <div>
                <DynamicTable
                    rows={getTableRows()}
                    head={getTableHead()}
                />
            </div>
        </div>
    )

}