import { addMinutes } from "date-fns";
import Highcharts, { AxisTickPositionsArray } from "highcharts";
import moment from "moment";
import { addDays, formattedDateTime, getFormattedDateTime } from "../../../../../../helpers/DateHelper";
import { elementsToMapWithConverted } from "../../../../../../helpers/StorageHelper";
import { t } from "../../../../../../i18n";
import { ChartRegressionSettings } from "../../../../../../server/AVTService/TypeLibrary/Common/ChartRegressionSettings";
import { SensorAttribyteEntry } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorAttribyteEntry";
import { getSensorValueAttributeByName, SensorValueAttribute } from "../../../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { GeovisChartSensorDataModel } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartSensorDataModel";
import { GeovisChartSlimConfig } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartSlimConfig";
import { TimeValueChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/TimeValueChartData";
import { ColorizableElement } from "../../../../../../server/AVTService/TypeLibrary/DB/ColorizableElement";
import { LogbookModel } from "../../../../../../server/AVTService/TypeLibrary/Logbook/LogbookModel";
import { AlarmInfoRecord } from "../../../../../../server/AVTService/TypeLibrary/Model/AlarmInfoRecord";
import { PlotBandModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/PlotBandModel";
import { TimeValueChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { HtmlReportSensorDescriptionViewModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/HtmlReportSensorDescriptionViewModel";
import { TimeValueChartDataModel } from "../../../../../../server/AVTService/TypeLibrary/Report/Model/TimeValueChartDataModel";
import { SensorSymbol } from "../../../../../../server/AVTService/TypeLibrary/Sensors/SensorSymbol";
import AuthService from "../../../../../../services/AuthService";
import { createChartRenderedFunc } from "../../tools";
import { getRegressionSettings } from "../../tools.regression";
import {
    getCustomTooltipInfo,
    getDashStyleValue,
    getExtremumValue,
    getGeovisChartSeriesLineWidth,
    getMarkerSymbol,
    getSeriesSymbol,
    getSeriesUnitShortName,
    getStyledPointName,
    getYAxisOptions,
    getYAxisUnitsAnnotation,
    LOGBOOK_AXIS_NAME,
    selectValue
} from "./chartRenderOptions";
import { getBoostOptions } from "./tools";

interface ITimeValueRenderOptionsSettings {
    chart: TimeValueChartModel;
    leftYAxisSensorIds: string[],
    rightYAxisSensorIds: string[],
    chartDataSlimConfig: GeovisChartSlimConfig;
    startMeasurement: string;
    onChartRender: () => void;
    sensorsData: GeovisChartSensorDataModel[];
    secondaryAxisSensorsData: GeovisChartSensorDataModel[];
    logbooks: LogbookModel[];
    mainAxisDescriptions: HtmlReportSensorDescriptionViewModel[];
    secondaryAxisDescriptions: HtmlReportSensorDescriptionViewModel[];
    timeslotTo: string;
    timeslotFrom: string;
    showNoData: boolean;
    linkedAlarmLines: AlarmInfoRecord[];
    bandModels: PlotBandModel[];
    relativeTime?: string;
    visualSettings: ProjectVisualSettings;
    minYValues: number[];
    maxYValues: number[];
    minYSecondaryValues: number[];
    maxYSecondaryValues: number[];
    index: number;
}

export const getTimeValueChartRenderOptions = (pageNum: number, chart: TimeValueChartModel, chartData: TimeValueChartData): Highcharts.Options[] => {

    const settings: ITimeValueRenderOptionsSettings = {
        chart,
        leftYAxisSensorIds: chartData.LeftYAxisSensorIds,
        rightYAxisSensorIds: chartData.RightYAxisSensorIds,
        chartDataSlimConfig: chartData.SlimChartConfig,
        mainAxisDescriptions: chartData.MainAxisDescriptions,
        secondaryAxisDescriptions: chartData.SecondaryAxisDescriptions,
        logbooks: chartData.Logbooks,
        onChartRender: createChartRenderedFunc(pageNum, chart.Id),
        sensorsData: chartData.SensorsData,
        secondaryAxisSensorsData: chartData.SecondaryAxisSensorsData,
        showNoData: chartData.ShowNoData,
        startMeasurement: chartData.startMeasurementTime,
        timeslotFrom: chartData.TimeSlotFrom,
        timeslotTo: chartData.TimeSlotTo,
        relativeTime: chart.UseRelativeTimeSetting.value ? chart.RelativeTime : '',
        linkedAlarmLines: chartData.LinkedAlarmLines,
        bandModels: chartData.PlotBands,
        visualSettings: chartData.visualSettings,
        maxYValues: chartData.MaxYValues,
        minYValues: chartData.MinYValues,
        maxYSecondaryValues: chartData.MaxYSecondaryValues,
        minYSecondaryValues: chartData.MinYSecondaryValues,
        index: 0
    };

    const result: Highcharts.Options[] = [];

    const leftAxisValueType = getSensorValueAttributeByName(chart.LeftYAxisSettings.sensorValue);
    const rightAxisValueType = getSensorValueAttributeByName(chart.RightYAxisSettings.sensorValue);

    const settingsToBeProcessed: ITimeValueRenderSingleOptionsSettings[] = [];

    if (leftAxisValueType === SensorValueAttribute.ValuesXYZ) {
        const vaList: SensorValueAttribute[] = [SensorValueAttribute.Value1, SensorValueAttribute.Value2, SensorValueAttribute.Value3];
        vaList.forEach((value, index) => {
            let normalizedRightAxisValueType = rightAxisValueType;
            if (normalizedRightAxisValueType === SensorValueAttribute.ValuesXYZ) {
                normalizedRightAxisValueType = value;
            }
            else if (normalizedRightAxisValueType === SensorValueAttribute.Axis) {
                normalizedRightAxisValueType = value + 10;
            }
            settingsToBeProcessed.push({ ...settings, leftAxisValueType: value, baseValueType: leftAxisValueType, rightAxisValueType: normalizedRightAxisValueType, needLeftAxis: true, needRightAxis: true, firstChartInList: index === 0, lastChartInList: index === 2, index });
        })
    }
    else if (leftAxisValueType === SensorValueAttribute.Axis) {
        const vaList: SensorValueAttribute[] = [SensorValueAttribute.AxisValue1, SensorValueAttribute.AxisValue2, SensorValueAttribute.AxisValue3];
        vaList.forEach((value, index) => {
            let normalizedRightAxisValueType = rightAxisValueType;
            if (normalizedRightAxisValueType === SensorValueAttribute.ValuesXYZ) {
                normalizedRightAxisValueType = value - 10;
            }
            else if (normalizedRightAxisValueType === SensorValueAttribute.Axis) {
                normalizedRightAxisValueType = value;
            }
            settingsToBeProcessed.push({ ...settings, leftAxisValueType: value, baseValueType: leftAxisValueType, rightAxisValueType: normalizedRightAxisValueType, needLeftAxis: true, needRightAxis: true, firstChartInList: index === 0, lastChartInList: index === 2, index });
        })
    }
    else {
        let rightBaseValueType = rightAxisValueType;
        let normalizedRightAxisValueType = rightAxisValueType;
        if (normalizedRightAxisValueType === SensorValueAttribute.ValuesXYZ) {
            normalizedRightAxisValueType = SensorValueAttribute.Value1;
            rightBaseValueType = leftAxisValueType;
        }
        else if (normalizedRightAxisValueType === SensorValueAttribute.Axis) {
            normalizedRightAxisValueType = SensorValueAttribute.AxisValue1;
            rightBaseValueType = leftAxisValueType;
        }
        settingsToBeProcessed.push({ ...settings, leftAxisValueType, baseValueType: leftAxisValueType, rightBaseValueType, rightAxisValueType: normalizedRightAxisValueType, needLeftAxis: true, needRightAxis: true, firstChartInList: true, lastChartInList: true });
    }

    for (let index = 0; index < settingsToBeProcessed.length; index++) {
        const singleOption = getSingleValueTypeOptions({ ...settingsToBeProcessed[index], firstChartInList: index === 0, lastChartInList: index === settingsToBeProcessed.length - 1 });
        if (singleOption !== undefined) {
            result.push(singleOption);
        }
        else if (index === 0) {
            // if first chart of few has no data it should not be displayed,
            // so to avoid some errors, the best solution is to remove processible setting from list
            settingsToBeProcessed.shift();
            index--;
        }
        else if (index === settingsToBeProcessed.length - 1) {
            // if that was the last chart of few we should process again previous chart options to let it display legend
            settingsToBeProcessed.pop();
            result.pop();
            index -= 2;
        }
    }

    return result;
}

interface ITimeValueRenderSingleOptionsSettings extends ITimeValueRenderOptionsSettings {
    leftAxisValueType: SensorValueAttribute,
    baseValueType: SensorValueAttribute,
    rightBaseValueType?: SensorValueAttribute,
    rightAxisValueType: SensorValueAttribute,
    needLeftAxis: boolean;
    needRightAxis: boolean;
    firstChartInList: boolean;
    lastChartInList: boolean;
}

const getSingleValueTypeOptions = (settings: ITimeValueRenderSingleOptionsSettings): Highcharts.Options | undefined => {
    const leftAxisSensorsData = settings.needLeftAxis ? elementsToMapWithConverted(settings.sensorsData, s => [s.SensorFullId, s.Data]) : new Map<string, TimeValueChartDataModel[]>();
    const rightAxisSensorsData = settings.needRightAxis ? elementsToMapWithConverted(settings.secondaryAxisSensorsData, s => [s.SensorFullId, s.Data]) : new Map<string, TimeValueChartDataModel[]>();

    const LeftYAxisSettings = settings.chart.LeftYAxisSettings;

    const dataSeries = getTimeValueSeries(
        settings.chart, {
        needMarkers: LeftYAxisSettings.ShowPointSymbols.value,
        sensorsData: leftAxisSensorsData,
        mainAxisSensorsInfo: settings.mainAxisDescriptions,
        secondaryAxisSensorsInfo: settings.secondaryAxisDescriptions,
        showNoData: settings.showNoData,
        yAxisNumber: 0,
        legendText: settings.chart.LegendText,
        valueType: settings.leftAxisValueType,
        baseValueType: settings.baseValueType,
        convMult: LeftYAxisSettings.DestConverter.multiplicatorValue ?? 1,
        useConversion: LeftYAxisSettings.DestConverter.enable,
        regression: LeftYAxisSettings.ShowRegression === undefined ? false : LeftYAxisSettings.ShowRegression.value,
        regressionSettings: LeftYAxisSettings.RegressionSettings,
        numberOfDigits: LeftYAxisSettings.numberOfDigits.value,
        visualSettings: settings.visualSettings,
        isRightAxis: false
    });

    const logbookDataSeries = getLogbookSeries(settings.logbooks);

    let hasData = false;

    dataSeries.forEach(seria => {
        if (seria.custom && seria.custom.countData) {
            hasData = true;
        }
    });

    dataSeries.push(...logbookDataSeries);

    if (!hasData && !(settings.lastChartInList && settings.firstChartInList)) {
        return undefined;
    }

    const RightYAxisSettings = settings.chart.RightYAxisSettings;

    dataSeries.push(...getTimeValueSeries(
        settings.chart, {
        needMarkers: RightYAxisSettings.ShowPointSymbols.value,
        sensorsData: rightAxisSensorsData,
        mainAxisSensorsInfo: settings.mainAxisDescriptions,
        secondaryAxisSensorsInfo: settings.secondaryAxisDescriptions,
        showNoData: settings.showNoData,
        yAxisNumber: 1,
        legendText: settings.chart.LegendText,
        valueType: settings.rightAxisValueType,
        baseValueType: settings.rightBaseValueType || settings.baseValueType,
        convMult: RightYAxisSettings.DestConverter.multiplicatorValue ?? 1,
        useConversion: RightYAxisSettings.DestConverter.enable,
        regression: RightYAxisSettings.ShowRegression === undefined ? false : RightYAxisSettings.ShowRegression.value,
        regressionSettings: RightYAxisSettings.RegressionSettings,
        numberOfDigits: RightYAxisSettings.numberOfDigits.value,
        visualSettings: settings.visualSettings,
        isRightAxis: true
    }));

    const annotations = getAnnotations(settings.chart, leftAxisSensorsData, settings.mainAxisDescriptions, settings.leftAxisValueType, 0);
    annotations.push(...getAnnotations(settings.chart, rightAxisSensorsData, settings.mainAxisDescriptions, settings.rightAxisValueType, 1));
    annotations.push(...settings.logbooks.map(logbook => getLogbookAnnotations(logbook)));
    annotations.push(getYAxisUnitsAnnotation(LeftYAxisSettings, 0));
    if (RightYAxisSettings.TypeOfSensor !== undefined) {
        annotations.push(getYAxisUnitsAnnotation(RightYAxisSettings, 1));
    }


    const options: Highcharts.Options = {
        boost: getBoostOptions(settings.chart.Type, settings.chartDataSlimConfig),
        chart: {
            zooming: {
                type: settings.chart.IsChartZoomAllow ? "xy" : undefined
            },
            reflow: true,
            alignTicks: true,
            showAxes: true,
            events: {
                render: settings.lastChartInList ? settings.onChartRender : undefined,
            }
        },
        annotations,
        title: {
            floating: false,
            text: "<div style='height:10px'></div>",
            useHTML: true
        },
        lang: {
            noData: `<div style='z-index:20'>${settings.leftYAxisSensorIds.length > 0 ? t('No data is available in the chart') : t('No sensor selected for drawing the chart')}</div>`
        },
        noData: {
            useHTML: true
        },
        legend: {
            enabled: settings.chart.ShowLegend.value && settings.lastChartInList,
            itemStyle: {
                fontSize: "14px"
            }
        },
        tooltip: {
            // style: {
            //     padding: '5px',
            // },
            snap: 5,
            outside: false,
            shared: settings.chart.ShowCommonTooltip.value,
            split: false,
            useHTML: true,
            formatter() {
                const tooltipDate = settings.chart.UseRelativeTimeSetting.value
                    ? getRelativeLabelText(settings.chart.RelativeTime, this.x ?? "", 1, true)
                    : moment(new Date(this.x ?? "")).format("DD.MM.YYYY HH:mm:ss");

                const getYString = (point: Highcharts.TooltipFormatterContextObject): string => {
                    const seriesOptions = point.series.options;

                    const isLogbookTooltip: boolean = seriesOptions.custom !== undefined && seriesOptions.custom.logbookId !== undefined;

                    if (isLogbookTooltip) {
                        const logbook = settings.logbooks.find(lb => lb.id === seriesOptions.custom?.logbookId);
                        if (logbook) {
                            return logbook.descriptionText;
                        }
                    }

                    const strPointY =
                        point.y === null || point.y === undefined ? 'null' :
                            seriesOptions.custom ? point.y.toFixed(seriesOptions.custom.numberOfDigits) : point.y.toString();

                    let name = seriesOptions.custom ? seriesOptions.custom.sensorId : point.series.name;
                    if (seriesOptions.custom && seriesOptions.custom.regression) {
                        name = `${name} (${seriesOptions.custom.regressionName})`;
                    }

                    // const symbol = (point.series as any).symbol;

                    return `${getSeriesSymbol({ symbol: 'circle', color: this.color })} ${getStyledPointName(name, point.color)}: ${strPointY} ${getSeriesUnitShortName(settings.chart, point.series.yAxis.side)}<br/>`;
                }

                if (settings.chart.ShowCommonTooltip.value) {
                    let commonTooltip = `${tooltipDate}<br />`

                    if (this.points) {
                        this.points.forEach(point => {
                            commonTooltip += getYString(point);
                        });
                    }
                    else {
                        commonTooltip += getYString(this);
                    }

                    return commonTooltip;
                }
                else {
                    let defaultTooltip = `${tooltipDate}<br/>${getYString(this)}`;
                    const isLogbookTooltip: boolean = this.series.options.custom !== undefined && this.series.options.custom.logbookId !== undefined;
                    if (isLogbookTooltip) {
                        // eslint-disable-next-line no-console
                        console.log("Here should be tooltip " + defaultTooltip);
                        return defaultTooltip;
                    }

                    if (settings.chart.ShowCustomTooltip && settings.chart.CustomTooltip.length > 0) {
                        const seriesOptions = this.point.series.options;
                        const name = seriesOptions.custom ? seriesOptions.custom.sensorFullId : this.point.series.name;
                        defaultTooltip += `${getCustomTooltipInfo(name, settings.mainAxisDescriptions)}`;
                    }
                    return defaultTooltip;
                }

            }
        },
        xAxis: {
            type: 'datetime',
            crosshair: true,
            startOnTick: false,
            endOnTick: false,
            minRange: 10000,
            lineWidth: 2,
            lineColor: "black",
            minorTickInterval: 'auto',
            min: getXLimitValue(settings.chart, settings.timeslotFrom),
            max: getXLimitValue(settings.chart, settings.timeslotTo),
            gridZIndex: 2,
            plotBands: getPlotBands(settings.bandModels),
            labels: {
                style: {
                    fontSize: settings.visualSettings.chartAxisLabelFontSize
                },
                formatter() {
                    const labelData = this.value;
                    if (this.axis.max !== null && this.axis.min !== null && this.axis.max > this.axis.min) {

                        const dateMin = new Date(this.axis.min);
                        const dateMax = new Date(this.axis.max);

                        const dateDiff = Math.abs(dateMax.getTime() - dateMin.getTime());
                        const diffDays = dateDiff / (1000 * 3600 * 24);

                        if (!settings.relativeTime) {
                            if (diffDays > 4) {
                                return getFormattedDateTime(new Date(labelData), "DD.MM.YYYY")
                            }
                            else if (diffDays > 1) {
                                return getFormattedDateTime(new Date(labelData), "DD.MM.YYYY HH:mm:ss")
                            }
                            else if (diffDays < 1) {
                                return getFormattedDateTime(new Date(labelData), "HH:mm:ss")
                            }
                        }
                        else {
                            return getRelativeLabelText(settings.relativeTime, labelData, diffDays, true);
                        }
                    }
                    return getFormattedDateTime(new Date(labelData), "DD.MM.YYYY HH:mm:ss")
                }
            },
            tickPositioner() {
                if (this.max !== null && this.min !== null) {
                    const positions: AxisTickPositionsArray = [];

                    if (this.min === this.max) {
                        return positions;
                    }

                    let tick = this.min;
                    const increment = Math.ceil((this.max - this.min) / 10);

                    const diapasonLengthInDays = (this.max - this.min) / 1000 / 3600 / 24;

                    if (diapasonLengthInDays > 10) {
                        const dateInc = Math.floor(diapasonLengthInDays / 10);
                        while (tick < this.max) {
                            const tickDate = new Date(tick);
                            tickDate.setHours(0, 0, 0, 0);
                            if (tick === this.min && tickDate < new Date(tick)) {
                                positions.push(addDays(tickDate, 1).getTime());
                            }
                            else if (tickDate > new Date(this.max)) {
                                positions.push(addDays(tickDate, -1).getTime());
                            }
                            else {
                                positions.push(tickDate.getTime())
                            }
                            tickDate.setDate(tickDate.getDate() + dateInc);
                            tick = tickDate.getTime();
                        }
                    }
                    else if (diapasonLengthInDays > 5) {
                        while (tick < this.max) {
                            const tickDate = new Date(tick);
                            tickDate.setHours(0, 0, 0, 0);
                            if (tick === this.min && tickDate < new Date(tick)) {
                                positions.push(addDays(tickDate, 1).getTime());
                            }
                            else if (tickDate > new Date(this.max)) {
                                positions.push(addDays(tickDate, -1).getTime());
                            }
                            else {
                                positions.push(tickDate.getTime())
                            }
                            tickDate.setDate(tickDate.getDate() + 1);
                            tick = tickDate.getTime();
                        }
                    }
                    else {
                        for (tick; tick - increment <= this.max; tick += increment) {
                            positions.push(tick);
                        }
                    }

                    return positions;
                }

                return this.tickPositions ?? [];
            },
            plotLines: getPlotLines(settings.chart)
        },
        yAxis: getYAxisOptions({
            chart: settings.chart,
            drawAlarmLines: true,
            linkedAlarmLines: settings.linkedAlarmLines,
            needSecondAxis: true,
            visualSettings: settings.visualSettings,
            hideUnitsInLabel: false,
            primaryAxisValueType: settings.leftAxisValueType,
            secondaryAxisValueType: settings.rightAxisValueType,
            minValue: getExtremumValue(settings.minYValues, settings.index),
            maxValue: getExtremumValue(settings.maxYValues, settings.index),
            maxYSecondary: getExtremumValue(settings.maxYSecondaryValues, settings.index),
            minYSecondary: getExtremumValue(settings.minYSecondaryValues, settings.index),
            noData: !hasData
        }),
        series: dataSeries,
        exporting: {
            enabled: false,
        },
        plotOptions: {
            line: {
                findNearestPointBy: 'xy'
            },
            series: {
                animation: false,
                events: {
                    legendItemClick() {
                        return false;
                    }
                }
            }
        },
        credits: {
            enabled: false
        }
    };

    if (settings.chart.ShowStartMeasurements && settings.firstChartInList) {
        const startMeasurementText = settings.startMeasurement ? formattedDateTime(settings.startMeasurement, "DD.MM.YYYY") : 'no data';

        options.subtitle = {
            align: 'center',
            text: `${t("Start of measurements")}: ${startMeasurementText}`
        };
    }

    return options;
}

const getRelativeLabelText = (relativeTime: string, labelData: string | number, diffDays: number, hideZeroLabel: boolean): string => {

    const isNagraDistribution = AuthService.isNagraDistribution();

    const relativeDate = new Date(relativeTime);
    const currentDate = new Date(labelData);

    let years: number = 0;
    let months: number = 0;
    let weeks: number = 0;
    let days: number = 0;
    let hours: number = 0;
    let minutes: number = 0;
    let seconds: number = 0;

    const diffOrig = relativeDate.getTime() - currentDate.getTime();
    const diff = Math.abs(diffOrig);

    if (!isNagraDistribution) {
        years = Math.floor(diff / (1000 * 3600 * 24 * 365));
        let leftTime = diff - years * 1000 * 3600 * 24 * 365;
        months = Math.floor(leftTime / (1000 * 3600 * 24 * 30));
        leftTime -= months * 1000 * 3600 * 24 * 30;
        weeks = Math.floor(leftTime / (1000 * 3600 * 24 * 7));
        leftTime -= weeks * 1000 * 3600 * 24 * 7;
        days = Math.floor(leftTime / (1000 * 3600 * 24));
        leftTime -= days * 1000 * 3600 * 24;
        hours = Math.floor(leftTime / (1000 * 3600));
        leftTime -= hours * 1000 * 3600;
        minutes = Math.floor(leftTime / (1000 * 60));
        leftTime -= minutes * 1000 * 60;
        seconds = Math.floor(leftTime / 1000);
    }
    else {
        days = Math.floor(diff / (1000 * 3600 * 24));
        let leftTime = diff - days * 1000 * 3600 * 24;
        hours = Math.floor(leftTime / (1000 * 3600));
        leftTime -= hours * 1000 * 3600;
        minutes = Math.floor(leftTime / (1000 * 60));
        leftTime -= minutes * 1000 * 60;
        seconds = Math.floor(leftTime / 1000);
    }

    if (years === 0 && months === 0 && days === 0 && weeks === 0 && hours === 0 && minutes === 0 && seconds === 0 && hideZeroLabel) {
        return ""; // Do not draw label for 0 point
    }

    return `${diffOrig > 0 ? "-" : ""}${years ? `${years}y ` : ""}${months ? `${months}m ` : ""}${weeks ? `${weeks}w ` : ""}${days ? `${days}d ` : ""}${hours && diffDays <= 2 ? `${hours}h ` : ""}${minutes && diffDays <= 2 ? `${minutes}min ` : ""}${seconds && diffDays <= 1 ? `${seconds}s` : ""}`;
}

const getPlotBands = (bandModels: PlotBandModel[]): Highcharts.XAxisPlotBandsOptions[] => {
    const bands: Highcharts.XAxisPlotBandsOptions[] = [];

    // if (showNoData) {
    //     return bands;
    // }

    bandModels.forEach(bm => {
        const from = new Date(bm.From);
        const to = new Date(bm.To);

        bands.push({
            from: addMinutes(from, from.getTimezoneOffset()).getTime(),
            to: addMinutes(to, to.getTimezoneOffset()).getTime(),
            color: bm.Color,
            zIndex: 2
        })
    })

    return bands;
}

const getPlotLines = (chart: TimeValueChartModel): Highcharts.XAxisPlotLinesOptions[] => {
    const plotLines: Highcharts.XAxisPlotLinesOptions[] = [];
    if (chart.UseRelativeTimeSetting.value) {
        plotLines.push({
            label: {
                text: "0",
                textAlign: "right",
                verticalAlign: "bottom",
                rotation: 0
            },
            color: "black",
            value: (new Date(chart.RelativeTime)).getTime(),
            width: 2,
            dashStyle: "Solid"
        })
    }
    return plotLines;
}

const getXLimitValue = (chart: TimeValueChartModel, limitTimeslot: string): null | number => {
    // if (chart.ShowAllMeasurementsSetting.value) {
    //     return null;
    // }
    return prepareDate(limitTimeslot);
}

/**
 * Get vertical lines for references and offsets
 * @param chart 
 * @param sensorsData 
 * @param sensorsInfo 
 * @param axisValueType 
 * @param yAxisNumber 
 * @returns 
 */
const getAnnotations = (chart: TimeValueChartModel, sensorsData: Map<string, TimeValueChartDataModel[]>, sensorsInfo: HtmlReportSensorDescriptionViewModel[], axisValueType: SensorValueAttribute, yAxisNumber: number): Highcharts.AnnotationsOptions[] => {

    const annotations: Highcharts.AnnotationsOptions[] = [];

    if (!chart.ShowReferenceAndOffset) {
        return [];
    }

    const getEntryData = (data: TimeValueChartDataModel[], date: string): number[] => {
        const result = data.find(d => d.x === date);
        return result ? result.value : [];
    }

    sensorsInfo.forEach((info) => {
        const data = sensorsData.get(info.id);
        let offsets: SensorAttribyteEntry[] = [];
        let references: SensorAttribyteEntry[] = [];
        if (data) {
            if (axisValueType === SensorValueAttribute.Axis || axisValueType === SensorValueAttribute.AxisValue1 || axisValueType === SensorValueAttribute.AxisValue2 || axisValueType === SensorValueAttribute.AxisValue3) {
                offsets = info.axisOffsets.map(offset => ({ date: offset.date, values: getEntryData(data, offset.date) }));
                references = info.axisReferences.map(reference => ({ date: reference.date, values: getEntryData(data, reference.date) }));
            }
            else if (axisValueType === SensorValueAttribute.ValuesXYZ || axisValueType === SensorValueAttribute.Value1 || axisValueType === SensorValueAttribute.Value2 || axisValueType === SensorValueAttribute.Value3) {
                offsets = info.offsets.map(offset => ({ date: offset.date, values: getEntryData(data, offset.date) }));
                references = info.references.map(reference => ({ date: reference.date, values: getEntryData(data, reference.date) }));
            }
        }

        annotations.push({
            draggable: undefined,
            labelOptions: {
                backgroundColor: 'rgba(255,255,255,0)',
                verticalAlign: 'top',
                y: 5,
                borderColor: 'rgba(255,255,255,0)'
            },
            labels: offsets.map(o => ({
                point: {
                    xAxis: 0,
                    yAxis: yAxisNumber,
                    x: (new Date(o.date)).getTime(),
                    y: selectValue(o.values, axisValueType)
                },
                text: "O"
            }))
        });

        annotations.push({
            draggable: undefined,
            labelOptions: {
                verticalAlign: 'top',
                backgroundColor: 'rgba(255,255,255,0)',
                y: 5,
                borderColor: 'rgba(255,255,255,0)'
            },
            labels: references.map(r => ({
                point: {
                    xAxis: 0,
                    yAxis: yAxisNumber,
                    x: (new Date(r.date)).getTime(),
                    y: selectValue(r.values, axisValueType)
                },
                text: "R"
            }))
        });
    })

    return annotations;
}

const getLogbookAnnotations = (logbook: LogbookModel): Highcharts.AnnotationsOptions => {
    return ({
        draggable: '',
        shapes: [
            {
                type: 'path',
                dashStyle: 'Dash',
                points: [
                    {
                        x: (new Date(logbook.reportDate)).getTime(),
                        xAxis: 0,
                        y: 0,
                        yAxis: null
                    },
                    (annotation: any) => ({
                        x: (new Date(logbook.reportDate)).getTime(),
                        xAxis: 0,
                        y: annotation.chart.plotHeight - 10,
                        yAxis: null
                    })
                ]
            }]
    })
}

interface ITimeValueSeriesOptions {
    yAxisNumber: number;
    sensorsData: Map<string, TimeValueChartDataModel[]>;
    mainAxisSensorsInfo: HtmlReportSensorDescriptionViewModel[];
    secondaryAxisSensorsInfo: HtmlReportSensorDescriptionViewModel[];
    needMarkers: boolean;
    showNoData: boolean;
    legendText: string;
    valueType: SensorValueAttribute;
    baseValueType: SensorValueAttribute;
    useConversion: boolean,
    convMult: number;
    regression: boolean;
    regressionSettings: ChartRegressionSettings;
    numberOfDigits: number;
    visualSettings: ProjectVisualSettings;
    isRightAxis: boolean;
}

const getLogbookSeries = (logbooks: LogbookModel[]): Highcharts.SeriesOptionsType[] => {
    const series: Highcharts.SeriesOptionsType[] = [];

    logbooks.forEach(logbook => {
        const logbookSeriaOptions: Highcharts.SeriesOptionsType = {
            type: 'scatter',
            color: 'red',
            showInLegend: false,
            data: [[(new Date(logbook.reportDate)).getTime(), 0]],
            lineWidth: 10,
            yAxis: LOGBOOK_AXIS_NAME,
            xAxis: 0,
            custom: {
                logbookId: logbook.id
            },
            states: {
                inactive: {
                    enabled: false
                }
            },
            marker: {
                enabled: true,
                symbol: getMarkerSymbol({ ... new ColorizableElement(), Symbol: SensorSymbol.Circle }),
                lineColor: 'red',
                lineWidth: 2
            }
        };
        series.push(logbookSeriaOptions);
    })

    return series;
}

const sortDataSeries = (a: [string, TimeValueChartDataModel[]], b: [string, TimeValueChartDataModel[]], props: ITimeValueSeriesOptions): number => {
    const sensorsInfo = props.isRightAxis ? props.secondaryAxisSensorsInfo : props.mainAxisSensorsInfo;
    const aSensor = sensorsInfo.find(s => s.id === a[0]);
    const bSensor = sensorsInfo.find(s => s.id === b[0]);

    if (!aSensor || !bSensor) {
        return 0;
    }

    const aName = props.legendText && aSensor.legendText ? aSensor.legendText : aSensor.name;
    const bName = props.legendText && bSensor.legendText ? bSensor.legendText : bSensor.name;


    return aName === bName ? 0 : aName > bName ? 1 : -1;
}

const getTimeValueSeries = (chart: TimeValueChartModel, props: ITimeValueSeriesOptions): Highcharts.SeriesOptionsType[] => {
    const series: Highcharts.SeriesOptionsType[] = [];

    if (props.showNoData) {
        return [];
    }

    const drawLinesBetweenPoints = props.isRightAxis ? chart.DrawLineBetweenPointsSecondary.value : chart.DrawLineBetweenPoints.value;
    const sensorsInfo = props.isRightAxis ? props.secondaryAxisSensorsInfo : props.mainAxisSensorsInfo;

    const sortedSensorsData = new Map<string, TimeValueChartDataModel[]>([...props.sensorsData].sort((a, b) => sortDataSeries(a, b, props)))

    sortedSensorsData.forEach((data, fullId) => {
        const sensorInfo = sensorsInfo.find(s => s.id === fullId);
        const sensorData = props.sensorsData.get(fullId);
        if (sensorInfo && sensorData) {

            const seriesData = getSeriesData(sensorData, props.valueType, props.baseValueType, props.useConversion, props.convMult);

            const seriesSettings: Highcharts.SeriesOptionsType = {
                type: 'line',
                name: props.legendText && sensorInfo.legendText ? sensorInfo.legendText : sensorInfo.name,
                color: sensorInfo.color,
                lineWidth: drawLinesBetweenPoints ? getGeovisChartSeriesLineWidth(props.visualSettings, sensorInfo) : 0,
                dashStyle: getDashStyleValue(sensorInfo.lineType),
                data: seriesData,
                yAxis: props.yAxisNumber,
                custom: {
                    sensorId: sensorInfo.name,
                    countData: seriesData.length,
                    numberOfDigits: props.numberOfDigits,
                    regression: false,
                    sensorFullId: fullId
                },
                marker: {
                    enabled: props.needMarkers,
                    symbol: getMarkerSymbol({ ... new ColorizableElement(), Symbol: sensorInfo.symbol }),
                    lineColor: sensorInfo.color,
                    lineWidth: getGeovisChartSeriesLineWidth(props.visualSettings, sensorInfo)
                },
                states: {
                    hover: {
                        lineWidthPlus: drawLinesBetweenPoints ? 1 : 0
                    }
                }
            };

            series.push(seriesSettings);

            if (props.regression) {
                const regressionSeriesSettings = getRegressionSettings(seriesSettings, props.regressionSettings);

                if (regressionSeriesSettings) {
                    series.push(regressionSeriesSettings);
                }
            }
        }
    });

    return series;
}

const prepareDate = (dateString: string): number => {
    const date = new Date(dateString);
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
}

const getSeriesData = (sensorData: TimeValueChartDataModel[], valueType: SensorValueAttribute, baseValueType: SensorValueAttribute, useConversion: boolean, convMult: number = 1): number[][] => {
    const result: number[][] = [];

    sensorData.forEach((data) => {
        // when we have an array of values we should choose what value to add to the series
        let value = NaN;

        if (valueType !== baseValueType) {
            value = selectValue(data.value, valueType);
        }
        else if (data.value.length > 0) {
            value = data.value[0];
        }

        if (!isNaN(value)) {
            result.push([prepareDate(data.x), useConversion ? value * convMult : value]);
        }
    })

    return result;
}

