/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.02.2020
 * @description Leaflet Map Tiles Layer
 */

import { LatLngBounds, latLngBounds } from 'leaflet';
import { TileLayer, TileLayerProps } from 'react-leaflet';
import { MAP_TILES_API_ROOT } from '../../ApiConfig';
import { LeafletMapTilesRoutes } from '../../helpers/MapTilesServiceRoutes';
import { ProjectDXFLayerInfo } from '../../server/ProjectDXFLayerInfo';
import AuthService from '../../services/AuthService';
import { MaxMapZoomLevel, MaxNativeTilesZoomLevel } from './types';

interface IGEOvisTileLayerProps {
    layer: ProjectDXFLayerInfo;
}

const getMapTileLayerBounds = ({ MapTileLayerBounds }: ProjectDXFLayerInfo): LatLngBounds | undefined => {
    if (!MapTileLayerBounds || !MapTileLayerBounds.IsValid) {
        return undefined;
    }

    const { MaxX, MaxY, MinX, MinY } = MapTileLayerBounds;

    return latLngBounds([
        [MinY - MinY * 0.1, MinX - MinX * 0.1],
        [MaxY + MaxY * 0.1, MaxX + MaxX * 0.1]
    ]);
}

export const GEOvisTileLayer = ({ layer }: IGEOvisTileLayerProps) => {

    const routeUrl = LeafletMapTilesRoutes.DxfMapTilesRoute.patch(layer.Id).path;
    const tilesUrl = AuthService.getFullUrl(MAP_TILES_API_ROOT, routeUrl);

    const tileLayerProps: TileLayerProps = {
        url: tilesUrl,
        zIndex: 100,
        bounds: getMapTileLayerBounds(layer),
        maxZoom: MaxMapZoomLevel,
        maxNativeZoom: MaxNativeTilesZoomLevel
    };

    return (
        <TileLayer {...tileLayerProps} />
    );
}