import Avatar from '@atlaskit/avatar';
import Dropdown, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { withTranslation, WithTranslation } from 'react-i18next';
import globusDeImage from 'src/images/globus-de.png';
import globusEnImage from 'src/images/globus-en.png';
import globusFrImage from 'src/images/globus-fr.png';
import globusItImage from 'src/images/globus-it.png';
import i18next, { AllowedLanguages, ILanguageProps } from '../i18n';
import { ActionResponse } from '../server/ActionResponse';
import ServerRoutesGen from '../server/Routes/ServerRoutesGen';
import AuthService from '../services/AuthService';
import FlagService from '../services/FlagService';
import { changeLanguage, refreshNavigationState } from '../store/creators/navigationCreators';
import { IGeovisAction } from '../store/types';
import { setLocalStorageItem } from './utils';

interface ILanguageSelectorProps extends WithTranslation {
    dispatch: (action: IGeovisAction) => void
}

const LanguageSelector = ({ dispatch }: ILanguageSelectorProps) => {

    const currentLanguage = i18next.language;

    const getLanguageProps = (language: string): ILanguageProps | null => {
        const filtered = AllowedLanguages.filter(l => l.language === language);
        return filtered && filtered.length > 0 ? filtered[0] : null;
    }

    const changeLanguageCallback = (error: any) => {
        if (!error) {
            setTimeout(() => dispatch(refreshNavigationState()), 50);
        }
    }

    const changeUserLanguage = async (language: string) => {
        try {
            const url = ServerRoutesGen.Account.ChangeLanguage.patch(language).path;
            const result = await AuthService.getRequestHelper(AuthService).post<ActionResponse>(url, "");
            if (result.Success) {
                setLocalStorageItem("id_language", language);
                i18next.changeLanguage(language, changeLanguageCallback)
                    .then(() => dispatch(changeLanguage(language)));
            }
            else {
                FlagService.addErrors("Failed to change the language", result.Messages);
            }
        }
        catch (error) {
            FlagService.addError("Failed to change the language", error);
        }
    }

    const toggleLanguage = (event?: any) => {
        const selectedValue = event && event.target.innerText;
        const languageProps = getLanguageProps(selectedValue);
        if (languageProps) {
            const newLanguage = languageProps.code;
            if (newLanguage !== currentLanguage) {
                changeUserLanguage(newLanguage);
            }
        }
    }

    const GetAvatarSrc = (langCode: string) => {
        switch (langCode) {
            case "de":
                return globusDeImage;

            case "fr":
                return globusFrImage;

            case "it":
                return globusItImage;

            default:
                return globusEnImage;
        }
    }

    const avatarSrc = GetAvatarSrc(currentLanguage);

    return (
        <Dropdown key="language-selector-dropdown-key" trigger={<Avatar src={avatarSrc} size="xsmall" />} position="right bottom">
            <DropdownItemGroup title={i18next.t("Select a language")}>
                {AllowedLanguages.map((language) => (
                    <DropdownItem
                        key={`language_${language.code}`}
                        onClick={toggleLanguage}>{language.language}
                    </DropdownItem>
                ))}
            </DropdownItemGroup>
        </Dropdown>
    );
}

export default withTranslation()(LanguageSelector);