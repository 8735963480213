/**
 * Map legend implementation as Leaflet control, 
 * need to do in JS, because need to access to Low level implementation
 */

export const createMapSectionViewLegend = (items, svgClassName) => {
    const legend = L.control({ position: 'bottomleft' });

    // create legend HTML
    legend.onAdd = (map) => {

        var div = L.DomUtil.create('div', svgClassName);
        const innerHTMLArr = items.map(item => {
            return `<div class="map-section-view-legend-item">${item.icon}<div class="map-section-view-legend-item-label">${item.label}</div></div>`;
        });

        div.innerHTML = innerHTMLArr.join("");

        return div;
    };

    return legend;
}

export const createProjectsOverviewMapLegend = (items) => {
    const legend = L.control({ position: 'bottomleft' });

    // create legend HTML
    legend.onAdd = (map) => {

        var div = L.DomUtil.create('div', 'projectsOverviewLegend');
        const innerHTMLArr = items.map(item => {
            return `<div>${item.icon}&nbsp;${item.label}</div>`;
        });

        div.innerHTML = innerHTMLArr.join("");

        return div;
    };

    return legend;
}

export const createDXFViewsMapLegend = (items, selectedItem, onElementChanged) => {
    const legend = L.control({ position: 'bottomleft' });

    // create legend HTML
    legend.onAdd = (map) => {

        // base div for select
        var div = L.DomUtil.create('div', 'projectsOverviewLegend');
        const selectCtrl = L.DomUtil.create('select', undefined, div);
        const selectEvent = L.DomEvent.addListener(selectCtrl, 'change', onElementChanged);

        const innerHTMLArr = items.map(item => {
            return `<option ${selectedItem === item.Id ? "selected" : ""} value=${item.Id}>${item.Name}</option>`;
        });

        selectCtrl.innerHTML = innerHTMLArr.join("");

        return div;
    };

    return legend;
}

export const createGeoRastersMapLegend = (items, selectedItem, onElementChanged) => {
    const legend = L.control({ position: 'bottomright' });

    // create legend HTML
    legend.onAdd = (map) => {

        // base div for select
        var div = L.DomUtil.create('div', 'geoRastersLegend');
        const selectCtrl = L.DomUtil.create('select', undefined, div);
        const selectEvent = L.DomEvent.addListener(selectCtrl, 'change', onElementChanged);

        const innerHTMLArr = items.map(item => {
            return `<option ${selectedItem === item.Id ? "selected" : ""} value=${item.Id}>${item.Name}</option>`;
        });

        selectCtrl.innerHTML = innerHTMLArr.join("");

        return div;
    };

    return legend;
}