import { Reducer } from "redux";
import { ProjectLogoKind } from "../../components/logosManager/types";
import { addOrUpdateElementInMap, elementsToMap } from "../../helpers/StorageHelper";
import { LogoFileInfo } from "../../server/AVTService/TypeLibrary/Logo/LogoFileInfo";
import { CompanyInfo } from "../../server/GEOvis3/Model/Company/CompanyInfo";
import { CompanyOwnData } from "../../server/GEOvis3/Model/Company/CompanyOwnData";
import {
    GEOVIS_BUSINESS_COMPANIES,
    GEOVIS_BUSINESS_COMPANIES_DATA,
    GEOVIS_BUSINESS_COMPANIES_NAMES,
    GEOVIS_BUSINESS_COMPANIES_NAMES_DATA,
    GEOVIS_BUSINESS_COMPANIES_ROWS_PER_PAGE,
    GEOVIS_BUSINESS_COMPANY_OWN_DATA,
    GEOVIS_BUSINESS_COMPANY_OWN_DATA_FAILED,
    GEOVIS_BUSINESS_COMPANY_OWN_DATA_IN_PROGRESS,
    GEOVIS_BUSINESS_COMPANY_RELATED_DATA,
    GEOVIS_BUSINESS_COMPANY_RELATED_DATA_IN_PROGRESS,
    GEOVIS_BUSINESS_HIDE_DELETE_COMPANY_DIALOG,
    GEOVIS_BUSINESS_HIDE_EDIT_COMPANY_DIALOG,
    GEOVIS_BUSINESS_REMOVE_RELATED_COMPANY,
    GEOVIS_BUSINESS_REMOVING_COMPANY_IN_PROGRESS,
    GEOVIS_BUSINESS_SHOW_DELETE_COMPANY_DIALOG,
    GEOVIS_BUSINESS_SHOW_EDIT_COMPANY_DIALOG,
    GEOVIS_BUSINESS_UPDATE_RELATED_COMPANY,
    GEOVIS_COMPANIES_SEARCH,
    GEOVIS_COMPANIES_SET_PAGE,
    GEOVIS_COMPANIES_SET_ROW_PER_PAGES,
    GEOVIS_COMPANIES_SORT,
    GEOVIS_COMPANY_LOGOS_INFO,
    GEOVIS_COMPANY_LOGOS_INFO_DATA,
    GEOVIS_COMPANY_LOGOS_INFO_ERROR,
    GEOVIS_COMPANY_STORAGE_UPDATE,
    GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA,
    GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA_ERROR,
    GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA_IN_PROGRESS
} from "../actions/dataActions";
import { LOGIN_SUCCESS } from "../actions/navigationActions";
import {
    LOGO_EDIT_HIDE_LOGO_PICKER,
    LOGO_EDIT_SET_LOGO_TO_PROJECT,
    LOGO_EDIT_SET_SELECTED_LOGO,
    LOGO_EDIT_SHOW_LOGO_PICKER,
    LOGO_EDIT_UPLOAD_END,
    LOGO_EDIT_UPLOAD_PROCESSING,
    LOGO_EDIT_UPLOAD_STARTED
} from "../actions/projectEditActions";
import { companyInitialState, IGeovisCompaniesAction, IGeovisCompaniesDataState } from "../companies.types";
import { defaultSomethingStorageState } from "../types";
import { GeovisUploadFileState } from "../uploading.types";
import { GeovisCompanyType } from "../../server/AVTService/TypeLibrary/Common/GeovisCompanyType";

const companyOwnDataInitialState: CompanyOwnData = {
    ...new CompanyOwnData(),
    Users: [],
    Projects: []
}

export const companiesDataInitialState: IGeovisCompaniesDataState = {
    companyStorage: {
        ...defaultSomethingStorageState,
        company: companyInitialState,
        removingInProgress: false
    },
    relatedCompaniesStorage: {
        ...defaultSomethingStorageState,
        companies: []
    },
    rowsPerPage: 25,
    addCompanyStorage: {
        ...defaultSomethingStorageState,
        companies: []
    },
    companyRelatedDataStorage: {
        ...defaultSomethingStorageState,
        companyRelatedData: []
    },
    companyOwnDataStorage: {
        ...defaultSomethingStorageState,
        companyOwnData: companyOwnDataInitialState
    },
    showEditDialog: false,
    showDeleteDialog: false,
    searchQuery: "",
    logoEditState: {
        isPostInProgress: false,
        logoKind: ProjectLogoKind.Company,
        selectedLogoId: '',
        showSelectLogoPicker: false,
        uploadingFiles: []
    },
    logosFileStorage: {
        ...defaultSomethingStorageState,
        filesInfo: new Map<string, LogoFileInfo>()
    },
    companySupportMessageTemplatesStorage: {
        ...defaultSomethingStorageState,
        supportMessageTemplates: []
    },
    currentPages: new Map([[GeovisCompanyType.Owner, 1], [GeovisCompanyType.Partner, 1], [GeovisCompanyType.Viewer, 1]]),
    rowsPerPages: new Map([[GeovisCompanyType.Owner, 25], [GeovisCompanyType.Partner, 25], [GeovisCompanyType.Viewer, 25]]),
    sortKeys: new Map([[GeovisCompanyType.Owner, 'name'], [GeovisCompanyType.Partner, 'name'], [GeovisCompanyType.Viewer, 'name']]),
    sortOrders: new Map([[GeovisCompanyType.Owner, 'ASC'], [GeovisCompanyType.Partner, 'ASC'], [GeovisCompanyType.Viewer, 'ASC']]),
}

const getCompanyInfo = (companyList: CompanyInfo[], targetCompanyId?: string, initialName?: string): CompanyInfo => {
    if (!targetCompanyId) {
        return { ...companyInitialState, Name: initialName || "" };
    }

    const filtered = companyList.filter(c => c.Id === targetCompanyId);
    return filtered.length > 0 ? filtered[0] : companyInitialState;
}

const updateCompanyInfo = (objectList: CompanyInfo[], targetObject?: CompanyInfo): CompanyInfo[] => {
    if (!targetObject) {
        return objectList;
    }

    const targetIndex = objectList.findIndex(c => c.Id === targetObject.Id);
    if (targetIndex !== -1) {
        const updatedObjectList = [...objectList];
        updatedObjectList[targetIndex] = targetObject;
        return updatedObjectList;
    }

    return objectList;
}

const companiesReducer: Reducer<IGeovisCompaniesDataState> = (
    state: IGeovisCompaniesDataState = companiesDataInitialState,
    action: IGeovisCompaniesAction): IGeovisCompaniesDataState => {

    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...companiesDataInitialState
            };

        case GEOVIS_BUSINESS_COMPANIES:
            return {
                ...companiesDataInitialState
            };

        case GEOVIS_BUSINESS_COMPANIES_DATA:
            return {
                ...state,
                relatedCompaniesStorage: {
                    ...state.relatedCompaniesStorage,
                    isLoaded: true,
                    isLoading: false,
                    companies: action.companies ? [...action.companies] : []
                },
                searchQuery: ""
            }
            break;

        case GEOVIS_BUSINESS_COMPANIES_ROWS_PER_PAGE:
            if (action.rowsPerPage) {
                return {
                    ...state,
                    rowsPerPage: action.rowsPerPage
                }
            }
            break;

        case GEOVIS_BUSINESS_SHOW_EDIT_COMPANY_DIALOG:
            return {
                ...state,
                companyStorage: {
                    ...companiesDataInitialState.companyStorage,
                    isLoaded: true,
                    isLoading: false,
                    isError: false,
                    company: {
                        ...getCompanyInfo(state.relatedCompaniesStorage.companies, action.companyId, state.searchQuery)
                    }
                },
                showEditDialog: true
            };

        case GEOVIS_BUSINESS_SHOW_DELETE_COMPANY_DIALOG:
            return {
                ...state,
                companyStorage: {
                    ...companiesDataInitialState.companyStorage,
                    isLoaded: true,
                    isLoading: false,
                    company: getCompanyInfo(state.relatedCompaniesStorage.companies, action.companyId)
                },
                showDeleteDialog: true
            };

        case GEOVIS_BUSINESS_HIDE_EDIT_COMPANY_DIALOG:
            return {
                ...state,
                showEditDialog: false
            };

        case GEOVIS_BUSINESS_HIDE_DELETE_COMPANY_DIALOG:
            return {
                ...state,
                showDeleteDialog: false
            };

        case GEOVIS_BUSINESS_COMPANIES_NAMES:
            return {
                ...state,
                addCompanyStorage: { ...companiesDataInitialState.addCompanyStorage },
                searchQuery: action.searchQuery || ""
            };

        case GEOVIS_BUSINESS_COMPANIES_NAMES_DATA:
            if (action.companies) {
                return {
                    ...state,
                    addCompanyStorage: {
                        isLoaded: true,
                        isLoading: false,
                        isError: false,
                        isInProgress: false,
                        companies: [...action.companies],
                        errorDescription: ''
                    }
                };
            }
            break;

        case GEOVIS_BUSINESS_REMOVING_COMPANY_IN_PROGRESS:
            return {
                ...state,
                companyStorage: {
                    ...state.companyStorage,
                    removingInProgress: true
                }
            }

        case GEOVIS_BUSINESS_REMOVE_RELATED_COMPANY:
            if (action.companyId) {
                return {
                    ...state,
                    relatedCompaniesStorage: {
                        ...state.relatedCompaniesStorage,
                        isLoaded: true,
                        isLoading: false,
                        companies: [...state.relatedCompaniesStorage.companies.filter(c => c.Id !== action.companyId)]
                    },
                    companyStorage: {
                        ...companiesDataInitialState.companyStorage
                    },
                    showDeleteDialog: false
                }
            }
            break;

        case GEOVIS_BUSINESS_UPDATE_RELATED_COMPANY:
            if (action.company) {
                return {
                    ...state,
                    relatedCompaniesStorage: {
                        ...state.relatedCompaniesStorage,
                        isLoaded: true,
                        isLoading: false,
                        companies: updateCompanyInfo(state.relatedCompaniesStorage.companies, action.company)
                    }
                }
            }
            break;

        case GEOVIS_BUSINESS_COMPANY_RELATED_DATA_IN_PROGRESS:
            return {
                ...state,
                companyRelatedDataStorage: { ...companiesDataInitialState.companyRelatedDataStorage }
            }
            break;

        case GEOVIS_BUSINESS_COMPANY_RELATED_DATA:
            if (action.companyRelatedData) {
                return {
                    ...state,
                    companyRelatedDataStorage: {
                        isLoaded: true,
                        isLoading: false,
                        isError: false,
                        isInProgress: false,
                        companyRelatedData: [...action.companyRelatedData],
                        errorDescription: ''
                    }
                }
            }
            break;

        case GEOVIS_BUSINESS_COMPANY_OWN_DATA_IN_PROGRESS:
            return {
                ...state,
                companyOwnDataStorage: { ...companiesDataInitialState.companyOwnDataStorage }
            }

        case GEOVIS_BUSINESS_COMPANY_OWN_DATA:
            if (action.companyOwnData) {
                return {
                    ...state,
                    companyOwnDataStorage: {
                        isLoaded: true,
                        isLoading: false,
                        isError: false,
                        isInProgress: false,
                        companyOwnData: { ...action.companyOwnData },
                        errorDescription: ''
                    }
                }
            }
            break;

        case GEOVIS_BUSINESS_COMPANY_OWN_DATA_FAILED:
            return {
                ...state,
                companyOwnDataStorage: {
                    isLoaded: true,
                    isLoading: false,
                    isError: true,
                    isInProgress: false,
                    companyOwnData: { ...companyOwnDataInitialState },
                    errorDescription: ''
                }
            }

        case GEOVIS_COMPANIES_SEARCH:
            return {
                ...state,
                searchQuery: action.searchQuery || "",
                currentPages: new Map([[GeovisCompanyType.Owner, 1], [GeovisCompanyType.Partner, 1], [GeovisCompanyType.Viewer, 1]]),
            }

        case GEOVIS_COMPANIES_SET_PAGE: {
            if (action.companyType === undefined || !action.page) {
                return state;
            }

            state.currentPages[action.companyType] = action.page;

            return {
                ...state,
                currentPages: state.currentPages
            }
        }

        case GEOVIS_COMPANIES_SORT: {
            if (action.companyType === undefined || !action.sortKey || !action.sortOrder) {
                return state;
            }

            state.sortKeys[action.companyType] = action.sortKey;
            state.sortOrders[action.companyType] = action.sortOrder;

            return {
                ...state,
                sortKeys: state.sortKeys,
                sortOrders: state.sortOrders
            }
        }

        case GEOVIS_COMPANIES_SET_ROW_PER_PAGES: {
            if (action.companyType === undefined || !action.rowsPerPage) {
                return state;
            }

            state.rowsPerPages[action.companyType] = action.rowsPerPage;

            return {
                ...state,
                rowsPerPages: state.rowsPerPages
            }
        }

        case GEOVIS_COMPANY_STORAGE_UPDATE:
            if (action.company) {
                return {
                    ...state,
                    companyStorage: {
                        ...state.companyStorage,
                        company: { ...action.company }
                    }
                }
            }
            break;

        case LOGO_EDIT_SHOW_LOGO_PICKER:
            if (action.logoKind === undefined) {
                return state;
            }

            return {
                ...state,
                logoEditState: {
                    ...state.logoEditState,
                    selectedLogoId: action.objectId || '',
                    logoKind: action.logoKind,
                    showSelectLogoPicker: true
                }
            }

        case LOGO_EDIT_HIDE_LOGO_PICKER:
            return {
                ...state,
                logoEditState: {
                    ...state.logoEditState,
                    selectedLogoId: '',
                    logoKind: ProjectLogoKind.Company,
                    showSelectLogoPicker: false
                }
            };

        case LOGO_EDIT_SET_SELECTED_LOGO:
            if (!action.objectId) {
                return state;
            }

            return {
                ...state,
                logoEditState: {
                    ...state.logoEditState,
                    selectedLogoId: action.objectId
                }
            };

        case LOGO_EDIT_SET_LOGO_TO_PROJECT:
            if (!action.objectId || action.logoKind !== ProjectLogoKind.Company) {
                return state;
            }

            return {
                ...state,
                logoEditState: {
                    ...state.logoEditState,
                    showSelectLogoPicker: false,
                    selectedLogoId: ''
                },
                companyStorage: {
                    ...state.companyStorage,
                    company: {
                        ...state.companyStorage.company,
                        LogoId: action.objectId
                    }
                }
            };

        case LOGO_EDIT_UPLOAD_STARTED:
        case LOGO_EDIT_UPLOAD_PROCESSING:
            if (!action.uploadingFiles) {
                return state;
            }

            return {
                ...state,
                logoEditState: {
                    ...state.logoEditState,
                    uploadingFiles: action.uploadingFiles.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f }))
                }
            };

        case LOGO_EDIT_UPLOAD_END:
            if (!action.objectId || !action.logoFileInfo) {
                return state;
            }

            return {
                ...state,
                logosFileStorage: {
                    isError: false,
                    isLoaded: true,
                    isLoading: false,
                    isInProgress: false,
                    filesInfo: addOrUpdateElementInMap(state.logosFileStorage.filesInfo, action.logoFileInfo),
                    errorDescription: ''
                },
                logoEditState: {
                    ...state.logoEditState,
                    uploadingFiles: state.logoEditState.uploadingFiles.filter(f => f.id !== action.objectId)
                }
            };


        case GEOVIS_COMPANY_LOGOS_INFO:
            return {
                ...state,
                logosFileStorage: { ...companiesDataInitialState.logosFileStorage }
            };

        case GEOVIS_COMPANY_LOGOS_INFO_ERROR:
            return {
                ...state,
                logosFileStorage: {
                    ...companiesDataInitialState.logosFileStorage,
                    isError: true
                }
            }

        case GEOVIS_COMPANY_LOGOS_INFO_DATA:
            if (!action.logoFilesInfo) {
                return state;
            }

            return {
                ...state,
                logosFileStorage: {
                    isError: false,
                    isLoaded: true,
                    isLoading: false,
                    isInProgress: false,
                    filesInfo: elementsToMap(action.logoFilesInfo),
                    errorDescription: ''
                }
            };

        case GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA_IN_PROGRESS:
            return {
                ...state,
                companySupportMessageTemplatesStorage: { ...companiesDataInitialState.companySupportMessageTemplatesStorage }
            };

        case GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA_ERROR:
            return {
                ...state,
                companySupportMessageTemplatesStorage: {
                    ...companiesDataInitialState.companySupportMessageTemplatesStorage,
                    isError: true,
                    errorDescription: action.errorDescription || ''
                }
            }

        case GEOVIS_COMPANY_SUPPORT_TEMPLATES_DATA:
            if (!action.supportMessageTemplates) {
                return state;
            }

            return {
                ...state,
                companySupportMessageTemplatesStorage: {
                    isError: false,
                    isLoaded: true,
                    isLoading: false,
                    isInProgress: false,
                    errorDescription: '',
                    supportMessageTemplates: [...action.supportMessageTemplates]
                }
            }
    }
    return state;
}

export default companiesReducer;