/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.10.2022
 * @description The warning dialog if user wan't to downgrade his relation to the project
 */

import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { t } from '../../i18n';
import { GeovisUserToProjectRole, getGeovisUserToProjectRoleToDescription } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserToProjectRole';

interface IComponentProps {
    sourceRole: GeovisUserToProjectRole;
    destRole: GeovisUserToProjectRole;

    onOkClick: () => void;
    onCancelClick: () => void;
}

/**
 * Dialog to ask user about downgrading his relation to the project
 * @param props
 * @returns 
 */
export const DowngradeUserToProjectRoleDialog = ({ destRole, sourceRole, onOkClick, onCancelClick }: IComponentProps) => (
    <ModalTransition>
        <Modal
            heading={t("Downgrading the user role")}
            actions={[
                { text: t("Ok"), onClick: onOkClick, appearance: "warning" },
                { text: t("Cancel"), onClick: onCancelClick }
            ]}
            onClose={onCancelClick}
            shouldCloseOnOverlayClick={false} >
            <div>
                {t("degratingUserRoleWarning").replace("%1", getGeovisUserToProjectRoleToDescription(sourceRole)).replace("%2", getGeovisUserToProjectRoleToDescription(destRole))}
            </div>
        </Modal>
    </ModalTransition>
)
